<template>
  <div v-title :data-title="tit">
    <!-- 头部 -->
    <mt-header fixed :title="tit">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
      <!-- <mt-button icon="more" slot="right"></mt-button> -->
    </mt-header>

    <div class="page-content">
      <feed-list @getData="getData" ref="child"></feed-list>
    </div>
  </div>
</template>

<script>
import FeedList from "@/components/FeedList";

export default {
  components: {
    FeedList
  },
  data() {
    return {
      userId: "",
      type: this.$route.query.type,
      items: [],
      itemLen: -1,
      tit: "游记列表"
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      if (this.check_login()) {
        this.userId = this.check_login().user_id;
      }
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      this.getData(1);
    },
    getData(...page) {
      var note_url = "User/get_user_notes_new";
      var data = "";
      if (this.type == "myNote") {
        this.tit = "我的游记列表";
        data = {
          page: page[0],
          user_id: this.userId
        };
      } else if (this.type == "taNote") {
        this.tit = "TA的游记列表";
        data = {
          page: page[0],
          user_id: this.$route.query.user_id
        };
      }
      this.$ajax("post", note_url, data, res => {
        if (res.data.id == 0) {
          if (page[0] == 1) {
            this.items = [];
            this.itemLen = res.data.data.length;
          }
          this.items = this.items.concat(res.data.data);
        }
        this.$refs.child.parentMsg({
          items: this.items,
          itemLen: this.itemLen,
          userId: this.userId,
          parklink: true
        });
      });
    }
  }
};
</script>

<style scoped>
.page-content {
  padding-bottom: 0;
}
</style>