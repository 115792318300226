<template>
  <div class="permission">
    <div class="top">
      <p>进行以下设置后，能够有效解决轨迹拉直线和计步不准确的问题</p>
    </div>
    <div class="iphonetype">
      <p class="iphone">请选择手机类型</p>
      <p class="color" id="iphone_type" v-if="!name" @click="name = !name">机型选择有误？点击修改</p>
    </div>
    <ul class="type" v-if="name">
      <li class="my-step" v-for="(k,i) in iphone" @click="iphone_type(k)" :key="i">
        <a href="javascript:;">
          <span class="distance">{{k}}</span>
        </a>
      </li>
    </ul>
    <div v-if="!name">
      <ul v-if="type == '小米'">
        <li class="my-step">
          <router-link :to="{path:'/setting/QiDongPermission',query:{type:'小米',name:'启动'}}">
            <span class="distance">小米自启动权限</span>
          </router-link>
        </li>
        <li class="my-step">
          <router-link :to="{path:'/setting/QiDongPermission',query:{type:'小米',name:'神隐'}}">
            <span class="distance">神隐模式</span>
          </router-link>
        </li>
      </ul>
      <ul v-if="type == '华为'">
        <li class="my-step">
          <router-link :to="{path:'/setting/QiDongPermission',query:{type:'华为',name:'启动'}}">
            <span class="distance">华为自启动权限</span>
          </router-link>
        </li>
        <li class="my-step">
          <router-link :to="{path:'/setting/QiDongPermission',query:{type:'华为',name:'后台保护'}}">
            <span class="distance">华为后台保护</span>
          </router-link>
        </li>
        <li class="my-step">
          <router-link :to="{path:'/setting/QiDongPermission',query:{type:'华为',name:'白名单'}}">
            <span class="distance">华为一键清理白名单</span>
          </router-link>
        </li>
      </ul>
      <ul v-if="type == '魅族'">
        <li class="my-step">
          <router-link :to="{path:'/setting/QiDongPermission',query:{type:'魅族',name:'启动'}}">
            <span class="distance">魅族自启动权限</span>
          </router-link>
        </li>
        <li class="my-step">
          <router-link :to="{path:'/setting/QiDongPermission',query:{type:'魅族',name:'后台保护'}}">
            <span class="distance">魅族后台保护</span>
          </router-link>
        </li>
        <li class="my-step">
          <router-link :to="{path:'/setting/QiDongPermission',query:{type:'魅族',name:'白名单'}}">
            <span class="distance">魅族应用白名单</span>
          </router-link>
        </li>
      </ul>
      <ul v-if="type == '大神'">
        <li class="my-step">
          <router-link :to="{path:'/setting/QiDongPermission',query:{type:'大神',name:'启动'}}">
            <span class="distance">大神自启动权限</span>
          </router-link>
        </li>
        <li class="my-step">
          <router-link :to="{path:'/setting/QiDongPermission',query:{type:'大神',name:'后台保护'}}">
            <span class="distance">大神后台保护</span>
          </router-link>
        </li>
      </ul>
      <ul v-if="type == '其他手机'">
        <li class="my-step">
          <router-link :to="{path:'/setting/QiDongPermission',query:{type:'其他手机',name:'启动'}}">
            <span class="distance">手机自启动权限</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      iphone: ["小米", "华为", "魅族", "大神", "其他手机"],
      type: "",
      name: true
    };
  },
  methods: {
    iphone_type(iphone) {
      this.type = iphone;
      this.name = !this.name;
    }
  }
};
</script>
<style scoped>
.permission {
  min-height: 100%;
  background-color: #fff;
  font-size: 0.7rem;
}

.permission .top {
  padding: 0.4rem;
}

.permission .color {
  color: #00cbbd;
}

.permission .iphonetype {
  padding: 0.4rem 0;
  border-bottom: 1px solid #f2f2f2;
}

.permission .iphonetype p {
  text-align: center;
}

.permission .my-step {
  text-align: left;
  border-bottom: 1px solid #f2f2f2;
  height: 2rem;
  line-height: 2rem;
}

.permission .my-step a {
  border-right: 0.6rem solid transparent;
  display: block;
  background-image: url(../../assets/img/home_icon_title_more.png);
  background-repeat: no-repeat;
  background-size: 0.2rem 0.5rem;
  background-position: center right;
  color: #333;
}

.permission .my-step span.distance {
  margin-left: 0.4rem;
  padding: 0;
  background: none;
}

.permission .wrap {
  padding: 0.4rem 1rem 0 1.5rem;
  width: auto;
}

.permission .wrap li {
  position: relative;
  border-left: 1px solid #ddd;
  padding: 0 0 0 1.5rem;
  margin-top: 0.4rem;
}

.permission .wrap p span {
  color: #ff9a80;
}

.permission .wrap li img {
  margin-top: 0.4rem;
  width: 100%;
}

.permission .wrap li img.dingwei {
  width: 2rem;
  position: absolute;
  top: -1rem;
  left: -1rem;
}

.permission .wrap li:last-child {
  border-left: 0;
}
</style>