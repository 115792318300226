<template>
  <div class="container">
    <div class="map-wrapper">
      <!-- leaftmap容器 -->
      <div id="map_" :style="getMapBoxHeight()"></div>
      <!-- 底部菜单 如果是小程序，就不显示 -->
      <div class="footer-menu" v-if="where !== 'applet'">
        <div class="item active">
          <i class="iconfont iconzuji"></i>
          <span class="text">地图</span>
        </div>
        <div class="item" @click="handleMenu('impression')">
          <i class="iconfont icondetail-copy"></i>
          <span class="text">科普</span>
        </div>
        <div class="item" @click="handleMenu('my')">
          <i class="iconfont iconicon-user"></i>
          <span class="text">我的</span>
        </div>
      </div>
      <!-- 分类菜单 -->
      <div
        class="menu-box"
        v-if="bottom_menu_list"
        v-show="showMenu"
        @click.self="showMenu = false"
      >
        <ul class="list">
          <li
            :class="['item', item.id == first_menu_type ? '-active' : '']"
            v-for="(item, i) in bottom_menu_list"
            :key="i"
            @click="toggleMenu(item.id, i)"
          >
            <img :src="setIconByType(item.id)" class="icon" />
            <span class="text">{{item.title}}</span>
          </li>
        </ul>
        <ul class="sub-list" v-if="first_menu_type != 'line'">
          <li class="item" v-for="(item, i) in filterPointListByType()" :key="i" @click="menuLocation(item.id)">
            <img class="icon" :src="icon_object['iconMap' + item.type_]" alt />
            <span class="text">{{item.spots_name}}</span>
            <button class="btn">查看</button>
          </li>
        </ul>
        <!-- 路线列表 -->
        <ul class="sub-list" v-else>
          <li class="item" v-for="(item, i) in lineList" :key="i"  @click="drawLine(item)">
            <img class="icon" :src="icon_object['iconMapline']" alt />
            <span class="text">{{item.title}}</span>
            <button class="btn">查看</button>
          </li>
        </ul>

        <div class="menu-close" @click="showMenu = false">
          <i class="iconfont iconclose"></i>
        </div>
      </div>

      <!-- 分区logo -->
      <transition name="area-fade">
        <div class="pixi-logo" v-if="logo_list" v-show="show_area">
          <div class="logo-wrapper">
            <ul class="logo-menu">
              <li class="logo-menu-list" v-for="(item, i) in logo_list" :key="i">
                <img :src="item.logo_" alt @click="areaLocation(item.x_, item.y_)" />
              </li>
            </ul>
            <div class="logo-btn" @click="show_area= false">
              <i class="iconfont iconqianjin"></i>
            </div>
          </div>
        </div>
      </transition>
      <!-- 统计数据 -->
      <map-user-list v-if="map_data.is_show_data > 0" :mapData="map_data" :show_data="show_data"></map-user-list>
      <!-- 顶部导航 -->
      <transition name="top-fade">
        <div class="function-top" v-show="!show_area">
          <ul class="top-menu">
            <li class="top-menu-li top-brief" @click="show_spot_brief = !show_spot_brief">
              <i class="iconfont iconShape"></i>
              <p>简介</p>
            </li>
            <li v-if="is_show_area" class="top-menu-li top-zone" @click="show_area = true">
              <i class="iconfont iconfenqu"></i>
              <p>分区</p>
            </li>
          </ul>

          <div
            :class="['top-data', 'top-menu-li', 'top-menu', show_data ? 'top-data-active' : '']"
            @click="show_data=!show_data"
          >
            <i class="iconfont iconsanxiangmulu"></i>
            <span>数据</span>
          </div>
          <a
            v-if="is_show_heat"
            class="top-menu-li top-menu top-heat"
            :class="showHeat ? '-active' : ''"
            @click="toggleHeat"
          >
            <i class="iconfont iconshanghurelitu"></i>
            <p>热力</p>
          </a>
          <!-- <div class="top-menu-li top-menu top-line" @click="showLineList =true">
            <i class="iconfont iconluxian1"></i>
            <p>路线</p>
          </div>-->
          <div class="top-menu-li top-menu top-classify" @click="handleShowMenu">
            <i class="iconfont iconleimupinleifenleileibie"></i>
            <p>分类</p>
          </div>
          <a class="top-menu-li top-menu top-vr" :href="vrUrl" v-if="vrUrl">
            <i class="iconfont iconvr"></i>
          </a>
          <div
            class="top-menu-li top-menu top-music"
            @click="playBgMusic"
            v-if="map_data.bgAudioUrl"
          >
            <i class="iconfont iconswitch_open" v-if="bgmusic_play"></i>
            <i class="iconfont iconswitch_close" v-else></i>
            <p>音乐</p>
          </div>
        </div>
      </transition>
      <!-- 景区简介 -->
      <transition name="bounce">
        <div class="spot-brief" v-show="show_spot_brief">
          <div class="wrapper">
            <div class="brief-close" @click="show_spot_brief = false">
              <i class="iconfont iconbaseline-close-px"></i>
            </div>
            <div class="spot-brief-icon">
              <img :src="map_data.logo_" alt />
            </div>
            <div class="spot-brief-header">
              <h2 class="spot-brief-name">{{map_data.label_name}}</h2>
            </div>
            <div class="spot-brief-info">
              <div class="spot-brief-address spot-brief-item">
                <p class="item-name">
                  <i class="iconfont icondizhi2"></i>
                  <span>地址：</span>
                </p>
                <p class="item-value">{{map_data.address}}</p>
              </div>
              <div class="spot-brief-time spot-brief-item" v-if="map_data.time">
                <p class="item-name">
                  <i class="iconfont iconshijian"></i>
                  <span>开放时间：</span>
                </p>
                <p class="item-value">{{map_data.time}}</p>
              </div>
            </div>
            <div class="spot-brief-content">
              <article v-html="map_data.content"></article>
            </div>
          </div>
        </div>
      </transition>
      <!-- 音频播放 -->
      <audio :src="map_data.bgAudioUrl" autoplay="autoplay" ref="audio"></audio>
      <!-- 播放界面 -->
      <transition name="audio-fade">
        <div class="audio-play" v-show="show_audio">
          <span class="audio-play-icon">
            <img src="../../assets/switch/bgmusic.png" alt />
          </span>
          <span class="audio-play-name">{{audio_name || map_data.label_name}}</span>
          <span class="audio-play-close" @click="closeAudio">
            <i class="iconfont iconbaseline-close-px"></i>
          </span>
        </div>
      </transition>
      <!-- 左下功能按钮 -->
      <div class="function-btn">
        <!-- 定位按钮 -->
        <div
          v-if="is_show_location"
          class="location item"
          :class="locate === null ? '' : 'locate-active'"
          @click="updateLocation"
        >
          <i class="iconfont icondingwei"></i>
        </div>

        <!-- 公司名称 -->
        <technical-support :ts_type_="ts_type_" contact_side="feedback" :id="id"></technical-support>
      </div>
      <!-- 发布红包 -->
      <publish-button :id="label_id" :id_type_="id_type_" v-if="is_admin" bottom="3rem"></publish-button>
      <!-- 红包雨 -->
      <red-packet
        :redPacketList="redPacketList"
        v-if="have_red_packet_rain"
        :id_type_="id_type_"
        :red_packet_type_="red_packet_type_"
        :red_packet_image_url="red_packet_image_url"
        :is_admin="is_admin"
      ></red-packet>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator } from 'mint-ui'

import { LMap, LTileLayer, LMarker, LPopup, LImageOverlay } from 'vue2-leaflet' // leaflet
import 'leaflet.markercluster/dist/MarkerCluster.css' // leaflet 点位聚合插件
import 'leaflet.markercluster/dist/MarkerCluster.Default.css' // leaflet 点位聚合插件
import 'leaflet.markercluster' // leaflet 点位聚合插件
import 'leaflet-responsive-popup' // leaflet popup弹出框插件
import 'leaflet.polyline.snakeanim' // leaflet 折线动画插件
import 'leaflet.heat' // 热力图插件

import TechnicalSupport from '../../components/TechnicalSupport'
import PublishButton from '../../components/PublishButton'
import RedPacket from '../../components/RedPacket'
import MapUserList from '../../components/MapUserList'
var wx = require('weixin-js-sdk')
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LImageOverlay,
    'technical-support': TechnicalSupport,
    'publish-button': PublishButton,
    'red-packet': RedPacket,
    'map-user-list': MapUserList
  },
  data () {
    return {
      // 初始数据
      park_id: 298,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      label_id: this.$route.query.id ? this.$route.query.id : 0,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      open_id: localStorage.getItem('open_id_' + this.$route.query.id)
        ? localStorage.getItem('open_id_' + this.$route.query.id)
        : '',
      id: this.$route.query.id ? this.$route.query.id : 0,
      ts_type_: 'label',
      id_type_: 'leafletMap',
      area_index:
        this.$route.query.area_index > -1 ? this.$route.query.area_index : -1,
      where: this.$route.query.where || '',
      // 请求数据
      bottom_menu_list: '', // 底部导航条
      map_data: [], // 地图对象
      point_list: [], // 点位列表
      tel_: '', // 联系电话
      is_pay: 0, // 是否支付
      price: 0, // 价格
      backUrl: 'nihewanMap',
      is_make_lnglat_by_xy: '',
      vrUrl: '',

      // icon
      // 15 停车场  16售票厅或车站  17厕所  18美食  19景点  20购物  21住宿  24文化
      icon_object: {
        iconMapAudio: require('../../assets/pixi/map_icon_audio.png'),
        iconMap43: require('../../assets/pixi/map_icon_29.png'),
        iconMap44: require('../../assets/pixi/map_icon_normal.png'),

        iconMapline: require('../../assets/pixi/map_icon_line.png'),
        iconMapNormal: require('../../assets/pixi/map_icon_normal.png'),
        iconMapCluster: require('../../assets/pixi/map_icon_cluster.png'),
        // icon

        icon_43: require('../../assets/pixi/icon_29.png'),
        icon_44: require('../../assets/pixi/icon_normal.png'),

        icon_line: require('../../assets/pixi/icon_line.png'),

        // 当前icon
        icon_act_43: require('../../assets/pixi/icon_act_29.png'),
        icon_act_44: require('../../assets/pixi/icon_act_normal.png'),

        icon_act_line: require('../../assets/pixi/icon_act_line.png')
      },

      mapExtent: [], // 地图西南和东北的点位列表
      zoom: 17, // 地图初始缩放
      mapMinZoom: 16, // 地图最小缩放
      mapMaxZoom: 20, // 地图最大缩放
      bounds: [], // 地图矩形区域对象
      map: null, // 地图对象
      zIndex: 1000,

      first_menu_type: '', // 初始目录类型
      show_menu: false, // 显示详细目录
      marker_group: {}, // marker列表
      marker_list: [],
      search_value: '', // 搜索内容
      show_point_brief: false,
      point_id: '', // 当前点位的id
      point_item: [], // 当前点位
      marker_id: '', // 当前显示的popup的marker的id

      is_show_location: 0, // 显示定位
      is_show_area: 0, // 显示分区
      show_area: false,
      logo_list: [],
      audio_id: -1,

      audio_dom: '',
      audio_name: '', // 正在播放的音频名称
      audio_play: false, // 解说播放
      bgmusic_play: true, // 背景音乐播放
      show_spot_brief: false, // 显示地图简介
      locate: null, // 定位locate对象
      locate_marker: null, // 定位marker对象

      first_latlng: '', // 首次进入地图时的坐标
      is_admin: 0,
      locateTimer: '',

      // 红包
      redPacketList: [],
      have_red_packet_rain: 0,
      red_packet_type_: '',
      red_packet_image_url: '',

      // 分享
      have_chance: 0,
      have_chance_id: '',

      // 动态路径
      showLineList: false,
      currentPath: null, // 动态路径对象
      lineList: [], // 折线经过的经纬度列表

      snake: null, // 折线对象
      isDrawLine: false, // 正在进行折线动画

      // 热力
      max_line_numbers: '', // 最大排队人数
      is_show_heat: '', // 是否有热力图
      showHeat: false, // 显示热力图
      heatList: [],
      heatLayer: null, // 热力图图层

      // 是否小程序
      isMiniProgram: false,

      // 顶部数据栏
      show_data: true,
      showMenu: false
    }
  },
  watch: {
    // 根据id获取点位对象
    point_id: function (val) {
      this.point_item = this.point_list.filter((item, i) => {
        return item.id === val
      })[0]
    }
  },
  computed: {
    show_audio: function () {
      if (this.audio_play) {
        return true
      } else {
        return false
      }
    }
  },

  mounted () {
    if (this.token) {
      // 已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id !== 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token === this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id_' + this.id, this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.id, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.id, '')
      }
    }
    if (this.open_id) {
    } else {
      this.authorize()
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }

    wx.miniProgram.getEnv(res => {
      console.log('res.miniprogram = ' + res.miniprogram) // true
      this.isMiniProgram = res.miniprogram
    })
    this.getData()
    // 获取audio元素
    this.$nextTick(() => {
      this.audio_dom = this.$refs.audio
      this.audio_dom.addEventListener('ended', () => {
        this.audio_play = false
        if (this.bgmusic_play) {
          this.bgmusic_play = false
        }
        this.checkIsPlay()
      })
    })
  },
  methods: {
    /**
     * 点击底部点位菜单中的点位元素
     * @param { string } id 点位的 id 值
     */
    menuLocation (id) {
      this.point_id = id
      if (id === this.marker_id) {
        return
      }
      let marker
      this.marker_list.forEach(item => {
        if (item.id === id) {
          marker = item
        }
      })
      this.showMenu = false
      this.togglePopup(marker)
    },
    filterPointListByType () {
      let list = this.point_list
      if (typeof this.first_menu_type === 'string') {
        return list.filter(item => {
          return item.type_ === this.first_menu_type
        })
      } else if (typeof this.first_menu_type === 'object') {
        return list.filter(item => {
          return this.first_menu_type.includes(item.type_ * 1)
        })
      } else {
        console.log('id是其他类型')
      }
    },
    // 切换目录
    toggleMenu (id, index) {
      if (this.isDrawLine) {
        // 如果正在画路径，则不响应点击事件
        return
      }

      this.first_menu_type = id
      this.marker_list.forEach((item, i) => {
        this.marker_group.removeLayer(item)

        if (
          (typeof id === 'string' && id === item.type_) ||
          (typeof id === 'object' && id.includes(item.type_ * 1)) ||
          id === 'line'
        ) {
          this.marker_group.addLayer(item)
        }
      })
    },
    // 设置目录图标
    setIconByType (id) {
      if (typeof id === 'string') {
        if (this.first_menu_type === id) {
          return this.icon_object['icon_act_' + id]
        } else {
          return this.icon_object['icon_' + id]
        }
      } else if (typeof id === 'object') {
        if (id.includes(this.first_menu_type[0])) {
          return this.icon_object['icon_act_' + id[0]]
        } else {
          return this.icon_object['icon_' + id[0]]
        }
      }
    },
    // 显示分类目录
    handleShowMenu () {
      this.showMenu = !this.showMenu
    },
    // 底部导航
    handleMenu (type) {
      if (type === 'my') {
        this.$router.push({
          path: '/nihewan/my',
          query: {
            id: this.id
          }
        })
      } else if (type === 'impression') {
        this.$router.push({
          path: '/nihewan/impression',
          query: {
            id: this.id
          }
        })
      }
    },
    // 显示热力图
    toggleHeat () {
      if (!this.showHeat) {
        if (!this.heatLayer) {
          // eslint-disable-next-line no-undef
          this.heatLayer = L.heatLayer(this.heatList, {
            minOpacity: 0.6,
            radius: 40,
            gradient: {
              0: '#000000',
              0.25: '#30b6f6',
              0.5: '#0dffd2',
              0.75: 'yellow',
              1: 'red'
            }
          }).addTo(this.map)
        } else {
          this.heatLayer.addTo(this.map)
        }
        this.showHeat = true
      } else {
        this.map.removeLayer(this.heatLayer)
        this.showHeat = false
      }
    },
    /**
     * 在leaflet map 上画路线
     *
     * @param { object } line 保存了路径字符串的路线对象
     */
    drawLine (line) {
      if (this.isDrawLine) {
        // 正在进行折线动画，退出
        return
      }
      if (!line.path) {
        Toast('暂无路线')
        return false
      }
      this.isDrawLine = true
      this.showMenu = false
      this.point_id = line.id
      let pathList = []
      if (this.is_make_lnglat_by_xy > 0) {
        line.path.split(';').forEach(item => {
          let xy = item.split(',')
          let lnglat = this.mockLagLng({
            x_: xy[0] * 1,
            y_: xy[1] * 1,
            lat: this.mapExtent[3],
            lng: this.mapExtent[2],
            width: this.map_data.width_,
            height: this.map_data.height_
          })

          pathList.push([lnglat[0], lnglat[1]])
        })
      } else {
        line.path.split(';').forEach(item => {
          let lnglat = item.split(',')
          pathList.push([lnglat[1] * 1, lnglat[0] * 1])
        })
      }
      // console.log(pathList);

      // 在map上画折线动画的方法
      this.snake = {
        layers: []
      }

      if (this.snake.group) {
        this.snake.group.clearLayers()
        this.snake.layers = []
      }

      this.clearPath()
      // eslint-disable-next-line no-undef
      this.map.fitBounds(L.latLngBounds(pathList))
      this.show_menu = false
      // eslint-disable-next-line no-undef
      this.snake.line = L.polyline(pathList, {
        snakingSpeed: 200,
        color: '#407eff',
        weight: 6
      })
      this.snake.line.addTo(this.map).snakeIn() // 动画方法
      // 标记起点与终点
      // let start = L.marker(this.pathList[0]);
      // let end = L.marker(this.pathList[this.pathList.length - 1]);
      // this.snake.layers.push(start)
      // this.snake.layers.push(end)
      this.snake.layers.push(this.snake.line)
      // eslint-disable-next-line no-undef
      this.snake.group = L.layerGroup(this.snake.layers).addTo(this.map)

      this.snake.line.on('snakeend', () => {
        // 动画完成后清除画完的路线并显示动态路线
        this.isDrawLine = false
        this.showPath(pathList)
        this.map.removeLayer(this.snake.group)
      })
    },
    // 清除当前显示的动态路线
    clearPath () {
      if (this.currentPath) {
        this.map.removeLayer(this.currentPath)
      }
    },
    /**
     * 在 map 中显示动态路线
     *
     * @param { array } pathList 保存路径点位的二维数组
     */
    showPath (pathList) {
      // eslint-disable-next-line no-undef
      this.currentPath = L.polyline.antPath(pathList, {
        paused: false,
        reverse: false,
        delay: 3000,
        dashArray: [10, 30],
        weight: 6,
        opacity: 1,
        color: '#407eff',
        pulseColor: '#ffffff'
      })

      this.currentPath.addTo(this.map)

      // this.map.fitBounds(currentPath.getBounds());
    },
    /**
     * 根据是否有底部菜单来返回地图容器的高度
     * @return { object } 返回地图容器的高度
     */
    getMapBoxHeight () {
      if (this.first_menu_type && this.where !== 'applet') {
        return {
          height: 'calc(100% - 2.8rem)'
        }
      } else {
        return {
          height: '100%'
        }
      }
    },

    /**
     * 将分区移动到屏幕显示区域
     *
     * @param { string } x_ 分区的 x 坐标
     * @param { string } y_ 分区的 y 坐标
     */
    areaLocation (x_, y_) {
      let mLatlng = this.mockLagLng({
        // 公共方法，将 x y 映射为 lng lat
        x_: x_,
        y_: y_,
        lat: this.mapExtent[3],
        lng: this.mapExtent[2],
        width: this.map_data.width_,
        height: this.map_data.height_
      })
      // eslint-disable-next-line no-undef
      this.map.setView(L.latLng(mLatlng[0], mLatlng[1]), this.zoom + 1)
    },
    // 点击定位按钮，更新定位
    updateLocation () {
      if (this.locate != null) {
        this.locate = null
        if (this.locate_marker !== null) {
          this.map.removeLayer(this.locate_marker)
          this.locate_marker = null
        }
        this.map.stopLocate()
        return
      }
      this.locate = this.map.locate({
        setView: false,
        watch: true,
        maxZoom: this.mapMaxZoom,
        maximumAge: 3000,
        timeout: 3000
      })
      if (this.is_show_location) {
        this.map.on('locationfound', e => {
          if (
            e.latlng.lng < this.mapExtent[0] ||
            e.latlng.lng > this.mapExtent[2] ||
            e.latlng.lat < this.mapExtent[1] ||
            e.latlng.lat > this.mapExtent[3]
          ) {
            Toast('你不在该地图区域')
            this.map.stopLocate()
            this.locate = null
            return
          }
          if (this.locate_marker != null) {
            this.map.removeLayer(this.locate_marker)
          } else {
            this.map.setView(e.latlng)
          }
          // eslint-disable-next-line no-undef
          this.locate_marker = L.marker(e.latlng)
            .addTo(this.map)
            .bindPopup('你在这里')
          this.sortByDistance(e.latlng)
        })
        this.map.on('locationerror', e => {
          Toast('定位失败,请允许权限')
          this.map.stopLocate()
          this.locate = null
          this.locate_marker = null
        })
      }
    },

    /**
     * 切换 marker 对应的弹出框
     * @param { object } marker map中的marker对象
     */
    togglePopup (marker) {
      // 如果已经打开  就不继续执行
      if (this.marker_id === marker.id) {
        // this.marker_id = "";
        return
      }

      this.marker_group.getVisibleParent(marker)
      this.map.setView(marker._latlng, this.mapMaxZoom - 1)

      marker.setZIndexOffset(this.zIndex)
      this.zIndex++
      this.point_id = marker.id

      if (marker.getPopup()) {
        setTimeout(() => {
          marker.openPopup()
          this.marker_id = marker.id
          this.btnClickHandle(marker)
        }, 300)
      } else {
        this.createPopup(marker)
        setTimeout(() => {
          marker.openPopup()
          this.marker_id = marker.id
          this.btnClickHandle(marker)
        }, 300)
      }
    },
    /**
     * 给marker对应的popup中的按钮绑定点击事件
     * @param { object } marker  map 中的 marker 对象
     */
    btnClickHandle (marker) {
      let nav = document.querySelector('.brief-nav')

      nav.addEventListener('click', () => {
        this.jumpPos()
      })

      if (
        this.point_item.type_ !== '15' &&
        this.point_item.type_ !== '16' &&
        this.point_item.type_ !== '17'
      ) {
        let detail = document.querySelector('.brief-detail')
        let audio = document.querySelector('.brief-audio')
        detail.addEventListener('click', () => {
          this.jumpToDetail()
        })
        if (this.point_item.zhAudioUrl) {
          audio.addEventListener('click', () => {
            this.audio_id = this.point_item.id
            this.playAudio()
          })
        }
      }
    },
    /**
     * 给 marker 创建对应的 popup 弹出框
     * @param { object } marker  map 中的 marker 对象
     */
    createPopup (marker) {
      // eslint-disable-next-line no-undef
      let popup = L.responsivePopup({
        maxWidth: 'auto',
        keepInView: false,
        className: 'brief',
        hasTip: true, // 三角形箭头。。
        autoPan: false,
        offset: [15, 25]
      })
        .setLatLng(marker._latlng)
        .setContent(this.createPopupContent(marker))
      marker.bindPopup(popup)
    },
    /**
     * 当文本过长时，截取指定的长度并返回
     * @param { string } tx 要截取的文本字符串
     * @param { number } n  要截取的长度
     *
     * @return { string } 返回被截取后的文本
     */
    clampText (tx, n) {
      if (tx.length <= n) {
        return tx
      } else {
        return tx.substr(0, n) + '...'
      }
    },

    /**
     * 创建marker的popup中的内容
     * @param { object } marker  map 中的 marker 对象
     *
     * @return { string } 返回生成的字符串格式的dom
     */
    createPopupContent (marker) {
      let title = "<div class='brief-title'>" + marker.spots_name + '</div>'
      let img =
        "<div class='brief-icon'><img class='icon' src=" +
        marker.logo_ +
        '></div>'

      let content, detail, audio, nav

      if (
        marker.type_ === '15' ||
        marker.type_ === '16' ||
        marker.type_ === '17'
      ) {
        content =
          "<div class='brief-content'>" +
          this.clampText(marker.address, 40) +
          '</div>'

        detail =
          "<div class='brief-detail brief-btn-item brief-btn-item-no'><i class='iconfont iconjianjie1'></i><span class='btnname'>详情</span></div>"
        audio =
          "<div class='brief-audio brief-btn-item brief-btn-item-no'><i class='iconfont icon1'></i><span class='btnname'>解说</span></div>"
        nav =
          "<div class='brief-nav brief-btn-item'><i class='iconfont icondaohang1'></i><span class='btnname'>导航</span></span>"
      } else {
        content =
          "<div class='brief-content'>" +
          this.clampText(marker.spots_content, 30) +
          '</div>'

        detail =
          "<div class='brief-detail brief-btn-item'><i class='iconfont iconjianjie1'></i><span  class='btnname'>详情</span></div>"
        if (marker.zhAudioUrl) {
          audio =
            "<div class='brief-audio brief-btn-item'><i class='iconfont icon1'></i><span  class='btnname'>解说</span></div>"
        } else {
          audio =
            "<div class='brief-audio brief-btn-item brief-btn-item-no'><i class='iconfont icon1'></i><span  class='btnname'>解说</span></div>"
        }
        nav =
          "<div class='brief-nav brief-btn-item'><i class='iconfont icondaohang1'></i><span class='btnname'>导航</span></span>"
      }
      let btns
      if (marker.zhAudioUrl) {
        btns = "<div class='brief-btn'>" + detail + audio + nav + '</div>'
      } else {
        btns = "<div class='brief-btn'>" + detail + nav + '</div>'
      }

      let info = "<div class='brief-info'>" + content + btns + '</div>'
      let body = "<div class='brief-body'>" + img + info + '</div>'

      let html = title + body
      return html
    },
    // 首次进入地图时定位
    firstLocation () {
      if (this.is_show_location) {
        Indicator.open('定位中...')
        this.map.locate({
          setView: false,
          timeout: 3000
        })

        this.map.on('locationfound', e => {
          Indicator.close()
          this.first_latlng = e.latlng
          this.sortByDistance(e.latlng)
          // console.log(this.point_list);
        })
        this.map.on('locationerror', e => {
          Toast('定位失败,请允许权限')
          Indicator.close()
          this.map.stopLocate()
        })

        this.locateTimer = setTimeout(() => {
          this.firstLocation()
        }, 60000)
      }
    },
    /**
     * 根据定位获取的经纬度计算与点位之间的距离，并将点位列表中的点位按照距离进行排序显示
     * @param { array } latlng 定位获取到的当前所在的纬度/经度数据
     */
    sortByDistance (latlng) {
      this.point_list.sort((item1, item2) => {
        // eslint-disable-next-line no-undef
        let latlng1 = L.latLng(item1.lat * 1, item1.lng * 1)
        // eslint-disable-next-line no-undef
        let latlng2 = L.latLng(item2.lat * 1, item2.lng * 1)
        let distance1 = this.map.distance(latlng1, latlng)
        let distance2 = this.map.distance(latlng2, latlng)
        item1.distance =
          Math.round(distance1) > 1000
            ? (distance1 / 1000).toFixed(2) + '千米'
            : Math.round(distance1) + '米'
        item2.distance =
          Math.round(distance2) > 1000
            ? (distance2 / 1000).toFixed(2) + '千米'
            : Math.round(distance2) + '米'
        return distance1 - distance2
      })
    },
    // 初始化leaflet
    initMap () {
      // 创建矩形区域
      // eslint-disable-next-line no-undef
      let bounds = new L.LatLngBounds(
        // eslint-disable-next-line no-undef
        new L.LatLng(this.mapExtent[1], this.mapExtent[0]),
        // eslint-disable-next-line no-undef
        new L.LatLng(this.mapExtent[3], this.mapExtent[2])
      )
      // 创建map
      // eslint-disable-next-line no-undef
      this.map = L.map('map_', {
        maxBounds: bounds,
        zoomControl: false,
        attributionControl: false
      }).fitBounds(bounds)

      // 加载公共地图的切片
      // L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      //   this.map
      // );

      let options = {
        minZoom: this.mapMinZoom,
        maxZoom: this.mapMaxZoom,
        opacity: 1.0,
        tms: false
      }
      // 手绘地图的切片层
      // eslint-disable-next-line no-undef
      L.tileLayer(
        '/static/map_tiles_' + this.id + '/{z}/{x}/{y}.png',
        options
      ).addTo(this.map)

      // 弹出层显示时添加marker标记
      this.map.on('popupopen', e => {
        // eslint-disable-next-line no-undef
        e.popup.marker1 = L.circleMarker(e.popup.getLatLng(), {
          radius: 10,
          opacity: 0,
          fillColor: '#fcfa29',
          fillOpacity: 0.6
        }).addTo(this.map)
      })
      this.map.on('popupclose', e => {
        this.marker_id = ''
        this.map.removeLayer(e.popup.marker1)
      })

      // 初始显示区域和缩放级别
      this.map.setView(
        [
          (this.mapExtent[1] + this.mapExtent[3]) / 2,
          (this.mapExtent[0] + this.mapExtent[2]) / 2
        ],
        this.zoom
      )

      this.firstLocation()
    },
    /**
     * 根据获取到的点位列表初始化点位
     * @param { array } ls 从服务器获取的点位信息
     */
    initPoint (ls) {
      for (let i = 0; i < ls.length; i++) {
        let iconUrl
        if (ls[i].zhAudioUrl && ls[i].zhAudioUrl !== '') {
          iconUrl = this.icon_object.iconMapAudio
        } else {
          iconUrl = this.icon_object['iconMap' + ls[i].type_]
        }
        // eslint-disable-next-line no-undef
        L.icon({
          iconUrl: iconUrl,
          iconSize: [40, 40],
          iconAnchor: [20, 40],
          popupAnchor: [0, -20]
        })

        let html
        if (ls[i].line_numbers > 0) {
          html =
            "<div class='icon-name icon-name-number'>" +
            "<p class='name'>" +
            ls[i].spots_name +
            '</p>' +
            "<p class='isline'>当前：<span class='self'>" +
            ls[i].line_number +
            "</span> / <span class='total'>" +
            ls[i].line_numbers +
            '</span></p></div>' +
            "<img class='icon-value' src='" +
            iconUrl +
            "'/>"
        } else {
          html =
            "<p class='icon-name'>" +
            ls[i].spots_name +
            '</p>' +
            "<img class='icon-value' src='" +
            iconUrl +
            "'/>"
        }
        // eslint-disable-next-line no-undef
        let divIcon = L.divIcon({
          html: html,
          iconSize: [40, 40],
          iconAnchor: [20, 40],
          popupAnchor: [0, -20]
        })
        // 创建marker
        let marker = null
        let mLatLng
        if (this.is_make_lnglat_by_xy > 0) {
          // 如果需要模拟经纬度
          mLatLng = this.mockLagLng({
            // 将x y 模拟成经纬度的公共方法
            x_: ls[i].x_,
            y_: ls[i].y_,
            lat: this.mapExtent[3],
            lng: this.mapExtent[2],
            width: this.map_data.width_,
            height: this.map_data.height_
          })
          // eslint-disable-next-line no-undef
          marker = L.marker(L.latLng(mLatLng[0], mLatLng[1]), {
            icon: divIcon
          })
        } else {
          // eslint-disable-next-line no-undef
          marker = L.marker(L.latLng(ls[i].lat * 1, ls[i].lng * 1), {
            icon: divIcon
          })
        }

        // 将部分点位属性绑定到marker上
        marker.id = ls[i].id // 点位的id
        marker.logo_ = ls[i].logo_ // 点位的logo
        marker.type_ = ls[i].type_ // 点位的类型，与菜单类型相对应
        marker.spots_name = ls[i].spots_name // 点位名称
        marker.spots_content = ls[i].spots_content // 点位的详情内容
        marker.zhAudioUrl = ls[i].zhAudioUrl // 中文解说
        marker.enAudioUrl = ls[i].enAudioUrl // 英文解说
        marker.address = ls[i].address // 地址
        marker.line_numbers = ls[i].line_numbers // 点位的热力值

        if (this.is_show_heat && this.is_make_lnglat_by_xy === 0) {
          if (ls[i].line_numbers > 0) {
            this.heatList.push([
              ls[i].lat * 1,
              ls[i].lng * 1,
              ls[i].line_numbers / this.max_line_numbers
            ])
          }
        } else if (this.is_show_heat && this.is_make_lnglat_by_xy > 0) {
          if (ls[i].line_numbers > 0) {
            this.heatList.push([
              mLatLng[0],
              mLatLng[1],
              ls[i].line_numbers / this.max_line_numbers
            ])
          }
        }

        marker.on('click', () => {
          this.togglePopup(marker)
        })

        this.marker_list.push(marker)
      }
    },
    /**
     * 点位聚合
     * @param { array } ls 获取的点位数据
     */
    initCluster (ls) {
      // eslint-disable-next-line no-undef
      let markers = L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        removeOutsideVisibleBounds: false,
        disableClusteringAtZoom: this.mapMaxZoom - 1,
        maxClusterRadius: 40,
        iconCreateFunction: cluster => {
          let _markers = cluster.getAllChildMarkers()

          let markerName =
            _markers[0].spots_name.length > 3
              ? _markers[0].spots_name.substr(0, 3) + '...等'
              : _markers[0].spots_name
          // eslint-disable-next-line no-undef
          return L.divIcon({
            html:
              "<p class='icon-name'>" +
              markerName +
              '</p>' +
              "<p class='icon-number'>" +
              cluster.getChildCount() +
              '</p>' +
              "<img class='icon-value' src='" +
              this.icon_object['iconMapCluster'] +
              "'/>",
            iconSize: [40, 40],
            iconAnchor: [20, 40]
          })
        }
      })
      // eslint-disable-next-line no-undef
      return markers.addLayer(L.layerGroup(ls))
    },
    // 跳转详情
    jumpToDetail () {
      if (this.isMiniProgram) {
        wx.miniProgram.navigateTo({
          url: '/pages/spots/spots?sid=' + this.point_item.id
        })
      } else if (
        this.point_item.type_ === '17' ||
        this.point_item.type_ === '15'
      ) {

      } else {
        this.$router.push({
          path: '/nihewan/spot',
          query: {
            id: this.id,
            aid: this.point_item.id
          }
        })
      }
    },
    // 停止播放
    closeAudio () {
      this.audio_dom.pause()
      this.audio_play = false
      this.checkIsPlay()
    },
    // 修改播放标记
    checkIsPlay () {
      this.point_list.forEach(item => {
        if (item.is_play && item.is_play !== 0) {
          item.is_play = 0
        }
      })
    },
    // 播放背景音乐
    playBgMusic () {
      if (this.bgmusic_play) {
        this.bgmusic_play = false
        this.audio_dom.pause()
      } else {
        // console.log(this.audio_dom);
        this.checkIsPlay() // 关闭点位播放音频
        this.bgmusic_play = true
        this.audio_play = false // 背景音乐不需要播放界面
        this.audio_dom.src = this.map_data.bgAudioUrl
        this.audio_dom.play()
      }
    },
    // 音频
    playAudio () {
      if (this.point_item.type_ === '15' || this.point_item.type_ === '17') {
        console.log('暂无音频')
        return
      }
      if (this.is_weixin) {
        if (this.price === 0 || (this.price > 0 && this.is_pay === 1)) {
          if (this.point_item.zhAudioUrl) {
            if (this.point_item.is_play !== 0) {
              this.point_item.is_play = 0
              this.audio_dom.pause()
              this.audio_play = false
            } else {
              this.checkIsPlay()
              this.bgmusic_play = false // 关闭背景音乐开关
              this.point_item.is_play = 1
              this.audio_play = true
              this.audio_name = this.point_item.spots_name
              this.audio_dom.src = this.point_item.zhAudioUrl
              this.audio_dom.play()
            }
          } else {
            Toast('暂无音频')
          }
        } else {
          // 还未支付
          if (this.open_id) {
            // 进行支付
            var openUrl =
              'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
              this.id +
              '&backUrl=' +
              this.backUrl +
              '&open_id=' +
              this.open_id +
              '&token=' +
              this.token +
              '&park_id=' +
              this.park_id +
              '&version=' +
              this.version +
              '&user_id='
            window.location.href = openUrl
          } else {
            // 获取微信授权
            this.authorize()
          }
        }
      } else {
        Toast('请在微信中打开')
      }
    },
    // 导航
    jumpPos () {
      if (this.is_weixin) {
        if (this.price > 0) {
          // 收费
          if (this.is_pay) {
            // 已经支付了
            this.wx_nav(
              window.location.href,
              this.point_item.lng,
              this.point_item.lat,
              this.point_item.spots_name,
              this.point_item.address
            )
          } else {
            // 还未支付
            if (this.open_id) {
              // 进行支付
              let openUrl =
                'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
                this.id +
                '&backUrl=' +
                this.backUrl +
                '&open_id=' +
                this.open_id +
                '&token=' +
                this.token +
                '&park_id=' +
                this.park_id +
                '&version=' +
                this.version +
                '&user_id='
              window.location.href = openUrl
            } else {
              // 进行微信授权
              this.authorize()
            }
          }
        } else {
          // 免费
          this.wx_nav(
            window.location.href,
            this.point_item.lng,
            this.point_item.lat,
            this.point_item.spots_name,
            this.point_item.address
          )
        }
      } else {
        Toast('请在微信中打开,使用导航')
      }
    },
    // 获取服务器数据
    getData () {
      if (
        this.id &&
        this.park_id &&
        this.id_type_ &&
        this.token &&
        this.open_id
      ) {
        this.$ajax(
          'post',
          'SpotsRace/get_map_data_by_label',
          {
            park_id: this.park_id,
            id: this.label_id,
            id_type_: this.id_type_,
            page: 1,
            where: 'map',
            token: this.token,
            is_type: 2,
            open_id: this.open_id,
            version: this.version
          },
          res => {
            if (res.data.id === 0) {
              //   console.log(res.data.data);
              this.bottom_menu_list = res.data.data.bottom_menu_list || ''

              this.map_data = res.data.data.map_data
              this.point_list = res.data.data.point_list
              this.tel_ = res.data.data.tel_
              this.is_pay = res.data.data.is_pay || 0
              this.price = res.data.data.price || 0
              this.is_admin = res.data.data.is_admin || 0
              this.is_show_location = res.data.data.is_show_location || 0
              this.is_show_area = res.data.data.is_show_area || 0
              this.zoom = this.map_data.init_zoom * 1 || 16
              this.mapMinZoom = this.map_data.min_zoom * 1 || 16
              this.mapMaxZoom = this.map_data.max_zoom * 1 || 20
              this.logo_list = res.data.data.logo_list || '' // 分区列表
              this.is_make_lnglat_by_xy =
                res.data.data.is_make_lnglat_by_xy || '' // 是否需要模拟经纬度

              this.max_line_numbers = res.data.data.max_line_numbers || '' // 最大排队人数
              this.is_show_heat = res.data.data.is_show_heat || 0 // 是否显示热力图

              this.lineList = res.data.data.line_list || [] // 路径列表
              this.vrUrl = res.data.data.vr || ''

              document.title = this.map_data.label_name
              this.mapExtent = [
                this.map_data.left_lng * 1,
                this.map_data.left_lat * 1,
                this.map_data.right_lng * 1,
                this.map_data.right_lat * 1
              ]

              this.redPacketList =
                res.data.data.red_packet.red_packet_rain_image_url_list
              this.have_red_packet_rain =
                res.data.data.red_packet.have_red_packet_rain || 0
              this.red_packet_type_ =
                res.data.data.red_packet.red_packet_type_ || ''
              this.red_packet_image_url =
                res.data.data.red_packet.red_packet_image_url || ''

              // 微信分享
              let wxTitle = res.data.data.map_data.label_name
              let wxContent = res.data.data.map_data.content
              let wxImg = res.data.data.map_data.logo_ || res.data.data.map_data.map_

              let sUrl = window.location.href

              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wxTitle,
                  content: wxContent,
                  img: wxImg
                })
              }

              if (this.bottom_menu_list) {
                this.first_menu_type = this.bottom_menu_list[0].id
              } else {
                // 如果没有底部目录列表，则为空
                this.first_menu_type = ''
              }

              this.initMap() // 初始化地图
              this.initPoint(this.point_list) // 初始化点位

              this.loadCluster()

              if (this.area_index > -1 && this.logo_list) {
                let x_ = this.logo_list[this.area_index * 1].x_
                let y_ = this.logo_list[this.area_index * 1].y_
                this.areaLocation(x_, y_)
              }
            }
          }
        )
      } else {
        console.log('参数错误')
      }
    },
    // 加载点位聚合方法
    loadCluster () {
      // if (L.markerClusterGroup) {
      this.marker_group = this.initCluster(this.marker_list)
      setTimeout(() => {
        this.map.addLayer(this.marker_group)
        if (this.bottom_menu_list) {
          this.bottom_menu_list.forEach((item, i) => {
            if (item.is_selected && item.is_selected === 1) {
              this.first_menu_type = item.id
            }
          })
        }
      }, 100)
    },

    // 授权方法
    authorize () {
      var openUrl =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&id_=0&state=' +
        this.backUrl
      window.location.href = openUrl
    },
    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id === 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            if (info && info !== '') {
              if (info.url !== '') {
                let urlArr = info.url.split('share_open_id')

                url = urlArr[0] + '&share_open_id=' + this.open_id
              } else {
                url = url + '&share_open_id=' + this.open_id
              }
            } else {
              url = url + '&share_open_id=' + this.open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id === 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },
    beforeDestroy () {
      clearTimeout(this.locateTimer)
    }
  }
}
</script>
<style scoped>
.container {
  position: relative;
  width: 100vw;
  height: 100vh;
}

/* 地图页 */
.map-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

#map_ {
  width: 100%;
  height: calc(100% - 2.4rem);
}

/* 底部导航 */

.footer-menu {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2.8rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 -2px 2px -2px rgba(0, 0, 0, 0.2);
}

.footer-menu > .item {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 0.74rem;
  cursor: pointer;
}

.footer-menu > .item > .iconfont {
  font-size: 1rem;
}

.footer-menu > .active {
  color: #40d9ff;
}

/* 播放界面 */
.audio-fade-enter,
.audio-fade-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
.audio-fade-enter-to,
.audio-fade-leave {
  opacity: 1;
  transform: translateY(0);
}
.audio-fade-enter-active,
.audio-fade-leave-active {
  transition: 0.3s;
}
.audio-play {
  position: fixed;
  left: 20%;
  bottom: 3rem;
  width: 60%;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 402;
}
.audio-play .audio-play-icon {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  animation: audio 4s linear infinite;
}
@keyframes audio {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.audio-play .audio-play-icon img {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
}
.audio-play .audio-play-name {
  font-size: 0.8rem;
  color: #fff;
}
.audio-play .audio-play-close {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.audio-play .audio-play-close i {
  font-size: 1rem;
  color: #fff;
}

/* 分类菜单 */
.menu-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 402;
}

.menu-box > .list {
  display: flex;
  width: 100%;
  height: 2.2rem;
  background: linear-gradient(#fff, #eee, #fff);
  border-bottom: 1px solid #eee;
}

.menu-box > .list > .item {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #666;
}

.menu-box > .list > .-active {
  background-color: #e6e6e6;
  color: #000;
}

.menu-box > .list > .item > .icon {
  margin-right: 4px;
  width: 1rem;
  height: 1rem;
  object-fit: cover;
}

.menu-box > .sub-list {
  width: 100%;
  max-height: 30vh;
  padding-bottom: 2px;
  overflow-y: scroll;
  background-color: #fff;
}

.menu-box > .sub-list > .item {
  position: relative;
  width: 100%;
  height: 2.8rem;
  padding: 0 4rem 0 .5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.menu-box > .sub-list > .item > .icon {
  margin-right: 4px;
  width: 1.4rem;
  height: 1.4rem;
  object-fit: cover;
}
.menu-box > .sub-list > .item > .text {
  flex: 1;
  color: #666;
  font-size: .8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menu-box > .sub-list > .item > .btn {
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px 12px;
  border: 1px solid rgba(64, 160, 255, 0.4);
  border-radius: 4px;
  color: #409eff;
  font-size: .7rem;
}

.menu-close {
  width: 2rem;
  height: 2rem;
  margin: 1rem auto;
  border: 2px solid#fff;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-close > .iconfont {
  color: #fff;
  font-size: 1.2rem;
}

/* 路线列表 */
.line-list {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 402;
  background-color: rgba(0, 0, 0, 0.6);
}

.line-list > .list {
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  background-color: #fff;
}

.line-list > .list > .item {
  width: 100%;
  height: 2.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 0.88rem;
}

.line-list > .list > .item:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.line-list > .list > .item > .btn {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.72rem;
  border: 1px solid hsla(210, 100%, 63%, 0.8);
  color: #409eff;
}

/* 顶部分区 */
.area-fade-enter,
.area-fade-leave-to {
  transform: translateX(-100%);
}
.area-fade-enter-to,
.area-fade-leave {
  transform: translateY(0);
}
.area-fade-enter-active,
.area-fade-leave-active {
  transition: ease 0.3s;
}
.pixi-logo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 401;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
}
.pixi-logo .logo-wrapper {
  width: 100%;
  height: 4rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 2px 20px rgb(95, 95, 95);
  display: flex;
}
.pixi-logo .logo-wrapper .logo-btn {
  width: 1.6rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.623); */
}
.pixi-logo .logo-wrapper .logo-btn i {
  transform: rotate(180deg);
  color: #fff;
  font-size: 1.6rem;
}
.pixi-logo .logo-menu {
  /* width: 100%; */
  flex: auto;
  height: 4rem;
  box-sizing: border-box;
  display: inline-flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.pixi-logo .logo-menu .logo-menu-list {
  width: 100%;
  text-align: center;
}
.pixi-logo .logo-menu .logo-menu-list img {
  height: 4rem;
  /* height: 2rem; */
}
/* 顶部导航 */
.top-fade-enter,
.top-fade-leave-to {
  transform: translateX(100%);
}
.top-fade-enter-to,
.top-fade-leave {
  transform: translateX(0);
}
.top-fade-enter-active,
.top-fade-leave-active {
  transition: ease 0.3s;
}
.function-top {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 401;
  padding: 0.5rem;
}
.function-top .top-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 2px #aaa;
  cursor: pointer;
}
.function-top .top-menu-li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2.2rem;
}
.function-top .top-menu-li p {
  color: #00000066;
  font-size: 0.6rem;
}
.function-top .top-brief i {
  color: rgb(250, 88, 59);
  font-size: 0.9rem;
}
.function-top .top-zone i {
  color: rgb(250, 119, 59);
  font-size: 0.9rem;
}

.top-data {
  margin-top: 0.4rem;
}

.top-data i {
  font-size: 1rem;
  color: #00000066;
  transition: 0.3s;
}
.top-data-active i {
  color: #fe5d08;
  transition: 0.3s;
}
.top-data span {
  font-size: 0.6rem;
  color: #00000066;
}

.top-vr {
  margin-top: 0.4rem;
}

.top-vr > .iconfont {
  font-size: 1.2rem;
  color: #fe5d08;

  animation: vr 2s ease 1 both;
}

@keyframes vr {
  0% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

.function-top .top-music,
.function-top .top-heat {
  margin-top: 0.4rem;
}
.function-top .top-music i {
  font-size: 1rem;
}

.function-top .top-music i.iconswitch_open {
  color: rgb(250, 119, 59);
}
.function-top .top-music i.iconswitch_close {
  color: #999;
}
.top-heat i {
  font-size: 0.88rem;
  color: #aaaaaa;
  transition: color 0.3s;
}
.top-heat.-active i {
  color: rgb(235, 63, 20);
  transition: color 0.3s;
}
/*
.top-line {
  margin-top: 0.4rem;
}

.top-line > .iconfont {
  font-size: 0.88rem;
  color: rgb(235, 63, 20);
} */

.top-classify {
  margin-top: 0.4rem;
}

.top-classify > .iconfont {
  font-size: 0.9rem;
  color: rgb(235, 63, 20);
}

/* 景区简介 */
.bounce-enter,
.bounce-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.bounce-enter-to,
.bounce-leave {
  transform: translateY(0);
  opacity: 1;
}
.bounce-enter-active,
.bounce-leave-active {
  transition: 0.3s;
}
.spot-brief {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 2.8rem;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  z-index: 402;
}
.spot-brief > .wrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 0 2px #aaa;
  padding-bottom: 1rem;
}
.spot-brief > .wrapper .brief-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.4rem;
  height: 1.4rem;
  /* background: rgba(0, 0, 0, 0.3); */
  /* color: #333;  */
  text-align: center;
  line-height: 1.4rem;
  border-radius: 50%;
}
.spot-brief > .wrapper .brief-close i {
  color: #333;
  font-size: 1rem;
}

.spot-brief .spot-brief-icon {
  position: absolute;
  top: -1rem;
  left: 0.5rem;
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  border: 2px solid rgb(255, 234, 175);
}
.spot-brief .spot-brief-icon img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.spot-brief-header {
  margin-left: 4.2rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
}

.spot-brief-header h2 {
  font-size: 0.88rem;
  padding-right: 2rem;
  color: #333;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.spot-brief-info {
  box-sizing: border-box;
  padding: 10px 0.6rem;
}
.spot-brief-info .spot-brief-item {
  font-size: 12px;
  color: #666;
  display: flex;
  padding-bottom: 10px;
}
.spot-brief-info .spot-brief-item .item-name {
  white-space: nowrap;
}
.spot-brief-info .spot-brief-item .item-value {
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.spot-brief-info .spot-brief-time {
  border-bottom: 1px solid #eee;
}
.spot-brief-info p i {
  font-size: 14px;
  color: #666;
  margin-right: 2px;
}

.spot-brief-content {
  width: 100%;
  padding: 0 0.6rem;
  box-sizing: border-box;
  color: #333;
  font-size: 0.8rem;
  text-align: justify;
  line-height: 1.5;
}
.spot-brief-content article {
  max-height: 16rem;
  overflow-y: auto;
}
.spot-brief-content article >>> span {
  text-align: justify;
  color: #333;
  font-size: 0.7rem !important;
}

/* 左下功能按钮 */
.function-btn {
  position: fixed;
  bottom: 3.4rem;
  left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 401;
}

.function-btn > .item {
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6px;
  align-items: center;
  background-color: #fff;
  box-shadow: 1px 1px 2px #aaa;
  border-radius: 4px;
}

/* 定位 */
.location {
  transition: ease 0.3s;
}
.location .iconfont {
  font-size: 1rem;
  color: #999;
}

.function-btn > .locate-active {
  background-color: rgb(250, 88, 59);
  transition: ease 0.3s;
}
.function-btn > .locate-active .iconfont {
  color: rgb(250, 250, 250);
}

/* 弹出框的样式 */

#map_ >>> .brief {
  width: 16rem;
  height: 8.2rem;
  box-sizing: border-box;
}
/* 简介内容 */
#map_ >>> .brief .leaflet-popup-close-button {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  padding: 0;
  top: 0;
  right: 0rem;
  font-size: 1rem;
}
#map_ >>> .brief .brief-title {
  width: 100%;
  padding-right: 2rem;
  padding-left: 0.6rem;
  box-sizing: border-box;
  height: 2rem;
  line-height: 2rem;
  color: #333;
  font-size: 0.8rem;
  font-weight: 600;
  border-bottom: 1px solid rgb(243, 243, 243);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#map_ >>> .brief .brief-body {
  width: 100%;
  padding: 0.6rem;
  box-sizing: border-box;
  display: flex;
}
#map_ >>> .brief .brief-icon {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
}
#map_ >>> .brief .brief-icon .icon {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#map_ >>> .brief .brief-info {
  margin-left: 0.5rem;
  width: calc(100% - 5.5rem);
  display: flex;
  flex-direction: column;
}
#map_ >>> .brief .brief-content {
  width: 100%;
  min-height: 2.8rem;
  color: #666;
  font-size: 0.7rem;
  text-align: justify;
}
#map_ >>> .brief .brief-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.35rem;
}
#map_ >>> .brief .brief-btn-item {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#map_ >>> .brief .brief-btn-item-no {
  cursor: none;
}
#map_ >>> .brief .brief-btn-item .iconfont {
  color: rgb(243, 98, 40);
  font-size: 0.8rem;
}
#map_ >>> .brief .brief-btn-item-no .iconfont {
  color: #999;
  font-size: 0.8rem;
}
#map_ >>> .brief .brief-btn-item .btnname {
  color: #333;
  font-size: 0.66rem;
}
#map_ >>> .brief .brief-btn-item-no .btnname {
  color: #999;
}
/*  */
#map_ >>> .brief .leaflet-popup-content-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  padding: 0;
}
#map_ >>> .brief .leaflet-popup-content {
  margin: 0;
  width: 100%;
  height: 100%;
}
/* North */

/* South */
#map_ >>> .leaflet-resp-popup-south .leaflet-popup-tip-container {
  top: 0;
  margin-top: -20px;
}

#map_ >>> .leaflet-resp-popup-south .leaflet-popup-tip {
  margin: 11px auto 0;
  box-shadow: none;
}

/* West */
#map_ >>> .leaflet-resp-popup-west .leaflet-popup-tip-container {
  right: 0;
  margin-right: -20px;
  left: 100%;
  margin-left: -2px;
  top: 50%;
  margin-top: -11px;
}

#map_ >>> .leaflet-resp-popup-west .leaflet-popup-tip {
  margin: 1px -8px 0 -8px;
  box-shadow: none;
}

/* East */
#map_ >>> .leaflet-resp-popup-east .leaflet-popup-tip-container {
  width: 20px;
  left: 2px;
  top: 50%;
  margin-top: -11px;
}

#map_ >>> .leaflet-resp-popup-east .leaflet-popup-tip {
  margin: 0 11px;
  box-shadow: none;
}

/* South-East */
#map_ >>> .leaflet-resp-popup-south-east .leaflet-popup-tip-container {
  left: 0;
  margin-left: 0;
  top: 0;
  margin-top: -20px;
}

#map_ >>> .leaflet-resp-popup-south-east .leaflet-popup-tip {
  margin: 11px 11px 11px -8px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-south-east .leaflet-popup-content-wrapper {
  border-radius: 0 20px 20px 20px;
}

/* East-South */
#map_ >>> .leaflet-resp-popup-east-south .leaflet-popup-tip-container {
  width: 20px;
  left: 0;
  top: 0;
}

#map_ >>> .leaflet-resp-popup-east-south .leaflet-popup-tip {
  margin: -8px 11px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-east-south .leaflet-popup-content-wrapper {
  border-radius: 0 20px 20px 20px;
}

/* North-East */
#map_ >>> .leaflet-resp-popup-north-east .leaflet-popup-tip-container {
  left: 0;
  margin-left: 0;
  margin-top: -1px;
}

#map_ >>> .leaflet-resp-popup-north-east .leaflet-popup-tip {
  margin: -8px 11px 11px -8px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-north-east .leaflet-popup-content-wrapper {
  border-radius: 20px 20px 20px 0;
}

/* East-North */
#map_ >>> .leaflet-resp-popup-east-north .leaflet-popup-tip-container {
  left: 0;
  margin-left: -20px;
  margin-top: -20px;
}

#map_ >>> .leaflet-resp-popup-east-north .leaflet-popup-tip {
  margin: 11px 11px 11px 11px;
  box-shadow: none;
}

/* North-West */
#map_ >>> .leaflet-resp-popup-north-west .leaflet-popup-tip-container {
  width: 20px;
  margin-right: 0;
  margin-top: -1px;
  left: 100%;
  right: 0;
}

#map_ >>> .leaflet-resp-popup-north-west .leaflet-popup-tip {
  margin: -8px 11px 11px 11px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-north-west .leaflet-popup-content-wrapper {
  border-radius: 20px 20px 0 20px;
}

/* West-North */
#map_ >>> .leaflet-resp-popup-west-north .leaflet-popup-tip-container {
  margin-left: 0;
  margin-top: -20px;
  left: 100%;
  right: 0;
}

#map_ >>> .leaflet-resp-popup-west-north .leaflet-popup-tip {
  margin: 11px 11px 0px -8px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-west-north .leaflet-popup-content-wrapper {
  border-radius: 20px 20px 0 20px;
}

/* South-West */
#map_ >>> .leaflet-resp-popup-south-west .leaflet-popup-tip-container {
  width: 20px;
  left: 100%;
  top: 0;
  margin-top: -20px;
}

#map_ >>> .leaflet-resp-popup-south-west .leaflet-popup-tip {
  margin: 11px 11px 11px 11px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-south-west .leaflet-popup-content-wrapper {
  border-radius: 20px 0 20px 20px;
}

/* West-South */
#map_ >>> .leaflet-resp-popup-west-south .leaflet-popup-tip-container {
  margin-left: 0;
  margin-right: -20px;
  left: 100%;
  right: 0;
  top: 0;
}

#map_ >>> .leaflet-resp-popup-west-south .leaflet-popup-tip {
  margin: -8px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-west-south .leaflet-popup-content-wrapper {
  border-radius: 20px 0 20px 20px;
}
/* 点位名称和icon */
#map_ >>> .leaflet-marker-pane .leaflet-marker-icon {
  position: relative;
  border: none;
  background-color: transparent;
}
#map_ >>> .icon-name {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -20px;
  font-size: 13px;
  left: 50%;
  border: 1px solid #333;
  border-radius: 4px;
  transform: translateX(-50%);
  padding: 0 6px;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.6);
  color: #333;
}
#map_ >>> .icon-name-number {
  top: -40px;
}
#map_ >>> .icon-name .self {
  color: #ec6002;
  font-weight: bold;
}
#map_ >>> .icon-name .total {
  color: #e33;
  font-weight: bold;
}
#map_ >>> .leaflet-marker-pane .icon-number {
  position: absolute;
  white-space: nowrap;
  top: 43.8%;
  font-size: 13px;
  font-weight: 600;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f6ad15;
}
#map_ >>> .leaflet-marker-pane .icon-value {
  width: 40px;
  height: 40px;
}

.map-wrapper >>> .red_packet_container .red_packet {
  z-index: 403;
}
.map-wrapper >>> .red_packet_container .red_packet_btn {
  z-index: 403;
}
.map-wrapper >>> .red_packet_container .red_packet_bounced {
  z-index: 403;
}
.map-wrapper >>> .red_packet_container .red_packet_banner {
  z-index: 403;
}

/* ===========================
    路线
=============================== */

.path {
  width: 2rem;
  height: 2rem;
  position: fixed;
  bottom: 7.4rem;
  left: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 1px 1px 2px #aaa;
  border-radius: 4px;
  z-index: 401;
}

.path button {
  outline: none;
  background-color: transparent;
  border: none;
  font-size: 0.68rem;
}

.map-wrapper >>> .issue_content {
  z-index: 401;
}
</style>
