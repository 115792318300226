<template>
  <div class="AwardList MyAwardInfo" v-title data-title="奖励详情">
    <mt-header fixed title="奖励详情">
      <a slot="left">
        <mt-button @click.native="backtrack()" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="user">
        <img :src="user.user_icon" alt v-if="type==2" />
        <img :src="have_info.park_logo" alt v-if="type==1" />
        <!--type = 154(现金) type = 155(优惠券) type = 156(实物礼品)-->
        <p>
          <span v-if="type==2">{{user.user_nick}}</span>
          <span v-if="type==1">{{have_info.park_name}}</span>
          <img
            v-if="label_fk_id== 155 || label_id== 155"
            src="../../assets/img/footprint_coupon.png"
            alt
          />
          <img
            v-if="label_fk_id == 154 || label_id == 154"
            src="../../assets/img/footprint_red.png"
            alt
          />
          <img
            v-if="label_fk_id == 156 || label_id == 156"
            src="../../assets/img/footprints_gifts.png"
            alt
          />
        </p>
        <div class="text" v-if="label_fk_id == 154">恭喜发财，万事如意！</div>
        <!--礼物详情-->
        <div class="img_example" v-if="label_id==156">
          <img :src="have_info.url" alt />
          <div class="info">
            <h4>
              {{have_info.title}}
              <span>已领取</span>
            </h4>
            <span>￥{{have_info.red_number}}</span>
            <p>
              <span>快递：货到付款</span>
              <span>已有{{have_info.use_mask}}人领取</span>
            </p>
          </div>
        </div>
      </div>
      <!--发出奖励列表-->
      <div class="AwardInfo_list_box" v-if="is_AwardInfo">
        <ul class="AwardInfo_list">
          <mt-loadmore
            :bottom-method="loadBottom"
            :bottom-all-loaded="allLoaded"
            ref="loadmore"
            :auto-fill="false"
          >
            <li
              v-for="(k, i) in info"
              :key="i"
              @click="AwardInfo_list_info(k.user_id,k.id,k.spots_fk_id,k.label_id)"
            >
              <div class="fr">{{k.red_packet}}元</div>
              <div class="fl">
                <img :src="k.user_icon" alt />
              </div>
              <div>
                <p>{{k.user_nick}}</p>
                <p>{{k.add_time}}</p>
              </div>
            </li>
          </mt-loadmore>
        </ul>
      </div>
      <div>
        <!--红包详情-->
        <div class="packet_num" v-if="label_id==154">
          <span>{{have_info.red_packet}}</span>元
        </div>
        <!--卷详情-->
        <div
          class="discount clearfix"
          v-if="label_id==155"
          :style="{backgroundImage: 'url(' + img_bg + ')'}"
        >
          <div class="fl">
            <p>
              <span>{{have_info.red_packet}}</span>元优惠卷
            </p>
            <span>使用时间：{{have_info.use_begin_time}}至{{have_info.use_end_time}}</span>
          </div>
          <div class="fr green" v-if="have_info.receive == 0">
            <span v-if="have_info.overdue == 0">
              使用码
              <br />
              {{have_info.use_mask}}
            </span>
            <span v-if="have_info.overdue == 1">已过期</span>
          </div>
          <div class="fr" v-if="have_info.receive == 1">已使用</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import green from "../../assets/img/footprint_coupon_green.png";
import grey from "../../assets/img/footprint_coupon_grey.png";
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      type: this.$route.query.type, //type=1 收到奖励列表  =2 发送奖励列表
      label_fk_id: this.$route.query.label_fk_id, //发送奖励类型
      label_id: this.$route.query.label_id, //收到奖励类型
      id: this.$route.query.id, //奖励id
      user_id: this.$route.query.user_id,
      my_id: this.get_User_Id(),
      info: [], //发送奖励数据列表
      user: {}, //用户信息
      have_info: {}, //收到奖励详情信息
      img_bg: green, //优惠卷颜色 未使用 & 未过期
      grey: grey, //优惠卷颜色 过期 & 已使用
      spot_id: this.$route.query.spot_id, //景点id
      path_data: this.$route.query.path_data,
      is_AwardInfo: true, //是否点击发送奖励详情
      page: 1,
      allLoaded: false
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      if (this.type == 1) {
        this.packet_data_1();
      } else {
        this.send_data_2(1);
      }
    },
    packet_data_1() {
      if (!this.user_id) {
        this.user_id = this.my_id;
      }
      var data;
      if (this.path_data) {
        data = {
          type: 1,
          red_packet_id: 0,
          spot_id: this.spot_id,
          user_id: this.user_id,
          my_id: this.my_id,
          park_id: this.id
        };
      } else {
        data = {
          type: 1,
          red_packet_id: this.id,
          spot_id: this.spot_id,
          user_id: this.user_id,
          my_id: this.my_id
        };
      }
      //收到奖励数据详情
      this.$ajax("post", "foot/click_packet", data, res => {
        if (res.data.data.packet.receive == 1) {
          //receive = 1 卷已使用
          this.img_bg = grey;
        } else if (
          res.data.data.packet.receive == 0 &&
          res.data.data.packet.overdue == 1
        ) {
          //overdue = 0 卷未使用 & overdue = 1 卷已过期
          this.img_bg = grey;
        }
        this.have_info = res.data.data.packet;
      });
    },
    send_data_2(page) {
      this.$ajax(
        "post",
        "foot/get_send_packet_list",
        {
          //发送奖励数据列表
          id: this.id,
          user_id: this.my_id,
          page: page
        },
        res => {
          this.info = this.info.concat(res.data.data.packet_list); //收到奖励数据
          this.user = res.data.data.user; //用户数据
        }
      );
    },
    AwardInfo_list_info(user_id, id, spot_id, label_id) {
      //发送奖励数据详情
      this.is_AwardInfo = false;
      this.label_id = label_id;
      this.$ajax(
        "post",
        "foot/click_packet",
        {
          //收到奖励数据详情
          type: 1,
          red_packet_id: id,
          spot_id: spot_id,
          user_id: user_id,
          my_id: this.my_id
        },
        res => {
          if (res.data.data.packet.receive == 1) {
            //receive = 1 卷已使用
            this.img_bg = grey;
          } else if (
            res.data.data.packet.receive == 0 &&
            res.data.data.packet.overdue == 1
          ) {
            //overdue = 0 卷未使用 & overdue = 1 卷已过期
            this.img_bg = grey;
          }
          this.have_info = res.data.data.packet;
        }
      );
    },
    backtrack() {
      //返回 如果点击了发送奖励详情 先隐藏 点击第二次返回
      if (this.type) {
        this.$router.go(-1);
      } else if (this.is_AwardInfo) {
        this.$router.go(-2);
      } else {
        this.label_id = "";
        this.is_AwardInfo = true;
      }
    },
    //            加载更多
    loadBottom() {
      var that = this;
      var item = this.info.length;
      if (item < 10) {
        that.$refs.loadmore.onBottomLoaded();
      }
      setTimeout(() => {
        if (item % 10 == 0) {
          that.page++;
          if (that.tab == 1) {
            that.send_data_2(that.page);
          } else {
            that.send_data_2(that.page);
          }
        } else {
          that.allLoaded = true;
          Toast("没有更多了");
        }
        that.$refs.loadmore.onBottomLoaded();
      }, 1500);
    }
  }
};
</script>
<style scoped>
.MyAwardInfo {
  min-height: 100%;
  background-color: #fff;
}

.MyAwardInfo .page-content .user p {
  line-height: 30px;
  height: 30px;
}

.MyAwardInfo .page-content .user .text {
  color: #ccc;
  font-size: 12px;
  padding-bottom: 10px;
}

.MyAwardInfo .page-content .user p img {
  width: auto;
  height: 24px;
  display: inline-block;
  vertical-align: text-bottom;
  border-radius: 0;
}

.MyAwardInfo .img_example {
  padding: 10px;
}

.MyAwardInfo .img_example img {
  width: 100%;
  height: 300px;
}

.img_example .info {
  text-align: left;
  padding-top: 10px;
}

.img_example .info h4 > span {
  border: 1px solid #ccc;
  font-size: 12px;
  padding: 2px 4px;
  color: #ccc;
}

.img_example .info > span {
  color: red;
}

.img_example .info > p {
  font-size: 12px;
  color: #999;
}

.img_example .info > p span {
  margin-right: 10px;
}

.AwardInfo_list_box {
  border-top: 4px solid #f2f2f2;
}

.AwardInfo_list_box h2 {
  font-size: 12px;
  color: #333;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
}

.AwardInfo_list_box ul li {
  border-top: 1px solid #f2f2f2;
  padding: 10px;
  font-size: 14px;
}

.AwardInfo_list_box ul li img {
  height: 36px;
  margin-right: 5px;
}

.AwardInfo_list_box ul li > div {
  overflow: hidden;
}

.AwardInfo_list_box ul li p:last-of-type {
  font-size: 12px;
  color: #ccc;
  margin-top: 2px;
}

.packet_num {
  text-align: center;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
}

.packet_num span {
  font-size: 24px;
}

.discount {
  margin: 10px;
  height: 100px;
  background-size: 100% 100%;
  text-align: center;
}

.discount .fl {
  width: 74%;
  font-size: 24px;
  color: #fff;
}

.discount .fl > p {
  font-size: 22px;
  margin-top: 5px;
}

.discount .fl p > span {
  font-size: 40px;
}

.discount .fl > span {
  font-size: 12px;
}

.discount .fr {
  width: 26%;
  font-size: 14px;
  margin-top: 8%;
  font-weight: 700;
}

.green {
  color: green;
}
</style>