<template>
  <div class="container">
    <div class="lucky-wheel">
      <div class="lucky-title"></div>
      <div class="wheel-main">
        <div class="wheel-pointer-box">
          <div
            class="wheel-pointer"
            @click="rotate_handle()"
            :style="{transform:rotate_angle_pointer,transition:rotate_transition_pointer}"
          ></div>
        </div>
        <div class="wheel-bg" :style="{transform:rotate_angle,transition:rotate_transition}">
          <div class="prize-list" v-if="prize_list.length">
            <div class="prize-item" v-for="(item,index) in prize_list" :key="index">
              <div class="prize-pic">
                <img
                  :src="item.iconUrl ? item.iconUrl : (item.type_=='money' ? money_icon : (item.type_ == 'coupon' ? coupon_icon : thanks_icon))"
                />
              </div>
              <div class="prize-count" v-if="item.price">￥{{item.price}}</div>
              <div class="prize-type">{{item.label_name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-bg"></div>
      <div class="bg-p"></div>
      <!-- 抽奖次数 -->
      <div class="content"></div>
      <!-- 答题 -->
      <div class="answer content_box" v-if="questionList">
        <h3 class="answer-title">答题</h3>

        <!-- <div class="answer-rule" v-if="!startAnswer">
          <p></p>
          <p>答对则可获取一次免费抽奖机会</p>
          <p>答错了啥都没有</p>
          <div class="answer-btn">
            <button @click="showAnswerClient">开始答题</button>
          </div>
        </div>-->
        <!-- 答题界面 -->
        <div class="question">
          <div class="qs_preview_wrapper">
            <div class="qs_preview_title">
              <span>{{questionInfo.title}}</span>
              <img :src="questionInfo.newUrl" alt v-if="questionInfo.newUrl" />
            </div>
            <ul v-if="questionInfo.type_ == 'text'" class="qs_preview_text">
              <li
                :class="questionInfo.showAnswer && (questionInfo.showAnswer == 'a' || questionInfo.answer_ == 'a') ? (questionInfo.answer_ == 'a' ? 'right_answer' : 'wrong_answer') : ''"
              >
                <span @click.stop.prevent="choseAnswer('a',$event)">A.{{questionInfo.a_}}</span>
              </li>

              <li
                :class="questionInfo.showAnswer && (questionInfo.showAnswer == 'b' || questionInfo.answer_ == 'b') ? (questionInfo.answer_ == 'b' ? 'right_answer' : 'wrong_answer') : ''"
              >
                <span @click.stop.prevent="choseAnswer('b',$event)">B. {{questionInfo.b_}}</span>
              </li>
              <li
                :class="questionInfo.showAnswer && (questionInfo.showAnswer == 'c' || questionInfo.answer_ == 'c') ? (questionInfo.answer_ == 'c' ? 'right_answer' : 'wrong_answer') : ''"
              >
                <span @click.stop.prevent="choseAnswer('c',$event)">C. {{questionInfo.c_}}</span>
              </li>
              <li
                :class="questionInfo.showAnswer && (questionInfo.showAnswer == 'd' || questionInfo.answer_ == 'd') ? (questionInfo.answer_ == 'd' ? 'right_answer' : 'wrong_answer') : ''"
              >
                <span @click.stop.prevent="choseAnswer('d',$event)">D. {{questionInfo.d_}}</span>
              </li>
            </ul>
            <ul v-else-if="questionInfo.type_ == 'img'" class="qs_preview_img">
              A:
              <li
                :class="questionInfo.showAnswer && (questionInfo.showAnswer == 'a' || questionInfo.answer_ == 'a') ? (questionInfo.answer_ == 'a' ? 'right_answer' : 'wrong_answer') : ''"
              >
                <img :src="questionInfo.a_" alt @click="choseAnswer('a')" />
              </li>B:
              <li
                :class="questionInfo.showAnswer && (questionInfo.showAnswer == 'b' || questionInfo.answer_ == 'b') ? (questionInfo.answer_ == 'b' ? 'right_answer' : 'wrong_answer') : ''"
              >
                <img :src="questionInfo.b_" alt @click="choseAnswer('b')" />
              </li>C:
              <li
                :class="questionInfo.showAnswer && (questionInfo.showAnswer == 'c' || questionInfo.answer_ == 'c') ? (questionInfo.answer_ == 'c' ? 'right_answer' : 'wrong_answer') : ''"
              >
                <img :src="questionInfo.c_" alt @click="choseAnswer('c')" />
              </li>D:
              <li
                :class="questionInfo.showAnswer && (questionInfo.showAnswer == 'd' || questionInfo.answer_ == 'd') ? (questionInfo.answer_ == 'd' ? 'right_answer' : 'wrong_answer') : ''"
              >
                <img :src="questionInfo.d_" alt @click="choseAnswer('d')" />
              </li>
            </ul>
            <transition name="next">
              <div class="answer-next" v-show="hasAnswer">
                <span @click="newtQuestion">继续答题</span>
              </div>
            </transition>
          </div>
        </div>
        <transition name="answer"></transition>
      </div>

      <!-- 活动规则 -->
      <div class="tip content_box">
        <div class="tip-title">活动规则</div>
        <div class="tip-content">
          <p v-for="(item,i) in content" :key="i">{{item}}</p>
        </div>
      </div>

      <!-- 参与人数 -->
      <div class="stats_wrapper">
        <div
          class="stats_join stats_item"
          :class="showStats == 1 ? 'stats_active' : ''"
          @click="showStats = 1"
        >
          <span class="stats_item_name">参与次数</span>
          <span class="stats_item_number">{{join_numbers}}</span>
        </div>
        <div
          class="stats_win stats_item"
          :class="showStats == 2 ? 'stats_active' : ''"
          @click="showStats = 2"
        >
          <span class="stats_item_name">中奖次数</span>
          <span class="stats_item_number">{{win_numbers}}</span>
        </div>
      </div>
      <!-- 参与列表 -->
      <transition name="stats">
        <div class="stats_container stats-list" v-show="showStats == 1 && join_numbers">
          <div class="join_list stats-list-body">
            <!-- <transition-group name="stats" tag="div" class="join_list"> -->
            <div class="join_list_item stats-list-item" v-for="(k,i) in join_list" :key="i">
              <div class="list-item-icon">
                <img :src="k.user_icon" v-if="k.user_icon" />
                <img src="../../assets/img/apply_avatar_bg.png" v-else-if="!k.user_icon" />
              </div>
              <div class="list-item-content">
                <span class="list-item-content-name">{{k.user_nick}}</span>
                <!-- <span>&nbsp;访问了{{k.numbers}}次</span> -->
              </div>
              <div class="list-item-count">
                <!-- <span class="count-progress-hold"></span> -->
                <span class="count-progress" :style="computedWidth(k.numbers)"></span>
                <span class="count-number">{{k.numbers}}次</span>
              </div>
            </div>
            <!-- </transition-group> -->
          </div>
          <div class="stats-list-footer">
            <span class="footer_text" @click="loadMoreData('join')">{{join_data_bottom}}</span>
          </div>
        </div>
      </transition>
      <transition name="stats">
        <div class="stats_container stats-list" v-show="showStats == 2 && win_numbers">
          <div class="win_list stats-list-body">
            <!-- <transition-group name="stats" tag="div" class="win_list"> -->
            <!-- <div class="win_list_item" v-for="(item,i) in filterBy(win_list)" :key="i"> -->
            <div class="win_list_item" v-for="(item,i) in win_list" :key="i">
              <img class="win_list_item_icon" :src="item.user_icon" alt v-if="item.user_icon" />
              <img src="../../assets/img/apply_avatar_bg.png" alt v-else />
              <span
                class="win_list_item_content"
              >{{filterByNick(item.user_nick)}}&nbsp;抽中&nbsp;￥{{item.price}}元&nbsp;{{item.notes}}</span>
              <span class="win_list_item_time">{{item.add_time}}</span>
            </div>
            <!-- </transition-group> -->
          </div>
          <div class="stats-list-footer">
            <span class="footer_text" @click="loadMoreData('win')">{{win_data_bottom}}</span>
          </div>
        </div>
      </transition>
    </div>
    <!-- 答题结果 -->
    <div class="toast" v-show="answer_control">
      <div class="toast-container">
        <img :src="answer_pictrue" class="toast-picture" />
        <div class="close" @click="close_toast"></div>
        <div class="toast-title">{{answer_title}}</div>
        <div class="toast-btn">
          <div class="toast-cancel" @click="close_toast">关闭</div>
        </div>
      </div>
    </div>
    <div class="toast-mask" v-show="answer_control"></div>
    <!-- 抽奖结果 -->
    <div class="toast" v-show="toast_control">
      <div class="toast-container">
        <img :src="toast_pictrue" class="toast-picture" />
        <div class="close" @click="close_toast()"></div>
        <div class="toast-title">{{toast_title}}</div>
        <div class="toast-btn">
          <div class="toast-cancel" @click="close_toast">关闭</div>
        </div>
      </div>
    </div>

    <!-- 提示分享 -->
    <transition name="opacity-in-out">
      <div class="mask" v-show="showRotateTip" @click="showRotateTip=false">
        <div class="mask_title">获取抽奖机会</div>
        <div class="mask_content">
          <p>点击右上角，选择【发送给朋友】</p>
          <div>
            <img src="../../assets/img/send_to_friend.png" alt />
          </div>
        </div>
        <img class="mask_img" src="../../assets/switch/share_arrow.png" alt />
      </div>
    </transition>
    <div class="toast-mask" v-show="toast_control"></div>
    <!-- 音频 -->
    <audio class="aright" ref="aright" :src="rightAudio"></audio>
    <audio class="wrong" ref="wrong" :src="wrongAudio"></audio>
  </div>
</template>
<script>
import { fail } from 'assert'
import { Toast, MessageBox } from 'mint-ui'
import md5 from 'js-md5'
var wx = require('weixin-js-sdk')
export default {
  data () {
    return {
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      open_id: localStorage.getItem('open_id_' + this.$route.query.id_)
        ? localStorage.getItem('open_id_' + this.$route.query.id_)
        : '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      id: this.$route.query.id ? this.$route.query.id : 0,
      id_: this.$route.query.id_ ? this.$route.query.id_ : 0, // 其他id传进来都是id_
      id_type_: this.$route.query.id_type_ ? this.$route.query.id_type_ : 0,
      share_type: this.$route.query.share_type || 0,
      state: 'lottery',
      park_id: 298,

      /*  */

      park_luck: [],

      prize_list: [], // 奖品列表
      toast_control: false, // 抽奖结果弹出框控制器
      hasPrize: false, // 是否中奖
      start_rotating_degree: 0, // 初始旋转角度
      rotate_angle: 0, // 将要旋转的角度
      start_rotating_degree_pointer: 0, // 指针初始旋转角度
      rotate_angle_pointer: 0, // 指针将要旋转的度数
      rotate_transition: 'transform 5s ease-in-out', // 初始化选中的过度属性控制
      rotate_transition_pointer: 'transform 10s ease-in-out', // 初始化指针过度属性控制
      isRotate: false, // 是否正在抽奖
      index: 0,
      deno: 0,
      /*  */
      thanks_icon: require('../../assets/lottery/give_up.png'), // 奖品图片
      money_icon: require('../../assets/switch/redpacket.png'),
      coupon_icon: require('../../assets/switch/coupon.png'), // 优惠券
      // 答题
      questionList: [],
      questionInfo: [],
      answerNum: 0, // 统计答题次数
      hasAnswer: false, //

      isAnswer: false,
      answer_control: false,
      clickAnswer: '', // 点击题目的正确答案

      // 规则
      content: [],
      have_chance: 0,
      have_chance_id: '',
      rightAudio: '',
      wrongAudio: '',
      // 参与
      join_list: [],
      join_numbers: 0,
      showStats: 1,
      join_data_page: 1,
      join_data_bottom: '点击查看更多',
      join_data_len: '',
      join_data_max: '',
      win_list: [],
      win_numbers: 0,
      win_data_bottom: '点击查看更多',
      win_data_len: '',
      win_data_page: 1,

      // 提示分享
      isShowMaskType: 0,
      showRotateTip: false,
      wx_title: '',
      wx_content: '',
      wx_img: ''
    }
  },
  created () {
    this.initSUrl()
    this.getData()
    this.getUserList()
  },
  computed: {
    toast_title () {
      return this.hasPrize
        ? '恭喜您，获得价值 ￥' +
            this.prize_list[this.index].price +
            ' 的' +
            this.prize_list[this.index].label_name
        : '未中奖'
    },

    toast_pictrue () {
      return this.hasPrize
        ? require('../../assets/lottery/congratulation.png')
        : require('../../assets/lottery/sorry.png')
    },
    answer_title () {
      return this.isAnswer
        ? '答对了！获得1次抽奖机会'
        : '答错了！正确答案是:' + this.clickAnswer.toUpperCase()
    },

    answer_pictrue () {
      return this.isAnswer
        ? require('../../assets/lottery/congratulation.png')
        : require('../../assets/lottery/sorrydacuole.png')
    }
  },
  mounted () {
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id !== 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token === this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem(
            'open_id_' + this.id_,
            this.$route.query.open_id
          )
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.id_, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.id_, '')
      }
    }
    if (this.open_id) {
    } else {
      this.authorize()
    }

    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }
  },
  methods: {
    // 计算进度条宽度
    computedWidth (num) {
      let width =
        (num / this.join_data_max) * 100 >= 6
          ? (num / this.join_data_max) * 100
          : 6
      // console.log(width);
      return {
        width: width + '%'
      }
    },

    // 加载更多数据
    loadMoreData (dataType) {
      let where_, page
      if (dataType === 'join') {
        if (this.join_data_len === 0) {
          this.join_data_bottom = '我是有底线的'
          return
        } else {
          this.join_data_page++
          where_ = 'join'
          page = this.join_data_page
        }
      } else if (dataType === 'win') {
        if (this.win_data_len === 0) {
          this.win_data_bottom = '我是有底线的'
          return
        } else {
          this.win_data_page++
          where_ = 'win'
          page = this.win_data_page
        }
      }

      if (this.id && this.token) {
        this.$ajax(
          'post',
          'Lottery/get_lottery_user_list',
          {
            id: this.id,
            id_: this.id_,
            id_type_: this.id_type_,
            token: this.token,
            where_: where_,
            page: page
          },
          res => {
            // console.log(res);
            if (res.data.id === 0 && res.data.data[dataType + '_list']) {
              this[dataType + '_list'] = this[dataType + '_list'].concat(
                res.data.data[dataType + '_list']
              )
              // console.log(this[dataType + "_list"]);
              this[dataType + '_data_len'] =
                res.data.data[dataType + '_list'].length
            } else {
              Toast('我是有底线的')
              this[dataType + '_data_len'] = 0
            }
          }
        )
      } else {
        // 还未授权过
        this.authorize()
      }
    },
    // 下一题
    newtQuestion () {
      this.showAnswerClient()
    },

    // 显示答题
    showAnswerClient () {
      if (!this.questionList.length) {
        return
      }
      if (this.isRotate) {
        Toast('正在抽奖...')
        return
      }

      // console.log(this.questionList);
      let len = this.questionList.length
      let i = Math.floor(Math.random() * len)

      this.questionInfo = this.questionList[i]
      // console.log(this.questionInfo);
      this.questionInfo.showAnswer = false
    },
    // 显示答题结果

    choseAnswer (answer, event) {
      // console.log(event.target);
      if (this.questionInfo.showAnswer) {
        Toast('您已经答过了')
        return
      }
      this.hasAnswer = true
      this.answerNum++
      this.answer_control = true // 显示答题结果
      this.questionInfo.showAnswer = answer
      this.clickAnswer = this.questionInfo.answer_
      if (answer === this.questionInfo.answer_) {
        this.isAnswer = true
        if (this.rightAudio) {
          this.$refs.aright.play()
        }
      } else if (answer !== this.questionInfo.answer_) {
        this.isAnswer = false
        if (this.wrongAudio) {
          this.$refs.wrong.play()
        }
      }

      let id = this.questionInfo.id

      if (
        this.open_id &&
        this.token &&
        id &&
        answer &&
        this.park_id &&
        this.id_ &&
        this.id_type_
      ) {
        this.$ajax(
          'post',
          'Lottery/save_answer_question_data',
          {
            open_id: this.open_id,
            id: id,
            answer_: answer,
            token: this.token,
            park_id: this.park_id,
            id_: this.id_,
            id_type_: this.id_type_
          },
          res => {
            if (res.data.id === 0) {
              this.have_chance = res.data.data.have_chance
              this.have_chance_id = res.data.data.have_chance_id
            } else {
              Toast('答题失败')
            }
          }
        )
      } else {
        this.authorize()
      }
    },

    // 处理抽奖
    rotate_handle () {
      if (this.isRotate) {
        Toast('正在抽奖中...')
        return
      }
      this.isRotate = true // 旋转结束前，不允许再次触
      if (this.token_ && this.open_id && this.park_id && this.id) {
        if (this.have_chance === 1 && this.have_chance_id) {
          this.$ajax(
            'post',
            'Lottery/get_chance',
            {
              user_id: '',
              is_zmlxj: '0',
              token: this.token_,
              id: this.id,
              open_id: this.open_id,
              park_id: this.park_id,
              have_chance_id: this.have_chance_id
            },
            res => {
              // console.log(res);
              if (res.data.id === 0) {
                this.have_chance = res.data.data.have_chance
                this.have_chance_id = res.data.data.have_chance_id
                this.index = res.data.data.index_
                this.rotating()
                if (this.prize_list[this.index].type_ === 'thanks') {
                  this.isPrize = false
                } else {
                  this.isPrize = true
                }
              } else {
                this.isRotate = false
                Toast('未中奖' + res.data.id)
              }
            }
          )
        } else {
          this.isRotate = false
          if (this.isShowMaskType === 1) {
            this.showRotateTip = true
            setTimeout(() => {
              this.showRotateTip = false
            }, 2000)
          } else {
            MessageBox.alert('发布足迹可获取抽奖机会')
          }
          // Toast("没有抽奖机会");
        }
      } else {
        this.isRotate = false
        this.authorize()
      }
    },
    rotating () {
      var type = 0 // 默认为 0  转盘转动 1 箭头和转盘都转动(暂且遗留)
      var duringTime = 5 // 默认为1s
      // var random = Math.floor(Math.random() * 7)
      var resultIndex = this.index // 最终要旋转到哪一块，对应prize_list的下标
      var resultAngle = [337.5, 292.5, 247.5, 202.5, 157.5, 112.5, 67.5, 22.5] // 最终会旋转到下标的位置所需要的度数
      var randCircle = 6 // 附加多转几圈，2-3
      if (type === 0) {
        // 转动盘子
        var rotateAngle =
          this.start_rotating_degree +
          randCircle * 360 +
          resultAngle[resultIndex] -
          (this.start_rotating_degree % 360)
        this.start_rotating_degree = rotateAngle
        this.rotate_angle = 'rotate(' + rotateAngle + 'deg)'
        // // //转动指针
        // this.rotate_angle_pointer = "rotate("+this.start_rotating_degree_pointer + 360*randCircle+"deg)";
        // this.start_rotating_degree_pointer =360*randCircle;
        var that = this
        // 旋转结束后，允许再次触发
        setTimeout(function () {
          that.isRotate = false
          that.game_over()
          if (that.isPrize && that.rightAudio) {
            that.$refs.aright.play()
          } else if (!that.isPrize && that.wrongAudio) {
            that.$refs.wrong.play()
          }
        }, duringTime * 1000 + 500) // 延时，保证转盘转完
      } else {
        //
      }
    },
    game_over () {
      if (this.prize_list.length) {
        this.hasPrize = this.prize_list[this.index].type_ === 'thanks' ? 0 : 1
      }

      this.toast_control = true
    },
    // 关闭弹窗
    close_toast () {
      this.toast_control = false
      this.answer_control = false
      this.showAnswerClient()
    },
    // 获取数据
    getData () {
      if (this.id && this.token && this.open_id) {
        this.$ajax(
          'post',
          'lottery/get_park_luck_label_list',
          {
            id: this.id,
            token: this.token,
            open_id: this.open_id,
            id_: this.id_,
            id_type_: this.id_type_
          },
          res => {
            if (res.data.id === 0) {
              this.wx_title = res.data.data.share.title
              this.wx_content = res.data.data.share.content
              this.wx_img = res.data.data.share.imgUrl
              // console.log(this.wx_content);

              if (this.is_weixin) {
                let type_ = this.share_type || this.id_type_
                this.wx_share_callback(window.location.href, {
                  url: this.sUrl,
                  id_: this.id_,
                  id_type_: type_,
                  token: this.token,
                  open_id: this.open_id,
                  title: this.wx_title,
                  content: this.wx_content,
                  img: this.wx_img
                })
              }

              this.isShowMaskType = res.data.data.is_show || 0 // 弹出分享或发布足迹
              localStorage.setItem('wx_title', res.data.data.share.title)
              localStorage.setItem('wx_content', res.data.data.share.content)
              localStorage.setItem('wx_img', res.data.data.share.imgUrl)
              if (res.data.data.data) {
                this.prize_list = res.data.data.data
              }
              // console.log(this.prize_list);
              if (res.data.data.park_luck) {
                this.park_luck = res.data.data.park_luck
                this.content = this.park_luck.content.split('\n')
                this.join_numbers = this.park_luck.join_numbers
                this.win_numbers = this.park_luck.win_numbers
              }

              if (res.data.data.question_list) {
                this.questionList = res.data.data.question_list
              } else {
                this.questionList = ''
              }
              // console.log(this.questionlist);

              this.showAnswerClient()

              document.title = this.park_luck.title
              this.token_ = res.data.data.token ? res.data.data.token : ''
              this.rightAudio = res.data.data.rightAudio
                ? res.data.data.rightAudio
                : '' // 答对了音效
              this.wrongAudio = res.data.data.wrongAudio
                ? res.data.data.wrongAudio
                : '' // 答错了音效
              this.have_chance = res.data.data.have_chance
                ? res.data.data.have_chance
                : 0 // 抽奖条件
              this.have_chance_id = res.data.data.have_chance_id
                ? res.data.data.have_chance_id
                : '' // 抽奖条件

              if (this.park_luck.condition_ === 'all') {
                // 参加活动的条件
                this.have_question = true
                this.have_share = true
              } else if (this.park_luck.condition_ === 'question') {
                this.have_question = true
                this.have_share = false
              } else if (this.park_luck.condition_ === 'share') {
                this.have_question = false
                this.have_share = true
              }
            } else {
              Toast('获取数据失败')
            }
          }
        )
      } else {
        // 缺少的就是open_id
        // 还未授权过
        // this.authorize();
      }
    },
    // 过滤中奖数据
    filterBy (list) {
      return list.filter(item => {
        return item.is_money !== '0'
      })
    },

    // 获取用户参与数据
    getUserList () {
      if (this.token && this.id) {
        this.$ajax(
          'post',
          'Lottery/get_lottery_user_list',
          {
            id: this.id,
            id_: this.id_,
            id_type_: this.id_type_,
            token: this.token,
            page: 1,
            where_: 'join'
          },
          res => {
            if (res.data.id === 0) {
              // console.log(res.data.data);
              if (res.data.data.join_list) {
                this.join_list = res.data.data.join_list
                this.join_data_len = this.join_list.length
                this.join_data_bottom = '点击加载更多'
                this.join_data_max = this.join_list[0].numbers
                  ? this.join_list[0].numbers
                  : 1
              } else {
                this.join_data_bottom = '我是有底线的'
              }
              if (res.data.data.win_list) {
                this.win_list = res.data.data.win_list
                this.win_data_len = this.win_list.length
                this.win_data_bottom = '点击加载更多'
              } else {
                this.win_data_bottom = '我是有底线的'
              }
            } else {
              // Toast('暂无参与信息')
            }
          }
        )
      } else {
      }
    },
    // 授权方法
    authorize () {
      var openUrl =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&id_=' +
        this.id_ +
        '&id_type_=' +
        this.id_type_ +
        '&state=' +
        this.state
      window.location.href = openUrl
    },
    // 初始化sUrl
    initSUrl () {
      if (this.id_type_ === 'spots') {
        this.sUrl =
          'https://m.zmlxj.com/#/footprints/PrimaryScenic?sid=' + this.id_
      } else if (this.id_type_ === 'activity') {
        this.sUrl =
          'https://m.zmlxj.com/#/activity/ActivityDetail?aid=' + this.id_
      } else if (this.id_type_ === 'vote') {
        this.sUrl = 'https://m.zmlxj.com/#/vote/vote?vid=' + this.id_
      } else if (this.id_type_ === 'label') {
        this.sUrl =
          'https://m.zmlxj.com/#/footprints/LineInfoList?label_id=' + this.id_
      } else if (this.id_type_ === 'books') {
        this.sUrl = 'https://m.zmlxj.com/#/books/books?label_id=' + this.id_
      } else if (this.id_type_ === 'map') {
        this.sUrl = 'https://m.zmlxj.com/#/DrawMap/map?id=' + this.id_
      } else if (this.id_type_ === 'NoDrawMap') {
        this.sUrl = 'https://m.zmlxj.com/#/DrawMap/NoDrawMap?id=' + this.id_
      } else if (this.id_type_ === 'pixi') {
        this.sUrl = 'https://m.zmlxj.com/#/pixi/pixiIndex?id=' + this.id_
      } else if (this.id_type_ === 'photo') {
        this.sUrl = 'https://m.zmlxj.com/#/parkPhoto/parkPhoto?aid=' + this.id_
      } else if (this.id_type_ === 'leafletMap') {
        this.sUrl = 'https://m.zmlxj.com/#/DrawMap/LeafletMap?id=' + this.id_
      } else if (this.id_type_ === 'pixiMap') {
        this.sUrl = 'https://m.zmlxj.com/#/pixi/pixiMap?id=' + this.id_
      } else if (this.id_type_ === 'pixiIndex') {
        this.sUrl = 'https://m.zmlxj.com/#/pixi/pixiIndex?id=' + this.id_
      } else if (this.id_type_ === 'nihewanQuestion') {
        this.sUrl =
          'https://m.zmlxj.com/#/nihewan/questionIndex?id=' + this.id_
      } else if (this.id_type_ === 'leafletMapIndex' || this.id_type_ === 'leafletMapIndexMini') {
        this.sUrl = 'https://m.zmlxj.com/#/drawmap/leafletmapindex?id=' + this.id_
      } else {
        this.sUrl =
          'https://m.zmlxj.com/#/lottery/lottery?id_=' +
          this.id_ +
          '&id=' +
          this.id +
          '&id_type_=' +
          this.id_type_
      }
    },
    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id === 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var openId = this.open_id
            if (info && info !== '') {
              if (info.url && info.url !== '') {
                let urlArr = info.url.split('share_open_id')

                url = urlArr[0] + '&share_open_id=' + openId
              } else {
                url = url + '&share_open_id=' + openId
              }
            } else {
              url = url + '&share_open_id=' + openId
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id === 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },
    // 截取昵称长度
    filterByNick (n) {
      if (n && n.length > 4) {
        return n.substring(0, 4) + '...'
      } else {
        return n
      }
    }
  }
}
</script>

<style scoped>
html {
  font-size: 14px;
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 376px) and (max-width: 414px) {
  html {
    font-size: 18px;
  }
}
@media screen and (min-width: 415px) and (max-width: 639px) {
  html {
    font-size: 20px;
  }
}
@media screen and (min-width: 640px) and (max-width: 719px) {
  html {
    font-size: 24px;
  }
}
@media screen and (min-width: 720px) and (max-width: 749px) {
  html {
    font-size: 28px;
  }
}
@media screen and (min-width: 750px) and (max-width: 799px) {
  html {
    font-size: 30px;
  }
}
@media screen and (min-width: 800px) {
  html {
    font-size: 48px;
  }
}

.container {
  width: 100%;
  overflow-x: hidden;
}
.lucky-wheel {
  width: 100%;
  height: 31.5625rem;
  background: rgb(252, 207, 133) url("../../assets/lottery/color_pillar.png")
    no-repeat center bottom;
  background-size: 100%;
  padding-top: 1.5625rem;
}
.lucky-title {
  width: 100%;
  height: 8.125rem;
  background: url("../../assets/lottery/lucky_title.png") no-repeat center top;
  background-size: 100%;
}
.wheel-main {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.wheel-bg {
  width: 18.4375rem;
  height: 18.4375rem;
  background: url("../../assets/lottery/draw_wheel.png") no-repeat center top;
  background-size: 100%;
  color: #fff;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  transition: transform 3s ease;
}
.wheel-pointer-box {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -60%);
  width: 5.3125rem;
  height: 5.3125rem;
}
.wheel-pointer {
  width: 5.3125rem;
  height: 5.3125rem;
  background: url("../../assets/lottery/draw_btn.png") no-repeat center top;
  background-size: 100%;
  transform-origin: center 60%;
}
.wheel-bg div {
  text-align: center;
}
.prize-list {
  width: 100%;
  height: 100%;
  position: relative;
}
.prize-list .prize-item {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.prize-list .prize-item:first-child {
  top: 0;
  left: 8.3125rem;
  transform: rotate(20deg);
}
.prize-list .prize-item:nth-child(2) {
  top: 2.8rem;
  left: 10.8rem;
  transform: rotate(67deg);
}
.prize-list .prize-item:nth-child(3) {
  top: 6.4rem;
  left: 10.6rem;
  transform: rotate(-250deg);
}
.prize-list .prize-item:nth-child(4) {
  top: 9rem;
  left: 8.2125rem;
  transform: rotate(-210deg);
}
.prize-list .prize-item:nth-child(5) {
  top: 9.2125rem;
  left: 4.4rem;
  transform: rotate(-160deg);
}
.prize-list .prize-item:nth-child(6) {
  top: 6.3875rem;
  left: 1.9rem;
  transform: rotate(-111deg);
}
.prize-list .prize-item:nth-child(7) {
  top: 2.8rem;
  left: 1.8125rem;
  transform: rotate(-69deg);
}
.prize-list .prize-item:nth-child(8) {
  top: 0;
  left: 4.5rem;
  transform: rotate(-20deg);
}
.prize-item {
  width: 5.875rem;
  height: 9rem;
}

.prize-pic img {
  height: 2rem;
  margin-top: 1.5625rem;
}
.prize-count {
  font-size: 0.875rem;
}
.prize-type {
  width: 70%;
  margin: 0 auto;
  font-size: 0.7rem;
  color: #fff;
}
.main {
  position: relative;
  width: 100%;
  min-height: 14.25rem;
  background: rgb(243, 109, 86);
  padding-bottom: 1.6875rem;
}
.main-bg {
  width: 100%;
  height: 6.5625rem;
  position: absolute;
  top: -3.4375rem;
  left: 0;
  background: url("../../assets/lottery/luck_bg.png") no-repeat center top;
  background-size: 100%;
}
.bg-p {
  width: 100%;
  height: 2.95rem;
  background: rgb(252, 207, 133);
}
.content {
  position: absolute;
  top: 0.175rem;
  left: 0;
  background: rgb(243, 109, 86);
  width: 100%;
  height: 3rem;
  font-size: 1.125rem;
  color: #ffeb39;
}
.content div {
  text-align: center;
}
.content_box {
  position: relative;
  margin: 0 auto;
  width: 17.5rem;
  min-height: 6rem;
  border: 1px solid #fbc27f;
}
/* 参与人数 */
.stats-enter {
  transform: translate(30%, 0);
  opacity: 0;
}

.stats-enter-to {
  transform: translate(0, 0);
  opacity: 1;
}
.stats-enter-active {
  transition: ease 0.3s;
}
.stats_wrapper {
  margin-top: 2.5rem;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
}
.stats_wrapper .stats_item {
  height: 100%;
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: #eee;
  background-color: rgba(255, 166, 0, 1);
  transition: 0.5s;
}
/* .stats_wrapper .stats_item:nth-child(1){
  border-right: 1px solid rgb(236, 154, 2);
} */
.stats_wrapper .stats_item.stats_active {
  background-color: rgba(255, 166, 0, 0.6);
  color: #fff;
  transition: 0.5s;
}
.stats_wrapper .stats_item .stats_item_name {
  font-size: 0.8rem;
}
.stats_wrapper .stats_item .stats_item_number {
  font-size: 0.88rem;
  font-weight: 600;
}

.win_list {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.win_list .win_list_item {
  width: 100%;
  height: 2.2rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.win_list .win_list_item img {
  width: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.win_list .win_list_item .win_list_item_content {
  width: 60%;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.win_list .win_list_item .win_list_item_time {
  width: 40%;
  color: #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 答题 */
.answer .answer-title {
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1rem;
  color: #fccc6e;
  background: rgb(243, 109, 86);
  padding: 0.3125rem 0.625rem;
  font-weight: 400;
}

.answer .question .answer-next {
  width: 100%;
  text-align: center;
  height: 2rem;
  line-height: 2rem;
}
.answer .question .answer-next span {
  font-size: 0.8rem;
  padding: 6px 16px;
  color: #fff;
  border-radius: 4px;
  background-color: rgba(226, 47, 38, 0.8);
}
.next-enter {
  height: 0;
}
.next-enter-to {
  height: 2rem;
}
.next-enter-active {
  transition: ease 0.3s;
}

/*  */

.question .question_preview {
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1rem;
  color: #fccc6e;
  background: rgb(243, 109, 86);
  padding: 0.3125rem 0.625rem;
  font-weight: 400;
}
.question .qs_preview_wrapper {
  position: relative;
  padding: 1rem 0.625rem 0.5rem;
  font-size: 0.875rem;
  color: #fff8c5;
  line-height: 1.5;
}
.question .qs_preview_wrapper .qs_preview_title img {
  width: 60%;
  display: block;
  margin: 1rem auto;
}

.question .qs_preview_wrapper .qs_preview_text li {
  margin: 0.5rem 0;
  padding: 1px 0.4rem;
  color: #fae4b9;
  line-height: 1.8rem;
  /* height: 1.8rem; */
  /* box-sizing: border-box; */
  border-radius: 4px;
  background: rgb(250, 132, 112);
}
.question .qs_preview_wrapper .qs_preview_text li span {
  display: inline-block;
  width: 100%;
  /* height: 100%; */
}

.question .qs_preview_wrapper .qs_preview_img li {
  position: relative;
  left: 20%;
  width: 60%;
  padding: 0.5rem;
  background: rgb(250, 132, 112);
  border-radius: 4px;
  box-sizing: border-box;
}
.question .qs_preview_wrapper ul li img {
  max-width: 100%;
  position: relative;
  display: block;
  margin: 0 auto;
  border-radius: 4px;
  box-sizing: border-box;
}
.question .qs_preview_wrapper ul li.wrong_answer {
  color: #fff;
  background: rgb(255, 44, 7);
  /* transition: 0.5s; */
}
.question .qs_preview_wrapper ul li.right_answer {
  color: #fff;
  background: rgb(24, 217, 66);
  /* transition: 0.5s; */
}
.question .qs_preview_wrapper .question_modify {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  font-size: 0.8rem;
  padding: 4px 12px;
  background-color: rgb(223, 73, 47);
  color: #fff;
}
.question .qs_preview_wrapper .question_del {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  font-size: 0.8rem;
  padding: 4px 12px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #eee;
}
.tip {
  margin-top: 2.5rem;
}
.tip-title {
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1rem;
  color: #fccc6e;
  background: rgb(243, 109, 86);
  padding: 0.3125rem 0.625rem;
}
.tip-content {
  padding: 1.5625rem 0.625rem;
  font-size: 0.875rem;
  color: #fff8c5;
  line-height: 1.5;
}
.toast-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  width: 100%;
  height: 100%;
}
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20000;
  transform: translate(-50%, -50%);
  width: 15.4375rem;
  background: #fff;
  border-radius: 0.3125rem;
  padding: 0.3125rem;
  background-color: rgb(252, 244, 224);
}
.toast-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px dotted #fccc6e;
}
.toast-picture {
  position: absolute;
  top: -6.5rem;
  left: -1.5rem;
  width: 18.75rem;
  height: 8.5625rem;
}
.toast-pictrue-change {
  position: absolute;
  top: -1.5rem;
  left: -1.375rem;
  width: 17.5rem;
  height: 3.125rem;
}
.toast-title {
  padding: 2.8125rem 0;
  font-size: 18px;
  color: #fc7939;
  text-align: center;
}
.toast-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.9375rem;
}
.toast-btn div {
  background-image: -moz-linear-gradient(
    -18deg,
    rgb(242, 148, 85) 0%,
    rgb(252, 124, 88) 51%,
    rgb(252, 124, 88) 99%
  );

  background-image: -ms-linear-gradient(
    -18deg,
    rgb(242, 148, 85) 0%,
    rgb(252, 124, 88) 51%,
    rgb(252, 124, 88) 99%
  );
  background-image: -webkit-linear-gradient(
    -18deg,
    rgb(242, 148, 85) 0%,
    rgb(252, 124, 88) 51%,
    rgb(252, 124, 88) 99%
  );
  box-shadow: 0px 4px 0px 0px rgba(174, 34, 5, 0.7);
  width: 4.6875rem;
  height: 1.875rem;
  border-radius: 1.875rem;
  text-align: center;
  line-height: 1.875rem;
  color: #fff;
}
.close {
  position: absolute;
  top: -0.9375rem;
  right: -0.9375rem;
  width: 2rem;
  height: 2rem;
  background: url("../../assets/lottery/close_store.png") no-repeat center top;
  background-size: 100%;
}

/* 提示分享 */
.opacity-in-out-enter,
.opacity-in-out-leave-to {
  opacity: 0;
}
.opacity-in-out-enter-to,
.opacity-in-out-leave {
  opacity: 1;
}
.opacity-in-out-enter-active,
.opacity-in-out-leave-active {
  transition: 0.3s;
}
.mask {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}
.mask .mask_title {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 1rem;
  font-weight: 400;
}
.mask .mask_content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.88rem;
}
.mask .mask_content div {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask .mask_content div img {
  width: 1.2rem;
  height: 1.2rem;
}
.mask .mask_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
}
/*  */

.stats-list {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0.4rem 1rem;
  /* background-color: #fff; */
}

.stats-list .stats-list-body {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  /* flex-wrap: wrap; */
  flex-direction: column;
  padding: 0.5rem 0;
}
.stats-list .stats-list-body .stats-list-item {
  position: relative;
  width: 100%;
  height: 2.2rem;
  display: flex;
  align-items: center;
}
.stats-list .stats-list-body img {
  width: 1.8rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.stats-list .stats-list-body .list-item-content {
  font-size: 0.8rem;
  color: #fff;
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stats-list .stats-list-body .list-item-content .list-item-content-name {
  color: #fff;
}
.stats-list .stats-list-body .list-item-count {
  position: relative;
  width: 80%;
  height: 0.8rem;
  line-height: 0.8rem;
  background-color: rgba(255, 166, 0, 0.2);
  text-align: right;
  border-radius: 100px;
  /* display: flex; */
}
/* .stats-list .stats-list-body .list-item-count .count-progress-hold {
  display: block;
  height: 100%;
  background-color: rgba(255, 0, 0, 1);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  width: 5%;
} */
.stats-list .stats-list-body .list-item-count .count-progress {
  display: block;
  height: 100%;
  background-color: rgba(255, 166, 0, 1);
  /* border-top-right-radius: 100px; */
  /* border-bottom-right-radius: 100px; */
  border-radius: 100px;
}
.stats-list .stats-list-body .list-item-count .count-number {
  position: absolute;
  top: 0;
  right: 0.5rem;
  height: 100%;
  color: #fff;
}
.stats-list .jl-body {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0.5rem 0;
}
.stats-list .jl-body .jl-item {
  display: flex;
  width: 100%;
  height: 2.4rem;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0.4rem;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.stats-list .jl-body .jl-item img {
  display: block;
  border-radius: 50%;
  width: 2rem;
  margin-right: 1%;
}
.stats-list .jl-body .jl-item .jl-name {
  margin-right: 5%;
  display: flex;
  align-items: center;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stats-list .jl-body .jl-item .jl-time {
  width: 40%;
  font-size: 12px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stats-list .jl-body .jl-item .jl-prize {
  color: #333;
}
.stats-list .jl-body .jl-item .jl-prize .jl-prize-price {
  color: rgb(168, 4, 4);
}
.stats-list .stats-list-footer {
  position: relative;
  width: 40%;
  margin: 1rem auto 0;
  color: #ccc;
  text-align: center;
  background-color: #ccc;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats-list .stats-list-footer .footer_text {
  display: inline-block;
  padding: 0 4px;
  background-color: rgb(243, 109, 86);
}
</style>
