<template>
  <div class="s_body">
    <div class="s_success">
      <span>支付成功{{second}} 秒后自动跳转</span>
      <span @click="go_ticket">点击会首页</span>
    </div>
    <div class="s_recommand"></div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
export default {
  components: {},
  data() {
    return {
      id: this.$route.query.id > 0 ? this.$route.query.id : 0,
      order_id: this.$route.query.order_id ? this.$route.query.order_id : 0,
      timeOut: "",
      second: 5
    };
  },
  created() {
    //判断是否已经存在定时器
    if (this.timeOut) {
      //清除定时器
      clearTimeout(this.timeOut);
    } else {
      this.create_time_out();
    }
  },

  mounts() {
    setinterval(this.go_ticket_time, 5000);
  },
  methods: {
    //点击回首页
    go_ticket() {
      this.$router.push({ path: "/my/Order" });
    },

    create_time_out() {
      let _this = this;
      this.timeOut = setTimeout(() => {
        //console.log(this.second);
        if (this.second <= 1) {
          clearTimeout(this.timeOut);
          _this.go_ticket();
        } else {
          --_this.second;
          _this.create_time_out();
        }
      }, 1000);
    }
  }
};
</script>

<style scoped>
.s_body {
  display: block;
  position: relative;
  margin-top: 4%;
  margin-left: 1%;
  width: 98%;
}
.s_body .s_success {
  display: block;
  position: relative;
  margin-top: 30%;
  width: 100%;
}
.s_body .s_success span {
  display: block;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin-top: 2%;
  background-color: #ff6347;
}
</style>