<template>
  <div v-title data-title="最美旅行家" class="home">
    <!-- 头部 -->
    <header class="header home fixed">
      <div class="LOGO_box">
        <img src="../assets/img/LOGO.png" alt class="LOGO" />
      </div>
      <router-link :to="{ path: '/SearchHome/SearchIndex/'}" class="search_img">
        <i class="iconfont iconsearch"></i>
      </router-link>
    </header>

    <div class="page-content">
      <mt-swipe :auto="4000" class="slide" v-if="indexBanner">
        <mt-swipe-item v-for="item in indexBanner" :key="item.id">
          <router-link :to="{path:'/activity/ActivityDetail',query:{aid:item.id}}">
            <img :src="item.banner_img_app" />
          </router-link>
        </mt-swipe-item>
      </mt-swipe>

      <!--活动专题-->
      <div class="homelink">
        <div class="feed-title" v-if="list.length > 0">
          <h1>
            活动专题
            <router-link to="/activity/ActivityList" class="more">更多活动</router-link>
          </h1>
          <div class="activity_list_box">
            <ul class="activity_list clearfix" :style="'width: ' + (list.length * 80) + '%'">
              <router-link
                tag="li"
                :to="{path:'/activity/ActivityDetail',query:{aid:k.id}}"
                v-for="k in list"
                :key="k.id"
                :style="'width: '+ 100 / list.length +'%'"
                class="li"
              >
                <img :src="k.theme_img_1" :alt="k.activity_name" />
                <p class="text">{{k.activity_name}}</p>
              </router-link>
            </ul>
          </div>
        </div>
      </div>
      <!--热门公园-->
      <div v-if="park_list.length > 0">
        <home-park :datainfo="park_list" :is_hot="true" :is_tit="true"></home-park>
      </div>
      <footprints-list @ievent="ievent" ref="child"></footprints-list>
      <router-link
        to="/footprints/FootprintsPublish"
        tag="div"
        class="publish"
        v-if="is_show_publish"
      >
        <img src="../assets/img/footprint_send.png" alt />
      </router-link>
      <!--原来发布足迹的图片：footprint_send.png-->
    </div>
    <home-footer></home-footer>
  </div>
</template>
<script>
import HomeFooter from "../components/HomeFooter";
import FootprintsList from "../components/FootprintsList";
import HomePark from "../components/HomePark";
export default {
  components: {
    HomeFooter,
    FootprintsList,
    HomePark
  },
  data() {
    return {
      indexBanner: {},
      list: [],
      info: [],
      park_list: [],
      scrollTop: 0,
      is_show_publish: true,
      isred: false,
      my_id: "",
      wx_img: "http://m.zmlxj.com/zmlxjshare.jpg", //微信分享的图片
      is_weixin: this.hybrid.hybrid.versions.is_weixin
    };
  },
  mounted() {
    this.unload();
    if (this.is_weixin) {
      this.wx_share(
        window.location.href,
        "最美旅行家",
        "人生就是一场旅行",
        this.wx_img
      );
    }
  },
  deactivated() {
    var old_spot_id = localStorage.old_spot_id;
    var new_spot_id = localStorage.new_spot_id;

    if (new_spot_id) {
      // 两者不相同时，则销毁组件
      if (new_spot_id !== old_spot_id) {
        this.$destroy;
      }
    }
  },
  methods: {
    unload() {
      if (this.check_login()) {
        this.my_id = this.check_login().user_id;
      }
      var that = this;
      this.$ajax("post", "index/getBanner", "", function(res) {
        that.indexBanner = res.data;
      });
      this.$ajax("post", "Park/hotRecomment_v2", "", res => {
        if (res.data.id == 0) {
          this.park_list = res.data.data;
        }
      });
      this.$ajax("post", "Park/clickHotActivity", "", ret => {
        if (ret.data.id == 0) {
          this.list = ret.data.data;
        }
      });
      this.getData(1);

      this.$nextTick(() => {
        var num = 0;
        window.addEventListener(
          "scroll",
          function() {
            that.scrollTop =
              document.documentElement.scrollTop || document.body.scrollTop;
            if (num < that.scrollTop) {
              that.is_show_publish = false; //false->表示向下滑动之后就不会显示，true->一直都会显示
            } else {
              that.is_show_publish = true;
            }
            num = that.scrollTop;
          },
          true
        );
      });
    },
    ievent(...data) {
      this.getData(data[0]);
    },
    //            获取数据
    getData(page) {
      this.$ajax(
        "post",
        "foot/get_spots_list",
        { page: page, my_id: this.my_id },
        res => {
          this.save_arr("old_spot_id", res.data.data.data[0].id);
          if (res.data.id == 0) {
            this.info = this.info.concat(res.data.data.data);
            if (res.data.data.red_packet == 1) {
              this.isred = true;
            }
            this.$refs.child.parentMsg({
              info: this.info,
              isred: this.isred,
              my_id: this.my_id,
              tit: true,
              parklink: true
            });
          }
        }
      );
    }
  }
};
</script>
<style scoped>
/*修改mintUI默认样式*/
.mint-header {
  background: #ff9a80;
  font-size: 0.8rem;
  height: 2rem;
}

.LOGO_box {
  width: 100%;
}

.LOGO {
  height: 1.6rem;
  display: inline-block;
  margin-top: 0.2rem;
}

.search_img {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgb(245, 245, 245);
}

.search_img .iconfont {
  font-size: 1rem;
  color: rgb(216, 30, 6);
}

@media screen and (max-width: 360px) {
  .slide.mint-swipe {
    height: 130px;
  }
}

@media screen and (min-width: 361px) and (max-width: 475px) {
  .slide.mint-swipe {
    height: 42vw;
  }
}

@media screen and (min-width: 476px) and (max-width: 767px) {
  .slide.mint-swipe {
    height: 228px;
  }
}

/* 平板 */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .slide.mint-swipe {
    height: 309px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .slide.mint-swipe {
    height: 412px;
  }
}

/* pc */
@media screen and (min-width: 1201px) {
  .slide.mint-swipe {
    height: 767px;
  }
}

/*内容主体*/
.mint-header-title {
  line-height: 2rem;
}

.page-content.no_top_padding {
  padding-top: 0;
}

.page-content.no_button_padding {
  padding-bottom: 0;
}

.home .page-content {
  padding: 3rem 0;
  font-size: 0.7rem;
  background-color: #fff;
}

.header {
  position: relative;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background: #fff;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.header.home {
  background: #fff;
  position: fixed;
}

.iconfont {
  font-size: 0.8rem;
}

/*焦点图*/
.home >>> .slide.mint-swipe a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home >>> .slide.mint-swipe .mint-swipe-indicators {
  bottom: 0;
}

.home >>> .slide.mint-swipe .mint-swipe-indicator {
  height: 0.4rem;
  width: 1rem;
  opacity: 1;
  border-radius: 0.2rem;
  background: rgba(192, 192, 192, 0.6);
}

.home >>> .mint-swipe-indicator {
  background-color: rgba(255, 255, 255, 0.6);
}

.home >>> .mint-swipe-indicator.is-active {
  background: rgb(255, 255, 255);
}

.home >>> .slide.mint-swipe .mint-swipe-indicator.is-active {
  background: rgb(255, 255, 255);
}

/*首页链接跳转*/
.homelink {
  display: flex;
  background: #fff;
}

.publish {
  width: 4rem;
  height: 4rem;
  position: fixed;
  bottom: 3rem;
  left: 45%;
  margin-left: -1rem;
}

.publish img {
  width: 100%;
}

.grayness > .mint-header {
  background: #fafafa;
  color: #000;
}

a:active {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
}

.mint-header.is-fixed {
  z-index: 1000;
}

.mint-button .mintui {
  font-size: 1rem;
}

.mint-actionsheet-listitem,
.mint-actionsheet-button {
  font-size: 0.7rem;
  line-height: 2rem;
  height: 2rem;
}

.homelink .feed-title {
  position: relative;
  margin-top: 1rem;
  padding: 0 1rem;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
}

.homelink .feed-title h1 {
  position: relative;
  width: 100%;
  height: 2rem;
  padding-left: 0.5rem;
  line-height: 2rem;
  font-size: 1rem;
  box-sizing: border-box;
  color: #000;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.homelink .feed-title h1::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 1rem;
  width: 0.2rem;
  background-color: #ff9a80;
}

.feed-title .more {
  position: absolute;
  right: 0;
  top: 0;
  height: 2rem;
  line-height: 2rem;
  color: #999;
  background: url(../assets/img/home_icon_title_more.png) 100% no-repeat;
  background-size: auto 12px;
  padding-right: 1rem;
  font-size: 0.76rem;
}

.activity_list_box {
  width: 100%;
  margin-top: 0.5rem;
  overflow-x: scroll;
}

.activity_list_box .activity_list .li {
  position: relative;
  width: 32%;
  float: left;
  padding-right: 1rem;
  box-sizing: border-box;
  overflow: hidden;
}

.activity_list_box .activity_list .li:last-child {
  padding-right: 0;
}

.activity_list_box .activity_list li .text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  color: #fff;
  text-shadow: -1px 1px 1px #000;
  font-size: 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.activity_list_box .activity_list li img {
  width: 100%;
  height: 35vw; /*20180320首页活动限制更多活动里面图片的最大高度*/
  display: inline-block;
  border-radius: 4px;
  object-fit: cover;
}

/* 补充样式 */
.note-track-card .map-pic {
  width: 100%;
  height: 6rem;
  background-position: 50%;
  background-size: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.feed-title h1 {
  height: 2rem;
  line-height: 2rem;
  font-size: 0.8rem;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>

