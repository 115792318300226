<template>
  <div v-title data-title="我的收藏">
    <div class="message">
      <mt-header fixed title="我的收藏">
        <a slot="left">
          <mt-button icon="back" @click="$router.go(-1)"></mt-button>
        </a>
      </mt-header>
    </div>
    <div class="page-content">
      <div class="container container-wh">
        <div class="message-list" v-if="items != '' ">
          <mt-loadmore
            :bottom-method="loadBottom"
            :bottom-all-loaded="allLoaded"
            ref="loadmore"
            :auto-fill="false"
          >
            <div class="feed-item" v-for="(item,index) in items" :key="index">
              <!-- 下拉菜单 -->
              <div
                class="feed-item-downbtn"
                :collect="item.collect"
                @click="setting($event,item.id,index,item.user_id)"
              ></div>
              <!-- 用户信息 -->
              <div class="feed-item-top">
                <div class="avatar fl" v-if="item.user_id != user_id">
                  <router-link :to="{name:'otherIndex', params: { uid: item.user_id }}">
                    <img :src="item.user_icon" v-if="item.user_icon != '' " />
                    <img src="../../assets/img/apply_avatar_bg.png" v-if="item.user_icon == '' " />
                  </router-link>
                </div>
                <div class="avatar fl" v-if="item.user_id == user_id">
                  <router-link
                    :to="{name:'MyIndex', params: { uid: item.user_id }}"
                    v-if="item.user_icon != '' "
                  >
                    <img :src="item.user_icon" v-if="item.user_icon != '' " />
                    <img src="../../assets/img/apply_avatar_bg.png" v-if="item.user_icon == '' " />
                  </router-link>
                </div>

                <div class="name">
                  <span v-if="item.user_id != user_id">
                    <router-link
                      :to="{name:'otherIndex', params: { uid: item.user_id }}"
                    >{{item.user_nick}}</router-link>
                  </span>
                  <span v-if="item.user_id == user_id">
                    <router-link
                      :to="{name:'MyIndex', params: { uid: item.user_id }}"
                    >{{item.user_nick}}</router-link>
                  </span>
                </div>
                <h2>
                  <span class="time">{{item.travel_notes_time}}</span>
                  <!--<span class="location">{{item.travel_notes_address}}</span>-->
                </h2>
              </div>
              <!-- 游记内容 -->
              <router-link
                :to="{ name: 'NoteDetail', params: { id: item.id }}"
                class="feed-item-body"
              >
                <!--<div class="feed-item-body">-->
                <div class="text">{{item.travel_notes_title}}</div>
                <div class="img-box row3">
                  <div
                    class="item"
                    pic="itemimg.travel_notes_img_1"
                    v-for="(itemimg,i) in item.img"
                    :key="i"
                  >
                    <!-- <img  :src="itemimg.travel_notes_img_1" /> -->
                    <p v-imgBg="itemimg.travel_notes_img_1"></p>
                  </div>
                </div>
                <div class="feed-item-tag">
                  <a href="javascript:;">{{item.park_name}}</a>
                </div>
                <!--</div>-->
              </router-link>
              <!-- 工具栏 -->
              <div class="feed-item-tool">
                <a class="zan" :class="{'yes':item.like == 1}" @click="postLike(item.id, index)">
                  <span>{{item.like_numbers}}</span>
                </a>
                <router-link :to="{ name: 'NoteDetail', params: { id: item.id }}" class="ping">
                  <span>{{item.discuss_numbers}}</span>
                </router-link>
                <a class="zhuan">
                  <span>{{item.transpond_numbers}}</span>
                </a>
              </div>
            </div>
          </mt-loadmore>
        </div>
        <div v-else class="foot_noinfo">
          <img src="/static/img/foot_noinfo@2x.14391c2.png" alt />
          <p>暂无数据</p>
        </div>
      </div>
      <mt-actionsheet :actions="setting_info" v-model="sheetVisible"></mt-actionsheet>
    </div>
  </div>
</template>
<style scoped>
.feed-item-top .name span a {
  font-size: 12px;
  color: #00cbbd;
}
</style>
<script>
import { Toast } from "mint-ui";
import { MessageBox } from "mint-ui";
export default {
  data() {
    return {
      items: [],
      user_id: this.get_User_Id(),
      page: 1,
      allLoaded: false, //是否加载
      totalCount: "", //初始化方法中的数量总数
      setting_info: [{ name: "取消收藏", method: this.collect }],
      setting_status: {
        collect: "", //收藏状态
        index: 0
      },
      sheetVisible: false,
      travel_notes_id: "", //游记id
      user_be_id: "" //关注的id
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      this.getTravelNotes(this.page);
    },
    // 得到收藏的游记
    getTravelNotes(page) {
      var that = this;
      var data = {
        user_id: this.user_id,
        page: page
      };
      this.$ajax("post", "User/getCollectList", data, function(res) {
        if (res.data.id == 0) {
          that.items = that.items.concat(res.data.data);
          that.totalCount = res.data.data.length;
        }
      });
    },
    //点赞
    postLike(nid, index) {
      this.$ajax(
        "post",
        "TravelNotes/clickLike",
        { user_id: this.user_id, travel_notes_id: nid },
        res => {
          if (res.data.id == 0) {
            var items = this.items[index];
            if (items.like == 1) {
              this.$set(items, "like", 0);
              items.like_numbers--;
            } else {
              this.$set(items, "like", 1);
              items.like_numbers++;
            }
            this.$set(items, "like_numbers", items.like_numbers);
          }
        }
      );
    },
    //            设置
    setting(e, travel_notes_id, i, user_be_id) {
      this.sheetVisible = !this.sheetVisible;
      this.travel_notes_id = travel_notes_id;
      this.user_be_id = user_be_id;
      this.$set(
        this.setting_status,
        "collect",
        e.target.getAttribute("collect")
      );
      this.$set(this.setting_status, "index", i);
      if (this.setting_status.collect == 0) {
        this.$set(this.setting_info[0], "name", "收藏");
      } else {
        this.$set(this.setting_info[0], "name", "取消收藏");
      }
    },
    //            收藏
    collect() {
      this.$ajax(
        "post",
        "TravelNotes/collectTravelNotes",
        {
          user_id: this.user_id,
          travel_notes_id: this.travel_notes_id
        },
        res => {
          if (res.data.id == 0) {
            if (this.setting_status.collect == 0) {
              Toast("收藏成功");
            } else {
              Toast("取消收藏成功");
            }
            this.$set(this.setting_status, "collect", res.data.data.is_cancel);
            $(".feed-item-downbtn")[this.setting_status.index].setAttribute(
              "collect",
              res.data.data.is_cancel
            );
          }
        }
      );
    },
    //  下拉加载页面
    loadBottom() {
      setTimeout(() => {
        if (this.totalCount % 10 == 0) {
          this.page = this.page + 1;
          this.getTravelNotes(this.page);
        } else {
          this.allLoaded = true; // 若数据已全部获取完毕
          Toast("没有更多了");
        }
        this.$refs.loadmore.onBottomLoaded();
      }, 500);
    }
  }
};
</script>