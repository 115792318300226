<template>
  <div v-title data-title="消息">
    <div class="message">
      <mt-header fixed title="消息">
        <a slot="left">
          <mt-button icon="back" @click="$router.go(-1)"></mt-button>
        </a>
      </mt-header>
    </div>

    <div class="page-content">
      <div class="message-list">
        <ul>
          <li class="clearfix">
            <router-link to="/message/MessageChat">
              <div class="icon msg-chat">私信</div>
              <div class="btn go fr"></div>
              <span class="fr" v-if="chat_numbers != 0">{{chat_numbers}}</span>
            </router-link>
          </li>
          <li class="clearfix">
            <router-link to="/message/MessageComment">
              <div class="icon msg-reply">评论</div>
              <div class="btn go fr"></div>
              <span class="fr" v-if="discuss_numbers != 0">{{discuss_numbers}}</span>
            </router-link>
          </li>
          <li class="clearfix">
            <router-link to="/message/MessageLike">
              <div class="icon msg-like">赞</div>
              <div class="btn go fr"></div>
              <span class="fr" v-if="like_numbers != 0">{{like_numbers}}</span>
            </router-link>
          </li>
        </ul>
        <ul>
          <li>
            <router-link to="/message/noteLike">
              <div class="icon my-collect">我的收藏</div>
              <div class="btn go fr"></div>
            </router-link>
          </li>
          <li>
            <router-link to="/message/spotComment">
              <div class="icon my-comment">我的点评</div>
              <div class="btn go fr"></div>
            </router-link>
          </li>
          <li>
            <router-link to="/message/MyActivities">
              <div class="icon my-activity">我的活动</div>
              <div class="btn go fr"></div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.message >>> .mint-header.is-fixed {
  background: #fafafa;
  color: #000;
}
.message-list {
  background-color: #fff;
}
.message-list ul {
  border-bottom: 0.3rem solid #f2f2f2;
}
.message-list ul li {
  padding: 0.5rem;
  border-bottom: 1px solid #f2f2f2;
}
.message-list ul li a {
  display: block;
  color: #666;
}
.message-list ul li a span {
  line-height: 32px;
  margin-right: 20px;
}
.message-list ul li .icon {
  margin-top: 0.2rem;
  display: inline-block;
  height: 2rem;
  padding-left: 3.5rem;
  line-height: 2rem;
  font-size: 0.6rem;
}
.message-list ul li .btn {
  background: url(../../assets/img/home_icon_title_more.png) no-repeat center;
  background-size: 0.65rem 1.1rem;
  width: 0.65rem;
  height: 1.6rem;
}
.message-list ul li .icon.msg-chat {
  background: url(../../assets/img/msg_menu_msg.png) no-repeat;
  background-size: 2rem;
}
.message-list ul li .icon.msg-reply {
  background: url(../../assets/img/msg_menu_reply.png) no-repeat;
  background-size: 2rem;
}
.message-list ul li .icon.msg-like {
  background: url(../../assets/img/msg_menu_zan.png) no-repeat;
  background-size: 2rem;
}
.message-list ul li .icon.my-collect {
  background: url(../../assets/img/msg_menu_collect.png) no-repeat;
  background-size: 2rem;
}
.message-list ul li .icon.my-comment {
  background: url(../../assets/img/msg_menu_comment.png) no-repeat;
  background-size: 2rem;
}
.message-list ul li .icon.my-activity {
  background: url(../../assets/img/msg_menu_activity.png) no-repeat;
  background-size: 2rem;
}
</style>

<script>
export default {
  data() {
    return {
      chat_numbers: "",
      discuss_numbers: "",
      like_numbers: ""
    };
  },
  mounted() {
    this.getNewMsg();
  },
  methods: {
    getNewMsg() {
      var that = this;

      var data = {
        user_id: that.get_User_Id()
      };

      this.$ajax("post", "Common/getNewMsg_v2", data, function(res) {
        //                   console.log(res.data.data);
        that.chat_numbers = res.data.data.chat_numbers;
        that.discuss_numbers = res.data.data.discuss_numbers;
        that.like_numbers = res.data.data.like_numbers;
      });
    }
  }
};
</script>