<template>
  <div class="container" v-if="loaded">
    <i class="back iconfont iconmenu2" @click="goBack"></i>
    <img src="../../assets/nihewan/books_bg.jpg" class="bg" />

    <button class="menu-btn" @click="showMenu = true">目录</button>

    <transition name="slide">
      <div class="menu" v-show="showMenu" @click.self="showMenu=false">
        <h2 class="title">目录</h2>
        <i class="iconfont iconclose close" @click="showMenu=false"></i>
        <ul class="list">
          <li class="item" v-for="(item, index) in titleList" :key="index">
            <p class="marker" @click="toAnchor(item.id)">{{item.title}}</p>

            <ul class="sublist" v-if="item.list && item.list.length">
              <li class="subitem" v-for="(it, idx) in item.list" :key="idx">
                <p class="marker" @click="toAnchor(it.id)">{{it.title}}</p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </transition>

    <main class="main">
      <swiper class="swiper-container" :options="swiperOption" ref="mainSwiper">
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </main>
    <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "swiper/vue";
import { Indicator } from "mint-ui";
import TechnicalSupport from "../../components/TechnicalSupport";
export default {
  components: {
    swiper,
    swiperSlide,
    "technical-support": TechnicalSupport
  },
  data() {
    return {
      swiperOption: {},
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanBook",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      aid: this.$route.query.aid || 0,

      // 获取数据
      list: [],
      titleList: [],

      // 自定义
      loaded: false,
      isLoading: false,
      activeIndex: 1,
      showMenu: false,
      mainSwiper: null,

      // company
      companyInfo: {},
      haveCompany: false
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
    }

    this.getData();
    this.getCompanyInfo(this.id, this.token).then(
      res => {
        this.companyInfo = res;
        this.haveCompany = true;
      },
      err => {
        console.log(err);
      }
    );
  },
  methods: {
    toAnchor(id) {
      //   console.log(id);
      if (!this.swiper) {
        this.mainSwiper = this.$refs.mainSwiper.swiper;
      }
      let index = -1;

      for (let i = 0, len = this.list.length; i < len; i++) {
        if (id === this.list[i].id) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        this.showMenu = false;
        this.mainSwiper.slideTo(index, 0);
      } else {
        console.log("没有这一页");
        return false;
      }
    },

    // 上一级
    goBack() {
      this.$router.push({
        path: "/nihewan/home",
        query: {
          id: this.id
        }
      });
    },
    initSwiper() {
      this.swiperOption = {
        autoplay: false,
        loop: false,
        speed: 200,
        zoom: true,
        lazy: true,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
          clickable: false
        },
        virtual: {
          slides: (() => {
            const slides = [];
            for (let i = 0, len = this.list.length; i < len; i += 1) {
              slides.push(
                `<div class="swiper-zoom-container"><img src="${this.list[i].newUrl}" class="swiper-lazy img" /><div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div></div>`
              );
            }
            return slides;
          })()
        }
      };
    },
    // 获取数据
    getData() {
      if (this.aid && this.open_id && this.token) {
        this.isLoading = true;
        Indicator.open("加载中...");
        this.$ajax(
          "post",
          "Label/get_books_image_data",
          {
            id: this.aid,
            id_type_: this.type_,
            open_id: this.open_id,
            token: this.token,
            version: this.version,
            park_id: this.park_id
          },
          res => {
            if (res.data.id === 0) {
              document.title = res.data.data.title;
              this.list.push(...res.data.data.list);
              this.titleList.push(...res.data.data.title_list);

              const sUrl = window.location.href,
                wx_title = res.data.data.title,
                wx_content = res.data.data.content,
                wx_img = res.data.data.list[0].newUrl;
              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                });
              }
            }

            this.initSwiper();
            this.isLoading = false;
            if (!this.loaded) {
              this.loaded = true;
            }
            Indicator.close();
          }
        );
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&id_=" +
        this.aid +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background: transparent;
}

.back {
  position: fixed;
  top: 0.4rem;
  left: 0.4rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);
  z-index: 2;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

/* 目录按钮 */
.menu-btn {
  position: fixed;
  top: 1rem;
  right: 1rem;
  outline: none;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.74rem;
  background-color: rgba(64, 160, 255, 0.9);
  color: #fff;
  z-index: 2;
}

/* 目录 */
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}
.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}
.slide-enter-active,
.slide-leave-active {
  transition: 0.3s ease;
}
.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80vw;
  padding: 1rem;
  box-sizing: border-box;
  background-color: #000;
  z-index: 10;
  color: #fff;
}
.menu::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 200vw;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.menu .title {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 1rem;
}

.menu .close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
}

.menu .list {
  margin-top: 1rem;
  width: 100%;
  height: calc(100% - 3rem);
  overflow-y: auto;
}

.menu .marker {
  padding: 0.5rem 0;
  font-size: 0.8rem;
}

/* main */

.main {
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-container >>> .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-container .swiper-pagination {
  color: #fff;
}

.swiper-container >>> .swiper-slide .swiper-zoom-container {
  background: url(../../assets/nihewan/loading.gif) no-repeat center center;
}

.swiper-container >>> .swiper-slide .img {
  width: calc(100% - 2rem);
  max-height: 90%;
}

.company {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  padding-bottom: 1rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 0.74rem;
}
</style>