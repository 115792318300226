<template>
  <div class="applyindex">
    <div id="wrap" class="my-pm-detail">
      <div class="header" id="header">
        <div class="btn close fl" onclick="javascript:history.back(-1);"></div>
        <div class="text">{{userinfo.user_nick}}</div>
        <span
          class="btn_follow fr r-23 followed"
          v-if="userinfo.attention == 0"
          @click="Attention"
        >+关注</span>
        <span
          class="btn_follow fr r-23 followed"
          v-if="userinfo.attention == 1 && userinfo.user_id != user_id"
          @click="Attention"
        >已关注</span>
        <span
          class="btn_follow fr r-23 followed"
          v-if="userinfo.attention == 2"
          @click="Attention"
        >互相关注</span>
      </div>

      <div id="main">
        <ul class="chat-list" v-if="chatList">
          <li v-for="chat in chatList" :key="chat.id" :id="chat.id">
            <div class="date-talk">{{chat.user_ask_time}}</div>
            <div class="me-talk" v-if="chat.flag == 1">
              <router-link class="avatar-talk" :to="{name:'MyIndex'}">
                <div class="avatar">
                  <img :src="chat.user_icon" />
                  <i></i>
                </div>
              </router-link>
              <div class="content-talk content-wb">
                <p>{{chat.content}}</p>
              </div>
            </div>

            <div class="guest-talk" v-if="chat.flag == 0">
              <router-link
                class="avatar-talk"
                :to="{name:'otherIndex',params: { uid: userinfo.user_id }}"
              >
                <div class="avatar">
                  <img :src="chat.user_icon" />
                  <i></i>
                </div>
              </router-link>
              <div class="content-talk content-wb">
                <p>{{chat.content}}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="comment-text">
      <input class="input-text" id="input_text" type="text" placeholder="回复" />
      <button class="btn" id="btn_send" @click="send_msg">发送</button>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      chatList: [],
      user_id: "",
      userinfo: []
    };
  },
  mounted() {
    this.getChat();
  },
  methods: {
    getChat() {
      var that = this;
      var user_ask_id = this.$route.params.uid;
      if (this.check_login()) {
        this.user_id = this.check_login().user_id;
      } else {
        localStorage.setItem("login_url", location.hash);
        this.$router.push({ path: "/my/login", query: { log_type: 1 } });
      }
      var data = {
        user_id: this.user_id,
        user_ask_id: user_ask_id
      };

      this.$ajax("post", "Common/getLetterInfo_new", data, function(res) {
        var chats = res.data.chats;
        if (chats != "") {
          var data = chats.sort(sortJ);
        }
        that.chatList = data;
        that.userinfo = res.data.user_info;
      });

      function sortJ(a, b) {
        return a.id - b.id;
      }

      $("#input_text").focus(function() {
        $("html, body").animate(
          {
            scrollTop: $(".chat-list").height()
          },
          1500
        );
      });
    },
    send_msg() {
      // 第一步，获取input内容：
      var content = $("#input_text").val();
      $("#input_text").val(""); //内容获取以后就清空val
      console.log(content);
      // 第二步，向服务器提交数据：
      if (content == "") {
        Toast("消息不得为空");
      } else {
        var user_ask_id = this.$route.params.uid;
        //                    console.log(user_ask_id);
        var data = {
          ask_user_id: this.user_id,
          response_user_id: user_ask_id,
          content: content
        };
        this.$ajax("post", "Common/sendLetter", data, function(res) {
          var ret = res.data;
          if (ret.id == 0 || ret.id == 1 || ret.id == 2) {
            // 0、1、2 代表插入数据成功
            // 第三步，客户端UI同步显示：
            var tpl = "";
            var date = "刚刚";
            tpl += "<li>";
            tpl += '    <div class="date-talk">' + date + "</div>";
            tpl += '    <div class="me-talk">';
            tpl += '        <a class="avatar-talk" href="../my/index.html">';
            tpl +=
              '            <div class="avatar' +
              my_vol +
              '"><img src="' +
              my_icon +
              '"><i></i></div>';
            tpl += "        </a>";
            tpl +=
              '        <div class="content-talk content-wb"><p>' +
              content +
              "</p></div>";
            tpl += "    </div>";
            tpl += "</li>";
            $(".chat-list").append(tpl);

            window.location.reload();
          }
        });

        $("html, body").animate(
          {
            scrollTop: $(".chat-list").height()
          },
          1500
        );
      }
    },
    Attention() {
      var attention_id = this.$route.params.uid;
      var data = {
        user_id: this.user_id,
        attention_id: attention_id
      };
      this.$ajax("post", "foot/clickAttentionButton", data, function(res) {
        var ret = res.data;
        if (ret.id == 0) {
          var follow;
          if (ret.data.each == 2) {
            //判断是否互相关注，2表示互相关注
            follow = "互相关注";
            Toast("关注成功");
          } else if (ret.data.each == 1) {
            follow = "已关注";
            Toast("关注成功");
          } else {
            follow = "+关注";
            Toast("取消关注成功");
          }

          $(".followed").html(follow);
        } else {
          //操作失败
          Toast("操作失败，请稍后再试！");
        }
      });
    }
  }
};
</script>
<style scoped>
#header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
  background: #fafafa;
  height: 2rem;
  border-bottom: 1px solid #f2f2f2;
}

#header .close {
  padding: 0 1.15rem;
  width: 16px;
  height: 2rem;
  background: url(../../assets/img/login_close.png) no-repeat;
  background-position: left center;
  background-size: 0.6rem;
}

#header .text {
  position: absolute;
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 0.6rem;
  z-index: -1;
}

.header .btn_follow.followed {
  top: 0;
  width: auto;
  padding: 0 0.5rem;
  border: 0;
  color: #999;
}

.btn_follow {
  width: 3.9rem;
  height: 2rem;
  line-height: 2rem;
  border: 1px solid #999;
  border-radius: 0.25rem;
  color: #ff9a80;
  text-align: center;
  font-size: 0.5rem;
  position: absolute;
}

.r-23 {
  right: 10px;
}

#main {
  margin-top: 2rem;
  display: block;
  background: #fff;
}

#main .page-tips {
  font-size: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #999;
  text-align: center;
}

#main .chat-list {
  padding: 0 1.15rem 5rem;
}

#main .chat-list li {
  margin-bottom: 0.5rem;
}

#main .date-talk {
  height: 0.8rem;
  color: #acacac;
  line-height: 0.8rem;
  font-size: 0.5rem;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  display: block;
}

#main .me-talk {
  text-align: right;
}

.guest-talk,
.me-talk {
  min-height: 42px;
  position: relative;
}

.me-talk .avatar-talk {
  right: 0;
  left: auto;
}

.chat-list .me-talk .avatar {
  float: right;
}

.chat-list .guest-talk .avatar {
  float: left;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.me-talk .content-talk {
  color: #fff;
  border: #ff9a80 solid 1px;
  background: #ff9a80;
}

.me-talk .content-talk {
  margin-right: 1rem;
  text-align: left;
}

.me-talk .content-talk::before {
  border-top: #ff9a80 solid 1px;
  border-right: #ff9a80 solid 1px;
  background: #ff9a80;
}

.me-talk .content-talk::before {
  right: -5px;
  -webkit-transform: rotate(45deg) skew(-6deg, -6deg);
  -moz-transform: rotate(45deg) skew(-6deg, -6deg);
  transform: rotate(45deg) skew(-6deg, -6deg);
}

.content-talk::before {
  width: 7px;
  height: 7px;
  content: "";
  position: absolute;
  top: 10px;
}

.content-talk {
  min-height: 1.37em;
  margin-left: 1rem;
  margin-top: 0.6rem;
  padding: 2px;
  font-size: 15px;
  vertical-align: bottom;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  max-width: 70%;
}

.content-wb {
  line-height: 13px;
  font-size: 1rem;
  word-break: break-all;
}

.content-talk p {
  height: 1rem;
  line-height: 1rem;
}

.guest-talk .content-talk {
  color: #5d5d5d;
  border: #dcdcdc solid 1px;
  background: #fff;
}

.guest-talk .content-talk::before {
  border-left: #dcdcdc solid 1px;
  border-top: #dcdcdc solid 1px;
  background: #fff;
}

.guest-talk .content-talk::before {
  left: -5px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.comment-text {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 8px 0;
  width: 100%;
  background-color: #fcfcfc;
  border-top: 1px solid #f2f2f2;
}

.comment-text input {
  padding-left: 2px;
  height: 1.6rem;
  width: 70%;
  margin-left: 10px;
  border-radius: 0.4rem;
  border: 1px solid #f2f2f2;
  background-color: #fff;
  font-size: 0.6rem;
}

.comment-text button {
  float: right;
  margin-right: 10px;
  background-color: #ff9a80;
  height: 1.6rem;
  vertical-align: top;
  width: 18%;
  font-size: 1rem;
  border-radius: 3px;
  color: #fff;
  border: none;
}

.header .btn_follow.unfollow {
  top: 1.2rem;
}
</style>


