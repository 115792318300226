<template>
    <div class="container">
        <!--地图-->
        <div id="map_" class="map">
        
        </div>
        <!--顶部菜单栏-->
        <div class="tmenu">
            <div class="tit">
                <div class="col1">
                    <div class="col1-1">
                        <el-input placeholder="请输入内容" v-model="key" class="input-with-select">
                            <el-select v-model="p_code" slot="prepend" placeholder="请选择" class="i-selected">
                            <el-option :label="item.title" :id="item.id" :value="item.id" v-for="item in province_" :key="item.id"></el-option>
                            </el-select>
                            <el-button slot="append" icon="el-icon-search" @click="btnSearch"></el-button>
                        </el-input>
                    </div>
                    <div class="col1-2" @click="btnClick">
                        <i class="el-icon-arrow-up" v-if="to_top"></i>
                        <i class="el-icon-arrow-down" v-else></i>
                    </div>
                </div>
                <div class="col2" v-if="to_top">
                    <div class="col2-1">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                            <div class="col2-1-2"></div>
                            <el-checkbox-group v-model="checkedCourses" @change="handleCheckedCourseChange">
                                <el-checkbox v-for="it in _course" :label="it.id" :key="it.id" :value="it.id">{{it.title}}</el-checkbox>
                            </el-checkbox-group>
                    </div>
                </div>
            </div>

        </div>
        <!--右上角图层切换-->
        <div class="rmenu">
            <div class="mit">
                <div class="agency">县代</div>
                <el-switch
                v-model="is_show_agency"
                active-color="#ff4892"
                inactive-color="#dcdfe6" @change="agencyChange">
                </el-switch>
            </div>

        </div>
    </div>
</template>

<script>
import { Toast, Indicator } from 'mint-ui'


export default {
    components:{
        
    },
    data(){
        return{
            park_id:3292,
            sUrl:'',
            lng:'',
            lat:'',
            map:'',
            token:'',
            addMarkerUrl:'https://t.zmlxj.com/App/image/markers/label_29_5.png',
            delIcon:'https://t.zmlxj.com/App/image/del_2.png',
            minZoom:16,//地图最小级别
            address:'',
            _park:'',
            _course:'',//合作项目
            _list:'',
            province_:'',
            _agency:'',//两个独家代理
            _area:'',//代理数据点集合
            polygons:[],//已经被占用的区域
            polygonMarkers:[],//独家带来的点位数据
            markers:[],//marker点位集合
            circles:[],//圆形集合
            addMarker:'',

            label_id:0,//选择的标签id
            label_name:'',//标签名称
            title:'',//学校名称
            name:'',
            tel:'',//手机号
            num:'',
            country:'',
            country_code:'zh',
            province:'',
            province_code:'',
            city:'',
            city_code:'',
            district:'',
            district_code:'',


            id_2611:2611,//合作校
            id_2612:2612,//独家代理
            type_:0,//选择的合作方式

            addPolygons:[],//多边形区域


            is_show_agency:true,//是否显示独家代理
            to_top:false,//课程收缩起来
            key:'',
            p_code:'',


            isIndeterminate:true,
            checkAll:false,
            checkedCourses:[],//已经选择的课程


        }
    },
    created(){

    },
    watch:{

    },
    mounted(){
        //初始化当前页面的url
        this.initSUrl();
        if(this.token)
        {
            //已经存在token
        }
        else
        {
            //第一次生成token
            var token = this.make_token();
            this.token = token;
            localStorage.setItem('token',token);
        }

        
       
        //获取定位
        this.locationFn().then(res=>{
            console.log('定位:',res)
        }).catch(err=>{
                console.log('位置信息获取失败')
        });
        
        this.initMap();//初始化地图

        //获取路书信息
        this.getData();

       
        this.$nextTick(() => {
            
        })
    },
    methods:{
        //初始化surl
        initSUrl()
        {
            this.sUrl = window.location.href;
        },
        //获取位置信息异步函数
        locationFn(){
            let that=this;
            return new Promise(function(res,err){
                //获取位置信息失败的回调函数
                function showError(error){

                    switch(error.code) {

                        case error.PERMISSION_DENIED:
                            console.log("定位失败,用户拒绝请求地理定位");
                            //err();
                            break;
                        case error.POSITION_UNAVAILABLE:
                            console.log("定位失败,位置信息是不可用");
                            //err();
                            break;
                        case error.TIMEOUT:
                            console.log("定位失败,请求获取用户位置超时");
                            //err();
                            break;
                        case error.UNKNOWN_ERROR:
                            console.log("定位失败,定位系统失效");
                            //err();
                            break;
                    }

                }

                //获取位置信息成功的回调函数
                function showPosition(position){
                    that.lat = position.coords.latitude;
                    that.lng = position.coords.longitude;
                    
                }

                //获取位置信息
                if (navigator.geolocation){
                    navigator.geolocation.getCurrentPosition(showPosition,showError,{enableHighAccuracy: true});
                }else{
                    console.log("浏览器不支持地理定位。");
                    
                }
            })
        },

        /**
         * 初始化地图
         * 
         */
        initMap()
        {
            var that = this;
            // 地图初始化选项
            let options = {
                zooms: [1, 21],
                expandZoomRange: true,
                zoomEnable:true,
                scrollWheel:true,
                touchZoom:true,
                mapStyle: 'amap://styles/9a9a6dc2a88ef7ac36fb8d1876ee5512'//amap://styles/whitesmoke
            }
            this.map = new AMap.Map('map_', options);

            this.map.on('complete', function(){
                // 地图图块加载完成后触发
            });
            
        },
        /**
         * 给marker点添加弹出框内容
         * 
         */
        createMarkerPopupContent()
        {
            //1:整体盒子
            let div = document.createElement('div');
            div.className = 'popup-body';

            //2：标题+删除图片
            let line1 = document.createElement('div');
            line1.className = 'line1';
            let title = document.createElement('div');
            title.innerHTML = '预签约';
            title.className = 'title';
            line1.appendChild(title);

            let delIcon = document.createElement('img');
            delIcon.className = 'del-icon';
            delIcon.src = this.delIcon;
            line1.appendChild(delIcon);
            var that = this;
            delIcon.onclick = function(){
                //关闭弹框及marker标记
                that.map.remove(that.addMarker);
                that.addMarker.infoWindow.close();
            }

            div.appendChild(line1);
            //3签约地址
            let line3 = document.createElement('div');
            line3.className = 'line3';
            div.appendChild(line3);
            line3.innerHTML = '<b class="txt3">签约地址:</b>'+this.address;
            //选择合作类型：独家代理或者是合作校
            let line9 = document.createElement('div');
            line9.className = 'line2';
            div.appendChild(line9);
            let col9_1 = document.createElement('div');
            col9_1.innerHTML = '合作类型:';
            col9_1.className = 'txt1';
            line9.appendChild(col9_1);

            let col9_2 = document.createElement('div');
            col9_2.className = 'col2-2';
            let select1 = document.createElement('select');
            select1.id = 'agency';
            select1.className = 'course';
            line9.appendChild(col9_2);
            col9_2.appendChild(select1);
            for(let j =0; j < this._agency.length;j++)
            {
                let option1 = document.createElement('option');
                option1.className = 'option-css';
                option1.innerHTML = this._agency[j].title;
                if(this._agency[j].is_selected * 1 == 1)
                {
                    //选择的是合作校
                    if(this._agency[j].id == this.id_2611)
                    {
                        this.type_ = this.id_2611;
                        //对label_id进行赋值
                        this.label_id = this._course ? this._course[0].id : 0;
                        this.label_name = this._course ? this._course[0].title : '';
                    }
                    else
                    {
                        this.type_ = this.id_2612;//县代
                    }
                    option1.selected = 'selected';
                }
                option1.value = j;
               
                select1.appendChild(option1);
            }

            //3:签约项目
            let line2 = document.createElement('div');
            line2.className="line2";
            div.appendChild(line2);
            //学校名称
            let line4 = document.createElement('div');
            div.append(line4);
            let line5 = document.createElement('div');
            div.appendChild(line5);
            line5.className = 'line4';
            let line5_1 = document.createElement('div');
            
            let line6 = document.createElement('div');
            line6.className = 'line4';
            div.appendChild(line6);
            let line6_1 = document.createElement('div');
            
            let line5_2 = document.createElement('input');

            //如果是选择的合作校，才显示下面的项目
            if(this.type_ == this.id_2611)
            {

                line2.style.display="";//显示
                line4.style.display="";//显示
                line5.style.display="";//显示
                line5_1.innerHTML ='校长姓名:<span class="must">(必填)</span>' ;
                line6_1.innerHTML ='校长手机号:<span class="must">(必填)</span>';
                line5_2.placeholder = '请输入校长姓名';
                //设置path_
                this.path_ = '';
            }
            else
            {
                line2.style.display="none";//隐藏
                line4.style.display="none";//隐藏
                line5.style.display="none";//隐藏
                line5_1.innerHTML ='县代姓名:<span class="must">(必填)</span>' ;
                line6_1.innerHTML ='县代手机号:<span class="must">(必填)</span>';
                line5_2.placeholder = '请输入县代姓名';
                //需要获取边界范围
                this.getAreaPath();
            }
            select1.onchange = function(e){
                //console.log('e',e.target.value);
                let index = e.target.value;
                that.type_ = that._agency[index].id;
                if(that.type_ == that.id_2611)
                {
                    //表示选择的是合作校，就需要把label_id label_name的值进行修改
                    that.label_id = that._course[0].id;
                    that.label_name = that._course[0].title;
                }
                else
                {
                    //县代
                    that.label_id = that._agency[1].id;
                    that.label_name = that._agency[1].title;
                }
                
                //如果是选择的合作校，才显示下面的项目
                if(that.type_ == that.id_2611)
                {

                    line2.style.display="";//显示
                    line4.style.display="";//显示
                    line5.style.display="";//显示
                    line5_1.innerHTML ='校长姓名:<span class="must">(必填)</span>' ;
                    line6_1.innerHTML ='校长手机号:<span class="must">(必填)</span>';
                    line5_2.placeholder = '请输入校长的名称';

                    that.path_ = '';
                }
                else
                {
                    line2.style.display="none";//隐藏
                    line4.style.display="none";//隐藏
                    line5.style.display="";//隐藏
                    line5_1.innerHTML ='县代姓名:<span class="must">(必填)</span>' ;
                    line6_1.innerHTML ='县代手机号:<span class="must">(必填)</span>';
                    line5_2.placeholder = '请输入县代姓名';
                    that.getAreaPath();//获取边界
                        
                }
            }

            
            
            let col2_1 = document.createElement('div');
            col2_1.innerHTML ='签约项目:';
            col2_1.className = 'txt1';
            line2.appendChild(col2_1);

            let col2_2 = document.createElement('div');
            col2_2.className="col2-2";

            //添加课程选择
            let select = document.createElement('select');
            select.id = 'course';
            select.className="course";
            line2.appendChild(col2_2);
            col2_2.appendChild(select);
            for(let i =0; i < this._course.length;i++)
            {
                let option = document.createElement('option');
                option.className="option-css";
                option.innerHTML = this._course[i].title;
                if(this._course[i].is_selected * 1 == 1)
                {
                    that.label_id = that._course[i].id;
                    that.label_name = that._course[i].title;
                    option.selected = 'selected';
                }
                option.value = i;
               
                
                select.appendChild(option);
            }
            select.addEventListener('change',function(e){
                //value就是选择的option的值,这个值是选择
                //console.log(e,this.id,this.value,that._course[this.value].title);
                that.label_id = that._course[this.value].id;
                that.label_name = that._course[this.value].title;
            })
            
            // select.onclick= (function(e){
            //     console.log(e);
            // });
            
           
            //学校名称
            line4.className = 'line4';
            let line4_1 = document.createElement('div');
            line4_1.innerHTML = '学校名称:<span class="must">(必填)</span>';
            line4_1.className = 'line4-1';
            line4.appendChild(line4_1);
            let line4_2 = document.createElement('input');
            line4_2.className = 'input-css';
            line4_2.type = 'text';
            line4_2.id="title";
            line4_2.placeholder = '请输入学校的名称';
            line4.appendChild(line4_2);
            
            //在失去焦点的时候，获取数据
            line4_2.onblur = function(e){
                // console.log('blur=',e,e.data,e.value,e.target.value);
                //^[\u4e00-\u9fa5]{0,}$
                let rule = new RegExp('^[\u4e00-\u9fa5]{0,}$');
                if(rule.exec(e.target.value))
                {
                    that.title = e.target.value;

                }
                else
                {
                    Toast('请输入正确的学校名称!');
                }
            }
            

            
            //校长名称
            line5_1.className = 'line4-1';
            line5.appendChild(line5_1);
            
            line5_2.className = 'input-css';
            line5_2.id="name";
            line5_2.type="text";
            
            line5.appendChild(line5_2);
            line5_2.onblur = function(e){
                //^[\u4e00-\u9fa5]{0,}$
                let rule = new RegExp('^[\u4e00-\u9fa5]{0,}$');
                if(rule.exec(e.target.value))
                {
                    that.name = e.target.value;

                }
                else
                {
                    Toast('请输入中文名字!');
                }
            }

            //校长手机号
            line6_1.className = 'line4-1';

            line6.appendChild(line6_1);
            let line6_2 = document.createElement('input');
            line6_2.className = 'input-css';
            line6_2.id="tel";
            line6_2.type="number";
            line6_2.placeholder = '请输入手机号码';
            line6.appendChild(line6_2);
            
            line6_2.onblur = function(e){
                //^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$
                let rule = new RegExp('^1[0-9]{10}$');
                console.log(e.target.value);
                if(rule.exec(e.target.value))
                {
                    that.tel = e.target.value;

                }
                else
                {
                    Toast('手机号码不正确!');
                }
            }

            //业务人员编号
            let line7 = document.createElement('div');
            line7.className = 'line4';
            let line7_1 = document.createElement('div');
            line7_1.innerHTML ='业务员编号:<span class="must">(必填)</span>';
            line7_1.className = 'line4-1';
            line7.appendChild(line7_1);
            let line7_2 = document.createElement('input');
            line7_2.className = 'input-css';
            line7_2.id="num";
            line7_2.type="text";
            line7_2.placeholder = '请输入业务员编号';
            line7.appendChild(line7_2);
            div.appendChild(line7);
            line7_2.onblur = function (e){
                let rule = new RegExp('^[a-zA-Z]{1}[A-Za-z0-9]*$','i');
                if(rule.exec(e.target.value))
                {
                    that.num = e.target.value;

                }
                else
                {
                    Toast('编号不正确!')
                }
            }

            //确定
            let line8 = document.createElement('div');
            line8.className = 'line8';
            let line8_1 = document.createElement('button');
            line8_1.className = 'btn';
            line8_1.innerHTML = '确定';
            line8_1.type="button";
            line8.appendChild(line8_1);
            div.appendChild(line8);
            line8_1.onclick=this.btnAddSubmit;
            
            let bigDiv = document.createElement('div');
            bigDiv.className = 'big';
            bigDiv.appendChild(div);
            let litterDiv = document.createElement('div');
            litterDiv.className = 'litter';
            bigDiv.appendChild(litterDiv); 
            return bigDiv;
        },
        /**
         * 获取边界范围
         */
        getAreaPath()
        {
            var that = this;
            AMap.plugin('AMap.DistrictSearch', function () {
            var districtSearch = new AMap.DistrictSearch({
                // 关键字对应的行政区级别，country表示国家
                level: 'district',
                //  显示下级行政区级数，1表示返回下一级行政区
                /**
                 * 	
            显示下级行政区级数（行政区级别包括：国家、省/直辖市、市、区/县4个级别），商圈为区/县下一级                                  可选值：0、1、2、3

            0：不返回下级行政区

            1：返回下一级行政区

            2：返回下两级行政区

            3：返回下三级行政区
                 */
                subdistrict: 0,
                showbiz:false,
                extensions:'all',
            })
            //需要先把上一次点击行政区域，去掉。然后在新增行政多边形
            if(that.addPolygons)
            {
                that.map.remove(that.addPolygons);
            }
            // 搜索指定行政区域代码的边界
            districtSearch.search(that.district_code, function(status, result) {
                    // 查询成功时，result即为对应的行政区信息status=complete
                    if(status == 'complete')
                    {
                        let bounds = result.districtList[0].boundaries;
                        if(bounds)
                        {
                            //生成行政区划分polygon
                            for(var i =0; i < bounds.length;i++)
                            {
                                let polygon = new AMap.Polygon({
                                    map:that.map,
                                    strokeWeight:1,
                                    path:bounds[i],
                                    fillOpacity:0.5,
                                    fillColor:'#ff4892',
                                    strokeColor:'#ff4892',
                                });
                                that.addPolygons.push(polygon);
                            }
                            
                            //that.map.setFitView();
                        }
                        else
                        {
                            Toast('无边界');
                        }
                    }
                    else
                    {
                        Toast('未获得边界');
                    }
                    console.log(status,result);
                })
            })
        },
        /**
         * 获取路线信息
         * id:
         * token
         * park_id
         * user_id
         * open_id
         */
        getData()
        {
            var that = this;
            this.$ajax('post','ApiSchool/ajax_get_school_data',{
                park_id:this.park_id,
                token:this.token,
               
            },res=>{
                if(res.data.id == 0)
                {
                    //console.log(res);
                    //设置标题
                    document.title = res.data.data.data.park.park_name;
                    this._park = res.data.data.data.park;
                    this._course = res.data.data.data.course;
                    this._list = res.data.data.data.list;
                    this.province_ = res.data.data.data.province;
                    this._agency = res.data.data.data.agency;
                    this._area = res.data.data.data.area;

                    //初始化点位
                    that.initMarker();
                 

                    //初始化行政边界
                    that.initArea();
                    //默认选中县代分类
                    this.label_id = res.data.data.data.agency ? res.data.data.data.agency[1].id : 0;
                    this.label_name = res.data.data.data.agency ? res.data.data.data.agency[1].title : '';
                    this.type_ = this.label_id;


                    that.map.on('click',function(e){
                        console.log('click map');
                            //首先判断当前地图级别，如果小于最小级别，必须先放到
                            let zoom = that.map.getZoom();
                            if(zoom * 1 < that.minZoom)
                            {
                                //需要先放到，并设置
                                that.map.setZoomAndCenter(that.minZoom,e.lnglat);
                            }
                            that.lng = e.lnglat.getLng();
                            that.lat = e.lnglat.getLat();
                            // 这里通过高德 SDK 完成。
                            var geocoder = new AMap.Geocoder({
                                radius: 1000,
                                extensions: "all"
                            });
                            geocoder.getAddress(e.lnglat, function (status, result) {
                                if (status === 'complete' && result.info === 'OK') {
                                    if (result && result.regeocode) {
                                        console.log('地址解析:',result);
                                        that.city_code = result.regeocode.addressComponent.citycode;
                                        that.city = result.regeocode.addressComponent.city;
                                        that.country = result.regeocode.addressComponent.country;
                                        that.district = result.regeocode.addressComponent.district;
                                        that.district_code = result.regeocode.addressComponent.adcode;
                                        that.province = result.regeocode.addressComponent.province;
                                        that.province_code = result.regeocode.addressComponent.adcode ? result.regeocode.addressComponent.adcode.substring(0,2) : '';

                                        that.address = result.regeocode.formattedAddress; 
                                        // 创建 AMap.Icon 实例：
                                        var icon = new AMap.Icon({
                                            size: new AMap.Size(40, 40),    // 图标尺寸
                                            image: that.addMarkerUrl,  // Icon的图像
                                            imageOffset: new AMap.Pixel(0, 0),  // 图像相对展示区域的偏移量，适于雪碧图等
                                            imageSize: new AMap.Size(40, 40)   // 根据所设置的大小拉伸或压缩图片
                                        });
                                        let content = that.createMarkerPopupContent();
                                        if(that.addMarker)
                                        {
                                            //先移除marker
                                            that.map.remove(that.addMarker);
                                        }
                                        //增加marker点
                                        that.addMarker = new AMap.Marker({
                                            map:that.map,
                                            //需要添加弹框，让用户输入鹿茸
                                            //content:content,     
                                            position:e.lnglat,
                                            achor:'bottom-center',
                                            offset:new AMap.Pixel(0,0),
                                            icon:icon
                                        });

                                        that.addMarker.infoWindow = new AMap.InfoWindow({
                                            isCustom: true, // 使用自定义窗体
                                            content: content, // 自定义窗体的信息
                                            autoMove: true, // 移动屏幕以显示信息窗体
                                            closeWhenClickMap: true, // 点击地图时自动关闭信息窗体
                                            anchor: 'bottom-center',
                                            offset: new AMap.Pixel(18, 10),
                                            showShadow: true
                                        });
                                        that.addMarker.infoWindow.open(that.map, e.lnglat);
                                        
                                        
                                    }
                                    else
                                    {
                                        console.log('地址解析失败'+result);
                                    }
                                }
                                else
                                {
                                    console.log('解析未完成');
                                }
                            });
                            
                            
                        });
                }
                else
                {
                    console.log('网络请求失败');
                }
            },'web');

            console.log(this);
        },

        /***
         * 初始化行政边界，通过返回的district_code区县代码进行逆地址查询
         * 
         */
        initArea(){
            if(this.polygons)
            {
                this.map.remove(this.polygons);
                this.map.remove(this.polygonMarkers);
            }
            let list = this._area;
            if(list)
            {
                var districtSearch = new AMap.DistrictSearch({
                    // 关键字对应的行政区级别，country表示国家
                    level: 'district',
                    	
                
                    subdistrict: 0,
                    showbiz:false,
                    extensions:'all',
                });
                var that = this;
               
                for(var i = 0 ; i < list.length;i++)
                {
                    if(list[i].district != '0')
                    {
                                //表示存在行政区代码，就可以直接获取边界
                                districtSearch.search(list[i].district_code, function(status, result) {
                                    // 查询成功时，result即为对应的行政区信息status=complete
                                    if(status == 'complete')
                                    {
                                        let bounds = result.districtList[0].boundaries;
                                        if(bounds)
                                        {
                                            //生成行政区划分polygon
                                            for(var k =0; k < bounds.length;k++)
                                            {
                                                let polygon = new AMap.Polygon({
                                                    map:that.map,
                                                    strokeWeight:1,
                                                    path:bounds[k],
                                                    fillOpacity:0.5,
                                                    fillColor:'#ff4892',
                                                    strokeColor:'#ff4892',
                                                });
                                                that.polygons.push(polygon);
                                            }
                                            
                                            //that.map.setFitView();
                                        }
                                        else
                                        {
                                            console.log('无边界');
                                        }
                                    }
                                    else
                                    {
                                        console.log('未获得边界');
                                    }
                                    //console.log('area=',status,result);
                                })
                    
                    }

                    //先创建icon
                    var icon = new AMap.Icon({
                        size:new AMap.Size(40,40),
                        image:list[i].markerUrl,
                        imageOffset:new AMap.Pixel(0,0),
                        imageSize:new AMap.Size(40,40),
                    });
                    list[i].index = i;
                    let content = this.createMarkerContent(list[i]);
                    let marker = new AMap.Marker({
                        map:this.map,
                        //content:content,
                        position:new AMap.LngLat(list[i].lng * 1,list[i].lat * 1),
                        achor:'bottom-center',
                        offset:new AMap.Pixel(-20,-35),
                        icon:icon,
                        extData:list[i],
                        zIndex:2,
                    });
                    marker.infoWindow = new AMap.InfoWindow({
                        isCustom: true, // 使用自定义窗体
                        content: content, // 自定义窗的信息
                        autoMove: true, // 移动屏幕以示信息窗体
                        closeWhenClickMap: true, // 击地图时自动关闭信息窗体
                        anchor: 'bottom-center',
                        offset: new AMap.Pixel(0, -25),
                        showShadow: true
                    });
                    marker.on('click', ()=>{
                        let obj = marker.getExtData();
                        marker.infoWindow.open(that.map,new AMap.LngLat(obj.lng * 1,obj.lat * 1))
                    });
                    this.polygonMarkers.push(marker);
                }
                 
                        
            }
        },
                /**
                 * 初始化marker
                 */
                initMarker()
                {
                    if(this.markers)
                    {
                        this.map.remove(this.markers);
                        this.map.remove(this.circles);
                    }
                    var that = this;
                    var list = this._list;
                    if(list)
                    {
                        for(var i =0; i < list.length;i++)
                        {
                            //先创建icon
                            var icon = new AMap.Icon({
                                size:new AMap.Size(40,40),
                                image:list[i].markerUrl,
                                imageOffset:new AMap.Pixel(0,0),
                                imageSize:new AMap.Size(40,40),
                            });
                            list[i].index = i;
                            let content = this.createMarkerContent(list[i]);
                            let marker = new AMap.Marker({
                                map:this.map,
                                //content:content,
                                position:new AMap.LngLat(list[i].lng * 1,list[i].lat * 1),
                                achor:'bottom-center',
                                offset:new AMap.Pixel(-20,-35),
                                icon:icon,
                                extData:list[i],
                                zIndex:2,
                            });
                            marker.infoWindow = new AMap.InfoWindow({
                                isCustom: true, // 使用自定义窗体
                                content: content, // 自定义窗的信息
                                autoMove: true, // 移动屏幕以示信息窗体
                                closeWhenClickMap: true, // 击地图时自动关闭信息窗体
                                anchor: 'bottom-center',
                                offset: new AMap.Pixel(0, -25),
                                showShadow: true
                            });
                            marker.on('click', ()=>{
                                let obj = marker.getExtData();
                                marker.infoWindow.open(that.map,new AMap.LngLat(obj.lng * 1,obj.lat * 1))
                            });
                            this.markers.push(marker);
                            //绘制圆
                            var circle = new AMap.Circle({
                                center: new AMap.LngLat(list[i].lng * 1,list[i].lat * 1),  // 圆心位置
                                radius: list[i].radius * 1, // 圆半径
                                fillColor: '#00FFFF',   // 圆形填充颜色
                                strokeColor: '#00FFFF', // 描边颜色
                                fillOpacity:0.5,
                                strokeWeight: 2, // 描边宽度
                                map:this.map,
                                zIndex:2,

                            });

                            this.circles.push(circle);
                        }
                    }
                    //console.log('_marker=',this.markers);
                },
                
                /**
                 * 点击marker点，弹出的infoWindow信息
                 */
                createMarkerContent(obj)
                {
                    var that = this;
                    //最外层div
                    let div = document.createElement('div');
                    div.className = 'item';

                    //删除按钮
                    let img = document.createElement('img');
                    img.className = 'del-img';
                    img.src=this.delIcon;
                    div.appendChild(img);
                    img.onclick = function(){
                        console.log(obj.index)
                        if(obj.type_ == that.id_2612 || obj.type_ == that.id_2611)
                        {
                            //代理
                            that.polygonMarkers[obj.index].infoWindow.close();
                        }
                        else
                        {
                            that.markers[obj.index].infoWindow.close();
                        }
                        
                     
                        
                    }

                    //显示当前是第多少家加盟校
                    let line1 = document.createElement('div');
                    line1.innerHTML = '第 <b class="bf">'+obj.snum+'</b> '+(obj.type_ == this.id_2612 ? ' 独家代理' : ' 家加盟校');
                    line1.className = 'it';
                    div.appendChild(line1);

                    //地址
                    let line2 = document.createElement('div');
                    line2.innerHTML = obj.address;
                    line2.className = 'it';
                    div.appendChild(line2);


                    let line3 = document.createElement('div');
                    line3.innerHTML = '签约项目:'+obj.notes+'['+obj.days+']';
                    line3.className = 'it';
                    div.appendChild(line3);

                    let dire = document.createElement('div');
                    dire.className = 'lit';

                    let mDiv = document.createElement('div');
                    mDiv.className = 'mDiv';
                    mDiv.appendChild(div);
                    mDiv.appendChild(dire);
                    return mDiv;
                },
                /**
                 * 点击确定按钮，提交合作项目数据
                 * 编号：^[a-zA-Z]{1}
                 */
                btnAddSubmit(){
                    var that = this;
                    if(this.label_id == this.id_2612)
                    {
                        //表示县代，判断县代姓名、手机号、
                        if(this.name)
                        {

                        }
                        else
                        {
                            Toast('请输入县代姓名!');
                            return true;

                        }
                    }
                    else
                    {
                        //表示合作校，判断学校名称、校长名称、手机号
                        if(this.title)
                        {

                        }
                        else
                        {
                            Toast('请输入学校名称!');
                            return true;
                        }

                        if(this.name)
                        {

                        }
                        else
                        {
                            Toast('请输入校长姓名!');
                            return true;
                        }
                    }
                    if(this.tel)
                    {

                    }
                    else
                    {
                        Toast('请输入手机号码!');
                        return true;

                    }
                    if(this.num)
                    {

                    }
                    else
                    {
                        Toast('请输入业务员编号!');
                        return true;
                    }
                    this.$ajax('post','ApiSchool/ajax_add_park_spots_data',{
                        label_id:this.label_id,
                        label_name:this.label_name,
                        title:this.title,
                        name:this.name,
                        tel:this.tel,
                        num:this.num,
                        lng:this.lng,
                        lat:this.lat,
                        park_id:this.park_id,
                        country:this.country,
                        country_code:this.country_code,
                        province:this.province,
                        province_code:this.province_code,
                        city:this.city,
                        city_code:this.city_code,
                        district:this.district,
                        district_code:this.district_code,
                        address:this.address,
                        path_:this.path_,
                        height:0,
                        token:this.token,
                    },res=>{
                        if(res.data.id == 0)
                        {
                            //console.log(res);
                            //提交数据成功之后，需要在本地进行处理绘制
                            if(that.label_id == that.id_2612)
                            {
                                //区域代理
                                if(that._area)
                                {
                                    that._area.push(res.data.data.data);
                                }
                                else
                                {
                                    that._area = [res.data.data.data];
                                }
                                //绘制区域
                                that.initArea();
                                that.map.remove(that.addPolygons);
                            }
                            //加盟校
                            if(that._list)
                            {
                                that._list.push(res.data.data.data);
                            }
                            else
                            {
                                that._list = [res.data.data.data];
                            }
                            that.initMarker();
                            that.map.remove(that.addMarker);
                            that.addMarker.infoWindow.close();
                        }
                        else
                        {
                            Toast('写入失败!');
                        }
                    },'app');
                },

                /**
                 * 是否显示独家代理
                 * 
                 */
                agencyChange:function(e){
                    if(e)
                    {
                        this.map.add(this.polygons);
                        this.map.add(this.polygonMarkers);
                    }
                    else
                    {
                        //关闭独家县代
                        this.map.remove(this.polygons);
                        this.map.remove(this.polygonMarkers);
                    }
                    
                },
                /**
                 * 地址搜索
                 */
                btnSearch:function(){
                    console.log(this.key)
                },
                /**
                 * 点击显示课程目录
                 */
                btnClick(){
                    //console.log(1)
                    this.to_top = !this.to_top;
                },
                handleCheckAllChange(e){
                   let arr = [];
                   for(var i =0; i < this._course.length;i++)
                   {
                       arr.push(this._course[i].id);
                   }
                    this.checkedCourses = e ? arr : [];
                    this.isIndeterminate = false;

                },
                //选某一些课程
                handleCheckedCourseChange:function(e)
                {
                    console.log(e);
                }
                
        

            }
        }
</script>

<style scoped>
    .container{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .container .map{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
    }

@media screen and (max-width: 960px){
	 /* 手机端CSS代码 */
     .map >>> .item{
         width: 17rem;
     }
     .col1-1 >>> .el-input-group__prepend{
         width: 1.5rem;
     }
     .rmenu{
         top: 4rem;
     }
}

@media screen and (min-width: 960px){
	  /* 电脑端CSS代码 */
      .map >>> .item {
          width: 12rem;
      }
      .col1-1 >>> .el-input-group__prepend{
          width: 3rem;
      }
      .rmenu{
          top: 2rem;
      }
  }
    /********预签约****************** */
    .map >>> .big{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .map >>> .big .popup-body{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 10px;
        flex-wrap: wrap;
        padding: 10px;
    }
    .map >>> .big  .popup-body .line1{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        margin-bottom: 10px;
    } 
    .map >>>  .big .popup-body .line1 .title{
        display: flex;
        width: 85%;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        padding: 5px 2px 5px 2px;
        font-weight: bold;
    }
    .map >>>  .big .popup-body .line1 .del-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
    }
    .map >>>  .big .popup-body .line2 {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        padding: 4px 0px 4px 0px;
    }
    .map >>>  .big .popup-body .line2 .txt1{
        display: flex;
        width: 25%;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
    }
    .map >>> .big  .popup-body .line2 .col2-2{
        display: flex;
        width: 75%;
        justify-content: center;
        align-items: center;
    }
    .map >>>  .big .popup-body .line2 .col2-2 .course{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 35px;
        border: 1px solid #cdcdcd;
    }
    .map >>> .big  .popup-body .line2 .col2-2 .course .option-css{
        height: 40px;
    }
    .map >>>  .big .popup-body .line3{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        padding: 4px 0px 4px 0px;
    }
    .map >>>  .big .popup-body .line3 .txt3{
        font-size: 14px;
        font-weight: bold;
        width: 30%;
    }
    .map >>> .big  .popup-body .line4{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 4px 0px 4px 0px;
    }
    .map >>>  .big .popup-body .line4 .line4-1 {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        padding: 4px 0px 4px 0px;
    }
    .map >>> .big  .popup-body .line4 .line4-1 .must{
        color: #ff4892;
    }
    .map >>> .big  .popup-body .line4 .input-css{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
            height: 35px;
    border: 1px solid #cdcdcd;
    border-radius: 2px;
    }

    .map >>>  .big .popup-body .line8{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .map >>> .big  .popup-body .line8 .btn{
        display: flex;
        width: 40%;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 2px;
            background-color: #ff4892;
    color: white;
    font-size: 14px;
    margin-top: 10px;
    }

    .map >>> .big .litter{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid #ffffff;
    }

    /**********点击marker之后，弹出***************/
    .map >>> .mDiv{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .map >>> .item{
        display: flex;
       
        justify-content: start;
        align-items: center;
        background-color: white;
        padding: 15px;
        flex-wrap: wrap;
    }

    .map >>> .item .it{
        display: flex;
        width: 100%;
        justify-content: start;
        align-items: center;
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        padding: 5px 0px 5px 0px;
        text-overflow: ellipsis;
    }
    .map >>> .item .it .bf{
        font-size: 1.2rem;
    }

    .map >>> .item .del-img{

        display: flex;
        position: absolute;
        
        justify-content: flex-end;
      
        align-items: flex-start;
        width: 40px;
        height: 40px;
        right: 0px;
        top: 0px;
    }
    
    .map >>> .lit{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 20px solid #ffffff;
    }
    /************switch**************/
     .rmenu{
        display: flex;
        justify-content: end;
        align-items: flex-start;
        position: fixed;
       
        right: 1rem;
        background-color: white;

        width: 2.5rem;
    height: 2.2rem;
    border-radius: 0.2rem;
    }
     .rmenu .mit{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

     .rmenu .mit .agency{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        color: #ff4892;
    }

    .tmenu {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        background-color: white;
        position: fixed;
        top: 0px;
        padding: 2px 0px 2px 0px;
    }

    .tmenu .tit{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .tmenu .tit .col1{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
    }
    .tmenu .tit .col1 .col1-1{
        display: flex;
        max-width: 90%;
        justify-content: center;
        align-items: center;
        padding: 4px 0px 4px 0px;
    }
    .tmenu .tit .col1 .col1-1 .input-with-select{
        width: 100%;
    }
    
  .col1-1 >>> .el-input-group__prepend{
            background-color: #ff4892;
            color: #ffffff;
            vertical-align: middle;
            display: table-cell;
            position: relative;
            border: 1px solid #ff4892;
            padding: 0 20px;
            white-space: nowrap;
    }
   .col1-1 >>> .el-input-group__append{
       background-color: #ff4892;
    color: #ffffff;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    border: 1px solid #ff4892;
    padding: 0 20px;
    white-space: nowrap;
   }

  .col1-1 >>> .el-input__inner{
      border:1px solid #ff4892;
      height: 30px;
  }
  .col1-1 >>> .el-input__icon{
      line-height: 30px;
  }
  
  .col1-1 >>> .el-select .el-input .el-select__caret{
      color: #ffffff;
  }

    .tmenu .tit .col1  .col1-2{
        display: flex;
        width: 10%;
        justify-content: center;
        align-items: center;
            margin-left: 0.5rem;
    }
    .tmenu .tit .col1  .col1-2 >>> i{
        font-size: 25px;
        color: #ff4892;
        font-weight: bold;
    }


    .tmenu .tit .col2 {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .tmenu .tit .col2 .col2-1 {
    }

    .tmenu .tit .col2 .col2-1 .col2-1-2{
        margin: 15px 0;
    }
    .tmenu .tit .col2 .col2-1   >>> .el-checkbox__input.is-indeterminate .el-checkbox__inner
    {
        border-color: #ff4892;
        background-color: #ff4892;
    }
    .tmenu .tit .col2 .col2-1   >>> .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
        border-color: #ff4892;
        background-color: #ff4892;
    }
    .tmenu .tit .col2 .col2-1   >>> .el-checkbox__label{
        color:#ff4892
    }

    

</style>