<template>
  <div class="container" v-show="loaded" v-title data-title="泥河湾电子书阅读">
    <i class="back iconfont iconmenu2" @click="goBack"></i>
    <img src="../../assets/nihewan/books_bg.jpg" class="bg" />
    <main class="main">
      <ul class="list">
        <li class="item" v-for="(item, i) in booksList" :key="i" @click="navToBook(item.id)">
          <img :src="item.bgUrl" class="img" />
        </li>
      </ul>
    </main>

    <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator } from "mint-ui";
import TechnicalSupport from "../../components/TechnicalSupport";
export default {
  components: {
    "technical-support": TechnicalSupport
  },
  data() {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanBooksList",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      // 获取数据
      booksList: [],

      // 自定义
      page: 1,
      haveMore: true,
      isLoading: false, // 正在加载
      loaded: false, // 初次加载完成

      // company
      companyInfo: {},
      haveCompany: false
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
    }

    this.getData();
    this.getCompanyInfo(this.id, this.token).then(
      res => {
        (this.companyInfo = res); (this.haveCompany = true);
      },
      err => {
        console.log(err);
      }
    );
    this.$nextTick(() => {
      //在冒泡阶段处理滚动的事件
      window.addEventListener("scroll", this.throttle(this.handleScroll));
    });
  },
  methods: {
    // 电子书
    navToBook(id) {
      this.$router.push({
        path: "/nihewan/book",
        query: {
          id: this.id,
          aid: id
        }
      });
    },
    // 上一级
    goBack() {
      this.$router.push({
        path: "/nihewan/home",
        query: {
          id: this.id
        }
      });
    },
    // 获取数据
    getData() {
      if (!this.haveMore || this.isLoading) {
        return false;
      }
      this.isLoading = true;
      Indicator.open("加载中...");
      this.$ajax(
        "post",
        "Label/get_science_data",
        {
          park_id: this.park_id,
          id_: this.id,
          id_type_: this.type_,
          where_: "books", // spots, history, books, video, question, activity
          page: this.page,
          token: this.token,
          version: this.version
        },
        res => {
          if (res.data.id == 0) {
            if (this.page === 1) {
              this.booksList.push(...res.data.data.books_list);
            } else {
              this.booksList.push(...res.data.data.list);
            }
            this.page++;

            const sUrl = window.location.href,
              wx_title = res.data.data.books_list[0].title,
              wx_content = res.data.data.books_list[0].shareContent,
              wx_img = res.data.data.books_list[0].bgUrl;
            if (this.is_weixin) {
              this.wx_share_callback(sUrl, {
                url: sUrl,
                id_: this.id,
                id_type_: this.backUrl,
                token: this.token,
                open_id: this.open_id,
                title: wx_title,
                content: wx_content,
                img: wx_img
              });
            }
          }
          if (
            res.data.id !== 0 ||
            (this.page === 1 &&
              (!res.data.data.books_list ||
                res.data.data.books_list.length < 10)) ||
            (this.page > 1 &&
              (!res.data.data.list || res.data.data.list.length < 10))
          ) {
            this.haveMore = false;
          }
          this.isLoading = false;
          if (!this.loaded) {
            this.loaded = true;
          }
          Indicator.close();
        }
      );
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    },
    //获取页面滚动的高度
    handleScroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;

      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;

      // 加载更多
      if (
        scrollTop + windowHeight > scrollHeight - 100 &&
        this.haveMore &&
        this.page > 1
      ) {
        // 滚动到底部，有更多，并且不是第一页
        this.getData();
      }
    },
    throttle(fn, interval = 100) {
      let canRun = true;
      return function() {
        if (!canRun) return;
        canRun = false;
        setTimeout(() => {
          fn.apply(this, arguments);
          canRun = true;
        }, interval);
      };
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background: transparent;
}

.back {
  position: fixed;
  top: 0.4rem;
  left: 0.4rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);
  z-index: 2;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.main {
  width: 100%;
  padding: 2.8rem 1rem 1rem;
  box-sizing: border-box;
  z-index: 1;
}

.list {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}

.list > .item {
  margin-bottom: 1rem;
  width: calc(50% - 0.5rem);
}

.list > .item:nth-child(2n + 1) {
  margin-right: 1rem;
}

.list > .item > .img {
  display: block;
  width: 100%;
  box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.8);
}


.company {
  position: relative;
  width: 100%;
  padding-bottom: 1rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 0.74rem;
}
</style>