<template>
  <div v-title data-title="红包提现">
    <!-- 头部 -->
    <mt-header fixed title="红包提现" v-if="header">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
      <router-link :to="{name:'GetMoneyRecord'}" slot="right">
        <mt-button>记录</mt-button>
      </router-link>
    </mt-header>

    <div class="page-content" :style="style">
      <mt-field label="支付宝" placeholder="请输入支付宝账号" v-model="payId"></mt-field>
      <mt-field label="金额" placeholder="请输入提现金额" v-model="money" type="number"></mt-field>
      <div class="my_balance red" v-if="parseInt(money) > parseInt(balance)">输入金额超过当前余额</div>
      <div class="my_balance" v-else>当前余额 {{balance}}</div>
      <mt-button
        type="danger"
        class="get_money_btn"
        :disabled="disabled"
        @click.native="postForm"
      >提交</mt-button>
    </div>
  </div>
</template>

<script>
import { Toast, MessageBox } from "mint-ui";
export default {
  data() {
    return {
      userId: "",
      header: true,
      style: "",
      payType: 1,
      payId: "",
      money: "",
      disabled: true,
      balance: this.$route.query.money
    };
  },

  watch: {
    payId() {
      this.checkForm();
    },

    money() {
      this.checkForm();
    }
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    checkLogin() {
      // 用户ID
      if (this.hybrid.hybrid.versions.myapp) {
        // FOR 安卓最美旅行家APP
        this.header = false;
        this.style = "padding:0";
        this.userId = JavaScriptInterface.getUserId();
        this.get_accounts();
      } else if (this.hybrid.hybrid.versions.myiOSapp) {
        // FOR IOS最美旅行家APP
        this.header = false;
        this.style = "padding:0";
        this.hybrid.hybrid.registerHandler(
          "getIOSUserId",
          (data, responseCallback) => {
            this.userId = data.user_id;
            this.get_accounts();
            var responseData = "已经收到IOS传来的userid";
            responseCallback(responseData);
          }
        );
      } else {
        // For web
        this.userId = this.get_User_Id();
        this.get_accounts();
      }
    },
    get_accounts() {
      this.$ajax(
        "post",
        "index/get_user_accounts",
        {
          user_id: this.userId
        },
        res => {
          if (res.data.id == 0) {
            this.payId = res.data.data;
          }
        }
      );
    },
    checkForm() {
      if (this.payId == "" || this.money == "") {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },

    postForm() {
      this.$ajax(
        "post",
        "foot/give_money",
        {
          type: this.payType,
          accounts: this.payId,
          user_id: this.userId,
          money: this.money
        },
        res => {
          console.log(res);
          if (res.data.id == 0) {
            this.$router.push({
              name: "GetMoneySuccess",
              query: { money: this.money }
            });
          } else {
            Toast("提现失败");
          }
        }
      );
    }
  }
};
</script>

<style scoped>
.page-content {
  padding-bottom: 0;
}

.get_money_btn {
  margin: 20px auto;
  display: block;
  width: 50%;
}

.my_balance {
  font-size: 14px;
  margin: 5px 0 0 10px;
}

.my_balance.red {
  color: red;
}
</style>