<template>
  <div class="dm-body" v-title :data-title="title">
    <div class="dm-contain">
      <div id="gd_map" style="width:100%; height:100%"></div>

      <ul class="map_info_tip" v-if="type_ == 'line'">
        <li>
          <p>总里程</p>
          <p>{{line_info.length}}</p>
        </li>
        <li>
          <p>最高海拔</p>
          <p>{{line_info.height}}</p>
        </li>
        <li>
          <p>最低海拔</p>
          <p>{{line_info.low}}</p>
        </li>
        <li>
          <p>徒步时间</p>
          <p>{{line_info.time_length}}</p>
        </li>
      </ul>
    </div>
    <div class="left_but" v-if="is_big && is_show_spots_list == 0">
      <div class="mak_tab">
        <!--点/图片 切换-->
        <img
          src="../../assets/img/map_park_tab_img.png"
          alt
          @click="tab_button"
          v-if="is_tab_button  && is_show_tab_img "
        />
        <img
          src="../../assets/img/map_park_tab_img_maks.png"
          alt
          @click="tab_button"
          v-if="!is_tab_button"
        />
        <img
          src="../../assets/img/map_3d.png"
          alt
          class="map_3d"
          @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
          v-if="is_tab_map_3d "
        />
        <img
          src="../../assets/img/map_3d_yes.png"
          alt
          class="map_3d"
          @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
          v-if="!is_tab_map_3d  "
        />
      </div>
      <div class="mak_tab">
        <!--地图 + -  -->
        <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
        <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
      </div>
      <div class="mak_tab map_my_location">
        <!--地图定位- -->
        <img src="../../assets/img/map_my_location.png" alt @click="click_Geo_location()" />
      </div>
    </div>

    <!--显示景点列表-->
    <div class="gd-right-but" v-if="is_have_spots_list && is_show_spots_list == 0">
      <div
        class="gd-r-item"
        v-for="(k,index) in menu_list"
        @click="ClickMenuItem(k,index)"
        :key="index"
      >
        <img :src="k.icon_selected_url" v-if="k.is_selected == 1" />
        <img :src="k.icon_normal_url" v-else />
      </div>
    </div>
    <div class="content_list" v-if="is_show_spots_list">
      <div class="park_search">
        <p>共{{snum}}个{{mtitle}}</p>

        <img @click="is_show_spots_list = 0" src="../../assets/img/del.png" alt />
        <div class="input_search clearfix">
          <input class="fl" type="text" placeholder="请输入关键字" v-model="stitle" />
          <button class="fl" @click="searchData(stitle,id_)">确定</button>
        </div>
        <ul v-if="menuType === 'point'">
          <li
            :id="k.id"
            v-for="k in title_list"
            :key="k.id"
            @click="ClickItem(k, 'point')"
          >{{k.title}}</li>
        </ul>
        <ul v-if="menuType === 'line'">
          <li
            :id="k.id"
            v-for="k in line_list"
            :key="k.id"
            @click="ClickItem(k, 'line')"
          >{{k.title}}</li>
        </ul>
      </div>
    </div>
    <!--底部菜单,只有2个景点列表和路线-->
    <div class="foot-menu" v-if="is_show_spots_list == 0">
      <ul class="dm-fm-ul">
        <li class="dm-fm-ul-li border_right" @click="ClickLiItem(0)">
          <i class="spots iconfont"></i>
          景点列表
        </li>
        <li class="dm-fm-ul-li" @click="ClickLiItem('line')">
          <i class="line iconfont"></i>
          特色路线
        </li>
      </ul>
    </div>
    <technical-support :ts_type_="ts_type_" contact_side="left" :id="label_id"></technical-support>
  </div>
</template>

<script>
import { Toast } from 'mint-ui'
import TechnicalSupport from '../../components/TechnicalSupport'
export default {
  components: {
    'technical-support': TechnicalSupport
  },
  data () {
    return {
      ts_type_: 'label',
      park_id: 298,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      label_id: this.$route.query.id ? this.$route.query.id : 0,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      list: '',
      point_list: '',
      markers: [],
      map: {}, // 地图的信息
      map_: '',
      union_id: localStorage.getItem('union_id')
        ? localStorage.getItem('union_id')
        : '',
      gdMapIconType: 'icon', // 在地图上显示的样式：image or icon
      price: 0, // 需要购买的金额
      is_pay: 0, // 是否支付
      is_big: 1,
      is_tab_button: 1,
      is_show_tab_img: 1,
      is_tab_map_3d: 1,
      currentLng: '',
      currentLat: '',
      is_have_spots_list: 0,
      is_show_spots_list: '',
      title: '',
      menu_list: '', // 菜单
      line_list: [],
      mtitle: '景点',
      id_: 0, // 选择菜单ID
      type_: '', // 选择菜单的类型
      menuType: '',
      stitle: '', // 搜索的关键字
      snum: 0, // 找到的个数
      title_list: '', // 弹出的列表
      line_info: '', // 路线的数据
      currentUrl: window.location.href,
      backUrl: 'NoDrawMap',
      cluster: '', // 高德聚合
      is_show_tel_me: 0,
      tel_: '', // 客服电话
      is_show_input: 0,
      input_tel: '', // 客户留下的电话
      input_content: '' // 客户留下的语言
    }
  },
  mounted () {
    if (this.token) {
      // 已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }
    if (
      this.union_id &&
      !(
        !this.union_id &&
        typeof this.union_id !== 'undefined' &&
        this.union_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有union_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.union_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.union_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.union_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }
    // 加载服务器数据
    this.LoadData()
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
      this.wx_share(window.location.href)
    }
  },
  created () {},
  methods: {
    LoadData () {
      var that_ = this
      this.$ajax(
        'post',
        'SpotsRace/get_map_data_by_label',
        {
          park_id: this.park_id,
          id: this.label_id,
          token: this.token,
          is_type: 2, // 表示不增加type_fk_id = 20手绘地图的查询条件
          version: this.version,
          open_id: this.union_id,
          where: 'NoDrawMap'
        },
        ret => {
          if (ret.data.id == 0) {
            this.map_ = ret.data.data.map_data
            this.price = ret.data.data.price
            this.is_pay = ret.data.data.is_pay
            this.gdMapIconType = ret.data.data.gdMapIconType
            if (ret.data.data.gdMapIconType == 'image') {
              this.is_tab_button = 1
            } else {
              this.is_tab_button = 0
            }

            this.map = this.gdMapInit({
              map: '',
              map_: this.map_,
              cluster_type_: 0
            })
            this.list = ret.data.data.point_list
            this.tel_ = ret.data.data.tel_
            this.point_list = ret.data.data.point_list
            this.title = ret.data.data.map_data.label_name
            this.menu_list = ret.data.data.bottom_menu_list
            this.title_list = ret.data.data.title_list
            this.line_list = ret.data.data.line_list
            if (ret.data.data.point_list) {
              this.is_have_spots_list = 1
            } else {
              this.is_have_spots_list = 0
            }
            document.title = ret.data.data.map_data.label_name
            // 微信分享
            localStorage.setItem('wx_content', ret.data.data.map_data.content)
            localStorage.setItem('wx_title', ret.data.data.map_data.label_name)
            localStorage.setItem('wx_img', ret.data.data.map_data.map_)
            // 初始化所有的点
            if (ret.data.data.point_list.length > 0) {
              this.gdMapAddMarker({
                map: this.map,
                // 这里面可以增加自己的业务参数
                gdMapIconType: this.gdMapIconType,
                is_pay: this.is_pay,
                price: this.price,
                open_id: this.union_id,
                token: this.token,
                label_id: this.label_id,
                park_id: this.park_id,
                version: this.version,
                is_weixin: this.is_weixin,
                backUrl: 'NoDrawMap',
                currentUrl: window.location.href,
                point_list: ret.data.data.point_list,
                type_: 0, // 显示全部,路线是line,其他类型是数字
                callback: function (ret_) {
                  that_.markers = ret_.markers
                  that_.cluster = ret_.cluster
                  // console.log(ret_);
                  // 所有的点加载完成之后的回调函数,这是Marker
                }
              })
            } else {
              Toast('暂无数据')
            }
          } else {
            Toast('无数据')
          }
        }
      )
    },
    tab_button () {
      // 切换图片和定位标记
      var that = this
      this.map.setZoom(15)
      this.is_tab_button = !this.is_tab_button
      if (this.gdMapIconType == 'icon') {
        this.gdMapIconType = 'image'
      } else {
        this.gdMapIconType = 'icon'
      }
      this.gdMapCutMarker(this.map, this.markers, this.gdMapIconType)
    },
    // 点击地图定位我的位置
    click_Geo_location () {
      var that = this
      that.map_Geo_location({
        map: this.map,
        callback: function (lng, lat) {
          that.currentLng = lng
          that.currentLat = lat
        }
      })
    },
    // 点击菜单
    ClickMenuItem (item, index) {
      this.map.setZoom(15)
      this.map.clearMap()
      var that_ = this
      this.id_ = item.id
      this.type_ = item.type_
      var icon_list = this.menu_list
      for (var i = 0; i < icon_list.length; i++) {
        if (i == index) {
          icon_list[index].is_selected = 1
        } else {
          icon_list[i].is_selected = 0
        }
      }

      this.menu_list = icon_list

      this.gdMapAddMarker({
        map: this.map,
        // 这里面可以增加自己的业务参数
        gdMapIconType: this.gdMapIconType,
        is_pay: this.is_pay,
        price: this.price,
        open_id: this.union_id,
        token: this.token,
        label_id: this.label_id,
        park_id: this.park_id,
        version: this.version,
        is_weixin: this.is_weixin,
        backUrl: 'NoDrawMap',
        currentUrl: window.location.href,
        point_list: this.point_list,
        cluster: this.cluster,
        type_: item.id, // 显示全部,路线是line,其他类型是数字,
        callback: function (ret_) {
          that_.markers = ret_.markers
          that_.cluster = ret_.cluster
        }
      })
    },
    // 搜索
    searchData (title, id_) {
      var list = this.title_list
      var snum = 0
      // console.log(id_);
      if (title) {
        var t_list = []
        for (var k = 0; k < list.length; k++) {
          list[k].is_show = 0
          t_list[k] = list[k].title
        }
        var reg = new RegExp(title)
        for (var i = 0; i < list.length; i++) {
          if (t_list[i].match(reg)) {
            list[i].is_show = 1
            ++snum
          }
        }
        this.list = list
      } else {
        for (var j = 0; j < list.length; j++) {
          if (id_ == 0) {
            if (list[j].type_ != 'line') {
              list[j].is_show = 1
              ++snum
            } else {
              list[j].is_show = 0
            }
          } else {
            // 表示路线搜索
            if (id_ == list[j].type_) {
              list[j].is_show = 1
              ++snum
            } else {
              list[j].is_show = 0
            }
          }
        }
        this.title_list = list
      }
      this.snum = snum
    },
    // 点击选择某一项
    ClickItem (item_, type) {
      var that = this

      if (type == 'line') {
        // 点击的是路线,重新请求接口,获取数据
        this.$ajax(
          'post',
          'SpotsRace/get_map_books_data',
          { id: item_.id, token: this.token },
          ret => {
            if (ret.data.id == 0) {
              that.line_info = ret.data.data
              const ls = new Array()
              ret.data.data.list.forEach(item => {
                ls.push(item.id)
              })

              const point_list = this.point_list.filter(item => {
                return ls.indexOf(item.id) > -1
              })
              console.log(point_list)
              that.map_root_book_point(
                this.map,
                point_list,
                data => {
                  that.jump(data, item_.id)
                }
              )
            } else {
              Toast(ret.data.msg)
            }
          }
        )
      } else {
        this.map.clearMap()
        this.gdMapAddMarker({
          map: this.map,
          // 这里面可以增加自己的业务参数
          gdMapIconType: this.gdMapIconType,
          is_pay: this.is_pay,
          price: this.price,
          open_id: this.union_id,
          token: this.token,
          label_id: this.label_id,
          park_id: this.park_id,
          version: this.version,
          is_weixin: this.is_weixin,
          backUrl: 'NoDrawMap',
          currentUrl: window.location.href,
          point_list: this.point_list,
          cluster: this.cluster,
          type_: 0, // 显示全部,路线是line,其他类型是数字,
          callback: function (ret_) {
            that.markers = ret_.markers
            that.cluster = ret_.cluster
          }
        })
        // 点击的是点
        this.point_list.forEach(function (item) {
          if (item_.id == item.id) {
            that.gdMapNewInfoWindow(
              that.map,
              item,
              that.is_pay,
              that.price,
              that.open_id,
              that.is_weixin,
              that.token,
              that.version,
              that.label_id,
              that.park_id,
              that.backUrl,
              that.currentUrl
            )
          }
        })
      }
      this.is_show_spots_list = 0
    },
    // 地图弹出菜单
    ClickLiItem (type_) {
      this.is_show_spots_list = 1
      this.menuType = type_ === 'line' ? 'line' : 'point'

      this.map.setZoom(15)
      var that_ = this
      if (this.title_list.length) {
        var list = this.title_list
        var snum = 0
        for (var i = 0, len = this.title_list.lenfth; i < len; i++) {
          if (type_ == 'line') {
            if (list[i].type_ == type_) {
              list[i].is_show = 1
              ++snum
            } else {
              list[i].is_show = 0
            }
          } else {
            if (list[i].type_ != 'line') {
              list[i].is_show = 1
              ++snum
            } else {
              list[i].is_show = 0
            }
          }
        }
        this.snum = snum
        this.title_list = list
      }

      this.id_ = type_
    },
    // 显示联系我们
    ClickTel (is_show_tel_me) {
      if (this.is_show_tel_me) {
        this.is_show_tel_me = 0
      } else {
        this.is_show_tel_me = 1
      }
      this.is_show_input = 0
    },
    CallTel () {
      window.location.href = 'tel://' + this.tel_
    },
    // 选择菜单
    ClickItemTelMe (index) {
      this.is_show_input = index
    },
    ClickSubmit () {
      // console.log(this.input_tel+this.input_content);
      if (this.input_tel && this.input_tel.length == 11) {
        if (this.input_content) {
          this.$ajax(
            'post',
            'DrawMap/save_advert_for_draw_map',
            {
              id: this.label_id,
              park_id: this.park_id,
              token: this.token,
              version: this.version,
              tel: this.input_tel,
              content: this.input_content
            },
            ret => {
              if (ret.data.id == 0) {
                Toast('提交成功,感谢你宝贵的意见!')
              } else {
                Toast('提交失败')
              }
              this.is_show_input = 0
            }
          )
        } else {
          Toast('请输入内容')
        }
      } else {
        Toast('请输入手机号')
      }
    }
  }
}
</script>

<style scoped>
.dm-body >>> .sr-only {
  display: none;
}
.tel-body {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: 1200;
}
.tel-me {
  position: absolute;
  top: 20%;
  left: 15%;
  background-color: white;
  width: 70%;
  z-index: 1210;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.tel-me .tm-head {
  display: inline-block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #ff9138;
}
.tel-me .tm-head .tmh-text {
  position: relative;
  width: 85%;
  float: left;
}
.tel-me .tm-head .tmh-text span {
  display: block;
  padding: 10px 0px 10px 0px;
  font-size: 1rem;
  text-align: center;
}
.tel-me .tm-head .tmh-del {
  position: relative;
  width: 15%;
  float: left;
}
.tel-me .tm-head .tmh-del img {
  width: 35px;
  height: 35px;
}
.tel-me .tm-tip {
  display: inline-block;
  position: relative;
  width: 100%;
}
.tel-me .tm-tip span {
  display: block;
  padding: 10px 0px 10px 0px;
  font-size: 0.6rem;
  text-align: center;
  color: #ababab;
}
.tel-me .tm-body {
  display: inline-block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #ff9138;
  padding-top: 5%;
  padding-bottom: 5%;
}
.tel-me .tm-body .tmb-item {
  position: relative;
  width: 50%;
  float: left;
}
.tel-me .tm-body .tmb-item .tmb-item-img {
  display: inline-block;
  position: relative;
  width: 100%;
}
.tel-me .tm-body .tmb-item .tmb-item-img img {
  width: 45px;
  height: 45px;
  margin-left: 30%;
}
.tel-me .tm-body .tmb-item .tmb-item-text {
  display: inline-block;
  position: relative;
  width: 100%;
}
.tel-me .tm-body .tmb-item .tmb-item-text span {
  display: block;
  padding: 5px 10px 5px 10px;
  font-size: 0.8rem;
  text-align: center;
}
.tel-me .tm-tel {
  display: inline-block;
  position: relative;
  width: 60%;
  margin-left: 20%;
  margin-top: 5%;
  margin-bottom: 5%;
}
.tel-me .tm-tel span {
  display: block;
  padding: 5px 10px 5px 10px;
  font-size: 1rem;
  background-color: #ff9138;
  color: white;
  text-align: center;
  border-radius: 10px;
}

.tel-me .tm-tel i::after {
  content: "\e69a";
}
.tel-me .tm-input {
  display: inline-block;
  position: relative;
  width: 100%;
}
.tel-me .tm-input .tmi-tel {
  display: inline-block;
  position: relative;
  width: 100%;
}
.tel-me .tm-input .tmi-tel .tmit-tel {
  display: inline-block;
  position: relative;
  width: 100%;
}
.tel-me .tm-input .tmi-tel .tmit-tel span {
  display: block;
  padding: 5px;
  font-size: 0.8rem;
}
.tel-me .tm-input .tmi-input {
  display: inline-block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
}
.tel-me .tm-input .tmi-input input {
  height: 40px;
  width: 97%;
  margin-left: 3%;
}
.tel-me .tm-input .tmi-tel .tmi-textarea {
  display: inline-block;
  position: relative;
  width: 97%;
  margin-left: 3%;
}
.tel-me .tmi-input .tmi-tel .tmi-textarea textarea {
  width: 95%;
  border: none;
}

.foot-menu {
  position: absolute;
  background-color: white;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}
.foot-menu .dm-fm-ul {
  display: inline-block;
  position: relative;
  width: 100%;
}
.foot-menu .dm-fm-ul .dm-fm-ul-li {
  position: relative;
  width: 49%;
  float: left;
  font-size: 0.8rem;
  text-align: center;
  padding: 8px 0px 8px 0px;
}
.foot-menu .dm-fm-ul .border_right {
  border-right: 1px solid #ff9138;
}
.foot-menu .dm-fm-ul .dm-fm-ul-li i {
  color: #ff9138;
}
.spots::before {
  content: "\e757";
}
.line::before {
  content: "\e677";
}

.gd-right-but {
  position: absolute;
  right: 0.5rem;
  bottom: 2.2rem;
}
.gd-right-but .gd-r-item {
  display: block;
  position: relative;
}
.gd-right-but .gd-r-item img {
  width: 40px;
  height: 40px;
}
.content_list {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  font-size: 0.7rem;
  z-index: 200;
}
.content_list .park_search {
  display: inline-block;
  position: relative;
  width: 100%;
}
.content_list .park_search > p {
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
}
.content_list .park_search img {
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
  width: 30px;
  height: 30px;
}
.content_list .park_search ul {
  border-top: 0.1rem solid #ccc;
  max-height: 15rem;
  min-height: 6rem;
  overflow-y: scroll;
  padding: 0 0.6rem;
  box-sizing: border-box;
}
.content_list .park_search li {
  line-height: 1.5rem;
  padding: 0.2rem;
  border-bottom: 0.01rem solid #f5f5f5;
}
.map_info_tip {
  position: fixed;
  top: 1rem;
  text-align: center;
  width: 90%;
  background-color: rgba(255, 154, 128, 0.6);
  border-radius: 4px;
  padding: 4px 6px;
  color: #fff;

  left: 2.5%;
  right: 2.5%;
  z-index: 0;
  font-size: 0.7rem;
}
.map_info_tip li {
  width: 25%;
  border-right: 1px dashed #fff;
  box-sizing: border-box;
  float: left;
}

/* 20191016 */
.dm-body {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}
.dm-body .dm-contain {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}
.leaflet-title {
  position: absolute;
  max-width: 100px;
  bottom: 20px;
}
.leaflet-title span {
  display: block;
  padding: 2px 5px 2px 5px;
  text-align: center;
  border: 1px solid #ff9a38;
  overflow: hidden;
  white-space: nowrap;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
}
.popu-body {
  display: inline-block;
  position: relative;
  width: 100%;
}
.popu-body .pb-title {
  width: 95%;
  margin-top: -4%;
}
.popu-body .pb-title h3 {
  color: #5b5b5b;
  padding: 4px 2px 4px 2px;
}
.popu-body .pb-image {
  position: relative;
  width: 30%;
  float: left;
}
.popu-body .pb-image img {
  display: block;
  float: left;
  margin: 0.2rem 0 0.2rem 0;

  width: 5rem;
  height: 5rem;
  border-radius: 0.3rem;
}
.popu-body .pb-right {
  position: relative;
  width: 65%;
  float: right;
  margin-left: 4%;
}
.popu-body .pb-right .pbr-content {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 64px;
}
.popu-body .pb-right .pbr-content span {
  color: #5b5b5b;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.popu-body .pb-right .pbr-bottom {
  display: inline-block;
  position: relative;
  width: 100%;
}
.popu-body .pb-right .pbr-bottom .pbr-audio {
  position: relative;
  width: 35%;
  float: left;
  background-color: #ff9a38;
  color: white;
  padding: 5px 2px 5px 2px;
  text-align: center;
  border-radius: 5px;
}
.popu-body .pb-right .pbr-bottom .pbr-audio::before {
  content: "\e647";
}
.popu-body .pb-right .pbr-bottom .pbr-detail {
  position: relative;
  width: 35%;
  float: left;
  background-color: #9196a9;
  color: white;
  padding: 5px 2px 5px 2px;
  text-align: center;
  border-radius: 5px;
}
.popu-body .pb-right .pbr-bottom .pbr-detail::before {
  content: "\e72c";
}
.popu-body .pb-right .pbr-bottom .pbr-nav {
  position: relative;
  width: 35%;
  float: left;
  background-color: #9196a9;
  color: white;
  padding: 5px 2px 5px 2px;
  text-align: center;
  border-radius: 5px;
  margin-left: 4%;
}
.popu-body .pb-right .pbr-bottom .pbr-nav::before {
  content: "\E677";
}
.left_but {
  position: fixed;
  left: 0.5rem;
  bottom: 4.6rem;
}
.left_but .mak_tab {
  background-color: #fff;
  width: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 2px 2px 5px #ccc;
  box-shadow: 2px 2px 5px #ccc;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
}

.left_but .mak_tab img {
  width: 100%;
  padding: 0.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.left_but .mak_tab:last-child {
  margin-bottom: 0;
}
.left_but .mak_tab.map_my_location img,
.left_but .mak_tab img:last-child {
  border: none;
}

.dm-contain >>> .amap-marker-content > div.no_bg {
  background: none;
}

.dm-contain >>> .amap-marker-content > div.no_bg img {
  height: 1.7rem;
  border-radius: 50%;
}

.dm-contain >>> .amap-marker-content > div.img {
  width: 2.4rem;
  height: 2.4rem;
  padding: 0.3rem;
  position: relative;
}

.dm-contain >>> .amap-marker-content > div.img.sola_picture {
  background: url(../../assets/img/sola_picture.png) 0 0 no-repeat;
  background-size: contain;
}

.dm-contain >>> .amap-marker-content > div.img.more_picture {
  background: url(../../assets/img/more_picture.png) 0 0 no-repeat;
  background-size: contain;
  padding: 0.5rem;
}

.dm-contain >>> .amap-marker-content > div.img img {
  border-radius: 0;
  width: 100%;
  height: 90%;
}

.dm-contain >>> .amap-marker-content > div.img i {
  position: absolute;
  bottom: 9px;
  z-index: 2;
  right: 10px;
  line-height: 0.7rem;
  background: #ff9a80;
  width: 0.7rem;
  height: 0.7rem;
  color: #fff;
  font-style: inherit;
  border-radius: 50%;
  font-size: 0.5rem;
}

.park_search .input_search {
  background: url(../../assets/img/search-bg.png) 0.8rem 0.8rem no-repeat;
  background-size: 1.2rem;
  display: flex;
  padding: 0.4rem 0.4rem 1rem;
  box-sizing: border-box;
}

.park_search .input_search input {
  height: 2rem;
  line-height: 2rem;
  flex: 4;
  border: 1px solid #ff9a80;
  border-radius: 0.4rem 0 0 0.4rem;
  padding-left: 2rem;
  font-size: 0.7rem;
}

.park_search .input_search button {
  flex: 1;
  background-color: #ff9a80;
  color: #fff;
  border: none;
  font-size: 0.7rem;
  border-radius: 0 0.4rem 0.4rem 0;
}

.dm-contain >>> .amap-marker-content > div {
  width: 1.4rem;
  height: 2rem;
  line-height: 1.4rem;
  text-align: center;
  background: url(../../assets/img/landmark.png) 0 0 no-repeat;
  background-size: contain;
}

#gd_map >>> .amap-maps .amap-marker-label {
  border: 1px solid #999;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
}

#gd_map >>> .amap-info > div {
  width: 85%;
  left: 6% !important;
}
#gd_map >>> .amap-info .center > div {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  line-height: 1.4;
  overflow: auto;
  box-shadow: 0 3px 14px rgba(0, 0, 100, 0.6);
  text-align: left;
  font-size: 0.7rem;
  min-width: 12.8rem;
  position: relative;
  border-radius: 5px;
}
#gd_map >>> .amap-info .center .titleD {
  border-bottom: 1px solid #f5f5f5;
}
#gd_map >>> .amap-info .center .closeX {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: #c3c3c3;
  text-decoration: none;
  font: 700 16px/14px Tahoma, Verdana, sans-serif;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

#gd_map >>> .amap-info .center .content_box {
  margin-top: 0.4rem;
}

#gd_map >>> .amap-info .center .content_box .contentimg {
  float: left;
  width: 3rem;
}

#gd_map >>> .amap-info .center .content_box .contentinfo {
  overflow: hidden;
  padding: 0 0.2rem;
  max-height: 3rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#gd_map >>> .amap-info .center .bottom_box,
#gd_map >>> .amap-info .center .btmtip {
  display: flex;
}
#gd_map >>> .amap-info .center .btmtip.jingdian {
  background-image: linear-gradient(10turn, #439dff, #95b5ff);
}
#gd_map >>> .amap-info .center .btmtip {
  margin-right: 5px;
  min-width: 3rem;
  max-width: 7rem;
  text-align: center;
  height: 1.4rem;
  line-height: 1.4rem;
  box-sizing: border-box;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
}
#gd_map >>> .amap-info .center .btmtip.luxian {
  background-image: linear-gradient(10turn, #ec1447, #ff6767);
}
#gd_map >>> .amap-info .center .btmtip.yuding {
  background-image: linear-gradient(10turn, #ff9d42, #ffbf83);
}
#gd_map >>> .amap-info .center .sharp {
  height: 23px;
  margin: 0 auto;
  overflow: hidden;
  top: -1px;
  width: 30px;
  border-radius: 0;
  position: relative;
  display: block;
}
#gd_map >>> .amap-info .center .title_,
#gd_map >>> .amap-info .center .titleD {
  padding-bottom: 0.4rem;
  box-sizing: border-box;
  font-size: 0.9rem;
}
#gd_map >>> .amap-info .center .titleD {
  border-bottom: 1px solid #f5f5f5;
}
#gd_map >>> .center .btmtip {
  font-size: 0.6rem;
  padding: 2px 5px;
  background-color: #ff9a80;
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  border-radius: 2px;
}
</style>
