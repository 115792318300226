<template>
  <div class="publich-container" v-title data-title="发布足迹">
    <header class="header" v-if="activity_name">
      <i class="back iconfont iconmenu2" @click="goBack"></i>
      <p class="title">{{activity_name}}</p>
    </header>
    <div class="content">
      <form id="form" action method="post" enctype="multipart/form-data">
        <div class="name form-item">
          <p class="item-title">作品名称</p>
          <input type="text" class="item-value" placeholder="请填写足迹名称" v-model="title" />
        </div>

        <div class="describe form-item">
          <p class="item-title">作品描述</p>
          <textarea
            :attr="{name:'content'}"
            v-model="content"
            placeholder="..."
            rows="3"
            type="textarea"
            class="item-value"
          ></textarea>
        </div>
        <div class="filenum clearfix form-item">
          <div class="fl" style="line-height: 32px">是否公开</div>
          <mt-switch v-model="value" class="fr"></mt-switch>
        </div>
        <div class="imgs clearfix form-item">
          <div class="item up-img" v-for="(item, i) in selectImgList" :key="i">
            <img :src="item.result" class="img" />
            <i class="remove" @click="removeImg(i)"></i>
          </div>
          <div class="item add-image" v-show="showAdd">
            <img class="img" src="../../assets/img/tour_photo@2x.png" />
            <input class="inp" type="file" accept="image/*" @input="addImg($event)" />
          </div>
        </div>
        <div class="tip">上传图片（最多上传9张图片）</div>

        <div id="container" style="display: none;" ref="container"></div>
      </form>
      <div class="issue form-item" @click="issue">
        <button class="btn">提交</button>
      </div>
    </div>
  </div>
</template>
<script>
import { Indicator, MessageBox, Toast } from "mint-ui";
import * as qiniu from "qiniu-js";
import md5 from "js-md5";
export default {
  data() {
    return {
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id")
        ? localStorage.getItem("open_id")
        : "",
      backUrl: "publishPhoto",
      park_id: 298,
      user_id: "", //用户ID
      verify: "",
      map: {},
      title: "", // 足迹名称
      content: "", // 足迹描述
      aid: this.$route.query.aid > 0 ? this.$route.query.aid : "",

      value:
        this.$route.query.open != 1 && this.$route.query.open ? false : true, // 是否公开
      add_time: new Date().getTime(),
      lng: 0,
      lat: 0,
      selectImgList: [],
      isIssue: false,
      back: this.$route.query.back || "",

      activity_name: this.$route.query.activity_name || "" // 活动的名称
    };
  },
  mounted() {
    if (this.token) {
    } else {
      //第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    console.log("token=" + this.token);
    //投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }

    this.unload();
  },
  computed: {
    showAdd: function() {
      return this.selectImgList.length < 9;
    }
  },
  methods: {
    // 上一级
    goBack() {
      this.$router.back(-1);
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=" +
        this.token +
        "&id=" +
        this.aid +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    },
    unload() {
      //加载地图，调用浏览器定位服务
      this.map = this.map_init({
        init_zoom: 15,
        max_zoom: 20
      });

      if (!this.check_login()) {
        MessageBox.alert("请先登录").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
          }
        });
      } else {
        this.user_id = this.check_login().user_id;
        this.verify = this.check_login().user_id + new Date().getTime();
      }
      this.getMap();
    },

    getMap(type) {
      if (type == 1) {
        if (this.lng == 0) {
          type = "";
        } else {
          this.map.setZoomAndCenter(this.map.getZoom(), [this.lng, this.lat]);
        }
      } else {
        type = "no";
      }

      this.gdMapLocation({
        map: this.map,
        type: type,
        callback: (lng, lat) => {
          this.lng = lng;
          this.lat = lat;
        }
      });
    },
    addImg(e) {
      if (!e.target.value) {
        return false;
      }
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const imgInfo = Object.create(null);
        imgInfo.result = e.target.result;
        imgInfo.filetype = file.type;
        imgInfo.filename = file.name;
        imgInfo.fileblob = this.upload(e.target.result, file.type);
        this.selectImgList.push(imgInfo);
      };
      reader.readAsDataURL(file);
      const objUrl = this.getObjectURL(file);
    },

    // 删除图片
    removeImg(i) {
      this.selectImgList.splice(i, 1);
    },
    //发布
    issue() {
      if (this.isIssue) {
        return false;
      } else if (this.lng == 0 || this.lat == 0) {
        Toast("请重新定位");
        return false;
      } else if (!this.selectImgList.length) {
        Toast("请选择至少一张图片");
        return false;
      }

      if (this.open_id && this.token && this.lng && this.lat && this.user_id) {
        this.isIssue = true;
        Indicator.open("发布中...");
        let promise = new Promise((resolve, reject) => {
          this.$ajax(
            "post",
            "Lottery/get_qiniu_token",
            {
              token: this.token,
              bucket: "image",
              open_id: this.open_id
            },
            res => {
              if (res.data.id === 0) {
                resolve(res.data.data.token);
              } else {
                console.log("提交失败");
                this.isIssue = false;
                Indicator.close();
              }
            }
          );
        });
        promise.then(qiniuToken => {
          let t = new Date()
            .toLocaleDateString()
            .split("/")
            .map(item => {
              if (item.length <= 1) {
                return "0" + item;
              }
              return item;
            })
            .join("");

          let len = this.selectImgList.length,
            sum = 0,
            img_key = "";
          return new Promise((resolve, reject) => {
            for (let i = 0; i < len; i++) {
              const fileName = this.selectImgList[i].filename;
              const key = t + "_" + md5(fileName);
              const blob = this.selectImgList[i].fileblob;

              let observable = qiniu.upload(blob, key, qiniuToken);
              let observer = {
                next(res) {},
                error(err) {
                  reject(err);
                },
                complete(res) {
                  sum++;
                  if (sum === 1) {
                    img_key = res.key;
                  } else {
                    img_key = img_key + "|" + res.key;
                  }
                  if (sum === len) {
                    resolve(img_key);
                  }
                }
              };
              observable.subscribe(observer);
            }
          }).then(
            img_key => {
              this.$ajax(
                "post",
                "foot/web_foot_upload",
                {
                  lng: this.lng,
                  lat: this.lat,
                  title: this.title,
                  content: this.content,
                  label_id: this.aid,
                  user_id: this.user_id,
                  verify: this.make_token(),
                  open: this.value ? 1 : 0,
                  img_key: img_key,
                  open_id: this.open_id
                },
                res => {
                  if (res.data.id == 0) {
                    //表示发布成功之后，跳转到投票页面
                    console.log("发布成功");

                    this.goBack();
                    this.isIssue = false;
                    Indicator.close();
                  } else {
                    this.isIssue = false;
                    Indicator.close();
                    Toast("发布失败");
                  }
                }
              );
            },
            err => {
              this.isIssue = false;
              console.log(err);
              Indicator.close();
              Toast("上传图片失败");
            }
          );
        });
      } else {
        if (this.open_id == "") {
          this.authorize();
        } else if (this.user_id == "") {
          MessageBox.alert("请先登录").then(action => {
            if (action == "confirm") {
              localStorage.setItem("login_url", location.hash);
              this.$router.push({ path: "/my/login" });
            }
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.publich-container {
  height: 100vh;
  width: 100vw;
  padding: 2.8rem 1rem 1rem 1rem;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
}

/* header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 2.8rem;
  width: 100%;
  padding: 0.4rem 0.8rem 0.4rem 0.4rem;
  display: flex;
  box-sizing: border-box;
  box-shadow: 0 2px 2px -1px rgba(211, 211, 211, 0.4);
  background-color: #fff;
  z-index: 1;
}

.header > .back {
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}

.header > .title {
  flex: 1;
  padding-right: 2rem;
  box-sizing: border-box;
  text-align: center;
  font-size: 1rem;
  line-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* container */

.form-item {
  margin-top: 1rem;
  font-size: 0.88rem;
}

.form-item > .item-title {
  width: 100%;
  margin-bottom: 0.5rem;
}

.item-value {
  display: inline-block;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: rgb(243, 245, 248);
  font-size: 0.8rem;
  color: #333;
  outline: none;
  border: none;
  resize: none;
}

.tip {
  font-size: 0.68rem;
  color: #666;
}

#form >>> .mint-switch-input:checked + .mint-switch-core {
  border-color: #fa4c21;
  background-color: #fa4c21;
}
#form >>> .mint-switch-core {
  transform: scale(0.666);
}

.imgs > .item {
  position: relative;
  float: left;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: calc(25vw - 1.25rem);
  overflow: hidden;
  height: calc(25vw - 1.25rem);
}

.imgs > .item:nth-child(4n + 2),
.imgs > .item:nth-child(4n + 3) {
  margin: 0 0.5rem;
}

.imgs > .item > .img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgs > .item > .remove {
  background: url(../../assets/img/search_clean.png) 0 0 no-repeat;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.imgs > .add-image > .inp {
  width: 100%;
  height: 100%;
  font-size: 0;
  opacity: 0;
}

/* 提交 */
.issue {
  width: 100%;
}

.issue > .btn {
  width: 100%;
  height: 2.4rem;
  border-radius: 4px;
  text-align: center;
  line-height: 2.4rem;
  background-color: #67c23a;
  font-size: 1rem;
  color: #fff;
}
</style>