<template>
  <div class="publish_container" v-cloak ref="container">
    <!--  -->
    <div class="publish_header">
      <div class="header_wrapper">
        <span :class="[isActive == 1 ? 'active' : '','header_item']" @click="isActive=1">
          <span>已上线</span>
        </span>
        <span :class="[isActive == 2 ? 'active' : '','header_item']" @click="isActive=2">
          <span>已下线</span>
        </span>
      </div>
    </div>
    <!--  -->
    <div class="published_banner" v-if="advert.newUrl">
      <img :src="advert.newUrl" alt />
    </div>
    <!--  -->
    <div class="published_header">
      <span class="name">名称</span>
      <span class="time">时间</span>
      <span class="number">参与人数</span>
    </div>
    <!-- 已发布 -->
    <mt-loadmore
      :bottom-method="onLoadBottom"
      :bottom-all-loaded="onAllLoaded"
      ref="loadmoreOn"
      :auto-fill="false"
      bottomPullText
    >
      <div class="publish_published" v-show="isActive==1">
        <div class="published_wrapper">
          <!--  -->
          <transition-group name="lowercase" tag="div" class="publish_group">
            <div class="published_item" v-for="(item,i) in onlineList" :key="item.id">
              <div class="item_display" @click="showDetail(i,'online')">
                <div class="item_status">
                  <span :style="'backgroundColor:'+ item.statusColor">{{item.status}}</span>
                </div>
                <span class="name">{{item.title}}</span>
                <span class="time">{{ item.btime }} 至 {{ item.etime }}</span>
                <span class="number">{{ item.join_numbers }}</span>
              </div>
              <!--  -->
              <div class="item_handle">
                <div class="handle_btn" @click="lowerCase(i)">下架</div>
                <div class="handle_btn" @click="edit(i,'online',item.is_lottery)">编辑</div>
                <div class="handle_btn" @click="showDel(i,'online')">删除</div>
                <div class="handle_btn" @click="showDetail(i,'online',item.is_lottery)"  v-if="item.is_lottery * 1 == 1">查看详情</div>
                <div class="handle_btn red_btn" v-if="item.is_pay == 0 && item.money_ > 0">支付</div>
              </div>
              <!--  -->
            </div>
          </transition-group>
        </div>
        <!--  -->
        <footer class="publish_footer" v-if="onAllLoaded">
          <div class="footer_content">没有更多了</div>
        </footer>
      </div>
    </mt-loadmore>
    <!-- 待发布 -->
    <mt-loadmore
      :bottom-method="offLoadBottom"
      :bottom-all-loaded="offAllLoaded"
      ref="loadmoreOff"
      :auto-fill="false"
      bottomPullText
    >
      <div class="publish_published" v-show="isActive==2">
        <div class="published_wrapper">
          <!-- 待发布项 -->
          <transition-group name="upcase" tag="div" class="publish_group">
            <div class="published_item" v-for="(item,i) in offlineList" :key="item.id">
              <div class="item_display" @click="showDetail(i,'offline')">
                <div class="item_status">
                  <span :style="'backgroundColor:'+ item.statusColor">{{item.status}}</span>
                </div>
                <span class="name">{{item.title}}</span>
                <span class="time">{{ item.btime }} 至 {{ item.etime }}</span>
                <span class="number">{{ item.join_numbers }}</span>
              </div>
              <!--  -->
              <div class="item_handle">
                <div class="handle_btn" @click="upCase(i,item.id)">上架</div>
                <div class="handle_btn" @click="edit(i,'offline',item.is_lottery)">编辑</div>
                <div class="handle_btn" @click="showDel(i,'offline')">删除</div>
                <div class="handle_btn" @click="showDetail(i,'offline',item.is_lottery)" v-if="item.is_lottery * 1 == 1">查看详情</div>
                <div
                  class="handle_btn red_btn"
                  v-if="item.is_pay == 0 && item.money_ > 0"
                  @click="jumpToPay(item.id)"
                >支付</div>
              </div>
              <!--  -->
            </div>
          </transition-group>
        </div>
        <!--  -->
        <footer class="publish_footer" v-if="offAllLoaded">
          <div class="footer_content">
            <span>没有更多了</span>
          </div>
        </footer>
      </div>
    </mt-loadmore>
    <!--  -->
    <transition name="newBtn">
      <div class="publish_new_lottery" @click="newBuild" v-show="showSettingBtn">
        <span>转盘抽奖</span>
      </div>
    </transition>
    <transition name="newBtn">
      <div class="publish_new_red_packet" @click="newBuildRedPacket" v-show="showSettingBtnRedPacket">
        <span>分享红包</span>
      </div>
    </transition>
    <!-- 设置和编辑 大转盘-->
    <transition name="set">
      <div class="publish_setting" v-show="showSetting">
        <div class="setting_wrapper">
          <div class="setting_close" @click.stop="showSetting=false;showTips = false;"></div>
          <transition name="tips">
            <div class="setting_tips" v-show="showTips">
              <span class="tips_text" ref="tips"></span>
            </div>
          </transition>
          <div class="setting_header">
            <h3>{{setting_title}}</h3>
          </div>
          <div class="setting_content_wrapper">
            <div class="setting_content">
              <div class="setting_item">
                <span class="name">设置名称：</span>
                <input
                  type="text"
                  v-model="setTitle"
                  class="setTitle"
                  :placeholder="'长度'+ minTitleSize + '-' + maxTitleSize"
                  @blur="checkName"
                  :style="'color:' + setTitleColor"
                />
              </div>
              <div class="setting_item">
                <span class="time">开始时间：</span>
                <input type="date" class="setTime" v-model="btime" :style="'color:' + setTimeColor" />
              </div>
              <div class="setting_item">
                <span class="time">结束时间：</span>
                <input type="date" class="setTime" v-model="etime" :style="'color:' + setTimeColor" />
              </div>
              <div class="setting_item">
                <span class="time">限制/天：</span>
                <input type="number" min="1" class="setTime" v-model="times_" />
              </div>
              <div class="setting_item">
                <span class="number">条件设置：</span>
                <input
                  type="checkbox"
                  name="setType"
                  value="share"
                  class="setType"
                  :class="shareFlag ? 'checked' : ''"
                  id="share"
                  ref="share"
                />
                <label for="share" @click="checkCondition(1)">分享</label>
                <input
                  type="checkbox"
                  name="setType"
                  value="question"
                  class="setType"
                  :class="questionFlag ? 'checked' : ''"
                  id="question"
                  ref="question"
                />
                <label for="question" @click="checkCondition(2)">答题</label>
              </div>
              <div class="setting_rule">
                <label class="rule" for="rule">规则设置：</label>
                <textarea
                  name="rule"
                  id="rule"
                  cols="30"
                  rows="10"
                  v-model="setContent"
                  @blur="checkTextArea"
                ></textarea>
                <span
                  class="area_count"
                  :style="'color:' + setContentColor"
                >字数:{{currentLen}}/{{totalLen}}</span>
              </div>
            </div>
          </div>
          <div class="setting_submit">
            <button @click="nextStep">下一步</button>
          </div>
        </div>
      </div>
    </transition>
    <!-- 设置和编辑 分享领取红包页面-->
    <transition name="set">
      <div class="publish_setting" v-show="showSettingRedPacket">
        <div class="setting_wrapper">
          <div class="setting_close" @click.stop="showSettingRedPacket=false;showTips = false;"></div>
          <transition name="tips">
            <div class="setting_tips" v-show="showTips">
              <span class="tips_text" ref="tips"></span>
            </div>
          </transition>
          <div class="setting_header">
            <h3>{{setting_title}}</h3>
          </div>
          <div class="setting_content_wrapper">
            <div class="setting_content">
              <div class="setting_item">
                <span class="name">设置名称：</span>
                <input
                        type="text"
                        v-model="redPacketSetTitle"
                        class="setTitle"
                        :placeholder="'长度'+ minTitleSize + '-' + maxTitleSize"
                        @blur="redPacketCheckName"
                        :style="'color:' + setTitleColor"
                />
              </div>
              <div class="setting_item">
                <span class="time">开始时间：</span>
                <input type="date" class="setTime" v-model="redPacketBTime" :style="'color:' + setTimeColor" />
              </div>
              <div class="setting_item">
                <span class="time">结束时间：</span>
                <input type="date" class="setTime" v-model="redPacketETime" :style="'color:' + setTimeColor" />
              </div>
              <div class="setting_item">
                <span class="time">红包金额：</span>
                <input type="number" min="1" class="setTime" v-model="redPacketMoney" />
              </div>
              <div class="setting_item">
                <span class="time">红包个数：</span>
                <input type="number" min="1" class="setTime" v-model="redPacketTotal" />
              </div>


            </div>
          </div>
          <div class="setting_submit">
            <button @click="redPacketNextStep">下一步</button>
          </div>
        </div>
      </div>
    </transition>
    <!-- 删除界面 -->
    <transition name="check">
      <div class="publish_check" v-show="show_del">
        <div class="check_wrapper">
          <div class="check_header">
            <span>确定删除</span>
            <span class="check_title">"{{del_title}}"</span>
            <span>吗？</span>
          </div>
          <div class="check_btn btn_wrapper">
            <button class="btn_yes" @click="delCase();show_del=false">确定</button>
            <button class="btn_no" @click="show_del=false">取消</button>
          </div>
        </div>
      </div>
    </transition>
    <!-- 上架时支付 -->
    <transition name="check">
      <div class="publish_check" v-show="show_pay">
        <div class="check_wrapper">
          <div class="check_header">是否前往支付？</div>
          <div class="check_btn btn_wrapper">
            <button class="btn_yes" @click="show_pay=false">确定</button>
            <button class="btn_no" @click="show_pay=false">取消</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { setTimeout, clearTimeout } from "timers";
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      id_type_: this.$route.query.id_type_ ? this.$route.query.id_type_ : "",
      id_: this.$route.query.id_ ? this.$route.query.id_ : "",
      aid: this.$route.query.aid ? this.$route.query.aid : 0,
      vid: this.$route.query.vid ? this.$route.query.vid : 0,
      lid: this.$route.query.lid ? this.$route.query.lid : 0,

      user_id: "",
      version: "1.0.0",
      park_id: 298,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id")
        ? localStorage.getItem("open_id")
        : "", // 备注删除
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      money_: 0,
      /*  */
      onlineList: [], // 数据信息
      offlineList: [],
      advert: {}, // 广告
      offAllLoaded: false, // 数据加载完
      onAllLoaded: false, // 数据加载完
      off_page: 1,
      on_page: 1,
      off_len: "", //已下线数据长度
      on_len: "", // 已上线数据长度

      show_del: false, //显示删除界面
      del_item: {}, // 要删除的项
      del_title: "", // 要删除项的名字
      /*  */
      show_pay: false, // 显示去支付
      /*  */
      lottery_id: "", // 转盘id
      setting_title: "",

      isActive: 1, // 导航
      showSettingBtn: true, // 新建按钮
        showSettingBtnRedPacket:true,//新建分享领取红包按钮
      /* 新建设置 */
      showSetting: false, // 显示设置
      showSettingRedPacket: false, // 显示分享领取红包设置
        redPacketMoney:1,
        redPacketTotal:100,
        redPacketBTime:"",
        redPacketETime:"",
        redPacketSetTitle:"",
      setTitle: "", // 活动名称
      minTitleSize: 3, // 名称长度
      maxTitleSize: 16, // 名称长度
      //   setTitleColor: "#333", // 名称颜色
      nameFlag: false, // 名称是否正确
      btime: "", // 开始时间
      etime: "", // 结束时间
      timeFlag: false, // 时间是否正确
      showTips: false, // 显示tip
      tipsTimer: "", // tip延时器
      times_: "",

      //   condition_: "share", // 条件
      // condition: "",
      shareFlag: true,
      questionFlag: false,
      content: "",
      setContent: `1.每日分享或答题后，即可获得一次幸运大转盘的机会，仅限当天有效，过期作废。 
      2.所中现金红包在公众号“最美旅行家”中点击领取；
3.其他奖品到【我的账户】中查询。
`, // 文本域内容
      textAreaFlag: false, // 文本内容是否正确

      totalLen: 200, // 总长度
      setContentColor: "#999",
      formFlag: false, // 表单内容是否正确
      setType: 0 // 1新建或2修改
    };
  },

  computed: {
    //   文本域长度
    currentLen: function() {
      if (this.setType == 2) {
        return;
      }
      let len = this.setContent.length;
      if (len > this.totalLen) {
        this.setContentColor = "#e33";
        this.textAreaFlag = false;
      } else {
        this.setContentColor = "#999";
        this.textAreaFlag = true;
      }
      return this.setContent.length;
    },
    //   条件
    condition_: function() {
      if (!this.shareFlag && !this.questionFlag) {
        return "none";
      } else if (this.shareFlag && !this.questionFlag) {
        return "share";
      } else if (!this.shareFlag && this.questionFlag) {
        return "question";
      } else {
        return "all";
      }
    },
    //   设置名称颜色
    setTitleColor: function() {
      let len = this.setTitle.length;
      if (!len) {
        this.nameFlag = false;
      } else if (len && (len < this.minTitleSize || len > this.maxTitleSize)) {
        this.nameFlag = false;
        return "#e33";
      } else {
        this.nameFlag = true;
        return "#333";
      }
    },
    // 设置时间颜色
    setTimeColor: function() {
      if (this.setType == 1) {
        if (this.btime && this.etime) {
          let start = Date.parse(new Date(this.btime));
          let end = Date.parse(new Date(this.etime));
          let current = Date.parse(new Date().toLocaleDateString());

          //   console.log(start+'=='+end );
          if (start > end || start < current || end < current) {
            this.timeFlag = false;
            return "#e33";
          } else {
            this.timeFlag = true;
            return "#333";
          }
        }
      } else if (this.setType == 2) {
        if (this.btime && this.etime) {
          let start = Date.parse(new Date(this.btime));
          let end = Date.parse(new Date(this.etime));
          let current = Date.parse(new Date().toLocaleDateString());

          //   console.log(start+'=='+end );
          if (start > end || end < current) {
            this.timeFlag = false;
            return "#e33";
          } else {
            this.timeFlag = true;
            return "#333";
          }
        }
      }
    }
  },

  created() {
    this.getData();
  },
  mounted() {
    if (this.token) {
    } else {
      //第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    console.log("token=" + this.token);
    //投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }
    if (this.open_id) {
    } else {
      this.authorize();
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
      this.wx_share(window.location.href);
    }

    document.title = "推广红包";
    //在冒泡阶段处理滚动的事件
    // this.$refs.container.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=" +
        this.token +
        "&id=" +
        this.id_ +
        "&state=" +
        this.id_type_;
      window.location.href = open_url;
    },
    // 查看详情
    showDetail(i, type,is_lottery) {
      let item = {};
      if (type == "online") {
        item = this.onlineList[i];
      } else if (type == "offline") {
        item = this.offlineList[i];
      }
      // console.log(item);
      this.lottery_id = item.id;
      this.setTitle = item.title;
      this.btime = item.btime;
      this.etime = item.etime;
      this.times_ = item.times_;
      this.content = item.content;
      let condition = item.condition_;

      if (condition == "share") {
        this.shareFlag = true;
        this.questionFlag = false;
      } else if (condition == "question") {
        this.shareFlag = false;
        this.questionFlag = true;
      } else if (condition == "all") {
        this.shareFlag = true;
        this.questionFlag = true;
      }

      // console.log(this.condition_);
        if(is_lottery == 1)
        {
            this.jumpToDetail();
        }
        else
        {
            //分享领取红包的情况，不进行跳转
        }
    },
    //   下一步
    nextStep() {
      this.showTips = false;
      if (!this.nameFlag) {
        Toast("请检查名称设置");
        return;
      } else if (!this.timeFlag) {
        Toast("请检查时间设置");
        return;
      } else if (this.condition_ == "none") {
        Toast("请至少选择一项条件");
        return;
      } else if (!this.textAreaFlag) {
        Toast("请检查规则设置");
        return;
      } else {
        this.formFlag = true;
        this.jumpToDetail();
      }
    },

      /**
       * 分享领取红包，下一步
       */
      redPacketNextStep(){
      console.log(this.lottery_id,this.redPacketSetTitle,this.redPacketMoney,this.redPacketTotal,this.redPacketBTime,this.redPacketETime);
        if(this.is_weixin)
        {
            if(this.open_id && this.redPacketSetTitle && this.token
            && this.redPacketMoney && this.redPacketTotal)
            {
                this.$ajax(
                    "post",
                    "Lottery/save_add_park_luck",
                    {
                        id: this.lottery_id,
                        park_id: this.park_id,
                        token: this.token,
                        title: this.redPacketSetTitle,
                        content: this.setContent,
                        money_: this.redPacketMoney,
                        total_:this.redPacketTotal,
                        user_id: this.user_id,
                        id_type_: this.id_type_,
                        id_: this.id_,
                        btime: this.redPacketBTime,
                        etime: this.redPacketETime,
                        times_: this.times_,
                        open_id: this.open_id,
                        condition_: this.condition_
                    },
                    res => {
                         console.log(res);
                        if (res.data.id == 0) {
                            let id = res.data.data.id;
                            //这里完成去支付
                            this.jumpToPay(id);
                        } else {
                            Toast("设置失败");
                        }
                    }
                );
            }
            else
            {
                //请求微信授权
                this.authorize();
            }
        }
        else
        {
            Toast("请在微信中打开");
        }
      },
    jumpToDetail() {
      if (this.is_weixin) {
        if (this.open_id && this.setTitle && this.money_) {
          this.$ajax(
            "post",
            "Lottery/save_park_luck_data",
            {
              id: this.lottery_id,
              park_id: this.park_id,
              token: this.token,
              title: this.setTitle,
              content: this.setContent,
              money_: this.money_,
              user_id: this.user_id,
              id_type_: this.id_type_,
              id_: this.id_,
              btime: this.btime,
              etime: this.etime,
              times_: this.times_,
              open_id: this.open_id,
              condition_: this.condition_
            },
            res => {
              // console.log(res);
              if (res.data.id == 0) {
                let id = res.data.data.id;
                this.$router.push({
                  path: "/publish/SetLottery",
                  query: {
                    id: id,
                    id_: this.id_,
                    id_type_: this.id_type_
                  }
                });
              } else {
                Toast("设置失败");
              }
            }
          );
        } else {
          //还未授权过
          this.authorize();
        }
      } else {
        Toast("请在微信中打开");
      }
    },
    // 上线加载更多
    onLoadBottom() {
      if (this.on_len % 10 !== 0 || this.onAllLoaded) {
        this.onAllLoaded = true;
        Toast("没有更多了");
      } else {
        this.on_page++;
        this.getMoreOnData();
      }
      this.$refs.loadmoreOn.onBottomLoaded();
    },
    // 下线加载更多
    offLoadBottom() {
      if (this.off_len % 10 !== 0 || this.offAllLoaded) {
        this.offAllLoaded = true;
        Toast("没有更多了");
      } else {
        this.off_page++;
        this.getMoreOffData();
      }
      this.$refs.loadmoreOff.onBottomLoaded();
    },
    // 提示删除
    showDel(i, type) {
      this.del_item.index = i;
      this.del_item.type = type;
      if (type == "online") {
        this.del_title = this.onlineList[i].title;
      } else if (type == "offline") {
        this.del_title = this.offlineList[i].title;
      }
      this.show_del = true;
    },
    // 删除
    delCase() {
      // console.log(this.del_item);
      let type = this.del_item.type;
      let i = this.del_item.index;
      let id;
      if (type == "offline") {
        id = this.offlineList[i].id;
      } else if (type == "online") {
        id = this.onlineList[i].id;
      }
      if (id && this.token && this.open_id) {
        this.$ajax(
          "post",
          "Lottery/set_park_luck_status",
          {
            id: id,
            token: this.token,
            open_id: this.open_id,
            where_: "is_delete"
          },
          res => {
            if (type == "offline") {
              this.offlineList.splice(i, 1);
            } else if (type == "online") {
              this.onlineList.splice(i, 1);
            }
            // console.log(res);
            if (res.data.id == 0) {
            } else {
              Toast("删除失败");
            }
          }
        );
      } else {
        //缺少的就是open_id
        //还未授权过
        this.authorize();
      }
    },

    // 支付方法
    jumpToPay(id) {
      if (
        this.id_ &&
        this.token &&
        this.park_id &&
        this.open_id &&
        id &&
        this.id_type_
      ) {
        var open_url =
          "https://www.zmlxj.com/api.php/Activity/web_pay_park_luck?token=" +
          this.token +
          "&id=" +
          id +
          "&park_id=" +
          this.park_id +
          "&open_id=" +
          this.open_id +
          "&id_type_=" +
          this.id_type_ +
          "&id_=" +
          this.id +
          +"&type_=publish";

        window.location.href = open_url;
      } else {
        this.authorize();
      }
    },
    // 上架
    upCase(i, id) {
      if (this.offlineList[i].money_ > 0 && this.offlineList[i].is_pay == 0) {
        // this.show_pay = true;
        // return;
        this.jumpToPay(id);
        return;
      }
      // console.log(this.offlineList[i]);
      // let id = this.offlineList[i].id;
      if (id && this.open_id && this.token) {
        this.$ajax(
          "post",
          "Lottery/set_park_luck_status",
          {
            id: id,
            token: this.token,
            open_id: this.open_id,
            where_: "is_online"
          },
          res => {
            // console.log(res);
            if (res.data.id == 0) {
              let item = this.offlineList.splice(i, 1);
              this.onlineList.unshift(item[0]);
            } else {
              Toast("设置失败");
            }
          }
        );
      } else {
        //缺少的就是open_id
        //还未授权过
        this.authorize();
      }
    },
    // 下架
    lowerCase(i) {
      // console.log(this.offlineList[i]);
      let id = this.onlineList[i].id;
      if (id && this.open_id && this.token) {
        this.$ajax(
          "post",
          "Lottery/set_park_luck_status",
          {
            id: id,
            token: this.token,
            open_id: this.open_id,
            where_: "is_offline"
          },
          res => {
            // console.log(res);
            if (res.data.id == 0) {
              let item = this.onlineList.splice(i, 1);
              this.offlineList.unshift(item[0]);
            } else {
              Toast("设置失败");
            }
          }
        );
      } else {
        //缺少的就是open_id
        //还未授权过
        this.authorize();
      }
    },
    // 编辑
    edit(i, type,is_lottery) {
      this.setType = 2;
      let list = [];
      if (type == "online") {
        list = this.onlineList;
      } else if (type == "offline") {
        list = this.offlineList;
      }
      this.setting_title = "编辑基本属性";
      this.setTitle = list[i].title;
      this.btime = list[i].btime;
      this.etime = list[i].etime;
      this.times_ = list[i].times_;
      this.lottery_id = list[i].id;
      this.content = list[i].content;
      let condition = list[i].condition_;

      if (condition == "share") {
        this.shareFlag = true;
        this.questionFlag = false;
      } else if (condition == "question") {
        this.shareFlag = false;
        this.questionFlag = true;
      } else if (condition == "all") {
        this.shareFlag = true;
        this.questionFlag = true;
      }
      // console.log(this.condition_);
      // console.log(this.shareFlag);
      // console.log(this.questionFlag);
        //在这里判断弹出哪一种类型的弹框,同时也在这里设置分享获取红包的数据字段的值
        this.redPacketMoney = list[i].money_;
            this.redPacketTotal = list[i].total_;
            this.redPacketBTime=list[i].btime;
            this.redPacketETime=list[i].etime;
            this.redPacketSetTitle=list[i].title;
        if(is_lottery == 1)
        {
            this.showSetting = true;
        }
        else
        {
            this.showSettingRedPacket = true;
        }

    },
    // 新建
    newBuild() {
      this.setType = 1;
      this.setting_title = "基本属性设置";

      let t = new Date()
        .toLocaleDateString()
        .split("/")
        .map(item => {
          if (item.length <= 1) {
            return "0" + item;
          }
          return item;
        });
      this.btime = this.etime = t.join("-");

      this.setTitle = "";
      // this.btime = "";
      // this.etime = "";
      this.times_ = 1;
      this.lottery_id = 0;
      this.content = this.setContent;
      this.shareFlag = true;
      this.questionFlag = false;
      this.showSetting = true;
    },
      newBuildRedPacket() {
      this.setType = 1;
      this.setting_title = "分享好友领取红包基本属性设置";
          console.log(this.lottery_id,this.redPacketSetTitle,
              this.redPacketMoney,this.redPacketTotal,
              this.redPacketBTime,this.redPacketETime);

      let t = new Date()
        .toLocaleDateString()
        .split("/")
        .map(item => {
          if (item.length <= 1) {
            return "0" + item;
          }
          return item;
        });
      this.btime = this.etime = t.join("-");

      this.redPacketSetTitle = "";
      this.redPacketMoney = 0.3;//表示领取红包的金额
      this.redPacketTotal = 10;//表示10个
      this.times_ = 0;//表示可以无限次活动红包，只要分享出去有人访问就可以获取红包
      this.lottery_id = 0;
      this.content = this.setContent;
      this.shareFlag = true;
      this.questionFlag = false;
      this.showSettingRedPacket = true;
    },
    // 检查文本域
    checkTextArea() {
      if (this.currentLen > this.totalLen) {
        this.$refs.tips.innerHTML = "文本域长度超出限制";
        this.showTips = true;
        this.tipsTimer = setTimeout(() => {
          this.showTips = false;
        }, 3000);
      } else {
        this.showTips = false;
      }
    },
    // 检查条件
    checkCondition(n) {
      if (n && n == 1) {
        this.shareFlag = !this.shareFlag;
      } else if (n && n == 2) {
        this.questionFlag = !this.questionFlag;
      }
      if (!this.shareFlag && !this.questionFlag) {
        this.$refs.tips.innerHTML = "请选择一项条件";
        this.showTips = true;
        this.tipsTimer = setTimeout(() => {
          this.tipsTimer = false;
        }, 3000);
      } else {
        this.showTips = false;
      }
      // console.log(this.condition_);
    },
    //   检查名称
    checkName() {
      let len = this.setTitle.length;
      //   this.$refs.tips.innerHTML = "";
      if (len && (len < this.minTitleSize || len > this.maxTitleSize)) {
        this.$refs.tips.innerHTML = "名称长度设置错误";
        this.showTips = true;
        this.tipsTimer = setTimeout(() => {
          this.showTips = false;
        }, 3000);
      } else {
        this.showTips = false;
      }
    },
//   检查名称
      redPacketCheckName() {
          let len = this.redPacketSetTitle.length;
          //   this.$refs.tips.innerHTML = "";
          if (len && (len < this.minTitleSize || len > this.maxTitleSize)) {
              this.$refs.tips.innerHTML = "名称长度设置错误";
              this.showTips = true;
              this.tipsTimer = setTimeout(() => {
                  this.showTips = false;
              }, 3000);
          } else {
              this.showTips = false;
          }
      },
    getMoreOnData() {
      if (this.id_ && this.token && this.id_type_ && this.on_page) {
        this.$ajax(
          "post",
          "Lottery/get_park_luck_list",
          {
            id_: this.id_,
            token: this.token,
            id_type_: this.id_type_,
            where_: "is_online",
            page: this.on_page
          },
          res => {
            // console.log(res);
            if (res.data.id == 0) {
              let info = res.data.data.data;
              this.on_len = info.length;
              this.onlineList = this.onlineList.concat(info);
              this.setStatusColor(this.onlineList);
            } else {
              Toast("加载完成");
              this.onAllLoaded = true;
              // this.$refs.loadmoreOn.onBottomLoaded();
            }
          }
        );
      } else {
        //缺少的就是open_id
        //还未授权过
        this.authorize();
      }
    },
    // 加载更多下线数据
    getMoreOffData() {
      if (this.id_ && this.token && this.id_type_ && this.off_page) {
        this.$ajax(
          "post",
          "Lottery/get_park_luck_list",
          {
            id_: this.id_,
            token: this.token,
            id_type_: this.id_type_,
            where_: "is_offline",
            page: this.off_page
          },
          res => {
            console.log(res);
            if (res.data.id == 0) {
              let info = res.data.data.data;
              this.off_len = info.length;
              this.offlineList = this.offlineList.concat(info);
              this.setStatusColor(this.offlineList);
              // console.log(info);
              // this.onlineList = res.data.data.online_list;
              // this.offlineList = res.data.data.offline_list;
            } else {
              Toast("加载完成");
              this.offAllLoaded = true;
              // this.$refs.loadmoreOff.onBottomLoaded();
            }
          }
        );
      } else {
        //缺少的就是open_id
        //还未授权过
        this.authorize();
      }
    },
    // 请求数据
    getData() {
      if (this.id_ && this.token && this.id_type_) {
        this.$ajax(
          "post",
          "Lottery/get_park_luck_list",
          {
            id_: this.id_,
            token: this.token,
            id_type_: this.id_type_,
            where_: "is_online",
            page: 1
          },
          res => {
            if (res.data.id == 0) {
              localStorage.setItem("wx_title", res.data.data.advert.title);
              localStorage.setItem("wx_content", res.data.data.advert.content);
              localStorage.setItem("wx_img", res.data.data.advert.newUrl);

              this.onlineList = res.data.data.online_list;
              this.on_len = this.onlineList.length;
              this.offlineList = res.data.data.offline_list;
              this.off_len = this.offlineList.length;
              this.advert = res.data.data.advert;
              this.setStatusColor(this.offlineList);
              this.setStatusColor(this.onlineList);
              // console.log(this.offlineList);
            } else {
              Toast("没有数据");
            }
          }
        );
      } else {
        //缺少的就是open_id
        //还未授权过
        this.authorize();
      }
    },
    setStatusColor(arr) {
      arr.forEach(item => {
        if (item.status == "未开始") {
          item.statusColor = "#67C23A";
        } else if (item.status == "已过期") {
          item.statusColor = "#666";
        } else if (item.status == "进行中") {
          item.statusColor = "#e33";
        }
      });
    }
  }
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

.publish_container {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: #eee;
  padding-top: 2.4rem;
  box-sizing: border-box;
}

/* 目录 */
.publish_container .publish_header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 2.4rem;
  overflow-x: auto;
  background-color: #fff;
}
.publish_container .publish_header::-webkit-scrollbar {
  display: none;
}
.publish_container .publish_header .header_wrapper {
  /* height: 100%; */
  width: 100%;
  /* width: 28rem; */
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.publish_header .header_wrapper .header_item {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
}
.publish_header .header_wrapper .header_item span {
  height: 2.4rem;
  width: 100%;
  line-height: 2.4rem;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  color: #333;
  font-size: 0.88rem;
  border-bottom: 1px solid transparent;
}
.publish_header .header_wrapper .header_item.active span {
  border-bottom: 1px solid rgb(230, 50, 50);
  color: rgb(230, 50, 50);
  transition: 0.5s;
}

/* 内容 */
.publish_container .publish_published {
  width: 100%;
  margin-bottom: 0.6rem;
  /* margin-top: 2.8rem; */
}
.publish_container .publish_published .published_wrapper {
  width: 100%;
}
.publish_published .published_wrapper .publish_group {
  position: relative;
}
.publish_published .published_wrapper .published_item {
  width: 100%;
  margin-bottom: 2px;
  padding: 0 0.5rem;
  box-sizing: border-box;
  background-color: #fff;
  transition: 0.5s;
}
.publish_published .published_wrapper .published_item .item_display {
  width: 100%;
  display: flex;
  color: #666;
}
.published_header {
  width: 100%;
  margin-bottom: 2px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  height: 2.4rem;
  color: #000;
  margin-top: 0.4rem;
}
.published_header span {
  display: inline-block;
  /* height: 2.4rem;
  line-height: 2.4rem; */
  text-align: center;
}
.published_header .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 28%;
}
.published_header .time {
  width: 52%;
}
.published_header .number {
  width: 20%;
}
.published_banner {
  /* margin-top: 2.8rem; */
  width: 100%;
  background-color: #fff;
  margin-top: 0.4rem;
}
.published_banner img {
  width: 100%;
}
.published_wrapper .published_item .item_display {
  position: relative;
  /* border-bottom: 1px solid #eee; */
}
.published_wrapper .published_item .item_display > span {
  display: inline-block;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
}
.publish_published .published_wrapper span.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 28%;
  color: #333;
  text-align: left;
  /* font-size: 0.8rem; */
}
.publish_published .published_wrapper .time {
  width: 52%;
}
.publish_published .published_wrapper .number {
  width: 20%;
  font-size: 0.8rem;
  color: rgb(167, 4, 4);
}
.publish_published .published_wrapper .item_handle {
  position: relative;
  width: 100%;
  height: 2.4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}
.publish_published .published_wrapper .item_handle .handle_btn {
  padding: 4px 8px;
  /* font-size: 0.8rem; */
  border-radius: 4px;
  color: #666;
  background: transparent;
  /* box-shadow: 0 0 2px #666; */
  border: 1px solid #ddd;
  margin-right: 0.5rem;
}
.publish_published .published_wrapper .item_handle .red_btn {
  background: rgb(230, 50, 50);
  color: #fff;
  border: 1px solid rgb(230, 50, 50);
}
.publish_published .published_wrapper .item_status {
  position: absolute;
  /* width: 28%; */
  left: 0;
  bottom: -1.6rem;
  text-align: center;
  opacity: 1;
}
.publish_published .published_wrapper .item_status span {
  color: #fff;
  /* border: 1px solid rgb(230, 50, 50); */
  font-size: 0.5rem;
  display: inline-block;
  padding: 0 2px;
  background-color: #666;

  /* border-radius: 4px; */
  /* border: 1px solid #ccc; */
}

/* 新建->变成转盘抽奖 */
.publish_container .publish_new_lottery {
  position: fixed;
  left: calc(50% - 4rem);
  bottom: 1rem;
  width: 4rem;
  height: 2rem;
  background-color: rgb(230, 50, 50);
  border-radius: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 4px rgb(221, 43, 43);
}
.publish_container .publish_new_lottery span {
  color: #fff;
  font-size: 0.88rem;
  font-weight: 600;
}
/* 新增分享领红包 */
.publish_container .publish_new_red_packet {
  position: fixed;
  left: calc(50% + 0.2rem);
  bottom: 1rem;
  width: 4rem;
  height: 2rem;
  background-color: rgb(230, 50, 50);
  border-radius: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 4px rgb(221, 43, 43);
}
.publish_container .publish_new_red_packet span {
  color: #fff;
  font-size: 0.88rem;
  font-weight: 600;
}
.newBtn-enter,
.newBtn-leave-to {
  opacity: 0;
  transform: scale(0);
}
.newBtn-enter-to,
.newBtn-leave {
  opacity: 1;
  transform: scale(1);
}
.newBtn-enter-active,
.newBtn-leave-active {
  transition: all 0.5s;
}
/*  */
.set-enter,
.set-leave-to {
  opacity: 0;
}
.set-enter-to,
.set-leave {
  opacity: 1;
}
.set-enter-active,
.set-leave-active {
  transition: ease 0.3s;
}
.publish_container .publish_setting {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem;
}
.publish_container .publish_setting .setting_tips {
  position: absolute;
  width: 100%;
  /* height: 2rem; */
  top: 0.4rem;
  left: 0;
  text-align: center;
}
.publish_container .publish_setting .setting_tips .tips_text {
  display: inline-block;
  padding: 6px 20px;
  color: #fff;
  font-size: 0.8rem;
  background-color: rgba(230, 30, 30, 1);
}

.tips-enter,
.tips-leave-to {
  transform: translateY(-20%);
  opacity: 0;
}
.tips-enter-to,
.tips-leave {
  transform: translateY(0);
  opacity: 1;
}
.tips-enter-active,
.tips-leave-active {
  transition: 0.3s ease;
}
.publish_container .publish_setting .setting_wrapper {
  position: relative;
  width: 100%;
  /* max-height: 80vh; */
  background-color: #fff;
  padding: 0.5rem 0.4rem;
  box-sizing: border-box;
  border-radius: 4px;
}
.publish_setting .setting_wrapper .setting_content_wrapper {
  overflow-y: auto;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.publish_container .publish_setting .setting_wrapper .setting_content {
  width: 100%;
  max-height: 60vh;
}
.publish_container .publish_setting .setting_wrapper .setting_close {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  background: url(../../assets/img/pm_close.png) no-repeat;
  background-size: contain;
  z-index: 1;
}
.publish_container .publish_setting .setting_wrapper .setting_header {
  text-align: center;
  /* margin-bottom: 0.4rem; */
  border-bottom: 1px solid #eee;
}
.publish_setting .setting_wrapper .setting_header h3 {
  font-size: 0.88rem;
  color: #333;
  height: 2rem;
  line-height: 2rem;
}
.publish_setting .setting_wrapper .setting_item {
  height: 2.4rem;
  line-height: 2.4rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.publish_setting .setting_wrapper .setting_rule {
  position: relative;
  box-sizing: border-box;
  padding: 0 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #333;
  /* margin-top: .2rem; */
}
.publish_setting .setting_wrapper .setting_rule .rule {
  width: 4.8rem;
  font-size: 0.8rem;
  display: inline-block;
  height: 2.4rem;
  line-height: 2.4rem;
}
.publish_setting .setting_wrapper .setting_rule textarea {
  width: 80%;
  resize: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  color: #666;
  overflow-x: hidden;
  /* padding-bottom: 0.8rem; */
}
.publish_setting .setting_wrapper .setting_rule .area_count {
  position: absolute;
  display: inline-block;
  padding: 2px;
  font-size: 0.6rem;
  background-color: rgba(255, 255, 255, 0.9);
  color: #999;
  left: 0.4rem;
  top: 2rem;
}
.publish_setting .setting_wrapper .setting_item span {
  font-size: 0.8rem;
  width: 4.8rem;
  color: #333;
}
.publish_setting .setting_wrapper .setting_item input {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 2rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  outline: none;
  padding: 0 0.4rem;
  width: 80%;
}
.publish_setting .setting_wrapper .setting_item .setType {
  width: 1rem;
  height: 1rem;
  visibility: hidden;
}
.publish_setting .setting_wrapper .setting_item .setType + label {
  color: #666;
  position: relative;
  width: 20%;
}
.publish_setting .setting_wrapper .setting_item .setType + label::before {
  content: "";
  display: block;
  position: absolute;
  top: 0.6rem;
  left: -1.2rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  background-color: rgb(230, 50, 50);
}

.setting_wrapper .setting_item .setType.checked + label::before {
  content: "\2714";
  color: #fff;
  font-size: 0.8rem;
  text-align: center;
}
.publish_setting .setting_wrapper .setting_submit {
  width: 100%;
  box-sizing: border-box;
  padding: 0.4rem;
  margin-top: 0.4rem;
  border-top: 1px solid #eee;
}
.publish_setting .setting_wrapper .setting_submit button {
  width: 100%;
  height: 2.4rem;
  white-space: nowrap;
  border: none;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  font-weight: 500;
  border-radius: 4px;
  font-size: 0.88rem;
  background-color: rgb(226, 46, 38);
  color: #fff;
}
/* foot */
.publish_container .publish_footer {
  position: relative;
  width: 100%;
  height: 6rem;
  margin-top: 1.5rem;
}

.publish_container .publish_footer .footer_content {
  position: relative;
  width: 80%;
  margin: 0 auto;
  height: 1px;
  background-color: #ddd;
  /* height: 6rem; */
  text-align: center;
}
.publish_container .publish_footer .footer_content span {
  display: inline-block;
  position: relative;
  background-color: #eee;
  height: 1rem;
  line-height: 1rem;
  padding: 0 6px;
  top: -0.5rem;
  color: #999;
}
/* 删除 */

.publish_container .publish_check {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: transparent; */
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.publish_container .publish_check .check_wrapper {
  width: 80%;
  /* height: 40vh; */
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #eee;
  box-shadow: 0 0 2px #aaa;
}
.publish_container .publish_check .check_wrapper .check_header {
  text-align: center;
  color: #333;
  font-size: 0.8rem;
  height: 2rem;
  line-height: 2rem;
}
.publish_check .check_wrapper .check_header .check_title {
  color: rgb(230, 66, 66);
}
.publish_check .check_wrapper .check_btn {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_wrapper button {
  padding: 6px 20px;
  white-space: nowrap;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  font-weight: 500;
  border-radius: 4px;
  font-size: 0.88rem;
}
.btn_wrapper .btn_yes {
  background-color: rgb(226, 46, 38);
  color: #fff;
  margin-right: 1rem;
  border: 1px solid rgb(226, 46, 38);
}
.btn_wrapper .btn_no {
  background-color: transparent;
  color: #333;
  /* margin-right: 1rem; */
  border: 1px solid #ccc;
}

.check-enter,
.check-leave-to {
  opacity: 0;
}
.check-enter-to,
.check-leave {
  opacity: 1;
}
.check-enter-active,
.check-leave-active {
  transition: ease 0.3s;
}

/* 上架支付 */
.publish_container .publish_checkpay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.publish_container .publish_checkpay .checkpay_wrapper {
  width: 80%;
  height: 20vh;
  position: relative;
  background-color: #fff;
}
/* 上架下架动画 */
/* .publish-enter-to, .publish-leave{
  opacity: 1;
} */
/* .publish-enter-active, .publish-leave-active{
  transition: 1s;
} */
/* .publish-move{
  transition: 1s;
} */
.upcase-enter,
.upcase-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.upcase-leave-active {
  position: absolute;
}
.lowercase-enter,
.lowercase-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.lowercase-leave-active {
  position: absolute;
}
</style>
