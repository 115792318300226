<template>
    <!--路书列表详情-->
    <ul class="LineInfoContent LineInfoList_list_box"  v-if="!is_app">
        <li v-for="(k,i) in info" :key="k.id" :id="k.id"
            @click="click_path_new(k.is_spots,k.id)">
            <!--:to="{path:'/footprints/FootprintsParticulars',query:{user_id:this.user_id,id:k.id}}"-->
            <div class="text_box">
                <div class="serial_number fl">{{i + 1}}</div>
                <div class="info_box">
                    <router-link :to="{name:'otherIndex', params: { uid: k.user_id }}"
                                 v-if="k.user_id != user_id" tag="div" class="user_info clearfix">
                        <img class="fl" alt="" v-lazy="k.user_icon" v-if="k.user_icon">
                        <h2>{{k.user_nick}}</h2>
                    </router-link>
                    <div class="user_info clearfix" v-else>
                        <img class="fl" alt="" v-lazy="k.user_icon" v-if="k.user_icon">
                        <h2>{{k.user_nick}}</h2>
                    </div>
                    <div class="title"><span v-html="k.spots_name" :class="{active:is_maxheight}"></span></div>
                    <span class='more' @click="is_maxheight=!is_maxheight"><span v-if="!is_maxheight">收齐</span></span>
                </div>
            </div>
            <div class="img_box">
                <div class="img_numbers"><span>{{k.img_numbers}}张图片</span></div>
                <img v-lazy="k.newUrl" v-if="k.newUrl" alt="">
                <p><span class="fl">距离 {{k.length}}</span>海拔{{k.height}}<span
                        class="fr">时间 {{k.spots_time}}</span></p>
            </div>
        </li>
        <!--<li v-for="(k,i) in info" :key="k.id" :id="k.id" v-if="is_app">-->
            <!--<div class="text_box">-->
                <!--<div class="serial_number fl">{{i + 1}}</div>-->
                <!--<div class="info_box">-->
                    <!--<a v-if="k.user_id != user_id" tag="div" class="user_info clearfix">-->
                        <!--<img class="fl" alt="" v-lazy="k.user_icon" v-if="k.user_icon">-->
                        <!--<h2>{{k.user_nick}}</h2>-->
                    <!--</a>-->
                    <!--<div class="user_info clearfix" v-else>-->
                        <!--<img class="fl" alt="" v-lazy="k.user_icon" v-if="k.user_icon">-->
                        <!--<h2>{{k.user_nick}}</h2>-->
                    <!--</div>-->
                    <!--<div class="title"><span v-html="k.spots_name" :class="{active:is_maxheight}"></span></div>-->
                    <!--<span class='more' @click="is_maxheight=!is_maxheight"><span v-if="!is_maxheight">收齐</span></span>-->
                <!--</div>-->
            <!--</div>-->
            <!--<div class="img_box">-->
                <!--<div class="img_numbers"><span>{{k.img_numbers}}张图片</span></div>-->
                <!--<img v-lazy="k.newUrl" v-if="k.newUrl" alt="">-->
                <!--<p><span class="fl">距离 {{k.length}}</span>海拔{{k.height}}<span-->
                        <!--class="fr">时间 {{k.spots_time}}</span></p>-->
            <!--</div>-->
        <!--</li>-->
    </ul>
</template>
<script>
    export default {
        props: {
            datainfo: Array,
            user_id: String,
            is_app: Boolean,
        },
        data(){
            return {
                info: [],
                is_maxheight: true,
                label_id: this.$route.query.label_id ? this.$route.query.label_id : 0,
            }
        },
        mounted(){
            this.unload();
        },
        methods: {
            unload(){
                this.info = this.datainfo
            },
            click_path_new(is_spots, id){
                if(!this.is_app){
                    if (true) {
                        this.$router.push({path: '/footprints/PrimaryScenic', query: {sid: id, id_: this.label_id }})
                    } else {
                        this.$router.push({
                            path: '/footprints/FootprintsParticulars',
                            query: {user_id: this.user_id, id: id}
                        })
                    }
                }
            }
        }
    }
</script>

<style  scoped>
.LineInfoList_list_box {
        padding: 5px 10px;
    }

    .LineInfoList_list_box li {
        padding: 5px 0;
    }

    .LineInfoList_list_box li .serial_number {
        width: 2rem;
        text-align: center;
        font-weight: 700;
        font-size: 1rem;
        height: 1.6rem;
        line-height: 1.6rem;
    }

    .LineInfoList_list_box li .info_box {
        overflow: hidden;
    }

    .LineInfoList_list_box li .info_box .user_info h2 {
        font-size: 1rem;
        overflow: hidden;
        height: 1.6rem;
        line-height: 1.6rem;
        font-weight: 700;
    }

    .LineInfoList_list_box li .info_box .user_info img {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        margin-right: 10px;
    }

    .LineInfoList_list_box li .title {
        padding-top: 5px;
        line-height: 1rem;
        overflow: hidden;
        position: relative;
    }

    .LineInfoList_list_box li .title span {
        font-weight: 700;
        background: url(../assets/img/track_name.png) left 3px no-repeat;
        background-size: 1.1rem;
        font-size: .7rem;
        color: #666;
        padding: 5px 0 5px 40px;
        margin-right: 10px;
        float: left;
    }

    .LineInfoList_list_box li .title span.active {
        max-height: 2.5rem;
    }

    .LineInfoList_list_box li span.more {
        background: #fff;
        padding: .2rem .4rem;
        color: #ff9a80;
        float: right;
    }

    .LineInfoList_list_box li .img_box {
        margin-top: 4px;
        position: relative;
    }

    .LineInfoList_list_box li .img_box p {
        text-align: center;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff;
        height: 36px;
        line-height: 36px;
        padding: 0 10px;
    }

    .LineInfoList_list_box li .img_box img {
        width: 100%;
        min-height: 263.78px;
        vertical-align: middle;
    }

    .LineInfoList_list_box .img_numbers {
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        color: #fff;
        text-align: center;
        font-size: 1.2rem;
    }

    .LineInfoList_list_box .img_numbers span {
        display: inline-block;
        padding: 10px 20px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.4);
    }
    .LineInfoList_list_box li .info_box {
        overflow: hidden;
    }

    .LineInfoList_list_box li .info_box .user_info h2 {
        font-size: 1rem;
        overflow: hidden;
        height: 1.6rem;
        line-height: 1.6rem;
        font-weight: 700;
    }

    .LineInfoList_list_box li .info_box .user_info img {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        margin-right: 10px;
    }
</style>