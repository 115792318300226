<template>
  <div v-title data-title="选择地质公园">
    <mt-header fixed title="选择地质公园">
      <router-link to="/Guide" slot="left">
        <mt-button icon="back"></mt-button>
      </router-link>
    </mt-header>

    <div class="page-content">
      <mt-index-list id="park-select-list">
        <mt-index-section :index="item.index" v-for="item in items" :key="item.index">
          <router-link
            class="go-guide"
            v-for="park in item.data"
            :key="park.id"
            :to="{path:'/Guide',query:{pid:park.id,pname:park.park_name}}"
          >
            <mt-cell :title="park.park_name" :pid="park.id"></mt-cell>
          </router-link>
        </mt-index-section>
      </mt-index-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: []
    };
  },
  mounted() {
    this.get_park();
  },
  methods: {
    get_park() {
      var that = this;
      this.$ajax("post", "Service/get_park", { web: 1 }, function(res) {
        console.log(res.data.data);
        that.items = res.data.data.normal;
        that.items.sort(function(s, t) {
          var a = s.index;
          var b = t.index;
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
      });
    }
  }
};
</script>

<style scoped>
.page-content {
  padding-bottom: 0;
}

.go-guide {
  color: #666;
}
</style>