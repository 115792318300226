<template>
  <div class="stats_container" v-if="info.join_numbers > 0 || info.looks_numbers > 0 || info.share_numbers > 0 || info.win_numbers > 0">
    <!-- 统计信息 -->
    <div class="stats_wrapper width-768" :class="stats_position ? 'stats_wrapper_fixed' : ''">
      <div class="stats_content">
        <div
          class="stats_item"
          :class="statsActive == 1 ? 'stats_active' : ''"
          @click="scrollBottom(1)"
        >
          <span class="stats_name">{{ language == 1 ? '访问次数' : (language == 2 ? 'Visit' : '访问次数')}}</span>
          <span class="stats_number">{{info.looks_numbers}}</span>
        </div>
        <div
          class="stats_item"
          :class="statsActive == 2 ? 'stats_active' : ''"
          @click="scrollBottom(2)"
        >
          <span class="stats_name">{{ language == 1 ? '分享次数' : (language == 2 ? 'Share' : '分享次数')}}</span>
          <span class="stats_number">{{info.share_numbers}}</span>
        </div>
        <div
          v-if="info.have_red_packet_rain || info.have_red_packet || (info.join_numbers && info.join_numbers != '0')"
          class="stats_item"
          :class="statsActive == 3 ? 'stats_active' : ''"
          @click="scrollBottom(3)"
        >
          <span class="stats_name">{{ language == 1 ? '参与次数' : (language == 2 ? 'Join' : '参与次数')}}</span>
          <span class="stats_number">{{info.join_numbers}}</span>
        </div>
        <div
          v-if="info.have_red_packet_rain || info.have_red_packet || (info.win_numbers && info.win_numbers != '0')"
          class="stats_item"
          :class="statsActive == 4 ? 'stats_active' : ''"
          @click="scrollBottom(4)"
        >
          <span class="stats_name">{{ language == 1 ? '中奖次数' : (language == 2 ? 'Win' : '中奖次数')}}</span>
          <span class="stats_number">{{info.win_numbers}}</span>
        </div>
      </div>
    </div>
    <!-- 统计信息 -->
    <div class="stats_wrapper" v-show="stats_position"></div>
    <div class="anchor_point" ref="anchor_point">
      <!-- 这是一个锚点 -->
    </div>
    <!--用户浏览数据-->
    <!-- 访问人数 -->
    <transition name="stats">
      <div class="looks-list stats-list" v-show="!statsActive || statsActive==1">
        <div class="stats-list-body" v-if="info.looks_user_list != ''">
          <div class="stats-list-item" v-for="(k,i) in info.looks_user_list" :key="i">
            <div class="list-item-icon">
              <img :src="k.user_icon" v-if="k.user_icon" />
              <img src="../assets/img/apply_avatar_bg.png" v-else-if="!k.user_icon" />
            </div>
            <div class="list-item-content">
              <span class="list-item-content-name">{{k.user_nick}}</span>
              <!-- <span>&nbsp;访问了{{k.numbers}}次</span> -->
            </div>
            <div class="list-item-count">
              <!-- <span class="count-progress-hold"></span> -->
              <span
                class="count-progress"
                :style="computedWidth(k.numbers, info.looks_user_list[0].numbers)"
              ></span>
              <span class="count-number">{{k.numbers}}</span>
            </div>
          </div>
        </div>

        <div class="stats-list-footer">
          <span
            class="footer_text"
            @click="loadMoreData('looks')"
          >{{language == 1 ? looks_data_bottom : (language == 2 ? looks_data_bottom_en : looks_data_bottom)}}</span>
        </div>
      </div>
    </transition>
    <!-- 分享人数 -->
    <transition name="stats">
      <div class="share-list stats-list" v-show="statsActive==2">

        <div class="stats-list-body" v-if="info.share_user_list">

          <div class="stats-list-item" v-for="(k,i) in info.share_user_list" :key="i">
            <div class="list-item-icon">
              <img :src="k.user_icon" v-if="k.user_icon" />
              <img src="../assets/img/apply_avatar_bg.png" v-else-if="!k.user_icon" />
            </div>
            <div class="list-item-content">
              <span class="list-item-content-name">{{k.user_nick}}</span>
              <!-- <span>&nbsp;访问了{{k.numbers}}次</span> -->
            </div>
            <div class="list-item-count">
              <!-- <span class="count-progress-hold"></span> -->
              <span
                class="count-progress"
                :style="computedWidth(k.numbers, info.share_user_list[0].numbers)"
              ></span>
              <span class="count-number">{{k.numbers}}</span>
            </div>
          </div>

        </div>
        <div class="stats-list-footer">
          <span
            class="footer_text"
            @click="loadMoreData('share')"
          >{{language == 1 ? share_data_bottom : (language == 2 ? share_data_bottom_en : share_data_bottom)}}</span>
        </div>
      </div>
    </transition>
    <!-- 参与人数 -->
    <transition name="stats">
      <div class="join-list stats-list" v-show="statsActive==3">
        <div class="stats-list-body" v-if="info.join_user_list">

            <div class="stats-list-item" v-for="(k,i) in info.join_user_list" :key="i">
              <div class="list-item-icon">
                <img :src="k.user_icon" v-if="k.user_icon" />
                <img src="../assets/img/apply_avatar_bg.png" v-else-if="!k.user_icon" />
              </div>
              <div class="list-item-content">
                <span class="list-item-content-name">{{k.user_nick}}</span>
                <!-- <span>&nbsp;访问了{{k.numbers}}次</span> -->
              </div>
              <div class="list-item-count">
                <!-- <span class="count-progress-hold"></span> -->
                <span
                  class="count-progress"
                  :style="computedWidth(k.numbers, info.join_user_list[0].numbers)"
                ></span>
                <span class="count-number">{{k.numbers}}</span>
              </div>
            </div>

        </div>
        <div class="stats-list-footer">
          <span
            class="footer_text"
            @click="loadMoreData('join')"
          >{{language == 1 ? join_data_bottom : (language == 2 ? join_data_bottom_en : join_data_bottom)}}</span>
        </div>
      </div>
    </transition>
    <!-- 中奖人数 -->
    <transition name="stats">
      <div class="win-list stats-list" v-show="statsActive==4">
        <div class="jl-body" v-if="info.win_user_list">

          <div class="jl-item" v-for="(k,i) in info.win_user_list" :key="i">
            <img :src="k.user_icon" v-if="k.user_icon" class="jl-icon" />
            <img src="../assets/img/apply_avatar_bg.png" v-else-if="!k.user_icon" class="jl-icon" />
            <span class="jl-name">
              <span>{{filterByNick(k.user_nick)}}</span>
              <span>&nbsp;抽中&nbsp;{{k.title}}&nbsp;元</span>
            </span>
            <span class="jl-time">{{k.add_time}}</span>
          </div>

        </div>
        <div class="stats-list-footer">
          <span
            class="footer_text"
            @click="loadMoreData('win')"
          >{{ language == 1 ? win_data_bottom : (language == 2 ? win_data_bottom_en : win_data_bottom)}}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Toast } from 'mint-ui'
export default {
  props: {
    info: {
      type: Object,
      required: true
    },
    language: {
      type: [String, Number]
    },
    id_type_: {
      type: String
    }
  },
  data () {
    return {
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      park_id: 298,
      user_id: '',
      // 底部用户数据
      statsActive: 0,
      // 访问
      looks_list: '',
      looks_numbers: 0,
      looks_data_page: 1,
      looks_data_bottom: '点击查看更多',
      looks_data_bottom_en: 'load more',
      looks_data_len: 1,
      looks_data_max: '',
      // 分享
      share_list: '',
      share_numbers: 0,
      share_data_page: 1,
      share_data_bottom: '点击查看更多',
      share_data_bottom_en: 'load more',
      share_data_len: 1,
      share_data_max: '',
      // 参与
      join_list: '',
      join_numbers: 0,
      join_data_page: 1,
      join_data_bottom: '点击查看更多',
      join_data_bottom_en: 'load more',
      join_data_len: 1,
      join_data_max: '',
      // 中奖
      win_list: '',
      win_numbers: 0,
      win_data_page: 1,
      win_data_bottom: '点击查看更多',
      win_data_bottom_en: 'load more',
      win_data_len: 1,
      win_data_max: '',
      // 统计数据样式
      stats_position: true,
      anchorHeight: '', // 锚点高度
      statsHeight: '' // 数据条高度
    }
  },

  mounted () {
    // 在冒泡阶段处理滚动的事件
    window.addEventListener('scroll', this.throttle(this.handleScroll))
  },

  methods: {
    // 计算进度条宽度
    computedWidth (num, max) {
      let width = (num / max) * 100 >= 6 ? (num / max) * 100 : 6
      return {
        width: width + '%'
      }
    },
    // 加载更多数据
    loadMoreData (dataType) {
      let where_, page
      if (dataType === 'looks') {
        // this.looks_data_len % 10 !== 0 ||
        if (
          (this.info.looks_user_list !== '' &&
            this.info.looks_user_list.length === 0) ||
          !this.info.looks_user_list || this.info.looks_user_list === '' ||
          this.looks_data_len === 0
        ) {
          this.looks_data_bottom = '我是有底线的'
          this.looks_data_bottom_en = 'nothing more'

          return
        } else {
          where_ = 'looks'
          page = ++this.looks_data_page
        }
      } else if (dataType === 'share') {
        // this.share_data_len % 10 !== 0 ||
        if (
          (this.info.share_user_list !== '' &&
            this.info.share_user_list.length === 0) ||
          !this.info.share_user_list || this.info.share_user_list === '' ||
          this.share_data_len === 0
        ) {
          this.share_data_bottom = '我是有底线的'
          this.share_data_bottom_en = 'nothing more'
          return
        } else {
          where_ = 'share'
          page = ++this.share_data_page
        }
      } else if (dataType === 'join') {
        // this.join_data_len % 10 !== 0 ||
        if (
          (this.info.join_user_list !== '' &&
            this.info.join_user_list.length === 0) ||
          !this.info.join_user_list || this.info.join_user_list === '' ||
          this.join_data_len === 0
        ) {
          this.join_data_bottom = '我是有底线的'
          this.join_data_bottom_en = 'nothing more'
          return
        } else {
          where_ = 'join'
          page = ++this.join_data_page
        }
      } else if (dataType === 'win') {
        // this.win_data_len % 10 !== 0 ||
        if (
          (this.info.win_user_list !== '' &&
            this.info.win_user_list.length === 0) ||
          !this.info.win_user_list || this.info.win_user_list === '' ||
          this.win_data_len === 0
        ) {
          this.win_data_bottom = '我是有底线的'
          this.win_data_bottom_en = 'nothing more'
          return
        } else {
          where_ = 'win'
          page = ++this.win_data_page
        }
      }

      // this.getPayMoneyData();
      let id_ = this.info.id

      if (this.open_id && this.token && this.park_id && id_ && this.id_type_) {
        this.$ajax(
          'post',
          'Lottery/get_four_type_user_list',
          {
            id_: id_,
            id_type_: this.id_type_,
            token: this.token,
            open_id: this.open_id,
            park_id: this.park_id,
            where_: where_,
            page: page
          },
          res => {
            // console.log(res);
            if (res.data.id === 0) {
              this.info[dataType + '_user_list'] =
                this.info[dataType + '_user_list'].concat(res.data.data.data)
              this[dataType + '_data_len'] = res.data.data.data.length
            } else if (res.data.id === -2) {
              // Toast("我是有底线的");
              this[dataType + '_data_len'] = 0
              this[dataType + '_data_bottom'] = '我是有底线的'
              this[dataType + '_data_bottom_en'] = 'nothing more'
            } else {
              Toast('我是有底线的')
            }
          }
        )
      } else {
        Toast('加载失败！')
      }
    },
    // 滚到底部
    scrollBottom (n) {
      this.statsActive = n
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      // 变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      // 变量scrollHeight是滚动条的总高度
      // let scrollHeight =
      //   document.documentElement.scrollHeight || document.body.scrollHeight
      if (scrollTop >= this.anchorHeight - windowHeight + this.statsHeight) {

      } else {
        let timer = setInterval(() => {
          let ispeed = Math.floor(
            (this.anchorHeight + this.statsHeight - scrollTop) / 10
          )
          // console.log(ispeed);
          document.documentElement.scrollTop = scrollTop + ispeed
          document.body.scrollTop = scrollTop + ispeed
          scrollTop = scrollTop + ispeed
          if (scrollTop >= this.anchorHeight - windowHeight) {
            document.documentElement.scrollTop =
              this.anchorHeight - windowHeight + this.statsHeight
            document.body.scrollTop =
              this.anchorHeight - windowHeight + this.statsHeight
            this.stats_position = false

            clearInterval(timer)
          }
        }, 16)
      }
    },
    // 获取页面滚动的高度
    handleScroll () {
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight

      // 获取滚动锚点的高度
      // let scrollHeight =
      //   document.documentElement.scrollHeight || document.body.scrollHeight
      // 滚动条到底部的条件
      if (scrollTop > 0 && scrollTop + windowHeight >= this.anchorHeight) {
        this.stats_position = false
      } else {
        this.stats_position = true
      }
      if (scrollTop <= 0) {
        this.statsActive = 0
      }
    },
    throttle (fn, interval = 300) {
      let canRun = true
      return function () {
        if (!canRun) return
        canRun = false
        setTimeout(() => {
          fn.apply(this, arguments)
          canRun = true
        }, interval)
      }
    },

    // 截取昵称长度
    filterByNick (n) {
      if (n && n.length > 4) {
        return n.substring(0, 4) + '...'
      } else {
        return n
      }
    }
  },
  updated () {
    // eslint-disable-next-line no-undef
    this.statsHeight = $('.stats_wrapper').height()
    // eslint-disable-next-line no-undef
    let footerHeight = $('.footer_hold').height()
      // eslint-disable-next-line no-undef
      ? $('.footer_hold').height()
      : 0

    // 获取滚动锚点的高度
    this.$nextTick(() => {
      this.anchorHeight = this.$refs.anchor_point.offsetTop + footerHeight
    })
  }
}
</script>

<style scoped>
/* 统计信息 */

.stats_container .stats_wrapper {
  height: 2.8rem;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
}
.stats_container .stats_wrapper_fixed {
  height: 2.8rem;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
}
.stats_container .stats_wrapper .stats_content {
  height: 2.8rem;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}
.stats_container .stats_wrapper .stats_content .stats_item {
  height: 2.8rem;
  width: 100%;
  text-align: center;
  /* box-sizing: border-box; */
  /* background-color: rgba(100, 100, 100, 0.4); */
  background-color: #fff;
  /* background-color: rgb(245, 108, 108); */
  color: rgb(189, 28, 28);
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: ease 0.3s;
}
.stats_container .stats_wrapper .stats_content .stats_item.stats_active {
  position: relative;
  transition: ease 0.3s;
  color: rgb(247, 68, 68);
  border-bottom: 1px solid rgb(238, 218, 218);
  /* background-color: #ddd; */
  /* background-color: rgb(252, 82, 38); */
  /* color: #fff; */
  /* border-bottom: 1px solid rgb(72, 192, 252); */
  /* background-color: rgba(100, 100, 100, 0.5); */
  /* background-color: #eee; */
}
.stats_container .stats_wrapper .stats_content .stats_item.stats_active::after {
  content: "";
  display: block;
  position: absolute;
  /* width: 6px; */
  /* height: 6px; */
  /* background-color: #a00; */
  bottom: 0;
  border-bottom: 4px solid rgb(239, 79, 79);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.stats_wrapper .stats_content .stats_item .stats_name {
  font-size: 12px;
}
.stats_wrapper .stats_content .stats_item .stats_number {
  /* color: #333; */
  font-weight: 600;
  font-size: 14px;
}

/* 访问数据 */
.stats-enter {
  transform: translate(30%, 0);
  opacity: 0;
}

.stats-enter-to {
  transform: translate(0, 0);
  opacity: 1;
}
.stats-enter-active {
  transition: ease 0.3s;
}

/* .stats-data-enter {
  transform: translate(5rem, 0);
  opacity: 0;
}
.stats-data-enter-to {
  transform: translate(0, 0);
  opacity: 1;
}
.stats-data-enter-active {
  transition: ease 0.3s;
} */

.stats_container .stats-list {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0.4rem 1rem;
  background-color: #fff;
}

.stats_container .stats-list .stats-list-body {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  /* flex-wrap: wrap; */
  flex-direction: column;
  padding: 0.5rem 0;
}
.stats_container .stats-list .stats-list-body .stats-list-item {
  position: relative;
  width: 100%;
  height: 2.2rem;
  display: flex;
  align-items: center;
}
.stats_container .stats-list .stats-list-body img {
  width: 1.8rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.stats_container .stats-list .stats-list-body .list-item-content {
  font-size: 0.8rem;
  color: #333;
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stats_container
  .stats-list
  .stats-list-body
  .list-item-content
  .list-item-content-name {
  color: #666;
}
.stats_container .stats-list .stats-list-body .list-item-count {
  position: relative;
  width: 80%;
  height: 0.8rem;
  line-height: 0.8rem;
  background-color: rgba(255, 0, 0, 0.2);
  text-align: right;
  border-radius: 100px;
  /* display: flex; */
}

.stats_container .stats-list .stats-list-body .list-item-count .count-progress {
  display: block;
  height: 100%;
  background-color: rgba(255, 0, 0, 1);
  /* border-top-right-radius: 100px; */
  /* border-bottom-right-radius: 100px; */
  border-radius: 100px;
}
.stats_container .stats-list .stats-list-body .list-item-count .count-number {
  position: absolute;
  top: 0;
  right: 0.5rem;
  height: 100%;
  color: #fff;
}
.stats_container .stats-list .jl-body {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0.5rem 0;
}
.stats_container .stats-list .jl-body .jl-item {
  display: flex;
  width: 100%;
  height: 2.4rem;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0.4rem;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.stats_container .stats-list .jl-body .jl-item img {
  display: block;
  border-radius: 50%;
  width: 2rem;
  margin-right: 1%;
}
.stats_container .stats-list .jl-body .jl-item .jl-name {
  margin-right: 2%;
  display: flex;
  align-items: center;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stats_container .stats-list .jl-body .jl-item .jl-name span:nth-child(1) {
  color: #666;
}

.stats_container .stats-list .jl-body .jl-item .jl-time {
  width: 40%;
  font-size: 12px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stats_container .stats-list .jl-body .jl-item .jl-prize {
  color: #333;
}
.stats_container .stats-list .jl-body .jl-item .jl-prize .jl-prize-price {
  color: rgb(168, 4, 4);
}
.stats_container .stats-list .stats-list-footer {
  position: relative;
  width: 40%;
  margin: 1rem auto 0;
  color: #999;
  text-align: center;
  background-color: #eee;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats_container .stats-list .stats-list-footer .footer_text {
  display: inline-block;
  padding: 0 4px;
  background-color: #fff;
}
/*  */
.user_list-enter{
  /* height: 0; */
  opacity: 0;
  transform: translateX(2rem)
}
.user_list-enter-to{
  /* height: 2.2rem; */
  opacity: 1;
  transform: translateX(0)
}
.user_list-enter-active{
  position: absolute;
  transition: 0.5s;
}
</style>
