<template>
  <div class="container" v-title data-title="我的足迹" v-show="loaded">
    <i class="back iconfont iconmenu2" @click="goBack"></i>
    <div class="list" v-if="list && list.length">
      <div class="item" v-for="(item, i) in list" :key="i">
        <div class="title">
          <span class="text">{{item.title}}</span>
        </div>
        <div class="sublist">
          <div
            class="subitem"
            v-for="(it, idx) in item.list_"
            :key="idx"
            @click="navToFootprintList(item,it)"
          >
            <div class="subtitle">
              <span class="text">{{it.title}}</span>
            </div>
            <i class="iconfont iconqianjin"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="none" v-else>还没有足迹信息哦~</div>
    <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator, MessageBox } from "mint-ui";
import TechnicalSupport from "../../components/TechnicalSupport";
export default {
  components: {
    "technical-support": TechnicalSupport
  },
  data() {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanMy",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      // 数据
      user: {},

      // 自定义
      page: 1,
      loaded: false,
      isLoading: false,
      haveMore: true,
      list: [],

      // company
      companyInfo: {},
      haveCompany: false
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }

    this.checkLogin();

    this.getData();

    this.getCompanyInfo(this.id, this.token).then(
      res => {
        this.companyInfo = res;
        this.haveCompany = true;
      },
      err => {
        console.log(err);
      }
    );

    if (this.is_weixin && this.id == "1837") {
      this.dealWithUrl(window.location.href);
      const sUrl = "https://m.zmlxj.com/#/nihewan/home?id=" + this.id,
        wx_title = "泥河湾国家自然保护区",
        wx_content = "泥河湾国家自然保护区",
        wx_img = "";
      this.wx_share_callback(sUrl, {
        url: sUrl,
        id_: this.id,
        id_type_: this.backUrl,
        token: this.token,
        open_id: this.open_id,
        title: wx_title,
        content: wx_content,
        img: wx_img
      });
    }
  },
  methods: {
    // 上一级
    goBack() {
      this.$router.push({
        path: "/nihewan/home",
        query: {
          id: this.id
        }
      });
    },
    // 足迹列表
    navToFootprintList(item, it) {
      this.$router.push({
        path: "/nihewan/myFootprintList",
        query: {
          id: this.id,
          code: it.code,
          pid_code: item.code,
          type_: item.type_,
          time_type_: it.type_,
          address_type_: it.type_
        }
      });
    },
    /**
     * 获取数据
     */
    getData() {
      const user_id = this.user.user_id;

      if (user_id) {
        this.isLoading = true;
        Indicator.open("加载中...");

        this.$ajax(
          "post",
          "weixin/get_user_info_v2",
          {
            user_id: user_id,
            token: this.token,
            type_: "all",
            park_id: this.park_id
          },
          res => {
            if (res.data.id == 0) {
              if (res.data.data.list) {
                this.list.push(...res.data.data.list);
              } else {
                Toast("暂无足迹信息");
              }
            }
            this.isLoading = false;
            this.loaded = true;
            Indicator.close();
          }
        );
      }
    },
    // 登录
    checkLogin() {
      if (!this.check_login()) {
        MessageBox.alert("请先登录").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
          }
        });
      } else {
        this.user = this.check_login();
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #fff;
}

.back {
  position: fixed;
  top: 0.4rem;
  left: 0.4rem;
  height: 1.6rem;
  width: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.list {
  width: 100%;
  height: 100%;
  padding: 3rem 1rem;
  box-sizing: border-box;
}

.list > .item {
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
}

.list > .item > .title {
  position: relative;
  padding-left: 1rem;
  font-size: 0.88rem;
  font-weight: bold;
}

.list > .item > .title::before {
  content: "";
  position: absolute;
  height: 0.88rem;
  width: 3px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #e6a23c;
}

.sublist {
  width: 100%;
  margin-top: 0.5rem;
  padding: 0 1rem;
  box-sizing: border-box;
}

.sublist > .subitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid #eee;
  font-size: 10pt;
  color: #333;
}

.sublist > .subitem > .iconfont {
  font-size: 0.88rem;
}

/* 没有信息 */
.none {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: #999;
  background-color: rgb(245, 245, 245);
  text-shadow: 0 1px 1px #fff;
}


.company {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  color: rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 0.74rem;
}
</style>