<template>
  <div class="HealthyMsg grayness" v-title data-title="编辑健康信息">
    <mt-header fixed title="编辑健康信息">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="main_frame">
        <form action id="formid" method="post" enctype="multipart/form-data">
          <input type="hidden" name="aid" v-model="aid" />
          <input type="hidden" name="uid" v-model="user_id" />
          <div class="apply-wrap">
            <span class="form-title">血型</span>
            <div class="apply-input">
              <select
                name="volunteer_bloody"
                v-model="info.volunteer_bloody"
                style="padding-right: 2rem"
              >
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="AB">AB</option>
                <option value="O">O</option>
              </select>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">身高</span>
            <div class="apply-input">
              <input
                type="text"
                name="volunteer_height"
                v-model="info.volunteer_height"
                placeholder="如：50~240"
              />
              <span>cm</span>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">体重</span>
            <div class="apply-input">
              <input
                type="text"
                name="volunteer_weight"
                v-model="info.volunteer_weight"
                placeholder="如：40~130"
              />
              <span>kg</span>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">心率</span>
            <div class="apply-input">
              <input
                type="text"
                name="volunteer_heart_rate"
                v-model="info.volunteer_heart_rate"
                placeholder="如：50~130"
              />
              <span>次/分</span>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">血压</span>
            <div class="apply-input">
              <input
                type="text"
                name="volunteer_blood_pressure"
                v-model="info.volunteer_blood_pressure"
                placeholder="如：80~110"
              />
              <span>mmHg</span>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">肝脏</span>
            <div class="apply-input">
              <input
                type="text"
                name="volunteer_liver"
                v-model="info.volunteer_liver"
                placeholder="如：正常"
              />
            </div>
          </div>
          <div class="apply-wrap apply">
            <span class="required">*</span>
            <span class="form-title">病史</span>
            <div class="apply-textarea" id="history">
              <textarea name="volunteer_history" v-model="info.volunteer_history"></textarea>
            </div>
          </div>
        </form>
        <div class="btn">
          <mt-button type="primary" class="mint-header" @click="submitForm">保存</mt-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      user_id: "",
      aid: this.$route.query.id,
      info: {}
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      if (this.check_login()) {
        this.user_id = this.check_login().user_id;
      } else {
        this.user_id = this.get_User_Id();
      }
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      this.getData();
    },
    getData() {
      this.$ajax(
        "post",
        "Volunteer/user_info",
        { aid: this.aid, uid: this.user_id },
        ret => {
          this.info = ret.data.info;
          if (this.info.volunteer_height == 0) this.info.volunteer_height = "";
          if (this.info.volunteer_weight == 0) this.info.volunteer_weight = "";
          if (this.info.volunteer_heart_rate == 0)
            this.info.volunteer_heart_rate = "";
          if (this.info.volunteer_blood_pressure == 0)
            this.info.volunteer_blood_pressure = "";
        }
      );
    },
    submitForm() {
      //提交表单
      var form = document.getElementById("formid");
      var formdata = new FormData(form);
      var that = this;
      this.formMethod("Volunteer/updata", formdata, function(data) {
        if (data.status == 1) {
          if (that.$route.query.type == 1) {
            that.$router.push({
              path: "/volunteer/apply",
              query: { aid: that.volunteer_id }
            });
          } else {
            that.$router.push({
              path: "/volunteer/CompileVolunteer",
              query: { id: that.id }
            });
          }
        } else {
          Toast(data.info);
        }
      });
    }
  }
};
</script>
<style scoped>
.HealthyMsg .page-content {
  background-color: #fff;
}
.HealthyMsg .btn .mint-header {
  margin-top: 20px;
}
</style>