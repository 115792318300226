<template>
  <div class="container" v-show="loaded" v-title data-title="泥河湾科普知识问答">
    <!-- 背景 -->
    <div class="question">
      <!-- header -->
      <div class="header">
        <i class="back iconfont iconmenu2" @click="goBack"></i>
        <div class="sub">
          <p class="text">已答对 {{rightCount}} / {{totalCount}} 题</p>
        </div>
        <!-- countdown -->
        <div class="countdown">
          <div class="outer">
            <div class="inner" :style="'width:' + countdownNumber * 100 / 30 + '%;'"></div>
            <div class="number">{{countdownNumber}}</div>
          </div>
        </div>
      </div>

      <!-- 题目 -->
      <div class="content">
        <div class="item">
          <div class="count">
            <span class="text">第{{currentCount}}题</span>
          </div>
          <div class="title">
            <span class="text">{{question.title}}</span>
          </div>
          <div
            :class="[answered === 'right' ? 'answered-right' : '', answered === 'wrong' ? 'answered-wrong' : '', 'options-text']"
            v-if="question.type_ === 'text'"
          >
            <div
              :class="[question.answer_ == 'a' ? 'right' : 'wrong', 'option', 'text']"
              @click="handleAnswer(question.answer_, 'a')"
            >A. {{question.a_}}</div>
            <div
              :class="[question.answer_ == 'b' ? 'right' : 'wrong', 'option', 'text']"
              @click="handleAnswer(question.answer_, 'b')"
            >B. {{question.b_}}</div>
            <div
              :class="[question.answer_ == 'c' ? 'right' : 'wrong', 'option', 'text']"
              @click="handleAnswer(question.answer_, 'c')"
            >C. {{question.c_}}</div>
            <div
              :class="[question.answer_ == 'd' ? 'right' : 'wrong', 'option', 'text']"
              @click="handleAnswer(question.answer_, 'd')"
            >D. {{question.d_}}</div>
          </div>
          <div
            :class="[answered === 'right' ? 'img-right' : answered === 'wrong' ? 'img-wrong' : '', 'options-img']"
            v-if="question.type_ === 'img'"
          >
            <div class="option">
              <img
                :class="[question.answer_ == 'a' ? 'right' : 'wrong', 'img']"
                :src="question.a_"
                @click="handleAnswer(question.answer_, 'a')"
              />
              <span class="text">A</span>
            </div>
            <div class="option">
              <img
                :class="[question.answer_ == 'b' ? 'right' : 'wrong', 'img']"
                :src="question.b_"
                @click="handleAnswer(question.answer_, 'b')"
              />
              <span class="text">B</span>
            </div>
            <div class="option">
              <img
                :class="[question.answer_ == 'c' ? 'right' : 'wrong', 'img']"
                :src="question.c_"
                @click="handleAnswer(question.answer_, 'c')"
              />
              <span class="text">C</span>
            </div>
            <div class="option">
              <img
                :class="[question.answer_ == 'd' ? 'right' : 'wrong', 'img']"
                :src="question.d_"
                @click="handleAnswer(question.answer_, 'd')"
              />
              <span class="text">D</span>
            </div>
          </div>
        </div>
      </div>
      <div class="giveup">
        <span class="text" @click="goBack(1)">放弃答题</span>
      </div>
      <div class="company-box" v-if="companyInfo.title">
        <!-- 联系信息 -->
        <div class="company" v-if="haveCompany">
          <technical-support
            :ts_type_="type_"
            contact_side="new"
            :text="companyInfo.title"
            :id="id"
            :company="companyInfo"
          ></technical-support>
        </div>
      </div>
    </div>

    <!-- 答题结果 -->
    <div class="result" v-if="showResult">
      <!-- <div class="result-chance" v-if="have_chance || 1">
        <p class="text">恭喜，您获得了一次抽奖机会，是否立即前往？</p>
        <div class="btn">
          <div class="cancel">取消</div>
          <div class="confirm">确定</div>
        </div>
      </div>-->
      <div class="result-box">
        <div class="content">
          <span class="text">共答对</span>
          <span class="text em">{{rightCount}}</span>
          <span class="text">题，获得</span>
          <span class="text em">{{rightCount * 10}}</span>
          <span class="text">点积分</span>
        </div>
        <div class="title">
          <img class="img" :src="resultSucceed" v-if="rightCount > 0" />
          <img class="img" :src="resultFail" v-if="rightCount == 0" />
        </div>
        <div class="options">
          <div class="btn btn-restart" @click="restart">再来一次</div>
          <div class="btn btn-back" @click="goBack(1)">返回首页</div>
        </div>
      </div>
    </div>

    <audio ref="audio"></audio>
  </div>
</template>

<script>
import { Toast, Indicator, MessageBox } from "mint-ui";
import TechnicalSupport from "../../components/TechnicalSupport";
export default {
  components: {
    "technical-support": TechnicalSupport
  },
  data() {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanQuestion",
      share_type: "nihewanQuestionShare",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      // 自定义
      isLoading: false,
      loaded: false,
      answered: "", // 答案 对/错
      currentCount: 1, // 第几题
      totalCount: 5, // 总共几题
      rightCount: 0, // 答对了几题

      user: {},

      countdownNumber: 30, // 倒计时
      timer: "", // 倒计时计时器
      showResult: false,
      answerResult: "",

      // 音效
      audio: null,
      audioRight: require("../../assets/nihewan/question/right.mp3"),
      audioWrong: require("../../assets/nihewan/question/wrong.mp3"),
      audioFail: require("../../assets/nihewan/question/fail.mp3"),
      audioSucceed: require("../../assets/nihewan/question/succeed.mp3"),
      // 挑战结果
      resultSucceed: require("../../assets/nihewan/question/success.png"),
      resultFail: require("../../assets/nihewan/question/fail.png"),

      // 获取数据
      list: [],
      questionIndex: 0,
      question: {},

      have_chance: 0, // 答题结束，是否有抽奖机会
      have_chance_id: "",
      red_packet_image_url_list: "",
      lotteryInfo: {},

      // company
      companyInfo: {},
      haveCompany: false,
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }
    // this.checkLogin();
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
    }
    this.getData();
    this.getCompanyInfo(this.id, this.token).then(
      res => {
        // console.log(res);
        this.companyInfo = res;
        this.haveCompany = true;
      },
      err => {
        console.log(err);
      }
    );
    this.$nextTick(() => {
      this.audio = this.$refs.audio;
    });
  },
  methods: {
    /**
     * 再来一次
     */
    restart() {
      location.reload();
    },
    /**
     * 返回上一页
     */
    goBack(state) {
      if (state === 1) {
        this.$router.push({
          path: "/nihewan/questionIndex",
          query: {
            id: this.id
          }
        });
      } else {
        this.$router.push({
          path: "/nihewan/home",
          query: {
            id: this.id
          }
        });
      }
    },
    /**
     * 答题
     */
    handleAnswer(result, option) {
      if (this.answered) {
        return false;
      }
      let score = "";

      this.answered = result === option ? "right" : "wrong";

      if (result === option) {
        this.audio.src = this.audioRight;
        this.audio.play();

        Toast({
          message: "回答正确",
          iconClass: "iconfont iconxuanze1"
        });
        score = "10";
        this.rightCount++;
      } else {
        this.audio.src = this.audioWrong;
        this.audio.play();
        Toast({
          message: "回答错误",
          iconClass: "iconfont iconclose"
        });
        score = "0";
      }
      this.answerResult =
        this.answerResult + this.question.id + "," + option + "," + score + ";";

      this.reset();
    },
    /**
     * 保存答题结果
     */
    saveResult() {
      this.$ajax(
        "post",
        "Question/write_question_data",
        {
          id_: this.id,
          id_type_: this.backUrl,
          key_id: this.answerResult,
          user_id: this.user.user_id,
          open_id: this.open_id,
          token: this.token,
          title_: "",
          num_: ""
        },
        res => {
          // console.log(res);
          setTimeout(() => {
            if (this.rightCount == 0) {
              this.audio.src = this.audioFail;
              this.audio.play();
            } else {
              this.audio.src = this.audioSucceed;
              this.audio.play();
            }

            this.showResult = true;
            if (res.data.id === 0) {
              this.have_chance = res.data.data.have_chance || 0;
              this.have_chance_id = res.data.data.have_chance_id || "";

              if (
                this.lotteryInfo.id &&
                this.lotteryInfo.id_ &&
                res.data.data.have_chance > 0
              ) {
                // 有奖品且有抽奖机会
                MessageBox.confirm(
                  "您获得了一次抽奖机会，是否立即前往？",
                  "恭喜"
                ).then(
                  action => {
                    this.$router.push({
                      path: "/lottery/lottery",
                      query: {
                        id: this.lotteryInfo.id,
                        id_: this.lotteryInfo.id_,
                        id_type_: this.lotteryInfo.id_type_
                      }
                    });
                  },
                  reject => {
                    return false;
                  }
                );
              }
            } else {
              return false;
            }
          }, 3000);
        }
      );
    },
    /**
     * 下一题
     */
    reset() {
      clearInterval(this.timer);
      if (this.questionIndex >= this.list.length - 1) {
        // 答题结束
        this.saveResult();
      } else {
        this.questionIndex++;
        setTimeout(() => {
          this.countdownNumber = 30;
          this.question = this.list[this.questionIndex];
          this.answered = "";
          this.currentCount++;
          this.countDown();
        }, 3000);
      }
    },
    /**
     * 倒计时
     */
    countDown() {
      this.timer = setInterval(() => {
        this.countdownNumber--;
        if (this.countdownNumber <= 0) {
          Toast("倒计时结束");
          this.reset();
        }
      }, 1000);
    },
    // 获取数据
    getData() {
      if (this.id && this.open_id && this.token) {
        this.isLoading = true;
        Indicator.open("加载中...");
        this.$ajax(
          "post",
          "Question/get_question_list",

          {
            id_: this.id,
            open_id: this.open_id,
            token: this.token,
            id_type_: this.backUrl,
            park_id: this.park_id
          },
          res => {
            if (res.data.id === 0) {
              this.list.push(...res.data.data.list);
              Object.assign(this.question, this.list[this.questionIndex]);
              this.red_packet_image_url_list =
                res.data.data.red_packet_image_url_list || "";
              if (
                this.red_packet_image_url_list &&
                this.red_packet_image_url_list.length > 0
              ) {
                Object.assign(this.lotteryInfo, {
                  id: this.red_packet_image_url_list[0].id,
                  id_: this.red_packet_image_url_list[0].id_,
                  id_type_: this.red_packet_image_url_list[0].id_type_
                });
              }

              this.currentCount = 1;
              this.rightCount = 0;
              this.totalCount = this.list.length;

              this.countDown();

              const sUrl =
                  "https://m.zmlxj.com/#/nihewan/questionIndex?id=" + this.id,
                wx_title = "泥河湾科普答题",
                wx_content = "",
                wx_img = "";
              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.share_type,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                });
              }
            }
            this.isLoading = false;
            this.loaded = true;
            Indicator.close();
          }
        );
      }
    },
    // 登录
    checkLogin() {
      if (!this.check_login()) {
        MessageBox.alert("请先登录").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
          }
        });
      } else {
        this.user = this.check_login();
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  min-height: 100vh;
}

/* 背景 */
.question {
  position: relative;
  width: 100%;
  min-height: 100vh;
  /* padding-bottom: 2rem; */
  box-sizing: border-box;
  background-color: rgb(127, 160, 255);
  /* background-color: rgb(238, 200, 170); */
  z-index: 1;
}

/* header */
.header {
  position: relative;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: rgb(229, 177, 139); */
  /* background-color: rgba(0, 0, 0, 0.1); */
}

.back {
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.header > .sub {
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}

/* 倒计时 */
.countdown {
  position: relative;
  width: 100%;
  margin-top: 6vw;
  padding: 0 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.countdown > .outer {
  position: relative;
  height: 20px;
  width: 100%;
  border-radius: 2em;
  background: linear-gradient(
    rgba(0, 0, 0, 0.12),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.12)
  );
  background-clip: padding-box;
  overflow: hidden;
}

.countdown > .outer > .inner {
  height: 100%;
  width: 80%;
  border-radius: 2em;
  background: linear-gradient(rgb(97, 125, 201), rgb(57, 68, 99));
  transition: width linear 1s;
}

.countdown > .outer > .number {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8rem;
  font-weight: bold;
  color: #fff;
}

/* 答题 */
.question .content {
  width: 88vw;
  margin: 6vw auto;
  padding: 8vw 6vw;
  background-color: #fff;
  border-radius: 12px;
  box-sizing: border-box;
}

.question .item {
  padding: 1rem 0;
}

.question .item:first-child {
  padding-top: 0;
}

.question .item:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.question .item > .count {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.question .item > .title {
  margin-top: 6vw;
  font-size: 0.88rem;
  color: #333;
}

.question .item > .options-text {
  font-size: 0.88rem;
  color: #000;
}

.question .item > .options-text > .option {
  margin-top: 1rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  background-color: rgb(235, 242, 246);
  border-radius: 2em;
}

.question .options-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.question .options-img > .option {
  position: relative;
  margin-top: 1rem;
  width: 36vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.question .options-img > .option > .img {
  position: relative;
  display: block;
  width: 36vw;
  height: 36vw;
  object-fit: cover;
  padding: 1rem;
  box-sizing: border-box;
  background-color: rgb(235, 242, 246);
}

.question .options-img > .option > .text {
  color: #000;
  font-size: 1rem;
}

.question > .giveup {
  margin-bottom: 2rem;
  font-size: 0.8rem;
  text-align: center;
}

.question > .giveup > .text {
  display: inline-block;
  padding: 6px 18px;
  border-radius: 2em;
  font-size: 0.8rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

/* 显示结果 */
/* 文字题 */

.question .answered-right > .text.right {
  color: #fff;
  background-color: #09bb07;
}
.question .answered-wrong > .text.right {
  color: #fff;
  background-color: #09bb07;
}
.question .answered-wrong > .text.wrong {
  color: #fff;
  background-color: rgb(247, 166, 113);
}

/* 图片题 */
.question .img-right > .option > .img.right {
  background-color: #09bb07;
}
.question .img-wrong > .option > .img.right {
  background-color: #09bb07;
}
.question .img-wrong > .option > .img.wrong {
  background-color: rgb(247, 166, 113);
}

/* 显示最终结果 */
.result {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
}

.result-box {
  width: 70%;
  margin: 10rem auto 0;
  padding: 2rem;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  z-index: 2;
}

.result-box > .title {
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;
}

.result-box > .title > .img {
  width: 80%;
}

.result-box > .content {
  color: #000;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.result-box > .content > .em {
  font-weight: bold;
  color: #e6a23c;
}

.result-box > .options > .btn {
  padding: 12px 30px;
  margin-bottom: 0.5rem;
  border-radius: 2em;
  font-size: 0.8rem;
}

.result-box > .options > .btn-restart {
  background-color: rgb(204, 158, 99);
  color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.result-box > .options > .btn-back {
  margin-top: 1rem;
  color: #fff;
  background-color: rgb(199, 210, 217);
}



/* 公司信息 */
.company {
  position: relative;
  width: 100%;
  padding: 1rem 0;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 0.74rem;
  z-index: 1;
}
</style>