<template>
  <div class="dylan container">
    <!-- 登录 -->
    <transition name="fade">
      <div class="login" v-show="show_login">
        <div class="login-wrapper">
          <div class="close" @click="show_login = false">
            <i class="iconfont iconbaseline-close-px"></i>
          </div>
          <div class="login-title">登录</div>
          <div class="login-content">
            <div class="login-item">
              <i class="iconfont icongerenxinxi1"></i>
              <input type="text" placeholder="姓名" v-model="title_" />
            </div>
            <div class="login-item">
              <i class="iconfont iconsanxiangmulu"></i>
              <input type="text" placeholder="编号" v-model="num_" />
            </div>
          </div>
          <div class="login-btn">
            <button @click="saveUserInfo">确定</button>
          </div>
        </div>
      </div>
    </transition>

    <!-- 开始答题 -->
    <div class="wrapper">
      <div class="dy-header">
        <div class="header-info">
          <div class="info-user">
            <img class="user-icon" :src="user.user_icon" alt />
            <span class="user-nick">{{user.title_ || '*'}} ({{user.user_nick}})</span>
            <span class="user-count">
              已答对
              <span class="right-count">{{right_count}}</span>
              / {{total_count}} 题
            </span>
          </div>
          <p class="info-count">
            第
            <span class="emphasis">{{question_num}}</span> 题
          </p>
        </div>

        <div class="header-title">
          <div class="info-time">
            <div class="time-wrapper">
              <div
                class="time-progress"
                :style="'width:' + progress_width + '%;background-color:' + progress_color"
              ></div>
            </div>
            <span>{{current_time}}</span>
          </div>
        </div>
      </div>
      <div class="dy-content">
        <div class="info-title">
          <p>{{question.title}}</p>
          <img v-if="question.newUrl" :src="question.newUrl" alt />
        </div>
        <!-- 文字选项 -->
        <div class="info-answer" v-if="question.type_ == 'text'">
          <span
            v-show="question.a_"
            class="option"
            :class="option_a == 'right' ? 'option_right' : (option_a == 'wrong' ? 'option_wrong' : '')"
            @click="checkAnswer('a')"
          >{{question.a_}}</span>
          <span
            v-show="question.b_"
            class="option"
            :class="option_b == 'right' ? 'option_right' : (option_b == 'wrong' ? 'option_wrong' : '')"
            @click="checkAnswer('b')"
          >{{question.b_}}</span>
          <span
            v-show="question.c_"
            class="option"
            :class="option_c == 'right' ? 'option_right' : (option_c == 'wrong' ? 'option_wrong' : '')"
            @click="checkAnswer('c')"
          >{{question.c_}}</span>
          <span
            v-show="question.d_"
            class="option"
            :class="option_d == 'right' ? 'option_right' : (option_d == 'wrong' ? 'option_wrong' : '')"
            @click="checkAnswer('d')"
          >{{question.d_}}</span>
        </div>
        <!-- 图片选项 -->
        <div class="info-answer" v-if="question.type_ == 'img'">
          <div
            v-show="question.a_"
            class="option option-img"
            :class="option_a == 'right' ? 'option_right' : (option_a == 'wrong' ? 'option_wrong' : '')"
            @click="checkAnswer('a')"
          >
            <img :src="question.a_" />
          </div>
          <div
            v-show="question.b_"
            class="option option-img"
            :class="option_b == 'right' ? 'option_right' : (option_b == 'wrong' ? 'option_wrong' : '')"
            @click="checkAnswer('b')"
          >
            <img :src="question.b_" />
          </div>
          <div
            v-show="question.c_"
            class="option option-img"
            :class="option_c == 'right' ? 'option_right' : (option_c == 'wrong' ? 'option_wrong' : '')"
            @click="checkAnswer('c')"
          >
            <img :src="question.c_" />
          </div>
          <div
            v-show="question.d_"
            class="option option-img"
            :class="option_d == 'right' ? 'option_right' : (option_d == 'wrong' ? 'option_wrong' : '')"
            @click="checkAnswer('d')"
          >
            <img :src="question.d_" />
          </div>
        </div>
        <div class="info-giveup">
          <button @click="backHome">放弃答题</button>
        </div>

        <!-- 公司信息 -->
        <div class="company-box" v-if="companyInfo.title">
          <a :href="companyInfo.url" class="name">{{companyInfo.title}}</a>
        </div>
      </div>
    </div>
    <!-- 音效 -->
    <audio src ref="audio"></audio>
    <!-- 答题结束 -->
    <div class="end" v-show="showResult">
      <div class="end-wrapper">
        <img
          class="user-icon"
          :src="user.user_icon ? user.user_icon :  '../../assets/img/apply_avatar_bg.png'"
          alt
        />
        <span class="user-nick">{{user.title_ || '*'}} ({{user.user_nick}})</span>

        <div class="result-title">
          <img v-if="right_count >= 1" src="../../assets/question/success.png" alt />
          <img v-else src="../../assets/question/fail.png" alt />
        </div>
        <div class="result-content">
          共答对
          <span class="emphasis">{{right_count}}</span> 题，获得
          <span class="emphasis">{{total_score}}</span> 点积分。
        </div>

        <button class="restart" @click="reStart">再来一次</button>
        <button class="back" @click="backHome">
          <i class="iconfont iconhoutui"></i>
          返回首页
        </button>
      </div>
      <!-- 红包雨 -->
      <!-- <red-packet
        :redPacketList="red_packet_image_url_list"
        v-if="have_red_packet_rain && showResult"
        :is_admin="is_admin"
        :id_type_="id_type_"
        :red_packet_type_="red_packet_type_"
        :red_packet_image_url="red_packet_image_url"
      ></red-packet>-->
    </div>
  </div>
</template>

<script>
import { Toast, MessageBox } from 'mint-ui'
import RedPacket from '../../components/RedPacket'
var wx = require('weixin-js-sdk')
export default {
  components: {
    'red-packet': RedPacket
  },
  data () {
    return {
      // 初始化
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      park_id: 298,
      open_id: localStorage.getItem('open_id_' + this.$route.query.aid)
        ? localStorage.getItem('open_id_' + this.$route.query.aid)
        : '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin, // -------------注意
      aid: this.$route.query.aid ? this.$route.query.aid : 0,
      id_type_: this.$route.query.id_type_
        ? this.$route.query.id_type_
        : 'activityQuestion',
      share_type: 'activityQuestionShare',
      sUrl: '',
      // 获取数据
      activity: {},
      question_list: [], // 题目列表
      is_have_input: 0, // 是否登录
      user: {},
      have_red_packet_rain: 0,
      red_packet_image_url_list: '',
      red_packet_image_url: '',
      red_packet_numbers: 0,
      red_packet_type_: 0,
      is_admin: 0,

      total_time: 30, // 倒计时
      current_time: 30, // 当前剩余时间
      count_timer: null, // 计时器
      start_count: false, // 开始计时
      question_end: false, // 答题结束
      option_a: '', // 选择答案
      option_b: '',
      option_c: '',
      option_d: '',
      right_count: 0, // 答对的题目数
      total_score: 0, // 获得的积分
      total_count: 0, // 总的题目数

      num: 0,
      question: {}, // 当前题目
      question_num: 1,

      show_login: false, // 显示登录界面
      question_start: false, // 开始答题
      title_: '', // 用户编号
      num_: '', // 用户名
      result: '',
      showResult: false, // 显示答题结果
      is_pause: false, // 3秒暂停
      progress_width: 100,

      have_chance: 0, // 答题结束，是否有抽奖机会
      have_chance_id: '',
      lotteryInfo: {},
      companyInfo: {}
    }
  },
  mounted () {
    this.initSUrl()
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem(
            'open_id_' + this.aid,
            this.$route.query.open_id
          )
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.aid, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.aid, '')
      }
    }
    if (this.open_id) {
    } else {
      this.authorize()
    }
    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }
    this.getData()
  },
  computed: {
    progress_color: function () {
      return this.progress_width > 50
        ? '#fff'
        : this.progress_width > 20
          ? '#f9b066'
          : '#ff4646'
    }
  },
  destroyed () {
    clearInterval(this.count_timer)
  },
  methods: {
    // 返回首页
    backHome () {
      this.$router.push({
        path: '/question/qindex',
        query: {
          aid: this.aid
        }
      })
    },
    // 重新开始
    reStart () {
      window.location.reload()
    },
    // 保存用户登录信息
    saveUserInfo () {
      if (this.title_ == '') {
        Toast('请输入姓名')
      } else if (this.num_ == '') {
        Toast('请输入编号')
      } else {
        this.is_have_input = 2
        this.show_login = false
        this.question_start = true
        this.countDown()
      }
    },

    /**
     * 保存答题结果
     */
    saveResult () {
      this.$ajax(
        'post',
        'Question/write_question_data',
        {
          id_: this.aid,
          id_type_: this.id_type_,
          key_id: this.result,
          user_id: '',
          open_id: this.open_id,
          token: this.token,
          title_: this.title_,
          num_: this.num_
        },
        res => {
          // console.log(res);
          setTimeout(() => {
            let audio = this.$refs.audio
            if (this.right_count == 0) {
              this.$refs.audio.src = require('../../assets/question/fail.mp3')
            } else {
              this.$refs.audio.src = require('../../assets/question/succeed.mp3')
            }
            audio.play()

            this.showResult = true
            if (res.data.id === 0) {
              this.have_chance = res.data.data.have_chance || 0
              this.have_chance_id = res.data.data.have_chance_id || ''

              if (
                this.lotteryInfo.id &&
                this.lotteryInfo.id_ &&
                res.data.data.have_chance > 0
              ) {
                // 有奖品且有抽奖机会
                MessageBox.confirm(
                  '您获得了一次抽奖机会，是否立即前往？',
                  '恭喜'
                ).then(
                  action => {
                    this.$router.push({
                      path: '/lottery/lottery',
                      query: {
                        id: this.lotteryInfo.id,
                        id_: this.lotteryInfo.id_,
                        id_type_: this.lotteryInfo.id_type_,
                        share_type: this.share_type
                      }
                    })
                  },
                  reject => {
                    return false
                  }
                )
              }
            } else {
              return false
            }
          }, 3000)
        }
      )
    },
    checkUser () {
      if (this.is_have_input == 0 || this.is_have_input == 2) {
        this.show_login = false
        this.question_start = true
        this.countDown()
      } else {
        this.show_login = true
      }
    },
    countDown () {
      // 开始倒计时
      this.question = this.question_list[this.num]
      this.current_time = this.total_time
      this.is_pause = false
      this.count_timer = setInterval(() => {
        this.start_count = true
        this.current_time--
        this.progress_width = (this.current_time / this.total_time) * 100
        if (this.current_time <= 0) {
          clearInterval(this.count_timer)
          // console.log("时间到");

          if (this.num < this.total_count) {
            this.nextCountDown()
          } else {
            this.question_end = true
          }
        }
      }, 1000)
    },
    nextCountDown () {
      // 下一题
      this.progress_width = 100
      this.current_time = this.total_time
      clearInterval(this.count_timer)
      this.start_count = false

      this.num++
      if (this.num >= this.total_count) {
        this.question_end = true
        // 答题结束
        this.saveResult()
      } else {
        setTimeout(() => {
          this.question_num = this.num + 1
          this.option_a = this.option_b = this.option_c = this.option_d = ''
          this.countDown()
        }, 3000)
      }
    },
    checkAnswer (n) {
      if (this.current_time <= 0 || this.question_end) {
        return
      }
      if (this.is_have_input == 1) {
        this.show_login = true
        return
      }
      if (this.is_pause) {
        return
      }
      if (n == this.question.answer_) {
        // console.log("right");
        Toast({
          message: '回答正确',
          iconClass: 'iconfont iconxuanze1'
        })
        this.$refs.audio.src = require('../../assets/question/right.mp3')
        this.$refs.audio.play()

        this['option_' + n] = 'right'
        this.right_count++
        this.total_score += this.question.score * 1
        this.result +=
          this.question.id + ',' + n + ',' + this.question.score + ';'
      } else {
        // console.log("wrong");
        Toast({
          message: '回答错误',
          iconClass: 'iconfont iconclose'
        })
        this.$refs.audio.src = require('../../assets/question/wrong.mp3')
        this.$refs.audio.play()
        this['option_' + this.question.answer_] = 'right'
        this['option_' + n] = 'wrong'
        this.result += this.question.id + ',' + n + ',' + 0 + ';'
      }
      this.is_pause = true
      this.nextCountDown()
    },
    // 获取数据
    getData () {
      if (
        this.aid &&
        this.open_id &&
        this.token &&
        this.id_type_ &&
        this.park_id
      ) {
        this.$ajax(
          'post',
          'Question/get_question_list',
          {
            id_: this.aid,
            open_id: this.open_id,
            token: this.token,
            id_type_: this.id_type_,
            park_id: this.park_id
          },
          res => {
            if (res.data.id == 0) {
              // console.log(res.data.data);
              this.activity = res.data.data.activity || ''
              this.question_list = res.data.data.list || ''
              this.user = res.data.data.user
              this.is_have_input = res.data.data.is_have_input || 0
              // 红包相关
              this.have_red_packet_rain =
                res.data.data.have_red_packet_rain || 0
              this.red_packet_numbers = res.data.data.red_packet_numbers || 0
              this.red_packet_type_ = res.data.data.red_packet_type_ || 0
              this.red_packet_image_url_list =
                res.data.data.red_packet_image_url_list || ''
              this.red_packet_image_url =
                res.data.data.red_packet_image_url || ''
              document.title = this.activity.activity_name
              Object.assign(this.companyInfo, res.data.data.company)

              if (
                this.red_packet_image_url_list &&
                this.red_packet_image_url_list.length > 0
              ) {
                Object.assign(this.lotteryInfo, {
                  id: this.red_packet_image_url_list[0].id,
                  id_: this.red_packet_image_url_list[0].id_,
                  id_type_: this.red_packet_image_url_list[0].id_type_
                })
              }
              this.total_count = this.question_list.length
                ? this.question_list.length
                : 0
              this.question = this.question_list[0]
                ? this.question_list[0]
                : []
              this.checkUser()

              // 微信分享
              let wx_title = res.data.data.activity.activity_name
              let wx_content = res.data.data.activity.activity_name
              let wx_img = res.data.data.activity.banner_img_app

              if (this.is_weixin) {
                this.wx_share_callback(window.location.href, {
                  url: this.sUrl,
                  id_: this.aid,
                  id_type_: this.share_type,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                })
              }
            } else {
              Toast('获取数据失败')
            }
          }
        )
      }
    },
    // 初始化sUrl
    initSUrl () {
      this.sUrl = window.location.href
    },
    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id == 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var open_id = this.open_id
            if (info != '') {
              if (info.url != '') {
                let url_arr = info.url.split('share_open_id')

                url = url_arr[0] + '&share_open_id=' + open_id
              } else {
                url = url + '&share_open_id=' + open_id
              }
            } else {
              url = url + '&share_open_id=' + open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id == 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=' +
        this.aid +
        '&token=' +
        this.token +
        '&state=' +
        this.id_type_ +
        '&id_=' +
        this.aid +
        '&id_type_=' +
        this.id_type_
      window.location.href = open_url
    }
  }
}
</script>

<style scoped>
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  background: url(../../assets/question/sbg.png) no-repeat;
  background-size: cover;
}

/* 登录框 */
.dylan .login {
  width: 100vw;
  height: 120vh;
  position: fixed;
  top: -10vh;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
}
.dylan .login .login-wrapper {
  position: relative;
  width: 80%;
  background-color: #fff;
  padding: 0 1rem;
  box-sizing: border-box;
}
.dylan .login .close i {
  color: #333;
}
.dylan .login .login-title {
  height: 2rem;
  width: 100%;
  line-height: 2rem;
  font-size: 0.8rem;
  color: #333;
  text-align: center;
  /* background-color: rgb(20, 194, 247); */
  /* border-bottom: 1px solid #eee; */
}
.dylan .login .login-content {
  width: 100%;
  padding: 1rem 0;
}
.dylan .login .login-content .login-item {
  position: relative;
  width: 100%;
  height: 2rem;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.dylan .login .login-content .login-item i {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  border-right: 1px solid #eee;
  color: #666;
}
.dylan .login .login-content .login-item input {
  width: calc(100% - 2rem);
  height: 2rem;
  line-height: 2rem;
  box-sizing: border-box;
  padding: 0 0.5rem;
}
.dylan .login .login-content .login-item:not(:last-child) {
  margin-bottom: 1rem;
}
.dylan .login .login-btn {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dylan .login .login-btn button {
  white-space: nowrap;
  outline: none;
  border: none;
  background-color: rgb(13, 120, 241);
  font-size: 0.8rem;
  color: #fff;
  width: 100%;
  padding: 6px 0;
  border-radius: 4px;
}
/* 关闭按钮 */
.dylan .close {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}
/* 登录框动画 */
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(10vh);
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
  transform: translateY(0);
}
.fade-enter-active,
.fade-leave-active {
  transition: 0.3s;
}

/*  */
.wrapper {
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  box-sizing: border-box;
}
.wrapper .dy-header {
  /* min-height: 50%; */
  width: 100%;
  background: transparent;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.header-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 1rem;
  box-sizing: border-box;
}
.info-user {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  height: 3rem;
  box-sizing: border-box;
  padding-left: 3.5rem;
}

.info-user .user-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.3);
}
.info-user .user-nick {
  line-height: 1.3rem;
  font-size: 1rem;
  width: 100%;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info-user .user-count {
  line-height: 1.3rem;
  font-size: 0.8rem;
  color: #eee;
}
.info-user .user-count .right-count {
  color: #fff;
  font-weight: bold;
}
.header-info .info-count {
  font-size: 0.8rem;
  padding: 4px 12px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}
.header-info .info-count .emphasis {
  font-size: 1rem;
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.header-title {
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
  flex: auto;
  font-size: 0.8rem;
}
.info-time {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  height: 3.2rem;
}
.info-time span {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  width: 2em;
  text-align: center;
  z-index: 1;
}
.info-time .time-wrapper {
  flex: 1;
  height: 16px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  border: 3px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 1px;
  box-sizing: border-box;
  background-clip: padding-box;
}
.info-time .time-wrapper .time-progress {
  width: 80%;
  height: 100%;
  background-color: #fff;
  border-radius: 16px;
  transition: linear 1s;
}

.dy-content {
  width: 100%;
  height: calc(100vh - 9rem);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 1rem 1rem;
}
.info-title {
  width: 100%;
  /* min-height: 20vh; */
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.info-title p {
  width: 100%;
  text-align: justify;
  line-height: 1.5;
  font-size: 0.88rem;
  color: #fff;
}
.info-title img {
  margin-top: 0.5rem;
  width: 60%;
  border: 4px solid rgba(255, 255, 255, 0.8);
}
.dy-content .info-answer {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dy-content .option {
  position: relative;
  width: 100%;
  margin-top: 2vh;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 0.8rem;
  color: #333;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  /* border: 4px solid rgb(166, 237, 246); */
}
.dy-content .info-answer .option-img {
  width: calc(50vw - 1.5rem);
  height: calc(50vw - 1.5rem);
}
.dy-content .info-answer .option-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dy-content .option.option_right {
  background-color: rgb(96, 235, 103);
  color: #fff;
}
.dy-content .option.option_wrong {
  background-color: rgb(255, 77, 77);
  color: #fff;
}
/* 放弃 */
.dy-content .info-giveup {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}
.dy-content .info-giveup button {
  outline: none;
  border: none;
  background: rgba(0, 0, 0, 0.1);
  padding: 6px 18px;
  color: #fff;
  border-radius: 2em;
  font-size: 0.8rem;
}
/* 答题结束 */
.end {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: url(../../assets/question/sbg.png) no-repeat;
  background-size: cover;
  box-sizing: border-box;

  z-index: 4;
}
.end .end-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
}
.end >>> .red_packet_banner {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
}
.end .end-wrapper .user-icon {
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
}
.end .end-wrapper .user-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.8);
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
.end .end-wrapper .user-nick {
  color: #fff;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}
.end .end-wrapper .result-title {
  width: 100%;
  /* height: 3rem; */
  text-align: center;
  color: #fff;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.end .end-wrapper .result-title img {
  width: 60%;
}
.end .end-wrapper .result-content {
  padding: 6px 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2em;
  color: #fff;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}
.end .end-wrapper .result-content .emphasis {
  color: rgb(255, 219, 153);
  font-weight: 600;
}
.end .end-wrapper button {
  outline: none;
  border: none;
  padding: 12px 30px;
  margin-bottom: 0.5rem;
  border-radius: 2em;
}
.end .end-wrapper button.restart {
  font-size: 0.8rem;
  background-color: #fff;
  color: #333;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.end .end-wrapper button.back {
  color: #fff;
  font-size: 0.8rem;
  background-color: transparent;
}
.end .end-wrapper button.back i {
  color: #fff;

  font-size: 0.7rem;
}

.company-box {
  position: relative;
  height: 2rem;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.company-box > .name {
  padding: 2px 6px;
  display: inline-block;
  font-size: 0.8rem;
  color: #fff;
  font-weight: bold;
  /* background-color: rgba(0, 0, 0, 0.116); */
  text-shadow: 0px 0 2px rgba(0, 0, 0, 0.4);
}
</style>
