<template>
  <div v-title data-title="轨迹详情">
    <!-- 头部 -->
    <mt-header fixed title="轨迹详情">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
      <!-- <mt-button icon="more" slot="right"></mt-button> -->
    </mt-header>

    <div class="page-content">
      <div class="track-map">
        <!-- 轨迹信息 -->
        <div class="track-info">
          <div class="dis">
            <h1>{{info.distance}}km</h1>
            <h2>
              <span>总里程</span>
            </h2>
          </div>
          <div class="kcal">
            <h1>{{info.kacl}}kCal</h1>
            <h2>
              <span>能量消耗</span>
            </h2>
          </div>
          <div class="time">
            <h1>{{info.duration}}</h1>
            <h2>
              <span>运动耗时</span>
            </h2>
          </div>
          <div class="spd">
            <h1>{{info.averagespeed}}km/h</h1>
            <h2>
              <span>全程匀速</span>
            </h2>
          </div>
          <div class="high">
            <h1>{{high}}m</h1>
            <h2>
              <span>累计爬升</span>
            </h2>
          </div>
        </div>
        <!-- 绘制地图 -->
        <div id="container" style="width:100%; height:100%"></div>
      </div>

      <!-- 循环图文 -->
      <div class="track-pic">
        <div class="item" v-for="(img,imgIndex) in info.img" :key="imgIndex">
          <h1>
            <div class="tit">{{imgIndex + 1}}.{{img.title}}</div>
          </h1>
          <img :src="img.img" @click.prevent="onClickMe(info.img,imgIndex)" />
          <h2>
            <span class="distance">距离：{{img.distance}}km</span>
            <span class="height">海拔：{{img.height}}m</span>
            <span class="time">{{img.point_time}}</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="preview-content" v-if="is_preview" @click="is_preview=false">
      <mt-swipe :auto="0" :showIndicators="true" :defaultIndex="index">
        <mt-swipe-item v-for="(item,i) in message" :key="i">
          <a href="javascript:;">
            <img :src="item.img_res" alt />
          </a>
        </mt-swipe-item>
      </mt-swipe>
    </div>
  </div>
</template>

<script>
import photo_label from "../../assets/img/photo_label.png";
import emphasis from "../../assets/img/emphasis.png";
import origin from "../../assets/img/origin.png";
export default {
  data() {
    return {
      trackId: "",
      info: "",
      altitude: [], //海拔
      high: 0, //累计爬升
      markers: [], //图片点坐标
      point_arr: [], //坐标点
      is_preview: false
    };
  },

  methods: {
    getNoteInfo: function() {
      this.$ajax(
        "post",
        "record/click_record_v2",
        { notes_id: this.trackId },
        res => {
          // 总数据
          this.info = res.data.data;
          // 获取轨迹坐标
          var pathline = res.data.data.pathline;
          var info_arr = pathline.split(";");
          var point_count = info_arr.length - 2;

          for (var i = 0; i < info_arr.length - 1; i++) {
            var arr = info_arr[i].split(",");
            this.point_arr[i] = [arr[1], arr[0]];
            this.altitude[i] = arr[5];
          }
          var map = new AMap.Map("container", {
            resizeEnable: true,
            center: this.point_arr[0],
            zoom: 12
          });
          var polyline = new AMap.Polyline({
            path: this.point_arr, //设置线覆盖物路径
            strokeColor: "#ff9a80", //线颜色
            strokeOpacity: 1, //线透明度
            strokeWeight: 6, //线宽
            strokeStyle: "solid", //线样式
            strokeDasharray: [10, 5] //补充线样式
          });
          polyline.setMap(map);
          //                    终点
          new AMap.Marker({
            map: map,
            position: this.point_arr[0],
            icon: new AMap.Icon({
              size: new AMap.Size(40, 50), //图标大小
              image: emphasis,
              imageOffset: new AMap.Pixel(0, -50)
            })
          });
          //                    起点
          new AMap.Marker({
            map: map,
            position: this.point_arr[this.point_arr.length - 1],
            icon: new AMap.Icon({
              size: new AMap.Size(40, 50), //图标大小
              image: origin,
              imageOffset: new AMap.Pixel(0, -50)
            })
          });
          //图片点坐标
          for (var i = 0; i < res.data.data.img.length; i++) {
            var Marker = new AMap.Marker({
              map: map,
              position: [res.data.data.img[i].lng, res.data.data.img[i].lat],
              icon: new AMap.Icon({
                size: new AMap.Size(40, 50), //图标大小
                image: photo_label,
                imageOffset: new AMap.Pixel(0, -50)
              }),
              extData: i
            });
            Marker.on("click", markerClick);
          }
          function markerClick(e) {
            var h = document.querySelector(".track-map").offsetHeight;
            var itemh = document.querySelector(".track-pic .item").offsetHeight;
            document.body.scrollTop = h + itemh * e.target.getExtData();
          }

          // 累计爬升
          var high =
            Math.max.apply(null, this.altitude) -
            Math.min.apply(null, this.altitude);
          this.high = high.toFixed(2);
        }
      );
    },
    onClickMe(img, index) {
      for (var i = 0; i < img.len; i++) {
        img = img.push(img[i].img_res);
      }
      this.is_preview = true;
      this.message = img;
      this.index = index;
      return false;
    }
  },

  mounted() {
    this.trackId = this.$route.params.id;
    this.getNoteInfo();
  }
};
</script>

<style scoped>
.page-content {
  padding-bottom: 0;
}
/*轨迹详情页*/
.track-detail #main {
  background: #fafafa;
}

.track-map .amap-icon img {
  top: 0 !important;
  display: inline-block;
  width: 100%;
}

.track-map {
  position: relative;
  height: 450px;
}

.track-map #map_box,
#map_in {
  height: 100%;
}

.track-map .amap-marker-label {
  border: 0;
  background: transparent;
  color: #fff;
  height: 19px;
  width: 19px;
  line-height: 18px;
  text-align: center;
  padding: 0;
  cursor: pointer;
}

.track-info {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  color: #fff;
  width: 100%;
  padding: 5px 0;
  z-index: 1;
}

.amap-copyright,
.amap-logo {
  z-index: 1;
}

.track-info > div {
  width: 33.333333%;
  text-align: center;
  float: left;
  margin: 5px 0;
}

.track-info h1,
.track-info h2 {
  font-weight: normal;
  font-size: 20px;
}

.track-info h2 {
  font-size: 12px;
}

.track-info .dis {
  padding: 29px 0;
}

.track-info .dis h2 span {
  background-image: url(../../assets/img/track_info_dis.png);
}

.track-info .kcal h2 span {
  background-image: url(../../assets/img/track_info_kcal.png);
}

.track-info .time h2 span {
  background-image: url(../../assets/img/track_info_time.png);
}

.track-info .spd h2 span {
  background-image: url(../../assets/img/track_info_spd.png);
}

.track-info .high h2 span {
  background-image: url(../../assets/img/track_info_high.png);
}

.track-info > div h2 span {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 20px;
}

.track-pic {
  background: #ddd;
  overflow: hidden;
}

.track-pic .item {
  position: relative;
  margin-top: 20px;
  background: #fff;
  border-bottom: 1px solid #ccc;
}

.track-pic .item img {
  width: 100%;
  display: block;
}

.track-pic .item h1,
.track-pic .item h2 {
  color: #666;
  padding: 5px 18px 5px 10px;
  font-size: 16px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.track-pic .item h1 .tit {
  padding-top: 2px;
}

.track-pic .item h2 span {
  font-size: 12px;
}

.track-pic .item h2 .height {
  margin: 0 0 0 10px;
}

.track-pic .item h2 .time {
  line-height: 20px;
  float: right;
}
</style>