<template>
  <div id="new-foot-guide">
    <ServiceTitle></ServiceTitle>

    <form id="new-foot" action="#" method="post" enctype="multipart/form-data">
      <input type="hidden" name="type" v-model="type" />
      <input type="hidden" name="title" v-model="title" />
      <input type="hidden" name="label_str" v-model="label_str" />
      <input type="hidden" name="funy" v-model="funy" />
      <input type="hidden" name="route" v-model="route" />
      <input type="hidden" name="notes" v-model="notes" />
      <input type="hidden" name="notes_id" v-model="notes_id" />
      <input type="hidden" name="service_number" v-model="service_number" />
      <input type="hidden" name="range_km" v-model="range_km" />
      <input type="hidden" name="time_length" v-model="time_length" />
      <input type="hidden" name="time_slot" v-model="time_slot" />
      <input type="hidden" name="lang" v-model="lang" />
      <input type="hidden" name="service_money" v-model="service_money" />
      <input type="hidden" name="cost_in" v-model="cost_in" />
      <input type="hidden" name="cost_out" v-model="cost_out" />
      <input type="hidden" name="cost_more" v-model="cost_more" />
      <input type="hidden" name="advance_time" v-model="advance_time" />
      <input type="hidden" name="refund" v-model="refund" />
      <input type="hidden" name="refund_text" v-model="refund_text" />
      <input type="hidden" name="other" v-model="other" />
      <input type="hidden" name="city" v-model="city" />
      <input type="hidden" name="city_code" v-model="city_code" />
      <input type="hidden" name="lng" v-model="lng" />
      <input type="hidden" name="lat" v-model="lat" />
      <input type="hidden" name="user_id" v-model="user_id" />
      <input type="hidden" name="verify" v-model="verify" />
      <input type="hidden" name="s_localhost_id" v-model="s_localhost_id" />
      <input type="hidden" name="park_id" v-model="park_id" />

      <!--新建服务-->
      <div class="page-content new-foot" v-show="foot">
        <div class="new-title-content">基本信息</div>

        <div class="withdraw-wrap">
          <ServiceIndexMenu></ServiceIndexMenu>
        </div>

        <div class="foot-guide-btn" @click="nextStep()">下一步</div>
      </div>

      <div class="page-content new-foot" id="book-index" v-show="book">
        <div class="new-title-content">基本信息</div>

        <div class="book-content" style="padding-bottom: 20%;">
          <router-link :to="feelink" class="menuList f12">
            <mt-cell is-link>
              <div slot="title">
                <i class="need"></i>
                <span>价格预定</span>
              </div>
              {{priceTip}}
            </mt-cell>
          </router-link>

          <div class="withdraw-wrap">
            <div
              class="input-wrap menuList f12"
              style="padding:0 10px;border-bottom: none;line-height:44px;"
            >
              <div class="input-wrap-content" style="width: 50%">
                <i class="need"></i>
                <span>时间预定</span>
              </div>
              <div class="input-wrap-content" style="width: 50%;text-align: right">
                提前
                <input type="text" v-model="advance_time" />天
              </div>
            </div>
          </div>

          <router-link :to="booktipsLink" class="menuList f12">
            <mt-cell is-link>
              <div slot="title">
                <i class="need"></i>
                <span>退订须知</span>
              </div>
              {{unsubscribeTips}}
            </mt-cell>
          </router-link>

          <router-link :to="howUseLink" class="menuList f12">
            <mt-cell is-link>
              <div slot="title">
                <i class="need"></i>
                <span>如何使用</span>
              </div>
              {{how}}
            </mt-cell>
          </router-link>
        </div>

        <div class="input-wrap f12" style="border-bottom:none;background-color: #fff;padding: 10px">
          <i class="need"></i>
          封面图片
          <div class="img-content">
            <img id="review" :src="reviewSrc" width="100%" height="100%" />
            <div class="add-img-content">
              <div class="add-img-container" @click="addImg">+</div>
              <input type="file" name="img1" id="img1" style="display: none" accept=", image/*" />
              请上传封面照片
            </div>
          </div>
        </div>

        <div class="book-index-btn">
          <div @click="preStep" class="pre">上一步</div>
          <div @click="addService" class="next">提交审核</div>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
.new-foot {
  position: absolute;
  width: 100%;
}
.f12 {
  font-size: 12px;
}
.new-title-content {
  width: 24%;
  height: 30px;
  padding-left: 15%;
  line-height: 30px;
  margin: 10px auto;
  border-radius: 30px;
  font-size: 12px;
  background: url("../../assets/img/tour_state_icon.png") no-repeat;
  background-position: 20% 50%;
  background-size: 20px 20px;
  background-color: #fff;
}
.img-content {
  margin-top: 10px;
  height: 200px;
  background-color: #f2f2f2;
}
.img-content .add-img-content {
  position: relative;
  top: -150px;
  z-index: 1;
  margin: 0 auto;
  width: 100px;
  height: 130px;
  text-align: center;
  color: #cbcbcb;
}
.img-content .add-img-container {
  width: 100px;
  height: 100px;
  border: 1px dashed #cbcbcb;
  font-size: 30px;
  line-height: 100px;
  margin-bottom: 10px;
}
.foot-guide-btn {
  display: block;
  position: fixed;
  width: 100%;
  bottom: 0;
  margin-top: 20px;
  text-align: center;
  line-height: 40px;
  height: 40px;
  color: #fff;
  background-color: #ff9a80;
}

/*预订页面 CSS*/
.menuList .mint-cell-wrapper {
  font-size: 14px;
}
.book-index-btn {
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
  line-height: 40px;
  height: 40px;
}
.book-index-btn .pre {
  float: left;
  display: block;
  width: 50%;
  height: 100%;
  background-color: #e3e3e3;
  color: #656565;
  font-size: 14px;
}
.book-index-btn .next {
  float: left;
  width: 50%;
  background-color: #ff9a80;
  color: #fff;
}
</style>

<script>
import ServiceTitle from "../../components/ServiceTitle";
import ServiceIndexMenu from "../../components/ServiceIndexMenu";
import { Toast } from "mint-ui";

export default {
  name: "NewFootGuide",
  components: {
    ServiceTitle,
    ServiceIndexMenu
  },
  data() {
    return {
      reviewSrc: "",
      foot: true,
      book: false,

      feelink: "",
      booktipsLink: "",
      howUseLink: "",
      bookSuccessLink: "",
      forward: "",
      advance_time: localStorage.advance_time,

      // 需要上传
      //				user_id:'13554289325',
      user_id: this.get_User_Id(),
      type: "",
      label_str: localStorage.label_str,
      title: localStorage.title,
      funy: localStorage.funy,
      route: localStorage.route,
      notes: localStorage.notes,
      notes_id: "",
      service_number: localStorage.service_number,
      range_km: localStorage.range_km,
      time_length: localStorage.time_length,
      time_slot: localStorage.time_slot,
      lang: localStorage.lang,
      service_money: localStorage.service_money,
      cost_in: localStorage.cost_in,
      cost_out: localStorage.cost_out,
      cost_more: localStorage.cost_more,
      refund: "",
      refund_text: localStorage.refund_text,
      other: localStorage.other,
      city: "",
      city_code: "",
      lng: "",
      lat: "",
      s_localhost_id: "1",
      verify: "",
      park_id: localStorage.park_id,

      // 判断
      priceTip: "",
      unsubscribeTips: "",
      how: localStorage.other
    };
  },
  mounted() {
    this.getBaseMsg();
  },
  methods: {
    getBaseMsg() {
      // 获取类型
      var stype = this.$route.query.type;
      this.type = stype;

      //				console.log(localStorage);
      // 获取退款规则
      if (
        localStorage.firstDay &&
        localStorage.firstKickback &&
        localStorage.secondDay &&
        localStorage.secondKickback &&
        localStorage.thirdDay &&
        localStorage.thirdKickback
      ) {
        var refund =
          localStorage.firstDay +
          "," +
          localStorage.firstKickback +
          ";" +
          localStorage.secondDay +
          "," +
          localStorage.secondKickback +
          ";" +
          localStorage.thirdDay +
          "," +
          localStorage.thirdKickback;
        this.refund = refund;
        this.unsubscribeTips = "已填";
      }

      if (
        this.title &&
        this.label_str &&
        this.funy &&
        this.route &&
        this.notes &&
        this.service_number &&
        this.range_km &&
        this.time_length &&
        this.time_slot &&
        this.lang &&
        this.park_id
      ) {
        this.foot = false;
        this.book = true;
      }

      // 得到verify
      var user_id = this.get_User_Id();
      var timestamp = new Date().getTime();
      this.verify = user_id + timestamp;

      if (
        localStorage.service_money &&
        localStorage.cost_more &&
        localStorage.cost_in
      ) {
        this.priceTip = "已填";
      }

      this.feelink = "/booking/FeeBook?type=" + stype;
      this.booktipsLink = "/booking/BookTips?type=" + stype;
      this.howUseLink = "/booking/HowUse?type=" + stype;
      this.bookSuccessLink = "/booking/SubmitSuccess?type=" + stype;
    },

    // 添加封面，图片预览
    addImg() {
      var review = document.getElementById("review");
      var file = document.getElementById("img1");
      file.click();

      var that = this;

      file.onchange = function() {
        var path = this.files[0];

        if (/^image\/\w+$/.test(path.type)) {
          var blobURL = URL.createObjectURL(path);
          //                        console.log(blobURL);
          review.setAttribute("src", blobURL);
        }
      };
    },

    //下一步
    nextStep() {
      // 判断下列部分是否为空
      var firstLocal = [
        "title",
        "label_str",
        "funy",
        "service_number",
        "range_km",
        "time_length",
        "time_slot",
        "lang",
        "park_id"
      ];

      var storage = new Array();

      for (var i = 0; i <= firstLocal.length - 1; i++) {
        storage[i] = localStorage.getItem(firstLocal[i]);
      }

      var msg = [
        "标题",
        "标签",
        "游玩亮点",
        "服务人数",
        "服务范围",
        "服务时长",
        "服务时段",
        "服务语言",
        "公园"
      ];

      if (storage[0] == null) {
        Toast({
          message: msg[0] + "不得为空",
          duration: 2000
        });
        return;
      }

      if (storage[1] == null) {
        Toast({
          message: msg[1] + "不得为空",
          duration: 2000
        });
        return;
      }

      if (storage[2] == null) {
        Toast({
          message: msg[2] + "不得为空",
          duration: 2000
        });
        return;
      }

      if (storage[3] == null) {
        Toast({
          message: msg[3] + "不得为空",
          duration: 2000
        });
        return;
      }

      if (storage[4] == null) {
        Toast({
          message: msg[4] + "不得为空",
          duration: 2000
        });
        return;
      }

      if (storage[5] == null) {
        Toast({
          message: msg[5] + "不得为空",
          duration: 2000
        });
        return;
      }

      if (storage[6] == null) {
        Toast({
          message: msg[6] + "不得为空",
          duration: 2000
        });
        return;
      }

      if (storage[7] == null) {
        Toast({
          message: msg[7] + "不得为空",
          duration: 2000
        });
        return;
      }

      if (storage[8] == null) {
        Toast({
          message: msg[8] + "不得为空",
          duration: 2000
        });
        return;
      }
      this.foot = false;
      this.book = true;
    },

    // 上一步
    preStep() {
      this.foot = true;
      this.book = false;
    },

    // 添加服务
    addService() {
      // 判断是否为空
      var nextContent = [
        "service_money",
        "cost_in",
        "advance_time",
        "firstDay",
        "firstKickback",
        "secondDay",
        "secondKickback",
        "thirdDay",
        "thirdKickback"
      ];

      var storage = new Array();
      for (var i = 0; i <= nextContent.length; i++) {
        storage[i] = localStorage.getItem(nextContent[i]);
      }

      var msg = ["服务价格", "服务包含费用", "提前预定", "退款规则"];

      if ($("#img1").val() == "") {
        Toast({
          message: "图片不得为空",
          duration: 2000
        });

        return;
      }

      if (storage[0] == null) {
        Toast({
          message: msg[0] + "不得为空"
        });
        return;
      }

      if (storage[1] == null) {
        Toast({
          message: msg[1] + "不得为空"
        });
        return;
      }

      if (storage[2] == null) {
        Toast({
          message: msg[2] + "不得为空"
        });
        return;
      }

      if (
        storage[3] == null &&
        storage[4] == null &&
        storage[5] == null &&
        storage[6] == null &&
        storage[7] == null &&
        storage[8] == null
      ) {
        Toast({
          message: msg[3] + "不得为空"
        });
        return;
      }

      var link = "";
      if (this.type != 2) {
        link = "/footguide/VisitedDescribe?type=" + this.type;
      } else {
        link = "/booking/SubmitSuccess?type=" + this.type;
      }

      var form = document.getElementById("new-foot");
      var formdata = new FormData(form);

      this.formMethod("Service/add_service", formdata, res => {
        //					    console.log(res);
        if (res.id == 0) {
          Toast(res.msg + "！跳转中……");
          this.$router.push(link);
          this.save_arr("f_localhost_id", res.data);
        } else {
          Toast(res.msg);
        }
      });
    }
  },
  watch: {
    advance_time(value) {
      this.save_arr("advance_time", value);
    }
  }
};
</script>