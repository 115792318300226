
<template>
  <div class="ld-body" v-if="info" v-title :data-title="title+'详情'">
    <!--logo-->
    <div class="ld-logo" v-if="info.logo_list">
      <div class="ld-logo-item" v-for="(item, i) in info.logo_list" :key="i">
        <img :src="item.newUrl" />
      </div>
    </div>

    <div class="ld-content" v-html="info.content"></div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";

export default {
  components: {},
  data() {
    return {
      id: this.$route.query.id,
      park_id: this.$route.query.park_id ? this.$route.query.park_id : 1,
      info: {},
      title: this.$route.query.title ? this.$route.query.title : "",
      park_logo: this.$route.query.park_logo ? this.$route.query.park_logo : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      wx_url:
        "http://www.zmlxj.com/api.php/weixin/wx_share?type=LabelDetail&id=" +
        this.$route.query.id +
        "&title=" +
        this.$route.query.title +
        "&park_logo=" +
        this.$route.query.park_logo
    };
  },
  mounted() {
    this.unload();
    if (this.is_weixin) {
      this.wx_share(this.wx_url);
    }
    document.title = this.title;

    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    if (this.id == 1209) {
      link.href = "https://m.zmlxj.com/favicon.ico";
    } else {
      link.href = "https://m.zmlxj.com/favicon.ico";
    }

    document.getElementsByTagName("head")[0].appendChild(link);
  },
  methods: {
    unload() {
      this.get_data();
    },

    // 获取数据  渲染地图上的mak点
    get_data() {
      var that = this;
      localStorage.setItem("wx_title", this.title);
      localStorage.setItem("wx_content", this.title + "地图详情");
      localStorage.setItem("wx_img", this.park_logo);
      this.url = "SpotsRace/get_foot_data_by_label";
      this.$ajax(
        "post",
        this.url,
        {
          park_id: this.park_id,
          id: this.id,
          page: 1,
          where: "web",
          token: "zmlxj_20190116"
        },
        ret => {
          if (ret.data.id == 0) {
            this.info = ret.data.data.data;
          }
        }
      );
    }
  }
};
</script>

<style scoped>
.ld-body {
  display: inline-block;
  position: relative;
  width: 100%;
}
.ld-body .ld-logo {
  display: inline-block;
  position: relative;
  width: 98%;
  margin-left: 1%;
  border-bottom: 2px solid #9cd678;
  margin-top: 4%;
  margin-bottom: 4%;
}
.ld-body .ld-logo .ld-logo-item {
  position: relative;
  width: 25%;
  float: left;
}
.ld-body .ld-logo .ld-logo-item img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.ld-body .ld-content {
  display: inline-block;
  position: relative;
  width: 98%;
  margin-left: 1%;
}
.ld-body .ld-content img {
  width: 100%;
}
</style>
