<template>
    <div class="FormTeam"  v-title :data-title="$route.query.title">
        <mt-header fixed :title="$route.query.title">
            <a slot="left">
                <mt-button icon="back" @click="$router.go(-1)"></mt-button>
            </a>
        </mt-header>
        <ul class="button" v-if="is_group == 2">
            <router-link tag="li" :to='{path:"/activity/NewTeamName",query:{aid:$route.query.aid,price:$route.query.price}}' class="captain">
                <img src="../../assets/img/activity_captain.png" alt="">
                <div>我是领队</div>
            </router-link>
            <router-link tag="li" :to='{path:"/activity/PriceApply",query:{aid:$route.query.aid,price:$route.query.price}}' class="member">
                <img src="../../assets/img/activity_member.png" alt="">
                <div>我是队员</div>
            </router-link>
        </ul>
        <ul class="button" v-else-if="is_group == 1">
            <router-link tag="li" :to='{path:"/activity/NewTeamName",query:{aid:$route.query.aid,price:$route.query.price}}' class="captain">
                <img src="../../assets/img/activity_captain.png" alt="">
                <div>我是领队</div>
            </router-link>
        </ul>
    </div>
</template>
<script>
    export default {
        data(){
            return{
                is_group:this.$route.query.is_group ,//1->单人组队参加 只显示队长 2->多人组队参加(两个都显示)
            }
        },
        mounted(){

        },
        methods:{}
    }
</script>
<style scoped>
    .FormTeam {
        height: 100%;
        background-color: #fff;
    }

    .FormTeam .button{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15%;
        box-sizing: border-box;
    }

    .FormTeam .button li{
        flex: 1;
        text-align: center;
        font-size: .8rem;

    }
    .FormTeam .button li img{
        width: 4rem;
    }
</style>