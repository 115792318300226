<template>
  <div class="dylan container">
    <!-- 关注公众号 -->
    <div class="public-qr" v-if="show_subscribe">
      <img :src="subscribeUrl" alt />
      <span>长按识别二维码</span>
    </div>
    <!-- 启动页 -->
    <transition name="fade-in" mode="out-in">
      <div class="start" v-if="show_start" key="start">
        <img :src="activity.bg_" alt />
        <button @click="show_start = false">我要答题</button>
      </div>

      <!-- 目录页 -->

      <div class="dy-menu" v-else key="menu">
        <!-- banner -->
        <div class="menu-banner">
          <img :src="activity.banner_img_app" alt />
        </div>
        <!-- 个人信息 -->

        <div class="menu-user">
          <div class="menu-user-bg">
            <img src="../../assets/question/question_icon_new.png" alt />
          </div>
          <div class="menu-user-info">
            <p class="user_num">{{user.num_}}</p>
            <img class="user-icon" :src="user.user_icon" alt v-if="user.user_icon" />
            <img class="user-icon" src="../../assets/img/apply_avatar_bg.png" alt v-else />

            <p class="user-nick">{{user.title_ || '*'}} ({{user.user_nick}})</p>
            <div class="user-stats">
              <!-- <p><span>答题次数:</span><span>{{user.numbers_}}</span></p> -->
              <p class="stats-integral">
                <span class="value">{{user.score}}</span>
              </p>
              <p class="stats-rank">
                <span class="value">{{user.rank}}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="menu-content">
          <div class="menu-left">
            <!-- 排行榜 -->
            <div class="menu-rank menu-item" @click="initRank">
              <p class="user-numbers">{{user_numbers}}</p>
              <img src="../../assets/question/paihang.png" alt />
            </div>
            <!-- 规则 -->
            <div class="menu-rule menu-item" @click="show_rule = true">
              <img src="../../assets/question/guize.png" alt />
            </div>
          </div>
          <div class="menu-right">
            <!-- 去答题 -->
            <div class="menu-question menu-item" @click="jumpToDetail">
              <img src="../../assets/question/kaishi.png" alt />
            </div>
            <!-- 奖品 -->
            <div class="menu-prize menu-item" @click="handleShowPrize">
              <img src="../../assets/question/jiangpin.png" alt />
            </div>
          </div>
        </div>
        <!-- 公司信息 -->
        <div class="company-box" v-if="companyInfo.title">
          <a :href="companyInfo.url" class="name">{{companyInfo.title}}</a>
        </div>
      </div>
    </transition>

    <!-- 排行榜 -->
    <transition name="bounced">
      <div class="rank" v-show="show_rank">
        <div class="rank-wrapper">
          <div class="close" @click="show_rank = false">
            <i class="iconfont iconbaseline-close-px"></i>
          </div>
          <ul class="rank-tag">
            <li class="tag" :class="tag_active == 1 ? 'tag-active' : ''" @click="tag_active = 1">
              <span>积分排行</span>
            </li>
            <li class="tag" :class="tag_active == 2 ? 'tag-active' : ''" @click="tag_active = 2">
              <span>答题排行</span>
            </li>
          </ul>

          <transition name="fade-left">
            <!-- 积分排行 -->
            <ul class="record-list" v-show="tag_active == 1">
              <li
                class="record-item"
                v-for="(item,i) in score_list"
                :key="i"
                :style="rankItemStyle(item.rank)"
              >
                <div class="record-ranking">{{item.rank}}</div>
                <!-- 用户头像 -->
                <div class="record-user-icon">
                  <img :src="item.user_icon" v-if="item.user_icon" alt />
                  <img src="../../assets/img/apply_avatar_bg.png" v-else alt />
                </div>
                <div class="record-user-info">
                  <span class="record-user-nick">{{item.title_ || '*'}} ({{item.user_nick}})</span>
                  <span class="record-user-num">{{item.num_}}</span>
                  <span class="record-user-value">积分：{{item.score}}</span>
                </div>
              </li>
              <div class="load-more" @click="loadMoreRank('score')">
                <span>{{ have_more_score ? '加载更多' : '我是有底线的'}}</span>
              </div>
            </ul>
          </transition>
          <!-- 参与次数排行 -->
          <transition name="fade-right">
            <ul class="record-list" v-show="tag_active == 2">
              <li
                class="record-item"
                v-for="(item,i) in join_list"
                :key="i"
                :style="rankItemStyle(item.rank)"
              >
                <div class="record-ranking">{{item.rank}}</div>
                <!-- 用户头像 -->
                <div class="record-user-icon">
                  <img :src="item.user_icon" v-if="item.user_icon" alt />
                  <img src="../../assets/img/apply_avatar_bg.png" v-else alt />
                </div>
                <div class="record-user-info">
                  <span class="record-user-nick">{{item.title_ || '*'}} ({{item.user_nick}})</span>
                  <span class="record-user-num">{{item.num_}}</span>
                  <span class="record-user-value">答题：{{item.join_numbers}}次</span>
                </div>
              </li>
              <div class="load-more" @click="loadMoreRank('join')">
                <span>{{ have_more_join ? '加载更多' : '我是有底线的'}}</span>
              </div>
            </ul>
          </transition>
        </div>
      </div>
    </transition>
    <!-- 规则 -->
    <transition name="bounced">
      <div class="rule" v-show="show_rule">
        <div class="rule-wrapper">
          <div class="close" @click="show_rule = false">
            <i class="iconfont iconbaseline-close-px"></i>
          </div>
          <div class="rule-title">答题规则：</div>
          <div class="rule-content">
            <p class="text" v-for="(item,i) in ruleContent" :key="i">{{item}}</p>
          </div>
        </div>
      </div>
    </transition>
    <!-- 奖品 -->
    <transition name="bounced">
      <div class="prize" v-show="showPrize">
        <div class="close" @click="showPrize = false">
          <i class="iconfont iconbaseline-close-px"></i>
        </div>
        <div class="prize-title">
          <span>{{prizeInfo.title}}</span>
        </div>
        <div class="prize-wrapper">
          <p class="prize-preview">- 奖品预览 -</p>
          <div class="prize-list">
            <div class="prize-item" v-for="(item,i) in filterByPrice(prizeList)" :key="i">
              <div class="item-icon">
                <img :src="item.newUrl" alt v-if="item.newUrl" />
                <img src="../../assets/lottery/give_up.png" alt v-else-if="item.is_money == 0" />
                <img src="../../assets/switch/redpacketbtn.png" alt v-else-if="item.is_money == 1" />
                <img src="../../assets/img/gift_128.png" alt v-else-if="item.is_money == 2" />
                <img src="../../assets/switch/coupon.png" alt v-else-if="item.is_money == 3" />
              </div>
              <div class="item-info">
                <p class="info-name">{{item.label_name === '现金' ? '现金红包' : item.label_name}}</p>
                <p class="info-value">￥{{item.price}}</p>
              </div>
            </div>
          </div>
          <!-- 去抽奖 按钮 -->
          <div class="to-lottery">
            <button class="btn" @click="handleToLottery">去抽奖</button>
          </div>
          <!-- 参与人数 -->
          <div class="stats_wrapper">
            <div
              class="stats_join stats_item"
              :class="showStats == 1 ? 'stats_active' : ''"
              @click="showStats = 1"
            >
              <span class="stats_item_name">参与次数</span>
              <span class="stats_item_number">{{joinNumbers}}</span>
            </div>
            <div
              class="stats_win stats_item"
              :class="showStats == 2 ? 'stats_active' : ''"
              @click="showStats = 2"
            >
              <span class="stats_item_name">中奖次数</span>
              <span class="stats_item_number">{{winNumbers}}</span>
            </div>
          </div>
          <!-- 参与列表 -->
          <transition name="stats">
            <div class="stats_container stats-list" v-show="showStats == 1 && joinNumbers">
              <div class="join_list stats-list-body">
                <!-- <transition-group name="stats" tag="div" class="join_list"> -->
                <div
                  class="join_list_item stats-list-item"
                  v-for="(k,i) in lotteryJoinList"
                  :key="i"
                >
                  <div class="list-item-icon">
                    <img :src="k.user_icon" v-if="k.user_icon" />
                    <img src="../../assets/img/apply_avatar_bg.png" v-else-if="!k.user_icon" />
                  </div>
                  <div class="list-item-content">
                    <span class="list-item-content-name">{{k.user_nick}}</span>
                    <!-- <span>&nbsp;访问了{{k.numbers}}次</span> -->
                  </div>
                  <div class="list-item-count">
                    <!-- <span class="count-progress-hold"></span> -->
                    <span class="count-progress" :style="computedWidth(k.numbers)"></span>
                    <span class="count-number">{{k.numbers}}次</span>
                  </div>
                </div>
                <!-- </transition-group> -->
              </div>
              <div class="stats-list-footer">
                <span class="footer_text" @click="loadMoreData('join')">{{join_data_bottom}}</span>
              </div>
            </div>
          </transition>
          <!-- 中奖列表 -->
          <transition name="stats">
            <div class="stats_container stats-list" v-show="showStats == 2 && winNumbers">
              <div class="win_list stats-list-body">
                <!-- <transition-group name="stats" tag="div" class="win_list"> -->
                <!-- <div class="win_list_item" v-for="(item,i) in filterBy(win_list)" :key="i"> -->
                <div class="win_list_item" v-for="(item,i) in lotteryWinList" :key="i">
                  <img class="win_list_item_icon" :src="item.user_icon" alt v-if="item.user_icon" />
                  <img src="../../assets/img/apply_avatar_bg.png" alt v-else />
                  <span
                    class="win_list_item_content"
                  >{{filterByNick(item.user_nick)}}&nbsp;抽中&nbsp;￥{{item.price}}元&nbsp;{{item.notes}}</span>
                  <span class="win_list_item_time">{{item.add_time}}</span>
                </div>
                <!-- </transition-group> -->
              </div>
              <div class="stats-list-footer">
                <span class="footer_text" @click="loadMoreData('win')">{{win_data_bottom}}</span>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
    <!-- 发布红包 -->
    <publish-button :id="aid" :id_type_="id_type_" v-if="isAdmin" bottom="2.8rem"></publish-button>
    <!-- 音频 -->
    <div class="bg-audio" v-html="activity.activity_other" style="display: none"></div>

    <!-- 反馈 -->
    <technical-support :ts_type_="id_type_" contact_side="bottom" :id="aid"></technical-support>
  </div>
</template>

<script>
import { Toast, Indicator, MessageBox } from 'mint-ui'
import PublishButton from '../../components/PublishButton'
import TechnicalSupport from '../../components/TechnicalSupport'
var wx = require('weixin-js-sdk')
export default {
  components: {
    'technical-support': TechnicalSupport,
    'publish-button': PublishButton
  },
  data () {
    return {
      // 初始化
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      park_id: 298,
      open_id: localStorage.getItem('open_id_' + this.$route.query.aid)
        ? localStorage.getItem('open_id_' + this.$route.query.aid)
        : '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin, // -------------注意
      aid: this.$route.query.aid ? this.$route.query.aid : 0,
      id_type_: this.$route.query.id_type_
        ? this.$route.query.id_type_
        : 'activityQuestion',
      share_type: 'activityQuestionShare',
      sUrl: '',
      // 获取数据
      activity: {}, // 活动对象
      ruleContent: [], // 活动规则
      user: {}, // 用户对象
      user_numbers: 0, // 参与人数
      have_red_packet_rain: 0, // 是否有奖品
      prize_list: [], // 奖品列表
      isAdmin: 0,
      companyInfo: {},
      joinNumbers: 0,
      maxJoinNumber: 1,
      winNumbers: 0,
      maxWinNumber: 1,
      join_data_page: 1,
      join_data_bottom: '点击查看更多',
      join_data_len: '',
      win_data_bottom: '点击查看更多',
      win_data_len: '',
      win_data_page: 1,

      // 自定义
      show_start: true, // 显示启动页
      show_rank: false, // 显示排行榜
      show_rule: false, // 显示规则
      tag_active: 1, // 排行榜标签
      score_page: 1, // 排行榜页数
      join_page: 1,
      score_list: [], // 积分排名
      join_list: [], // 参与次数排名
      have_more_score: true, // 更多排名数据
      have_more_join: true, // 更多排名数据
      is_have_subscribe: 0, // 需要关注公众号
      is_subscribe: 0, // 关注公众号
      show_subscribe: false, // 显示二维码
      subscribeUrl: '',
      isGetRank: false,
      // 奖品信息
      prizeList: [],
      prizeInfo: {},
      isGetPrize: false,
      thanks_icon: require('../../assets/lottery/give_up.png'), // 奖品图片
      money_icon: require('../../assets/switch/redpacket.png'),
      coupon_icon: require('../../assets/switch/coupon.png'), // 优惠券
      showPrize: false,
      // 抽奖
      have_chance: 0,
      have_chance_id: '',
      have_red_packet_rain: '',
      red_packet_image_url: '',
      red_packet_image_url_list: '',
      red_packet_numbers: 0,
      red_packet_type_: 0,
      lotteryInfo: {},
      // 用户列表
      lotteryWinList: [],
      lotteryJoinList: [],
      showStats: 1
    }
  },
  mounted () {
    this.initSUrl()
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem(
            'open_id_' + this.aid,
            this.$route.query.open_id
          )
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.aid, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.aid, '')
      }
    }
    if (this.open_id) {
    } else {
      this.authorize()
    }
    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }

    this.getData()
  },
  methods: {
    // 加载更多数据
    loadMoreData (dataType) {
      let where_, page
      if (dataType == 'join') {
        if (this.join_data_len == 0) {
          this.join_data_bottom = '我是有底线的'
          return
        } else {
          this.join_data_page++
          where_ = 'join'
          page = this.join_data_page
        }
      } else if (dataType == 'win') {
        if (this.win_data_len == 0) {
          this.win_data_bottom = '我是有底线的'
          return
        } else {
          this.win_data_page++
          where_ = 'win'
          page = this.win_data_page
        }
      }

      if (this.aid && this.token) {
        this.$ajax(
          'post',
          'Lottery/get_lottery_user_list',
          {
            id: this.lotteryInfo.id,
            id_: this.aid,
            id_type_: this.lotteryInfo.id_type_,
            token: this.token,
            where_: where_,
            page: page
          },
          res => {
            // console.log(res);
            if (res.data.id == 0 && res.data.data[dataType + '_list']) {
              this[dataType + '_list'] = this[dataType + '_list'].concat(
                res.data.data[dataType + '_list']
              )
              // console.log(this[dataType + "_list"]);
              this[dataType + '_data_len'] =
                res.data.data[dataType + '_list'].length
            } else {
              Toast('我是有底线的')
              this[dataType + '_data_len'] = 0
            }
          }
        )
      } else {
        // 还未授权过
        this.authorize()
      }
    },
    // 截取昵称长度
    filterByNick (n) {
      if (n && n.length > 4) {
        return n.substring(0, 4) + '...'
      } else {
        return n
      }
    },
    // 计算进度条宽度
    computedWidth (num) {
      let width =
        (num / this.maxJoinNumber) * 100 >= 6
          ? (num / this.maxJoinNumber) * 100
          : 6
      // console.log(width);
      return {
        width: width + '%'
      }
    },
    // 获取用户参与数据
    getUserList (id) {
      // 测试！！
      // id = 29;
      this.$ajax(
        'post',
        'Lottery/get_lottery_user_list',
        {
          id: id,
          id_: this.aid,
          id_type_: this.id_type_,
          token: this.token,
          // is_eject: 1, // 滚动数据
          page: 1,
          where_: 'join'
        },
        res => {
          if (res.data.id == 0) {
            // console.log(res.data.data);

            if (res.data.data.join_list && res.data.data.join_list.length > 0) {
              this.lotteryJoinList.push(...res.data.data.join_list)
              this.join_data_len = this.lotteryJoinList.length
              this.join_data_bottom = '点击加载更多'
              this.maxJoinNumber = this.lotteryJoinList[0].numbers || 1

              // this.$nextTick(() => {
              //   this.userListSwipe();
              // });
            } else {
              this.join_data_bottom = '我是有底线的'
            }
            if (res.data.data.win_list && res.data.data.win_list.length > 0) {
              this.lotteryWinList.push(...res.data.data.win_list)
              this.maxWinNumber = this.lotteryWinList[0].numbers || 1
              this.win_data_len = this.lotteryWinList.length
              this.win_data_bottom = '点击加载更多'
            } else {
              this.win_data_bottom = '我是有底线的'
            }
          } else {
            console.log('暂无参与信息')
          }
        }
      )
    },
    // 去抽奖
    handleToLottery () {
      if (this.have_chance > 0) {
        this.$router.push({
          path: '/lottery/lottery',
          query: {
            id: this.lotteryInfo.id,
            id_: this.lotteryInfo.id_,
            id_type_: this.lotteryInfo.id_type_,
            share_type: this.share_type
          }
        })
      } else {
        MessageBox.confirm('是否前往答题获取抽奖机会？').then(
          action => {
            this.jumpToDetail()
          },
          reject => {
            return false
          }
        )
      }
    },
    // 过滤奖品中的谢谢参与
    filterByPrice (ls) {
      return ls.filter(item => {
        return parseFloat(item.price) > 0
      })
    },
    // 显示奖品
    handleShowPrize () {
      if (this.lotteryInfo.id && this.lotteryInfo.id_ && !this.isGetPrize) {
        // 有抽奖
        this.getPrize(this.lotteryInfo.id, this.lotteryInfo.id_)
        this.showPrize = true
      } else if (
        this.lotteryInfo.id &&
        this.lotteryInfo.id_ &&
        this.isGetPrize
      ) {
        this.showPrize = true
      } else {
        // 没有抽奖
        Toast('暂未开放')
        return false
      }
    },
    // 获取奖品

    getPrize (id, id_) {
      if (this.token && this.open_id) {
        Indicator.open('加载中...')
        this.$ajax(
          'post',
          'lottery/get_park_luck_label_list',
          {
            id: id,
            token: this.token,
            open_id: this.open_id,
            id_: id_,
            id_type_: this.id_type_
          },
          res => {
            Indicator.close()
            this.isGetPrize = true
            if (res.data.id == 0) {
              this.prizeList.push(...res.data.data.data)
              Object.assign(this.prizeInfo, res.data.data.park_luck)
              this.joinNumbers = this.prizeInfo.join_numbers
              this.winNumbers = this.prizeInfo.win_numbers
            }
          }
        )
      }
    },
    // 排行榜背景色
    rankItemStyle (rank) {
      if (rank == 1) {
        return {
          'background-color': '#e33'
        }
      } else if (rank == 2) {
        return {
          'background-color': '#fd8f1e'
        }
      } else if (rank == 3) {
        return {
          'background-color': '#7ac305'
        }
      } else {
        return {
          'background-color': 'rgb(86, 190, 255)'
        }
      }
    },
    // 加载更多
    loadMoreRank (type) {
      this.getRank(type)
    },

    // 获取排行榜数据
    initRank () {
      if (this.score_page == 1 && this.join_page == 1) {
        this.getRank('score')
      } else {
        this.show_rank = true
      }
    },
    getRank (type) {
      let page
      if (type == 'score') {
        page = this.score_page
      } else if (type == 'join') {
        page = this.join_page
      }
      if (this.aid && this.token && this.id_type_) {
        if (this.isGetRank) {
          return false
        }
        this.isGetRank = true
        Indicator.open('加载中...')
        this.$ajax(
          'post',
          'Question/get_rank_list',
          {
            id_: this.aid,
            token: this.token,
            id_type_: this.id_type_,
            page: page,
            type_: type
          },
          res => {
            if (res.data.id == 0) {
              // console.log(res.data.data);
              if (this.score_page == 1 && this.join_page == 1) {
                if (
                  res.data.data.score_list &&
                  res.data.data.score_list.length > 0
                ) {
                  this.score_list = this.score_list.concat(
                    res.data.data.score_list
                  )
                }
                if (
                  res.data.data.join_list &&
                  res.data.data.join_list.length > 0
                ) {
                  this.join_list = this.join_list.concat(
                    res.data.data.join_list
                  )
                }
                this.score_page++
                this.join_page++
              } else if (type == 'score') {
                if (res.data.data.list && res.data.data.list.length > 0) {
                  this.score_list = this.score_list.concat(res.data.data.list)
                }
                this.score_page++
              } else if (type == 'join') {
                if (res.data.data.list && res.data.data.list.length > 0) {
                  this.join_list = this.join_list.concat(res.data.data.list)
                }
                this.join_page++
              }
              this.show_rank = true
            } else if (res.data.id == -2) {
              Toast('没有更多数据')
              if (type == 'score') {
                this.have_more_score = false
              } else if (type == 'join') {
                this.have_more_join = false
              }
            } else {
              Toast('获取排名失败')
            };
            Indicator.close()
            this.isGetRank = false
          }
        )
      } else {
        this.authorize()
      }
    },
    // 后台切换刷新
    visibilityChange () {
      let that = this
      if (this.is_have_subscribe == 1) {
        // 页面显示状态改变
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState == 'visible') {
            window.location.reload()
          }
        })
      }
    },
    // 判断是否关注
    checkSubscribe () {
      if (
        this.open_id &&
        this.aid &&
        this.token &&
        this.park_id &&
        this.id_type_
      ) {
        this.$ajax(
          'post',
          'SpotsRace/get_is_subscribe',
          {
            open_id: this.open_id,
            id_: this.aid,
            id_type_: this.id_type_,
            token: this.token,
            park_id: this.park_id
          },
          res => {
            if (res.data.id == 0) {
              this.is_subscribe = 1
            } else if (res.data.id == -2) {
              this.subscribeUrl = res.data.data.subscribeUrl
              this.show_subscribe = true
            } else {
              Toast('失败' + res.data.id)
            }
          }
        )
      }
    },
    // 获取数据
    getData () {
      if (
        this.aid &&
        this.open_id &&
        this.token &&
        this.id_type_ &&
        this.park_id
      ) {
        this.$ajax(
          'post',
          'Question/get_question_data',
          {
            id_: this.aid,
            open_id: this.open_id,
            token: this.token,
            id_type_: this.id_type_,
            park_id: this.park_id
          },
          res => {
            if (res.data.id == 0) {
              // console.log(res.data.data);

              // console.log(this.user);
              if (res.data.data.user != '') {
                if (this.is_have_subscribe == 1 && this.is_subscribe == 0) {
                  this.checkSubscribe()
                }
              } else {
                if (res.data.data.is_del_open_id == 1) {
                  localStorage.setItem('open_id_' + this.aid, '')
                }
                this.authorize()
              }

              this.activity = res.data.data.activity || ''
              this.ruleContent.push(
                ...this.activity.activity_content.split('\n')
              );
              (this.isAdmin = res.data.data.is_admin || 0),
              (this.user = res.data.data.user || '')
              this.user_numbers = res.data.data.user_numbers || 0
              this.have_red_packet_rain =
                res.data.data.have_red_packet_rain || 0
              document.title = this.activity.activity_name
              this.is_have_subscribe = this.user.is_have_subscribe || 0
              this.is_subscribe = this.user.is_subscribe || 0
              this.visibilityChange()

              this.have_chance = res.data.data.have_chance || 0
              this.have_chance_id = res.data.data.have_chance_id || ''
              this.have_red_packet_rain =
                res.data.data.have_red_packet_rain || ''
              this.red_packet_image_url =
                res.data.data.red_packet_image_url || ''
              this.red_packet_image_url_list =
                res.data.data.red_packet_image_url_list || ''
              this.red_packet_numbers = res.data.data.red_packet_numbers || 0
              this.red_packet_type_ = res.data.data.red_packet_type_ || 0

              Object.assign(this.companyInfo, res.data.data.company)
              if (
                this.red_packet_image_url_list &&
                this.red_packet_image_url_list.length > 0
              ) {
                Object.assign(this.lotteryInfo, {
                  id: this.red_packet_image_url_list[0].id,
                  id_: this.red_packet_image_url_list[0].id_,
                  id_type_: this.red_packet_image_url_list[0].id_type_
                })
              }

              if (this.lotteryInfo.id) {
                this.getUserList(this.lotteryInfo.id)
              }

              // 微信分享
              let wx_title = res.data.data.activity.activity_name
              let wx_content = res.data.data.activity.activity_name
              let wx_img = res.data.data.activity.banner_img_app

              if (this.is_weixin) {
                this.wx_share_callback(window.location.href, {
                  url: this.sUrl,
                  id_: this.aid,
                  id_type_: this.share_type,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                })
              }
            } else {
              Toast('获取数据失败')
            }
          }
        )
      }
    },
    // 调答题
    jumpToDetail () {
      this.$router.push({
        path: '/question/qdetail',
        query: {
          aid: this.aid
        }
      })
    },

    // 初始化sUrl
    initSUrl () {
      this.sUrl = window.location.href
    },
    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id == 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var open_id = this.open_id
            if (info != '') {
              if (info.url != '') {
                let url_arr = info.url.split('share_open_id')

                url = url_arr[0] + '&share_open_id=' + open_id
              } else {
                url = url + '&share_open_id=' + open_id
              }
            } else {
              url = url + '&share_open_id=' + open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id == 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=' +
        this.aid +
        '&token=' +
        this.token +
        '&state=' +
        this.id_type_ +
        '&id_=' +
        this.aid +
        '&id_type_=' +
        this.id_type_
      window.location.href = open_url
    }
  }
}
</script>

<style scoped>
.dylan {
  width: 100%;
  min-height: 100%;
  background-color: rgb(207, 232, 255);
}
/* 公众号二维码 */
.dylan .public-qr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.8);
}
.dylan .public-qr img {
  max-width: 100%;
  margin-bottom: 0.5rem;
}
.dylan .public-qr span {
  font-size: 0.8rem;
  color: #fff;
}
/* 启动页 */
.dylan .start {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #fff;
  overflow: hidden;
  z-index: 1;
}
.dylan .start img {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  animation: bg 10s linear infinite alternate;
}
@keyframes bg {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% + 100vw));
  }
}
.dylan .start button {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
  border: 3px solid rgba(255, 255, 255, 0.6);
  background-color: rgba(36, 159, 241, 0.8);
  white-space: nowrap;
  padding: 8px 30px;
  -webkit-appearance: none;
  border-radius: 2em;
  font-size: 1rem;
  color: #fff;
  box-shadow: 1px 1px 4px #666;
}

/* 目录 */
.dylan .dy-menu {
  width: 100%;
  /* min-height: 100vh; */
  background-color: rgb(207, 232, 255);
}
.dylan .dy-menu .menu-banner {
  width: 100%;
}
.dylan .dy-menu .menu-banner img {
  width: 100%;
}
/* 用户个人信息 */
.dylan .menu-user {
  position: relative;
  width: calc(100% - 2rem);
  background-size: contain;
  margin: -1rem auto 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px 4px rgb(207, 232, 255);
}
.dylan .menu-user .menu-user-bg {
  width: 100%;
}
.dylan .menu-user .menu-user-bg img {
  width: 100%;
  display: block;
  border-radius: 0.5rem;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}
.dylan .menu-user .menu-user-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4%;
}
.dylan .menu-user .menu-user-info .user_num {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 2px 12px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 2em;
  color: #fff;
}
.dylan .menu-user .user-icon {
  width: 20.5%;
  border-radius: 50%;
  margin-bottom: 0.4rem;
}
.dylan .menu-user .user-nick {
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0.4rem;
  width: 50%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dylan .menu-user .user-stats {
  position: absolute;
  top: 74%;
  left: 0;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 600;
}
.dylan .menu-user .user-stats p {
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}
.dylan .menu-user .user-stats p.stats-integral {
  left: 14%;
}
.dylan .menu-user .user-stats p.stats-rank {
  left: 86.4%;
}

.dylan .menu-user .user-stats .value {
  color: rgb(160, 250, 250);
}
.dylan .menu-user .user-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
}
.dylan .menu-user .user-btn i {
  color: #fff;
}
/* 菜单 */
.dylan .menu-content {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dylan .menu-left,
.dylan .menu-right {
  width: calc(50% - 0.5rem);
  display: flex;
  flex-direction: column;
}

.dylan .menu-item {
  position: relative;
  width: 100%;
  border-radius: 0.24rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.dylan .menu-item img {
  width: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
}
.dylan .menu-item .user-numbers {
  position: absolute;
  top: 5.5%;
  right: 14%;
  color: #fff;
  font-size: 0.7rem;
  width: 42%;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .dylan .menu-rank {
  background: linear-gradient(90deg, rgb(60, 213, 252), rgb(29, 206, 250));
  height: 10rem;
}
.dylan .menu-prize {
  background: linear-gradient(90deg, rgb(248, 51, 150), rgb(252, 29, 196));
  height: 6rem;
}
.dylan .menu-rule {
  background: linear-gradient(90deg, rgb(248, 189, 51), rgb(255, 177, 0));
  height: 6rem;
}
.dylan .menu-question {
  background: linear-gradient(90deg, rgb(51, 176, 248), rgb(0, 119, 255));
  height: 10rem;
} */

/* 用户信息 */
.dylan .user {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  /* padding: 1rem; */
  box-sizing: border-box;
  background-color: rgb(196, 241, 255);
}
.dylan .user .user-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgb(38, 156, 253);
  /* border-radius: 0.5rem; */
  overflow-y: auto;
}
/* 关闭按钮 */
.dylan .close {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}
.dylan .close i {
  color: #fff;
  font-size: 1.4rem;
}
.dylan .user .user-wrapper .user-info {
  height: 6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dylan .user .user-wrapper .user-info .user-icon {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: 4px solid #fff;
}

.dylan .user .user-record {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}
.dylan .user .user-record .record-item {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  box-sizing: border-box;
  padding: 0 1rem;
  margin-bottom: 1rem;
  border-radius: 2em;
  background-color: #00000020;
}
.dylan .user .item-name {
  color: aqua;
}
.dylan .user .item-value {
  color: #fff;
}
/* 排行榜 */
.dylan .rank {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url(../../assets/question/sbg.png) no-repeat;
  background-size: cover;
  z-index: 1;
}
.dylan .rank .rank-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */
  padding: 1rem 0;
  box-sizing: border-box;
}

.dylan .rank .rank-tag {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  /* margin-top: 1rem; */
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
}
.dylan .rank .rank-tag .tag {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.88rem;
  box-sizing: border-box;
}
.dylan .rank .rank-tag .tag:not(:last-child) {
  border-right: 1px solid #ccc;
}
.dylan .rank .rank-tag .tag span {
  color: #eee;
  height: 100%;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
}
.dylan .rank .rank-tag .tag-active span {
  color: #fff;
  border-color: #fff;
}
.dylan .rank .record-list {
  width: 100%;
  height: calc(100vh - 5rem);
  box-sizing: border-box;
  padding: 0 1rem 1rem 1rem;
  margin-top: 1rem;
  overflow-y: auto;
}

.dylan .rank .record-list .load-more {
  height: 2rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2em;
  text-align: center;
  line-height: 2rem;
  color: #666;
}
.dylan .rank .record-list .record-item {
  position: relative;
  width: 100%;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 2rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.dylan .rank .record-list .record-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.2) 50%, transparent 0);
  border-top-left-radius: 0.5rem;
}
.dylan .rank .record-ranking {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.88rem;
  font-weight: 600;
}
.dylan .rank .record-user-icon {
  width: 2.6rem;
  height: 2.6rem;
  box-sizing: border-box;
  border: 3px solid #fff;
  border-radius: 50%;
}
.dylan .rank .record-user-icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.dylan .rank .record-user-info {
  position: relative;
  width: calc(100% - 2.6rem);
  height: 2.6rem;
  padding-left: 0.6rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.dylan .rank .record-user-info .record-user-nick {
  height: 1.3rem;
  line-height: 1.3rem;
  font-size: 0.8rem;
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dylan .rank .record-user-info .record-user-num {
  height: 1.3rem;
  line-height: 1.3rem;
  color: #e6e6e6;
  border-radius: 2em;
}
.dylan .rank .record-user-info .record-user-value {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 1.3rem;
  line-height: 1.3rem;
  padding: 0 12px;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 2em;
}

/* 规则 */
.dylan .rule {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url(../../assets/question/pbg.png) no-repeat;
  background-size: cover;
}
.dylan .rule .rule-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
  box-sizing: border-box;
}
.dylan .rule .rule-wrapper .rule-title {
  height: 3rem;
  line-height: 3rem;
  font-size: 0.88rem;
  color: #fff;
  font-weight: 600;
  /* margin-bottom: 0.5rem; */
}
.dylan .rule .rule-wrapper .rule-content {
  width: 100%;
  height: calc(100vh - 5rem);
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
  box-sizing: border-box;
  overflow-y: auto;
}
.rule-content > .text {
  color: #333;
  font-size: 0.8rem;
  line-height: 2;
  text-align: justify;
}

/* 奖品 */
.dylan .prize {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url(../../assets/question/pbg.png) no-repeat;
  background-size: cover;
  z-index: 1;
}
.prize-wrapper {
  position: relative;
  width: 100%;
  height: calc(100% - 2.8rem);
  padding: 1rem 0;
  box-sizing: border-box;
  overflow-y: auto;
}

.prize > .close {
  top: 0.4rem;
  right: 0.4rem;
}
.prize-title {
  width: 100%;
  height: 2.8rem;
  text-align: center;
  line-height: 2.8rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
}
.prize-preview {
  font-size: 1rem;
  color: rgb(255, 215, 69);
  font-weight: bold;
  text-align: center;
}

.prize-list {
  position: relative;
  width: 90%;
  margin: 1rem auto;
  overflow-y: auto;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background-color: rgba(153, 178, 255, 1);
}
.prize-list .prize-item {
  position: relative;
  width: 100%;
  overflow-y: auto;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background-color: #fff;
  display: flex;
}
.prize-list .prize-item:not(:last-child) {
  margin-bottom: 1rem;
}
.prize-list .prize-item .item-icon {
  width: 1.4rem;
  height: 1.4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.prize-list .prize-item .item-icon img {
  width: 100%;
}
.prize-list .prize-item .item-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.5rem;
}
.prize-list .prize-item .item-info .info-name {
  font-size: 0.8rem;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.prize-list .prize-item .item-info .info-value {
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 去抽奖 */

.to-lottery {
  position: relative;
  width: 60%;
  margin: 1rem auto;
  padding: 1rem;
  box-sizing: border-box;
}

.to-lottery > .btn {
  width: 100%;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  font-size: 1rem;
  border-radius: 1.2rem;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2),
    0 2px 4px -2px inset rgba(255, 255, 255, 0.8);
  color: #fff;
  font-weight: bold;
}

/* 参与人数 */
.stats-enter {
  transform: translate(30%, 0);
  opacity: 0;
}

.stats-enter-to {
  transform: translate(0, 0);
  opacity: 1;
}
.stats-enter-active {
  transition: ease 0.3s;
}
.stats_wrapper {
  /* margin-top: 2.5rem; */
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
}
.stats_wrapper .stats_item {
  height: 100%;
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.5s;
}
/* .stats_wrapper .stats_item:nth-child(1){
  border-right: 1px solid rgb(236, 154, 2);
} */
.stats_wrapper .stats_item.stats_active {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  transition: 0.5s;
}
.stats_wrapper .stats_item .stats_item_name {
  font-size: 0.8rem;
}
.stats_wrapper .stats_item .stats_item_number {
  font-size: 0.88rem;
  font-weight: 600;
}

.win_list {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.win_list .win_list_item {
  width: 100%;
  height: 2.2rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.win_list .win_list_item img {
  width: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.win_list .win_list_item .win_list_item_content {
  width: 60%;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.win_list .win_list_item .win_list_item_time {
  width: 40%;
  color: #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stats-list {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0.4rem 1rem;
  /* background-color: #fff; */
}

.stats-list .stats-list-body {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  /* flex-wrap: wrap; */
  flex-direction: column;
  padding: 0.5rem 0;
}
.stats-list .stats-list-body .stats-list-item {
  position: relative;
  width: 100%;
  height: 2.2rem;
  display: flex;
  align-items: center;
}
.stats-list .stats-list-body img {
  width: 1.8rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.stats-list .stats-list-body .list-item-content {
  font-size: 0.8rem;
  color: #fff;
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stats-list .stats-list-body .list-item-content .list-item-content-name {
  color: #fff;
}
.stats-list .stats-list-body .list-item-count {
  position: relative;
  width: 80%;
  height: 0.8rem;
  line-height: 0.8rem;
  background-color: rgba(95, 117, 178, 0.2);
  text-align: right;
  border-radius: 100px;
  /* display: flex; */
}
/* .stats-list .stats-list-body .list-item-count .count-progress-hold {
  display: block;
  height: 100%;
  background-color: rgba(255, 0, 0, 1);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  width: 5%;
} */
.stats-list .stats-list-body .list-item-count .count-progress {
  display: block;
  height: 100%;
  background-color: rgb(95, 117, 178);
  /* border-top-right-radius: 100px; */
  /* border-bottom-right-radius: 100px; */
  border-radius: 100px;
}
.stats-list .stats-list-body .list-item-count .count-number {
  position: absolute;
  top: 0;
  right: 0.5rem;
  height: 100%;
  color: #fff;
}
.stats-list .jl-body {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0.5rem 0;
}
.stats-list .jl-body .jl-item {
  display: flex;
  width: 100%;
  height: 2.4rem;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0.4rem;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.stats-list .jl-body .jl-item img {
  display: block;
  border-radius: 50%;
  width: 2rem;
  margin-right: 1%;
}
.stats-list .jl-body .jl-item .jl-name {
  margin-right: 5%;
  display: flex;
  align-items: center;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stats-list .jl-body .jl-item .jl-time {
  width: 40%;
  font-size: 12px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stats-list .jl-body .jl-item .jl-prize {
  color: #333;
}
.stats-list .jl-body .jl-item .jl-prize .jl-prize-price {
  color: rgb(168, 4, 4);
}
.stats-list .stats-list-footer {
  position: relative;
  width: 40%;
  margin: 1rem auto 0;
  color: #ccc;
  text-align: center;
  background-color: #ccc;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats-list .stats-list-footer .footer_text {
  display: inline-block;
  padding: 0 4px;
  background-color: rgb(127, 160, 255);
}

/* 弹框动画 */
.bounced-enter,
.bounced-leave-to {
  transform: translateX(100%);
}
.bounced-enter-to,
.bounced-leave {
  transform: translateX(0);
}
.bounced-enter-active,
.bounced-leave-active {
  transition: 0.3s;
}

/* 左右切换 */
.fade-left-enter,
.fade-left-leave-to {
  transform: translateX(-100%);
}
.fade-left-enter-to,
.fade-left-leave {
  transform: translateX(0);
}
.fade-left-enter-active,
.fade-left-leave-active {
  position: absolute;
  transition: ease 0.3s;
}
.fade-right-enter,
.fade-right-leave-to {
  transform: translateX(100%);
}
.fade-right-enter-to,
.fade-right-leave {
  transform: translateX(0);
}
.fade-right-enter-active,
.fade-right-leave-active {
  position: absolute;
  transition: ease 0.3s;
}
.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}
.fade-in-enter-to,
.fade-in-leave {
  opacity: 1;
}
.fade-in-enter-active,
.fade-in-leave-active {
  position: absolute;
  transition: ease 0.3s;
}

/* 公司信息 */
.company-box {
  position: relative;
  height: 2rem;
  width: 100%;
  text-align: center;
  /* z-index: 1; */
}

.company-box > .name {
  padding: 2px 6px;
  display: inline-block;
  font-size: 0.8rem;
  color: #fff;
  font-weight: bold;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
}
</style>
