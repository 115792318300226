<template>
  <div class="music_container">
    <div class="music_content">
      <img src="../assets/switch/bgmusic.png" alt :class="isPlay ? 'music_play' : ''" @click="togglePlay" />
      <audio
        :src="bg_audio_url"
        autoplay="autoplay"
        loop="loop"
        ref="audio"
      ></audio>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bg_audio_url: {
      type: String
    },
    play_bg_music: {
      type: Boolean
    }
  },
  data () {
    return {
      isPlay: true
    }
  },
  watch: {
    play_bg_music: function (val) {
      if (!val) {
        this.isPlay = false
        this.$refs.audio.pause()
      }
    }
  },
  methods: {
    togglePlay () {
      if (this.isPlay) {
        this.isPlay = false
        this.$refs.audio.pause()
      } else {
        this.isPlay = true
        this.$refs.audio.play()
      }
    }
  },
  mounted () {

  }
}
</script>

<style scoped>
.music_container {
  position: fixed;
  top: 3rem;
  right: 0.6rem;
  border-radius: 50%;
  /* box-shadow: 0 0 2px #aaa; */
  border: 1px solid #fff;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}
.music_container .music_content {
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
}
.music_container .music_play {
  animation: music 5s linear infinite;
}
@keyframes music {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.music_container .music_content img {
  width: 100%;
}
</style>
