import { render, staticRenderFns } from "./HuangShiTravelIndex.vue?vue&type=template&id=3354fd91&scoped=true&"
import script from "./HuangShiTravelIndex.vue?vue&type=script&lang=js&"
export * from "./HuangShiTravelIndex.vue?vue&type=script&lang=js&"
import style0 from "./HuangShiTravelIndex.vue?vue&type=style&index=0&id=3354fd91&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3354fd91",
  null
  
)

export default component.exports