<template>
  <div class="container dylan">
    <!-- 江岸1802 -->
    <div class="load-wrapper" v-if="!loaded && id == 1802">
      <div class="progress-outer">
        <span class="load-text">loading...</span>
        <img class="load-img" src="/static/jiangan_1802/noodle.png" alt />
        <div class="progress-inner" :style="'width:' + progress + '%'"></div>
      </div>
    </div>
    <div class="start-wrapper" v-if="show_start">
      <!-- 背景图 -->
      <div class="start-bg" v-if="id == 1802">
        <img :src="bg_" v-if="bg_" alt />
      </div>
      <div class="start-bg" v-else>
        <img :src="bg_" v-if="bg_" alt />
      </div>
      <div class="start-btn" v-show="show_start">
        <button @click="handleStartBtn">点击进入</button>
      </div>
      <!-- 江岸1802背景动画 -->
      <div class="start-animate" v-if="id == 1802">
        <img class="animate-fish animate-item" src="/static/jiangan_1802/fish.png" />

        <img class="animate-water1 animate-item" src="/static/jiangan_1802/water1.png" />

        <img class="animate-water2 animate-item" src="/static/jiangan_1802/water2.png" />

        <img class="animate-board animate-item" src="/static/jiangan_1802/board.png" />
      </div>
      <!-- 公司名称 -->
      <!-- <div class="company" v-if="company">{{company}}</div> -->
      <technical-support :ts_type_="ts_type_" contact_side="text" :id="id" :company="company"></technical-support>
    </div>
  </div>
</template>

<script>
import TechnicalSupport from '../../components/TechnicalSupport'
var wx = require('weixin-js-sdk')
export default {
  components: {
    'technical-support': TechnicalSupport
  },
  data () {
    return {
      // 初始数据
      park_id: 298,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      label_id: this.$route.query.id ? this.$route.query.id : 0,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      id: this.$route.query.id ? this.$route.query.id : 0,
      ts_type_: 'label',
      id_type_: 'leafletLoadMap',
      backUrl: 'leafletLoadMap',

      show_start: true, // 显示启动页
      loaded: false, // 显示加载页
      info: [], // data
      bg_list: [], // 背景图片列表
      map_info: {}, // 地图信息
      bg_: '', // 背景图片
      progress: 0, // 加载进度

      // 江岸1802

      img_water1: '',
      img_water2: '',
      img_fish: '',
      img_board: '',
      img_noodle: '',

      company: null
    }
  },
  watch: {
    // 加载进度
    progress: function (val) {
      if (Math.ceil(val) >= 100) {
        setTimeout(() => {
          this.loaded = true
        }, 1000)
      }
    }
  },
  mounted () {
    if (this.token) {
      // 已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }
    if (this.open_id) {
    } else {
      this.authorize()
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }
    this.getData()
  },
  created () {
    this.progress = 0
    if (this.id == 1802) {
      this.init1802()
    }
  },
  methods: {
    init1802 () {
      let img_list = [
        '/static/jiangan_1802/water1.png',
        '/static/jiangan_1802/water2.png',
        '/static/jiangan_1802/fish.png',
        '/static/jiangan_1802/board.png',
        '/static/jiangan_1802/noodle.png'
      ]

      for (let i = 0; i < img_list.length; i++) {
        let image = new Image()
        image.src = img_list[i]
        image.onload = () => {
          this.progress += 60 / img_list.length
        }
      }
    },
    // 加载进度
    preLoadFirst (ls) {
      let img_length = ls.length
      for (let img of ls) {
        let image = new Image()
        image.src = img
        image.onload = () => {
          this.img_count++
          this.load_progress += 20 / img_length
        }
        if (image.complete) {
          // 如果是从缓存中读数据， 这里的进度弥补pixi进度的不足
          this.img_count++
          this.load_progress += 40 / img_length
        }
      }
    },
    // 点击启动页按钮
    handleStartBtn () {
      // this.show_start = false;
      this.$router.push({
        path: '/DrawMap/LeafletMap',
        query: {
          id: this.id
        }
      })
    },
    getData () {
      if (this.id && this.token) {
        this.$ajax(
          'post',
          'label/get_map_load_data',
          {
            id: this.id,
            token: this.token,
            open_id: this.open_id
          },
          res => {
            if (res.data.id == 0) {
              // this.bg_list = res.data.data.bg_list || ""; // 背景图片列表
              this.info = res.data.data.data || '' // 数据
              this.map_info = res.data.data.map || ''
              this.bg_ = this.map_info.bg_ || ''
              document.title = this.map_info.label_name

              this.company = res.data.data.company || ''

              // 微信分享
              localStorage.setItem('wx_content', res.data.data.map.content)
              localStorage.setItem('wx_title', res.data.data.map.label_name)
              localStorage.setItem('wx_img', res.data.data.map.logo_)

              // 微信分享
              let wx_title = res.data.data.map.label_name,
                wx_content = res.data.data.map.content,
                wx_img = res.data.data.map.logo_
              let sUrl = window.location.href

              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                })
              }
              let image = new Image()
              image.src = this.bg_
              image.onload = () => {
                // 加载进度
                this.progress += 40
              }
            } else {
              Toast('获取数据失败')
            }
          }
        )
      } else {
      }
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&id_=0&state=' +
        this.backUrl
      window.location.href = open_url
    },
    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id == 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var open_id = this.open_id
            if (info != '') {
              if (info.url != '') {
                let url_arr = info.url.split('share_open_id')

                url = url_arr[0] + '&share_open_id=' + open_id
              } else {
                url = url + '&share_open_id=' + open_id
              }
            } else {
              url = url + '&share_open_id=' + open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id == 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    }
  }
}
</script>

<style scoped>
.load-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(195, 67, 56);
  z-index: 100;
}
.progress-outer {
  position: absolute;
  width: 80%;
  height: 24px;
  padding: 2px 6px;
  box-sizing: border-box;
  top: 50%;
  left: 10%;
  border-radius: 2em;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  border: 2px solid #4b0101;
}
.load-text {
  position: absolute;
  bottom: 100%;
  width: 100%;
  padding: 4px 0;
  text-align: center;
  left: 0;
  font-size: 0.8rem;
  color: #fff;
}
.load-img {
  position: absolute;
  bottom: 0;
  right: -20px;
  width: 54px;
  object-fit: cover;
  z-index: 1;
}
.progress-inner {
  position: relative;
  width: 100%;
  height: 8px;
  background: rgb(202, 35, 28);
  border-radius: 2em;
  transition: 1s;
}
.progress-inner::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: rgb(202, 35, 28);
}
.progress-inner::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  transform: translate(100%, -50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fff;
}
/* 启动页 */
.start-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: #e2e4e4;
  z-index: 1;
}

.dylan .start-wrapper .start-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: rgb(219, 221, 221);
}
.dylan .start-wrapper .start-bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.dylan .start-wrapper .start-btn {
  position: absolute;
  bottom: 30%;
  left: 0;
  width: 100%;
  /* height: 20%; */
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.dylan .start-wrapper .start-btn button {
  position: relative;
  display: block;
  background: linear-gradient(
    45deg,
    rgba(250, 69, 14, 0.8),
    rgba(250, 1, 1, 0.8)
  );

  padding: 10px 30px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  transition: 0.1s;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  border-radius: 2em;
  animation: btn 0.5s linear 0.5s 1 both;
}
@keyframes btn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.start-btn button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2em;
  background: linear-gradient(
      60deg,
      transparent,
      transparent 18px,
      rgba(255, 255, 255, 0.2) 24px,
      transparent 30px,
      transparent 100%
    )
    no-repeat;
  background-size: 36px 100%;
  animation: bulin 2s linear infinite;
}
@keyframes bulin {
  from {
    background-position: -100% 0%;
  }
  to {
    background-position: 200% 0%;
  }
}

.company {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  text-align: center;
  color: #eee;
  z-index: 3;
}

/* 1802 */
.start-animate {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 30%;
  width: 100%;
  overflow: hidden;
}
.animate-item {
  position: absolute;
}
.animate-water1 {
  top: 0;
  left: 0;
  height: 100%;
  width: 400vw;
  z-index: 1;
  animation: water 60s linear infinite;
}
.animate-water2 {
  opacity: 0.8;
  height: 65%;
  width: 400vw;
  top: 35%;
  left: 0;
  z-index: 4;
  animation: water 55s linear infinite;
}
@keyframes water {
  to {
    transform: translateX(-300vw);
  }
}
.animate-board {
  height: 40%;
  top: 20%;
  right: 0;
  z-index: 2;
  animation: board 24s linear infinite;
}
@keyframes board {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100vw);
  }
}
.animate-fish {
  height: 38%;
  top: 32%;
  left: 0;
  z-index: 3;
  animation: fish 30s linear infinite;
}
@keyframes fish {
  0% {
    transform: translate(-100%, 50%) rotate(20deg);
  }
  25% {
    transform: translate(0, 0) rotate(0);
  }
  50% {
    transform: translate(40vw, 40%) rotate(20deg);
  }
  75% {
    transform: translate(80vw, 0) rotate(0);
  }

  100% {
    transform: translate(100vw, 50%) rotate(20deg);
  }
}
</style>
