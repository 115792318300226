<template>
  <div v-title data-title="我的活动">
    <div class="message">
      <mt-header fixed title="我的活动">
        <a slot="left">
          <mt-button icon="back" @click="$router.go(-1)"></mt-button>
        </a>
      </mt-header>
    </div>

    <div class="page-content">
      <div class="container container-wh">
        <div class="message-list" v-if="list != '' ">
          <div class="activitylist">
            <mt-loadmore
              :bottom-method="loadBottom"
              :bottom-all-loaded="allLoaded"
              ref="loadmore"
              :auto-fill="false"
            >
              <div class="item pic-wide" v-for="(k,i) in list" :key="i">
                <router-link :to="{path:'/activity/ActivityDetail',query:{aid:k.id}}"></router-link>
                <div class="pic">
                  <img :src="k.theme_img_1" :alt="k.activity_name" />
                </div>
                <div class="txt">
                  <span>{{k.activity_name}}</span>
                  <span>开始时间：{{k.activity_begin_time}}&nbsp;结束时间：{{k.activity_begin_time}}</span>
                </div>
              </div>
            </mt-loadmore>
          </div>
        </div>
        <div v-else class="foot_noinfo">
          <img src="/static/img/foot_noinfo@2x.14391c2.png" alt />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Indicator, Loadmore } from "mint-ui";
import { Toast } from "mint-ui";

export default {
  data() {
    return {
      list: [],
      page: 1,
      allLoaded: false, //是否加载
      totalCount: "" //初始化方法中的数量总数
    };
  },
  mounted() {
    this.get_user_activity();
  },
  methods: {
    unload() {
      this.get_user_activity(this.page);
    },
    get_user_activity(page) {
      var that = this;
      var user_id = this.get_User_Id();

      var data = {
        user_id: user_id,
        page: page
      };

      this.$ajax("post", "user/get_user_activity", data, ret => {
        that.list = that.list.concat(ret.data.data);
        that.totalCount = ret.data.data.length;
      });
    },

    //  下拉加载页面
    loadBottom() {
      setTimeout(() => {
        if (this.totalCount % 10 == 0) {
          this.page = this.page + 1;
          this.get_user_activity(this.page);
        } else {
          this.allLoaded = true; // 若数据已全部获取完毕
          Toast("没有更多了");
        }
        this.$refs.loadmore.onBottomLoaded();
      }, 500);
    }
  }
};
</script>