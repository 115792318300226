<template>
  <div class="multiple">
    <!-- 启动页 -->
    <transition name="fade">
      <Start btn="我要报名" v-if="show_start" :imgUrl="banner.newUrl" v-show="!is_load">
        <button class="start-btn" @click="show_start=false">进入投票</button>
      </Start>
    </transition>

    <div class="wrapper" v-show="!show_start">
      <div class="public-qr" v-if="is_subscribe == -1">
        <img :src="subscribeUrl" alt />
        <span>长按识别二维码</span>
      </div>
      <!-- 大图 -->
      <div class="index-banner" v-if="banner.banner_img_app">
        <img class="img" :src="banner.banner_img_app" alt />
      </div>

      <!--顶部 数据-->
      <div class="index-info">
        <div class="btn_box">
          <div class="btn" @click="showRankList">排行榜</div>
          <div class="btn rank">
            <span class="span">活动于</span>
            <div class="activity-time" v-if="banner.activity_end_time">
              <p class="text">
                <span class="span">{{banner.activity_end_time.split("-")[0]}}</span>年
                <span class="span">{{banner.activity_end_time.split("-")[1]}}</span>月
                <span class="span">{{banner.activity_end_time.split("-")[2]}}</span>日
              </p>
              <p class="text">
                <span class="span">24</span>时
                <span class="span">00</span>分
                <span class="span">00</span>秒
              </p>
            </div>
            <span class="span">结束</span>
          </div>
          <a class="btn" href="javascript:;" @click.prevent="rule=true">活动规则</a>
        </div>
        <ul class="box clearfix">
          <li>
            <p>参与数</p>
            <span>{{banner.join_numbers}}</span>
          </li>
          <li>
            <p>投票数</p>
            <span>{{banner.vote_numbers}}</span>
          </li>
          <li>
            <p>浏览数</p>
            <span>{{banner.looks_numbers}}</span>
          </li>
        </ul>
        <!--  -->
      </div>

      <!-- 搜索 -->
      <div ref="search" class="index-search">
        <div class="search-wrapper">
          <i class="iconfont iconsousuo"></i>
          <input
            type="text"
            placeholder="搜索作品名称或编号"
            v-model="key_word"
            @focus="wrighting=true"
            @blur="wrighting=false"
          />
          <button type="submit" @click="search">搜索</button>
        </div>
      </div>
      <div class="search-fix-top" v-show="search_fix_top">
        <div class="search-wrapper">
          <i class="iconfont iconsousuo"></i>
          <input
            type="text"
            placeholder="搜索作品名称或编号"
            v-model="key_word"
            @focus="wrighting=true"
            @blur="wrighting=false"
          />
          <button type="submit" @click="search">搜索</button>
        </div>
      </div>
      <!-- 作品列表 -->
      <div class="no-work" v-if="!workList || !workList.length">
        <img src="../../assets/switch/error.png" alt />
        <p>还没有作品~~</p>
      </div>
      <div class="list" ref="loadmore" v-if="workList && workList.length">
        <div class="item" v-for="(item, index) in workList" :key="index" ref="group">
          <div class="title" v-if="item && item[0]">
            <p class="text em">第{{toUpper(index + 1)}}组：</p>
            <p class="text">{{item[0].notes}}</p>
          </div>
          <div class="sublist" v-if="item && item[0]">
            <div v-for="(it) in item" :key="it.id" class="subitem">
              <div class="pic-wrapper">
                <span class="num">{{it.tel}}号</span>
                <img class="pic" :src="it.baseUrl" />
                <span class="img_num">共{{it.img_numbers}}张</span>
              </div>
              <div class="u-card">
                <h1 class="title" @click="showDescribe(it)">
                  <span class="text">{{it.title}}</span>
                  <i class="iconfont iconqianjin"></i>
                </h1>
                <div class="card-data">
                  <p class="text">
                    <span class="name">排名</span>
                    <span class="value">{{it.rank}}</span>
                  </p>
                  <p class="text">
                    <span class="name">票数</span>
                    <span class="value">{{it.vote_numbers}}</span>
                  </p>
                </div>
                <div class="select" @click="handleSelect(it, index)">
                  <span
                    :class="['box', it.vote == -1 || !voteBtnShow ? 'disabled' : '', it.vote == 1 ? 'selected' : '']"
                  >
                    <i
                      class="iconfont iconxuanze1"
                      v-if="(it.vote == 0 &&  it.isSelect) || it.vote == 1"
                    ></i>
                  </span>
                  <span class="text">选择</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 投票按钮 -->
      <div class="vote" v-show="voteBtnShow">
        <button :class="['btn', allSelected ? 'active' : 'disabled']" @click="handleVote">投票</button>
      </div>

      <!-- 描述 -->
      <transition name="fade">
        <div class="describe" v-show="isDescribe" @click.self="isDescribe = false">
          <div class="wrapper">
            <i class="iconfont iconclose" @click="isDescribe = false"></i>
            <h2 class="title">{{describe.notes}}：{{describe.title}}</h2>
            <div class="content" v-html="describe.content"></div>
          </div>
        </div>
      </transition>

      <!-- 投票规则 - 弹出层 -->
      <transition name="fade">
        <div class="popups-bg" v-show="rule"></div>
      </transition>
      <transition name="bounce">
        <div class="popups apply" id="rule" v-show="rule">
          <div class="popups-box">
            <div class="box-header">
              <h2>投票规则</h2>
              <div class="close" @click="rule=false">
                <i class="iconfont iconbaseline-close-px"></i>
              </div>
            </div>
            <div class="wrapper">
              <div class="text" v-html="banner.content"></div>
            </div>
          </div>
        </div>
      </transition>

      <!-- 排行榜 -->
      <transition name="slider">
        <div class="rank-wrapper" v-show="show_rank">
          <div class="rank-header">
            <h2 class="title">票数排行</h2>

            <div class="close" @click="show_rank=false">
              <i class="iconfont iconclose"></i>
            </div>
          </div>
          <div class="rank-content" ref="rank" @scroll="scrollLoadMore($event)">
            <ul class="rank-list" v-if="rankList && rankList.length">
              <li class="rank-list-item" v-for="(item) in rankList" :key="item.id">
                <div class="rank-ranking">
                  <p>{{item.rank}}</p>
                </div>
                <div class="rank-item-icon">
                  <img :src="item.baseUrl" alt />
                </div>

                <div class="rank-item-info">
                  <p class="info-name">{{item.title}}</p>
                  <p class="info-num">编号：{{item.tel}}</p>
                  <p class="info-votes">票数：{{item.vote_numbers}}</p>
                </div>
              </li>
            </ul>
            <div class="no-rank-list" v-else>
              <img src="../../assets/switch/error.png" />
              <p>暂无排行数据~~</p>
            </div>
            <div class="load-more" v-if="!haveMoreRank">
              <p class="load-more-line"></p>
              <p class="load-more-text">我是有底线的</p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator } from 'mint-ui'
import bgMusic from '../../components/Music'
import Start from '../../components/Start'

var wx = require('weixin-js-sdk')
export default {
  components: {
    Start,
    'bg-music': bgMusic
  },
  data () {
    return {
      aid: this.$route.query.aid ? this.$route.query.aid : 0,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      union_id: localStorage.getItem('open_id_' + this.$route.query.aid)
        ? localStorage.getItem('open_id_' + this.$route.query.aid)
        : '',
      open_id: localStorage.getItem('open_id_' + this.$route.query.aid)
        ? localStorage.getItem('open_id_' + this.$route.query.aid)
        : '',

      rule: false,
      //   backUrl: "shopList",
      backUrl: 'multipleVote',
      key_word: '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      page: 1,
      banner: {},
      dataList: [],
      workList: [],
      park_id: 298, // 给发布足迹页面使用的
      user_id: '',
      // 分享地址
      sUrl: '',
      // 排行榜
      show_rank: false, // 显示排行榜
      rankList: [],
      haveMoreRank: true,
      loading: false,

      search_fix_top: false, // 搜索栏吸顶
      se_offsetTop: 0, // 搜索栏距离顶部高度

      show_start: true, // 显示启动页

      wrighting: false, // 正在输入

      is_load: false, // 正在加载

      selectList: [], // 保存选择的数据
      isVoting: false, // 正在投票
      is_subscribe: '',
      subscribeUrl: '',
      isDescribe: false, // 显示描述
      describe: '',
      isEnd: false, // 活动结束
      voteBtnShow: true // 显示投票按钮
    }
  },

  mounted () {
    this.initSUrl()
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem(
            'open_id_' + this.aid,
            this.$route.query.open_id
          )
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.aid, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.aid, '')
      }
    }
    if (this.open_id) {
    } else {
      this.authorize()
    }
    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }

    this.getData()
    this.$nextTick(() => {
      // 在冒泡阶段处理滚动的事件
      window.addEventListener('scroll', this.throttle(this.handleScroll))
    })
  },
  computed: {
    allSelected: function () {
      let flag = true
      this.selectList.forEach(item => {
        if (!item.selected) {
          flag = false
        }
      })
      if (flag) {
        console.log(this.selectList)
      }
      return flag
    }
  },
  methods: {
    // 数字小写转大写
    toUpper (n) {
      let m
      switch (n) {
        case 1:
          m = '一'
          break
        case 2:
          m = '二'
          break
        case 3:
          m = '三'
          break
        case 4:
          m = '四'
          break
        case 5:
          m = '五'
          break
        case 6:
          m = '六'
          break
        case 7:
          m = '七'
          break
        case 8:
          m = '八'
          break
        case 9:
          m = '九'
          break
        case 10:
          m = '十'
          break
        default:
          m = n
      }
      return m
    },
    //   显示描述
    showDescribe (info) {
      this.describe = info
      this.isDescribe = true
    },
    // 投票
    handleVote () {
      if (!this.allSelected) {
        return false
      }
      if (this.isVoting) {
        return false
      }
      let ls = [],
        str = ''
      this.selectList.forEach(item => {
        ls.push(item.selectId)
      })
      str = ls.join(',')
      if (ls.length == 10 && this.aid && this.token && this.open_id) {
        this.isVoting = true
        Indicator.open('正在投票...')
        this.$ajax(
          'post',
          'Vote/save_vote_data',
          {
            aid: this.aid,
            id_: this.aid,
            str: str,
            id_type_: 'spots',
            token: this.token,
            open_id: this.open_id
          },
          ret => {
            if (ret.data.id == 0) {
              Toast('投票成功')
              window.location.reload()
            } else {
              Toast('投票失败')
            }

            this.isVoting = false
            Indicator.close()
          }
        )
      }
    },
    // 滚到下一组
    scrollToNextGroup (anchorHeight) {
      let statsHeight = 50
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop

      // 变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight

      let timer = setInterval(() => {
        let ispeed = Math.floor((anchorHeight + statsHeight - scrollTop) / 10)
        document.documentElement.scrollTop = scrollTop + ispeed
        document.body.scrollTop = scrollTop + ispeed
        scrollTop = scrollTop + ispeed
        if (ispeed > 0 && scrollTop >= anchorHeight - statsHeight) {
          document.documentElement.scrollTop = anchorHeight - statsHeight
          document.body.scrollTop = anchorHeight - statsHeight
          clearInterval(timer)
        }
      }, 16)
    },
    // 处理选择
    handleSelect (item, index) {
      if (item.vote != 0 || !this.voteBtnShow) {
        return false
      }
      if (item.isSelect) {
        item.isSelect = false
        this.$forceUpdate()
        this.selectList[index].selected = false
        this.selectList[index].selectId = ''
      } else {
        if (this.selectList[index].selected) {
          this.workList[index].forEach(it => {
            it.isSelect = false
          })
        }
        item.isSelect = true
        this.$forceUpdate()
        this.selectList[index].selected = true
        this.selectList[index].selectId = item.id
        if (index < this.selectList.length - 1) {
          const anchorHeight = this.$refs.group[index + 1].offsetTop
          this.scrollToNextGroup(anchorHeight)
        }
      }
    },

    // 滚动加载排行榜
    scrollLoadMore (e) {
      let rank = this.$refs.rank
      let sh = rank.scrollHeight,
        st = rank.scrollTop,
        ch = rank.clientHeight
      if (st + ch >= sh - 100 && !this.loading && this.haveMoreRank) {
        this.getRankList()
      }
    },
    // 显示排行榜
    showRankList () {
      if (this.page === 1) {
        this.getRankList()
      }

      this.show_rank = true
    },
    // 获取排行榜
    getRankList () {
      if (this.aid && this.token && this.backUrl) {
        Indicator.open('加载中...')
        this.loading = true
        this.$ajax(
          'post',
          'vote/get_rank_list',
          {
            aid: this.aid,
            token: this.token,
            page: this.page
          },
          ret => {
            if (ret.data.id == 0) {
              this.rankList.push(...ret.data.data.list)
              this.page++
            } else {
              Toast('加载完毕')
            }
            Indicator.close()
            if (!ret.data.data.list || ret.data.data.list.length < 10) {
              this.haveMoreRank = false
            }
            this.loading = false
          }
        )
      } else {
        Toast('参数错误')
      }
    },

    // 初始化sUrl
    initSUrl () {
      this.sUrl = window.location.href
    },

    // 搜索
    search () {
      let arr = new Array()
      this.dataList.forEach((item, index) => {
        let a = item.filter(it => {
          return it.title.match(this.key_word) || it.tel.match(this.key_word)
        })
        arr.push(a)
      })
      this.workList = arr
    },
    getData () {
      Indicator.open()
      this.is_load = true
      if (this.aid && this.backUrl && this.token) {
        this.$ajax(
          'post',
          'Vote/get_activity_vote_data',
          {
            aid: this.aid,
            id_: this.aid,
            id_type_: this.backUrl,
            token: this.token,
            open_id: this.open_id
          },
          ret => {
            if (ret.data.id == 0) {
              this.banner = ret.data.data.banner // 活动结束的时间
              this.dataList = ret.data.data.list || ''
              this.is_subscribe = ret.data.data.is_subscribe || 0 // 是否需要关注， 0不需要， -1没有关注， 1已经关注
              this.subscribeUrl = ret.data.data.subscribeUrl // 关注的二维码
              this.voteBtnShow = ret.data.data.show == 1 // 0 未开始  1 正在进行中  2 已结束
              if (this.is_subscribe == -1) {
                this.checkSubscribe()
                this.visibilityChange() // 绑定页面从后台切换回来时刷新
              }

              // 微信分享
              const wx_title = ret.data.data.banner.activity_name
              const wx_content = ret.data.data.banner.activity_name
              const wx_img = ret.data.data.banner.banner_img_app
              document.title = '专题-' + ret.data.data.banner.activity_name
              if (this.dataList) {
                this.dataList.forEach(list => {
                  this.selectList.push({ selected: false, selectId: '' })
                  list.forEach(item => {
                    Object.assign(item, { isSelect: false })
                  })
                })
              }
              this.workList = this.dataList

              this.isEnd = this.checkTime(this.banner.activity_end_time)
              if (this.is_weixin) {
                this.wx_share_callback(window.location.href, {
                  url: this.sUrl,
                  id_: this.aid,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                })
              }
            } else {
              this.authorize()
            }

            Indicator.close()
            this.is_load = false
          }
        )
      } else {

      }
    },
    // 判断活动结束
    checkTime (etime) {
      etime = Date.parse(etime)
      let ctime = Date.parse(new Date())
      return ctime > etime
    },
    // 判断是否关注
    checkSubscribe () {
      if (this.aid && this.token && this.park_id) {
        this.$ajax(
          'post',
          'SpotsRace/get_is_subscribe',
          {
            open_id: this.open_id,
            id_: this.aid,
            token: this.token,
            park_id: this.park_id
          },
          res => {
            if (res.data.id == 0) {
              this.is_subscribe = 1
            } else if (res.data.id == -2) {
              localStorage.setItem('weixin_user_info', '')
              localStorage.setItem('open_id_' + this.aid, '')
              this.open_id = ''
              this.authorize()
            } else {
              console.log(res.data.msg)
            }
          }
        )
      }
    },
    // 后台切换刷新
    visibilityChange () {
      if (this.is_subscribe == -1) {
        // 页面显示状态改变
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState == 'visible') {
            window.location.reload()
          }
        })
      }
    },

    //
    login () {
      if (!this.check_login()) {
        localStorage.setItem('login_url', location.hash)
        this.$router.push({ path: '/my/login', query: { log_type: 1 } })
      } else {
        this.user_id = this.check_login().user_id
      }
    },

    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id == 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var open_id = this.open_id
            if (info != '') {
              if (info.url != '') {
                let url_arr = info.url.split('share_open_id')

                url = url_arr[0] + '&share_open_id=' + open_id
              } else {
                url = url + '&share_open_id=' + open_id
              }
            } else {
              url = url + '&share_open_id=' + open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id == 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=' +
        this.$route.query.aid +
        '&token=' +
        this.token +
        '&state=' +
        this.backUrl
      window.location.href = open_url
    },
    // 获取页面滚动的高度
    handleScroll () {
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight

      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      // 滚动条到底部的条件
      if (scrollTop > 0 && scrollTop + windowHeight >= scrollHeight) {
        this.showPublish = false
      } else {
        this.showPublish = true
      }

      // 搜索栏吸顶
      if (this.se_offsetTop) {
        this.search_fix_top = scrollTop >= this.se_offsetTop
      } else {
        let se_dom = this.$refs.search
        if (se_dom) {
          this.se_offsetTop = se_dom.offsetTop
          this.search_fix_top = scrollTop >= this.se_offsetTop
        } else {
          console.log('加载中')
        }
      }
    },
    throttle (fn, interval = 100) {
      let canRun = true
      return function () {
        if (!canRun) return
        canRun = false
        setTimeout(() => {
          fn.apply(this, arguments)
          canRun = true
        }, interval)
      }
    }
  }
}
</script>
<style scoped>
/* 过渡 */
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: ease 0.3s;
}
.bounce-enter,
.bounce-leave-to {
  opacity: 0;
  transform: translateY(10%);
}
.bounce-enter-to,
.bounce-leave {
  opacity: 1;
  transform: translateY(0);
}
.bounce-enter-active,
.bounce-leave-active {
  transition: ease 0.3s;
}
.slider-enter,
.slider-leave-to {
  transform: translateX(100%);
}
.slider-enter-to,
.slider-leave {
  transform: translateX(0);
}
.slider-enter-active,
.slider-leave-active {
  transition: ease 0.3s;
}
/* 过渡结束 */

.multiple {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  box-sizing: border-box;
}
.multiple > .wrapper {
  width: 100%;
  position: relative;
}
.start-btn {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
  border: none;
  background: linear-gradient(
    45deg,
    rgba(223, 54, 3, 0.9),
    rgba(154, 24, 2, 0.9)
  );
  box-shadow: 0 0 2px #ffffff33;
  padding: 10px 30px;
  font-size: 0.88rem;
  color: #fff;
  border-radius: 2em;
  font-weight: bold;
  letter-spacing: 2px;
}
/*投票规则 中富文本内容的图片*/
.popups-box .text >>> img {
  max-width: 100%;
}

.index-banner .img {
  width: 100%;
  height: auto;
}

.index-info {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  text-align: center;
}

.btn_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_box > .btn {
  color: #fff;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  height: 2rem;
  line-height: 2rem;
  border-radius: 0.3rem;
  flex: 1;
  margin: 0 0.1rem;
  padding: 0 0.1rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  box-shadow: 0 0 4px #aaa;
  background: linear-gradient(to right, #ee3d52, #f5744d);
  border-bottom: none;
}

.btn_box > .rank {
  font-size: 1rem;
  height: 3rem;
  line-height: 2.8rem;
  border-radius: 0.5rem;
  background: #e03347;
  background: linear-gradient(45deg, #f5744d, #ff6a45, #ee3d3d, #ee3d52);
  border-bottom: none;
  flex: 3;
}

.btn_box > .rank > .activity-time {
  display: inline-block;
  vertical-align: inherit;
}

.btn_box > .rank > .activity-time .text {
  line-height: 1.2rem;
  font-size: 0.6rem;
}

.btn_box > .rank > .activity-time .text .span {
  background-color: #fff;
  color: #e13046;
  display: inline-block;
  line-height: 0.8rem;
  padding: 0 0.05rem;
  margin: 0 0.05rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
}

.btn_box > .rank .iconfont {
  font-size: 1.7rem;
  line-height: 0;
}

.index-info .box {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  display: flex;
}

.index-info .box li {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #666;
}
.index-info .box li span {
  padding: 0 6px;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

.index-search {
  position: relative;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  padding: 0.5rem 0.5rem;
  box-sizing: border-box;
  border-bottom: 0.1rem solid #dedede;
}
.search-fix-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2);
  padding: 0.5rem 0.5rem;
  box-sizing: border-box;
}

.search-wrapper {
  position: relative;
  padding: 0;
  display: flex;
  text-align: center;
  width: 100%;
}
.search-wrapper i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.5rem;
  font-size: 0.8rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  color: #666;
}
.search-wrapper input {
  height: 1.8rem;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  background: #fff;
  padding: 0 0.5rem 0 2rem;
  border: 1px solid rgba(238, 51, 51, 0.3);
  border-radius: 0.15rem 0 0 0.15rem;
  color: #666;
  outline: 0;
  -webkit-appearance: none;
  border-radius: 2em 0 0 2em;
  flex: 1;
  font-size: 0.78rem;
  border-right: none;
}
.search-wrapper input:focus {
  border-color: rgba(238, 51, 51, 0.5);
}

.search-wrapper button {
  height: 1.8rem;
  line-height: 1.8rem;
  display: inline-block;
  outline: 0;
  background: linear-gradient(rgb(252, 60, 60), rgb(240, 123, 55));
  color: #fff;
  border: none;
  border-radius: 0 2em 2em 0;
  width: 4rem;
  font-size: 0.78rem;
}

.search-wrapper a {
  flex: 0.8;
}

.search-wrapper .all-photo {
  height: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  font-size: 0.7rem;
  background: linear-gradient(rgb(252, 60, 60), rgb(240, 123, 55));
  color: #fff;
  border-radius: 4px;
  text-align: center;
  margin-left: 1rem;
  box-shadow: 0 0 2px #ff9a80;
}

/* 作品列表 */
.no-work {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-work img {
  opacity: 0.4;
  width: 40%;
  margin-bottom: 1rem;
}
.no-work p {
  font-size: 0.8rem;
  color: #999;
}

.list {
  padding: 4vw 4vw 3rem;
  background-color: rgb(255, 255, 255);
}

.list > .item {
  width: 100%;
  margin-bottom: 5vw;
  border-radius: 12px;
  background-color: #fddeb0;
}

.list > .item > .title {
  height: 3rem;
  width: 100%;
  padding: 0 3%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
}

.sublist {
  padding: 0 4vw;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sublist > .subitem {
  position: relative;
  width: 40vw;
  margin-bottom: 0.8rem;
  box-sizing: border-box;
  border-radius: 8px;
  /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
}

.sublist > .subitem .num {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 0.2rem 0.4rem;
  border-radius: 0;
  font-size: 0.6rem;
  color: #fff;
}

.sublist > .subitem .pic-wrapper {
  position: relative;
  width: 100%;
}

.sublist > .subitem .pic {
  width: 100%;
  height: 56vw;
  object-fit: cover;
  display: block;
  border-radius: 0;
}

.sublist > .subitem .img_num {
  position: absolute;
  bottom: 0;
  color: #fff;
  left: 0;
  font-size: 0.7rem;
  background: rgba(0, 0, 0, 0.4);
  padding: 0.2rem 0.4rem;
}

.sublist > .subitem .u-card {
  width: 100%;
  position: relative;
  color: #666;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: #fff;
}

.u-card .title {
  padding-bottom: 0.4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.u-card .title > .text {
  flex: 1;
  display: inline-flex;
  font-size: 0.88rem;
  color: #000;
  font-weight: bold;
  overflow-x: auto;
}

.u-card .title > .text::-webkit-scrollbar {
  display: none;
}

.u-card .title > .iconfont {
  font-size: 0.88rem;
  color: #666;
  font-weight: bold;
}

.card-data {
  width: 100%;
  padding-bottom: 0.4rem;
  display: flex;
  justify-content: space-between;
}
.card-data > .text {
  font-size: 0.78rem;
  color: #666;
}
.card-data > .text > .value {
  font-family: "Times New Roman", Times, serif;
  color: #000;
}

.u-card > .select {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}

.u-card > .select > .box {
  display: inline-block;
  margin-right: 0.4rem;
  width: 0.8rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  border: 1px solid #ee3d52;
  border-radius: 2px;
}
.u-card > .select > .box > .iconfont {
  font-size: 1rem;
  font-weight: bold;
  color: #ee3d52;
}

.u-card > .select > .disabled {
  border-color: #d6d6d6;
  background-color: #ddd;
}

.u-card > .select > .selected {
  border-color: #d6d6d6;
}

.u-card > .select > .selected > .iconfont {
  color: #999;
}

/* 投票按钮 */
.vote {
  position: fixed;
  bottom: 0.5rem;
  left: 16%;
  height: 2.4rem;
  width: 68%;
}

.vote > .btn {
  width: 100%;
  height: 100%;
  line-height: 2.4rem;
  border-radius: 2em;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.vote > .active {
  background: #09bb07;
  /* background: linear-gradient(45deg, #f7693e, #f85e38, #f34c46, #f5475b); */
}

.vote > .disabled {
  background: #aaa;
}

/* 投票规则 */
.popups-bg {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}
.popups {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}
.popups-box {
  width: 85%;
  background-color: #fff;
  text-align: center;
  border-radius: 0.4rem;
  overflow: hidden;
  /* max-height: 90%; */
  padding: 0.6rem;
  padding-bottom: 0.7rem;
  box-sizing: border-box;
}

.popups-box .box-header {
  position: relative;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #333;
  border-bottom: 1px solid #eee;
}
.popups-box .box-header h2 {
  font-size: 0.88rem;
  font-weight: bold;
  color: #333;
}
.popups.apply .close {
  position: absolute;
  top: 0rem;
  right: 0;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  overflow: hidden;
  background: #fff;
  border-radius: 50%;
}
.popups.apply .close i {
  font-size: 1rem;
  color: #666;
}
.popups .wrapper {
  overflow-y: auto;
  max-height: 80vh;
  box-sizing: border-box;
  padding-top: 0.5rem;
}
.popups .wrapper::-webkit-scrollbar {
  display: none;
}
.popups .text {
  font-size: 0.8rem;
  text-align: justify;
}
.popups >>> p {
  margin: 10px 0;
  font-weight: 100;
}
.popups.apply >>> span {
  color: #fe0101;
}

.popups >>> p >>> img {
  margin: 0 auto;
}

.popups >>> h1 {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  color: #333;
  text-align: center;
  font-weight: 400;
}

.popups.apply >>> ol >>> li {
  font-size: 0.7rem;
}

/* 描述 */
.describe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.describe > .wrapper {
  position: relative;
  width: 80%;
  padding: 1rem;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fff;
}

.describe > .wrapper > .title {
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
  text-align: center;
}

.describe > .wrapper > .content {
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
}

.describe > .wrapper > .iconfont {
  position: absolute;
  top: 0;
  right: 0;
  height: 2.4rem;
  width: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
}

.describe > .wrapper >>> .MsoNormal {
  font-size: 0.8rem;
  text-align: justify !important;
  text-indent: 1.6rem !important;
  color: #333;
}

/* 排行榜 */

.rank-wrapper {
  position: relative;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(243, 213, 162);
  padding: 0;
  box-sizing: border-box;
}
.rank-header {
  position: relative;
  height: 6rem;
  width: 100%;
  position: relative;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 0 0 0.8rem 0.8rem;
  overflow: hidden;
}
.rank-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.24)
  );
}
.rank-header::after {
  content: "";
  position: absolute;
  width: calc(100% - 1rem);
  height: 10px;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
  bottom: calc(2rem - 5px);
  left: 0.5rem;
  border-radius: 2em;
}

.rank-wrapper h2 {
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  padding: 0 6px;
  transition: color 0.3s;
}

.rank-wrapper h2::after {
  content: "";
  display: block;
  width: 2.64rem;
  height: 3px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  transition: width 0.3s;
}

.rank-wrapper .close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rank-wrapper .close i {
  font-size: 1rem;
  color: #eee;
}

/* 排行列表 */
.no-rank-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-rank-list img {
  opacity: 0.3;
  width: 40%;
  margin-bottom: 1rem;
}

.no-rank-list p {
  font-size: 0.8rem;
  color: #999;
}
.rank-content {
  width: 100%;
  height: calc(100% - 3.5rem);
  overflow-y: auto;
  padding: 0.8rem;
  padding-top: 0;
  box-sizing: border-box;
  margin-top: -2rem;
  background-color: inherit;
}
.rank-list {
  width: 100%;
  padding: 0;
}
.rank-list-item {
  position: relative;
  width: 100%;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.8rem 0.8rem 1.8rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  background-color: #4ec0f1;
  overflow: hidden;
}
.rank-list-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1)
  );
}
.rank-list-item:nth-child(1) {
  background-color: #e33;
}
.rank-list-item:nth-child(2) {
  background-color: #f37e11;
}
.rank-list-item:nth-child(3) {
  background-color: #67c23a;
}
.rank-ranking::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.2) 50%, transparent 0);
  border-top-left-radius: 0.5rem;
  z-index: -1;
}
.rank-ranking {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.78rem;
  font-weight: 600;
  z-index: 1;
}
.rank-list-item .rank-item-icon {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}
.rank-item-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rank-item-info {
  position: relative;
  width: calc(100% - 3rem);
  height: 3rem;
  padding-left: 0.4rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.rank-item-info .info-name {
  height: 1.6rem;
  line-height: 1.6rem;
  font-size: 0.88rem;
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
}
.rank-item-info .info-num {
  height: 1.4rem;
  line-height: 1.4rem;
  font-size: 0.68rem;
  color: #ffffffef;
  border-radius: 2em;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
}
.rank-item-info .info-votes {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.68rem;
  padding: 2px 12px;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 2em;
}
.load-more {
  position: relative;
  height: 2rem;
  width: 100%;
  z-index: 1;
  background-color: inherit;
}
.load-more .load-more-line {
  position: absolute;
  z-index: 0;
  width: 40%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.3);
  top: 50%;
  left: 30%;
}
.load-more .load-more-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: hsla(0, 0%, 0%, 0.3);
  font-size: 0.68rem;
  background-color: inherit;
  padding: 0 4px;
  z-index: 1;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.3);
}

/* 公众号二维码 */
.public-qr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.8);
}
.public-qr img {
  max-width: 100%;
  margin-bottom: 0.5rem;
}
.public-qr span {
  font-size: 0.8rem;
  color: #fff;
}
</style>
