/**
 * 自定义过滤器
 */

/**
 * 格式化时间
 * @param timestamp  从后台获取到的毫秒级时间戳
 */
const formatDate = (timestamp) => {
    timestamp = timestamp.replace(/^\s+|\s+$/, '');
    if (/^\d{10}$/.test(timestamp)) {
        timestamp *= 1000;
    } else if (/^\d{13}$/.test(timestamp)) {
        timestamp = parseInt(timestamp);
    } else {
        alert('时间戳格式不正确！');
        return;
    }

    var time = new Date(timestamp);
    var year = time.getFullYear();
    var month = (time.getMonth() + 1) > 9 && (time.getMonth() + 1) || ('0' + (time.getMonth() + 1));
    var date = time.getDate() > 9 && time.getDate() || ('0' + time.getDate());
    var hour = time.getHours() > 9 && time.getHours() || ('0' + time.getHours());
    var minute = time.getMinutes() > 9 && time.getMinutes() || ('0' + time.getMinutes());
    var second = time.getSeconds() > 9 && time.getSeconds() || ('0' + time.getSeconds());
    var YmdHis = year + '-' + month + '-' + date
        + ' ' + hour + ':' + minute + ':' + second;

    return YmdHis;
};

/**
 * 格式化播放次数
 * @param playnum  从接口获取到的播放次数
 */
const formatPlaynum = (playnum) => {
    var str=0;
    if (playnum > 10000) {       //大于一万就以‘万’为单位显示
        str = (playnum/10000).toFixed(1) + '万';
    }else if (playnum > 100000) { //向上取整，有小数就整数部分加一
        str = Math.ceil(playnum/10000).toFixed(1) + '万';
    }else {
        return playnum
    }
    return str
};

module.exports =  {
    formatDate:formatDate,
    formatPlaynum:formatPlaynum
};