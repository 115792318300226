<template>
  <div class="issue_sale_container">
    <div class="is_header">
      <div class="back">
        <button @click="goBack">
          <i class="iconfont iconback"></i>
          返回
        </button>
      </div>
      <h2 class="title">奖品设置</h2>
      <div class="new-btn">
        <button @click="showNewSelect=true">+添加</button>
      </div>
      <div class="new-select-mask" v-show="showNewSelect" @click.self="showNewSelect=false"></div>
      <transition name="scale">
        <div class="new-select" v-show="showNewSelect">
          <p @click="newSale('sale')">添加折扣</p>
          <p @click="newSale('prize')">添加赠品</p>
        </div>
      </transition>
    </div>
    <div class="is_main">
      <div class="is_main_wrapper" v-if="sale_list && sale_list.length">
        <transition-group name="item" class="tansition_item" tag="div">
          <div class="is_main_item" v-for="(item, i) in sale_list" :key="item.id">
            <div class="item-sale" v-if="!item.newUrl">
              <div class="left">
                <!-- <p class="title">{{i+1}}/折扣券</p> -->
                <div class="content">
                  <p>{{item.label_name}}</p>
                  <p>折</p>
                </div>
              </div>
              <div class="right">
                <div class="row row-1">
                  <h3>
                    <span class="name">折扣券</span>
                    <span class="sep">/</span>
                    <span class="chance">{{filterChance(item.chance)}}%</span>
                  </h3>
                  <span
                    class="status"
                    :style="'color:' + filterStats(item.is_online).color"
                  >{{filterStats(item.is_online).text}}</span>
                </div>
                <div class="row row-2">
                  <p>时间：{{item.btime}} 至 {{item.etime}}</p>
                  <!-- <p>中奖几率： {{filterChance(item.chance)}}%</p> -->
                </div>
                <div class="row row-3">
                  <transition name="online" mode="out-in">
                    <button class="online" v-if="item.is_online == 0" key="on">
                      <span @click="handleStats('is_online', i)">上线</span>
                    </button>
                    <button class="online" v-else-if="item.is_online == 1" key="off">
                      <span @click="handleStats('is_offline', i)">下线</span>
                    </button>
                  </transition>
                  <button class="edit" @click="editSale(i + 1, item.newUrl)">修改</button>
                  <button class="delete" @click="handleDel(i)">删除</button>
                </div>
              </div>
            </div>

            <div class="item-prize" v-if="item.newUrl">
              <div class="left">
                <img :src="item.newUrl" alt />
              </div>
              <div class="right">
                <div class="row row-1">
                  <h3>
                    <span class="name">{{item.label_name}}</span>
                    <span class="sep">/</span>
                    <span class="chance">{{filterChance(item.chance)}}%</span>
                  </h3>
                  <span
                    class="status"
                    :style="'color:' + filterStats(item.is_online).color"
                  >{{filterStats(item.is_online).text}}</span>
                </div>
                <div class="row row-2">
                  <p>时间：{{item.btime}} 至 {{item.etime}}</p>
                </div>
                <div class="row row-3">
                  <transition name="online" mode="out-in">
                    <button class="online" v-if="item.is_online == 0" key="on">
                      <span @click="handleStats('is_online', i)">上线</span>
                    </button>
                    <button class="online" v-else-if="item.is_online == 1" key="off">
                      <span @click="handleStats('is_offline', i)">下线</span>
                    </button>
                  </transition>
                  <button class="edit" @click="editSale(i + 1, item.newUrl)">修改</button>
                  <button class="delete" @click="handleDel(i)">删除</button>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>

      <div class="is_main_empty" v-else>
        <img src="../../assets/switch/error.png" alt />
        <span>还没有相关奖品</span>
      </div>
    </div>

    <!-- 联系我们 -->
    <technical-support ts_type_="sale" contact_side="text" :id="id"></technical-support>
    <!-- 添加、修改折扣券 -->
    <transition name="edit">
      <div class="is_edit_mask" v-show="show_edit" @click.self="show_edit = false">
        <div class="is_edit_wrapper">
          <div class="edit_title">
            <span class="edit_back" @click="show_edit = false">
              <i class="iconfont iconbaseline-close-px"></i>
            </span>
            <h2>折扣券{{index + 1}}设置</h2>
          </div>

          <div class="edit_content">
            <div class="content_wrapper">
              <!-- 折扣 或 奖品名称 -->
              <div class="content_item" v-if="newType == 'sale'">
                <span class="item_name">折扣设置：</span>
                <input
                  type="number"
                  v-model="setting_sale"
                  placeholder="设置折扣比例"
                  :style="'color:' + saleColor"
                  @input="checkSale"
                />
                <span class="item_unit">单位：折</span>
              </div>
              <!-- 赠品名称 -->
              <div class="content_item" v-else-if="newType == 'prize'">
                <span class="item_name">赠品名称</span>
                <input
                  type="text"
                  v-model="setting_prize"
                  placeholder="设置赠品名称"
                  oninput="value=value.replace(/(\w{6})(\w*)/,'$1')"
                />
                <span class="item_unit">不超过6位字符</span>
              </div>
              <!-- 赠品图片 -->
              <div class="content_item" v-if="newType == 'prize'">
                <span class="item_name">设置图片</span>
                <img v-if="img_selected" :src="img_selected" class="preview-img" />
                <button
                  :class="[img_selected ? 'change-img' : '','select-img']"
                  @click="showEditImg=true"
                >{{img_selected ? "更换图片" : "选择图片"}}</button>
              </div>
              <!-- 几率 -->
              <div class="content_item">
                <span class="item_name">中奖几率：</span>
                <input
                  type="number"
                  v-model="setting_chance"
                  placeholder="设置中奖几率"
                  :style="'color:' + chanceColor"
                  @input="checkProb"
                />
                <span class="item_unit">可分配：{{remain_chance}}%</span>
              </div>
              <!-- 开始时间 -->
              <div class="content_item">
                <span class="item_name">开始时间：</span>
                <input type="date" v-model="btime" />
              </div>
              <!-- 结束时间 -->
              <div class="content_item">
                <span class="item_name">结束时间：</span>
                <input type="date" v-model="etime" :style="'color:' + timeColor" />
              </div>
            </div>
          </div>
          <div class="edit_footer">
            <div class="edit_button">
              <span class="edit_button_cancel" @click="show_edit = false">取消</span>
              <span class="edit_button_confirm" @click="submitEdit">确定</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 用户选择图片 -->
    <transition name="slide">
      <div class="edit-img" v-show="showEditImg">
        <div class="header">
          <div class="back" @click="showEditImg=false">
            <i class="iconfont iconback"></i>
            <span>取消</span>
          </div>
          <button @click="useImg">使用</button>
        </div>
        <div class="select">
          <div class="preview">
            <img :src="img_preview" alt />
          </div>
          <p class="title">选择本地图片</p>
          <div class="custom">
            <img
              :class="[preview_id == '-1' ? 'custom-preview-active' : '','custom-preview']"
              v-if="img_custom"
              :src="img_custom"
              @click="toggleSelectImg('-1')"
            />
            <div class="custom-input">
              <input type="file" accept="image/*" @input="checkImg($event)" />
              <img src="../../assets/img/tour_photo@2x.png" alt="上传图片" />
            </div>
          </div>
          <p class="title">挑选推荐图片</p>
          <div class="recommend">
            <ul class="list">
              <li
                :class="[preview_id == item.id ? 'item-active' : '','item']"
                v-for="(item,i) in saleImgList"
                :key="i"
                @click="toggleSelectImg(item.id,item.newUrl)"
              >
                <img :src="item.newUrl" />
              </li>
            </ul>
          </div>
        </div>
        <div class="preview"></div>
      </div>
    </transition>
    <!-- 删除折扣券 -->
    <transition name="edit">
      <div class="act_del" v-show="show_del" @click.self="show_del = false">
        <div class="del_wrapper">
          <h2 class="del_title">确定删除该折扣券吗？</h2>
          <div class="del_content">
            <span class="del_cancel" @click="show_del = false">取消</span>
            <span class="del_confirm" @click="handleStats('is_delete', del_index)">确定</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Toast, Indicator } from 'mint-ui'
import TechnicalSupport from '../../components/TechnicalSupport'
import * as qiniu from 'qiniu-js'
import md5 from 'js-md5'
export default {
  components: {
    'technical-support': TechnicalSupport
  },
  data () {
    return {
      // 授权信息
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      park_id: 298,
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin, // -------------注意
      id: this.$route.query.id_ ? this.$route.query.id_ : 0,
      type_: 'sale',
      id_type_: 'sale',
      user_id: '',
      // 获取数据
      info: {},
      sale_list: [],
      spots: [],
      total_chance: '', // 总的几率
      //   remain_chance: "", // 剩余可分配几率

      setting_sale: '', // 设置的折扣
      setting_chance: '', // 设置的几率
      setting_prize: '', // 赠品名称
      show_edit: false, // 显示编辑界面
      index: -1, // 编辑的折扣券索引
      btime: '', // 开始时间
      etime: '', // 结束时间
      default_b_time: '', // 默认开始时间
      default_e_time: '', // 默认结束时间
      is_submit: false, // 正在提交
      del_index: -1, // 要删除的折扣券索引
      show_del: false, // 显示删除界面
      is_admin: 0,
      newDisabled: false, // 不能添加

      showNewSelect: false, // 新增选项
      newType: '', // 新增类型
      showEditImg: false, // 显示选择图片
      saleImgList: [], // 推荐图片列表

      img_file: null, // 本地图片文件
      img_blob: '', // 本地图片的blob格式
      img_change: false, // 图片改变
      preview_id: '',
      img_id: '', // 选择的图片id，本地图片为 "-1"

      img_selected: '', // 选择的图片url地址
      img_custom: '', // 用户上传的图片url地址
      img_preview: '', // 预览选择的图片

      // 七牛
      qiniu_token: '',
      qiniu_img_arr: [],
      qiniu_key: ''
    }
  },
  computed: {
    // 折扣超出限制时高亮显示
    saleColor: function () {
      let val = parseFloat(this.setting_sale)
        ? parseFloat(this.setting_sale)
        : 0
      if (val >= 10) {
        return '#e33'
      } else {
        return '#333'
      }
    },
    //
    chanceColor: function () {
      let val = parseFloat(this.setting_chance)
        ? parseFloat(this.setting_chance)
        : 0
      let rem = parseFloat(this.remain_chance)
        ? parseFloat(this.remain_chance)
        : 0
      if (val > rem) {
        return '#e33'
      } else {
        return '#333'
      }
    },
    timeColor: function () {
      let btime = Date.parse(new Date(this.btime))
      let etime = Date.parse(new Date(this.etime))
      if (btime > etime) {
        return '#e33'
      } else {
        return '#666'
      }
    },
    // 剩余可分配几率
    remain_chance: function () {
      let val
      if (this.sale_list[this.index]) {
        val = this.total_chance - this.sale_list[this.index].chance
      } else {
        val = parseFloat(this.total_chance) ? parseFloat(this.total_chance) : 0
      }

      return (100 - val).toFixed(2)
    }
  },
  created () {
    this.initTime()
  },
  watch: {
    total_chance: function (val) {
      if (val >= 100) {
        this.newDisabled = true
      } else {
        this.newDisabled = false
      }
    }
  },
  mounted () {
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }
    if (this.open_id) {
    } else {
      this.authorize()
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
      this.wx_share(window.location.href)
    }
    this.getData() // 获取数据
    this.getSaleImgList() // 获取推荐图片
  },
  methods: {
    // 返回
    goBack () {
      this.$router.go(-1)
    },
    // 点击使用时，保存预览的图片
    useImg () {
      if (!this.preview_id) {
        Toast('请选择一张图片')
        return
      }
      this.img_selected = this.img_preview
      this.showEditImg = false
      this.img_change = true // 图片被修改了
      this.img_id = this.preview_id
    },
    /**
     * 点击时根据id切换选择的图片
     *
     * @param {string} id 图片的id，本地图片id为"-1"
     * @param {string=} url 图片的url，使用本地图片时，从data中获取url
     */
    toggleSelectImg (id, url) {
      this.preview_id = id
      if (id == '-1') {
        this.img_preview = this.img_custom
      } else {
        this.img_preview = url
      }
    },
    // 上传本地图片
    checkImg (e) {
      console.log(e.target)

      if (!e.target.value) {
        return
      }

      this.img_file = e.target.files[0]

      let reader = new FileReader()
      reader.onload = e => {
        this.img_custom = e.target.result
        this.render(e.target.result, this.img_file.name)
        this.img_blob = this.upload(this.img_custom, this.img_file.type)
        this.toggleSelectImg('-1')
      }

      reader.readAsDataURL(this.img_file)
    },

    /**
     * 点击删除按钮弹出提示
     *
     * @param {number} i 要删除的奖品在奖品列表中的索引
     */
    handleDel (i) {
      if (this.is_admin != 1) {
        Toast('没有权限')
        return
      }
      this.del_index = i
      this.show_del = true
    },
    /**
     * 处理奖品的上线、下线、删除操作
     *
     * @param {string} type_ 执行的操作类型
     * @param {number} index 操作对象在奖品列表中的索引
     */
    handleStats (type_, index) {
      if (this.is_admin != 1) {
        Toast('没有权限')
        return
      }
      if (type_ && index >= 0) {
      } else {
        Toast('参数错误')
        return
      }

      if (type_ == 'is_online' && this.sale_list[index].is_status != 1) {
        Toast('不在时间内')
        return
      }
      let id = this.sale_list[index].id
      if (this.open_id && this.token && id) {
        this.$ajax(
          'post',
          'Shop/update_whzyn_status_data',
          {
            id: id,
            token: this.token,
            open_id: this.open_id,
            type_: type_
          },
          res => {
            if (res.data.id == 0) {
              if (type_ == 'is_delete') {
                this.total_chance -= this.sale_list[index].chance
                this.sale_list.splice(index, 1)
                this.show_del = false
                Toast('删除成功')
              } else if (type_ == 'is_offline') {
                this.sale_list[index].is_online = '0'
              } else if (type_ == 'is_online') {
                this.sale_list[index].is_online = '1'
              } else {
                Toast('未知参数')
              }
            } else {
              Toast('操作失败')
            }
          }
        )
      } else {
        this.authorize()
      }
    },
    //   提交编辑
    submitEdit () {
      if (this.is_admin != 1) {
        Toast('没有权限')
        return
      }
      // 正在提交
      if (this.is_submit) {
        return
      }

      // 检查是否修改过
      if (
        this.sale_list[this.index] &&
        this.sale_list[this.index] !== undefined
      ) {
        let data_name = this.sale_list[this.index].label_name
        let data_chance = parseFloat(this.sale_list[this.index].chance)
          ? parseFloat(this.sale_list[this.index].chance)
          : 0
        let data_btime = this.sale_list[this.index].btime
        let data_etime = this.sale_list[this.index].etime
        if (
          (this.newType == 'sale' &&
            this.setting_sale == data_name &&
            this.setting_chance == data_chance &&
            this.btime == data_btime &&
            this.etime == data_etime) ||
          (this.newType == 'prize' &&
            this.setting_prize == data_name &&
            !this.img_change &&
            this.setting_chance == data_chance &&
            this.btime == data_btime &&
            this.etime == data_etime)
        ) {
          // 没有改动
          this.show_edit = false
          return
        }
      }

      // 检查折扣设置
      if (
        this.newType == 'sale' &&
        (!this.setting_sale ||
          this.setting_sale >= 10 ||
          this.setting_sale == '0')
      ) {
        Toast('请检查折扣设置')
        return
      }

      // 检查概率设置
      let rem = parseFloat(this.remain_chance)
        ? parseFloat(this.remain_chance)
        : 0
      if (
        !this.setting_chance ||
        this.setting_chance > rem ||
        this.setting_chance < 0
      ) {
        Toast('请检查中奖几率')
        return
      }

      // 检查时间设置
      let btime = Date.parse(new Date(this.btime))
      let etime = Date.parse(new Date(this.etime))
      if (etime < btime) {
        Toast('请检查时间设置')
        return
      }

      // 提交
      if (
        this.open_id &&
        this.token &&
        this.id &&
        this.id_type_ &&
        this.setting_chance
      ) {
        if (this.newType == 'sale') {
          // 检查折扣设置
          if (
            !this.setting_sale ||
            this.setting_sale >= 10 ||
            this.setting_sale == '0'
          ) {
            Toast('请检查折扣设置')
          } else {
            this.send(this.setting_sale, '')
          }
        } else if (this.newType == 'prize') {
          if (!this.setting_prize) {
            Toast('请设置奖品名称')
            return
          }

          if (
            // 如果是修改，此时可能图片没有修改
            this.sale_list[this.index] &&
            this.sale_list[this.index] !== undefined &&
            !this.img_change
          ) {
            let img_key = this.sale_list[this.index].img_key
            this.send(this.setting_prize, img_key)
          } else {
            // 新增或者图片被改变

            if (this.img_id == '') {
              // 先检查图片设置
              Toast('请选择一张图片')
            } else if (this.img_id != '-1') {
              // 使用的推荐图片
              let img_key = this.saleImgList.filter(item => {
                return item.id == this.img_id
              })[0].img_key

              this.send(this.setting_prize, img_key)
            } else {
              // 使用的本地图片
              this.uploadQiniu()
            }
          }
        }
      } else {
        this.authorize()
      }
    },

    /**
     * 向服务器提交数据
     *
     * @param {string} name 折扣或者奖品名称
     * @param {string} key 实物图片的key
     */
    send (name, key) {
      // 获取id
      let id =
        this.sale_list[this.index] && this.sale_list[this.index].id
          ? this.sale_list[this.index].id
          : 0
      // 提交
      let setting_name
      if (this.newType == 'sale') {
        setting_name = this.setting_sale
      } else if (this.newType == 'prize') {
        setting_name = this.setting_prize
      }

      this.is_submit = true
      Indicator.open()
      this.$ajax(
        'post',
        'Shop/save_whzyn_sale_data',
        {
          id: id,
          token: this.token,
          open_id: this.open_id,
          sale: name,
          chance: this.setting_chance,
          id_: this.id,
          id_type_: this.id_type_,
          btime: this.btime,
          etime: this.etime,
          img_key: key
        },
        res => {
          if (res.data.id == 0) {
            console.log(res)
            this.total_chance = res.data.data.have_chance
            if (this.sale_list[this.index]) {
              this.sale_list[this.index].is_status = res.data.data.is_status
              if (this.newType == 'sale') {
                this.sale_list[this.index].label_name = this.setting_sale
              } else {
                this.sale_list[this.index].label_name = this.setting_prize
                if (this.img_change) {
                  this.sale_list[this.index].newUrl = this.img_selected
                }
              }
              this.sale_list[this.index].chance = this.setting_chance
              this.sale_list[this.index].btime = this.btime
              this.sale_list[this.index].etime = this.etime
              console.log(this.sale_list)
            } else {
              this.sale_list.push(res.data.data.data)
            }
          } else {
            Toast('设置失败')
          }

          // 提交完成了
          this.is_submit = false
          this.show_edit = false
          Indicator.close()
        }
      )
    },

    // qiniu
    uploadQiniu () {
      Indicator.open()
      this.$ajax(
        'post',
        'Lottery/get_qiniu_token',
        {
          token: this.token,
          bucket: 'public',
          open_id: this.open_id
        },
        res => {
          Indicator.close()
          if (res.data.id == 0) {
            let t = new Date()
              .toLocaleDateString()
              .split('/')
              .map(item => {
                if (item.length <= 1) {
                  return '0' + item
                }
                return item
              })
              .join('')
            let fileName = this.img_file.name
            console.log(fileName)
            let key = t + '_' + md5(fileName)

            let blob = this.img_blob

            let observable = qiniu.upload(blob, key, res.data.data.token)

            let that = this

            let observer = {
              next (res) {},
              error (err) {
                console.log(err)
                Toast('上传图片失败')
              },
              complete (res) {
                console.log(res)
                let img_key = res.key
                that.send(that.setting_prize, img_key)
              }
            }

            let subscription = observable.subscribe(observer)
          } else {
            Toast('图片上传失败')
          }
        }
      )
    },

    /**
     * 新增奖品信息
     *
     * @param {string} type 操作对象的类型，"sale"为折扣券，"prize"为奖品
     */
    newSale (type) {
      if (this.is_admin != 1) {
        Toast('没有权限')
        return
      }
      if (this.newDisabled) {
        Toast('概率超过100%，不能添加')
        return
      }
      // 数据先重置一下
      this.resetData()
      this.index = this.sale_list.length
      this.newType = type
      this.showNewSelect = false
      this.show_edit = true
    },
    /**
     * 编辑奖品信息
     *
     * @param {number} n 修改对象的索引值
     * @param {string} url 修改对象的图片url
     */
    editSale (n, url) {
      if (this.is_admin != 1) {
        Toast('没有权限')
        return
      }
      // 数据先重置一下
      this.resetData()
      this.index = n - 1

      this.setting_chance = parseFloat(this.sale_list[this.index].chance)
        ? parseFloat(this.sale_list[this.index].chance)
        : 0
      this.btime = this.sale_list[this.index].btime
        ? this.sale_list[this.index].btime
        : this.default_b_time
      this.etime = this.sale_list[this.index].etime
        ? this.sale_list[this.index].etime
        : this.default_e_time
      if (url != '') {
        this.newType = 'prize'
        this.img_selected = url
        this.setting_prize = this.sale_list[this.index].label_name
      } else {
        this.newType = 'sale'
        this.setting_sale = this.sale_list[this.index].label_name
      }
      this.show_edit = true
    },
    // 重置数据
    resetData () {
      this.img_file = null
      this.img_blob = ''
      this.img_change = false
      this.preview_id = ''
      this.img_id = ''
      this.img_selected = ''
      this.img_custom = ''
      this.img_preview = ''

      this.setting_sale = ''
      this.setting_prize = ''
      this.setting_chance = ''
      this.btime = this.default_b_time
      this.etime = this.default_e_time
    },
    /**
     * 根据传入的值返回用于显示奖品状态的字符串
     *
     * @param {string} val 用0和1表示奖品的下线、上线状态
     * @return {string} 返回在页面中显示的字符串
     */
    filterStats (val) {
      if (val == '0') {
        return {
          color: '#888',
          text: '未上线'
        }
      } else if (val == '1') {
        return {
          color: '#09bb07',
          text: '已上线'
        }
      } else {
        Toast('未知状态')
      }
    },
    /**
     * 将传入的概率参数经过优化后输出显示
     *
     * @param {string} val 字符串类型的概率值
     * @return {number} 返回浮点型的概率值
     */
    filterChance (val) {
      return parseFloat(val) ? parseFloat(val) : 0
    },
    // 检查输入数字
    checkSale () {
      var regStrs = [
        ['[^\\d\\.]+$', ''], // 禁止录入任何非数字和点
        ['\\.(\\d?)\\.+', '.$1'], // 禁止录入两个以上的点
        ['^(\\d+\\.\\d{1}).+', '$1'] // 禁止录入小数点后两位以上
      ]
      for (let i = 0; i < regStrs.length; i++) {
        var reg = new RegExp(regStrs[i][0])
        this.setting_sale = this.setting_sale.replace(reg, regStrs[i][1])
      }
      // console.log(typeof this.settingValue);
    },
    // 检查输入数字
    checkProb () {
      var regStrs = [
        ['[^\\d\\.]+$', ''], // 禁止录入任何非数字和点
        ['\\.(\\d?)\\.+', '.$1'], // 禁止录入两个以上的点
        ['^(\\d+\\.\\d{2}).+', '$1'] // 禁止录入小数点后两位以上
      ]
      for (let i = 0; i < regStrs.length; i++) {
        var reg = new RegExp(regStrs[i][0])
        this.setting_chance = this.setting_chance.replace(reg, regStrs[i][1])
      }
      // console.log(typeof this.settingValue);
    },
    // 返回上一级
    backToUp () {
      this.$router.go(-1)
    },
    getData () {
      if (this.token && this.open_id && this.id) {
        this.$ajax(
          'post',
          'Shop/get_whzyn_sale_list_data',
          {
            token: this.token,
            id: this.id,
            open_id: this.open_id,
            id_type_: this.id_type_
          },
          res => {
            if (res.data.id == 0) {
              this.info = res.data.data // 所有数据
              this.sale_list = this.info.list ? this.info.list : ''
              this.total_chance = this.info.chance ? this.info.chance : 0
              this.spots = this.info.spots ? this.info.spots : ''
              document.title = this.spots.title // 标题
              this.is_admin = this.info.is_admin
              console.log(this.info)
            } else if (res.data.id == -2) {
              this.is_admin = res.data.data.is_admin
            } else {
              Toast('无数据')
            }
          }
        )
      } else {
        // this.authorize();
      }
    },
    // 获取实物奖品图片列表
    getSaleImgList () {
      this.$ajax(
        'post',
        'Shop/get_shop_sale_image_list',
        {
          id_: this.id,
          id_type_: this.id_type_,
          token: this.token,
          open_id: this.open_id,
          page: 1
        },
        res => {
          console.log(res)
          if (res.data.id == 0) {
            this.saleImgList = res.data.data.list || ''
          } else {
            console.log(res.data.msg)
          }
        }
      )
    },
    // 初始化默认时间
    initTime () {
      let t = new Date()
        .toLocaleDateString()
        .split('/')
        .map(item => {
          if (item.length <= 1) {
            return '0' + item
          }
          return item
        })
      let year = parseInt(t[0]),
        month = t[1],
        day = t[2]
      this.default_b_time = year + '-' + month + '-' + day
      this.default_e_time = year + 1 + '-' + month + '-' + day
      console.log(this.default_b_time)
      console.log(this.default_e_time)
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&state=sale'
      window.location.href = open_url
    }
  }
}
</script>

<style scoped>
button {
  outline: none;
  border: none;
  background-color: transparent;
  background-image: none;
}
.issue_sale_container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: rgb(238, 241, 244);
  padding-bottom: 1rem;
  padding-top: 3.5rem;
  box-sizing: border-box;
}
.is_header {
  position: fixed;
  top: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  line-height: 3rem;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 2px #ccc;
  z-index: 2;
}
.is_header .title {
  color: #000;
  font-size: 1rem;
}

.is_header .back {
  position: absolute;
  height: 3rem;
  line-height: 3rem;
  top: 0;
  left: 0.5rem;
}
.is_header .back button {
  height: 100%;
  color: #333;
  font-size: 0.78rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is_header .back i {
  font-size: 0.78rem;
  color: #666;
}

.is_header .new-btn {
  position: absolute;
  right: 0.5rem;
  top: 0;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
}

.is_header .new-btn button {
  border: 1px solid #00000010;
  color: #fff;
  display: inline-block;
  padding: 6px 18px;
  background: rgb(17, 174, 255);
  border-radius: 2em;
}

.new-select-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 1;
}

.new-select {
  position: absolute;
  right: 0.5rem;
  top: 3rem;
  width: 6rem;
  background-color: #666;
  border-radius: 4px;
  color: #fff;
  transform-origin: 5rem 0;
  font-size: 0.78rem;
  z-index: 2;
}

.new-select p:not(:last-child) {
  border-bottom: 1px solid #aaa;
}

.new-select::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #666;
  top: -6px;
  right: 1rem;
}

.scale-enter,
.scale-leave-to {
  opacity: 0;
  transform: scale(0);
}
.scale-enter-to,
.scale-leave {
  opacity: 1;
  transform: scale(1);
}
.scale-enter-active,
.scale-leave-active {
  transition: 0.3s;
}

/*  */
.is_main {
  width: 100%;
  min-height: 100%;
  margin-bottom: 3rem;
}
.is_main .is_main_wrapper {
  width: 100%;
  height: 100%;
}

.is_main .is_main_empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.is_main .is_main_empty img {
  width: 60%;
  opacity: 0.3;
  margin-bottom: 2rem;
}

.is_main .is_main_empty span {
  font-size: 0.68rem;
  color: #666;
}

.is_main .is_main_wrapper .is_main_item {
  position: relative;
  color: #333;
}

.is_main .is_main_wrapper .is_main_item .item_row {
  width: 100%;
  height: 2rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.online-enter {
  opacity: 0;
  transform: translateY(50%);
}
.online-enter-to,
.online-leave {
  opacity: 1;
  transform: translateY(0);
}

.online-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}
.online-enter-active,
.online-leave-active {
  transition: ease 0.3s;
}
.is_main .is_main_wrapper .is_main_item .item_row_2 {
  padding: 0 1rem;
  box-sizing: border-box;
  justify-content: flex-end;
}
.is_main .is_main_wrapper .is_main_item .item_row_1 > span {
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 2rem;
  line-height: 2rem;
}
.is_main .item_row_1 .item_sale .item_sale_number {
  color: #e33;
  font-size: 14px;
  font-weight: 600;
}
.is_main .is_main_wrapper .is_main_item .item_row_2 div {
  text-align: right;
  margin-left: 1rem;
}
.is_main .is_main_wrapper .is_main_item .item_row_2 div span {
  display: inline-block;
  padding: 4px 12px;
  border: 1px solid #666;
  color: #666;
  border-radius: 2em;
}
.is_main .is_main_wrapper .is_main_item .item_row_2 div.item_upline span {
  border-color: #67c23a;
  color: #67c23a;
}
.is_main .is_main_wrapper .is_main_item .item_row_2 div.item_delete span {
  background-color: #e33;
  color: #fff;
  border-color: #e33;
}
.is_main .is_main_wrapper .is_main_item .item_row_2 div.item_change span {
  background-color: transparent;
  color: #e33;
  border-color: #e33;
}
.is_main .is_main_wrapper .is_main_item .item_row_3 .item_time_name {
  width: 25%;
  text-align: center;
  color: #666;
}
.is_main .is_main_wrapper .is_main_item .item_row_3 .item_time_duration {
  width: 50%;
  text-align: left;
}
.is_main .is_main_wrapper .is_main_item .item_row_3 .item_time_stats {
  width: 25%;
  text-align: center;
}
.is_main .is_main_wrapper .is_main_item .item_row_3 .in_time span {
  display: inline-block;
  padding: 2px 6px;
  /* background-color: rgb(36, 181, 248); */
  /* border-radius: 2em; */
  color: rgb(36, 181, 248);
}
/* 新增 */
.is_main .is_main_new {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}
.is_main .is_main_new span {
  display: inline-block;
  padding: 8px 20px;
  background: rgb(250, 184, 43);
  border-radius: 2em;
  color: #fff;
}
.is_main .is_main_new span.new_disabled {
  background: #999;
}
/* 底部 */
.is_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
}

/* 添加，修改 */
.edit-enter,
.edit-leave-to {
  transform: translateY(10vh);
  opacity: 0;
}
.edit-enter-to,
.edit-leave {
  transform: translateY(0);
  opacity: 1;
}
.edit-enter-active,
.edit-leave-active {
  transition: ease 0.3s;
}

.issue_sale_container .is_edit_mask {
  position: fixed;
  width: 100%;
  height: 120vh;
  top: -10vh;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.5rem;
  z-index: 2;
}
.issue_sale_container .is_edit_mask .is_edit_wrapper {
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0.5rem;
}
.is_edit_mask .is_edit_wrapper .edit_title {
  position: relative;
  height: 2.4rem;
  width: 100%;
  line-height: 2.4rem;
  text-align: center;
  /* background-color: #fff; */
  box-sizing: border-box;
  color: #333;
  border-bottom: 1px solid #eee;
}

.is_edit_wrapper .edit_title .edit_back {
  font-size: 14px;
  position: absolute;
  right: 0;
  padding-right: 0.5rem;
}
.is_edit_wrapper .edit_title .edit_back .iconfont {
  font-size: 20px;
  color: #666;
}
/* 编辑内容区域 */
.is_edit_wrapper .edit_content {
  width: 100%;
  /* max-height: 50vh; */
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
}
.is_edit_wrapper .edit_content .content_item {
  position: relative;
  width: 100%;
  height: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}
.is_edit_wrapper .edit_content .content_item .item_name {
  width: 4.6rem;
}
.is_edit_wrapper .edit_content .content_item input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 2rem;
  line-height: 2rem;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  flex: 1;
}

.edit_content .preview-img {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  margin-right: 1rem;
}

.edit_content .select-img {
  height: 2rem;
  line-height: 2rem;
  border-radius: 4px;
  border: 1px solid hsla(0, 0%, 0%, 0.1);
  background-color: #409eff;
  color: #fff;
  flex: 1;
}

.edit_content .change-img {
  border: 1px solid hsla(0, 0%, 0%, 0.1);
  background-color: #fff;
  color: #666;
}

.is_edit_wrapper .edit_content .content_item input::-webkit-input-placeholder {
  color: #999;
  font-size: 12px;
}
.is_edit_wrapper .edit_content .content_item .item_unit {
  position: absolute;
  display: inline-block;
  color: #666;
  font-size: 12px;
  right: 0.5rem;
}
/* 编辑底部按钮 */
.is_edit_wrapper .edit_footer {
  height: 3rem;
  width: 100%;
}
.is_edit_wrapper .edit_footer .edit_button {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.is_edit_wrapper .edit_footer .edit_button span {
  display: inline-block;
  padding: 6px 20px;
  border-radius: 2em;
  margin-right: 1rem;
  font-size: 14px;
}
.is_edit_wrapper .edit_footer .edit_button span:last-child {
  margin: 0;
}
.is_edit_wrapper .edit_footer .edit_button span.edit_button_cancel {
  color: #e33;
  border: 1px solid #e33;
}
.is_edit_wrapper .edit_footer .edit_button span.edit_button_confirm {
  color: #fff;
  border: 1px solid #e33;
  background: #e33;
}

/* 选择图片 */
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}

.slide-enter-active,
.slide-leave-active {
  transition: 0.3s;
}

/* ======
  编辑图片
  ====== */
.edit-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4;
  background-color: #666;
  padding: 0.5rem;
  box-sizing: border-box;
}

.edit-img .header {
  position: relative;
  height: 2.4rem;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
}

.edit-img .header .back {
  height: 2.4rem;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 0.8rem;
}
.edit-img .header .iconback {
  color: #fff;
  font-size: 1.2rem;
}

.edit-img .header button {
  background-color: #09bb07;
  font-size: 0.8rem;
  padding: 6px 18px;
  border-radius: 4px;
  color: #fff;
}

.edit-img .select {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.edit-img .select .preview {
  position: relative;
  width: 40vw;
  height: 40vw;
  margin: 1rem auto 0;
}

.edit-img .select .preview::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 30%;
  background-color: transparent;
  top: -8px;
  left: -8px;
  border-top: 2px solid rgb(233, 109, 27);
  border-left: 2px solid rgb(248, 108, 27);
}

.edit-img .select .preview::after {
  content: "";
  position: absolute;
  width: 40%;
  height: 30%;
  background-color: transparent;
  right: -8px;
  bottom: -8px;
  border-right: 2px solid rgb(233, 109, 27);
  border-bottom: 2px solid rgb(248, 108, 27);
}

.edit-img .select .preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.edit-img .title {
  position: relative;
  height: 2rem;
  line-height: 2rem;
  margin: 0.5rem 0;
  padding-left: 0.5rem;
  font-size: 0.8rem;
  color: #fff;
}

.edit-img .title::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0.6rem;
  width: 2px;
  height: 0.8rem;
  background-color: rgb(41, 152, 243);
}

.edit-img .custom {
  position: relative;
  width: 100%;
  height: 92px;
  display: flex;
}
.edit-img .custom .custom-preview {
  width: 92px;
  height: 92px;
  object-fit: cover;
  margin-right: 4px;
}

.edit-img .custom .custom-preview-active {
  box-shadow: 0 0 2px 2px #fff;
}

.edit-img .custom-input {
  position: relative;
  width: 92px;
  height: 92px;
  overflow: hidden;
}

.edit-img .custom-input img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.edit-img .custom-input input {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  padding-top: 100%;
  height: 0;
  width: 100%;
  border: none;
  background-size: cover;
  background-position: center;
}

.edit-img .recommend {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}

.edit-img .recommend .list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.edit-img .recommend .item {
  width: calc(25vw - 0.25rem);
  height: calc(25vw - 0.25rem);
  border: 2px solid transparent;
  box-sizing: border-box;
}
.edit-img .recommend .item-active {
  border-color: rgba(255, 255, 255, 0.8);
}

.edit-img .recommend .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* 删除界面 */
.act_del {
  position: fixed;
  top: -10vh;
  left: 0;
  width: 100vw;
  height: 120vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 4;
}

.act_del .del_wrapper {
  width: 80%;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0.5rem;
}
.act_del .del_wrapper .del_title {
  text-align: center;
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  /* border-bottom: 1px solid #eee; */
}
.act_del .del_wrapper .del_content {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.act_del .del_wrapper .del_content span {
  padding: 6px 12px;
  border: 1px solid #e33;
  border-radius: 4px;
}

.act_del .del_wrapper .del_content .del_cancel {
  color: #e33;
  margin-right: 2rem;
}
.act_del .del_wrapper .del_content .del_confirm {
  background-color: #e33;
  color: #fff;
}

/*  */

.tansition_item {
  position: relative;
}

.item-enter,
.item-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.item-enter-active,
.item-leave-active {
  position: absolute;
  transition: 0.5s ease;
}

/* 新的折扣列表样式 1122 */
.item-sale {
  width: 100%;
  height: 6rem;
  padding: 0.5rem;
  /* margin: 0.5rem 0; */
  box-sizing: border-box;
  display: flex;
  filter: drop-shadow(0 0 4px #e6e6e6);
}

.item-sale .left {
  position: relative;
  width: 5rem;
  height: 100%;
  background: radial-gradient(
        circle at top left,
        transparent 0,
        rgb(244, 92, 92) 0
      )
      top left,
    radial-gradient(circle at top right, transparent 8px, rgb(244, 92, 92) 0)
      top right,
    radial-gradient(circle at bottom right, transparent 8px, rgb(244, 92, 92) 0)
      bottom right,
    radial-gradient(circle at bottom left, transparent 0, rgb(244, 92, 92) 0)
      bottom left;
  background-size: 52% 52%;
  background-repeat: no-repeat;
}

.item-sale .left::after {
  content: "";
  position: absolute;
  top: 10px;
  left: -3px;
  width: 100%;
  height: calc(100% - 20px);
  background-color: transparent;
  border-right: 6px dotted rgb(238, 241, 244);
}

.item-sale .right {
  height: 100%;
  flex: 1;
  background: radial-gradient(circle at top left, transparent 8px, #fff 0) top
      left,
    radial-gradient(circle at top right, transparent 0, #fff 0) top right,
    radial-gradient(circle at bottom right, transparent 0, #fff 0) bottom right,
    radial-gradient(circle at bottom left, transparent 8px, #fff 0) bottom left;
  background-size: 52% 52%;
  background-repeat: no-repeat;
}

.item-sale .left .title {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
}

.item-sale .left .content {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-sale .left .content p {
  font-size: 1.2rem;
  font-weight: bold;
}

.is_main_item .right .row {
  height: 1.5rem;
  width: 100%;
  padding: 0 0.8rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.is_main_item .right .row-1 h3 {
  color: #333;
  font-size: 0.8rem;
  font-weight: normal;
}

.is_main_item .right .row-1 .chance {
  font-weight: bold;
}

.is_main_item .right .row-1 .status {
  font-size: 0.68rem;
}

.is_main_item .right .row-2 {
  color: #666;
  font-size: 0.68rem;
}

.is_main_item .right .row-3 {
  position: relative;
  height: 2rem;
}
.is_main_item .right .row-3 button {
  padding: 4px 12px;
  font-size: 0.7rem;
  color: #666;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 4px #eee;
}

.is_main_item .right .row-3 button.delete {
  color: #e64340;
}

.item-prize {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  height: 6rem;
  padding: 0.5rem;
  filter: drop-shadow(0 0 4px #e6e6e6);
}

.item-prize .left {
  width: 5rem;
  height: 100%;
  box-shadow: 2px 0 4px -2px #ccc;
  z-index: 1;
}

.item-prize .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-prize .right {
  flex: 1;
  height: 100%;
  background-color: #fff;
}
</style>
