<template>
  <div class="AwardList" v-title data-title="奖励">
    <mt-header fixed title="奖励">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="AwardList_body" v-if="info">
        <div class="user">
          <img :src="user.user_icon" alt />
          <p>{{user.user_nick}}</p>
        </div>
        <ul class="item_list">
          <router-link
            tag="li"
            :to="{path:'/footprints/RedPacketMap',
                                 query:{r_lat:k.spot_lat,r_lng:k.spot_lng,park_id:k.park_fk_id,type:1,spot_id:k.id,user_id:user_id,title:k.park_name}}"
            v-for="(k ,i) in info"
            :key="i"
          >
            <h2 class="clearfix">
              <span class="fl">
                {{k.park_name}}
                <img
                  :src="filterUrlById(label.label_fk_id)"
                  alt
                  v-for="(label,i) in k.label"
                  :key="i"
                />
              </span>
              <span class="fr">{{k.actual_number}}个</span>
            </h2>
            <h3 class="clearfix">
              <span class="fl">{{k.add_time}}</span>
              <span class="fr">已有{{k.user_number}}人领取</span>
            </h3>
          </router-link>
        </ul>
      </div>
      <div class="foot_noinfo" v-else>
        <img src="../../assets/img/foot_noinfo@2x.png" alt />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user_id: this.get_User_Id(),
      info: [],
      user: {}
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    filterUrlById(id) {
      if (id == 154) {
        return "../../assets/img/footprint_red.png";
      } else if (id == 155) {
        return "../../assets/img/footprint_coupon.png";
      } else if (id == 156) {
        return "../../assets/img/footprints_gifts.png";
      } else {
        return false;
      }
    },
    unload() {
      this.$ajax(
        "post",
        "foot/get_user_packet_list",
        { user_id: this.user_id, page: 1 },
        res => {
          //console.log(res);
          if (res.data.data.have_packet_list == 1) {
            this.info = res.data.data.packet_list;
            this.user = res.data.data.user;
          }
        }
      );
    }
  }
};
</script>
<style scoped>
.foot_noinfo {
  width: 100%;
  text-align: center;
  padding: 100px 0;
  border-top: 5px solid #f2f2f2;
}
.foot_noinfo img {
  display: inline-block;
  width: 10rem;
}
.foot_noinfo p {
  font-size: 0.8rem;
}
.AwardList .page-content {
  padding-bottom: 0;
}

.AwardList .page-content .AwardList_body {
  background-color: #fff;
}

.AwardList .page-content .user {
  text-align: center;
  padding-top: 20px;
}

.AwardList .page-content .user > img {
  width: 3rem;
  /*height: 60px;*/
  border-radius: 50%;
  display: inline-block;
}

.AwardList .page-content .blue {
  font-size: 0.5rem;
  margin: 5px 10px;
}

.AwardList .page-content .item_list {
  font-size: 0.7rem;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 10px;
  border-top: 5px solid #f2f2f2;
}

.AwardList .page-content .item_list li {
  padding: 10px;
  border-bottom: 1px solid #f2f2f2;
}

.AwardList .page-content .item_list span {
  display: inline-block;
}

.AwardList .page-content .item_list img {
  width: 1rem;
  display: inline-block;
  vertical-align: middle;
}

.AwardList .page-content .item_list h3 {
  font-size: 0.6rem;
  color: #ccc;
}
</style>