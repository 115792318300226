import Vue from 'vue'
import md5 from 'js-md5'
import VueRouter from 'vue-router';
//微信分享
var wxshare = require('./wxshare.js');


var my_map = require('./map.js');
/**
 * 混合开发
 */
var hybrid = require('./hybrid.js');
Vue.prototype.hybrid = hybrid;


// 原生JS数据请求方法
var http = require('../services/xmlhttp.js');

/**
 * 原生Ajax请求方法
 * @param method post/get
 * @param url    数据接口名
 * @param data   上传的数据
 * @param callback  回调函数
 * @param to_:api,app 接口访问的地址
 */
Vue.prototype.$ajax = function (method, url, data, callback,to_='api') {
  http.xmlhttp.method(method, url, data, callback,to_);
};


/**
 * 表单提交请求方法 可用来图片上传；默认为post方法
 * @param url  数据接口名
 * @param data 表单数据
 * @param callback 回调函数
 */
Vue.prototype.formMethod = function (url, data, callback) {
  http.xmlhttp.formMethod(url, data, callback);
};

/**
 *  图片上传，将base64的图片转成二进制对象，塞进formdata上传
 * @param basestr    图片base64
 * @param type       file.type
 * @returns {*}      返回blob数据 form 上传
 */
Vue.prototype.upload = function (basestr, type) {
  var text = window.atob(basestr.split(",")[1]);
  var buffer = new ArrayBuffer(text.length);
  var ubuffer = new Uint8Array(buffer);
  for (var i = 0; i < text.length; i++) {
    ubuffer[i] = text.charCodeAt(i);
  }
  var Builder = window.WebKitBlobBuilder || window.MozBlobBuilder;
  var blob;
  if (Builder) {
    var builder = new Builder();
    builder.append(buffer);
    blob = builder.getBlob(type);
  } else {
    blob = new window.Blob([buffer], { type: type });
  }
  return blob;
};
/**
 * 获取file数据预览图片
 */
Vue.prototype.getObjectURL = function (file) {
  var url = null;
  if (window.createObjectURL != undefined) {
    url = window.createObjectURL(file)
  } else if (window.URL != undefined) {
    url = window.URL.createObjectURL(file)
  } else if (window.webkitURL != undefined) {
    url = window.webkitURL.createObjectURL(file)
  }
  return url
};
/**
 * 得到本地存储中登录前的页面(需要记录请求用户登录前的页面url，相对于login.html文件路径)
 * @return string  存储的跳转到登录页前的页面
 * @author  vitoz
 */
Vue.prototype.get_login_url = function () {
  var url = localStorage.getItem('login_url') ? localStorage.getItem('login_url') : '/';
  if (/^\/dist/.test(url)) {
    url = url.substring(5)
  }
  if (/^#/.test(url)) {
    url = url.substring(1)
  }
  return url;
};
/**
 * 存储数组对象到本地存储localStorage
 * @param   string  key 存储的键
 * @param   obj   arr 数组对象
 * @return  boolean
 * @author  vitoz
 */
Vue.prototype.save_arr = function (key, arr) {
  var jsonStr;

  if (typeof arr == 'string') {
    jsonStr = arr;
  } else {
    jsonStr = JSON.stringify(arr);
  }

  //return jsonStr;
  try {
    return localStorage.setItem(key, jsonStr);
  } catch (e) {
    return "localStorage error:" + e;
  }
};

/**
 * 从本地存储localStorage中获取数组
 * @param   string  key   需要数据的键
 * @return  obj
 * @author  vitoz
 */
Vue.prototype.get_arr = function (key) {
  var jsonStr = localStorage.getItem(key);
  return jsonStr ? JSON.parse(jsonStr) : jsonStr;
};

/**
 * 检查用户是否登录
 * @return obj  已登录返回用户信息user_info
 * @return null 未登录返回 null
 * @author  vitoz
 */
Vue.prototype.check_login = function () {
  var user_info = this.get_arr('user_info');
  if (user_info && user_info.user_id) {
    return user_info;
  } else {
    return false;
  }
};
/**
 * 把 秒 转 时：分
 * @param value
 * @returns {string}
 */
Vue.prototype.formatSeconds = function (value) {
  var theTime = parseInt(value); // 秒
  var theTime1 = 0; // 分
  var theTime2 = 0; // 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
    }
  }
  var result = parseInt(theTime);
  if (result < 10) {
    result = '0' + result
  } else if (result == 60) {
    theTime1 = 1
    result = '00'
  }
  if (theTime1 > 0) {
    if (theTime1 == 60) {
      theTime2 = 1
      theTime1 = '00'
    }
    if (theTime1 > 9) {
      result = "" + parseInt(theTime1) + ":" + result;
    } else {
      result = "0" + parseInt(theTime1) + ":" + result;
    }
  } else {
    result = "00:" + result;
  }
  if (theTime2 > 0) {
    if (theTime1 > 9) {
      result = "" + parseInt(theTime2) + ":" + result;
    } else {
      result = "0" + parseInt(theTime2) + ":" + result;
    }
  }
  return result;
};

/**
 * 生成token
 * @returns {string}
 */
Vue.prototype.make_token = function () {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = "-";

  var time_ = new Date().getTime();
  var uuid = s.join("");
  return md5(uuid + time_);
};

/**
 * 格式化音频时间
 */
Vue.prototype.FormatAudioTime = function (duration) {
  var minute = 0;
  var second = 0;
  var minute = parseInt(duration / 60);
  if (minute < 10) minute = "0" + minute;

  var second = parseInt((duration - minute * 60) % 60);
  if (second < 10) second = "0" + second;
  var length = minute + ":" + second;
  if (duration > 0) {
    return length;
  } else {
    return "0";
  }
};

Vue.prototype.isPC = function () {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  if (window.screen.width >= 768) {
    flag = true;
  }
  return flag;
};

Vue.prototype.phoneType = function () {
  var type;
  if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("Adr") > -1) {
    type = "isAndroid";
  } else if (!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    type = "isIOS";
  } else if (!!navigator.userAgent.match(/ucbrowser/i)) {
    type = "isUC";
  } else if (!!navigator.userAgent.match(/huawei/i)) {
    type = "isHUAWEI";
  } else if (!!navigator.userAgent.match(/oppo/i)) {
    type = "isOPPO";
  } else if (!!navigator.userAgent.match(/quark/i)) {
    type = "isQuark";
  } else if (!!navigator.userAgent.indexOf("micromessenger") != -1) {
    type = "isWeixin";
  } else if (!!navigator.userAgent.match(/qqbrowser/i)) {
    type = "isQQ";
  }
  return type;
};

/**
 * 根据经纬度计算距离
 * @param latlang1 latlang2  需要计算距离的两个坐标值
 * @return 距离，米为单位
 *  */

Vue.prototype.distance = function (latlng1, latlng2) {
  var rad = Math.PI / 180,
    R = 6371000,
    lat1 = latlng1.lat * rad,
    lat2 = latlng2.lat * rad,
    sinDLat = Math.sin((latlng2.lat - latlng1.lat) * rad / 2),
    sinDLon = Math.sin((latlng2.lng - latlng1.lng) * rad / 2),
    a = sinDLat * sinDLat + Math.cos(lat1) * Math.cos(lat2) * sinDLon * sinDLon,
    c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

/**
 * 根据x，y坐标值以及图片大小计算虚拟经纬度
 * @param obj  参数对象
 * @param x_ 点位在图片中的x坐标，以左上角为原点
 * @param y_ 点位在图片中的y坐标，以左上角为原点
 * @param width,height 图片的宽高
 * @return [lat,lng]
 */

Vue.prototype.mockLagLng = function (obj) {
  // this.p_lng = this.x_ / this.width * this.lng;
  // this.p_lat = (1 - this.y_ / this.height) * this.lat;
  let x_ = obj.x_ * 1,
    y_ = obj.y_ * 1,
    width = obj.width * 1,
    height = obj.height * 1,
    lat = obj.lat * 1,
    lng = obj.lng * 1;
  let i_lng = x_ / width * lng,
    i_lat = (1 - y_ / height) * lat;
  return [i_lat, i_lng];
};

/**
 * 回到页面顶部
 */
Vue.prototype.scrollToTop = function () {
  let scrollTop =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop;

  let timer = setInterval(() => {
    let ispeed = Math.floor(-scrollTop / 5);
    document.documentElement.scrollTop = scrollTop + ispeed;
    document.body.scrollTop = scrollTop + ispeed;
    scrollTop = scrollTop + ispeed;
    if (scrollTop <= 1) {
      clearInterval(timer);
    }
  }, 16);
};

/**
 * 获取联系信息
 * @param {id_, token}
 */

Vue.prototype.getCompanyInfo = function (id_, token) {
  let promise = new Promise((resolve, reject) => {
    this.$ajax(
      'post',
      'Company/get_company_data',
      {
        id_, token
      },
      res => {
        if (res.data.id === 0 && res.data.data.company) {
          resolve(res.data.data.company)
        } else {
          // console.log(res.data.id);
          reject(res.data.id)
        }
      }
    )
  });
  return promise
} 
