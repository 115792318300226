<template>
  <div class="friend_container" v-cloak>
    <!--  -->
    <div class="mask" v-show="showMask" @click="showMask=false">
      <div class="mask_title">邀请好友帮忙代付</div>
      <div class="mask_content">
        <p>点击右上角，选择【发送给朋友】</p>
        <div>
          <img src="../../assets/img/send_to_friend.png" alt />
        </div>
      </div>
      <img class="mask_img" src="../../assets/switch/share_arrow.png" alt />
    </div>
    <!--  -->
    <div class="user_header" v-if="user_type === 1">
      <img :src="infoList.user_icon" alt class="header_potrait" />
      <p class="header_info">代付订单已创建成功，发给好友帮你付款吧~</p>
    </div>
    <!--  -->
    <div class="friend_header" v-if="user_type === 2">
      <div class="friend_info">
        <img :src="infoList.user_icon" alt class="friend_potrait" />
        <div class="friend_info_content">
          <span class="friend_name">{{infoList.user_nick}}</span>
          <span class="friend_text">帮我付一下这件商品吧，谢谢啦~</span>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="friend_content">
      <div class="pay_info">
        <div class="pay_price_name">代付金额</div>
        <div class="pay_price_content">
          <span class="price_type">￥</span>
          <span class="price_count">{{amountPrice}}</span>
        </div>
        <div class="pay_send_friend">
          <button @click="handlePay">{{ user_type === 1 ? '发送给微信好友' : '立即支付'}}</button>
        </div>
        <div class="pay_footer_info">
          <p>{{user_type===1 ? '如果订单申请退款，已支付金额将原路退还给您的好友' : '如果订单申请退款，已支付金额将原路退还给您'}}</p>
        </div>
      </div>
      <div class="goods_info" @click="backToMerchant">
        <div class="goods_img">
          <img :src="infoList.newUrl" alt />
        </div>
        <div class="goods_content">
          <div class="goods_content_title">{{infoList.title}}</div>
          <p class="goods_content_type">{{infoList.label_str}}</p>
          <div class="godds_content_price">
            <div class="goods_unit_price">￥{{infoList.amount}}</div>
            <div class="goods_numbers">x {{infoList.numbers}}</div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      sid: this.$route.query.sid ? this.$route.query.sid : "",
      user_id: "",
      version: "1.0.0",
      park_id: 298,
      oid: this.$route.query.oid ? this.$route.query.oid : 0,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id")
        ? localStorage.getItem("open_id")
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      share_open_id: this.$route.query.share_open_id
        ? this.$route.query.share_open_id
        : "",
      infoList: [], // 获取的数据列表
      numbers: "", // 商品数量
      btime: "", // 开始时间
      etime: "", // 结束时间
      notes: "", // 商家备注
      id_: this.$route.query.id_ ? this.$route.query.id_ : "",
      user_type: "", // 打开页面的人,1自己打开，2好友打开
      showMask: false, // 弹出提示遮罩层

      state: "payOrderByFriend" // 获取授权相关字段
    };
  },
  created() {},
  mounted() {
    if (this.token) {
    } else {
      //第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    // console.log("token=" + this.token);
    //投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有union_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }

    if (this.open_id) {
    } else {
      this.authorize();
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
      this.wx_share(window.location.href);
    }

    this.getData(); //最后发送网络请求，获取数据

    this.getUser();
  },
  computed: {
    amountPrice: function() {
      return (
        parseFloat(this.infoList.amount) * parseFloat(this.infoList.numbers)
      ).toFixed(2);
    }
  },
  methods: {
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=" +
        this.token +
        "&id_=" +
        this.id_ +
        "&id=" +
        this.sid +
        "&state=" +
        this.state;
      window.location.href = open_url;
    },
    // 处理点击事件
    handlePay() {
      if (this.is_weixin) {
        if (this.user_type && this.user_type === 1) {
          this.showMask = true;
        } else if (this.user_type && this.user_type === 2) {
          if (this.open_id) {
            //已经授权过了,进行支付
            var open_url =
              "https://www.zmlxj.com/api.php/Activity/web_pay_spots_order?id=" +
              this.oid +
              "&label_id=" +
              this.id_ +
              "&open_id=" +
              this.open_id +
              "&token=" +
              this.token +
              "&park_id=" +
              this.park_id +
              "&version=" +
              this.version +
              "&is_pay=1" +
              "&numbers=" +
              this.numbers +
              "&btime=" +
              this.btime +
              "&etime=" +
              this.etime +
              "&notes=" +
              this.notes;
            window.location.href = open_url;
          } else {
            //缺少的就是open_id
            //还未授权过
            this.authorize();
          }
        }
      } else {
        Toast("请在微信中打开！");
      }
    },
    // 返回商户
    backToMerchant() {
      this.$router.push({
        path: "/footprints/PrimaryScenic",
        query: {
          sid: this.sid,
          id_: this.id_
        }
      });
    },
    // 判断谁点开的页面
    getUser() {
      if (this.share_open_id && this.share_open_id != "") {
        this.user_type = 2; // 好友打开的页面
      } else {
        this.user_type = 1; // 自己打开的页面
      }
    },
    // 获取数据
    getData() {
      this.$ajax(
        "post",
        "Shop/get_spots_order",
        {
          id: this.oid,
          token: this.token,
          park_id: this.park_id,
          version: this.version,
          open_id: this.open_id,
          is_pay: 0
        },
        res => {
          if (res.data.id == 0) {
            this.infoList = res.data.data.data;
            document.title = this.infoList.title;
            console.log(this.infoList);
            this.numbers = this.infoList.numbers;
            this.btime = this.infoList.btime;
            this.etime = this.infoList.etime;
            this.notes = this.infoList.notes;
            this.share_open_id = this.infoList.open_id;
          } else {
            //缺少的就是open_id
            //还未授权过
            this.authorize();
          }
        }
      );
    }
  }
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}
* {
  user-select: none;
}
.friend_container {
  width: 100%;
  height: 100%;
  /* background-color: #000; */
}
.friend_container .mask {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}
.friend_container .mask .mask_title {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 1rem;
  font-weight: 400;
}
.friend_container .mask .mask_content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.88rem;
}
.friend_container .mask .mask_content div {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.friend_container .mask .mask_content div img {
  width: 1.2rem;
  height: 1.2rem;
}
.friend_container .mask .mask_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
}
.friend_container .user_header {
  width: 100%;
  height: 8rem;
  background-color: rgb(255, 87, 100);
  text-align: center;
  padding-top: 0.5rem;
}
.friend_container .user_header .header_potrait {
  width: 2rem;
  height: 2rem;
  display: block;
  border-radius: 50%;
  margin: 0.5rem auto;
}
.friend_container .user_header .header_info {
  color: #fff;
  font-size: 0.8rem;
}
.friend_container .friend_header {
  width: 100%;
  height: 8rem;
  background-color: rgb(255, 87, 100);
  /* text-align: center; */
  padding-top: 0.5rem;
}
.friend_container .friend_header .friend_info {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
}
.friend_container .friend_header .friend_info .friend_potrait {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 0.4rem;
}
.friend_container .friend_header .friend_info .friend_info_content {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.friend_header .friend_info .friend_info_content .friend_name {
  display: inline-block;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.8rem;
}
.friend_header .friend_info .friend_info_content .friend_text {
  position: relative;
  display: inline-block;
  height: 1.8rem;
  line-height: 1.8rem;
  padding: 0 0.4rem;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 0.88rem;
  border-radius: 2px;
}
.friend_header .friend_info .friend_info_content .friend_text::before {
  content: "";
  position: absolute;
  top: calc(0.9rem - 4px);
  left: -6px;
  display: inline-block;
  font-size: 0;
  width: 0;
  height: 0;
  border-right: 5px solid rgba(255, 255, 255, 0.4);
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 1px solid transparent;
}

.friend_container .friend_content {
  width: 90vw;
  /* height: 35vh; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  background-color: #fff;
  margin: -3rem auto 0;
  border-radius: 4px;
  padding: 0.5rem;
}
.friend_container .friend_content .pay_info {
  width: 100%;
  /* height: 20vh; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.friend_container .friend_content .pay_info .pay_price_name {
  margin-bottom: 0.5rem;
  font-size: 0.88rem;
}
.friend_container .friend_content .pay_info .pay_price_content {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}
.friend_container .friend_content .pay_info .pay_price_content .price_count {
  font-size: 1.6rem;
  font-weight: 600;
}
.friend_container .friend_content .pay_info .pay_send_friend {
  margin-bottom: 0.8rem;
  width: 100%;
  padding: 0 0.5rem;
}
.friend_container .friend_content .pay_info .pay_send_friend button {
  width: 100%;
  height: 2.4rem;
  white-space: nowrap;
  border: 1px solid rgb(196, 46, 39);
  box-sizing: border-box;
  outline: none;
  margin: 0;
  font-weight: 500;
  border-radius: 4px;
  font-size: 0.88rem;
  background-color: rgb(226, 46, 38);
  color: #fff;
  /* padding: 0 12px; */
}

.friend_container .friend_content .pay_info .pay_footer_info {
  color: #999;
  margin-bottom: 0.8rem;
}

/* 商品 */
.friend_container .friend_content .goods_info {
  width: 100%;
  display: flex;
  padding-top: 0.5rem;
}
.friend_container .friend_content .goods_info .goods_img {
  margin-right: 0.5rem;
}
.friend_container .friend_content .goods_info .goods_img img {
  width: 4.8rem;
  height: 4.8rem;
}
.friend_container .friend_content .goods_info .goods_content {
  width: calc(100% - 5.8rem);
  position: relative;
}
/* 多行溢出关键设置， 否则  -webkit-box-orient 无效*/
/* autoprefixer: off */

.friend_content .goods_info .goods_content .goods_content_title {
  width: 100%;
  white-space: nowrap;

  overflow: hidden;

  text-overflow: ellipsis;
  font-size: 0.88rem;
  margin-bottom: 0.5rem;
}
.friend_content .goods_info .goods_content .goods_content_type {
  width: 100%;
  color: #999;
  margin-bottom: 0.4rem;
  font-size: 0.72rem;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.friend_content .goods_info .goods_content .godds_content_price {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  font-size: 0.72rem;
  height: 1rem;
  line-height: 1rem;
}
</style>
