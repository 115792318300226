<template>
  <div class="wrapper">
    <div class="img-box">
      <img :src="qrcode" alt="" class="img">
    </div>
    <p class="text">微信扫一扫 体验完整功能</p>
    <div class="close" @click="handleClose">
      <i class="iconfont iconclose"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: ['showQrCode', 'qrcode'],
  data () {
    return {
      show: this.showQrCode
    }
  },
  methods: {
    handleClose () {
      this.show = false
      this.$emit('closecode')
    }
  }
}
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper > .img-box{
  width: 240px;
  height: 240px;
  overflow: hidden;
}

.wrapper > .text {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.wrapper > .close {
  margin-top: 20px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #c9c9c9;
  border-radius: 50%;
  box-sizing: border-box;
}

.wrapper > .close > .iconfont{
  font-size: 16px;
  color: #c9c9c9;
}
</style>
