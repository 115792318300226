<template>
  <div class="TrackParticulars" v-title :data-title="info.title" v-if="info.id">
    <!--给微信分享增加的文字和图片-->
    <div class="hidden">
      <img :src="wx_img" />
    </div>
    <mt-header fixed :title="info.title">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content normal" v-if="info.id">
      <div class="track_list">
        <div class="track_item">
          <div class="track_item_body">
            <!--没有标签足迹详情    is_other == false 正常的       == true 显示标签类型足迹详情-->
            <div v-if="is_other" class="padding_4">
              <div class="user-and-vote">
                <div class="feed-item-top">
                  <!--进入红包地图，判断这条足迹是不是我本人自己的，是的话就进入红包地图页面-->
                  <router-link
                    tag="div"
                    class="fr"
                    v-if="info.user_id==my_id && info.red_packet==0"
                    :to="{path:'/footprints/RedPacketMap',
                        query:{park_id:info.park_id,r_lng: info.spot_lng,r_lat: info.spot_lat,type:1,user_id:info.user_id,spot_id:spot_id}}"
                  >
                    <img src="../../assets/img/footprint_red.png" alt />
                  </router-link>
                  <router-link
                    tag="div"
                    class="fr"
                    v-if="info.user_id==my_id && info.red_packet==1"
                    :to="{path:'/my/MyAwardInfo',
                        query:{type:1,spot_id:spot_id,id:info.red_packet_id,label_id:info.label_id}}"
                  >
                    <img src="../../assets/img/footprint_red.png" alt />
                  </router-link>
                  <router-link
                    :to="{name:'otherIndex', params: { uid: info.user_id }}"
                    v-if="info.user_id != my_id"
                  >
                    <div class="avatar fl">
                      <img class="img" :src="info.user_icon" v-if="info.user_icon" />
                      <div class="img" v-else></div>
                    </div>
                    <h1>
                      <span class="name">{{info.user_nick}}</span>
                    </h1>
                    <h2>
                      <span class="time">{{info.add_time}}</span>
                    </h2>
                  </router-link>
                  <div v-else>
                    <div class="avatar fl">
                      <img class="img" :src="info.user_icon" v-if="info.user_icon" />
                      <div class="img" v-else></div>
                    </div>
                    <h1>
                      <span class="name">{{info.user_nick}}</span>
                    </h1>
                    <h2>
                      <span class="time">{{info.add_time}}</span>
                    </h2>
                  </div>
                </div>
                <!--新增进入专题页面-->
                <div class="go-vote-css" v-if="info.popular_type_fk_id > 7">
                  <router-link
                    :to="{path:'/parkPhoto/parkPhoto', query: { aid: info.popular_type_fk_id}}"
                  >
                    <p class="go-vote">专题</p>
                  </router-link>
                </div>
                <!--新增去投票按钮-->
                <div class="go-vote-css" v-if="geo_show_vote">
                  <router-link
                    :to="{path:'/parkPhoto/detail', query: { aid: info.popular_type_fk_id, id:info.id}}"
                  >
                    <p class="go-vote">投票</p>
                  </router-link>
                </div>
              </div>

              <div class="track-item-tag">
                <a href="javascript:;">{{info.title}}</a>
              </div>
              <div class="track-item-tag" v-if="info.content">
                <a
                  href="javascript:;"
                  v-html="info.content"
                  :class="{active:!is_active}"
                  @click="is_active=!is_active"
                ></a>
              </div>
              <div class="img-box padding_t_4" :class="getRowType(info.img.length)" v-if="info.img">
                <div v-for="(k,i) in info.img" class="item" :key="i">
                  <img v-if="i < 9" class="img" :src="k.img" @click.prevent="onClickMe(info.img,i)" />
                </div>
              </div>
            </div>
            <div v-if="!is_other">
              <router-link
                tag="div"
                :to="{path:'/footprints/ImgList',query:{imglist:info.img}}"
                class="top_img_box"
                v-imgBg="info.img[0].img"
              >
                <div class="top_laber">
                  <span
                    v-for="(laber, i) in info.unique_label"
                    :id="laber.id"
                    :key="i"
                  >{{laber.label_name}}</span>
                </div>
                <div class="img_num">
                  <h2>{{info.title}}</h2>
                  <p>共{{info.img.length}}张</p>
                </div>
                <!--下面是导航的功能，以前是直接跳转到高德地图页面，现在需要弹出一个选项框，让用户选择高德，百度，腾讯地图。让后在跳转进入-->
                <div
                  class="navigation"
                  @click.stop.prevent="open_map_nav(info.spot_lng,info.spot_lat,info.title,info.address)"
                >
                  <img src="../../assets/img/navigation.png" alt />
                </div>
              </router-link>
              <div class="title_info">
                <p class="clearfix">
                  <span class="fl">{{info.address}}</span>
                  <span class="fr" v-if="info.value_label[0]">
                    <span class="blue">{{info.value_label[0].label_name}}</span> 起
                  </span>
                </p>
                <p class="clearfix">
                  <span class="fl" v-if="info.time_label[0]">营业时间：{{info.time_label[0].label_name}}</span>
                  <a class="fr blue" :href="'tel:'+ info.user_tel">
                    <i class="iconfont icon-dianhua"></i>联系客服
                  </a>
                </p>
                <p class="spots_label">
                  <span v-for="(k, i) in info.spots_label" :key="i">{{k.label_name}}</span>
                </p>
              </div>
            </div>
            <div class="center_info padding_4">
              <div class="center_txt" v-if="!is_other" v-html="info.content"></div>
              <!--地图-->
              <div class="note-track-card" v-if="info.map">
                <router-link
                  :to="{path:'/footprints/RedPacketMap',query:{park_id:info.park_id,r_lng: info.spot_lng,
                                    r_lat: info.spot_lat,type:1,user_id:info.user_id,spot_id:spot_id,title:info.title}}"
                  class="map-pic"
                >
                  <img :src="info.map" alt />
                  <div
                    v-if="is_other"
                    @click.stop.prevent="open_map_nav(info.spot_lng,info.spot_lat,info.title,info.address)"
                  >
                    <img src="../../assets/img/home_navigation.png" alt class="navigation" />
                  </div>

                  <p class="foot_heigth">
                    <span>海拔:{{info.height}}&nbsp;&nbsp;&nbsp;时间:{{info.spots_time}}</span>
                  </p>
                </router-link>
              </div>
              <div class="feed-item-tag last">
                <a href="javascript:;">
                  {{info.park_name}}
                  <span class="blue">#{{info.label_name}}#</span>
                </a>
              </div>
              <!--已浏览用户列表-->
              <div class="viewer-list" v-if="info.user_list.length > 0">
                <div class="box">
                  <div class="avatar" v-for="(icon, i) in info.user_list" :key="i">
                    <router-link
                      :to="{name:'otherIndex', params: { uid: icon.user_id }}"
                      v-if="icon.user_id != user_id"
                    >
                      <div class="img" v-imgBg="icon.user_icon" v-if="icon.user_icon"></div>
                      <div class="img" v-else></div>
                    </router-link>
                    <a href="javascript:;" v-else>
                      <div class="img" v-imgBg="icon.user_icon" v-if="icon.user_icon"></div>
                      <div class="img" v-else></div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="feed-item-tool">
            <a class="zan" :class="{yes:info.like==1}" @click="zan(info.id)">
              <span>{{info.like_numbers}}</span>
            </a>
            <a class="ping" @click="discuss">
              <span>{{info.discuss_numbers}}</span>
            </a>
            <a class="zhuan">
              <span>{{info.transpond_numbers}}</span>
            </a>
          </div>
        </div>
      </div>
      <!--路书列表  组件-->
      <div v-if="info.root_list.length > 0">
        <line-info
          ref="child"
          :length="2"
          :is_border="false"
          is_tit="最美路书"
          :datainfo="info.root_list"
          :user_id="user_id"
        ></line-info>
      </div>
      <!--是否有已领取红包数据-->
      <div class="red_packet_info feed-item-top" v-if="info.have_red_packet == 1">
        <h3 class="tit">
          已领取奖励
          <span class="fr" v-if="!my_id">
            赶快点击
            <router-link :to="{path:'/my/UserReg',query:{uid:info.user_id}}" class="blue">注册</router-link>发布足迹抢红包吧!
          </span>
        </h3>
        <div class="avatar fl">
          <div
            class="img"
            v-imgBg="info.red_packet_data.park_logo"
            v-if="info.red_packet_data.park_logo"
          ></div>
          <div class="img" v-else></div>
        </div>
        <h1>
          <span class="name">{{info.red_packet_data.park_name}}</span>
        </h1>
        <h2>
          <span>{{info.red_packet_data.title}}</span>
          <img
            v-if="info.red_packet_data.label_fk_id == 154"
            src="../../assets/img/footprint_red.png"
            alt
          />
          <img
            v-if="info.red_packet_data.label_fk_id == 155"
            src="../../assets/img/footprint_coupon.png"
            alt
          />
          <img
            v-if="info.red_packet_data.label_fk_id == 156"
            src="../../assets/img/footprints_gifts.png"
            alt
          />
          <span>{{info.red_packet_data.red_packet}}元</span>
        </h2>
        <img
          class="gifts"
          @click="onClickMe([info.red_packet_data.img_base],0)"
          v-if="info.red_packet_data.label_fk_id == 156&&info.red_packet_data.img"
          :src="info.red_packet_data.img"
          alt
        />
      </div>
      <!--评论数据-->
      <div class="travelcomment">
        <h4 class="blue">最新评论({{info.discuss_numbers}})</h4>
        <ul class="comment_list" v-if="info.discuss_numbers != 0">
          <li v-for="(k ,i) in info.discuss" :key="i" :id="k.id">
            <div class="feed-item-top">
              <div class="fr" @click="zan_click_discuss(k.id,i)">
                <img v-if="k.like==0" src="../../assets/img/home_tool_btn_like.png" alt />
                <img v-if="k.like==1" src="../../assets/img/home_icon_hot_list.png" alt />
                <span>{{k.like_numbers}}</span>
              </div>
              <div class="avatar fl">
                <div class="img" v-if="k.user_icon" v-imgBg="k.user_icon"></div>
                <div class="img" v-else></div>
              </div>
              <div class="content">
                <h1>
                  <router-link
                    :to="{name:'otherIndex', params: { uid: k.user_id }}"
                    v-if="k.user_id != my_id"
                  >{{k.user_nick}}</router-link>
                  <router-link to v-else>{{k.user_nick}}</router-link>
                </h1>
                <h2>
                  <span class="time">{{k.add_time}}</span>
                  <!--<span class="location"></span>-->
                </h2>
                <p @click="add_discuss_content(k.user_nick,k.id)" v-html="k.content"></p>
              </div>
              <div class="img-box" :class="getRowType(k.img.length)" v-if="k.img">
                <div v-for="(item,i) in k.img" class="item" :key="i">
                  <p v-imgBg="item.img" @click="onClickMe(k.img,i)"></p>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div v-else class="foot_noinfo">
          <img src="/static/img/foot_noinfo@2x.14391c2.png" alt />
          <p>暂无评论</p>
        </div>
      </div>
      <!--在这里添加作品列表-->
      <div class="ParkPhotoList ParkPhoto" v-if="show_list">
        <!--v-if="is_weixin"-->
        <!-- 搜索 -->
        <div class="index-search">
          <div class="search-box">
            <input type="text" placeholder="搜索作品（手机号、编号）" id="search_text" v-model="key_word" />
            <button @click="get_data(1,union_id)">搜索</button>
            <!--<a class="all-photo" @click="get_data(1,union_id,1,1)">全部作品</a>-->
            <!--修改全部作品跳转到全部作品投票页面-->
            <router-link
              class="all-photo"
              :to="{path:'/parkPhoto/ParkPhoto',query:{aid:aid}}"
              v-if="is_vote"
            >全部作品</router-link>
          </div>
        </div>
        <!-- 作品列表 -->
        <mt-loadmore
          :bottom-method="loadBottom"
          :bottom-all-loaded="allLoaded"
          ref="loadmore"
          :auto-fill="false"
        >
          <div class="photo-list-activity">
            <div class="col">
              <div class="item" v-for="(k, i) in info_1" :key="i">
                <div class="num">
                  <span>{{k.id}}号</span>
                </div>
                <!--进入投票页面-->
                <router-link
                  :to="{path:'/parkPhoto/detail',query:{id:k.id,aid:aid,park_id:park_id,park_name:park_name,go_vote:1}}"
                  v-if="is_vote"
                >
                  <img class="pic" :src="k.newUrl" />
                </router-link>
                <!--进入足迹详情-->
                <router-link
                  :to="{path:'/footprints/FootprintsParticulars',query:{user_id:k.user_id,popular_type_fk_id:aid,id:k.id,is_vote:is_vote}}"
                  v-if="!is_vote"
                >
                  <img class="pic" :src="k.newUrl" />
                </router-link>
                <div class="img_num">
                  <span>共{{k.img_numbers}}张</span>
                </div>
                <div class="u-card" v-if="k.type != 1">
                  <router-link :to="{path:'/parkPhoto/PhotoList',query:{photo_all:1,aid:aid}}">
                    <img class="user_icon" :src="k.user_icon" />
                  </router-link>
                  <h1>{{k.user_nick}}</h1>
                  <h2 v-if="is_vote">{{k.numbers}}票</h2>
                  <h2 v-if="!is_vote">{{k.numbers}}条评论</h2>
                  <div @click.stop.prevent="open_map_nav(k.lng,k.lat,k.spots_name,k.address)">
                    <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
                  </div>
                </div>
                <div class="u-card" v-if="k.type == 1" :class="{no_park:k.type == 1}">
                  <h1>{{k.spots_name}}</h1>
                  <h2>{{k.numbers}}票</h2>
                  <div @click.stop.prevent="open_map_nav(k.lng,k.lat,k.spots_name,k.address)">
                    <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
                  </div>
                </div>
                <div
                  class="vote"
                  v-if="is_weixin && is_vote"
                  @click="vote(k)"
                  :class="{'no': (k.show == 0)|| (k.show == 1 && k.vote == 1) || (k.show == 3) }"
                >
                  <span v-if="k.show == 0">投票未开始</span>
                  <span v-else-if="k.show == 1 && k.vote == 0">我要投票</span>
                  <span v-else-if="k.show == 1 && k.vote == 1">已投票</span>
                  <span v-if="k.show == 3">投票已结束</span>
                </div>
                <div class="vote" v-else-if="is_vote" :class="{'no': 1 }">
                  <span>微信中投票</span>
                </div>
                <div class="vote" v-else :class="{'no': 1 }">
                  <span>查看详情</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="item" v-for="(k, i) in info_2" :key="i">
                <div class="num">
                  <span>{{k.id}}号</span>
                </div>
                <router-link
                  :to="{path:'/parkPhoto/detail',query:{id:k.id,aid:aid,park_id:park_id,park_name:park_name,go_vote:1}}"
                  v-if="is_vote"
                >
                  <img class="pic" :src="k.newUrl" />
                </router-link>
                <!--进入足迹详情-->
                <router-link
                  :to="{path:'/footprints/FootprintsParticulars',query:{user_id:k.user_id,popular_type_fk_id:aid,id:k.id,is_vote:is_vote}}"
                  v-if="!is_vote"
                >
                  <img class="pic" :src="k.newUrl" />
                </router-link>
                <div class="img_num">
                  <span>共{{k.img_numbers}}张</span>
                </div>
                <div class="u-card" v-if="k.type != 1">
                  <router-link :to="{path:'/parkPhoto/PhotoList',query:{photo_all:1,aid:aid}}">
                    <img class="user_icon" :src="k.user_icon" />
                  </router-link>
                  <h1>{{k.user_nick}}</h1>
                  <h2 v-if="is_vote">{{k.numbers}}票</h2>
                  <h2 v-else>{{k.numbers}}条评论</h2>
                  <div @click.stop.prevent="open_map_nav(k.lng,k.lat,k.spots_name,k.address)">
                    <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
                  </div>
                </div>
                <div class="u-card" v-if="k.type == 1" :class="{no_park:k.type == 1}">
                  <h1>{{k.spots_name}}</h1>
                  <h2>{{k.numbers}}票</h2>
                  <div @click.stop.prevent="open_map_nav(k.lng,k.lat,k.spots_name,k.address)">
                    <!--:to="{path:'/parkPhoto/ActivityMap',-->
                    <!--query:{aid:aid,-->
                    <!--production_id:k.id,-->
                    <!--type:k.type,-->
                    <!--spots_name:k.spots_name,-->
                    <!--lng:k.lng,-->
                    <!--lat:k.lat}}"-->
                    <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
                  </div>
                </div>
                <div
                  class="vote"
                  v-if="is_weixin && is_vote"
                  @click="vote(k)"
                  :class="{'no': (k.show == 0)|| (k.show == 1 && k.vote == 1) || (k.show == 3) }"
                >
                  <span v-if="k.show == 0">投票未开始</span>
                  <span v-else-if="k.show == 1 && k.vote == 0">我要投票</span>
                  <span v-else-if="k.show == 1 && k.vote == 1">已投票</span>
                  <span v-if="k.show == 3">投票已结束</span>
                </div>
                <div class="vote" v-else-if="is_vote" :class="{'no':1 }">
                  <span>微信中投票</span>
                </div>
                <div class="vote" v-else :class="{'no':1 }">
                  <span>查看详情</span>
                </div>
              </div>
            </div>
          </div>
        </mt-loadmore>
        <!--投票成功弹窗-->
        <div class="pop-up-box" @touchmove.prevent v-if="is_pop_up">
          <img class="pop-up" src="../../assets/img/vote_pop-up.png" alt />
        </div>
      </div>
      <!-------上面作品数据--->
      <!--评论输入框-->
      <div class="discuss" :class="price > 0 ? 'margin_bottom_' : '' ">
        <input type="text" placeholder="我来评论一下" @focus="discuss" />
        <router-link :to="{path:'/my/UserReg',query:{uid:info.user_id}}" v-if="!my_id">注册</router-link>
        <div @click="discuss" v-if="my_id">评论</div>
      </div>
      <!--评论弹窗输入框遮罩-->
      <div class="shade" v-if="is_discuss" @click="is_discuss=false"></div>
      <!--评论弹窗-->
      <div
        class="text_discuss TrackPublish"
        :class="price > 0 ? 'margin_bottom_' : '' "
        v-if="is_discuss"
      >
        <form id="form" action method="post" enctype="multipart/form-data">
          <div class="bd_text_discuss">
            <mt-field
              :attr="{ name: 'content' }"
              v-model="content"
              placeholder="添加评论"
              rows="4"
              type="textarea"
            ></mt-field>
            <input type="hidden" name="spot_id" v-model="info.id" />
            <input type="hidden" name="star" value="1" />
            <input type="hidden" name="user_id" v-model="my_id" />
            <input type="hidden" name="discuss_id" v-model="discuss_id" />

            <div class="imgs clearfix" v-show="is_add_img"></div>
          </div>
          <div class="click_button clearfix">
            <div class="fl">
              <div class="footprint-image">
                <img src="../../assets/img/footprint_photo.png" alt @click="AddImage($event)" />
                <input type="file" multiple accept="image/*" />
              </div>
              <div class="emoji">
                <img src="../../assets/img/footprint_emoji.png" alt />
              </div>
            </div>
            <div class="fr" @click="add_discuss">评论</div>
          </div>
        </form>
      </div>
    </div>
    <!--img轮播图-->
    <div class="preview-content" v-if="is_preview" @click="is_preview=false">
      <mt-swipe :auto="0" :showIndicators="true" :defaultIndex="index">
        <mt-swipe-item v-for="(item,i) in message" :key="i">
          <a href="javascript:;">
            <img :src="item.img_res+'&imageMogr/auto-orient'" alt />
          </a>
        </mt-swipe-item>
      </mt-swipe>
    </div>
    <!--在页面底部下面弹出高德，百度，腾讯，取消选择功能按钮-->
    <!--支付下单按钮-->
    <div class="shade" v-if="is_show_select_numbers"></div>
    <div class="select-numbers" v-if="is_show_select_numbers">
      <div class="head">
        <div class="img">
          <img :src="info.img[0].android_newUrl" />
        </div>
        <div class="title">
          <span>{{info.title}}</span>
        </div>
        <div class="close" @click="is_show_select_numbers = false">
          <img src="../../assets/img/del.png" />
        </div>
      </div>
      <div class="body">
        <div class="numbers">
          <input type="number" v-model="numbers" />
        </div>
        <div class="add_reduce">
          <div class="reduce" @click="--numbers " v-show="numbers > 1">
            <i class="iconfont iconjian"></i>
          </div>
          <div class="add" @click="++numbers">
            <i class="iconfont iconjia"></i>
          </div>
        </div>
      </div>
      <div class="stand-label" v-if="info.food_label != '' ">
        <div class="stand-title">
          <span>美食：</span>
        </div>
        <div
          class="item"
          v-for="(fk,fi) in info.food_label"
          @click="select_label(fk.label_fk_id,fk.price)"
          :key="fi"
        >
          <span :class="fk.label_fk_id == label_id_  ? 'red_' : '' ">{{fk.label_name}}/￥{{fk.price}}</span>
        </div>
      </div>
      <div class="stand-label" v-if="info.sleep_label != '' ">
        <div class="stand-title">
          <span>住宿：</span>
        </div>
        <div
          class="item"
          v-for="(sk,si) in info.sleep_label"
          @click="select_label(sk.label_fk_id,sk.price)"
          :key="si"
        >
          <span :class="sk.label_fk_id == label_id_ ? 'red_' : '' ">{{sk.label_name}}/￥{{sk.price}}</span>
        </div>
      </div>
    </div>
    <div class="pay" v-if="price > 0">
      <div class="price">
        <span>￥{{price * numbers}}</span>
      </div>
      <div class="select_num" @click="select_numbers">
        <span>选择</span>
      </div>
      <div class="make_order" @click="make_order">
        <span>下单</span>
      </div>
    </div>
    <!--App下载浮框-->
    <download v-if="is_app"></download>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
import { Indicator } from "mint-ui";
import { MessageBox } from "mint-ui";
import LineInfo from "../../components/LineInfo";
import download from "../../components/Download";
export default {
  components: {
    LineInfo,
    download
  },
  data() {
    return {
      is_app: false,
      user_id: this.$route.query.user_id,
      spot_id: this.$route.query.id,
      info: {},
      is_add_img: false,
      my_id: "",
      content: "",
      file_num: 0,
      discuss_id: 0,
      is_preview: false,
      message: [],
      index: 0,
      is_discuss: false,
      is_active: false,
      discuss_img_arr: [],
      is_other: false,
      go_show_vote: false, //this.popular_type_fk_id == 6 || this.popular_type_fk_id > 40,
      is_show_all_map: false, //这个字段为bool类型的变量，表示点击导航按钮之后，是否弹出地图选择框
      //下面是作品相关的配置
      aid: this.$route.query.popular_type_fk_id
        ? this.$route.query.popular_type_fk_id
        : this.$route.query.id == 4243
        ? 69
        : 67, //这种复杂的写法是因为app在分享的时候,没有传递popular_type_fk_id参数
      key_word: this.$route.query.key_word,
      park_id: this.$route.query.park_id,
      park_name: this.$route.query.park_name,
      banner_img: this.$route.query.banner_img,
      page: 1,
      info_1: [],
      info_2: [],
      allLoaded: false,
      itemLen: 0,
      search_list: false,
      is_pop_up: false,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      union_id: localStorage.getItem("weixin_user_info"),
      show_list: false, //是否显示作品投票列表
      wx_img: "", //微信分享使用的图片
      wx_title: "", //微信分享文字描述
      wx_url:
        "http://www.zmlxj.com/api.php/weixin/wx_share?type=FootprintsParticulars&user_id=" +
        this.$route.query.user_id +
        "&spot_id=" +
        this.$route.query.id +
        "&popular_type_fk_id=" +
        this.$route.query.popular_type_fk_id +
        "&label_id=" +
        this.$route.query.label_id +
        "&price=" +
        this.$route.query.price +
        "&del_price=" +
        this.$route.query.del_price, //分享之后跳转到地址
      wx_content: "", //desc
      is_vote: false, //判断是否投票多了一个开关

      id: this.$route.query.id ? this.$route.query.id : 0,
      label_id: this.$route.query.label_id ? this.$route.query.label_id : 0,
      price: this.$route.query.price ? this.$route.query.price : 0,
      del_price: this.$route.query.del_price ? this.$route.query.del_price : 0,
      numbers: 1, //购买商品的个数
      is_show_select_numbers: false,
      open_id: "", //微信open_id
      label_list: [], //选择的标签
      label_id_: 0
    };
  },
  mounted() {
    this.unload();
    if (this.is_weixin) {
      //实现微信分享功能
      this.dealWithUrl(window.location.href);
      this.wx_share(window.location.href);
    }
  },
  methods: {
    unload() {
      var open_id = this.$route.query.open_id;
      if (open_id) {
        this.open_id = open_id;
        localStorage.setItem("open_id", open_id);
      }
      //                开始渲染数据
      this.getData();
      //this.get_data(1,this.union_id,1);
    },
    getData() {
      //                判断是否是app打开 去除头部
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      //                是否登录
      if (this.check_login()) {
        this.my_id = this.check_login().user_id;
      }
      //                请求数据
      this.$ajax(
        "post",
        "foot/get_foot_info_v2",
        {
          spot_id: this.spot_id,
          user_id: this.my_id,
          page: 1
        },
        res => {
          if (res.data.id == 0) {
            this.info = Object.assign({}, this.info, res.data.data);
            this.aid = this.info.popular_type_fk_id; //这个值需要后台设置此条足迹某一个活动的id
            //否则不能正确的拿到aid数据,就不会显示作品数据了
            localStorage.setItem("wx_img", res.data.data.img[0].android_newUrl);
            localStorage.setItem("wx_title", res.data.data.title);
            localStorage.setItem("wx_content", res.data.data.content);

            //console.log(this.wx_img);
            this.$set(this.info, "discuss", res.data.data.discuss.reverse());
            this.is_other = this.info.is_other == 1 ? false : true;
            if (res.data.data.is_vote == 1) {
              //(this.info.popular_type_fk_id == 6) || (this.info.popular_type_fk_id > 40)
              this.geo_show_vote = true;
            } else {
              this.geo_show_vote = false;
            }
            //                        this.is_other = false
            //console.log(res);
          }
        }
      );
    },
    /*------------------------------------------------*/
    get_data(page, union_id, all, clear_page) {
      if (clear_page == 1) {
        this.page = 1;
      }
      if (all == 1) {
        var data = {
          type: 2,
          aid: this.aid,
          page: page,
          union_id: union_id
        };
      } /*else if (this.user_id) {//没看懂这个条件是什么情况？
                    var data = {
                        type: 2,
                        aid: this.aid,
                        user_id: this.user_id,
                        union_id: union_id
                    }
                }*/ else {
        var data = {
          park_id: this.park_id,
          page: page,
          aid: this.aid,
          type: 2,
          key: this.key_word,
          union_id: union_id
        };
      }
      this.$ajax("post", "SpotsRace/search", data, ret => {
        if (ret.data.id == 0) {
          this.show_list = ret.data.data ? true : false; //是否显示作品投票列表
          if (page == 1) {
            this.info_1 = ret.data.data.data.slice(
              0,
              ret.data.data.data.length / 2
            );
            this.info_2 = ret.data.data.data.slice(
              ret.data.data.data.length / 2
            );
          } else {
            if (this.getMinUl().index() == 0) {
              this.info_1 = this.info_1.concat(
                ret.data.data.data.slice(0, ret.data.data.length / 2)
              );
              this.info_2 = this.info_2.concat(
                ret.data.data.slice(ret.data.data.length / 2)
              );
            } else {
              this.info_1 = this.info_1.concat(
                ret.data.data.data.slice(0, ret.data.data.length / 2)
              );
              this.info_2 = this.info_2.concat(
                ret.data.data.data.slice(ret.data.data.length / 2)
              );
            }
          }
          this.itemLen = ret.data.data.length;
        }
      });
    },
    //            点击投票
    vote(data) {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login?aid=" +
        this.$route.query.aid;
      if (data.show == 0) {
        Toast("投票未开始");
      } else if (data.show == 3) {
        Toast("投票已结束");
      } else if (data.show == 1 && data.vote == 1) {
        Toast("今日已投票");
      } else {
        if (this.union_id) {
          this.$ajax(
            "post",
            "SpotsRace/vote",
            {
              aid: this.aid,
              spots_id: data.id,
              union_id: this.union_id
            },
            ret => {
              if (ret.data.id == 0) {
                Toast({
                  message: ret.data.msg,
                  iconClass: "iconfont icon-chenggong",
                  duration: 1000
                });
                //直接修改data里面的数据
                data.show = 1;
                data.vote = 1;
                ++data.numbers;
                //this.get_data(this.page, this.union_id)
              } else {
                Toast({
                  message: ret.data.msg,
                  iconClass: "iconfont icon-fail",
                  duration: 1000
                });
                //投票失败重新请求授权
                window.location.href = open_url;
              }
            }
          );
        } else {
          //没有拿到用户的数据
          if (this.is_weixin) {
            //重新请求
            window.location.href = open_url;
          } else {
            //表示不再微信中打开-什么都不管
          }
        }
      }
    },
    getMinUl() {
      var $arrUl = $(".photo-list .col");
      var $minUl = $arrUl.eq(0);
      $arrUl.each(function(index, elem) {
        if ($(elem).height() < $minUl.height()) {
          $minUl = $(elem);
        }
      });
      return $minUl;
    },
    //            加载更多
    loadBottom() {
      var that = this;
      setTimeout(() => {
        if (this.itemLen % 10 == 0) {
          that.page++;
          that.get_data(that.page, this.union_id, 1);
        } else {
          that.allLoaded = true;
          Toast("没有更多了");
        }
        that.$refs.loadmore.onBottomLoaded();
      }, 1500);
    },
    /*---------------------上面---------------------------------*/

    //            选择图片
    AddImage($event) {
      var file = $($event.target).siblings()[0];
      file.click();
      var that = this;
      file.onchange = function() {
        that.is_add_img = true;
        var files = Array.prototype.slice.call(this.files);
        files.forEach(function(file, i) {
          var reader = new FileReader();
          reader.onload = function() {
            that.discuss_img_arr.push(that.upload(this.result, file.type));
          };
          reader.readAsDataURL(file);
          var objUrl = that.getObjectURL(file);
          var html =
            '<div class="item up-img">' +
            '<img src="' +
            objUrl +
            '"><i class="remove"></i>' +
            "</div>";
          $(".bd_text_discuss .imgs").append(html);
          if ($(".footprint-image input").length > 9) {
            Toast("最多可上传9张图片");
          }
        });
        $(".remove").on("click", that.remove_img);
        if ($(".up-img").length >= 9) {
          Toast("最多可上传9张图片");
        }
      };
    },
    //            删除评价图片
    remove_img(e) {
      this.discuss_img_arr.splice(
        $(e.target)
          .parent()
          .index(),
        1
      );
      $(e.target)
        .parent()
        .remove();
    },
    discuss() {
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "确认登陆吗？",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            this.get_User_Id();
          }
        });
      } else {
        this.is_discuss = true;
        this.$nextTick(() => {
          $(".mint-field-core")[0].focus();
        });
      }
    },
    //            提交评论
    add_discuss() {
      if (!this.content) {
        Toast("请输入评论内容");
        return;
      }
      if (this.discuss_img_arr.length > 9) {
        Toast("最多可上传9张图片");
        return;
      }
      Indicator.open("发布中...");
      var that = this;
      var form = document.getElementById("form");
      var formdata = new FormData(form);
      for (var i = 0; i < this.discuss_img_arr.length; i++) {
        formdata.append("img" + (i + 1), this.discuss_img_arr[i]);
      }
      this.formMethod("foot/discuss_spots_and_foot", formdata, function(data) {
        Indicator.close();
        that.is_discuss = false;
        if (data.id == 0) {
          that.$ajax(
            "post",
            "foot/get_foot_info",
            {
              spot_id: that.spot_id,
              user_id: that.my_id,
              page: 1
            },
            res => {
              if (res.data.id == 0) {
                that.discuss_img_arr = [];
                that.info = Object.assign({}, that.info, res.data.data);
                that.$set(
                  that.info,
                  "discuss",
                  res.data.data.discuss.reverse()
                );
                that.content = "";
                that.file_num = 0;
                var TrackParticulars = document.querySelector(
                  ".TrackParticulars"
                );
                document.body.scrollTop = TrackParticulars.scrollHeight;
              }
            }
          );
        } else {
          Toast(data.msg);
        }
      });
    },
    add_discuss_content(content, pid) {
      this.discuss();
      this.content = "@" + content + ":";
      this.discuss_id = pid;
    },
    zan(spot_id) {
      //赞景点
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "确认登陆吗？",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            this.get_User_Id();
          }
        });
      } else {
        var user_id = this.check_login().user_id;
        this.$ajax(
          "post",
          "foot/click_spots",
          {
            user_id: user_id,
            spot_id: spot_id
          },
          res => {
            if (res.data.id == 0) {
              if (this.info.like == 0) {
                this.info.like_numbers++;
                this.$set(this.info, "like", 1);
                this.$set(this.info, "like_numbers", this.info.like_numbers);
              } else {
                this.info.like_numbers--;
                this.$set(this.info, "like", 0);
                this.$set(this.info, "like_numbers", this.info.like_numbers);
              }
            }
          }
        );
      }
    },
    zan_click_discuss(id, i) {
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "确认登陆吗？",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            this.get_User_Id();
          }
        });
      } else {
        var user_id = this.check_login().user_id;
        this.$ajax(
          "post",
          "foot/click_spots_discuss_like",
          {
            user_id: user_id,
            discuss_id: id
          },
          res => {
            if (res.data.id == 0) {
              if (this.info.discuss[i].like == 0) {
                this.info.discuss[i].like_numbers++;
                this.$set(this.info.discuss[i], "like", 1);
                this.$set(
                  this.info,
                  "like_numbers",
                  this.info.discuss[i].like_numbers
                );
              } else {
                this.info.discuss[i].like_numbers--;
                this.$set(this.info.discuss[i], "like", 0);
                this.$set(
                  this.info.discuss[i],
                  "like_numbers",
                  this.info.discuss[i].like_numbers
                );
              }
            }
          }
        );
      }
    },
    onClickMe(img, index) {
      this.is_preview = true;
      this.message = img;
      this.index = index;
      return false;
    },
    getRowType(value) {
      var rowType = "";
      if (value == 1) {
        rowType = "row1";
      } else if (value == 2 || value == 4) {
        rowType = "row2";
      } else if (value == 3 || value >= 5) {
        rowType = "row3";
      }
      return rowType;
    },
    //增加的弹出地图选择功能模块的代码
    //设置is_show_all_map的值
    set_is_show_all_map() {
      this.is_show_all_map = !this.is_show_all_map;
      console.log(this.is_show_all_map);
    },
    //直接打开腾讯地图
    location_tengxun() {
      var tengxun =
        "https://apis.map.qq.com/uri/v1/routeplan?type=drive&from=&fromcoord=&to=" +
        this.info.address +
        "&tocoord=" +
        this.info.spot_lat +
        "," +
        this.info.spot_lng +
        "&coord_type=1&policy=0&referer=最美旅行家";
      window.location.href = open_url;
    },
    //显示下单的选择框
    select_numbers() {
      this.is_show_select_numbers = true;
    },

    //选择其他标签套餐
    select_label(label_id_, price) {
      this.label_id_ = label_id_;
      this.price = price;
    },
    //下单
    make_order() {
      //先判断是否已经登录
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "确认登陆吗？",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            this.get_User_Id();
          }
        });
      } else {
        //已经登录成功了
        var user_id = this.check_login().user_id;
        var id = this.info.id;
        var title = this.info.title;
        var price = this.price;
        var numbers = this.numbers;
        var label_id = this.label_id;
        var open_id = this.open_id;
        var is_weixin = this.is_weixin;
        var label_id_ = this.label_id_;
        var park_id = this.info.park_id;
        //console.log(id,price,numbers,label_id);
        if (is_weixin) {
          //是微信
          if (open_id) {
            this.$router.push({
              path: "/ticket/MakeOrder",
              query: {
                id: id,
                title: title,
                price: price,
                numbers: numbers,
                label_id: label_id,
                open_id: open_id,
                label_id_: label_id_,
                park_id: park_id
              }
            });
          } else {
            //还没有拿到open_id
            var open_url =
              "https://www.zmlxj.com/api.php/ticket/weixin_login?id=" +
              id +
              "&price=" +
              price +
              "&numbers=" +
              numbers +
              "&label_id=" +
              label_id +
              "&label_id_=" +
              label_id_; //+'&title='+title;
            window.location.href = open_url;
          }
        } else {
          this.$router.push({
            path: "/ticket/MakeOrder",
            query: {
              id: id,
              title: title,
              price: price,
              numbers: numbers,
              label_id: label_id,
              open_id: open_id,
              label_id_: label_id_,
              park_id: park_id
            }
          });
        }
      }
    },
    open_map_nav(lng, lat, title, address) {
      if (this.is_weixin) {
        this.wx_nav(window.location.href, lng, lat, title, address);
      } else {
        Toast("请在微信中打开,使用导航");
      }
    }
  }
};
</script>
<style scoped>
a {
  color: #000;
  text-decoration: none;
}
.mint-header {
  background: #ff9a80;
  font-size: 0.8rem;
  height: 2rem;
}
.mint-header-button > a {
  color: inherit;
}
/*新增地图多选选择功能20180115*/
.select-all-map {
  /*始终漂浮在页面的最下面*/
  position: fixed;
  bottom: 2.7rem;
  left: 0;
  right: 0;
  background-color: rgba(241, 234, 234, 0.6);
  height: 11rem;
  padding: 0.2rem 0.8rem;
  z-index: 1000;
}
.nav-map .map-name {
  text-decoration: underline;
  text-align: center;

  font-size: 2rem;
}
.user-and-vote {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.go-vote {
  margin-top: 21px;
}
.go-vote-css {
  width: 30%;
  height: auto;
  background-color: #ff9a80;
  font-size: 29px;
  text-align: center;
  margin-top: 5px;
  float: right;
  line-height: 10px;
}

.TrackParticulars {
  min-height: 100%;
  background-color: #fff;
}
.hidden {
  display: none;
}
.TrackParticulars .track_list {
  font-size: 0.6rem;
}

.TrackParticulars {
  min-height: 100%;
  background-color: #fff;
}
.page-content {
  padding: 2rem 0 2.4rem;
  font-size: 0.7rem;
}
.TrackParticulars .page-content {
  height: 100%;
}
/*  */

.TrackParticulars .track_list {
  font-size: 0.6rem;

  background-color: #fff;
  position: relative;
}

.track_item_body {
  position: relative;
  display: block;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.user-and-vote {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.feed-item-top {
  width: 70%;
  overflow: hidden;
  padding: 0.4rem 0;
}
.feed-item-top .avatar {
  margin-right: 0.4rem;
}
.feed-item-top h1 {
  padding: 2px 0;
  font-size: 0.7rem;
}
.feed-item-top h1 .name {
  color: #ff9a80;
  font-size: 0.8rem;
}
.feed-item-top h2 {
  font-size: 0.6rem;
}
.feed-item-top h2 .time {
  color: #999;
  background: url(../../assets/img/feed-item-time.png) 0 no-repeat;
  background-size: auto 80%;
  padding-left: 20px;
}
.go-vote-css {
  width: 30%;
  height: auto;
  background-color: #ff9a80;
  font-size: 29px;
  text-align: center;
  margin-top: 5px;
  float: right;
  line-height: 10px;
}
.go-vote {
  margin-top: 21px;
}
.track_item_body .track-item-tag {
  padding-top: 5px;
  line-height: 20px;
  overflow: hidden;
  position: relative;
}
.track_item_body .track-item-tag:nth-of-type(2) a {
  font-weight: 700;
  background: url(../../assets/img/track_name.png) left 0.2rem no-repeat;
  background-size: 1.2rem;
}
.track_item_body .track-item-tag a {
  font-size: 0.8rem;
  color: #666;
  padding: 0.3rem 0 0 1.6rem;
  margin-right: 10px;
  float: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.track_item_body .track-item-tag {
  padding-top: 5px;
  line-height: 20px;
  overflow: hidden;
  position: relative;
}
.track_item_body .track-item-tag:nth-of-type(3) a.active {
  display: block;
}
.track_item_body .track-item-tag:nth-of-type(3) a {
  background: url(../../assets/img/track_describe.png) 0 0 no-repeat;
  background-size: 1.2rem;
  box-sizing: border-box;
  padding: 0 0 0 1.6rem;
  width: 100%;
  line-height: 1.1rem;
  overflow: hidden;
  margin: 0.3rem 0;
}
.img-box {
  width: 100%;
  margin-left: -3px;
  overflow: hidden;
}
.padding_t_4 {
  padding-top: 0.4rem;
}
.img-box .item,
.img-box .video {
  position: relative;
  float: left;
  width: 50%;
  padding: 0;
  margin: 0;
  border-color: #fff;
  border-style: solid;
  border-width: 0 0 3px 3px;
  box-sizing: border-box;
}
.img-box.row1 .item {
  width: 80%;
}
.img-box .item img {
  width: 100%;
  height: 100%;
}
.padding_4 {
  padding: 0 0.4rem;
}
.note-track-card {
  background: #f2f2f2;
  border-radius: 3px;
}
.note-track-card .map-pic {
  width: 100%;
  height: 6rem;
  background-position: 50%;
  background-size: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.map-pic > img {
  width: 100%;
  margin-top: 3rem;
}
.navigation {
  position: absolute;
  right: 0;
  bottom: 0;
  display: inline-block;
  height: 100%;
}
.foot_heigth {
  color: #000;
  left: 0;
  z-index: 0;
  font-size: 0.7rem;
}
.foot_heigth span {
  background-color: rgba(0, 0, 0, 0.4);
  display: inline-block;
  padding: 0 0.4rem;
  color: #fff;
}
.foot_heigth,
.navigation {
  position: absolute;
  right: 0;
  bottom: 0;
}
.track_item_body .feed-item-tag {
  width: 100%;
}
.feed-item-tag {
  padding-top: 5px;
  overflow: hidden;
}
.feed-item-tag a {
  font-size: 0.6rem;
  color: #666;
  background: url(../../assets/img/feed-item-tag.png) left 6px no-repeat;
  background-size: 0.8rem;
  padding: 5px 0 5px 1rem;
  margin-right: 10px;
  display: block;
}
.track_item_body .feed-item-tag.last a span,
.track_item_body .track-item-tag:nth-of-type(3) a span {
  margin-left: 10px;
}
.blue {
  color: #ff9a80;
}
.track_list .viewer-list {
  height: 2rem;
}
.viewer-list {
  overflow: hidden;
  height: 4rem;
}
.viewer-list .box {
  padding: 0 10px 7px;
  height: 3rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.viewer-list .avatar {
  margin: 0 5px 5px 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  border: 1px solid #ff9a80;
  box-sizing: border-box;
  border-radius: 50%;
}
.avatar .img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f2f2f2;
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAIAAAD/gAIDAAAAGXRFW…4y+cbvZWkBLCLVX8DZC41awvEbNCjyZvq8ajuvVa7/CTAAzE2bDvr05TEAAAAASUVORK5CYII=); */
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.TrackParticulars .feed-item-tool {
  margin-top: 5px;
  border-top: 0.1rem solid #f2f2f2;
  border-bottom: 0.3rem solid #f2f2f2;
  overflow: hidden;
}
.feed-item-tool a {
  float: left;
  color: #999;
  font-size: 0.8rem;
  padding: 3px 0;
  margin: 5px 0;
  width: 33.33333%;
  text-align: center;
  overflow: hidden;
}
.TrackParticulars .feed-item-tool .ping {
  border: 0.1rem solid #f2f2f2;
  border-width: 0 1px;
}
.feed-item-tool .zan span {
  background-image: url(../../assets/img/feed-tool-zan.png);
}
.feed-item-tool .ping span {
  background-image: url(../../assets/img/feed-tool-ping.png);
}
.feed-item-tool .zhuan span {
  background-image: url(../../assets/img/feed-tool-zhuan.png);
}
.feed-item-tool span {
  background: 0 no-repeat;
  background-size: contain;
  padding-left: 1.4rem;
}
.travelcomment {
  overflow: hidden;
  clear: both;
  margin-bottom: 10rem;
}
.TrackParticulars h4 {
  font-size: 0.8rem;
  line-height: 2rem;
  padding: 0 10px;
  color: #ff9a80;
  border: none;
}
.foot_noinfo {
  width: 100%;
  text-align: center;
  padding: 100px 0;
  border-top: 5px solid #f2f2f2;
}
.foot_noinfo img {
  display: inline-block;
  width: 10rem;
}
.foot_noinfo p {
  font-size: 0.8rem;
}
.discuss {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.2rem 0.5rem;
  background-color: #fff;
  z-index: 1;
}
.discuss,
.discuss input {
  border-top: 0.1rem solid #f2f2f2;
}
.discuss input {
  height: 1.4rem;
  padding: 0 0.1rem;
  border-radius: 12px;
  width: 77%;
  vertical-align: top;
  font-size: 0.8rem;
}
.discuss > a,
.discuss > div {
  display: inline-block;
  background: linear-gradient(45deg, #ffd39f, #ff9a80);
  height: 1.5rem;
  line-height: 1.5rem;
  width: 20%;
  text-align: center;
  color: #fff;
  border-radius: 2px;
  font-size: 0.8rem;
}

/*  */

.discuss {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 0.1rem solid #f2f2f2;
  padding: 0.2rem 0.5rem;
  background-color: #fff;
  z-index: 1;
}

.discuss input {
  border-top: 0.1rem solid #f2f2f2;
  height: 1.4rem;
  padding: 0 0.1rem;
  border-radius: 12px;
  width: 77%;
  vertical-align: top;
  font-size: 0.8rem;
}

.discuss > div,
.discuss > a {
  display: inline-block;
  background: linear-gradient(45deg, #ffd39f 0%, #ff9a80 100%);
  height: 1.5rem;
  line-height: 1.5rem;
  width: 20%;
  text-align: center;
  color: #fff;
  border-radius: 2px;
  font-size: 0.8rem;
}

.shade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
}

.text_discuss {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 4;
  background-color: #fff;
  padding: 10px;
}

.bd_text_discuss {
  border: 1px solid #f2f2f2;
  padding: 2px;
  border-radius: 2px;
}

.TrackPublish .imgs,
.TrackPublish .mint-cell:first-child .mint-cell-wrapper,
.TrackPublish .mint-field.is-textarea .mint-cell-value {
  border: none;
  padding: 0;
}

.TrackPublish .mint-cell-wrapper .mint-cell-value .mint-field-core {
  width: 100%;
  text-align: left;
}

.TrackParticulars .TrackPublish .imgs .item {
  width: 50px;
  height: 50px;
}

.TrackPublish .TrackPublish .imgs .up-img {
  margin: 4px;
}

.TrackPublish .click_button {
  padding: 10px 0 0 0;
}

.TrackPublish .click_button .fl .footprint-image,
.TrackPublish .click_button .fl .emoji {
  display: inline-block;
  position: relative;
}

.TrackPublish .click_button .emoji img,
.TrackPublish .click_button .footprint-image img {
  display: inline-block;
  width: 1.5rem;
}

.TrackPublish .click_button .footprint-image input {
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
}

.TrackPublish .click_button div.fr {
  background-color: #ff9a80;
  color: #fff;
  width: 30%;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 2px;
  font-size: 0.8rem;
}

.TrackParticulars h4 {
  font-size: 0.8rem;
  line-height: 2rem;
  padding: 0 10px;
  color: #ff9a80;
  border: none;
}

.comment_list li {
  padding: 10px;
  border-bottom: 1px solid #f2f2f2;
}

.comment_list li:last-of-type {
  border-bottom: none;
}

.normal .track_item_body .track-item-tag:nth-of-type(3) a img {
  display: none;
}
.feed-item-top {
  width: 70%;
  /*ackground-color: red;*/
}
.TrackParticulars .feed-item-top .fr {
  margin: 5px;
  font-size: 0.6rem;
  line-height: 14px;
}

.TrackParticulars .travelcomment .feed-item-top .fr img {
  width: 16px;
  padding: 0 4px 6px;
  display: inline-block;
  vertical-align: middle;
}

.TrackParticulars .feed-item-top .content {
  overflow: hidden;
  font-size: 0.7rem;
}

.TrackParticulars .feed-item-top .content a {
  color: #333;
}

.TrackParticulars .feed-item-top .content p {
  color: #666;
  font-size: 0.6rem;
  height: 24px;
  line-height: 24px;
}

.TrackParticulars .travelcomment .feed-item-top h2 .time {
  background: none;
  padding-left: 0;
}

.comment_content {
  background-color: #f2f2f2;
  margin: 10px 0;
  border-radius: 4px;
  padding: 10px;
}

.TrackParticulars .comment_content li {
  position: relative;
}

.TrackParticulars .comment_content .fr {
  position: absolute;
  top: 10px;
  right: 10px;
}

.TrackParticulars .comment_content .fr img {
  width: 12px;
}

.TrackParticulars .comment_content .content span {
  color: #ff9a80;
}

.TrackParticulars .comment_content .fr {
  color: #999;
  font-size: 12px;
}

.TrackParticulars .red_packet_info {
  margin-top: 10px;
  padding: 0 10px;
  position: relative;
}

.TrackParticulars .red_packet_info .avatar .img {
  border-radius: 0;
}

.TrackParticulars .red_packet_info h2 {
  color: #333;
  line-height: 1rem;
}

.TrackParticulars .red_packet_info .gifts {
  display: inline-block;
  width: 40px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.TrackParticulars .red_packet_info h2 img {
  display: inline-block;
  width: 0.8rem;
}

.TrackParticulars .red_packet_info h2 span:nth-of-type(2) {
  font-size: 0.8rem;
}

.TrackParticulars .red_packet_info .tit {
  line-height: 1.6rem;
  font-size: 0.8rem;
  color: #333;
}

.TrackParticulars .red_packet_info .tit span {
  line-height: 1.6rem;
  margin: 0;
}

.top_img_box {
  height: 10rem;
  background-size: 100%;
  position: relative;
  color: #fff;
}

.top_img_box .top_laber {
  padding: 0.5rem;
}

.top_img_box .top_laber span {
  border: 1px solid #fff;
  padding: 0.1rem 0.2rem;
  font-size: 0.6rem;
  margin-right: 0.2rem;
}

.top_img_box .img_num {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.2rem 0.6rem;
}

.top_img_box .img_num h2 {
  font-size: 1rem;
  font-weight: 600;
}

.top_img_box .navigation {
  height: auto;
  right: 0.5rem;
}

.top_img_box .navigation img {
  width: 3rem;
  height: 3rem;
}

.track_list .title_info {
  border-bottom: 1px solid #ccc;
  padding: 0.4rem 0.4rem;
  font-size: 0.7rem;
}

.track_list .title_info p {
  line-height: 1.6rem;
}

.track_list .title_info p .fl {
  max-width: 80%;
}

.track_list .title_info p .fr {
  color: #666;
}

.track_list .title_info span > span {
  font-size: 1rem;
  line-height: 0;
  color: #ff3400;
}

.track_list .title_info a.fr {
  border: 1px solid #ff9a80;
  border-radius: 1rem;
  position: relative;
  padding: 0 0.4rem 0 1.4rem;
  height: 1.2rem;
  line-height: 1.2rem;
  color: #ff3400;
  margin-top: 0.1rem;
}

.track_list .title_info a i {
  border-radius: 1rem;
  border: 1px solid #ff9a80;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1.2rem;
  height: 1.2rem;
  width: 1.2rem;
  text-align: center;
  box-sizing: border-box;
}

.track_list .title_info .spots_label span {
  margin-right: 0.5rem;
  padding: 0 0.2rem;
  border: 1px solid #ff9a80;
  color: #666;
}

.track_list .center_info .center_txt {
  font-size: 0.7rem;
  padding: 0.2rem;
  line-height: 1.2rem;
}

.track_list .center_info .center_txt h1 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 2rem;
}

.track_list .center_info .center_txt h2 {
  font-size: 0.8rem;
  line-height: 1.6rem;
}

.track_list .center_info .center_txt img {
  width: 100%;
  height: auto;
}

.track_list .viewer-list {
  height: 2rem;
}

.TrackParticulars .feed-item-tool {
  margin-top: 5px;
  border-top: 0.1rem solid #f2f2f2;
  border-bottom: 0.3rem solid #f2f2f2;
  overflow: hidden;
  display: flex;
}

.TrackParticulars .feed-item-tool .ping {
  border: 0.1rem solid #f2f2f2;
  border-width: 0 1px;
}

.margin_bottom_ {
  margin-bottom: 3rem;
}
.select-numbers {
  position: fixed;
  left: 0;
  bottom: 3rem;
  right: 0;
  z-index: 4;
  background-color: #fff;
  padding: 10px;
}
.select-numbers .head {
  position: relative;
  float: left;
  border-bottom: 1px solid #dedede;
}
.select-numbers .head .img {
  position: relative;
  float: left;
  width: 40%;
}
.select-numbers .head .img img {
  width: 100%;
  height: 100px;
}
.select-numbers .head .title {
  position: relative;
  float: left;
  width: 40%;
}
.select-numbers .head .title span {
  display: block;
  padding: 4px;
  text-align: left;
  margin-left: 1%;
}
.select-numbers .head .close {
  position: relative;
  float: left;
  width: 18%;
}
.select-numbers .head .close img {
  width: 45px;
  height: 45px;
  float: right;
}

.select-numbers .body {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100px;
}
.select-numbers .body .numbers {
  position: relative;
  width: 60%;
  float: left;
}
.select-numbers .body .numbers input {
  height: 100px;
  width: 60%;
}
.select-numbers .body .add_reduce {
  position: relative;
  width: 40%;
  float: left;
  margin-top: 30px;
}
.select-numbers .body .add_reduce .add {
  position: relative;
  float: right;
  width: 20%;
}
.select-numbers .body .add_reduce .add .iconfont {
  font-size: 30px;
  color: #000;
}
.select-numbers .body .add_reduce .reduce {
  position: relative;
  float: left;
  width: 20%;
}
.select-numbers .body .add_reduce .reduce .iconfont {
  font-size: 30px;
  color: #000;
}
.select-numbers .stand-label {
  position: relative;
  width: 100%;
  margin-top: 2%;
  border-top: 1px solid #dedede;
}
.select-numbers .stand-label .stand-title {
  display: block;
  position: relative;
  width: 100%;
}
.select-numbers .stand-label .stand-title span {
  display: block;
  padding: 10px;
  text-align: left;
}
.select-numbers .stand-label .item {
  position: relative;
  width: 50%;
  float: left;
}
.select-numbers .stand-label .item span {
  display: block;
  padding: 10px;
  border-radius: 4px;
}

.pay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 0.1rem solid #f2f2f2;
  padding: 0.2rem 0.5rem;

  z-index: 1;
  width: 100%;
}
.pay .price {
  width: 33%;
  background-color: white;
  position: relative;
  float: left;
}
.pay .select_num {
  width: 33%;
  background-color: #00bfff;
  position: relative;
  float: left;
}
.pay .make_order {
  width: 33%;
  background-color: red;
  position: relative;
  float: left;
}
.pay span {
  display: block;
  padding: 15px;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
</style>
