<template>
  <div class="container" v-title :data-title="title" v-show="loaded">
    <header class="header">
      <i class="back iconfont iconmenu2" @click="goBack"></i>
      <p class="title">活动报名</p>
    </header>

    <!-- 第一步：选择身份 -->
    <div class="step identify" v-if="step === 1">
      <div class="item" @click="handleIdentify('captain')">
        <img class="img" src="../../assets/img/activity_captain.png" />
        <span class="text">新建队伍</span>
      </div>
      <div class="item" @click="handleIdentify('member')">
        <img class="img" src="../../assets/img/activity_member.png" />
        <span class="text">加入队伍</span>
      </div>
    </div>
    <!-- 第二步 输入队名 -->
    <div class="step group-name" v-if="step === 2">
      <div class="title">输入新的队名</div>
      <input class="inp" type="text" v-model="groupName" placeholder="请输入队名" />
      <button class="btn" @click="handleSubmitGroupName">确定</button>
    </div>
    <!-- 第三步 -->
    <div class="step-3 captain" v-if="step === 3">
      <div class="activity-info">
        <div class="title">
          <span class="text">{{title}}</span>
        </div>
        <div class="subtitle">
          <div class="tag">
            <div class="item price">
              <span class="text name">{{selectTitle}}￥</span>
              <!-- 
              -->
              <span class="text value">{{selectPrice}}</span>
            </div>
          </div>
          <div class="time item">
            <span class="text name">活动时间：</span>
            <span class="text value">{{btime}} - {{etime}}</span>
          </div>
        </div>
      </div>

      <!-- 报名信息 -->
      <div class="content">
        <!-- 选择队伍 -->
        <div class="item g-name" v-if="isLeader == 0">
          <span class="text label">队伍</span>
          <span
            :class="['value', groupName ? 'active-value' : '']"
            @click="showGroupPicker = true"
          >{{groupName || '请选择队伍'}}</span>
        </div>

        <!-- picker -->
        <!-- picker遮罩 -->
        <div class="pick-mask" v-show="showGroupPicker" @click.self="showGroupPicker = false"></div>
        <!-- 加入队伍 -->
        <transition name="slide">
          <div class="picker-box" v-show="showGroupPicker">
            <div class="picker-header">
              <button class="cancel" @click="showGroupPicker = false">取消</button>
              <button class="certain" @click="handleGroupChange">确定</button>
            </div>
            <mt-picker :slots="groupNameList" ref="groupPicker"></mt-picker>
          </div>
        </transition>
        <!-- 队长时显示队伍 -->
        <div class="item" v-if="isLeader == 1">
          <span class="text label">队伍名称</span>
          <span class="text value active-value">{{groupName}}</span>
        </div>
        <div class="item" v-if="isLeader == 1">
          <span class="text label">队伍编号</span>
          <span class="text value active-value">{{groupId}}</span>
        </div>
        <!-- 姓名 -->
        <div class="item name">
          <span class="text label">姓名</span>
          <input
            :class="['inp', 'value', name ? 'active-value' : '']"
            type="text"
            v-model="name"
            placeholder="请输入您的真实姓名"
          />
        </div>
        <!-- 性别 -->
        <div class="item gender">
          <span class="text label">性别</span>
          <span
            :class="['value', gender ? 'active-value' : '']"
            @click="showGenderPicker=true"
          >{{gender || '请选择您的性别'}}</span>
        </div>

        <!-- picker -->
        <!-- picker遮罩 -->
        <div class="pick-mask" v-show="showGenderPicker" @click.self="showGenderPicker = false"></div>
        <!-- 性别 -->
        <transition name="slide">
          <div class="picker-box" v-show="showGenderPicker">
            <div class="picker-header">
              <button class="cancel" @click="showGenderPicker = false">取消</button>
              <button class="certain" @click="handleGenderChange">确定</button>
            </div>
            <mt-picker :slots="genderList" ref="genderPicker"></mt-picker>
          </div>
        </transition>
        <!-- 身份证 -->
        <div class="item idcard">
          <span class="text label">身份证号</span>
          <input
            :class="['inp', 'value', idcard ? 'active-value' : '']"
            type="number"
            v-model="idcard"
            placeholder="请输入身份证号"
          />
        </div>
        <!-- 服装尺寸 -->
        <div class="item size">
          <span class="text label">服装尺寸</span>
          <span
            :class="['value', size ? 'active-value' : '']"
            @click="showSizePicker=true"
          >{{size || '请选择您的服装尺寸'}}</span>
        </div>

        <!-- picker -->
        <!-- picker遮罩 -->
        <div class="pick-mask" v-show="showSizePicker" @click.self="showSizePicker = false"></div>
        <!-- 尺寸 -->
        <transition name="slide">
          <div class="picker-box" v-show="showSizePicker">
            <div class="picker-header">
              <button class="cancel" @click="showSizePicker = false">取消</button>
              <button class="certain" @click="handleSizeChange">确定</button>
            </div>
            <mt-picker :slots="sizeList" ref="sizePicker"></mt-picker>
          </div>
        </transition>

        <!-- 支付 -->
        <div class="pay-info">
          <div class="info">
            <span class="text name">实付金额：</span>
            <span class="text union">￥</span>
            <span class="text value">{{selectPrice}}</span>
          </div>
          <div class="submit">
            <button
              :class="['btn', isPay == '1' ? 'btn-disabled' : '']"
              @click="handleSubmit"
            >{{isPay == '1' ? '已支付' : '去支付'}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 队伍信息 -->
    <div class="group-info" v-if="step === 3">
      <div class="title">
        <span class="text">队伍信息</span>
      </div>
      <div class="list">
        <div class="item" v-for="item in userList" :key="item.id">
          <img class="img" :src="item.user_icon" />
          <div class="content">
            <div class="user">
              <div class="nick">
                <span class="text">{{item.user_nick}}</span>
              </div>
              <div class="identify">
                <span
                  :class="['text', item.is_leader == 1 ? 'captain' : 'member']"
                  @click="handleMember(item)"
                >{{item.is_leader == 1 ? '队长' : '队员'}}</span>
              </div>
            </div>
            <div class="time">
              <span class="text">{{item.add_time}}</span>
            </div>
          </div>
          <!--show_btn = 0 不显示 1 踢出 2 退出-->
          <div class="handle kick" v-if="item.show_btn == 1">
            <span class="text">踢出</span>
          </div>
          <div class="handle quit" v-if="item.show_btn == 2">
            <span class="text">退出</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Picker, DatetimePicker, MessageBox } from "mint-ui";
export default {
  data() {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanActivity",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      aid: this.$route.query.aid || 0,
      tel: this.$route.query.tel || "",
      btime: this.$route.query.btime || "",
      etime: this.$route.query.etime || "",
      selectPrice: this.$route.query.selectPrice || 0,
      number: this.$route.query.number || 0,
      title: this.$route.query.title || "",
      selectTitle: this.$route.query.selectTitle || "",
      is_group: this.$route.query.is_group || "",
      have_group_name: this.$route.query.have_group_name || "",
      is_price: this.$route.query.is_price || "",

      // 自定义
      user_id: "",
      loaded: false,
      isSubmit: false, // 正在提交
      // 第一步
      step: 0, // 步骤
      identify: "", // 身份
      // 第二步

      name: "", // 姓名
      idcard: "", // 身份证号

      showGroupPicker: false,
      groupNameList: [
        {
          values: [],
          className: "item-gender",
          textAlign: "center"
        }
      ],
      groupName: "",
      groupId: "", // 队伍编号
      person: "", // 队伍人数
      showGenderPicker: false,
      genderList: [
        {
          values: ["男", "女", "其他"],
          className: "item-gender",
          textAlign: "center"
        }
      ],
      gender: "",
      showSizePicker: false,
      size: "", // 服装尺寸
      sizeList: [
        {
          values: ["M", "L", "XL", "XLL"],
          className: "item-gender",
          textAlign: "center"
        }
      ],

      startDate: new Date("1990-1-1"),

      // 获取数据
      userJoinInfo: {},
      userList: [],
      isLeader: "",
      isPay: "",
      group_id: "",
      gId: "",
      gName: "",
      groupList: []
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }

    this.checkLogin();
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
    }
    if (this.have_group_name == 1) {
      // 已经有队伍，请求队伍数据
      this.getGroupList();
    } else {
      // 否则，选择身份
      this.step = 1;
    }
  },
  methods: {
    // 上一级
    goBack() {
      this.$router.push({
        path: "/nihewan/home",
        query: {
          id: this.id
        }
      });
    },
    /*踢人或者退出*/
    handleMember(item) {
      const uid = item.user_id,
        id = item.id;
      if (this.aid && this.user_id && uid && id && this.token) {
        this.$ajax(
          "post",
          "activity/modify_show_btn",
          {
            aid: this.aid,
            my_id: this.user_id,
            other_id: uid,
            id: id,
            token: this.token
          },
          res => {
            if (res.data.id == 0) {
              Toast("执行成功");
              this.$router.push({
                path: "/nihewan/activity",
                query: {
                  id: this.id,
                  aid: this.aid
                }
              });
            } else {
              Toast("执行失败");
            }
          }
        );
      } else {
        console.log("参数错误，不能退出");
      }
    },
    /**
     * 提交报名信息
     */
    handleSubmit() {
      if (this.isPay == 1) {
        Toast("已支付");
        return false;
      }
      if (this.isSubmit) {
        return false;
      }
      // 验证身份证号
      const idcardReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (!idcardReg.test(this.idcard)) {
        Toast("身份证号格式错误");
        return false;
      }

      const aid = this.data.aid,
        name = this.data.name,
        gender = this.data.genderList[this.data.genderIndex] || "", //性别
        idcard = this.data.idcard,
        size = this.data.size,
        person = this.data.person, // 固定一人
        isPay = this.data.isPay,
        price = this.data.selectPrice,
        gId = this.data.gId,
        gName = this.data.gName,
        is_price = this.data.is_price,
        park_id = app.globalData.park_id,
        open_id = wx.getStorageSync("open_id"),
        user_id = this.data.user_info["user_id"];

      // 判断信息是否输入完整
      if (!this.name) {
        // 姓名
        Toast("请输入姓名");
        return false;
      }
      if (!this.gender) {
        // 性别
        Toast("请选择性别");
        return false;
      }

      if (this.open_id && this.user_id && this.aid && this.token) {
        this.isSubmit = true;
        this.$ajax(
          "post",
          "activity/weixin_add_activity_order",
          {
            user_id: this.user_id,
            g_id: this.gId,
            group_name: this.gName,
            activity_id: this.aid,
            name: this.name,
            card_id: this.idcard,
            price: this.price,
            number: this.person,
            size: this.size,
            sex: this.gender,
            is_price: this.is_price,
            open_id: this.open_id,
            park_id: this.park_id
          },
          res => {
            if (res.data.id == 0) {
              if (res.data.data.price == 0) {
                Toast("报名成功");
                //免费活动，报名成功,直接跳转到活动详情
                this.$router.push({
                  path: "/nihewan/activity",
                  query: {
                    id: this.id,
                    aid: this.aid
                  }
                });
              } else if (res.data.data.price > 0) {
                let open_url =
                  "https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=" +
                  this.aid +
                  "&spot_id=0&backUrl=label&open_id=" +
                  this.open_id +
                  "&token=" +
                  this.token +
                  "&park_id=" +
                  this.park_id +
                  "&version=" +
                  this.version +
                  "&user_id=" +
                  this.user_id +
                  "&money=" +
                  ret.data.data.price;
                window.location.href = open_url;
              }
            } else {
              Toast("报名失败");
            }
            this.isSubmit = false;
          }
        );
      }
    },
    // 服装尺寸
    handleSizeChange() {
      this.size = this.$refs.sizePicker.values[0];
      this.showSizePicker = false;
    },

    // 性别
    handleGenderChange() {
      this.gender = this.$refs.genderPicker.values[0];
      this.showGenderPicker = false;
    },
    // 加入队伍
    handleGroupChange() {
      this.groupName = this.$refs.groupPicker.values[0];
      this.showGroupPicker = false;
      let index = 0,
        ls = this.groupNameList[0].values;
      for (let i = 0, len = ls.length; i < len; i++) {
        if (ls[i] === this.groupName) {
          index = i;
          break;
        }
      }
      this.gId = this.groupList[index].gId;
      this.gName = this.groupList[index].gName;
      this.groupId = this.groupList[index].groupId;
    },
    /**
     * 提交队名
     */
    handleSubmitGroupName() {
      if (this.isSubmit) {
        return false;
      }

      this.isSubmit = true;

      if (this.aid && this.user_id && this.groupName) {
        this.$ajax(
          "post",
          "activity/user_add_group_name",
          {
            user_id: this.user_id,
            aid: this.aid,
            group_name: this.groupName,
            group_id: ""
          },
          res => {
            if (res.data.id == 0) {
              Toast("添加成功");
              setTimeout(() => {
                this.getGroupList();
              }, 1500);
            } else {
              Toast("添加失败");
            }

            this.isSubmit = false;
          }
        );
      }
    },
    /**
     * 选择身份
     */
    handleIdentify(identify) {
      if (identify === "captain") {
        this.step = 2;
      } else if (identify === "member") {
        this.getGroupList();
      } else {
        return false;
      }

      this.identify = identify;
    },

    // 获取用户信息
    getUserInfo() {
      if (this.aid && this.user_id) {
        this.$ajax(
          "post",
          "activity/get_activity_user_info",
          {
            activity_id: this.aid,
            user_id: this.user_id
          },
          res => {
            if (res.data.id == 0) {
              this.userJoinInfo = res.data.data;
              this.name = res.data.data.name || "";
              this.person = res.data.data.number || "";
              this.idcard = res.data.data.card_id || "";
              this.gId = res.data.data.g_id || "";
              this.groupId = res.data.data.group_id || "";
              this.groupName = res.data.data.group_name || "";
              this.userList = res.data.data.user_list;
              this.isLeader = res.data.data.is_leader || "";
              this.isPay = res.data.data.is_pay || "";
              this.group_id = res.data.data.id || "";
              const genderIndex = res.data.data.sex;
              this.gender = genderIndex
                ? this.genderList[0].values[genderIndex]
                : "";
              this.size = res.data.data.size || "";

              const sUrl =
                  "https://m.zmlxj.com/#/nihewan/activity?aid=" + this.aid,
                wx_title = this.title,
                wx_content = "",
                wx_img = "";
              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                });
              }
            }
          }
        );
      } else {
        console.log("参数错误");
      }
    },
    getGroupList() {
      //请求报名的数据

      if (this.aid) {
        this.$ajax(
          "post",
          "activity/get_group_name_list",
          {
            aid: this.aid
          },
          res => {
            if (res.data.id == 0) {
              if (!res.data.data || !res.data.data.length) {
                Toast("还没有队伍");
                return false;
              }
              for (let i = 0, len = res.data.data.length; i < len; i++) {
                let obj = {};
                Object.assign(obj, {
                  gId: res.data.data[i].g_id,
                  groupId: res.data.data[i].group_id,
                  gName: res.data.data[i].g_name,
                  groupName: res.data.data[i].group_name
                });

                this.groupNameList[0].values.push(res.data.data[i].group_name);
                this.groupList.push(obj);
              }

              this.gId = res.data.data[0].g_id;
              this.gName = res.data.data[0].g_name;
              this.step = 3;
              this.getUserInfo();
            }
            this.loaded = true;
            if (!res.data.data || !res.data.data.length) {
              Toast("还没有队伍");
            }
          }
        );
      }
    },
    // 登录
    checkLogin() {
      if (!this.check_login()) {
        MessageBox.alert("请先登录").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
          }
        });
      } else {
        this.user_id = this.check_login().user_id;
        this.verify = this.check_login().user_id + new Date().getTime();
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&id_=" +
        this.aid +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    }
  }
};
</script>

<style scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  font-size: 0.8rem;
  background-color: #fff;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 2.8rem;
  width: 100%;
  padding: 0.4rem 0.8rem 0.4rem 0.4rem;
  display: flex;
  box-sizing: border-box;
  box-shadow: 0 2px 2px -1px rgba(211, 211, 211, 0.4);
  background-color: #fff;
  z-index: 101;
}

.header > .back {
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}

.header > .title {
  flex: 1;
  padding-right: 2rem;
  box-sizing: border-box;
  text-align: center;
  font-size: 1rem;
  line-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.container > .title {
  font-size: 1rem;
  margin-top: 2.8rem;
  font-weight: bold;
}

/* 第一步：选择身份 */
.step {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 100;
}

.identify > .item {
  width: 40%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  font-size: 0.8rem;
}

.identify > .item > .img {
  display: block;
  width: 80%;
  margin-bottom: 4px;
}

/* 第二步 队名 */
.group-name {
  flex-direction: column;
  padding: 0 20%;
  box-sizing: border-box;
}

.group-name > .title {
  font-size: 1rem;
  font-weight: bold;
}

.group-name > .inp {
  margin-top: 1rem;
  height: auto;
  min-height: 0;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #eee;
  box-sizing: border-box;
  font-size: 0.8rem;
  border-radius: 4px;
  color: #333;
}

.group-name > .btn {
  margin-top: 1rem;
  width: 100%;
  min-height: 0;
  padding: 0.5rem 0;
  border-radius: 4px;
  background-color: #e6a23c;
  color: #fff;
  font-size: 0.88rem;
  font-weight: bold;
}

/* 活动信息 */
.captain > .activity-info {
  margin-top: 2.8rem;
  padding: 1rem;
  background-color: #fff;
}

.captain > .activity-info > .title {
  font-size: 1rem;
  font-weight: bold;
}

.captain > .activity-info > .subtitle {
  margin-top: 0.5rem;
}

.captain > .activity-info > .subtitle > .tag > .item {
  display: inline-block;
  margin-right: 0.5ren;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.68rem;
  border: 1px solid rgba(255, 203, 126, 0.5);
  color: #e6a23c;
}

.captain > .activity-info > .subtitle > .time {
  margin-top: 0.5rem;
  display: inline-block;
  margin-right: 0.5rem;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.68rem;
  border: 1px solid rgba(64, 160, 255, 0.5);
  color: #409eff;
}

.captain > .activity-info > .subtitle > .time > .value {
  font-family: "Times New Roman", Times, serif;
}

/* 报名信息 */

.captain > .content {
  padding: 0 1rem;
  box-sizing: border-box;
  background-color: #fff;
}

.captain > .content > .item:not(:first-child) {
  margin-top: 1rem;
}

.label {
  position: relative;
  padding-left: 8px;
  font-size: 0.88rem;
}
.label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 3px;
  height: 0.88rem;
  background-color: #e6a23c;
}

.captain > .content > .item .value {
  display: block;
  margin-top: 0.5rem;
  height: auto;
  min-height: 0;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 0.8rem;
  background-color: rgb(243, 245, 246);
  color: #999;
}

.captain > .content > .item .active-value {
  color: #333;
}

/* 支付信息 */
.captain .pay-info {
  padding: 1rem 0;
  box-sizing: border-box;
  text-align: right;
  font-size: 0.74rem;
  color: #666;
  background-color: #fff;
}

.captain .pay-info > .info > .union {
  color: #fd4545;
}

.captain .pay-info > .info > .value {
  color: #fd4545;
  font-size: 1.2rem;
  font-weight: bold;
}

/* picker */
.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}
.slide-enter-to,
.slide-leave {
  transform: translateY(0);
}
.slide-enter-active,
.slide-leave-active {
  transition: 0.3s;
}

.pick-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.picker-box {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 11;
}

.picker-header {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(246, 248, 250);
}

.picker-header > .cancel {
  width: 100%;
  font-size: 16px;
  color: #26a2ff;
}

.picker-header > .certain {
  width: 100%;
  font-size: 16px;
  color: #26a2ff;
}

.picker-box >>> .picker {
  width: 100%;
}

/* 提交 */

.captain .submit {
  margin-top: 1rem;
}

.captain .submit .btn {
  width: 100%;
  min-height: 0;
  padding: 0.5rem 0;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1rem;
  color: #fff;
  background-color: rgb(204, 158, 99);
}

.captain .submit .btn-disabled {
  background-color: #888;
}

/* 队伍信息 */
.group-info {
  width: 100%;
  padding: 1rem 1rem 2rem;
  margin-top: 0.5rem;
  box-sizing: border-box;
  background-color: #fff;
}

.group-info > .title {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
  font-size: 1rem;
}

.group-info > .list {
  margin-top: 1rem;
}

.group-info > .list > .item {
  display: flex;
  margin-bottom: 1rem;
}

.group-info > .list > .item > .img {
  margin-right: 8px;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.group-info > .list > .item > .content {
  flex: 1;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.group-info .content > .user {
  display: flex;
  align-items: center;
}

.group-info .content > .user > .nick {
  margin-right: 8px;
  font-size: 0.8rem;
  color: #333;
}

.group-info .content > .user > .identify {
  display: flex;
}

.group-info .content > .user > .identify > .text {
  padding: 0 4px;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: 0.68rem;
}

.group-info .content > .user > .identify > .captain {
  border-color: rgba(230, 67, 64, 0.5);
  color: #e64340;
}

.group-info .content > .user > .identify > .member {
  border-color: rgba(64, 160, 255, 0.5);
  color: #409eff;
}

.group-info .content > .time {
  font-size: 0.68rem;
  color: #999;
}

.group-info .handle {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 0.68rem;
  color: #fff;
}

.group-info .handle > .text {
  padding: 4px 12px;
  border-radius: 4px;
}

.group-info .quit > .text {
  background-color: #409eff;
}

.group-info .kick > .text {
  background-color: #e64340;
}
</style>