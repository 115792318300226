<template>
  <!--路书列表 组件-->
  <div
    class="LineInfo ParkMapLineList-content"
    :class="{'border':is_border}"
    v-if="info.length != 0"
  >
    <ul class="LineList_info_list">
      <h3 v-if="is_tit">
        <img src="../assets/img/line.png" alt />
        {{is_tit}}
      </h3>
      <mt-loadmore
        :bottom-method="loadBottom"
        :bottom-all-loaded="allLoaded"
        ref="loadmore"
        :auto-fill="false"
      >
        <router-link
          tag="li"
          :to="{path:link,query:{user_id:user_id,label_id:k.label_fk_id}}"
          v-for="(k) in filterBy(info)"
          :key="k.label_fk_id"
        >
          <div class="label_name">
            <span>{{ k.label_name}}</span>
          </div>
          <div class="img_box">
            <div class="img_info">
              <p>
                <span class="fl">距离{{k.length}}</span>
                游玩点 {{k.spots_numbers}}个
                <span class="fr">时间 {{k.time_length}}</span>
              </p>
            </div>
            <img v-lazy="k.img" alt />
            <!--添加完成定向賽任务个数的信息-->
            <div v-if="k.complete != 0" class="show-complete">
              <span>完成了{{k.complete}}个任务</span>
            </div>
          </div>
          <div class="user_info clearfix">
            <div>
              <img v-lazy="user_img.user_icon" alt v-for="(user_img, i) in k.user_list" :key="i" />
            </div>
          </div>
        </router-link>
      </mt-loadmore>
    </ul>
    <div v-if="info.length>2 && length">
      <div class="more" @click="num = info.length" v-if="num==2">
        更多导图
        <i class="iconfont icon-jinru"></i>
      </div>
      <div class="more" @click="num = 2" v-else>收起</div>
    </div>
  </div>
</template>
<script>
// 景 19     吃 18    住 21    购 20
import { Toast } from "mint-ui";
export default {
  props: {
    datainfo: Array,
    user_id: String,
    is_border: Boolean,
    is_tit: String,
    is_app: Boolean,
    length: Number
  },
  data() {
    return {
      page: 1, //页码
      label_type: 19, //类别
      info: [], //获取的数据列表
      allLoaded: false,
      num: 0,
      link: "/footprints/LineInfoList"
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    filterBy(ls) {
      return ls.filter((item, i) => {
        return i < this.num;
      });
    },
    unload() {
      if (this.is_app) {
        this.link = "/footprints/AppLineInfoList";
      } else {
        this.link = "/footprints/LineInfoList";
      }
      this.num = this.length ? this.length : this.datainfo.length;
      this.info = this.datainfo;
    },
    //            切换
    parentMsg: function(data) {
      console.log(data);
      if (data.info.length > 0) {
        this.info = data.info;
      } else {
        this.info = [];
      }
      if (!data.allLoaded) {
        this.allLoaded = data.allLoaded;
      }
    },
    getData(page, label_type) {
      this.$emit("ievent", page, label_type);
    },
    //            加载更多
    loadBottom() {
      var that = this;
      var itemLen = that.info.length;
      setTimeout(() => {
        if (itemLen % 10 == 0) {
          that.page++;
          that.getData(that.page);
        } else {
          that.allLoaded = true;
          Toast("没有更多了");
        }
        that.$refs.loadmore.onBottomLoaded();
      }, 1500);
    }
  }
};
</script>
<style scoped>
/*实现定向賽完成任务个数*/
.ParkMapLineList-content .show-complete {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
}
.ParkMapLineList-content .show-complete span {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
}
</style>