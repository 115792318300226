import Vue from "vue";

var wx = require('weixin-js-sdk')

Vue.prototype.wx_share = function (url, info) {
  // var url = 'http://m.zmlxj.com/#/activity/activityDetail?aid='+this.aid;//.split('#')[0];
  // var url = window.location.href.split('#')[0]+'#'+window.location.href.split('#')[1]; // 分享链接！这里是关键 因为微信会把我们分享的链接截取掉 我在这里手动拼接上
  var iRet
  if (url) {
    var that = this
    // 先使用？进行分截
    var url_ = url.split('#')[0]

    this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, (ret) => {
      if (ret.data.id == 0) {
        // 表示获得了数据-存储在本地
        var title = localStorage.getItem('wx_title')
        var content = localStorage.getItem('wx_content')
        var img = localStorage.getItem('wx_img')

        wx.config({
          debug: false,
          appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
          timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
          signature: ret.data.data, // 必填，签名，见附录1
          // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
          jsApiList: [
            'onMenuShareAppMessage', 'onMenuShareTimeline',
            'onMenuShareQQ', 'onMenuShareQZone', 'openLocation', 'getLocation'
          ]
        })

        // 处理验证失败的信息
        wx.error(function (res) {
          // iRet = that.save_share_data(info);
          iRet = ''
        });
        var open_ ;
        if(info.open_id)
        {
          open_ = info.open_id;
        }
        else
        {
          open_ = 'open_id';
        }
        var open_id = localStorage.getItem(open_) ? localStorage.getItem(open_) : ''
        if (info) {
          if (info.url != '') {
            url = info.url + '&share_open_id=' + open_id
          } else {
            url = url + '&share_open_id=' + open_id
          }
        } else {
          url = url + '&share_open_id=' + open_id
        }

        // 处理验证成功的信息
        wx.ready(function () {
          wx.onMenuShareAppMessage({
            title: title,
            desc: content,
            link: url,
            imgUrl: img,
            success: function (res) {
              iRet = that.save_share_data(info)
            }

          })
          wx.onMenuShareTimeline({
            title: title,
            desc: content,
            link: url,
            imgUrl: img,
            success: function (res) {
              iRet = that.save_share_data(info)
            }

          })

          // qq空间
          wx.onMenuShareQZone({
            title: title,
            desc: content,
            link: url,
            imgUrl: img,
            success: function (res) {
              iRet = that.save_share_data(info)
            }

          })
          // qq
          wx.onMenuShareQQ({
            title: title,
            desc: content,
            link: url,
            imgUrl: img,
            success: function (res) {
              iRet = that.save_share_data(info)
            }

          })
          wx.updateAppMessageShareData({
            title: title, // 分享标题
            desc: content, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: img, // 分享图标
            success: function () {
              // 设置成功
              iRet = that.save_share_data(info)
            }

          })
          wx.updateTimelineShareData({
            title: title, // 分享标题
            desc: content,
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: img, // 分享图标
            success: function () {
              // 设置成功
              iRet = that.save_share_data(info)
            }

          })
        })
      } else {

      }
    })
  } else {

  }

  return iRet
}

/**
 * 调用微信导航功能
 */
Vue.prototype.wx_nav = function (url, lng, lat, title, address) {
  if (url) {
    var url_ = url.split('#')[0]
    this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, (ret) => {
      if (ret.data.id == 0) {
        wx.config({
          debug: false,
          appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
          timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
          signature: ret.data.data, // 必填，签名，见附录1
          // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
          jsApiList: [
            'onMenuShareAppMessage', 'onMenuShareTimeline',
            'onMenuShareQQ', 'onMenuShareQZone', 'openLocation', 'getLocation'
          ]
        })

        // 处理验证失败的信息
        wx.error(function (res) {
          // logUtil.printLog('验证失败返回的信息:',res);
        })
        // 处理验证成功的信息
        var lng_ = parseFloat(lng)
        var lat_ = parseFloat(lat)
        var name = title
        console.log('lng=' + lng + 'lat=' + lat + 'title=' + title + 'address=' + address)
        wx.ready(function () {
          wx.openLocation({
            latitude: lat_, // 纬度，浮点数，范围为90 ~ -90
            longitude: lng_, // 经度，浮点数，范围为180 ~ -180。
            name: name, // 位置名
            address: address, // 地址详情说明
            scale: 15, // 地图缩放级别,整形值,范围从1~28。默认为最大
            infoUrl: 'http://apis.map.qq.com/uri/v1/marker?marker=coord:' + lng + ',' + lat + ';' // 在查看位置界面底部显示的超链接,可点击跳转
          })
        })
      } else {
        localStorage.setItem('wx_config', '')
      }
    })
  }
}

/**
 * 打开小程序
 * @param url ：小程序的页面
 */
Vue.prototype.wx_open_mini = function (url) {
 
  wx.miniProgram.navigateTo({
    url: url,
    success: function (res) {
      //logUtil.printLog('验证失败返回的信息:', res)
    },
    fail: function (err) {
      //logUtil.printLog('验证失败返回的信息:', err)
    }
  })

}

/**
 * 处理url
 */
Vue.prototype.dealWithUrl = function (url) {
  // var share_open_id = url.split("share_open_id");
  var urlFrom = url.split('from')
  // if (share_open_id.length > 1) {
  // var share_open_id_ = share_open_id[0].substr(0, share_open_id[0].length - 1);
  // urlFrom = share_open_id_.split("from");
  // } else {
  // urlFrom = url.split("from");
  // }

  if (urlFrom.length > 1) {
    // 表示从微信中分享的
    var url_1 = url.split('?')
    var url_2 = url_1[1].split('#')

    var new_url = url_1[0] + '#' + url_2[1] + '?' + url_1[2]

    if (url_2) {
      // 表示分享出来，才进行跳转
      window.location.href = new_url
    }
  } else {
    // 项目原始的地址,不用改变
    window.location.href = url
  }

  // console.log(urlFrom + "-----")
}

// 微信分享回调
Vue.prototype.wx_share_callback = function (url, info) {
  if (url) {
    // 先使用？进行分截
    let url_ = url.split('#')[0]

    this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
      if (ret.data.id === 0) {
        console.log(info)
        // 表示获得了数据-存储在本地
        const title = info.title
        const content = info.content
        const img = info.img
        wx.config({
          debug: false,
          appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
          timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
          signature: ret.data.data, // 必填，签名，见附录1
          // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
          jsApiList: [
            'onMenuShareAppMessage',
            'onMenuShareTimeline',
            'onMenuShareQQ',
            'onMenuShareQZone',
            'openLocation',
            'getLocation'
          ]
        })

        // 处理验证失败的信息
        wx.error(err => {
          throw err
          // this.save_share_data(info);
        })

        if (info != '') {
          if (info.url != '') {
            let url_arr = info.url.split('share_open_id')

            url = url_arr[0] + '&share_open_id=' + info.open_id
          } else {
            url = url + '&share_open_id=' + info.open_id
          }
        } else {
          url = url + '&share_open_id=' + info.open_id
        }

        // 处理验证成功的信息
        wx.ready(() => {
          wx.onMenuShareAppMessage({
            title: title,
            desc: content,
            link: url,
            imgUrl: img,
            success: res => {
              this.save_share_data(info)
            }
          })
          wx.onMenuShareTimeline({
            title: title,
            desc: content,
            link: url,
            imgUrl: img,
            success: res => {
              this.save_share_data(info)
            }
          })

          // qq空间
          wx.onMenuShareQZone({
            title: title,
            desc: content,
            link: url,
            imgUrl: img,
            success: res => {
              this.save_share_data(info)
            }
          })
          // qq
          wx.onMenuShareQQ({
            title: title,
            desc: content,
            link: url,
            imgUrl: img,
            success: res => {
              this.save_share_data(info)
            }
          })
          wx.updateAppMessageShareData({
            title: title, // 分享标题
            desc: content, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: img, // 分享图标
            success: () => {
              // 设置成功
              this.save_share_data(info)
            }
          })
          wx.updateTimelineShareData({
            title: title, // 分享标题
            desc: content,
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: img, // 分享图标
            success: () => {
              // 设置成功
              this.save_share_data(info)
            }
          })
        })
      } else {
      }
    })
  } else {
  }
}

// 分享成功发送请求
Vue.prototype.save_share_data = function (info) {
  var iRet_ = {}
  if (info) {
    if (info.id_ && info.id_type_ && info.open_id && info.token) {
      this.$ajax('post', 'Share/web_share_label_spots', {
        id_: info.id_,
        id_type_: info.id_type_,
        open_id: info.open_id,
        share_open_id: '',
        token: info.token
      }, (ret) => {
        if (ret.data.id == 0) {
          iRet_.have_chance = 1
          iRet_.have_chance_id = ret.data.data.have_chance_id
        } else {
          iRet_.have_chance = 0
          iRet_.have_chance_id = ''
        }
      })
    } else {
      iRet_.have_chance = 0
      iRet_.have_chance_id = ''
    }
  } else {
    iRet_.have_chance = 0
    iRet_.have_chance_id = ''
  }
  return iRet_
}
