<template>
  <div>
    <ServiceIndexTitle></ServiceIndexTitle>

    <div class="page-content">
      <mt-cell :title="'线路开启'" class="switch-title">
        <mt-switch v-model="value" @change="handleChange"></mt-switch>
      </mt-cell>
      <div class="foot-guide-wrap">
        <p>说明：</p>
        <p>1.每个导游可创建3个{{title}}线路</p>
        <p>2.未上架的线路不会展示给用户看</p>
        <p>3.线路每编辑一次都需要等待管理员的审核</p>
      </div>

      <div class="serve-list">
        <div
          class="withdraw-wrap foot-guide-wrap"
          v-for="(myService,index) in myServiceList"
          :key="index"
          :id="myService.id"
        >
          <div class="line-title">
            <i class="need"></i>
            线路{{index+1}}
            <span class="checking" v-if="myService.status == 0">未审核</span>
            <span class="checking" v-else-if="myService.status == 1">审核失败</span>
            <span class="checking" v-else-if="myService.status == 2">审核成功</span>
            <span class="checking" v-else-if="myService.status == 3">审核成功</span>
            <!--<label class="edit">编辑</label>-->
            <label
              class="putaway"
              v-if="myService.status == 2"
              @click="groundService(myService.id,grounding)"
            >{{grounding}}</label>
            <label
              class="putaway"
              v-if="myService.status == 3"
              @click="groundService(myService.id,undercarriage)"
            >{{undercarriage}}</label>
          </div>
          <div class="line-content clearfix">
            <div class="line-content-left">
              <div class="line-content-title">{{myService.title}}</div>
              <div class="line-content-time">{{myService.add_time}}</div>
            </div>
            <div class="line-content-right">
              <span>￥{{myService.service_money}}/</span>1天
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceIndexTitle from "../../components/ServiceIndexTitle";
import { Toast } from "mint-ui";

export default {
  components: {
    ServiceIndexTitle
  },
  data() {
    return {
      value: false,
      title: "",
      myServiceList: [],
      grounding: "",
      undercarriage: ""
    };
  },
  mounted() {
    this.getServiceType();
  },
  methods: {
    handleChange(event) {
      var open = "";
      if (event == true) {
        open = "1";
      } else {
        open = "2";
      }

      var data = {
        open: open,
        user_id: this.get_User_Id(),
        type: this.$route.query.type
      };

      this.$ajax("post", "Service/close_type_service", data, res => {
        if (res.data.id == 0) {
          Toast(res.msg);
          this.$router.go(0);
        }
      });
    },
    getServiceType() {
      var stype = this.$route.query.type;

      switch (stype) {
        case "1":
          this.title = "徒步";
          break;
        case "2":
          this.title = "接送机服务";
          break;
        case "3":
          this.title = "包车";
          break;
        default:
          this.title = "导游服务";
          break;
      }

      // 得到我目前提供的服务
      var that = this;

      // 获取用户ID
      var user_id = this.get_User_Id();

      var data = {
        type: stype,
        user_id: user_id
      };

      this.$ajax("post", "Service/get_my_provide_service", data, res => {
        that.grounding = "上架";
        that.undercarriage = "下架";
        //            console.log(res.data.data);
        that.myServiceList = res.data.data;
      });
    },

    // 上下架服务
    groundService(id, value) {
      var name = value;
      var type = "";
      if (name != "上架") {
        type = 1;
      } else {
        type = 2;
      }

      var data = {
        service_id: id,
        type: type
      };

      this.$ajax("post", "Service/ground_service", data, res => {
        if (res.data.id == 0) {
          Toast(res.data.msg);
          this.$router.go(0);
        }
      });
    }
  }
};
</script>

<style scoped>
.foot-border {
  border-top: 1px solid #f0f0f0;
}
.switch-title {
  padding-left: 10%;
  font-size: 14px;
  color: #666;
  border-bottom: 1px solid #f0f0f0;
  background: url("../../assets/img/tour_armyflag.png") no-repeat left;
  background-size: 20px;
  background-position: 5% 50%;
  background-color: #fff;
}
.mint-button-text a {
  color: #fff;
  font-size: 20px;
}
.foot-guide-wrap {
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}
.foot-guide-wrap p {
  color: #ccc;
  font-size: 10px;
}
.foot-guide-wrap span {
  padding: 2px 10px;
  border-radius: 30px;
  margin-left: 10px;
}
.foot-guide-wrap .checking {
  border: 1px solid #00cbc3;
  color: #00cbc3;
}
.foot-guide-wrap label {
  position: relative;
  bottom: 6px;
  font-size: 12px;
  float: right;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid #ccc;
  line-height: 30px;
  text-align: center;
}
.foot-guide-wrap .putaway {
  border-color: #b6de6c;
  color: #b6de6c;
  margin-right: 10px;
}
.foot-guide-wrap .edit {
  border-color: #f9894a;
  color: #f9894a;
}
.line-content-left {
  margin-top: 10px;
  width: 60%;
  float: left;
}
.line-content-left .line-content-title {
  color: #666;
  font-size: 12px;
  margin-bottom: 10px;
}
.line-content-left .line-content-time {
  font-size: 10px;
  color: #c9c9c9;
}
.line-content-right {
  width: 40%;
  text-align: right;
  float: left;
  color: #cacaca;
  font-size: 12px;
  line-height: 30px;
}
.line-content-right span {
  font-size: 14px;
  color: #f79e00;
  padding: 0;
  margin: 0;
}
</style>