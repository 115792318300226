<template>
  <div class="ParkPhotoListDetail ParkPhoto" v-title :data-title="'作品详情:'+title_name">
    <!--v-if="is_weixin"-->
    <div class="index-banner">
      <router-link :to="{path:'/activity/ActivityDetail',query:{aid:info.banner_id}}">
        <img :src="info.banner" alt />
      </router-link>
    </div>
    <div class="content">
      <router-link
        class="u-link"
        :to="{path:'/parkPhoto/PhotoList',query:{user_id:info.user_id,aid:info.banner_id}}"
      >
        <img :src="info.user_icon" v-if="is_show_icon" />
      </router-link>
      <p class="blue" v-if="is_show_icon">{{info.user_nick}}</p>
      <div class="u-info">
        <ul class="table-box clearfix">
          <li>
            <span>编号</span>
            <span>{{info.id}}</span>
          </li>
          <li>
            <span>票数</span>
            <span>{{info.numbers}}</span>
          </li>
          <li>
            <span>排名</span>
            <span>{{info.rank}}</span>
          </li>
        </ul>
        <!--增加一个重新获取授权的按钮-->
        <div class="vote-btn" v-if="is_weixin" @click="go_token">
          <span v-if="true">重新授权</span>
        </div>
        <!--改变投票按钮-->
        <div
          class="vote-btn"
          v-if="is_weixin"
          @click="vote"
          :class="{'no': (info.show == 0)|| (info.show == 1 && info.vote == 1) || (info.show == 3) }"
        >
          <span v-if="info.show == 0">投票未开始</span>
          <span v-else-if="info.show == 1 && info.vote == 0">我要投票</span>
          <span v-else-if="info.show == 1 && info.vote == 1">已投票</span>
          <span v-if="info.show == 3">投票已结束</span>
        </div>
        <div class="vote-btn" v-else :class="{'no': 1 }">
          <span>微信中投票</span>
        </div>
      </div>

      <!--20180320 动态按钮点击跳转到公园景区的全域旅游地图-->
      <ul class="LineList_list clearfix" v-if="go_park_id">
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:19}}"
          class="jingdian"
          v-if="show_btn_spots"
        >
          <img src="../../assets/img/park_button_scene.png" alt />
          <p>景点</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:18}}"
          class="chifan"
          v-if="show_btn_food"
        >
          <img src="../../assets/img/park_button_eat.png" alt />
          <p>美食</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:21}}"
          class="zhuzhi"
          v-if="show_btn_stay"
        >
          <img src="../../assets/img/park_button_ceas.png" alt />
          <p>住宿</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:20}}"
          class="gouwuche-copy"
          v-if="show_btn_shop"
        >
          <img src="../../assets/img/park_button_buy.png" alt />
          <p>购物</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:''}}"
          class="youji"
          v-if="show_btn_notes"
        >
          <img src="../../assets/img/park_button_note.png" alt />
          <p>游记</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:''}}"
          class="zuji"
          v-if="show_btn_foot"
        >
          <img src="../../assets/img/park_button_foot.png" alt />
          <p>足迹</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:''}}"
          class="huodong"
          v-if="show_btn_activity"
        >
          <img src="../../assets/img/park_button_guide.png" alt />
          <p>活动</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:17}}"
          class="cesuo"
          v-if="show_btn_wc"
        >
          <img src="../../assets/img/park_button_cesuo.png" alt />
          <p>厕所</p>
        </router-link>
      </ul>

      <div class="img_tit_box">
        <div class="track_item_body">
          <div class="track-item-tag"></div>
          <div class="track-item-tag">
            <a>{{info.name}}</a>
          </div>
          <div class="track-item-tag" v-if="info.content">
            <a
              href="javascript:;"
              v-html="info.content"
              :class="{active:!is_active}"
              @click="is_active=!is_active"
            ></a>
            <!--<span class="blue unfold" v-show="info.content.length>100"-->
            <!--@click.prevent="is_active=!is_active"><span v-if="is_active">展开</span><span-->
            <!--v-if="!is_active">收起</span></span>-->
          </div>
        </div>
        <div class="pic-list" v-for="(k,i) in info.img" :key="i">
          <img :src="k.baseUrl" />
        </div>
        <div class="note-track-card" v-if="info.gd_map != '' ">
          <router-link
            class="map-pic"
            tag="div"
            :to="{path:'/parkPhoto/ActivityMap',query:{aid:aid,production_id:info.id,
                                    type:info.type,
                                    spots_name:info.name,
                                    lng:info.lng,
                                    lat:info.lat}}"
          >
            <img v-lazy="info.gd_map" alt />
            <!--
                        <div @click.stop.prevent="map_open(info.lng,info.lat)"><img
            src="../../assets/img/home_navigation.png" alt="" class="navigation"></div>-->
            <div class="navigation" @click.stop.prevent="open_nav">
              <img src="../../assets/img/home_navigation.png" alt />
            </div>
            <p class="foot_heigth">
              <span>海拔:{{info.height}}&nbsp;&nbsp;&nbsp;时间:{{info.spots_time}}</span>
            </p>
          </router-link>
        </div>
      </div>

      <!-- 已投票的人 -->
      <div class="who_vote" v-if="info.user != ''">
        <h1>投票者</h1>
        <div class="row" v-for="(t,i) in info.user" :key="i">
          <div class="time">{{t[0].add_time}}</div>
          <div class="list">
            <img :src="k.user_icon" v-for="(k,n) in t" :key="n" />
          </div>
        </div>
      </div>
    </div>
    <!-- 查看更多公园作品 -->
    <div class="more_park_photo">
      <router-link :to="{path:'/parkPhoto/ParkPhoto',query:{aid:info.banner_id}}">查看更多作品>></router-link>
    </div>
    <!--投票规则-->
    <div class="notes-box">
      <input type="hidden" id="aid" v-model="aid" />
      <div class="box" v-html="info.notes"></div>
    </div>

    <!--投票成功弹窗-->
    <div class="pop-up-box" @touchmove.prevent v-if="is_pop_up">
      <img class="pop-up" src="../../assets/img/vote_pop-up.png" alt />
    </div>

    <!--20180324 在投票页面增加发布足迹功能按钮 go_vote表示发布成功之后，跳转到投票页面-->
    <router-link
      :to="{path:'/footprints/FootprintsPublish',query:{aid:aid,park_id:park_id,park_name:park_name,back_:'ParkPhoto'}}"
      tag="div"
      class="photo_publish"
      v-if="is_show_publish"
    >
      <img src="../../assets/switch/footprint_send.png" alt />
    </router-link>
    <!-- 红包雨 -->
    <red-packet
      :redPacketList="red_packet_rain_image_url_list"
      v-if="have_red_packet_rain"
      id_type_="photo"
      :red_packet_type_="info.red_packet_type_"
      :red_packet_image_url="info.red_packet_image_url"
    ></red-packet>
    <!-- 背景音乐 -->
    <bg-music :bg_audio_url="bg_audio_url" v-if="bg_audio_url"></bg-music>
  </div>
  <!--注释掉投票只能在微信打开的提示-->
  <!--<div v-else class="foot_noinfo"><img src="/static/img/foot_noinfo@2x.14391c2.png" alt="">
  <p>请在微信页面打开链接</p></div>-->
</template>
<script>
import { Toast } from 'mint-ui'
import RedPacket from '../../components/RedPacket'
import bgMusic from '../../components/Music'
var wx = require('weixin-js-sdk')
export default {
  components: {
    'red-packet': RedPacket,
    'bg-music': bgMusic
  },
  data () {
    return {
      // 背景音乐
      bg_audio_url: '',
      have_red_packet_rain: 0,
      red_packet_rain_image_url_list: '',
      spots_id: this.$route.query.id,
      aid: this.$route.query.aid,
      sUrl: '',
      info: {},
      is_active: false,
      is_pop_up: false,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      union_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      show_btn_spots: 0, // 显示景点
      show_btn_food: 0, // 显示美食
      show_btn_shop: 0, // 购物
      show_btn_wc: 0, // 厕所
      show_btn_notes: 0, // 游记
      show_btn_activity: 0, // 活动
      show_btn_stay: 0, // 住宿
      show_btn_foot: 0, // 足迹
      go_park_id: '', // 菜单跳转到那家公园的ID
      park_name: this.$route.query.park_name ? this.$route.query.park_name : '', // 给足迹发布界面
      park_id: this.$route.query.park_id ? this.$route.query.park_id : 298,
      is_show_publish: true, // 是否显示足迹发布按钮
      title_name: '', // 浏览器的标题
      wx_title: '', // 微信分享的标题
      wx_content: '',
      wx_img: '',
      wx_url:
        'http://www.zmlxj.com/api.php/weixin/wx_share?type=detail&aid=' +
        this.$route.query.aid +
        '&spot_id=' +
        this.$route.query.id,
      is_show_icon: false,
      user_id: ''
    }
  },
  mounted () {
    this.initSUrl()
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }
    if (this.open_id) {
    } else {
      this.authorize()
    }
    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }
    this.unload()
  },
  methods: {
    // 初始化sUrl
    initSUrl () {
      this.sUrl = window.location.href // "https://m.zmlxj.com/#/parkPhoto/parkPhoto?aid=" + this.aid;
    },
    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id == 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var open_id = this.open_id
            if (info != '') {
              if (info.url != '') {
                let url_arr = info.url.split('share_open_id')

                url = url_arr[0] + '&share_open_id=' + open_id
              } else {
                url = url + '&share_open_id=' + open_id
              }
            } else {
              url = url + '&share_open_id=' + open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id == 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },
    unload () {
      this.get_data(this.union_id)
    },
    get_data (union_id) {
      this.$ajax(
        'post',
        'SpotsRace/get_spots_info',
        {
          spots_id: this.spots_id,
          aid: this.aid,
          union_id: union_id
        },
        ret => {
          if (ret.data.id == 0) {
            this.info = ret.data.data.data

            this.title_name = ret.data.data.data.user_nick + ret.data.data.name
            document.title =
              ret.data.data.data.user_nick + '~' + ret.data.data.data.name
            // console.log(this.title);
            this.show_btn_spots = ret.data.data.data.show_btn_spots
            this.show_btn_food = ret.data.data.data.show_btn_food
            this.show_btn_stay = ret.data.data.data.show_btn_stay
            this.show_btn_wc = ret.data.data.data.show_btn_wc
            this.show_btn_activity = ret.data.data.data.show_btn_activity
            this.show_btn_notes = ret.data.data.data.show_btn_notes
            this.show_btn_shop = ret.data.data.data.show_btn_shop
            this.show_btn_foot = ret.data.data.data.show_btn_foot
            this.go_park_id = ret.data.data.data.go_park_id
            this.wx_title = ret.data.data.data.name
            this.wx_content = ret.data.data.data.content
            this.wx_img = ret.data.data.data.img[0].baseUrl

            if (this.is_weixin) {
              this.wx_share_callback(window.location.href, {
                url: this.sUrl,
                id_: this.aid,
                id_type_: 'photo',
                token: this.token,
                open_id: this.open_id,
                title: this.wx_title,
                content: this.wx_content,
                img: this.wx_img
              })
            }
            this.is_show_icon = ret.data.data.data.is_show_icon

            // 红包
            this.have_red_packet_rain = ret.data.data.have_red_packet_rain
            this.red_packet_rain_image_url_list =
              ret.data.data.red_packet_rain_image_url_list

            // 背景音乐
            this.bg_audio_url = ret.data.data.bg_audio_url
              ? ret.data.data.bg_audio_url
              : ''
            localStorage.setItem('wx_title', ret.data.data.data.name)
            localStorage.setItem('wx_content', ret.data.data.data.content)
            localStorage.setItem('wx_img', ret.data.data.data.img[0].baseUrl)
          }
        }
      )
    },
    login () {
      if (!this.check_login()) {
        localStorage.setItem('login_url', location.hash)
        this.$router.push({ path: '/my/login', query: { log_type: 1 } })
      } else {
        this.user_id = this.check_login().user_id
      }
    },
    //            点击投票
    vote () {
      if (this.info.show == 0) {
        Toast('投票未开始')
      } else if (this.info.show == 3) {
        Toast('投票已结束')
      } else if (this.info.show == 1 && this.info.vote == 1) {
        Toast('今日已投票')
      } else {
        if (this.union_id) {
          this.$ajax(
            'post',
            'SpotsRace/vote',
            {
              aid: this.aid,
              spots_id: this.info.id,
              union_id: this.union_id,
              user_id: this.user_id
            },
            ret => {
              if (ret.data.id == 0) {
                Toast({
                  message: ret.data.msg,
                  iconClass: 'iconfont icon-chenggong',
                  duration: 1000
                })
                this.get_data(this.union_id)
              } else if (ret.data.id == 1) {
                this.authorize()
              } else {
                Toast({
                  message: ret.data.msg,
                  iconClass: 'iconfont icon-fail',
                  duration: 1000
                })
              }
            }
          )
        } else {
          this.authorize()
        }
      }
    },
    // 增加的弹出地图选择功能模块的代码
    // 设置is_show_all_map的值
    open_nav () {
      this.wx_nav(
        window.location.href,
        this.info.lng,
        this.info.lat,
        this.info.name,
        this.info.address
      )
    },

    go_token () {
      this.authorize()
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id_=' +
        this.$route.query.aid +
        '&id=' +
        this.spots_id +
        '&token=' +
        this.token +
        '&state=photoDetail'
      window.location.href = open_url
    }
  }
}
</script>
<style scoped>
html {
  overflow-x: hidden;
}
/*20180323 限制地图导航图片的大小*/
.notes {
  position: fixed;
  float: right;
  top: 65%;
  right: 0;
}
.navigation img {
  width: 4rem;
  height: 6rem;
}
/*20180320 内容里面的图片宽度限制*/
.track-item-tag img {
  max-width: 100%;
}
.ParkPhotoListDetail .note-track-card {
  text-align: left;
}

.ParkPhotoListDetail .index-banner .park_link,
.ParkPhotoListDetail .more_park_photo a {
  width: 50%;
  display: inline-block;
  color: #fff;
  background: #ff9a80;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}

.ParkPhotoListDetail .more_park_photo {
  text-align: center;
  margin: 0 0 0.5rem;
  font-size: 0.7rem;
}

.ParkPhotoListDetail .content {
  margin: 1rem 0 1rem;
  text-align: center;
  font-size: 0.8rem;
}

.ParkPhotoListDetail .u-link img {
  width: 4rem;
  display: inline-block;
  border-radius: 50%;
}

.ParkPhotoListDetail .u-info {
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 0.8rem 0;
  border-bottom: 5px solid #ccc;
}

.ParkPhotoListDetail .content pre {
  font-size: 0.9rem;
  color: #fff;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0.5rem 0;
}

.ParkPhotoListDetail .u-info .table-box {
  width: 65%;
  display: inline-block;
  background: #f59f32;
  border-bottom: 0.2rem solid #936425;
  border-radius: 0.3rem;
  padding: 0 0.2rem;
}

.ParkPhotoListDetail .u-info .table-box li {
  float: left;
  width: 33.33%;
  height: 2.4rem;
  /* line-height: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ParkPhotoListDetail .u-info .table-box li span:nth-child(1) {
  color: #eee;
}

.ParkPhotoListDetail .u-info .vote-btn {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  /* line-height: 2.4rem; */
  background: #e03347;
  border-radius: 0.3rem;
  border-bottom: 0.2rem solid #892c37;
  padding: 0 0.2rem;
  margin-left: 1px;
}

.ParkPhotoListDetail .u-info .vote-btn.no {
  background-color: #ccc;
  border-bottom: 0.2rem solid #999;
}

.ParkPhotoListDetail .content .img_tit_box {
  padding: 0 10px;
  text-align: left;
}

.ParkPhotoListDetail .content .pic-list img {
  width: 100%;
  display: block;
  margin: 0.2rem 0;
}

.ParkPhotoListDetail .who_vote {
  font-size: 0;
}

.ParkPhotoListDetail .who_vote h1 {
  font-size: 0.9rem;
  color: #fff;
}

.ParkPhotoListDetail .who_vote .row {
  margin-top: 0.1rem;
  height: 1.2rem;
  /* overflow: hidden; */
}

.ParkPhotoListDetail .who_vote .time {
  font-size: 0.7rem;
  height: 1.2rem;
  line-height: 1.2rem;
  color: #fff;
  float: left;
  margin-right: 0.2rem;
}

.ParkPhotoListDetail .who_vote .list {
  height: 1.2rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.ParkPhotoListDetail .who_vote img {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
  border-radius: 50%;
}

.notes-box {
  padding: 0 0.5rem;
  line-height: 1.5rem;
  font-size: 0.7rem;
}
.notes-box .box {
  padding: 0 0 1.15rem 0;
  border-bottom: 1px solid #f2f2f2;
}
.box img {
  border: 0;
  max-width: 100%;
  margin: 0 auto;
  height: auto;
}

.LineList_list {
  padding-top: 0.5rem;
}

.LineList_list li {
  color: #666;
  text-align: center;
}

.LineList_list li.jingdian,
.LineList_list li.zhuzhi,
.LineList_list li.gouwuche-copy,
.LineList_list li.chifan {
  background: none;
}

.LineList_list li img {
  width: 2rem;
  display: inline-block;
}

.LineList_list li p {
  margin: 0.2rem 0;
}
.ParkPhoto .photo_publish {
  position: fixed;
  width: 3rem;
  height: 3rem;
  bottom: 2rem;
  left: calc(50% - 1.5rem);
  margin: 0;
}
.ParkPhoto .photo_publish img {
  width: 100%;
}
.ParkPhoto {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  padding-bottom: 2rem;
  /* margin-bottom: 60%; */
}
.ParkPhoto .index-banner img {
  width: 100%;
  height: auto;
}
a {
  color: #000;
  text-decoration: none;
}
.track_item_body {
  position: relative;
  display: block;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.track_item_body .track-item-tag {
  padding-top: 5px;
  line-height: 20px;
  overflow: hidden;
  position: relative;
}
.track_item_body .track-item-tag:nth-of-type(2) a {
  font-weight: 700;
  /* background: url(../../assets/img/track_describe.png)
    left 0.2rem no-repeat;
  background-size: 1.2rem; */
}
.ParkPhotoListDetail .note-track-card {
  text-align: left;
}
.note-track-card {
  background: #f2f2f2;
  border-radius: 3px;
}
.note-track-card .map-pic {
  width: 100%;
  height: 6rem;
  background-position: 50%;
  background-size: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.map-pic > img {
  width: 100%;
  margin-top: 3rem;
}
.foot_heigth,
.navigation {
  position: absolute;
  right: 0;
  bottom: 0;
}
.navigation {
  display: inline-block;
  height: 100%;
}
.navigation img {
  width: 4rem;
  height: 6rem;
}
.foot_heigth {
  color: #000;
  left: 0;
  z-index: 0;
  font-size: 0.7rem;
}
.foot_heigth,
.navigation {
  position: absolute;
  right: 0;
  bottom: 0;
}
.foot_heigth span {
  background-color: rgba(0, 0, 0, 0.4);
  display: inline-block;
  padding: 0 0.4rem;
  color: #fff;
}
.ParkPhotoListDetail .who_vote {
  font-size: 0;
}
.ParkPhotoListDetail .who_vote h1 {
  font-size: 0.9rem;
  color: #fff;
}
.ParkPhotoListDetail .who_vote .row {
  margin-top: 0.1rem;
  height: 1.2rem;
  overflow: hidden;
}
.ParkPhotoListDetail .who_vote .row {
  text-align: left;
  padding: 0 0.3rem;
}
.ParkPhotoListDetail .who_vote .time {
  font-size: 0.7rem;
  height: 1.2rem;
  line-height: 1.2rem;
  color: #fff;
  float: left;
  margin-right: 0.2rem;
}
.ParkPhoto.ParkPhotoListDetail .who_vote .time {
  color: #333;
}
.ParkPhotoListDetail .who_vote .list {
  height: 1.2rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.ParkPhotoListDetail .who_vote img {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
  border-radius: 50%;
}

/*重新获取授权按钮*/
.vote-btn {
  background-color: #03a9f4;
  border-bottom: 1px solid #999;
}
</style>
