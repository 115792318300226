<template>
  <div class="BaseMsg grayness" v-title data-title="编辑基本信息">
    <mt-header fixed title="编辑基本信息">
      <a slot="left">
        <mt-button icon="back" @click="$router.go(-1)"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="main_frame">
        <form id="formid" method="post" enctype="multipart/form-data">
          <input type="hidden" name="aid" v-model="id" />
          <input type="hidden" name="uid" v-model="user_id" />
          <div class="apply-wrap apply">
            <div class="vol-img" @click="file_img" style="margin: 0 auto">
              <img :src="idcard" alt />
              <div id="img_btn"></div>
            </div>
            <input type="file" id="user_img" name="volunteer_img" class="hidden" />
            <div
              class="no-write"
              style="margin-top: 3rem"
            >要求：请上传本人一寸照片，为保证个人照片的清晰度，需上传宽高200px以上，大小1M以内的jpg或者png图片</div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">姓名</span>
            <div class="apply-input">
              <input
                type="text"
                placeholder="请填写"
                v-model="info.volunteer_name"
                name="volunteer_name"
                required="required"
                maxlength="4"
                minlength="2"
              />
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">性别</span>
            <div class="apply-input">
              <input type="hidden" v-model="info.volunteer_sex" name="volunteer_sex" />
              <div
                class="sex woman"
                :class="{active_woman:info.volunteer_sex==0}"
                @click="info.volunteer_sex=0"
              >女</div>
              <div
                class="sex man"
                :class="{active_man:info.volunteer_sex==1}"
                @click="info.volunteer_sex=1"
              >男</div>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">身份证号</span>
            <div class="apply-input">
              <input
                type="text"
                placeholder="请填写"
                v-model="info.volunteer_card"
                name="volunteer_card"
                maxlength="18"
              />
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">学历</span>
            <div class="apply-input">
              <select
                name="volunteer_education"
                v-model="info.volunteer_education"
                style="padding-right: 2rem"
              >
                <option value="小学">小学</option>
                <option value="中学">中学</option>
                <option value="高中">高中</option>
                <option value="专科">专科</option>
                <option value="本科">本科</option>
                <option value="硕士研究生">硕士研究生</option>
                <option value="博士研究生">博士研究生</option>
                <option value="其他">其他</option>
              </select>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">QQ</span>
            <div class="apply-input">
              <input
                placeholder="请填写"
                v-model="info.volunteer_qq"
                name="volunteer_qq"
                required="required"
                minlength="6"
                type="text"
              />
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">微信号</span>
            <div class="apply-input">
              <input
                placeholder="请填写"
                v-model="info.volunteer_weixin"
                name="volunteer_weixin"
                type="text"
              />
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">工作单位</span>
            <div class="apply-input">
              <input
                placeholder="请填写"
                v-model="info.volunteer_work"
                name="volunteer_work"
                required="required"
                type="text"
              />
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">职业</span>
            <div class="apply-input">
              <input
                placeholder="请填写"
                v-model="info.volunteer_profession"
                name="volunteer_profession"
                required="required"
                type="text"
              />
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">所学专业</span>
            <div class="apply-input">
              <input
                placeholder="请填写"
                v-model="info.volunteer_major"
                name="volunteer_major"
                required="required"
                type="text"
              />
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">民族</span>
            <div class="apply-input">
              <select name="volunteer_nation" v-model="info.volunteer_nation">
                <option v-for="(k, i) in nation_options" :key="i">{{k}}</option>
              </select>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">国籍</span>
            <div class="apply-input">
              <input
                placeholder="请填写"
                v-model="info.volunteer_country"
                name="volunteer_country"
                type="text"
              />
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">住址</span>
            <div class="apply-input">
              <input
                placeholder="请填写"
                v-model="info.user_address"
                name="user_address"
                required="required"
                type="text"
              />
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">省市</span>
            <div class="apply-input">
              <select name="user_province" @change="ProvinceSelect">
                <option :value="info.user_province" v-if="info.user_province!=''"></option>
                <option>--请选择--</option>
                <option v-for="(k, i) in pro" :key="i">{{k}}</option>
              </select>
              <select name="user_city" id="city" style="width: 100px;background-color: #fff">
                <option v-if="info.user_city!=''" :value="info.user_city"></option>
                <option>--请选择--</option>
                <option v-for="(k, i) in city_info" :key="i">{{k}}</option>
              </select>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">邮箱</span>
            <div class="apply-input">
              <input
                placeholder="请填写"
                v-model="info.volunteer_email"
                name="volunteer_email"
                type="text"
              />
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">服装尺码</span>
            <div class="apply-input">
              <select name="volunteer_clothes" v-model="info.volunteer_clothes">
                <option v-for="(k,i) in clothes_options" :key="i">{{k}}</option>
              </select>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">紧急联系人</span>
            <div class="apply-input">
              <input
                placeholder="请填写"
                v-model="info.volunteer_nstancy_people"
                name="volunteer_nstancy_people"
                required="required"
                type="text"
              />
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">紧急人手机</span>
            <div class="apply-input">
              <input
                placeholder="请填写"
                v-model="info.volunteer_nstancy_tel"
                name="volunteer_nstancy_tel"
                required="required"
                type="text"
              />
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">个人特长</span>
            <div class="apply-textarea">
              <textarea
                name="specialties"
                v-model="info.specialties"
                required="required"
                placeholder="您有哪些技能，如琴棋书画等"
              ></textarea>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">科普传播资源</span>
            <div class="apply-textarea">
              <textarea
                name="resource"
                v-model="info.resource"
                required="required"
                placeholder="申请者需服务地质公园的科普推广工作，请详细说明你开展科普工作可以使用的社会资源、人脉、媒体、能力等"
              ></textarea>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">个人履历</span>
            <div class="apply-textarea">
              <textarea
                name="resume"
                v-model="info.resume"
                required="required"
                placeholder="申请者需服务地质公园的科普推广工作，请详细说明你开展科普工作可以使用的社会资源、人脉、媒体、能力等"
              ></textarea>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="form-title">作品</span>
            <div class="apply-textarea">
              <textarea
                name="production"
                v-model="info.production"
                placeholder="若有作品（视频、诗词歌赋、摄影、模型、标本等），请将个人作品/成果以附件形式，发送到邮箱volunteer@zmlxj.com，邮件主题名为：科普志愿者申请人XXX-作品（如：科普志愿者申请人王强-作品）"
              ></textarea>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">你从何处得知此活动</span>
            <div class="apply-textarea">
              <textarea
                name="come_where"
                v-model="info.come_where"
                required="required"
                placeholder="网上、朋友介绍等"
              ></textarea>
            </div>
          </div>
        </form>
        <div class="btn">
          <mt-button type="primary" class="mint-header" @click="submitForm">保存</mt-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      user_id: "",
      id: this.$route.query.id,
      info: {},
      idcard: "",
      nation_options: [
        "汉族",
        "壮族",
        "满族",
        "回族",
        "苗族",
        "维吾尔族",
        "土家族",
        "彝族",
        "蒙古族",
        "藏族",
        "布依族",
        "侗族",
        "瑶族",
        "朝鲜族",
        "白族",
        "哈尼族",
        "哈萨克族",
        "黎族",
        "傣族",
        "畲族",
        "傈僳族",
        "仡佬族",
        "东乡族",
        "高山族",
        "拉祜族",
        "水族",
        "佤族",
        "纳西族",
        "羌族",
        "土族",
        "仫佬族",
        "锡伯族",
        "柯尔克孜族",
        "达斡尔族",
        "景颇族",
        "毛南族",
        "撒拉族",
        "布朗族",
        "塔吉克族",
        "阿昌族",
        "普米族",
        "鄂温克族",
        "怒族",
        "京族",
        "基诺族",
        "德昂族",
        "保安族",
        "俄罗斯族",
        "裕固族",
        "乌孜别克族",
        "门巴族",
        "鄂伦春族",
        "独龙族",
        "塔塔尔族",
        "赫哲族",
        "珞巴族"
      ], //民族
      pro: [
        "北京",
        "天津",
        "上海",
        "重庆",
        "河北",
        "山西",
        "辽宁",
        "吉林",
        "黑龙江",
        "江苏",
        "浙江",
        "安徽",
        "福建",
        "江西",
        "山东",
        "河南",
        "湖北",
        "湖南",
        "广东",
        "海南",
        "四川",
        "贵州",
        "云南",
        "陕西",
        "甘肃",
        "青海",
        "内蒙古",
        "广西",
        "西藏",
        "宁夏",
        "新疆维吾尔自治区",
        "香港",
        "澳门",
        "台湾"
      ], //城市选择
      city: {
        北京: [
          "东城区",
          "西城区",
          "崇文区",
          "宣武区",
          "朝阳区",
          "海淀区",
          "丰台区",
          "石景山区",
          "房山区",
          "通州区",
          "顺义区",
          "昌平区",
          "大兴区",
          "怀柔区",
          "平谷区",
          "门头沟区",
          "密云县",
          "延庆县"
        ],
        天津: [
          "和平区",
          "河东区",
          "河西区",
          "南开区",
          "河北区",
          "红桥区",
          "东丽区",
          "西青区",
          "北辰区",
          "津南区",
          "武清区",
          "宝坻区",
          "滨海新区",
          "静海县",
          "宁河县",
          "蓟县"
        ],
        上海: [
          "黄浦区",
          "卢湾区",
          "徐汇区",
          "长宁区",
          "静安区",
          "普陀区",
          "闸北区",
          "虹口区",
          "杨浦区",
          "闵行区",
          "宝山区",
          "嘉定区",
          "浦东新区",
          "金山区",
          "松江区",
          "青浦区",
          "奉贤区",
          "崇明县"
        ],
        重庆: [
          "渝中区",
          "大渡口区",
          "江北区",
          "南岸区",
          "北碚区",
          "渝北区",
          "巴南区",
          "长寿区",
          "双桥区",
          "沙坪坝区",
          "万盛区",
          "万州区",
          "涪陵区",
          "黔江区",
          "永川区",
          "合川区",
          "江津区",
          "九龙坡区",
          "南川区",
          "綦江县",
          "潼南县",
          "荣昌县",
          "璧山县",
          "大足县",
          "铜梁县",
          "梁平县",
          "开县",
          "忠县",
          "城口县",
          "垫江县",
          "武隆县",
          "丰都县",
          "奉节县",
          "云阳县",
          "巫溪县",
          "巫山县",
          "石柱土家族自治县",
          "秀山土家族苗族自治县",
          "酉阳土家族苗族自治县",
          "彭水苗族土家族自治县"
        ],
        河北: [
          "石家庄",
          "唐山",
          "秦皇岛",
          "邯郸",
          "邢台",
          "保定",
          "张家口",
          "承德",
          "沧州",
          "廊坊",
          "衡水"
        ],
        山西: [
          "太原",
          "大同",
          "阳泉",
          "长治",
          "晋城",
          "朔州",
          "晋中",
          "运城",
          "忻州",
          "临汾",
          "吕梁"
        ],
        辽宁: [
          "沈阳",
          "大连",
          "鞍山",
          "抚顺",
          "本溪",
          "丹东",
          "锦州",
          "营口",
          "阜新",
          "辽阳",
          "盘锦",
          "铁岭",
          "朝阳",
          "葫芦岛"
        ],
        吉林: [
          "长春",
          "吉林",
          "四平",
          "辽源",
          "通化",
          "白山",
          "松原",
          "白城",
          "延边朝鲜族自治州"
        ],
        黑龙江: [
          "哈尔滨",
          "齐齐哈尔",
          "鹤岗",
          "双鸭山",
          "鸡西",
          "大庆",
          "伊春",
          "牡丹江",
          "佳木斯",
          "七台河",
          "黑河",
          "绥化",
          "大兴安岭"
        ],
        江苏: [
          "南京",
          "苏州",
          "无锡",
          "常州",
          "镇江",
          "南通",
          "泰州",
          "扬州",
          "盐城",
          "连云港",
          "徐州",
          "淮安",
          "宿迁"
        ],
        浙江: [
          "杭州",
          "宁波",
          "温州",
          "嘉兴",
          "湖州",
          "绍兴",
          "金华",
          "衢州",
          "舟山",
          "台州",
          "丽水"
        ],
        安徽: [
          "合肥",
          "芜湖",
          "蚌埠",
          "淮南",
          "马鞍山",
          "淮北",
          "铜陵",
          "安庆",
          "黄山",
          "滁州",
          "阜阳",
          "宿州",
          "巢湖",
          "六安",
          "亳州",
          "池州",
          "宣城"
        ],
        福建: [
          "福州",
          "厦门",
          "莆田",
          "三明",
          "泉州",
          "漳州",
          "南平",
          "龙岩",
          "宁德"
        ],
        江西: [
          "南昌",
          "景德镇",
          "萍乡",
          "九江",
          "新余",
          "鹰潭",
          "赣州",
          "吉安",
          "宜春",
          "抚州",
          "上饶"
        ],
        山东: [
          "济南",
          "青岛",
          "淄博",
          "枣庄",
          "东营",
          "烟台",
          "潍坊",
          "济宁",
          "泰安",
          "威海",
          "日照",
          "莱芜",
          "临沂",
          "德州",
          "聊城",
          "滨州",
          "菏泽"
        ],
        河南: [
          "郑州",
          "开封",
          "洛阳",
          "平顶山",
          "安阳",
          "鹤壁",
          "新乡",
          "焦作",
          "濮阳",
          "许昌",
          "漯河",
          "三门峡",
          "南阳",
          "商丘",
          "信阳",
          "周口",
          "驻马店"
        ],
        湖北: [
          "武汉",
          "黄石",
          "十堰",
          "荆州",
          "宜昌",
          "襄樊",
          "鄂州",
          "荆门",
          "孝感",
          "黄冈",
          "咸宁",
          "随州",
          "恩施"
        ],
        湖南: [
          "长沙",
          "株洲",
          "湘潭",
          "衡阳",
          "邵阳",
          "岳阳",
          "常德",
          "张家界",
          "益阳",
          "郴州",
          "永州",
          "怀化",
          "娄底",
          "湘西"
        ],
        广东: [
          "广州",
          "深圳",
          "珠海",
          "汕头",
          "韶关",
          "佛山",
          "江门",
          "湛江",
          "茂名",
          "肇庆",
          "惠州",
          "梅州",
          "汕尾",
          "河源",
          "阳江",
          "清远",
          "东莞",
          "中山",
          "潮州",
          "揭阳",
          "云浮"
        ],
        海南: ["海口", "三亚"],
        四川: [
          "成都",
          "自贡",
          "攀枝花",
          "泸州",
          "德阳",
          "绵阳",
          "广元",
          "遂宁",
          "内江",
          "乐山",
          "南充",
          "眉山",
          "宜宾",
          "广安",
          "达州",
          "雅安",
          "巴中",
          "资阳",
          "阿坝",
          "甘孜",
          "凉山"
        ],
        贵州: [
          "贵阳",
          "六盘水",
          "遵义",
          "安顺",
          "铜仁",
          "毕节",
          "黔西南",
          "黔东南",
          "黔南"
        ],
        云南: [
          "昆明",
          "曲靖",
          "玉溪",
          "保山",
          "昭通",
          "丽江",
          "普洱",
          "临沧",
          "德宏",
          "怒江",
          "迪庆",
          "大理",
          "楚雄",
          "红河",
          "文山",
          "西双版纳"
        ],
        陕西: [
          "西安",
          "铜川",
          "宝鸡",
          "咸阳",
          "渭南",
          "延安",
          "汉中",
          "榆林",
          "安康",
          "商洛"
        ],
        甘肃: [
          "兰州",
          "嘉峪关",
          "金昌",
          "白银",
          "天水",
          "武威",
          "酒泉",
          "张掖",
          "庆阳",
          "平凉",
          "定西",
          "陇南",
          "临夏",
          "甘南"
        ],
        青海: ["西宁", "海东", "海北", "海南", "黄南", "果洛", "玉树", "海西"],
        内蒙古: [
          "呼和浩特",
          "包头",
          "乌海",
          "赤峰",
          "通辽",
          "鄂尔多斯",
          "呼伦贝尔",
          "巴彦淖尔",
          "乌兰察布",
          "锡林郭勒盟",
          "兴安盟",
          "阿拉善盟"
        ],
        广西: [
          "南宁",
          "柳州",
          "桂林",
          "梧州",
          "北海",
          "防城港",
          "钦州",
          "贵港",
          "玉林",
          "百色",
          "贺州",
          "河池",
          "来宾",
          "崇左"
        ],
        西藏: ["拉萨", "那曲", "昌都", "林芝", "山南", "日喀则", "阿里"],
        宁夏: ["银川", "石嘴山", "吴忠", "固原", "中卫"],
        新疆维吾尔自治区: [
          "乌鲁木齐",
          "克拉玛依",
          "吐鲁番",
          "哈密",
          "和田",
          "阿克苏",
          "喀什",
          "克孜勒苏",
          "巴音郭楞",
          "昌吉",
          "博尔塔拉",
          "伊犁",
          "塔城",
          "阿勒泰"
        ],
        香港: ["香港岛", "九龙东", "九龙西", "新界东", "新界西"],
        澳门: ["澳门半岛", "离岛"],
        台湾: ["台北", "高雄", "基隆", "新竹", "台中", "嘉义", "台南市"]
      }, //城市
      city_info: [], //区
      clothes_options: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"] //服装尺码
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      if (this.check_login()) {
        this.user_id = this.check_login().user_id;
      }
      var that = this;
      if (this.hybrid.hybrid.versions.myapp) {
        // FOR 安卓最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding-top", "0");
        that.user_id = JavaScriptInterface.getUserId();
        that.getData();
      } else if (this.hybrid.hybrid.versions.myiOSapp) {
        // FOR IOS最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding-top", "0");
        this.hybrid.hybrid.registerHandler("getIOSUserId", function(
          data,
          responseCallback
        ) {
          that.user_id = data.user_id;
          var responseData = "已经收到IOS传来的userid";
          responseCallback(responseData);
          that.getData();
        });
      } else {
        this.getData();
      }
    },
    getData() {
      this.$ajax(
        "post",
        "volunteer/user_info",
        { aid: this.id, uid: this.user_id },
        ret => {
          this.info = ret.data.info;
          this.idcard = this.info.volunteer_img;
        }
      );
    },
    ProvinceSelect(e) {
      //城市选择
      this.city_info = this.city[e.target.value];
    },
    file_img() {
      var that = this;
      var file = $("#user_img")[0];
      file.click();
      file.onchange = function() {
        that.idcard = that.getObjectURL(this.files[0]);
      };
    },
    submitForm() {
      //提交表单
      var form = document.getElementById("formid");
      var formdata = new FormData(form);
      var that = this;
      this.formMethod("volunteer/updata", formdata, function(data) {
        if (data.status == 1) {
          if (that.$route.query.type == 1) {
            that.$router.push({
              path: "/volunteer/apply",
              query: { aid: that.volunteer_id }
            });
          } else {
            that.$router.push({
              path: "/volunteer/HealthyMsg",
              query: { id: that.id }
            });
          }
        } else {
          Toast(data.info);
        }
      });
    }
  }
};
</script>
<style scoped>
.hidden {
  display: none;
}
.BaseMsg .page-content {
  background-color: #fff;
}

.BaseMsg .btn {
  margin-top: 1rem;
}

.apply-wrap {
  padding: 0.5rem 0.8rem;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
}

.apply-wrap label {
  color: #999;
  font-size: 0.5rem;
}

.vol-img {
  width: 72px;
  height: 72px;
  background: url("../../assets/img/touxiang.png") no-repeat;
  background-size: 72px;
}

.vol-img img {
  width: 100%;
  height: 100%;
}

#img_btn {
  position: relative;
  top: -30px;
  left: 48px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background: url("../../assets/img/tour_camera@2x.png") no-repeat;
  background-size: 45px;
  cursor: pointer;
}

.no-write {
  font-size: 0.5rem;
  color: #999;
}

.required {
  color: #ff0000;
  font-size: 0.5rem;
}

.form-title {
  color: #666;
  font-size: 0.7rem;
}

.apply-input {
  float: right;
  color: #333;
}

.apply-input input {
  text-align: right;
  font-size: 0.8rem;
  color: #333;
}

.apply-input .sex {
  float: right;
  width: 1.4rem;
  height: 1.4rem;
  padding-left: 1.5rem;
  cursor: pointer;
}

.apply-input .man {
  background: url("../../assets/img/no_active_man.png") no-repeat;
  background-size: 1rem;
}

.apply-input .woman {
  background: url(../../assets/img/no_active_woman.png) no-repeat;
  background-size: 1rem;
}

.apply-input .active_man {
  background: url("../../assets/img/active_man.png") no-repeat;
  background-size: 1rem;
}

.apply-input .active_woman {
  background: url("../../assets/img/active_woman.png") no-repeat;
  background-size: 1rem;
}

.apply-input select {
  border: none;
  color: #333;
  text-align: center;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url("../../assets/img/feed-downlist.png") no-repeat scroll
    transparent;
  background-position: 100% 54%;
  background-size: 0.5rem;
}

.apply-textarea textarea {
  padding: 2.5%;
  border: 0;
  margin-top: 0.2rem;
  width: 95%;
  height: 3.4rem;
  color: #333;
  background-color: #f2f2f2;
  font-size: 0.6rem;
}
</style>