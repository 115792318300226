<template>
    <div class="download" v-if="show_download">
        <img class="Logo" src="../assets/img/login_geoLogo.png" alt="">
        <a href="https://a.app.qq.com/o/simple.jsp?pkgname=com.zmlxj.www" target="_blank"><img class="dowload_btn" src="../assets/img/dowload.png" alt=""></a>
        <div class="info">
            <h2>抢红包  留足迹  写游记</h2>
            <p>心动不如行动</p>
            <p>赶紧来来下载我们的APP</p>
        </div>
        <img class="clean" @click="show_download=false" src="../assets/img/search_clean.png" alt="">
    </div>
</template>
<script>
export default {
  data () {
    return {
      show_download: true
    }
  }
}
</script>
<style scoped>
    .download {
        position: fixed;
        bottom: 2.7rem;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.6);
        height: 2.4rem;
        padding: .2rem .8rem;
        z-index: 1000;
    }

    .download .Logo {
        height: 2.4rem;
        float: left;
        margin-right: 10px;
    }

    .download .info {
        overflow: hidden;
        font-size: .6rem;
        color: #fff;
    }

    .download .info h2 {
        font-size: .8rem;
        line-height: 1rem;
    }

    .download .info p {
        line-height: 0.7rem;
    }

    .dowload_btn {
        float: right;
        height: 2rem;
        margin-top: .2rem;
        margin-right: 1.8rem;
    }

    .clean {
        position: absolute;
        top: .6rem;
        right: .2rem;
        height: 1.8rem;
    }

</style>
