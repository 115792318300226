<template>
  <div class="apply_box grayness" v-title data-title="志愿者">
    <mt-header fixed title="志愿者">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="main_frame">
        <div class="apply-wrap apply" style="padding-right: 0">
          <div class="apply-vol-wrap clearfix">
            <div class="apply-park-name" style="color: #333">志愿者申请表填写</div>
            <span class="update-time">申请于：{{info.apply_time}}</span>
            <router-link :to="{path:'/Volunteer/view',query:{id:id}}" class="info-preview fr">预览</router-link>
          </div>
          <div class="apply-vol-wrap">
            <span class="check-guide" @click="examine=true">查看填写指南</span>
          </div>
          <div class="apply-vol-wrap" id="apply_status">
            <div class="apply-status pending" v-if="info.status==1 && info.complete==0">
              <span>正在审核</span>
              <p>您的申请资料尚未完善</p>
            </div>
            <div class="apply-status pending" v-if="info.status==1 && info.complete!=0">
              <span>正在审核</span>
              <p>您的资料正在审核中，请耐心等待...</p>
            </div>
            <div class="apply-status pass" v-if="info.status==2">
              <span>通过</span>
              <p>恭喜您成为志愿者中的一员</p>
            </div>
            <div class="apply-status fail" v-if="info.status==3">
              <span>未通过</span>
              <p>
                很抱歉，你的申请资料未通过审核，
                <a class="alert_why_fail" @click="reason=true">查看原因</a>
              </p>
            </div>
          </div>
        </div>
        <!-- 基本信息 -->
        <div id="base-msg">
          <div class="apply-wrap" style="border-top: 0.3rem solid #f2f2f2;">
            <div class="apply-title">
              <span class="icon-base">基本信息</span>
              <router-link
                class="fr"
                v-if="info.complete==0"
                :to="{path:'/volunteer/BaseMsg',query:{id:info.aid,type:1}}"
              >待完善</router-link>
              <router-link
                class="fr"
                v-if="info.complete!=0"
                :to="{path:'/volunteer/BaseMsg',query:{id:info.aid,type:1}}"
              >完善</router-link>
            </div>
          </div>
          <!-- 信息未填写 -->
          <div class="msg-no-edit">
            <div class="apply-wrap apply" v-if="info.complete==0">
              <div class="no-write">请填写您的基本信息，包括特长，履历等</div>
            </div>
          </div>
        </div>
        <!-- 健康信息 -->
        <div id="health-msg">
          <div class="apply-wrap" style="border-top: 0.3rem solid #f2f2f2;">
            <div class="apply-title">
              <span class="icon-health">健康信息</span>
              <router-link
                class="fr"
                v-if="info.complete2==0"
                :to="{path:'/volunteer/HealthyMsg',query:{id:info.aid,type:1}}"
              >待完善</router-link>
              <router-link
                class="fr"
                v-if="info.complete2!=0"
                :to="{path:'/volunteer/HealthyMsg',query:{id:info.aid,type:1}}"
              >完善</router-link>
            </div>
          </div>
          <!-- 信息未填写 -->
          <div class="msg-no-edit">
            <div class="apply-wrap apply" v-if="info.complete2==0">
              <div class="no-write">请填写您最近的健康信息，帮助我们了解您的请看</div>
            </div>
          </div>
        </div>
        <!-- 个人志愿 -->
        <div id="individual_volunteer">
          <div class="apply-wrap" style="border-top: 0.3rem solid #f2f2f2;">
            <div class="apply-title">
              <span class="icon-state">个人志愿</span>
              <router-link
                class="fr"
                v-if="info.complete3==0"
                :to="{path:'/volunteer/CompileVolunteer',query:{id:info.aid,type:1}}"
              >待完善</router-link>
              <router-link
                class="fr"
                v-if="info.complete3!=0"
                :to="{path:'/volunteer/CompileVolunteer',query:{id:info.aid,type:1}}"
              >完善</router-link>
            </div>
          </div>
          <!-- 信息未填写 -->
          <div class="msg-no-edit">
            <div class="apply-wrap apply" v-if="info.complete3==0">
              <div class="no-write">请填写您的意向公园，和对志愿者工作的愿景</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 查看填写指南 - 弹窗 -->
    <div class="popups apply" v-show="examine">
      <div class="popups-bg"></div>
      <div class="popups-box">
        <div class="close" @click="examine=false">x</div>
        <h1>填写指南</h1>
        <ol>
          <li>
            申请者请
            <b>逐项全部</b>填写申请表，信息应真实有效；
          </li>
          <li>
            申请者可
            <span>提供自荐视频</span>，内容时长不限，可以说一些申请表中没有的内容，或者展示下自己的风采。视频文件命名：XXX-视频（如王强-视频），发送到邮箱volunteer@zmlxj.com；（可选）
          </li>
          <li>
            <b>资源</b>：申请者需服务地质公园的科普推广工作，请
            <span>详细说明</span>你开展科普工作可以使用的社会资源、人脉、媒体、能力等；
          </li>
          <li>
            <b>作品</b>：若有作品，请将个人作品/成果以附件形式，随申请表一起发送到邮箱volunteer@zmlxj.com，命名为XXX-作品（如：王强-作品）；
          </li>
          <li>
            健康信息：请申请者
            <span>务必</span>填写真实健康状况；
          </li>
          <li>如有特殊情况或问题，请咨询工作人员；（朱静QQ：2934022763）</li>
          <li>
            建议认真填写“你将为公园和活动做出的贡献”一项，请结合招募公告里的要求，整合自己资源进行规划，此项为加分项。
            <br />
            <br />
            <span>
              重要提示：
              <br />1、申请表提交成功后，即进入最美旅行家科普志愿者数据库。最美旅行家工作人员将根据申请人填写的申请信息， 对申请人进行综合评估、筛选及电话/网络面试， 与地质公园一起确定最终志愿者名单。请大家随时保持电话畅通（电话面试时如多次无法与申请者取得联系，视为申请者主动放弃）。进入志愿数据库的申请者将会优先参与最美旅行家组织的相关地学、科普活动；
              <br />2、申请表填写完整度越高，入选率越大；
              <br />3、请正确填写申请表基本信息、健康信息以及个人志愿信息，分步保存成功即表示您的申请表资料已提交给最美旅行家及对应公园相关人员处，进入审核流程。
            </span>
          </li>
        </ol>
      </div>
    </div>
    <!-- 未通过原因 - 弹窗 -->
    <div class="popups apply" v-if="reason">
      <div class="popups-bg" @click="reason=false"></div>
      <div class="popups-box">
        <div class="close" @click="reason=false">x</div>
        <h1>未通过原因</h1>
        <div class="text">{{info.reason}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user_id: "",
      id: this.$route.query.aid,
      info: {},
      examine: false, //查看填写指南
      reason: false
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      if (this.check_login()) {
        this.user_id = this.check_login().user_id;
      } else {
        this.user_id = this.get_User_Id();
      }
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      this.getData();
    },
    getData() {
      this.$ajax("post", "Volunteer/vol_status", { id: this.id }, ret => {
        if (ret.data.error == 0) this.info = ret.data.data;
      });
    }
  }
};
</script>
<style scoped>
.apply_box .apply-wrap {
  padding-left: 0.5rem;
}
.apply_box {
  height: 100%;
  background-color: #fff;
}
.apply_box .apply-park-name {
  width: auto;
}
.info-preview {
  display: inline-block;
  height: 1.5rem;
  line-height: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #fff;
  font-size: 0.8rem;
  background-color: #ff9a80;
}
.apply-vol-wrap {
  margin-bottom: 0.3rem;
  line-height: 1rem;
}
.check-guide {
  font-size: 0.7rem;
  text-decoration: underline;
  color: #ffaa48;
}
.update-time {
  margin-left: 0.3rem;
  font-size: 0.6rem;
  color: #999;
}
.apply-status {
  display: inline-block;
  text-align: center;
  font-size: 0.7rem;
  color: #999;
  padding-left: 0.9rem;
  background: no-repeat left center;
  background-size: 0.7rem;
}
.apply-status span {
  color: #333;
}
.apply-status p {
  margin-left: 2rem;
  display: inline;
}
.apply-title a {
  font-size: 0.7rem;
  padding-right: 0.7rem;
  background: url(../../assets/img/feed-title-more.png) no-repeat right;
  background-size: 0.5rem 0.7rem;
  color: #ffaa48;
}
.popups-bg {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}
.popups-box {
  position: fixed;
  z-index: 5000;
  width: 85%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  text-align: center;
  border-radius: 3px;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 90%;
  border-radius: 0.3rem;
  padding: 0.7rem 0.5rem 0.9rem;
}
.popups.apply h1 {
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
  color: #333;
  font-weight: 900;
}
.popups.apply .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;
  text-align: center;
  font-size: 1rem;
  overflow: hidden;
  background: #fff;
  color: #666;
}
.popups.apply ol {
  margin: 0 0.6rem 0 0.9rem;
  padding: 0;
}
.popups.apply ol li {
  list-style-type: decimal;
  text-align: left;
  color: #666;
  font-size: 0.6rem;
  margin: 0.3rem 0;
}
.popups.apply span {
  color: #fe0101;
}
</style>