<template>
  <div v-title data-title="我的点评">
    <div class="message">
      <mt-header fixed title="我的点评">
        <a slot="left">
          <mt-button icon="back" @click="$router.go(-1)"></mt-button>
        </a>
      </mt-header>
    </div>

    <div class="page-content">
      <div class="container container-wh" v-if="commentList != '' ">
        <mt-loadmore
          :bottom-method="loadBottom"
          :bottom-all-loaded="allLoaded"
          ref="loadmore"
          :auto-fill="false"
        >
          <div class="note-list">
            <div class="note" v-for="(comment,index) in commentList" :key="comment.id">
              <div class="note-info">
                <div class="avatar">
                  <router-link
                    :to="{name:'MyIndex', params: { uid: comment.user_id }}"
                    v-if="comment.user_icon == '' "
                  >
                    <img src="../../assets/img/apply_avatar_bg.png" />
                  </router-link>
                  <router-link
                    :to="{name:'MyIndex', params: { uid: comment.user_id }}"
                    v-if="comment.user_icon != '' "
                  >
                    <img :src="comment.user_icon" />
                  </router-link>
                </div>
                <div class="name" style="margin-bottom: 0">
                  <span>
                    <router-link
                      :to="{name:'MyIndex', params: { uid: comment.user_id }}"
                    >{{comment.user_nick}}</router-link>
                  </span>
                  <div
                    class="btn_follow unfollow"
                    @click="contribute(comment.id,index)"
                    v-if="comment.is_accept != 1"
                  >+ 投稿</div>
                  <div class="btn_follow unfollow" v-else>已投稿</div>
                </div>
                <div class="discuss-star">
                  <p class="p-star" v-if="comment.discuss_star == '1'">
                    <i></i>
                  </p>
                  <p class="p-star" v-else-if="comment.discuss_star == '2'">
                    <i></i>
                    <i></i>
                  </p>
                  <p class="p-star" v-else-if="comment.discuss_star == '3'">
                    <i></i>
                    <i></i>
                    <i></i>
                  </p>
                  <p class="p-star" v-else-if="comment.discuss_star == '4'">
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                  </p>
                  <p class="p-star" v-else-if="comment.discuss_star == '5'">
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                  </p>
                </div>
                <div class="datetime">
                  <span>{{comment.discuss_time}}</span>
                </div>
              </div>
              <div class="content">
                <!--<router-link to="">-->
                <div class="txt">{{comment.discuss_content}}</div>
                <!--</router-link>-->
                <div class="audio" v-if="comment.media_path != '' ">
                  <audio>
                    <source :src="comment.media_path" type="audio/mpeg" />
                  </audio>
                  <i class="icon-play"></i>
                  <span class="fr text-5">8</span>
                </div>
                <div v-if="comment.img != '' ">
                  <div class="images img-box" :class="getRowType(comment.img.length)">
                    <div
                      class="item"
                      v-for="(commentimg,commentindex) in filterBy(comment.img)"
                      :key="commentindex"
                    >
                      <p v-imgBg="commentimg.resource_img"></p>
                    </div>
                  </div>
                </div>
                <div class="note-park-name">
                  ——{{comment.park_name}}
                  <!--<a class="spot-name" href="#"># {{comment.spot_name}}</a>-->
                </div>
                <!--<div class="tool-list">-->
                <!--<div class="tool like">{{comment.like}}</div>-->
                <!--<div class="tool ">-->
                <!--<div class="icon unlike"></div>-->
                <!--{{comment.tread}}-->
                <!--</div>-->
                <!--</div>-->
              </div>
            </div>
          </div>
        </mt-loadmore>
      </div>
      <div v-else class="foot_noinfo">
        <img src="/static/img/foot_noinfo@2x.14391c2.png" alt />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.note-list {
  background-color: #fff;
}

.note-list .page-content {
  padding-bottom: 0;
}

.note-list .note {
  padding: 0 1.15rem 1.5rem;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 1.3rem;
  overflow: hidden;
}

.note-list .note-info {
  position: relative;
  display: inline-block;
  width: 100%;
}

.note-list .note-info a {
  display: initial;
  color: #ff9a80;
}

.note-list .note-info .avatar {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  margin-top: 0.2rem;
  border-radius: 50%;
  overflow: hidden;
  float: left;
}

.note-list .note-info .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.note-list .note-info .name {
  display: inline-block;
  font-size: 0.8rem;
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
  color: #00cbbd;
}

.note-list .note-info .discuss-star .p-star {
  display: inline-block;
}

.note-list .note-info .discuss-star .p-star i {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url(../../assets/img/icon-star.png) no-repeat;
  background-position: 66.6667% 0;
  background-size: 400%;
  margin: 0 0.2rem;
}

.note-list .note-info .datetime {
  padding-left: 4rem;
  font-size: 0.8rem;
  color: #999999;
}

.note-list .note-info .datetime span {
  float: left;
  margin-right: 1rem;
  margin-bottom: 0.3rem;
}

.note-list .btn_follow {
  position: absolute;
  right: 0;
  top: 0.2rem;
  width: 3rem;
  height: 1.2rem;
  line-height: 1.2rem;
  border: 1px solid #999;
  border-radius: 0.25rem;
  color: #ff9a80;
  text-align: center;
  font-size: 0.6rem;
}

.note-list .content a {
  display: block;
}

.note-list .content .txt {
  font-size: 0.6rem;
  line-height: 1.6rem;
  color: #333333;
  margin-top: 0.8rem;
  margin-bottom: 1.2rem;
  margin-left: 20px;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.note-list .content .audio {
  height: 1.5rem;
  width: 5rem;
  border: 1px solid #e5e5e5;
  border-radius: 1.5rem;
  margin-bottom: 0.6rem;
}

.note-list .content .audio i {
  display: block;
  float: left;
  height: 1.3rem;
  width: 1.3rem;
  margin: 0.1rem 0.2rem;
  border-radius: 0.9rem;
  background-image: url(../../assets/img/icon-play.png);
  background-size: cover;
}

.note-list .content .audio span {
  line-height: 1.5rem;
  padding-right: 0.5rem;
  font-size: 0.5rem;
}

.note-list .content .images {
  width: 100%;
  margin-left: -0.3rem;
  display: inline-block;
}

.note-list .content .images .item p {
  padding-top: 100%;
  position: relative;
  display: block;
  background-size: cover;
  border-radius: 0.2rem;
  background-position: center;
}

.note-list .content .note-park-name,
.note-park-name a {
  font-size: 0.6rem;
  color: #666666;
  margin: 0.5rem 1rem 0;
  width: 100%;
  text-align: left;
}

.note-list a.spot-name {
  color: #ff9a80;
  margin-top: 0.2rem;
}

.note-list .content .tool-list {
  float: right;
  width: 4.5rem;
  height: 1.5rem;
  margin-top: 0.5rem;
}

.note-list .content .tool-list .tool {
  display: inline-block;
  color: #666;
  font-size: 0.6rem;
  margin-right: 0.5rem;
}

.note-list .content .tool-list .like {
  background: url(../../assets/img/home_tool_btn_like.png) no-repeat;
  background-position: 0 50%;
  background-size: 1rem;
  padding-left: 1.5rem;
}

.note-list .content .tool-list .unlike {
  position: relative;
  top: 0.2rem;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url(../../assets/img/home_tool_btn_like.png) no-repeat;
  background-position: 0 70%;
  background-size: 1rem;
  -moz-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  transform: scaleY(-1);
}
</style>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      commentList: [],
      user_id: this.get_User_Id(),
      page: 1,
      allLoaded: false, //是否加载
      totalCount: "" //初始化方法中的数量总数
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    filterBy(ls) {
      return ls.filter((item, i) => {
        return i < 9;
      });
    },
    unload() {
      this.getComment(this.page);
    },
    // 得到我的点评
    getComment(page) {
      var that = this;
      var data = {
        user_id: this.user_id,
        page: page
      };

      this.$ajax("post", "User/getDiscussSpot_new", data, function(res) {
        if (res.data.id == 0) {
          that.commentList = that.commentList.concat(res.data.data);
          that.totalCount = res.data.data.length;
        }
      });
    },
    contribute(discuss_id, index) {
      this.$ajax(
        "post",
        "Play/submission",
        { user_id: this.user_id, discuss_id: discuss_id },
        res => {
          if (res.data.id == 0) {
            this.$set(this.commentList[index], "is_accept", 1);
            Toast(res.data.msg);
          }
        }
      );
    },
    // 播放音频
    //            playAudio(){
    //                var i = 0;
    //                var arr_audio = $(".note-list").find("audio");
    //                for (i = 0; i < arr_audio.length; i++) {
    //                    arr_audio[i].pause();
    //                }
    //                // $(".discuss-list").find("audio").each(function(i){
    //                //  console.log($(this))
    //                //  alert($(this)[i]);
    //                //  $(this)[i].pause();
    //                //  ++i;
    //                // })
    //                $(this).children("audio")[0].play();
    //            },

    //  下拉加载页面
    loadBottom() {
      setTimeout(() => {
        if (this.totalCount % 10 == 0) {
          this.page = this.page + 1;
          this.getComment(this.page);
        } else {
          this.allLoaded = true; // 若数据已全部获取完毕
          Toast("没有更多了");
        }
        this.$refs.loadmore.onBottomLoaded();
      }, 500);
    },
    getRowType(value) {
      var rowType = "";
      if (value == 1) {
        rowType = "row1";
      } else if (value == 2 || value == 4) {
        rowType = "row2";
      } else if (value == 3 || value >= 5) {
        rowType = "row3";
      }
      return rowType;
    }
  }
};
</script>