<template>
  <div class="MakerMap" v-title :data-title="title">
    <mt-header fixed :title="title">
      <a slot="left">
        <mt-button @click.native="$router.go(-1)"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div id="container" style="width:100%; height:100%"></div>
    </div>
  </div>
</template>
<script>
var map, Marker;
import landmark from "../../assets/img/landmark.png";
export default {
  data() {
    return {
      lng: this.$route.query.lng,
      lat: this.$route.query.lat,
      radius: this.$route.query.radius,
      title: this.$route.query.title
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      //初始化地图对象，加载地图
      map = new AMap.Map("container", {
        resizeEnable: true,
        center: [this.lng, this.lat], //地图中心点
        zoom: 11 //地图显示的缩放级别
      });
      map.plugin(["AMap.ToolBar"], function() {
        map.addControl(new AMap.ToolBar());
      });

      this.$ajax(
        "post",
        "foot/get_round_spots",
        {
          lng: this.lng,
          lat: this.lat,
          radius: this.radius
        },
        res => {
          if (res.data.id == 0) {
            var data = res.data.data;
            for (var i = 0; i < data.length; i++) {
              //添加点标记，并使用自己的icon
              Marker = new AMap.Marker({
                map: map,
                position: [data[i].lng, data[i].lat],
                extData: data[i],
                icon: new AMap.Icon({
                  size: new AMap.Size(30, 40), //图标大小
                  image: landmark
                })
              });
              Marker.setLabel({
                //label默认蓝框白底左上角显示，样式className为：amap-marker-label
                content: i + 1
              });
            }
          }
        }
      );
    }
  }
};
</script>
<style scoped>
.MakerMap,
.MakerMap #container,
.MakerMap .page-content {
  width: 100%;
  height: 100%;
  padding: 0;
}
.MakerMap .amap-marker-label {
  border: 0;
  background: transparent;
  margin: 3px;
}
</style>