
<template>
  <div class="ParkMapLineList ParkPhoto map" v-if="info" v-title :data-title="park_name+'详情'">
    <!--给微信分享用的图片-->
    <div class="container_box" :class="{active:is_big}">
      <div id="container" style="width:100%; height:100%"></div>

      <!--左上角菜单-->
      <div class="left-up-menu" v-if="left_up_menu_list && is_show_float_menu">
        <div class="lumt-title" @click="select_left_up_menu">
          <span>{{left_up_menu_title}}</span>
        </div>
        <transition name="menu_transition">
          <div class="luml-list" v-if="is_select_left_up_menu">
            <div
              class="luml-item"
              v-for="(item,i) in left_up_menu_list"
              :key="i"
              @click="left_up_menu_click(item.id)"
            >
              <div class="luml-item-title">
                <span>{{item.title}}</span>
              </div>
              <div class="luml-item-icon">
                <img :src="item.icon" />
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!--左下角菜单-->
      <div class="left-bottom-menu" v-if="left_bottom_menu_list && is_show_float_menu">
        <div class="lbm-title" @click="select_left_bottom_menu">
          <span>{{left_bottom_menu_title}}</span>
        </div>
        <transition name="menu_transition">
          <div class="lbm-list" v-if="left_bottom_menu_list && is_select_left_bottom_menu">
            <div
              class="lbml-item"
              v-for="(item, i) in left_bottom_menu_list"
              :key="i"
              @click="left_bottom_menu_click(item.id)"
            >
              <div class="lbml-item-icon">
                <img :src="item.icon" />
              </div>
              <div class="lbml-item-title">
                <span>{{item.title}}</span>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!--右下角菜单-->
      <div class="right-bottom-menu" v-if="right_bottom_menu_list && is_show_float_menu">
        <div class="rbm-title" @click="select_right_bottom_menu">
          <span>{{right_bottom_menu_title}}</span>
        </div>
        <transition name="menu_transition">
          <div class="rbm-list" v-if="right_bottom_menu_list && is_select_right_bottom_menu">
            <div
              class="rbml-item"
              v-for="(item, i) in right_bottom_menu_list"
              :key="i"
              @click="right_bottom_menu_click(item.id)"
            >
              <div class="rbml-item-icon">
                <img :src="item.icon" />
              </div>
              <div class="rbml-item-title">
                <span>{{item.title}}</span>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <transition name="menu_transition">
        <div class="menu-list" v-if="is_select_left_up_menu">
          <div class="ml-item" @click="del">
            <div class="ml-item-text">
              <span>收起</span>
            </div>
            <div class="ml-item-del">
              <img src="../../assets/img/del.png" />
            </div>
          </div>
          <div
            class="ml-item"
            v-for="(item, i) in left_up_menu_list"
            :key="i"
            @click="left_up_menu_click(item.id)"
          >
            <div class="ml-item-icon">
              <img :src="item.icon" />
            </div>
            <div class="ml-item-title">
              <span>{{item.title}}</span>
            </div>
          </div>
        </div>
      </transition>
      <transition name="menu_transition">
        <div class="menu-list" v-if="is_select_left_bottom_menu">
          <div class="ml-item" @click="del">
            <div class="ml-item-text">
              <span>收起</span>
            </div>
            <div class="ml-item-del">
              <img src="../../assets/img/del.png" />
            </div>
          </div>
          <div
            class="ml-item"
            v-for="(item, i) in left_bottom_menu_list"
            :key="i"
            @click="left_bottom_menu_click(item.id)"
          >
            <div class="ml-item-icon">
              <img :src="item.icon" />
            </div>
            <div class="ml-item-title">
              <span>{{item.title}}</span>
            </div>
          </div>
        </div>
      </transition>
      <transition name="menu_transition">
        <div class="menu-list" v-if="is_select_right_bottom_menu">
          <div class="ml-item" @click="del">
            <div class="ml-item-text">
              <span>收起</span>
            </div>
            <div class="ml-item-del">
              <img src="../../assets/img/del.png" />
            </div>
          </div>
          <div
            class="ml-item"
            v-for="(item, i) in right_bottom_menu_list"
            :key="i"
            @click="right_bottom_menu_click(item.id)"
          >
            <div class="ml-item-icon">
              <img :src="item.icon" />
            </div>
            <div class="ml-item-title">
              <span>{{item.title}}</span>
            </div>
          </div>
        </div>
      </transition>
      <div class="l-footer">
        <div class="lf-item border_right" @click="select_left_up_menu">
          <div class="lf-item-img">
            <img src="../../assets/img/label_1209_wu_xiang.png" />
          </div>
          <div class="lf-item-text">
            <span>五大之乡</span>
          </div>
        </div>
        <div class="lf-item border_right" @click="select_left_bottom_menu">
          <div class="lf-item-img">
            <img src="../../assets/img/label_1209_te_chan.png" />
          </div>
          <div class="lf-item-text">
            <span>特色产业</span>
          </div>
        </div>
        <div class="lf-item" @click="select_right_bottom_menu">
          <div class="lf-item-img">
            <img src="../../assets/img/label_1209_pin_pai.png" />
          </div>
          <div class="lf-item-text">
            <span>区域品牌</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";

export default {
  components: {},
  data() {
    return {
      map: {},

      id: this.$route.query.id,
      park_id: this.$route.query.park_id ? this.$route.query.park_id : 1,
      info: {},

      Marker: [], //所有点
      page: 1,
      is_big: true, //地图放大缩小
      zpark_logo: this.$route.query.park_logo, //给微信分享的图片
      park_name: this.$route.query.park_name ? this.$route.query.park_name : "", //给微信分享的文字描述
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      wx_url:
        "http://www.zmlxj.com/api.php/weixin/wx_share?type=Label&park_id=" +
        this.$route.query.park_id +
        "&id=" +
        this.$route.query.id +
        "&park_name=" +
        this.$route.query.park_name +
        "&park_logo=" +
        this.$route.query.park_logo,

      is_show_float_menu: 0,
      l_index: 0,
      r_index: 0,
      l_lng: 0,
      l_lat: 0,
      select_type: 0,
      is_audio_select: 0,
      is_map_setFitView: 0,
      left_up_menu_title: "",
      left_up_menu_list: [],
      left_bottom_menu_title: "",
      left_bottom_menu_list: [],
      right_bottom_menu_title: "",
      right_bottom_menu_list: [],
      is_select_left_bottom_menu: false,
      is_select_right_bottom_menu: false,
      is_select_left_up_menu: false
    };
  },
  mounted() {
    this.unload();
    if (this.is_weixin) {
      this.wx_share(this.wx_url);
    }
    document.title = this.park_name;

    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    if (this.id == 1209) {
      link.href = "https://m.zmlxj.com/favicon.ico";
    } else {
      link.href = "https://m.zmlxj.com/favicon.ico";
    }

    document.getElementsByTagName("head")[0].appendChild(link);
  },
  created() {},
  methods: {
    unload() {
      this.get_data();
    },

    // 获取数据  渲染地图上的mak点
    get_data() {
      var that = this;
      localStorage.setItem("wx_title", this.park_name);
      localStorage.setItem("wx_content", this.park_name + "地图详情");
      localStorage.setItem("wx_img", this.park_logo);
      this.url = "SpotsRace/get_map_data_by_label";
      this.$ajax(
        "post",
        this.url,
        {
          park_id: this.park_id,
          id: this.id,
          page: 1,
          where: "web"
        },
        ret => {
          if (ret.data.id == 0) {
            this.info = ret.data.data;
            this.map = this.map_init(this.info.map_data);
            this.point_list = ret.data.data.point_list;
            this.l_index = ret.data.data.map_data.l_index;
            this.r_index = ret.data.data.map_data.r_index;
            this.l_lng = ret.data.data.map_data.left_lng;
            this.l_lat = ret.data.data.map_data.left_lat;
            this.left_up_menu_title = ret.data.data.left_up_menu_title;
            this.left_up_menu_list = ret.data.data.left_up_menu_list;
            this.left_bottom_menu_title = ret.data.data.left_bottom_menu_title;
            this.left_bottom_menu_list = ret.data.data.left_bottom_menu_list;
            this.right_bottom_menu_title =
              ret.data.data.right_bottom_menu_title;
            this.right_bottom_menu_list = ret.data.data.right_bottom_menu_list;
            this.is_show_float_menu = ret.data.data.is_show_float_menu;
            this.is_map_setFitView = ret.data.data.is_map_setFitView;
            if (ret.data.data.point_list.length > 0) {
              this.map_add_Marker({
                map: this.map,
                l_index: this.l_index,
                r_index: this.r_index,
                l_lng: this.l_lng,
                l_lat: this.l_lat,
                is_map_setFitView: this.is_map_setFitView,
                is_audio_select: this.is_audio_select,
                list_data: ret.data.data.point_list,
                type: 0,
                callback: function(Marker_arr) {
                  that.Marker = Marker_arr;
                }
              });
              if (this.select_type) {
                //说明是从投票页面进来的,需要调用一些下面的这个方法
                this.click_tab_label(this.select_type);
              }
            } else {
              Toast("暂无数据");
            }
          }
        }
      );
    },
    left_up_menu_click(id) {
      this.select_type = id;
      this.is_select_right_bottom_menu = false;
      this.is_select_left_up_menu = false;
      this.is_select_left_bottom_menu = false;

      this.click_tab_label(id);
    },
    left_bottom_menu_click(id) {
      this.select_type = id;
      this.is_select_right_bottom_menu = false;
      this.is_select_left_up_menu = false;
      this.is_select_left_bottom_menu = false;

      this.click_tab_label(id);
    },
    right_bottom_menu_click(id) {
      this.select_type = id;
      this.is_select_right_bottom_menu = false;
      this.is_select_left_up_menu = false;
      this.is_select_left_bottom_menu = false;

      this.click_tab_label(id);
    },
    click_tab_label(type) {
      var result = [],
        that = this;
      this.map.clearMap();

      if (type > 0) {
        for (var i = 0; i < this.point_list.length; i++) {
          if (this.point_list[i]["type_"] == type) {
            result.push(this.point_list[i]);
          }
        }
        if (result.length <= 0) {
          Toast("暂无数据");
          return;
        }
      }

      this.map_add_Marker({
        map: this.map,
        list_data: result,
        l_index: this.l_index,
        r_index: this.r_index,
        l_lng: this.l_lng,
        l_lat: this.l_lat,
        is_map_setFitView: this.is_map_setFitView,
        is_audio_select: this.is_audio_select,
        type: type,
        callback: function(Marker_arr) {
          that.Marker = Marker_arr;
        }
      });
    },
    click_is_big() {
      this.is_root_books = this.is_park_search = false;
      this.is_big = !this.is_big;
      this.map.clearInfoWindow();
    },
    select_left_up_menu() {
      this.is_select_left_up_menu = !this.is_select_left_up_menu;
      this.is_select_left_bottom_menu = false;
      this.is_select_right_bottom_menu = false;
    },
    select_left_bottom_menu() {
      this.is_select_left_bottom_menu = !this.is_select_left_bottom_menu;
      this.is_select_left_up_menu = false;
      this.is_select_right_bottom_menu = false;
    },
    select_right_bottom_menu() {
      this.is_select_right_bottom_menu = !this.is_select_right_bottom_menu;
      this.is_select_left_up_menu = false;
      this.is_select_left_bottom_menu = false;
    },
    del() {
      this.is_select_right_bottom_menu = false;
      this.is_select_left_up_menu = false;
      this.is_select_left_bottom_menu = false;
    }
  }
};
</script>
<style scoped>
.menu_transition-enter-active {
  animation: bounce-in 0.5s;
}
.menu_transition-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.l-footer {
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0rem;
  background-color: white;
}
.l-footer .lf-item {
  position: relative;
  width: 32%;
  float: left;
}
.l-footer .lf-item .lf-item-img {
  position: relative;
  width: 35%;
  float: left;
}
.l-footer .lf-item .lf-item-img img {
  width: 20px;
  height: 20px;
  padding: 10px 0px;
  margin-left: 70%;
}
.l-footer .lf-item .lf-item-text {
  position: relative;
  width: 60%;
  float: left;
}
.l-footer .lf-item .lf-item-text span {
  display: block;
  padding: 10px 0px 10px 0px;
  font-size: 0.8rem;
  text-align: center;
}
.border_right {
  border-right: 1px solid #9cd678;
}
.menu-list {
  position: absolute;
  bottom: 2rem;
  z-index: 2;
  width: 98%;
  margin-left: 1%;
}
.menu-list .ml-item {
  display: inline-block;
  position: relative;
  width: 100%;

  border-bottom: 1px solid #9cd678;
}
.menu-list .ml-item .ml-item-icon {
  position: relative;
  width: 10%;
  float: left;
}
.menu-list .ml-item .ml-item-icon img {
  width: 30px;
  height: 30px;
}
.menu-list .ml-item .ml-item-title {
  position: relative;
  width: 65%;
  float: left;
}
.menu-list .ml-item .ml-item-title span {
  display: block;
  padding: 5px 0px 5px 0px;
  font-size: 0.8rem;
}
.menu-list .ml-item .ml-item-text {
  position: relative;
  width: 50%;
  float: left;
}
.menu-list .ml-item .ml-item-text span {
  display: block;
  padding: 7px 0px 5px 8px;
  font-size: 0.8rem;
  color: #9cd678;
}
.menu-list .ml-item .ml-item-del {
  position: relative;
  width: 50%;
  float: left;
}
.menu-list .ml-item .ml-item-del img {
  width: 35px;
  height: 35px;
  float: right;
}

.right-bottom-menu {
  position: absolute;
  z-index: 2;
  top: 48%;
  background: rgba(222, 234, 172, 0.7);
  width: 25%;
  border-radius: 10px;
  right: 1%;
}
.right-bottom-menu .rbm-title {
  display: inline-block;
  position: relative;
  width: 100%;
}
.right-bottom-menu .rbm-title span {
  display: block;
  padding: 2px;
  font-size: 0.8rem;
  color: #5c8037;
}
.right-bottom-menu .rbm-list {
  display: inline-block;
  position: relative;
  width: 100%;
}
.right-bottom-menu .rbm-list .rbml-item {
  display: inline-block;
  position: relative;
  width: 100%;
}
.right-bottom-menu .rbm-list .rbml-item .rbml-item-icon {
  position: relative;
  width: 15%;
  float: left;
}
.right-bottom-menu .rbm-list .rbml-item .rbml-item-icon img {
  width: 28px;
  height: 28px;
}
.right-bottom-menu .rbm-list .rbml-item .rbml-item-title {
  display: inline-block;
  position: relative;
  width: 85%;
}
.right-bottom-menu .rbm-list .rbml-item .rbml-item-title span {
  display: block;
  padding: 10px;
  font-size: 0.5rem;
  font-weight: bold;
}

.left-bottom-menu {
  position: absolute;
  z-index: 2;
  top: 48%;
  background: rgba(222, 234, 172, 0.7);
  width: 35%;
  border-radius: 10px;
}
.left-bottom-menu .lbm-title {
  display: inline-block;
  position: relative;
  width: 100%;
}
.left-bottom-menu .lbm-title span {
  display: block;
  padding: 2px;
  font-size: 0.8rem;
  color: #5c8037;
}
.left-bottom-menu .lbm-list {
  display: inline-block;
  position: relative;
  width: 100%;
}
.left-bottom-menu .lbm-list .lbml-item {
  display: inline-block;
  position: relative;
  width: 100%;
}
.left-bottom-menu .lbm-list .lbml-item .lbml-item-icon {
  position: relative;
  width: 15%;
  float: left;
}
.left-bottom-menu .lbm-list .lbml-item .lbml-item-icon img {
  width: 28px;
  height: 28px;
}
.left-bottom-menu .lbm-list .lbml-item .lbml-item-title {
  position: relative;
  width: 85%;
  float: left;
}
.left-bottom-menu .lbm-list .lbml-item .lbml-item-title span {
  display: block;
  padding: 10px;
  font-size: 0.5rem;
  font-weight: bold;
}

.left-up-menu {
  position: absolute;
  z-index: 2;
  float: left;
  top: 10%;
  background: rgba(193, 225, 156, 0.7);
  width: 25%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.left-up-menu .lumt-title {
  display: inline-block;
  position: relative;
  width: 100%;
}
.left-up-menu .lumt-title span {
  display: block;
  padding: 5px;
  font-size: 0.7rem;
  text-align: left;
  color: #5c8037;
}
.left-up-menu .luml-list {
  display: inline-block;
  position: relative;
  width: 100%;
}
.left-up-menu .luml-list .luml-item {
  display: inline-block;
  position: relative;
  width: 100%;
}
.left-up-menu .luml-list .luml-item .luml-item-title {
  position: relative;
  width: 80%;
  float: left;
}
.left-up-menu .luml-list .luml-item .luml-item-title span {
  display: block;
  padding: 6px;
  font-size: 0.5rem;
  text-align: left;
  font-weight: bold;
}
.left-up-menu .luml-list .luml-item .luml-item-icon {
  position: relative;
  width: 20%;
  float: left;
  height: 28px;
}
.left-up-menu .luml-list .luml-item .luml-item-icon img {
  width: 28px;
  height: 28px;
}

.ParkPhoto.map .container_box .map_info {
  width: 100%;
  position: inherit;
  background: none;
  padding: 0.5rem;
}

.ParkPhoto.map .amap-marker-content > div.user_bg {
  background: url(../../assets/img/user_touxoiang.png) 0 0 no-repeat;
  background-size: contain;
  width: 2.1rem;
  height: 2.2rem;
  padding: 0.15rem 0.1rem 0;
  box-sizing: border-box;
}

.hide {
  display: none;
}

.ParkPhoto .amap-touch-toolbar .amap-zoomcontrol {
  width: 2rem;
}

.amap-zoom-touch-minus,
.amap-zoom-touch-plus {
  height: 2rem;
}

.amap-zoom-touch-minus > div,
.amap-zoom-touch-plus > div {
  line-height: 2rem;
}

.amap-touch-toolbar .amap-zoomcontrol {
  left: 10px;
  bottom: 10px;
}

.amap-toolbar {
  bottom: 0 !important;
}

.ParkMapLineList-content {
  padding: 0.4rem 10px 0;
}
</style>
