<template>
  <div class="ParkLineFoot ParkPhoto map">
    <div class="container_box" :class="{active:is_big}">
      <div id="container" style="width:100%; height:100%"></div>
      <i
        class="container_quanping iconfont"
        :class="{'icon-quitquanping':is_big,'icon-quanping':!is_big}"
        @click="is_big=!is_big"
      ></i>
      <!--活跃用户-->
      <div class="active_user_box ParkPhotoListDetail">
        <div class="who_vote">
          <div class="row">
            <div class="list">
              <router-link
                :to="{name:'otherIndex',params:{uid:k.user_id}}"
                class="num_box"
                v-for="(k,i) in info.user_list"
                :key="k.user_id"
              >
                <img :src="k.user_icon" />
                <div class="num">
                  <i class="iconfont icon-zuji"></i>
                  {{k.spots_numbers}}
                </div>
                <span v-if="i<3">NO.{{i + 1}}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="left_but" v-if="is_big">
        <div class="mak_tab">
          <!--点/图片 切换-->
          <img
            src="../../assets/img/map_park_tab_img.png"
            alt
            @click="click_mak_type"
            v-if="is_tab_button"
          />
          <img
            src="../../assets/img/map_park_tab_img_maks.png"
            alt
            @click="click_mak_type"
            v-if="!is_tab_button"
          />
          <img
            src="../../assets/img/map_3d.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="is_tab_map_3d"
          />
          <img
            src="../../assets/img/map_3d_yes.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="!is_tab_map_3d"
          />
        </div>
        <div class="mak_tab">
          <!--地图 + -  -->
          <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
          <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
        </div>
      </div>
    </div>
    <!--足迹列表-->
    <footprints-list ref="child"></footprints-list>
  </div>
</template>
<script>
import FootprintsList from "../../components/FootprintsList";
export default {
  components: {
    FootprintsList
  },
  data() {
    return {
      map: {},
      aid: this.$route.query.aid,
      park_id: this.$route.query.park_id,
      type: this.$route.query.type,
      info: {},
      page: 1,
      marker_list: [], //所有点
      is_big: false, //地图放大缩小
      is_tab_map_3d: true, //3D地图按钮是否点击
      is_tab_button: true //图片切换是否点击
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      if (this.check_login()) {
        this.my_id = this.check_login().user_id;
      }
      //初始化地图对象，加载地图
      this.map = this.map_init();
      this.get_data();
    },
    get_data() {
      this.$ajax(
        "post",
        "SpotsRace/get_one_label_spots_list",
        {
          park_id: this.park_id,
          aid: this.aid,
          type: this.type
        },
        ret => {
          if (ret.data.id == 0) {
            this.info = ret.data.data;
            if (ret.data.data.spots_point_list.length > 0) {
              //                            this.map_add_Marker(this.map, ret.data.data.spots_point_list, 'park', (Marker_arr) => {
              //                                this.marker_list = Marker_arr
              //                            })

              this.map_add_Marker({
                map: this.map,
                list_data: ret.data.data.spots_point_list,
                type: "park",
                callback: Marker_arr => {
                  this.marker_list = Marker_arr;
                }
              });
            }

            this.$refs.child.parentMsg({
              info: this.info.spots_list,
              isred: false,
              my_id: this.my_id,
              tit: true,
              parklink: true
            });
          }
        }
      );
    },
    click_mak_type() {
      this.is_tab_button = !this.is_tab_button;
      this.map_cut_Marker(this.map, this.marker_list, this.is_tab_button);
    }
  }
};
</script>
<style scoped>
.ParkPhoto .container_box.active {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
</style>