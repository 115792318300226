<template>
  <!-- 新增 pixiIndex -->
  <div class="pixiIndex dylan">
    <!-- loading -->
    <transition name="load">
      <div class="dylan load-container" v-if="loading">
        <div class="load-progress" v-show="show_loading">
          <!-- <span class="load-text">loading...</span> -->
          <img class="load-text" :src="loading_text" alt />
          <div class="progress-wrapper">
            <div class="progress-img-pre progress-img">
              <img class="img-pre" :src="loading_img_0" alt />
              <div
                class="progress-img-next progress-img"
                :style="'height:' + load_progress * 0.77 + '%'"
              >
                <img class="img-next" :src="loading_img" alt />
              </div>
            </div>
            <div class="progress-inner" :style="'width:' + load_progress + '%'"></div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 启动页 -->
    <div class="pixi-start" v-show="show_start">
      <!-- 背景图 -->
      <div class="start-bg">
        <img :src="start_bg" alt />
      </div>
      <!-- 底部动画 -->
      <div class="start-1741" v-if="id == 1741">
        <img v-for="(item,i) in flower_list" :key="i" :src="item" :class="'flower-'+i" alt />
      </div>
      <div class="start-btn" v-if="!loading">
        <button @click="show_start=false">点击进入</button>
      </div>
    </div>
    <!-- 地图页 -->
    <div class="pixi-map" v-show="!show_start">
      <!-- pixi map -->
      <div class="pi-body" id="pixi_" ref="pixi_"></div>

      <!-- 顶部导航 -->
      <transition name="top-fade">
        <div class="pixi-top">
          <ul class="top-menu">
            <li
              class="top-menu-li top-brief"
              @click="show_spot_brief = !show_spot_brief;show_point_brief = false"
            >
              <i class="iconfont iconShape"></i>
              <p>简介</p>
            </li>
          </ul>
          <div
            class="top-menu-li top-menu top-music"
            @click="playBgMusic"
            v-if="map_data.bgAudioUrl"
          >
            <i class="iconfont iconswitch_open" v-if="bgmusic_play"></i>
            <i class="iconfont iconswitch_close" v-else></i>
            <p>音乐</p>
          </div>
        </div>
      </transition>
      <!-- 景区简介 -->
      <transition name="spot-brief">
        <div class="pixi-spot-brief" v-show="show_spot_brief">
          <div class="spot-brief-wrapper">
            <div class="brief-close" @click="show_spot_brief = false">
              <i class="iconfont iconbaseline-close-px"></i>
            </div>
            <div class="spot-brief-icon">
              <img :src="map_data.logo_" alt />
            </div>
            <div class="spot-brief-header">
              <h2 class="spot-brief-name">{{map_data.label_name}}</h2>
            </div>
            <div class="spot-brief-info">
              <div class="spot-brief-address spot-brief-item">
                <p class="item-name">
                  <i class="iconfont icondizhi2"></i>
                  <span>地址：</span>
                </p>
                <p class="item-value">{{map_data.address}}</p>
              </div>
              <div class="spot-brief-time spot-brief-item" v-if="map_data.time">
                <p class="item-name">
                  <i class="iconfont iconshijian"></i>
                  <span>开放时间：</span>
                </p>
                <p class="item-value">{{map_data.time}}</p>
              </div>
            </div>
            <div class="spot-brief-content">
              <article v-html="map_data.content"></article>
            </div>
          </div>
        </div>
      </transition>

      <!-- 底部导航 -->
      <div class="pixi-footer" :class="show_menu ? 'pixi-footer-active' : ''">
        <!-- 目录 -->
        <div class="footer-menu">
          <ul class="footer-menu-list">
            <li
              class="menu-item"
              v-for="(item, i) in menu_list"
              :key="i"
              @click="menuClickHandle(item.id)"
              :class="first_menu_type == item.id ? 'menu-item-active' : ''"
            >
              <img :src="choseIconByType(item.id)" alt />
              <span>{{item.title}}</span>
            </li>
          </ul>
          <div class="footer-btn" @click="menuBtnHandle">
            <i class="iconfont iconUp"></i>
          </div>
        </div>
        <!-- 底部导航 -->
        <div class="footer-nav" v-show="show_menu">
          <!-- 搜索 -->
          <div
            class="footer-search"
            v-show="first_menu_type != 15 && first_menu_type != 16 && first_menu_type != 17"
          >
            <div class="search-wrapper">
              <div class="search-input">
                <input type="text" placeholder="输入景点名称" v-model="search_value" />
              </div>
              <div class="search-icon">
                <i class="iconfont iconsousuo"></i>
              </div>
            </div>
          </div>
          <!-- 点位列表 -->
          <div class="nav-wrapper">
            <ul class="footer-nav-list">
              <li
                class="nav-item"
                v-for="(item,i) in filterPointListByType(point_list)"
                :key="i"
                @click="menuLocation(item.id)"
              >
                <img :src="point_type_icon" alt />
                {{item.spots_name}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 点位简介 -->
      <transition name="brief-fade">
        <div class="point-brief" v-show="show_point_brief">
          <div class="brief-wrapper">
            <div class="brief-close" @click="show_point_brief = false">
              <i class="iconfont iconbaseline-close-px"></i>
            </div>
            <div class="brief-header">
              <div class="brief-icon">
                <div class="audio-btn" v-show="point_item.zhAudioUrl">
                  <i
                    class="audio-btn-play iconfont iconbofang1"
                    v-if="point_item.is_play != 0"
                    @click="playAudio"
                  ></i>
                  <i class="audio-btn-play iconfont iconbofang" v-else @click="playAudio"></i>
                </div>
                <img :src="point_item.logo_" alt />
              </div>
              <div class="brief-title" @click="jumpToDetail">
                <div
                  class="brief-title-tip"
                  v-if="point_item.type_ != 17"
                ></div>
                <h2>{{point_item.spots_name}}</h2>
              </div>
              <div class="brief-nav" @click="jumpPos">
                <i class="iconfont icondaohang1"></i>
                <span>导航</span>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <!-- 音频播放 -->
      <audio :src="map_data.bgAudioUrl" autoplay="autoplay" ref="audio"></audio>
      <!-- 播放界面 -->
      <transition name="audio-fade">
        <div class="audio-play" v-show="show_audio">
          <span class="audio-play-icon">
            <img src="../../assets/switch/bgmusic.png" alt />
          </span>
          <span class="audio-play-name">{{audio_name || title}}</span>
          <span class="audio-play-close" @click="closeAudio">
            <i class="iconfont iconbaseline-close-px"></i>

          </span>
        </div>
      </transition>

      <!-- 反馈 -->
      <technical-support :ts_type_="ts_type_" contact_side="feedback" :id="id"></technical-support>
    </div>
  </div>

  <!-- pixiIndex结束标签 -->
</template>

<script>
import * as PIXI from 'pixi.js'
import { Viewport } from 'pixi-viewport'
import { GlowFilter } from '@pixi/filter-glow' // shadow
import { TweenMax } from 'gsap'
import { Toast } from 'mint-ui'
import TechnicalSupport from '../../components/TechnicalSupport'
export default {
  components: {
    'technical-support': TechnicalSupport
  },
  data () {
    return {
      show_loading: false, // 显示加载动画
      loading_img_0: '',
      loading_img: '',
      loading_text: '',
      loading: false, // 显示加载动画
      map_bg: '', // pixi地图
      start_bg: '', // 引导页背景
      show_start: false, // 显示引导页
      pre_load_list: [], // 加载图片列表
      img_count: 0, // 加载完成的图片数量
      load_progress: 0, // 加载进度
      backgroundColor: '', // pixi map背景颜色
      flower_list: [], // 花的数组
      // 20190924
      show_spot_brief: false, // 地图简介
      show_point_brief: false, // 点位简介
      show_menu: false, // 显示目录列表
      first_menu_type: 19, // 初始目录类型
      search_value: '', // 搜索值
      point_id: [0, 0], // 当前点位和前一个点位的id
      point_item: [], //
      // 点位精灵
      pre_sprite: '', // 上一个点位精灵
      /* 音频 */

      audio_dom: '',
      audio_name: '', // 正在播放的音频名称
      audio_play: false,
      bgmusic_play: true,
      // icon
      icon_object: {
        iconMap15: require('../../assets/pixi/map_icon_15.png'),
        iconMap16: require('../../assets/pixi/map_icon_16.png'),
        iconMap17: require('../../assets/pixi/map_icon_17.png'),
        iconMap18: require('../../assets/pixi/map_icon_18.png'),
        iconMapAudio: require('../../assets/pixi/map_icon_audio.png'),
        iconMap19: require('../../assets/pixi/map_icon_19.png'),
        iconMap20: require('../../assets/pixi/map_icon_20.png'),
        iconMap21: require('../../assets/pixi/map_icon_21.png'),
        iconMapNormal: require('../../assets/pixi/map_icon_normal.png'),
        // icon
        icon_15: require('../../assets/pixi/icon_15.png'),
        icon_16: require('../../assets/pixi/icon_16.png'),
        icon_17: require('../../assets/pixi/icon_17.png'),
        icon_18: require('../../assets/pixi/icon_18.png'),
        icon_19: require('../../assets/pixi/icon_19.png'),
        icon_20: require('../../assets/pixi/icon_20.png'),
        icon_21: require('../../assets/pixi/icon_21.png'),

        // 当前icon
        icon_act_15: require('../../assets/pixi/icon_act_15.png'),
        icon_act_16: require('../../assets/pixi/icon_act_16.png'),
        icon_act_17: require('../../assets/pixi/icon_act_17.png'),
        icon_act_18: require('../../assets/pixi/icon_act_18.png'),
        icon_act_19: require('../../assets/pixi/icon_act_19.png'),
        icon_act_20: require('../../assets/pixi/icon_act_20.png'),
        icon_act_21: require('../../assets/pixi/icon_act_21.png')
      },
      // 地图定位icon

      // 初始数据

      ts_type_: 'label',
      park_id: 298,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      id: this.$route.query.id ? this.$route.query.id : 0,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      union_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      is_pay: 0, // 是否支付
      price: 0, // 价格
      backUrl: 'pixiIndex',

      pixiApp: '',
      IMG_WIDTH: 4000, // 图片宽度
      IMG_HEIGHT: 4000, // 图片高度
      PRE_PERSENT: '', // 图片初始化比例
      WINDOW_WIDTH: '', // 设备宽度
      WINDOW_HEIGHT: '', // 设备高度
      viewport: {}, // 保存viewport实例

      point_list: [], // 点位数据
      map_data: '',
      is_have_spots_list: 0,
      is_show_spots_list: 0,
      title: '',
      menu_list: '', // 菜单
      mtitle: '景点',

      title_list: '', // 弹出的列表
      tel_: '', // 客服电话
      bgSprite: '', // 背景图片精灵

      /* 判断设备类型 */
      PC: '', // 是否是PC
      mobile: '', // 手机型号
      MAX_SCALE: 4,
      MIN_SCALE: 1,
      offsetWidth: '',
      offsetHeight: '',
      sprite_array: [],
      text_array: []
    }
  },
  computed: {
    point_type_icon: function () {
      return this.icon_object['iconMap' + this.first_menu_type]
    },
    show_audio: function () {
      if (this.audio_play) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    // 根据id获取点位对象
    point_id: function (val) {
      if (val[1] < 0) {
        this.point_item = []
      } else {
        this.point_item = this.point_list.filter((item, i) => {
          return item.id == val[1]
        })[0]
      }
    },
    // 加载进度
    load_progress: function (val) {
      if (Math.ceil(val) >= 100) {
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }
    }
  },

  mounted () {
    if (this.token) {
      // 已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }
    if (
      this.union_id &&
      !(
        !this.union_id &&
        typeof this.union_id !== 'undefined' &&
        this.union_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有union_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.union_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.union_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.union_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }
    this.initScale()
    this.load()
    // 获取audio元素
    this.$nextTick(() => {
      this.audio_dom = this.$refs.audio
      this.audio_dom.addEventListener('ended', () => {
        this.audio_play = false
        if (this.bgmusic_play) {
          this.bgmusic_play = false
        }
        this.checkIsPlay()
      })
      // console.log(this.audio_dom);
    })
  },

  methods: {
    // 跳转
    jumpToDetail () {
      if (
        this.point_item.type_ == 17
      ) {

      } else {
        this.$router.push({
          path: '/footprints/PrimaryScenic',
          query: {
            id_: this.id,
            sid: this.point_item.id
          }
        })
      }
    },
    // 停止播放
    closeAudio () {
      this.audio_dom.pause()
      this.audio_play = false
      this.checkIsPlay()
    },
    // 修改播放标记
    checkIsPlay () {
      this.point_list.forEach(item => {
        if (item.is_play != 0) {
          item.is_play = 0
        }
      })
    },
    // 播放背景音乐
    playBgMusic () {
      if (this.bgmusic_play) {
        this.bgmusic_play = false
        this.audio_dom.pause()
      } else {
        // console.log(this.audio_dom);
        this.checkIsPlay() // 关闭点位播放音频
        this.bgmusic_play = true
        this.audio_play = false // 背景音乐不需要播放界面
        this.audio_dom.src = this.map_data.bgAudioUrl
        this.audio_dom.play()
      }
    },
    // 音频
    playAudio () {
      if (this.is_weixin) {
        if (this.price == 0 || (this.price > 0 && this.is_pay == 1)) {
          if (this.point_item.zhAudioUrl) {
            if (this.point_item.is_play != 0) {
              this.point_item.is_play = 0
              this.audio_dom.pause()
            } else {
              this.checkIsPlay()
              this.bgmusic_play = false // 关闭背景音乐开关
              this.point_item.is_play = 1
              this.audio_play = true
              this.audio_name = this.point_item.spots_name
              this.audio_dom.src = this.point_item.zhAudioUrl
              this.audio_dom.play()
            }
          } else {
            Toast('音频播放错误')
          }
        } else {
          // 还未支付
          if (this.open_id) {
            // 进行支付
            var open_url =
              'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
              this.id +
              '&backUrl=' +
              this.backUrl +
              '&open_id=' +
              this.open_id +
              '&token=' +
              this.token +
              '&park_id=' +
              this.park_id +
              '&version=' +
              this.version +
              '&user_id='
            window.location.href = open_url
          } else {
            // 获取微信授权
            this.authorize()
          }
        }
      } else {
        Toast('请在微信中打开')
      }
    },
    // 根据目录的ID值选择图片
    choseIconByType (id) {
      if (this.first_menu_type == id) {
        return this.icon_object['icon_act_' + id]
      } else {
        return this.icon_object['icon_' + id]
      }
    },
    // 点位精灵点击
    onPointerUp (e) {
      // console.log(e);
      let sprite = e.target
      this.spriteGlow(sprite)
      this.viewportMove(sprite)
      this.show_point_brief = true
      this.point_id.shift()
      this.point_id.push(sprite.id)
    },
    // 当前精灵发光
    spriteGlow (sprite) {
      if (this.pre_sprite == '') {
        // e.target.filters = [new OutlineFilter(2,  0xFF0000, 0.5)];
        sprite.filters = [new GlowFilter(10, 4, 0, 0xecfe68, 0.5)]
        this.pre_sprite = sprite
      } else {
        this.pre_sprite.filters = null
        // console.log(sprite);
        this.pre_sprite = sprite
        sprite.filters = [new GlowFilter(10, 4, 0, 0xecfe68, 0.5)]
      }
    },
    // 目录点击
    menuLocation (id) {
      this.point_id.shift()
      this.point_id.push(id)

      let sprite = this.sprite_array.filter(item => {
        return item.id == id
      })[0]

      this.spriteGlow(sprite)
      this.viewportMove(sprite)
      this.show_menu = false
      this.show_point_brief = true
    },
    menuBtnHandle () {
      this.show_menu = !this.show_menu
      if (this.show_menu) {
        this.search_value = ''
      }
    },
    // 点击目录按钮
    menuClickHandle (type) {
      if (this.show_menu) {
        this.showMenu(type)
      } else {
      }
      this.first_menu_type = type
      // console.log(this.sprite_array);
      this.sprite_array.forEach(item => {
        if (item.type_ == type) {
          item.visible = true
        } else {
          item.visible = false
        }
      })
    },
    //   显示目录列表
    showMenu (type) {
      if (this.first_menu_type == type && this.show_menu) {
        this.show_menu = false
      } else if (this.first_menu_type == type && !this.show_menu) {
        this.show_menu = true
      } else {
        this.show_menu = true
      }
    },
    // 根据类型过滤
    filterPointListByType (list) {
      if (
        this.first_menu_type != 15 &&
        this.first_menu_type != 16 &&
        this.first_menu_type != 17
      ) {
        // 如果是景点，需要双层过滤
        let spot_list = list.filter(item => {
          // 根据类型过滤
          return item.type_ == this.first_menu_type
        })
        return spot_list.filter(item => {
          // 根据搜索过滤
          return item.spots_name.match(this.search_value)
        })
      } else {
        // 不是景点没有搜索
        return list.filter(item => {
          return item.type_ == this.first_menu_type
        })
      }
    },
    // 移动viewport
    viewportMove (sprite) {
      // console.log(sprite);
      // console.log(this.viewport);
      var scale = this.viewport.scale.x

      var target_left =
          sprite.x * this.PRE_PERSENT - this.WINDOW_WIDTH / scale / 2,
        target_top =
          sprite.y * this.PRE_PERSENT - this.WINDOW_HEIGHT / scale / 2
      let v_x = this.PRE_PERSENT * this.IMG_WIDTH - this.WINDOW_WIDTH / scale
      let v_y = this.PRE_PERSENT * this.IMG_HEIGHT - this.WINDOW_HEIGHT / scale

      if (this.viewport.height >= this.WINDOW_HEIGHT) {
        if (target_top < 0) {
          target_top = 0
        } else if (target_top > v_y) {
          target_top = v_y
        }
      } else {
        target_top = (this.viewport.height * scale - this.WINDOW_HEIGHT) / 2
        if (target_top > 0) {
          target_top = 0
        } else if (target_top < v_y) {
          target_top = v_y
        }
      }
      if (this.viewport.width >= this.WINDOW_WIDTH) {
        if (target_left < 0) {
          target_left = 0
        } else if (target_left > v_x) {
          target_left = v_x
        }
      } else if (this.viewport.width < this.WINDOW_WIDTH) {
        target_left = (this.viewport.width - this.WINDOW_WIDTH) / 2
        if (target_left > 0) {
          target_left = 0
        } else if (target_left < v_x) {
          target_left = v_x
        }
      }
      if (scale >= 1) {
        TweenMax.to(this.viewport, 0.3, { left: target_left, top: target_top })
      } else {
        TweenMax.to(this.viewport, 0.3, { left: target_left })
      }
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&id_=0&state=' +
        this.backUrl
      window.location.href = open_url
    },
    // 获取设备类型

    // 初始化方法
    load () {
      this.$ajax(
        'post',
        'SpotsRace/get_map_data_by_label',
        {
          park_id: this.park_id,
          id: this.id,
          page: 1,
          where: 'web',
          token: this.token,
          is_type: 2,
          open_id: this.union_id,
          version: this.version
        },
        ret => {
          if (ret.data.id == 0) {
            this.point_list = ret.data.data.point_list //
            this.title = ret.data.data.map_data.label_name
            this.menu_list = ret.data.data.bottom_menu_list
            this.title_list = ret.data.data.title_list
            this.ImageUrl = ret.data.data.map_data.ImageUrl
            this.map_data = ret.data.data.map_data
            this.tel_ = ret.data.data.tel_

            if (ret.data.data.point_list) {
              this.is_have_spots_list = 1
            } else {
              this.is_have_spots_list = 0
            }
            if (
              ret.data.data.map_data.width_ > 0 &&
              ret.data.data.map_data.height_ > 0
            ) {
              this.IMG_WIDTH = ret.data.data.map_data.width_
              this.IMG_HEIGHT = ret.data.data.map_data.height_
            }

            this.is_pay = ret.data.data.is_pay
            this.price = ret.data.data.price
            // 微信分享
            localStorage.setItem('wx_content', ret.data.data.map_data.content)
            localStorage.setItem('wx_title', ret.data.data.map_data.label_name)
            localStorage.setItem('wx_img', ret.data.data.map_data.map_)
            document.title = ret.data.data.map_data.label_name

            this.wx_share(window.location.href)

            this.load_progress += 40
            // 初始化pixi
            this.initPixi()
          }
        }
      )
    },
    // 初始化pixi
    initPixi () {
      // 设置初始缩放值,这个值并没有实际的加载价值,在数据初始化之后,就进行修改
      this.PRE_PERSENT = parseFloat(this.offsetHeight / this.IMG_HEIGHT)

      this.PRE_PERSENT = this.PC
        ? this.offsetWidth / this.IMG_WIDTH
        : this.PRE_PERSENT

      // console.log(this.PRE_PERSENT+','+this.IMG_HEIGHT);
      this.MIN_SCALE = Math.max(
        (this.PRE_PERSENT * this.IMG_WIDTH) / this.WINDOW_WIDTH,
        (this.PRE_PERSENT * this.IMG_HEIGHT) / this.WINDOW_HEIGHT
      )

      // 判断是否支持WEBGL
      let type = 'WebGL'
      /* 创建一个 pixi 舞台 */

      // console.log(this.map_bg);
      const isIOS = navigator.userAgent.match(/iphone/i)
      this.pixiApp = new PIXI.Application({
        width: this.offsetWidth,
        height: this.offsetHeight,
        antialias: true, // 抗锯齿
        resolution: 2, // 分辨率
        autoDensity: true,
        forceCanvas: isIOS // 如果是 ios ，则使用 canvas引擎， 默认使用webgl引擎
        // threshold: 30, // 要移动以触发输入事件（例如，拖动，捏合）或禁用单击事件的像素数
        // stopPropagation: true // 停止影响视口的事件的传播
      })

      // 设置舞台样式
      this.pixiApp.renderer.backgroundColor = this.backgroundColor // 画布的背景颜色
      this.pixiApp.renderer.view.style.position = 'absolute'
      this.pixiApp.renderer.view.style.display = 'block'
      this.pixiApp.renderer.autoResize = true // 用于宽高格式的正确

      //  将 pixi 添加到页面
      document.getElementById('pixi_').appendChild(this.pixiApp.view)
      /* 创建 PIXI 结束 */

      /* 创建viewport */
      this.viewport = new Viewport({
        screenWidth: this.WINDOW_WIDTH,
        screenHeight: this.WINDOW_HEIGHT,
        worldWidth: this.PRE_PERSENT * this.IMG_WIDTH,
        worldHeight: this.PRE_PERSENT * this.IMG_HEIGHT,
        interaction: this.pixiApp.renderer.plugins.interaction // 该模块用于移动或缩放
      })

      this.viewport
        .drag()
        .pinch()
        .wheel()
        .bounce()

      // 绘制背景
      var baseTexture = new PIXI.BaseTexture(this.map_bg)
      var texture = new PIXI.Texture(baseTexture)
      var bg = new PIXI.Sprite(texture)
      // 浏览器返回时不执行该方法
      bg.texture.baseTexture.on('loaded', () => {
        this.load_progress += 40
      })

      this.viewport.clampZoom({
        maxHeight: this.WINDOW_HEIGHT * this.MIN_SCALE,
        maxWidth: this.WINDOW_WIDTH * this.MIN_SCALE,
        minHeight: this.WINDOW_HEIGHT * 0.1,
        minWidth: this.WINDOW_WIDTH * 0.1
      })

      bg.zOrder = 100
      bg.width = this.PRE_PERSENT * this.IMG_WIDTH
      bg.height = this.PRE_PERSENT * this.IMG_HEIGHT

      // 设置窗口对应的位子
      this.viewport.left = (bg.width - this.WINDOW_WIDTH) / 2
      this.viewport.top = (bg.height - this.WINDOW_HEIGHT) / 2

      this.viewport.addChild(bg) // 将 viewport 添加进PIXI舞台
      this.pixiApp.stage.addChild(this.viewport)
      if (this.id == 1741) {
        // 长沙旅游地图的云彩
        let cloud_pos = [
          { x: 1060, y: 410 },
          { x: 352, y: 412 },
          { x: 620, y: 800 },
          { x: 183, y: 1210 },
          { x: 353, y: 2900 },
          { x: 2544, y: 546 },
          { x: 2700, y: 1100 }
        ]
        for (let i = 0; i < 7; i++) {
          let cloud = PIXI.Sprite.from('/static/changsha/cloud_' + i + '.png')
          cloud.interactive = true
          bg.addChild(cloud)
          cloud.scale.set(1, 1) // 放大2倍
          cloud.position.set(cloud_pos[i].x, cloud_pos[i].y)
          cloud.alpha = 0.7
          let derection = Math.random() - 0.5 >= 0 ? 1 : -1
          this.pixiApp.ticker.add(delta => {
            // console.log(cloud.width);
            cloud.x += derection
            if (derection > 0 && cloud.x > this.IMG_WIDTH * 1) {
              cloud.x = -cloud.width
            } else if (derection < 0 && cloud.x <= -cloud.width) {
              cloud.x = this.IMG_WIDTH * 1
            }
          })
        }
      }

      // 先加载点
      if (this.point_list.length > 0) {
        var sprite_list = this.point_list
        var sprite = ''
        for (var i = 0; i < sprite_list.length; i++) {
          // 绘制文本

          // let text = new PIXI.Text(sprite_list[i].spots_name, {
          //   fontFamily: "PingFang SC",
          //   fontSize: 26,
          //   fontWeight: 600,
          //   // fill: 0xff1010,
          //   fill: "#6f3bfc",
          //   align: "center"
          // });

          // text.x = sprite_list[i].x_ ;
          // text.y = (sprite_list[i].y_ - 40 );

          if (sprite_list[i].type_ == 15) {
            sprite = PIXI.Sprite.from(this.icon_object.iconMap15)
          } else if (sprite_list[i].type_ == 16) {
            sprite = PIXI.Sprite.from(this.icon_object.iconMap16)
          } else if (sprite_list[i].type_ == 17) {
            sprite = PIXI.Sprite.from(this.icon_object.iconMap17)
          } else if (sprite_list[i].type_ == 18) {
            sprite = PIXI.Sprite.from(this.icon_object.iconMap18)
          } else if (sprite_list[i].type_ == 19) {
            if (sprite_list[i].have_audio) {
              sprite = PIXI.Sprite.from(this.icon_object.iconMapAudio)
            } else {
              sprite = PIXI.Sprite.from(this.icon_object.iconMap19)
            }
          } else if (sprite_list[i].type_ == 20) {
            sprite = PIXI.Sprite.from(this.icon_object.iconMap20)
          } else if (sprite_list[i].type_ == 21) {
            sprite = PIXI.Sprite.from(this.icon_object.iconMap21)
          } else {
            sprite = PIXI.Sprite.from(this.icon_object.iconMapNormal)
          }

          sprite.position.set(sprite_list[i].x_, sprite_list[i].y_)
          sprite.id = sprite_list[i].id
          sprite.scale.set(1, 1) // 放大2倍
          sprite.interactive = true // 表示精灵是有事件的
          // sprite.buttonMode = true; // 表示精灵是作为按钮使用
          sprite.name = sprite_list[i].spots_name
          sprite.index_ = i
          sprite.type_ = sprite_list[i].type_
          // sprite.on("click", this.onClickHandle);  // click事件只能在PC端触发
          sprite
            .on('pointerup', this.onPointerUp)
            .on('mouseup', this.onMouseUp)

          // bg.addChild(text);
          bg.addChild(sprite)
          // text.visible = false;//隐藏文字
          // this.text_array.push(text);
          this.sprite_array.push(sprite)
        }
      } else {
        Toast('暂无数据')
      }
      // 保存背景精灵对象
      this.bgSprite = bg
    },

    onMouseUp () {},

    jumpPos () {
      // 导航
      if (this.is_weixin) {
        if (this.price > 0) {
          // 收费
          if (this.is_pay) {
            // 已经支付了
            this.wx_nav(
              window.location.href,
              this.point_item.lng,
              this.point_item.lat,
              this.point_item.spots_name,
              this.point_item.address
            )
          } else {
            // 还未支付
            if (this.union_id) {
              // 进行支付
              var open_url =
                'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
                this.id +
                '&backUrl=' +
                this.backUrl +
                '&open_id=' +
                this.union_id +
                '&token=' +
                this.token +
                '&park_id=' +
                this.park_id +
                '&version=' +
                this.version +
                '&user_id='
              window.location.href = open_url
            } else {
              // 进行微信授权
              this.authorize()
            }
          }
        } else {
          // 免费
          this.wx_nav(
            window.location.href,
            this.point_item.lng,
            this.point_item.lat,
            this.point_item.spots_name,
            this.point_item.address
          )
        }
      } else {
        Toast('请在微信中打开,使用导航')
      }
    },

    initScale () {
      // 获取浏览器宽高
      this.WINDOW_WIDTH = window.innerWidth
      this.WINDOW_HEIGHT = window.innerHeight
      //   获取body宽高
      this.offsetWidth = document.querySelector('body').offsetWidth
      this.offsetHeight = document.querySelector('body').offsetHeight
      // 上面两个值是相等的
      // console.log(offsetWidth+','+offsetHeight+','+this.WINDOW_WIDTH+','+this.WINDOW_HEIGHT);
    },
    preLoadFirst (ls) {
      let img_length = ls.length
      for (let img of ls) {
        let image = new Image()
        image.src = img
        image.onload = () => {
          this.img_count++
          this.load_progress += 20 / img_length
        }
        if (image.complete) {
          // 如果是从缓存中读数据， 这里的进度弥补pixi进度的不足
          this.img_count++
          this.load_progress += 40 / img_length
        }
      }
    },
    initPreData () {
      if (this.id == 1741) {
        // 长沙旅游地图
        this.loading_img = '/static/changsha/loading_' + this.id + '.png'
        this.loading_img_0 = '/static/changsha/loading_' + this.id + '_0.png'
        this.loading_text = '/static/changsha/loading_text_' + this.id + '.png'
        let load_ls = [this.loading_img, this.loading_img_0]
        for (let i = 0; i < load_ls.length; i++) {
          let image = new Image()
          image.src = load_ls[i]
          image.onload = () => {
            if (i == load_ls.length - 1) {
              this.show_loading = true
            }
          }
        }

        this.loading = true // 显示loading
        this.show_start = true // 显示引导页
        this.map_bg = '/static/map/map_' + this.id + '.png' // pixi地图
        this.start_bg = '/static/changsha/start_bg_' + this.id + '.png' // 启动页背景图
        this.pre_load_list.push(this.start_bg) // 加载图片列表
        for (let i = 0; i < 12; i++) {
          this.flower_list.push(
            '/static/changsha/flower_' + this.id + '_' + i + '.png'
          )
        }
        this.pre_load_list = this.pre_load_list.concat(this.flower_list)
        this.backgroundColor = 0xd3e5e5
      } else {
        this.backgroundColor = 0x505555
        this.map_bg = '/static/map/map_' + this.id + '.jpg'
      }

      this.PC = this.isPC() // 是否是PC
      this.mobile = this.phoneType() // 手机型号
    }
    // 1741启动页
  },

  created () {
    this.initPreData()
    this.preLoadFirst(this.pre_load_list)
  }
}
</script>

<style scoped>
.pixiIndex {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
/* 加载动画 */
.load-leave {
  opacity: 1;
}
.load-leave-to {
  opacity: 0;
}
.load-leave-active {
  transition: 0.5s;
}
.dylan .load-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4;
  /* background-color: rgb(211, 229, 229); */
  background: radial-gradient(rgb(211, 229, 229), rgb(166, 199, 199));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dylan .load-progress {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dylan .load-progress .load-text {
  width: 40%;
}
.dylan .load-progress .progress-wrapper {
  position: relative;
  width: 70%;
  height: 24px;
  border: 2px solid #000;
  background-color: #fff;
  border-radius: 2em;
  display: flex;
  align-items: center;
  padding: 0 4px;
  box-sizing: border-box;
  /* overflow: hidden; */
}
.dylan .load-progress .progress-wrapper .progress-img-pre {
  position: absolute;
  width: 90px;
  height: 90px;
  left: 100%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 1;
}
.dylan .load-progress .progress-wrapper .progress-img img {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dylan .load-progress .progress-wrapper .progress-img-next {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  height: 0;
  transition: 1s;
}

.dylan .load-progress .progress-wrapper .progress-inner {
  position: relative;
  height: 8px;
  width: 8px;
  background: rgb(202, 35, 28);
  transition: 0.5s;
  border-radius: 2em;
  transition: 1s;
}
.dylan .load-progress .progress-wrapper .progress-inner::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: rgb(202, 35, 28);
}
.dylan .load-progress .progress-wrapper .progress-inner::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  transform: translate(100%, -50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fff;
}

/*  */
.detail-wrapper {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.781);
  z-index: 667;
}
.detail-container {
  position: absolute;
  left: 50%;
  width: 93.73333vw;
  height: 129.2vw;
  background-color: #eee;
  border-radius: 4px;
  /* background: url(/static/map/detail_bg.png) no-repeat border-box;
  background-size: 100% 100%; */
  margin-left: -46.86667vw;
  top: 3.2vw;
  z-index: 10;
}

.detail-container .detail-title {
  width: 59.73333vw;
  line-height: 10.13333vw;
  white-space: nowrap;
  font-size: 5.5vw;
  color: #333;
  position: absolute;
  text-align: center;
  left: 50%;
  margin-left: -29.86667vw;
}
.btn {
  cursor: pointer;
}
.detail-container .btn-close {
  width: 9.86667vw;
  height: 9.86667vw;
  /* background-color: #ddd; */
  background: url(../../assets/img/del.png) no-repeat border-box;
  background-size: 100% 100%;
  position: absolute;
  z-index: 11;
  right: 2vw;
  top: 1vw;
}

.detail-container .detail-content {
  position: absolute;
  width: 78vw;
  height: 105.33333vw;
  left: 50%;
  margin-left: -39vw;
  top: 17vw;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 4.3vw;
}
.detail-editor .content-img img {
  border-width: 0px;
  width: 100%;
  height: auto;
  max-width: 100%;
  border: 0;
  outline: none;
  vertical-align: middle;
}
.content-txt img {
  display: inline-block;
  position: relative;
  width: 100%;
}
.btn-container {
  left: 50%;
  margin-left: -46.86667vw;
  width: 93.73333vw;
  position: absolute;
  top: 136.8vw;
  z-index: 10;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  font: normal 4.5vw/1.3em "微软雅黑" !important;
}
.btn-container .btn {
  width: 22vw;
  height: 10.26667vw;
  line-height: 10.26667vw;
  border-radius: 4px;
  /* font-size: 0.8rem; */
  color: white;
  text-align: center;
}
.btn-container .btn-audio {
  background-color: #409eff;
}
.btn-container .btn-audio::before {
  content: "\e647";
}
.btn-container .btn-pos {
  background-color: #67c23a;
}
.btn-container .btn-pos::before {
  content: "\e677";
}
.btn-container .btn-intro {
  background-color: #e6a23c;
}
.btn-container .btn-intro::before {
  content: "\e63e";
}
.btn-container .btn-phone {
  background-color: #f56c6c;
}
.btn-container .btn-phone::before {
  content: "\e69a";
}
.foot-menu {
  position: absolute;
  background-color: white;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}
.foot-menu .dm-fm-ul {
  display: inline-block;
  position: relative;
  width: 100%;
}
.foot-menu .dm-fm-ul .dm-fm-ul-li {
  position: relative;
  width: 49%;
  float: left;
  font-size: 0.8rem;
  text-align: center;
  padding: 8px 0px 8px 0px;
}
.foot-menu .dm-fm-ul .border_right {
  border-right: 1px solid #ff9138;
}
.foot-menu .dm-fm-ul .dm-fm-ul-li i {
  color: #ff9138;
}
.spots::before {
  content: "\e757";
}
.line::before {
  content: "\e677";
}
.content_list {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  font-size: 0.7rem;
  z-index: 2;
}
.content_list .park_search {
  display: inline-block;
  position: relative;
  width: 100%;
}
.content_list .park_search > p {
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
}
.content_list .park_search img {
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
  width: 30px;
  height: 30px;
}
.content_list .park_search ul {
  border-top: 0.1rem solid #ccc;
  max-height: 15rem;
  min-height: 6rem;
  overflow-y: scroll;
  padding: 0 0.6rem;
  box-sizing: border-box;
}
.content_list .park_search ul li {
  line-height: 1.5rem;
  padding: 0.2rem;
  border-bottom: 0.01rem solid #f5f5f5;
}
.park_search .input_search {
  background: url(../../assets/img/search-bg.png) 0.8rem 0.8rem no-repeat;
  background-size: 1.2rem;
  display: flex;
  padding: 0.4rem 0.4rem 1rem;
  box-sizing: border-box;
}

.park_search .input_search input {
  height: 2rem;
  line-height: 2rem;
  flex: 4;
  border: 1px solid #ff9a80;
  border-radius: 0.4rem 0 0 0.4rem;
  padding-left: 2rem;
  font-size: 0.7rem;
}

.park_search .input_search button {
  flex: 1;
  background-color: #ff9a80;
  color: #fff;
  border: none;
  font-size: 0.7rem;
  border-radius: 0 0.4rem 0.4rem 0;
}
.content-txt {
  width: 100%;
}
.content-txt >>> img {
  width: 100%;
}

/* 20190924增加 */
.dylan .pixi-start {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #d3e5e5;
}
.dylan .pixi-start .start-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  z-index: 0;
  background: rgb(211, 230, 229);
}
.dylan .pixi-start .start-bg img {
  width: 100%;
  height: 100%;
}
/* .dylan .pixi-start .start-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.dylan .pixi-start .start-btn {
  position: absolute;
  bottom: 30%;
  left: 0;
  width: 100%;
  /* height: 20%; */
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.dylan .pixi-start .start-btn button {
  display: block;
  background: rgba(105, 174, 171, 0.8);

  padding: 10px 30px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  transition: 0.1s;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  border-radius: 2em;
  animation: btn 0.5s linear 0.5s 1 both;
}
@keyframes btn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* 地图页 */
.dylan .pixi-map {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.dylan .pixi-body {
  position: relative;
  width: 100%;
  height: 100vh;
}
/* 顶部导航 */
.top-fade-enter,
.top-fade-leave-to {
  transform: translateX(100%);
}
.top-fade-enter-to,
.top-fade-leave {
  transform: translateX(0);
}
.top-fade-enter-active,
.top-fade-leave-active {
  transition: ease 0.3s;
}
.dylan .pixi-top {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 0.5rem;
}
.dylan .pixi-top .top-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  justify-content: space-between;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 2px #aaa;
}
.dylan .pixi-top .top-menu-li {
  display: flex;
  flex-direction: column;
  /* padding: 4px 6px; */
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2.2rem;
}
.dylan .pixi-top .top-menu-li p {
  color: #999;
  font-size: 0.6rem;
}
.dylan .pixi-top .top-brief i {
  color: rgb(250, 88, 59);
  font-size: 16px;
}

.dylan .pixi-top .top-music {
  margin-top: 6px;
}
.dylan .pixi-top .top-music i {
  font-size: 18px;
}
.dylan .pixi-top .top-music i.iconswitch_open {
  color: rgb(250, 119, 59);
}
.dylan .pixi-top .top-music i.iconswitch_close {
  color: #999;
}
/* 底部导航 */
.dylan .pixi-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 2.4rem;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  background-color: #fff;
  transition: ease 0.3s;
  box-shadow: 0 0 1px #e9e9e9;
}
.dylan .pixi-footer-active {
  height: 19rem;
  transition: ease 0.3s;
}
.dylan .pixi-footer .footer-menu {
  height: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #eee; */
}
.dylan .pixi-footer .footer-menu .footer-menu-list {
  width: calc(100% - 2.4rem);
  height: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dylan .pixi-footer .footer-menu .menu-item {
  width: 100%;
  height: 100%;
  color: #666;
  text-align: center;
  /* line-height: 2.4rem; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-top: 1px solid rgb(45, 63, 85); */
}
.dylan .pixi-footer .footer-menu .menu-item-active {
  color: #333;
}
.dylan .pixi-footer .footer-menu .menu-item img {
  /* width: 1rem; */
  height: 0.88rem;
}
.dylan .pixi-footer .footer-btn {
  width: 2.4rem;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
  color: #666;
}
.dylan .pixi-footer .footer-btn i {
  font-size: 1rem;
}
.dylan .pixi-footer-active .footer-btn {
  transform: rotate(180deg);
}
/* 搜索 */
.dylan .footer-search {
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* padding: 0.5rem 0; */
  margin-bottom: 0.4rem;
}
.dylan .footer-search .search-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background: #fff;
}
.dylan .footer-search .search-wrapper .search-input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 40px 0 12px;
}
.dylan .footer-search .search-wrapper .search-input input {
  width: 100%;
  height: 100%;
  font-size: 12px;
}
.dylan .footer-search .search-wrapper .search-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
}
.dylan .footer-search .search-wrapper .search-icon i {
  font-size: 1rem;
  color: #999;
}
/* 目录列表 */
.dylan .pixi-footer .footer-nav {
  width: 100%;
  height: calc(100% - 2.4rem);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
}
.dylan .pixi-footer .footer-nav .nav-wrapper {
  width: 100%;
  height: 13.2rem;
  overflow: auto;
}
.dylan .pixi-footer .footer-nav .footer-nav-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.dylan .pixi-footer .footer-nav .footer-nav-list .nav-item {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.dylan .pixi-footer .footer-nav .footer-nav-list .nav-item img {
  width: 1.2rem;
  /* margin-right: 6px; */
}
/* 点位简介 */
.brief-fade-enter,
.brief-fade-leave-to {
  transform: translateY(100%);
}
.brief-fade-enter-to,
.brief-fade-leave {
  transform: translateY(0);
}
.brief-fade-enter-active,
.brief-fade-leave-active {
  transition: ease 0.3s;
}
.dylan .point-brief {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 2.4rem;
  box-sizing: border-box;
  padding: 0.5rem;
  z-index: 1;
  /* background-color: rgba(0, 0, 0, 0.3); */
}
.dylan .point-brief .brief-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 4px #ccc;
}
.dylan .point-brief .brief-wrapper .brief-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
  /* border-radius: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #aaa; */
  text-align: center;
}
.dylan .point-brief .brief-wrapper .brief-close i {
  font-size: 1rem;
  color: #999;
}
.dylan .point-brief .brief-wrapper .brief-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 4px;
}
.dylan .brief-header .brief-icon {
  position: relative;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dylan .brief-header .brief-icon .audio-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.dylan .brief-header .brief-icon .audio-btn-play {
  font-size: 1.5rem;
  color: #fff;
}
.dylan .brief-header .brief-icon img {
  width: 100%;
  /* height: 100%; */
}
.dylan .brief-header .brief-title {
  position: relative;
  height: 100%;
  width: calc(100% - 7rem);
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dylan .brief-header .brief-title .brief-title-tip {
  left: 50%;
  transform: translateX(-50%);
  bottom: -100%;
  position: absolute;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url(../../assets/switch/shou.gif) no-repeat;
  background-size: contain;
}
.dylan .brief-header .brief-title h2 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  text-align: center;
  z-index: 1;
}
.dylan .brief-header .brief-nav {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dylan .brief-header .brief-nav i {
  font-size: 1.2rem;
  color: #666;
}
/* 景区简介 */
.spot-brief-enter,
.spot-brief-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.spot-brief-enter-to,
.spot-brief-leave {
  transform: translateY(0);
  opacity: 1;
}
.spot-brief-enter-active,
.spot-brief-leave-active {
  transition: 0.3s;
}
.dylan .pixi-spot-brief {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 2.4rem;
  box-sizing: border-box;
  padding: 0.5rem 2rem;
  z-index: 1;
}
.dylan .pixi-spot-brief .spot-brief-wrapper {
  position: relative;
  width: 100%;
  border-radius: 2px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 0 2px #aaa;
  padding-bottom: 1rem;
}
.dylan .pixi-spot-brief .spot-brief-wrapper .brief-close {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  width: 1.4rem;
  height: 1.4rem;
  /* background: rgba(0, 0, 0, 0.3); */
  /* color: #333;  */
  text-align: center;
  line-height: 1.4rem;
  border-radius: 50%;
}
.dylan .pixi-spot-brief .spot-brief-wrapper .brief-close i {
  color: #333;
  font-size: 1rem;
}

.dylan .pixi-spot-brief .spot-brief-icon {
  position: absolute;
  top: -1rem;
  left: 0.5rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  border: 2px solid rgb(255, 234, 175);
}
.dylan .pixi-spot-brief .spot-brief-icon img {
  height: 100%;
  width: 100%;
}
.dylan .spot-brief-header {
  margin-left: 4rem;
}

.dylan .spot-brief-header h2 {
  font-size: 0.8rem;
  height: 2rem;
  line-height: 2rem;
  color: #333;
  font-weight: 600;
}
.dylan .spot-brief-info {
  /* margin-top: 0.4rem; */
  box-sizing: border-box;
  padding: 10px 0.6rem;
}
.dylan .spot-brief-info .spot-brief-item {
  font-size: 12px;
  color: #333;
  display: flex;
  padding-bottom: 10px;
}
.dylan .spot-brief-info .spot-brief-item .item-name {
  white-space: nowrap;
}
.dylan .spot-brief-info .spot-brief-item .item-value {
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dylan .spot-brief-info .spot-brief-time {
  border-bottom: 1px solid #eee;
}
.dylan .spot-brief-info p i {
  font-size: 14px;
  color: #333;
  margin-right: 2px;
}

.dylan .spot-brief-content {
  padding: 0 0.6rem;
  box-sizing: border-box;
  color: #333;
  font-size: 0.76rem;
  width: 100%;
}
.dylan .spot-brief-content article {
  max-height: 10rem;
  overflow-y: auto;
}
.dylan .spot-brief-content article >>> span {
  font-size: 14px !important;
}
/* 播放界面 */
.audio-fade-enter,
.audio-fade-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
.audio-fade-enter-to,
.audio-fade-leave {
  opacity: 1;
  transform: translateY(0);
}
.audio-fade-enter-active,
.audio-fade-leave-active {
  transition: 0.3s;
}
.dylan .audio-play {
  position: fixed;
  left: 20%;
  top: 0.5rem;
  width: 60%;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
}
.dylan .audio-play .audio-play-icon {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  animation: audio 4s linear infinite;
}
@keyframes audio {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dylan .audio-play .audio-play-icon img {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
}
.dylan .audio-play .audio-play-name {
  font-size: 0.8rem;
  color: #fff;
}
.dylan .audio-play .audio-play-close {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dylan .audio-play .audio-play-close i {
  font-size: 1rem;
  color: #fff;
}

/* 单独样式 */
/* 启动页底部动画 */
.dylan .start-1741 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  display: flex;
}
.dylan .start-1741 img {
  position: absolute;
  height: 100%;
  left: 100%;
  bottom: -5%;
  transform-origin: center bottom;
}
.dylan .start-1741 .flower-0 {
  left: 0;
  height: 80%;
  animation: flower0 3s linear infinite alternate;
  z-index: 1;
}
.dylan .start-1741 .flower-1 {
  left: 8%;
  height: 80%;
  bottom: 20%;
  animation: flower1 3s linear infinite alternate;
  z-index: 0;
}
.dylan .start-1741 .flower-2 {
  left: 20%;
  height: 80%;
  animation: flower0 3s linear infinite alternate;
  z-index: 2;
}
.dylan .start-1741 .flower-3 {
  left: 25%;
  height: 60%;
  animation: flower1 3s linear infinite alternate;
  z-index: 2;
}
.dylan .start-1741 .flower-4 {
  left: 22%;
  height: 90%;
  animation: flower0 3s linear infinite alternate;
  z-index: 1;
}
.dylan .start-1741 .flower-5 {
  left: 35%;
  height: 46%;
  animation: flower1 3s linear infinite alternate;
  z-index: 2;
}
.dylan .start-1741 .flower-6 {
  left: 42%;
  height: 76%;
  animation: flower0 3s linear infinite alternate;
  z-index: 2;
}
.dylan .start-1741 .flower-7 {
  left: 66%;
  height: 56%;
  animation: flower1 3s linear infinite alternate;
  z-index: 2;
}
.dylan .start-1741 .flower-8 {
  left: 42%;
  /* height: 76%; */
  animation: flower0 3s linear infinite alternate;
  z-index: 1;
}
.dylan .start-1741 .flower-9 {
  left: 65%;
  height: 90%;
  animation: flower1 3s linear infinite alternate;
  z-index: 0;
}
.dylan .start-1741 .flower-10 {
  left: 65%;
  height: 76%;
  animation: flower0 3s linear infinite alternate;
  z-index: 1;
}
.dylan .start-1741 .flower-11 {
  left: 80%;
  /* height: 76%; */
  animation: flower1 3s linear infinite alternate;
  z-index: 0;
}
@keyframes flower0 {
  0% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(8deg);
  }
}
@keyframes flower1 {
  0% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(-8deg);
  }
}
</style>
