import { render, staticRenderFns } from "./MessageComment.vue?vue&type=template&id=312c7e98&scoped=true&"
import script from "./MessageComment.vue?vue&type=script&lang=js&"
export * from "./MessageComment.vue?vue&type=script&lang=js&"
import style0 from "./MessageComment.vue?vue&type=style&index=0&id=312c7e98&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "312c7e98",
  null
  
)

export default component.exports