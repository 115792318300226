<template>
  <div v-title data-title="全部订单">
    <mt-header fixed title="全部订单">
      <router-link to slot="left">
        <mt-button icon="back" @click="goBack(-1)"></mt-button>
      </router-link>
    </mt-header>

    <div class="page-content" id="orders">
      <mt-navbar v-model="selected">
        <mt-tab-item v-for="nav in navlList" :key="nav.id" :id="nav.id">{{nav.title}}</mt-tab-item>
      </mt-navbar>

      <!-- tab-container -->
      <div class="order-content">
        <mt-tab-container v-model="selected">
          <mt-tab-container-item
            v-for="orderList in orderLists"
            :key="orderList.id"
            :id="orderList.id"
          >
            <mt-loadmore
              :bottom-method="loadBottom"
              :bottom-all-loaded="allLoaded"
              ref="loadmore"
              :auto-fill="false"
            >
              <div
                class="order-item"
                v-for="(order,index) in orderList.content"
                :key="index"
                :id="order.id"
              >
                <div class="item-wrap clearfix">
                  <div class="order-name">{{order.title}}</div>
                  <div class="order-time">
                    出行时间：{{order.service_begin_time}}
                    <i @click="deleteOrder(order.id,index)"></i>
                  </div>
                </div>
                <div class="order" @click="getOrderDetail(order.id , order.status , order.type)">
                  <div class="item-wrap clearfix">
                    <div class="user-img">
                      <img :src="order.img" alt />
                    </div>
                    <div class="user-wrap">
                      <div class="user-name">{{order.name}}</div>
                      <div class="order-price">￥{{order.pay_money}}</div>
                    </div>
                    <div class="user-wrap">
                      <div
                        class="order-num"
                      >人数：{{sum = parseInt(order.man) + parseInt(order.child)}}</div>
                      <!-- 付款 -->
                      <div
                        class="order-status"
                        v-if="order.type == 0 && order.is_pay == 0 && order.status == 0"
                      >待付款</div>
                      <div
                        class="order-status"
                        v-else-if="order.type == 0 && order.is_pay == 0 && order.status == 1"
                      >商家已接单</div>
                      <div
                        class="order-status"
                        v-else-if="order.is_pay == 0 && order.status == 2"
                      >商家拒绝接单</div>
                      <div
                        class="order-status"
                        v-else-if="order.type == 1 && order.is_pay == 0 && order.status == 0 || order.type == 1 && order.is_pay == 0 && order.status == 1"
                      >客户未支付</div>

                      <!-- 确认订单 -->
                      <div class="order-status" v-else-if="order.is_pay == 1">待确认</div>

                      <!-- 出行 -->
                      <div class="order-status" v-else-if="order.go == 0">待出行</div>
                      <div class="order-status" v-else-if="order.go == 1">出行中</div>
                      <div class="order-status" v-else-if="order.go == 2">出行完成</div>

                      <!-- 撤销 -->
                      <div class="order-status" v-else-if="order.status == 3">正在撤销</div>
                      <div class="order-status" v-else-if="order.status == 4">撤销失败</div>
                      <div class="order-status" v-else-if="order.status == 5">撤销成功</div>

                      <!-- 完成 -->
                      <div class="order-status" v-else-if="order.status == 6">订单完成</div>
                    </div>
                  </div>
                </div>
                <div
                  class="user-wrap order-btn clearfix"
                  v-if="order.type == 1 && order.is_pay == 1 && order.status == 0 || order.type == 1 && order.is_pay == 0 && order.status == 1 || order.type == 1 && order.is_pay == 0 && order.status == 0"
                >
                  <mt-button type="primary" @click="setOrder(1,order.id)">接单</mt-button>
                  <mt-button type="danger" @click="setOrder(2,order.id)">拒绝</mt-button>
                </div>

                <div
                  class="user-wrap order-btn clearfix"
                  v-if="order.type == 0 && order.is_pay == 1 && order.status == 0 || order.type == 0 && order.is_pay == 0 && order.status == 1 || order.type == 0 && order.is_pay == 0 && order.status == 0"
                >
                  <mt-button type="danger" @click="setOrder(3,order.id)">撤销订单</mt-button>
                </div>
              </div>
              <div class="no-content" v-show="nocontent">暂无账单</div>
            </mt-loadmore>
          </mt-tab-container-item>
        </mt-tab-container>
      </div>
    </div>
  </div>
</template>


<script>
import { Indicator, Loadmore } from "mint-ui";
import { Toast } from "mint-ui";
import { MessageBox } from "mint-ui";

export default {
  name: "MyOrder",
  data() {
    return {
      selected: "1",
      navlList: [
        {
          title: "全部订单",
          id: "1"
        },
        {
          title: "待付款",
          id: "2"
        },
        {
          title: "待确认",
          id: "3"
        },
        {
          title: "待出行",
          id: "4"
        },
        {
          title: "撤销",
          id: "5"
        },
        {
          title: "已完成",
          id: "6"
        }
      ],
      orderLists: [
        {
          id: "1",
          content: []
        },
        {
          id: "2",
          content: []
        },
        {
          id: "3",
          content: []
        },
        {
          id: "4",
          content: []
        },
        {
          id: "5",
          content: []
        },
        {
          id: "6",
          content: []
        }
      ],
      nocontent: false,
      page: 1,
      allLoaded: false, //是否加载
      totalCount: "", //初始化方法中的数量总数
      user_id: "",
      listType: ""
    };
  },
  mounted() {
    this.getOrderList(this.page);
  },
  methods: {
    // 点击全部订单，默认订单列表显示全部订单
    getOrderList(page) {
      var Ordertype = this.$route.query.type;
      this.selected = Ordertype;

      // 用户ID
      if (this.hybrid.hybrid.versions.myapp) {
        // FOR 安卓最美旅行家APP
        $(".mint-header").remove();
        $(".page-content").css("padding", "0");

        this.user_id = JavaScriptInterface.getUserId();
        this.otherOrder(Ordertype, page, 0);
      } else if (this.hybrid.hybrid.versions.myiOSapp) {
        // FOR IOS最美旅行家APP

        $(".mint-header").remove();
        $(".page-content").css("padding", "0");

        this.hybrid.hybrid.registerHandler("getIOSUserId", function(
          data,
          responseCallback
        ) {
          this.user_id = data.user_id;
          this.otherOrder(Ordertype, page, 0);

          var responseData = "已经收到IOS传来的userid";
          responseCallback(responseData);
        });
      } else {
        // For web

        this.user_id = this.get_User_Id();
        this.otherOrder(Ordertype, page, 0);
      }
    },

    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },

    // 关闭页面（未使用）
    closePage() {
      if (this.hybrid.hybrid.versions.myapp) {
        JavaScriptInterface.finishPage();
      }
    },

    // 得到订单详情
    // id-> 订单ID ；status -> 订单状态 ； my-> 是否是我下的单
    getOrderDetail(id, status, my) {
      this.$router.push({
        name: "GuideOrderDetail",
        params: { id: id, status: status, my: my }
      });
    },

    // 得到不同类型的数据
    // value-> 订单类型， obj-> 数据请求返回的值 ， index -> orderList 索引值 , listType -> 是否是点击事件
    getOrderInfo(value, obj, index, listType) {
      var that = this;
      var order = [];
      switch (value) {
        // 全部订单
        case "1":
          if (listType == "click") {
            that.orderLists[index].content.length = 0;
          }

          if (obj) {
            that.orderLists[index].content = that.orderLists[
              index
            ].content.concat(obj);
            this.nocontent = false;
          } else {
            this.nocontent = true;
          }
          break;

        // 待付款
        case "2":
          for (var j = 0; j <= obj.length - 1; j++) {
            if (obj[j].is_pay == 0) {
              order.push(obj[j]);
            }
          }

          if (listType == "click") {
            that.orderLists[index].content.length = 0;
          }

          if (this.totalCount != 0) {
            that.orderLists[index].content = that.orderLists[
              index
            ].content.concat(order);
            this.nocontent = false;
          } else {
            this.nocontent = true;
          }
          break;

        // 待确认
        case "3":
          for (var j = 0; j <= obj.length - 1; j++) {
            if (obj[j].is_pay == 1) {
              order.push(obj[j]);
            }
          }

          if (listType == "click") {
            that.orderLists[index].content.length = 0;
          }

          if (order.length != 0) {
            that.orderLists[index].content = that.orderLists[
              index
            ].content.concat(order);
            this.nocontent = false;
          } else {
            this.nocontent = true;
          }
          break;

        // 待出行
        case "4":
          for (var j = 0; j <= obj.length - 1; j++) {
            if (obj[j].go == 0 || obj[j].go == 1 || obj[j].go == 2) {
              order.push(obj[j]);
            }
          }

          if (listType == "click") {
            that.orderLists[index].content.length = 0;
          }

          if (order.length != 0) {
            that.orderLists[index].content = that.orderLists[
              index
            ].content.concat(order);
            this.nocontent = false;
          } else {
            this.nocontent = true;
          }
          break;

        // 撤销
        case "5":
          for (var j = 0; j <= obj.length - 1; j++) {
            if (
              obj[j].status == 3 ||
              obj[j].status == 4 ||
              obj[j].status == 5
            ) {
              order.push(obj[j]);
            }
          }

          if (listType == "click") {
            that.orderLists[index].content.length = 0;
          }

          if (order.length != 0) {
            that.orderLists[index].content = that.orderLists[
              index
            ].content.concat(order);
            this.nocontent = false;
          } else {
            this.nocontent = true;
          }
          break;

        // 订单完成
        case "6":
          for (var j = 0; j <= obj.length - 1; j++) {
            if (obj[j].status == 6) {
              order.push(obj[j]);
            }
          }

          if (listType == "click") {
            that.orderLists[index].content.length = 0;
          }

          if (order.length != 0) {
            that.orderLists[index].content = that.orderLists[
              index
            ].content.concat(order);
            this.nocontent = false;
          } else {
            this.nocontent = true;
          }
          break;
      }
    },

    //  下拉加载页面
    loadBottom() {
      setTimeout(() => {
        if (this.totalCount % 10 == 0) {
          this.page = this.page + 1;
          if (this.selected == 1) {
            this.getOrderList(this.page);
          } else {
            this.otherOrder(this.selected, this.page, this.selected - 1);
          }
        } else {
          this.allLoaded = false; // 若数据已全部获取完毕
          Toast("没有更多了");
        }
        this.$refs.loadmore[this.selected - 1].onBottomLoaded();
      }, 500);
    },

    // 获取订单
    // type-> 订单类型， page-> 页码 ， i -> orderList 索引值 , listType -> 是否是点击事件
    otherOrder(type, page, i, listType) {
      var data = {
        user_id: this.user_id,
        //                user_id:'13554289325',
        type: type,
        page: page
      };

      // 发出POST 请求
      this.$ajax("post", "Service/get_service_order", data, res => {
        var orders = res.data.data;
        this.totalCount = orders.length;
        if (orders) {
          if (res.data.id == 0) {
            this.getOrderInfo(type, orders, i, listType);
          }
        } else {
          this.nocontent = true;
        }
      });
    },

    // 拒绝、接受、撤销订单
    // t-> 订单状态，id-> 订单ID
    setOrder(t, id) {
      var type = "";
      var msg = "";
      switch (t) {
        case 1:
          type = "0";
          msg = "接单";
          break;
        case 2:
          type = "0";
          msg = "拒绝订单";
          break;
        case 3:
          type = "1";
          msg = "订单撤销";
          break;
      }
      var data = {
        user_id: this.user_id,
        order_id: id,
        status: t,
        type: type
      };
      MessageBox({
        title: "提示",
        message: "确认" + msg + "吗？",
        showCancelButton: true
      }).then(action => {
        if (action == "confirm") {
          this.$ajax("post", "Service/setServiceOrderStatus", data, res => {
            if (res.data.id == 0) {
              Toast(msg + res.data.msg);
              window.location.reload();
            } else {
              Toast(res.data.msg);
            }
          });
        }
      });
    },

    // 删除订单
    // id-> 订单ID，index -> 订单索引值
    deleteOrder(id, index) {
      var data = {
        order_id: id,
        user_id: this.user_id
      };
      MessageBox({
        title: "提示",
        message: "确认删除吗？",
        showCancelButton: true
      }).then(action => {
        if (action == "confirm") {
          this.$ajax("post", "Service/DeleteServiceOrder", data, res => {
            if (res.data.id == 0) {
              Toast(res.data.msg);
              this.orderLists[this.selected - 1].content.splice(index, 1);
              if (this.orderLists[this.selected - 1].content.length == 0) {
                this.nocontent = true;
              }
            }
          });
        }
      });
    }
  },
  watch: {
    /**
     * 选择其他状态的订单
     * @param type 1->全部订单,2->待付款,3->待确认,4->待出发,5->出行中,6->已完成
     */
    selected: function(type) {
      var type = type;

      // 取得orderLists的index值
      var i = type - 1;

      this.listType = "click";

      // 用户ID
      if (this.hybrid.hybrid.versions.myapp) {
        // FOR 安卓最美旅行家APP
        $(".mint-header").remove();
        $(".page-content").css("padding", "0");

        this.user_id = JavaScriptInterface.getUserId();
        this.otherOrder(type, this.page);
      } else if (this.hybrid.hybrid.versions.myiOSapp) {
        // FOR IOS最美旅行家APP

        $(".mint-header").remove();
        $(".page-content").css("padding", "0");

        this.hybrid.hybrid.registerHandler("getIOSUserId", function(
          data,
          responseCallback
        ) {
          this.user_id = data.user_id;
          this.otherOrder(type, this.page);

          var responseData = "已经收到IOS传来的userid";
          responseCallback(responseData);
        });
      } else {
        // For web

        this.user_id = this.get_User_Id();
        this.otherOrder(type, this.page, i, this.listType);
      }
    }
  }
};
</script>

<style scoped>
.mint-header {
  background: #ff9a80;
}
.order-btn {
  margin-top: 10px;
}
.order-btn button {
  float: right;
  width: 75px;
  height: 30px;
  font-size: 12px;
  margin-right: 10px;
}
.order-btn button:first-child {
  margin-right: 0;
}
#orders >>> .is-selected {
  color: #ff9a80;
  border-bottom: 3px solid #ff9a80;
}
.order-content {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  background-repeat: no-repeat;
  background-size: 100px 100px;
  background-position: 50% 20%;
}
.order-content >>> .mint-tab-container {
  padding-bottom: 10%;
}
.order-item {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  position: relative;
  z-index: 2;
}
.order-item .item-wrap {
  margin-bottom: 10px;
}
.order-item .item-icon {
  float: left;
  width: 30px;
  height: 30px;
  border-radius: 3px;
}

.order-item .order-name {
  float: left;
  width: 33%;
  color: #333;
  font-size: 14px;
  /*margin-left: 10px;*/
  line-height: 30px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.order-item .order-time {
  float: right;
  font-size: 12px;
  line-height: 30px;
  color: #cdcdcd;
}
.order-item .order-time i {
  float: right;
  width: 20px;
  height: 30px;
  background: url("../../assets/img/tour_ashcan.png") no-repeat;
  background-size: 20px 20px;
  background-position: 0 50%;
  margin-left: 10px;
}
.order-item .user-img {
  float: left;
  width: 45px;
  height: 45px;
}
.order-item .user-img img {
  width: 100%;
  height: 100%;
}
.order-item .user-name,
.order-item .order-num {
  margin-left: 10px;
  display: inline-block;
  font-size: 10px;
  color: #666;
}
.order-item .order-price,
.order-item .order-status {
  float: right;
  font-size: 14px;
  color: #ff9c00;
}
</style>