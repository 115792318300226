import { render, staticRenderFns } from "./PixiMap.vue?vue&type=template&id=e2ddb958&scoped=true&"
import script from "./PixiMap.vue?vue&type=script&lang=js&"
export * from "./PixiMap.vue?vue&type=script&lang=js&"
import style0 from "./PixiMap.vue?vue&type=style&index=0&id=e2ddb958&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2ddb958",
  null
  
)

export default component.exports