<template>
  <div class="LineInfoList LineInfoListone" v-title :data-title="wx_title+'最美路书'">
    <div class="hidden">
      <img :src="wx_img" />
    </div>
    <a slot="left">
      <mt-button icon="back" @click="$router.go(-1)"></mt-button>
    </a>
    <!--page-content样式完成-->
    <div class="page-content">
      <div class="layout-auto reg-trip-detail">
        <div class="mod-timeline-trip">
          <h1 class="trip-title">{{info.label_name}}</h1>
          <ul @click="open_big_map(user_id,label_id)" class="map_info clearfix">
            <li>
              <p>总里程</p>
              <p>{{info.length}}</p>
            </li>
            <li>
              <p>最高海拔</p>
              <p>{{info.height}}</p>
            </li>
            <li>
              <p>最低海拔</p>
              <p>{{info.low}}</p>
            </li>
            <li>
              <p>徒步时间</p>
              <p>{{info.time_length}}</p>
            </li>
          </ul>
          <div class="timeline js-timeline">
            <div class="start js-trip-start" @click="click_scroll(info.spots_list[0].id)">
              <i class="icon-detail-fn-1">
                <i class="txt">行程提醒</i>
              </i>
              <i class="icon-detail-fn-2">
                <i class="txt">语音导览</i>
              </i>
              <i
                @click.stop="$router.push({path: '/footprints/LineInfoListtwo',query: {user_id: user_id, label_id: label_id,container_big:true}})"
                class="icon-detail-fn-3"
              >
                <i class="txt">一键导航</i>
              </i>
              <span class="line-1"></span>
              <span class="line-2"></span>
              <span class="line-3"></span>
            </div>
            <div class="days js-days">
              <!--循环输出每一个点-->
              <div class="day-info" v-for="(k,i) in info.spots_list" :key="k.id" :id="k.id">
                <!--如果是第一个节点,前面就不显示两个点之间的步行，驾车等方式信息-->
                <div class="day-item traffic" :class="{'left':i%2 == 0}" v-if="i!=0">
                  <!--两个点之间的数据,点击进入地图页面-->
                  <a href="javascript:;" @click="line_info(i)">
                    <div class="cell-pic-lr clearfix">
                      <div class="c-img fl">
                        <i
                          class="iconfont"
                          :class="{'icon-buhang':k.is_car==0,'icon-cheliang':k.is_car==1}"
                        ></i>
                        <span class="type" v-if="k.is_car==0">步行</span>
                        <span class="type" v-if="k.is_car==1">驾车</span>
                      </div>
                      <div class="c-txt">
                        <p>
                          <span class="type">距离：{{k.two_length}}</span>
                        </p>
                        <p>
                          <span class="time">用时：{{k.two_time}}</span>
                        </p>
                        <p class="desc">点击查看路线</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="day-item poa" :class="{'left':i%2 == 0}">
                  <div class="type">
                    <img src="../../assets/img/map_lord_park.png" alt v-if="k.audio_path != ''" />
                    <img src="../../assets/img/map_park_all.png" alt v-else-if="k.label_id == 0" />
                    <!--足迹-->
                    <img
                      src="../../assets/img/map_park_lavatory.png"
                      alt
                      v-else-if="k.label_id == 17"
                    />
                    <!--厕所-->
                    <img src="../../assets/img/map_park_eat.png" alt v-else-if="k.label_id == 18" />
                    <!--吃-->
                    <img src="../../assets/img/map_park_scene.png" alt v-else-if="k.label_id == 19" />
                    <!--景-->
                    <img src="../../assets/img/map_park_buy.png" alt v-else-if="k.label_id == 20" />
                    <!--购-->
                    <img
                      src="../../assets/img/map_park_cease_yes.png"
                      alt
                      v-else-if="k.label_id == 21"
                    />
                    <!--住-->
                    <img src="../../assets/img/footBoutique.png" alt v-else-if="k.label_id == 276" />
                    <!--精品-->
                  </div>
                  <div class="cell-pic-tb">
                    <div class="c-img" v-if="k.url != ''">
                      <!--判断是否有外部的url，有就直接跳转-->
                      <img :src="k.newUrl" @click="go_other_url(k.url)" />
                      <div class="c-title">{{k.spots_name}}</div>
                      <div class="img_n">共{{k.img_numbers}}张</div>
                    </div>
                    <div class="c-img" v-else>
                      <!--进入景点详情-->
                      <router-link
                        :to="{path: '/footprints/PrimaryScenic', query: {sid: k.id, id_: 0}}"
                        class="item"
                        v-if="k.label_id == 19"
                      >
                        <img :src="k.newUrl" />
                        <div class="c-title">{{k.spots_name}}</div>
                        <div class="img_n">共{{k.img_numbers}}张</div>
                      </router-link>
                      <!--进入足迹详情-->
                      <router-link
                        :to="{path: '/footprints/FootprintsParticulars', query: {user_id: k.user_id, id: k.id}}"
                        class="item"
                        v-else
                      >
                        <img :src="k.newUrl" />
                        <div class="c-title">{{k.spots_name}}</div>
                        <div class="img_n">共{{k.img_numbers}}张</div>
                      </router-link>
                    </div>
                    <div class="c-txt">
                      <div class="fn-list">
                        <router-link
                          :to="{path: '/footprints/PrimaryScenic', query: {sid: k.id, id_: 0}}"
                          class="item"
                          v-if="0"
                        >
                          <i class="iconfont icon-chakanxiangqing"></i>
                          <p class="desc">查看详情</p>
                        </router-link>
                        <!--跳转到商户的URL
                                                :to="{path: '/footprints/FootprintsParticulars', query: {user_id: k.user_id, id: k.id}}"

                        -->
                        <div class="item" @click="openLocation(k.lng,k.lat,k.spots_name,k.address)">
                          <i class="iconfont icon-daohang"></i>
                          <p class="desc">导航</p>
                        </div>
                        <!--判断是否有音频-->
                        <div
                          v-if="k.audio_path != ''"
                          class="item"
                          @click="play($event,k.id)"
                          :index="k.index"
                        >
                          <audio :src="k.audio_path" :id="'audio'+ k.id"></audio>
                          <i
                            class="iconfont"
                            :class="{
                                                        'icon-zanting1':k.active,
                                                       'icon-erji':!k.active}"
                            :index="k.index"
                          ></i>
                          <p class="desc" :index="k.index">语音导览</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--添加获奖信息-->
      <div class="award" v-if="have_win">
        <!--奖品数据-->
        <div class="award-info">
          <div class="award-title">
            <h2>{{info.win.title}}</h2>
          </div>
          <!--主题图片-->
          <div class="award-theme-img">
            <img :src="info.win.theme_img" />
          </div>
          <!--获奖证书四个字-->
          <div class="win-text">完赛证书</div>
          <div class="award-win">
            <!--队编号-->
            <div class="award-group-id">
              <span>编号:</span>
              <span>{{info.win.group_id}}</span>
            </div>
            <!--队名-->
            <div class="award-group-name">
              <span>队名:</span>
              <span>{{info.win.group_name}}</span>
            </div>
            <!--排名-->
            <div class="award-rank">
              <span>排名:</span>
              <span>{{info.win.rank}}</span>
            </div>
            <!--成绩-->
            <div class="award-time-length">
              <span>成绩:</span>
              <span>{{info.win.time_length}}</span>
            </div>
          </div>
          <!--团队成员-->
          <div class="viewer-list" v-if="info.join_user_list.length > 0">
            <div class="box">
              <div class="avatar" v-for="(icon, i) in info.join_user_list" :key="i">
                <router-link
                  :to="{name:'otherIndex', params: { uid: icon.user_id }}"
                  v-if="icon.user_id != user_id"
                >
                  <div class="img" v-imgBg="icon.user_icon" v-if="icon.user_icon"></div>
                  <div class="img" v-else></div>
                </router-link>
                <a href="javascript:;" v-else>
                  <div class="img" v-imgBg="icon.user_icon" v-if="icon.user_icon"></div>
                  <div class="img" v-else></div>
                </a>
              </div>
            </div>
          </div>
          <!--举办单位-->
          <div class="award-organizer">
            <h3>举办单位:</h3>
            <div class="award-organizer-list" v-for="(org, i) in info.win.organizer_list" :key="i">
              <h4>{{org.title}}</h4>
            </div>
          </div>
        </div>
      </div>
      <!--显示完成任务点的列表-->
      <div class="award-list" v-if="have_complete_point">
        <h2>完成任务点:</h2>
        <div class="rank-list" v-for=" (rank,i) in info.complete_list" :key="i">
          <ul class="rank-list-one">
            <span>
              {{rank.title}}
              <img
                src="../../assets/img/complete.png"
                v-if="rank.is_complete == 1"
                class="complete-img"
              />
            </span>
          </ul>
        </div>
      </div>
      <!--完赛路书排名列表-->
      <div class="award-list" v-if="have_reward_list">
        <h2>比赛排行榜:</h2>
        <div class="rank-list" v-for=" (rank, i) in info.reward_list" :key="i">
          <ul
            class="rank-list-one"
            @click="$router.push({path: '/footprints/RootBooksInfo',query: {user_id:'', label_id: rank.root_id,show_vote:show_vote ? 1: 0}})"
          >
            <span>{{rank.title}}</span>
            <span>{{rank.group_id}}</span>
            <span>{{rank.group_name}}</span>
            <span>{{rank.time_length}}</span>
            <span>查看路书</span>
          </ul>
        </div>
      </div>
      <!--未参赛路书排名列表,只完成了部分点的路书-->
      <div class="award-list" v-if="have_join_root_books">
        <h2>未完赛的路书:</h2>
        <div class="rank-list" v-for=" (rank, i) in info.join_root_books_list" :key="i">
          <!--<div class="rank-list-one">-->
          <ul
            class="rank-list-one"
            @click="$router.push({path: '/footprints/RootBooksInfo',query: {user_id:'', label_id: rank.root_id,show_vote:show_vote ? 1: 0}})"
          >
            <span>{{rank.title}}</span>
            <span>{{rank.group_id}}</span>
            <span>{{rank.group_name}}</span>
            <span>{{rank.time_length}}</span>
            <span>查看路书</span>
          </ul>
        </div>
      </div>
      <!--行程目录-->
      <div class="mod-trip-index js-trip-index" @click="is_mod_trip = !is_mod_trip" v-if="is_mod">
        <div class="title">行程目录</div>
        <!--这里通过is_mod_trip的值动态的显示列表菜单列表-->
        <div class="cell-list-scroll" v-show="is_mod_trip">
          <div class="c-txt">
            <div
              data-day="0"
              class="c-item"
              v-for="(m,index) in info.spots_list"
              :key="m.id"
              @click="click_scroll(m.id)"
            >
              <em class="day" v-if="index == 0">起</em>
              <em class="day" v-else-if="index ==(info.spots_list.length-1)">终</em>
              <em class="day" v-else>{{index + 1}}</em>
              {{m.spots_name}}
            </div>
          </div>
        </div>
      </div>
      <!--20180316 投票按钮-->
      <!--<div class="mod-trip-index-show-vote" v-if="show_vote && 1">
                <div :class="{'no': (info.show == 0)|| (info.show == 1 && info.vote == 1) || (info.show == 3) }">投票</div>
                &lt;!&ndash; class=show-vote &ndash;&gt;
      </div>-->
      <div
        class="mod-trip-index-show-vote"
        v-if="show_vote && is_weixin"
        @click="vote_aid_user_id"
        :class="{'no': (bShow == 0)|| (bShow == 1 && bVote == 1) || (bShow == 3) }"
      >
        <span v-if="bShow == 0">投票未开始</span>
        <span v-else-if="bShow == 1 && bVote == 0">我要投票</span>
        <span v-else-if="bShow == 1 && bVote == 1">已投票</span>
        <span v-if="bShow == 3">投票已结束</span>
      </div>
      <!-- 已投票的人 -->
      <div class="who_vote" v-if="info.have_vote_user != 0">
        <h1>投票数：{{vote_numbers}}</h1>
        <div class="row" v-for="(t,i) in info.vote_user" :key="i">
          <div class="time">{{t[0].add_time}}</div>
          <div class="list">
            <img :src="k.user_icon" v-for="(k,i) in t" :key="i" />
          </div>
        </div>
      </div>
      <!--招商信息-->
      <div class="advert" v-if="info.have_activity_content">
        <input type="hidden" id="aid" v-model="info.aid" />
        <div class="advert_img" v-if="info.advert_url != '' ">
          <router-link :to="{path:'/activity/ActivityDetail',query:{aid: info.aid}}">
            <img :src="info.advert_url" />
          </router-link>
        </div>
        <div class="content" v-html="info.activity_content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      label_id: this.$route.query.label_id, //路书的ID
      user_id: this.$route.query.user_id ? this.$route.query.user_id : "", //用户的ID
      info: {},
      is_mod: false, //是否显示行程目录
      is_mod_trip: false, //是否显示行程目录列表
      isPlaying: false,
      index: -1, //用于记录路书行程菜单的序号
      have_win: false, //显示完赛证书
      have_reward_list: false, //显示排行榜
      is_weixin: this.hybrid.hybrid.versions.is_weixin, //-------------注意
      bShow: "", //this.$route.query.bShow,//是否开始投票了
      bVote: "", //this.$route.query.bVote,//投票的状态,未投,已投
      union_id: localStorage.getItem("open_id") ? localStorage("open_id") : "",
      aid: this.$route.query.aid ? this.$route.query.aid : 0,
      show_vote: this.$route.query.show_vote ? true : false, //从投票页面进来,表示在路书界面显示投票按钮
      wx_title: "",
      wx_content: "",
      wx_img: "",
      wx_url:
        "http://www.zmlxj.com/api.php/weixin/wx_share?type=LineInfoList&label_id=" +
        this.$route.query.label_id +
        "&show_vote=" +
        this.show_vote +
        "&user_id=" +
        this.$route.query.user_id +
        "&aid=" +
        this.$route.query.aid,
      vote_numbers: 0, //投票数
      have_complete_point: false, //是否显示完成任务点的信息
      have_join_root_books: false, //是否存在参加路书,就是没有完成所有任务点的路书
      price: 0, //导航和语音讲解的费用
      is_pay: 0, //是否支付
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      version: "1.0.0",
      park_id: 298
    };
  },
  mounted() {
    if (this.token) {
    } else {
      //第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    console.log("token=" + token);
    //投票相关
    if (
      this.union_id &&
      !(
        !this.union_id &&
        typeof this.union_id != "undefined" &&
        this.union_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有union_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.union_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.union_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.union_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }
    //在冒泡阶段处理滚动的事件
    window.addEventListener("scroll", this.handleScroll);
    this.unload(); //
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
      this.wx_share(window.location.href);
    }
  },
  methods: {
    unload() {
      //如果用户没有登录-跳到登录页面
      //console.log("show_vote:"+this.show_vote);
      if (this.show_vote) {
        //不需要登录
      } else {
        if (!this.user_id) {
          if (this.check_login()) {
            this.user_id = this.check_login().user_id;
            //console.log("user_id:"+this.user_id);
          } else {
            //                        localStorage.setItem('login_url', location.hash);
            //                        this.$router.push({path: '/my/login', query: {log_type: 1}})
            //                        return;
          }
        }
      }

      //如果是在app中打开的移动站
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      //发送请求获取路书数据
      this.$ajax(
        "post",
        "foot/get_one_user_collect_spots_info",
        {
          user_id: this.user_id,
          label_id: this.label_id,
          union_id: this.union_id
        },
        res => {
          if (res.data.id == 0) {
            this.info = res.data.data; //返回的数据
            this.is_pay = res.data.data.is_pay;
            this.price = res.data.data.price;
            this.bShow = res.data.data.show;
            this.bVote = res.data.data.vote;
            this.vote_numbers = res.data.data.vote_numbers
              ? res.data.data.vote_numbers
              : 0;
            for (var i = 0; i < this.info.spots_list.length; i++) {
              //给数据增加两个字段
              this.$set(this.info.spots_list[i], "active", false);
              this.$set(this.info.spots_list[i], "index", i);
            }
            if (res.data.data.have_win == 1) {
              this.have_win = true;
            } else {
              this.have_win = false;
            }
            if (res.data.data.have_reward_list == 1) {
              this.have_reward_list = true;
            } else {
              this.have_reward_list = false;
            }
            if (res.data.data.have_join_root_books == 1) {
              //说明有参加路书,其实就是没有完成所有点的路书
              this.have_join_root_books = true;
            } else {
              this.have_join_root_books = false;
            }
            if (res.data.data.have_complete_point == 1) {
              //判断是否显示完成点位的信息
              this.have_complete_point = true;
            } else {
              this.have_complete_point = false;
            }
            //this.have_win = res.data.data.have_win ? true : false;
            //this.have_reward_list = res.data.data.have_reward_list ? true : false;
            //微信分享
            localStorage.setItem("wx_content", res.data.data.wx_content);
            localStorage.setItem("wx_title", res.data.data.label_name);
            localStorage.setItem(
              "wx_img",
              res.data.data.spots_list[0].android_newUrl
            );
          }
          //console.log(this.have_win);
        }
      );
    },
    //获取页面滚动的高度
    //设置底部是否显示路书行程列表
    handleScroll() {
      //pageYOffset 表示在Y轴的移动值
      //document.documentElement //返回文档的根节点
      // .scrollTop //是否滚到顶部
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 400) {
        this.is_mod = true;
      } else {
        this.is_mod = false;
      }
    },
    //点击行程目录列表中的任何一个节点滚动到节点指定的位置
    //传入被点击的节点的序号
    click_scroll(index) {
      var element = document.getElementById(index);
      //下面的代码表示让滚动跳转到指定的高度
      $("html, body").animate(
        { scrollTop: element.offsetTop + 200 + "px" },
        500
      );
    },
    line_info(i) {
      //slice(begin,end)返回begin与end之间的数据
      this.info.spots_list = this.info.spots_list.slice(i - 1, i + 1);
      this.info.spots_point_list = this.info.spots_point_list.slice(
        i - 1,
        i + 1
      );
      //                console.log(this.info);
      this.$router.push({
        path: "/footprints/LineInfoListtwo",
        query: {
          user_id: this.user_id,
          label_id: this.label_id,
          data: JSON.stringify(this.info)
        }
      });
    },
    //            点击语音导览
    play(e, id) {
      var that = this;
      //循环设置所有的active属性为flase
      this.info.spots_list.forEach(function(data, i) {
        that.$set(that.info.spots_list[i], "active", false);
      });
      //	document.querySelectorAll() 是 HTML5中引入的新方法，返回文档中匹配的CSS选择器的所有元素节点列表
      var audio_all = document.querySelectorAll("audio");
      var audio = document.getElementById("audio" + id);
      //暂停所有的音频
      for (var i = 0; i < audio_all.length; i++) {
        audio_all[i].pause();
        audio_all[i].currentTime = 0;
      }
      //这里没看懂e.target //目标，目的，对象
      //应该是得到e对象的属性
      if (e.target.getAttribute("index") == this.index) {
        if (this.isPlaying) {
          console.log("暂停");
          audio.pause();
          this.isPlaying = false;
        } else {
          console.log("播放");
          audio.play();
          this.isPlaying = true;
          this.$set(
            this.info.spots_list[e.target.getAttribute("index")],
            "active",
            true
          );
        }
      } else {
        console.log("播放");
        audio.play();
        this.isPlaying = true;
        this.$set(
          this.info.spots_list[e.target.getAttribute("index")],
          "active",
          true
        );
      }
      this.index = e.target.getAttribute("index");
    },

    //点击进行投票
    /*规则：
     * 传递aid+user_id
     * 在查询这个活动所有的足迹，然后按照每个足迹的拍照时间进行投票
     * 回调地址：label_id=491&show_vote=1&bShow=1&bVote=0&spot_id=5411&aid=72&user_id=3a9bedd6305de2e1b0acf06502f534a5582e67dabfd96b7d8e581a1d82a076bc
     * */
    vote_aid_user_id() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login?aid=" +
        this.$route.query.aid +
        "&show_vote=" +
        this.show_vote +
        "&bShow=" +
        this.bShow +
        "&bVote=" +
        this.bVote +
        "&user_id=" +
        this.user_id +
        "&state=is_root_books&label_id=" +
        this.label_id +
        "&is_root_books=1";
      if (this.bShow == 0) {
        Toast("投票未开始");
      } else if (this.bShow == 3) {
        Toast("投票已结束");
      } else if (this.bShow == 1 && this.bVote == 1) {
        Toast("今日已投票");
      } else {
        console.log(this.union_id + "-" + this.aid + "-" + this.user_id);
        console.log(open_url);
        if (
          this.union_id &&
          !(
            !this.union_id &&
            typeof this.union_id != "undefined" &&
            this.union_id != 0
          )
        ) {
          this.$ajax(
            "post",
            "SpotsRace/vote_root_books",
            {
              aid: this.aid,
              user_id: this.user_id,
              union_id: this.union_id
            },
            ret => {
              if (ret.data.id == 0) {
                this.bVote = 1;
                Toast({
                  message: ret.data.msg,
                  iconClass: "iconfont icon-chenggong",
                  duration: 1000
                });
              } else {
                Toast({
                  message: ret.data.msg,
                  iconClass: "iconfont icon-fail",
                  duration: 1000
                });
                //获取授权
                window.location.href = open_url;
              }
            }
          );
        } else {
          //console.log(this.is_weixin);
          if (this.is_weixin) {
            //表示在微信打开了页面-直接回调到某一个作品页面中去
            window.location.href = open_url;
            //window.location.href ="www.zmlxj.com/api.php/SpotsRace/weixin_login?aid="+this.$route.query.aid;
          } else {
            //不是在微信打开的页面不用管
            console.log("not in weixin");
          }
        }
      }
    },

    /*进入地图页面,进行路线规划*/
    open_big_map(user_id, label_id, is_have_price) {
      if (is_have_price) {
        //表示需要支付,才能查看路线信息,不需要用户登录注册
      } else {
        //免费的路线,不需要进行支付,可以直接进行使用
        this.$router.push({
          path: "/footprints/LineInfoListtwo",
          query: { user_id: user_id, label_id: label_id, container_big: true }
        });
      }
    },
    /*跳转到外部网页*/
    go_other_url(url) {
      window.location.href = url;
    },
    /*进行导航*/
    openLocation(lng, lat, title, address) {
      if (this.is_weixin) {
        if (this.price) {
          //收费的路书
          if (this.is_pay) {
            //已经支付了，可以直接使用导航
            this.wx_nav(window.location.href, lng, lat, title, address);
          } else {
            //还未支付
            if (this.union_id) {
              var open_url =
                "https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=" +
                this.label_id +
                "&open_id=" +
                this.union_id +
                "&token=" +
                this.token +
                "&park_id=" +
                this.park_id +
                "&version=" +
                this.version +
                "&user_id=" +
                this.user_id;
              window.location.href = open_url;
            } else {
              //用户还未授权，跳转到授权页面,进行授权
              this.authorize();
            }
          }
        } else {
          //免费路书,直接导航
          this.wx_nav(window.location.href, lng, lat, title, address);
        }
      } else {
        Toast("请在微信中打开,使用导航功能");
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=" +
        this.token +
        "&id=" +
        this.label_id +
        "&state=is_root_books";
      window.location.href = open_url;
    }
  }
};
</script>
<style scoped>
.advert {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 10%;
}
.advert .advert_img {
  display: inline-block;
  position: relative;
  width: 98%;
  margin-left: 1%;
}
.advert .advert_img img {
  width: 100%;
}
.advert .content {
  display: inline-block;
  position: relative;
  width: 98%;
  margin-left: 1%;
}
.advert .content img {
  max-width: 100%;
}
/*任务点是否完成图片*/
.complete-img {
  width: 22px;
  height: 22px;
  /* text-align: center; */
  float: right;
  position: relative;
  margin-top: 5px;
  margin-left: 6px;
}
/*20180316投票用户的信息*/
.page-content .who_vote {
  font-size: 0;
  margin: 0.5rem;
}

.page-content .who_vote h1 {
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
}

.page-content .who_vote .row {
  margin-top: 0.1rem;
  height: 1.2rem;
  overflow: hidden;
}

.page-content .who_vote .time {
  font-size: 0.7rem;
  height: 1.2rem;
  line-height: 1.2rem;
  color: #fff;
  float: left;
  margin-right: 0.2rem;
}

.page-content .who_vote .list {
  height: 1.2rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.page-content .who_vote img {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
  border-radius: 50%;
}
/*这是20180106添加的奖品数据*/
.award {
  margin-top: 20px;
  background-color: #fff;
  border: 16px solid pink;
  padding: 2px;
  height: auto;
}
/*活动题目*/
.award-info .award-title {
  /*background-color:red;*/
  /*border:1px dotted red;*/
  text-align: center;
  color: red;
}
/*主题图片*/
.award-info .award-theme-img {
  /*background-color: red;*/
  /*margin:0 auto;*/
  margin-top: 10px;
  /*vertical-align: middle;
        display: table-cell;*/
  text-align: center;
}
.award-info .award-theme-img img {
  margin: 0 auto;
  display: block;
  width: 100%;
}
/*获奖证书*/
.award-info .win-text {
  margin: 0 auto;
  color: #bbbb00;
  font-size: 67px;
  text-align: center;
  line-height: 83px;
  /*background-color: pink;*/
}
/*奖状下面的文字信息*/
.award-info .award-win {
  height: 182px;
  /*background-color: red;*/
  padding-left: 60px;
  margin-top: 18px;
  color: red;
}
/*设置4行文字每一行的行高*/
.award-info .award-win div {
  margin-bottom: 10px;
}
/*.award-info .award-group-id
    {
        height:40px;
        background-color: red;
        padding-left: 60px;
    }*/
/*奖状中所有的第2个span标签向右漂浮*/
.award-info span:nth-child(2) {
  float: right;
  margin-right: 100px;
}
.award-info span {
  text-decoration: underline;
  font-size: 2em;
}
/*团队成员头像*/
.award-info .award-join-user-list {
  height: 2em;
  background-color: red;
  /*display: inline-block;*/
}
.award-info .join-user-box {
  margin: 0 5px 5px 0;
  vertical-align: top;
  position: relative;
  border: 1px solid #ff9a80;
  box-sizing: border-box;
  border-radius: 50%;
}
.award-info .viewer-list {
  height: 3em;
}
/*举办单位*/
.award-info .award-organizer {
  height: 3em;
  /*background-color:red;*/
  color: black;
  line-height: 3em;
  display: inline-block;
}
.award-info .award-organizer-list {
  display: inline-block;
  margin-right: 20px;
}
/*排名列表*/
.award-list {
  min-height: 100px;
  /*height:auto;*/
  /*background-color: red;*/
  padding-top: 10px;
}
.award-list .rank-list-one {
  height: 30px;
  background-color: pink;
  margin-bottom: 10px;
  line-height: 30px;
  margin-top: 10px;
}
.award-list .rank-list-one span {
  float: left;
  margin-left: 20px;
}
/**
    ------------------------------------------------------------
     */
.LineInfoList {
  min-height: 100%;
  background-color: #fff;
}

.LineInfoListone.LineInfoList {
  background-color: #ff9a80;
  color: #fff;
}

.LineInfoListone.LineInfoList > a {
  position: fixed;
  top: 10px;
  left: 10px;
}

.LineInfoListone .mint-button--default {
  border: none;
  background: #fff;
  color: #ff9a80;
  border-radius: 50%;
  height: 1.6rem;
  width: 1.6rem;
  box-sizing: border-box;
  padding: 0;
}

.LineInfoListone.LineInfoList ul.map_info {
  display: flex;
  background-color: #fff;
  color: #f79980;
  padding: 0.4rem 0;
}

.LineInfoListone.LineInfoList ul.map_info li {
  flex: 1;
  text-align: center;
  border-right: 1px dashed #f79980;
}

.LineInfoListone.LineInfoList ul.map_info li:last-child {
  border: none;
}

.LineInfoListone.LineInfoList ul.map_info li p {
  line-height: 1.2rem;
}

.LineInfoListone.LineInfoList ul.map_info li p:last-child {
  font-weight: 700;
}

.mod-timeline-trip .trip-title {
  margin: 0 auto;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  margin-bottom: 1rem;
}

.mod-timeline-trip .timeline {
  position: relative;
  background: #e83a17;
  width: 0.2rem;
  margin: 7rem auto 0;
  padding-top: 1.3rem;
  min-height: 30rem;
}

.mod-timeline-trip .start {
  position: relative;
  border: 0 solid rgba(255, 255, 255, 0.2);
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  border-radius: 50%;
  top: -3rem;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5rem;
}

.mod-timeline-trip [class^="line-"] {
  transform-origin: 0 bottom;
  transform: scale(0);
  width: 0.1rem;
  height: 5.2rem;
  background: #fff;
  opacity: 0.2;
  display: block;
  position: absolute;
  left: 1.5rem;
  top: -3.5rem;
}

.mod-timeline-trip .line-1 {
  animation: 0.6s el-growth-1;
  animation-timing-function: cubic-bezier(0.55, 0.27, 0.4, 1.49);
  animation-fill-mode: forwards;
  animation-delay: 800ms;
}

.mod-timeline-trip .line-2 {
  animation: 0.6s el-growth-2;
  animation-timing-function: cubic-bezier(0.55, 0.27, 0.4, 1.49);
  animation-fill-mode: forwards;
  animation-delay: 1060ms;
}

.mod-timeline-trip .line-3 {
  animation: 0.6s el-growth-3;
  animation-timing-function: cubic-bezier(0.55, 0.27, 0.4, 1.49);
  animation-fill-mode: forwards;
  animation-delay: 1580ms;
}

.mod-timeline-trip .start:before {
  z-index: 2;
  content: "\00a0";
  position: absolute;
  width: 3rem;
  height: 3rem;
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.2) 75%,
    #fff 100%
  );
  border-radius: 50%;
  animation-timing-function: ease-out;
  animation: 1.5s shadow-shrink infinite;
  transform: scale(1.3);
}

.mod-timeline-trip .start:after {
  z-index: 2;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  content: "启程";
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #fff, #fefeff);
  border: 0.12rem solid #fff;
  color: #ff3400;
  font-size: 0.88rem;
  font-weight: 700;
  border-radius: 50%;
  text-align: center;
  line-height: 2.5rem;
  text-shadow: 1px 1px rgba(10, 2, 4, 0.2);
}

.mod-timeline-trip .start .txt {
  position: absolute;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 0.65rem;
  min-width: 100%;
  text-align: center;
  white-space: nowrap;
  text-shadow: 1px 1px rgba(10, 2, 4, 0.2);
  top: -0.7rem;
  animation: 0.7s el-opacity;
  animation-timing-function: cubic-bezier(0.73, 0.22, 0.59, 0.69);
  animation-fill-mode: forwards;
  animation-delay: 2540ms;
  line-height: 0;
  opacity: 0;
}

.icon-detail-fn-1 .txt {
  left: -0.1rem;
}

.icon-detail-fn-1,
.icon-detail-fn-2,
.icon-detail-fn-3 {
  position: absolute;
  box-shadow: 3px 4px 10px rgba(10, 2, 4, 0.2);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1;
  transform: scale(0);
  /*opacity: 0;*/
}

.icon-detail-fn-1 {
  left: 0rem;
  top: 0rem;
  animation: 0.6s el-move-to-1;
  animation-timing-function: cubic-bezier(0.55, 0.27, 0.4, 1.49);
  animation-fill-mode: forwards;
  animation-delay: 800ms;
  background: url("../../assets/img/line_journey.png") 0 0 no-repeat;
  background-size: cover;
}

.icon-detail-fn-2 {
  left: 0rem;
  top: 0rem;
  animation: 0.6s el-move-to-2;
  animation-timing-function: cubic-bezier(0.55, 0.27, 0.4, 1.49);
  animation-fill-mode: forwards;
  animation-delay: 1060ms;
  background: url("../../assets/img/line_multimedia.png") 0 0 no-repeat;
  background-size: cover;
}

.icon-detail-fn-3 {
  left: 0rem;
  top: 0rem;
  animation: 0.6s el-move-to-3;
  animation-timing-function: cubic-bezier(0.55, 0.27, 0.4, 1.49);
  animation-fill-mode: forwards;
  animation-delay: 1320ms;
  background: url("../../assets/img/line_map.png") 0 0 no-repeat;
  background-size: cover;
}

.mod-timeline-trip .day,
.mod-timeline-trip .top-day {
  box-sizing: border-box;
  width: 2.4rem;
  position: relative;
  left: -1.2rem;
  background: #fd0002;
  border-radius: 0.2rem;
  min-width: 1.4rem;
  height: 0.9rem;
  line-height: 0.9rem;
  text-align: center;
  color: #fff;
}

.mod-timeline-trip .top-day {
  top: 0.8rem !important;
  left: 1rem;
  transform: translateX(-50%);
  z-index: 2;
}

.util-hidden {
  visibility: hidden !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.mod-timeline-trip .day-info {
  padding-bottom: 1.4rem;
}

.mod-timeline-trip .traffic {
  box-sizing: border-box;
  padding: 0.2rem;
  min-width: 7rem;
  border-radius: 0.2rem;
  box-shadow: 0.06rem 0.08rem 0.2rem rgba(10, 2, 4, 0.2);
  background: #fff;
  position: relative;
  left: 2rem;
  margin: 0.5rem 0;
}

.mod-timeline-trip .traffic.left {
  left: -8.6rem;
}

.mod-timeline-trip .traffic:before {
  content: "\00a0";
  background: #e83a17;
  position: absolute;
  left: -2.15rem;
  top: 0.52rem;
  width: 0.48rem;
  height: 0.48rem;
  border-radius: 0.48rem;
}

.mod-timeline-trip .traffic.left:before {
  left: auto;
  right: -1.9rem;
}

.mod-timeline-trip .traffic:after {
  right: 100%;
  top: 35%;
  content: "\00a0";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 0.3rem solid rgba(0, 0, 0, 0);
  border-right-color: #fff;
  margin-top: -0.2rem;
}

.mod-timeline-trip .traffic.left:after {
  right: auto;
  left: 100%;
  border-right-color: rgba(0, 0, 0, 0);
  border-left-color: #fff;
}

.mod-timeline-trip .traffic a {
  font-size: 0.5rem;
  color: #333;
}

.mod-timeline-trip .traffic a .cell-pic-lr {
  display: flex;
}

.cell-pic-lr .c-img {
  min-width: 1.6rem;
  text-align: center;
  font-size: 0.7rem;
  color: #999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.cell-pic-lr .c-img i {
  color: #000;
  background-color: #e63917;
  border-radius: 50%;
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  font-size: 1rem;
}

.cell-pic-lr .c-txt {
  padding-left: 0.16rem;
  overflow: hidden;
  line-height: 1.2;
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cell-pic-lr .c-txt p {
  /*white-space: nowrap;*/

  font-size: 0.5rem;
}

.cell-pic-lr .c-txt p.desc {
  font-size: 0.7rem;
  color: #ff7a5f;
}

.mod-timeline-trip .poa {
  width: 7rem;
  position: relative;
  left: 1.6rem;
  margin: 1.2rem 0;
}

.mod-timeline-trip .poa.left {
  left: -8.5rem;
}

.mod-timeline-trip .poa:before {
  content: "\00a0";
  height: 0.14rem;
  width: 1.58rem;
  position: absolute;
  background: #e93a1d;
  top: 0.6rem;
  right: 100%;
}

.mod-timeline-trip .poa.left:before {
  left: 100%;
  right: auto;
}

.mod-timeline-trip .poa .type {
  position: absolute;
  right: 7.3rem;
  top: -0.5rem;
  height: 2.5rem;
}

.mod-timeline-trip .poa.left .type {
  right: -3rem;
}

.mod-timeline-trip .poa .type img {
  height: 100%;
}

.mod-timeline-trip .poa .cell-pic-tb {
  border-radius: 0.1rem;
  box-shadow: 0.03rem 0.04rem 0.1rem rgba(10, 2, 4, 0.2);
}

.cell-pic-tb .c-img {
  width: 100%;
  max-height: 100%;
  position: relative;
}

.cell-pic-tb .c-txt {
  width: 100%;
  background: #fff;
  border-radius: 0 0 0.1rem 0.1rem;
}

.cell-pic-tb .c-img img {
  border-radius: 0.1rem 0.1rem 0 0;
  display: block;
  width: 100%;
}

.cell-pic-tb .c-title {
  box-sizing: border-box;
  padding: 0.2rem 0.15rem 0.1rem;
  color: #fff;
  position: absolute;
  bottom: 0;
  line-height: 1.4;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.51) 51%,
    rgba(0, 0, 0, 0.65) 100%
  );
  width: 100%;
  font-size: 0.7rem;
}

.cell-pic-tb .img_n {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 0.2rem;
}

.cell-pic-tb .c-txt .fn-list:first-child {
  border-bottom: 1px solid #dadada;
}

.cell-pic-tb .c-txt .fn-list:last-child {
  border-bottom: 0;
}

.cell-pic-tb .c-txt .fn-list {
  zoom: 1;
  display: flex;
  padding: 0.3rem 0 0.2rem;
}

.cell-pic-tb .c-txt .item {
  text-align: center;
  border-right: 0.1rem solid #dadada;
  flex: 1;
  color: #333;
}

.cell-pic-tb .c-txt .item i {
  height: 0.5rem;
  font-size: 1.2rem;
  color: #e93a1d;
  line-height: 1rem;
}

.cell-pic-tb .c-txt .item:nth-of-type(2) i {
  color: #e93a1d;
}

.cell-pic-tb .c-txt .item .desc {
  margin-top: 0.24rem;
}

.amap-marker-label {
  border: 1px solid #ff9a80;
  border-radius: 0.4rem;
  font-size: 0.5rem;
  line-height: 1rem;
  /*height: 1rem;*/
  padding: 0 0.5rem;
  top: 2rem !important;
}

.LineInfoList.active {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.LineInfoList.active,
.LineInfoList.active .page-content,
.LineInfoList.active .container_box {
  height: 100%;
  box-sizing: border-box;
}

.LineInfoList .amap-touch-toolbar .amap-zoomcontrol {
  bottom: 10px;
}

.container_box .map_info {
  text-align: center;
  width: 90%;
  background-color: rgba(255, 154, 128, 0.6);
  border-radius: 4px;
  padding: 4px 6px;
  color: #fff;
  position: absolute;
  top: 10px;
  left: 2.5%;
  right: 2.5%;
  z-index: 0;
  font-size: 0.7rem;
}

.container_box .map_info li {
  width: 25%;
  border-right: 1px dashed #fff;
  box-sizing: border-box;
  float: left;
}

.container_box .map_info li:last-child {
  border: none;
}

.container_box .container_quanping {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 1.6rem;
  background-color: rgba(255, 154, 128, 0.6);
  display: inline-block;
  color: #fff;
  text-align: center;
  height: 1.6rem;
  line-height: 1.4rem;
  border-radius: 4px;
  font-size: 1rem;
}

.mod-trip-index {
  background: #ea381a;
  position: fixed;
  z-index: 999;
  border-radius: 0.2rem;
  left: 0.4rem;
  bottom: 0.4rem;
  width: 4rem;
  height: 1.8rem;
  line-height: 1.8rem;
  color: #ffbe22;
}
/*20190316 投票按钮*/
.mod-trip-index .title {
  text-align: center;
}
.mod-trip-index-show-vote {
  background: #ea381a;
  position: fixed;
  z-index: 999;
  border-radius: 0.2rem;
  right: 0.4rem;
  bottom: 0.4rem;
  width: 4rem;
  height: 1.8rem;
  line-height: 1.8rem;
  color: #ffbe22;
}
.mod-trip-index-show-vote {
  text-align: center;
}
.mod-trip-index-show-vote.no {
  background-color: #ccc;
  border-bottom: 0.2rem solid #999;
}
.mod-trip-index .cell-list-scroll {
  position: absolute;
  top: -13rem;
  background: #fff;
  border-radius: 0.1rem;
}

.cell-list-scroll .c-txt {
  overflow: auto;
  width: 9rem;
  height: 12rem;
}

.cell-list-scroll:after {
  top: 100%;
  left: 1.2rem;
  content: "\00a0";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 0.5rem solid rgba(0, 0, 0, 0);
  border-top-color: #fff;
  margin-left: 0.5rem;
}

.cell-list-scroll .c-item {
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.4rem;
  height: 1.4rem;
  line-height: 1.4rem;
  color: #333;
}

.cell-list-scroll .c-item .day {
  display: inline-block;
  width: 1.4rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.1rem;
  margin-right: 0.3rem;
  background: #ff6c00;
  text-align: center;
  font-size: 0.6rem;
  font-style: normal;
  color: #fff;
}

.cell-list-scroll .c-item:first-child .day {
  background: #fc0405;
}

.cell-list-scroll .c-item:last-child .day {
  background: #36b371;
}

.LineInfoList_list_box {
  padding: 5px 10px;
}

.LineInfoList_list_box li {
  padding: 5px 0;
}

.LineInfoList_list_box li .serial_number {
  width: 2rem;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  height: 1.6rem;
  line-height: 1.6rem;
}

.LineInfoList_list_box li .info_box {
  overflow: hidden;
}

.LineInfoList_list_box li .info_box .user_info h2 {
  font-size: 1rem;
  overflow: hidden;
  height: 1.6rem;
  line-height: 1.6rem;
  font-weight: 700;
}

.LineInfoList_list_box li .info_box .user_info img {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  margin-right: 10px;
}

.LineInfoList_list_box li .title {
  padding-top: 5px;
  line-height: 1rem;
  overflow: hidden;
  position: relative;
}

.LineInfoList_list_box li .title span {
  font-weight: 700;
  background: url(../../assets/img/track_name.png) left 3px no-repeat;
  background-size: 1.1rem;
  font-size: 0.7rem;
  color: #666;
  padding: 5px 0 5px 40px;
  margin-right: 10px;
  float: left;
}

.LineInfoList_list_box li .title span.active {
  max-height: 2.5rem;
}

.LineInfoList_list_box li span.more {
  background: #fff;
  padding: 0.2rem 0.4rem;
  color: #ff9a80;
  float: right;
}

.LineInfoList_list_box li .img_box {
  margin-top: 4px;
  position: relative;
}

.LineInfoList_list_box li .img_box p {
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
}

.LineInfoList_list_box li .img_box img {
  width: 100%;
  min-height: 263.78px;
  vertical-align: middle;
}

.LineInfoList_list_box .img_numbers {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
}

.LineInfoList_list_box .img_numbers span {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
}

/*启程光圈*/
@keyframes shadow-shrink {
  0% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1.3);
  }
}

/*行程提醒 线*/
@keyframes el-growth-1 {
  0% {
    transform: rotate(232deg) scale(0.1);
  }
  100% {
    transform: rotate(232deg) scale(1);
  }
}

@keyframes el-move-to-1 {
  0% {
    left: 0rem;
    top: 0rem;
    transform: scale(0.1);
  }
  100% {
    left: -4rem;
    top: 3.5rem;
    transform: scale(1);
  }
}

/*语音导览 线*/
@keyframes el-growth-2 {
  0% {
    transform: rotate(295deg) scale(0.1);
  }
  100% {
    transform: rotate(295deg) scale(1);
  }
}

@keyframes el-move-to-2 {
  0% {
    left: 0rem;
    top: 0rem;
    transform: scale(0.1);
  }
  100% {
    left: -4rem;
    top: -2rem;
    transform: scale(1);
  }
}

/*一键导航 线*/
@keyframes el-growth-3 {
  0% {
    transform: rotate(72deg) scale(0.1);
  }
  100% {
    transform: rotate(72deg) scale(1);
  }
}

@keyframes el-move-to-3 {
  0% {
    left: 0rem;
    top: 0rem;
    transform: scale(0.1);
  }
  100% {
    left: 5rem;
    top: -1rem;
    transform: scale(1);
  }
}

/*文字*/
@keyframes el-opacity {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
</style>