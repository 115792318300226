<template>
  <div class="TrackIndex" v-title :data-title="title" :class="{active:container_big}">
    <mt-header fixed :title="title">
      <a slot="left">
        <mt-button @click.native="back()" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="container_box" v-show="type=='parkFoot'">
        <div id="container" style="width:100%; height:100%"></div>
        <img
          class="red_packet_spread"
          @click="click_container_big"
          src="../../assets/img/red_packet_spread.png"
          alt
        />
        <div class="left_but">
          <div class="mak_tab" v-if="container_big">
            <!--点/图片 切换-->
            <img
              src="../../assets/img/map_park_tab_img.png"
              alt
              @click="click_mak_type"
              v-if="is_tab_button"
            />
            <img
              src="../../assets/img/map_park_tab_img_maks.png"
              alt
              @click="click_mak_type"
              v-if="!is_tab_button"
            />
            <img
              src="../../assets/img/map_3d.png"
              alt
              class="map_3d"
              @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
              v-if="is_tab_map_3d"
            />
            <img
              src="../../assets/img/map_3d_yes.png"
              alt
              class="map_3d"
              @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
              v-if="!is_tab_map_3d"
            />
          </div>
          <div class="mak_tab">
            <!--地图 + -  -->
            <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
            <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
          </div>
        </div>
      </div>
      <footprints-list @ievent="ievent" @getparkdata="getparkdata" ref="child"></footprints-list>
    </div>
    <div
      v-if="itemLen<0"
      style="text-align:center;color:#999;padding-top:60px;font-size: 14px;"
    >加载中...</div>
    <div class="foot_noinfo" v-else-if="itemLen==0">
      <img src="../../assets/img/foot_noinfo@2x.png" alt />
      <p>暂无数据</p>
    </div>
    <div class="preview-content" v-if="is_preview" @click="is_preview=false">
      <mt-swipe :auto="0" :showIndicators="true" :defaultIndex="index">
        <mt-swipe-item v-for="(item,i) in message" :key="i">
          <a href="javascript:;">
            <img :src="item.img_res+'&imageMogr/auto-orient'" alt />
          </a>
        </mt-swipe-item>
      </mt-swipe>
    </div>
    <!--发布足迹按钮-->
    <router-link
      :to="{path:'/footprints/FootprintsPublish',query:{aid:aid,park_id:park_id,park_name:park_name}}"
      tag="div"
      class="publish"
      v-if="is_show_publish"
    >
      <img src="../../assets/img/footprint_send.png" alt />
    </router-link>
  </div>
</template>
<script>
var centre_marker;
import { Toast } from "mint-ui";
import FootprintsList from "../../components/FootprintsList";
import icon from "../../assets/img/landmark.png";
export default {
  components: {
    FootprintsList
  },
  data() {
    return {
      map: {},
      my_id: "",
      user_id: "",
      park_id: this.$route.query.pid,
      park_name: "",
      aid: this.$route.query.aid ? this.$route.query.aid : 7, //活动的ID传递给发布页面
      bark: 1,
      type: this.$route.query.type,
      radius: this.$route.query.radius,
      info: [],
      page: 1,
      allLoaded: false,
      bottomStatus: "",
      is_preview: false,
      message: [],
      index: 0,
      itemLen: -1,
      title: "足迹列表",
      lng: this.$route.query.lng ? this.$route.query.lng : 0,
      lat: this.$route.query.lat ? this.$route.query.lat : 0,
      img_marker_list: [],
      micro_img_marker_list: [],
      mak_type: "img",
      ago_title: "",
      container_big: false, //地图是否放大
      is_tab_button: true, //图片切换是否点击
      is_tab_map_3d: true, //3D地图按钮是否点击
      is_show_publish: true //this.$route.query.is_show_publish,//这个值需要从url地址里面进行获取,用于在公园的足迹列表页面显示发布足迹的按钮
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      if (this.check_login()) {
        this.my_id = this.check_login().user_id;
      }
      if (this.type == 1) {
        this.user_id = this.$route.query.user_id;
      } else if (this.type == 0) {
        // 我的足迹
        this.user_id = this.my_id;
        this.title = "我的足迹列表";
      } else if (this.type == "parkFoot") {
        // 公园足迹
        this.park_id = this.$route.query.pid;
        if (this.$route.query.title) {
          this.title = this.$route.query.title;
        } else {
          this.title = "公园足迹列表";
        }
      } else {
        // 他的足迹
        this.title = "TA的足迹列表";
        this.user_id = this.$route.query.type;
      }
      this.getData(1);
      //发布足迹按钮-------------------------------------------
      var that = this;
      this.$nextTick(() => {
        var num = 0;
        window.addEventListener(
          "scroll",
          function() {
            that.scrollTop =
              document.documentElement.scrollTop || document.body.scrollTop;
            if (num < that.scrollTop) {
              that.is_show_publish = false;
            } else {
              that.is_show_publish = true;
            }
            num = that.scrollTop;
            //console.log(num+','+that.scrollTop);
          },
          true
        );
      });
    },
    getData(page) {
      var that = this;
      //初始化地图对象，加载地图
      this.map = this.map_init();

      this.$ajax(
        "post",
        "foot/get_spots_list",
        {
          page: page,
          user_id: this.user_id,
          my_id: this.my_id,
          park_id: this.park_id,
          lng: this.lng,
          lat: this.lat,
          radius: this.radius
        },
        res => {
          if (res.data.id == 0) {
            this.info = this.info.concat(res.data.data.data);
            this.park_name = this.info[0].park_name;
            if (page == 1) {
              this.itemLen = res.data.data.data.length;
            }
            this.$refs.child.parentMsg({
              info: this.info,
              isred: false,
              my_id: this.my_id,
              tit: false,
              parklink: false,
              typename: this.type
            });
            if (this.type == "parkFoot") {
              centre_marker = new AMap.Marker({
                map: that.map,
                icon: new AMap.Icon({
                  size: new AMap.Size(25, 35), //图标大小
                  image: icon
                }),
                position: [that.lng, that.lat]
              });
              if (res.data.data.park_spots_list.length > 0) {
                //                                this.map_add_Marker(this.map, res.data.data.park_spots_list, '', function (marker) {
                //                                    that.micro_img_marker_list = marker
                //                                }, '', function (data) {
                ////                                    console.log(data);
                //                                    that.$router.push({
                //                                        path: '/footprints/FootprintsParticulars',
                //                                        query: {id: data.id}
                //                                    })
                //                                })

                this.map_add_Marker({
                  map: this.map,
                  list_data: res.data.data.park_spots_list,
                  callback: Marker_arr => {
                    this.micro_img_marker_list = Marker_arr;
                  },
                  click_callback: data => {
                    that.$router.push({
                      path: "/footprints/FootprintsParticulars",
                      query: { id: data.id }
                    });
                  }
                });
              }
            }
          } else {
            this.itemLen = 0;
          }
        }
      );
    },
    ievent(...data) {
      this.getData(data[0]);
    },
    //            点击返回，判断是直接从首页跳转过来的就返回首页，如点击他的、足迹跳转再点击公园名称显示公园列表，点击返回就先显示他的足迹列表在显示首页
    back() {
      if (this.bark == 1) {
        this.$router.go(-1);
      } else {
        this.info = [];
        this.bark = 1;
        this.page = 1;
        this.park_id = "";
        this.type = 0;
        this.title = this.ago_title;
        this.getData(this.page);
      }
    },
    //            点击列表公园名称，页面显示这一个公园的列表 只是改变显示的数据 未进行跳转
    getparkdata(...park_id) {
      if (park_id[0] != this.park_id) {
        this.park_id = park_id[0];
        this.info = [];
        this.page = 1;
        this.bark = 2;
        this.type = "parkFoot";
        this.lng = park_id[1];
        this.lat = park_id[2];
        this.ago_title = this.title;
        this.title = "公园足迹列表";
        this.getData(1);
      }
    },
    //            地图放大按缩小 点击放大重新强求数据显示 图片 数字
    click_container_big() {
      var that = this;
      this.container_big = !this.container_big;
      if (this.container_big) {
        centre_marker.hide();
        for (var i = 0; i < this.micro_img_marker_list.length; i++) {
          this.micro_img_marker_list[i].hide();
        }
        if (this.img_marker_list.length == 0) {
          this.$ajax(
            "post",
            "foot/get_park_spots",
            {
              park_id: this.park_id,
              lng: this.lng,
              lat: this.lat,
              radius: this.radius
            },
            res => {
              if (res.data.id == 0) {
                //                                this.map_add_Marker(this.map, res.data.data.park_spots_list, '', function (marker) {
                //                                    that.img_marker_list = marker
                //                                },'',function (data) {
                //                                    that.$router.push({
                //                                        path: '/footprints/FootprintsParticulars',
                //                                        query: {id: data.id}
                //                                    })
                //                                })

                this.map_add_Marker({
                  map: this.map,
                  list_data: res.data.data.park_spots_list,
                  callback: Marker_arr => {
                    this.img_marker_list = Marker_arr;
                  },
                  click_callback: data => {
                    that.$router.push({
                      path: "/footprints/FootprintsParticulars",
                      query: { id: data.id }
                    });
                  }
                });
              }
            }
          );
        } else {
          for (var i = 0; i < this.img_marker_list.length; i++) {
            this.img_marker_list[i].show();
          }
        }
      } else {
        for (var i = 0; i < this.micro_img_marker_list.length; i++) {
          this.micro_img_marker_list[i].show();
        }
        for (var i = 0; i < this.img_marker_list.length; i++) {
          this.img_marker_list[i].hide();
        }
        centre_marker.show();
        $(".amap-zoomcontrol").hide();
      }
    },
    // 切换显示图片 图标
    click_mak_type() {
      this.is_tab_button = !this.is_tab_button;
      this.map_cut_Marker(this.map, this.img_marker_list, this.is_tab_button);
    }
  }
};
</script>
<style scoped>
.TrackIndex.active .amap-touch-toolbar .amap-zoomcontrol {
  bottom: 110px;
}

.amap-simple-marker-position-point {
  display: none !important;
}

.amap-simple-marker-label {
  width: 100%;
  height: 100%;
  bottom: 0;
}

.amap-simple-marker {
  background: #fff;
  padding: 4px 4px 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.amap-simple-marker-label > i {
  background-color: #ff9a80;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  font-style: normal;
}

.amap-icon img {
  width: 100%;
}

.container_box {
  position: relative;
  height: 12rem;
}

.TrackIndex.active {
  height: 100%;
  overflow: hidden;
}

.TrackIndex.active .page-content {
  height: 100%;
}

.TrackIndex.active .container_box {
  height: 100%;
  position: fixed;
  box-sizing: border-box;
  left: 0;
  right: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}

.TrackIndex.active .container_box img.red_packet_spread {
  bottom: 60px;
}

.container_box img.red_packet_spread {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 2rem;
}

.blue {
  color: #ff9a80;
}

.TrackIndex .page-content {
  padding-bottom: 0;
  background-color: #fff;
}

.TrackIndex .tab_list {
  border-bottom: 1px solid #f2f2f2;
  text-align: center;
}

.TrackIndex .tab_list li {
  width: 50%;
  float: left;
  color: #666;
  font-size: 0.7rem;
}

.TrackIndex .tab_list li span {
  display: inline-block;
  padding: 10px;
}

.TrackIndex .tab_list li.active span {
  border-bottom: 1px solid #ff9a80;
  color: #ff9a80;
}

.track_item_body {
  position: relative;
  display: block;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.track_item_body .track-item-tag {
  padding-top: 5px;
  line-height: 20px;
  overflow: hidden;
  position: relative;
}

.track_item_body .track-item-tag a {
  font-size: 0.8rem;
  color: #666;
  padding: 0.3rem 0 0 1.6rem;
  margin-right: 10px;
  float: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.track_item_body .track-item-tag:nth-of-type(2) a {
  font-weight: 700;
  background: url(../../assets/img/track_name.png) left 0.2rem no-repeat;
  background-size: 1.2rem;
}

.track_item_body .track-item-tag:nth-of-type(3) a {
  background: url(../../assets/img/track_describe.png) left top no-repeat;
  background-size: 1.2rem;
  box-sizing: border-box;
  padding: 0 0 0 1.6rem;
  width: 100%;
  line-height: 1.1rem;
  overflow: hidden;
  margin: 0.3rem 0;
}

.track_item_body .track-item-tag:nth-of-type(3) a.active {
  display: block;
}

.track_item_body .feed-item-tag {
  width: 100%;
}

.track_item_body .feed-item-tag.last a span,
.track_item_body .track-item-tag:nth-of-type(3) a span {
  margin-left: 10px;
}

.note-track-card {
  background: #f2f2f2;
  border-radius: 3px;
  /*height: 120px;*/
}

.note-track-card .map-pic {
  width: 100%;
  height: 6rem;
  background-position: center;
  background-size: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.feed-item-top .fr img {
  width: 36px;
}

.unfold {
  position: absolute;
  right: 10px;
  bottom: 0px;
  background: #fff;
  font-size: 0.8rem;
  padding: 0 1.02rem;
}

.mak_type {
  position: fixed;
  bottom: 30px;
  left: 10px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}

.mak_type li {
  float: left;
  margin: 5px 0;
  padding: 0 10px;
  box-sizing: border-box;
  width: 50px;
  height: 30px;
}

.mak_type li:nth-child(1) {
  border-right: 1px dashed #f2f2f2;
}

.mak_type li img {
  height: 100%;
  width: 100%;
}
</style>