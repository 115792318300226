<template>
  <div class="container" v-show="loaded" :style="'background:url(' + label.bg_ + ') 0% 0% / 100% repeat;'">
    <i class="back iconfont iconmenu2" @click="goBack"></i>
    <main class="main">
      <ul class="list">
        <li class="item" v-for="(item, i) in list" :key="i">
          <div class="logo">
            <img :src="item.logo_" class="img" />
          </div>
          <div class="describe">
            <p class="content">{{item.content}}</p>
            <div class="footer">
              <div class="more" @click="navToSpot(item.id)">
                <span class="text">查看更多</span>
                <i class="iconfont iconmove-right"></i>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </main>
    <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator } from "mint-ui";
import TechnicalSupport from "../../components/TechnicalSupport";
export default {
  components: {
    "technical-support": TechnicalSupport
  },
  data() {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanRoot",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      aid: this.$route.query.aid || 0,

      // 获取数据
      label: {},
      list: [],
      // 自定义
      isLoading: false,
      loaded: false,

      // company
      companyInfo: {},
      haveCompany: false
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
    }

    this.getData();
    this.getCompanyInfo(this.id, this.token).then(
      res => {
        this.companyInfo = res;
        this.haveCompany = true;
      },
      err => {
        console.log(err);
      }
    );
  },
  methods: {
    // 详情
    navToSpot(id) {
      this.$router.push({
        path: "/nihewan/spot",
        query: {
          id: this.id,
          aid: id
        }
      });
    },
    // 上一级
    goBack() {
      this.$router.push({
        path: "/nihewan/home",
        query: {
          id: this.id
        }
      });
    },
    getData() {
      if (this.aid && this.open_id && this.token) {
        this.isLoading = true;
        Indicator.open("加载中...");
        this.$ajax(
          "post",
          "Label/get_history_root_data",
          {
            id: this.aid,
            id_type_: this.type_,
            open_id: this.open_id,
            park_id: this.park_id,
            token: this.token,
            user_id: "",
            version: this.version
          },
          res => {
            if (res.data.id === 0) {
              Object.assign(this.label, res.data.data.label);
              this.list = this.list.concat(res.data.data.list);
              document.title = res.data.data.label.title;

              const sUrl = window.location.href,
                wx_title = res.data.data.label.title,
                wx_content = "",
                wx_img = res.data.data.label.bg;
              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                });
              }
            }

            this.isLoading = false;
            Indicator.close();
            this.loaded = true;
          }
        );
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&id_=" +
        this.aid +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    }
  }
};
</script>

<style scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
}

.back {
  position: fixed;
  top: 0.4rem;
  left: 0.4rem;
  height: 1.6rem;
  width: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.main {
  position: relative;
  width: 100%;
}

.list {
  padding-top: 2.8rem;
  width: 100%;
}

.list > .item {
  padding: 1rem;
  display: flex;
  box-sizing: border-box;
}

.list > .item:nth-child(2n + 2) {
  flex-direction: row-reverse;
}

.logo {
  width: 50%;
  text-align: center;
}

.logo > .img {
  width: 90%;
}

.describe {
  padding: 0 5%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.describe > .content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  font-size: 0.8rem;
  color: #fdf2c0;
  text-shadow: -1px 1px 1px rgb(0, 0, 0);
  text-align: justify;
}

.describe > .footer {
  margin-top: 0.5rem;
}

.more {
  display: inline-block;
  padding: 4px 12px;
  background-color: rgba(80, 48, 0, 0.4);
  color: #fff;
  font-size: 0.74rem;
}

.more > .iconfont {
  font-size: 0.74rem;
}

.company {
  position: relative;
  width: 100%;
  padding: 1rem 0;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 0.74rem;
}
</style>