<template>
  <div class="content" v-title data-title="核销">
    <div class="title">{{title}}</div>
    <!-- 搜索 -->
    <div class="index-search">
      <div class="search-box">
        <div>
          <input type="text" :placeholder="key_word_info" v-model="key_word" />
          <button type="submit" @click="search">搜索</button>
        </div>
      </div>
    </div>
    <!--作品列表-->
    <mt-loadmore
      :bottom-method="loadBottom"
      :bottom-all-loaded="allLoaded"
      ref="loadmore"
      :auto-fill="false"
    >
      <div class="list-content">
        <div v-for="(k, i) in info" class="item-packet" :key="i">
          <div class="list-display list-user-icon">
            <img :src="k.user_icon" />
          </div>
          <div class="nick-tel">
            <div class="list-user-nick">
              <span>{{k.user_nick}}</span>
            </div>
            <div class="list-user-tel">
              <span>手机:{{k.user_tel}}</span>
            </div>
            <div class="list-display list-user-packet">
              <span>价值:{{k.red_packet}}</span>
            </div>
          </div>
          <div class="list-display list-user-mask">
            <span>{{k.use_mask}}</span>
          </div>
          <div
            class="list-display btn-change"
            @click="change(k.rp_id,k.urp_id,k.use_mask)"
            v-bind:class="{ btn_change_font: k.use_mask != 0 ? true:false }"
          >
            <span v-if="k.use_mask != 0">已兑换</span>
            <span v-else>请兑换</span>
          </div>
        </div>
      </div>
    </mt-loadmore>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      title: "核销",
      key_word: "",
      key_word_info: "请输入6位数兑换码",
      aid: this.$route.query.aid, //表示查询
      info: "", //兑奖列表
      allLoaded: false, //判断加载更多是否加载完毕了
      is_changed: false, //没有使用
      page: 1,
      length: 0 //新加载数据的长度
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      if (this.check_login()) {
        this.user_id = this.check_login().user_id;
      } else {
        this.user_id = this.get_User_Id();
      }
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      this.getData(this.aid, this.page);
    },
    getData(aid, page, key_word) {
      //发送请求获取兑换列表
      if (aid > 0) {
        if (key_word) {
          var data = {
            aid: aid,
            code: key_word,
            page: page
          };
        } else {
          var data = {
            aid: aid,
            page: page
          };
        }
        this.$ajax("post", "packet/get_convert_list", data, ret => {
          if (ret.data.id == 0) {
            this.info = ret.data.data;
            this.length = ret.data.data.length;
          } else {
            //没有查到数据
            Toast("没有数据");
          }
        });
      } else {
      }
    },
    search() {
      var key_word = this.key_word;
      if (key_word) {
        this.getData(this.aid, this.page, key_word);
      }
    },
    loadBottom() {
      this.allLoaded = true; // 若数据已全部获取完毕
      this.$refs.loadmore.onBottomLoaded();
      var that = this;
      setTimeout(() => {
        if (this.length % 10 == 0) {
          that.page++;
          that.getData(that.aid, that.page, that.key_word);
        } else {
          that.allLoaded = true; //表示已经加载完毕了
          that.$refs.loadmore.onBottomLoaded();
          Toast("没有更多了");
        }
      });
    },
    change(rp_id, urp_id, use_mask) {
      if (use_mask != 0) {
        //说明已经兑换过了
        Toast("已兑换");
      } else {
        //还没有兑换
        this.$ajax(
          "post",
          "packet/change_code",
          { rp_id: rp_id, urp_id: urp_id },
          ret => {
            if (ret.data.id == 0) {
              //兑换成功
              this.is_changed = true;
              this.getData(this.aid);
            } else {
              //什么也不用做
              Toast("兑换失败");
            }
          }
        );
      }
    }
  }
};
</script>

<style scoped>
/*核销优惠卷和实物礼品*/
.content .title {
  font-size: 1rem;
  margin-top: 0.2rem;

  text-align: center;
}
.search-box {
  font-size: 0;
  padding: 1rem 0.4rem;
  display: flex;
}

.search-box > div {
  text-align: center;
  flex: 3;
  display: flex;
}

.search-box input {
  height: 1.4rem;
  display: inline-block;
  vertical-align: top;
  background: #fff;
  padding: 0 0.5rem;
  border: 1px solid #ff9a80;
  border-radius: 0.15rem 0 0 0.15rem;
  color: #666;
  outline: 0;
  -webkit-appearance: none;
  border-radius: 0.7rem 0 0 0.7rem;
  flex: 3;
  font-size: 0.7rem;
}

.search-box button {
  height: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  outline: 0;
  border-radius: 0 0.15rem 0.15rem 0;
  background: #ff9a80;
  color: #fff;
  border: none;
  border-radius: 0 0.7rem 0.7rem 0;
  flex: 1;
  font-size: 0.7rem;
}
/*作品列表*/
.item-packet {
  background-color: #ff9a80;
  margin-top: 0.5rem;
}
.list-display {
  display: inline-block;
  font-size: 20px;
  vertical-align: middle; /*表示居中显示*/
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}
.list-user-icon img {
  width: 60px;
  height: 60px;
  margin: 0.5rem;
}
.nick-tel {
  display: inline-block;

  vertical-align: middle;
}
.nick-tel span {
  font-size: 1rem;
}

.list-user-packet span {
  float: left;
  margin-top: 0.2rem;
}
.list-user-mask span {
  margin-left: 47px;
}
.btn-change {
  float: right;
  background-color: #fff0f0;
  width: 86px;
  height: 86px;
}
.btn-change span {
  /*vertical-align: middle;*/
  position: absolute;
  top: 50%;

  transform: translateY(-50%);
  margin-left: 15px;
}
.btn_change_font {
  background-color: dimgrey;
}
</style>
