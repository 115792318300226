<template>
  <div class="TrackPublish_box" v-title data-title="添加足迹">
    <div class="TrackPublish">
      <mt-header fixed title="添加足迹">
        <a slot="left">
          <mt-button @click="$router.go(-1)" icon="back"></mt-button>
        </a>
        <mt-button slot="right" @click.native="issue">发布</mt-button>
      </mt-header>
      <div class="page-content">
        <form id="form" action method="post" enctype="multipart/form-data">
          <mt-field
            :attr="{name:'title'}"
            v-model="title"
            placeholder="请填写足迹名称"
            rows="1"
            type="textarea"
          ></mt-field>

          <input type="hidden" name="label_id" v-model="label_id" />
          <div class="describe">
            <mt-field
              :attr="{name:'content'}"
              v-model="content"
              placeholder="足迹描述（根据足迹特点进行描述）"
              rows="4"
              type="textarea"
            ></mt-field>
          </div>
          <div class="filenum clearfix" style="padding-bottom: 5px">
            <div class="fl" style="line-height: 32px">是否公开足迹</div>
            <mt-switch v-model="value" class="fr"></mt-switch>
            <input type="hidden" name="open" v-if="value" value="1" />
            <input type="hidden" name="open" v-if="!value" value="0" />
          </div>
          <div class="imgs clearfix">
            <div class="item add-image">
              <img src="../../assets/img/tour_photo@2x.png" alt @click="AddImage($event)" />
              <input type="file" accept="image/*" />
            </div>
          </div>
          <div class="blue filenum">上传足迹图片（最多上传一张图片）</div>
        </form>
        <div id="container" style="width:100%; height:150px" ref="container"></div>
        <div class="left_but">
          <div class="mak_tab">
            <!--地图 + -  -->
            <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
            <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
          </div>
          <div class="mak_tab map_my_location">
            <!--地图定位- -->
            <img src="../../assets/img/map_my_location.png" alt @click="get_map(1)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Indicator, MessageBox, Toast } from "mint-ui";
import * as qiniu from "qiniu-js";
import md5 from "js-md5";
export default {
  data() {
    return {
      map: {},
      items: [],
      recommend_park: [],
      type: this.$route.query.type,
      title: this.$route.query.title ? this.$route.query.title : "",
      content: this.$route.query.content ? this.$route.query.content : "",
      label_id: this.$route.query.aid > 0 ? this.$route.query.aid : 7,
      parkname: this.$route.query.park_name ? this.$route.query.park_name : "",
      park_id: this.$route.query.park_id > 0 ? this.$route.query.park_id : 298,
      back_: this.$route.query.back_ ? this.$route.query.back_ : 0, //表示发布成功之后，是否跳转到投票页面

      value:
        this.$route.query.open != 1 && this.$route.query.open ? false : true,
      user_id: "", //用户ID
      verify: "",
      add_time: new Date().getTime(),
      lng: 0,
      lat: 0,
      discuss_img_arr: [],
      foot_upload_img: [],
      get_foot_upload_img: [],
      text: "",
      is_tab_map_3d: true, //3D地图按钮是否点击
      label_list: "", //足迹标签列表
      maxSize: 100 * 1024, //图片压缩
      is_android: false, //判断在android还是在ios中打开

      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id")
        ? localStorage.getItem("open_id")
        : "",
      qiniu_token: "",
      qiniu_img_arr: [],
      qiniu_key: ""
    };
  },
  mounted() {
    this.unload();
    if (this.token) {
    } else {
      //第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    console.log("token=" + this.token);
    //投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }
    if (this.open_id) {
    } else {
      this.authorize();
    }
  },
  methods: {
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=" +
        this.token +
        "&id=" +
        this.id +
        "&state=activity";
      window.location.href = open_url;
    },
    unload() {
      //加载地图，调用浏览器定位服务
      this.map = this.map_init();
      var that = this;
      if (this.type == 2) {
        this.foot_upload_img = JSON.parse(
          localStorage.getItem("foot_upload_img")
        );
        this.get_foot_upload_img = JSON.parse(
          localStorage.getItem("foot_upload_img")
        );
        if (this.get_foot_upload_img && this.get_foot_upload_img.length > 0) {
          this.get_foot_upload_img.forEach(function(data, i) {
            var html =
              '<div class="item up-img">' +
              '<img src="' +
              data.result +
              '"><i class="remove"></i>' +
              "</div>";
            $(".imgs").prepend(html);
            that.discuss_img_arr.push(that.upload(data.result, data.type));
          });
        }
      } else {
        localStorage.removeItem("foot_upload_img");
      }
      this.$nextTick(() => {
        console.log(this.$refs.container.style.height);
        //   this.$refs.container.style.minHeight = "350px"
        $("#container")[0].style.height =
          $(".TrackPublish_box")[0].offsetHeight -
          $("#form")[0].offsetHeight -
          $(".mint-header")[0].offsetHeight +
          "px";
        $("#container")[0].style.minHeight = "350px";
        $(".park_info").height($("#app").height() - 40);
      });
      if (!this.check_login()) {
        MessageBox.alert("请先登录").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login", query: { log_type: 1 } });
          }
        });
      } else {
        this.user_id = this.check_login().user_id;
        this.verify = this.check_login().user_id + new Date().getTime();
      }
      this.get_map();
      let ua = navigator.userAgent.toLowerCase();
      //android终端
      let isAndroid = ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1;
      this.is_android = isAndroid >= 0 ? true : false;
      this.get_foot_label();
    },
    get_foot_label() {
      this.$ajax("post", "foot/get_foot_label", {}, ret => {
        if (ret.data.id == 0) {
          this.label_list = ret.data.data;
        } else {
          //没有得到标签-移动站发布必须有网
        }
      });
    },
    get_map(type) {
      var that = this;
      if (type == 1) {
        if (this.lng == 0) {
          type = "";
        } else {
          this.map.setZoomAndCenter(this.map.getZoom(), [this.lng, this.lat]);
        }
      } else {
        type = "no";
      }
      //                this.map_Geo_location(this.map, "", "", function (lng, lat) {
      //                    that.lng = lng
      //                    that.lat = lat
      //                }, type)

      that.map_Geo_location({
        map: this.map,
        type: type,
        callback: function(lng, lat) {
          that.lng = lng;
          that.lat = lat;
        }
      });
    },
    AddImage($event) {
      // console.log($event.target);
      var file = $($event.target).siblings()[0];
      // console.log(file);
      var that = this;
      file.click();
      file.onchange = function() {
        var files = Array.prototype.slice.call(this.files);
        files.forEach(function(file, i) {
          var reader = new FileReader();
          reader.onload = function() {
            var img_data = {};
            img_data.result = this.result;
            img_data.filetype = file.type;
            img_data.filename = file.name;
            var length = file.size;
            // that.render(this.result, file.name);
            that.discuss_img_arr.push(that.upload(this.result, file.type));
            that.foot_upload_img.push(img_data);
            // console.log(that.foot_upload_img);
            // console.log(that.discuss_img_arr);
            localStorage.setItem(
              "foot_upload_img",
              JSON.stringify(that.foot_upload_img)
            );
          };
          reader.readAsDataURL(file);
          var objUrl = that.getObjectURL(file);
          var html =
            '<div class="item up-img">' +
            '<img src="' +
            objUrl +
            '"><i class="remove"></i>' +
            "</div>";
          $(".imgs").prepend(html);
          if ($(".up-img").length >= 1) {
            $(".add-image").hide();
            Toast("每次只能上传1张图片");
          }
        });
        $(".remove").on("click", that.remove_img);
      };
    },
    //            删除图片
    remove_img(e) {
      this.discuss_img_arr.splice(
        $(e.target)
          .parent()
          .index(),
        1
      );
      $(e.target)
        .parent()
        .remove();
      if ($(".up-img").length < 9) {
        $(".add-image").show();
      }
    },
    //发布
    issue() {
      if (this.lng == 0) {
        Toast("请重新定位");
        return;
      }
      if (!$(".up-img").length) {
        Toast("请选择至少一张图片");
        return;
      }
      Indicator.open("发布中...");

      var that = this;
      if (this.open_id && this.token && this.lng && this.lat && this.user_id) {
        this.$ajax(
          "post",
          "Lottery/get_qiniu_token",
          {
            token: this.token,
            bucket: "image",
            open_id: this.open_id
          },
          res => {
            if (res.data.id == 0) {
              let t = new Date()
                .toLocaleDateString()
                .split("/")
                .map(item => {
                  if (item.length <= 1) {
                    return "0" + item;
                  }
                  return item;
                })
                .join("");
              let fileName = this.foot_upload_img[0].filename;
              let key = t + "_" + md5(fileName);
              let blob = this.discuss_img_arr[0];

              var observable = qiniu.upload(blob, key, res.data.data.token);
              var observer = {
                next(res) {},
                error(err) {
                  console.log(err);
                  Toast("上传图片失败");
                },
                complete(res) {
                  that.$ajax(
                    "post",
                    "foot/web_foot_upload",
                    {
                      lng: that.lng,
                      lat: that.lat,
                      title: that.title,
                      content: that.content,
                      park_id: that.park_id,
                      label_id: that.label_id,
                      user_id: that.user_id,
                      verify: that.make_token(),
                      open: that.value ? 1 : 0,
                      img_key: res.key,
                      open_id: that.open_id
                    },
                    res => {
                      if (res.data.id == 0) {
                        //表示发布成功之后，跳转到投票页面
                        if (that.back_ == "ParkPhoto") {
                          that.$router.push({
                            path: "/parkPhoto/ParkPhoto",
                            query: {
                              aid: that.label_id
                            }
                          });
                        } else {
                          that.$router.push({
                            path: "/"
                          });
                        }
                      } else {
                        Toast("发布失败");
                      }
                    }
                  );
                }
              };
              var subscription = observable.subscribe(observer);

              Indicator.close();
            } else {
              Toast("提交失败");
            }
          }
        );
      } else {
        this.authorize();
      }
    }
  }
};
</script>

<style scoped>
.mint-indexlist-content {
  overflow: initial;
  overflow-y: scroll;
}

.TrackPublish_box {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #fff;
}

.TrackPublish .el-vue-amap-container,
.TrackPublish .el-vue-amap-container .el-vue-amap {
  width: 100%;
  height: 150px;
}

.page-content {
  background-color: #fff;
  margin-top: 40px;
  padding: 0;
}

.gray_bg {
  background-color: #f2f2f2;
}

.TrackPublish .filenum {
  font-size: 0.6rem;
  padding: 0.2rem 0.6rem 0.8rem;
}

.TrackPublish .park {
  padding: 0.4rem;
  font-size: 0.6rem;
}

.TrackPublish .park .list span {
  padding: 3px 8px;
  border-radius: 3px;
}

.TrackPublish .specialty_list {
  font-size: 0.6rem;
  padding: 0 0.2rem 0.4rem;
}

.TrackPublish .specialty_list span {
  display: inline-block;
  padding: 3px 5px;
  border-radius: 2px;
  color: #000;
}

.TrackPublish .specialty_list span.active {
  background-color: #ff9a80;
  color: #fff;
}
/*  */
.left_but {
  position: fixed;
  left: 0.5rem;
  bottom: 4.6rem;
}
.left_but .mak_tab {
  background-color: #fff;
  width: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 2px 2px 5px #ccc;
  box-shadow: 2px 2px 5px #ccc;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
}

.left_but .mak_tab img {
  width: 100%;
  padding: 0.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.left_but .mak_tab:last-child {
  margin-bottom: 0;
}
.left_but .mak_tab.map_my_location img,
.left_but .mak_tab img:last-child {
  border: none;
}

.TrackPublish >>> .mint-cell-wrapper {
  background: none;
  font-size: 0.7rem;
  border: none;
  padding: 0 10px !important;
}

.TrackPublish >>> .mint-cell:last-child {
  background: none;
}
.TrackPublish .page-content .describe {
  border: none;
}
.TrackPublish .page-content >>> .mint-field.is-textarea {
  border: none;
}
.TrackPublish .page-content >>> .mint-field-core {
  text-align: left;
  font-size: 0.7rem;
  border: 1px solid #eee;
  color: #333;
  resize: none;
}

.TrackPublish_box
  .TrackPublish
  .page-content
  #form
  >>> .mint-switch-input:checked
  + .mint-switch-core {
  border-color: #ff9a80;
  background-color: #ff9a80;
}
.TrackPublish .page-content #form >>> .mint-switch-core {
  transform: scale(0.666);
}

.TrackPublish .page-content #form .blue {
  color: #ff9a80;
}

.TrackPublish_box .TrackPublish .imgs {
  padding: 10px;
}

.TrackPublish_box .TrackPublish .imgs .item {
  position: relative;
  float: left;
  width: 50%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 4rem;
  overflow: hidden;
  height: 4rem;
}

.TrackPublish_box .TrackPublish .imgs .item input {
  padding-top: 100%;
  position: relative;
  display: block;
  background-size: cover;
  border-radius: 2px;
  background-position: center;
  width: 100%;
  height: 0;
  z-index: -1;
}

.TrackPublish_box .TrackPublish .imgs .item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.TrackPublish_box .TrackPublish .imgs .item .remove {
  background: url(../../assets/img/search_clean.png) 0 0 no-repeat;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.TrackPublish_box .TrackPublish .imgs .add-image input {
  height: 0;
  border: none;
}
</style>