<template>
  <div id="books" class="dylan">
    <div class="books_container" :style="'padding-top:' + 2.8 * is_weixin + 'rem'">
      <!-- 语言选择 -->
      <div class="switch" v-if="is_show_language">
        <div class="sw-item" v-if="language == 1" @click="switch_language(language)">
          <!--中文-->
          <img src="../../assets/switch/en.png" />
        </div>
        <div class="sw-item" v-else-if="language == 2" @click="switch_language(language)">
          <!--英文-->
          <img src="../../assets/switch/zh.png" />
        </div>
      </div>
      <!--  -->
      <div class="books_menu" :class="showNav ? 'books_menu_active' : ''" @click="showNavBar">
        <i class="iconfont iconclose" v-show="showNav"></i>
        <i class="iconfont iconliebiao" v-show="!showNav"></i>
      </div>
      <!-- 目录 -->
      <div class="books_nav" v-show="showNav" @click.self="hideNavBar"></div>
      <transition name="navbar">
        <div class="nav_bar" v-show="showNav">
          <ul class="menu_bar">
            <li
              v-for="(item,i) in spots_list"
              :key="i"
              @click.stop="goAnchor('anchor_' + i)"
              :class="tl_active == i ? 'mb_active' : ''"
            >
              <span class="menu_index">{{i+1}}</span>
              <span
                class="menu_content"
              >{{language == 1 ? item.spots_name : (language == 2 ? item.en_title : item.spots_name)}}</span>
            </li>
          </ul>
        </div>
      </transition>

      <!--  -->
      <transition name="backtop">
        <back-top v-show="showBackTop"></back-top>
      </transition>
      <!--  -->
      <transition name="progress">
        <div class="books_progress" v-show="showProgress && 0">
          <i class="iconfont icon-jiaoyin"></i>
          <span class="progress_wrapper">
            <span
              class="progress"
              :style="'width:' + (tl_active + 1) / (spots_list.length) * 100 + '%;'"
            >
              <span class="instance">{{spots_list[tl_active] ? spots_list[tl_active].length : ''}}</span>
            </span>
          </span>
        </div>
      </transition>
      <!--  -->

      <div class="books_header">
        <h1
          class="header_title"
        >{{language == 1 ? info.label_name : (language == 2 ? info.en_title : info.label_name)}}</h1>
        <div class="header_content">
          <div class="header_distance header_info">
            <span class="title">{{language ==1 ? "总里程" : (language == 2 ? "Toatl KM" : "总里程")}}</span>
            <span class="content">{{info.length}}</span>
          </div>
          <div class="header_max header_info">
            <span
              class="title"
            >{{language ==1 ? "最高海拔" : (language == 2 ? "Max Altitude" : "最高海拔")}}</span>
            <span class="content">{{info.height}}</span>
          </div>
          <div class="header_min header_info">
            <span
              class="title"
            >{{language ==1 ? "最低海拔" : (language == 2 ? "Max Altitude" : "最低海拔")}}</span>
            <span class="content">{{info.low}}</span>
          </div>
          <div class="header_time header_info">
            <span
              class="title"
            >{{language ==1 ? "徒步时间" : (language == 2 ? "Walking Time" : "徒步时间")}}</span>
            <span class="content">{{info.time_length}}</span>
          </div>
        </div>
      </div>

      <div class="books_main">
        <ul class="main_timeline">
          <li
            class="timeline_item"
            v-for="(item,i) in spots_list"
            :key="i"
            :id="'anchor_' + i"
            :class="tl_active == i ? 'tl_active' : ''"
          >
            <div class="timeline_line" v-if="i != spots_list.length - 1"></div>
            <div class="timeline_int timeline_1">
              <h2
                class="timeline_title"
                @click="jumpToDetail(i)"
              >{{ language == 1 ? item.spots_name : (language == 2 ? item.en_title : item.spots_name)}}</h2>
              <div class="timeline_tips">
                <span
                  class="timeline_activities"
                  v-if="item.have_red_packet && item.have_red_packet != '0'"
                >{{language == 1 ? "红包" : (language == 2 ? "RedPacket" : "红包")}}</span>
                <span
                  class="timeline_activities"
                  v-if="item.have_coupon && item.have_coupon != '0'"
                >{{language == 1 ? "优惠券" : (language == 2 ? "Coupon" : "优惠券")}}</span>
                <span class="timeline_tags" v-if="item.notes">{{item.notes}}</span>
              </div>
              <!-- <p class="timeline_content">{{item.spots_name}}</p> -->
              <div class="timeline_info">
                <div class="timeline_img" @click="jumpToDetail(i)">
                  <span class="img_num" v-if="language == 1">共{{item.img_numbers}}张</span>
                  <span class="img_num" v-else-if="language == 2">{{item.img_numbers}}&nbsp;Pic</span>
                  <img :src="item.newUrl" alt />
                </div>
                <div class="info_wrapper">
                  <span
                    class="info_navigation info_item"
                    @click="openLocation(item.lng,item.lat,item.spots_name,item.address)"
                  >
                    <i class="iconfont icondaohang1"></i>
                    {{language == 1 ? "导航" : (language == 2 ? "Nav" : "导航")}}
                  </span>
                  <span
                    class="info_audio info_item"
                    v-if="item.audio_path != ''"
                    @click="play(item.index)"
                    :index="item.index"
                  >
                    <audio
                      class="line_audio"
                      :src="item.audio_path"
                      :id="'audio_'+ item.index"
                      @ended="audioPlayEnd"
                    ></audio>
                    <i
                      class="iconfont"
                      :class="{'icon1' : !audioActive, 'icon-zanting1': audioActive}"
                    ></i>
                    {{language == 1 ? "语音" : (language == 2 ? "Audio" : "语音")}}
                  </span>
                  <span class="info_road info_item" @click="jumpToDetail(i)">
                    <i class="iconfont iconjianjie1"></i>
                    {{language == 1 ? "详情" : (language == 2 ? "Detail" : "详情")}}
                  </span>
                </div>
              </div>
            </div>
            <div class="timeline_dot">
              <span></span>
            </div>
            <div class="timeline_day">
              <span>{{item.day_}}</span>
            </div>
            <div class="timeline_length" v-if="i + 1 < spots_list.length">
              <span>{{ spots_list[i+1].two_length}}</span>
            </div>
          </li>
        </ul>
      </div>

      <!-- 赞赏 -->
      <div class="books_appreciate" v-if="pay_numbers > 0" @click="pay">
        <span class="appreciate_btn">{{language == 1 ? "赞赏" : (language == 2 ? "Like" : "赞赏")}}</span>
        <span
          class="appreciate_num"
          v-if="language == 1"
          @click="OpenLabelUserList('pay_list')"
        >——— {{pay_numbers}}人赞赏 ———</span>
        <span
          class="appreciate_num"
          v-else-if="language == 2"
          @click="OpenLabelUserList('pay_list')"
        >——— {{pay_numbers}}&nbsp;likes ———</span>
      </div>
      <!-- 进入地图/ 邀请排行榜 -->
      <div class="function-btn">
        <div class="btn" @click="jumpToMap">
          <span class="name">进入地图</span>
          <i class="iconfont iconqianjin"></i>
        </div>
        <div class="btn" @click="getShareRank('show')">
          <span class="name">邀请达人榜</span>
          <i class="iconfont iconqianjin"></i>
        </div>
      </div>
      <!-- 红包雨 -->
      <red-packet
        :redPacketList="redPacketList"
        v-if="info.have_red_packet_rain"
        id_type_="books"
        :red_packet_type_="info.red_packet_type_"
        :red_packet_image_url="info.red_packet_image_url"
        :is_admin="is_admin"
      ></red-packet>
      <!-- fast duration 获奖用户滚动显示-->
      <vue-marquee style="height:48px;background-color:white;margin-bottom:10px;" :showProgress="false" v-if="marquee_list.length > 0">
        <vue-marquee-slide v-for="item in marquee_list" :key="item.id">
          <span style="display:block;padding:10px 2px 10px 2px;text-align:center; font-size:0.6rem">{{item.user_nick}}<span style="color:#999;font-size:0.4rem;padding:0px 10px 0px 10px ;">{{item.add_time}}获得</span><span style="color:red; font-size:0.8rem;">{{item.amount}}￥</span> </span>
        </vue-marquee-slide>
      </vue-marquee>
      <!-- 统计信息 -->
      <user-list
        :language="language ? language : 1"
        :info="info"
        id_type_="books"
        v-if="is_weixin && (info.join_numbers > 0 || info.looks_numbers > 0 || info.share_numbers > 0 || info.win_numbers > 0)"
      ></user-list>

      <!-- 关联路书 -->
      <div class="books_friends" v-if="info.other_label_list">
        <h2 class="friends_title">{{language == 1 ? "相关路书" : (language == 2 ? "Relevant" : "相关路书")}}</h2>
        <ul class="friends-list">
          <li class="friends-item" v-for="(item,i) in info.other_label_list" :key="i">
            <div @click="jumpToMe(item)" class="item-wrapper" v-if="item">
              <span class="item-title">{{item.title}}</span>
              <img v-if="item.baseUrl" :src="item.baseUrl" alt class="item-banner" />
            </div>
          </li>
        </ul>
      </div>
      <!-- 底部富文本 -->
      <div class="advert">
        <div class="advert_img" v-if="info.advert_url != '' ">
          <!--
          -->
          <router-link :to="{path:'/activity/ActivityDetail',query:{aid: info.aid}}">
            <!--
            -->
            <img :src="info.advert_url" />
            <!--
            -->
          </router-link>
        </div>
        <div class="books_rich_text" v-if="info.content" v-html="info.content"></div>
      </div>
      <!--  -->
      <!-- <div class="books_footer">
        <a>武汉地普游科技提供技术支持</a>
      </div>-->

      <!-- 联系信息 -->
      <div class="company" v-if="haveCompany">
        <technical-support
          :ts_type_="ts_type_"
          contact_side="new"
          :text="companyInfo.title"
          :id="label_id"
          :company="companyInfo"
        ></technical-support>
      </div>
    </div>

    <!-- 分享排行 -->
    <transition name="slide-down">
      <div class="share-rank-box" v-show="showShareRank" ref="share-rank-box">
        <div class="close" @click="showShareRank = false">
          <i class="iconfont iconiconfontback"></i>
        </div>
        <div class="banner">
          <div class="banner-title">
            <p class="text">邀请达人榜</p>
          </div>
          <div class="banner-icon">
            <i class="iconfont iconjiangpai"></i>
          </div>

        </div>
        <!--增加显示红包剩余个数信息-->
        <div class="statistics_percentage statistics_item_opt" v-if="red_packet != ''">
          <span class="statistics_name">红包剩余数量：{{ red_packet.total_ - red_packet.numbers >= 0 ? red_packet.total_ - red_packet.numbers : 0}} / {{red_packet.total_}}</span>
          <span class="numbers_percentage_outer">
                      <span
                              class="numbers_percentage_inner"
                              :style="'width:' + (red_packet.total_ - red_packet.numbers)/red_packet.total_ *100+ '%' "
                      ></span>
              </span>
        </div>
        <div class="self-share">
          <div class="left">
            <img :src="myShareInfo.user_icon" class="icon" alt v-if="myShareInfo.user_icon" />
            <img src="../../assets/img/apply_avatar_bg.png" alt class="icon" v-else />
          </div>
          <div class="middle">
            <p class="nick">{{myShareInfo.user_nick || '*'}}</p>
            <p class="tag" v-if="myShareInfo.numbers > 0">
              <span class="text">邀请达人</span>
            </p>
            <p class="tip" v-if="myShareInfo.amount">
              成功邀请
              <span class="num">{{myShareInfo.numbers}}</span> 位好友,获得<span class="num">{{myShareInfo.amount}}</span> 
            </p>
            <p class="tip" v-else>
              成功邀请
              <span class="num">{{myShareInfo.numbers}}</span> 位好友
            </p>
            <div class="self-list" v-if="myShareInfo.list">
              <ul class="list">
                <li class="item" v-for="(item, i) in myShareInfo.list" :key="i">
                  <img :src="item.user_icon" alt class="icon" v-if="item.user_icon"/>
                  <img src="../../assets/img/apply_avatar_bg.png" alt class="icon" v-else />
                </li>
              </ul>
            </div>
          </div>
          <div class="right">
            <button class="btn" @click="handleShowTipShare">邀请好友</button>
          </div>
        </div>

        <ul class="share-list">
          <li
            class="item"
            v-for="(item,i) in shareRankList"
            :key="i"
            @click="handleShowSubShareList(item.list)"
          >
            <div class="left">
              <img class="icon" :src="item.user_icon" alt v-if="item.user_icon" />
              <img src="../../assets/img/apply_avatar_bg.png" alt class="icon" v-else />
            </div>
            <div class="middle">
              <p class="nick">{{item.user_nick  || '*'}}</p>
              <p class="tip" v-if="item.amount">
                邀请
                <span class="num">{{item.numbers}}</span>
                位好友,获得<span class="num">{{item.amount}}</span>
              </p>
              <p class="tip" v-else>
                邀请
                <span class="num">{{item.numbers}}</span>
                位好友
              </p>
            </div>
            <div class="right">
              <div class="rank">{{item.rank}}</div>
            </div>
          </li>
        </ul>
        <!-- 个人分享信息 -->
        <div class="sub-share-box" v-show="showSubShareList" @click.self="showSubShareList = false">
          <div class="sub-share-wrapper">
            <div class="header">
              <p class="title">邀请信息</p>
              <i class="iconfont iconclose" @click="showSubShareList=false"></i>
            </div>
            <ul class="sub-share-list">
              <li class="item" v-for="(item, i) in subShareList" :key="i">
                <div class="item-icon">
                  <img class="icon" :src="item.user_icon" alt v-if="item.user_icon" />
                  <img src="../../assets/img/apply_avatar_bg.png" alt class="icon" v-else />
                </div>
                <div class="item-content">
                  <!-- <span class="nick">{{item.user_nick || '*'}}</span> -->
                  <p class="nick">{{item.user_nick || '*'}}
                    <span style="color:#999;font-size:0.7rem;" v-if="item.amount">,获得</span>
                    <span style="color:red;font-size:0.7rem;" v-if="item.amount">{{item.amount}}</span>
                  </p>
                  <div class="info">
                    <span class="time">{{item.add_time}}</span>
                    <span class="num">浏览：{{item.looks_numbers}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>

    <!-- 提示分享界面 -->
    <div class="tip-share" v-show="showTipShare" @click.self="showTipShare = false">
      <p class="text">点击右上角，开始邀请好友吧~~</p>
      <img class="img" src="../../assets/switch/share_arrow.png" alt />
    </div>

    <!-- 正在播放 -->
    <transition name="playIcon">
      <div
        class="books_isplaying"
        v-show="isPlaying && showPlayIcon && !showPlayControl"
        @click="handlePlay"
      ></div>
    </transition>
    <transition name="playControl">
      <div class="books_audio_control" v-show="showPlayControl">
        <span class="audio_play_btn" @click="play(index,'play')">
          <i class="iconfont iconmusic-icon_pause" v-if="isPlaying"></i>
          <i class="iconfont iconplay" v-else></i>
        </span>
        <!-- <span class="audio_play_progress"></span> -->
        <span class="audio_play_name" @click="goAnchor('anchor_' + index)">
          <i class="iconfont icon-dingwei"></i>
          <span>{{index >= 0 ? spots_list[index].spots_name : ""}}</span>
        </span>
        <i class="iconfont iconclose" @click.stop="play(index,'close')"></i>
        <!-- <span class="audio_play_close" @click.stop="play(index,'close')"></span> -->
      </div>
    </transition>
    <!-- 联系我们 -->
    <technical-support :ts_type_="ts_type_" contact_side="bottom" :id="label_id"></technical-support>

    <!-- 背景音乐 -->
    <bg-music :bg_audio_url="bg_audio_url" :play_bg_music="play_bg_music" v-if="bg_audio_url"></bg-music>

    <!-- 发布红包 -->
    <publish-button :id="label_id" id_type_="books" v-if="is_admin" bottom="6.4rem"></publish-button>

    <!-- 微信扫描二维码 -->
    <transition name='anim-opacity'>
      <div class="qrcode-box" v-show="showQrCode" v-if="info.qr_code" @click.self="showQrCode=false">
        <qr-code :showQrCode="showQrCode" :qrcode="info.qr_code" @closecode="showQrCode=false"></qr-code>
      </div>
    </transition>
  </div>
</template>

<script>
import { Toast, Indicator } from 'mint-ui'
import TechnicalSupport from '../../components/TechnicalSupport'
import RedPacket from '../../components/RedPacket'
import bgMusic from '../../components/Music'
import BackTop from '../../components/BackTop'
import UserList from '../../components/UserList'
import PublishButton from '../../components/PublishButton'
import QrCode from '../../components/QrCode'
import { Marquee, Slide } from "vue-marquee-component"
var wx = require('weixin-js-sdk')
export default {
  components: {
    'technical-support': TechnicalSupport, // 联系我们
    'red-packet': RedPacket, // 红包雨
    'bg-music': bgMusic, // 背景音乐
    'back-top': BackTop, // 回到顶部
    'user-list': UserList,
    'publish-button': PublishButton,
    QrCode,
     [Marquee.name]: Marquee,
    [Slide.name]: Slide
  },
  data () {
    return {
      audioActive: false,
      showNav: false, // 显示目录
      tl_active: -1, // 显示当前景点
      showProgress: false,

      // 数据
      show_vote: !!this.$route.query.show_vote, // 从投票页面进来,表示在路书界面显示投票按钮
      label_id: this.$route.query.label_id, // 路书的ID
      user_id: this.$route.query.user_id ? this.$route.query.user_id : '', // 用户的ID
      info: {},
      spots_list: [],
      isPlaying: false, // 正在播放
      showPlayIcon: false, // 播放图标
      showPlayControl: false, // 播放条
      index: -1, // 用于记录路书行程菜单的序号
      have_win: false, // 显示完赛证书
      have_reward_list: false, // 显示排行榜
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      bShow: '', // this.$route.query.bShow,//是否开始投票了
      bVote: '', // this.$route.query.bVote,//投票的状态,未投,已投

      open_id: localStorage.getItem('open_id_' + this.$route.query.label_id)
        ? localStorage.getItem('open_id_' + this.$route.query.label_id)
        : '',
      share_open_id: this.$route.query.share_open_id
        ? this.$route.query.share_open_id
        : '',
      aid: this.$route.query.aid ? this.$route.query.aid : 0,
      vote_numbers: 0, // 投票数
      have_complete_point: false, // 是否显示完成任务点的信息
      have_join_root_books: false, // 是否存在参加路书,就是没有完成所有任务点的路书
      price: 0, // 导航和语音讲解的费用
      is_pay: 0, // 是否支付
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      park_id: 298,
      is_show_language: 0, // 是否显示语言切换
      language: 1,
      bg_audio_url: '', // 背景音乐
      showBackTop: false,
      type_: 'books',
      ts_type_: 'books',

      redPacketList: '', // 红包
      have_red_packet: 0,
      money_: 0, // 已经获得的金额
      pay_numbers: 0, // 已经赞赏人的个数
      sUrl: '',
      play_bg_music: true, // 播放背景音乐
      is_admin: 0,

      // company
      companyInfo: {},
      haveCompany: false,

      // share
      myShareInfo: {},
      shareRankList: [],
      subShareList: [],
      isGetShareData: false,
      showShareRank: false,
      shareRankPage: 1,
      haveMoreShareData: true,
      showTipShare: false,
      showSubShareList: false,
        red_packet:'',
        marquee_list:[],//获奖用户滚动显示

      // 非微信导航、解说时显示二维码
      showQrCode: false
    }
  },
  watch: {
    $route (to, from) {
      this.$router.go(0)
    }
  },
  mounted () {
    this.initSUrl()
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id !== 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token === this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem(
            'open_id_' + this.label_id,
            this.$route.query.open_id
          )
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.label_id, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.label_id, '')
      }
    }

    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }
    // 在冒泡阶段处理滚动的事件
    window.addEventListener('scroll', this.handleScroll)
    this.getData() //

    // 获取公司信息
    this.getCompanyInfo(this.label_id, this.token).then(
      res => {
        this.companyInfo = res
        this.haveCompany = true
      },
      err => {
        console.log(err)
      }
    )

    this.$nextTick(() => {
      this.$refs['share-rank-box'].addEventListener('scroll', e => {
        this.bindShareListScroll(e)
      })
    })
  },
  methods: {
    handleShowSubShareList (ls) {
      if (ls && ls.length > 0) {
        this.subShareList = ls
        this.showSubShareList = true
      } else {
        return false
      }
    },
    handleShowTipShare () {
      if (this.is_weixin) {
        this.showTipShare = true
        setTimeout(() => {
          this.showTipShare = false
        }, 3000)
      } else {
        Toast('请在微信中打开')
      }
    },
    // 分享列表滚动
    bindShareListScroll (e) {
      if (!this.haveMoreShareData || this.isGetShareData) {
        // 没有更多数据或者正在请求数据
        return false
      }
      // console.log(e.target.scrollTop);
      // console.log(e.target.clientHeight);
      // console.log(e.target.scrollHeight);
      let st = e.target.scrollTop
      let ch = e.target.clientHeight
      let sh = e.target.scrollHeight
      if (st + ch >= sh - 10) {
        this.getShareRank('more') // 获取更多分享数据
      }
    },
    // 获取邀请数据
    getShareRank (type) {
      if (this.isGetShareData) {
        return false
      }

      if (type === 'show' && this.shareRankPage > 1) {
        this.showShareRank = true
        return false
      }
      this.isGetShareData = true
      Indicator.open('加载中...')
      this.$ajax(
        'post',
        'Share/get_label_share_looks_rank_data',
        {
          page: this.shareRankPage,
          id: this.label_id,
          token: this.token,
          open_id: this.open_id,
            id_type_:this.ts_type_
        },
        res => {
          // console.log(res);
          if (res.data.id === 0) {
            Object.assign(this.myShareInfo, res.data.data.myself);
            if(res.data.data.red_packet)
            {
                this.red_packet = res.data.data.red_packet;
            }

            this.shareRankList.push(...res.data.data.list);

          } else {
            console.log(res.data.msg)
          }

          if (
            res.data.id !== 0 ||
            !res.data.data.list ||
            res.data.data.list.length < 10
          ) {
            this.haveMoreShareData = false
          }

          if (
            // eslint-disable-next-line eqeqeq
            this.shareRankPage == 1 &&
            (res.data.id !== 0 ||
              !res.data.data.list ||
              res.data.data.list.length === 0)
          ) {
            Toast('暂无邀请信息')
            Indicator.close()

            this.isGetShareData = false
            return false
          }

          Indicator.close()
          this.shareRankPage++
          this.showShareRank = true
          this.isGetShareData = false
        }
      )
    },
    // 跳转地图
    jumpToMap () {
      if (this.info.url_) {
        window.location.href = this.info.url_
      } else {
        this.$router.push({
          path: '/DrawMap/leafletNoDrawMap',
          query: {
            id: this.label_id
          }
        })
      }
    },
    // 播放结束
    audioPlayEnd () {
      console.log(this.index)
      this.isPlaying = false
      this.showPlayIcon = false
      this.showPlayControl = true
    },
    // 跳转路书
    jumpToMe (item) {
      if (item.url) {
        window.location.href = item.url
      } else {
        if (item.id) {
          this.$router.push({
            path: '/books/books',
            query: {
              label_id: item.id
            }
          })
        } else {
          console.log('参数错误')
        }
      }
    },
    // 进入商户详情
    jumpToDetail (i) {
      this.$router.push({
        path: '/footprints/PrimaryScenic',
        query: {
          sid: this.spots_list[i].id,
          id_: this.label_id
        }
      })
    },
    // 进行赞赏支付
    pay () {
      if (this.is_weixin) {
        if (this.open_id) {
          this.$router.push({
            path: '/footprints/PayMoney',
            query: { label_id: this.label_id }
          })
        } else {
          // 还未授权
          this.authorize()
        }
      } else {
        Toast('请在微信中打开')
      }
    },
    // 锚点跳转
    goAnchor (id) {
      this.hideNavBar()
      let anchor = document.getElementById(id).offsetTop
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let targetScroll = anchor - scrollTop

      let timer = setInterval(() => {
        let ispeed = Math.floor(targetScroll / 20)
        document.documentElement.scrollTop = scrollTop + ispeed
        document.body.scrollTop = scrollTop + ispeed
        scrollTop = scrollTop + ispeed
        if (
          (ispeed > 0 && scrollTop >= anchor - 100) ||
          (ispeed < 0 && scrollTop <= anchor - 100)
        ) {
          document.body.scrollTop = document.documentElement.scrollTop =
            anchor - 100
          clearInterval(timer)
        }
      }, 16)
    },
    // 隐藏目录
    hideNavBar () {
      this.showNav = false
    },
    // 显示目录导航
    showNavBar () {
      this.showNav = !this.showNav
    },
    //   显示回到顶部
    handleScroll () {
      if (this.isPlaying && this.showPlayControl) {
        this.showPlayIcon = true
        this.showPlayControl = false
      }

      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop > 400) {
        this.showBackTop = true
      } else {
        this.showBackTop = false
      }

      // let clientHeight =
      //   document.documentElement.clientHeight || document.body.clientHeight
      let liArr = document.querySelectorAll('.timeline_item')

      for (let i = 0; i < liArr.length; i++) {
        if (scrollTop === 0 || scrollTop > liArr[liArr.length - 1].offsetTop) {
          this.tl_active = -1
        } else if (scrollTop > 0 && scrollTop <= liArr[0].offsetTop) {
          this.tl_active = 0
        } else if (
          scrollTop >= liArr[i].offsetTop &&
          scrollTop <= liArr[i + 1].offsetTop
        ) {
          this.tl_active = i + 1
        }
        if (
          this.tl_active >= 0 &&
          this.tl_active <= this.info.spots_list.length
        ) {
          this.showProgress = true
        } else {
          this.showProgress = false
        }
      }
    },
    // 回到顶部
    backToTop () {
      this.hideNavBar()
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop

      let timer = setInterval(() => {
        let ispeed = Math.floor(-scrollTop / 5)
        document.documentElement.scrollTop = scrollTop + ispeed
        document.body.scrollTop = scrollTop + ispeed
        scrollTop = scrollTop + ispeed
        if (scrollTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    },
    // 查看赞赏列表
    OpenLabelUserList (where_) {
      this.$router.push({
        path: '/footprints/LabelUserList',
        query: {
          id_: this.label_id,
          where_: where_,
          type_: 'books',
          sid: 0
        }
      })
    },
    /* 进行导航 */
    openLocation (lng, lat, title, address) {
      if (this.is_weixin) {
        if (this.price) {
          // 收费的路书
          if (this.is_pay) {
            // 已经支付了，可以直接使用导航
            this.wx_nav(window.location.href, lng, lat, title, address)
          } else {
            // 还未支付
            if (this.open_id) {
              var openUrl =
                'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
                this.label_id +
                '&backUrl=books&open_id=' +
                this.open_id +
                '&token=' +
                this.token +
                '&park_id=' +
                this.park_id +
                '&version=' +
                this.version +
                '&user_id=' +
                this.user_id
              window.location.href = openUrl
            } else {
              this.authorize()
            }
          }
        } else {
          // 免费路书,直接导航
          this.wx_nav(window.location.href, lng, lat, title, address)
        }
      } else {
        if (this.info.qr_code) {
          this.showQrCode = true
        } else {
          Toast('请在微信中打开,使用导航')
        }
      }
    },
    // 处理播放
    handlePlay () {
      this.showPlayIcon = false
      this.showPlayControl = true
    },
    // 点击语音导览
    play (id, type) {
      var that = this
      // 循环设置所有的active属性为flase
      this.info.spots_list.forEach(function (data, i) {
        that.$set(that.info.spots_list[i], 'active', false)
      })
      var audioAll = document.querySelectorAll('.line_audio')
      var audio = document.getElementById('audio_' + id)
      var spotsList = this.info.spots_list
      // 暂停所有的音频
      for (var i = 0; i < audioAll.length; i++) {
        audioAll[i].pause()
        audioAll[i].currentTime = 0
        var arr_ = audioAll[i].id.split('_')
        if (this.language === 1 || this.language === '1') {
          audioAll[i].src = spotsList[arr_[1]].audio_path
        } else {
          audioAll[i].src = spotsList[arr_[1]].en_audio_path
        }
      }
      // 点击播放暂停按钮
      // 点击关闭按钮
      if (type && type === 'close') {
        this.showPlayControl = false
        this.index = -1
        return
      } else if (type && type === 'play') {
        if (this.isPlaying) {
        } else {
          this.showPlayIcon = true
          this.showPlayControl = false
        }
      }

      if (this.is_weixin) {
        if (this.price) {
          if (this.is_pay) {
            // 表示已经支付了,可以直接听语音
            if (id === this.index) {
              if (this.isPlaying) {
                console.log('暂停')
                audio.pause()
                this.isPlaying = false
                this.showPlayIcon = false
              } else {
                console.log('播放')
                audio.play()
                this.play_bg_music = false // 暂停背景音乐
                this.isPlaying = true
                this.showPlayIcon = true
                this.$set(this.info.spots_list[id], 'active', true)
              }
            } else {
              console.log('播放')
              audio.play()

              this.play_bg_music = false // 暂停背景音乐
              this.isPlaying = true
              this.showPlayIcon = true
              this.$set(this.info.spots_list[id], 'active', true)
            }
            this.index = id
          } else {
            // 还未支付
            if (this.open_id) {
              // 已经授权过了,进行支付
              const openUrl =
                'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
                this.label_id +
                '&sid=0&backUrl=books&open_id=' +
                this.open_id +
                '&token=' +
                this.token +
                '&park_id=' +
                this.park_id +
                '&version=' +
                this.version +
                '&user_id=' +
                this.user_id
              window.location.href = openUrl
            } else {
              this.authorize()
            }
          }
        } else {
          // 免费的直接可播放
          if (id === this.index) {
            if (this.isPlaying) {
              console.log('暂停')
              audio.pause()
              this.isPlaying = false
              this.showPlayIcon = false
            } else {
              console.log('播放')
              audio.play()
              this.play_bg_music = false // 暂停背景音乐
              this.isPlaying = true
              this.showPlayIcon = true
              this.$set(this.info.spots_list[id], 'active', true)
            }
          } else {
            console.log('播放')
            audio.play()
            this.play_bg_music = false // 暂停背景音乐
            this.isPlaying = true
            this.showPlayIcon = true
            this.$set(this.info.spots_list[id], 'active', true)
          }
          this.index = id
        }
      } else {
        Toast('请在微信中打开')
        this.showQrCode = true
      }
    },
    // 切换语言
    switch_language (language) {
      var that = this
      // 循环设置所有的active属性为flase
      this.info.spots_list.forEach(function (data, i) {
        that.$set(that.info.spots_list[i], 'active', false)
      })
      if (language === 1 || language === '1') {
        this.language = 2 // 英文
      } else {
        this.language = 1 // 中文
      }
      var spotsList = this.info.spots_list
      var audioAll = document.querySelectorAll('.line_audio')

      // 暂停所有的音频
      for (var i = 0; i < audioAll.length; i++) {
        audioAll[i].pause()
        audioAll[i].currentTime = 0
        var arr_ = audioAll[i].id.split('_')
        var index_ = parseInt(arr_[1])
        if (language === 1 || language === '1') {
          audioAll[i].src = spotsList[index_].en_audio_path
        } else {
          audioAll[i].src = spotsList[index_].audio_path
        }
        audioAll[i].pause()
      }
      this.is_playing = 0
    },
    // 获取数据
    getData () {
      // 判断用户是否授权
      if (this.open_id) {
        Indicator.open('加载中...')
        // 已经授权
        // 发送请求获取路书数据
        this.$ajax(
          'post',
          'foot/get_one_user_collect_spots_info',
          {
            user_id: '',
            label_id: this.label_id,
            union_id: this.open_id,
            id_type_: 'books',
            share_open_id: this.share_open_id
          },
          res => {
            if (res.data.id === 0) {
              this.info = res.data.data // 返回的数据
              document.title = this.info.label_name // 设置标题
              this.is_pay = res.data.data.is_pay
              this.price = res.data.data.price
              this.bShow = res.data.data.show
              this.bVote = res.data.data.vote
              this.money_ = res.data.data.money_
              this.pay_numbers = res.data.data.pay_numbers
              this.is_show_language = res.data.data.is_show_language // 显示切换语言
              this.vote_numbers = res.data.data.vote_numbers
                ? res.data.data.vote_numbers
                : 0
              this.is_admin = this.info.is_admin // 管理员标识

              this.bg_audio_url = res.data.data.bg_audio_url
                ? res.data.data.bg_audio_url
                : '' // 背景音乐
                this.marquee_list = res.data.data.marquee_list||[];//获奖用户滚动信息

              // 红包信息
              if (this.info.have_red_packet_rain) {
                this.redPacketList = this.info.red_packet_rain_image_url_list
                this.have_red_packet = this.info.have_red_packet_rain
              }
              // 音频
              if (res.data.data.zhAudioDuration) {
                this.zhAudioDurationText = this.FormatAudioTime(
                  res.data.data.zhAudioDuration
                )
              }
              for (var i = 0; i < this.info.spots_list.length; i++) {
                // 给数据增加两个字段
                this.$set(this.info.spots_list[i], 'active', false)
                this.$set(this.info.spots_list[i], 'index', i)
              }
              this.spots_list = this.info.spots_list
              if (res.data.data.have_win === 1 || res.data.data.have_win === '1') {
                this.have_win = true
              } else {
                this.have_win = false
              }
              if (res.data.data.have_reward_list === 1 || res.data.data.have_reward_list === '1') {
                this.have_reward_list = true
              } else {
                this.have_reward_list = false
              }
              if (res.data.data.have_join_root_books === 1 || res.data.data.have_join_root_books === '1') {
                // 说明有参加路书,其实就是没有完成所有点的路书
                this.have_join_root_books = true
              } else {
                this.have_join_root_books = false
              }
              if (res.data.data.have_complete_point === 1 || res.data.data.have_complete_point === '1') {
                // 判断是否显示完成点位的信息
                this.have_complete_point = true
              } else {
                this.have_complete_point = false
              }

              if (!this.spots_list.length) {
                return false
              }

              if (this.is_weixin) {
                // 微信分享
                const wxTitle = res.data.data.label_name
                const wxContent = res.data.data.wx_content
                const wxImg = res.data.data.spots_list[0].android_newUrl
                this.wx_share_callback(window.location.href, {
                  url: this.sUrl,
                  id_: this.label_id,
                  id_type_: 'books',
                  token: this.token,
                  open_id: this.open_id,
                  title: wxTitle,
                  content: wxContent,
                  img: wxImg
                })
              }
              console.log(this.info)
              Indicator.close()
            }
          }
        )
      } else {
        // 用户还未授权，跳转到授权页面,进行授权

        this.authorize()
      }
    },
    // 授权方法
    authorize () {
      const openUrl =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.label_id +
        '&state=' +
        this.type_ +
        '&share_open_id=' +
        this.share_open_id
      window.location.href = openUrl
    },
    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id === 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            if (info) {
              if (info.url) {
                let urlArr = info.url.split('share_open_id')

                url = urlArr[0] + '&share_open_id=' + this.open_id
              } else {
                url = url + '&share_open_id=' + this.open_id
              }
            } else {
              url = url + '&share_open_id=' + this.open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id === 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },
    // 初始化sUrl
    initSUrl () {
      this.sUrl = window.location.href
    }
  }
}
</script>

<style scoped>
#books {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.books_container {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  background-color: rgb(244, 245, 247);
  /* padding-top: 2.8rem; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
/* 切换语言 */
.switch {
  position: fixed;
  top: 4.8rem;
  right: 0.6rem;
  z-index: 10;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  background-color: rgba(0, 0, 0, 0.3);
  /* box-shadow: 0 0 4px #aaa; */
  border: 1px solid #fff;
}
.switch .sw-item {
  display: inline-block;
  position: relative;
  width: 100%;
}
.switch .sw-item img {
  width: 100%;
}
.books_container .books_menu {
  position: fixed;
  bottom: 2.8rem;
  right: 0.6rem;
  width: 1.6rem;
  height: 1.6rem;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 13;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.books_container .books_menu > .iconfont {
  color: #fff;
  font-size: 1rem;
}
.books_container .books_nav {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
}
.books_container .nav_bar {
  position: fixed;
  right: 0;
  width: 72vw;
  max-width: 360px;
  height: 100vh;
  top: 0;
  z-index: 12;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  padding: 2rem;
}
.books_container .menu_bar li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.88rem;
  margin-bottom: 0.5rem;
  height: 1.2rem;
  line-height: 1.2rem;
  cursor: pointer;
}

.books_container .menu_bar li .menu_index {
  display: inline-block;
  background-color: #666;
  width: 1.4rem;
  /* height: 1rem; */
  /* font-size: 0.66rem; */
  text-align: center;
  color: #fff;
  border-radius: 4px;
  transform-origin: center center;
  transform: scale(0.88);
  transition: 0.3s;
}
.books_container .menu_bar li.mb_active .menu_index {
  background-color: rgb(236, 24, 101);
  transition: 0.3s;
}
.books_container .menu_bar li.mb_active .menu_content {
  color: rgb(236, 24, 101);
  transition: 0.3s;
}
.books_container .menu_bar li .menu_content {
  color: #999;
  transition: 0.3s;
}

.navbar-enter,
.navbar-leave-to {
  transform: translateX(100%);
}
.navbar-enter-to,
.navbar-leave {
  transform: translateX(0);
}
.navbar-enter-active,
.navbar-leave-active {
  transition: ease 0.3s;
}
.books_container .books_menu span {
  display: inline-block;
  width: 16px;
  height: 2px;
  box-sizing: border-box;
  /* border-top: 2px solid #000; */
  background-color: #fff;
}
.books_container .books_menu span:nth-child(2) {
  margin: 4px 0;
}
.books_container .books_backtop {
  position: fixed;
  width: 28px;
  height: 28px;
  background: transparent;
  right: 12px;
  bottom: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 3;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  cursor: pointer;
}
.books_container .books_backtop span {
  display: inline-block;
  border-bottom: 10px solid #333;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.backtop-enter,
.backtop-leave-to {
  opacity: 0;
  transform: translateY(6px);
}
.backtop-enter-to,
.backtop-leave {
  opacity: 1;
  transform: translateY(0);
}
.backtop-enter-active,
.backtop-leave-active {
  transition: all 0.5s ease;
}

.books_container .books_progress {
  position: fixed;
  width: 100%;
  height: 49px;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}
.books_container .books_progress i {
  color: rgb(229, 58, 61);
  font-size: 18px;
  position: absolute;
  left: calc(10% - 1rem);
  bottom: 4px;
}
.books_container .books_progress .progress_wrapper {
  position: absolute;
  width: 80%;
  height: 4px;
  bottom: 13px;
  left: 10%;
  border-radius: 4px;
  background: linear-gradient(rgb(250, 192, 195), rgb(252, 151, 155));
}

.books_container .books_progress .progress_wrapper .progress {
  position: absolute;
  /* width: 10%; */
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 4px;
  background-color: rgb(229, 58, 61);
  transition: 0.5s;
}
.books_container .books_progress .progress_wrapper .progress::after {
  position: absolute;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(229, 58, 61);
  right: -2px;
  top: -2px;
}
.books_container .books_progress .progress_wrapper .instance {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
  font-size: 10px;
  height: 16px;
  line-height: 18px;
  width: 46px;
  text-align: center;
  top: -24px;
  right: -20px;
  color: #fff;

  border-radius: 2px;
  background-color: rgb(229, 58, 61);
  transition: 0.5s;
}
.books_container .books_progress .progress_wrapper .instance::after {
  content: "";
  position: absolute;
  display: block;
  bottom: -4px;
  right: 18px;
  border-top: 4px solid rgb(229, 58, 61);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  /* background-color: rgb(229, 58, 61); */
}
.progress-enter,
.progress-leave-to {
  opacity: 0;
}
.progress-enter-to,
.progress-leave {
  opacity: 1;
}
.progress-enter-active,
.progress-leave-active {
  transition: all 0.5s;
}
.books_container .books_header {
  max-width: 500px;
  margin: 0 auto;
  /* height: 130px; */
  background-color: rgb(230, 55, 58);
}
.books_container .books_header .header_title {
  text-align: center;
  font-size: 1.6rem;
  padding: 20px 0;
  color: #fff;
}
.books_container .books_header .header_content {
  display: flex;
  color: #fff;
  justify-content: center;
  padding-bottom: 20px;
  z-index: 1;
}
.books_container .books_header .header_content .header_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px dotted #eee;
}

.books_container .books_header .header_content .header_info:nth-last-child(1) {
  border-right: none;
}

/* 备注删除 start */
.books_container .books_setout {
  width: 100%;
  box-sizing: border-box;
  height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
  /* background-color: #ff0000; */
}
.books_container .books_setout .setout_item {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  /* color: #d81e06; */
  text-align: center;
}
.books_container .books_setout .setout_item span {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  color: #000;
  background-color: rgba(255, 255, 255, 0.548);
  /* text-align: center; */
}
.books_container .books_setout .setout_audio {
  background: url(../../assets/img/line_multimedia.png) no-repeat;
  background-size: contain;
}
.books_container .books_setout .setout_nav {
  background: url(../../assets/img/line_map.png) no-repeat;
  background-size: contain;
}
.books_container .books_setout .setout_route {
  background: url(../../assets/img/line_journey.png) no-repeat;
  background-size: contain;
}
/* 备注删除 end */

.books_container .books_main {
  width: 100%;
  /* background-color: rgb(227, 231, 233); */
}

.books_container .books_main .main_timeline {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  margin: auto;
}
.books_container .books_main .main_timeline .timeline_item {
  position: relative;
  margin-left: 38px;
  box-sizing: border-box;
  box-shadow: 1px 1px 2px #ccc;
  margin-bottom: 20px;
  transition: 0.5s;
}
.books_container .books_main .main_timeline .timeline_item.tl_active {
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
}

.books_container .books_main .main_timeline .timeline_item .timeline_line {
  position: absolute;
  width: 1px;
  height: calc(100% + 60px);
  background-color: #ccc;
  top: 12px;
  left: -30px;
}
.books_container .books_main .main_timeline .timeline_item .timeline_length {
  position: absolute;
  top: 50%;
  left: -29px;
  color: #ccc;
  transform-origin: left center;
  transform: rotate(90deg);
  background-color: rgb(244, 245, 247);
}
.books_container .books_main .main_timeline .timeline_item::before {
  display: block;
  content: "";
  position: absolute;
  top: 10px;
  left: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 12px solid #fff;
}

.books_container .books_main .main_timeline .timeline_item .timeline_int {
  display: block;
  padding: 12px;
  position: relative;
  /* margin-left: 20px; */
  background-color: #fff;
}

.books_container .books_main .main_timeline .timeline_item .timeline_title {
  font-size: 16px;
  /* margin-bottom: 4px; */
  font-weight: 600;
  color: #333;
}
.books_container
  .books_main
  .main_timeline
  .timeline_item.tl_active
  .timeline_title {
  color: rgb(3, 72, 129);
}
.books_container .books_main .main_timeline .timeline_item .timeline_tips {
  margin: 4px 0 12px 0;
}

.books_main .main_timeline .timeline_item .timeline_tips .timeline_activities {
  font-size: 12px;
  display: inline-block;
  padding: 0 4px;
  border-radius: 2px;
  border: 1px solid #d81e06;
  color: #fff;
  background-color: #d81e06;
}
.books_main .main_timeline .timeline_item .timeline_tips .timeline_tags {
  font-size: 12px;
  display: inline-block;
  padding: 0 4px;
  border-radius: 2px;
  border: 1px solid rgb(38, 165, 255);
  color: rgb(38, 165, 255);
  /* background-color: #d81e06; */
}
.timeline_item .timeline_content {
  font-size: 14px;
  margin-bottom: 6px;
  color: #999;
}
.timeline_item .timeline_info {
  width: 100%;
  display: flex;
}

.timeline_info .timeline_img {
  width: 50%;
  height: calc(33.5vw - 29px);
  position: relative;
  cursor: pointer;
}

@media (min-width: 524px) {
  .timeline_info .timeline_img {
  height: 148px;
}
}

.timeline_info .info_wrapper {
  width: 50%;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.timeline_info .info_wrapper .info_item {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 50%;
  position: relative;
  color: #333;
  cursor: pointer;
}

.timeline_info .info_wrapper i {
  /* font-size: 0.88rem; */
  color: #999;
  transition: 0.5s;
}
.timeline_item.tl_active .timeline_int .timeline_info .info_wrapper i {
  /* font-size: 1rem; */
  color: #e93a1d;
  transition: 0.5s;
}

.timeline_img .img_num {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  font-size: 0.6rem;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0 4px;
}

.timeline_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.timeline_item .timeline_dot {
  position: absolute;
  font-size: 0;
  top: 10px;
  left: -40px;
  width: 20px;
  height: 20px;
  background-color: rgb(250, 217, 230);
  /* background-color: rgb(244, 181, 205); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center center;
  transform: scale(0.9);
  transition: 0.5s;
}

.timeline_item.tl_active .timeline_dot {
  transform: scale(1.04);
  background-color: rgb(244, 181, 205);
  transition: 0.5s;
  box-shadow: 0 0 10px #aaa;
}

.timeline_item .timeline_dot span {
  display: inline-block;
  width: 4px;
  height: 4px;
  border: 3px solid #ed1165;
  background: #fff;
  border-radius: 100%;
}
.timeline_item .timeline_day {
  position: absolute;
  top: 42px;
  left: -44px;
  background-color: rgb(227, 231, 233);
  color: #666;
}

.books_container .books_appreciate {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.books_container .books_appreciate .appreciate_btn {
  margin-bottom: 1rem;
  display: inline-block;
  text-align: center;
  padding: 10px 24px;
  border-radius: 4px;
  color: #fff;
  background-color: rgb(230, 55, 58);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
}
.books_container .books_appreciate .appreciate_num {
  color: #999;
}

/* 进入地图 */
.function-btn {
  width: 100%;
  margin-bottom: 0.5rem;
  /* padding: 0 12px 0 50px; */
  box-sizing: border-box;
  font-size: 0.8rem;
}

.function-btn > .btn {
  margin-top: 2px;
  padding: 0.6rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  color: #333;
  background-color: #fff;
  cursor: pointer;
}

.function-btn > .btn > .iconfont {
  color: #aaa;
  font-size: 0.8rem;
}

/* 底部富文本 */
.dylan .advert {
  width: 100%;
  margin-top: 1rem;
}
.dylan .advert .advert_img {
  width: 100%;
}
.dylan .advert .advert_img img {
  width: 100%;
  display: block;
}
.books_container .books_rich_text {
  width: 100%;
  box-sizing: border-box;
  /* margin-top: 1rem; */
  padding: 0.5rem;
  background-color: #fff;
  overflow-x: hidden;
  color: #666;
  font-size: 0.8rem;
  line-height: 1.8;
}
.books_container .books_rich_text >>> img {
  width: 100%;
}

.books_container .books_looks {
  position: relative;
  padding: 0.4rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  background-color: #fff;
}

.books_container .books_looks .bl_head span {
  display: inline-block;
  padding: 2px 6px;
  text-align: left;
  border-radius: 4px;
  font-size: 0.88rem;
  color: #fff;
  background-color: rgb(215, 35, 14);
}
.books_container .books_looks .bl_body {
  position: relative;
  width: 100%;
  margin-top: 0.6rem;
  display: flex;
  flex-wrap: wrap;
  height: 10rem;
  overflow-y: auto;
}
.books_container .books_looks .bl_body::-webkit-scrollbar {
  display: none;
}
.books_container .books_looks .bl_body .bl_item {
  position: relative;
  width: 10%;
  box-sizing: border-box;
  padding: 2px;
}
.books_container .books_looks .bl_body .bl_item img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.books_container .books_friends {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #fff;
}
.books_container .books_friends .friends_title {
  font-weight: 400;
  font-size: 0.88rem;
  text-align: center;
  height: 2rem;
  line-height: 2rem;
  border-bottom: 1px solid rgb(168, 219, 246);
  color: #333;
  font-weight: 600;
}
.friends-list {
  margin-top: 1rem;
  color: #666;
}
.friends-list > .friends-item {
  text-align: center;
  margin: 30px 0;
}
.friends-list > .friends-item > .item-wrapper {
  font-size: 0.88rem;
  color: rgb(5, 96, 145);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
}

.friends-item > .item-wrapper > .item-banner {
  width: 100%;
  margin-top: 6px;
  border-radius: 6px;
}

/* .friends-item > .item-wrapper > .item-title {

 } */
/* 底部 */
/* .books_container .books_footer {
  margin-top: 1rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
}
.books_container .books_footer > a {
  color: #aaa;
} */
.company {
  position: relative;
  width: 100%;
  padding: 1rem 0;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 0.74rem;
}
/*  */
.books_isplaying {
  position: fixed;
  bottom: 50%;
  width: 1.8rem;
  height: 1.8rem;
  /* left: calc(50% - 0.9rem); */
  right: 0.6rem;
  background: transparent url(../../assets/switch/books_erji.png) no-repeat;
  background-size: contain;
  background-position: center center;
  border-radius: 50%;
  animation: playing 5s linear infinite;
  z-index: 3;
}
@keyframes playing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.books_audio_control {
  position: fixed;
  bottom: 50%;
  width: 50%;
  height: 1.8rem;
  right: 0.6rem;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 2em;
  color: rgb(163, 248, 241);
  display: flex;
  box-sizing: border-box;
  padding: 0 0.5rem;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.books_audio_control .audio_play_name {
  width: calc(100% - 3rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.books_audio_control .audio_play_name span {
  /* text-decoration: underline; */
  display: inline-block;
  border-bottom: 1px solid rgb(163, 248, 241);
}
.books_audio_control .audio_play_name .iconfont {
  font-size: 12px;
}
.books_audio_control .audio_play_btn {
  width: 1rem;
  height: 1rem;
}
.books_audio_control .audio_play_btn img {
  width: 1rem;
  height: 1rem;
}
.books_audio_control .audio_play_btn .iconfont {
  font-size: 1rem;
  color: #fff;
}
.books_audio_control .iconclose {
  font-size: 0.8rem;
  color: #fff;
}
.playIcon-enter,
.playIcon-leave-to {
  opacity: 0;
}
.playIcon-enter-to,
.playIcon-leave {
  opacity: 1;
}
.playIcon-enter-active,
.playIcon-leave-active {
  transition: 0.3s;
}
.playControl-enter,
.playControl-leave-to {
  opacity: 0.2;
  width: 1.8rem;
}
.playControl-enter-to,
.playControl-leave {
  opacity: 1;
  width: 50%;
}
.playControl-enter-active,
.playControl-leave-active {
  transition: 0.5s;
}

/* 分享排行页面 */
.slide-down-enter,
.slide-down-leave-to {
  transform: translateY(-100%);
}
.slide-down-enter-to,
.slide-down-leave {
  transform: translateY(0);
}
.slide-down-enter-active,
.slide-down-leave-active {
  transition: 0.3s;
}

.share-rank-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(244, 245, 247);
  overflow-y: auto;
  z-index: 1000;
}

.share-rank-box > .close {
  position: fixed;
  top: 0.6rem;
  left: 0.6rem;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  background-color: rgba(255, 255, 255, 0.4);
}

.share-rank-box > .close > .iconfont {
  vertical-align: middle;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.4);
}

.share-rank-box > .banner {
  height: 5.6rem;
  padding: 0 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(rgb(255, 155, 105), rgb(255, 205, 145));
}

.share-rank-box > .banner >   .banner-title {
  padding-bottom: 0.2rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  border-bottom: 1px solid #fff;

}

.share-rank-box > .banner >   .banner-icon > .iconfont {
  font-size: 4rem;
  color: rgba(255, 255, 255, 0.3);
}

.share-rank-box > .self-share {
  width: 100%;
  display: flex;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;
}

.share-rank-box > .self-share > .left {
  margin-right: 0.5rem;
}

.share-rank-box > .self-share > .middle {
  width: calc(100% - 7.2rem);
}

.self-share > .left > .icon {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
}

.self-share > .middle > .nick {
  font-size: 0.9rem;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.self-share > .middle > .tag {
  margin-top: 2px;
}

.self-share > .middle > .tag > .text {
  display: inline-block;
  padding: 1px 2px;
  /* background-color: rgb(255, 155, 105); */
  border: 1px solid rgb(255, 155, 105);
  color: rgb(255, 155, 105);
  border-radius: 2px;
  font-size: 11px;
}

.self-share > .middle > .tip {
  margin-top: 4px;
  font-size: 0.74rem;
  color: #999;
}

.self-share > .middle > .tip > .num {
  color: rgb(240, 122, 53);
}

.self-share > .middle > .self-list {
  margin-top: 4px;
  display: inline-flex;
  width: 100%;
  overflow-x: auto;
}

.self-list::-webkit-scrollbar {
  display: none;
}

.self-list > .list {
  display: inline-flex;
}

.self-list > .list > .item {
  width: 1rem;
  height: 1rem;
  margin-right: 2px;
  margin-top: 2px;
}

.self-list > .list > .item > .icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.self-share > .right {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}

.self-share > .right > .btn {
  width: 4rem;
  height: 1.6rem;
  border-radius: 2em;
  font-size: 0.74rem;
  color: #fff;
  background-color: rgb(26, 173, 22);
}

.share-rank-box > .share-list {
  width: 100%;
  margin-top: 0.5rem;
  background-color: #fff;

  box-sizing: border-box;
}

.share-list > .item {
  position: relative;
  width: 100%;
  display: flex;
  padding: 0.5rem;
  box-sizing: border-box;
  /* border-bottom: 1px solid transparent; */
  /* border-image: 100% 0 0 linear-gradient(90deg, transparent 3.6rem, #eee 0); */
}

.share-list > .item:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0.5rem;
  width: 80%;
  height: 0;
  background-color: transparent;
  border-bottom: 1px solid #eee;
}

.share-list > .item > .left {
  margin-right: 0.5rem;
}

.share-list > .item > .left > .icon {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
}

.share-list > .item > .middle {
  flex: 1;
}

.share-list > .item > .middle > .nick {
  font-size: 0.9rem;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.share-list > .item > .middle > .tip {
  margin-top: 4px;
  font-size: 0.74rem;
  color: #999;
}

.share-list > .item > .middle > .tip > .num {
  color: rgb(240, 122, 53);
}

.share-list > .item > .right {
  margin-left: 0.5rem;
}

.share-list > .item > .right > .rank {
  width: 1.7rem;
  height: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: #81888f;
  border: 4px solid rgba(255, 255, 255, 0.6);
}

.share-list > .item:nth-child(1) > .right > .rank {
  background-color: rgb(255, 110, 117);
}
.share-list > .item:nth-child(2) > .right > .rank {
  background-color: rgb(255, 197, 91);
}
.share-list > .item:nth-child(3) > .right > .rank {
  background-color: rgb(111, 189, 255);
}

/* 提示分享 */
.tip-share {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
}

.tip-share > .text {
  width: 100%;
  margin-top: 130px;
  text-align: center;
  font-size: 0.8rem;
  color: #fff;
}

.tip-share > .img {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
}

/* 个人分享列表 */
.sub-share-box {
  position: fixed;
  top: -10vh;
  left: -10vw;
  width: 120vw;
  height: 120vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
}

.sub-share-wrapper {
  width: 80vw;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.sub-share-wrapper > .header {
  position: relative;
  height: 2.6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, .3);
  background: linear-gradient(rgb(255, 155, 105), rgb(255, 205, 145));
}

.sub-share-wrapper > .header > .title {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.sub-share-wrapper > .header > .iconfont {
  position: absolute;
  right: 0;
  top: 0;
  width: 2.4rem;
  height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

.sub-share-list {
  min-height: 20vh;
  max-height: 60vh;
  padding: 0.5rem;
  box-sizing: border-box;
  overflow-y: auto;
}

.sub-share-list > .item {
  position: relative;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.sub-share-list > .item:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 3rem);
  height: 0;
  border-bottom: 1px solid #eee;
}

.sub-share-list .item-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.sub-share-list .item-icon > .icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.sub-share-list .item-content {
  width: calc(100% - 2.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub-share-list .item-content > .nick {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333;
  font-size: .8rem;
}


.sub-share-list .item-content > .info {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}

.sub-share-list .item-content > .info > .time {
  font-size: .7rem;
  color: #999;
}

.sub-share-list .item-content > .info > .num {
  padding: 1px 2px;
  /* background-color: rgb(255, 130, 110); */
  font-size: 11px;
  color: rgb(255, 130, 110);
  border: 1px solid rgba(255, 130, 110, .6);
  border-radius: 2px;
}

/* 微信二维码 */
.qrcode-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}


  /**
  红包显示数据
   */
   .statistics_percentage{
    width: 100%;
    box-sizing: border-box;

     margin-top: 1%;
     background-color: white;
     margin-bottom: 1%;
  }
  .statistics_percentage .statistics_name{
    display: block;
    padding: 0.2rem 0.1rem 0.4rem 0.1rem;
    color: #999;
    font-size: 0.9rem;
  }
  .statistics_percentage .numbers_percentage_outer {
  display: inline-block;
  width: 100%;
  height: 0.6rem;
  background-color: rgba(252, 200, 200, 0.5);
  border-radius: 2em;
}
 .numbers_percentage_outer .numbers_percentage_inner {
  display: block;
  width: 98%;
  height: 100%;
  background-color: rgb(248, 85, 85);
  border-radius: 2em;
  color: #fff;
}
</style>
