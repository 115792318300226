
<template>
  <div class="ParkMapLineList ParkPhoto map" v-if="info" v-title :data-title="title_name+'详情'">
    <!--给微信分享用的图片-->
    <!--<div class="hidden"><img :src="info.logo" v-if="logo"><img :src="park_logo" v-else></div>-->
    <div class="container_box" :class="{active:is_big}">
      <div id="container" style="width:100%; height:100%"></div>

      <div
        class="active_user_box ParkPhotoListDetail"
        :class="{active:have_title != 0}"
        v-if="aid != 1"
      >
        <div class="who_vote">
          <div class="row" v-if="have_title==0 && !is_map_info">
            <div class="list">
              <router-link
                :to="{name:'otherIndex',params:{uid:k.user_id}}"
                class="num_box"
                v-for="(k,i) in user_list"
                :key="i"
              >
                <img :src="k.user_icon" />
                <div class="num">
                  <i class="iconfont icon-zuji"></i>
                  {{k.spots_numbers}}
                </div>
                <span v-if="i<3">NO.{{i + 1}}</span>
              </router-link>
            </div>
          </div>
          <h2 v-if="have_title==1">{{title_content}}</h2>
          <ul class="map_info clearfix" v-if="is_map_info">
            <li>
              <p>总里程</p>
              <p>{{spots_point.length}}</p>
            </li>
            <li>
              <p>最高海拔</p>
              <p>{{spots_point.height}}</p>
            </li>
            <li>
              <p>最低海拔</p>
              <p>{{spots_point.low}}</p>
            </li>
            <li>
              <p>徒步时间</p>
              <p>{{spots_point.time_length}}</p>
            </li>
          </ul>
        </div>
        <!--点击游客-->
        <div class="fr" @click="click_tab_label('user')">
          <img src="../../assets/img/map_park_user_yes.png" alt v-if="map_user_yes" />
          <img src="../../assets/img/map_park_user.png" alt v-else />
        </div>
      </div>
      <!--左上角自动播放,语音播放,推荐路线-->
      <div class="auto_play" @click="auto_play">
        <img src="../../assets/img/auto_play_no.png" v-if="is_auto_play" />
        <img src="../../assets/img/auto_play_yes.png" v-else />
      </div>
      <div class="audio_select" @click="is_select_audio = !is_select_audio">
        <img src="../../assets/img/draw_map_logo.png" v-if="is_audio_select == -1 " />
        <img src="../../assets/img/machine.png" v-else-if="is_audio_select == 0 " />
        <img src="../../assets/img/male.png" v-else-if="is_audio_select == 1 " />
        <img src="../../assets/img/woman.png" v-else-if="is_audio_select == 2 " />
      </div>
      <div class="root_books" @click="is_play_root_books = !is_play_root_books">
        <img src="../../assets/img/play_root_books.png" />
      </div>
      <div class="left_but" v-if="is_big">
        <div class="mak_tab">
          <!--点/图片 切换-->
          <img
            src="../../assets/img/map_park_tab_img.png"
            alt
            @click="tab_button"
            v-if="is_tab_button"
          />
          <img
            src="../../assets/img/map_park_tab_img_maks.png"
            alt
            @click="tab_button"
            v-if="!is_tab_button"
          />
          <img
            src="../../assets/img/map_3d.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="is_tab_map_3d && aid != 1 "
          />
          <img
            src="../../assets/img/map_3d_yes.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="!is_tab_map_3d && aid != 1 "
          />
        </div>
        <div class="mak_tab">
          <!--地图 + -  -->
          <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
          <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
        </div>
        <div class="mak_tab map_my_location">
          <!--地图定位- -->
          <img src="../../assets/img/map_my_location.png" alt @click="click_Geo_location()" />
        </div>
      </div>
      <div class="button_list">
        <ul class="click_list_">
          <!--循环输出菜单-->
          <li v-for="(k) in menu_list" :key="k.id" @click="click_tab_label(k.id)">
            <img src="../../assets/img/draw_map_menu_item_stop.png" v-if="k.id == 15" />
            <img src="../../assets/img/draw_map_menu_item_ticket.png" v-if="k.id == 16" />
            <img src="../../assets/img/draw_map_menu_item_wc.png" v-if="k.id == 17" />
            <img src="../../assets/img/draw_map_menu_item_food.png" v-if="k.id == 18" />
            <img src="../../assets/img/draw_map_menu_item_spots.png" v-if="k.id == 19" />
            <img src="../../assets/img/draw_map_menu_item_shop.png" v-if="k.id == 20" />
            <img src="../../assets/img/draw_map_menu_item_sleep.png" v-if="k.id == 21" />
            <span class="menu-title">{{k.title}}</span>
          </li>
        </ul>

        <div
          class="content_list"
          :class="{root_book:is_root_books}"
          v-if="is_park_search || is_root_books"
        >
          <div class="park_search">
            <p v-if="is_park_search">共{{list_data.length}}个景点</p>
            <p v-if="is_root_books">共{{list_data.length}}个路书</p>
            <img
              @click="is_root_books = is_park_search = false"
              src="../../assets/img/footprints_delete.png"
              alt
            />
            <div class="input_search clearfix">
              <input class="fl" type="text" placeholder="请输入关键字" v-model="value_parkName" />
              <button class="fl" @click="searchData(value_parkName,is_park_search)">确定</button>
            </div>
            <ul>
              <li
                @click="list_path(k.id,k.label_fk_id)"
                :id="k.label_fk_id || k.id"
                v-for="(k) in list_data"
                :key="k.id"
              >{{k.spots_name || k.label_name}}</li>
            </ul>
          </div>
        </div>
      </div>

      <!--靠右边的景点列表-->
      <div class="click-spots-list" @click="show_spots_list = !show_spots_list">
        <div class="csl_body">
          <div class="img_">
            <img src="../../assets/img/draw_map_menu_item_spots.png" />
          </div>
          <div class="title">
            <span>景</span>
            <span>点</span>
            <span>列</span>
            <span>表</span>
          </div>
        </div>
      </div>
      <transition name="show_list">
        <div class="show-spots-list width_" v-if="show_spots_list">
          <div class="head">
            <div class="title">
              <span>景点列表</span>
            </div>
            <div class="del" @click="show_spots_list = !show_spots_list">
              <img src="../../assets/img/del.png" />
            </div>
          </div>
          <div class="item" v-for="(k,i) in main_spots_list" :key="i" @click="list_path(k.id)">
            <div class="item-title">
              <span>{{i+1}}-{{k.spots_name}}</span>
            </div>
          </div>
          <div class="item_" @click="show_spots_list = !show_spots_list">
            <div class="item-del">
              <span>收起</span>
            </div>
            <div class="img">
              <img src="../../assets/img/feed-title-more.png" />
            </div>
          </div>
        </div>
      </transition>
      <!--游玩路线-->
      <transition name="root_books">
        <div class="show-spots-list" v-if="is_play_root_books">
          <div
            class="item"
            v-for="(k,i) in root_books_list"
            :key="i"
            @click="list_path(0,k.label_fk_id)"
          >
            <div class="item-title">
              <span>{{i+1}}-{{k.label_name}}</span>
            </div>
          </div>
        </div>
      </transition>
      <!--语音切换-->
      <transition name="audio_list">
        <div class="show-spots-list spots_list_width" v-if="is_select_audio">
          <div class="audio_body">
            <div class="del" @click="is_select_audio = !is_select_audio">
              <img src="../../assets/img/del.png" />
            </div>
            <div class="title">
              <span>真人语音</span>
            </div>
            <div class="man">
              <div class="human" @click="select_audio_(1)">
                <div class="icon">
                  <img src="../../assets/img/male.png" />
                </div>
                <div class="nick">
                  <span>男声版</span>
                </div>
              </div>
              <div class="human" @click="select_audio_(2)">
                <div class="icon">
                  <img src="../../assets/img/woman.png" />
                </div>
                <div class="nick">
                  <span>女声版</span>
                </div>
              </div>
            </div>
            <div class="title">
              <span>合成语音</span>
            </div>
            <div class="machine">
              <div class="machine-item" @click="select_audio_(0)">
                <div class="icon">
                  <img src="../../assets/img/machine.png" />
                </div>
                <div class="nick">
                  <span>合成语音</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <ul class="LineList_list clearfix" v-if="aid > 1">
      <router-link
        tag="li"
        :to="{path:'/parkPhoto/ParkLineFoot',query:{aid:aid,park_id:park_id,type:19}}"
        class="jingdian"
      >
        <img src="../../assets/img/park_button_scene.png" alt />
        <p>景点</p>
      </router-link>
      <router-link
        tag="li"
        :to="{path:'/parkPhoto/ParkLineFoot',query:{aid:aid,park_id:park_id,type:18}}"
        class="chifan"
      >
        <img src="../../assets/img/park_button_eat.png" alt />
        <p>美食</p>
      </router-link>
      <router-link
        tag="li"
        :to="{path:'/parkPhoto/ParkLineFoot',query:{aid:aid,park_id:park_id,type:21}}"
        class="zhuzhi"
      >
        <img src="../../assets/img/park_button_ceas.png" alt />
        <p>住宿</p>
      </router-link>
      <router-link
        tag="li"
        :to="{path:'/parkPhoto/ParkLineFoot',query:{aid:aid,park_id:park_id,type:20}}"
        class="gouwuche-copy"
      >
        <img src="../../assets/img/park_button_buy.png" alt />
        <p>购物</p>
      </router-link>
      <router-link
        tag="li"
        :to="{path:'/note/NoteIndex',query:{pid:park_id,type:'parkNote'}}"
        class="youji"
      >
        <img src="../../assets/img/park_button_note.png" alt />
        <p>游记</p>
      </router-link>
      <router-link
        tag="li"
        :to="{path:'/footprints/FootprintsIndex',query:{pid:park_id,type:'parkFoot',lng:lng,lat:lat}}"
        class="zuji"
      >
        <img src="../../assets/img/park_button_foot.png" alt />
        <p>足迹</p>
      </router-link>
      <router-link tag="li" :to="{path:'/Guide',query:{pid:park_id}}" class="huodong">
        <img src="../../assets/img/park_button_guide.png" alt />
        <p>活动</p>
      </router-link>
      <router-link
        tag="li"
        :to="{path:'/parkPhoto/ParkLineFoot',query:{aid:aid,park_id:park_id,type:17}}"
        class="cesuo"
      >
        <img src="../../assets/img/park_button_cesuo.png" alt />
        <p>厕所</p>
      </router-link>
    </ul>
    <!--路书列表  组件-->
    <div v-if="info.root_books_list">
      <line-info
        ref="child"
        :length="2"
        :is_border="true"
        is_tit
        :datainfo="info.root_books_list"
        :user_id="user_id"
      ></line-info>
    </div>
    <!--足迹列表-->
    <footprints-list ref="child" @ievent="get_foot_list"></footprints-list>
    <!--音频空间-->
    <audio id="audio_id" src="audio_src"></audio>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
import LineInfo from "../../components/LineInfo";
import FootprintsList from "../../components/FootprintsList";
export default {
  components: {
    LineInfo,
    FootprintsList
  },
  data() {
    return {
      map: {},
      my_id: "",
      user_id: "",
      aid: this.$route.query.aid,
      park_id: this.$route.query.park_id,
      info: {},
      isred: false,
      user_list: [], //活跃用户
      list_data: [], //搜索框列表显示数据
      spots_list: [],
      spots_point: {}, //路书线路数据（里程、时间、海拔）
      Marker: [], //所有点
      img_Marker: [], //游客点
      page: 1,
      num: 2,
      label_info: this.$route.query.select_type
        ? this.$route.query.select_type
        : 19, //点击景点、美食。。请求点数据    记录当前点击的tab
      is_big: true, //地图放大缩小
      is_tab_button: true, //图片切换是否点击
      map_user_yes: false, //是否点击游客按钮
      is_tab_map_3d: true, //3D地图按钮是否点击
      is_park_search: false, //是否显示下拉列表公园数据
      is_root_books: false, //是否显示下拉列表路书线路数据
      is_map_info: false, //是否显示路书线路数据（里程、时间、海拔）
      value_parkName: "", //下拉列表搜索框val
      lng: this.$route.query.lng, //经度
      lat: this.$route.query.lat, //纬度
      have_title: "", // =0 > 显示活跃用户 首页按钮     =1 > 显示title  路书线路数据（里程、时间、海拔）
      title_content: "", //have_title = 1 展示的title
      url: "",
      select_type: this.$route.query.select_type
        ? this.$route.query.select_type
        : 19,
      title_name: this.$route.query.park_name
        ? this.$route.query.park_name
        : "",
      park_logo: this.$route.query.park_logo, //给微信分享的图片
      park_name: this.$route.query.park_name ? this.$route.query.park_name : "", //给微信分享的文字描述
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      wx_url:
        "http://www.zmlxj.com/api.php/weixin/wx_share?type=DrawMap&park_id=" +
        this.$route.query.park_id +
        "&aid=" +
        this.$route.query.aid +
        "&select_type=" +
        this.$route.query.select_type +
        "&park_name=" +
        this.$route.query.park_name +
        "&park_logo=" +
        this.$route.query.park_logo,
      have_main_spots_2: 0, //主景点
      have_foot_3: 0, //足迹
      have_geo_11: 0, //表示地质
      have_people_12: 0, //表示人文 默认为0->表示没有 1->表示有
      have_left_13: 0, //生物
      have_plant_14: 0, //植物
      have_stop_15: 0, //停车场
      have_ticket_16: 0, //售票厅
      have_wc_17: 0, //厕所
      have_food_18: 0, //美食
      have_spots_19: 0, //景点（非主景点-没有音频，富文本内容）
      have_shop_20: 0, //购物
      have_sleep_21: 0, //住宿
      other_url: "",
      logo: "",
      l_index: 0,
      r_index: 0,
      l_lng: 0,
      l_lat: 0,
      menu_list: [], //顶部菜单
      show_spots_list: false, //右边景点列表
      main_spots_list: [], //景点列表
      is_auto_play: true,
      is_play_root_books: false,
      root_books_list: [], //路线
      is_select_audio: false, //语音切换
      audio_src: "", //音频文件的路劲
      is_audio_select: 0,
      is_map_setFitView: 0
    };
  },
  mounted() {
    this.unload();
    if (this.is_weixin) {
      this.wx_share(this.wx_url);
    }
    document.title = this.title_name;

    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    if (this.park_id == 796) {
      link.href =
        "https://www.zmlxj.com/Public/map/nan_jing_qi_xia_shan_logo.jpg";
    } else {
      link.href = "https://m.zmlxj.com/favicon.ico";
    }

    document.getElementsByTagName("head")[0].appendChild(link);
    /*
            (function() {
                var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = 'http://www.zmlxj.com/Public/map/nan_jing_qi_xia_shan_logo.jpg';

                document.getElementsByTagName('head')[0].appendChild(link);
            })();*/
  },
  methods: {
    unload() {
      if (this.check_login()) {
        this.my_id = this.check_login().user_id;
      }

      this.get_data();
    },
    /*自动播放*/
    auto_play() {
      this.is_auto_play = !this.is_auto_play;
      /*进行定位,发送label_id,lng,lat,进行判断*/
      var that = this;
      if (!this.is_auto_play) {
        //设置定时器，每3秒刷新一次
        var self = this;
        setInterval(getTotelNumber, 300000);
        function getTotelNumber() {
          that.map_Geo_location({
            map: that.map,
            callback: function(lng, lat) {
              that.lng = lng;
              that.lat = lat;
              if (lng && lat) {
                if (that.park_id) {
                  this.$ajax(
                    "post",
                    "SpotsRace/get_near_spots",
                    {
                      park_id: that.park_id,
                      aid: that.aid,
                      lng: lng,
                      lat: lat,
                      token: "zmlxj_20181009"
                    },
                    ret => {
                      if (ret.data.id == 0) {
                        if (
                          that.is_audio_select == -1 ||
                          that.is_audio_select == 0
                        ) {
                          //合成的
                          that.audio_src = ret.data.data.audio_path;
                        } else if (that.is_audio_select == 1) {
                          that.audio_src = ret.data.data.man_audio_path;
                        } else {
                          that.audio_src = ret.data.data.woman_audio_path;
                        }

                        var audio = document.getElementById("audio_id");
                        document.addEventListener(
                          "WeixinJSBridgeReady",
                          function() {
                            audio.play();
                          },
                          false
                        );
                      }
                    }
                  );
                } else {
                  Toast("少值");
                }
              } else {
                Toast("定位失败");
              }
            }
          });
        }
        getTotelNumber();
      }
    },
    //            获取数据  渲染地图上的mak点
    get_data() {
      var that = this;
      localStorage.setItem("wx_title", this.park_name);
      localStorage.setItem("wx_content", this.park_name + "地图详情");
      localStorage.setItem("wx_img", this.park_logo);
      this.url = "SpotsRace/get_weixin_park_data";
      this.$ajax(
        "post",
        this.url,
        {
          park_id: this.park_id,
          aid: this.aid,
          page: 1,
          where: "web"
        },
        ret => {
          if (ret.data.id == 0) {
            this.info = ret.data.data;
            this.logo = ret.data.data.logo;
            this.other_url = ret.data.data.map_data.content;
            this.map = this.map_init(this.info.map_data);
            this.user_list = ret.data.data.user_list;
            this.have_title = ret.data.data.have_title;
            this.title_content = ret.data.data.title;
            this.spots_point_list = ret.data.data.spots_point_list;
            this.main_spots_list = ret.data.data.main_spots_list;
            this.l_index = ret.data.data.l_index;
            this.r_index = ret.data.data.r_index;
            this.l_lng = ret.data.data.l_lng;
            this.l_lat = ret.data.data.l_lat;
            this.menu_list = ret.data.data.menu_list;
            this.root_books_list = ret.data.data.root_books_list;
            this.is_map_setFitView = ret.data.data.is_map_setFitView;
            if (ret.data.data.spots_point_list.length > 0) {
              //this.map_rectangle(this.map,{'l_lng':118.952967,'l_lat':32.144917,'r_lng':118.981259,'r_lat':32.165481});

              this.map_add_Marker({
                map: this.map,
                l_index: this.l_index,
                r_index: this.r_index,
                l_lng: this.l_lng,
                l_lat: this.l_lat,
                is_map_setFitView: this.is_map_setFitView,
                is_audio_select: this.is_audio_select,
                list_data: ret.data.data.spots_point_list,
                callback: function(Marker_arr) {
                  that.Marker = Marker_arr;
                }
              });
              if (this.select_type) {
                if (this.select_type == 19) {
                  this.select_type = 2;
                }
                //说明是从投票页面进来的,需要调用一些下面的这个方法
                this.click_tab_label(this.select_type);
              }
            } else {
              Toast("暂无数据");
            }

            this.have_main_spots_2 = ret.data.data.have_icon_list.have_geo_11; //主景点
            this.have_foot_3 = ret.data.data.have_icon_list.have_foot_3; //足迹
            this.have_geo_11 = ret.data.data.have_icon_list.have_geo_11; //表示地质
            this.have_people_12 = ret.data.data.have_icon_list.have_people_12; //表示人文 默认为0->表示没有 1->表示有
            this.have_left_13 = ret.data.data.have_icon_list.have_left_13; //生物
            this.have_plant_14 = ret.data.data.have_icon_list.have_plant_14; //植物
            this.have_stop_15 = ret.data.data.have_icon_list.have_stop_15; //停车场
            this.have_ticket_16 = ret.data.data.have_icon_list.have_ticket_16; //售票厅
            this.have_wc_17 = ret.data.data.have_icon_list.have_wc_17; //厕所
            this.have_food_18 = ret.data.data.have_icon_list.have_food_18; //美食
            this.have_spots_19 = ret.data.data.have_icon_list.have_spots_19; //景点（非主景点-没有音频，富文本内容）
            this.have_shop_20 = ret.data.data.have_icon_list.have_shop_20; //购物
            this.have_sleep_21 = ret.data.data.have_icon_list.have_sleep_21; //住宿
            this.$refs.child.parentMsg({
              info: this.info.spots_list,
              isred: false,
              my_id: this.my_id,
              tit: false,
              tit2: true,
              parklink: true
            });
          }
        }
      );
    },
    go_other_url() {
      window.location.href = this.other_url;
    },
    //            传递数据到子组件 下拉加载更多   page=>页数
    get_foot_list(page) {
      this.$ajax(
        "post",
        this.url,
        {
          park_id: this.park_id,
          aid: this.aid,
          page: page
        },
        ret => {
          if (ret.data.id == 0) {
            this.spots_list = this.spots_list.concat(ret.data.data.spots_list);

            this.$refs.child.parentMsg({
              info: this.spots_list,
              isred: false,
              my_id: this.my_id,
              tit: false,
              tit2: true,
              parklink: true
            });
          }
        }
      );
    },
    //            点击地图定位我的位置
    click_Geo_location() {
      var that = this;

      that.map_Geo_location({
        map: this.map,
        callback: function(lng, lat) {
          that.lng = lng;
          that.lat = lat;
        }
      });
    },
    //            点击地图按钮切换图片/mak点
    tab_button() {
      if (!this.map_user_yes) {
        this.is_tab_button = !this.is_tab_button;
        this.map_cut_Marker(this.map, this.Marker, this.is_tab_button);
      }
    },
    /**
     *点击底部景点、路书显示数据列表
     *  @param type     ==park >显示公园景点数据
     *                  ==root >显示路书数据
     */
    click_list(type) {
      if (!this.is_big) {
        Toast("请进入地图查看");
        return;
      }
      if (type == "park") {
        if (this.is_root_books) {
          this.is_root_books = false;
          this.is_park_search = true;
        } else {
          this.is_park_search = !this.is_park_search;
        }
        if (this.main_spots_list.length > 0) {
          this.list_data = this.main_spots_list;
        } else {
          Toast("无景点");
          this.is_park_search = false;
        }
      } else if (type == "root") {
        if (this.is_park_search) {
          this.is_park_search = false;
          this.is_root_books = true;
        } else {
          this.is_root_books = !this.is_root_books;
        }
        if (this.info.root_books_list.length > 0) {
          this.list_data = this.info.root_books_list;
        } else {
          Toast("无路线 | 路书");
          this.is_root_books = false;
        }
      }
    },
    /**
     * 点击路书或者景点列表操作地图
     * @param id    地图显示景点mak 并弹出信息窗体
     * @param label_fk_id   地图显示路书线路
     */
    list_path(id, label_fk_id) {
      var bgMusic = document.getElementsByClassName("bgMusic")[0];
      if (bgMusic) {
        bgMusic.pause();
        bgMusic.currentTime = 0;
      }
      this.is_map_info = false;
      this.is_root_books = this.is_park_search = false;
      var that = this;
      if (id) {
        this.spots_point_list.forEach(function(item) {
          if (item.id == id) {
            //                            that.map_add_Marker(that.map, [item], null, function (Marker_arr) {
            //                                that.Marker = Marker_arr
            //                            })

            that.map_add_Marker({
              map: that.map,
              list_data: [item],
              is_audio_select: that.is_audio_select,
              callback: function(Marker_arr) {
                that.Marker = Marker_arr;
              }
            });
            that.map_newInfoWindow(
              that.map,
              item,
              "primary",
              that.is_audio_select
            );
          }
        });
        this.show_spots_list = !this.show_spots_list;
      } else if (label_fk_id) {
        this.$ajax(
          "post",
          "SpotsRace/get_root_book_point",
          { label_id: label_fk_id },
          ret => {
            if (ret.data.id == 0) {
              that.spots_point = ret.data.data;
              that.is_map_info = true;
              that.map_root_book_point(
                this.map,
                ret.data.data.spots_point_list,
                data => {
                  that.jump(data, label_fk_id);
                }
              );
            } else {
              Toast(ret.data.msg);
            }
          }
        );
      }
    },
    /**
     * 点击底部列表里面的搜索确定 找出数组数据返回显示
     * @param parkName  关键字
     * @param is_park_search    ==true  显示景点列表数据
     *                          ==false 显示路书列表数据
     */
    searchData(parkName, is_park_search) {
      if (is_park_search) {
        this.list_data = this.main_spots_list;
      } else {
        this.list_data = this.info.root_books_list;
      }
      if (parkName) {
        var result = [];
        for (var i = 0; i < this.list_data.length; i++) {
          var obj = this.list_data[i];
          for (var k in obj) {
            if (obj[k].indexOf(parkName.replace(/^\s*|\s*$/g, "")) != -1) {
              if (obj["spots_name"]) {
                result.push({ spots_name: obj["spots_name"], id: obj["id"] });
              } else {
                result.push({
                  label_name: obj["label_name"],
                  label_fk_id: obj["label_fk_id"]
                });
              }
              break;
            }
          }
        }
        this.list_data = result;
      }
    },
    /**
     * 点击地图右侧景、吃、住、购。。。 显示相应的mak点
     * @param type  ==user>     显示用户头像
     */
    select_audio_(audio) {
      this.is_select_audio = !this.is_select_audio;
      var that = this;
      this.is_audio_select = audio;
      this.map_add_Marker({
        map: this.map,
        l_index: this.l_index,
        r_index: this.r_index,
        l_lng: this.l_lng,
        l_lat: this.l_lat,
        is_map_setFitView: this.is_map_setFitView,
        is_audio_select: audio,
        list_data: this.spots_point_list,
        callback: function(Marker_arr) {
          that.Marker = Marker_arr;
        }
      });
    },

    click_tab_label(type) {
      type = type == 19 ? 2 : type;
      var bgMusic = document.getElementsByClassName("bgMusic")[0];
      if (bgMusic) {
        bgMusic.pause();
        bgMusic.currentTime = 0;
      }
      var result = [],
        that = this;
      this.map.clearMap();
      this.label_info = type;
      this.is_tab_button = true;
      this.is_map_info = false;
      if (type == 0) {
        //                    this.map_add_Marker(this.map, this.spots_point_list, '', (Marker_arr) => {
        //                        that.Marker = Marker_arr
        //                    })

        this.map_add_Marker({
          map: this.map,
          l_index: this.l_index,
          r_index: this.r_index,
          l_lng: this.l_lng,
          l_lat: this.l_lat,
          is_map_setFitView: this.is_map_setFitView,
          is_audio_select: this.is_audio_select,
          list_data: this.spots_point_list,
          callback: function(Marker_arr) {
            that.Marker = Marker_arr;
          }
        });
      } else if (type == "user") {
        //用户
        this.map_user_yes = !this.map_user_yes;
        this.$ajax(
          "post",
          "SpotsRace/get_huang_shi_user_data",
          {
            aid: this.aid,
            park_id: this.park_id
          },
          ret => {
            if (ret.data.id == 0) {
              this.spot_park_list = ret.data.data;
              //                            this.map_add_Marker(this.map, this.spot_park_list, type, function (Marker_arr) {
              //                                that.img_Marker = Marker_arr
              //                            })

              this.map_add_Marker({
                map: this.map,
                list_data: this.spot_park_list,
                type: type,
                callback: function(Marker_arr) {
                  that.img_Marker = Marker_arr;
                }
              });
            } else {
              Toast(ret.data.msg);
            }
          }
        );
      } else {
        //全部 为空    景 19     吃 18    住 21    购 20   足迹 3     主景点 2
        this.map_user_yes = false;
        for (var i = 0; i < this.spots_point_list.length; i++) {
          if (this.spots_point_list[i]["is_spots"] == type) {
            result.push(this.spots_point_list[i]);
          }
        }
        if (result.length <= 0) {
          Toast("暂无数据");
          return;
        }
        //                    this.map_add_Marker(this.map, result, type, function (Marker_arr) {
        //                        that.Marker = Marker_arr
        //                    })

        this.map_add_Marker({
          map: this.map,
          list_data: result,
          l_index: this.l_index,
          r_index: this.r_index,
          l_lng: this.l_lng,
          l_lat: this.l_lat,
          is_map_setFitView: this.is_map_setFitView,
          is_audio_select: this.is_audio_select,
          type: type,
          callback: function(Marker_arr) {
            that.Marker = Marker_arr;
          }
        });
      }
    },
    click_is_big() {
      this.is_root_books = this.is_park_search = false;
      this.is_big = !this.is_big;
      this.map.clearInfoWindow();
    }
  }
};
</script>
<!--左上角自动播放,语音切换,推荐路线-->
<style scoped>
  .ParkPhoto {
    width: 100%;
    min-height: 100%;
    background-color: #fff;
    /* margin-bottom: 60%; */
  }
  .ParkPhoto.map .button_list {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    max-width: 55rem;
  }

  .ParkPhoto.map .button_list .click_list {
    display: flex;
  }

  .ParkPhoto.map .button_list .click_list li {
    flex: 1;
    text-align: center;
    font-size: 0.7rem;
    height: 2rem;
    line-height: 2rem;
    box-shadow: -0.3rem -0.1rem 0.3rem rgb(234, 234, 234) inset;
    cursor: pointer;
  }

  .ParkPhoto.map .button_list .click_list li.active {
    background-color: #cdcdcd;
    box-shadow: 0rem 0.2rem 0.3rem #a9a9a9 inset;
  }

  .ParkPhoto.map .button_list .click_list li i {
    color: #ff9138;
    margin-right: 0.2rem;
  }

  .ParkPhoto.map .button_list .click_list li img {
    height: 0.8rem;
    vertical-align: text-top;
    margin-right: 0.2rem;
  }
  .ParkPhoto.map .button_list .click_list_ {
    display: flex;
  }

  .ParkPhoto.map .button_list .click_list_ li {
    flex: 1;
    text-align: center;
    font-size: 0.7rem;
    height: 2.5rem;

    /*box-shadow: -0.3rem -0.1rem 0.3rem rgb(234, 234, 234) inset;*/
    border-right: 1px solid #dedede;
    cursor: pointer;
    padding-top: 0.2rem;
  }

  .ParkPhoto.map .button_list .click_list_ li.active {
    background-color: #cdcdcd;
    box-shadow: 0rem 0.2rem 0.3rem #a9a9a9 inset;
  }

  .ParkPhoto.map .button_list .click_list_ li i {
    color: #ff9138;
    margin-right: 0.2rem;
  }

  .ParkPhoto.map .button_list .click_list_ li img {
    height: 1.5rem;
    vertical-align: text-top;
    margin-right: 0.2rem;
  }
  .ParkPhoto.map .button_list .content_list {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    left: 0;
    background-color: #fff;
    font-size: 0.7rem;
  }

  @media screen and (min-width: 1201px) {
    .ParkPhoto.map .button_list .content_list {
      width: 33.33%;
    }

    .ParkPhoto.map .button_list .content_list.root_book {
      margin-left: 33.33%;
    }
  }

  .ParkPhoto.map .button_list .content_list .park_search p {
    height: 2rem;
    line-height: 2rem;
    padding: 0 0.4rem;
    box-sizing: border-box;
  }

  .ParkPhoto.map .button_list .content_list .park_search > img {
    height: 1.6rem;
    position: absolute;
    right: 1rem;
    top: 0.4rem;
  }




  .container_box {
    height: 35rem;
  }
  .container_box .map_info {
    text-align: center;
    width: 90%;
    background-color: rgba(255, 154, 128, 0.6);
    border-radius: 4px;
    padding: 4px 6px;
    color: #fff;
    position: absolute;
    top: 10px;
    left: 2.5%;
    right: 2.5%;
    z-index: 0;
    font-size: 0.7rem;
  }

  .container_box .map_info li {
    width: 25%;
    border-right: 1px dashed #fff;
    box-sizing: border-box;
    float: left;
  }

  .container_box .map_info li:last-child {
    border: none;
  }
  .container_box .container_quanping {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 1.6rem;
    background-color: rgba(255, 154, 128, 0.6);
    display: inline-block;
    color: #fff;
    text-align: center;
    height: 1.6rem;
    line-height: 1.4rem;
    border-radius: 4px;
    font-size: 1rem;
  }

  .left_but {
    position: absolute;
    left: 0.5rem;
    bottom: 0.6rem;
  }

  .left_but .mak_tab {
    background-color: #fff;
    width: 2rem;
    box-sizing: border-box;
    box-shadow: 2px 2px 5px #ccc;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
  }

  .left_but .mak_tab:last-child {
    margin-bottom: 0;
  }

  .left_but .mak_tab img {
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    cursor: pointer;
  }

  .left_but .mak_tab img:nth-of-type(1) {
    border-bottom: 1px solid #ccc;
  }

  .left_but .mak_tab img:last-child,
  .left_but .mak_tab.map_my_location img {
    border: none;
  }






.auto_play {
  position: absolute;
  top: 2rem;
  left: 1px;
  bottom: 1px;
  z-index: 8;
  height: 64px;
}
.auto_play img {
  width: 54px;
  height: 64px;
}
.audio_select {
  position: absolute;
  top: 5.5rem;
  left: 1px;
  z-index: 8;
  height: 64px;
}
.audio_select img {
  width: 54px;
  height: 64px;
}

.root_books {
  position: absolute;
  top: 9rem;
  left: 1px;
  z-index: 8;
  height: 64px;
}
.root_books img {
  width: 54px;
  height: 64px;
}

.LineList_list {
  padding: 0;
}

.LineList_list li {
  color: #666;
  text-align: center;
}

.LineList_list li.jingdian,
.LineList_list li.zhuzhi,
.LineList_list li.gouwuche-copy,
.LineList_list li.chifan {
  background: none;
}

.LineList_list li img {
  width: 2rem;
  display: inline-block;
}

.LineList_list li p {
  margin: 0.2rem 0;
}
.ParkPhoto.map .amap-marker-content > div.user_bg {
  background: url(../../assets/img/user_touxoiang.png) 0 0 no-repeat;
  background-size: contain;
  width: 2.1rem;
  height: 2.2rem;
  padding: 0.15rem 0.1rem 0;
  box-sizing: border-box;
}

.ParkPhoto.map .container_box .map_info {
  width: 100%;
  position: inherit;
  background: none;
  padding: 0.5rem;
}

.hide {
  display: none;
}

.ParkPhoto .amap-touch-toolbar .amap-zoomcontrol {
  width: 2rem;
}

.amap-zoom-touch-minus,
.amap-zoom-touch-plus {
  height: 2rem;
}

.amap-zoom-touch-minus > div,
.amap-zoom-touch-plus > div {
  line-height: 2rem;
}

.amap-touch-toolbar .amap-zoomcontrol {
  left: 10px;
  bottom: 10px;
}

.amap-toolbar {
  bottom: 0 !important;
}

.ParkMapLineList-content {
  padding: 0.4rem 10px 0;
}

.click_list_ .menu-title {
  display: block;
  text-align: center;
}

/*右边漂浮的景点列表*/
.click-spots-list {
  display: inline-block;
  position: absolute;

  right: 1px;
  bottom: 3rem;
  z-index: 8;
}
.click-spots-list .csl_body {
  display: inline-block;
  background-color: white;
  border-radius: 3px;
}
.click-spots-list .csl_body .img_ {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
}
.click-spots-list .csl_body .img_ img {
  height: 100%;
  width: 100%;
}
.click-spots-list .csl_body .title {
  display: block;
  position: relative;
}
.click-spots-list .csl_body .title span {
  width: 25px;
  font-size: 0.5rem;
  text-align: center;
  display: block;
  font-weight: bold;
}
.head {
  display: inline-block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #d81e06;
}
.head .title {
  position: relative;
  width: 80%;
  float: left;
}
.head .title span {
  display: block;
  padding: 4px;
  text-align: left;
  font-size: 0.8rem;
  color: #d81e06;
}
.head .del {
  position: relative;
  width: 20%;
  float: left;
}
.head .del img {
  width: 30px;
  height: 30px;
  float: right;
}
.show-spots-list {
  position: absolute;
  right: 1px;
  bottom: 3rem;
  z-index: 8;
  width: 100%;
  background-color: white;
}
.width_ {
  width: 40%;
}
.spots_list_width {
  width: 80%;
  margin-left: 10%;
  right: 10%;
  bottom: 30%;
}
.show-spots-list .item {
  display: inline-block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #dedede;
}

.show-spots-list .item .item-title {
  position: relative;
  width: 80%;
  float: left;
}
.show-spots-list .item .item-title span {
  display: block;
  padding: 4px;
  text-align: left;
  font-size: 0.4rem;
  color: #01bba6;
}
.show-spots-list .item_ {
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: white;
}
.show-spots-list .item_ .item-del {
  position: relative;
  width: 20%;
  float: left;
}

.show-spots-list .item_ .item-del span {
  display: block;
  padding: 4px;
  text-align: left;
  font-size: 0.5rem;
  color: #d81e06;
  font-weight: bold;
}
.show-spots-list .item_ .img {
  position: relative;
  width: 75%;
  float: left;
}
.show-spots-list .item_ .img img {
  width: 10px;
  height: 10px;
  margin-top: 6px;
  float: right;
}

.show_list-enter-active {
  animation: bounce-in 0.5s;
}
.show_list-leave-active {
  animation: bounce-in 0.5s reverse;
}

/*游玩路线*/
.root_books-enter-active {
  animation: bounce-in 0.5s;
}
.root_books-leave-active {
  animation: bounce-in 0.5s reverse;
}

.audio_list-enter-active {
  animation: bounce-in 0.5s;
}
.audio_list-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
/*音频选择*/
.show-spots-list .audio_body {
  display: inline-block;
  position: relative;
  width: 100%;
}
.show-spots-list .audio_body .del {
  position: relative;
  float: left;
  width: 100%;
}
.show-spots-list .audio_body .del img {
  width: 35px;
  height: 35px;
  float: right;
}
.show-spots-list .audio_body .title {
  position: relative;
  float: left;
  width: 100%;
  border-bottom: 1px solid #d81e06;
}
.show-spots-list .audio_body .title span {
  display: block;
  padding: 4px;
  text-align: left;
  font-size: 0.8rem;
  color: #d81e06;
  font-weight: bold;
}
.show-spots-list .audio_body .man {
  position: relative;
  width: 100%;
  float: left;
}
.show-spots-list .audio_body .man .human {
  position: relative;
  float: left;
  width: 45%;
  margin-left: 5%;
}
.show-spots-list .audio_body .man .human .icon {
  display: block;
  position: relative;
  width: 70px;
  height: 70px;
}
.show-spots-list .audio_body .man .human .icon img {
  width: 100%;
  height: 100%;
  margin-left: 20%;
}
.show-spots-list .audio_body .man .human .nick {
  display: block;
  width: 72%;
}
.show-spots-list .audio_body .man .human .nick span {
  display: block;
  padding: 4px;
  text-align: center;
  font-size: 0.5rem;
  color: #d81e06;
}
.show-spots-list .audio_body .machine {
  display: inline-block;
  position: relative;
  width: 100%;
}
.show-spots-list .audio_body .machine .machine-item {
  position: relative;
  float: left;
  margin-left: 35%;
}
.show-spots-list .audio_body .machine .machine-item .icon {
  display: block;
  width: 100%;
}
.show-spots-list .audio_body .machine .machine-item .icon img {
  width: 70px;
  height: 70px;
}
.show-spots-list .audio_body .machine .machine-item .nick {
  display: block;
  width: 100%;
}
.show-spots-list .audio_body .machine .machine-item .nick span {
  display: block;
  padding: 4px;
  text-align: center;
  font-size: 0.5rem;
  color: #d81e06;
}



  #gd_map >>> .amap-maps .amap-marker-label {
    border: 1px solid #999;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
  }

  #gd_map >>> .amap-info > div {
    width: 85%;
    left: 6% !important;
  }
  #gd_map >>> .amap-info .center > div {
    background: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    line-height: 1.4;
    overflow: auto;
    box-shadow: 0 3px 14px rgba(0, 0, 100, 0.6);
    text-align: left;
    font-size: 0.7rem;
    min-width: 12.8rem;
    position: relative;
    border-radius: 5px;
  }
  #gd_map >>> .amap-info .center .titleD {
    border-bottom: 1px solid #f5f5f5;
  }
  #gd_map >>> .amap-info .center .closeX {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    color: #c3c3c3;
    text-decoration: none;
    font: 700 16px/14px Tahoma, Verdana, sans-serif;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }

  #gd_map >>> .amap-info .center .content_box {
    margin-top: 0.4rem;
  }

  #gd_map >>> .amap-info .center .content_box .contentimg {
    float: left;
    width: 3rem;
  }

  #gd_map >>> .amap-info .center .content_box .contentinfo {
    overflow: hidden;
    padding: 0 0.2rem;
    max-height: 3rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  #gd_map >>> .amap-info .center .bottom_box,
  #gd_map >>> .amap-info .center .btmtip {
    display: flex;
  }
  #gd_map >>> .amap-info .center .btmtip.jingdian {
    background-image: linear-gradient(10turn, #439dff, #95b5ff);
  }
  #gd_map >>> .amap-info .center .btmtip {
    margin-right: 5px;
    min-width: 3rem;
    max-width: 7rem;
    text-align: center;
    height: 1.4rem;
    line-height: 1.4rem;
    box-sizing: border-box;
    cursor: pointer;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  #gd_map >>> .amap-info .center .btmtip.luxian {
    background-image: linear-gradient(10turn, #ec1447, #ff6767);
  }
  #gd_map >>> .amap-info .center .btmtip.yuding {
    background-image: linear-gradient(10turn, #ff9d42, #ffbf83);
  }
  #gd_map >>> .amap-info .center .sharp {
    height: 23px;
    margin: 0 auto;
    overflow: hidden;
    top: -1px;
    width: 30px;
    border-radius: 0;
    position: relative;
    display: block;
  }
  #gd_map >>> .amap-info .center .title_,
  #gd_map >>> .amap-info .center .titleD {
    padding-bottom: 0.4rem;
    box-sizing: border-box;
    font-size: 0.9rem;
  }
  #gd_map >>> .amap-info .center .titleD {
    border-bottom: 1px solid #f5f5f5;
  }
  #gd_map >>> .center .btmtip {
    font-size: 0.6rem;
    padding: 2px 5px;
    background-color: #ff9a80;
    color: #fff;
    display: inline-block;
    margin-top: 10px;
    border-radius: 2px;
  }
</style>