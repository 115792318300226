<template>
  <div class="red_packet_container">
    <!-- 红包雨 -->
    <div
      class="red_packet"
      v-show="showRedPacket"
      v-if="red_packet_type_ == 0 || red_packet_type_ == 1"
    >
      <img
        class="hb-item"
        :class="getClass()"
        v-for="(item,i) in redPacketList"
        :src="item.url"
        :key="i"
        :style="redpacketStyle[i]"
        @click="pathToLottery(item.id, item.id_, item.id_type_, item.back_)"
      />
    </div>
    <!-- 侧边栏红包按钮 -->
    <div
      class="red_packet_btn"
      :style="btn_style()"
      @click="pathToLottery(redPacketList[0].id, redPacketList[0].id_, redPacketList[0].id_type_, redPacketList[0].back_)"
    ></div>

    <!-- 弹框红包 -->
    <div
      class="red_packet_bounced"
      v-show="show_bounced"
      v-if="red_packet_type_ == 0 || red_packet_type_ == 2"
    >
      <div class="bounced_mask"></div>
      <div
        class="bounced_content"
        @click="pathToLottery(redPacketList[0].id, redPacketList[0].id_, redPacketList[0].id_type_, redPacketList[0].back_)"
      >
        <div class="close" @click.stop="show_bounced = false">
          <i class="iconfont iconbaseline-close-px"></i>
        </div>
        <div class="seal"></div>
        <div class="symbol">￥</div>
        <div class="btn">拆</div>
      </div>
    </div>

    <!-- 底部红包 -->
    <div
      class="red_packet_banner"
      v-if="red_packet_image_url"
      @click="pathToLottery(redPacketList[0].id, redPacketList[0].id_, redPacketList[0].id_type_, redPacketList[0].back_)"
    >
      <img :src="red_packet_image_url" alt />
      <!-- <img :src="red_packet_image_url || 'https://p.zmlxj.com/red_packet_image_url.jpg'" alt /> -->
    </div>

    <!-- 提示发布足迹 -->
    <div class="show-publish-tip" v-show="showPublishTip">
      <div class="show-publish-box">
        <div class="show-publish-img">
          <img src="../assets/switch/footprint_send.png" alt="">
        </div>
        <div class="show-publish-content">
          <span class="text">发布足迹可获取抽奖机会</span>
        </div>
        <div class="show-publish-btn">
          <button class="btn" @click="showPublishTip = false">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 import { MessageBox } from 'mint-ui'
export default {
  props: {
    redPacketList: {
      type: Array,
      default () {
        return new Array(0)
      }
    },
    red_packet_type_: {
      type: String
    },
    red_packet_image_url: {
      type: String
    },
    id_type_: {
      type: String
    },
    is_admin: {
      type: [Number, String]
    },
    have_chance: {
      type: [Number, String]
    }
  },
  data () {
    return {
      /*  */
      show_bounced: true,
      redPacketCount: 10,
      minSize: 40,
      maxSize: 80,
      redpacketStyle: [],
      /* 显示红包 */
      showRedPacket: true,

      // 显示发布提示
      showPublishTip: false
    }
  },
  mounted () {
    // 初始化红包雨样式
    this.getStyle()
    setTimeout(() => {
      this.showRedPacket = false
    }, 42000)
  },
  methods: {
    btn_style () {
      if (this.id_type_ === 'spots') {
        return {
          right: '0.5rem',
          bottom: '6rem'
        }
      } else if (this.id_type_ === 'books') {
        if (this.is_admin === 1 || this.is_admin === '1') {
          return {
            right: '0.6rem',
            bottom: '8.4rem'
          }
        } else {
          return {
            right: '0.6rem',
            bottom: '6.4rem'
          }
        }
      } else if (this.id_type_ === 'photo' || this.id_type_ === 'vote') {
        if (this.is_admin === 1 || this.is_admin === '1') {
          return {
            right: '0.6rem',
            bottom: '4.2rem'
          }
        } else {
          return {
            right: '0.6rem',
            bottom: '2.6rem'
          }
        }
      }
    },
    // 红包跳转转盘
    pathToLottery (id, id_, type, back_) {
      if (type === 'leafletMapIndex' || type === 'leafletMapIndexMini') {
        // 足迹营销页面单独设置逻辑
        // console.log('have_chance = ' + this.have_chance)
        if (!this.have_chance || this.have_chance === '0') {
          this.showPublishTip = true
          // MessageBox.alert('发布足迹可获取抽奖机会')
          return false
        }
      }
      if(this.red_packet_type_ != 3)
      {
          this.$router.push({
              path: '/lottery/lottery',
              query: {
                  id: id,
                  id_: id_,
                  id_type_: type,
                  back_: back_
              }
          })
      }
      else
      {
          //表示分享邀请好友，即可获取红包，不用跳转
          MessageBox("分享给好友，就可领取红包！");
          return false;
      }
    },

    // 随机红包类名
    getClass () {
      return `hbsd-${Math.floor(Math.random() * 100 + 30)}`
    },
    // 随机红包样式
    getStyle () {
      for (let i = 0; i < this.redPacketCount; i++) {
        let left, top
        if (i < 3) {
          left = i * 27 + Math.random() * 27
          top = Math.random() * 30
        } else if (i >= 3 && i < 6) {
          left = (i - 3) * 27 + Math.random() * 27
          top = 30 + Math.random() * 30
        } else if (i >= 6 && i < 9) {
          left = (i - 6) * 27 + Math.random() * 27
          top = 60 + Math.random() * 30
        } else {
          left = Math.random() * 90
          top = Math.random() * 90
        }
        this.redpacketStyle.push({
          position: `absolute`,
          width: `${Math.random() * (this.maxSize - this.minSize) +
            this.minSize}px`,
          left: left + '%',
          top: top + '%',
          // left: `${Math.random() * (i+1) * 8}%`,
          // top: `${Math.random() * (i+1) * 9}%`,
          'border-radius': '4px',
          transform: `rotate3d(${Math.random()},${Math.random()},${Math.random()},${Math.random() *
            360}deg)`
        })
      }
    }
  }
}
</script>

<style scoped>
.red_packet {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: -100vh;
  z-index: 103;
  /* perspective: 500; */
}
.red_packet_btn {
  position: fixed;
  width: 1.6rem;
  height: 1.6rem;
  background: url(../assets/switch/redpacketbtn.png) no-repeat;
  background-size: contain;
  right: 0.5rem;
  bottom: 6rem;
  z-index: 3;
  animation: red_btn 1s linear infinite alternate-reverse;
}

@keyframes red_btn {
  0% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

.red_packet .hb-item {
  position: absolute;
  cursor: pointer;
  /* animation: hb 10s linear 2 both; */
}
.hb-item:nth-child(1) {
  animation: hb1 20s linear 2 both;
}
.hb-item:nth-child(2) {
  animation: hb2 20s linear 2 both;
}
.hb-item:nth-child(3) {
  animation: hb3 20s linear 2 both;
}
.hb-item:nth-child(4) {
  animation: hb4 20s linear 2 both;
}
.hb-item:nth-child(5) {
  animation: hb5 20s linear 2 both;
}
.hb-item:nth-child(6) {
  animation: hb6 20s linear 2 both;
}
.hb-item:nth-child(7) {
  animation: hb7 20s linear 2 both;
}
.hb-item:nth-child(8) {
  animation: hb8 20s linear 2 both;
}
.hb-item:nth-child(9) {
  animation: hb9 20s linear 2 both;
}
.hb-item:nth-child(10) {
  animation: hb10 20s linear 2 both;
}
@keyframes hb1 {
  0% {
    transform: translateY(0) rotate3d(0, 0, 0, 0);
  }
  100% {
    transform: translateY(200vh) rotate3d(1, 1, 1, 360deg);
  }
}
@keyframes hb2 {
  0% {
    transform: translateY(0) rotate3d(1, 1, 1, 360deg);
  }
  100% {
    transform: translateY(200vh) rotate3d(0, 0, 0, -180deg);
  }
}
@keyframes hb3 {
  0% {
    transform: translateY(0) rotate3d(0.3, 0.4, 0.3, -225deg);
  }
  100% {
    transform: translateY(200vh) rotate3d(0.8, 0, 1, 270deg);
  }
}
@keyframes hb4 {
  0% {
    transform: translateY(0) rotate3d(0, 0, 0.3, -45deg);
  }
  100% {
    transform: translateY(200vh) rotate3d(0.8, 1, 1, 360deg);
  }
}
@keyframes hb5 {
  0% {
    transform: translateY(0) rotate3d(0.8, 1, 0.6, 345deg);
  }
  100% {
    transform: translateY(200vh) rotate3d(0.2, 0, 0.2, -90deg);
  }
}
@keyframes hb6 {
  0% {
    transform: translateY(0) rotate3d(0, 0, 1, -145deg);
  }
  100% {
    transform: translateY(200vh) rotate3d(0.8, 1, 0, 275deg);
  }
}
@keyframes hb7 {
  0% {
    transform: translateY(0) rotate3d(0, 0.4, 1, 275deg);
  }
  100% {
    transform: translateY(200vh) rotate3d(0.8, 1, 0, -300deg);
  }
}
@keyframes hb8 {
  0% {
    transform: translateY(0) rotate3d(0.5, 0.4, 0.3, 300deg);
  }
  100% {
    transform: translateY(200vh) rotate3d(0.8, 0.8, 1, -245deg);
  }
}
@keyframes hb9 {
  0% {
    transform: translateY(0) rotate3d(0.6, 0.5, 0.9, -150deg);
  }
  100% {
    transform: translateY(200vh) rotate3d(0.8, 0, 0.2, 345deg);
  }
}
@keyframes hb10 {
  0% {
    transform: translateY(0) rotate3d(0.1, 0.9, 0.3, 345deg);
  }
  100% {
    transform: translateY(200vh) rotate3d(0.8, 0, 1, -320deg);
  }
}

/* 弹框红包 */
.red_packet_bounced {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  display: flex;
  justify-content: center;
  align-items: center;
}
.red_packet_bounced .bounced_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
}

  .red_packet_bounced .bounced_content {
    position: relative;
    width: 36vh;
    height: 50vh;
    background-color: rgb(216, 30, 6);
    border-radius: 0.6rem;
    z-index: 1;
    animation: bounced 1s ease 1;
  }

@keyframes bounced {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.red_packet_bounced .bounced_content .close {
  position: absolute;
  right: -2rem;
  top: -2rem;
  cursor: pointer;
}
.red_packet_bounced .bounced_content .close i {
  font-size: 1.4rem;
  color: #ddd;
}
.red_packet_bounced .bounced_content .seal {
  position: relative;
  width: 100%;
  height: 30%;
  border-radius: 0.6rem 0.6rem 50% 50%;
  background-color: rgb(202, 28, 5);
}
.red_packet_bounced .bounced_content .seal::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.6rem 0.6rem 50% 50%;
  background-color: gold;
  z-index: -1;
}
.red_packet_bounced .bounced_content .symbol {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 8rem;
  color: gold;
}
.red_packet_bounced .bounced_content .btn {
  position: absolute;
  left: calc(50% - 2rem);
  top: 20%;
  /* transform: translateX(-50%); */
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 50%;
  background-color: rgb(238, 179, 51);
  color: #e33;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  animation: open 0.5s linear infinite alternate;
}
@keyframes open {
  to {
    transform: scale(1.1);
  }
}
.red_packet_banner {
  width: 100%;
  height: 100px;
  margin-bottom: 1rem;
  display: flex;
}
.red_packet_banner img {
  width: 100%;
}

/* 提示发布足迹 */
.show-publish-tip {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.show-publish-box {
  padding: 15px 0 0;
  width: 14rem;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
}

.show-publish-img {
  text-align: center;
}

.show-publish-img > img {
  width: 40px;
  height: 40px;
}

.show-publish-content {
  padding: 10px 20px 15px;
  border-bottom: 1px solid #ddd;
  min-height: 36px;
  position: relative;
  margin: 0;
  text-align: center;
  line-height: 36px;
  color: #333;
  font-size: 0.8rem;
}

.show-publish-btn > .btn{
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 0.78rem;
  background-color: #fc5227;
  color: #fff;
}
</style>
