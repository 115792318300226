
<template>
  <div class="ParkMapLineList ParkPhoto map" v-if="info" v-title :data-title="title_name+'详情'">
    <div class="container_box" :class="{active:is_big}">
      <div id="container" style="width:100%; height:100%"></div>
      <div
        class="active_user_box ParkPhotoListDetail"
        :class="{active:have_title != 0}"
        v-if="aid != 1"
      >
        <div class="who_vote">
          <div class="row" v-if="have_title==0 && !is_map_info">
            <div class="list">
              <router-link
                :to="{name:'otherIndex',params:{uid:k.user_id}}"
                class="num_box"
                v-for="(k,i) in user_list"
                :key="i"
              >
                <img :src="k.user_icon" />
                <div class="num">
                  <i class="iconfont icon-zuji"></i>
                  {{k.spots_numbers}}
                </div>
                <span v-if="i<3">NO.{{i + 1}}</span>
              </router-link>
            </div>
          </div>
          <h2 v-if="have_title==1">{{title_content}}</h2>
          <ul class="map_info clearfix" v-if="is_map_info">
            <li>
              <p>总里程</p>
              <p>{{spots_point.length}}</p>
            </li>
            <li>
              <p>最高海拔</p>
              <p>{{spots_point.height}}</p>
            </li>
            <li>
              <p>最低海拔</p>
              <p>{{spots_point.low}}</p>
            </li>
            <li>
              <p>徒步时间</p>
              <p>{{spots_point.time_length}}</p>
            </li>
          </ul>
        </div>
        <!--点击游客-->
        <div class="fr" @click="click_tab_label('user')">
          <img src="../../assets/img/map_park_user_yes.png" alt v-if="map_user_yes" />
          <img src="../../assets/img/map_park_user.png" alt v-else />
        </div>
      </div>
      <!--类型切换-->
      <div class="tab_label clearfix" v-if="is_big">
        <ul>
          <li @click="click_tab_label(3)" v-if="have_foot_3">
            <!--足迹-->
            <img src="../../assets/img/map_park_all_yes.png" alt v-if="label_info == 3" />
            <img src="../../assets/img/map_park_all.png" alt v-else />
          </li>
          <li @click="click_tab_label(11)" v-if="have_geo_11">
            <!--地质-->
            <img src="../../assets/img/map_geo_selected.png" alt v-if="label_info == 11" />
            <img src="../../assets/img/map_geo_unselected.png" alt v-else />
          </li>
          <li @click="click_tab_label(12)" v-if="have_people_12">
            <!--人文-->
            <img src="../../assets/img/map_people_selected.png" alt v-if="label_info == 12" />
            <img src="../../assets/img/map_people_unselected.png" alt v-else />
          </li>
          <li @click="click_tab_label(13)" v-if="have_left_13">
            <!--生物-->
            <img src="../../assets/img/map_life_selected.png" alt v-if="label_info == 13" />
            <img src="../../assets/img/map_life_unselected.png" alt v-else />
          </li>
          <li @click="click_tab_label(14)" v-if="have_plant_14">
            <!--植物-->
            <img src="../../assets/img/map_plant_selected.png" alt v-if="label_info == 14" />
            <img src="../../assets/img/map_plant_unselected.png" alt v-else />
          </li>
          <li @click="click_tab_label(15)" v-if="have_stop_15">
            <!--停车场-->
            <img src="../../assets/img/selected_stop.png" alt v-if="label_info == 15" />
            <img src="../../assets/img/unselected_stop.png" alt v-else />
          </li>
          <li @click="click_tab_label(16)" v-if="have_ticket_16">
            <!--售票厅-->
            <img src="../../assets/img/selected_ticket.png" alt v-if="label_info == 16" />
            <img src="../../assets/img/unselected_ticket.png" alt v-else />
          </li>
          <li @click="click_tab_label(17)" v-if="have_wc_17">
            <img src="../../assets/img/map_park_lavatory_yes.png" alt v-if="label_info == 17" />
            <img src="../../assets/img/map_park_lavatory.png" alt v-else />
          </li>
          <li @click="click_tab_label(18)" v-if="have_food_18">
            <img src="../../assets/img/map_park_eat_yes.png" alt v-if="label_info == 18" />
            <img src="../../assets/img/map_park_eat.png" alt v-else />
          </li>
          <li @click="click_tab_label(2)" v-if="have_spots_19">
            <!--原来是19表示景点-->
            <img src="../../assets/img/map_park_scene_yes.png" alt v-if="label_info == 2" />
            <img src="../../assets/img/map_park_scene.png" alt v-else />
          </li>
          <li @click="click_tab_label(20)" v-if="have_shop_20">
            <img src="../../assets/img/map_park_buy_yes.png" alt v-if="label_info == 20" />
            <img src="../../assets/img/map_park_buy.png" alt v-else />
          </li>
          <li @click="click_tab_label(21)" v-if="have_sleep_21">
            <img src="../../assets/img/map_park_cease_yes.png" alt v-if="label_info == 21" />
            <img src="../../assets/img/map_park_cease.png" alt v-else />
          </li>

          <!--<li @click="click_tab_label('conceal')">-->
          <!--<img src="../../assets/img/map_park_conceal.png" alt=""></li>-->
        </ul>
      </div>
      <div class="left_but" v-if="is_big">
        <div class="mak_tab">
          <!--点/图片 切换-->
          <img
            src="../../assets/img/map_park_tab_img.png"
            alt
            @click="tab_button"
            v-if="is_tab_button  && is_show_tab_img "
          />
          <img
            src="../../assets/img/map_park_tab_img_maks.png"
            alt
            @click="tab_button"
            v-if="!is_tab_button"
          />
          <img
            src="../../assets/img/map_3d.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="is_tab_map_3d"
          />
          <img
            src="../../assets/img/map_3d_yes.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="!is_tab_map_3d"
          />
        </div>
        <div class="mak_tab">
          <!--地图 + -  -->
          <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
          <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
        </div>
        <div class="mak_tab map_my_location">
          <!--地图定位- -->
          <img src="../../assets/img/map_my_location.png" alt @click="click_Geo_location()" />
        </div>
      </div>
      <div class="button_list">
        <ul class="click_list">
          <li @click="click_list('park')" :class="{active:is_park_search}">
            <i class="iconfont icon-senlingongyuan-copy"></i>景点列表
          </li>
          <li :class="{active:is_root_books}" @click="call_tel_" v-if="tel_ != '' ">
            <img src="../../assets/img/call.png" alt />
            一键求救
          </li>
          <li :class="{active:is_root_books}" @click="go_other_url" v-else-if="aid == 1 ">
            <img src="../../assets/img/line.png" alt />购票
          </li>
          <li :class="{active:is_root_books}" @click="click_list('root')" v-else-if="is_root_books">
            <img src="../../assets/img/line.png" alt />好玩路线 | 路书
          </li>

          <li v-if="have_title==0 && is_big" @click="click_is_big()">
            <i class="iconfont icon-canyin2"></i>首页
          </li>
          <li v-if="have_title==0 && !is_big" @click="click_is_big()">
            <i class="iconfont icon-canyin2"></i>进入地图
          </li>
        </ul>
        <div
          class="content_list"
          :class="{root_book:is_root_books}"
          v-if="is_park_search || is_root_books"
        >
          <div class="park_search">
            <p v-if="is_park_search">共{{list_data.length}}个景点</p>
            <p v-if="is_root_books">共{{list_data.length}}个路书</p>
            <img
              @click="is_root_books = is_park_search = false"
              src="../../assets/img/footprints_delete.png"
              alt
            />
            <div class="input_search clearfix">
              <input class="fl" type="text" placeholder="请输入关键字" v-model="value_parkName" />
              <button class="fl" @click="searchData(value_parkName,is_park_search)">确定</button>
            </div>
            <ul>
              <li
                @click="list_path(k.id,k.label_fk_id)"
                :id="k.label_fk_id || k.id"
                v-for="k in list_data"
                :key="k.id"
              >{{k.spots_name || k.label_name}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ul class="LineList_list clearfix" v-if="aid > 1">
      <router-link
        tag="li"
        :to="{path:'/parkPhoto/ParkLineFoot',query:{aid:aid,park_id:park_id,type:19}}"
        class="jingdian"
      >
        <img src="../../assets/img/park_button_scene.png" alt />
        <p>景点</p>
      </router-link>
      <router-link
        tag="li"
        :to="{path:'/parkPhoto/ParkLineFoot',query:{aid:aid,park_id:park_id,type:18}}"
        class="chifan"
      >
        <img src="../../assets/img/park_button_eat.png" alt />
        <p>美食</p>
      </router-link>
      <router-link
        tag="li"
        :to="{path:'/parkPhoto/ParkLineFoot',query:{aid:aid,park_id:park_id,type:21}}"
        class="zhuzhi"
      >
        <img src="../../assets/img/park_button_ceas.png" alt />
        <p>住宿</p>
      </router-link>
      <router-link
        tag="li"
        :to="{path:'/parkPhoto/ParkLineFoot',query:{aid:aid,park_id:park_id,type:20}}"
        class="gouwuche-copy"
      >
        <img src="../../assets/img/park_button_buy.png" alt />
        <p>购物</p>
      </router-link>
      <router-link
        tag="li"
        :to="{path:'/note/NoteIndex',query:{pid:park_id,type:'parkNote'}}"
        class="youji"
      >
        <img src="../../assets/img/park_button_note.png" alt />
        <p>游记</p>
      </router-link>
      <router-link
        tag="li"
        :to="{path:'/footprints/FootprintsIndex',query:{pid:park_id,type:'parkFoot',lng:lng,lat:lat}}"
        class="zuji"
      >
        <img src="../../assets/img/park_button_foot.png" alt />
        <p>足迹</p>
      </router-link>
      <router-link tag="li" :to="{path:'/Guide',query:{pid:park_id}}" class="huodong">
        <img src="../../assets/img/park_button_guide.png" alt />
        <p>活动</p>
      </router-link>
      <router-link
        tag="li"
        :to="{path:'/parkPhoto/ParkLineFoot',query:{aid:aid,park_id:park_id,type:17}}"
        class="cesuo"
      >
        <img src="../../assets/img/park_button_cesuo.png" alt />
        <p>厕所</p>
      </router-link>
    </ul>
    <!--路书列表  组件-->
    <div v-if="info.root_books_list">
      <line-info
        ref="child"
        :length="2"
        :is_border="true"
        is_tit
        :datainfo="info.root_books_list"
        :user_id="user_id"
      ></line-info>
    </div>
    <!--足迹列表-->
    <footprints-list ref="child" @ievent="get_foot_list"></footprints-list>
  </div>
</template>
<script>
import { Toast } from 'mint-ui'
import LineInfo from '../../components/LineInfo'
import FootprintsList from '../../components/FootprintsList'
export default {
  components: {
    LineInfo,
    FootprintsList
  },
  data () {
    return {
      map: {},
      my_id: '',
      user_id: '',
      aid: this.$route.query.aid,
      park_id: this.$route.query.park_id,
      info: {},
      isred: false,
      user_list: [], // 活跃用户
      list_data: [], // 搜索框列表显示数据
      spots_list: [],
      spots_point: {}, // 路书线路数据（里程、时间、海拔）
      Marker: [], // 所有点
      img_Marker: [], // 游客点
      page: 1,
      num: 2,
      label_info: this.$route.query.select_type
        ? this.$route.query.select_type
        : 19, // 点击景点、美食。。请求点数据    记录当前点击的tab
      is_big: true, // 地图放大缩小
      is_tab_button: true, // 图片切换是否点击
      map_user_yes: false, // 是否点击游客按钮
      is_tab_map_3d: true, // 3D地图按钮是否点击
      is_park_search: false, // 是否显示下拉列表公园数据
      is_root_books: false, // 是否显示下拉列表路书线路数据
      is_map_info: false, // 是否显示路书线路数据（里程、时间、海拔）
      value_parkName: '', // 下拉列表搜索框val
      lng: this.$route.query.lng, // 经度
      lat: this.$route.query.lat, // 纬度
      have_title: '', // =0 > 显示活跃用户 首页按钮     =1 > 显示title  路书线路数据（里程、时间、海拔）
      title_content: '', // have_title = 1 展示的title
      url: '',
      select_type: this.$route.query.select_type
        ? this.$route.query.select_type
        : 19,
      title_name: this.$route.query.park_name
        ? this.$route.query.park_name
        : '',
      park_logo: this.$route.query.park_logo, // 给微信分享的图片
      park_name: this.$route.query.park_name ? this.$route.query.park_name : '', // 给微信分享的文字描述
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      wx_url:
        'http://www.zmlxj.com/api.php/weixin/wx_share?type=ParkMapLineList&park_id=' +
        this.$route.query.park_id +
        '&park_name=' +
        this.$route.query.park_name +
        '&park_logo=' +
        this.$route.query.park_logo,
      have_main_spots_2: 0, // 主景点
      have_foot_3: 0, // 足迹
      have_geo_11: 0, // 表示地质
      have_people_12: 0, // 表示人文 默认为0->表示没有 1->表示有
      have_left_13: 0, // 生物
      have_plant_14: 0, // 植物
      have_stop_15: 0, // 停车场
      have_ticket_16: 0, // 售票厅
      have_wc_17: 0, // 厕所
      have_food_18: 0, // 美食
      have_spots_19: 0, // 景点（非主景点-没有音频，富文本内容）
      have_shop_20: 0, // 购物
      have_sleep_21: 0, // 住宿
      other_url: '',
      logo: '',
      l_index: 0,
      r_index: 0,
      r_index_: 0,
      l_lng: 0,
      l_lat: 0,
      tel_: '',
      is_show_tab_img: false
    }
  },
  mounted () {
    this.unload()
    if (this.is_weixin) {
      // this.wx_share(this.wx_url)
      this.dealWithUrl(window.location.href)
    }
    document.title = this.title_name

    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    if (this.park_id == 796) {
      link.href =
        'https://www.zmlxj.com/Public/map/nan_jing_qi_xia_shan_logo.jpg'
    } else {
      link.href = 'https://m.zmlxj.com/favicon.ico'
    }

    document.getElementsByTagName('head')[0].appendChild(link)
  },
  methods: {
    unload () {
      if (this.check_login()) {
        this.my_id = this.check_login().user_id
      }

      this.get_data()
    },

    //            获取数据  渲染地图上的mak点
    get_data () {
      var that = this
      localStorage.setItem('wx_title', this.park_name)
      localStorage.setItem('wx_content', this.park_name + '地图详情')
      localStorage.setItem('wx_img', this.park_logo)
      this.url = 'SpotsRace/get_weixin_park_data'
      this.$ajax(
        'post',
        this.url,
        {
          park_id: this.park_id,
          aid: this.aid,
          page: 1,
          where: 'web'
        },
        ret => {
          if (ret.data.id == 0) {
            this.info = ret.data.data
            // this.info.center_lng = "0";
            // this.info.center_lat = "0";
            document.title = this.info.title
            // 微信分享
            if (this.is_weixin) {
              const wxTitle = this.info.title || ''
              const wxContent = this.info.map_data.content || ''
              const wxImg = this.info.logo || ''
              const sUrl = window.location.href
              this.wx_share_callback(sUrl, {
                url: sUrl,
                title: wxTitle,
                content: wxContent,
                img: wxImg
              })
            }

            this.logo = ret.data.data.logo
            this.other_url = ret.data.data.map_data.content
            this.map_data = ret.data.data.map_data

            this.map = this.map_init(
              this.info.map_data,
              this.info.center_lng,
              this.info.center_lat
            )
            this.user_list = ret.data.data.user_list
            this.have_title = ret.data.data.have_title
            this.title_content = ret.data.data.title
            this.spots_point_list = ret.data.data.spots_point_list
            this.main_spots_list = ret.data.data.main_spots_list
            this.l_index = ret.data.data.l_index
            this.r_index = ret.data.data.r_index
            this.r_index_ = ret.data.data.r_index
            this.l_lng = ret.data.data.l_lng
            this.l_lat = ret.data.data.l_lat
            // this.l_lng = "0";
            // this.l_lat = "0";
            this.tel_ = ret.data.data.tel_
            this.is_show_tab_img = ret.data.data.is_show_tab_img
            let that = this
            if (ret.data.data.spots_point_list.length > 0) {
              this.map_add_Marker({
                map: this.map,
                l_index: this.l_index,
                r_index: this.r_index,
                l_lng: this.l_lng,
                l_lat: this.l_lat,
                lng_: this.map_data.add_lng,
                lat_: this.map_data.add_lat,
                list_data: ret.data.data.spots_point_list,
                callback: function (markerArr) {
                  that.Marker = markerArr
                }
              })
              if (this.select_type) {
                if (this.select_type == 19) {
                  this.select_type = 2
                }
                // 说明是从投票页面进来的,需要调用一些下面的这个方法
                this.click_tab_label(this.select_type)
              }
            } else {
              Toast('暂无数据')
            }
            this.have_main_spots_2 = ret.data.data.have_icon_list.have_geo_11 // 主景点
            this.have_foot_3 = ret.data.data.have_icon_list.have_foot_3 // 足迹
            this.have_geo_11 = ret.data.data.have_icon_list.have_geo_11 // 表示地质
            this.have_people_12 = ret.data.data.have_icon_list.have_people_12 // 表示人文 默认为0->表示没有 1->表示有
            this.have_left_13 = ret.data.data.have_icon_list.have_left_13 // 生物
            this.have_plant_14 = ret.data.data.have_icon_list.have_plant_14 // 植物
            this.have_stop_15 = ret.data.data.have_icon_list.have_stop_15 // 停车场
            this.have_ticket_16 = ret.data.data.have_icon_list.have_ticket_16 // 售票厅
            this.have_wc_17 = ret.data.data.have_icon_list.have_wc_17 // 厕所
            this.have_food_18 = ret.data.data.have_icon_list.have_food_18 // 美食
            this.have_spots_19 = ret.data.data.have_icon_list.have_spots_19 // 景点（非主景点-没有音频，富文本内容）
            this.have_shop_20 = ret.data.data.have_icon_list.have_shop_20 // 购物
            this.have_sleep_21 = ret.data.data.have_icon_list.have_sleep_21 // 住宿
            this.$refs.child.parentMsg({
              info: this.info.spots_list,
              isred: false,
              my_id: this.my_id,
              tit: false,
              tit2: true,
              parklink: true
            })
          }
        }
      )
    },
    /* 拨打电话 */
    call_tel_ () {
      window.location.href = 'tel://' + this.tel_
    },
    go_other_url () {
      window.location.href = this.other_url
    },
    //            传递数据到子组件 下拉加载更多   page=>页数
    get_foot_list (page) {
      this.$ajax(
        'post',
        this.url,
        {
          park_id: this.park_id,
          aid: this.aid,
          page: page
        },
        ret => {
          if (ret.data.id == 0) {
            this.spots_list = this.spots_list.concat(ret.data.data.spots_list)

            this.$refs.child.parentMsg({
              info: this.spots_list,
              isred: false,
              my_id: this.my_id,
              tit: false,
              tit2: true,
              parklink: true
            })
          }
        }
      )
    },
    // 点击地图定位我的位置
    click_Geo_location () {
      var that = this
      that.map_Geo_location({
        map: this.map,
        callback: function (lng, lat) {
          that.lng = lng
          that.lat = lat
        }
      })
    },
    //            点击地图按钮切换图片/mak点
    tab_button () {
      if (!this.map_user_yes) {
        this.is_tab_button = !this.is_tab_button
        this.map_cut_Marker(this.map, this.Marker, this.is_tab_button)
      }
    },
    /**
     *点击底部景点、路书显示数据列表
     *  @param type     ==park >显示公园景点数据
     *                  ==root >显示路书数据
     */
    click_list (type) {
      if (!this.is_big) {
        Toast('请进入地图查看')
        return
      }
      if (type == 'park') {
        if (this.is_root_books) {
          this.is_root_books = false
          this.is_park_search = true
        } else {
          this.is_park_search = !this.is_park_search
        }
        if (this.main_spots_list.length > 0) {
          this.list_data = this.main_spots_list
        } else {
          Toast('无景点')
          this.is_park_search = false
        }
      } else if (type == 'root') {
        if (this.is_park_search) {
          this.is_park_search = false
          this.is_root_books = true
        } else {
          this.is_root_books = !this.is_root_books
        }
        if (this.info.root_books_list.length > 0) {
          this.list_data = this.info.root_books_list
        } else {
          Toast('无路线 | 路书')
          this.is_root_books = false
        }
      }
    },
    /**
     * 点击路书或者景点列表操作地图
     * @param id    地图显示景点mak 并弹出信息窗体
     * @param label_fk_id   地图显示路书线路
     */
    list_path (id, label_fk_id) {
      var bgMusic = document.getElementsByClassName('bgMusic')[0]
      if (bgMusic) {
        bgMusic.pause()
        bgMusic.currentTime = 0
      }
      this.is_map_info = false
      this.is_root_books = this.is_park_search = false
      var that = this
      if (id) {
        this.spots_point_list.forEach(function (item) {
          if (item.id == id) {
            //

            that.map_add_Marker({
              map: that.map,
              list_data: [item],
              callback: function (Marker_arr) {
                that.Marker = Marker_arr
              }
            })
            that.map_newInfoWindow(that.map, item, 'primary')
          }
        })
      } else if (label_fk_id) {
        this.$ajax(
          'post',
          'SpotsRace/get_root_book_point',
          { label_id: label_fk_id },
          ret => {
            if (ret.data.id == 0) {
              that.spots_point = ret.data.data
              that.is_map_info = true
              that.map_root_book_point(
                this.map,
                ret.data.data.spots_point_list,
                data => {
                  that.jump(data, label_fk_id)
                }
              )
            } else {
              Toast(ret.data.msg)
            }
          }
        )
      }
    },
    /**
     * 点击底部列表里面的搜索确定 找出数组数据返回显示
     * @param parkName  关键字
     * @param is_park_search    ==true  显示景点列表数据
     *                          ==false 显示路书列表数据
     */
    searchData (parkName, is_park_search) {
      if (is_park_search) {
        this.list_data = this.main_spots_list
      } else {
        this.list_data = this.info.root_books_list
      }
      if (parkName) {
        var result = []
        for (var i = 0; i < this.list_data.length; i++) {
          var obj = this.list_data[i]
          for (var k in obj) {
            if (obj[k].indexOf(parkName.replace(/^\s*|\s*$/g, '')) != -1) {
              if (obj['spots_name']) {
                result.push({ spots_name: obj['spots_name'], id: obj['id'] })
              } else {
                result.push({
                  label_name: obj['label_name'],
                  label_fk_id: obj['label_fk_id']
                })
              }
              break
            }
          }
        }
        this.list_data = result
      }
    },
    /**
     * 点击地图右侧景、吃、住、购。。。 显示相应的mak点
     * @param type  ==user>     显示用户头像
     */
    click_tab_label (type) {
      var bgMusic = document.getElementsByClassName('bgMusic')[0]
      if (bgMusic) {
        bgMusic.pause()
        bgMusic.currentTime = 0
      }
      var result = [],
        that = this
      this.map.clearMap()
      this.label_info = type
      this.is_tab_button = true
      this.is_map_info = false
      if (type == 0) {
        //                    this.map_add_Marker(this.map, this.spots_point_list, '', (Marker_arr) => {
        //                        that.Marker = Marker_arr
        //                    })

        this.map_add_Marker({
          map: this.map,
          l_index: this.l_index,
          r_index: this.r_index,
          l_lng: this.l_lng,
          l_lat: this.l_lat,
          lng_: this.map_data.add_lng,
          lat_: this.map_data.add_lat,
          list_data: this.spots_point_list,
          callback: function (Marker_arr) {
            that.Marker = Marker_arr
          }
        })
      } else if (type == 'user') {
        // 用户
        this.map_user_yes = !this.map_user_yes
        this.$ajax(
          'post',
          'SpotsRace/get_huang_shi_user_data',
          {
            aid: this.aid,
            park_id: this.park_id
          },
          ret => {
            if (ret.data.id == 0) {
              this.spot_park_list = ret.data.data
              //                            this.map_add_Marker(this.map, this.spot_park_list, type, function (Marker_arr) {
              //                                that.img_Marker = Marker_arr
              //                            })

              this.map_add_Marker({
                map: this.map,
                list_data: this.spot_park_list,
                type: type,
                callback: function (Marker_arr) {
                  that.img_Marker = Marker_arr
                }
              })
            } else {
              Toast(ret.data.msg)
            }
          }
        )
      } else {
        // 全部 为空    景 19     吃 18    住 21    购 20   足迹 3     主景点 2
        this.map_user_yes = false
        for (var i = 0; i < this.spots_point_list.length; i++) {
          if (this.spots_point_list[i]['is_spots'] == type) {
            result.push(this.spots_point_list[i])
          }
        }
        if (result.length <= 0) {
          Toast('暂无数据')
          return
        }
        //                    this.map_add_Marker(this.map, result, type, function (Marker_arr) {
        //                        that.Marker = Marker_arr
        //                    })

        this.map_add_Marker({
          map: this.map,
          list_data: result,
          l_index: this.l_index,
          r_index: this.r_index,
          l_lng: this.l_lng,
          l_lat: this.l_lat,
          lng_: this.map_data.add_lng,
          lat_: this.map_data.add_lat,
          type: type,
          callback: function (Marker_arr) {
            that.Marker = Marker_arr
          }
        })
      }
    },
    click_is_big () {
      this.is_root_books = this.is_park_search = false
      this.is_big = !this.is_big
      this.map.clearInfoWindow()
    }
  }
}
</script>
<style scoped>
.LineList_list {
  padding: 0;
}

.LineList_list li {
  color: #666;
  text-align: center;
}

.LineList_list li.jingdian,
.LineList_list li.zhuzhi,
.LineList_list li.gouwuche-copy,
.LineList_list li.chifan {
  background: none;
}

.LineList_list li img {
  width: 2rem;
  display: inline-block;
}

.LineList_list li p {
  margin: 0.2rem 0;
}
/*  */
.ParkPhoto {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
}
.ParkPhoto .container_box.active {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.ParkPhoto.map .container_box .map_info {
  width: 100%;
  position: inherit;
  background: none;
  padding: 0.5rem;
}

.ParkPhoto.map >>> .amap-marker-label {
  position: absolute;
  z-index: 2;
  border: 1px solid rgb(0, 162, 255);
  background-color: rgba(255, 255, 255, 0.4);
  white-space: nowrap;
  cursor: default;
  padding: 3px;
  font-size: 12px;
  line-height: 14px;
}

.hide {
  display: none;
}

.ParkPhoto .amap-touch-toolbar .amap-zoomcontrol {
  width: 2rem;
}

.amap-zoom-touch-minus,
.amap-zoom-touch-plus {
  height: 2rem;
}

.amap-zoom-touch-minus > div,
.amap-zoom-touch-plus > div {
  line-height: 2rem;
}

.amap-touch-toolbar .amap-zoomcontrol {
  left: 10px;
  bottom: 10px;
}

.amap-toolbar {
  bottom: 0 !important;
}

.ParkMapLineList-content {
  padding: 0.4rem 10px 0;
}

.left_but {
  position: fixed;
  left: 0.5rem;
  bottom: 4.6rem;
}
.left_but .mak_tab {
  background-color: #fff;
  width: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 2px 2px 5px #ccc;
  box-shadow: 2px 2px 5px #ccc;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
}

.left_but .mak_tab img {
  width: 100%;
  padding: 0.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.left_but .mak_tab:last-child {
  margin-bottom: 0;
}
.left_but .mak_tab.map_my_location img,
.left_but .mak_tab img:last-child {
  border: none;
}

.dm-contain >>> .amap-marker-content > div {
  width: 1.4rem;
  height: 2rem;
  line-height: 1.4rem;
  text-align: center;
  background: url(../../assets/img/landmark.png) 0 0 no-repeat;
  background-size: contain;
}

#container >>> .amap-maps .amap-marker-label {
  border: 1px solid #999;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
}

#container >>> .amap-info > div {
  width: 85%;
  left: 6% !important;
}
#container >>> .amap-info .center > div {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  line-height: 1.4;
  overflow: auto;
  box-shadow: 0 3px 14px rgba(0, 0, 100, 0.6);
  text-align: left;
  font-size: 0.7rem;
  min-width: 12.8rem;
  position: relative;
  border-radius: 5px;
}
#container >>> .amap-info .center .titleD {
  border-bottom: 1px solid #f5f5f5;
}
#container >>> .amap-info .center .closeX {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: #c3c3c3;
  text-decoration: none;
  font: 700 16px/14px Tahoma, Verdana, sans-serif;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

#container >>> .amap-info .center .content_box {
  margin-top: 0.4rem;
}

#container >>> .amap-info .center .content_box .contentimg {
  float: left;
  width: 3rem;
}

#container >>> .amap-info .center .content_box .contentinfo {
  overflow: hidden;
  padding: 0 0.2rem;
  max-height: 3rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#container >>> .amap-info .center .bottom_box,
#container >>> .amap-info .center .btmtip {
  display: flex;
}
#container >>> .amap-info .center .btmtip.jingdian {
  background-image: linear-gradient(10turn, #439dff, #95b5ff);
}
#container >>> .amap-info .center .btmtip {
  margin-right: 5px;
  min-width: 3rem;
  max-width: 7rem;
  text-align: center;
  height: 1.4rem;
  line-height: 1.4rem;
  box-sizing: border-box;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
}
#container >>> .amap-info .center .btmtip.luxian {
  background-image: linear-gradient(10turn, #ec1447, #ff6767);
}
#container >>> .amap-info .center .btmtip.yuding {
  background-image: linear-gradient(10turn, #ff9d42, #ffbf83);
}
#container >>> .amap-info .center .sharp {
  height: 23px;
  margin: 0 auto;
  overflow: hidden;
  top: -1px;
  width: 30px;
  border-radius: 0;
  position: relative;
  display: block;
}
#container >>> .amap-info .center .title_,
#container >>> .amap-info .center .titleD {
  padding-bottom: 0.4rem;
  box-sizing: border-box;
  font-size: 0.9rem;
}
#container >>> .amap-info .center .titleD {
  border-bottom: 1px solid #f5f5f5;
}
#container >>> .center .btmtip {
  font-size: 0.6rem;
  padding: 2px 5px;
  background-color: #ff9a80;
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  border-radius: 2px;
}

#container >>> .amap-info > div {
  width: 85%;
  left: 6% !important;
}
#container >>> .amap-info .center > div {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  line-height: 1.4;
  overflow: auto;
  box-shadow: 0 3px 14px rgba(0, 0, 100, 0.6);
  text-align: left;
  font-size: 0.7rem;
  min-width: 12.8rem;
  position: relative;
  border-radius: 5px;
}
#container >>> .amap-info .center .titleD {
  border-bottom: 1px solid #f5f5f5;
}
#container >>> .amap-info .center .closeX {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: #c3c3c3;
  text-decoration: none;
  font: 700 16px/14px Tahoma, Verdana, sans-serif;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

#container >>> .amap-info .center .content_box {
  margin-top: 0.4rem;
}
#container >>> .amap-info .center .bottom_box,
#container >>> .amap-info .center .btmtip {
  display: flex;
}
#container >>> .amap-info .center .btmtip.jingdian {
  background-image: linear-gradient(10turn, #439dff, #95b5ff);
}
#container >>> .amap-info .center .btmtip {
  margin-right: 5px;
  min-width: 3rem;
  max-width: 7rem;
  text-align: center;
  height: 1.4rem;
  line-height: 1.4rem;
  box-sizing: border-box;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
}
#container >>> .amap-info .center .btmtip.luxian {
  background-image: linear-gradient(10turn, #ec1447, #ff6767);
}
#container >>> .amap-info .center .btmtip.yuding {
  background-image: linear-gradient(10turn, #ff9d42, #ffbf83);
}
#container >>> .amap-info .center .sharp {
  height: 23px;
  margin: 0 auto;
  overflow: hidden;
  top: -1px;
  width: 30px;
  border-radius: 0;
  position: relative;
  display: block;
}
#container >>> .amap-info .center .title_,
#container >>> .amap-info .center .titleD {
  padding-bottom: 0.4rem;
  box-sizing: border-box;
  font-size: 0.9rem;
}
#container >>> .amap-info .center .titleD {
  border-bottom: 1px solid #f5f5f5;
}
#container >>> .center .btmtip {
  font-size: 0.6rem;
  padding: 2px 5px;
  background-color: #ff9a80;
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  border-radius: 2px;
}
</style>
