<template>
  <div class="HomePark_box">
    <div class="homelink">
      <div class="feed-title" v-if="park_list.length > 0">
        <h1 v-if="is_tit">
          热门公园
          <router-link to="/park/HomeParkList" class="more">更多公园</router-link>
        </h1>
        <mt-loadmore
          :bottom-method="loadBottom"
          :bottom-all-loaded="allLoaded"
          ref="loadmore"
          :auto-fill="false"
        >
          <div class="park_list_box">
            <ul class="park_list">
              <router-link
                tag="li"
                v-for="k in park_list"
                :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:k.id,park_name:k.park_name,park_logo:k.android_newUrl}}"
                :key="k.id"
              >
                <img :src="k.thumb_img_3" :alt="k.activity_name" />
                <p>{{k.park_name}}</p>
                <div class="spots_num_box">
                  <span class="spots_num">{{k.spots_list}}</span>
                </div>
              </router-link>
            </ul>
          </div>
        </mt-loadmore>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  props: {
    datainfo: Array,
    is_tit: Boolean,
    is_hot: Boolean
  },
  data() {
    return {
      park_list: [],
      key_word: "",
      page: 1,
      allLoaded: false //加载更多
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    parentMsg: function(data) {
      this.park_list = data.park_list;
      if (data.page) {
        this.page = data.page;
      }
    },
    unload() {
      if (this.datainfo) {
        this.park_list = this.datainfo;
      }
    },
    //            加载更多
    loadBottom() {
      var that = this;
      var itemLen = that.park_list.length;
      setTimeout(() => {
        if (itemLen % 10 == 0 && !this.is_hot) {
          that.page++;
          //                        that.getData(that.key_word, that.page);
          //                调用主模块方法
          this.$emit("ievent", that.page);
        } else {
          that.allLoaded = true;
          Toast("没有更多了");
        }
        that.$refs.loadmore.onBottomLoaded();
      }, 1500);
    }
  }
};
</script>
<style scoped>
.homelink {
  display: block;
  border: none;
}
.feed-title {
  position: relative;
  margin-top: 1rem;
  padding: 0 1rem;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.feed-title h1 {
  position: relative;
  width: 100%;
  height: 2rem;
  padding-left: 0.5rem;
  line-height: 2rem;
  font-size: 1rem;
  box-sizing: border-box;
  color: #000;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.feed-title h1::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 1rem;
  width: 0.2rem;
  background-color: #ff9a80;
}


.feed-title .more {
  position: absolute;
  right: 0;
  top: 0;
  height: 2rem;
  line-height: 2rem;
  color: #999;
  background: url(../assets/img/home_icon_title_more.png) 100% no-repeat;
  background-size: auto 12px;
  padding-right: 1rem;
  font-size: .76rem;
}

.park_list_box {
  width: 100%;
  padding: 0.5rem 0;
}

.park_list_box .park_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.park_list_box .park_list li {
  position: relative;
  width: calc(50% - 0.5rem);
  margin-bottom: 0.5rem;
}

.park_list_box .park_list li img {
  display: block;
  width: 100%;
  height: 33vw;
  border-radius: 4px;
  object-fit: cover;
}

.park_list_box .park_list li p {
  margin: 0.5rem 0;
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.park_list_box .park_list li .spots_num_box {
  position: absolute;
  padding: 0.2rem 0.4rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  white-space: nowrap;
}
</style>