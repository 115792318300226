<template>
  <div
    class="activity_box ActivityDetail grayness"
    v-if="Info"
    v-title
    :data-title="'活动详情-' + title_name"
  >
    <div class="page-content" :class="{no_top_padding:app}">
      <!-- 活动图 -->
      <div class="act-banner">
        <img :src="Info.banner_img_app" />
      </div>
      <!-- 顶部信息 -->
      <div class="act-header">
        <h1 class="activity_name">{{Info.activity_name}}</h1>
        <div class="activity_stats">
          <span class="looks_numbers stats-item" v-if="Info.looks_numbers">
            <!-- <i class="iconfont iconliulan"></i> -->
            <span>{{Info.look_numbers}}</span>
          </span>
          <span class="pay_numbers stats-item" v-if="Info.pay_numbers">
            <span>{{Info.pay_numbers}}</span>
          </span>
          <span class="pay_numbers" v-if="Info.pay_numbers"></span>
        </div>
        <!-- 价格 -->
        <div class="activity-price-list" v-if="price_label">
          <div class="price_item" v-for="(item, i) in price_label" :key="i">
            <span>{{item.title}}:{{item.price}}</span>
          </div>
        </div>
        <!-- 活动时间及人数 -->
        <div class="activity-time-number">
          <div class="activity_time" v-if="Info.activity_time">
            <i class="iconfont iconshijianlishijilujishizhongbiaomianxing"></i>
            <span>{{Info.activity_time}}</span>
          </div>
          <div class="activity_max_number">
            <span>{{Info.max_number}}</span>
          </div>
        </div>

        <!-- 地址 -->
        <router-link
          tag="div"
          class="track-address"
          :to="{path:'/parkPhoto/ActivityMap',query:{aid:aid,production_id:Info.id,
                                    type:Info.type_,
                                    spots_name:Info.park_name,
                                    lng:Info.lng,
                                    lat:Info.lat}}"
        >
          <div class="address-wrapper">
            <i class="iconfont icondizhi"></i>
            <span>{{Info.address ? Info.address : "点击导航"}}{{Info.height && Info.height != 0 ? "|海拔：" + Info.height : ""}}</span>
          </div>
          <div class="address-icon" @click.stop.prevent="open_nav">
            <i class="iconfont icondaohang"></i>
          </div>
        </router-link>
      </div>

      <!-- 活动详情 -->
      <ul class="act-detail">
        <input type="hidden" id="aid" v-model="aid" />

        <li class="detail">
          <span>活动内容</span>
          <div class="text" v-if="Info.activity_rich_text !=''" v-html="Info.activity_rich_text"></div>
        </li>
        <!-- 图片跳转到小程序-->
        <div class="act-banner to-mini" v-if="Info.wx_url != ''" @click="click_wx_miniProgram">
          <img :src="Info.wx_url" />
        </div>
        <li class="rank" v-if="isrank">
          <span>获奖名单</span>
          <div class="rank-item" v-for="(k,index) in Info.rank" :key="index">
            <div class="rank-no fl">NO.{{index + 1}}</div>
            <div class="avatar" :class="{vol:k.volunteer==1}">
              <img :src="k.user_icon" />
              <i></i>
            </div>
            <div class="name fl">{{k.user_nick}}</div>
            <div class="detail fr">
              <router-link to>查看详情>></router-link>
            </div>
          </div>
        </li>
        <li class="enter" v-if="isenter">
          <span>已报名（{{Info.enter_numbers}}）</span>
          <div class="enter-list">
            <div class="enter-item" v-for="(k,i) in Info.enter" :key="i">
              <!-- 跳转个人中心 -->
              <!-- <router-link class="link" v-if="k.user_id==user_id" to="/my"></router-link> -->
              <!-- 跳转用户个人中心 -->
              <!-- <router-link
                class="link"
                v-if="k.user_id!=user_id"
                :to="{name:'otherIndex',params:{uid:k.user_id}}"
              ></router-link>-->
              <div class="avatar" :class="{vol:k.volunteer==1}">
                <img v-if="k.user_icon!=''" :src="k.user_icon" />
                <img v-if="k.user_icon==''" src="../../assets/img/apply_avatar_bg.png" />
                <i></i>
              </div>
              <div class="name">{{k.user_nick}}</div>
            </div>
            <!--点击加载更多按钮-->
            <div class="click-load-more" @click="load_more_enter_user">
              <div class="load-more-text-enter-user">点击加载更多</div>
              <div class="load-more-img"></div>
            </div>
          </div>
        </li>
        <li class="group_list" v-if="have_group">
          <span>组队信息：（编号,队名,人员）</span>

          <div class="group-list-one" v-for="(k,i) in group_list" :key="i">
            <span>{{k[0].group_id}}</span>
            <span>{{k[0].group_name}}</span>
            <span>{{k.length}}人</span>
            <div class="enter-list">
              <div class="enter-item" v-for="(kk, i) in k" :key="i">
                <router-link class="link" v-if="kk.user_id==user_id" to="/my"></router-link>
                <router-link
                  class="link"
                  v-if="kk.user_id!=user_id"
                  :to="{name:'otherIndex',params:{uid:kk.user_id}}"
                ></router-link>
                <div class="avatar" :class="{vol:kk.volunteer==1}">
                  <img v-if="kk.user_icon!=''" :src="kk.user_icon" />
                  <img v-if="kk.user_icon==''" src="../../assets/img/apply_avatar_bg.png" />
                  <i v-if="kk.is_leader == 1">领队</i>
                </div>
                <div class="name">{{kk.user_nick}}</div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!--在这里添加作品列表-->
      <div class="ParkPhotoList ParkPhoto" v-if="show_list">
        <!--v-if="is_weixin"-->
        <ul class="act-detail act-works">
          <li class="detail">
            <span>作品集</span>
          </li>
        </ul>
        <!-- 搜索 -->
        <div class="index-search">
          <div class="search-box">
            <div>
              <input type="text" placeholder="搜索作品（手机号、编号）" id="search_text" v-model="key_word" />
              <button @click="get_data(1,union_id)">搜索</button>
            </div>
            <!--<a class="all-photo" @click="get_data(1,union_id,1,1)">全部作品</a>-->
            <!--修改全部作品跳转到全部作品投票页面-->
            <router-link
              class="all-photo"
              :to="{path:'/parkPhoto/ParkPhoto',query:{aid:aid}}"
              v-if="is_vote"
            >全部作品</router-link>
          </div>
        </div>
        <!-- 作品列表 -->
        <mt-loadmore
          :bottom-method="loadBottom"
          :bottom-all-loaded="allLoaded"
          ref="loadmore"
          :auto-fill="false"
        >
          <div class="photo-list-activity">
            <div class="col">
              <div class="item" v-for="(k,i) in info_1" :key="i">
                <div class="num">
                  <span>{{k.id}}号</span>
                </div>
                <!--进入投票页面-->
                <router-link
                  :to="{path:'/parkPhoto/detail',query:{id:k.id,aid:aid,park_id:park_id,park_name:park_name,go_vote:1}}"
                  v-if="is_vote"
                >
                  <img class="pic" :src="k.newUrl" />
                </router-link>
                <!--进入足迹详情-->
                <router-link
                  :to="{path:'/footprints/FootprintsParticulars',query:{user_id:k.user_id,popular_type_fk_id:aid,id:k.id,is_vote:is_vote}}"
                  v-if="!is_vote"
                >
                  <img class="pic" :src="k.newUrl" />
                </router-link>
                <div class="img_num">
                  <span>共{{k.img_numbers}}张</span>
                </div>
                <div class="u-card" v-if="k.type != 1">
                  <router-link :to="{path:'/parkPhoto/PhotoList',query:{photo_all:1,aid:aid}}">
                    <img class="user_icon" :src="k.user_icon" />
                  </router-link>
                  <h1>{{k.user_nick}}</h1>
                  <h2 v-if="is_vote">{{k.numbers}}票</h2>
                  <h2 v-if="!is_vote">{{k.numbers}}条评论</h2>
                  <div @click.stop.prevent="map_open(k.lng,k.lat)">
                    <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
                  </div>
                </div>
                <div class="u-card" v-if="k.type == 1" :class="{no_park:k.type == 1}">
                  <h1>{{k.spots_name}}</h1>
                  <h2>{{k.numbers}}票</h2>
                  <!-- <div @click.stop.prevent="map_open(k.lng,k.lat)">
                    <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
                  </div>-->
                </div>
                <div
                  class="vote"
                  v-if="is_weixin && is_vote"
                  @click="vote(k)"
                  :class="{'no': (k.show == 0)|| (k.show == 1 && k.vote == 1) || (k.show == 3) }"
                >
                  <span v-if="k.show == 0">投票未开始</span>
                  <span v-else-if="k.show == 1 && k.vote == 0">我要投票</span>
                  <span v-else-if="k.show == 1 && k.vote == 1">已投票</span>
                  <span v-if="k.show == 3">投票已结束</span>
                </div>
                <div class="vote" v-else-if="is_vote" :class="{'no': 1 }">
                  <span>微信中投票</span>
                </div>
                <div class="vote" v-else :class="{'no': 1 }">
                  <span>查看详情</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="item" v-for="(k, i ) in info_2" :key="i">
                <div class="num">
                  <span>{{k.id}}号</span>
                </div>
                <router-link
                  :to="{path:'/parkPhoto/detail',query:{id:k.id,aid:aid,park_id:park_id,park_name:park_name,go_vote:1}}"
                  v-if="is_vote"
                >
                  <img class="pic" :src="k.newUrl" />
                </router-link>
                <!--进入足迹详情-->
                <router-link
                  :to="{path:'/footprints/FootprintsParticulars',query:{user_id:k.user_id,popular_type_fk_id:aid,id:k.id,is_vote:is_vote}}"
                  v-if="!is_vote"
                >
                  <img class="pic" :src="k.newUrl" />
                </router-link>
                <div class="img_num">
                  <span>共{{k.img_numbers}}张</span>
                </div>
                <div class="u-card" v-if="k.type != 1">
                  <router-link :to="{path:'/parkPhoto/PhotoList',query:{photo_all:1,aid:aid}}">
                    <img class="user_icon" :src="k.user_icon" />
                  </router-link>
                  <h1>{{k.user_nick}}</h1>
                  <h2 v-if="is_vote">{{k.numbers}}票</h2>
                  <h2 v-else>{{k.numbers}}条评论</h2>
                  <div @click.stop.prevent="map_open(k.lng,k.lat)">
                    <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
                  </div>
                </div>
                <div class="u-card" v-if="k.type == 1" :class="{no_park:k.type == 1}">
                  <h1>{{k.spots_name}}</h1>
                  <h2>{{k.numbers}}票</h2>
                  <!-- <div @click.stop.prevent="map_open(k.lng,k.lat)">
                    <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
                  </div>-->
                </div>
                <div
                  class="vote"
                  v-if="is_weixin && is_vote"
                  @click="vote(k)"
                  :class="{'no': (k.show == 0)|| (k.show == 1 && k.vote == 1) || (k.show == 3) }"
                >
                  <span v-if="k.show == 0">投票未开始</span>
                  <span v-else-if="k.show == 1 && k.vote == 0">我要投票</span>
                  <span v-else-if="k.show == 1 && k.vote == 1">已投票</span>
                  <span v-if="k.show == 3">投票已结束</span>
                </div>
                <div class="vote" v-else-if="is_vote" :class="{'no':1 }">
                  <span>微信中投票</span>
                </div>
                <div class="vote" v-else :class="{'no':1 }">
                  <span>查看详情</span>
                </div>
              </div>
            </div>
          </div>
        </mt-loadmore>
        <!--投票成功弹窗-->
        <div class="pop-up-box" @touchmove.prevent v-if="is_pop_up">
          <img class="pop-up" src="../../assets/img/vote_pop-up.png" alt />
        </div>
        <!--点击加载更多按钮-->
        <div class="click-load-more" @click="click_load_more">
          <div class="load-more-text-enter-user">点击加载更多</div>
          <div class="load-more-img">
          </div>
        </div>
      </div>

      <!--这里结束-->

      <!-- 底部导航 -->
      <div class="act_bottom_nav">
        <div class="bottom_nav_home bottom_nav_tag">
          <img src="../../assets/switch/home.png" alt />
          <span>首页</span>
        </div>
        <div class="bottom_nav_tag bottom_nav_service">
          <img src="../../assets/switch/shop_contact.png" alt />
          <span>客服</span>
        </div>
        <div class="bottom_nav_btn bottom_nav_sign">
          <span>立即报名</span>
        </div>
      </div>
      <!-- 表示这个活动需要报名按钮 -->
      <!-- <div class="act-btn-box" v-if="Info.geo_join!=0">
        <div class="act-btn" @click="applybtn">{{volunteertet}}</div>
      </div>-->
      <!--20180324 在活动页面增加发布足迹功能按钮 go_vote表示发布成功之后，跳转到投票页面-->
      <router-link
        :to="{path:'/footprints/FootprintsPublish',query:{aid:aid,park_id:park_id,park_name:park_name,go_vote:1}}"
        tag="div"
        class="photo_publish"
        v-if="is_show_publish"
      >
        <img src="../../assets/switch/footprint_send.png" alt />
      </router-link>
    </div>
    <div id="apply" class="popups" v-if="displayNone">
      <div class="popups-bg" @click="displayNone=false"></div>
      <div class="popups-box">
        <div class="popups-title">
          报名资料
          <a class="close" @click="displayNone=false">放弃</a>
        </div>
        <div class="popups-form-box">
          <div class="popups-form active">
            <form id="form" action method="post" enctype="multipart/form-data">
              <div class="flex-box flex-justify">
                <div class="popups-form-row flex-box" style="margin-right: 8px;">
                  <span>姓名：</span>
                  <div class="input">
                    <input name="name" type="text" />
                  </div>
                </div>
                <div class="popups-form-row flex-box">
                  <span>性别：</span>
                  <div class="input">
                    <span :class="{active:sex=='女'}" @click="sex='女'">女</span>
                    <span :class="{active:sex=='男'}" @click="sex='男'">男</span>
                    <input type="hidden" v-model="sex" name="sex" />
                  </div>
                </div>
              </div>
              <div class="popups-form-row flex-box">
                <span>职业：</span>
                <div class="input">
                  <input name="job" type="text" />
                </div>
              </div>
              <div class="popups-form-row flex-box">
                <span>出生日期：</span>
                <div class="input" :class="{full:birthday!=''}">
                  <input
                    id="inputdate"
                    placeholder="请选择出生日期"
                    v-model="birthday"
                    name="birthday"
                    type="date"
                  />
                </div>
              </div>
              <input type="hidden" name="aid" v-model="aid" />
              <input type="hidden" name="user_id" v-model="user_id" />
              <div class="popups-form-btn" @click="popupsform">提交</div>
              <div class="form-tips">{{form_tips}}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- 红包雨 -->
    <red-packet
      :redPacketList="red_packet_image_url_list"
      v-if="have_red_packet_rain"
      id_type_="vote"
      :red_packet_type_="info.red_packet_type_"
      :red_packet_image_url="info.red_packet_image_url"
    ></red-packet>

    <!-- 发布红包 -->
    <publish-button :id="aid" id_type_="activity" v-if="is_admin" bottom="3rem"></publish-button>
  </div>
</template>
<script>
import { Toast } from 'mint-ui'
import origin from '../../assets/img/origin.png'
import emphasis from '../../assets/img/emphasis.png'
import mark from '../../assets/img/mark.png'
import LineInfo from '../../components/LineInfo'
import RedPacket from '../../components/RedPacket'
import PublishButton from '../../components/PublishButton'
// 引入微信分享sdk
// import wx from 'weixin-js-sdk';
var wx = require('weixin-js-sdk')

export default {
  components: {
    LineInfo,
    'red-packet': RedPacket,
    'publish-button': PublishButton
  },
  data () {
    return {
      aid: this.$route.query.aid,
      user_id: '',
      Info: null,
      isrank: false, // 获奖名单是否有数据
      isenter: false, // 已报名是否有数据
      volunteertet: '马上报名',
      path: '',
      user_info: this.check_login(),
      geo_join: '',
      displayNone: false,
      form_tips: '',
      sex: '女',
      birthday: '',
      is_maxheight: true,
      container_big: false,
      spots_point_list: [],
      Marker: [],
      app: false,
      title_name: '',
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin, // 判断是不是微信
      union_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      // 下面是获取作品的数据
      key_word: this.$route.query.key_word,

      park_name: this.$route.query.park_name,
      banner_img: this.$route.query.banner_img,
      page: 1,
      enter_user_page: 2, // 报名用户页数
      is_enter_user_more: true, // 点击加载更多报名用户按钮，是否发送请求
      info_1: [],
      info_2: [],
      allLoaded: false,
      itemLen: 0,
      search_list: false,
      is_pop_up: false,

      is_show_publish: true, // 显示足迹发布按钮和作品列表
      show_list: false, // 是否显示作品列表信息
      wx_img: '', // 微信分享需要的图片
      wx_content: '',
      wx_title: '',
      wx_url:
        'http://www.zmlxj.com/api.php/weixin/wx_share?type=ActivityDetail&aid=' +
        this.$route.query.aid,
      have_group: false, // 是否显示组队信息
      group_list: [], // 组队信息
      is_vote: false, // 活动返回值,是否允许投票
      version: '1.4.5',
      park_id: 298,
      red_packet_image_url_list: [],
      have_red_packet_rain: 0,
      is_admin: 0,
      price_label: [] // 价格列表
    }
  },
  mounted () {
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }
    if (this.open_id) {
    } else {
      this.authorize()
    }
    if (this.is_weixin) {
      // 如果是微信,才向服务器请求数据
      this.dealWithUrl(window.location.href)
    }
    this.unload()
  },
  methods: {
    // 增加的弹出地图选择功能模块的代码
    // 设置is_show_all_map的值
    open_nav () {
      this.wx_nav(
        window.location.href,
        this.Info.lng,
        this.Info.lat,
        this.Info.park_name,
        this.Info.address
      )
    },
    unload () {
      var that = this
      if (this.hybrid.hybrid.versions.myapp) {
        // FOR 安卓最美旅行家APP
        this.app = true
        this.isrank = false
        this.isenter = false
        $('.act-btn-box').remove()
        that.getData()
      } else if (this.hybrid.hybrid.versions.myiOSapp) {
        // FOR IOS最美旅行家APP
        this.app = true
        that.isrank = false
        that.isenter = false
        $('.act-btn-box').remove()
        this.hybrid.hybrid.registerHandler('getIOSUserId', function (
          data,
          responseCallback
        ) {
          that.user_id = data.user_id
          var responseData = '已经收到IOS传来的userid'
          responseCallback(responseData)
          that.getData()
        })
      } else {
        this.getData()
        this.get_data(1, this.open_id, 1)
      }
    },
    getData () {
      if (this.user_info) {
        this.user_id = this.user_info.user_id
      }

      this.$ajax(
        'post',
        'index/getBannerInfo_new_v2',
        {
          banner_id: this.aid,
          user_id: this.user_id,
          token: this.token,
          version: this.version,
          park_id: this.park_id,
          open_id: this.open_id
        },
        ret => {
          if (ret.data.id != 0) {
            Toast('数据库查询失败，请稍后重试！')
          } else {
            this.Info = Object.assign({}, this.Info, ret.data)
            this.price_label = this.Info.price_label
              ? this.Info.price_label
              : ''
            this.have_red_packet_rain = this.Info.have_red_packet_rain
              ? this.Info.have_red_packet_rain
              : 0
            this.red_packet_image_url_list = this.Info.red_packet_image_url_list
              ? this.Info.red_packet_image_url_list
              : ''
            this.wx_img =
              ret.data.banner_img_app + '?imageView2/1/w/300/h/300'
                ? ret.data.banner_img_app + '?imageView2/1/w/300/h/300'
                : 'http://www.zmlxj.com/zmlxjshare.jpg'
            this.title_name = ret.data.activity_name
            this.wx_content = ret.data.activity_content
              ? ret.data.activity_content
              : '人生就是一场旅行'
            this.wx_title = ret.data.activity_name
            this.have_group = ret.data.have_group == 1
            this.group_list = ret.data.group_list
            this.is_admin = this.Info.is_admin ? this.Info.is_admin : 0

            // console.log(this.Info);

            this.is_vote = ret.data.is_vote == 1 // 是否允许投票
            this.park_id = ret.data.volunteer_park_id
              ? ret.data.volunteer_park_id
              : 298
            this.park_name = ret.data.park_name
              ? ret.data.park_name
              : '最美旅行家'
            if (this.Info.rank.length != 0) this.isrank = true
            if (this.Info.enter.length > 0 && this.Info.rank.length == 0) { this.isenter = true }
            if (ret.data.is_volunteer == 1) {
              // 是志愿者活动
              if (ret.data.volunteer_joined == 1) {
                this.path = '/volunteer/index' // 表示报名了
              } else {
                this.path = '/volunteer/BaseMsg' // 没有报名-跳转过去填写基础的信息
              }
            } else if (ret.data.is_volunteer == 0) {
              // 不是志愿者活动,普通活动
              if (ret.data.status != 0) {
                this.path = ret.data.status // 表示当前活动报名的状态
              }
            }
            /** 注释(20190904)：
             * 分类：
             * 自驾活动(is_car=1)多个价格，还有团购，参考小程序平台,
             * 组队活动(is_group =1 自己一个人组队 or is_group =2 有队长共五人一起组队) 也有免费和收费(多个价格),
             * 攻略(is_plan = 1),
             * 普通免费活动(is_free = 1 && price =0)
             * 普通收费活动(is_free = 0 && price > 0)
             *
             * @type {Number|number}
             */
            // 根据报名状态显示按钮不同样式
            var sta = parseInt(this.Info.status)
            if (sta == 1) {
              this.volunteertet = '正在审核'
            } else if (sta == 2) {
              this.volunteertet = '恭喜！您已报名成功'
            } else if (sta == 3) {
              this.volunteertet = '很遗憾！报名未通过审核'
            }
          }
        }
      )
    },
    /* ------------------------------------------------ */
    /* 进入小程序 */
    click_wx_miniProgram () {
      // wx.miniProgram.navigateTo({url:this.Info.wx_url_path});
      wx.miniProgram.navigateTo({ url: this.Info.wx_url_path })
    },
    get_data (page, union_id, all, clear_page) {
      if (clear_page == 1) {
        this.page = 1
      }
      if (all == 1) {
        var data = {
          type: 2,
          aid: this.aid,
          page: page,
          union_id: union_id
        }
      } else {
        var data = {
          park_id: this.park_id,
          page: page,
          aid: this.aid,
          type: 2,
          key: this.key_word,
          union_id: union_id
        }
      }

      this.$ajax('post', 'SpotsRace/search', data, ret => {
        if (ret.data.id == 0) {
          this.show_list = ret.data.id == 0
          if (page == 1) {
            this.info_1 = ret.data.data.data.slice(
              0,
              ret.data.data.data.length / 2
            )

            this.info_2 = ret.data.data.data.slice(
              ret.data.data.data.length / 2
            )
          } else {
            if (this.getMinUl().index() == 0) {
              this.info_1 = this.info_1.concat(
                ret.data.data.data.slice(0, ret.data.data.data.length / 2)
              )
              this.info_2 = this.info_2.concat(
                ret.data.data.data.slice(ret.data.data.data.length / 2)
              )
            } else {
              this.info_1 = this.info_1.concat(
                ret.data.data.data.slice(0, ret.data.data.data.length / 2)
              )
              this.info_2 = this.info_2.concat(
                ret.data.data.data.slice(ret.data.data.data.length / 2)
              )
            }
          }
          this.itemLen = ret.data.data.data.length
          // console.log(this.itemLen);
        } else {
          Toast(ret.data.msg)
        }
      })
    },
    //            点击投票
    vote (data) {
      if (data.show == 0) {
        Toast('投票未开始')
      } else if (data.show == 3) {
        Toast('投票已结束')
      } else if (data.show == 1 && data.vote == 1) {
        Toast('今日已投票')
      } else {
        if (this.open_id) {
          this.$ajax(
            'post',
            'SpotsRace/vote',
            {
              aid: this.aid,
              spots_id: data.id,
              union_id: this.open_id
            },
            ret => {
              if (ret.data.id == 0) {
                Toast({
                  message: ret.data.msg,
                  iconClass: 'iconfont icon-chenggong',
                  duration: 1000
                })
                // 直接修改data里面的数据
                data.show = 1
                data.vote = 1
                ++data.numbers
                // this.get_data(this.page, this.union_id)
              } else {
                Toast({
                  message: ret.data.msg,
                  iconClass: 'iconfont icon-fail',
                  duration: 1000
                })
                // 投票失败重新请求授权
                this.authorize()
              }
            }
          )
        } else {
          this.authorize()
        }
      }
    },
    getMinUl () {
      var $arrUl = $('.photo-list-activity .col')
      var $minUl = $arrUl.eq(0)
      $arrUl.each(function (index, elem) {
        if ($(elem).height() < $minUl.height()) {
          $minUl = $(elem)
        }
      })
      return $minUl
    },
    //            加载更多
    loadBottom () {
      var that = this
      setTimeout(() => {
        if (this.itemLen % 10 == 0) {
          that.page++
          that.get_data(that.page, this.open_id, 1)
        } else {
          that.allLoaded = true
          Toast('没有更多了')
        }
        that.$refs.loadmore.onBottomLoaded()
      }, 1500)
    },
    // 点击在家更多
    click_load_more () {
      var that = this

      that.page++
      that.get_data(that.page, this.open_id, 1)
    },
    /* 报名用户加载更多 */
    load_more_enter_user () {
      if (this.is_enter_user_more) {
        this.$ajax(
          'post',
          'index/get_more_enter_user',
          {
            aid: this.aid,
            page: this.enter_user_page++,
            token: this.token
          },
          ret => {
            if (ret.data.id == 0) {
              this.Info.enter = this.Info.enter.concat(ret.data.data)
              if (ret.data.data.length == 25) {
                // 说明有可能还有更多的数据
                this.is_enter_user_more = true
              } else {
                this.is_enter_user_more = false
              }
            } else {
              Toast(ret.data.msg)
            }
          }
        )
      } else {
        Toast('无更多数据')
      }
    },
    /* ---------------------上面--------------------------------- */
    // 这个是点击活动报名按钮
    applybtn () {
      if (!this.user_info) {
        this.get_User_Id()
      } else {
        var open_id = localStorage.getItem('my_open_id')
        if (this.is_weixin && !open_id) {
          // 不存在就需要重新请求
          var open_url =
            'https://www.zmlxj.com/api.php/index/weixin_login?aid=' + this.aid
          window.location.href = open_url
          console.log(this.is_weixin + ',' + !open_id + ',' + this.aid)
        } else {
          if (!this.path) {
            // this.path =0 表示还未参加这个活动，这里面有可能是组队活动和非组队活动
            //                        收费    跳转
            if (this.Info.price > 0 || this.Info.price != '') {
              // 组队活动      is_group == 1 组队活动     have_group_name == 0 该活动还未选择队伍
              if (this.Info.is_group > 0 && this.Info.have_group_name == 0) {
                this.$router.push({
                  path: '/activity/FormTeam',
                  query: {
                    aid: this.aid,
                    price: this.Info.price,
                    is_group: this.Info.is_group,
                    title: this.Info.activity_name
                  }
                })
              } else {
                // 收费+非组队的活动   进入报名详情页面只是不需要选择队和队名
                this.$router.push({
                  path: '/activity/PriceApply',
                  query: {
                    aid: this.aid,
                    price: this.Info.price,
                    is_group: this.Info.is_group
                  }
                })
              }
            } else {
              // 免费活动里面也有可能存在组队活动
              if (this.Info.is_group > 0 && this.have_group_name == 0) {
                this.$router.push({
                  path: '/activity/FormTeam',
                  query: {
                    aid: this.aid,
                    price: this.Info.price,
                    is_group: this.Info.is_group,
                    title: this.Info.activity_name
                  }
                })
              } else {
                // 不是组队活动    弹窗直接报名
                this.displayNone = true
              }
            }
          } else if (this.path.indexOf('/') == 0) {
            // 跳转到志愿者页面去
            this.$router.push({ path: this.path, query: { aid: this.aid } })
          } else {
            // 不是志愿者活动,可能是收费活动或者是免费活动
            if (this.Info.price > 0 || this.Info.price != '') {
              // console.log(this.Info.have_group_name);
              if (this.Info.is_group > 0) {
                // 表示是组队活动
                if (this.Info.have_group_name != 0) {
                  // 已经组队了
                  this.$router.push({
                    path: '/activity/PriceApply',
                    query: {
                      aid: this.aid,
                      price: this.Info.price,
                      is_group: this.Info.is_group
                    }
                  })
                } else {
                  // 还没有进队-跳转到组队界面
                  this.$router.push({
                    path: '/activity/FormTeam',
                    query: {
                      aid: this.aid,
                      price: this.Info.price,
                      is_group: this.Info.is_group,
                      title: this.Info.activity_name
                    }
                  })
                }
              } else {
                // 不是组队的收费活动
                // 收费+非组队的活动   进入报名详情页面只是不需要选择队和队名
                this.$router.push({
                  path: '/activity/PriceApply',
                  query: {
                    aid: this.aid,
                    price: this.Info.price,
                    is_group: this.Info.is_group
                  }
                })
              }
            } else {
              this.displayNone = true
            }
          }
        }
      }
    },
    popupsform () {
      var Form = document.getElementById('form')
      var formdata = new FormData(Form)
      var that = this
      for (var pair of formdata.values()) {
        if (!pair) {
          this.form_tips = '请填全信息'
          return
        }
      }
      this.formMethod('index/join_activity_new', formdata, function (data) {
        that.displayNone = false
        if (data.error == 0) {
          Toast('报名成功，等待审核')
          that.volunteertet = '正在审核'
          that.path = '/volunteer/index'
        } else {
          Toast(data.msg)
        }
      })
    },

    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=' +
        this.aid +
        '&token=' +
        this.token +
        '&state=activityDetail'
      window.location.href = open_url
    }
  }
}
</script>
<style scoped>
.activity_box {
  width: 100%;
  height: 100%;
  position: relative;
}
/*作品页面点击加载更多*/
.click-load-more {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}
.click-load-more .load-more-text {
  color: #999;
  font-size: 0.88rem;
}
.click-load-more .load-more-text {
  font-size: 16px;
  color: #dedede;
}
.click-load-more .load-more-img {
  width: 60%;
  height: 1px;
  background-color: #ccc;
}

/*报名用户加载更多*/
.click-load-more .load-more-text-enter-user {
  font-size: 14px;
  height: 1.2rem;
  line-height: 1.2rem;
  color: #999;
  position: absolute;
  /* top:-0.6rem; */
  padding: 0 4px;
  background-color: #fff;
}
.m_top {
  margin-top: 2.5rem;
}

.ActivityDetail .page-content {
  padding: 0 0 6rem 0;
  /* padding: 2rem 0 4rem; */
  font-size: 0.7rem;
}

.act-banner {
  width: 100%;
}
.act-banner img {
  width: 100%;
  display: block;
}

.act-detail {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 0.5rem;
  line-height: 1.5rem;
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
}
.act-detail li {
  width: 100%;
}

.act-detail > li > span {
  margin-top: 6px;
  display: block;
  color: #ab0101;
  line-height: 42px;
  /* padding: 0 0 0 1.2rem; */
  text-align: center;
  font-size: 18px;
  border-bottom: 1px solid #eee;
  margin-bottom: 6px;
  /* font-weight: 600; */
  /* background-image: url(../../assets/img/activity_title_detail.png); */
  /* background-size: 15px; */
  /* background-position: center left; */
  /* background-repeat: no-repeat; */
}

.act-detail .time .act-prize-show {
  position: absolute;
  right: 0;
  top: -15px;
  color: #ff9a80;
  font-size: 16px;
  background: #fff;
  padding: 0 10px;
}

.act-detail .rank span {
  background-image: url(../../assets/img/act_rank.png);
  background-size: 15px;
}

.act-detail .rank .rank-item {
  width: 100%;
  display: inline-block;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
  line-height: 40px;
}

.act-detail .rank .rank-item .avatar {
  width: 40px;
  height: 40px;
  float: left;
  margin-right: 10px;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.act-detail .rank .rank-item .rank-no {
  margin-right: 10px;
}

.act-detail .rank .rank-item .detail a {
  color: #ff9a80;
}

.act-detail .enter .enter-list {
  width: 100%;
}

.act-detail .enter .enter-item {
  width: 20%;
  display: inline-block;
  margin-top: 10px;
  position: relative;
}

.enter-item .link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.act-detail .enter .enter-item a {
  display: block;
}

.act-detail .enter .enter-item .avatar {
  margin: 0 auto;
}

.act-detail .enter .enter-item .name {
  height: 14px;
  line-height: 14px;
  width: 94%;
  padding: 5px 3% 0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  font-size: 0.6rem;
}

.act-detail .text {
  padding: 0 0 1.15rem 0;
  border-bottom: 1px solid #f2f2f2;
  line-height: 1.5;
}

.act-detail .text >>> hr {
  border: 0;
}

.act-detail .text >>> p,
.act-detail .text >>> div {
  word-wrap: break-word;
}

.act-detail .text >>> p {
  margin: 5px 0;
}

.act-detail .text >>> table {
  border-collapse: collapse;
}

.act-detail .text >>> img {
  border: 0;
  max-width: 100%;
  margin: 0 auto;
  height: auto;
}

.act-detail .text >>> noscript {
  display: none;
}

.act-detail li:nth-last-child(1) .text {
  border: 0;
}
.act-detail li:nth-last-child(1) .text >>> div {
  width: 100%;
  position: relative;
  /* height: 0; */
  /* line-height: 0; */
  padding: 56% 0 0;
  margin-bottom: 0.4rem;
}
.act-detail li:nth-last-child(1) .text >>> div iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.act-btn-box {
  position: fixed;
  bottom: 15px;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  text-align: center;
  border-top: 1px solid #f2f2f2;
}

.act-btn,
.apply-v-btn {
  background-color: #ff9a80;
  width: 80.5%;
  height: 1.6rem;
  line-height: 1.6rem;
  border-radius: 4px;
  text-align: center;
  font-size: 0.8rem;
  color: #fff;
  margin: 0.4rem auto;
  display: block;
}

.act-btn:active,
.apply-v-btn:active {
  background-color: #ff9a80;
}

.popups .popups-box {
  width: 80%;
  padding-bottom: 50px;
}

.popups-title {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #666;
  border-bottom: 1px solid #f2f2f2;
  position: relative;
}

.popups-title a.close {
  color: #ff9a80;
  font-size: 14px;
  position: absolute;
  right: 14px;
  bottom: 0;
}

.popups-form {
  padding: 24px 4% 0;
  position: relative;
}

.popups-form-row {
  overflow: hidden;
  font-size: 12px;
  height: 12px;
  line-height: 12px;
  padding: 12px 10px;
  padding-right: 0;
  background-color: #f2f2f2;
  border-radius: 4px;
  color: #666;
  margin-bottom: 14px;
  text-align: left;
  flex: 1;
  box-flex: 1;
}

.flex-box .popups-form-row .input {
  width: 65%;
}

.popups-form-row input[type="date"]:before {
  color: #a9a9a9;
  content: attr(placeholder);
}

.popups-form-row .input.full input[type="date"]:before {
  color: black;
  content: "" !important;
}

.flex-box .popups-form-row .input span {
  background: #ccc;
  padding: 2px 5px;
  border-radius: 4px;
  color: #fff;
}

.flex-box .popups-form-row .input span.active {
  background-color: #ff9a80;
}

.flex-box .popups-form-row .input input {
  width: 100%;
}

#inputdate {
  vertical-align: top;
  font-size: 12px;
  height: 12px;
}

.popups-form-btn {
  width: 80%;
  height: 40px;
  line-height: 40px;
  background-color: #ff9a80;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  margin: 0 auto;
}

.popups-form .form-tips {
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 100%;
  color: red;
  text-align: center;
}

/*---------------下面是作品的样式------------*/
.pop-up-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
}

.pop-up-box .pop-up {
  width: 50%;
  margin-top: 30%;
}

.mint-loadmore-bottom {
  font-size: 0.8rem;
}

.photo-list-activity {
  padding: 0 0.5rem;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

.photo-list-activity .col {
  box-sizing: border-box;
  width: 46%;
  overflow: hidden;
  margin: 0.5rem 2% 0;
  float: left;
}

.photo-list-activity .item {
  vertical-align: top;
  width: 100%;
  position: relative;
  color: #fff;
  border-radius: 4px;
  float: left;
  margin-bottom: 1.2rem;
  border: 1px solid rgba(218, 218, 218, 0.6);
  box-sizing: border-box;
  padding-bottom: 0.6rem;
}

.photo-list-activity .item .num {
  position: absolute;
  width: 100%;
}

.photo-list-activity .item .num span {
  background: rgba(0, 0, 0, 0.8);
  float: right;
  padding: 0.2rem 0.4rem;
  border-radius: 0 4px 0 4px;
  font-size: 0.6rem;
}

.photo-list-activity .item .u-card a {
  float: left;
  margin-left: 1rem;
}

.photo-list-activity .item .pic {
  border-radius: 4px 4px 0 0;
  width: 100%;
  display: block;
}

.photo-list-activity .item .img_num {
  position: absolute;
  bottom: 3.8rem;
  width: 100%;
  left: 0;
  font-size: 0.7rem;
}

.photo-list-activity .item .img_num span {
  background: rgba(0, 0, 0, 0.4);
  padding: 0.2rem 0.4rem;
  display: inline-block;
}

.photo-list-activity .item .u-card {
  width: 100%;
  position: relative;
  color: #666;
  height: 3.2rem;
}

.photo-list-activity .item .u-card.no_park {
  text-align: center;
}

.photo-list-activity .item .u-card img.navigation_icon {
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  top: -1.6rem;
  right: 0.4rem;
}

.photo-list-activity .item .u-card img.user_icon {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin: 0.4rem 0.2rem;
  border: 1px solid #ff9a80;
  border-radius: 50%;
  box-sizing: border-box;
}

.photo-list-activity .item .vote {
  width: 70%;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  position: absolute;
  bottom: -0.8rem;
  left: 15%;
  border-radius: 0.8rem;
  background-color: rgb(255, 77, 77);
  font-size: 0.7rem;
}

.photo-list-activity .item .vote.no {
  background: #ccc;
}

.photo-list-activity .item .u-card h1 {
  padding: 0.4rem 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.7rem;
  color: #333;
  font-weight: 700;
}

.photo-list-activity .item .u-card h2 {
  font-size: 0.6rem;
}

.photo-list-activity .item .u-card .vote a {
  color: #fff;
}

.ParkPhoto .loading {
  text-align: center;
  color: #ff9a80;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  clear: both;
}

.group_list .group-list-one {
  background-color: #cdcdcd;
}
.group_list .group-list-one span {
  font-size: 1rem;
  margin-left: 2rem;
}
.group-list-one .enter-list {
  width: 100%;
  overflow-x: scroll;
}
.group-list-one .enter-list .enter-item {
  width: 20%;
  display: inline-block;
  margin-top: 10px;
  position: relative;
}
.group-list-one .enter-list .enter-item .avatar i {
  font-style: inherit;
  background-color: red;
  color: #fff;
  font-size: 0.5rem;
  padding: 0.1rem 0.2rem;
  vertical-align: text-bottom;
  position: absolute;
  display: inline-block;
  line-height: normal;
  margin-top: -23px;
  margin-left: 5px;
}
.group-list-one .enter-list .enter-item .avatar {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
}
.group-list-one .enter-list .enter-item .name {
  height: 28px;
  line-height: 14px;
  width: 94%;
  padding: 5px 3% 0;
  text-align: center;
  overflow: hidden;
  color: #333;
  font-size: 0.6rem;
}
.ParkPhoto {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
}

.ParkPhoto .search-box input {
  height: 1.5rem;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  background: #fff;
  padding: 0 0.5rem;
  border: 1px solid rgba(238, 51, 51, 0.5);
  border-radius: 0.15rem 0 0 0.15rem;
  color: #666;
  outline: 0;
  -webkit-appearance: none;
  border-radius: 4px 0 0 4px;
  flex: 3;
  font-size: 0.7rem;
  border-right: none;
}
.ParkPhoto .search-box {
  font-size: 0;
  padding: 1rem 0.5rem;
  display: flex;
}
.ParkPhoto .search-box > div {
  text-align: center;
  flex: 3;
  display: flex;
  border-radius: 4px;
  box-shadow: 0 0 2px #ccc;
}
.ParkPhoto .search-box button {
  height: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  outline: 0;
  border-radius: 0 0.15rem 0.15rem 0;
  background: linear-gradient(rgb(252, 60, 60), rgb(240, 123, 55));
  color: #fff;
  border: none;
  border-radius: 0 4px 4px 0;
  flex: 1;
  font-size: 0.7rem;
}
.ParkPhoto .search-box .all-photo {
  height: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  font-size: 0.7rem;
  background: linear-gradient(rgb(252, 60, 60), rgb(240, 123, 55));
  color: #fff;
  border-radius: 4px;
  text-align: center;
  margin-left: 1rem;
  box-shadow: 0 0 2px #ff9a80;
}
.ParkPhoto .search-box a {
  -webkit-box-flex: 0.8;
  -ms-flex: 0.8;
  flex: 0.8;
}

.photo-list-activity .col {
  width: 46%;
  overflow: hidden;
  margin: 0.5rem 2% 0;
  float: left;
}
.mint-loadmore-bottom {
  font-size: 0.8rem;
  margin-bottom: -50px;
}
.mint-loadmore-bottom,
.mint-loadmore-top {
  text-align: center;
  height: 50px;
  line-height: 50px;
}
.mint-loadmore-text {
  vertical-align: middle;
}

.photo_publish {
  position: fixed;
  width: 3rem;
  height: 3rem;
  bottom: 2.6rem;
  left: calc(50% - 1.5rem);
  margin: 0;
}
.photo_publish img {
  width: 100%;
}

/* 底部导航 */
.act_bottom_nav {
  position: fixed;
  width: 100%;
  height: 2.4rem;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  border-top: 1px solid #eee;
}
.act_bottom_nav .bottom_nav_tag {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
}
.act_bottom_nav .bottom_nav_tag img {
  width: 1rem;
}
.act_bottom_nav .bottom_nav_btn {
  width: 66%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 77, 77);
  color: #fff;
  font-size: 16px;
}

/* 20190919新增 */
.act-header {
  background-color: #fff;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
}
.activity_name {
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
  text-align: center;
}
.activity_stats {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
}

.activity_stats .stats-item {
  color: #666;
  font-size: 12px;
}
.activity_stats span.looks_numbers {
  margin-right: 12px;
}

.activity-price-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0;
}

.activity-price-list .price_item span {
  padding: 0 6px;
  border: 1px solid rgb(172, 2, 2);
  color: rgb(172, 2, 2);
}
.activity-time-number {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}
.activity_time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.activity_time i {
  color: #666;
  margin-right: 4px;
  font-size: 16px;
}

.activity_time span {
  color: #333;
  font-size: 14px;
}
.activity_max_number {
  color: rgb(155, 62, 0);
}
.act-works {
  padding: 0;
}

.track-address {
  width: 100%;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  box-sizing: border-box;
}
.track-address .address-wrapper {
  width: calc(100% - 2rem);
  height: 1.8rem;
  display: flex;
  align-items: center;
}
.track-address .address-wrapper i {
  font-size: 16px;
  color: rgb(51, 89, 145);
}
.track-address .address-wrapper span {
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  color: rgb(51, 89, 145);
}

.track-address .address-icon i {
  font-size: 16pxrem;
  color: rgb(51, 89, 145);
}
</style>
