<template>
  <div
    class="AppLineInfoList LineInfoList"
    :class="{active:container_big}"
    v-title
    data-title="最美路书"
  >
    <div class="container_box">
      <div id="container" style="width:100%; height:100%"></div>
      <ul class="map_info clearfix">
        <li>
          <p>总里程</p>
          <p>{{info.length}}</p>
        </li>
        <li>
          <p>最高海拔</p>
          <p>{{info.height}}</p>
        </li>
        <li>
          <p>最低海拔</p>
          <p>{{info.low}}</p>
        </li>
        <li>
          <p>徒步时间</p>
          <p>{{info.time_length}}</p>
        </li>
      </ul>
      <i
        class="container_quanping iconfont"
        :class="{'icon-quitquanping':container_big,'icon-quanping':!container_big}"
        @click="container_big=!container_big"
      ></i>
      <div class="left_but" v-if="container_big">
        <div class="mak_tab">
          <!--点/图片 切换-->
          <img
            src="../../assets/img/map_3d.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="is_tab_map_3d"
          />
          <img
            src="../../assets/img/map_3d_yes.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="!is_tab_map_3d"
          />
        </div>
        <div class="mak_tab">
          <!--地图 + -  -->
          <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
          <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
        </div>
      </div>
    </div>
    <div v-if="info.spots_list">
      <line-info-content :datainfo="info.spots_list" :user_id="user_id" :is_app="true"></line-info-content>
    </div>
    <mt-actionsheet :actions="actions" v-model="sheetVisible"></mt-actionsheet>
  </div>
</template>
<script>
import LineInfoContent from "../../components/LineInfoContent";
export default {
  components: {
    LineInfoContent
  },
  data() {
    return {
      map: {},
      label_id: this.$route.query.label_id,
      user_id: this.$route.query.user_id,
      info: {},
      point_list: [],
      driving_box: [],
      container_big: false,
      sheetVisible: false,
      actions: [
        { name: "分享到微博", method: this.microblog },
        { name: "分享到QQ空间", method: this.shareToQq }
      ],
      picurl: "",
      is_maxheight: true,
      Marker: [], //所有点
      is_tab_map_3d: true, //3D地图按钮是否点击
      container_big: false //地图放大缩小
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      var that = this;
      //初始化地图对象，加载地图
      this.map = this.map_init();
      this.$ajax(
        "post",
        "foot/get_one_user_collect_spots_info",
        {
          user_id: this.user_id,
          label_id: this.label_id
        },
        res => {
          if (res.data.id == 0) {
            this.info = res.data.data;
            this.point_list = res.data.data.spots_point_list;

            this.picurl = res.data.data.spots_list[0].newUrl;
            if (res.data.data.spots_point_list.length > 0) {
              this.map_root_book_point(
                this.map,
                res.data.data.spots_point_list,
                data => {
                  this.jump(data, "", this.container_big);
                }
              );
            }
          }
        }
      );
    },
    //分享到新浪微博
    microblog() {
      var title = encodeURIComponent("最美旅行家路书分享");
      var link = encodeURIComponent(
        "https://m.zmlxj.com/footprints/LineInfoListtwo?user_id=" +
          this.user_id +
          "&label_id=" +
          this.label_id
      );
      var image = encodeURIComponent(this.picurl);
      shareToXl(title, link, image);
      //内容，网址  图片
      function shareToXl(title, url, picurl) {
        var sharesinastring =
          "https://v.t.sina.com.cn/share/share.php?title=" +
          title +
          "&url=" +
          url +
          "&content=utf-8&sourceUrl=" +
          url +
          "&pic=" +
          picurl;
        window.open(
          sharesinastring,
          "newwindow",
          "height=400,width=400,top=100,left=100"
        );
      }
    },
    //分享到qq空间
    shareToQq() {
      var title = encodeURIComponent("最美旅行家路书分享");
      var link = encodeURIComponent(
        "https://m.zmlxj.com/footprints/LineInfoListtwo?user_id=" +
          this.user_id +
          "&label_id=" +
          this.label_id
      );
      var image = encodeURIComponent(this.picurl);
      qq_space(title, link, image);
      function qq_space(title, url, picurl) {
        var shareqqzonestring =
          "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?summary=" +
          title +
          "&url=" +
          url +
          "&pics=" +
          picurl;
        console.log(shareqqzonestring);
        window.open(
          shareqqzonestring,
          "newwindow",
          "height=400,width=400,top=100,left=100"
        );
      }
    }
  }
};
</script>