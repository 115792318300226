<template>
  <div class="tl-body">
    <mt-header fixed :title="title">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="menu" v-if="type_ != 'park'">
      <div class="menu-item" :class="index == 0 ? 'red_' : '' " @click="select_menu('all',0)">
        <span>全部</span>
      </div>
      <div class="menu-item" :class="index == 1 ? 'red_' : '' " @click="select_menu('spots',1)">
        <span>景点</span>
      </div>
      <div class="menu-item" :class="index == 2 ? 'red_' : '' " @click="select_menu('food',2)">
        <span>美食</span>
      </div>
      <div class="menu-item" :class="index == 3 ? 'red_' : '' " @click="select_menu('shop',3)">
        <span>购物</span>
      </div>
      <div class="menu-item" :class="index == 4 ? 'red_' : '' " @click="select_menu('sleep',4)">
        <span>住宿</span>
      </div>
      <div class="menu-item" :class="index == 5 ? 'red_' : '' " @click="select_menu('order',5)">
        <span>订单</span>
      </div>
    </div>
    <div class="near-ticket-list" v-if=" type_ == 'near_ticket' && list != '' ">
      <div class="near-ticket-item" v-for="(k,i) in near_ticket_list" :key="i">
        <router-link
          :to="{path:'footprints/FootprintsParticulars',query:{id:k.id,label_id:k.label_id,price:k.price,del_price:k.del_price,popular_type_fk_id:k.popular_type_fk_id,user_id:k.user_id}}"
        >
          <div class="item" :class="i % 4 >= 1 ? 'margin_left':''">
            <div class="img">
              <img :src="k.newUrl" />
            </div>
            <div class="title">
              <span>{{k.title}}</span>
            </div>
            <div class="foot">
              <div class="price">
                <span>￥{{k.price}}</span>
              </div>
              <div class="del_price">
                <span>{{k.del_price}}</span>
              </div>
              <div class="label_name">
                <span>{{k.label_name}}</span>
              </div>
              <div class="shop_numbers">
                <span v-if="k.price > 0 ">已售:{{k.shop_numbers}}件</span>
                <span v-else>浏览:{{k.shop_numbers}}次</span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <!--3热门票务-->
    <div
      class="hot-ticket"
      v-else-if=" (type_ == 'hot_ticket' || type_ == 'park_ticket') && list != '' "
    >
      <div class="list">
        <div class="item" v-for="(k,i) in list" :key="i">
          <router-link
            :to="{path:'/footprints/FootprintsParticulars',query:{id:k.id,label_id:k.label_id,price:k.price,del_price:k.del_price,popular_type_fk_id:k.popular_type_fk_id,user_id:k.user_id}}"
          >
            <div class="left_">
              <div class="img">
                <img :src="k.newUrl" />
                <span>{{k.label_name}}</span>
              </div>
            </div>
            <div class="right_">
              <div class="title">
                <span>{{k.title}}</span>
              </div>
              <div class="notes">
                <span>{{k.notes}}</span>
              </div>
              <div class="footer">
                <div class="price_">
                  <span>￥</span>
                  <span style="font-weight: bold;color: red;">{{k.price}}</span>
                  <span style="text-decoration: line-through;">{{k.del_price}}</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!--4景区公园和省份-->
    <div class="park-ticket-list" v-else-if=" type_ == 'park' && list != '' ">
      <div class="list">
        <div class="item" v-for="(k,i) in list" :key="i">
          <router-link
            :to="{path:'/Ticket/TicketList',query:{type_:'park_ticket',id:k.id,token:'zmlxj_2018'}}"
          >
            <div class="img" :class="i % 2 > 0 ? 'park_margin_left':''">
              <img :src="k.main_img" />
            </div>
          </router-link>

          <div class="name" :class="i % 2 > 0 ? 'park_margin_left':''">
            <span>{{k.park_name}}</span>
          </div>
        </div>
      </div>
    </div>

    <!--点击加载更多按钮-->
    <div class="click-load-more" @click="click_load_more">
      <div class="load-more-text">点击加载更多</div>
      <div class="load-more-img">
        <img src="../../assets/img/click-load-more.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";

export default {
  components: {},
  data() {
    return {
      id: this.$route.query.id ? this.$route.query.id : 0,
      lng: this.$route.query.lng ? this.$route.query.lng : "",
      lat: this.$route.query.lat ? this.$route.query.lat : "",
      type_: this.$route.query.type_,
      list: [],
      title: this.$route.query.title ? this.$route.query.title : "票务信息",
      page: this.$route.query.type_ == "park_ticket" ? 1 : 2,
      index: 0, //索引
      menu_type_: "all",
      all_list: [],
      food_list: [],
      shop_list: [],
      sleep_list: [],
      spots_list: [],
      all_page: 1,
      food_page: 1,
      shop_page: 1,
      sleep_page: 1,
      spots_page: 1
    };
  },
  mounted() {
    //请求数据
    this.get_server_data(this.page, this.type_, this.id, this.lng, this.lat);
  },
  methods: {
    //请求数据
    get_server_data(page_, type_, id, lng, lat) {
      //根据type_的不通知请求不同的接口
      var url = "";
      if (type_ == "near_ticket") {
        //请求附近的景点
        url = "Ticket/get_near_ticket_list";
      } else if (type_ == "hot_ticket") {
        //请求热门景点
        url = "Ticket/get_hot_ticket_list";
      } else if (type_ == "park") {
        //请求公园列表
        url = "Ticket/get_park_list";
      } else if (type_ == "park_ticket") {
        //请求某一个公园的票务
        url = "Ticket/get_park_ticket_list";
      }
      this.$ajax(
        "post",
        url,
        { id: id, page: page_, token: "zmlxj_2018", lng: lng, lat: lat },
        ret => {
          //console.log(ret);
          if (ret.data.id == 0) {
            this.list = ret.data.data.all_list;
            this.all_list = ret.data.data.all_list;
            this.spots_list = ret.data.data.spots_list;
            this.food_list = ret.data.data.food_list;
            this.shop_list = ret.data.data.shop_list;
            this.sleep_list = ret.data.data.sleep_list;
          } else {
            Toast("无数据");
          }
        }
      );
    },
    //加载更多
    click_load_more() {
      var index = this.index;
      var menu_type_ = this.menu_type_;
      var url = "";
      if (this.type_ == "near_ticket") {
        //请求附近的景点
        url = "Ticket/get_near_ticket_list";
      } else if (this.type_ == "hot_ticket") {
        //请求热门景点
        url = "Ticket/get_hot_ticket_list";
      } else if (this.type_ == "park") {
        //请求公园列表
        url = "Ticket/get_park_list";
      } else if (this.type_ == "park_ticket") {
        //请求某一个公园的票务
        url = "Ticket/get_park_ticket_list";
      }
      var page_ = 2;
      if (this.type_ == "park") {
        page_ = ++this.page;
      } else {
        if (menu_type_ == "all") {
          page_ = ++this.all_page;
        } else if (menu_type_ == "food") {
          page_ = ++this.food_page;
        } else if (menu_type_ == "sleep") {
          page_ = ++this.sleep_page;
        } else if (menu_type_ == "shop") {
          page_ = ++this.shop_page;
        } else if (menu_type_ == "spots") {
          page_ = ++this.spots_page;
        }
      }
      var id = this.id;
      var lng = this.lng;
      var lat = this.lat;
      this.$ajax(
        "post",
        url,
        {
          id: id,
          page: page_,
          token: "zmlxj_2018",
          lng: lng,
          lat: lat,
          where: menu_type_
        },
        ret => {
          //console.log(ret);
          if (ret.data.id == 0) {
            if (menu_type_ == "all") {
              this.all_list = this.all_list.concat(ret.data.data.all_list);
            } else if (menu_type_ == "food") {
              this.food_list = this.food_list.concat(ret.data.data.food_list);
            } else if (menu_type_ == "sleep") {
              this.sleep_list = this.sleep_list.concat(
                ret.data.data.sleep_list
              );
            } else if (menu_type_ == "shop") {
              this.shop_list = this.shop_list.concat(ret.data.data.shop_list);
            } else if (menu_type_ == "spots") {
              this.spots_list = this.spots_list.concat(
                ret.data.data.spots_list
              );
            }
            this.list = this.list.concat(ret.data.data.all_list);
          } else {
            Toast("无数据");
          }
        }
      );
    },
    select_menu(type_, index) {
      this.menu_type_ = type_;
      this.index = index;
      if (type_ == "all") {
        this.list = this.all_list;
      } else if (type_ == "food") {
        this.list = this.food_list;
      } else if (type_ == "shop") {
        this.list = this.shop_list;
      } else if (type_ == "sleep") {
        this.list = this.sleep_list;
      } else if (type_ == "spots") {
        this.list = this.spots_list;
      } else if (type_ == "order") {
        //跳转到个人订单
        this.$router.push({ path: "/my/Order" });
      }
    }
  }
};
</script>

<style scoped>
.menu {
  position: fixed;
  float: left;
  width: 108%;
  margin-top: 8%;
  z-index: 11;
  background-color: #eaeaea;
}
.menu .menu-item {
  position: relative;
  float: left;
  width: 15%;
}
.menu .menu-item span {
  display: block;
  padding: 10px;
  text-align: center;
}
.red_ {
  color: red;
  text-decoration: underline;
  background-color: #ff9a80;
}
</style>