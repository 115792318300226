<template>
  <div v-title data-title="填写订单">
    <mt-header fixed title="填写订单">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="order-edit-head">
        <div class="guide-item-tit">{{title}}</div>

        <div class="guide-order-price">
          <span class="price">￥{{onePrice}}</span>
          <span class="day">/天</span>
        </div>
      </div>

      <ul class="order-edit-form">
        <li class="tour_message">
          <h1>出行信息</h1>
          <div class="from-box">
            <mt-field
              label="开始日期"
              placeholder="请输入出行时间"
              type="date"
              v-model="startTime"
              :class="{full:startTime!=''}"
            ></mt-field>
            <mt-field
              label="结束日期"
              placeholder="请输入出行时间"
              type="date"
              v-model="endTime"
              :class="{full:endTime!=''}"
            ></mt-field>
            <mt-field label="见面地点" placeholder="请填写见面地点" v-model="meetPlace"></mt-field>
            <mt-field label="成人" placeholder="请输入人数" type="number" v-model="adultNum"></mt-field>
            <mt-field label="儿童" placeholder="请输入人数" type="number" v-model="kidNum"></mt-field>
          </div>
        </li>
        <li class="tour_xinxi">
          <h1>出行人信息</h1>
          <div class="from-box">
            <mt-field label="联系人" placeholder="请填写联系人姓名" v-model="contactName"></mt-field>
            <mt-field label="电话" placeholder="请填写联系人电话" v-model="contactPhone"></mt-field>
          </div>
        </li>
        <li class="tour_remark">
          <h1>
            备注
            <span>(选填)</span>
          </h1>
          <div class="from-box">
            <mt-field label placeholder="请填写需要特别说明的事项" type="textarea" v-model="remark"></mt-field>
          </div>
        </li>
      </ul>

      <div class="guide-order-agree">
        <input type="checkbox" checked="checked" name />
        <span>同意</span>
        <router-link to="/order/GuideAgreement">《最美旅行家购买协议》</router-link>
      </div>
    </div>

    <div class="guide-footbar">
      <div v-show="showType" class="order-detail-box">
        <div class="detail-date" v-if="startTime!='' && endTime!=''">
          <span>{{startTime}} - {{endTime}}</span>
        </div>
        <div class="detail-date" v-else>
          <span>请选择出行日期</span>
        </div>
        <div class="detail-tit">{{title}}</div>
        <div class="detail-price">
          ￥{{onePrice}}
          <span>x{{dateLen}}天</span>
        </div>
      </div>
      <div v-on:click="toggleShowType" class="order-detail" :class="{ active: showType }">
        <span>￥{{allPrice}} 明细</span>
      </div>
      <a @click="postGuideOrder" class="go-pay">立即支付</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      guideId: this.$route.params.id,
      userId: "",
      onePrice: this.$route.params.price,
      title: this.$route.params.tit,
      luId: this.$route.params.luId,
      verify: this.$route.params.verify == 0 ? null : this.verify,
      // 表单数据
      startTime: "",
      endTime: "",
      meetPlace: "",
      adultNum: "",
      kidNum: "",
      contactName: "",
      contactPhone: "",
      remark: "",
      // 计算
      allPrice: 0,
      dateLen: 0,
      // 明细弹窗
      showType: false
    };
  },
  mounted() {
    this.getLoginInfo();
  },
  watch: {
    adultNum() {
      this.countPrice();
    },
    kidNum() {
      this.countPrice();
    },
    startTime() {
      this.dateLen = this.getDateDiff(this.startTime, this.endTime);
      this.countPrice();
    },
    endTime() {
      this.dateLen = this.getDateDiff(this.startTime, this.endTime);
      this.countPrice();
    }
  },
  methods: {
    getLoginInfo: function() {
      //检测登录
      var user_info = this.check_login();
      if (!user_info) {
        localStorage.setItem("login_url", location.pathname);
        this.$router.push({ name: "login" });
      } else {
        this.userId = user_info.user_id;
      }
    },
    getDateDiff: function(startDate, endDate) {
      //计算天数
      if (startDate == "" || endDate == "") {
        return 0;
      }
      var startTime = new Date(
        Date.parse(startDate.replace(/-/g, "/"))
      ).getTime();
      var endTime = new Date(Date.parse(endDate.replace(/-/g, "/"))).getTime();
      var dates = Math.abs(startTime - endTime) / (1000 * 60 * 60 * 24);
      dates = dates == 0 ? 1 : dates;
      return dates;
    },

    toggleShowType: function() {
      //开关明细
      return (this.showType = this.showType === false ? true : false);
    },

    countPrice: function() {
      //计算总价
      var adultNum_ = this.adultNum == "" ? 0 : this.adultNum;
      var kidNum_ = this.kidNum == "" ? 0 : this.kidNum;
      this.allPrice =
        (parseInt(adultNum_) + parseInt(kidNum_)) *
        this.onePrice *
        this.dateLen;
    },

    postGuideOrder: function() {
      //提交订单
      if (
        this.startTime == "" ||
        this.end_time == "" ||
        this.meetPlace == "" ||
        this.adultNum == 0 ||
        this.contactName == "" ||
        this.contactPhone == ""
      ) {
        alert("请完善您的信息！");
        return false;
      }

      var that = this;
      // /order/GuideOrderDetail
      this.$ajax(
        "post",
        "/Service/order",
        {
          user_id: that.userId,
          begin_time: that.startTime,
          end_time: that.end_time,
          time_length: that.dateLen,
          meet_address: that.meetPlace,
          man: that.adultNum,
          child: that.kidNum,
          name: that.contactName,
          tel: that.contactPhone,
          service_id: that.guideId,
          l_u_id: that.luId,
          notes: that.remark,
          verify: that.verify
        },
        function(res) {
          that.$router.push({
            name: "GuideOrderDetail",
            params: { id: res.data.data.order_id, my: 0, status: 0 }
          });
        }
      );
    }
  }
};
</script>

<style scoped>
.order-edit-form >>> .mint-field-core {
  text-align: left;
}

.order-edit-head {
  background: #fff;
  padding: 10px;
}

.guide-item-tit .park-avatar {
  height: 30px;
  width: 30px;
  float: left;
  border-radius: 50%;
  border: 1px solid #f2f2f2;
  margin-right: 10px;
}

.guide-order-price {
  margin-top: 5px;
  font-size: 0.6rem;
}

.guide-order-price .price {
  color: #ff6101;
}

.guide-order-price .day {
  color: #999;
}

.guide-order-price .ticket {
  color: #333;
  margin-left: 10px;
  font-size: 14px;
}

.order-edit-form li {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin: 5px 10px;
}

.order-edit-form li h1 {
  color: #ff9a80;
  border-bottom: 1px dotted #f2f2f2;
  padding-bottom: 5px;
  background: no-repeat left 2px;
  background-size: auto 18px;
  padding-left: 26px;
  font-size: 0.7rem;
}

.order-edit-form .tour_message h1 {
  background-image: url(../../assets/img/tour_message.png);
}

.order-edit-form .tour_xinxi h1 {
  background-image: url(../../assets/img/tour_xinxi.png);
}

.order-edit-form .tour_remark h1 {
  background-image: url(../../assets/img/tour_remark.png);
}

.guide-order-agree {
  padding: 0.5rem;
  font-size: 0.6rem;
}

.guide-order-agree span {
  color: #999;
}

.guide-order-agree a {
  color: #ff9a80;
}

.guide-footbar .order-detail-box {
  position: absolute;
  width: 100%;
  background: #fff;
  bottom: 2rem;
  text-align: left;
  border-top: 1px solid #f2f2f2;
}

.guide-footbar .order-detail-box > div {
  padding: 0 0.5rem;
  line-height: 1.5rem;
}

.guide-footbar .order-detail-box .detail-date {
  font-size: 0.6rem;
  color: #999;
}

.guide-footbar .order-detail-box .detail-date span {
  background: url(../../assets/img/tour_price_detail_tit.png) no-repeat 0.1rem
    center;
  background-size: auto 100%;
  padding-left: 1.2rem;
}

.guide-footbar .order-detail-box .detail-tit {
  font-size: 0.9rem;
  font-weight: bold;
}

.guide-footbar .order-detail-box .detail-price {
  color: #fe7c00;
  font-size: 0.9rem;
}

.guide-footbar .order-detail-box .detail-price span {
  color: #999;
  font-size: 0.7rem;
  margin-left: 0.5rem;
}

.guide-footbar .order-detail {
  width: 50%;
  float: left;
  background: #fe580e;
  color: #fff;
}

.guide-footbar .order-detail span {
  background: url(../../assets/img/arrow_down_fff.png) no-repeat right center;
  background-size: auto 60%;
  padding-right: 2rem;
}

.guide-footbar .order-detail.active span {
  background-image: url(../../assets/img/arrow_up_fff.png);
}

.guide-footbar .go-pay {
  width: 50%;
  float: left;
  background: linear-gradient(90deg, #fd6208 0%, #ff8500 100%);
  color: #fff;
}

input[type="date"]:before {
  color: #a9a9a9;
  content: attr(placeholder);
}

.full input[type="date"]:before {
  color: black;
  content: "" !important;
}

.order-edit-form >>> .mint-cell-wrapper {
  padding: 0;
  font-size: 0.7rem;
}

.guide-footbar {
  line-height: 2rem;
}

.guide-item-tit {
  line-height: 32px;
}
</style>