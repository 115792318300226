<template>
  <div class="container" v-title data-title="泥河湾印象" v-if="loaded">
    <i class="iconfont iconxuanzhongshangcheng backHome" @click="backHome"></i>
    <div class="footer-menu">
      <div class="item" @click="handleMenu('map')">
        <i class="iconfont iconzuji"></i>
        <span class="text">地图</span>
      </div>
      <div class="item active" @click="handleMenu">
        <i class="iconfont icondetail-copy"></i>
        <span class="text">科普</span>
      </div>
      <div class="item" @click="handleMenu('my')">
        <i class="iconfont iconicon-user"></i>
        <span class="text">我的</span>
      </div>
    </div>
    <div class="banner">
      <img :src="shareInfo.shareImage" class="img" />
      <span class="text">{{shareInfo.title}}</span>
    </div>

    <div class="menu">
      <ul class="menu-list">
        <li class="item" v-for="(item, i) in menuList" :key="i" @click="toMore(item.type)">
          <i :class="['iconfont', item.icon]"></i>
          <span class="text">{{item.name}}</span>
        </li>
      </ul>
    </div>
    <!-- spots -->
    <div class="spots main">
      <div class="header">
        <h2 class="title">热门景点</h2>
        <div class="more" @click="toMore('spots')">
          <span class="text">查看全部</span>
          <i class="iconfont iconqianjin"></i>
        </div>
      </div>
      <div class="content">
        <mt-swipe class="spots-list list" :auto="3000" :speed="200">
          <mt-swipe-item class="item" v-for="item in spotsList" :key="item.id">
            <img class="img" :src="item.baseUrl" @click="navToSpot(item.id)" />
            <div class="title">
              <span class="text">{{item.title}}</span>
            </div>
          </mt-swipe-item>
        </mt-swipe>
      </div>
    </div>

    <!-- root -->
    <div class="root main">
      <div class="header">
        <h2 class="title">人文历史</h2>
        <div class="more" @click="toMore('history')">
          <span class="text">查看全部</span>
          <i class="iconfont iconqianjin"></i>
        </div>
      </div>
      <div class="content">
        <div class="root-list list">
          <div class="item" v-for="item in rootList" :key="item.id" @click="navToRoot(item.id)">
            <img :src="item.newUrl" class="img" />
          </div>
        </div>
      </div>
    </div>

    <!-- books -->
    <div class="books main">
      <div class="header">
        <h2 class="title">电子书阅读</h2>
        <div class="more" @click="toMore('books')">
          <span class="text">查看全部</span>
          <i class="iconfont iconqianjin"></i>
        </div>
      </div>
      <div class="content">
        <div class="books-list list">
          <div class="item" v-for="item in booksList" :key="item.id" @click="navToBook(item.id)">
            <img :src="item.bgUrl" class="img" />
          </div>
        </div>
      </div>
    </div>

    <!-- 视频 -->
    <div class="video main">
      <div class="header">
        <h2 class="title">视频资料</h2>
        <div class="more" @click="toMore('video')">
          <span class="text">查看全部</span>
          <i class="iconfont iconqianjin"></i>
        </div>
      </div>
      <div class="content">
        <div class="video">
          <iframe
            class="video-iframe"
            :src="'https://v.qq.com/iframe/player.html?vid=' + videoList[0].content"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>

    <!-- 科普答题 -->
    <div class="question main">
      <div class="header">
        <h2 class="title">科普答题</h2>
        <div class="more" @click="toMore('question')">
          <span class="text">查看全部</span>
          <i class="iconfont iconqianjin"></i>
        </div>
      </div>
      <div class="content" @click="toMore('question')">
        <img src="../../assets/nihewan/question-bg_1.jpg" class="question-bg img" />
      </div>
    </div>

    <!-- 科普活动 -->
    <div class="activity main">
      <div class="header">
        <h2 class="title">科普活动</h2>
        <div class="more" @click="toMore('activity')">
          <span class="text">查看全部</span>
          <i class="iconfont iconqianjin"></i>
        </div>
      </div>
      <div class="content">
        <ul class="activity-list">
          <li
            class="item"
            v-for="item in activityList"
            :key="item.id"
            @click="navToActivity(item.id)"
          >
            <img :src="item.banner_img_app" class="img" />
            <div class="title">
              <span class="text">{{item.activity_name}}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 相册 -->
    <div class="album main">
      <div class="header">
        <h2 class="title">最新相册</h2>
        <div class="more" @click="toMore('album')">
          <span class="text">查看全部</span>
          <i class="iconfont iconqianjin"></i>
        </div>
      </div>
      <div class="content">
        <ul class="album-list">
          <li class="item" v-for="item in albumList" :key="item.aid" @click="navToAlbum(item.aid)">
            <img :src="item.newUrl" class="img" />
            <div class="title">
              <span class="text">{{item.title}}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Toast, Indicator, MessageBox } from 'mint-ui'

import TechnicalSupport from '../../components/TechnicalSupport'
export default {
  components: {
    'technical-support': TechnicalSupport
  },
  data () {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || '1837',
      version: '1.0.0',
      type_: 'label',
      backUrl: 'nihewanImpression',
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      open_id: localStorage.getItem('open_id_' + this.$route.query.id)
        ? localStorage.getItem('open_id_' + this.$route.query.id)
        : '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      // 请求数据

      activityList: [],
      booksList: [],
      menu: [], // 后台返回的目录
      menuList: [
        {
          id: 0,
          name: '景点',
          icon: 'iconscene',
          type: 'spots'
        },
        {
          id: 1,
          name: '人文',
          icon: 'iconshanzi',
          type: 'history'
        },
        {
          id: 2,
          name: '电子书',
          icon: 'iconbook1',
          type: 'books'
        },
        {
          id: 3,
          name: '视频',
          icon: 'iconvideo2',
          type: 'video'
        },
        {
          id: 4,
          name: '科普答题',
          icon: 'iconbianji1',
          type: 'question'
        },
        {
          id: 5,
          name: '科普活动',
          icon: 'iconkepuzhishi',
          type: 'activity'
        },
        {
          id: 6,
          name: '相册',
          icon: 'iconcollections',
          type: 'album'
        }
      ],
      questionJoinNumbers: '',
      questionList: [],
      questionUserList: [],
      questionUserNumbers: '',
      rootList: [],
      spotsList: [],
      spotsSwipeList: [],
      videoList: [],
      albumList: [],
      shareInfo: {},
      // 自定义属性
      bannerImgUrl: '',
      questionExample: {},
      isLoading: false,
      loaded: false,

      // 联系信息
      companyInfo: {},
      haveCompany: false
    }
  },
  mounted () {
    if (this.token) {
      // 已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id_' + this.id, this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.id, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.id, '')
      }
    }
    if (!this.open_id) {
      this.authorize()
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }

    this.getData()
    this.getCompanyInfo(this.id, this.token).then(
      res => {
        this.companyInfo = res
        this.haveCompany = true
      },
      err => {
        console.log(err)
      }
    )
    this.getAlbum()
  },
  methods: {
    // 返回首页
    backHome () {
      this.$router.push({
        path: '/nihewan/home',
        query: {
          id: this.id
        }
      })
    },
    // 底部导航
    handleMenu (type) {
      if (type === 'my') {
        this.$router.push({
          path: '/nihewan/my',
          query: {
            id: this.id
          }
        })
      } else if (type === 'map') {
        this.$router.push({
          path: '/nihewan/map',
          query: {
            id: this.id
          }
        })
      }
    },
    // 相册
    navToAlbum (aid) {
      this.$router.push({
        path: '/album/album',
        query: {
          id: this.id,
          aid: aid
        }
      })
    },
    // 活动详情
    navToActivity (id) {
      this.$router.push({
        path: '/nihewan/activity',
        query: {
          id: this.id,
          aid: id
        }
      })
    },
    // 电子书
    navToBook (id) {
      this.$router.push({
        path: '/nihewan/book',
        query: {
          id: this.id,
          aid: id
        }
      })
    },
    navToRoot (id) {
      this.$router.push({
        path: '/nihewan/root',
        query: {
          id: this.id,
          aid: id
        }
      })
    },
    // 景点详情
    navToSpot (aid) {
      this.$router.push({
        path: '/nihewan/spot',
        query: {
          id: this.id,
          aid
        }
      })
    },
    // 查看更多
    toMore (type) {
      let url = ''
      if (type === 'spots') {
        url = '/nihewan/spotsList'
      } else if (type === 'history') {
        url = '/nihewan/rootList'
      } else if (type === 'books') {
        url = '/nihewan/booksList'
      } else if (type === 'video') {
        url = '/nihewan/videoList'
      } else if (type === 'question') {
        url = '/nihewan/questionIndex'
      } else if (type === 'activity') {
        url = '/nihewan/activityList'
      } else if (type === 'album') {
        url = '/nihewan/albumList'
      } else {
        return false
      }
      this.$router.push({
        path: url,
        query: {
          id: this.id
        }
      })
    },
    // 获取相册
    getAlbum () {
      this.$ajax(
        'post',
        'weixin/get_image_list',
        {
          park_id: this.park_id,
          token: this.token,
          page: 1,
          where: 'head',
          version: this.version
        },
        res => {
          if (res.data.id === 0) {
            this.albumList.push(
              ...res.data.data.filter((item, i) => {
                return i < 5
              })
            )
          }
        }
      )
    },
    // 获取数据
    getData () {
      if (this.token) {
        Indicator.open('加载中...')
        this.isLoading = true
        this.$ajax(
          'post',
          'Label/get_science_data',
          {
            park_id: this.park_id,
            id_: this.id,
            id_type_: this.type_,
            where_: 'all', // spots, history, books, video, question, activity
            page: 1,
            token: this.token,
            version: this.version
          },
          res => {
            if (res.data.id == 0) {
              this.activityList.push(...res.data.data.activity_list)
              this.booksList.push(...res.data.data.books_list)
              this.menu.push(
                ...res.data.data.menu.filter(item => {
                  return item.is_show !== 0
                })
              )
              this.questionList.push(...res.data.data.question_list)
              this.questionUserList.push(...res.data.data.question_user_list)
              this.rootList.push(...res.data.data.root_list)
              this.spotsList.push(...res.data.data.spots_list)
              this.videoList.push(...res.data.data.video_list)
              this.questionJoinNumbers =
                res.data.data.question_join_numbers || ''
              this.questionUserNumbers =
                res.data.data.question_user_numbers || ''
              Object.assign(this.shareInfo, res.data.data.share)
              // 自定义
              this.bannerImgUrl = this.spotsList[1].baseUrl
              this.questionExample = this.questionList.filter(item => {
                return item.type_ == 'text'
              })[0]

              const sUrl = window.location.href,
                wx_title = this.shareInfo.title,
                wx_content = this.shareInfo.shareContent,
                wx_img = this.shareInfo.shareImage
              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                })
              }
            }

            this.isLoading = false
            if (!this.loaded) {
              this.loaded = true
            }
            Indicator.close()
          }
        )
      }
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=' +
        this.id +
        '&token=' +
        this.token +
        '&state=' +
        this.backUrl
      window.location.href = open_url
    }
  }
}
</script>

<style scoped>
.container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 3rem;
  background-color: #fff;
}

.backHome {
  position: fixed;
  top: 1rem;
  left: 1rem;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  text-align: center;
  line-height: 2rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  z-index: 3;
  cursor: pointer;
}

.footer-menu {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 2.8rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 -2px 2px -2px rgba(0, 0, 0, 0.2);
}

.footer-menu > .item {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 0.74rem;
  cursor: pointer;
}

.footer-menu > .item > .iconfont {
  font-size: 1rem;
}

.footer-menu > .active {
  color: #40d9ff;
}

/* banner */
.banner {
  position: relative;
  width: 100%;
}

.banner > .img {
  display: block;
  width: 100%;
  height: 56vw;
  object-fit: cover;
}

.banner > .text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  color: #fff;
  text-shadow: -1px 1px 1px #000;
  font-size: 0.88rem;
}

/* menu */
.menu {
  margin-top: 0.5rem;
  width: 100%;
}

.menu-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.menu-list > .item {
  width: 25%;
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menu-list > .item:hover {
  transform: scale(1.02);
  transition: 0.3s;
}

.menu-list > .item > .iconfont {
  font-size: 1.5rem;
  color: #333;
}

.menu-list > .item > .text {
  margin-top: 0.4rem;
  font-size: 0.74rem;
  color: #666;
}

/* main */
.main {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.main > .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.main > .header > .title {
  position: relative;
  font-size: 1rem;
  padding-left: 8px;
}

.main > .header > .title::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e6a23c;
}

.main > .header > .more {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.main > .header > .more > .text {
  font-size: 0.74rem;
  font-weight: bold;
  color: #999;
}

.main > .header > .more > .iconfont {
  font-size: 0.8rem;
  color: #999;
}

.main > .content {
  width: 100%;
  margin-top: 1rem;
}

/* spots */
.spots-list {
  width: 100%;
  height: 44vw;
  cursor: pointer;
}

.spots-list .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.spots-list .title {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spots-list .title > .text {
  color: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
}

/* mint-swipe-indicator */

.spots-list >>> .mint-swipe-indicator {
  width: 12px;
  height: 4px;
  border-radius: 0;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.6);
}

.spots-list >>> .mint-swipe-indicator.is-active {
  background-color: #409eff;
}

/* root */
.root > .content {
  overflow-x: auto;
  padding-bottom: 0.5rem;
}

.root-list {
  display: inline-flex;
}

.root-list > .item {
  position: relative;
  width: 40vw;
  height: 60vw;
  margin-right: 0.5rem;
  cursor: pointer;
}

.root-list > .item:last-child {
  margin-right: 0;
}

.root-list > .item > .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.root-list > .item > .title {
  position: absolute;
  padding: 0.5rem;
  width: 100%;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  font-size: 0.8rem;
  text-shadow: -1px 1px 1px #000;
}

/* books */
.books > .content {
  overflow-x: auto;
  padding-bottom: 0.5rem;
}

.books-list {
  display: inline-flex;
}

.books-list > .item {
  margin-right: 0.8rem;
  position: relative;
  width: 30vw;
  cursor: pointer;
}

.books-list > .item:last-child {
  margin-right: 0;
}

.books-list > .item > .img {
  display: block;
  width: 30vw;
  height: 42vw;
  object-fit: cover;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

/* video */
.video-iframe {
  width: 100%;
  height: calc((100vw - 2rem) * 9 / 16);
}

/* question */

.question-bg {
  display: block;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
}

/* activity */
.activity > .content {
  overflow-x: auto;
  padding-bottom: 0.5rem;
}

.activity-list {
  display: inline-flex;
}

.activity-list > .item {
  position: relative;
  margin-right: 0.5rem;
  width: 40vw;
  height: 28vw;
  cursor: pointer;
}

.activity-list > .item:last-child {
  margin-right: 0;
}

.activity-list > .item > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.activity-list > .item > .title {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  text-shadow: -1px 1px 1px #000;
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 相册 */
.album > .content {
  overflow-x: auto;
  padding-bottom: 0.5rem;
}

.album-list {
  display: inline-flex;
}

.album-list > .item {
  position: relative;
  width: 40vw;
  height: 48vw;
  margin-right: 0.5rem;
  cursor: pointer;
}

.album-list > .item:last-child {
  margin-right: 0;
}

.album-list > .item > .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.album-list > .item > .title {
  position: absolute;
  padding: 0.5rem;
  width: 100%;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  font-size: 0.8rem;
  text-shadow: -1px 1px 1px #000;
}

.company {
  position: relative;
  padding-bottom: 1rem;
  color: rgba(0, 0, 0, 0.5);

  /* text-shadow: 1px 1px 1px rgba(55, 55, 55, 0.1); */
  text-align: center;
  font-size: .74rem;
}

</style>
