<template>
    <div class="input-wrap f12" id="serviceMenu"  style="padding:0;">
        <router-link :to="titlelink" class="menuList">
            <mt-cell  is-link>
                <div slot="title">
                    <i class="need"></i>
                    <span>标题</span>
                </div>
                {{title}}
            </mt-cell>
        </router-link>

        <router-link :to="parker" class="menuList">
            <mt-cell  is-link>
                <div slot="title">
                    <i class="need"></i>
                    <span>选择公园</span>
                </div>
                {{parkName}}
            </mt-cell>
        </router-link>

        <router-link :to="taglink" class="menuList">
            <mt-cell  is-link>
                <div slot="title">
                    <i class="need"></i>
                    <span>分类标签</span>
                </div>
                {{cateName}}
            </mt-cell>
        </router-link>

        <router-link :to="featurelink" class="menuList">
            <mt-cell   is-link>
                <div slot="title">
                    <i class="need"></i>
                    <span>游玩亮点</span>
                </div>
                {{funy}}
            </mt-cell>
        </router-link>



        <router-link :to="tracklink" class="menuList">
            <mt-cell  is-link>
                <div slot="title">
                    <i class="need"></i>
                    <span>载入轨迹</span>
                </div>
                {{record_title}}
            </mt-cell>
        </router-link>


        <router-link :to="detail" class="menuList">
            <mt-cell  is-link>
                <div slot="title">
                    <i class="need"></i>
                    <span>游玩详情</span>
                </div>
                {{vdetail}}
            </mt-cell>
        </router-link>


        <router-link :to="Note" class="menuList">
            <mt-cell  is-link>
                <div slot="title">
                    <i class="need"></i>
                    <span>服务说明</span>
                </div>
                {{vservice}}
            </mt-cell>
        </router-link>

    </div>
</template>

<style>
    .menuList{
        box-sizing: border-box;
        color: inherit;
        min-height: 48px;
        display: block;
        border-top:1px solid #f2f2f2;
    }

    #serviceMenu .mint-cell-value{
        width: 50%;
    }
</style>

<script>
    export default {
        data(){
            return{
                imglink:'',
                titlelink:'',
                taglink:'',
                featurelink:'',
                tracklink:'',
                detail:'',
                Note:'',
                parker:'',

                title:localStorage.title,
                cateName:'',
                labelName:localStorage.labelName,
                parkName:localStorage.park_name,
                funy:localStorage.funy,
                record_title:localStorage.record_title,
                vdetail:'',
                vservice:''
            }
        },
        mounted(){
            this.ServiceMenu();
        },
        methods:{
            ServiceMenu(){
                var stype = this.$route.query.type;

                if(localStorage.cateName){
                    this.cateName = '已填';
                }

                if(localStorage.route && localStorage.notes){
                    this.vdetail = '已填';
                }

                if(localStorage.service_number && localStorage.km && localStorage.time_length
                   && localStorage.time_slot && localStorage.lang_name){
                    this.vservice = '已填';
                }


                this.titlelink = '/footguide/GuideTitle?type=' + stype;
                this.taglink = '/footguide/CategoryTags?type=' + stype;
                this.featurelink = '/footguide/GuideFeatures?type=' + stype;
                this.tracklink = '/footguide/LoadingTrack?type=' + stype;
                this.detail = '/footguide/VisitDetail?type=' + stype;
                this.Note = '/footguide/ServiceNote?type=' + stype;
                this.parker = '/footguide/ChoosePark?type='+stype;
            }
        }
    }
</script>