<template>
  <div class="RedPacketMap" v-title data-title="红包地图">
    <mt-header fixed title="红包地图">
      <a slot="left">
        <mt-button @click.native="back()" icon="back"></mt-button>
      </a>
      <mt-button slot="right" @click="click_GiveRabPacket">
        <a>
          <img src="../../assets/img/red_packet_icon.png" alt />发红包
        </a>
      </mt-button>
    </mt-header>
    <div class="page-content">
      <div v-show="is_packet_list_box">
        <ul class="packet_park_list" v-if="is_packet_park_list">
          <li class="clean" @click="is_packet_park_list = false">
            <img src="../../assets/img/search_clean.png" alt />
          </li>
          <li v-for="(k,i) in packet_park_list" :key="i">
            <div class="is_show_box" @click="click_is_show(k.label_id,k.id,i)">
              <span :class="{active:k.is_show}">显示</span>
              <span :class="{active:!k.is_show}">隐藏</span>
            </div>
            <div class="park_name">{{k.park_name}}</div>
            <div class="overall_money">
              <span>总金额</span>
              <br />
              <span>{{k.input_money}}</span>
            </div>
            <div class="surplus_number">
              <span>剩余个数</span>
              <br />
              <span>{{k.red_number}}</span>
            </div>
            <div class="probability">
              <span>概率</span>
              <br />
              <span>{{k.percent}}%</span>
            </div>
            <div class="grab" @click="rob_red(packet_park_list[i])">
              <img src="../../assets/img/red_packet_grab.png" alt />
            </div>
          </li>
        </ul>
        <ul class="packet_list_box" v-if="!is_packet_park_list">
          <li>
            <img @click="show_packet_park_list(154)" src="../../assets/img/footprint_red.png" alt />
          </li>
          <li>
            <img
              @click="show_packet_park_list(155)"
              src="../../assets/img/footprint_coupon.png"
              alt
            />
          </li>
          <li>
            <img
              @click="show_packet_park_list(156)"
              src="../../assets/img/footprints_gifts.png"
              alt
            />
          </li>
        </ul>
      </div>
      <div id="container" class="zoom" style="width:100%; height:100%"></div>
      <div class="left_but">
        <div class="mak_tab">
          <img
            src="../../assets/img/map_3d.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="is_tab_map_3d"
          />
          <img
            src="../../assets/img/map_3d_yes.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="!is_tab_map_3d"
          />
        </div>
        <div class="mak_tab">
          <!--地图 + -  -->
          <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
          <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
        </div>
      </div>
      <div class="location" v-if="islocation">
        <img src="../../assets/img/map_location.png" alt />
        <span>{{location_name}}</span>
      </div>
    </div>
    <div class="popup_box" v-if="is_popup">
      <div class="popup">
        <img src="../../assets/img/footprints_delete.png" class="remove" alt @click="popup" />
        <div v-if="lottery==1">
          <img
            class="red_pack"
            src="../../assets/img/footprint_open_red.png"
            alt
            v-if="popup_info.type==154||popup_info.label_id==154"
            @click="open_pack(popup_info.id,popup_info.label_id)"
          />
          <img
            class="red_pack"
            src="../../assets/img/footprint_open_coupon.png"
            alt
            v-if="popup_info.type==155||popup_info.label_id==155"
            @click="open_pack(popup_info.id,popup_info.label_id)"
          />
          <img
            class="red_pack"
            src="../../assets/img/footprint_open_gifts.png"
            alt
            v-if="popup_info.type==156||popup_info.label_id==156"
            @click="open_pack(popup_info.id,popup_info.label_id)"
          />
        </div>
        <div class="popup_2" v-show="lottery ==2">
          <p>很遗憾，没有中奖哦！</p>
          <router-link to="/">
            <img src="../../assets/img/footprints_button.png" alt />
          </router-link>
        </div>
        <div class="popup_info">
          <img class="fl" :src="popup_info.park_logo" alt />
          <div>
            <p>{{popup_info.park_name}}</p>
            <p>
              <span>红包:{{popup_info.red_number}}个</span>
              <span>人数:{{popup_info.user_number}}个</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
import { MessageBox } from "mint-ui";
import { Indicator } from "mint-ui";
export default {
  name: "amap-page",
  data() {
    return {
      map: {},
      info: {},
      is_popup: false,
      popup_info: {},
      spot_id: this.$route.query.spot_id,
      lottery: 1,
      user_id: this.$route.query.user_id,
      my_id: "",
      red_packet_list: [], //公园红包数据
      coupon_list: [], //公园卷数据
      goods_list: [], //公园礼物数据
      title: this.$route.query.title,
      Lng: "",
      Lat: "",
      location_tit: "",
      islocation: false,
      location_name: "",
      is_packet_park_list: false, //是否显示列表
      packet_park_list: [], //列表数据
      is_packet_list_box: false,
      park_id: [],
      red_Marker_list: [],
      is_tab_map_3d: true //3D地图按钮是否点击
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      if (!this.check_login()) {
        //                    MessageBox.alert("请先登陆").then(action => {
        //                        if (action == 'confirm') {
        //                            this.$router.push({path: '/my/login', query: {log_type: 1}})
        //                        }
        //                    })
        //                    return false;
      } else {
        this.my_id = this.check_login().user_id;
      }

      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      var that = this;
      //初始化地图对象，加载地图
      this.map = this.map_init();
      //                定位
      var data = {
        lng: that.$route.query.r_lng,
        lat: that.$route.query.r_lat,
        spots_name: that.title,
        id: that.spot_id
      };
      this.map_add_Marker_1(this.map, data, "", true, "RedPacket");

      //                       获取所发布足迹经纬度的地址信息  begin
      this.map_getAddress(
        this.map,
        that.$route.query.r_lng,
        that.$route.query.r_lat,
        function(address) {
          that.location_name = address;
        }
      );
      this.mapZoom();
    },
    //            页面加载请求数据
    mapZoom() {
      var that = this;
      that.islocation = true;
      that.is_packet_list_box = true;

      that.red_packet_info("", function(res) {
        that.red_packet_list = res.data.data.park_number.red_packet_list;
        that.coupon_list = res.data.data.park_number.coupon_list;
        that.goods_list = res.data.data.park_number.goods_list;
        add_is_show(that.red_packet_list);
        add_is_show(that.coupon_list);
        add_is_show(that.goods_list);
        that.red_packet_data_drawing(res.data.data.red_packet);
      });
      function add_is_show(list) {
        for (var i = 0; i < list.length; i++) {
          that.$set(list[i], "is_show", true);
        }
      }
    },
    back() {
      Indicator.close();
      this.$router.go(-1);
    },
    //            渲染地图上的红包标记
    red_packet_data_drawing(data) {
      var that = this;
      //                    type = 154(现金) type = 155(优惠券) type = 156(实物礼品)
      this.map_add_Marker({
        map: this.map,
        list_data: data,
        callback: Marker_arr => {
          this.red_Marker_list = Marker_arr;
        },
        click_callback: ExtData => {
          if (ExtData.open == 0) {
            Toast("赶快发布足迹领取红包吧！");
          } else {
            that.is_popup = true;
            that.popup_info = ExtData;
          }
        },
        clearMap: true
      });
    },
    //            点击抢红包
    open_pack(red_packet_id, label_id) {
      var data = {},
        path_data;
      if (label_id) {
        data = {
          user_id: this.user_id,
          my_id: this.my_id,
          spot_id: this.spot_id,
          red_packet_id: 0,
          page: 1,
          park_id: red_packet_id
        };
        path_data = "rob_red";
      } else {
        data = {
          user_id: this.user_id,
          my_id: this.my_id,
          spot_id: this.spot_id,
          red_packet_id: red_packet_id,
          page: 1
        };
      }
      this.$ajax("post", "foot/click_packet", data, res => {
        if (res.data.id == 0) {
          if (res.data.data.packet.red_packet != 0) {
            this.$router.push({
              path: "/my/MyAwardInfo",
              query: {
                spot_id: this.spot_id,
                type: 1,
                id: red_packet_id,
                label_id: res.data.data.packet.label_id,
                path_data: path_data
              }
            });
          } else {
            this.lottery = 2;
          }
        } else {
          this.lottery = 2;
        }
      });
    },
    //            点击公园列表随机抢
    rob_red(data) {
      this.is_popup = true;
      this.popup_info = data;
    },
    //            点击X ，关闭抢红包
    popup() {
      this.is_popup = false;
      this.lottery = 1;
    },
    //            点击左侧
    show_packet_park_list(label_id) {
      this.packet_park_list = [];
      this.is_packet_park_list = true;
      if (label_id == 154 && this.red_packet_list.length != 0) {
        this.packet_park_list = this.red_packet_list;
      } else if (label_id == 155 && this.coupon_list.length != 0) {
        this.packet_park_list = this.coupon_list;
      } else if (label_id == 156 && this.goods_list.length != 0) {
        this.packet_park_list = this.goods_list;
      } else {
        this.is_packet_park_list = false;
      }
    },
    //            点击切换是否显示隐藏公园红包    重新请求数据
    click_is_show(label_id, id, i) {
      var that = this;
      if (label_id == 154) {
        this.$set(
          this.red_packet_list[i],
          "is_show",
          !this.red_packet_list[i].is_show
        );
        arr_splice(this.red_packet_list[i].is_show, id);
      } else if (label_id == 155) {
        this.$set(this.coupon_list[i], "is_show", !this.coupon_list[i].is_show);
        arr_splice(this.coupon_list[i].is_show, id);
      } else if (label_id == 156) {
        this.$set(this.goods_list[i], "is_show", !this.goods_list[i].is_show);
        arr_splice(this.goods_list[i].is_show, id);
      }
      function arr_splice(is_show, id) {
        if (is_show) {
          var index = getIndex(that.park_id, id);
          if (index == -1) return;
          that.park_id.splice(index, 1);
        } else {
          that.park_id.push(id);
        }
        that.red_packet_info(that.park_id.join(","));
      }

      function getIndex(arr, item) {
        if (!arr.length || !item) return -1;
        var i = 0,
          len = arr.length;
        for (; i < len; i++) {
          if (arr[i] == item) return i;
        }
        return -1;
      }
    },
    red_packet_info(park_id, callback) {
      var that = this;
      if (that.red_Marker_list.length > 0) {
        this.map.clearMap();
      }
      var query = {
        park_id: park_id,
        l_lng: that.$route.query.r_lng,
        l_lat: that.$route.query.r_lat,
        type: 1,
        user_id: that.user_id,
        my_id: that.my_id,
        spot_id: that.spot_id
      };
      that.$ajax("post", "foot/get_red_packet", query, res => {
        if (res.data.id == 0) {
          that.red_packet_data_drawing(res.data.data.red_packet);
          if (typeof callback === "function") {
            callback(res);
          }
        } else {
          Toast("没有数据");
          //                        that.Go_to_here()
        }
      });
    },
    //            点击发红包 未登录提示登录
    click_GiveRabPacket() {
      if (!this.check_login()) {
        //                    Toast("请先登陆");
        //                    MessageBox("请先登陆").then(action => {
        //                        if (action == 'confirm') {
        //                            localStorage.setItem('login_url', location.hash);
        //                            this.$router.push({path: '/my/login'})
        //                            return;
        //                        }
        //                    })
        MessageBox({
          title: "提示",
          message: "请先登陆",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
            return;
          }
        });
      } else {
        Indicator.close();
        this.$router.push({ path: "/footprints/GiveRabPacket" });
      }
    }
  }
};
</script>
<style scoped>
.RedPacketMap {
  height: 100%;
}

.RedPacketMap .page-content {
  height: 100%;
  padding-bottom: 0;
  box-sizing: border-box;
}

.RedPacketMap .popup_box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  text-align: center;
}

.RedPacketMap .popup_box .popup {
  width: 100%;
  display: inline-block;
  margin-top: 40%;
  position: relative;
}

.RedPacketMap .popup_box .popup .remove {
  display: inline-block;
  position: absolute;
  right: 10%;
  width: 40px;
  top: 0;
}

.RedPacketMap .popup_box .popup .red_pack {
  width: 80%;
  display: inline-block;
}

.RedPacketMap .popup_box .popup .popup_info {
  display: inline-block;
  width: 70%;
  height: 50px;
  background-color: #fff;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: left;
}

.RedPacketMap .popup_box .popup .popup_info img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 7px 10px;
  display: inline-block;
}

.RedPacketMap .popup_box .popup .popup_info > div {
  overflow: hidden;
}

.RedPacketMap .popup_box .popup .popup_info p {
  font-size: 12px;
  color: #ccc;
  display: block;
  height: 24px;
  line-height: 24px;
}

.RedPacketMap .popup_box .popup .popup_info p span:nth-of-type(1) {
  margin-right: 10px;
}

.RedPacketMap .popup_box .popup_2 {
  background: url(../../assets/img/footprints_no_winning.png) 50% 0 no-repeat;
  width: 100%;
  padding: 40% 10% 10%;
  background-size: 80%;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
}

.RedPacketMap .popup_box .popup_2 img {
  width: 40%;
  display: inline-block;
  margin-top: 20px;
}

.RedPacketMap .el-vue-amap-container,
.RedPacketMap .el-vue-amap-container .el-vue-amap {
  height: 100%;
}

.RedPacketMap .amap-container img {
  display: inline-block;
  width: 100%;
}

.RedPacketMap .top_shade {
  width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
  padding: 0 10px;
  box-sizing: border-box;
}

.location {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  height: 1.6rem;
  z-index: 1;
  border-top: 1px solid #f2f2f2;
  padding: 0.2rem 0.8rem;
  display: flex;
  font-size: 0.7rem;
  overflow: hidden;
}

.location img {
  display: inline-block;
  height: 1.4rem;
  vertical-align: sub;
}

.location span {
  line-height: 1.6rem;
  height: 1.6rem;
  margin-left: 0.5rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.RedPacketMap .mint-button-text a {
  color: #fff;
  font-size: 0.6rem;
}

.RedPacketMap .mint-button-text img {
  height: 18px;
  margin: 0 auto;
  display: block;
}

.packet_list_box {
  position: absolute;
  top: 3rem;
  left: 0.5rem;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 1;
  padding: 0 10px;
  border: 1px solid #f2f2f2;
}

.packet_list_box li {
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
}

.packet_list_box li:last-child {
  border: none;
}

.packet_list_box li img {
  width: 1.3rem;
}

.packet_park_list {
  width: 95%;
  position: absolute;
  top: 3rem;
  left: 2.5%;
  right: 2.5%;
  z-index: 1000;
  color: #fff;
  font-size: 0.6rem;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: rgba(255, 154, 128, 0.7);
  padding: 4px 0;
}

.packet_park_list li.clean {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  margin: 0;
  padding: 0;
  border: none;
}

.packet_park_list li.clean img {
  width: 100%;
}

.packet_park_list li {
  box-sizing: border-box;
  margin: 0 8px;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.packet_park_list li:last-child {
  border: none;
}

.packet_park_list li > div {
  display: inline-block;
  margin: 0 0.5%;
  text-align: center;
}

.packet_park_list .is_show_box {
  width: 21%;
  background-color: #fff;
  border-radius: 20px;
  height: 30px;
  padding: 2px;
}

.packet_park_list .is_show_box span.active {
  color: #fff;
  background-color: rgba(255, 154, 128, 0.7);
}

.packet_park_list .is_show_box > span {
  color: #ff9a80;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  width: 47%;
  text-align: center;
  border-radius: 15px;
}

.packet_park_list .park_name {
  width: 25%;
}

.packet_park_list .overall_money {
  width: 13%;
}

.packet_park_list .surplus_number {
  width: 17%;
}

.packet_park_list .probability {
  width: 9%;
}

.packet_park_list .grab {
  width: 7%;
}

.packet_park_list .grab img {
  width: 100%;
}
</style>