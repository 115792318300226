<template>
    <div v-if="items.length != 0">
        <div class="guide-feed-item" v-for="(item, i) in items" :key="i">
            <!-- 用户信息 -->
            <div class="feed-item-top">
                <router-link :to="{ name: 'GuidePage', params: { id: item.user_id }}" class="avatar fl">
                    <img class="img" :src='item.user_img'/>
                </router-link>
                <h1>
                    <router-link :to="{ name: 'GuidePage', params: { id: item.user_id }}" class="name">
                        {{item.name}}
                    </router-link>
                </h1>
                <h2><span class="time">{{item.add_time}} 发布向导</span></h2>
            </div>
            <router-link :to="{ name: 'GuideDetail', params: { id: item.id ,verify:0}}" class="guide-item-img">
                <div class="img-tag">{{item.type_name}}</div>
                <img class="img" v-lazy='item.img'/>
            </router-link>
            <div class="guide-item-tit">{{item.title}}</div>
            <div class="guide-item-tag">
                <a class="tag-track no" v-if="item.notes_id == 0">无轨迹</a>
                <a class="tag-track" v-else>有轨迹</a>
                <a class="tag-img" v-if="item.car_status == 1"><img src="../assets/img/tour_havecar.png"></a>
                <a class="tag-img" v-else><img src="../assets/img/tour_havecar_no.png"></a>
                <a class="tag-img" v-if="item.card_status == 1"><img src="../assets/img/tour_tourist.png"></a>
                <a class="tag-img" v-else><img src="../assets/img/tour_tourist_no.png"></a>
                <a v-for="(label,i) in item.label" :key="i">#{{label.label_name}}#</a>
            </div>
        </div>
        <div class="foot_noinfo" v-if="items.length <= 0">
            <img src="../assets/img/foot_noinfo@2x.png" alt="">
            <p>暂无数据</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['parkId', 'listIndex'],
        data() {
            return {
                items: []
            }
        },
        mounted(){
            this.getGuideItems();
        },
        methods: {
            parentMsg: function (data) {
                this.items = data.parkinfo
            },
            getGuideItems(){

            }
        }
    }
</script>

<style scoped>
    .guide-feed-item {
        background: #fff;
        padding: 10px;
        border-bottom: 3px solid #f2f2f2;
    }

    .guide-feed-item .feed-item-top {
        padding: 0 0 5px;
    }

    .guide-item-img {
        position: relative;
        display: block;
    }

    .guide-item-img .img {
        width: 100%;
        /*padding-top: 52%;*/
        background-color: #f2f2f2;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 6px;
    }

    .guide-item-img .img-tag {
        position: absolute;
        top: 0;
        right: 0;
        width: 3rem;
        height: 1.2rem;
        line-height: 1.2rem;
        text-align: center;
        font-size: .6rem;
        color: #fff;
        border-radius: 0 .3rem;
        background: linear-gradient(30deg, #59f18b 0%, #25b7ec 50%, #2e6fb7 100%);
    }

    .guide-item-tit {
        padding: .2rem 0 0;
        font-size: .8rem;
    }

    .guide-item-tag {
        padding-top: .2rem;
        overflow: hidden;
    }

    .guide-item-tag a {
        font-size: .7rem;
        line-height: 1rem;
        color: #01cbbf;
        float: left;
        margin-right: .5rem;
        padding: .2rem 0;
    }

    .guide-item-tag .tag-track {
        background: url(../assets/img/tour_track.png) left center no-repeat;
        background-size: auto 75%;
        padding-left: 1.3rem;
    }

    .guide-item-tag .tag-track.no {
        background-image: url(../assets/img/tour_track_no.png);
        color: #999;
    }

    .guide-item-tag .tag-img img {
        width: auto;
        height: 1rem;
    }
</style>