<template>
  <div class="check_body">
    <div class="head">
      <div class="left_input">
        <input type="number" placeholder="请输入手机号码" v-model="tel" />
        <input type="text" placeholder="请输入6位核销码" v-model="code" />
      </div>
      <div class="right_button">
        <button type="button" @click="search">查询</button>
      </div>
    </div>
    <div class="list" v-if="list">
      <div class="item" v-for="(k,i) in list" :key="i">
        <div class="left_">
          <img :src="k.newUrl" />
        </div>
        <div class="meddle_">
          <div class="code">
            <span>{{k.code}}</span>
          </div>
          <div class="title">
            <span>{{k.title}}</span>
          </div>
          <div class="price">
            <span>{{k.amount}}|{{k.is_pay_text}}|{{k.is_status_text}}</span>
          </div>
          <div class="add_time">
            <span>{{k.add_time}}</span>
          </div>
        </div>
        <div class="right_">
          <div class="status" v-if="k.show_btn" @click="modify_status(k.id,k.code,k.is_gift)">
            <span>核销</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
export default {
  components: {},
  data() {
    return {
      tel: "",
      code: "",
      park_id: this.$route.query.park_id > 0 ? this.$route.query.park_id : 0,
      list: [],
      token: "zmlxj_20198",
      user_id: ""
    };
  },
  watch: {},
  mounted() {},
  methods: {
    search() {
      var tel = this.tel;
      var code = this.code;
      var token = this.token;
      var url = "Ticket/search";
      if (!this.check_login()) {
        /*
                    MessageBox({
                        title: '提示',
                        message: '确认登陆吗？',
                        showCancelButton: true
                    }).then(action => {
                        if (action == 'confirm') {
                            this.get_User_Id()
                        }
                    })*/
        localStorage.setItem("login_url", location.hash);
        this.$router.push({ path: "/my/login", query: { log_type: 1 } });
      } else {
        var user_id = this.check_login().user_id;
        if (tel && code && token && user_id) {
          this.$ajax(
            "post",
            url,
            { tel: tel, code: code, token: token, user_id: user_id },
            ret => {
              if (ret.data.id == 0) {
                this.list = ret.data.data;
              } else {
                Toast("无数据");
              }
            }
          );
        } else {
          Toast("少值不能查询");
        }
      }
    },
    modify_status(id, code, is_gift) {
      var id = id;
      if (!this.check_login()) {
        /*
                    MessageBox({
                        title: '提示',
                        message: '确认登陆吗？',
                        showCancelButton: true
                    }).then(action => {
                        if (action == 'confirm') {
                            this.get_User_Id()
                        }
                    });
                    */
        localStorage.setItem("login_url", location.hash);
        this.$router.push({ path: "/my/login", query: { log_type: 1 } });
      } else {
        var user_id = this.check_login().user_id;
        if (user_id && id) {
          this.$ajax(
            "post",
            "ticket/check_status",
            {
              id: id,
              user_id: user_id,
              code: code,
              is_gift: is_gift,
              token: "zmlxj_sas"
            },
            ret => {
              if (ret.data.id == 0) {
                window.location.reload();
              } else {
                Toast("核销失败");
              }
            }
          );
        } else {
          Toast("数据错误不能核销");
        }
      }
    }
  }
};
</script>

<style scoped>
.check_body {
  display: block;
  position: relative;
  float: left;
  margin-left: 1%;
  background-color: #f5f5f5;
  width: 98%;
  height: 100%;
  margin-top: 15%;
}
.check_body .left_input {
  position: relative;
  width: 70%;
  float: left;
}
.check_body .left_input input {
  border-radius: 4px;
  border: 1px solid #dedede;
  height: 45px;
  width: 100%;
}
.check_body .right_button {
  position: relative;
  width: 26%;
  float: left;
  margin-left: 2%;
}
.check_body .right_button button {
  display: block;
  width: 100%;
  height: 90px;
}

.check_body .list {
  margin-top: 2%;
}
.check_body .list .item {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 2%;
  background-color: white;
}
.check_body .list .item .left_ {
  position: relative;
  float: left;
  width: 35%;
}
.check_body .list .item .left_ img {
  width: 100%;
  height: 100px;
}
.check_body .list .item .meddle_ {
  position: relative;
  width: 45%;
  float: left;
}
.check_body .list .item .meddle_ .code {
  display: block;
}
.check_body .list .item .meddle_ .code span {
  display: block;
  padding: 4px;
  font-size: 1rem;
  text-align: left;
}
.check_body .list .item .meddle_ .title {
  display: block;
}
.check_body .list .item .meddle_ .title span {
  display: block;
  padding: 4px;
  font-size: 0.5rem;
  text-align: left;
}
.check_body .list .item .meddle_ .price {
  display: block;
}
.check_body .list .item .meddle_ .price span {
  display: block;
  padding: 4px;
  font-size: 0.8rem;
  text-align: left;
  color: red;
}
.check_body .list .item .meddle_ .add_time {
  display: block;
}
.check_body .list .item .meddle_ .add_time span {
  display: block;
  padding: 4px;
  font-size: 0.3rem;
  text-align: left;
}
.check_body .list .item .right_ {
  position: relative;
  width: 16%;
  float: left;
}
.check_body .list .item .right_ .status {
  width: 100%;
}
.check_body .list .item .right_ .status span {
  display: block;
  padding: 10px;
  text-align: center;
  background-color: red;
  margin-top: 50%;
  font-size: 1rem;
  color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
</style>