/**
 *  原生JS请求方法
 * @type {{
 *          init: xmlhttp.init   初始化,
 *          method: xmlhttp.method  非表单请求方法 ,
 *          formMethod: xmlhttp.formMethod  表单提交方法
 * }}
 */

var xmlhttp = {
    init: function() {
        var xmlhttp;
        if (window.XMLHttpRequest) {
            // IE7+, Firefox, Chrome, Opera, Safari 浏览器执行代码
            xmlhttp = new XMLHttpRequest();
        } else {
            // IE6, IE5 浏览器执行代码
            xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
        }
        return xmlhttp;
    },

    /**
     * 原生JS 非表单Ajax请求方法
     * @param method   GET/POST
     * @param url     请求数据接口
     * @param data    需要传输的值 Object/string
     * @param callback  回调函数
     * @param to_:api ,app//表示接口请求地址
     */

    method: function(method, url, data, callback,to_='api') {
        var xmlhttp = this.init();
        if(to_ == 'api')
        {
            var baseUrl = 'https://www.zmlxj.com/api.php/' + url;
        }
        else
        {
            var baseUrl = 'https://www.zmlxj.com/app.php/' + url;
        }
        

        var params = [];
        for (var key in data) {

            var param = data[key] ? data[key] : '' // 0 false null 都转换为了 ''
            params.push(key + '=' + param);

        }
        var postData = params.join('&');

        if (method.toUpperCase() === 'POST') {
            xmlhttp.open(method, baseUrl, true);
            xmlhttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
            xmlhttp.setRequestHeader('app', 'web');

            xmlhttp.send(postData);
        } else if (method.toUpperCase() === 'GET') {
            xmlhttp.open(method, baseUrl + '?' + postData, true);
            xmlhttp.setRequestHeader('app','web');

            xmlhttp.send(null);
        }

        xmlhttp.onreadystatechange = function() {
            var res = xmlhttp.responseText;
            if (xmlhttp.readyState == 4) {
                var ret = {
                    data: JSON.parse(res)
                };
                if (xmlhttp.status == 200) {
                    callback(ret);
                } else {
                    callback(ret);
                }
            }
        };
    },

    /**
     * 纯JS 表单提交请求方法 用于图片上传
     * @param url   数据接口名
     * @param data  表单数据
     * @param callback  回调函数
     */
    formMethod: function(url, data, callback) {
        var xhr = this.init();
        var baseUrl = 'https://www.zmlxj.com/api.php/' + url;
        // var baseUrl = 'https://192.168.0.112/zmlxj/api.php/' + url;
        xhr.open('post', baseUrl, true);
        xhr.send(data);
        xhr.onreadystatechange = function() {
            var res = xhr.responseText;
            if (xhr.readyState == 4) {
                if (xhr.status == 200) {
                    callback(JSON.parse(res));
                } else {
                    callback(JSON.parse(res));
                }
            }
        };
    }
};

module.exports = {
    xmlhttp: xmlhttp
};