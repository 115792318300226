<template>
  <div>
    <ServiceTitle></ServiceTitle>

    <div class="page-content">
      <div class="new-title-content">服务说明</div>
      <div class="input-wrap foot-guid-wrap" @click="numsVisible = true">
        <mt-cell to="javascript:;" is-link>
          <div slot="title">
            <i class="need"></i>
            <span>接待人数</span>
          </div>
          {{serverNumber}}
        </mt-cell>
      </div>
      <div class="input-wrap foot-guid-wrap">
        <mt-cell to="javascript:;" is-link @click.native="scopesVisible = true" size="large">
          <div slot="title">
            <i class="need"></i>
            <span>服务范围</span>
          </div>
        </mt-cell>
        <div class="selected-tips service" id="service_scope">
          <span>{{range}}{{km}}</span>
        </div>
      </div>
      <div class="input-wrap foot-guid-wrap" @click="durationsVisible = true">
        <mt-cell to="javascript:;" is-link>
          <div slot="title">
            <i class="need"></i>
            <span>服务时长</span>
          </div>
          {{time_length}}
        </mt-cell>
      </div>
      <div class="input-wrap foot-guid-wrap" @click="timesVisible = true">
        <mt-cell to="javascript:;" is-link>
          <div slot="title">
            <i class="need"></i>
            <span>服务时段</span>
          </div>
          {{time_slot}}
        </mt-cell>
      </div>
      <div class="input-wrap foot-guid-wrap" @click="languagesVisible = true">
        <mt-cell to="javascript:;" is-link>
          <div slot="title">
            <i class="need"></i>
            <span>服务语言</span>
          </div>
          {{lang}}
        </mt-cell>
      </div>
    </div>

    <mt-actionsheet :actions="nums" v-model="numsVisible" @click="getNum(this)"></mt-actionsheet>
    <div class="service-note-content">
      <mt-popup v-model="scopesVisible" position="bottom" class="mint-popup-4">
        <mt-picker
          :slots="scopeSlots"
          @change="OnRange"
          :visible-item-count="5"
          :show-toolbar="false"
        ></mt-picker>
      </mt-popup>
    </div>

    <mt-actionsheet :actions="durations" v-model="durationsVisible"></mt-actionsheet>
    <mt-actionsheet :actions="times" v-model="timesVisible"></mt-actionsheet>
    <mt-actionsheet :actions="languages" v-model="languagesVisible"></mt-actionsheet>
  </div>
</template>

<script>
import ServiceTitle from "../../components/ServiceTitle";

export default {
  name: "ServiceNote",
  components: {
    ServiceTitle
  },
  data() {
    return {
      numsVisible: false,
      scopesVisible: false,
      durationsVisible: false,
      timesVisible: false,
      languagesVisible: false,
      nums: [],
      scopeSlots: [
        {
          values: ["市内", "省内"]
        },
        {
          divider: true,
          content: "-"
        },
        {
          values: ["50KM", "100KM", "150KM", "200KM", "250KM"]
        }
      ],
      durations: [],
      times: [],
      languages: [],

      serverNumber: localStorage.service_number,
      range: localStorage.range,
      km: localStorage.km,
      time_length: localStorage.time_length,
      time_slot: localStorage.time_slot,
      lang: localStorage.lang_name
    };
  },
  mounted() {
    // 人数
    this.nums = [
      { name: "1-10", method: this.getNum },
      { name: "11-20", method: this.getNum },
      { name: "21-30", method: this.getNum },
      { name: "31-40", method: this.getNum },
      { name: "41-50", method: this.getNum }
    ];

    // 时长
    this.durations = [
      { name: "1-3", method: this.getTime },
      { name: "4-6", method: this.getTime },
      { name: "7-9", method: this.getTime },
      { name: "10-12", method: this.getTime },
      { name: "13-15", method: this.getTime },
      { name: "16-18", method: this.getTime },
      { name: "19-21", method: this.getTime },
      { name: "22-24", method: this.getTime }
    ];

    // 时段
    this.times = [
      { name: "1：00—5：00", method: this.getslot },
      { name: "5：00—8：00", method: this.getslot },
      { name: "8：00—11：00", method: this.getslot },
      { name: "11：00—13：00", method: this.getslot },
      { name: "13：00—17：00", method: this.getslot },
      { name: "17：00—19：00", method: this.getslot },
      { name: "19：00—20：00", method: this.getslot },
      { name: "20：00—24：00", method: this.getslot }
    ];

    // 语言
    this.languages = [
      {
        name: "英语",
        method: this.getLang,
        id: 1
      },
      {
        name: "中文",
        method: this.getLang,
        id: 2
      }
    ];
  },
  methods: {
    //得到服务人数
    getNum(ele) {
      //		        console.log(ele.name);
      this.serverNumber = ele.name;
      this.save_arr("service_number", ele.name);
    },

    // 范围
    OnRange(picker, value) {
      document.body.addEventListener(
        "touchmove",
        function(event) {
          event.preventDefault();
        },
        false
      );
      picker.setSlotValues(value[0], value[1]);
      this.range = value[0];
      this.km = value[1];
      var v = value[0] + value[1];
      //				console.log(value);
      this.save_arr("range_km", v);
    },

    // 得到服务时长
    getTime(ele) {
      this.time_length = ele.name;
      this.save_arr("time_length", ele.name);
    },

    // 得到服务时段
    getslot(ele) {
      this.time_slot = ele.name;
      this.save_arr("time_slot", ele.name);
    },

    // 得到语言类型
    getLang(ele) {
      this.lang = ele.name;
      this.save_arr("lang", ele.id);
      this.save_arr("lang_name", ele.name);
    }
  }
};
</script>
<style scoped>
.service span {
  border: none;
}
.service-note-content .mint-popup-bottom {
  width: 100%;
}
.service-note-content .picker-slot {
  width: 48%;
}
.service-note-content .picker-slot.picker-slot-divider {
  width: 5%;
}
</style>