<template>
  <div class="container">
    <div id="map"></div>
    <!-- 右侧按钮 -->
    <div class="right-btn">
      <!-- 缩放 -->
      <!-- <div class="zoom-control">
        <div class="up btn" @click="zoomControl('up')">
          <i class="iconfont iconjia"></i>
        </div>
        <div class="down btn" @click="zoomControl('down')">
          <i class="iconfont iconjian"></i>
        </div>
      </div>-->
      <!-- 定位 -->
      <div class="location" @click="handleLocation">
        <i class="iconfont icondingwei"></i>
      </div>
    </div>
    <!-- 左侧按钮 -->
    <div class="left-btn">
      <div class="share left-btn-item" @click="handleGenerateOptions"  v-if="isShowBtn">
        <i class="iconfont iconArtboardCopy"></i>
      </div>
      <div class="refresh left-btn-item" @click="handleRefresh">
        <i class="iconfont iconrefresh"></i>
      </div>

      <!-- <div class="search left-btn-item">
        <i class="iconfont iconsearch"></i>
      </div>-->
    </div>

    <!-- 顶部按钮 -->
    <div class="top-btn">
      <div class="top-btn-item detail-list" @click="navToDetailList">
        <i class="iconfont iconmenu"></i>
        <span class="name">列表</span>
      </div>
      <div class="top-btn-item detail-list" @click="navToMiniHome">
        <i class="iconfont iconxuanzhongshangcheng"></i>
        <span class="name">主页</span>
      </div>
      <!-- <div
        class="top-btn-item current-layer"
        @click="showLayerList = !showLayerList"
      >{{currentLayer}}</div>
      <ul class="layer-list" v-show="showLayerList">
        <li
          class="item"
          v-for="(item, i) in layerNameList"
          :key="i"
          @click="toggleLayer(item.type)"
        >{{item.name}}</li>
      </ul>-->
      <!-- 切换地图 -->
      <div class="map-control">
        <div class="radio-list">
          <div
            :class="['radio-item', currentLayer === item.name ? '-active' : '']"
            v-for="(item, i) in layerNameList"
            :key="i"
            @click="toggleLayer(item.type)"
          >
            <i :class="['iconfont', currentLayer === item.name ? 'iconradiochecked' : 'iconradio']"></i>
            <span class="name">{{item.name}}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="foot-publish" v-show="isShowBtn">
      <img
        src="../../assets/switch/footprint_send.png"
        alt
        class="img"
        @click="showPublishBtn = true"
      />
    </div>
    <!--显示三个选择框-->
    <div class="publish-select" v-show="showPublishBtn">
      <div class="publish-select-mask" @click.self="showPublishBtn=false"></div>
      <transition name="slide-up">
        <div class="publish-select-box" v-show="showPublishBtn">
          <div class="publish-option" @click="handlePublishOption('video')">
            <span>拍视频</span>
          </div>
          <div class="publish-option" @click="handlePublishOption('camera')">
            <span>拍照</span>
          </div>
          <div class="publish-option" @click="handlePublishOption('local')">
            <span>本地相册</span>
          </div>
          <div class="publish-option" @click="showPublishBtn = false">
            <span>取消</span>
          </div>
        </div>
      </transition>
    </div>
    <!-- 顶部菜单 -->
    <div class="top-menu">
      <div class="menu-wrapper" :style="(typeList.length > 0) ? 'width: calc(100% - 48px)' : 'width: 100%'">
        <ul class="list">
          <li
            :class="['item', currentMenuType === item.id ? 'item-active' : '']"
            v-for="(item) in labelList"
            :key="item.id"
            @click="menuClickHandle(item)"
          >
            <img :src="item.newUrl" class="img" />
            <span class="text">{{item.title}}</span>
          </li>
        </ul>
      </div>
      <div class="select-btn" @click="showSelectMenu = true" v-if="typeList.length > 0">
        <i class="iconfont iconleimupinleifenleileibie"></i>
        <span class="text">{{selectedMenuTitle}}</span>
      </div>
    </div>
    <!-- 时间分类菜单 -->
    <div class="select-menu" v-show="showSelectMenu">
      <div class="select-menu-mask" @click.self="showSelectMenu = false"></div>
      <transition name="slide-down">
        <div class="select-menu-wrapper" v-show="showSelectMenu">
          <div class="selected">
            <p class="text">{{selectedMenuTitle}}</p>
            <div class="close" @click="showSelectMenu = false">
              <i class="iconfont iconclose"></i>
            </div>
          </div>
          <ul class="list">
            <li
              :class="['item', item.id === selectedMenuId ? 'item-active' : '']"
              v-for="(item,i) in typeList"
              :key="i"
              @click="selectMenuClickHandle(item)"
            >
              <p class="text">{{item.title}}</p>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <!-- 生成分享图片选项 -->
      <div class="generate-share" v-show="showGenetateOption">
        <div class="generate-share-mask" @click.self="showGenetateOption = false"></div>
        <transition name="slide-up">
          <div class="generate-options" v-show="showGenetateOption">
            <div class="options-title">
              <p class="name">{{optionsTitle[generateStep]}}</p>
              <i class="iconfont iconclose" @click="showGenetateOption = false"></i>
            </div>
              <ul class="options-list" v-if="generateStep === 0">
                <li :class="['item', currentGenerateLabelIndex === i ? 'item-active' : '']" v-for="(item, i) in labelList" :key="i" @click="currentGenerateLabelIndex = i">
                  <p class="name">{{item.title}}</p>
                </li>
              </ul>
              <ul class="options-list" v-else>
                <li :class="['item', currentGenerateTypeIndex === i ? 'item-active' : '']" v-for="(item, i) in typeList" :key="i"
                @click="currentGenerateTypeIndex = i">
                  <p class="name">{{item.title}}</p>
                </li>
              </ul>

            <div class="options-foot">
              <div :class="['foot-item', generateStep === 0 ? 'foot-disable' : '']" @click="handleGenerateStep('up')">上一步</div>
              <div class="foot-item" @click="handleGenerateStep('down')">下一步</div>
            </div>
          </div>
        </transition>
      </div>
    <!-- 正在生成图片 / 不可点击 -->
    <div class="generating-mask" v-show="isGenerating"></div>
    <!-- echarts / 生成图片 -->
    <div id="echarts-box"></div>
    <div class="share-wrapper" v-show="showPicture">
      <div class="share-pic-box">
        <div class="share-close" @click="showPicture = false">
          <i class="iconfont iconclose"></i>
        </div>
        <img src alt="长按分享" id="share-pic" />
        <p>长按分享给好友</p>
      </div>
      <p></p>
      <canvas id="share" style="display:block"></canvas>
    </div>
    <!-- 发布红包 -->
      <publish-button :id="label_id" :id_type_="id_type_" v-if="is_admin" bottom="9.8rem"></publish-button>
    <!-- 红包雨 -->
      <red-packet
        :redPacketList="redPacketList"
        v-if="have_red_packet_rain && isShowBtn"
        :id_type_="id_type_"
        :red_packet_type_="red_packet_type_"
        :red_packet_image_url="red_packet_image_url"
        :is_admin="is_admin"
        :have_chance="have_chance"
      ></red-packet>
  </div>
</template>
<script>
import { Toast, Indicator, MessageBox } from 'mint-ui'

// eslint-disable-next-line no-unused-vars
import { LMap, LTileLayer, LMarker, LPopup, LImageOverlay } from 'vue2-leaflet' // leaflet
import 'leaflet.markercluster/dist/MarkerCluster.css' // leaflet 点位聚合插件
import 'leaflet.markercluster/dist/MarkerCluster.Default.css' // leaflet 点位聚合插件
import 'leaflet.markercluster' // leaflet 点位聚合插件
import 'leaflet-responsive-popup' // leaflet popup弹出框插件
import 'proj4leaflet'
import 'leaflet.chinatmsproviders'
import RedPacket from '../../components/RedPacket'
import PublishButton from '../../components/PublishButton'
import echarts from 'echarts'
// import '../../../node_modules/echarts/map/js/china.js' // 引入中国地图数据
import { markerStore } from '../../assets/utils/map-marker.js'
var wx = require('weixin-js-sdk')

export default {
  components: {
    'red-packet': RedPacket,
    'publish-button': PublishButton
  },
  data () {
    return {
      // 初始数据
      park_id: 298,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      label_id: this.$route.query.id ? this.$route.query.id : 0,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: this.$route.query.version || '1.0.0',
      id: this.$route.query.id ? this.$route.query.id : 0,
      open_id: localStorage.getItem('open_id_' + this.$route.query.id)
        ? localStorage.getItem('open_id_' + this.$route.query.id)
        : '',

      id_type_: '',
      backUrl: 'leafletMapIndex',
      user_id: this.$route.query.user_id || '',
      where_: this.$route.query.where_ || 'web',
      // 自定义
      isLoading: false,
      map: {},
      center: [39.921108, 116.395562],
      zoom: 3,
      minZoom: 3,
      maxZoom: 21,
      zIndex: 1000,
      showLayerList: false,
      tileLayer: null,
      currentLayer: '地图',
      currentLayerType: 'normal',
      layerNameList: [
        {
          name: '地图',
          type: 'normal'
        },
        {
          name: '影像',
          type: 'satellite'
        }
      ],
      markerList: [],
      markerGroup: null,
      currentMarkerId: '',
      currentPointId: '',
      currentPointItem: {},
      selectedMenuTitle: '', // 已选类型菜单title
      selectedMenuId: '', // 已选类型菜单id
      showSelectMenu: false, // 显示选择菜单
      currentMenuType: '',
      isByUserId: false,
      defaultMenuType: '',
      showPublishBtn: false,
      locateMarker: null, // 定位
      // 是否小程序
      isMiniProgram: false,
      isShowBtn: false,
      // 获取数据
      parkInfo: {},
      labelList: [],
      typeList: [],
      pointList: [],
      initialPointList: [], // 初始获取的点位列表
      locateAddress: '',
      // 红包
      redPacketList: [],
      have_red_packet_rain: 0,
      red_packet_type_: '',
      red_packet_image_url: '',
      have_chance: 0,
      have_chance_id: '',
      is_admin: 0,
      // marker icon
      marker_icon: markerStore,

      // echarts
      myChart: null,
      chartImg: '',
      showPicture: false,

      // generate share image
      isFix: '', // 是否采取固定图例
      showGenetateOption: false,
      optionsTitle: [
        '请选择足迹类型',
        '请选择足迹时间'
      ],
      generateStep: 0,
      generateOptionsList: [],
      currentGenerateLabelOption: '', // 选择的足迹类型 id
      currentGenerateLabelIndex: 0, // 足迹类型在足迹列表中的 index
      currentGenerateTypeOption: '', // 选择的时间类型 id
      currentGenerateTypeIndex: 0, // 时间类型在时间列表中的 index
      isShareByUserId: false, // 选择 '我的' ，生成的数据需要传递 user_id

      // share image
      provinceReg: /台湾|河北|山西|内蒙古|辽宁|吉林|黑龙江|江苏|浙江|安徽|福建|江西|山东|河南|湖北|湖南|广东|广西|海南|四川|贵州|云南|西藏|陕西|甘肃|青海|宁夏|新疆|北京|天津|上海|重庆|香港|澳门|南海诸岛/,
      shareTitle: '',
      sharePointList: [],
      sharePointAcount: 0,
      shareWxCode: '',
      shareWxUser: '',
      shareLegend: '',
      haveShareData: false,
      company: '',
      provinceName: '',
      echartValueMax: 0,
      echartValueMin: 0,
      isGenerating: false
    }
  },
  mounted () {
    if (this.token) {
      // 已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id !== '0'
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token === this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id_' + this.id, this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.id, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.id, '')
      }
    }

    // hash值判断小程序还是web
    if (this.where_ === 'mini') {
      this.id_type_ = 'leafletMapIndexMini'
    } else {
      this.id_type_ = 'leafletMapIndex'
    }

    if (!this.open_id) {
      this.authorize()
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }

    // 微信提供的是否小程序的方法
    wx.miniProgram.getEnv(res => {
      console.log('res.miniprogram = ' + res.miniprogram) // true
      this.isMiniProgram = res.miniprogram
    })
    this.getData()
  },
  watch: {
    // 根据id获取点位对象
    currentPointId: function (val) {
      this.currentPointItem = this.pointList.filter((item, i) => {
        return item.id === val
      })[0]
    }
  },
  methods: {
    // 初始化 echarts
    initChart () {
      this.myChart = echarts.init(document.getElementById('echarts-box'))
      const option = {
        series: [
          {
            name: '中国地图',
            type: 'map',
            roam: false, // 缩放
            top: 0,
            left: 0,
            right: 0,
            map: 'china',
            emphasis: {
              // 高亮状态
              label: {
                show: true
              },
              itemStyle: {
                areaColor: '#fdc8c0' // 高亮状态和普通状态颜色一致
              }
            },
            itemStyle: {
              areaColor: '#fdc8c0',
              borderColor: 'rgba(0, 0, 0, 0.2)',
              normal: {
                areaColor: '#fdc8c0',
                borderColor: 'rgba(0, 0, 0, 0.2)'
              },
              emphasis: {
                areaColor: '#fdc8c0',
                borderColor: '#e35d4c',
                shadowColor: 'rgba(0, 0, 0, 0.2)',
                shadowBlur: 4,
                label: {
                  color: '#fff',
                  fontWeight: 'bold',
                  formatter: function (params) {
                    return '{selectedImg|}' // marker 标记
                  },
                  rich: {
                    // 这里设置您的图片引用名称
                    selectedImg: {
                      // 引入图片
                      backgroundColor: {
                        // image: 'https://mat1.gtimg.com/pingjs/ext2020/zhufu/choose-mark.png',
                        image:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAABQCAMAAABLc1c6AAAABGdBTUEAALGPC/xhBQAAAM9QTFRF/GRP/GRP+WRO/GNP/2pV+2RP6V1KCgQCwUw8ZykfAAAA7l9LAAAA/GROAAAAAAAA+mVQ/WRP/GRP/2BQ/GVQ+2VP/GRQAAAA/WRQ/GRP/GRP/GRP/GVPzE8//2JOAAAA/GROAAAA/GNP/GRP/GRPAAAAAAAAAAAA/Ihx/MGn/NC1/Ny//Nq+/HVe/Ipz/KmQ/JqC/Hli/GxX/Lie/LCX/Mes/Mqv/Lug/KSL/HBa/JB4/Na6/IBo/GlU/Hxl/Ni8/K2T/GdS/OHE/GRP/OTHqAzXKAAAACh0Uk5T4+QuTQyKrYTPo3TzV7NBIjBruxCTRK1HcMVU+q/XGnqZZ1q/7AaCACBmbl4AAAH8SURBVEjH7ZTXmqowFIUzvRfrKAIKIk5xbDMKiJSweP9nOiBjCBi8PTeuqyTfn12SvTcZ52pIza7R6xndptTgjglbtWRwklsHiNoG6DRcBVEUrMIpBdpqEdENLO0gZprYSxg6jwyADQekCjbAIEcGoL/xgX5pxqSIDmrFAlkUeoaoBsJYqBCGukPa2MQV2qCdIi0sgyokWKKVIDLsuFI25DFpgAbVSEDRIBKm+UkUbj1vG0b5yRQ6aXLpuH72Qb7LJdUkXayYDX//iT6zs0KXGAjyG0zMcgCD9MBubHNkyyyjxyM0RyiPnMEVIB7LAITU8XHM0Qfq5AqzY+HOcEUu8H4s6XdckEt4UfXTRR4uybgOq/oDLNSTn+5Ul0taMJ0EUUEnVcSEQk2rTs5zKmuWlEuKSPiOxET0DSnrgCF+xMgPhn9NUsNCjPio7VutwoyFLuvGmjCaaJFkzHp6CFHD7iLZIzqcrzLx5UDnh8cT5mVkjqfCfHkFXRWJNcVrcUoNsIhKsQ7Ks25YdDX/i5VHzsE/joW9G35iduCtWSBe9iQlZHQN5zMjPh1cjw6Q0VvfVODs7KwdKGb/bVRCNNM0bxRQ23VtCuUm2WoCxDRvs/K+3W00gaNEd8rDg3KXrg4dJdDL86N2n4D9e+3x+WUkyKhSJ+SEnJAT8h+Rf4nYBMat5asAAAAAAElFTkSuQmCC'
                      },
                      align: 'center',
                      width: 17,
                      height: 40
                    }
                  }
                }
              }
            },
            label: {
              normal: {
                show: true, // 显示标签（省名）
                fontSize: 10,
                fontFamily: 'MFPinSong, sans-serif',
                color: '#8a350b',
                // position: [x, y],  // 通过相对的百分比或者绝对像素值表示标签相对于图形包围盒左上角的位置
                // distance: number, // 距离图形元素的距离。当 position 为字符描述值（如 'top'、'insideRight'）时候有效。
                // rotate: number, // 标签旋转。从 -90 度到 90 度。正值是逆时针。
                // offset: [x, y], // 是否对文字进行偏移。默认不偏移。例如：[30, 40] 表示文字在横向上偏移 30，纵向上偏移 40。
                formatter: (params) => {
                  // 标签内容格式器，支持字符串模板和回调函数两种形式，字符串模板与回调函数返回的字符串均支持用 \n 换行。
                  const { name, value } = params
                  // console.log('name = ' + name + '---' + 'value = ' + value)
                  // if (value) {
                  //   return value // marker 标记
                  //   // return ' {selectedImg|} \n' + name
                  // } else {
                  //   // this.provinceName = this.provinceName + name + '|'
                  //   // console.log(this.provinceName)
                  //   return name
                  // }
                  let val = value || ''
                  return name + '\n' + val
                },
                rich: {
                  // 这里设置您的图片引用名称
                  selectedImg: {
                    // 引入图片
                    backgroundColor: {
                      // image: 'https://mat1.gtimg.com/pingjs/ext2020/zhufu/choose-mark.png',
                      image:
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAABQCAMAAABLc1c6AAAABGdBTUEAALGPC/xhBQAAAM9QTFRF/GRP/GRP+WRO/GNP/2pV+2RP6V1KCgQCwUw8ZykfAAAA7l9LAAAA/GROAAAAAAAA+mVQ/WRP/GRP/2BQ/GVQ+2VP/GRQAAAA/WRQ/GRP/GRP/GRP/GVPzE8//2JOAAAA/GROAAAA/GNP/GRP/GRPAAAAAAAAAAAA/Ihx/MGn/NC1/Ny//Nq+/HVe/Ipz/KmQ/JqC/Hli/GxX/Lie/LCX/Mes/Mqv/Lug/KSL/HBa/JB4/Na6/IBo/GlU/Hxl/Ni8/K2T/GdS/OHE/GRP/OTHqAzXKAAAACh0Uk5T4+QuTQyKrYTPo3TzV7NBIjBruxCTRK1HcMVU+q/XGnqZZ1q/7AaCACBmbl4AAAH8SURBVEjH7ZTXmqowFIUzvRfrKAIKIk5xbDMKiJSweP9nOiBjCBi8PTeuqyTfn12SvTcZ52pIza7R6xndptTgjglbtWRwklsHiNoG6DRcBVEUrMIpBdpqEdENLO0gZprYSxg6jwyADQekCjbAIEcGoL/xgX5pxqSIDmrFAlkUeoaoBsJYqBCGukPa2MQV2qCdIi0sgyokWKKVIDLsuFI25DFpgAbVSEDRIBKm+UkUbj1vG0b5yRQ6aXLpuH72Qb7LJdUkXayYDX//iT6zs0KXGAjyG0zMcgCD9MBubHNkyyyjxyM0RyiPnMEVIB7LAITU8XHM0Qfq5AqzY+HOcEUu8H4s6XdckEt4UfXTRR4uybgOq/oDLNSTn+5Ul0taMJ0EUUEnVcSEQk2rTs5zKmuWlEuKSPiOxET0DSnrgCF+xMgPhn9NUsNCjPio7VutwoyFLuvGmjCaaJFkzHp6CFHD7iLZIzqcrzLx5UDnh8cT5mVkjqfCfHkFXRWJNcVrcUoNsIhKsQ7Ks25YdDX/i5VHzsE/joW9G35iduCtWSBe9iQlZHQN5zMjPh1cjw6Q0VvfVODs7KwdKGb/bVRCNNM0bxRQ23VtCuUm2WoCxDRvs/K+3W00gaNEd8rDg3KXrg4dJdDL86N2n4D9e+3x+WUkyKhSJ+SEnJAT8h+Rf4nYBMat5asAAAAAAElFTkSuQmCC'
                    },
                    align: 'center',
                    width: 17,
                    height: 40
                  }
                }
              }
            },
            // 文本位置修正
            // textFixed: {
            //   '南海诸岛': [32, 80],
            //   '广东': [0, -10],
            //   '香港': [10, 5],
            //   '澳门': [-10, 10],
            //   '天津': [5, 5]
            // },
            data: this.sharePointList
            // data: [
            //   {
            //     name: '湖北',
            //     value: 108
            //   },
            //   {
            //     name: '南海诸岛',
            //     value: 0,
            //     itemStyle: {
            //       normal: { opacity: 1, label: { show: true } }
            //     }
            //   }
            // ]
          }
        ]
      }

      this.myChart.setOption(option)
      window.addEventListener('resize', () => {
        this.myChart.resize()
      })

      this.myChart.on('finished', () => {
        this.chartImg = this.myChart.getDataURL({
          // DOC: https://www.echartsjs.com/zh/api.html#echartsInstance.getDataURL
          type: 'png', // 导出的格式，可选 png, jpeg
          pixelRatio: 2, // 导出的图片分辨率比例，默认为 1。
          backgroundColor: '#c0e2ff'
        })
      })
    },

    // 生成 echarts 的图片
    genenrateChartImg () {
      this.chartImg = this.myChart.getDataURL({
        // DOC: https://www.echartsjs.com/zh/api.html#echartsInstance.getDataURL
        type: 'png', // 导出的格式，可选 png, jpeg
        pixelRatio: 2, // 导出的图片分辨率比例，默认为 1。
        backgroundColor: '#fee2de'
      })
    },
    // 生成图片
    drawImage () {
      this.genenrateChartImg()
      const canvas = document.getElementById('share')
      canvas.width = 750
      canvas.height = 1334
      const ctx = canvas.getContext('2d')

      const logo = require('../../assets/img/login_geoLogo.png')
      const wxCode = this.where_ === 'mini' ? '/static/wx_acode/qrcode_park_298.png' : '/static/wx_acode/qrcode_park_298_web.png'

      // 用户
      let userNick = this.shareWxUser.user_nick || '微信用户'
      // let userIcon = this.shareWxUser.user_icon || require('../../assets/img/default_user_icon.png')
      let userIcon = require('../../assets/img/default_user_icon.png')
      Promise.all([
        this.loadImg(this.chartImg),
        this.loadImg(logo),
        this.loadImg(wxCode),
        this.loadImg(userIcon)
      ]).then(values => {
        let [chartImg, logo, wxCode, userIcon] = values
        // ctx.drawImage(bgImg, 0, 0, canvas.width, canvas.height)
        // 背景
        ctx.fillStyle = '#fee2de'
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        // 标题
        ctx.textAlign = 'center'
        ctx.font = 'bold 54px MFPinSong'
        let shareTitle = this.shareImageTitle // 分享图片的标题
        ctx.fillStyle = '#ffa79b'
        ctx.fillText(shareTitle, canvas.width / 2 + 3, 120 + 3)
        ctx.fillStyle = '#fc644f'
        ctx.fillText(shareTitle, canvas.width / 2, 120)

        // 地图
        ctx.drawImage(chartImg, 22, 200, 712, 570)

        if (this.haveShareData) {
          // 图例
          this.shareLegend.forEach((item, i) => {
            let color = item.color
            let val = item.value
            ctx.fillStyle = color
            ctx.fillRect(30 + i * 118, 775, 16, 16)
            ctx.fillStyle = '#000'
            ctx.textAlign = 'left'
            ctx.font = '16px MFPinSong'
            ctx.fillText(val, 50 + i * 118, 790)
          })
        }

        // 内容边框
        ctx.fillStyle = '#ffdcbc'
        ctx.fillRect(72, 890, 638, 238)

        ctx.moveTo(60, 856)

        ctx.lineTo(690, 856)
        ctx.lineTo(690, 1090)
        ctx.lineTo(670, 1110)
        ctx.moveTo(690, 1090)
        ctx.lineTo(670, 1090)
        ctx.lineTo(670, 1110)
        ctx.lineTo(60, 1110)
        ctx.lineTo(60, 856)
        ctx.strokeStyle = '#fc644f'
        ctx.lineWidth = 5
        ctx.stroke()
        // ctx.strokeRect(60, 856, 630, 243)

        // 用户
        ctx.drawImage(userIcon, 40, 820, 64, 64)
        ctx.fillStyle = '#fc644f'
        ctx.fillRect(120, 836, 580, 40)
        ctx.fillStyle = '#eeeeee'
        ctx.textAlign = 'left'
        ctx.font = '20px MFPinSong'
        let addressTx = this.locateAddress ? ('从' + this.locateAddress) : ''
        let userTx = this.clampUserNick(userNick) + addressTx + '分享了' + this.typeList[this.currentGenerateTypeIndex].title + '\'' + this.labelList[this.currentGenerateLabelIndex].title + '\'足迹'
        ctx.fillText(userTx, 140, 864)

        // 点亮区域
        let lightTx1 = '共' + this.sharePointAcount + '条足迹'
        let lightTx2 = '点亮全国' + this.sharePointList.length + '个地区'
        ctx.fillStyle = 'rgb(252, 100, 79)'
        ctx.textAlign = 'left'
        ctx.font = 'bold 44px Times New Roman'
        ctx.fillText(lightTx1, 220, 960)
        ctx.fillText(lightTx2, 220, 1032)

        // 二维码
        ctx.drawImage(wxCode, 40, 1032, 145, 145)
        ctx.fillStyle = 'rgb(252, 100, 79)'
        ctx.textAlign = 'left'
        ctx.font = '32px MFPinSong'
        ctx.fillText('立即扫码 足迹打卡', 200, 1167)

        // 时间戳
        let ts = this.getTimeStamp(new Date())
        ctx.fillStyle = 'rgba(0, 0, 0, 0.3)'
        ctx.textAlign = 'left'
        ctx.font = '24px Times New Roman'
        ctx.fillText(ts, 220, 1094)
        // ctx.rotate(-Math.PI / 12)
        // ctx.translate(208, 1042)
        // ctx.fillText('图片已生成', 100, 143)
        // ctx.translate(-208, -1042)
        // ctx.rotate(Math.PI / 12)

        // logo
        ctx.drawImage(logo, 351, 1220, 36, 36)

        // 公司名
        ctx.textAlign = 'center'
        ctx.fillStyle = '#333333'
        ctx.font = '18px sans-serif'
        let parkName = this.company.title
        ctx.fillText(parkName, canvas.width / 2, 1288)

        // 显示头像
        ctx.arc(83, 825, 42, 0, 2 * Math.PI)
        ctx.clip()

        let dataUrl = canvas.toDataURL('image/jpeg')
        let sharePicture = document.getElementById('share-pic')
        // console.log(sharePicture)
        canvas.style.display = 'none'
        this.showPicture = true
        sharePicture.src = dataUrl
        this.isGenerating = false // 生成分享图片结束
        Indicator.close()
        this.showGenetateOption = false
      })
    },
    clampUserNick (tx) {
      if (tx.length > 6) {
        return tx.substr(0, 6) + '...'
      } else {
        return tx
      }
    },
    // 加载图片
    loadImg (src) {
      return new Promise(resolve => {
        let img = new Image()
        img.setAttribute('crossOrigin', 'anonymous')
        img.src = src
        img.onload = () => {
          resolve(img)
          // console.log(111)
        }
        img.onerror = () => {
          // console.log(222)
          resolve(new Image())
        }
      })
    },
    // 获取当前时间
    getTimeStamp (d) {
      const to2 = num => ('0' + num).slice(-2)
      let yy = d.getFullYear()
      let hh = to2(d.getHours())
      let mm = to2(d.getMonth() + 1)
      let MM = to2(d.getMinutes())
      return yy + '年' + mm + '月' + d.getDate() + '日' + hh + ':' + MM
    },
    // 格式化地图数据
    fomaterShareDate (ls) {
      this.sharePointList.length = 0
      this.sharePointAcount = 0
      if (ls && ls.length) { // 有分享点位数据
        this.haveShareData = true
        ls.forEach((item, i) => {
          if (!item.province || !item.province.match(this.provinceReg)) {
            return false
          }
          const name = item.province.match(this.provinceReg)[0]
          const value = item.numbers * 1
          if (value && value >= 0) {
            if (i === 0) {
              this.echartValueMax = this.echartValueMin = value
            } else {
              if (value > this.echartValueMax) {
                this.echartValueMax = value
              } else if (value < this.echartValueMin) {
                this.echartValueMin = value
              }
            }
          } else {
            console.log('数值错误')
          }

          this.sharePointList.push({
            name: name,
            value: value
          })
        })

        let lev1Color = '#fdc8c0'
        let lev2Color = '#ffac9f'
        let lev3Color = '#ff907e'
        let lev4Color = '#f76a54'
        let lev5Color = '#f54b31'
        let lev6Color = '#f12809'
        let lev1Value = 50
        let lev2Value = 100
        let lev3Value = 200
        let lev4Value = 500
        let lev5Value = 1000
        if (!this.isFix) {
          let space = Math.ceil((this.echartValueMax - this.echartValueMin) / 4)
          lev1Value = this.echartValueMin
          lev2Value = lev1Value + space
          lev3Value = lev2Value + space
          lev4Value = lev3Value + space
          lev5Value = lev4Value + space
        }
        // let lev6Value = this.echartValueMax
        this.sharePointList.forEach(item => {
          let areaColor = lev1Color
          let val = item.value
          if (val && val > 0) {
            if (val < lev1Value) { // 1
              areaColor = lev1Color
            } else if (val >= lev1Value && val < lev2Value) {
              areaColor = lev2Color
            } else if (val >= lev2Value && val < lev3Value) {
              areaColor = lev3Color
            } else if (val >= lev3Value && val < lev4Value) {
              areaColor = lev4Color
            } else if (val >= lev4Value && val < lev5Value) {
              areaColor = lev5Color
            } else {
              areaColor = lev6Color
            }
            item.itemStyle = {
              areaColor
            }
            this.sharePointAcount += val * 1
          }
        })

        // 设置图例
        this.shareLegend = [
          {
            color: lev1Color,
            value: ' < ' + lev1Value
          },
          {
            color: lev2Color,
            value: lev1Value + ' - ' + lev2Value
          },
          {
            color: lev3Color,
            value: lev2Value + ' - ' + lev3Value
          },
          {
            color: lev4Color,
            value: lev3Value + ' - ' + lev4Value
          },
          {
            color: lev5Color,
            value: lev4Value + ' - ' + lev5Value
          },
          {
            color: lev6Color,
            value: ' > ' + lev5Value
          }
        ]
      } else {
        this.haveShareData = false
        this.sharePointAcount = 0
      }
    },
    // 获取生成图片的数据
    getMakeImageData () {
      if (this.isGenerating) {
        // 正在生成分享图片
        return false
      }
      this.isGenerating = true
      Indicator.open('正在生成中...')
      let userId = ''
      if (this.isShareByUserId) {
        userId = this.user_id
      }
      this.$ajax(
        'post',
        'Map/get_make_image_data',
        {
          open_id: this.open_id,
          id_: this.currentGenerateLabelOption,
          tid_: this.currentGenerateTypeOption,
          park_id: this.park_id,
          token: this.token,
          user_id: userId
        },
        res => {
          if (res.data.id === 0) {
            this.shareImageTitle = res.data.data.title || '获取标题失败'
            this.shareWxCode = res.data.data.wxacode + '?v= ' + new Date().getTime()
            this.shareWxUser = res.data.data.wx_user
            this.company = res.data.data.company
            this.isFix = res.data.data.is_fix || 0 // 固定图例
            // this.sharePointList.push(...res.data.data.point_list)
            this.fomaterShareDate(res.data.data.point_list)
            this.initChart() // 初始化 echart
            this.getCurrentProvince().then(res => {
              this.drawImage() // 画图
            }, rej => {
              this.drawImage() // 画图
            })
          } else {
            Toast('生成图片失败')
          }
        }
      )
    },

    // getURLBase64 (url) {
    //   return new Promise((resolve, reject) => {
    //     let xhr = new XMLHttpRequest()
    //     xhr.open('get', url, true)
    //     xhr.responseType = 'blob'
    //     xhr.onload = function () {
    //       if (this.status === 200) {
    //         let blob = this.response
    //         let fileReader = new FileReader()
    //         fileReader.onloadend = function (e) {
    //           let result = e.target.result
    //           resolve(result)
    //         }
    //         fileReader.readAsDataURL(blob)
    //       }
    //     }
    //     xhr.onerror = function (err) {
    //       reject(err)
    //     }
    //     xhr.send()
    //   })
    // },
    // 生成选项步骤选择
    handleGenerateStep (direction) {
      if (direction === 'up' && this.generateStep === 1) {
        this.generateStep--
      } else if (direction === 'down' && this.generateStep === 0) {
        this.generateStep++
      } else if (direction === 'down' && this.generateStep === 1) {
        this.currentGenerateLabelOption = this.labelList[this.currentGenerateLabelIndex].id
        this.currentGenerateTypeOption = this.typeList[this.currentGenerateTypeIndex].id
        if (this.labelList[this.currentGenerateLabelIndex].isByUser_id === 1) { // 选择了 '我的'
          this.isShareByUserId = true
        } else {
          this.isShareByUserId = false
        }
        this.getMakeImageData()
      } else {
        return false
      }
    },
    // 生成图片前选择类型和时间
    handleGenerateOptions () {
      this.generateStep = 0
      this.generateOptionsList = this.labelList
      // if (this.generateStep === 0) {
      //   this.generateOptionsList = this.labelList
      // } else if (this.generateStep === 1) {
      //   this.generateOptionsList = this.typeList
      // }
      this.showGenetateOption = true
    },
    // 进入小程序列表详情
    navToDetailList () {
      if (this.isMiniProgram) {
        let url = '/pages/foot/list'
        wx.miniProgram.navigateTo({
          url: url
        })
      } else {
        MessageBox.alert('请在小程序中打开')
      }
    },
    // 进入小程序主页
    navToMiniHome () {
      if (this.isMiniProgram) {
        let url = '/pages/index/index'
        wx.miniProgram.navigateTo({
          url: url
        })
      } else {
        MessageBox.alert('请在小程序中打开')
      }
    },
    // 发布足迹选项
    handlePublishOption (type) {
      if (this.isMiniProgram) {
        let url = ''
        if (type === 'video') {
          url = '/pages/index/video?back_to=0&aid=0&park_packet_id=0'
        } else if (type === 'camera') {
          url = '/pages/index/publish?aid=0&back_to=0&park_packet_id=0'
        } else if (type === 'local') {
          url = '/pages/index/local?aid=0&is_red_packet=0&is_task=0&back_to=0'
        }
        wx.miniProgram.navigateTo({
          url: url
        })
      } else {
        MessageBox.alert('请在小程序中打开')
      }
    },
    // 点击类型目录
    selectMenuClickHandle (item) {
      this.selectedMenuTitle = item.title
      this.selectedMenuId = item.id
      this.currentMenuType = this.defaultMenuType
      this.getDataByTime(item.type_)
    },
    // 根据时间类型获取数据
    getDataByTime (type, user_id = '') {
      // 点击 "我的" 需要传递user_id, 其他的user_id 需要传空

      Indicator.open('加载中...')
      this.$ajax(
        'post',
        'Map/get_map_data_by_select_time_type',
        {
          park_id: this.park_id,
          token: this.token,
          version: this.version,
          type_: type,
          user_id: user_id
        },
        res => {
          if (res.data.id === 0) {
            this.pointList.length = 0
            this.markerList.forEach(item => {
              this.markerGroup.removeLayer(item)
            })
            this.markerList.length = 0

            this.pointList.push(...res.data.data.point_list)
            this.initPoint()
          } else if (res.data.id === -2) {
            this.pointList.length = 0
            this.markerList.forEach(item => {
              this.markerGroup.removeLayer(item)
            })
            this.markerList.length = 0
            MessageBox.alert('没有相关数据')
          } else {
            console.log(res.data.msg)
          }

          Indicator.close()
          this.showSelectMenu = false
        }
      )
    },
    // 点击目录
    menuClickHandle (it) {
      if (it.isByUser_id === 1) { // 点击 '我的'
        if (it.id !== this.currentMenuType) {
          this.isByUserId = true
          this.currentMenuType = it.id
          // 初始已选菜单
          this.selectedMenuTitle = this.typeList[0].title
          this.selectedMenuId = this.typeList[0].id
          this.getDataByTime(it.id, this.user_id)
        } else {
          this.isByUserId = false
          this.pointList.length = 0
          this.markerList.forEach(item => {
            this.markerGroup.removeLayer(item)
          })
          this.markerList.length = 0

          this.pointList.push(...this.initialPointList)
          this.initPoint()
          this.currentMenuType = this.defaultMenuType
        }
      } else {
        if (it.id === '42') { // 点击 '全部'
          if (this.currentMenuType === it.id) {
            return false
          } else {
            if (this.isByUserId) {
              this.isByUserId = false
              this.pointList.length = 0
              this.markerList.forEach(item => {
                this.markerGroup.removeLayer(item)
              })
              this.markerList.length = 0

              this.pointList.push(...this.initialPointList)
              this.initPoint()
              this.currentMenuType = it.id
            } else {
              this.currentMenuType = it.id
              this.markerList.forEach(item => {
                this.markerGroup.removeLayer(item)
                this.markerGroup.addLayer(item)
              })
            }
          }
        } else {
          if (it.id === this.currentMenuType) {
            this.markerList.forEach(item => {
              if (item.type_ !== it.id) {
                this.markerGroup.addLayer(item)
              }
            })
            this.currentMenuType = this.defaultMenuType
          } else {
            if (this.isByUserId) {
              this.isByUserId = false

              this.pointList.length = 0
              this.markerList.forEach(item => {
                this.markerGroup.removeLayer(item)
              })
              this.markerList.length = 0

              this.pointList.push(...this.initialPointList)
              this.initPoint()
              this.markerList.forEach(item => {
                this.markerGroup.removeLayer(item)
                if (item.type_ === it.id) {
                  console.log(it.id)
                  this.markerGroup.addLayer(item)
                }
              })
              this.currentMenuType = it.id
            } else {
              this.markerList.forEach(item => {
                this.markerGroup.removeLayer(item)
                if (item.type_ === it.id) {
                  console.log(it.id)
                  this.markerGroup.addLayer(item)
                }
              })
              this.currentMenuType = it.id
            }
          }
        }
      }
    },

    // 切换图层
    toggleLayer (type) {
      if (this.currentLayerType === type) {
        this.showLayerList = false
        return false
      }
      let layerName = ''
      let zoomOption = {
        maxZoom: this.maxZoom,
        minZoom: this.minZoom
      }
      if (this.tileLayer) {
        this.map.removeLayer(this.tileLayer)
        this.tileLayer = null
      }
      if (type === 'normal') {
        layerName = '地图'
        // eslint-disable-next-line no-undef
        this.tileLayer = L.tileLayer.chinaProvider(
          'GaoDe.Normal.Map',
          zoomOption
        )
      } else if (type === 'satellite') {
        layerName = '影像'
        // eslint-disable-next-line no-undef
        let tile1 = L.tileLayer.chinaProvider(
          'GaoDe.Satellite.Map',
          zoomOption
        )

        // eslint-disable-next-line no-undef
        let tile2 = L.tileLayer.chinaProvider(
          'GaoDe.Satellite.Annotion',
          zoomOption
        )
        // eslint-disable-next-line no-undef
        this.tileLayer = L.layerGroup()
          .addLayer(tile1)
          .addLayer(tile2)
      } else {
        return false
      }
      this.currentLayer = layerName
      this.currentLayerType = type
      this.tileLayer.addTo(this.map)
      this.showLayerList = false
    },
    // 刷新
    handleRefresh () {
      this.map.setView(this.center, 3)
      // if (this.tileLayer) {
      //   this.map.removeLayer(this.tileLayer);
      //   this.tileLayer = L.tileLayer.chinaProvider(
      //     "GaoDe.Normal.Map",
      //     {
      //     maxZoom: this.maxZoom,
      //     minZoom: this.minZoom
      //   }
      //   ).addTo(this.map);
      // }
    },
    // 根据经纬度获取地理位置
    getCurrentProvince () {
      return new Promise((resolve, reject) => {
        this.map.locate({
          tiemout: 3000,
          maximumAge: 3000,
          enableHighAccuracy: true
        })
        this.map.on('locationfound', e => {
          // eslint-disable-next-line no-undef
          var geocoder = new AMap.Geocoder({
            city: '010', // 城市设为北京，默认：“全国”
            radius: 1000 // 范围，默认：500
          })
          let lnglat = e.latlng.lng + ',' + e.latlng.lat
          geocoder.getAddress(lnglat, (status, result) => {
            if (status === 'complete' && result.regeocode) {
              this.locateAddress = result.regeocode.formattedAddress.match(this.provinceReg)[0]
              console.log(this.locateAddress)
              resolve(this.locateAddress)
            } else {
              console.log('根据经纬度查询地址失败')
            }
          })
        })
        this.map.on('locationerror', e => {
          console.log('获取位置信息失败')
          this.map.stopLocate()
          reject(e)
        })
      })
    },
    // 定位
    handleLocation () {
      Indicator.open('定位中...')
      this.map.locate({
        timeout: 3000,
        maximumAge: 3000,
        enableHighAccuracy: true
      })

      this.map.on('locationfound', e => {
        if (this.locateMarker != null) {
          this.map.removeLayer(this.locateMarker)
        }
        let iconUrl = this.marker_icon['marker_locate']
        let html = "<img class='icon-value' src='" + iconUrl + "'/>"
        // eslint-disable-next-line no-undef
        let divIcon = L.divIcon({
          html: html,
          iconSize: [40, 40],
          iconAnchor: [20, 40],
          popupAnchor: [0, -20]
        })
        // eslint-disable-next-line no-undef
        this.locateMarker = L.marker(e.latlng, {
          icon: divIcon
        })
          .addTo(this.map)
          .bindPopup('你在这里')
        this.map.setView(e.latlng, 18)
        Indicator.close()
        // this.first_latlng = e.latlng;
        // this.sortByDistance(e.latlng);
        // console.log(this.point_list);
      })
      this.map.on('locationerror', e => {
        Toast('定位失败,请允许权限')
        Indicator.close()
        this.map.stopLocate()
      })
    },
    // 地图缩放
    // zoomControl(type) {
    //   if (type === "up" && this.zoom < this.maxZoom) {
    //     this.zoom++;
    //   } else if (type === "down" && this.zoom > this.minZoom) {
    //     this.zoom--;
    //   } else {
    //     return false;
    //   }
    //   this.map.setZoom(this.zoom);
    // },
    // 获取数据

    getData () {
      if (this.park_id && this.version && this.token) {
        this.isLoading = true
        Indicator.open('加载中...')
        this.$ajax(
          'post',
          'Map/get_map_data',
          {
            open_id: this.open_id,
            park_id: this.park_id,
            version: this.version,
            token: this.token,
            user_id: this.user_id,
            id_: this.park_id,
            where_: this.where_,
            id_type_: this.id_type_
          },
          res => {
            if (res.data.id === 0) {
              Object.assign(this.parkInfo, res.data.data.park)
              this.labelList.push(...res.data.data.label_list)
              this.typeList.push(...res.data.data.type_list)
              this.initialPointList.push(...res.data.data.point_list)

              this.pointList.push(...res.data.data.point_list)
              document.title = this.parkInfo.park_name
              this.defaultMenuType = this.labelList[0] ? this.labelList[0].id : ''
              this.currentMenuType = this.defaultMenuType
              this.redPacketList =
                res.data.data.red_packet_image_url_list
              this.have_red_packet_rain =
                res.data.data.have_red_packet_rain || 0
              this.red_packet_type_ =
                res.data.data.red_packet_type_ || ''
              this.red_packet_image_url =
                res.data.data.red_packet_image_url || ''

              this.isShowBtn = res.data.data.show_btn || ''

              this.is_admin = res.data.data.is_admin || 0
              this.have_chance = res.data.data.have_chance || 0
              this.have_chance_id = res.data.data.have_chance_id || ''

              // 微信分享
              const sUrl = window.location.href
              const wxTitle = this.parkInfo.park_name
              const wxContent = this.parkInfo.park_content
              const wxImg = this.parkInfo.park_logo
              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wxTitle,
                  content: wxContent,
                  img: wxImg
                })
              }
              // 初始已选菜单
              this.selectedMenuTitle = this.typeList[0].title
              this.selectedMenuId = this.typeList[0].id
            }

            this.isLoading = false
            Indicator.close()
            this.initMap()
          }
        )
      }
    },
    initMap () {
      // 创建map
      // eslint-disable-next-line no-undef
      this.map = L.map('map', {
        center: this.center,
        zoom: this.zoom,
        zoomControl: true,
        attributionControl: false
      })
      // eslint-disable-next-line no-undef
      this.tileLayer = L.tileLayer.chinaProvider('GaoDe.Normal.Map', {
        maxZoom: this.maxZoom,
        minZoom: this.minZoom
      })
      this.tileLayer.addTo(this.map)
      // L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      //   this.map
      // );
      this.initPoint()
    },
    // marker 层
    initPoint () {
      this.pointList.forEach(item => {
        let iconUrl = this.marker_icon['marker_' + item.type_]

        let html = "<img class='icon-value' src='" + iconUrl + "'/>"
        // eslint-disable-next-line no-undef
        let divIcon = L.divIcon({
          html: html,
          iconSize: [40, 40],
          iconAnchor: [20, 40],
          popupAnchor: [0, -20]
        })
        // eslint-disable-next-line no-undef
        let marker = L.marker(L.latLng(item.lat, item.lng), {
          icon: divIcon
        })
        marker.title = item.title
        marker.id = item.id
        marker.numbers = item.numbers
        marker.type_ = item.type_
        marker.newUrl = item.newUrl
        marker.city = item.city
        marker.years = item.years
        marker.numbers = item.numbers
        marker.on('click', () => {
          this.togglePopup(marker)
        })
        this.markerList.push(marker)
      })
      // eslint-disable-next-line no-undef
      let markers = L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        removeOutsideVisibleBounds: false,
        // disableClusteringAtZoom: this.maxZoom - 1,
        maxClusterRadius: 40,
        iconCreateFunction: cluster => {
          // let _markers = cluster.getAllChildMarkers()
          // let markerName =
          //   _markers[0].title.length > 3
          //     ? _markers[0].title.substr(0, 3) + '...等'
          //     : _markers[0].title
          // eslint-disable-next-line no-undef
          return L.divIcon({
            // html:
            //   "<p class='icon-name'>" +
            //   markerName +
            //   "</p>" +
            //   "<p class='icon-number'>" +
            //   cluster.getChildCount() +
            //   "</p>" +
            //   "<img class='icon-value' src='" +
            //   this.marker_cluster +
            //   "'/>",
            html:
              "<p class='icon-number'>" +
              cluster.getChildCount() +
              '</p>' +
              "<img class='icon-value' src='" +
              this.marker_icon['marker_cluster'] +
              "'/>",
            iconSize: [40, 40],
            iconAnchor: [20, 40]
          })
        }
      })
      // eslint-disable-next-line no-undef
      this.markerGroup = markers.addLayer(L.layerGroup(this.markerList))

      this.map.addLayer(this.markerGroup)
    },
    /**
     * 切换 marker 对应的弹出框
     * @param { object } marker map中的marker对象
     */
    togglePopup (marker) {
      // 如果已经打开  就不继续执行
      if (this.currentMarkerId === marker.id) {
        return false
      }

      // let visibleOne = this.markerGroup.getVisibleParent(marker)
      // this.map.setView(marker._latlng, this.mapMaxZoom - 1);
      this.map.setView(marker._latlng)

      marker.setZIndexOffset(this.zIndex)
      this.zIndex++
      this.currentPointId = marker.id

      if (marker.getPopup()) {
        marker.openPopup()
        this.currentMarkerId = marker.id
        setTimeout(() => {
          this.btnClickHandle(marker)
        }, 300)
      } else {
        this.createPopup(marker)
        marker.openPopup()
        this.currentMarkerId = marker.id
        setTimeout(() => {
          this.btnClickHandle(marker)
        }, 300)
      }
    },
    /**
     * 给 marker 创建对应的 popup 弹出框
     * @param { object } marker  map 中的 marker 对象
     */
    createPopup (marker) {
      // eslint-disable-next-line no-undef
      let popup = L.responsivePopup({
        maxWidth: 'auto',
        keepInView: false,
        className: 'brief',
        hasTip: true, // 三角形箭头。。
        autoPan: false,
        closeButton: false, // 不要关闭按钮
        offset: [15, 25]
      })
        .setLatLng(marker._latlng)
        .setContent(this.createPopupContent(marker))
      marker.bindPopup(popup)
    },
    /**
     * 创建marker的popup中的内容
     * @param { object } marker  map 中的 marker 对象
     *
     * @return { string } 返回生成的字符串格式的dom
     */
    createPopupContent (marker) {
      let title = "<div class='brief-title'>" + marker.title + '</div>'
      let img =
        "<div class='brief-icon'><img class='icon' src=" +
        marker.newUrl +
        '></div>'

      let detail, audio, nav

      detail =
        "<div class='brief-detail brief-btn-item'><i class='iconfont iconjianjie1'></i><span  class='btnname'>详情</span></div>"
      if (marker.zhAudioUrl) {
        audio =
          "<div class='brief-audio brief-btn-item'><i class='iconfont icon1'></i><span  class='btnname'>解说</span></div>"
      } else {
        audio =
          "<div class='brief-audio brief-btn-item brief-btn-item-no'><i class='iconfont icon1'></i><span  class='btnname'>解说</span></div>"
      }
      nav =
        "<div class='brief-nav brief-btn-item'><i class='iconfont icondaohang1'></i><span class='btnname'>导航</span></span>"
      let btns
      if (marker.zhAudioUrl) {
        btns = "<div class='brief-btn'>" + detail + audio + nav + '</div>'
      } else {
        btns = "<div class='brief-btn'>" + detail + nav + '</div>'
      }

      let tags =
        "<div class='brief-tags'>" +
        // "<div class='brief-tags-item brief-tags-city'>" +
        // marker.city +
        // "</div>" +
        "<div class='brief-tags-item brief-tags-numbers'>热度：" +
        marker.numbers +
        '</div>' +
        '</div>'

      let info = "<div class='brief-info'>" + title + tags + btns + '</div>'
      let html = "<div class='brief-box'>" + img + info + '</div>'

      return html
    },
    /**
     * 给marker对应的popup中的按钮绑定点击事件
     * @param { object } marker  map 中的 marker 对象
     */
    btnClickHandle (marker) {
      let nav = document.querySelector('.brief-nav')
      nav.removeEventListener('click', this.jumpPos)
      nav.addEventListener('click', this.jumpPos)

      let detail = document.querySelector('.brief-detail')
      let audio = document.querySelector('.brief-audio')
      detail.removeEventListener('click', this.jumpToDetail)
      detail.addEventListener('click', this.jumpToDetail)
      if (this.currentPointItem.zhAudioUrl) {
        audio.addEventListener('click', () => {
          this.audio_id = this.currentPointItem.id
          // this.playAudio();
        })
      }
    },
    // 跳转详情
    jumpToDetail () {
      if (
        this.currentPointItem.type_ === '17' ||
        this.currentPointItem.type_ === '15'
      ) {

      } else {
        // this.$router.push({
        //   path: '/footprints/PrimaryScenic',
        //   query: {
        //     id_: this.id,
        //     sid: this.currentPointItem.id
        //   }
        // })
        let url = '/pages/foot/detail?id=' + this.currentPointItem.id + '&aid=0' + '&rp_fk_id=0' + '&rp_fk_id=0'
        wx.miniProgram.navigateTo({
          url: url
        })
      }
    },
    // 导航
    jumpPos () {
      if (this.is_weixin || this.isMiniProgram || this.where_ === 'mini') {
        if (this.currentPointItem.price > 0) {
          // 收费
          if (this.currentPointItem.is_nav_pay) {
            // 已经支付了
            this.wx_nav(
              window.location.href,
              this.currentPointItem.lng,
              this.currentPointItem.lat,
              this.currentPointItem.title,
              this.currentPointItem.address
            )
          } else {
            // 还未支付
            if (this.open_id) {
              // 进行支付
              var openUrl =
                'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
                this.id +
                '&backUrl=' +
                this.backUrl +
                '&open_id=' +
                this.open_id +
                '&token=' +
                this.token +
                '&park_id=' +
                this.park_id +
                '&version=' +
                this.version +
                '&user_id='
              window.location.href = openUrl
            } else {
              // 进行微信授权
              this.authorize()
            }
          }
        } else {
          // 免费
          this.wx_nav(
            window.location.href,
            this.currentPointItem.lng,
            this.currentPointItem.lat,
            this.currentPointItem.title,
            this.currentPointItem.address
          )
        }
      } else {
        Toast('请在微信中打开,使用导航')
      }
    },
    /**
     * 当文本过长时，截取指定的长度并返回
     * @param { string } tx 要截取的文本字符串
     * @param { number } n  要截取的长度
     *
     * @return { string } 返回被截取后的文本
     */
    clampText (tx, n) {
      if (tx.length <= n) {
        return tx
      } else {
        return tx.substr(0, n) + '...'
      }
    },
    // 授权方法
    authorize () {
      var openUrl =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&id_=0&state=' +
        this.backUrl +
        '&id_type_=' +
        this.id_type_ +
        '&where_=' +
        this.where_ +
        '&user_id=' +
        this.user_id
      window.location.href = openUrl
      // console.log(openUrl)
    },
    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id === 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var openId = this.open_id
            if (info && info !== '') {
              if (info.url && info.url !== '') {
                let urlArr = info.url.split('share_open_id')

                url = urlArr[0] + '&share_open_id=' + openId
              } else {
                url = url + '&share_open_id=' + openId
              }
            } else {
              url = url + '&share_open_id=' + openId
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id === 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#map {
  width: 100%;
  height: 100vh;
  z-index: 400;
}

.right-btn {
  position: fixed;
  right: 0.6rem;
  bottom: 0.6rem;
  width: 2rem;
  z-index: 401;
}

.location {
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.location:active {
  background: rgb(201, 235, 235);
  -webkit-tap-highlight-color: transparent;
}

.location > .iconfont {
  font-size: 1rem;
  color: #333;
}

.left-btn {
  position: fixed;
  bottom: 0.6rem;
  left: 0.6rem;
  width: 2rem;
  z-index: 401;
}

.left-btn-item {
  height: 2rem;
  width: 100%;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.left-btn-item > .iconfont {
  color: #333;
  font-size: 1rem;
}

.left-btn-item:active {
  background: rgb(201, 235, 235);
  -webkit-tap-highlight-color: transparent;
}

.top-btn {
  position: fixed;
  top: 54px;
  right: 0.6rem;
  z-index: 401;
}

.top-btn-item {
  width: 58px;
  height: 36px;
  margin-bottom: 6px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.top-btn-item > .iconfont {
  font-size: 14px;
  color: #666;
}

.top-btn-item > .name {
  font-size: 14px;
  color: #333;
}
/* 地图切换 */
.map-control {
  margin-bottom: 6px;
}

.radio-list {
  /* margin-top: 0.4rem; */
  padding: 4px 0;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #fff;
}

.radio-item {
  width: 58px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #999;
}

.radio-item > .iconfont {
  margin-right: 4px;
  font-size: 14px;
  color: #ccc;
}

.radio-item > .iconfont.iconradiochecked {
  color: #666;
}

.radio-list > .-active {
  color: #333;
}

/*
.current-layer {
  margin-top: 0.4rem;
  font-size: 0.7rem;
}

.layer-list {
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.3);
}

.layer-list > .item {
  margin-top: 2px;
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  color: #666;
  font-size: 0.7rem;
}

.detail-list > .iconfont {
  font-size: 1.2rem;
  color: #333;
} */

/* 顶部目录 */

.top-menu {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 48px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  z-index: 401;
  overflow-x: auto;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
}

.top-menu > .menu-wrapper {
  width: calc(100% - 48px);
  overflow-x: auto;
}

.top-menu > .menu-wrapper > .list {
  box-sizing: border-box;
  display: inline-flex;
  background-color: #fff;
}

.top-menu > .menu-wrapper::-webkit-scrollbar {
  display: none;
}

.top-menu > .menu-wrapper > .list > .item {
  position: relative;
  width: 68px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: #666;
}

.top-menu > .menu-wrapper > .list > .item-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: 3em;
  background-color: #f5ae14ec;
}

.top-menu > .menu-wrapper > .list > .item-active {
  color: #000;
}

.top-menu > .menu-wrapper > .list .img {
  /* width: 24px; */
  height: 18px;
}

.top-menu .text {
  text-align: center;
  margin-top: 2px;
  font-size: 13px;
}

.select-btn {
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: -2px 0 10px -4px rgba(0, 0, 0, 0.16);
}

.select-btn > .iconfont {
  font-size: 16px;
  color: rgb(0, 68, 95);
}

.select-btn > .text {
  color: rgb(0, 68, 95);
}

/* 顶部选择菜单 */
.select-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.3); */
  z-index: 1001;
}

.select-menu-mask {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
}

.select-menu-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 2;
}

.select-menu-wrapper > .selected {
  position: relative;
  width: 100%;
  height: 2.4rem;
  line-height: 2.4rem;
  font-size: 0.8rem;
  color: #999;

  border-bottom: 1px solid #eee;
}

.select-menu-wrapper > .selected > .close {
  position: absolute;
  right: -1rem;
  top: 0;
  width: 2.4rem;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
}

.select-menu-wrapper > .selected > .close > .iconfont {
  font-size: 16px;
  color: #333;
}

.select-menu-wrapper > .list {
  width: 100%;
  max-height: 40vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.select-menu-wrapper > .list::-webkit-scrollbar {
  display: none;
}

.select-menu-wrapper > .list > .item {
  width: 100%;
  height: 2.4rem;
  line-height: 2.4rem;
  font-size: 0.88rem;
  font-family: 'Times New Roman', Times, serif;
  color: #333;
}
.select-menu-wrapper > .list > .item-active {
  color: rgb(0, 68, 95);
  font-weight: bold;
}

.slide-down-leave-to,
.slide-down-enter {
  transform: translateY(-100%);
}
.slide-down-enter-to,
.slide-down-leave {
  transform: translateY(0);
}
.slide-down-enter-active,
.slide-down-leave-active {
  transition: 0.3s;
}

/* 底部菜单 */

.foot-publish {
  position: fixed;
  bottom: 0rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 401;
}

.foot-publish > .img {
  width: 4rem;
  height: 4rem;
}

/* 发布选项 */

.publish-select {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0rem;
  left: 0;
  z-index: 1001;
}

.publish-select-mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.publish-select-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}

/*拍照*/

.publish-select .publish-option {
  width: 100%;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.publish-select .publish-option span {
  font-size: 1rem;
}

.publish-select .publish-option:last-child span {
  color: #409eff;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}
.slide-up-enter-to,
.slide-up-leave {
  transform: translateY(0);
}
.slide-up-leave-active,
.slide-up-enter-active {
  transition: 0.3s;
}

/* 弹出框的样式 */

#map >>> .brief {
  width: 80vw;
  height: 6.2rem;
  box-sizing: border-box;
}
/* 简介内容 */
#map >>> .brief .leaflet-popup-close-button {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  padding: 0;
  top: 0;
  right: 0rem;
  font-size: 1rem;
  cursor: pointer;
}

#map >>> .brief .brief-box {
  width: 100%;
  padding: 0.6rem;
  box-sizing: border-box;
  display: flex;
}

#map >>> .brief .brief-icon {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 12px;
}

#map >>> .brief .brief-icon .icon {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#map >>> .brief .brief-info {
  margin-left: 0.5rem;
  width: calc(100% - 5.5rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#map >>> .brief .brief-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
}

#map >>> .brief .brief-tags {
  display: flex;
  align-items: center;
}

#map >>> .brief .brief-tags-item {
  margin-right: 6px;
  font-size: 12px;
  padding: 1px 4px;
  border: 1px solid transparent;
  border-radius: 4px;
}

#map >>> .brief .brief-tags-city {
  color: rgba(64, 160, 255, 1);
  border-color: rgba(64, 160, 255, 0.4);
}

#map >>> .brief .brief-tags-numbers {
  color: rgba(255, 179, 64, 1);
  border-color: rgba(255, 179, 64, 0.4);
}

#map >>> .brief .brief-content {
  width: 100%;
  min-height: 2.8rem;
  color: #666;
  font-size: 0.7rem;
  text-align: justify;
}

#map >>> .brief .brief-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.35rem;
}

#map >>> .brief .brief-btn-item {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#map >>> .brief .brief-btn-item .iconfont {
  color: rgb(243, 98, 40);
  font-size: 0.8rem;
}
#map >>> .brief .brief-btn-item-no .iconfont {
  color: #999;
  font-size: 0.8rem;
}
#map >>> .brief .brief-btn-item .btnname {
  color: #333;
  font-size: 0.66rem;
}
#map >>> .brief .brief-btn-item-no .btnname {
  color: #999;
}
/*  */
#map >>> .brief .leaflet-popup-content-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  padding: 0;
}
#map >>> .brief .leaflet-popup-content {
  margin: 0;
  width: 100%;
  height: 100%;
}
/* North */

/* South */
#map >>> .leaflet-resp-popup-south .leaflet-popup-tip-container {
  top: 0;
  margin-top: -20px;
}

#map >>> .leaflet-resp-popup-south .leaflet-popup-tip {
  margin: 11px auto 0;
  box-shadow: none;
}

/* West */
#map >>> .leaflet-resp-popup-west .leaflet-popup-tip-container {
  right: 0;
  margin-right: -20px;
  left: 100%;
  margin-left: -2px;
  top: 50%;
  margin-top: -11px;
}

#map >>> .leaflet-resp-popup-west .leaflet-popup-tip {
  margin: 1px -8px 0 -8px;
  box-shadow: none;
}

/* East */
#map >>> .leaflet-resp-popup-east .leaflet-popup-tip-container {
  width: 20px;
  left: 2px;
  top: 50%;
  margin-top: -11px;
}

#map >>> .leaflet-resp-popup-east .leaflet-popup-tip {
  margin: 0 11px;
  box-shadow: none;
}

/* South-East */
#map >>> .leaflet-resp-popup-south-east .leaflet-popup-tip-container {
  left: 0;
  margin-left: 0;
  top: 0;
  margin-top: -20px;
}

#map >>> .leaflet-resp-popup-south-east .leaflet-popup-tip {
  margin: 11px 11px 11px -8px;
  box-shadow: none;
}

#map >>> .leaflet-resp-popup-south-east .leaflet-popup-content-wrapper {
  border-radius: 0 20px 20px 20px;
}

/* East-South */
#map >>> .leaflet-resp-popup-east-south .leaflet-popup-tip-container {
  width: 20px;
  left: 0;
  top: 0;
}

#map >>> .leaflet-resp-popup-east-south .leaflet-popup-tip {
  margin: -8px 11px;
  box-shadow: none;
}

#map >>> .leaflet-resp-popup-east-south .leaflet-popup-content-wrapper {
  border-radius: 0 20px 20px 20px;
}

/* North-East */
#map >>> .leaflet-resp-popup-north-east .leaflet-popup-tip-container {
  left: 0;
  margin-left: 0;
  margin-top: -1px;
}

#map >>> .leaflet-resp-popup-north-east .leaflet-popup-tip {
  margin: -8px 11px 11px -8px;
  box-shadow: none;
}

#map >>> .leaflet-resp-popup-north-east .leaflet-popup-content-wrapper {
  border-radius: 20px 20px 20px 0;
}

/* East-North */
#map >>> .leaflet-resp-popup-east-north .leaflet-popup-tip-container {
  left: 0;
  margin-left: -20px;
  margin-top: -20px;
}

#map >>> .leaflet-resp-popup-east-north .leaflet-popup-tip {
  margin: 11px 11px 11px 11px;
  box-shadow: none;
}

/* North-West */
#map >>> .leaflet-resp-popup-north-west .leaflet-popup-tip-container {
  width: 20px;
  margin-right: 0;
  margin-top: -1px;
  left: 100%;
  right: 0;
}

#map >>> .leaflet-resp-popup-north-west .leaflet-popup-tip {
  margin: -8px 11px 11px 11px;
  box-shadow: none;
}

#map >>> .leaflet-resp-popup-north-west .leaflet-popup-content-wrapper {
  border-radius: 20px 20px 0 20px;
}

/* West-North */
#map >>> .leaflet-resp-popup-west-north .leaflet-popup-tip-container {
  margin-left: 0;
  margin-top: -20px;
  left: 100%;
  right: 0;
}

#map >>> .leaflet-resp-popup-west-north .leaflet-popup-tip {
  margin: 11px 11px 0px -8px;
  box-shadow: none;
}

#map >>> .leaflet-resp-popup-west-north .leaflet-popup-content-wrapper {
  border-radius: 20px 20px 0 20px;
}

/* South-West */
#map >>> .leaflet-resp-popup-south-west .leaflet-popup-tip-container {
  width: 20px;
  left: 100%;
  top: 0;
  margin-top: -20px;
}

#map >>> .leaflet-resp-popup-south-west .leaflet-popup-tip {
  margin: 11px 11px 11px 11px;
  box-shadow: none;
}

#map >>> .leaflet-resp-popup-south-west .leaflet-popup-content-wrapper {
  border-radius: 20px 0 20px 20px;
}

/* West-South */
#map >>> .leaflet-resp-popup-west-south .leaflet-popup-tip-container {
  margin-left: 0;
  margin-right: -20px;
  left: 100%;
  right: 0;
  top: 0;
}

#map >>> .leaflet-resp-popup-west-south .leaflet-popup-tip {
  margin: -8px;
  box-shadow: none;
}

#map >>> .leaflet-resp-popup-west-south .leaflet-popup-content-wrapper {
  border-radius: 20px 0 20px 20px;
}
/* 点位名称和icon */
#map >>> .leaflet-marker-pane .leaflet-marker-icon {
  position: relative;
  border: none;
  background-color: transparent;
}
#map >>> .icon-name {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -20px;
  font-size: 13px;
  left: 50%;
  border: 1px solid #333;
  border-radius: 4px;
  transform: translateX(-50%);
  padding: 0 6px;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.6);
  color: #333;
}
#map >>> .icon-name-number {
  top: -40px;
}
#map >>> .icon-name .self {
  color: #ec6002;
  font-weight: bold;
}
#map >>> .icon-name .total {
  color: #e33;
  font-weight: bold;
}
#map >>> .leaflet-marker-pane .icon-number {
  position: absolute;
  white-space: nowrap;
  top: 42%;
  font-size: 12px;
  font-weight: 600;
  left: 49%;
  transform: translate(-50%, -50%);
  color: #f6ad15;
}
#map >>> .leaflet-marker-pane .icon-value {
  width: 40px;
  height: 40px;
}

/* zoom控件 */

#map >>> .leaflet-control-zoom {
  position: fixed;
  margin: 0;
  right: 0.6rem;
  bottom: 3rem;
  z-index: 402;
  border: none;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

#map >>> .leaflet-control-zoom > .leaflet-control-zoom-in,
#map >>> .leaflet-control-zoom > .leaflet-control-zoom-out {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  color: #333;
  border-color: #eee;
  font-weight: normal;
  font-size: 1.2rem;
}

/* 生成分享图片选项 */
.generate-share {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.generate-share-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.generate-options {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;
}

.options-title {
  position: relative;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
  font-size: 1rem;
  color: #2b2b2b;
  font-weight: bold;
  box-shadow: 0 2px 10px -8px rgba(0, 0, 0, .3);
}

.options-title > .iconfont {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
  font-size: 1rem;
}

.generate-options > .options-list {
  height: 40vh;
  padding: 10px 0;
  box-sizing: border-box;
  overflow-y: auto;
}
.generate-options > .options-list > .item {
  position: relative;
  display: inline-block;
  padding: 6px 16px;
  margin: 10px;
  text-align: center;
  color: #666;
  font-size: .8rem;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2em;
}

.generate-options > .options-list > .item:active {
  background-color: rgb(245, 248, 248);
}

.generate-options > .options-list > .item-active {
  z-index: 2;
  color: #fff;
  background-color: rgb(81, 84, 93);
  /* background-color: #47c23a; */
}

.generate-options > .options-foot {
  height: 2.4rem;
  width: 100%;
  display: flex;
  box-shadow: 0 -2px 10px -8px rgba(0, 0, 0, .3);
}

.generate-options > .options-foot > .foot-item {
  width: 50%;
  height: 100%;
  line-height: 2.4rem;
  text-align: center;
  font-size: 1rem;
  background-color: #47c23a;
  color: #fff;
  box-sizing: border-box;
}

.generate-options > .options-foot > .foot-item:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.generate-options > .options-foot > .foot-disable {
  background-color: #efefef;
  color: #666;
}

/* 正在生成图片 / 遮罩，防点击 */
.generating-mask {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

/* echarts */
#echarts-box {
  width: 100vw;
  height: 80vw;
  display: none;
}

.share-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1001;
}

.share-pic-box {
  position: relative;
  width: 80vw;
  transform: translate(10vw, 14vw);
}

.share-close {
  position: absolute;
  width: 24px;
  height: 24px;
  top: -30px;
  right: -6px;
  border: 1px solid #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
}

.share-close > .iconfont {
  color: #fff;
  font-size: 12px;
}

.share-pic-box > p {
  margin-top: 10px;
  color: #fff;
  font-size: 0.8rem;
  text-align: center;
}

.share-wrapper #share-pic {
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* 发布按钮 */
.container >>> .issue_content {
  z-index: 500;
}

.container >>> .red_packet {
  z-index: 500;
}

.container >>> .red_packet_btn {
  bottom: 7.6rem;
  z-index: 500;
}

.container >>> .red_packet_bounced {
  z-index: 500;
}
</style>
