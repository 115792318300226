<template>
  <div class="ps_container">
    <!-- <div class="header">这是商户的支付页面</div> -->
    <div class="main">
      <div class="main_title">
        <span class="title_name">{{title}}</span>

        <span class="title_tip">
          <span class="title_value">码上折扣</span>
          <span v-if="is_playing">{{history_etime}}到期</span>
          <span v-else>没有进行中的活动</span>
        </span>
        <!-- <span class="title_tip"></span> -->
      </div>
      <div class="main_option">
        <!-- 选择支付方式 -->
        <div class="content_option">
          <div class="content_title">
            <span @click="show_tips = true">
              支付选项
              <i class="iconfont iconwenhao2"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="main_content">
        <!-- 结束日期 -->

        <div class="content_etime content_item">
          <span class="item_name">结束日期</span>
          <mt-datetime-picker
            ref="pick_etime"
            type="date"
            :startDate="startDate ? startDate : new Date()"
            v-model="picker_etime_Value"
            year-format="{value} 年"
            month-format="{value} 月"
            date-format="{value} 日"
            @confirm="handleEtime"
          ></mt-datetime-picker>
          <span class="item_value" @click="openEtimePicker">{{etime ? etime : "设置结束日期"}}</span>
        </div>
        <!-- </transition> -->
        <!-- 备注留言 -->
        <div class="content_note content_item">
          <span class="item_name">备注留言</span>
          <input type="text" class="item_value" placeholder="选填，给我们留言" v-model="notes" />
        </div>
      </div>
      <!-- 选择信息 -->
      <div class="main_info">
        <div class="info_day info_item">
          <span class="item_name">日期预览</span>
          <span class="item_value">{{total_time ? total_time : "-"}}</span>
        </div>
        <div class="info_money info_item">
          <span class="item_name">实付款</span>
          <span class="item_value">
            ￥
            <span style="fontWeight: 600;fontSize: 1rem;">{{payMoney}}</span>
          </span>
        </div>
      </div>
      <!-- 历史记录 -->
      <div class="main_history main_info">
        <div class="history_item info_item" @click="show_history = true">
          <span class="item_name">历史记录</span>
          <span class="item_value">
            点击查看
            <i class="iconfont iconqianjin"></i>
          </span>
        </div>
      </div>
    </div>

    <!-- 底部支付 -->
    <div class="footer">
      <div class="pay_wrapper">
        <button
          type="button"
          :disabled="!payMoney"
          class="pay_content"
          :class="payMoney ? 'pay_active' : ''"
          @click="submitData"
        >￥{{payMoney}} 提交订单</button>
      </div>
    </div>
    <!-- 历史记录 -->
    <div class="history">
      <transition name="mask">
        <div class="history_mask mask" v-show="show_history" @click.self="show_history = false"></div>
      </transition>
      <transition name="content">
        <div class="history_wrapper" v-show="show_history">
          <div class="history_close close" @click="show_history = false">
            <i class="iconfont iconbaseline-close-px"></i>
          </div>
          <div class="history_content">
            <div class="history_item" v-for="(item,i) in history_list" :key="i">
              <div class="item_row_1">
                <div class="item_name">
                  <span class="history_date">{{item.btime}}</span> -
                  <span class="history_date">{{item.etime}}</span>
                </div>
                <div class="item_value">￥{{item.amount}}</div>
              </div>
              <div class="item_row_2">支付时间：{{item.add_time}}</div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- tips -->
    <div class="pay_tip">
      <transition name="mask">
        <div class="tip_mask mask" v-show="show_tips" @click.self="show_tips = false"></div>
      </transition>
      <transition name="content">
        <div class="tip_wrapper" v-show="show_tips">
          <div class="close" @click="show_tips = false">
            <i class="iconfont iconbaseline-close-px"></i>
          </div>
          <ul class="tip_content">
            <li class="tip_item">1. 新用户首次支付可享受30天免费使用天数，超出30天的按超出天数计费；</li>
            <li class="tip_item">2. 开始日期与结束日期均包括所选择日的当日；</li>
            <li class="tip_item">3. 实际支付费用为所选总天数乘以每天的单价（新用户30天优惠除外）；</li>
            <li class="tip_item">4. 最终解释权归武汉地普游有限责任公司所有；</li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { Toast, DatetimePicker } from "mint-ui";
var wx = require("weixin-js-sdk");
export default {
  components: {},
  data() {
    return {
      // 授权信息
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      version: "1.0.0",
      park_id: 298,
      open_id: localStorage.getItem("open_id")
        ? localStorage.getItem("open_id")
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin, //-------------注意
      id: this.$route.query.id ? this.$route.query.id : 0,
      id_type_: this.$route.query.id_type_
        ? this.$route.query.id_type_
        : "sale",
      current_time: "", // 本地时间
      btime: "", // 开始日期
      etime: "", // 结束日期
      set_day: "",
      notes: "",
      picker_btime_Value: "", // 开始Date对象
      picker_etime_Value: "", // 结束Date对象
      // pick_date: 0, // 选择的时间
      pick_money: 0, // 支付金额
      sale_price: "",
      title: "",
      history_btime: "",
      history_etime: "",
      history_list: [], // 历史记录
      is_playing: "", // 进行中的
      startDate: "", // 时间选择器起始时间
      show_history: false,
      show_tips: false // 显示解释说明
      // is_option: 1 // 选择支付方式
    };
  },
  created() {
    this.initDate();
  },
  mounted() {
    if (this.token) {
    } else {
      //第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    console.log("token=" + this.token);
    //投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有union_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }

    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);

      if (this.open_id) {
      } else {
        this.authorize();
      }
    }

    this.getData(); //
  },
  computed: {
    // 选择的起始到结束日期
    total_time: function() {
      if (this.etime) {
        return `${this.btime} - ${this.etime}`;
      } else {
        return 0;
      }
    },
    // 支付的金额
    payMoney: function() {
      if (this.pick_date) {
        return this.sale_price * this.pick_date;
      } else {
        return 0;
      }
    },
    // 选择的天数
    pick_date: function() {
      let btime = this.btime ? Date.parse(this.btime) : 0;
      let etime = this.etime ? Date.parse(this.etime) : 0;
      if (!btime || !etime) {
        return 0;
      } else {
        let dur_time = (etime - btime) / 1000;
        let day = dur_time / 24 / 60 / 60 + 1;
        return day;
      }
    }
  },

  methods: {
    // 提交数据
    submitData() {
      if (
        this.token &&
        this.open_id &&
        this.id &&
        this.park_id &&
        this.btime &&
        this.etime
      ) {
        let open_url =
          "https://www.zmlxj.com/api.php/shop/web_pay_park_spots_sale?id=" +
          this.id +
          "&token=" +
          this.token +
          "&open_id=" +
          this.open_id +
          "&park_id=" +
          this.park_id +
          "&btime=" +
          this.btime +
          "&etime=" +
          this.etime +
          "&notes=" +
          this.notes;

        window.location.href = open_url;
      } else {
        this.authorize();
      }
    },
    getData() {
      if (this.token && this.open_id && this.id) {
        this.$ajax(
          "post",
          "Shop/get_sale_record_list",
          {
            token: this.token,
            id: this.id,
            open_id: this.open_id
          },
          res => {
            if (res.data.id == 0) {
              console.log(res.data.data.data.sale_price);
              this.sale_price = res.data.data.data.sale_price;
              document.title = this.title = res.data.data.data.title;
              this.history_btime = res.data.data.data.btime;
              // this.history_etime = 0;
              this.history_etime = res.data.data.data.etime;
              this.history_list = res.data.data.list ? res.data.data.list : "";
              this.wx_share(window.location.href, {
                title: res.data.data.data.title,
                content: res.data.data.data.content,
                img: res.data.data.data.ShareImageUrl
              });
              // 正在进行中
              this.is_playing = (() => {
                if (this.history_etime) {
                  let etime = Date.parse(this.history_etime),
                    ctime = Date.parse(new Date());
                  if (ctime <= etime) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              })();

              // 日期选择器起始时间
              this.startDate = (() => {
                let day = 24 * 60 * 60 * 1000;
                return this.is_playing
                  ? new Date(Date.parse(this.history_etime) + day)
                  : new Date();
              })();

              this.btime = this.forMatDate("YYYY-mm-dd", this.startDate);
            } else {
              Toast("获取数据失败");
            }
          }
        );
      } else {
        this.authorize();
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=" +
        this.token +
        "&id=" +
        this.id +
        "&state=sale";
      window.location.href = open_url;
    },

    // 结束日期选择器
    openEtimePicker() {
      this.$refs.pick_etime.open();
    },

    // 处理选择的结束日期
    handleEtime() {
      this.etime = this.forMatDate("YYYY-mm-dd", this.picker_etime_Value);
    },
    // 格式化选择的Date对象
    forMatDate(fmt, date) {
      if (date && date instanceof Date) {
        let ret;
        let opt = {
          "Y+": date.getFullYear().toString(), // 年
          "m+": (date.getMonth() + 1).toString(), // 月
          "d+": date.getDate().toString() // 日
          // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
          ret = new RegExp("(" + k + ")").exec(fmt);
          if (ret) {
            fmt = fmt.replace(
              ret[1],
              ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
            );
          }
        }
        return fmt;
      } else {
        Toast("日期选择错误");
      }
    },
    // 获取本地时间
    initDate() {
      let date = new Date();
      this.current_time = this.forMatDate("YYYY-mm-dd", date);
    }
  }
};
</script>

<style scoped>
.ps_container {
  width: 100%;
  height: 100%;
  background-color: rgb(238, 241, 243);
}

.ps_container .main {
  width: 100%;
}
.ps_container .main .main_option {
  width: 100%;
  background-color: #fff;
  /* margin-bottom: 0.4rem; */
  border-bottom: 1px solid #eee;
}
.ps_container .main .main_content {
  width: 100%;
  background-color: #fff;
}
.ps_container .main .main_title {
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #333;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  padding: 0 1rem;
  background-color: #fff;
  margin-bottom: 0.4rem;
}
.ps_container .main .main_title .title_name {
  position: relative;
  width: 100%;
  font-size: 1rem;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
}

.ps_container .main .main_title .title_value {
  color: rgb(248, 32, 32);
  /* background-color: rgb(248, 32, 32); */
  border: 1px solid rgb(248, 32, 32);
  margin-bottom: 0.2rem;
  padding: 0 4px;
}
.ps_container .main .main_title .title_tip {
  color: #999;
}

/* 支付选择 */

.ps_container .main .content_option {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 0 1rem;
  /* position: relative; */
}
.ps_container .main .option_item {
  width: 35%;
  height: 2.4rem;
  display: flex;
  align-items: center;
  font-size: 0.72rem;
  color: #999;
}
.ps_container .main .option_active {
  color: #333;
}

.ps_container .main .option_item .option_value i {
  font-size: 0.8rem;
}
.ps_container .main .content_item {
  width: 100%;
  height: 2.4rem;
  display: flex;
  align-items: center;
  font-size: 0.88rem;
  box-sizing: border-box;
  padding: 0 1rem;
  /* border-bottom: 1px solid #eee; */
}
.ps_container .main .content_title {
  width: 30%;
  height: 2.4rem;
  display: flex;
  align-items: center;
  font-size: 0.88rem;
  /* border-bottom: 1px solid #eee; */
  color: #333;
  font-weight: 600;
}
.ps_container .main .content_title span {
  display: flex;
  align-items: center;
}
.ps_container .main .content_title i {
  margin-left: 4px;
  /* display: inline-block; */
  margin-top: 3px;
  font-size: 1rem;
  color: #aaa;
}

.ps_container .main .content_item .item_name {
  width: 30%;
  height: 2.4rem;
  line-height: 2.4rem;
  color: #333;
}
.ps_container .main .content_item .item_value {
  width: 70%;
  height: 2.4rem;
  line-height: 2.4rem;
  color: #999;
  font-size: 0.88rem;
}
.ps_container .main .content_item .item_value::placeholder {
  color: #999;
}

/* 选择信息 */
.ps_container .main .main_info {
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.4rem;
  background-color: #fff;
  padding: 0 1rem;
}
.ps_container .main .main_info .info_item {
  width: 100%;
  height: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.88rem;
  /* border-bottom: 1px solid #eee; */
}
.ps_container .main .main_info .info_day {
  border-bottom: 1px solid #eee;
}
.ps_container .main .main_info .info_item .item_name {
  color: #333;
}
.ps_container .main .main_info .info_item .item_value {
  color: rgb(240, 131, 8);
  /* font-weight: 600; */
}
.ps_container .main .main_history .info_item .item_value {
  height: 100%;
  color: #999;
  font-size: 0.8rem;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.ps_container .main .main_history .info_item .item_value i {
  font-size: 0.88rem;
  color: #999;
  margin-top: 2px;
}
/* 底部 */
.ps_container .footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3rem;
  background-color: #fff;
  border-top: 1px solid #eee;
  z-index: 2;
}
.ps_container .footer .pay_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.ps_container .footer .pay_wrapper .pay_content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #999;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  -webkit-user-select: none;
  border: none;
}
.ps_container .footer .pay_wrapper .pay_active {
  background-color: #e33;
}

/* mask遮罩层 */
.mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
}
.ps_container .history .history_wrapper {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 1.8rem 1rem 1rem;
  box-sizing: border-box;
  z-index: 3;
}
/* 关闭按钮 */
.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
}
.close i {
  font-size: 1.2rem;
}
.ps_container .history .history_wrapper .history_content {
  width: 100%;
  height: 12rem;
  font-size: 0.8rem;
  color: #999;
  overflow-y: auto;
}
.ps_container .history .history_wrapper .history_item {
  /* height: 2.4rem; */
  font-size: 0.8rem;
  color: #999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.ps_container .history .history_wrapper .history_item .item_row_1 {
  /* height: 2.4rem; */
  font-size: 0.8rem;
  color: #999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ps_container .history .history_wrapper .history_item .item_row_2 {
  font-size: 0.6rem;
  width: 100%;
  text-align: center;
}
.ps_container .history .history_wrapper .history_item .history_date {
  display: inline-block;
  padding: 4px 8px;
  /* background-color: #eee; */
  border-radius: 4px;
  color: #666;
}
.ps_container .history .history_wrapper .history_item .item_value {
  font-size: 0.8rem;
  font-weight: 600;
  color: #444;
}
.mask-enter,
.mask-leave-to {
  opacity: 0;
}
.mask-enter-to,
.mask-leave {
  opacity: 1;
}
.mask-enter-active,
.mask-leave-active {
  transition: ease 0.3s;
}

.content-enter,
.content-leave-to {
  transform: translateY(100%);
}
.content-enter-to,
.content-leave {
  transform: translateY(0);
}
.content-enter-active,
.content-leave-active {
  transition: ease 0.3s;
}

.pay_tip .tip_wrapper {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 1.8rem 1rem 1rem;
  box-sizing: border-box;
  z-index: 3;
}
.pay_tip .tip_wrapper .tip_content {
  width: 100%;
  height: 12rem;
  font-size: 0.8rem;
  color: #666;
  overflow-y: auto;
}
.pay_tip .tip_wrapper .tip_content .tip_item {
  line-height: 1.4;
  margin-top: 6px;
  font-size: 0.8rem;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>