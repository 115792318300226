<template>
  <div>
    <ServiceTitle></ServiceTitle>

    <div class="page-content">
      <div class="new-title-content">游玩详情</div>
      <div class="withdraw-wrap"></div>

      <router-link class="add-detail" :to="addDetailLink">+ 添加下一个游玩点</router-link>
    </div>
  </div>
</template>

<style scoped>
.item-content-title {
  padding-left: 10px;
  font-size: 14px;
  color: #ff9a80;
  margin-bottom: 10px;
}
.item-detail {
  padding-left: 10px;
  font-size: 12px;
  color: #cdcdcd;
}
.add-detail {
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 45%;
  height: 30px;
  line-height: 30px;
  color: #ff9a80;
  font-size: 14px;
  border: 1px solid #ff9a80;
  border-radius: 30px;
}
</style>

<script>
import ServiceTitle from "../../components/ServiceTitle";

export default {
  name: "VisitedDescribe",
  components: {
    ServiceTitle
  },
  data() {
    return {
      addDetailLink: ""
    };
  },
  mounted() {
    this.getServiceType();
  },
  methods: {
    getServiceType() {
      var stype = this.$route.query.type;
      this.addDetailLink = "/footguide/AddVisitPoint?type=" + stype;
    }
  }
};
</script>
