<template>
  <div class="container dylan">
    <div class="map-wrapper">
      <!-- leaftmap容器 -->
      <div id="map_"></div>
      <!-- 顶部菜单 -->
      <div class="top-nav">
        <div class="top-nav-wrapper">
          <ul class="menu-list">
            <li
              class="menu-item"
              v-for="(item,i) in bottom_menu_list"
              :class="check_menu === i ? 'menu-item-active' : ''"
              :key="i"
              @click="menuClickHandle(item.id, i)"
            >
              <p class="menu-name">{{item.title}}</p>
            </li>
          </ul>
        </div>
      </div>
      <!-- 详情 -->
      <transition name="bounce">
        <div class="detail" v-show="show_brief" ref="detail">
          <div class="detail-mask"></div>
          <!-- <div
            class="detail-btn"
            @touchend="detailTouchEnd($event)"
            @touchstart="detailTouchStart($event)"
            @touchmove="detailTouchMove($event)"
          > -->
            <div class="detail-btn" @click="show_brief = false">
            <i class="iconfont iconxiangxia"></i>
          </div>
          <div class="detail-wrapper" v-if="point_item.type_">
            <div class="detail-img">
              <img :src="point_item.newUrl" alt />
            </div>
            <div class="detail-title">
              <i class="iconfont iconlingxing1"></i>
              <p>{{point_item.spots_name}}</p>
            </div>
            <div class="detail-star" v-if="point_item.star && point_item.star != '0'">
              <!-- <span :style="'width:' + (point_item.star * 1) / 3 * 100+ '%;'"></span> -->
              <img v-for="item in (point_item.star * 1)" src="/static/map/star_light.png" alt :key="item"/>
              <img v-for="item in (3 - point_item.star * 1)" src="/static/map/star_grey.png" alt  :key="item"/>
            </div>
            <div class="detail-address" v-show="point_item.address">
              <p>
                <i class="iconfont iconShape1"></i>
                {{clampText(point_item.address,20)}}
              </p>
              <p>
                距您
                <span>{{point_item.distance || "---"}}</span>
                米
              </p>
            </div>
            <div class="detail-time" v-show="point_item.open_time">
              <p>
                <i class="iconfont iconshijian"></i>
                {{point_item.open_time}}
              </p>
            </div>

            <div class="content-wrapper">
              <div class="detail-content">
                <p class="detail-content-html" v-html="point_item.zhContent_"></p>
              </div>
            </div>
            <div class="detail-nav">
              <button @click="jumpPos">开始导航</button>
            </div>
          </div>
        </div>
      </transition>
      <!-- 定位按钮 -->
      <div
        class="ll-location"
        :class="locate === null ? '' : 'locate-active'"
        @click="updateLocation"
      >
        <i class="iconfont icondingwei"></i>
        <!-- <p>定位</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'mint-ui'

import { LMap, LTileLayer, LMarker, LPopup, LImageOverlay } from 'vue2-leaflet'
import 'leaflet.markercluster/dist/MarkerCluster.css' // leaflet 点位聚合插件
import 'leaflet.markercluster/dist/MarkerCluster.Default.css' // leaflet 点位聚合插件
import 'leaflet.markercluster' // leaflet 点位聚合插件
import 'leaflet-responsive-popup' // leaflet popup弹出框插件
import 'leaflet.polyline.snakeanim' // leaflet 折线动画插件
import 'leaflet.heat' // 热力图插件

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LImageOverlay
  },
  data () {
    return {
      // 初始数据
      park_id: 298,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      label_id: this.$route.query.id ? this.$route.query.id : 0,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      id: this.$route.query.id ? this.$route.query.id : 0,
      ts_type_: 'label',
      id_type_: 'leafletMap',

      // 请求数据
      bottom_menu_list: [], // 底部导航条
      map_data: [], // 地图对象
      point_list: [], // 点位列表
      title_list: [], // 标题列表
      tel_: '', // 联系电话
      is_pay: 0, // 是否支付
      price: 0, // 价格
      backUrl: 'leafletMapV2',
      is_draw_rect: 0, // 画矩形区域

      // icon
      // 15 停车场  16售票厅或车站  17厕所  18美食  19景点  20购物  21住宿  24文化 26民宿
      icon_object: {
        iconMap15: require('../../assets/shanghai_1766/map_icon_15.png'),
        iconMap16: require('../../assets/shanghai_1766/map_icon_16.png'),
        iconMap17: require('../../assets/shanghai_1766/map_icon_17.png'),
        iconMap18: require('../../assets/shanghai_1766/map_icon_18.png'),
        iconMap19: require('../../assets/shanghai_1766/map_icon_19.png'),
        iconMap24: require('../../assets/shanghai_1766/map_icon_24.png'),
        iconMap26: require('../../assets/shanghai_1766/map_icon_26.png'),
        iconMapCluster: require('../../assets/shanghai_1766/map_icon_cluster.png'),

        iconMapShadow15: require('../../assets/shanghai_1766/map_icon_shadow_15.png'),
        iconMapShadow16: require('../../assets/shanghai_1766/map_icon_shadow_16.png'),
        iconMapShadow17: require('../../assets/shanghai_1766/map_icon_shadow_17.png'),
        iconMapShadow18: require('../../assets/shanghai_1766/map_icon_shadow_18.png'),
        iconMapShadow19: require('../../assets/shanghai_1766/map_icon_shadow_19.png'),
        iconMapShadow24: require('../../assets/shanghai_1766/map_icon_shadow_24.png'),
        iconMapShadow26: require('../../assets/shanghai_1766/map_icon_shadow_26.png')
      },

      mapExtent: [], // 地图西南和东北的点位列表
      zoom: 16, // 地图初始缩放
      mapMinZoom: 16, // 地图最小缩放
      mapMaxZoom: 20, // 地图最大缩放
      bounds: [], // 地图矩形区域对象
      map: null, // 地图对象
      zIndex: 1000,
      show_brief: false,

      check_menu: -1,
      first_menu_type: '', // 初始目录类型
      marker_group: {}, // marker列表
      marker_list: [],
      search_value: '', // 搜索内容
      show_point_brief: false,
      point_id: '', // 当前点位的id
      point_item: {}, // 当前点位
      marker_id: '', // 当前显示的popup的marker的id

      show_spot_brief: false, // 显示地图简介
      locate: null, // 定位locate对象
      locate_marker: null, // 定位marker对象
      all_star: 3 // 星级
    }
  },
  watch: {
    // 根据id获取点位对象
    point_id: function (val) {
      this.point_item = this.point_list.filter((item, i) => {
        return item.id == val
      })[0]
    }
  },

  mounted () {
    if (this.token) {
      // 已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }
    // if (this.open_id) {
    // } else {
    //   this.authorize();
    // }
    // if (this.is_weixin) {
    //   this.dealWithUrl(window.location.href);
    // }
    this.getData()
  },
  methods: {
    //
    // detailTouchMove(e) {
    //   let win_height = window.innerHeight,
    //     move_height = e.touches[0].clientY,
    //     detail = this.$refs.detail;
    //   detail.style.height = win_height - move_height + "px";
    // },
    // detailTouchEnd(e) {
    // },
    // detailTouchStart(e) {
    //   e.preventDefault();
    // },
    // 定位
    updateLocation () {
      if (this.locate != null) {
        this.locate = null
        if (this.locate_marker !== null) {
          this.map.removeLayer(this.locate_marker)
          this.locate_marker = null
        }
        this.map.stopLocate()
        return
      }
      this.locate = this.map.locate({
        setView: false,
        watch: true,
        maxZoom: this.mapMaxZoom,
        maximumAge: 3000,
        timeout: 3000
      })
      // console.log(this.locate);

      this.map.on('locationfound', e => {
        // console.log(e);
        if (
          e.latlng.lng < this.mapExtent[0] ||
          e.latlng.lng > this.mapExtent[2] ||
          e.latlng.lat < this.mapExtent[1] ||
          e.latlng.lat > this.mapExtent[3]
        ) {
          Toast('你不在该地图区域')
          this.map.stopLocate()
          this.locate = null
          return
        }
        if (this.locate_marker != null) {
          this.map.removeLayer(this.locate_marker)
        } else {
          this.map.setView(e.latlng)
        }
        this.locate_marker = L.marker(e.latlng)
          .addTo(this.map)
          .bindPopup('你在这里')
        this.computeDistance(e.latlng)
      })
      this.map.on('locationerror', e => {
        Toast('定位失败,请允许权限')
        this.map.stopLocate()
        this.locate = null
        this.locate_marker = null
      })
    },

    // 点击目录按钮
    menuClickHandle (id, index) {
      if (this.check_menu !== index) {
        this.map.setView(
          [
            (this.mapExtent[1] + this.mapExtent[3]) / 2,
            (this.mapExtent[0] + this.mapExtent[2]) / 2
          ],
          this.zoom
        )
        this.check_menu = index
        this.first_menu_type = id
        this.marker_list.forEach((item, i) => {
          this.marker_group.removeLayer(item)
          if (
            (typeof id === 'string' && id == item.type_) ||
            (typeof id === 'object' && id.includes(item.type_ * 1))
          ) {
            this.marker_group.addLayer(item)
          }
        })
      } else {
        this.marker_list.forEach(item => {
          if (
            (typeof id === 'string' && id != item.type_) ||
            (typeof id === 'object' && !id.includes(item.type_ * 1))
          ) {
            this.marker_group.addLayer(item)
          }
        })
        this.check_menu = -1
        typeof id === 'string'
          ? (this.first_menu_type = '-1')
          : (this.first_menu_type = [-1])
      }
    },

    // 显示marker的弹出层
    togglePopup (marker) {
      // 如果已经打开  就不继续执行
      if (this.marker_id == marker.id) {
        // this.marker_id = "";
        return
      }

      let visibleOne = this.marker_group.getVisibleParent(marker)
      this.map.setView(marker._latlng, this.mapMaxZoom - 1)

      let iconUrl = this.icon_object['iconMapShadow' + marker.type_]
      // 更改icon
      let myIcon = this.setDivIcon(marker, 1)
      marker.setIcon(myIcon)

      marker.setZIndexOffset(this.zIndex)
      this.zIndex++
      this.point_id = marker.id

      if (marker.getPopup()) {
        setTimeout(() => {
          marker.openPopup()
          this.marker_id = marker.id
          this.btnClickHandle(marker)
        }, 300)
      } else {
        this.createPopup(marker)
        setTimeout(() => {
          marker.openPopup()
          this.marker_id = marker.id
          this.btnClickHandle(marker)
        }, 300)
      }
    },
    // 按钮点击事件
    btnClickHandle (marker) {
      if (
        marker.type_ == '15' ||
        marker.type_ == '16' ||
        marker.type_ == '17'
      ) {
        let nav = document.querySelector('.brief-nav')
        nav.addEventListener('click', () => {
          this.jumpPos()
        })
      } else {
        let wrapper = document.querySelector('.brief-wrapper')
        wrapper.addEventListener('click', () => {
          this.show_brief = true
        })
      }
    },
    // 创建弹出框
    createPopup (marker) {
      let popup = L.responsivePopup({
        maxWidth: 'auto',
        keepInView: false,
        className: 'brief',
        hasTip: true, // 三角形箭头。。
        autoPan: false,
        closeButton: false, // 不要关闭按钮
        offset: [15, 15]
      })
        .setLatLng(marker._latlng)
        .setContent(this.createPopupContent(marker))
      marker.bindPopup(popup)
    },
    // 长文本截取
    clampText (tx, n) {
      tx = tx.replace(/(^\s*)|(\s*$)/g, '')
      if (tx.length <= n) {
        return tx
      } else {
        return tx.substr(0, n) + '...'
      }
    },

    // 创建弹出框中的内容
    createPopupContent (marker) {
      let title,
        img,
        nav,
        adress,
        distance,
        star,
        star_light = '',
        star_grey = '',
        body,
        html
      title = "<div class='brief-title'>" + marker.spots_name + '</div>'
      img = "<div class='brief-icon'><img src=" + marker.logo_ + '></div>'
      if (marker.distance) {
        distance =
          "<div class='brief-distance'>距您 " +
          '<span>' +
          marker.distance +
          ' </span>米</div>'
      } else {
        distance = "<div class='brief-distance'>距您 <span>---</span> 米</div>"
      }

      if (marker.type_ == '19' || marker.type_ == '24') {
        adress = "<div class='brief-address'>" + marker.address + '</div>'
        body =
          "<div class='brief-body'>" + title + adress + distance + '</div>'
        html = "<div class='brief-wrapper'>" + img + body + '</div>'
      } else if (marker.type_ == '18' || marker.type_ == '26') {
        let star1 = "<img src='/static/map/star_light.png' />"

        let star2 = "<img src='/static/map/star_grey.png' />"

        if (marker.star && marker.star != '0') {
          for (let i = 0; i < marker.star * 1; i++) {
            star_light += star1
          }
          for (let i = 0; i < this.all_star - marker.star * 1; i++) {
            star_grey += star2
          }

          star = "<div class='brief-star'>" + star_light + star_grey + '</div>'
        } else {
          star = ''
        }

        adress = "<div class='brief-address'>" + marker.address + '</div>'
        body =
          "<div class='brief-body'>" +
          title +
          adress +
          distance +
          star +
          '</div>'
        html = "<div class='brief-wrapper'>" + img + body + '</div>'
      } else if (
        marker.type_ == '15' ||
        marker.type_ == '16' ||
        marker.type_ == '17'
      ) {
        nav = "<div class='brief-nav'>GO</div>"
        body = "<div class='brief-body'>" + title + distance + '</div>'
        html = "<div class='brief-wrapper'>" + img + body + nav + '</div>'
      }
      return html
    },
    // 首次进入地图时定位
    firstLocation () {
      let locate = this.map.locate({
        setView: false,
        timeout: 3000
      })

      this.map.on('locationfound', e => {
        // e.latlng
        this.first_latlng = e.latlng
        this.computeDistance(e.latlng)
      })
      this.map.on('locationerror', e => {
        Toast('定位失败,请允许权限')
        this.map.stopLocate()
      })
    },
    // 根据距离排序
    computeDistance (latlng) {
      this.point_list.forEach((item, i) => {
        let c_latlng = L.latLng(item.lat * 1, item.lng * 1)
        let distance = this.map.distance(latlng, c_latlng)
        item.distance = Math.round(distance)
        this.marker_list[i].distance = Math.round(distance)
      })
    },
    // 初始化leaflet
    initMap () {
      // console.log(this.mapExtent);
      // 创建矩形区域
      let bounds = new L.LatLngBounds(
        new L.LatLng(this.mapExtent[1], this.mapExtent[0]),
        new L.LatLng(this.mapExtent[3], this.mapExtent[2])
      )
      // 创建map
      this.map = L.map('map_', {
        maxBounds: bounds,
        maxBoundsViscosity: 1.0,
        zoomControl: false,
        attributionControl: false
      }).fitBounds(bounds)
      // L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      //   this.map
      // );
      // 初始显示区域和缩放级别
      this.map.setView(
        [
          (this.mapExtent[1] + this.mapExtent[3]) / 2,
          (this.mapExtent[0] + this.mapExtent[2]) / 2
        ],
        this.zoom
      )
      let layer
      let options = {
        minZoom: this.mapMinZoom,
        maxZoom: this.mapMaxZoom,
        opacity: 1.0,
        tms: false
      }
      // 切片层
      layer = L.tileLayer(
        '/static/map_tiles_1766/{z}/{x}/{y}.png',
        options
      ).addTo(this.map)

      // 弹出层显示时添加marker标记
      this.map.on('popupopen', e => {})
      this.map.on('popupclose', e => {
        setTimeout(() => {
          let marker = this.marker_list.filter(item => {
            return this.marker_id === item.id
          })[0]
          let divIcon = this.setDivIcon(marker)
          marker.setIcon(divIcon)

          this.marker_id = ''
        }, 0)
      })

      this.firstLocation()
    },
    setDivIcon (ls, flag) {
      let iconUrl
      if (flag) {
        iconUrl = this.icon_object['iconMapShadow' + ls.type_]
      } else {
        iconUrl = this.icon_object['iconMap' + ls.type_]
      }
      let divIcon = L.divIcon({
        html:
          "<p class='icon-name'>" +
          ls.spots_name +
          '</p>' +
          "<img class='icon-value' src='" +
          iconUrl +
          "'/>",
        // iconUrl: iconUrl,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [0, -20]
      })
      return divIcon
    },
    // 初始化marker点位
    initPoint (ls) {
      for (let i = 0; i < ls.length; i++) {
        let divIcon = this.setDivIcon(ls[i])
        // 创建marker
        let marker = L.marker(L.latLng(ls[i].lat * 1, ls[i].lng * 1), {
          icon: divIcon
        })

        // 将部分点位属性绑定到marker上
        marker.id = ls[i].id
        marker.logo_ = ls[i].logo_
        marker.type_ = ls[i].type_
        marker.spots_name = ls[i].spots_name
        marker.spots_content = ls[i].spots_content
        marker.zhAudioUrl = ls[i].zhAudioUrl
        marker.enAudioUrl = ls[i].enAudioUrl
        marker.address = ls[i].address
        marker.star = ls[i].star
        marker.on('click', () => {
          this.togglePopup(marker)
        })

        this.marker_list.push(marker)
      }
    },
    // 聚合点位
    initCluster (ls) {
      let markers = L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        removeOutsideVisibleBounds: false,
        disableClusteringAtZoom: this.mapMaxZoom - 1,
        maxClusterRadius: 40,
        iconCreateFunction: cluster => {
          let _markers = cluster.getAllChildMarkers()

          let markerName =
            _markers[0].spots_name.length > 3
              ? this.clampText(_markers[0].spots_name, 3) + '等'
              : _markers[0].spots_name
          return L.divIcon({
            html:
              "<p class='icon-name'>" +
              markerName +
              '</p>' +
              "<p class='icon-number'>" +
              cluster.getChildCount() +
              '</p>' +
              "<img class='icon-value' src='" +
              this.icon_object['iconMapCluster'] +
              "'/>",
            iconSize: [40, 40],
            iconAnchor: [20, 40]
          })
        }
      })
      return markers.addLayer(L.layerGroup(ls))
    },

    jumpPos () {
      // 导航
      if (this.is_weixin) {
        if (this.price > 0) {
          // 收费
          if (this.is_pay) {
            // 已经支付了
            this.wx_nav(
              window.location.href,
              this.point_item.lng,
              this.point_item.lat,
              this.point_item.spots_name,
              this.point_item.address
            )
          } else {
            // 还未支付
            if (this.open_id) {
              // 进行支付
              var open_url =
                'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
                this.id +
                '&backUrl=' +
                this.backUrl +
                '&open_id=' +
                this.open_id +
                '&token=' +
                this.token +
                '&park_id=' +
                this.park_id +
                '&version=' +
                this.version +
                '&user_id='
              window.location.href = open_url
            } else {
              // 进行微信授权
              this.authorize()
            }
          }
        } else {
          // 免费
          this.wx_nav(
            window.location.href,
            this.point_item.lng,
            this.point_item.lat,
            this.point_item.spots_name,
            this.point_item.address
          )
        }
      } else {
        Toast('请在微信中打开,使用导航')
      }
    },

    getData () {
      if (this.id && this.park_id && this.id_type_ && this.token) {
        this.$ajax(
          'post',
          'SpotsRace/get_map_data_by_label',
          {
            park_id: this.park_id,
            id: this.label_id,
            id_type_: this.id_type_,
            page: 1,
            where: 'map',
            token: this.token,
            is_type: 2,
            open_id: this.open_id,
            version: this.version
          },
          res => {
            if (res.data.id == 0) {
              //   console.log(res.data.data);
              this.bottom_menu_list = res.data.data.bottom_menu_list.filter(item => {
                return item.id !== 'line'
              })

              this.map_data = res.data.data.map_data
              this.point_list = res.data.data.point_list
              this.title_list = res.data.data.title_list
              this.tel_ = res.data.data.tel_
              this.is_pay = res.data.data.is_pay || 1
              this.price = res.data.data.price || 0
              this.is_draw_rect = res.data.data.is_draw_rect || 0
              this.zoom = this.map_data.init_zoom * 1 || 16
              this.mapMinZoom = this.map_data.min_zoom * 1 || 16
              this.mapMaxZoom = this.map_data.max_zoom * 1 || 20

              document.title = this.map_data.label_name
              this.mapExtent = [
                this.map_data.left_lng * 1,
                this.map_data.left_lat * 1,
                this.map_data.right_lng * 1,
                this.map_data.right_lat * 1
              ]

              // 微信分享
              localStorage.setItem(
                'wx_content',
                res.data.data.map_data.content
              )
              localStorage.setItem(
                'wx_title',
                res.data.data.map_data.label_name
              )
              localStorage.setItem('wx_img', res.data.data.map_data.map_)

              this.wx_share(window.location.href)

              this.first_menu_type =
                typeof this.bottom_menu_list[0].id === 'string' ? '19' : [19]

              this.initMap() // 初始化地图
              this.initPoint(this.point_list) // 初始化点位

              this.loadCluster()
            }
          }
        )
      } else {
        console.log('参数错误')
      }
    },
    loadCluster () {
      if (L.markerClusterGroup) {
        this.marker_group = this.initCluster(this.marker_list)
        setTimeout(() => {
          this.map.addLayer(this.marker_group)
          this.menuClickHandle(this.first_menu_type, 0)
        }, 100)
      } else {
        console.log('延时加载')
        setTimeout(() => {
          this.loadCluster()
        }, 100)
      }
    },

    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&id_=0&state=' +
        this.backUrl
      window.location.href = open_url
    }
  }
}
</script>
<style scoped>
.container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* 地图页 */
.map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  box-sizing: border-box;
}

#map_ {
  width: 100%;
  /* margin-top: 2.4rem; */
  height: 100%;
}

/* 顶部导航 */
.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  overflow: hidden;
  width: 100%;
  z-index: 404;
  background-color: #fff;
  transition: ease 0.3s;
  box-shadow: 0 2px 4px -2px #ccc;
}

.top-nav .top-nav-wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #eee; */
}
.top-nav .top-nav-wrapper .menu-list {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-nav .top-nav-wrapper .menu-item {
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.38);
  text-align: center;
  /* line-height: 2.4rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Regular;
  letter-spacing: 0;
}
.top-nav .top-nav-wrapper .menu-item-active {
  font-size: 1rem;
  font-family: PingFangSC-Semibold;
  color: #bf863c;
  font-weight: bold;
}

/* 详情 */
.bounce-enter,
.bounce-leave-to {
  transform: translateY(100%);
}
.bounce-enter-to,
.bounce-leave {
  transform: translateY(0);
}
.bounce-enter-active,
.bounce-leave-active {
  transition: 0.3s;
}
.detail {
  width: 100%;
  height: 85%;
  position: fixed;
  z-index: 403;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 0.6rem 0.6rem 0 0;
  overflow-y: auto;
  box-shadow: 0 -2px 4px -2px #00000011;
}
.detail .detail-wrapper {
  width: 100%;
  height: calc(100% - 1.5rem);
  overflow-y: auto;
}
.detail-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: transparent;
}
.detail .detail-btn {
  width: 100%;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}
.detail .detail-btn i {
  color: #d0ced8;
  font-size: 23px;
}

.detail .detail-img {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.5rem;
}
.detail .detail-img img {
  width: 100%;
  border-radius: 2px;
}
.detail .detail-title {
  display: flex;
  align-items: center;
  font-family: FZFYSJW--GB1-0;
  font-size: 1.12rem;
  color: #000000;
  letter-spacing: 0;
  font-weight: bold;
  margin: 0.6rem 0.5rem;
}
.detail .detail-title i {
  margin-right: 4px;
  font-size: 10px;
  color: rgb(194, 122, 35);
}
.detail .detail-star {
  position: relative;
  height: 1rem;
  /* width: 3rem; */
  margin: 0.5rem;
  display: flex;
  align-items: center;
}

.detail .detail-star img {
  width: 1rem;
  height: 1rem;
}
.detail .detail-address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  margin: 6px 0;
  font-family: PingFangSC-Regular;
  font-size: 0.78rem;
  color: #000000;
  letter-spacing: 0;
}
.detail .detail-address i {
  /* margin-right: 2px; */
  font-size: 12px;
  color: rgba(191, 134, 60, 0.6);
  margin-left: 3px;
}
.detail .detail-address span {
  font-family: PingFangSC-Semibold;
  color: #ff972d;
  letter-spacing: 0;
}
.detail .detail-time {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  margin: 6px 0;
  font-family: PingFangSC-Regular;
  font-size: 0.78rem;
  color: #000000;
  letter-spacing: 0;
}
.detail .detail-time i {
  /* margin-right: 2px; */
  font-size: 12px;
  color: rgba(191, 134, 60, 0.6);
  margin-left: 3px;
}
.content-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
}
.detail .detail-content {
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #c4c4c4;
  /* padding: 0.5rem 0; */
  font-family: PingFangSC-Regular;
  font-size: 0.78rem;
  color: #474747;
  letter-spacing: 0;
  text-align: justify;
  padding-top: 0.5rem;
}
.detail .detail-content .detail-content-html >>> img {
  width: 100%;
}
.detail .detail-content .detail-content-html >>> table {
  width: 100% !important;
}
.detail .detail-nav {
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  margin-bottom: 1rem;
}
.detail .detail-nav button {
  background-color: rgba(191, 134, 60, 0.8);
  border: none;
  outline: none;
  border-radius: 2em;
  width: 73.3%;
  height: 42px;
  line-height: 42px;
  box-shadow: 0 0 2px #00000011;

  font-family: PingFangSC-Semibold;
  font-size: 1rem;
  color: #ffffff;
  letter-spacing: 0;
}

/* 联系我们 */

.dylan >>> .contact_us .contact_6 {
  z-index: 401;
}
/* 发布按钮 */
.dylan >>> .issue_container .issue_content {
  z-index: 401;
}
/* 定位 */
.ll-location {
  width: 2rem;
  height: 2rem;
  position: fixed;
  bottom: 5.2rem;
  left: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 1px 1px 2px #aaa;
  border-radius: 4px;
  z-index: 401;
  transition: ease 0.3s;
}
.ll-location i {
  font-size: 1rem;
  color: #999;
}
.ll-location p {
  font-size: 0.66rem;
  color: #999;
}
.locate-active {
  background-color: rgb(250, 88, 59);
  transition: ease 0.3s;
}
.locate-active i {
  color: rgb(250, 250, 250);
}
.locate-active p {
  color: rgb(250, 250, 250);
}
/* 弹出框的样式 */

#map_ >>> .brief {
  width: 89vw;
  height: 5.6rem;
  box-sizing: border-box;
}
/* 简介内容 */
#map_ >>> .brief .leaflet-popup-tip-container .leaflet-popup-tip {
  border-radius: 2px;
  margin-top: -12px;
}
#map_ >>> .brief .leaflet-popup-close-button {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  padding: 0;
  top: 0;
  right: 0rem;
  font-size: 1rem;
}
#map_ >>> .brief .brief-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.7rem;
  display: flex;
}
#map_ >>> .brief .brief-icon {
  width: 4.2rem;
  height: 4.2rem;
}
#map_ >>> .brief .brief-icon img {
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
}
#map_ >>> .brief .brief-body {
  position: relative;
  width: calc(100% - 4.2rem);
  padding-left: 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
#map_ >>> .brief .brief-body .brief-title {
  width: calc(100% - 3rem);
  height: 1.8rem;
  line-height: 1.8rem;
  font-weight: bold;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: PingFangSC-Semibold;
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0;
}
#map_ >>> .brief .brief-body .brief-address {
  width: 100%;
  height: 1.2rem;
  line-height: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: PingFangSC-Regular;
  font-size: 0.78rem;
  color: #000000;
  letter-spacing: 0;
}
#map_ >>> .brief .brief-body .brief-distance {
  width: 100%;
  height: 1.2rem;
  line-height: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: PingFangSC-Regular;
  font-size: 0.78rem;
  color: #000000;
  letter-spacing: 0;
}
#map_ >>> .brief .brief-body .brief-distance span {
  color: #ff972d;
}
#map_ >>> .brief .brief-body .brief-star {
  position: absolute;
  top: 0.3rem;
  right: 0;
  height: 1rem;
  display: flex;
  align-items: center;
}

#map_ >>> .brief .brief-body .brief-star img {
  top: 0;
  right: 0;
  height: 100%;
}
#map_ >>> .brief .brief-nav {
  position: absolute;
  top: 1rem;
  right: 0.78rem;
  height: 3.6rem;
  width: 3.6rem;
  font-size: 1.3rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgb(204, 158, 99);
}

/*  */
#map_ >>> .brief .leaflet-popup-content-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0.6rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  padding: 0;
}
#map_ >>> .brief .leaflet-popup-content {
  margin: 0;
  width: 100%;
  height: 100%;
}
/* North */

/* South */
#map_ >>> .leaflet-resp-popup-south .leaflet-popup-tip-container {
  top: 0;
  margin-top: -20px;
}

#map_ >>> .leaflet-resp-popup-south .leaflet-popup-tip {
  margin: 11px auto 0;
  box-shadow: none;
}

/* West */
#map_ >>> .leaflet-resp-popup-west .leaflet-popup-tip-container {
  right: 0;
  margin-right: -20px;
  left: 100%;
  margin-left: -2px;
  top: 50%;
  margin-top: -11px;
}

#map_ >>> .leaflet-resp-popup-west .leaflet-popup-tip {
  margin: 1px -8px 0 -8px;
  box-shadow: none;
}

/* East */
#map_ >>> .leaflet-resp-popup-east .leaflet-popup-tip-container {
  width: 20px;
  left: 2px;
  top: 50%;
  margin-top: -11px;
}

#map_ >>> .leaflet-resp-popup-east .leaflet-popup-tip {
  margin: 0 11px;
  box-shadow: none;
}

/* South-East */
#map_ >>> .leaflet-resp-popup-south-east .leaflet-popup-tip-container {
  left: 0;
  margin-left: 0;
  top: 0;
  margin-top: -20px;
}

#map_ >>> .leaflet-resp-popup-south-east .leaflet-popup-tip {
  margin: 11px 11px 11px -8px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-south-east .leaflet-popup-content-wrapper {
  border-radius: 0 20px 20px 20px;
}

/* East-South */
#map_ >>> .leaflet-resp-popup-east-south .leaflet-popup-tip-container {
  width: 20px;
  left: 0;
  top: 0;
}

#map_ >>> .leaflet-resp-popup-east-south .leaflet-popup-tip {
  margin: -8px 11px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-east-south .leaflet-popup-content-wrapper {
  border-radius: 0 20px 20px 20px;
}

/* North-East */
#map_ >>> .leaflet-resp-popup-north-east .leaflet-popup-tip-container {
  left: 0;
  margin-left: 0;
  margin-top: -1px;
}

#map_ >>> .leaflet-resp-popup-north-east .leaflet-popup-tip {
  margin: -8px 11px 11px -8px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-north-east .leaflet-popup-content-wrapper {
  border-radius: 20px 20px 20px 0;
}

/* East-North */
#map_ >>> .leaflet-resp-popup-east-north .leaflet-popup-tip-container {
  left: 0;
  margin-left: -20px;
  margin-top: -20px;
}

#map_ >>> .leaflet-resp-popup-east-north .leaflet-popup-tip {
  margin: 11px 11px 11px 11px;
  box-shadow: none;
}

/* North-West */
#map_ >>> .leaflet-resp-popup-north-west .leaflet-popup-tip-container {
  width: 20px;
  margin-right: 0;
  margin-top: -1px;
  left: 100%;
  right: 0;
}

#map_ >>> .leaflet-resp-popup-north-west .leaflet-popup-tip {
  margin: -8px 11px 11px 11px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-north-west .leaflet-popup-content-wrapper {
  border-radius: 20px 20px 0 20px;
}

/* West-North */
#map_ >>> .leaflet-resp-popup-west-north .leaflet-popup-tip-container {
  margin-left: 0;
  margin-top: -20px;
  left: 100%;
  right: 0;
}

#map_ >>> .leaflet-resp-popup-west-north .leaflet-popup-tip {
  margin: 11px 11px 0px -8px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-west-north .leaflet-popup-content-wrapper {
  border-radius: 20px 20px 0 20px;
}

/* South-West */
#map_ >>> .leaflet-resp-popup-south-west .leaflet-popup-tip-container {
  width: 20px;
  left: 100%;
  top: 0;
  margin-top: -20px;
}

#map_ >>> .leaflet-resp-popup-south-west .leaflet-popup-tip {
  margin: 11px 11px 11px 11px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-south-west .leaflet-popup-content-wrapper {
  border-radius: 20px 0 20px 20px;
}

/* West-South */
#map_ >>> .leaflet-resp-popup-west-south .leaflet-popup-tip-container {
  margin-left: 0;
  margin-right: -20px;
  left: 100%;
  right: 0;
  top: 0;
}

#map_ >>> .leaflet-resp-popup-west-south .leaflet-popup-tip {
  margin: -8px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-west-south .leaflet-popup-content-wrapper {
  border-radius: 20px 0 20px 20px;
}
/* 点位名称和icon */
#map_ >>> .leaflet-marker-pane .leaflet-marker-icon {
  position: relative;
  border: none;
  background-color: transparent;
}
#map_ >>> .leaflet-marker-pane .icon-name {
  position: absolute;
  white-space: nowrap;
  top: -20px;
  font-size: 13px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.6);
  padding: 0 6px;
  color: #333;
  border: 1px solid #333;
  border-radius: 4px;
}
#map_ >>> .leaflet-marker-pane .icon-number {
  position: absolute;
  white-space: nowrap;
  top: 43.8%;
  font-size: 13px;
  font-weight: 600;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f6ad15;
}
#map_ >>> .leaflet-marker-pane .icon-value {
  width: 40px;
  height: 40px;
}

.map-wrapper >>> .red_packet_container .red_packet {
  z-index: 403;
}
.map-wrapper >>> .red_packet_container .red_packet_btn {
  z-index: 403;
}
.map-wrapper >>> .red_packet_container .red_packet_bounced {
  z-index: 403;
}
.map-wrapper >>> .red_packet_container .red_packet_banner {
  z-index: 403;
}
</style>
