var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"permission"},[(_vm.type == '小米' && _vm.name == '启动')?_c('ul',{staticClass:"wrap"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.type == '小米' && _vm.name == '神隐')?_c('ul',{staticClass:"wrap"},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)]):_vm._e(),(_vm.type == '华为' && _vm.name == '启动')?_c('ul',{staticClass:"wrap"},[_vm._m(11),_vm._m(12),_vm._m(13)]):_vm._e(),(_vm.type == '华为' && _vm.name == '后台保护')?_c('ul',{staticClass:"wrap"},[_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17)]):_vm._e(),(_vm.type == '华为' && _vm.name == '白名单')?_c('ul',{staticClass:"wrap"},[_vm._m(18),_vm._m(19)]):_vm._e(),(_vm.type == '魅族' && _vm.name == '启动')?_c('ul',{staticClass:"wrap"},[_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23)]):_vm._e(),(_vm.type == '魅族' && _vm.name == '后台保护')?_c('ul',{staticClass:"wrap"},[_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27)]):_vm._e(),(_vm.type == '魅族' && _vm.name == '白名单')?_c('ul',{staticClass:"wrap"},[_vm._m(28),_vm._m(29),_vm._m(30),_vm._m(31),_vm._m(32)]):_vm._e(),(_vm.type == '大神' && _vm.name == '启动')?_c('ul',{staticClass:"wrap"},[_vm._m(33),_vm._m(34),_vm._m(35)]):_vm._e(),(_vm.type == '大神' && _vm.name == '后台保护')?_c('ul',{staticClass:"wrap"},[_vm._m(36),_vm._m(37),_vm._m(38),_vm._m(39)]):_vm._e(),(_vm.type == '其他手机' && _vm.name == '启动')?_c('ul',{staticClass:"wrap qt"},[_vm._m(40),_vm._m(41),_vm._m(42)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 打开 "),_c('span',[_vm._v("安全中心")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/xm8.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 进入 "),_c('span',[_vm._v("授权管理")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/xm9.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 选择 "),_c('span',[_vm._v("自启动管理")])]),_c('img',{attrs:{"src":require("../../assets/img/xm11.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission03.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 允许 "),_c('span',[_vm._v("最美旅行家")]),_vm._v("自启动 ")]),_c('img',{attrs:{"src":require("../../assets/img/xm10.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission04.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 打开 "),_c('span',[_vm._v("设置")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/xm4.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 选择 "),_c('span',[_vm._v("其他高级设置")])]),_c('img',{attrs:{"src":require("../../assets/img/xm3.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 进入 "),_c('span',[_vm._v("电量和性能")])]),_c('img',{attrs:{"src":require("../../assets/img/xm1.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission03.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 进入 "),_c('span',[_vm._v("神隐模式")])]),_c('img',{attrs:{"src":require("../../assets/img/xm6.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission04.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 进入 "),_c('span',[_vm._v("应用配置")])]),_c('img',{attrs:{"src":require("../../assets/img/xm7.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission05.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 找到 "),_c('span',[_vm._v("最美旅行家")]),_vm._v("并进入 ")]),_c('img',{attrs:{"src":require("../../assets/img/xm2.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission06.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 选择 "),_c('span',[_vm._v("自定义设置，")]),_vm._v("并在后台定位配置中选择 "),_c('span',[_vm._v("保持定位可用")])]),_c('img',{attrs:{"src":require("../../assets/img/xm5.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission07.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 打开 "),_c('span',[_vm._v("设置")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/hw6.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 在全部设置中选择 "),_c('span',[_vm._v("开机自启动")])]),_c('img',{attrs:{"src":require("../../assets/img/hw5.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 将 "),_c('span',[_vm._v("最美旅行家")]),_vm._v("的 "),_c('span',[_vm._v("自启动")]),_vm._v("开关打开 ")]),_c('img',{attrs:{"src":require("../../assets/img/xm10.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission03.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 打开 "),_c('span',[_vm._v("手机管家")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/hw3.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 进入 "),_c('span',[_vm._v("省电管理")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/hw2.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 选择 "),_c('span',[_vm._v("受保护应用")])]),_c('img',{attrs:{"src":require("../../assets/img/hw4.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission03.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 将 "),_c('span',[_vm._v("最美旅行家")]),_vm._v("开关打开 ")]),_c('img',{attrs:{"src":require("../../assets/img/hw1.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission04.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 点击手机底部的 "),_c('span',[_vm._v("菜单键")]),_vm._v("进入 "),_c('span',[_vm._v("后台应用列表")])]),_c('img',{attrs:{"src":require("../../assets/img/hw8.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 找到最美旅行家并 "),_c('span',[_vm._v("下拉锁定")]),_vm._v("，使最美旅行家上显示一个锁状图标 ")]),_c('img',{attrs:{"src":require("../../assets/img/hw9.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 打开 "),_c('span',[_vm._v("手机管家")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/mz7.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 进入 "),_c('span',[_vm._v("权限管理")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/mz9.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 选择 "),_c('span',[_vm._v("自启动管理")])]),_c('img',{attrs:{"src":require("../../assets/img/xm11.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission03.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 将 "),_c('span',[_vm._v("最美旅行家")]),_vm._v("自启动 ")]),_c('img',{attrs:{"src":require("../../assets/img/xm10.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission04.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 打开 "),_c('span',[_vm._v("手机管家")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/mz7.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 进入 "),_c('span',[_vm._v("省电模式")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/mz6.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 选择 "),_c('span',[_vm._v("待机耗电管理")])]),_c('img',{attrs:{"src":require("../../assets/img/mz8.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission03.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 将 "),_c('span',[_vm._v("最美旅行家")]),_vm._v("打开 ")]),_c('img',{attrs:{"src":require("../../assets/img/mz5.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission04.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 打开 "),_c('span',[_vm._v("设置")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/mz10.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 进入 "),_c('span',[_vm._v("手机加速")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/mz2.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 选择右上角的 "),_c('span',[_vm._v("设置")])]),_c('img',{attrs:{"src":require("../../assets/img/mz1.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission03.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 选择 "),_c('span',[_vm._v("手机加速白名单")])]),_c('img',{attrs:{"src":require("../../assets/img/mz13.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission04.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 将 "),_c('span',[_vm._v("最美旅行家")]),_vm._v("添加到白名单 ")]),_c('img',{attrs:{"src":require("../../assets/img/mz4.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission05.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 打开 "),_c('span',[_vm._v("设置")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/ds1.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 进入 "),_c('span',[_vm._v("应用自启动")])]),_c('img',{attrs:{"src":require("../../assets/img/ds4.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 将 "),_c('span',[_vm._v("最美旅行家")]),_vm._v("的 "),_c('span',[_vm._v("自启动")]),_vm._v("开关打开 ")]),_c('img',{attrs:{"src":require("../../assets/img/ds5.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission03.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 打开 "),_c('span',[_vm._v("设置")])]),_c('img',{staticStyle:{"width":"30%"},attrs:{"src":require("../../assets/img/ds1.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 进入 "),_c('span',[_vm._v("电池和省电")])]),_c('img',{attrs:{"src":require("../../assets/img/ds6.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 选择 "),_c('span',[_vm._v("锁屏受保护应用")])]),_c('img',{attrs:{"src":require("../../assets/img/ds3.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission04.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 将 "),_c('span',[_vm._v("最美旅行家")]),_vm._v("的保护开关打开 ")]),_c('img',{attrs:{"src":require("../../assets/img/ds2.png"),"alt":""}}),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission04.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 打开 "),_c('span',[_vm._v("设置或安全中心")])]),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission01.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 进入 "),_c('span',[_vm._v("自启动管理")])]),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission02.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" 找到 "),_c('span',[_vm._v("自启动管理")]),_vm._v("，并允许其 "),_c('span',[_vm._v("自启动")])]),_c('img',{staticClass:"dingwei",attrs:{"src":require("../../assets/img/permission03.png"),"alt":""}})])
}]

export { render, staticRenderFns }