<template>
  <div class="container" v-show="loaded">
    <i class="back iconfont iconmenu2" @click="goBack"></i>
    <div class="banner">
      <mt-swipe class="swiper" :auto="3000" :speed="200">
        <mt-swipe-item class="item" v-for="item in swiperList" :key="item.id">
          <img class="img" :src="item.img_res" />
        </mt-swipe-item>
      </mt-swipe>
    </div>
    <header class="header">
      <h1 class="title">{{title}}</h1>
      <div class="open subtitle" v-if="openTime">
        <i class="iconfont icontime"></i>
        <span class="value">{{openTime}}</span>
      </div>
      <div class="tel subtitle" v-if="tel">
        <i class="iconfont icondianhua"></i>
        <span class="value">{{tel}}</span>
        <a :href="'tel:' + tel" class="handle">拨打</a>
      </div>
      <div class="address subtitle" v-if="address">
        <i class="iconfont iconaddress"></i>
        <span class="value">{{address}}</span>
        <a class="handle" @click="goOpenMap">导航</a>
      </div>
    </header>
    <!-- 解说 -->
    <section class="audio-box" v-if="audioList && audioList.length">
      <ul class="audio-list">
        <li class="item" v-for="(item, i) in audioList" :key="i" @click="toggleAudio(item)">
          <span :class="['text', activeAudioType === item.type_ ? 'active' : '']">{{item.title}}</span>
        </li>
      </ul>
      <div class="audio-content">
        <div class="handle" @click="togglePlay">
          <img :src="swiperList[0] ? swiperList[0].img : ''" class="bg" />
          <i class="iconfont iconbofang" v-if="!isPlaying"></i>
          <i class="iconfont iconbofang1" v-else></i>
        </div>
        <span class="name">语音解说</span>
        <div class="progress">
          <span class="current">{{currentTime}}</span>
          <span class="total">/ {{duration}}</span>
        </div>
      </div>
    </section>
    <main class="main">
      <div class="content" v-html="content"></div>
    </main>
    <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>

    <audio src ref="audio"></audio>
  </div>
</template>

<script>
import { Toast, Indicator, Swipe, SwipeItem } from "mint-ui";
import TechnicalSupport from "../../components/TechnicalSupport";
export default {
  components: {
    "technical-support": TechnicalSupport
  },
  data() {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanSpot",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      aid: this.$route.query.aid || 0,

      // 获取数据
      title: "",
      address: "",
      content: "",
      lng: "",
      lat: "",
      swiperList: [],
      openTime: "",
      tel: "",
      // 解说
      audioList: [],
      activeAudioType: "",
      isPlaying: false,
      duration: "",
      currentAudio: "",
      currentTime: "00:00",
      audio: null,
      audioTimer: "",

      // 自定义
      isLoading: false,
      loaded: false,

      // company
      companyInfo: {},
      haveCompany: false
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
    }

    this.getData();
    this.getCompanyInfo(this.id, this.token).then(
      res => {
        this.companyInfo = res;
        this.haveCompany = true;
      },
      err => {
        console.log(err);
      }
    );
  },
  methods: {
    // 上一级
    goBack() {
      this.$router.push({
        path: "/nihewan/home",
        query: {
          id: this.id
        }
      });
    },
    // 导航
    goOpenMap(item) {
      if (this.is_weixin) {
        if (this.lng && this.lat) {
          this.wx_nav(
            window.location.href,
            this.lng * 1,
            this.lat * 1,
            this.title,
            this.address
          );
        } else {
          Toast("没有导航");
        }
      } else {
        Toast("请在微信中打开,使用导航");
      }
    },
    // 播放
    playAudio() {
      if (!this.audio) {
        this.audio = this.$refs.audio;

        this.audio.addEventListener("ended", () => {
          clearTimeout(this.audioTimer);
          this.isPlaying = false;
        });
      }
      this.audio.src = this.currentAudio;
      this.audio.play();
      this.getCurrentTime();
    },
    // 当前播放位置
    getCurrentTime() {
      this.currentTime = this.formatAudioTime(this.audio.currentTime);
      console.log(this.audio.currentTime);
      this.audioTimer = setTimeout(() => {
        this.getCurrentTime();
      }, 500);
    },
    // 切换解说播放/赞同
    togglePlay() {
      if (this.isPlaying) {
        this.audio.pause();
        clearTimeout(this.audioTimer);
      } else {
        this.playAudio();
      }

      this.isPlaying = !this.isPlaying;
    },
    // 切换解说语言
    toggleAudio(item) {
      this.activeAudioType = item.type_;
      this.duration = this.formatAudioTime(item.Duration);
      this.currentAudio = item.audioPath;
      if (this.isPlaying) {
        this.playAudio();
      }
    },
    // 获取数据
    getData() {
      if (this.aid && this.open_id && this.park_id && this.id) {
        this.isLoading = true;
        Indicator.open("加载中...");
        this.$ajax(
          "post",
          "foot/get_foot_info_v2",
          {
            spot_id: this.aid,
            user_id: "",
            page: 1,
            open_id: this.open_id,
            where_: "web",
            park_id: this.park_id,
            share_open_id: "",
            label_id: this.id
          },
          res => {
            if (res.data.id === 0) {
              document.title = res.data.data.title;
              this.title = res.data.data.title;
              this.address = res.data.data.address;
              this.content = res.data.data.content;
              this.lng = res.data.data.spot_lng;
              this.lat = res.data.data.spot_lat;
              this.swiperList.push(...res.data.data.img);
              this.openTime = res.data.data.open_time;
              this.tel = res.data.data.user_tel;
              if (res.data.data.audio_list && res.data.data.audio_list.length) {
                this.audioList.push(...res.data.data.audio_list);

                this.duration = this.formatAudioTime(
                  res.data.data.audio_list[0].Duration
                );
                this.currentAudio = res.data.data.audio_list[0].audioPath;
                this.activeAudioType = res.data.data.audio_list[0].type_;
              }

              const sUrl = window.location.href,
                wx_title = res.data.data.title,
                wx_content = res.data.data.content,
                wx_img = res.data.data.img[0].img_res;
              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                });
              }
            }
            Indicator.close();
            this.isLoading = false;
            this.loaded = true;
          }
        );
      }
    },
    // 格式化音频时间
    formatAudioTime(data) {
      data = data * 1;
      const hour = parseInt(data / 3600);
      data = data % 3600;
      const _minute = parseInt(data / 60);
      const minute = _minute >= 10 ? "" + _minute : "0" + _minute;
      data = Math.floor(data % 60);
      const second = data >= 10 ? "" + data : "0" + data;
      if (hour > 0) {
        return hour + ":" + minute + ":" + second;
      } else {
        return minute + ":" + second;
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&id_=" +
        this.aid +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    }
  },
  beforeDestroy() {
    clearTimeout(this.audioTimer);
  }
};
</script>

<style scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
}

.back {
  position: fixed;
  top: 0.4rem;
  left: 0.4rem;
  height: 1.6rem;
  width: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.swiper {
  width: 100%;
  height: 55.5vw;
}

.swiper .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* header */
.header {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  /* border-bottom: 1px solid #eee; */
}

.header > .title {
  padding-bottom: 0.5rem;
  font-size: 1rem;
}

.header > .subtitle {
  height: 1.8rem;
  display: flex;
  align-items: center;
  font-size: 0.74rem;
  color: #666;
}

.header > .subtitle > .iconfont {
  margin-right: 0.5rem;
  font-size: 0.88rem;
  color: #333;
}

.header > .subtitle > .value {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header > .subtitle > .handle {
  padding: 3px 9px;
  font-size: 0.68rem;
  color: #409eff;
  border: 1px solid rgba(64, 160, 255, 0.6);
  border-radius: 4px;
}

/* audio */
.audio-box {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.audio-list {
  display: flex;
}

.audio-list > .item {
  margin-right: 1rem;
  font-size: 0.88rem;
  color: #666;
}

.audio-list > .item > .active {
  color: #409eff;
}

.audio-content {
  position: relative;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.audio-content > .handle {
  position: relative;
  width: 4rem;
  height: 4rem;
}

.audio-content > .handle > .bg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.audio-content > .handle > .iconfont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: #fff;
}

.audio-content > .name {
  flex: 1;
  padding-right: 4rem;
  box-sizing: border-box;
  text-align: center;
  font-size: 1rem;
  color: #000;
}

.audio-content > .progress {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  font-size: 0.68rem;
  color: #333;
}

/* main */
.main {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  color: #333;
  border-bottom: 1px solid #eee;
}

.content >>> .MsoNormal {
  font-size: 0.8rem;
  color: #333;
  text-align: justify;
}

.content >>> img {
  display: block;
  width: 100%;
  margin-top: 1rem;
}

.company {
  position: relative;
  width: 100%;
  padding: 1rem 0;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 0.74rem;
}
</style>