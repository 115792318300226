<template>
  <div class="grayness" v-title data-title="编辑个人志愿">
    <mt-header fixed title="编辑个人志愿">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="main_frame">
        <form action id="formid" method="post" enctype="multipart/form-data">
          <input type="hidden" name="aid" v-model="id" />
          <input type="hidden" name="uid" v-model="user_id" />
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">意向公园</span>
            <div class="apply-input">
              <select name="park_name" style="width: 100%;padding-right: .5rem">
                <option>{{info.park_name}}</option>
              </select>
            </div>
          </div>
          <div class="apply-wrap">
            <span class="required">*</span>
            <span class="form-title">你为什么参与？</span>
            <div class="apply-textarea">
              <textarea name="why" id="volunteer_why" required="required" v-model="info.why"></textarea>
            </div>
          </div>
          <div class="apply-wrap apply">
            <span class="required">*</span>
            <span class="form-title">科普工作计划</span>
            <label>（可结合专业、户外、科普、旅游、摄影等专长，及公益活动类经历， 谈谈如何对公园进行推广与宣传）</label>
            <div class="apply-textarea">
              <textarea
                name="todo"
                id="volunteer_todo"
                required="required"
                placeholder="您对未来的工作计划"
                v-model="info.todo"
              ></textarea>
            </div>
          </div>
        </form>
        <div class="btn">
          <mt-button type="primary" class="mint-header" @click="submitForm">保存</mt-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      user_id: "",
      id: this.$route.query.id,
      info: {}
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      if (this.check_login()) {
        this.user_id = this.check_login().user_id;
      } else {
        this.user_id = this.get_User_Id();
      }
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      this.getData();
    },
    getData() {
      this.$ajax(
        "post",
        "Volunteer/user_info",
        { aid: this.id, uid: this.user_id },
        ret => {
          if (ret.data.status == 1) this.info = ret.data.info;
        }
      );
    },
    submitForm() {
      var form = document.getElementById("formid");
      var formdata = new FormData(form);
      var that = this;
      this.formMethod("Volunteer/updata", formdata, function(data) {
        if (data.status == 1) {
          if (that.$route.query.type == 1) {
            that.$router.push({
              path: "/volunteer/apply",
              query: { aid: that.volunteer_id }
            });
          } else {
            that.$router.push({
              path: "/volunteer/index",
              query: { id: that.id }
            });
          }
        } else {
          Toast(data.info);
        }
      });
    }
  }
};
</script>
<style scoped>
.grayness {
  min-height: 100%;
  background-color: #fff;
}

.grayness .page-content {
  padding-bottom: 0;
}
</style>
