<template>
  <div>
    <ServiceTitle></ServiceTitle>

    <div class="page-content">
      <div class="new-title-content">游玩亮点</div>
      <div class="withdraw-wrap">
        <div class="input-wrap clearfix" style="padding:0">
          <mt-field
            placeholder="请输入你的游玩亮点"
            v-model="funy"
            type="textarea"
            rows="3"
            :attr="{maxlength:20}"
          ></mt-field>
          <span class="foot-guide-tips fr" style="padding-right:10px;">(您可以输入{{wordNum}}个字)</span>
        </div>
      </div>
      <div class="tips-content">
        <p
          class="foot-guide-tips"
          v-for="(list, i) in lists"
          :key="i"
          style="margin-bottom:10px;"
        >{{list.content}}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.input-wrap textarea {
  font-size: 12px;
}
.tips-content {
  padding: 10px;
}
.foot-guide-tips {
  font-size: 10px;
  color: #cecece;
  margin-bottom: 10px;
}
</style>

<script>
import ServiceTitle from "../../components/ServiceTitle";

export default {
  name: "GuideFeatures",
  components: {
    ServiceTitle
  },
  data() {
    return {
      funy: "",
      wordNum: "20",
      lists: [
        {
          content: "填写到您的服务中，最具特色或者最吸引人的部分"
        },
        {
          content: "范例："
        },
        {
          content: "漫步青石板小巷"
        },
        {
          content: "撑一把雨伞，坐乌篷船感受水乡清雅"
        }
      ]
    };
  },
  watch: {
    funy: function(value) {
      var funyLength = value.length;
      this.wordNum = 20 - funyLength;
      this.save_arr("funy", value);
    }
  }
};
</script>