<template>
  <div class="container" v-show="loaded">
    <i class="back iconfont iconmenu2" @click="goBack"></i>
    <div class="header">
      <p>作品详情</p>
    </div>
    <div class="main">
      <div class="title">
        <span class="text">{{title}}</span>
      </div>
      <div class="subtitle">
        <span class="text nick">{{user_nick}}</span>
        <span class="text time">{{addTime}}</span>
      </div>
      <div class="rich-content" v-html="content"></div>
      <div class="img-list" v-if="imgNumbers > 0">
        <!-- 一张图片 -->
        <div class="imgs imgs-1" v-if="imgNumbers == 1">
          <img
            class="item"
            :src="item.android_newUrl"
            v-for="(item, i) in imgList"
            :key="i"
            @click="handleShowPreview(i)"
          />
        </div>
        <!-- 2张或4张图片 -->
        <div class="imgs imgs-2" v-if="imgNumbers == 2 || imgNumbers == 4">
          <img
            class="item"
            :src="item.android_newUrl"
            v-for="(item, i) in imgList"
            :key="i"
            @click="handleShowPreview(i)"
          />
        </div>
        <!-- 3张或4张以上 -->
        <div class="imgs imgs-3" v-if="imgNumbers == 3 || imgNumbers > 4">
          <img
            class="item"
            :src="item.android_newUrl"
            v-for="(item, i) in imgList"
            :key="i"
            @click="handleShowPreview(i)"
          />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="address" @click="goOpenMap">
        <span class="text">{{address}}</span>
      </div>
      <div class="count">
        <div class="count-numbers">
          <span class="text">点赞:</span>
          <span class="text num">{{likeNumbers}}</span>
          <span class="text">浏览:</span>
          <span class="text num">{{looksNumbers}}</span>
        </div>
        <div class="like-btn" @click="handleLike">
          <i class="iconfont iconzan1 before-like" v-if="like == 0"></i>
          <i class="iconfont iconzan2 after-like" v-if="like == 1"></i>
        </div>
      </div>
      <div class="like-list" v-if="likeList && likeList.length">
        <i class="iconfont iconlike3"></i>
        <span
          class="text"
          v-for="(item,i) in likeList"
          :key="i"
        >{{i == likeList.length - 1 ? item.user_nick : item.user_nick + ','}}</span>
      </div>
      <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
      <img class="bottom-holder" src="../../assets/nihewan/white_tempBg.jpg" />
    </div>

    <!-- 图片预览 -->
    <div class="preview" v-show="showPreview" v-if="loaded">
      <swiper
        class="swiper-container"
        :options="swiperOption"
        ref="mainSwiper"
        @click="showPreview = false"
      >
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator, MessageBox } from "mint-ui";
import { swiper, swiperSlide } from "swiper/vue";
import TechnicalSupport from "../../components/TechnicalSupport";
export default {
  components: {
    swiper,
    swiperSlide,
    "technical-support": TechnicalSupport
  },
  data() {
    return {
      swiperOption: {},
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanWorks",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      aid: this.$route.query.aid || 0,

      // 获取数据
      activity: {},
      imgList: [],
      likeList: [],
      preImgList: [],
      address: "",
      addTime: "",
      title: "",
      user_icon: "",
      user_nick: "",
      imgNumbers: "",
      lng: "",
      lat: "",
      price: "",
      isPay: "",
      looksNumbers: "",
      likeNumbers: "",
      like: "",
      content: "",

      // 自定义
      page: 1,
      loaded: false,
      isLoading: false,
      isLiking: false,
      user_id: "",
      user_nick: "",
      showPreview: false,
      mainSwiper: null,

      // company
      companyInfo: {},
      haveCompany: false
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
      // console.log("no");
    }

    this.checkLogin();
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
    }
    this.getData();
    this.getCompanyInfo(this.id, this.token).then(
      res => {
        this.companyInfo = res;
        this.haveCompany = true;
      },
      err => {
        console.log(err);
      }
    );
  },
  methods: {
    // 点赞
    handleLike() {
      if (this.isLiking) {
        return false;
      }
      this.isLiking = true;

      if (this.aid && this.token && this.user_id) {
        //发送网络请求
        this.$ajax(
          "post",
          "foot/click_spots",
          {
            token: this.token,
            spot_id: this.aid,
            user_id: this.user_id,
            park_id: this.park_id
          },
          res => {
            if (res.data.id == 0) {
              let is_add = 1,
                like = "0",
                addNumber = -1;
              for (let i = 0, len = this.likeList.length; i < len; i++) {
                if (this.likeList[i].user_id == this.user_id) {
                  if (this.likeList[i + 1]) {
                    this.likeList[i].spots_fk_id = this.likeList[
                      i + 1
                    ].spots_fk_id;
                    this.likeList[i].user_id = this.likeList[i + 1].user_id;
                    this.likeList[i].user_nick = this.likeList[i + 1].user_nick;
                    is_add = 0;
                  } else {
                    is_add = 2;
                  }
                }
              }
              if (is_add == 1) {
                addNumber = 1;
                like = "1";
                let arr_ = new Array();
                if (this.likeList) {
                  arr_ = this.likeList;
                }
                let obj = {};
                obj.spots_fk_id = this.aid;
                obj.user_id = this.user_id;
                obj.user_nick = this.user_nick;
                arr_.push(obj);
                this.likeList = arr_;
              } else if (is_add == 2) {
                //删除最后一个元素
                this.likeList.pop();
              }

              this.likeNumbers = this.likeNumbers * 1 + addNumber;
              this.like = like;
            }
            this.isLiking = false;
          }
        );
      }
    },
    // 上一级
    goBack() {
      this.$router.push({
        path: "/nihewan/home",
        query: {
          id: this.id
        }
      });
    },
    // 导航
    goOpenMap() {
      if (this.is_weixin) {
        if (this.lng && this.lat) {
          this.wx_nav(
            window.location.href,
            this.lng * 1,
            this.lat * 1,
            this.title,
            this.address
          );
        } else {
          Toast("没有导航");
        }
      } else {
        Toast("请在微信中打开,使用导航");
      }
    },
    // 图片预览
    handleShowPreview(i) {
      this.showPreview = true;
      if (!this.mainSwiper) {
        this.mainSwiper = this.$refs.mainSwiper.swiper;
      }
      this.mainSwiper.slideTo(i, 0);
    },
    initSwiper() {
      this.swiperOption = {
        autoplay: false,
        loop: false,
        speed: 200,
        zoom: true,
        lazy: true,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
          clickable: false
        },
        virtual: {
          slides: (() => {
            const slides = [];
            for (let i = 0, len = this.preImgList.length; i < len; i += 1) {
              slides.push(
                `<div class="swiper-zoom-container"><img src="${this.preImgList[i]}" class="swiper-lazy img" /><div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div></div>`
              );
            }
            return slides;
          })()
        }
      };
    },
    // 获取数据
    getData() {
      if (this.open_id && this.park_id && this.aid && this.user_id) {
        this.isLoading = true;
        Indicator.open("加载中...");
        this.$ajax(
          "post",
          "foot/get_foot_info_v2",
          {
            spot_id: this.aid,
            user_id: this.user_id,
            page: this.page,
            open_id: this.open_id,
            aid: this.aid,
            park_id: this.park_id,
            rp_fk_id: "",
            version: this.version
          },
          res => {
            if (res.data.id === 0) {
              document.title = res.data.data.title;
              Object.assign(this.activity, res.data.data.activity);
              this.imgList.push(...res.data.data.img);
              this.likeList.push(...res.data.data.like_list);

              this.preImgList.push(...res.data.data.pre_img_list);
              this.address = res.data.data.address;
              this.addTime = res.data.data.add_time;
              this.title = res.data.data.title;
              this.user_icon = res.data.data.user_icon;
              this.user_nick = res.data.data.user_nick;
              this.imgNumbers = res.data.data.img_numbers;
              this.lng = res.data.data.spot_lng * 1;
              this.lat = res.data.data.spot_lat * 1;
              this.price = res.data.data.price;
              this.isPay = res.data.data.is_nav_pay;
              this.looksNumbers = res.data.data.looks_numbers;
              this.likeNumbers = res.data.data.like_numbers;
              this.like = res.data.data.like;
              this.content = res.data.data.content;

              this.initSwiper();

              const sUrl = window.location.href,
                wx_title = res.data.data.title,
                wx_content = res.data.data.content,
                wx_img = res.data.data.img[0].img_res;
              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                });
              }
            }
            Indicator.close();
            this.isLoading = false;
            this.loaded = true;
          }
        );
      }
    },
    // 登录
    checkLogin() {
      if (!this.check_login()) {
        MessageBox.alert("请先登录").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
          }
        });
      } else {
        this.user_id = this.check_login().user_id;
        this.user_nick = this.check_login().user_nick;
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&id_=" +
        this.aid +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    }
  }
};
</script>

<style scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  padding: 0 3vw;
  box-sizing: border-box;
  background-color: #fff;
}

.back {
  position: fixed;
  top: 0.4rem;
  left: 0.4rem;
  height: 1.6rem;
  width: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.4rem;
  padding: 0.4rem 2.4rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

/* main */
.main {
  padding-top: 3rem;
  overflow: hidden;
}

.main > .title {
  font-size: 1rem;
  font-weight: bold;
}

.main > .subtitle {
  margin-top: 0.5rem;
  font-size: 0.74rem;
  color: #999;
}

.main > .subtitle > .nick {
  margin-right: 0.5rem;
  font-size: 0.74rem;
  color: #888;
}

.main > .rich-content {
  margin-top: 1rem;
  font-size: 0.8rem;
  color: #444;
}

.main > .img-list {
  margin-top: 1rem;
  width: 100%;
}

.img-list > .imgs {
  width: 100%;
  margin-top: 1vw;
  display: flex;
  flex-wrap: wrap;
}

.img-list > .imgs > .item {
  display: block;
}

.img-list > .imgs-1 > .item {
  width: 100%;
}

.img-list > .imgs-2 > .item {
  width: 46.5vw;
  height: 46.5vw;
  object-fit: cover;
}

.img-list > .imgs-2 > .item:nth-child(2n + 1) {
  margin-right: 1vw;
}

.img-list > .imgs-3 > .item {
  width: 31vw;
  height: 31vw;
  margin-top: 0.5vw;
}

.img-list > .imgs-3 > .item:nth-child(3n + 2) {
  margin-right: 0.5vw;
  margin-left: 0.5vw;
}

/* footer */
.footer {
  width: 100%;
  margin-top: 1rem;
}

.footer > .address {
  width: 100%;
  font-size: 0.68rem;
  color: #576b95;
}

.footer > .count {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.68rem;
  color: #999;
}

.footer > .count > .count-numbers > .num {
  margin-right: 0.5rem;
  color: #888;
  font-family: "Times New Roman", Times, serif;
}

.footer > .count > .like-btn {
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #f4f5f7;
}

.footer > .count > .like-btn > .iconfont {
  font-size: 0.88rem;
}

.footer > .count > .like-btn > .before-like {
  color: #999;
}

.footer > .count > .like-btn > .after-like {
  color: rgb(248, 67, 26);
}

.footer > .like-list {
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  padding: 4px 8px;
  box-sizing: border-box;
  background-color: #f4f5f7;
  border-radius: 2px;
}

.footer > .like-list::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -6px;
  left: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #f4f5f7;
}

.footer > .like-list > .iconfont {
  margin-right: 4px;
  font-size: 0.74rem;
  vertical-align: middle;
  font-weight: bold;
  color: #036096;
}

.footer > .like-list > .text {
  font-size: 0.74rem;
  color: #036096;
  vertical-align: middle;
}

.bottom-holder {
  display: block;
  width: 100%;
}

/* 预览 */
.preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 10;
}

.preview >>> .swiper-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview >>> .swiper-container .img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.preview >>> .swiper-container .swiper-pagination {
  color: #fff;
}

.company {
  position: relative;
  width: 100%;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 0.74rem;
}
</style>