<template>
  <div class="container" v-if="loaded" v-title data-title="泥河湾自然保护区">
    <header class="header">
      <i class="back iconfont iconmenu2" @click="goBack"></i>
      <div class="search-handle">
        <i class="iconfont iconsearch"></i>
        <input class="inp" type="text" placeholder="搜景点" ref="search" v-model="searchValue" />
      </div>
      <i class="iconfont cancle iconclose" @click="searchValue=''" v-show="searchValue"></i>
    </header>
    <main class="main">
      <mt-swipe class="swiper" :auto="3000" :speed="200">
        <mt-swipe-item class="item" v-for="item in swiperList" :key="item.id">
          <img class="img" :src="item.baseUrl" />
          <div class="title">
            <span class="text">{{item.title}}</span>
          </div>
        </mt-swipe-item>
      </mt-swipe>

      <ul class="list">
        <li class="item" v-for="item in filterBySearch(spotsList)" :key="item.id">
          <div class="cover">
            <img :src="item.baseUrl" class="img" @click="navToSpot(item.id)" />
          </div>
          <div class="info">
            <h3 class="title">{{item.title}}</h3>
            <ul class="handle-list">
              <li class="handle-item handle-detail" @click="navToSpot(item.id)">详情</li>
              <li class="handle-item handle-audio" @click="handlePlayAudio(item.audio_list)">解说</li>
              <li class="handle-item handle-nav" @click="goOpenMap(item)">导航</li>
            </ul>
          </div>
        </li>
      </ul>
    </main>

    <transition name="fade">
      <div class="language" v-show="showLanguage" @click.self="showLanguage = false">
        <ul class="language-list">
          <li
            class="item"
            v-for="(item, i) in audioList"
            :key="i"
            @click="playAudio(item.audioPath)"
          >{{item.title}}</li>
        </ul>
      </div>
    </transition>
    <audio src ref="audio"></audio>
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Toast, Indicator } from "mint-ui";
import TechnicalSupport from "../../components/TechnicalSupport";
export default {
  components: {
    "technical-support": TechnicalSupport
  },
  data() {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanSpotsList",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      // 请求数据
      page: 1,
      spotsList: [],
      swiperList: [],
      // 自定义
      isLoading: false,
      loaded: false,
      haveMore: true,
      searchValue: "",
      audio: null,
      audioList: [],
      showLanguage: false,
      scrollDisable: false,

      // company
      companyInfo: {},
      haveCompany: false,
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
    }

    this.getData();
    this.getCompanyInfo(this.id, this.token).then(
      res => {
        this.companyInfo = res;
        this.haveCompany = true;
      },
      err => {
        console.log(err);
      }
    );
    this.$nextTick(() => {
      //在冒泡阶段处理滚动的事件
      window.addEventListener("scroll", this.throttle(this.handleScroll));
    });
  },
  methods: {
    // 上一级
    goBack() {
      this.$router.push({
        path: "/nihewan/home",
        query: {
          id: this.id
        }
      });
    },
    // 景点详情
    navToSpot(aid) {
      this.$router.push({
        path: "/nihewan/spot",
        query: {
          id: this.id,
          aid: aid
        }
      });
    },
    // 导航
    goOpenMap(item) {
      if (this.is_weixin) {
        const lng = item.lng,
          lat = item.lat;
        if (lng && lat) {
          this.wx_nav(
            window.location.href,
            lng * 1,
            lat * 1,
            item.title,
            item.address
          );
        } else {
          Toast("没有导航");
        }
      } else {
        Toast("请在微信中打开,使用导航");
      }
    },
    // 播放音频
    playAudio(src) {
      this.audio.src = src;
      this.audio.play();
      this.showLanguage = false;
    },
    // 处理解说
    handlePlayAudio(arg) {
      if (!this.audio) {
        // 首次点击解说时，获取音频节点
        this.audio = this.$refs.audio;
      }
      if (!arg) {
        Toast("暂无解说");
      } else if (typeof arg === "string") {
        this.playAudio(arg);
      } else if (typeof arg === "object") {
        this.showLanguage = true;
        this.audioList.splice(0, this.audioList.length);
        this.audioList.push(...arg);
      }
    },

    // 根据搜索值返回数组
    filterBySearch(ls) {
      return ls.filter(item => {
        return item.title.match(this.searchValue);
      });
    },

    // 获取数据
    getData() {
      if (!this.haveMore || this.isLoading) {
        return false;
      }
      this.isLoading = true;
      Indicator.open("加载中...");
      this.$ajax(
        "post",
        "Label/get_science_data",
        {
          park_id: this.park_id,
          id_: this.id,
          id_type_: this.type_,
          where_: "spots", // spots, history, books, video, question, activity
          page: this.page,
          token: this.token,
          version: this.version
        },
        res => {
          if (res.data.id == 0) {
            if (this.page === 1) {
              this.spotsList.push(...res.data.data.spots_list);
              this.swiperList.push(
                ...res.data.data.spots_list.filter((item, i) => {
                  return i < 5;
                })
              );
            } else {
              this.spotsList.push(...res.data.data.list);
            }
            this.page++;

            const sUrl = window.location.href,
              wx_title = res.data.data.spots_list[0].title,
              wx_content = res.data.data.spots_list[0].shareContent,
              wx_img = res.data.data.spots_list[0].newUrl;
            if (this.is_weixin) {
              this.wx_share_callback(sUrl, {
                url: sUrl,
                id_: this.id,
                id_type_: this.backUrl,
                token: this.token,
                open_id: this.open_id,
                title: wx_title,
                content: wx_content,
                img: wx_img
              });
            }
          }

          if (
            res.data.id !== 0 ||
            (this.page === 1 &&
              (!res.data.data.spots_list ||
                res.data.data.spots_list.length < 10)) ||
            (this.page > 1 &&
              (!res.data.data.list || res.data.data.list.length < 10))
          ) {
            // 没有更多
            this.haveMore = false;
          }

          this.isLoading = false;
          Indicator.close();
          if (!this.loaded) {
            this.loaded = true;
          }
        }
      );
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    },
    //获取页面滚动的高度
    handleScroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;

      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop > 400) {
        this.showToTop = true;
      } else {
        this.showToTop = false;
      }

      // 加载更多
      if (
        scrollTop + windowHeight > scrollHeight - 100 &&
        this.haveMore &&
        this.page > 1
      ) {
        this.getData();
      }
    },
    throttle(fn, interval = 100) {
      let canRun = true;
      return function() {
        if (!canRun) return;
        canRun = false;
        setTimeout(() => {
          fn.apply(this, arguments);
          canRun = true;
        }, interval);
      };
    }
  }
};
</script>

<style scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
}

.header {
  position: fixed;
  height: 2.8rem;
  width: 100%;
  padding: 0.4rem 0.8rem 0.4rem 0.4rem;
  display: flex;
  box-sizing: border-box;
  box-shadow: 0 2px 2px -1px rgba(211, 211, 211, 0.4);
  background-color: #fff;
  z-index: 1;
}

.header > .back {
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}

.search-handle {
  flex: 1;
  height: 100%;
  padding-left: 1rem;
  border-radius: 2em;
  line-height: 2rem;
  font-size: 0.8rem;
  color: #666;
  box-sizing: border-box;
  background-color: rgb(243, 245, 248);
}

.search-handle > .iconfont {
  font-size: 0.8rem;
  color: #999;
}

.header > .iconclose {
  position: absolute;
  top: 0.4rem;
  right: 0.8rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  font-size: 0.8rem;
  color: #666;
}

/* main */

.main {
  width: 100%;
  padding-top: 2.8rem;
}

.swiper {
  width: 100%;
  height: 55.5vw;
}

.swiper .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper .title {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  font-size: 0.88rem;
  color: #fff;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.6);
}

/* mint-swipe-indicator */

.swiper >>> .mint-swipe-indicator {
  border-radius: 50%;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
}

.swiper >>> .mint-swipe-indicator.is-active {
  background-color: #fff;
}

/* list */
.list {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.list > .item {
  width: 100%;
  display: flex;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.list > .item:not(:first-child) {
  margin-top: 1rem;
}

.list > .item > .cover {
  margin-right: 1rem;
  width: 36vw;
  height: 24vw;
}

.list > .item > .cover > .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.list > .item > .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list > .item > .info > .title {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 0.8rem;
  font-weight: normal;
}

.handle-list {
  display: flex;
  justify-content: space-between;
}

.handle-list .handle-item {
  padding: 2px 8px;
  border-radius: 2px;
}

.handle-detail {
  color: #fff;
  background-color: #67c23a;
}

.handle-audio,
.handle-nav {
  color: #e6a23c;
  border: 1px solid rgba(230, 162, 60, 0.2);
}

/* 解说语言 */
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: 0.3s ease;
}
.language {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
}

.language-list {
  width: 60%;
  border-radius: 4px;
  background-color: #fff;
}

.language-list > .item {
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  font-size: 0.8rem;
}

.language-list > .item:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.company {
  position: relative;
  padding-bottom: 1rem;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: .74rem;
}
</style>