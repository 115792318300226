<template>
  <div class="qr-body">
    <div class="qr">
      <div class="qr-item">
        <div class="qri-rank">
          <span>排名</span>
        </div>
        <div class="qri-rank">
          <span>头像</span>
        </div>
        <div class="qri-nick_">
          <span>昵称</span>
        </div>
        <div class="qri-score">
          <span>积分</span>
        </div>
      </div>
      <div class="qr-item" v-for="(item,index) in list" :key="index" v-show="list">
        <div class="qri-rank">
          <span>{{index + 1}}</span>
        </div>
        <div class="qri-icon">
          <img :src="item.user_icon" />
        </div>
        <div class="qri-nick">
          <span>{{item.user_nick}}</span>
        </div>
        <div class="qri-score">
          <span>{{item.score}}</span>
        </div>
      </div>
    </div>
    <!--点击加载更多按钮-->
    <div class="click-load-more" @click="click_load_more">
      <div class="load-more-text">点击加载更多</div>
      <div class="load-more-img">
        <img src="../../assets/img/click-load-more.png" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.qr-body {
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: white;
  height: 100%;
}
.qr-body .qr {
  display: inline-block;
  position: relative;
  width: 98%;
  margin-left: 1%;
}
.qr-body .qr .qr-item {
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: #ff5487;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  height: 48px;
}
.qr-body .qr .qr-item .qri-rank {
  position: relative;
  width: 17%;
  float: left;
  margin-top: 2%;
}
.qr-body .qr .qr-item .qri-rank span {
  display: block;
  padding: 5px;
  font-size: 1rem;
  color: white;
}
.qr-body .qr .qr-item .qri-icon {
  position: relative;
  width: 13%;
  float: left;
}
.qr-body .qr .qr-item .qri-icon img {
  width: 45px;
  height: 45px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.qr-body .qr .qr-item .qri-nick {
  position: relative;
  width: 50%;
  float: left;
  margin-top: 2%;
}
.qr-body .qr .qr-item .qri-nick span {
  display: block;
  padding: 5px;
  font-size: 0.8rem;
  color: white;
  text-align: center;
}
.qr-body .qr .qr-item .qri-nick_ {
  position: relative;
  width: 40%;
  float: left;
  margin-top: 2%;
}
.qr-body .qr .qr-item .qri-nick_ span {
  display: block;
  padding: 5px;
  font-size: 1rem;
  color: white;
  text-align: center;
}
.qr-body .qr .qr-item .qri-score {
  position: relative;
  width: 20%;
  float: left;
  margin-top: 2%;
}
.qr-body .qr .qr-item .qri-score span {
  display: block;
  padding: 5px;
  font-size: 1rem;
  color: white;
  text-align: center;
}
</style>

<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      aid: this.$route.query.aid,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      list: "",
      page: 1
    };
  },

  mounted() {
    this.get_data();
  },
  create() {},
  methods: {
    get_data() {
      var data_ = {
        aid: this.aid,
        token: "zmlxj_2019_wuhanlvyouxiehui",
        page: this.page
      };

      this.$ajax("post", "Question/get_rank_list", data_, ret => {
        if (ret.data.id == 0) {
          this.list = ret.data.data.list;
        } else {
          //没有数据
          Toast("没有数据");
        }
      });
    },
    /*加载更多*/
    click_load_more() {
      var data_ = {
        aid: this.aid,
        token: "zmlxj_2019_wuhanlvyouxiehui",
        page: ++this.page
      };

      this.$ajax("post", "Question/get_rank_list", data_, ret => {
        if (ret.data.id == 0) {
          this.list = this.list.concat(ret.data.data.list);
        } else {
          //没有数据
          Toast("无更多数据");
        }
      });
    }
  }
};
</script>
