<template>
  <div class="ImgList" v-title data-title="图片">
    <mt-header fixed title="图片">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content" v-if="imglist.length > 0">
      <div class="img-box row2">
        <div v-for="(imgitem,imgIndex) in filterBy(imglist)" :key="imgIndex" class="item">
          <img class="img" v-lazy="imgitem.img" @click.prevent="onClickMe(imglist,imgIndex)" />
        </div>
      </div>
    </div>
    <div class="preview-content" v-if="is_preview" @click="is_preview=false">
      <mt-swipe :auto="0" :showIndicators="true" :defaultIndex="index">
        <mt-swipe-item v-for="(item,i) in message" :key="i">
          <a href="javascript:;">
            <img :src="item.img_res+'&imageMogr/auto-orient'" alt />
          </a>
        </mt-swipe-item>
      </mt-swipe>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imglist: this.$route.query.imglist,
      is_preview: false
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    filterBy(ls) {
      return ls.filter((item, i) => {
        return i < 9;
      });
    },
    unload() {
      //                判断是否是app打开 去除头部
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
    },
    onClickMe(img, index) {
      this.is_preview = true;
      this.message = img;
      this.index = index;
      return false;
    }
  }
};
</script>