<template>
  <div v-title data-title="导游">
    <mt-header fixed title="导游">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
    </mt-header>

    <div class="page-content">
      <!-- 头部 -->
      <div class="guide-page-top" v-if="user">
        <div class="topDetail">
          <div class="avatar">
            <div class="img" v-imgBg="user.user_img" v-if="user.user_img"></div>
          </div>
          <div class="name">{{user.name}}</div>
          <div class="contact">
            <span class="fans">粉丝 {{user.fansi}}</span>|
            <span class="follow">关注 {{user.attention}}</span>
          </div>
          <div class="tag">
            <span v-for="(tag,i) in user.label_data" :key="i">{{tag.label_name}}</span>
          </div>
        </div>
        <div class="topbg" v-imgBg="user.background_img" v-if="user.background_img"></div>
      </div>
      <!-- 简介 -->
      <div class="guide-page-about">
        <div class="tit">
          <img src="../../assets/img/serve_tag1.png" />
        </div>
        <div class="intro">{{user.content}}</div>
      </div>
      <!-- 服务项目 -->
      <ul class="guide-service">
        <router-link
          :to="{ name: 'GuideDetail', params: { id: item.id }}"
          v-for="(item,i) in list"
          :key="i"
          class="item"
        >
          <h1>徒步向导</h1>
          <div class="img">
            <div class="text">
              <div class="text-box">
                <h2>{{item.title}}</h2>
                <p>￥ {{item.service_money}}</p>
              </div>
            </div>
            <div class="pic" v-imgBg="item.img"></div>
          </div>
          <div class="info">
            <div>可接待：{{item.service_number}}人</div>
            <div>范围：市内{{item.range_km}}</div>
            <div>时长：{{item.time_length}}小时</div>
          </div>
        </router-link>
      </ul>
    </div>

    <div class="guide-footbar">
      <a href="javascript:;" class="follow-me">
        <span>关注</span>
      </a>
      <a href="javascript:;" class="ask-orange">
        <span>咨询</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: "",
      // 用户信息
      user: "",
      // 列表信息
      list: ""
    };
  },

  mounted() {
    this.userId = this.$route.params.id;
    this.getPageIfo(1);
  },

  methods: {
    getPageIfo(page) {
      this.$ajax(
        "post",
        "Service/user_service_list",
        { user_id: this.userId, page: page },
        res => {
          console.log(res.data.data);
          this.user = res.data.data.user;
          this.list = res.data.data.service;
          console.log(this.list);
        }
      );
    }
  }
};
</script>

<style scoped>
.page-content {
  padding-top: 0;
}
.mint-header {
  background: transparent;
  z-index: 2;
}

/*头部*/
.guide-page-top {
  position: relative;
  height: 250px;
  background-color: #666;
}
.guide-page-top .topDetail {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 250px;
  z-index: 1;
  color: #fff;
}
.guide-page-top .topDetail .avatar {
  width: 80px;
  height: 80px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin: 50px auto 0;
}
.guide-page-top .topDetail .name {
  font-size: 16px;
  margin-top: 10px;
}
.guide-page-top .topDetail .contact {
  font-size: 14px;
  margin-top: 10px;
}
.guide-page-top .topDetail .contact span {
  margin: 0 8px;
}
.guide-page-top .topDetail .tag {
  display: inline-block;
  font-size: 12px;
  padding: 3px 5px 4px;
  border-radius: 11.5px;
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.5);
}
.guide-page-top .topDetail .tag span {
  margin: 0 5px;
}
.guide-page-top .topbg {
  overflow: hidden;
  width: 100%;
  height: 100%;
  filter: blur(4px);
  background-size: auto 100%;
  background-position: center;
}
/*简介*/
.guide-page-about {
  background: #fff;
  padding: 10px;
}
.guide-page-about .tit {
  color: #b7e066;
  margin-bottom: 5px;
  line-height: 28px;
  overflow: hidden;
}
.guide-page-about .tit img {
  float: left;
  width: auto;
  height: 26px;
  margin-right: 6px;
}
.guide-page-about .intro {
  font-size: 16px;
  color: #666;
}
/*服务项目*/
.guide-service .item {
  background: #fff;
  margin: 10px 0;
  padding: 10px;
  display: block;
}
.guide-service .item > h1 {
  color: #333;
}
.guide-service .item > h1:before {
  content: "";
  float: left;
  width: 8px;
  height: 8px;
  margin: 8px 10px 0 0;
  border-radius: 50%;
  background: #00cbbf;
}
.guide-service .img {
  position: relative;
  margin: 10px 0;
}
.guide-service .img .text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50px;
}
.guide-service .img .text-box {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 55%;
}
.guide-service .img .text-box h2 {
  color: #fff;
  border-bottom: 1px solid #fff;
  padding: 0 10px 5px;
  margin-bottom: 5px;
}
.guide-service .img .text-box p {
  color: #fff000;
}
.guide-service .img > .pic {
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: cover;
}
.guide-service .info {
  display: flex;
  justify-content: space-between;
  color: #666;
}
.guide-footbar .follow-me {
  float: left;
  width: 50%;
  background: #dbdbdb;
  color: #333;
  line-height: 44px;
}
.guide-footbar .follow-me span {
  background-image: url(../../assets/img/tour_attention_no.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  padding-left: 32px;
}
.guide-footbar .follow-me .yes {
  background-image: url(../../assets/img/tour_attention_yes.png);
}
.guide-footbar .ask-orange {
  float: left;
  width: 50%;
  background: #ff9c00;
  color: #fff;
  line-height: 44px;
}
.guide-footbar .ask-orange span {
  background: url(../../assets/img/tour_privateletter.png) no-repeat left center;
  background-size: auto 16px;
  padding-left: 32px;
}
</style>