// 天气图标
const weather = {
  // 晴 少云 热
  icon0: require('../../assets/weather/0.png'),
  // 晴间多云 多云
  icon1: require('../../assets/weather/1.png'),
  //  阴 平静
  icon2: require('../../assets/weather/2.png'),
  // 阵雨 强阵雨
  icon3: require('../../assets/weather/3.png'),
  // 雷阵雨 强雷阵雨
  icon4: require('../../assets/weather/4.png'),
  // 雷阵雨并伴有冰雹
  icon5: require('../../assets/weather/5.png'),
  // 雨雪天气 雨夹雪 阵雨夹雪
  icon6: require('../../assets/weather/6.png'),
  // 小雨 雨 毛毛雨/细雨
  icon7: require('../../assets/weather/7.png'),
  // 中雨 小雨-中雨
  icon8: require('../../assets/weather/8.png'),
  // 大雨 中雨-大雨
  icon9: require('../../assets/weather/9.png'),
  // 暴雨 大雨-暴雨
  icon10: require('../../assets/weather/10.png'),
  // 大暴雨 暴雨-大暴雨
  icon11: require('../../assets/weather/11.png'),
  // 特大暴雨 大暴雨-特大暴雨 极端降雨
  icon12: require('../../assets/weather/12.png'),
  // 阵雪
  icon13: require('../../assets/weather/13.png'),
  // 雪 小雪
  icon14: require('../../assets/weather/14.png'),
  // 中雪 小雪-中雪
  icon15: require('../../assets/weather/15.png'),
  // 大雪 中雪-大雪 冷
  icon16: require('../../assets/weather/16.png'),
  // 暴雪 大雪-暴雪
  icon17: require('../../assets/weather/17.png'),
  // 雾 轻雾
  icon18: require('../../assets/weather/18.png'),
  // 冻雨
  icon19: require('../../assets/weather/19.png'),
  // 沙尘暴
  icon20: require('../../assets/weather/20.png'),
  // 浓雾 强浓雾 大雾 特强浓雾
  icon21: require('../../assets/weather/21.png'),
  // 浮尘 扬沙
  icon22: require('../../assets/weather/22.png'),
  // 强沙尘暴
  icon23: require('../../assets/weather/23.png'),
  // 霾 中度霾 重度霾 严重霾
  icon24: require('../../assets/weather/24.png'),
  // 未知
  icon25: require('../../assets/weather/25.png'),
  // 有风 微风 和风 清风
  icon26: require('../../assets/weather/26.png'),
  // 强风/劲风 疾风
  icon27: require('../../assets/weather/27.png'),
  // 大风 烈风 风暴
  icon28: require('../../assets/weather/28.png'),
  // 狂爆风 飓风 热带风暴
  icon29: require('../../assets/weather/29.png')
}

module.exports = {
  weather: weather
}
