<template>
  <div class="applyindex grayness" v-title data-title="志愿者">
    <mt-header fixed title="志愿者">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="apply-list">
        <div class="apply-wrap" v-for="(k, i) in info" :key="i" v-show="info !=''">
          <router-link class="link" :to="{path:'/Volunteer/apply',query:{aid:k.id}}"></router-link>
          <div class="apply-vol-wrap clearfix">
            <div class="apply-status pending fr" v-if="k.status=='1'">
              <span class="fr">正在审核</span>
            </div>
            <div class="apply-status pass fr" v-if="k.status =='2'">
              <span class="fr">通过</span>
            </div>
            <div class="fail pending fr" v-if="k.status !='1'&&k.status !='2'">
              <span class="fr">未通过</span>
            </div>
            <div class="apply-park-name">{{k.park_name}}</div>
          </div>
          <div class="apply-vol-wrap clearfix">
            <div class="apply-time">申请时间：{{k.apply_time}}</div>
            <div class="apply-text fr" v-if="k.complete == '1'">资料正在审核中</div>
            <div class="apply-text fr" v-if="k.complete != '1'">请完善您的资料</div>
          </div>
        </div>
        <div class="page-tips" style="background:#fff;" v-if="info ==''">您还没有参加过活动~</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user_id: this.get_User_Id(),
      info: {}
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      var that = this;
      if (this.hybrid.hybrid.versions.myapp) {
        // FOR 安卓最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding-top", "0");
        that.user_id = JavaScriptInterface.getUserId();
        that.getData();
      } else if (this.hybrid.hybrid.versions.myiOSapp) {
        // FOR IOS最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding-top", "0");
        this.hybrid.hybrid.registerHandler("getIOSUserId", function(
          data,
          responseCallback
        ) {
          that.user_id = data.user_id;
          var responseData = "已经收到IOS传来的userid";
          responseCallback(responseData);
          that.getData();
        });
      } else {
        that.getData();
      }
    },
    getData() {
      this.$ajax(
        "post",
        "Volunteer/vol_list",
        { user_id: this.user_id },
        ret => {
          if (ret.data.error == 0) this.info = ret.data.data;
        }
      );
    }
  }
};
</script>
<style scoped> 
.applyindex {
  height: 100%;
  background-color: #fff;
}
.applyindex .apply-wrap {
  position: relative;
}
.applyindex .apply-wrap .link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.apply-status.pending {
  background-image: url(../../assets/img/apply_pending.png);
}
.apply-status {
  display: inline-block;
  text-align: center;
  font-size: 0.7rem;
  color: #999;
  padding-left: 1rem;
  background: no-repeat left center;
  background-size: 0.5rem;
}
.apply-park-name {
  display: inline-block;
  font-size: 0.8rem;
  color: #666;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 13rem;
}
.apply-time {
  display: inline-block;
  padding-left: 1rem;
  font-size: 0.6rem;
  color: #999;
  background: url(../../assets/img/feed-item-time.png) no-repeat;
  background-size: 0.6rem;
  background-position: 0 50%;
}
.apply-text {
  color: #999;
  font-size: 0.6rem;
}
.page-tips {
  font-size: 0.6rem;
  padding-top: 4rem;
  color: #999;
  text-align: center;
}
</style>