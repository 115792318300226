<template>
  <div class="body">
    <div class="head">
      <div class="banner">
        <img :src="ainfo.banner_img_app" />
      </div>
    </div>
    <div class="bg__">
      <div class="video">
        <div class="left_up">
          <img src="../../assets/img/left_up.png" />
        </div>
        <div class="left_bottom">
          <img src="../../assets/img/left_bottom.png" />
        </div>
        <div class="right_up">
          <img src="../../assets/img/right_up.png" />
        </div>
        <div class="right_bottom">
          <img src="../../assets/img/right_bottom.png" />
        </div>
        <div class="tip-num">
          <span>{{sinfo.vote_numbers}}</span>
        </div>
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        ></video-player>
      </div>

      <!--文字说明-->
      <div class="content__">
        <div class="title__">
          <div class="title_left">
            <span>编&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号：</span>
          </div>
          <div class="video-name font_weight">
            <span>{{sinfo.id}}号</span>
          </div>
        </div>
        <div class="title__">
          <div class="title_left">
            <span>片&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</span>
          </div>
          <div class="video-name">
            <span>{{sinfo.spots_name}}</span>
          </div>
        </div>
        <div class="title__">
          <div class="title_left">
            <span>创作单位：</span>
          </div>
          <div class="video-name">
            <span>{{sinfo.user_name}}</span>
          </div>
        </div>
        <div class="rich__">
          <div class="content-tip">
            <span>内容提要：</span>
          </div>
          <div class="content-rich" v-html="sinfo.spots_content"></div>
        </div>
      </div>
    </div>

    <!--投票按钮-->
    <div class="vote">
      <div class="vote-up" v-if="is_show">
        <div class="method_">
          <div class="all_" @click="select_all">
            <span>全选</span>
          </div>
          <div class="vote_fun" v-if="is_show == 0">
            <span>投票未开始</span>
          </div>
          <div class="vote_fun" @click="video_vote" v-else-if="is_show == 1">
            <span>投票</span>
          </div>
          <div class="vote_fun" v-else-if="is_show == 3">
            <span>投票已结束</span>
          </div>
        </div>
      </div>
      <div class="vote-bottom">
        <div class="vote-index">
          <router-link class="link_" :to="{path:'/parkPhoto/VideoVote',query:{aid:aid}}">
            <div class="index-img">
              <img src="../../assets/img/index-img.png" />
            </div>
            <div class="index-text">
              <span>首页</span>
            </div>
          </router-link>
        </div>
        <div class="vote-index">
          <router-link class="link_" :to="{path:'/parkPhoto/VideoRank',query:{aid:aid}}">
            <div class="index-img">
              <img src="../../assets/img/rank_.png" />
            </div>
            <div class="index-text">
              <span>排行榜</span>
            </div>
          </router-link>
        </div>
        <div class="vote-index">
          <router-link class="link_" :to="{path:'/parkPhoto/VideoRule',query:{aid:aid}}">
            <div class="index-img">
              <img src="../../assets/img/rule.png" />
            </div>
            <div class="index-text">
              <span>规则说明</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.body .video {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 5%;
}
.body .video .left_up {
  position: absolute;
  margin-top: -2%;
  margin-left: 0%;
}
.body .video .left_bottom {
  position: absolute;
  left: 2%;
  bottom: 0%;
}
.body .video .right_up {
  position: absolute;
  top: -2%;
  right: 2%;
}
.body .video .right_bottom {
  position: absolute;
  bottom: 0;
  right: 2%;
}
.body .video .video-player {
  margin-bottom: 2%;
  width: 92%;
  margin-left: 4%;
}
.body .video .video-player-box {
  width: 100%;
  margin-left: 7%;
  margin-top: 2%;
}
.body .video .vjs-poster {
  background-color: white;
  background-position: 0% 0%;
  background-size: 100% 100%;
}
.body .video .video-js {
  height: 200px;
}
.body .video .video-js .vjs-big-play-button {
  margin-left: -8%;
  height: 2em;
  width: 2em;
  line-height: 2em;
  border-radius: 1em;
}

.body .bg__ {
  background-color: #ecc48d;
  margin-bottom: 2rem;
}
.body .content__ {
  display: inline-block;
  position: relative;
  width: 90%;
  margin-left: 5%;
}
.body .video .tip-num {
  display: inline-block;
  position: absolute;
  z-index: 10;
  margin-left: 6%;
}
.body .video .tip-num span {
  display: block;
  padding: 4px 2px 4px 2px;
  font-size: 0.8rem;
  background-color: #db3e3a;
  color: white;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.body .content__ .name__ {
  display: inline-block;
  position: relative;
  width: 100%;
}
.body .content__ .name__ span {
  display: block;
  padding: 4px;
  font-size: 1rem;
  font-weight: bold;
  color: #db3e3a;
}
.body .content__ .title__ {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 2%;
  border-top: 1px solid white;
}
.body .content__ .title__ .title_left {
  position: relative;
  width: 30%;
  float: left;
  margin-top: 2%;
}

.body .content__ .title__ .title_left span {
  display: block;
  padding: 4px;
  font-size: 1rem;
  color: #db3e3a;
  font-weight: bold;
}
.body .content__ .title__ .font_weight {
  font-weight: bold;
}
.body .content__ .title__ .video-name {
  position: relative;
  float: left;
  width: 70%;
  margin-top: 3%;
}
.body .content__ .title__ .video-name span {
  display: block;
  padding: 4px;
  font-size: 0.8rem;
  color: #db3e3a;
  text-align: center;
}
.body .content__ .rich__ {
  display: inline-block;
  position: relative;
  width: 100%;
  border-top: 1px solid white;
}
.body .content__ .rich__ .content-tip {
  position: relative;
  width: 30%;
  float: left;
}
.body .content__ .rich__ .content-tip span {
  display: block;
  padding: 4px;
  font-size: 1rem;
  font-weight: bold;
  color: #db3e3a;
}
.body .content__ .rich__ .content-rich {
  position: relative;
  width: 70%;
  float: left;
  font-size: 0.8rem;
  color: #db3e3a;
  margin-top: 2%;
}
.body .content__ .work {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 5%;
  border-top: 1px solid white;
}
.body .content__ .work span {
  display: block;
  padding: 2px;
  font-size: 1rem;
  color: #db3e3a;
}
</style>
<script>
import { Toast } from "mint-ui";
import { Indicator } from "mint-ui";
import "video.js/dist/video-js.css";

import { videoPlayer } from "vue-video-player";
export default {
  components: {
    videoPlayer
  },
  data() {
    return {
      aid: this.$route.query.aid,
      id: this.$route.query.id,
      ainfo: "",
      sinfo: "",
      playerOptions: {
        // videojs options
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src:
              "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
          }
        ],
        poster: "",
        isFullScreen: false
      },
      is_show: false
    };
  },
  mounted() {
    this.unload();
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    }
  },
  methods: {
    unload() {
      this.get_server_data();
      //this.weixin_hidden();
    },
    get_server_data() {
      var aid = this.aid;
      var id = this.id;
      if (aid && id) {
        this.$ajax(
          "post",
          "SpotsRace/get_video_detail",
          {
            aid: aid,
            id: id,
            token: "SpotsRace-get_video_detail"
          },
          ret => {
            if (ret.data.id == 0) {
              this.ainfo = ret.data.data.activity_info;
              this.sinfo = ret.data.data.spots_info;
              if (ret.data.data.spots_info.video_path) {
                this.playerOptions.sources[0].src =
                  ret.data.data.spots_info.video_path;
              }
              if (ret.data.data.spots_info.baseUrl) {
                this.playerOptions.poster = ret.data.data.spots_info.baseUrl;
              }
            } else {
              Toast("无数据");
            }
          }
        );
      } else {
        Toast("查询失败");
      }
    },
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },
    onPlayerLoadeddata(player) {},
    onPlayerCanplay(player) {},
    onPlayerCanplaythrough(player) {},
    onPlayerPlaying(player) {},
    onPlayerTimeupdate(player) {},
    onPlayerWaiting(player) {},
    onPlayerEnded(player) {},
    cancel(player) {},
    weixin_hidden() {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function onBridgeReady() {
          // 通过下面这个API隐藏右上角按钮
          WeixinJSBridge.call("hideOptionMenu");
        }
      );
    }
  }
};
</script>