<template>
  <div class="PriceApply" v-title data-title="活动报名">
    <mt-header fixed title="活动报名">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <form
        id="form"
        action="https://www.zmlxj.com/api.php/activity/add_activity_order"
        method="post"
        enctype="multipart/form-data"
        onsubmit="return false"
      >
        <mt-field
          label="编号"
          v-if="is_group != 0&&info.is_leader == 1"
          :attr="{disabled:'disabled',class:'blue mint-field-core'}"
          v-model="info.group_id"
        ></mt-field>
        <mt-field
          label="队名"
          v-if="is_group != 0&&info.is_leader == 1"
          :attr="{disabled:'disabled',class:'blue mint-field-core'}"
          v-model="info.group_name"
        ></mt-field>
        <mt-cell
          title="编号"
          v-if="is_group != 0&&info.is_leader != 1"
          to="javascript:;"
          is-link
          :value="group_id?group_id:'请选择编号'"
          @click.native="is_groups = !is_groups"
        ></mt-cell>
        <mt-cell
          title="队名"
          v-if="is_group != 0&&info.is_leader != 1"
          to="javascript:;"
          is-link
          :value="group_name?group_name:'请选择队名'"
          @click.native="is_groups = !is_groups"
        ></mt-cell>
        <mt-field label="费用" :attr="{disabled:'disabled'}" v-model="price_num"></mt-field>
        <mt-field
          label="昵称"
          placeholder="请输入昵称"
          v-model="info.user_nick"
          :attr="{name:'user_nick'}"
        ></mt-field>
        <mt-field label="手机" placeholder="请输入手机号码" v-model="info.phone" :attr="{name:'phone'}"></mt-field>
        <mt-cell
          title="性别"
          to="javascript:;"
          is-link
          :value="info.sex==1?'男':'女'"
          @click.native="is_sexs = !is_sexs"
          v-if="price > 0"
        ></mt-cell>
        <input type="hidden" v-model="info.sex" name="sex" />
        <mt-field
          label="真实姓名"
          placeholder="请输入真实姓名"
          v-model="info.name"
          :attr="{name:'name'}"
          v-if="price > 0"
        ></mt-field>
        <mt-field
          label="身份证号"
          placeholder="请输入身份证号"
          v-model="info.card_id"
          :attr="{name:'card_id'}"
          v-if="price > 0"
        ></mt-field>
        <mt-cell
          title="衣服尺寸"
          to="javascript:;"
          is-link
          :value="info.size"
          @click.native="is_sizes = !is_sizes"
          v-if="price > 0"
        ></mt-cell>
        <ul class="group_user_list" v-if="is_group !=0">
          <li>团队成员</li>
          <li v-for="(k,i) in group_user_list" :key="i" class="clearfix">
            <div class="fl">
              <div class="user_icon">
                <img :src="k.user_icon" alt />
                <i v-if="k.is_leader == 1">领队</i>
              </div>
              <span>{{k.user_nick}}</span>
            </div>
            <div class="fr fr-time">
              <img src="../../assets/img/feed-item-time.png" alt />
              {{k.add_time}}
            </div>
            <div
              class="f1 get-out"
              v-if="k.show_btn"
              @click="show_btn(info.user_id,k.user_id,k.id)"
            >
              <span v-if="k.show_btn == 1">踢出</span>
              <span v-if="k.show_btn == 2">退出</span>
            </div>
          </li>
        </ul>

        <div class="agreement">
          <input @click="is_checkbox=!is_checkbox" type="checkbox" :checked="is_checkbox" />我同意
          <router-link to="/activity/ApplyAgreement" class="blue">《最美旅行家报名协议》</router-link>
        </div>
        <input type="hidden" v-model="info.size" name="size" />
        <input type="hidden" name="activity_id" v-model="aid" />
        <input type="hidden" name="user_id" v-model="user_id" />
        <input type="hidden" name="price" v-model="price" />
        <input type="hidden" name="g_id" v-model="g_id" v-if="is_group != 0&&info.is_leader != 1" />
        <input
          type="hidden"
          name="group_name"
          v-model="group_name"
          v-if="is_group != 0&&info.is_leader != 1"
        />
        <input type="hidden" name="number" value="1" />
        <input type="hidden" name="car_numbers" v-model="car_numbers" />

        <!--传递用户的open_id-->
        <input type="hidden" name="open_id" v-model="open_id" />
        <input type="hidden" name="weixin_pay" v-model="weixin_pay" />
        <div class="btn" :class="{avtive:!is_checkbox}">
          <mt-button type="primary" @click="success" class="mint-header">{{button_info}}</mt-button>
        </div>
      </form>
      <mt-actionsheet :actions="sexs" v-model="is_sexs"></mt-actionsheet>
      <mt-actionsheet :actions="sizes" v-model="is_sizes"></mt-actionsheet>
      <mt-actionsheet :actions="group" v-model="is_groups"></mt-actionsheet>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      group_list: [],
      group_user_list: [],
      g_id: "",
      group_id: "",
      group_name: "",
      aid: this.$route.query.aid, //活动id
      user_id: "", //用户id
      price: this.$route.query.price, //报名费用
      price_num: this.$route.query.price + "元", //报名费用
      info: {
        user_nick: "", //用户昵称
        size: "", //衣服尺寸
        card_id: "", //用户身份证号
        name: "", //用户真实姓名
        phone: "", //用户电话号码
        sex: "" //用户性别
      },
      is_sexs: false, //是否显示性别弹窗
      is_sizes: false, //是否显示衣服尺寸弹窗
      is_groups: false, //是否编号 队名弹窗
      sexs: [
        //性别弹窗信息
        { name: "男", method: this.set_sex },
        { name: "女", method: this.set_sex }
      ],
      sizes: [
        //示衣服尺寸弹窗信息
        { name: "XXL", method: this.set_size },
        { name: "XL", method: this.set_size },
        { name: "L", method: this.set_size },
        { name: "M", method: this.set_size },
        { name: "S", method: this.set_size }
      ],
      group: [
        //性别编号 队名信息
        //{name: "男", method: this.set_group},
      ],
      button_info: "确定报名",
      is_checkbox: true, //是否同意协议
      is_disabled: true,
      is_group: -1, //是否组队
      is_leader: -1,
      open_id: localStorage.getItem("my_open_id"),
      weixin_pay: this.hybrid.hybrid.versions.is_weixin ? 1 : 0,
      is_group: this.$route.query.is_group ? this.$route.query.is_group : 0, //活动是否组队0-不组队，1-单人组队 2-多人组队
      car_numbers: "",
      card_id: ""
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      var that = this;
      if (this.check_login()) {
        this.user_id = this.check_login().user_id;
      }
      if (this.hybrid.hybrid.versions.myapp) {
        // FOR 安卓最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
        this.user_id = JavaScriptInterface.getUserId();
        this.getData();
      } else if (this.hybrid.hybrid.versions.myiOSapp) {
        // FOR IOS最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
        this.hybrid.hybrid.registerHandler("getIOSUserId", function(
          data,
          responseCallback
        ) {
          that.user_id = data.user_id;
          var responseData = "已经收到IOS传来的userid";
          responseCallback(responseData);
          that.getData();
        });
      } else {
        this.getData();
      }
    },
    getData() {
      this.$ajax(
        "post",
        "activity/get_activity_user_info",
        {
          activity_id: this.aid,
          user_id: this.user_id
        },
        ret => {
          if (ret.data.id == 0) {
            this.info = ret.data.data;
            this.is_group = ret.data.data.is_group;
            this.is_leader = ret.data.data.is_leader;
            this.group_id = ret.data.data.group_id;
            this.group_name = ret.data.data.group_name;
            this.group_user_list = ret.data.data.user_list;
            this.g_id = ret.data.data.g_id;
            this.car_numbers = ret.data.data.car_numbers;
            this.card_id = ret.data.data.card_id;
            if (ret.data.data.is_pay == 1 && ret.data.data.joined == 1) {
              if (!ret.data.data.g_id) {
                this.is_disabled = true; //说明已经报名成功了,只有先被踢出去了或者自己退出了,才能重新选择
                this.button_info = "报名成功,请选一个队";
              } else {
                //可以什么都不做
                this.is_disabled = false;
                this.button_info = "报名成功";
              }
            } else if (ret.data.data.is_pay == 0 && ret.data.data.joined == 1) {
              this.button_info = "报名成功  点击支付";
            } else {
              this.button_info = "确定报名";
            }
          }
        }
      );

      this.$ajax(
        "post",
        "activity/get_group_name_list",
        {
          aid: this.aid
        },
        ret => {
          if (ret.data.id == 0) {
            this.group_list = ret.data.data;
            for (var i = 0; i < this.group_list.length; i++) {
              this.group[i] = {
                name: this.group_list[i].group_name,
                method: this.set_group,
                g_id: this.group_list[i].g_id,
                g_name: this.group_list[i].g_name,
                group_id: this.group_list[i].group_id
              };
              //                            this.group[i].name = this.group_list[i].group_name
              //                            this.group[i].method = this.set_group
              //                            this.group[i].g_id = this.group_list[i].g_id
              //                            this.group[i].group_id = this.group_list[i].group_id
              //                            this.group[i].g_name = this.group_list[i].g_name
            }
          }
        }
      );
    },
    //            选择性别
    set_sex(e) {
      this.$set(this.info, "sex", e.name == "男" ? 1 : 0);
    },
    //            选择衣服大小
    set_size(e) {
      this.$set(this.info, "size", e.name);
    },
    set_group(e) {
      this.g_id = e.g_id;
      this.group_id = e.group_id;
      this.group_name = e.g_name;
    },
    success() {
      if (!this.is_checkbox) return;
      //add by lyh 2017-12-14
      if (this.is_group == 1) {
        //是队员，而且还是this.g_id < 1需要组队
        if (this.is_leader != 1 && !(this.g_id > 0)) {
          Toast("请选择队伍");
          return;
        }
      } else {
        //不需要组队-就不需要进行判断
      }
      if (this.price > 0) {
        if (!this.info.user_nick || !this.info.size || !this.info.sex) {
          Toast("请填全报名信息");
          return;
        }
        var regName = /^[\u4e00-\u9fa5]{2,4}$/;
        if (!regName.test(this.info.name) || this.info.name.length < 2) {
          Toast("真实姓名填写有误");
          return false;
        }
        //                var regIdNo = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[12])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i;
        if (this.info.card_id.length != 18) {
          Toast("请确认身份证号正确");
          return;
        }
      }

      if (!this.is_disabled) {
        Toast("已报名成功");
        return false;
      }
      document.getElementById("form").removeAttribute("onsubmit");
    },
    //踢人和退出按钮
    show_btn(my_id, other_id, id) {
      //console.log(my_id+'-'+other_id+'-'+id);
      if (my_id && other_id && id > 0) {
        this.$ajax(
          "post",
          "activity/modify_show_btn",
          {
            aid: this.aid,
            my_id: my_id,
            other_id: other_id,
            id: id,
            token: "token"
          },
          ret => {
            if (ret.data.id == 0) {
              if (this.is_group == 1) {
                //表示单人组队,直接跳转到活动详情
                this.$router.push({
                  path: "/activity/ActivityDetail",
                  query: { aid: this.aid } //, price: this.Info.price,is_group:this.Info.is_group, title: this.Info.activity_name
                });
              } else if (this.is_group == 2) {
                //多人组队,本页面刷新,重新选择
                window.location.reload();
              }
            } else {
              //修改失败
            }
          }
        );
      } else {
        console.log("少值");
      }
    }
  }
};
</script>
<style scoped>
.mint-field-core {
  text-align: end;
  padding-right: 20px;
}

.PriceApply {
  min-height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.PriceApply .btn {
  position: absolute;

  right: 0;
  left: 0;
  width: 100%;
}

.PriceApply .btn.avtive .mint-button--primary {
  background-color: #cccccc;
}

.PriceApply .btn .mint-button--primary {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
}

.PriceApply .agreement {
  text-align: center;
  padding: 6px 0;
  font-size: 14px;
  color: #666;
}

.group_user_list {
  background-color: #fff;
}

.group_user_list li {
  padding: 0.1rem 0.6rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  line-height: 2rem;
}

.group_user_list .user_icon {
  display: inline-block;
  min-width: 4rem;
  position: relative;
}

.group_user_list .user_icon i {
  font-style: inherit;
  background-color: red;
  color: #fff;
  font-size: 0.5rem;
  padding: 0.1rem 0.2rem;
  vertical-align: text-bottom;
  position: absolute;
  display: inline-block;
  line-height: initial;
}

.group_user_list .user_icon img {
  width: 2rem;
  vertical-align: middle;
  border: 1px solid #cccccc;
  border-radius: 50%;
}

.group_user_list .fl span {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
}

.group_user_list .fr img {
  width: 1rem;
  vertical-align: middle;
  margin-right: 6px;
}
.group_user_list .fr-time {
  float: inherit;
  margin-left: 1rem;
}
/*提出的按钮*/
.group_user_list .get-out {
  float: right;
  vertical-align: middle;
  background-color: palevioletred;
  color: #cccccc;
}
.group_user_list .get-out span {
  padding: 0.1rem;
}
</style>