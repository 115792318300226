<template>
  <div v-title data-title="约导游">
    <mt-header fixed title="活动列表">
      <a slot="left">
        <mt-button v-if="parkId" icon="back" @click="$router.go(-1)"></mt-button>
        <mt-button v-if="!parkId" @click="$router.go(-1)"></mt-button>
      </a>
    </mt-header>

    <div class="page-content">
      <guide-feed-item ref="child" :parkId="parkId" :listIndex="listIndex"></guide-feed-item>
    </div>

    <home-footer v-if="!parkId"></home-footer>
  </div>
</template>

<script>
import HomeFooter from "@/components/HomeFooter";
import GuideFeedItem from "@/components/GuideFeedItem";

export default {
  components: {
    HomeFooter,
    GuideFeedItem
  },
  data() {
    return {
      banner: {},
      items: [],
      listIndex: 6,
      parkId: this.$route.query.pid,
      parkName: "点击选择公园"
    };
  },
  watch: {
    listIndex() {
      this.getGuideBanner();
    }
  },
  mounted() {
    this.getGuideBanner();
  },
  methods: {
    getGuideBanner() {
      var that = this;
      this.$ajax(
        "post",
        "service/service_list",
        {
          park_id: that.parkId,
          type: that.listIndex
        },
        function(res) {
          if (res.data.id == 0) {
            //                    that.banner = res.data.data.banner;
            that.items = res.data.data.service;
          }
          that.$refs.child.parentMsg({ parkinfo: that.items });
        }
      );
    }
  }
};
</script>

<style scoped>
.select-park-btn {
  color: #fff;
  font-size: 12px;
  margin: 0 auto;
  line-height: 40px;
  padding-right: 18px;
  background: url(../../assets/img/feed-downlist.png) right center no-repeat;
  background-size: 15px;
}

.select-park-btn .avatar {
  margin: 5px 10px 0 0;
  width: 30px;
  height: 30px;
}

.homelink-item img {
  width: 68%;
  height: auto;
  margin: 0 auto;
}
</style>