<template>
  <div>
    <ServiceTitle></ServiceTitle>

    <div class="page-content">
      <div class="new-title-content">选择公园</div>
      <div class="book-content">
        <mt-index-list id="park-list">
          <mt-index-section :index="item.index" v-for="item in items" :key="item.index">
            <mt-cell
              v-for="park in item.data"
              to="javascript:void(0);"
              :key="park.id"
              :title="park.park_name"
              :pid="park.id"
              @click.native="getParkId(park.id,park.park_name)"
            ></mt-cell>
          </mt-index-section>
        </mt-index-list>
      </div>
    </div>
  </div>
</template>

<style scoped>
#park-list .mint-cell:hover,
#park-list .mint-cell.active {
  background-color: #ff9a80;
  color: #fff;
}
</style>

<script>
import ServiceTitle from "../../components/ServiceTitle";

export default {
  components: {
    ServiceTitle
  },
  data() {
    return {
      items: [],
      type: ""
    };
  },
  mounted() {
    this.get_park();
  },
  methods: {
    get_park() {
      var that = this;
      this.type = this.$route.query.type;

      this.$ajax("post", "Service/get_park", { web: 1 }, function(res) {
        that.items = res.data.data.normal;
      });
    },

    getParkId(id, value) {
      //                console.log(value);
      if ($("#" + id + "").hasClass("active")) {
        $("#" + id + "").removeClass("active");
      } else {
        $("#" + id + "").addClass("active");
      }

      this.$router.push("/footguide/NewFootGuide?type=" + this.type);

      this.save_arr("park_id", id);
      this.save_arr("park_name", value);
    }
  }
};
</script>