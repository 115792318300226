<template>
  <div class="order-container width-768">
    <div class="order-header width-768">
      <!-- 搜索 -->
      <div class="order-search"></div>
      <!-- 目录 -->

      <ul class="order-menu">
        <li
          v-for="(item, i) in menu_list"
          :key="i"
          :class="['menu-item pointer', current_menu === i ? 'menu-item-active' : '']"
          @click="toggleMenu(i)"
        >{{item.title}}</li>
      </ul>
    </div>
    <div class="order-content" ref="order" @scroll="handleScroll($event)">
      <!-- 订单列表 -->
      <div class="error" v-show="show_error">
        <img src="../../assets/switch/error.png" alt />
        <p>没有相关订单</p>
      </div>
      <ul class="order-list" v-show="!show_error">
        <li class="order-item" v-for="(item, i) in order_list" :key="i">
          <div class="order-title">
            <span class="order-title-name" @click="jumpToSpots(item.sid)">
              <i class="iconfont iconshanghu"></i>
              <span>{{item.title_}}</span>

              <i class="iconfont iconqianjin"></i>
            </span>
            <span
              class="order-stats"
              :style="'color:' + computeStas(item).color"
            >{{computeStas(item).stats}}</span>
          </div>
          <div class="item-content">
            <img class="order-icon" :src="item.newUrl" v-if="item.newUrl" />
            <img class="order-icon" src="../../assets/switch/error.png" v-else />
            <div class="order-info">
              <p class="order-type">{{getOrderStats(detail_order)}}</p>
              <p class="order-option">{{item.priceText}}</p>
              <p class="order-time">{{item.add_time}}</p>
            </div>
          </div>

          <div class="item-footer">
            <div class="order-amount">
              共
              <span class="order-num">{{item.numbers}}</span>件商品
              合计：
              <span class="order-money">￥{{item.amount}}</span>
            </div>
            <div class="item-button">
              <button class="order-btn order-btn-detail pointer" @click="showDetail(item)">详情</button>
              <!-- <button class="order-btn order-btn-cancel pointer" v-if="item.status == '0'">取消</button> -->
              <button
                class="order-btn order-btn-more pointer"
                @click="jumpToSpots(item)"
              >{{item.is_pay == 1 ? '再次购买' : '去付款'}}</button>
            </div>
          </div>
        </li>
      </ul>
      <!-- 加载更多 -->
      <div class="order-loadmore" v-show="order_list && order_list.length" @click="loadMore">
        <span class="loadmore-line"></span>
        <span class="loadmore-text">{{have_more ? "点击加载更多" : "我是有底线的"}}</span>
      </div>
      <!-- 订单详情 -->
      <transition duration="300">
        <div class="detail width-768" v-show="show_detail">
          <transition name="bounce">
              <div class="detail-box" v-show="show_detail">
                <div class="close pointer" @click="show_detail=false">
                  <i class="iconfont iconclose"></i>
                </div>
                <div class="detail-header">
                  <p>订单信息</p>
                </div>
                <div class="detail-wrapper">
                  <div class="detail-content">
                    <img :src="detail_order.newUrl" alt />
                    <div class="detail-info">
                      <p class="info-title">{{detail_order.title_}}</p>
                      <p class="info-option">{{detail_order.priceText}}</p>
                      <p class="info-price">
                        <span>数量：{{detail_order.numbers}}</span>
                        <span>
                          总价：
                          <span class="price">￥{{detail_order.amount}}</span>
                        </span>
                      </p>
                    </div>
                  </div>
                  <ul class="detail-list">
                    <li class="detail-item">
                      <p class="item-left">
                        <span>订单类型：</span>
                      </p>
                      <p class="item-right">{{getOrderStats(detail_order)}}</p>
                    </li>
                    <li class="detail-item">
                      <p class="item-left">
                        <span>创建时间：</span>
                      </p>
                      <p class="item-right">{{detail_order.add_time}}</p>
                    </li>
                    <li class="detail-item">
                      <p class="item-left">
                        <span>订单总价：</span>
                      </p>
                      <p class="item-right">￥{{detail_order.amount}}</p>
                    </li>
                    <li class="detail-item">
                      <p class="item-left">
                        <span>订单编号：</span>
                      </p>
                      <p class="item-right">{{detail_order.order_id}}</p>
                    </li>
                    <li class="detail-item" v-if="detail_order.btime != '0'">
                      <p class="item-left">
                        <span>开始日期：</span>
                      </p>
                      <p class="item-right">{{detail_order.btime}}</p>
                    </li>
                    <li class="detail-item" v-if="detail_order.etime != '0'">
                      <p class="item-left">
                        <span>结束日期：</span>
                      </p>
                      <p class="item-right">{{detail_order.etime}}</p>
                    </li>
                    <li class="detail-item" v-if="detail_order.notes">
                      <p class="item-left">
                        <span>备注信息：</span>
                      </p>
                      <p class="item-right">{{detail_order.notes}}</p>
                    </li>
                  </ul>
                  <div class="detail-btn">
                    <button class="pointer">{{detail_order.is_pay == 1 ? "删除订单" : "取消订单"}}</button>
                    <a :href="'tel:' + detail_order.tel_">
                      <button class="pointer">联系客服</button>
                    </a>
                    <button class="pointer" @click="jumpToSpots(detail_order)">返回商户</button>
                  </div>
                </div>
              </div>
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { Indicator } from 'mint-ui'
export default {
  data () {
    return {
      menu_list: [
        {
          name: 'all_order',
          title: '全部'
        },

        {
          name: 'no_pay_order',
          title: '待付款'
        },
        {
          name: 'is_pay_order',
          title: '已付款'
        },
        {
          name: 'no_send_order',
          title: '待发货'
        },
        {
          name: 'is_send_order',
          title: '已发货'
        }
      ],
      // 初始数据
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      park_id: 298,
      user_id: '',
      version: '1.0.0',
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      id: this.$route.query.id || 0,
      self_: this.$route.query.self_ || 1,
      backUrl: 'shopOrder',

      // get数据
      order_list: [],
      all_order: {
        list: [],
        page: 1,
        more: true,
        type: 'all'
      },
      no_pay_order: {
        list: [],
        page: 1,
        more: true,
        type: 'noPay'
      },
      is_pay_order: {
        list: [],
        page: 1,
        more: true,
        type: 'isPay'
      },
      is_send_order: {
        list: [],
        page: 1,
        more: true,
        type: 'isSend'
      },
      no_send_order: {
        list: [],
        page: 1,
        more: true,
        type: 'noSend'
      },
      have_more: true,

      is_admin: '', // 管理者标识

      //   目录
      current_menu: 0,
      is_first: true, // 初次加载不显示error
      detail_order: {},
      show_detail: false,

      loading: false // 加载中
    }
  },
  computed: {
    show_error: function () {
      if (!this.is_first) {
        return !this.order_list.length
      }
    }
  },
  mounted () {
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id !== 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token === this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }
    if (this.open_id) {
    } else {
      this.authorize()
    }

    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }

    // 获取数据
    this.getData(this.all_order)
  },

  methods: {
    handleScroll (e) {
      if (this.have_more && !this.loading) {
        const sh = e.target.scrollHeight
        const st = e.target.scrollTop
        const sc = e.target.clientHeight
        if (st + sc >= sh - 100) {
          this.loadMore()
        }
      }
    },
    //   获取订单类型
    getOrderStats (order) {
      if (order.is_group_ === '1' && order.is_finish === '1') {
        return '团购订单（已结束）'
      } else if (order.is_group_ === '1' && order.is_finish === '0') {
        return '团购订单（进行中）'
      } else {
        return '个人订单'
      }
    },
    //   详情
    showDetail (order) {
      this.detail_order = order
      this.show_detail = true
    },
    //   跳转商户
    jumpToSpots (order) {
      if (typeof order === 'object') {
        if (order.is_pay === 1) {
          // 已经支付，返回商户
          this.$router.push({
            path: '/footprints/PrimaryScenic',
            query: {
              sid: order.sid
            }
          })
        } else if (order.is_pay === 0) {
          // 未支付，去支付
          this.$router.push({
            path: '/payforgoods/payforgoods',
            query: {
              oid: order.id,
              sid: order.sid,
              id_: order.label_id,
              user_id: this.user_id
            }
          })
        }
      } else {
        this.$router.push({
          path: '/footprints/PrimaryScenic',
          query: {
            sid: order
          }
        })
      }
    },
    // 加载更多
    loadMore () {
      const name = this.menu_list[this.current_menu].name
      this.getData(this[name])
    },
    // 计算支付状态
    computeStas (obj) {
      if (obj.is_pay === '1') {
        return {
          stats: '已付款',
          color: 'rgba(0, 0, 0,0.8)'
        }
      } else {
        return {
          stats: '待付款',
          color: 'rgb(209, 138, 43)'
        }
      }
    },
    //   切换目录
    toggleMenu (i) {
      this.current_menu = i

      const name = this.menu_list[this.current_menu].name
      if (this[name].page === 1) {
        this.$refs.order.scrollTop = 0
        this.getData(this[name])
      } else {
        this.order_list = this[name].list
      }
      this.have_more = this[name].more
    },
    getData (order) {
      this.loading = true
      Indicator.open()
      if (this.open_id && this.id && this.park_id && this.token) {
        this.$ajax(
          'post',
          'Shop/get_owner_shop_order_list',
          {
            open_id: this.open_id,
            id: this.id,
            token: this.token,
            park_id: this.park_id,
            version: this.version,
            where_: 'web',
            page: order.page,
            type_: order.type,
            is_myself: this.self_,
            key: ''
          },
          res => {
            if (res.data.id === 0) {
              // console.log(res.data.data.list);
              order.list = order.list.concat(res.data.data.list)
              this.order_list = order.list
              order.page++
              if (res.data.data.list.length < 10) {
                order.more = false
              }
              this.have_more = order.more
            } else if (res.data.id === -2) {
              console.log('无数据')
              this.order_list = order.list
              order.more = false
              this.have_more = order.more
            } else {
              console.log('请求错误')
            }
            if (this.is_first) {
              this.is_first = false
            }
            Indicator.close()
            this.loading = false
          }
        )
      }
    },
    // 授权方法
    authorize () {
      const url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&id_=0' +
        '&state=' +
        this.backUrl
      window.location.href = url
    }
  }
}
</script>

<style scoped>
.order-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  overflow-y: auto;
  background-color: rgb(240, 242, 244);
}
/* 顶部 */
.order-header {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 3rem;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 2px 4px -2px #00000011;
  z-index: 1;
}
.order-menu {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.order-menu .menu-item {
  width: 50%;
  text-align: center;
  position: relative;
  color: #999;
  font-size: 0.8rem;
  transition: 0.3s;
}

.order-menu .menu-item::after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  bottom: -0.4rem;
  left: 50%;
  border-radius: 3px;
  transform: translateX(-50%);
  background-color: rgba(238, 51, 51, 0.479);
  transition: 0.3s;
}
.order-menu .menu-item-active::after {
  width: 1.6rem;
  transition: 0.3s;
}
.order-menu .menu-item-active {
  color: #333;
  transition: 0.3s;
}
/* 订单列表 */
.error {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error img {
  opacity: 0.6;
  width: 60%;
  margin-bottom: 1rem;
}
.error p {
  font-size: 0.8rem;
  color: #666;
}
.order-content {
  position: relative;
  width: 100%;
  height: 100vh;
  padding-top: 3rem;
  box-sizing: border-box;
  overflow-y: auto;
}
.order-content::-webkit-scrollbar {
  display: none;
}
.order-list {
  position: relative;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding: 0.8rem;
}
.order-item {
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.44rem;
  margin-bottom: 0.8rem;
  box-shadow: 0 0 2px #00000011;
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  box-sizing: border-box;
}
.order-item:last-child {
  margin: 0;
}
.item-content {
  display: flex;
}
.order-icon {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  margin-right: 0.5rem;
  border-radius: 0.2rem;
}
.order-info {
  width: calc(100% - 3.5rem);
  display: flex;
  flex-direction: column;
}
/* .order-price{
  width: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
} */
.order-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #eee;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.order-title-name {
  font-size: 0.8rem;
  color: #333;
  display: flex;
  align-items: center;
}
.order-title-name .iconshanghu {
  color: #666;
  margin-right: 4px;
  font-size: 0.8rem;
}
.order-title-name .iconqianjin {
  color: #666;
  margin-left: 4px;
  font-size: 0.8rem;
}
.order-stats {
  /* color: rgba(206, 134, 0, 0.4); */
  font-size: 0.68rem;
}

.order-option {
  font-size: 0.68rem;
  color: #666;
  line-height: 1.2rem;
}
.order-amount {
  line-height: 2rem;
  font-size: 0.68rem;
  color: #666;
}
.order-time {
  font-size: 0.68rem;
  color: #666;
  line-height: 1rem;
}
.order-type {
  font-size: 0.68rem;
  color: #666;
  line-height: 1rem;
}
.order-num {
  color: #000;
}
.order-money {
  color: #000;
  font-size: 0.88rem;
}
.item-footer {
  /* padding-top: 0.5rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
button {
  outline: none;
  border: none;
  color: #666;
  border-radius: 2em;
  font-size: 0.6rem;
  padding: 6px 18px;
  background: transparent;
}
.item-footer .item-button .order-btn {
  outline: none;
  border: 1px solid #ddd;
  margin-right: 6px;
}
.item-footer .item-button .order-btn:last-child {
  margin-right: 0;
}
.item-footer .item-button .order-btn-more {
  border-color: #f9d7d7;
  color: #fc5858;
}
/* 加载更多 */
.order-loadmore {
  position: relative;
  width: 100%;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  z-index: 1;
  margin-bottom: 0.5rem;
}
.order-loadmore .loadmore-line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  width: 40%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: -1;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
}
.order-loadmore .loadmore-text {
  display: inline-block;
  color: rgba(0, 0, 0, 0.3);
  padding: 0 4px;
  background-color: rgb(240, 242, 244);
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
}
/* 详情 */
.bounce-enter,
.bounce-leave-to {
  transform: translateY(100%);
}
.bounce-enter-to,
.bounce-leave {
  transform: translateY(0);
}
.bounce-enter-active,
.bounce-leave-active {
  transition: ease 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: 0.3s;
}

.detail {
  position: fixed;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.detail-box {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
}

.close {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close .iconclose {
  color: #666;
  font-size: 1rem;
}
.detail .detail-header {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.88rem;
  color: #333;
  text-align: center;
  padding: 0 0.5rem;
  box-sizing: border-box;
}
.detail .detail-header p {
  border-bottom: 1px solid #eee;
}
.detail-wrapper {
  width: 100%;
  /* height: calc(100% - 2rem); */
  padding: 0.5rem;
  box-sizing: border-box;
  overflow-y: auto;
}
.detail-wrapper .detail-content {
  width: 100%;
  display: flex;
}

.detail-content img {
  width: 4rem;
  height: 4rem;
  border-radius: 0.4rem;
  margin-right: 0.5rem;
}
.detail-content .detail-info {
  display: flex;
  flex-direction: column;
  width: calc(100% - 4.5rem);
}
.info-title {
  font-size: 0.8rem;
  line-height: 1.6rem;
}
.info-option {
  font-size: 0.68rem;
  color: #666;
  line-height: 1.2rem;
}
.info-price {
  font-size: 0.68rem;
  color: #666;
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-price .price {
  font-size: 0.8rem;
  color: #333;
}
.detail-list {
  width: 100%;
  margin-top: 1rem;
}
.detail-item {
  display: flex;
  align-items: center;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.68rem;
  color: #666;
}
.detail-item .item-left {
  width: 3.8rem;
}
.detail-btn {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0;
}
.detail-btn button {
  padding: 6px 18px;
  border: 1px solid #ddd;
  border-radius: 2em;
  margin-left: 4px;
}
</style>
