<template>
  <div class="SearchIndex" v-title data-title="搜索">
    <!-- 头部 -->
    <header class="mint-header is-fixed header search fixed">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
      <div class="input_search">
        <i class="iconfont iconsousuo"></i>
        <input class="inp" type="text" placeholder="请输入关键字" v-model="key_word" />
        <button @click="getData(1,type)">搜索</button>
      </div>
    </header>
    <div class="page-content botton0">
      <div class="all_list_box" v-if="info && info.length > 0">
        <ul class="all_list" v-for="(list_data, i) in info" :key="i">
          <li>{{list_data.title}}</li>
          <li
            tag="li"
            @click="click_path_new(k.is_spots,k.id,k.type)"
            :to="{name:'otherIndex', params: { uid: k.id }}"
            v-for="k in list_data.list"
            :key="k.id"
          >
            <img :src="k.web_newUrl" alt />
            {{k.title}}
          </li>
          <li
            v-if="list_data.more_numbers != 0"
            @click="getData(1,list_data.type)"
          >查看更多 ({{list_data.more_numbers}})</li>
        </ul>
      </div>
      <div v-else-if="value_list && value_list.length > 0">
        <mt-loadmore
          :bottom-method="loadBottom"
          :bottom-all-loaded="allLoaded"
          ref="loadmore"
          :auto-fill="false"
        >
          <ul class="all_list no_border">
            <li v-if="type == 1">我的足迹</li>
            <li v-if="type == 2">足迹</li>
            <li v-if="type == 3">路书</li>
            <li v-if="type == 4">公园</li>
            <li v-if="type == 5">用户</li>
            <li
              tag="li"
              @click="click_path_new(k.is_spots,k.id,k.type,k.title)"
              :to="{name:'otherIndex', params: { uid: k.id }}"
              v-for="k in value_list"
              :key="k.id"
            >
              <img :src="k.web_newUrl" alt />
              {{k.title}}
            </li>
          </ul>
        </mt-loadmore>
      </div>
      <div v-else class="foot_noinfo">
        <img src="/static/img/foot_noinfo@2x.14391c2.png" alt />
        <p>{{reminder}}</p>
      </div>
    </div>
  </div>
</template>
<script>
//    type = //1-我的足迹 2-> 所有的足迹 3-> 路书 4->公园 5-> 用户
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      info: {},
      value_list: [],
      my_id: "",
      type: this.$route.query.type_ ? this.$route.query.type_ : "",
      page: 1, //数据页码
      allLoaded: false, //加载更多
      key_word: "",
      reminder: "请输入关键字搜索",
      is_ticket: this.$route.query.is_ticket ? this.$route.query.is_ticket : 0
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      if (this.check_login()) {
        this.my_id = this.check_login().user_id;
      }
    },
    //            获取数据
    getData(page, type) {
      this.type = type;
      this.$ajax(
        "post",
        "park/search",
        {
          page: page,
          key_word: this.key_word,
          type: type,
          user_id: this.my_id
        },
        res => {
          if (res.data.id == 0) {
            if (page == 1) {
              this.info = res.data.data;
            }
            if (type) {
              this.value_list = this.value_list.concat(
                res.data.data.value_list
              );
            }
            if (this.info.have_value != 1) {
              this.reminder = "请输入其他关键字搜索";
            }
          }
        }
      );
    },
    //            加载更多
    loadBottom() {
      var that = this;
      var itemLen = that.value_list.length;
      setTimeout(() => {
        if (itemLen % 10 == 0) {
          that.page++;
          that.getData(that.page, that.type);
        } else {
          that.allLoaded = true;
          Toast("没有更多了");
        }
        that.$refs.loadmore.onBottomLoaded();
      }, 1500);
    },
    click_path_new(is_spots, id, type, title) {
      if (type == 1 || type == 2) {
        if (is_spots == 1) {
          this.$router.push({
            path: "/footprints/PrimaryScenic",
            query: { sid: id, id_: 0 }
          });
        } else {
          this.$router.push({
            path: "/footprints/FootprintsParticulars",
            query: { id: id }
          });
        }
      } else if (type == 3) {
        this.$router.push({
          path: "/footprints/LineInfoListtwo",
          query: { label_id: id }
        });
      } else if (type == 4) {
        if (this.is_ticket == 1) {
          this.$router.push({
            path: "/ticket/TicketList",
            query: {
              type_: "park_ticket",
              id: id,
              token: "zmlxj_20198",
              title: title
            }
          });
        } else {
          this.$router.push({
            path: "/parkPhoto/ParkMapLineList",
            query: { park_id: id }
          });
        }
      } else if (type == 5) {
        this.$router.push({ name: "otherIndex", params: { uid: id } });
      }
    }
  }
};
</script>
<style scoped>
.no_border {
  border: none !important;
}

.header.search {
  background-color: #ff9a80;
}

.SearchIndex {
  min-height: 100%;
  background-color: #fff;
}

.SearchIndex .input_search {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
}

.SearchIndex .input_search i {
  position: absolute;
  left: 0.4rem;
  color: rgb(252, 144, 144);
}

.SearchIndex .input_search .inp {
  height: 1.6rem;
  line-height: 1.6rem;
  background-color: #fff;
  padding-left: 2rem;
  flex: 3;
  font-size: 0.7rem;
  border-radius: 6rem;
}

.SearchIndex .input_search button {
  background-color: #ff9a80;
  color: #fff;
  border: none;
  font-size: 0.78rem;
}

ul.all_list {
  padding: 0 0.4rem;
  font-size: 0.8rem;
  padding-bottom: 0.2rem;
  border-bottom: 0.2rem solid #ccc;
}

ul.all_list li {
  height: 2.2rem;
  line-height: 2.2rem;
  border-bottom: 1px solid #f2f2f2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

ul.all_list li:last-child {
  border: none;
}

ul.all_list li img {
  width: 2rem;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.page-content {
  width: 100vw;
  min-height: 100vh;
  padding-top: 40px;
  box-sizing: border-box;
}

.foot_noinfo {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>