// icon
// 5-问号（其他） 7-汽车维修 12-民宿 14-植物 15-停车场  16-售票厅  17-厕所  18-美食 19-景点 20-购物
// 21-房子  24-书（文创） 25-咖啡  26-酒店  27-朋友圈  28-书（电子书）  29-遗迹  30-厂区（花）
// 37-地质（地质层）38-保护区 39-赏花 40-滑雪 41-热门 42-全部 43-遗迹 44-古生物 45-人文 46-自然遗迹
const icon = {
  iconMap0: require('../pixi/map_icon_27.png'),
  iconMap5: require('../pixi/map_icon_5.png'),
  iconMap7: require('../pixi/map_icon_7.png'),
  iconMap12: require('../pixi/map_icon_12.png'),
  iconMap13: require('../../assets/markers/label_29_13.png'),
  iconMap14: require('../pixi/map_icon_30.png'),
  iconMap15: require('../pixi/map_icon_15.png'),
  iconMap16: require('../pixi/map_icon_16.png'),
  iconMap17: require('../pixi/map_icon_17.png'),
  iconMap18: require('../pixi/map_icon_18.png'),
  iconMapAudio: require('../pixi/map_icon_audio.png'),
  iconMap19: require('../pixi/map_icon_19.png'),
  iconMap20: require('../pixi/map_icon_20.png'),
  iconMap21: require('../pixi/map_icon_21.png'),
  iconMap24: require('../pixi/map_icon_24.png'),
  iconMap25: require('../pixi/map_icon_25.png'),
  iconMap26: require('../pixi/map_icon_26.png'),
  iconMap27: require('../pixi/map_icon_27.png'),
  iconMap28: require('../pixi/map_icon_28.png'),
  iconMap29: require('../pixi/map_icon_29.png'),
  iconMap30: require('../pixi/map_icon_30.png'),
  iconMap36: require('../pixi/map_icon_normal.png'),
  iconMap37: require('../pixi/map_icon_37.png'),
  iconMap43: require('../pixi/map_icon_29.png'),
  iconMap44: require('../pixi/map_icon_normal.png'),
  iconMap45: require('../pixi/map_icon_45.png'),
  iconMap46: require('../pixi/map_icon_46.png'),
  iconMap2494: require('../../assets/markers/label_29_2494.png'),//第一组
  iconMap185: require('../../assets/markers/label_29_185.png'),//第二组
  iconMap186: require('../../assets/markers/label_29_186.png'),//第三组
  iconMap187: require('../../assets/markers/label_29_187.png'),//第四组
  iconMap188: require('../../assets/markers/label_29_188.png'),//第五组
  iconMap200: require('../../assets/markers/label_29_200.png'),//第六组
  iconMap201: require('../../assets/markers/label_29_201.png'),//第七组
  iconMap203: require('../../assets/markers/label_29_203.png'),//第八组
  iconMap204: require('../../assets/markers/label_29_204.png'),//第九组
  iconMap2492: require('../../assets/markers/label_29_2492.png'),//第十组
  iconMap2510: require('../../assets/markers/label_29_2510.png'),//第十组

  iconMapline: require('../pixi/map_icon_line.png'),
  iconMapNormal: require('../pixi/map_icon_normal.png'),
  iconMapCluster: require('../pixi/map_icon_cluster.png'),
  iconMapClusterGd: require('../pixi/map_icon_cluster_gd.png'),
  // icon
  icon_0: require('../pixi/icon_27.png'),
  icon_5: require('../pixi/icon_5.png'),
  icon_7: require('../pixi/icon_7.png'),
  icon_12: require('../pixi/icon_12.png'),
  icon_13: require('../pixi/icon_13.png'),
  icon_14: require('../pixi/icon_30.png'),
  icon_15: require('../pixi/icon_15.png'),
  icon_16: require('../pixi/icon_16.png'),
  icon_17: require('../pixi/icon_17.png'),
  icon_18: require('../pixi/icon_18.png'),
  icon_19: require('../pixi/icon_19.png'),
  icon_20: require('../pixi/icon_20.png'),
  icon_21: require('../pixi/icon_21.png'),
  icon_24: require('../pixi/icon_24.png'),
  icon_25: require('../pixi/icon_25.png'),
  icon_26: require('../pixi/icon_26.png'),
  icon_27: require('../pixi/icon_27.png'),
  icon_28: require('../pixi/icon_28.png'),
  icon_29: require('../pixi/icon_29.png'),
  icon_30: require('../pixi/icon_30.png'),
  icon_36: require('../pixi/icon_normal.png'),
  icon_37: require('../pixi/icon_37.png'),
  icon_43: require('../pixi/icon_29.png'),
  icon_44: require('../pixi/icon_normal.png'),
  icon_45: require('../pixi/icon_45.png'),
  icon_46: require('../pixi/icon_46.png'),
  icon_line: require('../pixi/icon_line.png'),
  icon_2494: require('../pixi/icon_2494.png'),//第一组
  icon_185: require('../pixi/icon_185.png'),//第二组
  icon_186: require('../pixi/icon_186.png'),//第三组
  icon_187: require('../pixi/icon_187.png'),//第四组
  icon_188: require('../pixi/icon_188.png'),//第五组
  icon_200: require('../pixi/icon_200.png'),//第六组
  icon_201: require('../pixi/icon_201.png'),//第七组
  icon_203: require('../pixi/icon_203.png'),//第八组
  icon_204: require('../pixi/icon_204.png'),//第九组
  icon_2492: require('../pixi/icon_2492.png'),//第十组
  icon_2510: require('../pixi/icon_2510.png'),//第十组
  // 当前icon
  icon_act_0: require('../pixi/icon_act_27.png'),
  icon_act_5: require('../pixi/icon_act_5.png'),
  icon_act_7: require('../pixi/icon_act_7.png'),
  icon_act_12: require('../pixi/icon_act_12.png'),
  icon_act_13: require('../pixi/icon_act_13.png'),

  icon_act_14: require('../pixi/icon_act_30.png'),
  icon_act_15: require('../pixi/icon_act_15.png'),
  icon_act_16: require('../pixi/icon_act_16.png'),
  icon_act_17: require('../pixi/icon_act_17.png'),
  icon_act_18: require('../pixi/icon_act_18.png'),
  icon_act_19: require('../pixi/icon_act_19.png'),
  icon_act_20: require('../pixi/icon_act_20.png'),
  icon_act_21: require('../pixi/icon_act_21.png'),
  icon_act_24: require('../pixi/icon_act_24.png'),
  icon_act_25: require('../pixi/icon_act_25.png'),
  icon_act_26: require('../pixi/icon_act_26.png'),
  icon_act_27: require('../pixi/icon_act_27.png'),
  icon_act_28: require('../pixi/icon_act_28.png'),
  icon_act_29: require('../pixi/icon_act_29.png'),
  icon_act_30: require('../pixi/icon_act_30.png'),
  icon_act_36: require('../pixi/icon_act_normal.png'),
  icon_act_37: require('../pixi/icon_act_37.png'),
  icon_act_43: require('../pixi/icon_act_29.png'),
  icon_act_44: require('../pixi/icon_act_normal.png'),
  icon_act_45: require('../pixi/icon_act_45.png'),
  icon_act_46: require('../pixi/icon_act_46.png'),
  icon_act_line: require('../pixi/icon_act_line.png'),
  icon_act_2494: require('../pixi/icon_act_2494.png'),//第一组
  icon_act_185: require('../pixi/icon_act_185.png'),//第二组
  icon_act_186: require('../pixi/icon_act_186.png'),//第三组
  icon_act_187: require('../pixi/icon_act_187.png'),//第四组
  icon_act_188: require('../pixi/icon_act_188.png'),//第五组
  icon_act_200: require('../pixi/icon_act_200.png'),//第六组
  icon_act_201: require('../pixi/icon_act_201.png'),//第七组
  icon_act_203: require('../pixi/icon_act_203.png'),//第八组
  icon_act_204: require('../pixi/icon_act_204.png'),//第九组
  icon_act_2492: require('../pixi/icon_act_2492.png'),//第十组
  icon_act_2510: require('../pixi/icon_act_2510.png'),//第十组
}

const marker = {
  marker_5: require('../../assets/markers/label_29_5.png'),
  marker_6: require('../../assets/markers/label_29_6.png'),
  marker_7: require('../../assets/markers/label_29_7.png'),
  marker_8: require('../../assets/markers/label_29_8.png'),
  marker_9: require('../../assets/markers/label_29_9.png'),
  marker_10: require('../../assets/markers/label_29_10.png'),
  marker_11: require('../../assets/markers/label_29_11.png'),
  marker_12: require('../../assets/markers/label_29_12.png'),
  marker_13: require('../../assets/markers/label_29_13.png'),
  marker_14: require('../../assets/markers/label_29_14.png'),
  marker_15: require('../../assets/markers/label_29_15.png'),
  marker_16: require('../../assets/markers/label_29_16.png'),
  marker_17: require('../../assets/markers/label_29_17.png'),
  marker_18: require('../../assets/markers/label_29_18.png'),
  marker_19: require('../../assets/markers/label_29_19.png'),
  marker_20: require('../../assets/markers/label_29_20.png'),
  marker_21: require('../../assets/markers/label_29_21.png'),
  marker_23: require('../../assets/markers/label_29_23.png'),
  marker_24: require('../../assets/markers/label_29_24.png'),
  marker_25: require('../../assets/markers/label_29_25.png'),
  marker_26: require('../../assets/markers/label_29_26.png'),
  marker_28: require('../../assets/markers/label_29_28.png'),
  marker_29: require('../../assets/markers/label_29_29.png'),
  marker_30: require('../../assets/markers/label_29_30.png'),
  marker_38: require('../../assets/markers/label_29_38.png'),
  marker_39: require('../../assets/markers/label_29_39.png'),
  marker_40: require('../../assets/markers/label_29_40.png'),
  marker_41: require('../../assets/markers/label_29_41.png'),
  marker_42: require('../../assets/markers/label_29_42.png'),
  marker_45: require('../../assets/markers/label_29_45.png'),
  marker_46: require('../../assets/markers/label_29_46.png'),
  marker_72: require('../../assets/markers/label_29_72.png'),
  marker_73: require('../../assets/markers/label_29_73.png'),
  marker_81: require('../../assets/markers/label_29_81.png'),
  marker_86: require('../../assets/markers/label_29_86.png'),
  marker_112: require('../../assets/markers/label_29_112.png'),
  marker_303: require('../../assets/markers/label_29_303.png'),
  marker_473: require('../../assets/markers/label_29_473.png'),
  marker_640: require('../../assets/markers/label_29_640.png'),
  marker_1200: require('../../assets/markers/label_29_1200.png'),
  marker_1203: require('../../assets/markers/label_29_1203.png'),
  marker_1696: require('../../assets/markers/label_29_1696.png'),
  marker_cluster: require('../../assets/markers/label_29_cluster.png'),
  marker_locate: require('../../assets/markers/label_29_locate.png'),
  marker_2494: require('../../assets/markers/label_29_2494.png'),//第一组
  marker_185: require('../../assets/markers/label_29_185.png'),//第二组
  marker_186: require('../../assets/markers/label_29_186.png'),//第三组
  marker_187: require('../../assets/markers/label_29_187.png'),//第四组
  marker_188: require('../../assets/markers/label_29_188.png'),//第五组
  marker_200: require('../../assets/markers/label_29_200.png'),//第六组
  marker_201: require('../../assets/markers/label_29_201.png'),//第七组
  marker_203: require('../../assets/markers/label_29_203.png'),//第八组
  marker_204: require('../../assets/markers/label_29_204.png'),//第九组
  marker_2492: require('../../assets/markers/label_29_2492.png'),//第十组
  marker_2510: require('../../assets/markers/label_29_2510.png'),//第十组
}

module.exports = {
  iconStore: icon,
  markerStore: marker
}
