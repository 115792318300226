<template>
  <div class="cl-body" v-title :data-title="title">
    <div class="cl-head">
      <div
        class="cl-head-left cl-head-item"
        :class="[is_show_list == 1 ? 'head_selected_bg': 'head_normal_bg'] "
        @click="is_show_list = 1"
      >
        <span>我要领劵</span>
      </div>
      <div
        class="cl-head-right cl-head-item"
        :class=" [is_show_list == 0 ? 'head_selected_bg' : 'head_normal_bg' ]"
        @click="is_show_list = 0"
      >
        <span>领劵记录</span>
      </div>
    </div>
    <div class="cl-list" v-if="is_show_list">
      <!--领劵列表页面-->
      <div class="cl-item" v-for="(item, i) in list" :key="i">
        <div
          class="cl-item-left"
          :class="[item.type_ == 'is_shop' ? 'shop_bg' : 'activity_bg']"
          @click="open_detail(item.id,item.type_)"
        >
          <div class="title">
            <span>{{item.title}}</span>
          </div>
          <div class="tip">
            <span>点击查看</span>
          </div>
          <div class="wave-left-decorate"></div>
        </div>

        <div class="cl-item-right">
          <div class="text_ content" @click="open_detail(item.id,item.type_)">
            <span>{{item.content}}</span>
          </div>
          <div class="text_ day" @click="open_detail(item.id,item.type_)">
            <span>{{item.day}}</span>
          </div>
          <div class="text_ tel">
            <span>
              <a :href="'tel:' + item.tel">{{item.tel_}}{{item.tel}}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="cl-list" v-else>
      <!--我的领取记录-->
      <div class="cl-item" v-for="(item, i) in my_list" :key="i">
        <div
          class="cl-item-left"
          :class="[item.type_ == 'is_shop' ? 'shop_bg' : 'activity_bg']"
          @click="open_detail(item.id,item.type_)"
        >
          <div class="title">
            <span>{{item.title}}</span>
          </div>
          <div class="tip">
            <span v-if="item.is_use == 1">已使用</span>
            <span v-else>未使用</span>
          </div>
          <div class="wave-left-decorate"></div>
        </div>

        <div class="cl-item-right">
          <div class="text__ content">
            <span>劵号：{{item.code}}</span>
          </div>
          <div class="text_ day">
            <span>领劵日期：{{item.btime}}</span>
          </div>
          <div class="text_ day">
            <span>截至日期：{{item.etime}}</span>
          </div>
        </div>
      </div>
    </div>
    <!--点击加载更多按钮-->
    <div class="click-load-more margin_bottom" @click="click_load_more">
      <div class="load-more-text">点击加载更多</div>
      <div class="load-more-img">
        <img src="../../assets/img/click-load-more.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      title: "美丽乡村",
      park_id: this.$route.query.park_id,
      list: "",
      my_list: "",
      list_page: 1,
      record_page: 1,
      is_show_list: 1,

      title: "领劵中心"
    };
  },
  mounted() {
    this.get_coupon_list();
  },
  methods: {
    get_coupon_list() {
      var user_id = this.check_login().user_id
        ? this.check_login().user_id
        : "";
      var data_ = {
        park_id: this.park_id,
        token: "zmlxj_2019_wuhanlvyouxiehui",
        page: this.list_page,
        user_id: "user_id",
        where: this.is_show_list
      };

      this.$ajax("post", "Other/get_coupon_list", data_, ret => {
        if (ret.data.id == 0) {
          this.list = ret.data.data.list;
          this.my_list = ret.data.data.record_list;
        } else {
          //没有数据
          Toast("没有数据");
        }
      });
    },
    //加载更多
    click_load_more() {
      var user_id = this.check_login().user_id
        ? this.check_login().user_id
        : "";
      var where = this.is_show_list;
      var page_ = 1;
      if (where) {
        page_ = ++this.list_page;
      } else {
        page_ = ++this.record_page;
      }
      var data_ = {
        park_id: this.park_id,
        token: "zmlxj_2019_wuhanlvyouxiehui",
        page: page_,
        user_id: user_id,
        where: where
      };

      this.$ajax("post", "Other/get_coupon_list", data_, ret => {
        if (ret.data.id == 0) {
          if (where) {
            if (ret.data.data.list) {
              this.list = this.list.concat(ret.data.data.list);
            } else {
              Toast("无更多数据");
            }
          } else {
            if (ret.data.data.record_list) {
              this.my_list = this.list.concat(ret.data.data.record_list);
            } else {
              Toast("无更多数据");
            }
          }
        } else {
          //没有数据
          Toast("没有数据");
        }
      });
    },
    //进入详情页面
    open_detail(id, type_, is_receive) {
      if (is_receive == 1) {
        //已经领取
        Toast("已经领取过了");
      } else {
        //没有领取
        this.$router.push({
          path: "/wuhanlvyouxiehui/wx_detail",
          query: { id: id, type_: type_, park_id: this.park_id }
        });
      }
    }
  }
};
</script>

<style scoped>
.cl-body {
  display: inline-block;
  position: relative;
  width: 100%;
}
.cl-body .cl-head {
  display: inline-block;
  position: relative;
  width: 100%;
}
.cl-body .cl-head .cl-head-left {
  border-right: 1px solid #fcfcfc;
  margin-left: 1%;
}
.head_selected_bg {
  background-color: #61dc9d;
  color: white;
}
.head_normal_bg {
  background-color: white;
}
.cl-body .cl-head .cl-head-item {
  position: relative;
  width: 49%;
  float: left;
}
.cl-body .cl-head .cl-head-item span {
  display: block;
  padding: 15px;
  font-size: 1rem;
  text-align: center;
}
.cl-body .cl-list {
  display: inline-block;
  position: relative;
  width: 90%;
  margin-left: 5%;
  margin-top: 10%;
}
.cl-body .cl-list .cl-item {
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: white;
  margin-bottom: 2%;
}
.shop_bg {
  background-color: #50aeee;
}

.activity_bg {
  background-color: #61dc9d;
}
.cl-body .cl-list .cl-item .cl-item-left {
  position: relative;
  width: 40%;
  float: left;
  overflow: hidden;
  height: 95px;
}
/* 波浪线 */
.cl-body .cl-list .cl-item .cl-item-left .wave-left-decorate {
  position: absolute;
  top: 90px;
  width: 95px;
  height: 8px;
  right: 0;
  transform-origin: right;
  transform: rotate(90deg);
  background: radial-gradient(
    circle,
    #fff 2px,
    #fff,
    transparent 3px,
    transparent 4px,
    transparent 4px,
    transparent
  );
  background-size: 8px 8px;
}
.cl-body .cl-list .cl-item .cl-item-left::before {
  content: "";
  position: absolute;
  z-index: 1000;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff;
  /*box-shadow: 4px 0 0 #ddd;*/
}

.cl-body .cl-list .cl-item .cl-item-left .title {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 6%;
}
.cl-body .cl-list .cl-item .cl-item-left .title span {
  display: block;
  padding: 2px;
  font-size: 0.8rem;
  color: white;
  text-align: center;
}
.cl-body .cl-list .cl-item .cl-item-left .tip {
  display: inline-block;
  position: relative;
  margin-left: 28%;
  margin-top: 10%;
}
.cl-body .cl-list .cl-item .cl-item-left .tip span {
  display: block;
  padding: 3px;
  font-size: 0.8rem;
  background-color: #ffa800;
  color: white;
  border-radius: 35px;
}
.cl-body .cl-list .cl-item .cl-item-right {
  position: relative;
  width: 60%;
  float: left;
}
.cl-body .cl-list .cl-item .cl-item-right .text_ {
  display: inline-block;
  position: relative;
  width: 100%;
}
.cl-body .cl-list .cl-item .cl-item-right .text_ span {
  color: #c2c2c2;
  font-size: 0.7rem;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.cl-body .cl-list .cl-item .cl-item-right .text__ {
  display: inline-block;
  position: relative;
  width: 100%;
}
.cl-body .cl-list .cl-item .cl-item-right .text__ span {
  color: #50aeee;
  font-size: 1rem;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.cl-body .cl-list .cl-item .cl-item-right .text_ span a {
  color: #c2c2c2;
}
</style>