<template>
  <div>
    <ServiceTitle></ServiceTitle>

    <div class="page-content">
      <div class="new-title-content">注意事项</div>
      <div class="withdraw-wrap">
        <div class="input-wrap clearfix" style="padding:0">
          <mt-field
            placeholder="请输入你的注意事项"
            v-model="tips"
            type="textarea"
            rows="3"
            :attr="{maxlength:398}"
          ></mt-field>
          <span class="foot-guide-tips fr" style="padding-right:10px;">(您可以输入{{wordNum}}个字)</span>
        </div>
      </div>
      <div class="tips-content">
        <p
          class="foot-guide-tips"
          v-for="(list, i) in lists"
          :key="i"
          style="margin-bottom:10px;"
        >{{list.content}}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.input-wrap textarea {
  font-size: 12px;
}
.tips-content {
  padding: 10px;
}
.foot-guide-tips {
  font-size: 10px;
  color: #cecece;
  margin-bottom: 10px;
}
</style>

<script>
import ServiceTitle from "../../components/ServiceTitle";

export default {
  name: "AttentionTips",
  components: {
    ServiceTitle
  },
  data() {
    return {
      wordNum: "398",
      tips: localStorage.notes,
      lists: [
        {
          content:
            "注意事项内容，不仅是您服务特色的表现，还是吸引出行客眼球的入口，给自己的服务取个有特色的标题吧"
        },
        {
          content: "范例："
        },
        {
          content: "1.最美旅行家热门景点一日游"
        },
        {
          content: "2.黄鹤楼、江滩、归元寺本地服务"
        },
        {
          content: "3.专职摄像机带您漫步江南水乡小镇，记录不一样的旅游"
        }
      ]
    };
  },
  watch: {
    tips: function(value) {
      var wlength = value.length;
      this.wordNum = 398 - wlength;
      this.save_arr("notes", value);
    }
  }
};
</script>