<template>
  <div
    class="LineInfoListtwo LineInfoList"
    :class="{active:container_big}"
    v-title
    :data-title="wx_title +'最美路书'"
  >
    <mt-header fixed :title="info.label_name">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
      <mt-button slot="right">
        <i class="iconfont icon-fenxiang" @click="sheetVisible = true"></i>
      </mt-button>
    </mt-header>
    <div class="page-content" style="padding-bottom: 0px;" v-if="info">
      <div class="container_box" :class=" container_big ? 'max_map_height' : 'min_map_height'">
        <div id="gd_map" style="width:100%; height:100%"></div>
        <ul class="map_info clearfix">
          <li>
            <p>总里程</p>
            <p>{{info.length}}</p>
          </li>
          <li>
            <p>最高海拔</p>
            <p>{{info.height}}</p>
          </li>
          <li>
            <p>最低海拔</p>
            <p>{{info.low}}</p>
          </li>
          <li>
            <p>徒步时间</p>
            <p>{{info.time_length}}</p>
          </li>
        </ul>
        <i
          class="container_quanping iconfont"
          :class="{'icon-quitquanping':container_big,'icon-quanping':!container_big}"
          @click="container_big=!container_big"
        ></i>
        <div class="left_but" v-if="container_big">
          <div class="mak_tab">
            <!--点/图片 切换-->
            <img
              src="../../assets/img/map_3d.png"
              alt
              class="map_3d"
              @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
              v-if="is_tab_map_3d"
            />
            <img
              src="../../assets/img/map_3d_yes.png"
              alt
              class="map_3d"
              @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
              v-if="!is_tab_map_3d"
            />
          </div>
          <div class="mak_tab">
            <!--地图 + -  -->
            <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
            <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
          </div>
        </div>
      </div>
      <div v-if="info.spots_list && !container_big">
        <line-info-content :datainfo="info.spots_list" :user_id="user_id"></line-info-content>
      </div>
      <mt-actionsheet :actions="actions" v-model="sheetVisible"></mt-actionsheet>
    </div>
  </div>
</template>
<script>
import { Toast } from 'mint-ui'
import LineInfoContent from '../../components/LineInfoContent'
export default {
  components: {
    LineInfoContent
  },
  data () {
    return {
      map: {},
      data: this.$route.query.data,
      label_id: this.$route.query.label_id,
      user_id: this.$route.query.user_id,
      info: {},
      sheetVisible: false,
      actions: [
        { name: '分享到微博', method: this.microblog },
        { name: '分享到QQ空间', method: this.shareToQq }
      ],
      picurl: '',
      Marker: [], // 所有点
      is_tab_map_3d: true, // 3D地图按钮是否点击
      container_big: !!this.$route.query.container_big, // 地图放大缩小
      wx_title: '',
      wx_content: '',
      wx_img: '',
      wx_url:
        'http://www.zmlxj.com/api.php/weixin/wx_share?type=LineInfoListtwo&label_id=' +
        this.$route.query.label_id,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      union_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
    }
  },
  mounted () {
    this.unload()
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.union_id &&
      !(
        !this.union_id &&
        typeof this.union_id !== 'undefined' &&
        this.union_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有union_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.union_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.union_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.union_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
      this.wx_share(window.location.href,'')
    }
  },
  methods: {
    unload () {
      if (!this.user_id) {
        if (this.check_login()) {
          this.user_id = this.check_login().user_id
        } else {
          //                        localStorage.setItem('login_url', location.hash);
          //                        this.$router.push({path: '/my/login', query: {log_type: 1}})
          //                        return;
        }
      }
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $('.mint-header.is-fixed').remove()
        $('.page-content').css('padding', '0')
      }

      if (this.data != undefined) {
        this.info = JSON.parse(this.data)
        // 初始化地图对象，加载地图
        this.map = this.gdMapInit({ map_: '', cluster_type: 0 })
        this.picurl = this.info.spots_list[0].newUrl
        if (this.info.spots_point_list.length > 0) {
          this.map_root_book_point(
            this.map,
            this.info.spots_point_list,
            data => {
              this.gdJump(data, this.label_id, this.token)
            }
          )
        }
      } else {
        this.$ajax(
          'post',
          'foot/get_one_user_collect_spots_info',
          {
            user_id: this.user_id,
            label_id: this.label_id
          },
          res => {
            if (res.data.id == 0) {
              this.info = res.data.data
              // console.log(this.info);
              // 初始化地图对象，加载地图
              this.map = this.gdMapInit()
              this.picurl = res.data.data.spots_list[0].newUrl
              if (res.data.data.spots_point_list.length > 0) {
                this.map_root_book_point(
                  this.map,
                  res.data.data.spots_point_list,
                  data => {
                    this.gdJump(data, this.label_id, this.token)
                  }
                )
              }
              // 微信数据
              localStorage.setItem('wx_title', res.data.data.label_name)
              localStorage.setItem('wx_content', res.data.data.wx_content)
              localStorage.setItem(
                'wx_img',
                res.data.data.spots_list[0].android_newUrl
              )
            }
          }
        )
      }
    },
    // 分享到新浪微博
    microblog () {
      var title = encodeURIComponent('最美旅行家路书分享')
      var link = encodeURIComponent(
        'https://m.zmlxj.com/footprints/LineInfoListtwo?user_id=' +
          this.user_id +
          '&label_id=' +
          this.label_id
      )
      var image = encodeURIComponent(this.picurl)
      shareToXl(title, link, image)
      // 内容，网址  图片
      function shareToXl (title, url, picurl) {
        var sharesinastring =
          'https://v.t.sina.com.cn/share/share.php?title=' +
          title +
          '&url=' +
          url +
          '&content=utf-8&sourceUrl=' +
          url +
          '&pic=' +
          picurl
        window.open(
          sharesinastring,
          'newwindow',
          'height=400,width=400,top=100,left=100'
        )
      }
    },
    // 分享到qq空间
    shareToQq () {
      var title = encodeURIComponent('最美旅行家路书分享')
      var link = encodeURIComponent(
        'https://m.zmlxj.com/footprints/LineInfoListtwo?user_id=' +
          this.user_id +
          '&label_id=' +
          this.label_id
      )
      var image = encodeURIComponent(this.picurl)
      qq_space(title, link, image)
      function qq_space (title, url, picurl) {
        var shareqqzonestring =
          'https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?summary=' +
          title +
          '&url=' +
          url +
          '&pics=' +
          picurl
        console.log(shareqqzonestring)
        window.open(
          shareqqzonestring,
          'newwindow',
          'height=400,width=400,top=100,left=100'
        )
      }
    }
  }
}
</script>

<style scoped>
.page-content {
  font-size: 0.7rem;
  padding-top: 2.4rem;
}
.container_box {
  position: relative;
 
}
.max_map_height{
  height:calc(100vh - 2.4rem);;
}
.min_map_height{
  height:12rem;
}
.container_box .map_info {
  text-align: center;
  width: 90%;
  background-color: rgba(255, 154, 128, 0.6);
  border-radius: 4px;
  padding: 4px 6px;
  color: #fff;
  position: absolute;
  top: 10px;
  left: 2.5%;
  right: 2.5%;
  z-index: 0;
  font-size: 0.7rem;
}

.container_box .map_info li {
  width: 25%;
  border-right: 1px dashed #fff;
  box-sizing: border-box;
  float: left;
}

.container_box .map_info li:last-child {
  border: none;
}
.container_box .container_quanping {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 1.6rem;
  background-color: rgba(255, 154, 128, 0.6);
  display: inline-block;
  color: #fff;
  text-align: center;
  height: 1.6rem;
  line-height: 1.4rem;
  border-radius: 4px;
  font-size: 1rem;
}

.left_but {
  position: absolute;
  left: 0.5rem;
  bottom: 0.6rem;
}

.left_but .mak_tab {
  background-color: #fff;
  width: 2rem;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px #ccc;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
}

.left_but .mak_tab:last-child {
  margin-bottom: 0;
}

.left_but .mak_tab img {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
}

.left_but .mak_tab img:nth-of-type(1) {
  border-bottom: 1px solid #ccc;
}

.left_but .mak_tab img:last-child,
.left_but .mak_tab.map_my_location img {
  border: none;
}
</style>
