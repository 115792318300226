<template>
  <div class="apply-view grayness" v-title data-title="预览申请表">
    <mt-header fixed title="预览申请表">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="info-box">
        <h1>
          <span>基本信息</span>
        </h1>
        <img id="u_avatar" :src="info.volunteer_img" />
        <dl class="col">
          <dt>姓名</dt>
          <dd id="name">{{info.volunteer_name}}</dd>
          <dt>性别</dt>
          <dd id="sex">{{info.volunteer_sex}}</dd>
          <dt>身份证号</dt>
          <dd id="id_card">{{info.volunteer_card}}</dd>
          <dt>学历</dt>
          <dd id="edu">{{info.volunteer_education}}</dd>
          <dt>QQ</dt>
          <dd id="qq">{{info.volunteer_qq}}</dd>
          <dt>微信号</dt>
          <dd id="weixin">{{info.volunteer_weixin}}</dd>
          <dt>工作单位</dt>
          <dd id="company">{{info.volunteer_work}}</dd>
          <dt>职业</dt>
          <dd id="job">{{info.volunteer_profession}}</dd>
          <dt>所学专业</dt>
          <dd id="pro">{{info.volunteer_major}}</dd>
          <dt>民族</dt>
          <dd id="nation">{{info.volunteer_nation}}</dd>
          <dt>国籍</dt>
          <dd id="nationality">{{info.volunteer_country}}</dd>
          <dt>地址</dt>
          <dd id="address">{{info.user_address}}</dd>
          <dt>省市</dt>
          <dd id="provinces">{{info.user_province+info.user_city}}</dd>
          <dt>邮箱</dt>
          <dd id="email">{{info.volunteer_email}}</dd>
          <dt>服装尺码</dt>
          <dd id="size">{{info.volunteer_clothes}}</dd>
          <dt>紧急联系人</dt>
          <dd id="e_contact">{{info.volunteer_nstancy_people}}</dd>
          <dt>联系人手机</dt>
          <dd id="e_contact_m">{{info.volunteer_nstancy_tel}}</dd>
        </dl>
        <dl>
          <dt>个人特长</dt>
          <dd id="speciality">{{info.specialties}}</dd>
          <dt>科普传播资源</dt>
          <dd id="resource">{{info.resource}}</dd>
          <dt>个人履历</dt>
          <dd id="resumes">{{info.resume}}</dd>
          <dt>作品</dt>
          <dd id="production">{{info.production}}</dd>
          <dt>你从何处得知此活动</dt>
          <dd id="how_know">{{info.come_where}}</dd>
        </dl>
      </div>
      <div class="info-box">
        <h1>
          <span>健康信息</span>
        </h1>
        <dl class="col">
          <dt>血型</dt>
          <dd id="blood_type">{{info.volunteer_bloody}}</dd>
          <dt>身高</dt>
          <dd id="height">{{info.volunteer_height}}</dd>
          <dt>体重</dt>
          <dd id="weight">{{info.volunteer_weight}}</dd>
          <dt>心率</dt>
          <dd id="heart_rate">{{info.volunteer_heart_rate}}</dd>
          <dt>血压</dt>
          <dd id="blood_pressure">{{info.volunteer_blood_pressure}}</dd>
          <dt>肝脏</dt>
          <dd id="liver">{{info.volunteer_liver}}</dd>
          <dt>病史</dt>
          <dd v-if="info.volunteer_history!=''">{{info.volunteer_history}}</dd>
          <dd v-if="info.volunteer_history==''">无</dd>
        </dl>
        <dl>
          <dd id="medical_history_detail"></dd>
        </dl>
      </div>
      <div class="info-box">
        <h1>
          <span>个人志愿</span>
        </h1>
        <dl class="col">
          <dt>意向公园</dt>
          <dd>{{info.park_name}}</dd>
        </dl>
        <dl>
          <dt>你为什么参与？</dt>
          <dd id="why_join">{{info.why}}</dd>
          <dt>科普工作计划</dt>
          <dd id="work_plan">{{info.todo}}</dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import touxiang from "../../assets/img/touxiang.png";
export default {
  data() {
    return {
      user_id: "",
      id: this.$route.query.id,
      info: {}
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      if (this.check_login()) {
        this.user_id = this.check_login().user_id;
      } else {
        this.user_id = this.get_User_Id();
      }
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      this.getData();
    },
    getData() {
      this.$ajax(
        "post",
        "Volunteer/info_view",
        { id: this.id, user_id: this.user_id },
        ret => {
          this.info = ret.data.data;
          if (this.info.volunteer_img == "") this.info.volunteer_img = touxiang;
        }
      );
    }
  }
};
</script>
<style scoped>
.apply-view {
  background-color: #fff;
}
.apply-view .info-box {
  border-bottom: 10px solid #f2f2f2;
  padding-bottom: 20px;
}
.apply-view .info-box:last-child {
  border-bottom: 0;
}

.apply-view .info-box #u_avatar {
  width: 4rem;
  height: 4rem;
  margin: 0.5rem auto;
  display: block;
}

.apply-view .info-box h1 {
  margin: 0.5rem 0 0.5rem;
  border-bottom: 0.2rem solid #ff9a80;
  font-size: 0;
}

.apply-view .info-box h1 span {
  color: #fff;
  background: #ff9a80;
  font-size: 1rem;
  font-weight: normal;
  padding: 0 0.2rem;
}

.apply-view .info-box dl {
  font-size: 0.8rem;
  padding: 0 0.7rem;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}

.apply-view .info-box dt {
  color: #999;
  margin: 0.2rem 0;
  height: 1rem;
}
.apply-view .info-box dd {
  color: #333;
  margin: 0.2rem 0;
  min-height: 1rem;
}
.apply-view .info-box .col dt {
  float: left;
  width: 40%;
}
.apply-view .info-box .col dd {
  float: left;
  width: 60%;
}
</style>