<template>
  <div>
    <!--头部信息-->
    <header class="header home fixed">
      <div class="LOGO_box">
        <img src="../../assets/img/map_ticket_selected.png" alt="搜索" class="LOGO" />
      </div>
      <router-link
        :to="{ path: '/SearchHome/SearchIndex/',query:{type_:4,token:'zmlxj_201808012',is_ticket:1}}"
        class="search_img"
      >
        <img src="../../assets/img/search-bg.png" alt />
      </router-link>
    </header>
    <!--内容中心-->
    <div class="page-content">
      <!--1票务的banner-->
      <div class="baner-ticket">
        <mt-swipe :auto="4000" class="slide" v-if="banner_ticket_list">
          <mt-swipe-item v-for="(item) in banner_ticket_list" :key="item.id">
            <router-link
              :to="{path:'/footprints/FootprintsParticulars',query:{id:item.id,label_id:item.label_id,price:item.price,del_price:item.del_price}}"
            >
              <img :src="item.baseUrl" />
            </router-link>
          </mt-swipe-item>
        </mt-swipe>
      </div>
      <!--2附近的票务-->
      <div class="near-ticket-list" v-if="near_ticket_list != '' ">
        <div class="near-head">
          <div class="near-title">
            <span>附近:</span>
          </div>
          <router-link
            :to="{path:'/Ticket/TicketList',query:{type_:'near_ticket',id:0,lng:lng,lat:lat,token:'zmlxj_2018'}}"
          >
            <div class="near-more">
              <i class="iconfont iconqianjin"></i>
            </div>
          </router-link>
        </div>
        <div class="near-ticket-item" v-for="(k,i) in near_ticket_list" :key="i">
          <router-link
            :to="{path:'footprints/FootprintsParticulars',query:{id:k.id,label_id:k.label_id,price:k.price,del_price:k.del_price,popular_type_fk_id:k.popular_type_fk_id,user_id:k.user_id}}"
          >
            <div class="item" :class="i % 4 >= 1 ? 'margin_left':''">
              <div class="img">
                <img :src="k.newUrl" />
              </div>
              <div class="title">
                <span>{{k.title}}</span>
              </div>
              <div class="foot">
                <div class="price">
                  <span>￥{{k.price}}</span>
                </div>
                <div class="del_price">
                  <span>{{k.del_price}}</span>
                </div>
                <div class="label_name">
                  <span>{{k.label_name}}</span>
                </div>
                <div class="shop_numbers">
                  <span v-if="k.price > 0 ">已售:{{k.shop_numbers}}件</span>
                  <span v-else>浏览:{{k.shop_numbers}}次</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <!--3热门票务-->
      <div class="hot-ticket" v-if="hot_ticket_list != '' ">
        <div class="head">
          <div class="text">
            <span>热门：</span>
          </div>
          <router-link
            :to="{path:'/Ticket/TicketList',query:{type_:'hot_ticket',id:0,lng:lng,lat:lat,token:'zmlxj_2018'}}"
          >
            <div class="img">
              <i class="iconfont iconqianjin"></i>
            </div>
          </router-link>
        </div>
        <div class="list">
          <div class="item" v-for="(k,i) in hot_ticket_list" :key="i">
            <router-link
              :to="{path:'footprints/FootprintsParticulars',query:{id:k.id,label_id:k.label_id,price:k.price,del_price:k.del_price,popular_type_fk_id:k.popular_type_fk_id,user_id:k.user_id}}"
            >
              <div class="left_">
                <div class="img">
                  <img :src="k.newUrl" />
                  <span>{{k.label_name}}</span>
                </div>
              </div>
              <div class="right_">
                <div class="title">
                  <span>{{k.title}}</span>
                </div>
                <div class="notes">
                  <span>{{k.notes}}</span>
                </div>
                <div class="footer">
                  <div class="price_">
                    <span>￥</span>
                    <span style="font-weight: bold;color: red;">{{k.price}}</span>
                    <span style="text-decoration: line-through;">{{k.del_price}}</span>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!--4景区公园和省份-->
      <div class="park-ticket-list" v-if="park_ticket_list != '' ">
        <div class="head">
          <div class="text">
            <span>公园景区:</span>
          </div>
          <router-link
            :to="{path:'/Ticket/TicketParkList',query:{type_:'park',id:0,lng:lng,lat:lat,'token':'zmlxj_2018'}}"
          >
            <div class="img">
              <i class="iconfont iconqianjin"></i>
            </div>
          </router-link>
        </div>
        <div class="list">
          <div class="item" v-for="(k,i) in park_ticket_list" :key="i">
            <router-link
              :to="{path:'/Ticket/TicketList',query:{type_:'park_ticket',id:k.id,token:'zmlxj_2018',title:k.park_name}}"
            >
              <div class="img" :class="i % 2 > 0 ? 'park_margin_left':''">
                <img :src="k.main_img" />
              </div>
            </router-link>

            <div class="name" :class="i % 2 > 0 ? 'park_margin_left':''">
              <span>{{k.park_name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--底部菜单-->
    <home-footer></home-footer>
  </div>
</template>
<!--脚本-->
<script>
import { Toast } from "mint-ui";
import HomeFooter from "@/components/HomeFooter";
//import MtHeader from "../../../../w_zmlxj_new/node_modules/mint-ui/packages/header/src/header.vue";
export default {
  components: {
    //MtHeader,
    HomeFooter
  },
  data() {
    return {
      map: [], //地图组件
      lng: "",
      lat: "",
      token: "zmlxj_2018",
      park_ticket_list: [],
      near_ticket_list: [],
      banner_ticket_list: [],
      hot_ticket_list: [],
      is_weixin: this.hybrid.hybrid.versions.is_weixin //判断是否是微信
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      this.map = this.map_init();
      var type = "";
      this.map_Geo_location({
        map: this.map,
        type: type,
        callback: function(lng, lat) {
          this.lng = lng;
          this.lat = lat;
          //console.log(lng+','+lat);
        }
      });
      /*1->请求banner数据*/
      this.get_data(this.token, this.lng, this.lat);
    },
    /*请求数据*/
    get_data(token, lng, lat) {
      this.$ajax(
        "post",
        "Ticket/get_ticket_list",
        { token: token, lng: lng, lat: lat },
        ret => {
          //console.log(ret);
          if (ret.data.id == 0) {
            this.banner_ticket_list = ret.data.data.banner_ticket_list;
            this.near_ticket_list = ret.data.data.near_ticket_list;
            this.hot_ticket_list = ret.data.data.hot_ticket_list;
            this.park_ticket_list = ret.data.data.park_list;
          } else {
            Toast({
              message: "无数据",
              iconClass: "iconfont icon-fail",
              duration: 2000
            });
          }
        }
      );
    }
  }
};
</script>
<!--附近-->
<style scoped>
.near-ticket-list {
  display: inline-block;
  width: 100%;
  background-color: #fffafa;
  margin-top: 4%;
}
.near-ticket-list .near-head {
  display: inline-block;
  width: 98%;
  margin-left: 1%;
  height: 10%;
  border-top: 1px solid white;
}
.near-ticket-list .near-head .near-title {
  width: 80%;
  float: left;
}
.near-ticket-list .near-head .near-title span {
  display: inline-block;
  padding: 10px;
  font-size: 0.8rem;
}
.near-ticket-list .near-head .near-more {
  width: 15%;
  float: right;
  margin-top: 2%;
}
.near-ticket-list .near-head .near-more img {
  font-size: 30px;
  float: right;
}

.near-ticket-list .near-ticket-item {
  position: relative;
  float: left;
  width: 24%;
}
.near-ticket-list .margin_left {
  margin-left: 6%;
}
.near-ticket-list .near-ticket-item .img {
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.near-ticket-list .near-ticket-item .img img {
  font-size: 40px;
  border-radius: 4px;
}
.near-ticket-list .near-ticket-item .title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.near-ticket-list .near-ticket-item .foot {
  display: inline-block;
  position: relative;
  width: 100%;
}
.near-ticket-list .near-ticket-item .foot .price {
  position: relative;
  float: left;
  width: 60%;
}
.near-ticket-list .near-ticket-item .foot .price span {
  display: block;
  padding: 4px;
  text-align: left;
  font-size: 0.8rem;
  color: red;
}
.near-ticket-list .near-ticket-item .foot .del_price {
  position: relative;
  float: left;
  width: 40%;
  margin-top: 1%;
}
.near-ticket-list .near-ticket-item .foot .del_price span {
  display: block;
  padding: 4px;
  font-size: 0.5rem;
  text-align: left;
  text-decoration: line-through;
  color: gray;
}
.near-ticket-list .near-ticket-item .foot .label_name {
  display: inline-block;
  position: relative;
  width: 100%;
}
.near-ticket-list .near-ticket-item .foot .label_name span {
  display: block;
  padding: 4px;
  text-align: center;
  border-radius: 5px;
  background-color: red;
  color: white;
}
.near-ticket-list .near-ticket-item .foot .shop_numbers {
  display: inline-block;
  position: relative;
  width: 100%;
}
.near-ticket-list .near-ticket-item .foot .shop_numbers span {
  display: block;
  padding: 4px;
  text-align: left;
  font-size: 0.5rem;
  width: 100%;
}

.hot-ticket {
  display: inline-block;
  position: relative;
  width: 98%;
  margin-left: 1%;
  margin-top: 18%;
  background-color: #fffafa;
  border-top: 4px solid white;
}
.hot-ticket .head {
  display: inline-block;
  position: relative;
  width: 100%;
}
.hot-ticket .head .text {
  position: relative;
  float: left;
  width: 80%;
}
.hot-ticket .head .text span {
  display: inline-block;
  padding: 10px;
  font-size: 0.8rem;
}
.hot-ticket .head .img {
  display: inline-block;
  position: relative;
  width: 18%;
}
.hot-ticket .head .img img {
  font-size: 30px;
  float: right;
}
.hot-ticket .list {
  display: inline-block;
}
.hot-ticket .list .item {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 2%;
}
.hot-ticket .list .item .left_ {
  position: relative;
  float: left;
  width: 40%;
}
.hot-ticket .list .item .left_ .img {
  position: relative;
  float: left;
}
.hot-ticket .list .item .left_ .img img {
  width: 100%;
  height: 108px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.hot-ticket .list .item .left_ span {
  display: block;
  position: absolute;
  margin-top: -18%;
  background-color: red;
  padding: 2px 1px 2px 1px;
  border-radius: 5px;
  width: 25%;
  text-align: center;
  color: white;
}
.hot-ticket .list .item .right_ {
  position: relative;
  float: left;
  width: 58%;
  margin-left: 1%;
}
.hot-ticket .list .item .right_ .title {
  display: inline-block;
  position: relative;

  font-weight: bold;
}
.hot-ticket .list .item .right_ .notes {
  display: block;
  position: relative;
}
.hot-ticket .list .item .right_ .footer {
  display: block;
  position: relative;
}
.hot-ticket .list .item .right_ .footer .price_ {
  display: block;
  position: relative;

  width: 100%;
}
.hot-ticket .list .item .right_ .footer .price_ span {
  display: inline-block;
  padding: 2px;
}

.park-ticket-list {
  display: inline-block;
  position: relative;
  width: 98%;
  margin-top: 2%;
  background-color: #fffafa;
}
.park-ticket-list .head {
  position: relative;
  float: left;
  width: 100%;
}
.park-ticket-list .head .text {
  position: relative;
  float: left;
  width: 60%;
}
.park-ticket-list .head .text span {
  display: block;
  padding: 10px;
  text-align: left;
}
.park-ticket-list .head .img {
  position: relative;
  float: left;
  width: 38%;
}
.park-ticket-list .head .img img {
  font-size: 30px;
  float: right;
}
.park-ticket-list .list {
  display: inline-block;
  position: relative;
}
.park-ticket-list .list .item {
  position: relative;
  float: left;
  width: 48%;
  margin-top: 2%;
}
.park-ticket-list .list .item .img {
  display: inline-block;
  position: relative;
  width: 100%;
}
.park-ticket-list .list .item .img img {
  width: 100%;
  height: 145px;
}
.park_margin_left {
  margin-left: 2%;
}
.park-ticket-list .list .item .name {
  display: block;
}
.park-ticket-list .list .item .name span {
  display: block;
  padding: 5px;
  background-color: red;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: white;
  text-align: center;
  overflow-x: scroll;
  white-space: nowrap;
}
</style>
