<template>
  <div class="container" v-title :data-title="title" v-show="loaded">
    <header class="header">
      <i class="back iconfont iconmenu2" @click="goBack"></i>
      <p class="title">活动报名</p>
    </header>

    <div class="title">
      <span class="text">{{title}}</span>
    </div>
    <div class="subtitle">
      <div class="tag">
        <div class="item price">
          <span class="text name">{{selectTitle}}￥</span>
          <!-- 
          -->
          <span class="text value">{{selectPrice}}</span>
        </div>
        <div class="item number">
          <span class="text name">数量</span>
          <span class="text value">{{number}}</span>
        </div>
      </div>
      <div class="time item">
        <span class="text name">活动时间：</span>
        <span class="text value">{{btime}} - {{etime}}</span>
      </div>
    </div>

    <div class="content">
      <!-- 姓名 -->
      <div class="item name">
        <span class="text label">姓名</span>
        <input
          :class="['inp', 'value', name ? 'active-value' : '']"
          type="text"
          v-model="name"
          placeholder="请输入您的真实姓名"
        />
      </div>

      <!-- 性别 -->
      <div class="item gender">
        <span class="text label">性别</span>
        <span
          :class="['value', gender ? 'active-value' : '']"
          @click="showGenderPicker=true"
        >{{ gender || '请选择您的性别'}}</span>
      </div>

      <!-- 生日 -->
      <div class="item borth">
        <span class="text label">生日</span>
        <span
          :class="['value', borth ? 'active-value' : '']"
          @click="showBorthPicker"
        >{{borth || '请选择您的出生日期'}}</span>
      </div>

      <!-- 职业 -->
      <div class="item profession">
        <span class="text label">职业</span>
        <span
          :class="['value', profession ? 'active-value' : '']"
          @click="showProfessionPicker = true"
        >{{profession || '请选择您的职业'}}</span>
      </div>
    </div>
    <!-- 支付 -->
    <div class="pay-info">
      <span class="text name">实付金额：</span>
      <span class="text union">￥</span>
      <span class="text value">{{selectPrice * number}}</span>
    </div>
    <div class="submit">
      <button
        :class="['btn', isPay == '1' ? 'btn-disabled' : '']"
        @click="handleSubmit"
      >{{isPay == '1' ? '已支付' : '去支付'}}</button>
    </div>
    <div class="order-id" v-if="orderId">
      <span class="text">订单编号：{{orderId}}</span>
    </div>

    <!-- picker -->
    <!-- picker遮罩 -->
    <div class="pick-mask" v-show="showGenderPicker" @click.self="showGenderPicker = false"></div>
    <div class="pick-mask" v-show="showProfessionPicker" @click.self="showProfessionPicker = false"></div>
    <!-- 性别 -->
    <transition name="slide">
      <div class="picker-box" v-show="showGenderPicker">
        <div class="picker-header">
          <button class="cancel" @click="showGenderPicker = false">取消</button>
          <button class="certain" @click="handleGenderChange">确定</button>
        </div>
        <mt-picker :slots="genderList" ref="genderPicker"></mt-picker>
      </div>
    </transition>
    <!-- 生日 -->
    <div class="picker-box">
      <mt-datetime-picker
        ref="borthPicker"
        type="date"
        :startDate="startDate"
        year-format="{value} 年"
        month-format="{value} 月"
        date-format="{value} 日"
        @confirm="handleBorthChange"
      ></mt-datetime-picker>
    </div>
    <!-- 职业 -->
    <transition name="slide">
      <div class="picker-box" v-show="showProfessionPicker">
        <div class="picker-header">
          <button class="cancel" @click="showProfessionPicker = false">取消</button>
          <button class="certain" @click="handleProfessionChange">确定</button>
        </div>
        <mt-picker :slots="professionList" ref="professionPicker"></mt-picker>
      </div>
    </transition>
  </div>
</template>

<script>
import { Toast, Picker, DatetimePicker, MessageBox } from "mint-ui";
export default {
  data() {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanActivity",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      aid: this.$route.query.aid || 0,
      tel: this.$route.query.tel || "",
      btime: this.$route.query.btime || "",
      etime: this.$route.query.etime || "",
      selectPrice: this.$route.query.selectPrice || 0,
      number: this.$route.query.number || 0,
      title: this.$route.query.title || "",
      selectTitle: this.$route.query.selectTitle || "",
      is_price: this.$route.query.is_price || "",

      // 数据
      isPay: "",
      orderId: "",
      // 自定义

      user_id: "",
      verify: "",
      loaded: false,
      startDate: new Date("1900-1-1"),

      // picker

      borth: "", // 出生日期
      name: "", // 姓名
      showGenderPicker: false,
      genderList: [
        {
          values: ["男", "女", "其他"],
          className: "item-gender",
          textAlign: "center"
        }
      ],
      gender: "",
      showProfessionPicker: false,
      professionList: [
        {
          values: [
            "学生",
            "教授",
            "IT工程师",
            "科普导讲员",
            "自驾游爱好者",
            "植物爱好者",
            "动物爱好者",
            "地质爱好者",
            "露营达人",
            "野外生存达人",
            "人文爱好者",
            "历史爱好者"
          ],
          className: "item-gender",
          textAlign: "center"
        }
      ], // 职业
      profession: "",
      isSubmit: false // 正在提交
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }

    this.checkLogin();
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
    }
    this.getData();
  },
  methods: {
    /**
     * 提交报名信息
     */
    handleSubmit() {
      if (this.isPay == "1") {
        Toast("已支付");
        return false;
      }
      if (this.isSubmit) {
        return false;
      }

      // 判断信息是否输入完整
      if (!this.name) {
        Toast("请输入姓名");
        return false;
      }
      if (!this.gender) {
        Toast("请选择性别");
        return false;
      }
      if (!this.borth) {
        Toast("请选择出生日期");
        return false;
      }
      if (!this.profession) {
        Toast("请选择职业");
        return false;
      }

      if (this.park_id && this.open_id && this.user_id && this.aid) {
        this.isSubmit = true;
        this.$ajax(
          "post",
          "index/weixin_join_activity",
          {
            user_id: this.user_id,
            aid: this.aid,
            volunteer_profession: this.profession,
            sex: this.gender,
            birthday: this.borth,
            name: this.name,
            park_id: this.park_id,
            open_id: this.open_id,
            is_price: this.is_price
          },
          res => {
            if (res.data.id == 0) {
              if (res.data.data.price > 0) {
                console.log(res);
                let open_url =
                  "https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=" +
                  this.aid +
                  "&spot_id=0&backUrl=label&open_id=" +
                  this.open_id +
                  "&token=" +
                  this.token +
                  "&park_id=" +
                  this.park_id +
                  "&version=" +
                  this.version +
                  "&user_id=" +
                  this.user_id +
                  "&money=" +
                  ret.data.data.price;
                window.location.href = open_url;
              }
            } else {
              Toast("报名失败");
            }
            this.isSubmit = false;
          }
        );
      }
    },
    // 职业
    handleProfessionChange() {
      this.profession = this.$refs.professionPicker.values[0];
      this.showProfessionPicker = false;
    },
    // 生日 确定
    handleBorthChange(value) {
      this.borth =
        value.getFullYear() +
        "-" +
        (value.getMonth() + 1) +
        "-" +
        value.getDate();
    },
    // 显示生日
    showBorthPicker() {
      this.$refs.borthPicker.open();
    },
    // 性别
    handleGenderChange() {
      this.gender = this.$refs.genderPicker.values[0];
      this.showGenderPicker = false;
    },
    // 上一级
    goBack() {
      this.$router.push({
        path: "/nihewan/home",
        query: {
          id: this.id
        }
      });
    },

    // 获取用户信息
    getData() {
      if (this.aid && this.user_id) {
        this.$ajax(
          "post",
          "activity/get_activity_user_info",
          {
            activity_id: this.aid,
            user_id: this.user_id
          },
          res => {
            if (res.data.id == 0) {
              this.isPay = res.data.data.is_pay || 0;
              this.name = res.data.data.name || "";
              this.orderId = res.data.data.order_id || "";

              const genderIndex = res.data.data.sex;
              this.gender = genderIndex
                ? this.genderList[0].values[genderIndex]
                : "";

              const sUrl =
                  "https://m.zmlxj.com/#/nihewan/activity?aid=" + this.aid,
                wx_title = this.title,
                wx_content = "",
                wx_img = "";
              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                });
              }
            }
            this.loaded = true;
          }
        );
      } else {
        console.log("参数错误");
      }
    },
    // 登录
    checkLogin() {
      if (!this.check_login()) {
        MessageBox.alert("请先登录").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
          }
        });
      } else {
        this.user_id = this.check_login().user_id;
        this.verify = this.check_login().user_id + new Date().getTime();
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&id_=" +
        this.aid +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    }
  }
};
</script>

<style scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  padding: 1rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  background-color: #fff;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 2.8rem;
  width: 100%;
  padding: 0.4rem 0.8rem 0.4rem 0.4rem;
  display: flex;
  box-sizing: border-box;
  box-shadow: 0 2px 2px -1px rgba(211, 211, 211, 0.4);
  background-color: #fff;
  z-index: 1;
}

.header > .back {
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}

.header > .title {
  flex: 1;
  padding-right: 2rem;
  box-sizing: border-box;
  text-align: center;
  font-size: 1rem;
  line-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.container > .title {
  font-size: 1rem;
  margin-top: 2.8rem;
  font-weight: bold;
}

.subtitle {
  margin-top: 0.5rem;
}

.subtitle > .tag > .item {
  display: inline-block;
  margin-right: 0.5rem;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.68rem;
  border: 1px solid rgba(230, 162, 60, 0.5);
  color: rgb(230, 162, 60);
}

.subtitle > .time {
  margin-top: 0.5rem;
  display: inline-block;
  margin-right: 1rem;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.68rem;
  border: 1px solid rgba(128, 190, 253, 0.5);
  color: #409eff;
}

.subtitle > .time > .value {
  font-family: "Times New Roman", Times, serif;
}

.content {
  margin-top: 1rem;
}

.content > .item {
  margin-top: 1rem;
}

.content > .item .label {
  position: relative;
  padding-left: 8px;
  font-size: 0.88rem;
}
.content > .item .label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 3px;
  height: 0.88rem;
  background-color: #e6a23c;
}

.content > .item .value {
  display: block;
  margin-top: 0.5rem;
  height: auto;
  min-height: 0;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 0.8rem;
  background-color: rgb(243, 245, 246);
  color: #999;
}

.content > .item .active-value {
  color: #333;
}

/* picker */
.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}
.slide-enter-to,
.slide-leave {
  transform: translateY(0);
}
.slide-enter-active,
.slide-leave-active {
  transition: 0.3s;
}

.pick-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.picker-box {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 11;
}

.picker-header {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(246, 248, 250);
}

.picker-header > .cancel {
  width: 100%;
  font-size: 16px;
  color: #26a2ff;
}

.picker-header > .certain {
  width: 100%;
  font-size: 16px;
  color: #26a2ff;
}

.picker-box >>> .picker {
  width: 100%;
}

/* 支付信息 */
.pay-info {
  margin-top: 1rem;
  text-align: right;
  font-size: 0.74rem;
  color: #666;
}

.pay-info > .union {
  color: #fd4545;
}

.pay-info > .value {
  color: #fd4545;
  font-size: 1.2rem;
  font-weight: bold;
}

/* 提交 */

.submit {
  margin-top: 1rem;
  width: 100%;
}

.submit .btn {
  width: 100%;
  min-height: 0;
  padding: 0.5rem 0;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: rgb(204, 158, 99);
}

.submit .btn-disabled {
  background-color: #888;
}

/* 订单号 */

.order-id {
  margin-top: 2rem;
  font-size: 0.68rem;
  color: #999;
}
</style>