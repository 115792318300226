<template>
  <div class="HomeSouPark" v-title data-title="公园搜索">
    <mt-header fixed title="公园搜索">
      <a slot="left">
        <mt-button @click.native="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="park_search">
      <div class="input_search clearfix sousuo_box">
        <input class="fl" type="text" placeholder="请输入关键字搜索" v-model="key_word" />
        <button class="fl" @click="sousuo">搜索</button>
      </div>
    </div>
    <div class="page-content">
      <home-park :is_tit="false" :is_hot="false" @ievent="ievent" ref="child"></home-park>
    </div>
  </div>
</template>
<script>
import HomePark from "../../components/HomePark";
export default {
  components: {
    HomePark
  },
  data() {
    return {
      park_list: [],
      key_word: "",
      page: 1
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      //                this.getData(this.key_word, 1)
    },
    ievent(...data) {
      this.page = data[0];
      this.getData(this.key_word, this.page);
    },
    //            获取数据
    getData(key_word, page) {
      this.$ajax(
        "post",
        "Park/clickMore_v2",
        { key_word: key_word, page: page },
        res => {
          if (res.data.id == 0) {
            this.park_list = this.park_list.concat(res.data.data);

            this.$refs.child.parentMsg({
              park_list: this.park_list,
              page: page
            });
          }
        }
      );
    },
    sousuo() {
      this.page = 1;
      this.park_list = [];
      this.getData(this.key_word, this.page);
    }
  }
};
</script>
<style scoped>
.HomeSouPark {
  background-color: #fff;
  min-height: 100%;
}

.HomeSouPark .page-content {
  padding: 4.5rem 0 0;
}

.HomeSouPark .park_search {
  position: fixed;
  top: 2rem;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
}

.HomeSouPark .park_search .sousuo_box {
  background-size: 1rem;
  background-position: 0.8rem 0.6rem;
}

.park_search .sousuo_box.input_search input {
  height: 1.4rem;
  line-height: 1.4rem;
  box-sizing: border-box;
}

.sousuo_box.input_search button {
  height: 1.4rem;
  line-height: 1.4rem;
  border: 1px solid #ff9a80;
}
</style>