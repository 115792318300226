<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <transition name="anim-opacity">
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </transition>

    <download v-if="is_app"></download>
  </div>
</template>

<script>
import download from './components/Download'
export default {
  name: 'app',
  components: {
    download
  },

  data () {
    return {
      is_app: false, // 设置首页下载按钮是否显示,
      isRouterAlive: true
    }
  },
  mounted () {
    this.unload()
  },
  methods: {
    unload () {
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        this.is_app = false
      }
    }
  }
}
</script>
<style>
html,
body,
#app {
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .width-768 {
    width: 768px !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
}

body {
  margin: 0;
  background: #f2f2f2;
  font-family: Helvetica Neue,Helvetica,PingFang SC,Tahoma,Arial,sans-serif;
}

a {
  text-decoration: none;
}

input {
  background: transparent;
  border: 0;
  padding: 0;
  outline: 0;
}

button {
  outline: none;
  border: none;
  background-color: transparent;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

img {
  display: inline-block;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

/*clearfix*/
.clearfix {
  clear: both;
}

.clearfix:before,
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  visibility: hidden;
  overflow: hidden;
}

/*flex css*/
.flex-box {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
}

.justify-flex {
  display: -webkit-box;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.flex-justify {
  justify-content: space-between;
  box-pack: justify;
}

.flex-justify-center {
  justify-content: center;
  box-pack: center;
}

.flex-center {
  align-items: center;
  box-align: center;
}

.flex-1 {
  flex: 1;
  box-flex: 1;
  -webkit-box-flex: 1;
}

.padding_4 {
  padding: 0 0.4rem;
}

.padding_t_4 {
  padding-top: 0.4rem;
}

.padding_n {
  padding: 0 !important;
}

#app .mint-header-title {
  font-weight: 700;
  font-size: 0.9rem;
}

.mint-indicator-text {
  font-size: 0.7rem;
}

.mint-msgbox-wrapper .mint-msgbox {
  width: 14rem;
}

.mint-msgbox-wrapper .mint-msgbox-message {
  color: #333;
}

.mint-msgbox-wrapper .mint-msgbox-title {
  line-height: 1.6rem;
  font-size: 0.8rem;
}

.mint-msgbox-wrapper .mint-msgbox-btns {
  margin-top: 0;
}

.mint-msgbox-wrapper .mint-msgbox-btns .mint-msgbox-cancel {
  background-color: #ccc;
  border: none;
  font-size: 0.7rem;
  color: #fff;
}

.mint-msgbox-wrapper .mint-msgbox-btns .mint-msgbox-confirm {
  color: #fff;
  background-color: #fc5227;
  font-size: 0.78rem;
}

.mint-toast.is-placemiddle .mint-toast-icon {
  font-size: 4rem;
  padding: 0 1.5rem;
}

.mint-toast.is-placemiddle .mint-toast-icon.icon-fail {
  color: #ff0f00;
}

.mint-toast.is-placemiddle .mint-toast-icon.icon-chenggong {
  color: #1aad19;
}

.mint-toast.is-placemiddle span {
  font-size: 0.8rem;
}

/* Indicator */
.mint-indicator-wrapper {
  z-index: 1001;
}

/* 公共动画样式 */
.anim-opacity-enter, .anim-opacity-leave-to {
  opacity: 0;
}
.anim-opacity-enter-to, .anim-opacity-leave {
  opacity: 1;
}
.anim-opacity-enter-active, .anim-opacity-leave-active {
  transition: .3s;
}

.slide-top-enter, .slide-top-leave-to {
  transform: translateY(100%);
}
.slide-top-enter-to, .slide-top-leave {
  transform: translateY(0);
}
.slide-top-enter-active, .slide-top-leave-active {
  transition: .3s;
}

.slide-right-enter, .slide-right-leave-to {
  transform: translateX(100%);
}
.slide-right-enter-to, .slide-right-leave {
  transform: translateX(0);
}
.slide-right-enter-active, .slide-right-leave-active {
  transition: .3s;
}
/* 公共样式 */
.img-box {
  overflow: hidden;
}

.img-box > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pointer {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.fixed {
  position: fixed;
}
</style>
