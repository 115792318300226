<template>
  <div class="container" v-cloak>
    <!-- 提示支付 -->
    <div class="act_pay" v-if="!is_pay">
      <div class="pay_content">
        <img src="../../assets/lottery/give_up.png" alt />
        <p>抱歉，您的余额不足...</p>
        <span @click="jumpToPay">去充值</span>
      </div>
    </div>
    <!-- 第一种 -->
    <div class="wrapper-1" v-if="sale_style == 0">
      <!-- 提示分享遮罩层 -->
      <div class="act_mask" v-show="showMask" @click="showMask = false">
        <div class="mask_title">
          <span style="color: rgb(255,196,4)">{{info.title}}</span>
        </div>
        <div></div>
        <div class="mask_content">
          <p>点击右上角，选择【分享到朋友圈】</p>

          <div>
            <img src="../../assets/img/send_to_circle.png" alt />
          </div>
        </div>
        <img class="mask_img" src="../../assets/switch/share_arrow.png" alt />
      </div>

      <!-- 顶部图片 -->
      <div class="act_header" ref="header">
        <!-- 活动时间提示 -->
        <div class="header_time" v-if="is_admin" @click="jumpToPay">
          <span>到期时间：</span>
          <span>{{etime ? etime : "已到期"}}</span>
          <span class="time_renew">续费</span>
        </div>
        <!-- 二维码icon -->
        <div class="header_qr_code" @click="show_qr_code = true" v-if="is_admin">
          <img src="../../assets/switch/qr_code.png" alt class="qr_code_icon" />
        </div>

        <!-- 轮播 -->
        <mt-swipe :auto="4000">
          <mt-swipe-item v-for="(item,i) in img_list" :key="i">
            <img :src="item.baseUrl" alt class="swipe_img" />
          </mt-swipe-item>
        </mt-swipe>
      </div>

      <!-- 刮奖区域 -->
      <div class="act_main">
        <div class="main_title">
          <h2 @click="jumpToDetail" v-if="info.title">
            <p class="main_title_value">{{info.title}}</p>
            <span class="main_title_btn">
              详情
              <i class="iconfont iconqianjin"></i>
            </span>
          </h2>
          <div class="main_open_time" v-if="open_time">
            <span class="time_title">营业时间</span>
            <span class="time_item" v-for="(item, i) in open_time" :key="i">{{item.label_name}}</span>
          </div>
          <div class="main_address" v-if="info.address">
            <span class="address_name">地址</span>
            <span class="address_value">{{info.address}}</span>
          </div>
          <div class="main_shop_tag" v-if="shop_label_list">
            <div class="tag_wrapper">
              <span
                class="tag_name"
                v-for="(item,i) in shop_label_list"
                :key="i"
              >{{item.label_name}}</span>
            </div>
          </div>
          <div class="main_shop_tag main_food_tag" v-if="food_label_list">
            <div class="tag_wrapper">
              <span
                class="tag_name"
                v-for="(item,i) in food_label_list"
                :key="i"
              >{{item.label_name}}</span>
            </div>
          </div>
          <div class="main_shop_tag main_play_tag" v-if="play_label_list">
            <div class="tag_wrapper">
              <span
                class="tag_name"
                v-for="(item,i) in play_label_list"
                :key="i"
              >{{item.label_name}}</span>
            </div>
          </div>
          <div class="main_shop_tag main_sleep_tag" v-if="sleep_label_list">
            <div class="tag_wrapper">
              <span
                class="tag_name"
                v-for="(item,i) in sleep_label_list"
                :key="i"
              >{{item.label_name}}</span>
            </div>
          </div>
        </div>

        <div class="main_wrapper" @touchstart="showFinger = false">
          <div class="main_btn" v-show="!showScratch" @click="showMask = true">
            <span>领取折扣</span>
          </div>
          <div class="main_finger" v-show="showFinger">
            <img src="../../assets/switch/finger.png" alt />
          </div>
          <div class="main_content">
            <div id="scratch">
              <div id="card" class="align_items">
                <!-- 未中奖 -->
                <div class="empty" v-if="info.saleText=='0'">
                  <img src="../../assets/lottery/give_up.png" alt />
                  <p class="title">很遗憾，您没有抽中奖品!</p>
                </div>
                <!-- 折扣券 -->
                <div class="sale" v-else-if="info.saleText!='0' && info.sale_newUrl==''">
                  <p class="title">
                    恭喜您获得折扣券
                    <span class="value">{{info.saleText}}</span>
                    折
                  </p>
                  <p class="tip">快去使用吧~~</p>
                  <p class="code">
                    折扣券编号:
                    <span class="value">{{info.sale_code}}</span>
                  </p>
                  <p class="date">使用有效期：{{info.sale_today}}</p>
                </div>
                <!-- 实物奖品 -->
                <div class="prize" v-else>
                  <p class="title">
                    恭喜您获得
                    <span class="value">{{info.saleText}}</span>
                  </p>
                  <img :src="info.sale_newUrl" class="preview" />
                  <p class="code">
                    奖品编号:
                    <span class="value">{{info.sale_code}}</span>
                  </p>
                  <p class="date">使用有效期：{{info.sale_today}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="act_rule">活动规则：中奖后向商家展示折扣券编码即可使用。折扣券只限店内消费使用，当天有效，过期作废。</p>
      </div>
      <!-- 底部奖品信息 -->
      <div class="act_footer">
        <div class="footer_title">
          <span class="title_line"></span>
          <span class="title_text">折扣展示</span>
        </div>
        <div class="footer_content" v-if="sale_list">
          <div class="footer_content_wrapper">
            <div
              class="footer_content_item"
              v-for="(item, i) in sale_list"
              :key="i"
              :style="'background-image:url(' + sale_bg_arr[i] + ')'"
            >
              <div class="item-text" v-if="!item.newUrl">
                <span class="item_num">{{item.label_name}}</span>
                <span class="item_unit">折</span>
              </div>
              <div class="item-img" v-else>
                <img class="img" :src="item.newUrl" alt />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="footer_bottom">
        <span>武汉地普游科技提供技术支持</span>
        </div>-->
        <!-- 二维码 -->
        <transition name="qr_code">
          <div
            class="act_qr_code"
            v-if="is_admin"
            v-show="show_qr_code"
            @click.self="show_qr_code = false"
          >
            <img :src="qr_sale" alt class="qr_code_img" />
          </div>
        </transition>
        <!-- 联系我们 -->
        <technical-support ts_type_="sale" contact_side="text" :id="id"></technical-support>
      </div>
      <!-- 管理员发布折扣 -->
      <div class="act_issue" v-if="is_admin">
        <span class="issue_btn" :class="showPublish ? 'issue_btn_lean' : ''">
          <img src="../../assets/switch/books_issue.png" alt @click="showPublish = !showPublish" />
        </span>
        <span
          @click="jumpToPublish"
          class="to_publish"
          :class="showPublish ? 'show_publish' : 'hide_publish'"
        >发布</span>
      </div>
    </div>
    <!-- 第二种 -->
    <div class="wrapper-2" v-else-if="sale_style == 1">
      <!-- 背景图片 -->

      <img class="bg" :src="style_1_bg" v-if="style_1_bg" />
      <img class="bg" src="../../assets/sale/sale_bg.jpg" v-else />
      <!-- <img class="bg" src="../../assets/sale/honglu1029.jpg"> -->
      <!-- 提示分享遮罩层 -->
      <div class="act_mask" v-if="is_show_share" v-show="showMask" @click="showMask = false">
        <div class="mask_title">
          <span style="color: rgb(255,196,4)">{{info.title}}</span>
        </div>
        <div></div>
        <div class="mask_content">
          <p>点击右上角，选择【分享到朋友圈】</p>

          <div>
            <img src="../../assets/img/send_to_circle.png" alt />
          </div>
        </div>
        <img class="mask_img" src="../../assets/switch/share_arrow.png" alt />
      </div>
      <!-- 活动时间提示 -->
      <div class="header_time" v-if="is_admin" @click="jumpToPay">
        <span>到期时间：</span>
        <span>{{etime ? etime : "已到期"}}</span>
        <span class="time_renew">续费</span>
      </div>
      <!-- 二维码icon -->
      <div class="header_qr_code" @click="show_qr_code = true" v-if="is_admin">
        <img src="../../assets/switch/qr_code.png" alt class="qr_code_icon" />
      </div>
      <!-- 刮奖区 -->
      <div class="main_box">
        <div class="main_wrapper" @touchstart="showFinger = false">
          <div class="main_btn" v-show="!showScratch" @click="handleScratchClick">
            <span>领取折扣</span>
          </div>
          <div class="main_finger" v-show="showFinger">
            <!-- <img src="../../assets/sale/sale_hand.png" alt /> -->
          </div>
          <div class="main_content">
            <div id="scratch">
              <div id="card"  class="align_items">
                <!-- 未中奖 -->
                <div class="empty" v-if="info.saleText=='0'">
                  <img src="../../assets/lottery/give_up.png" alt />
                  <p class="title">很遗憾，您没有抽中奖品!</p>
                </div>
                <!-- 折扣券 -->
                <div class="sale" v-else-if="info.saleText!='0' && info.sale_newUrl==''">
                  <p class="title">
                    恭喜您获得折扣券
                    <span class="value">{{info.saleText}}</span>
                    折
                  </p>
                  <p class="tip">快去使用吧~~</p>
                  <p class="code">
                    折扣券编号:
                    <span class="value">{{info.sale_code}}</span>
                  </p>
                  <p class="date">使用有效期：{{info.sale_today}}</p>
                </div>
                <!-- 实物奖品 -->
                <div class="prize" v-else>
                  <p class="title">
                    恭喜您获得
                    <span class="value">{{info.saleText}}</span>
                  </p>
                  <img :src="info.sale_newUrl" class="preview" />
                  <p class="code">
                    奖品编号:
                    <span class="value">{{info.sale_code}}</span>
                  </p>
                  <p class="date">使用有效期：{{info.sale_today}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 底部奖品信息 -->
      <div class="act_footer">
        <div class="footer_stick">
          <img src="../../assets/sale/sale_stick.png" alt />
        </div>
        <div class="footer_content" v-if="sale_list">
          <div class="footer_content_wrapper">
            <div
              class="footer_content_item"
              v-for="(item, i) in sale_list"
              :key="i"
              :style="knotStyle()"
            >
              <div class="item-text" v-if="!item.newUrl">
                <span class="item_num">{{item.label_name}}</span>
                <span class="item_unit">折</span>
              </div>
              <div class="item-img" v-else>
                <img class="img" :src="item.newUrl" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 规则 -->
      <div class="rule" @click="show_rule = !show_rule"></div>
      <transition name="rule">
        <div class="rule_wrapper" v-show="show_rule" @click.self="show_rule = false">
          <div class="rule_container">
            <div class="rule_close" @click="show_rule = false">
              <i class="iconfont iconbaseline-close-px"></i>
            </div>
            <h1 class="rule_title">活动规则</h1>
            <div class="rule_content">
              <h2>活动说明</h2>
              <p>参与活动即有机会获得抽取折扣券,折扣券不累加。之前获取的折扣券可前往商户详情中查看。</p>
              <p>折扣展示区中的所有折扣券均有一定概率获取。</p>
              <h2>使用说明</h2>
              <p>1.中奖后向商家展示折扣券编码即可使用。</p>
              <p>2.折扣券只限店内消费使用，当天有效，过期作废。</p>
            </div>
          </div>
        </div>
      </transition>
      <!-- 管理员发布折扣 -->
      <div class="act_issue" v-if="is_admin">
        <span class="issue_btn" :class="showPublish ? 'issue_btn_lean' : ''">
          <img src="../../assets/switch/books_issue.png" alt @click="showPublish = !showPublish" />
        </span>
        <span
          @click="jumpToPublish"
          class="to_publish"
          :class="showPublish ? 'show_publish' : 'hide_publish'"
        >发布</span>
      </div>
      <!-- 二维码 -->
      <transition name="qr_code">
        <div
          class="act_qr_code"
          v-if="is_admin"
          v-show="show_qr_code"
          @click.self="show_qr_code = false"
        >
          <img :src="qr_sale" alt class="qr_code_img" />
        </div>
      </transition>
      <!-- 联系我们 -->
      <technical-support ts_type_="sale" contact_side="top" :id="id"></technical-support>
    </div>
    <!-- 第三种 -->
    <div class="wrapper-3" v-else-if="sale_style == 2">
      <!-- 背景图片 -->
      <img class="bg" :src="style_1_bg" v-if="style_1_bg" />
      <img class="bg" src="../../assets/sale/sale_bg.jpg" v-else />
      <!-- <img class="bg" src="../../assets/sale/honglu1029.jpg"> -->
      <!-- 提示分享遮罩层 -->
      <div class="act_mask" v-if="is_show_share" v-show="showMask" @click="showMask = false">
        <div class="mask_title">
          <span style="color: rgb(255,196,4)">{{info.title}}</span>
        </div>
        <div></div>
        <div class="mask_content">
          <p>点击右上角，选择【分享到朋友圈】</p>

          <div>
            <img src="../../assets/img/send_to_circle.png" alt />
          </div>
        </div>
        <img class="mask_img" src="../../assets/switch/share_arrow.png" alt />
      </div>
      <!-- 活动时间提示 -->
      <div class="header_time" v-if="is_admin" @click="jumpToPay">
        <span>到期时间：</span>
        <span>{{etime ? etime : "已到期"}}</span>
        <span class="time_renew">续费</span>
      </div>
      <!-- 二维码icon -->
      <div class="header_qr_code" @click="show_qr_code = true" v-if="is_admin && qr_sale">
        <img src="../../assets/switch/qr_code.png" alt class="qr_code_icon" />
      </div>
      <!-- 刮奖区 -->
      <div class="main_box">
        <div class="main_wrapper" @touchstart="showFinger = false">
          <div class="main_btn" v-show="!showScratch" @click="handleScratchClick">
            <span>领取折扣</span>
          </div>
          <div class="main_finger" v-show="showFinger"></div>
          <div class="main_content">
            <div id="scratch">
              <div id="card"  class="align_items">
                <!-- 未中奖 -->
                <div class="empty" v-if="info.saleText=='0'">
                  <img src="../../assets/lottery/give_up.png" alt />
                  <p class="title">很遗憾，您没有抽中奖品!</p>
                </div>
                <!-- 折扣券 -->
                <div class="sale" v-else-if="info.saleText!='0' && info.sale_newUrl==''">
                  <p class="title">
                    恭喜您获得折扣券
                    <span class="value">{{info.saleText}}</span>
                    折
                  </p>
                  <p class="tip">快去使用吧~~</p>
                  <p class="code">
                    折扣券编号:
                    <span class="value">{{info.sale_code}}</span>
                  </p>
                  <p class="date">使用有效期：{{info.sale_today}}</p>
                </div>
                <!-- 实物奖品 -->
                <div class="prize" v-else>
                  <p class="title">
                    恭喜您获得
                    <span class="value">{{info.saleText}}</span>
                  </p>
                  <img :src="info.sale_newUrl" class="preview" />
                  <p class="code">
                    奖品编号:
                    <span class="value">{{info.sale_code}}</span>
                  </p>
                  <p class="date">使用有效期：{{info.sale_today}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 底部奖品信息 -->
      <div class="footer">
        <div class="footer-title">奖品展示</div>
        <div class="content" v-if="sale_list">
          <div class="sale-list">
            <div class="sale-item" v-for="(item, i) in sale_list" :key="i">
              <div class="item sale" v-if="!item.newUrl">
                <div class="value">
                  <span>{{item.label_name}}</span>
                  <span>折</span>
                </div>
                <span class="name">折扣券</span>
              </div>
              <div class="item prize" v-else>
                <div class="value">
                  <img :src="item.newUrl" alt />
                </div>
                <span class="name">{{item.label_name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 规则 -->
      <div class="rule" @click="show_rule = !show_rule"></div>
      <transition name="rule">
        <div class="rule_wrapper" v-show="show_rule" @click.self="show_rule = false">
          <div class="rule_container">
            <div class="rule_close" @click="show_rule = false">
              <i class="iconfont iconbaseline-close-px"></i>
            </div>
            <h1 class="rule_title">活动规则</h1>
            <div class="rule_content">
              <h2>活动说明</h2>
              <p>参与活动即有机会获得抽取折扣券,折扣券不累加。之前获取的折扣券可前往商户详情中查看。</p>
              <p>折扣展示区中的所有折扣券均有一定概率获取。</p>
              <h2>使用说明</h2>
              <p>1.中奖后向商家展示折扣券编码即可使用。</p>
              <p>2.折扣券只限店内消费使用，当天有效，过期作废。</p>
            </div>
          </div>
        </div>
      </transition>
      <!-- 管理员发布折扣 -->
      <div class="act_issue" v-if="is_admin">
        <span class="issue_btn" :class="showPublish ? 'issue_btn_lean' : ''">
          <img src="../../assets/switch/books_issue.png" alt @click="showPublish = !showPublish" />
        </span>
        <span
          @click="jumpToPublish"
          class="to_publish"
          :class="showPublish ? 'show_publish' : 'hide_publish'"
        >发布</span>
      </div>
      <!-- 二维码 -->
      <transition name="qr_code">
        <div
          class="act_qr_code"
          v-if="is_admin"
          v-show="show_qr_code"
          @click.self="show_qr_code = false"
        >
          <img :src="qr_sale" alt class="qr_code_img" />
        </div>
      </transition>
      <!-- 联系我们 -->
      <technical-support ts_type_="sale" contact_side="top" :id="id"></technical-support>
    </div>
    <!--第四种情况-->
    <div class="wrapper-4" v-else-if="sale_style == 3">
      <img class="sale-location" src="../../assets/sale/sale-location.png" @click="open_map_nav">
      <!-- 背景图片 -->
      <img class="bg" :src="style_1_bg" v-if="style_1_bg" />
      
       <img class="sale-nav" src="../../assets/sale/sale-nav.png" @click="open_map_nav"> 
      <!-- 提示分享遮罩层 -->
      <div class="act_mask" v-if="is_show_share" v-show="showMask" @click="showMask = false">
        <div class="mask_title">
          <span style="color: rgb(255,196,4)">{{info.title}}</span>
        </div>
        <div></div>
        <div class="mask_content">
          <p>点击右上角，选择【分享到朋友圈】</p>

          <div>
            <img src="../../assets/img/send_to_circle.png" alt />
          </div>
        </div>
        <img class="mask_img" src="../../assets/switch/share_arrow.png" alt />
      </div>

      <!-- 刮奖区 -->
      <div class="main_box">
        <div class="main_wrapper" @touchstart="showFinger = false">
          <div class="main_btn" v-show="!showScratch" @click="handleScratchClick">
            <span>领取折扣</span>
          </div>
          <div class="main_finger" v-show="showFinger"></div>
          <div class="main_content">
            <div id="scratch">
              <div id="card" class="card4">
                <!-- 未中奖 -->
                <div class="empty" v-if="info.saleText=='0'">
                  <img src="../../assets/lottery/give_up.png" alt />
                  <p class="title">很遗憾，您没有抽中奖品!</p>
                </div>
                <!-- 折扣券 -->
                <div class="sale4" v-else-if="info.saleText!='0' && info.sale_newUrl==''">
                  <p class="title">
                    恭喜您抽中
                    <span class="value">{{info.saleText}}</span>
                    折
                  </p>
                 
                  <p class="code">
                    折扣券编号:
                    <span class="value">{{info.sale_code}}</span>
                  </p>
                  <p class="date">使用有效期：{{info.sale_today}}</p>
                  
                </div>
                
                <!-- 实物奖品 -->
                <div class="prize" v-else>
                  <p class="title">
                    恭喜您获得
                    <span class="value">{{info.saleText}}</span>
                  </p>
                  <!--暂时不显示实物礼品的图片-->
                  <!-- <img :src="info.sale_newUrl" class="preview" /> -->
                  <p class="code">
                    奖品编号:
                    <span class="value">{{info.sale_code}}</span>
                  </p>
                  <p class="date">使用有效期：{{info.sale_today}}</p>
                </div>
                
                <div class="checkUrl" @click="show_qr_code=true">
                  <img :src="checkUrl" v-if="checkUrl">
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <!-- 管理员发布折扣 -->
      <div class="act_issue" v-if="is_admin">
        <span class="issue_btn" :class="showPublish ? 'issue_btn_lean' : ''">
          <img src="../../assets/switch/books_issue.png" alt @click="showPublish = !showPublish" />
        </span>
        <span
          @click="jumpToPublish"
          class="to_publish"
          :class="showPublish ? 'show_publish' : 'hide_publish'"
        >发布</span>
      </div>
      <!-- 二维码 -->
      <transition name="qr_code">
        <div
          class="act_qr_code"
          v-if="is_admin"
          v-show="show_qr_code"
          @click.self="show_qr_code = false"
        >
          <img :src="checkUrl" alt class="qr_code_img" />
        </div>
      </transition>
      <!-- 联系我们 -->
      <technical-support ts_type_="sale" contact_side="top" :id="id"></technical-support>

    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Toast } from 'mint-ui'
import TechnicalSupport from '../../components/TechnicalSupport'
var wx = require('weixin-js-sdk')
export default {
  components: {
    'technical-support': TechnicalSupport
  },
  data () {
    return {
      // 授权信息
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      park_id: 298,
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin, // -------------注意
      id: this.$route.query.id ? this.$route.query.id : 0,
      id_type_: this.$route.query.id_type_
        ? this.$route.query.id_type_
        : 'sale',
      user_id: '',
      // 获取数据
      info: {},
      img_list: [], // 顶部图片
      sale_list: [], // 折扣信息

      showScratch: false, // 允许刮刮
      showMask: false, // 显示分享提示
      showFinger: false, // 显示手指动作

      sUrl: '', // 分享的地址

      have_chance: 0,
      have_chance_id: '',

      sale_bg_arr: [], // 折扣背景图数组
      is_admin: 0, // 管理员
      showPublish: false, // 显示发布
      open_time: '', // 营业时间
      shop_label_list: '', //
      food_label_list: '', // 美食
      play_label_list: '', // 游戏
      sleep_label_list: '', // 住宿
      saleId: '', // 用户获取的折扣id
      qr_sale: '', // 二维码
      show_qr_code: false, // 显示二维码
      is_pay: true, // 充值
      btime: '', // 开始日期
      etime: '', // 结束日期

      sale_style: -1,
      show_rule: false, // 显示规则
      is_show_share: 0, // 显示提示分享
      style_1_bg: '',

      sale_icon_0: require('../../assets/sale/sale_icon_0.png'),
      sale_icon_1: require('../../assets/sale/sale_icon_1.png'),
      sale_icon_2: require('../../assets/sale/sale_icon_2.png'),
      sale_icon_3: require('../../assets/sale/sale_icon_3.png'),
      sale_icon_4: require('../../assets/sale/sale_icon_4.png'),
      sale_icon_5: require('../../assets/sale/sale_icon_5.png'),
      sale_icon_6: require('../../assets/sale/sale_icon_6.png'),
      sale_icon_7: require('../../assets/sale/sale_icon_7.png'),
      sale_icon_8: require('../../assets/sale/sale_icon_8.png'),
      sale_icon_9: require('../../assets/sale/sale_icon_9.png'),

      checkUrl:'',//核销生成的二维码
      lng:'',
      lat:'',
      address:'',
    }
  },
  created () {},
  updated () {},
  mounted () {
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有union_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }

    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
      if (this.open_id) {
      } else {
        this.authorize()
      }
    }

    this.getData() //
  },
  methods: {
    // 点击领取折扣
    handleScratchClick () {
      if (this.is_show_share) {
        this.showMask = true
      } else {
        Toast('请查看活动规则')
      }
    },
    // 去充值
    jumpToPay () {
      if (this.is_weixin) {
        this.$router.push({
          path: '/shop/payforsale',
          query: {
            id: this.id
          }
        })
      } else {
        Toast('请在微信中打开')
      }
    },
    // 去发布
    jumpToPublish () {
      if (this.is_weixin) {
        this.$router.push({
          path: '/publish/PublishSale',
          query: {
            id_: this.id
          }
        })
      } else {
        Toast('请在微信中打开')
      }
    },
    // 初始化折扣券背景
    init_sale_bg () {
      for (let i = 0; i < this.sale_list.length; i++) {
        let icon = this['sale_icon_' + (i % 10)]
        this.sale_bg_arr.push(icon)
      }
    },
    // 跳转商户详情
    jumpToDetail () {
      if (!this.showScratch) {
        Toast('领取折扣后可查看详情')
        return
      }
      this.$router.push({
        path: '/footprints/PrimaryScenic',
        query: {
          sid: this.id + '_0_' + this.saleId
        }
      })
    },
    // 样式2的中国结
    knotStyle () {
      let win_height = window.innerHeight,
        win_width = window.innerWidth
      let ratio = 230 / 120
      let knot_width = win_width / 5,
        knot_height = knot_width * ratio
      return {
        width: knot_width + 'px',
        height: knot_height + 'px'
      }
    },
    // 获取数据
    getData () {
      if (this.token && this.open_id && this.id) {
        this.$ajax(
          'post',
          'Shop/get_whzyn_spots_sale_data',
          {
            token: this.token,
            id: this.id,
            open_id: this.open_id,
            user_id: this.user_id
          },
          res => {
            if (res.data.id == 0) {
              this.info = res.data.data.data // 所有数据
              console.log(this.info)
              this.sale_style = this.info.sale_style ? this.info.sale_style : 0 // 用哪种样式

              // 测试
              // ====================================================================
              // this.sale_style = 2;
              // ====================================================================
              this.img_list = this.info.img_list ? this.info.img_list : '' // 图片数组
              this.sale_list = this.info.sale_list ? this.info.sale_list : '' // 折扣数组
              this.is_show_share = this.info.is_show_share || 0
              this.style_1_bg = this.info.bg_ || ''
              if (this.sale_list) {
                this.init_sale_bg() // 折扣背景图
              }
              this.is_admin = this.info.is_admin // 管理员标识
              this.saleId = this.info.saleId // 折扣id
              document.title = this.info.title // 标题
              this.qr_sale = this.info.qr_sale ? this.info.qr_sale : ''
              this.is_pay = this.info.is_pay // 是否支付
              this.btime = this.info.btime // 开始时间
              let etime = this.info.etime // 结束时间
              if (etime > 0) {
                this.etime =
                  etime.substring(0, 4) +
                  '-' +
                  etime.substring(4, 6) +
                  '-' +
                  etime.substring(6)
              } else {
                this.etime = ''
              }
              this.checkUrl = this.info.checkUrl || '';
              this.lng = this.info.spot_lng || '';
              this.lat = this.info.spot_lat || '';
              this.address = this.info.address || "";

              this.open_time = this.info.open_time ? this.info.open_time : '' // 营业时间
              this.shop_label_list = this.info.shop_label_list
                ? this.info.shop_label_list
                : '' // 美食
              this.food_label_list = this.info.food_label_list
                ? this.info.food_label_list
                : '' // 美食
              this.play_label_list = this.info.play_label_list
                ? this.info.play_label_list
                : '' // 游戏
              this.sleep_label_list = this.info.sleep_label_list
                ? this.info.sleep_label_list
                : '' // 住宿
              // 折扣id
              // this.initSUrl();
              this.sUrl = this.info.shareUrl
              let wx_img = this.info.img_list
                ? this.info.img_list[0].baseUrl
                : ''
              if (this.is_weixin) {
                this.wx_share_callback(window.location.href, {
                  url: this.sUrl,
                  id_: this.id,
                  id_type_: this.id_type_,
                  token: this.token,
                  open_id: this.open_id,
                  title: this.info.title,
                  content: this.info.content,
                  img: wx_img
                })
              }

              let timer = setInterval(() => {
               
                if (document.getElementById('card')) {
                  // console.log(document.getElementById("card"));
                  let color = this.sale_style == 0 ? '#888888' : '#fdce74'
                  LuckyCard.case({
                    coverColor: color,
                   
                    ratio: 0.2,
                    callback: function () {
                      this.clearCover()
                    }
                  })
                  clearInterval(timer)
                }
               
              }, 100)

              if (this.sale_style == 0) {
                // 只有第一种需要获取banner图的高度
                var img = new Image()
                img.src = this.img_list[0].baseUrl
                img.onload = () => {
                  let prop = img.height / img.width
                  // console.log(img.height / img.width);
                  this.$refs.header.style.height = prop * 100 + 'vw'
                }
              }
            } else {
              Toast('无数据')
            }
          }
        )
      } else {
        this.authorize()
      }
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&state=sale'
      window.location.href = open_url
    },

  /**
   * 导航
   */
    open_map_nav()
    {
      if(this.lng && this.lat)
      {
        this.wx_nav(window.location.href, this.lng, this.lat, this.title, this.address)
      }
      else
      {
        Toast("不能定位!");
      }
        
    },
    // 初始化sUrl
    initSUrl () {
      this.sUrl =
        'https://m.zmlxj.com/#/footprints/PrimaryScenic?sid=' +
        this.id +
        '_0_' +
        this.saleId
    },
    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id == 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                // "onMenuShareAppMessage",
                'onMenuShareTimeline',
                // "onMenuShareQQ",
                // "onMenuShareQZone",
                'openLocation',
                'getLocation',
                'hideMenuItems'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var open_id = this.open_id
            if (info != '') {
              if (info.url != '') {
                let url_arr = info.url.split('share_open_id')

                url = url_arr[0] + '&share_open_id=' + open_id
              } else {
                url = url + '&share_open_id=' + open_id
              }
            } else {
              url = url + '&share_open_id=' + open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.hideMenuItems({
                menuList: [
                  'menuItem:share:appMessage',
                  'menuItem:share:qq',
                  'menuItem:share:weiboApp',
                  'menuItem:share:facebook',
                  'menuItem:share:QZone'
                ] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              if (ret.data.id == 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id

                this.showScratch = true
                this.showFinger = true
                this.showMask = false
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    }
  }
}
</script>

<style>
@import "../../assets/utils/lucky-card.css";
</style>

<style scoped>
[v-cloak] {
  display: none;
}
.align_items{
  align-items:center;
}
.container {
  width: 100%;
  min-height: 100%;

  /* overflow-y: auto;
  overflow-x: hidden; */
  position: relative;
}
.container .wrapper-1 {
  width: 100%;
  background-color: rgb(255, 196, 4);
}

/* 遮罩层 */
.wrapper-1 .act_mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  color: #fff;
}
.wrapper-1 .act_mask .mask_title {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 1rem;
  font-weight: 400;
}
.wrapper-1 .act_mask .mask_content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.88rem;
}
.wrapper-1 .act_mask .mask_content div {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-1 .act_mask .mask_content div img {
  width: 1.2rem;
  height: 1.2rem;
}
.wrapper-1 .act_mask .mask_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
}
/*  */
.container .wrapper-1 .act_header {
  position: relative;
  width: 100%;
  height: 50vh;
}
.container .wrapper-1 .act_header .swipe_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* 时间 */
.container .wrapper-1 .act_header .header_time {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: rgb(255, 196, 4);
  /* display: flex; */
  /* border: 1px solid #fff; */
  color: #fff;
  z-index: 1;
  padding: 4px 12px;
  border-radius: 2em;
}
.container .wrapper-1 .act_header .header_time .time_renew {
  margin-left: 4px;
  color: rgb(13, 180, 231);
}
/* 二维码 */
.container .wrapper-1 .act_header .header_qr_code {
  position: absolute;
  width: 2rem;
  height: 2rem;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: #fff;
  z-index: 1;
  animation: qr_code_bg 3s linear infinite alternate;
}
@keyframes qr_code_bg {
  0% {
    background: transparent;
  }

  100% {
    background: #fff;
  }
}
.container .wrapper-1 .act_header .header_qr_code .qr_code_icon {
  width: 100%;
  height: 100%;
}

/*  */
.container .wrapper-1 .act_main {
  width: 100%;
  background-color: transparent;
  padding: 0 3vw;
  box-sizing: border-box;
}
.container .wrapper-1 .act_main .main_title {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.container .wrapper-1 .act_main .main_title h2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container .wrapper-1 .act_main .main_title h2 .main_title_value {
  font-size: 18px;
  color: #333;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.container .wrapper-1 .act_main .main_title h2 .main_title_btn {
  font-size: 14px;
  color: #888;
  font-weight: 400;
  display: flex;
  align-items: center;
}

/* 标签 */
.container .wrapper-1 .act_main .main_open_time {
  margin-top: 4px;
}
.container .wrapper-1 .act_main .main_open_time .time_title {
  display: inline-block;
  padding: 2px 0;
  color: #666;
}
.container .wrapper-1 .act_main .main_open_time .time_item {
  display: inline-block;
  padding: 2px 0;
  color: #333;
  margin-left: 6px;
}
/* 地址 */
.container .wrapper-1 .act_main .main_address {
  /* margin-top: 4px; */
  display: flex;
  width: 100%;
}
.container .wrapper-1 .act_main .main_address .address_name {
  color: #666;
  /* width: 20%; */
  white-space: nowrap;
}
.container .wrapper-1 .act_main .main_address .address_value {
  color: #333;
  margin-left: 6px;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}
.container
  .wrapper-1
  .act_main
  .main_address
  .address_value::-webkit-scrollbar {
  display: none;
}

.container .wrapper-1 .act_main .main_shop_tag {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 4px;
}
.container .wrapper-1 .act_main .main_shop_tag::-webkit-scrollbar {
  display: none;
}
.container .wrapper-1 .act_main .main_shop_tag .tag_wrapper {
  display: inline-flex;
  white-space: nowrap;
}
.container .wrapper-1 .act_main .main_shop_tag .tag_name {
  display: inline-block;
  padding: 2px 6px;
  margin-right: 0.5rem;
  background-color: rgba(240, 139, 64, 0.42);
  color: rgb(143, 37, 0);
}

/*  */
.container .wrapper-1 .act_main .main_wrapper {
  position: relative;
  width: 100%;
  height: 36vh;
  padding: 20px;
  box-sizing: border-box;
  margin: 1rem auto;
  background-color: rgb(222, 222, 212);
}

.wrapper-1 .act_main .main_wrapper::before {
  background-color: transparent;
  border-top: 12px dotted rgb(255, 196, 4);
  border-bottom: 12px dotted rgb(255, 196, 4);
  box-sizing: border-box;
  content: "";
  height: calc(100% + 12px);
  left: 12px;
  position: absolute;
  width: calc(100% - 24px);
  top: -6px;
  z-index: 0;
}

.container .wrapper-1 .act_main .main_wrapper .main_btn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .wrapper-1 .act_main .main_wrapper .main_finger {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 50%;
  left: 50%;
  z-index: 1;
  animation: finger 2s ease infinite both;
}
@keyframes finger {
  0% {
    left: 20%;
    opacity: 1;
  }
  100% {
    left: 80%;
    opacity: 0;
  }
}
.container .wrapper-1 .act_main .main_wrapper .main_finger img {
  width: 100%;
}
.container .wrapper-1 .act_main .main_wrapper .main_btn span {
  display: inline-block;
  padding: 6px 20px;
  background: linear-gradient(#e33, rgb(230, 45, 45), #e33);
  border-radius: 4px;
  border: 1px solid rgb(245, 44, 44);
  box-shadow: 0 0 2px rgb(131, 6, 6);
  color: #fff;
  font-size: 24px;
}
.container .wrapper-1 .act_main .main_wrapper .main_content {
  width: 100%;
  height: 100%;
}
.wrapper-1 #scratch {
  width: 100%;
  height: 100%;
}
/* .wrapper-1 #card {
  position: relative;
  height: 100%;
  width: 100%;
  font-weight: bold;
  background-color: tranparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(155, 29, 29);
}
.wrapper-1 #card .card_code {
  font-size: 20px;
  color: #f33;
  margin-top: 1rem;
}
.wrapper-1 #card .card_code span {
  color: #f33;
}
.wrapper-1 #card .card_value {
  font-size: 18px;
}
.wrapper-1 #card .card_value .card_num {
  font-size: 30px;
  color: #f33;
}
.wrapper-1 #card .card_content {
  font-size: 16px;
  margin-top: 1rem;
}
.wrapper-1 #card .card_tip {
  font-size: 14px;
  margin-top: 1rem;
  color: rgb(150, 137, 137);
} */
/* 活动规则 */
.wrapper-1 .act_rule {
  color: rgb(165, 113, 1);
}
/* 底部 */
.container .wrapper-1 .act_footer {
  width: 100%;
  background-color: rgb(235, 181, 3);
  /* background-color: #666; */
  padding: 0 1.2rem 3rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
}
.container .wrapper-1 .act_footer .footer_title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
}
.container .wrapper-1 .act_footer .footer_title .title_line {
  width: 80%;
  height: 1px;
  background: rgba(238, 238, 238, 0.801);
}
.container .wrapper-1 .act_footer .footer_title .title_text {
  display: block;
  position: absolute;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: rgb(235, 181, 3);
  padding: 0 6px;
  /* background-color: #666; */
}
.container .wrapper-1 .act_footer .footer_content {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.container .wrapper-1 .act_footer .footer_content::-webkit-scrollbar {
  display: none;
}
.wrapper-1 .act_footer .footer_content .footer_content_wrapper {
  white-space: nowrap;
  display: inline-flex;
  margin-top: 1rem;
}

.wrapper-1 .footer_content_wrapper .footer_content_item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  text-align: center;
  width: 4rem;
  margin-right: 1rem;
  box-sizing: border-box;
  border: 2px solid gold;
  background-color: #fff;
  background-image: url(../../assets/sale/sale_icon_1.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.wrapper-1 .footer_content_wrapper .footer_content_item:last-child {
  margin-right: 0;
}

.wrapper-1 .footer_content_item .item-img {
  height: 100%;
  width: 100%;
}

.wrapper-1 .footer_content_item .item-img > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper-1 .footer_content_wrapper .footer_content_item .item_num {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
}
.wrapper-1
  .footer_content
  .footer_content_wrapper
  .footer_content_item
  .item_unit {
  position: absolute;
  right: 0.5rem;
  bottom: 0.6rem;
  display: inline-block;
  /* padding: 4px; */
  border: 1px solid rgb(189, 3, 3);

  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;

  font-size: 12px;
  color: rgb(189, 2, 2);
}

/* 发布按钮 */
.wrapper-1 .act_issue {
  position: fixed;
  bottom: 2rem;
  right: 0.6rem;
  height: 1.8rem;
  border-radius: 2em;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  z-index: 2;
}
.wrapper-1 .act_issue .issue_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  transform-origin: center;
  transition: ease 0.5s;
}
.wrapper-1 .act_issue .issue_btn_lean {
  transform: rotate(-135deg);
  transition: ease 0.5s;
}
.wrapper-1 .act_issue .issue_btn img {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
}
.wrapper-1 .act_issue .to_publish {
  /* display: inline-block; */
  height: 1.8rem;
  line-height: 1.8rem;
  overflow: hidden;
  color: rgb(112, 200, 252);
  font-size: 14px;
  /* display: flex; */
  /* align-items: center; */
}
.wrapper-1 .act_issue .hide_publish {
  width: 0;
  transition: ease 0.5s;
}
.wrapper-1 .act_issue .show_publish {
  width: 2.4rem;
  transition: ease 0.5s;
}
/* 二维码图片 */
.wrapper-1 .act_qr_code {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
}
.wrapper-1 .act_qr_code .qr_code_img {
  width: 60%;
}
.qr_code-enter,
.qr_code-leave-to {
  opacity: 0;
}
.qr_code-enter-to,
.qr_code-leave {
  opacity: 1;
}
.qr_code-enter-active,
.qr_code-leave-active {
  transition: ease 0.3s;
}

/* 去支付 */
.act_pay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.act_pay .pay_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.act_pay .pay_content img {
  width: 130px;
  height: 80px;
}
.act_pay .pay_content p {
  font-size: 20px;
  color: #fff;
  margin-top: 1rem;
}
.act_pay .pay_content span {
  height: 2rem;
  width: 40%;
  background-color: rgb(255, 196, 4);
  margin-top: 2rem;
  text-align: center;
  line-height: 2rem;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 2em;
  border: solid 1px rgb(253, 228, 87);
  box-shadow: 0 0 2px gold;
}

/* 第二个 */
.wrapper-2 {
  width: 100vw;
  height: 100vh;
  /* background: url(../../assets/sale/sale_bg.jpg) no-repeat; */
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
}
.wrapper-2 .bg {
  width: 100%;
  height: 100%;
}

/* 遮罩层 */
.wrapper-2 .act_mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  color: #fff;
}
.wrapper-2 .act_mask .mask_title {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 1rem;
  font-weight: 400;
}
.wrapper-2 .act_mask .mask_content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.88rem;
}
.wrapper-2 .act_mask .mask_content div {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-2 .act_mask .mask_content div img {
  width: 1.2rem;
  height: 1.2rem;
}
.wrapper-2 .act_mask .mask_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
}
/* 倒计时 */
.wrapper-2 .header_time {
  position: absolute;
  background-color: rgb(50, 47, 82);
  color: #fff;
  padding: 2px 12px;
  border-radius: 2em;
  font-size: 12px;
  white-space: nowrap;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrapper-2 .header_time .time_renew {
  color: rgb(53, 160, 248);
}
/* 二维码 */
.wrapper-2 .header_qr_code {
  position: absolute;
  top: 32%;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  transform: translate(0, -100%);
  animation: qr_code_bg 3s linear infinite alternate;
}
.wrapper-2 .header_qr_code img {
  width: 100%;
}
/* 刮奖区 */
.container .wrapper-2 .main_box {
  position: absolute;
  width: 94%;
  height: 37.5%;
  left: 3%;
  top: 32.2%;
  background-color: rgb(243, 67, 69);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 2px 2px inset #ffffff22;
}
.container .wrapper-2 .main_box::before {
  content: "";
  display: block;
  position: absolute;
  height: 80%;
  width: 92%;
  top: 10%;
  left: 4%;
  border-radius: 8px;
  background-color: rgb(255, 239, 203);
  z-index: 0;
}
.container .wrapper-2 .main_box::after {
  content: "";
  display: block;
  position: absolute;
  height: calc(80% - 20px);
  width: calc(92% + 10px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  background-color: transparent;
  border-left: 10px dotted rgb(243, 67, 69);
  border-right: 10px dotted rgb(243, 67, 69);
  z-index: 0;
}
.container .wrapper-2 .main_wrapper {
  position: absolute;
  width: 85%;
  height: 70%;
  left: 7.5%;
  top: 15%;
  box-sizing: border-box;
  z-index: 2;
}

.container .wrapper-2 .main_wrapper .main_btn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .wrapper-2 .main_wrapper .main_finger {
  position: absolute;
  width: 4.225rem;
  height: 4.35rem;
  top: 20%;
  left: 10%;
  z-index: 1;
  background: url(../../assets/sale/sale_hand.png) no-repeat;
  background-size: cover;
  background-position: 0 0;
  animation: finger 2s linear infinite;
}
@keyframes finger {
  0%,
  100% {
    transform: translate(0);
  }
  14% {
    transform: translate(8rem, 0.4rem);
  }
  28% {
    transform: translateY(0.55rem);
  }
  42% {
    transform: translate(9rem, 2rem);
  }
  56% {
    transform: translate(3.25rem, 2.85rem);
  }
  68%,
  90% {
    transform: translate(3rem, 2.85rem);
  }
}
.container .wrapper-2 .main_wrapper .main_finger img {
  width: 100%;
}
.container .wrapper-2 .main_wrapper .main_btn span {
  display: inline-block;
  padding: 6px 20px;
  background: linear-gradient(#e33, rgb(230, 45, 45), #e33);
  border-radius: 4px;
  border: 1px solid rgb(245, 44, 44);
  box-shadow: 0 0 2px rgb(131, 6, 6);
  color: #fff;
  font-size: 24px;
}
.container .wrapper-2 .main_wrapper .main_content {
  width: 100%;
  height: 100%;
}
.wrapper-2 #scratch {
  width: 100%;
  height: 100%;
}
/* .wrapper-2 #card {
  position: relative;
  height: 100%;
  width: 100%;
  font-weight: bold;
  background-color: tranparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(155, 29, 29);
}
.wrapper-2 #card .card_code {
  font-size: 1.2rem;
  color: #f33;
  margin-top: 0.8rem;
}
.wrapper-2 #card .card_code span {
  color: #f33;
}
.wrapper-2 #card .card_value {
  font-size: 0.8rem;
}
.wrapper-2 #card .card_value .card_num {
  font-size: 1.6rem;
  color: #f33;
}
.wrapper-2 #card .card_content {
  font-size: 1rem;
  margin-top: 0.8rem;
}
.wrapper-2 #card .card_tip {
  font-size: 14px;
  margin-top: 0.8rem;
  color: rgb(150, 137, 137);
} */
/* 底部信息、弹框 */
.wrapper-2 .act_footer {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 77%;
}
.wrapper-2 .act_footer .footer_content {
  position: relative;
  width: 100%;
  overflow-x: auto;
  /* overflow-y: hidden; */
}
.wrapper-2 .act_footer .footer_content::-webkit-scrollbar {
  display: none;
}
.wrapper-2 .act_footer .footer_content_wrapper {
  display: inline-flex;
  position: relative;
}
.wrapper-2 .act_footer .footer_stick {
  position: absolute;
  width: 100%;
  bottom: 100%;
  left: 0;
  text-align: center;
}
.wrapper-2 .act_footer .footer_stick img {
  width: 100%;
  display: block;
}

.wrapper-2 .act_footer .footer_content_item {
  position: relative;
  margin-left: 0.2rem;
  background: url(../../assets/sale/sale_item.png) no-repeat;
  background-size: contain;
}

.wrapper-2 .footer_content_item .item-text {
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -55%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper-2 .footer_content_item .item_num {
  line-height: 1;
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(255, 241, 141);
}
.wrapper-2 .footer_content_item  .item_unit {
  font-size: 0.8rem;
  color: rgb(255, 241, 141);
}

.wrapper-2 .footer_content_item .item-img {
  position: absolute;
  top: 19.6%;
  left: 9%;
  width: 81.5%;
  height: 43%;
}

.wrapper-2 .footer_content_item .item-img > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
}
/* 发布按钮 */
.wrapper-2 .act_issue {
  position: fixed;
  top: 3rem;
  right: 0.6rem;
  height: 1.8rem;
  border-radius: 2em;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  z-index: 2;
}
.wrapper-2 .act_issue .issue_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  transform-origin: center;
  transition: ease 0.3s;
}
.wrapper-2 .act_issue .issue_btn_lean {
  transform: rotate(-135deg);
  transition: ease 0.3s;
}
.wrapper-2 .act_issue .issue_btn img {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
}
.wrapper-2 .act_issue .to_publish {
  /* display: inline-block; */
  height: 1.8rem;
  line-height: 1.8rem;
  overflow: hidden;
  color: rgb(112, 200, 252);
  font-size: 14px;
  /* display: flex; */
  /* align-items: center; */
}
.wrapper-2 .act_issue .hide_publish {
  width: 0;
  transition: ease 0.5s;
}
.wrapper-2 .act_issue .show_publish {
  width: 2.4rem;
  transition: ease 0.5s;
}
/* 二维码 */
.wrapper-2 .act_qr_code {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
/* 规则 */

.wrapper-2 .rule {
  position: absolute;
  left: 0.3rem;
  top: 1rem;
  width: 4rem;
  height: 1.44rem;
  text-align: center;
  font-size: 0.65rem;
  z-index: 1;
  background: url(../../assets/sale/sale_rule.png) no-repeat;
  background-size: 100% 100%;
}
.rule-enter,
.rule-leave-to {
  opacity: 0;
}
.rule-enter-to,
.rule-leave {
  opacity: 1;
}
.rule-enter-active,
.rule-leave-active {
  transition: 0.3s ease;
}
.wrapper-2 .rule_wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-2 .rule_wrapper .rule_container {
  position: relative;
  width: 85%;
  background-color: #fff;
  border-radius: 0.5rem;
}
.wrapper-2 .rule_wrapper .rule_container .rule_close {
  position: absolute;
  width: 1rem;
  height: 1rem;
  text-align: center;
  line-height: 1rem;
  top: 0.5rem;
  right: 0.5rem;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
}
.wrapper-2 .rule_wrapper .rule_container .rule_close i {
  font-size: 0.8rem;
}
.wrapper-2 .rule_wrapper .rule_container .rule_title {
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  color: #eee;
  font-size: 1.2rem;
  font-weight: 400;
  background: linear-gradient(left, rgb(250, 85, 50), rgb(252, 116, 26));
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.wrapper-2 .rule_wrapper .rule_container .rule_content {
  width: 100%;
  height: 16rem;
  box-sizing: border-box;
  padding: 0.5rem;
  overflow-y: auto;
  font-size: 0.8rem;
  color: #444;
}
.wrapper-2 .rule_wrapper .rule_container h2 {
  font-size: 0.88rem;
  color: #333;
  margin: 0.4rem 0;
}
.wrapper-2 .rule_wrapper .rule_container p {
  margin-bottom: 0.2rem;
}

/* 第三种样式
===================================================================================
===================================================================================
=================================================================================== */
.wrapper-3 {
  width: 100vw;
  height: 100vh;
  /* background: url(../../assets/sale/sale_bg.jpg) no-repeat; */
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
}
.wrapper-3 .bg {
  width: 100%;
  height: 100%;
}

/* 遮罩层 */
.wrapper-3 .act_mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  color: #fff;
}
.wrapper-3 .act_mask .mask_title {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 1rem;
  font-weight: 400;
}
.wrapper-3 .act_mask .mask_content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.88rem;
}
.wrapper-3 .act_mask .mask_content div {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-3 .act_mask .mask_content div img {
  width: 1.2rem;
  height: 1.2rem;
}
.wrapper-3 .act_mask .mask_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
}
/* 倒计时 */
.wrapper-3 .header_time {
  position: absolute;
  background-color: rgb(50, 47, 82);
  color: #fff;
  padding: 2px 12px;
  border-radius: 2em;
  font-size: 12px;
  white-space: nowrap;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrapper-3 .header_time .time_renew {
  color: rgb(53, 160, 248);
}
/* 二维码 */
.wrapper-3 .header_qr_code {
  position: absolute;
  top: 32%;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  transform: translate(0, -100%);
  animation: qr_code_bg 3s linear infinite alternate;
}
.wrapper-3 .header_qr_code img {
  width: 100%;
}
/* 刮奖区 */
.wrapper-3 .main_box {
  position: absolute;
  width: 94%;
  height: 37.5%;
  left: 3%;
  top: 32.2%;
  background-color: rgb(243, 67, 69);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 2px 2px inset #ffffff22;
}
.wrapper-3 .main_box::before {
  content: "";
  display: block;
  position: absolute;
  height: 80%;
  width: 92%;
  top: 10%;
  left: 4%;
  border-radius: 8px;
  background-color: rgb(255, 239, 203);
  z-index: 0;
}
.wrapper-3 .main_box::after {
  content: "";
  display: block;
  position: absolute;
  height: calc(80% - 20px);
  width: calc(92% + 10px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  background-color: transparent;
  border-left: 10px dotted rgb(243, 67, 69);
  border-right: 10px dotted rgb(243, 67, 69);
  z-index: 0;
}
.wrapper-3 .main_wrapper {
  position: absolute;
  width: 85%;
  height: 70%;
  left: 7.5%;
  top: 15%;
  box-sizing: border-box;
  z-index: 2;
}

.wrapper-3 .main_wrapper .main_btn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-3 .main_wrapper .main_finger {
  position: absolute;
  width: 4.225rem;
  height: 4.35rem;
  top: 20%;
  left: 10%;
  z-index: 1;
  background: url(../../assets/sale/sale_hand.png) no-repeat;
  background-size: cover;
  background-position: 0 0;
  animation: finger 2s linear infinite;
}
@keyframes finger {
  0%,
  100% {
    transform: translate(0);
  }
  14% {
    transform: translate(8rem, 0.4rem);
  }
  28% {
    transform: translateY(0.55rem);
  }
  42% {
    transform: translate(9rem, 2rem);
  }
  56% {
    transform: translate(3.25rem, 2.85rem);
  }
  68%,
  90% {
    transform: translate(3rem, 2.85rem);
  }
}
.wrapper-3 .main_wrapper .main_finger img {
  width: 100%;
}
.wrapper-3 .main_wrapper .main_btn span {
  display: inline-block;
  padding: 6px 20px;
  background: linear-gradient(#e33, rgb(230, 45, 45), #e33);
  border-radius: 4px;
  border: 1px solid rgb(245, 44, 44);
  box-shadow: 0 0 2px rgb(131, 6, 6);
  color: #fff;
  font-size: 24px;
}
.wrapper-3 .main_wrapper .main_content {
  width: 100%;
  height: 100%;
}
.wrapper-3 #scratch {
  width: 100%;
  height: 100%;
}
#card {
  position: relative;
  height: 100%;
  width: 100%;
  font-weight: bold;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  color: rgb(155, 29, 29);
}

/* 未中奖 */
#card .empty img {
  width: 12vh;
  display: block;
  margin: 0 auto;
}
#card .empty .title {
  font-size: 1rem;
}

/* 奖品信息展示 */
#card .prize {
  padding: 1vh 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#card .prize .title {
  width: 100%;
  height: 4.5vh;
  line-height: 4.5vh;
  font-size: 1rem;
  text-align: center;
}

#card .prize .title .value {
  font-size: 1.2rem;
  color: #e33;
}

#card .prize .preview {
  display: block;
  margin: 0 auto;
  width: 10.5vh;
  height: 10.5vh;
  object-fit: cover;
  border-radius: 4px;
}

#card .prize .code {
  width: 100%;
  height: 4.5vh;
  line-height: 4.5vh;
  font-size: 0.88rem;
  text-align: center;
}

#card .prize .code .value {
  font-size: 1rem;
  color: #e33;
}

#card .prize .date {
  width: 100%;
  height: 4.5vh;
  line-height: 4.5vh;
  font-size: 0.88rem;
  text-align: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.4);
}

/* 折扣券奖品 */
#card .sale {
  width: 100%;
  height: 100%;
  padding: 1vh 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#card .sale .title {
  width: 100%;
  text-align: center;
  font-size: 0.88rem;
}

#card .sale .title .value {
  font-size: 1.4rem;
  color: #e33;
}

#card .sale .tip {
  width: 100%;
  height: 4.5vh;
  line-height: 4.5vh;
  font-size: 0.88rem;
  text-align: center;
}
#card .sale .code {
  width: 100%;
  height: 4.5vh;
  line-height: 4.5vh;
  font-size: 0.88rem;
  text-align: center;
}

#card .sale .code .value {
  font-size: 1rem;
  color: #e33;
}

#card .sale .date {
  width: 100%;
  height: 4.5vh;
  line-height: 4.5vh;
  font-size: 0.88rem;
  text-align: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.4);
}

/* 底部信息、弹框 */
.wrapper-3 .footer {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 77%;
  height: 23vh;
  background-color: hsla(0, 0%, 0%, 0.3);
}

.wrapper-3 .footer .content {
  position: relative;
  width: 100%;
  height: 19vh;
  overflow-x: auto;
}

.wrapper-3 .footer .content::-webkit-scrollbar {
  display: none;
}

.wrapper-3 .footer .footer-title {
  position: relative;
  height: 5vh;
  width: 100%;
  text-align: center;
  line-height: 5vh;
  color: rgb(255, 205, 90);
  font-size: 0.8rem;
  font-weight: bold;
}
.wrapper-3 .footer .footer-title::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 3.2rem;
  height: 1px;
  background-color: rgb(234, 49, 49);
}

.wrapper-3 .footer .sale-list {
  display: inline-flex;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  padding: 1vh 0.5rem;
}

.wrapper-3 .footer .sale-item {
  position: relative;
  height: 100%;
  width: 10vh;
  box-sizing: border-box;
}

.wrapper-3 .footer .sale-item:not(:last-child) {
  margin-right: 0.5rem;
}

.wrapper-3 .footer .item {
  width: 100%;
  height: 17vh;
}

.wrapper-3 .footer .item .name {
  position: relative;
  display: block;
  height: 5vh;
  line-height: 5vh;
  width: 100%;
  text-align: center;
  color: rgb(250, 241, 141);
  font-size: 0.68rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wrapper-3 .footer .item .value {
  position: relative;
  width: 10vh;
  height: 10vh;
  color: rgb(255, 234, 138);
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(244, 69, 69);
  border-radius: 4px;
  box-sizing: border-box;
}
.wrapper-3 .footer .prize img {
  position: relative;
  width: 10vh;
  height: 10vh;
  object-fit: cover;
  border-radius: 4px;
}
.wrapper-3 .footer .value::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 2px 2px inset rgb(250, 211, 100);
  z-index: 1;
}

/* 发布按钮 */
.wrapper-3 .act_issue {
  position: fixed;
  top: 3rem;
  right: 0.6rem;
  height: 1.8rem;
  border-radius: 2em;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  z-index: 2;
}
.wrapper-3 .act_issue .issue_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  transform-origin: center;
  transition: ease 0.3s;
}
.wrapper-3 .act_issue .issue_btn_lean {
  transform: rotate(-135deg);
  transition: ease 0.3s;
}
.wrapper-3 .act_issue .issue_btn img {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
}
.wrapper-3 .act_issue .to_publish {
  /* display: inline-block; */
  height: 1.8rem;
  line-height: 1.8rem;
  overflow: hidden;
  color: rgb(112, 200, 252);
  font-size: 14px;
  /* display: flex; */
  /* align-items: center; */
}
.wrapper-3 .act_issue .hide_publish {
  width: 0;
  transition: ease 0.5s;
}
.wrapper-3 .act_issue .show_publish {
  width: 2.4rem;
  transition: ease 0.5s;
}
/* 二维码 */
.wrapper-3 .act_qr_code {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
/* 规则 */

.wrapper-3 .rule {
  position: absolute;
  left: 0.3rem;
  top: 1rem;
  width: 4rem;
  height: 1.44rem;
  text-align: center;
  font-size: 0.65rem;
  z-index: 1;
  background: url(../../assets/sale/sale_rule.png) no-repeat;
  background-size: 100% 100%;
}
.rule-enter,
.rule-leave-to {
  opacity: 0;
}
.rule-enter-to,
.rule-leave {
  opacity: 1;
}
.rule-enter-active,
.rule-leave-active {
  transition: 0.3s ease;
}
.wrapper-3 .rule_wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-3 .rule_wrapper .rule_container {
  position: relative;
  width: 85%;
  background-color: #fff;
  border-radius: 0.5rem;
}
.wrapper-3 .rule_wrapper .rule_container .rule_close {
  position: absolute;
  width: 1rem;
  height: 1rem;
  text-align: center;
  line-height: 1rem;
  top: 0.5rem;
  right: 0.5rem;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
}
.wrapper-3 .rule_wrapper .rule_container .rule_close i {
  font-size: 0.8rem;
}
.wrapper-3 .rule_wrapper .rule_container .rule_title {
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  color: #eee;
  font-size: 1.2rem;
  font-weight: 400;
  background: linear-gradient(left, rgb(250, 85, 50), rgb(252, 116, 26));
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.wrapper-3 .rule_wrapper .rule_container .rule_content {
  width: 100%;
  height: 16rem;
  box-sizing: border-box;
  padding: 0.5rem;
  overflow-y: auto;
  font-size: 0.8rem;
  color: #444;
}
.wrapper-3 .rule_wrapper .rule_container h2 {
  font-size: 0.88rem;
  color: #333;
  margin: 0.4rem 0;
}
.wrapper-3 .rule_wrapper .rule_container p {
  margin-bottom: 0.2rem;
}

/* 第四种样式
===================================================================================
===================================================================================
=================================================================================== */
.wrapper-4 {
  width: 100vw;
  
  /* background: url(../../assets/sale/sale_bg.jpg) no-repeat; */
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
}
.wrapper-4 .bg {
  width: 100%;
  
}

/* 遮罩层 */
.wrapper-4 .act_mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  color: #fff;
}
.wrapper-4 .act_mask .mask_title {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 1rem;
  font-weight: 400;
}
.wrapper-4 .act_mask .mask_content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.88rem;
}
.wrapper-4 .act_mask .mask_content div {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-4 .act_mask .mask_content div img {
  width: 1.2rem;
  height: 1.2rem;
}
.wrapper-4 .act_mask .mask_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
}
/* 倒计时 */
.wrapper-4 .header_time {
  position: absolute;
  background-color: rgb(50, 47, 82);
  color: #fff;
  padding: 2px 12px;
  border-radius: 2em;
  font-size: 12px;
  white-space: nowrap;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrapper-4 .header_time .time_renew {
  color: rgb(53, 160, 248);
}
/* 二维码 */
.wrapper-4 .header_qr_code {
  position: absolute;
  top: 32%;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  transform: translate(0, -100%);
  animation: qr_code_bg 3s linear infinite alternate;
}
.wrapper-4 .header_qr_code img {
  width: 100%;
}
/* 刮奖区 */
.wrapper-4 .main_box {
  position: absolute;
      width: 82%;
    height: 8.5%;
    left: 9%;
    top: 24.2%;
 
  background-color: #d3412d;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 2px 2px inset #ffffff22;
}
.wrapper-4 .main_box::before {
  content: "";
  display: block;
  position: absolute;
  height: 80%;
  width: 92%;
  top: 10%;
  left: 4%;
  border-radius: 8px;
  background-color: rgb(255, 239, 203);
  z-index: 0;
}
.wrapper-4 .main_box::after {
  content: "";
  display: block;
  position: absolute;
  height: calc(80% - 20px);
  width: calc(92% + 10px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  background-color: transparent;
  border-left: 10px dotted #d3412d;
  border-right: 10px dotted #d3412d;
  z-index: 0;
}
.wrapper-4 .main_wrapper {
  position: absolute;
  width: 92%;
    height: 80%;
    left: 4%;
    top: 10%;
  
  box-sizing: border-box;
  z-index: 2;
}

.wrapper-4 .main_wrapper .main_btn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-4 .main_wrapper .main_finger {
  position: absolute;
  width: 4.225rem;
  height: 4.35rem;
  top: 20%;
  left: 10%;
  z-index: 1;
  background: url(../../assets/sale/sale_hand.png) no-repeat;
  background-size: cover;
  background-position: 0 0;
  animation: finger 2s linear infinite;
}
@keyframes finger {
  0%,
  100% {
    transform: translate(0);
  }
  14% {
    transform: translate(8rem, 0.4rem);
  }
  28% {
    transform: translateY(0.55rem);
  }
  42% {
    transform: translate(9rem, 2rem);
  }
  56% {
    transform: translate(3.25rem, 2.85rem);
  }
  68%,
  90% {
    transform: translate(3rem, 2.85rem);
  }
}
.wrapper-4 .main_wrapper .main_finger img {
  width: 100%;
}
.wrapper-4 .main_wrapper .main_btn span {
  display: inline-block;
  padding: 6px 20px;
  background: linear-gradient(#e33, rgb(230, 45, 45), #e33);
  border-radius: 4px;
  border: 1px solid rgb(245, 44, 44);
  box-shadow: 0 0 2px rgb(131, 6, 6);
  color: #fff;
  font-size: 24px;
}
.wrapper-4 .main_wrapper .main_content {
  width: 100%;
  height: 100%;
}
.wrapper-4 #scratch {
  width: 100%;
  height: 100%;
}
.card4 {
  position: relative;
  height: 100%;
  width: 68%;
  font-weight: bold;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  color: rgb(155, 29, 29);
}

/* 未中奖 */
.card4 .empty img {
  width: 12vh;
  display: block;
  margin: 0 auto;
}
.card4 .empty .title {
  font-size: 1rem;
}

/* 奖品信息展示 */
.card4 .prize {
  padding: 1vh 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.card4 .prize .title {
  width: 100%;
  height: 4.5vh;
  line-height: 4.5vh;
  font-size: 1rem;
  text-align: center;
}

.card4 .prize .title .value {
  font-size: 1.2rem;
  color: #e33;
}

.card4 .prize .preview {
  display: block;
  margin: 0 auto;
  width: 10.5vh;
  height: 10.5vh;
  object-fit: cover;
  border-radius: 4px;
}

.card4 .prize .code {
  width: 100%;
  height: 4.5vh;
  line-height: 4.5vh;
  font-size: 0.88rem;
  text-align: center;
}

.card4 .prize .code .value {
  font-size: 1rem;
  color: #e33;
}

.card4 .prize .date {
  width: 100%;
  height: 4.5vh;
  line-height: 4.5vh;
  font-size: 0.88rem;
  text-align: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.4);
}

/* 折扣券奖品 */
.card4 .sale4 {
  width: 68%;
  height: 100%;
  padding: 1vh 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: center; */
  display: inline-block;
  position: absolute;
}

.card4 .sale4 .title {
  width: 100%;
  text-align: center;
  font-size: 0.88rem;
}

.card4 .sale4 .title .value {
  font-size: 1.4rem;
  color: #e33;
}


.card4 .sale4 .code {
  width: 100%;
  height: 4.5vh;
  line-height: 4.5vh;
  font-size: 0.88rem;
  text-align: center;
}

.card4 .sale4 .code .value {
  font-size: 1rem;
  color: #e33;
}

.card4 .sale4 .date {
  width: 100%;
  height: 4.5vh;
  line-height: 4.5vh;
  font-size: 0.88rem;
  text-align: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.4);
}

/* 底部信息、弹框 */
.wrapper-4 .footer {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 77%;
  height: 23vh;
  background-color: hsla(0, 0%, 0%, 0.3);
}

.wrapper-4 .footer .content {
  position: relative;
  width: 100%;
  height: 19vh;
  overflow-x: auto;
}

.wrapper-4 .footer .content::-webkit-scrollbar {
  display: none;
}

.wrapper-4 .footer .footer-title {
  position: relative;
  height: 5vh;
  width: 100%;
  text-align: center;
  line-height: 5vh;
  color: rgb(255, 205, 90);
  font-size: 0.8rem;
  font-weight: bold;
}
.wrapper-4 .footer .footer-title::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 3.2rem;
  height: 1px;
  background-color: rgb(234, 49, 49);
}

.wrapper-4 .footer .sale-list {
  display: inline-flex;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  padding: 1vh 0.5rem;
}

.wrapper-4 .footer .sale-item {
  position: relative;
  height: 100%;
  width: 10vh;
  box-sizing: border-box;
}

.wrapper-4 .footer .sale-item:not(:last-child) {
  margin-right: 0.5rem;
}

.wrapper-4 .footer .item {
  width: 100%;
  height: 17vh;
}

.wrapper-4 .footer .item .name {
  position: relative;
  display: block;
  height: 5vh;
  line-height: 5vh;
  width: 100%;
  text-align: center;
  color: rgb(250, 241, 141);
  font-size: 0.68rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wrapper-4 .footer .item .value {
  position: relative;
  width: 10vh;
  height: 10vh;
  color: rgb(255, 234, 138);
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(244, 69, 69);
  border-radius: 4px;
  box-sizing: border-box;
}
.wrapper-4 .footer .prize img {
  position: relative;
  width: 10vh;
  height: 10vh;
  object-fit: cover;
  border-radius: 4px;
}
.wrapper-4 .footer .value::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 2px 2px inset rgb(250, 211, 100);
  z-index: 1;
}

/* 发布按钮 */
.wrapper-4 .act_issue {
  position: fixed;
  top: 3rem;
  right: 0.6rem;
  height: 1.8rem;
  border-radius: 2em;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  z-index: 2;
}
.wrapper-4 .act_issue .issue_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  transform-origin: center;
  transition: ease 0.3s;
}
.wrapper-4 .act_issue .issue_btn_lean {
  transform: rotate(-135deg);
  transition: ease 0.3s;
}
.wrapper-4 .act_issue .issue_btn img {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
}
.wrapper-4 .act_issue .to_publish {
  /* display: inline-block; */
  height: 1.8rem;
  line-height: 1.8rem;
  overflow: hidden;
  color: rgb(112, 200, 252);
  font-size: 14px;
  /* display: flex; */
  /* align-items: center; */
}
.wrapper-4 .act_issue .hide_publish {
  width: 0;
  transition: ease 0.5s;
}
.wrapper-4 .act_issue .show_publish {
  width: 2.4rem;
  transition: ease 0.5s;
}
/* 二维码 */
.wrapper-4 .act_qr_code {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
 .act_qr_code .qr_code_img {
  width: 60%;
}
/* 规则 */

.wrapper-4 .rule {
  position: absolute;
  left: 0.3rem;
  top: 1rem;
  width: 4rem;
  height: 1.44rem;
  text-align: center;
  font-size: 0.65rem;
  z-index: 1;
  background: url(../../assets/sale/sale_rule.png) no-repeat;
  background-size: 100% 100%;
}
.rule-enter,
.rule-leave-to {
  opacity: 0;
}
.rule-enter-to,
.rule-leave {
  opacity: 1;
}
.rule-enter-active,
.rule-leave-active {
  transition: 0.3s ease;
}
.wrapper-4 .rule_wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-4 .rule_wrapper .rule_container {
  position: relative;
  width: 85%;
  background-color: #fff;
  border-radius: 0.5rem;
}
.wrapper-4 .rule_wrapper .rule_container .rule_close {
  position: absolute;
  width: 1rem;
  height: 1rem;
  text-align: center;
  line-height: 1rem;
  top: 0.5rem;
  right: 0.5rem;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
}
.wrapper-4 .rule_wrapper .rule_container .rule_close i {
  font-size: 0.8rem;
}
.wrapper-4 .rule_wrapper .rule_container .rule_title {
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  color: #eee;
  font-size: 1.2rem;
  font-weight: 400;
  background: linear-gradient(left, rgb(250, 85, 50), rgb(252, 116, 26));
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.wrapper-4 .rule_wrapper .rule_container .rule_content {
  width: 100%;
  height: 16rem;
  box-sizing: border-box;
  padding: 0.5rem;
  overflow-y: auto;
  font-size: 0.8rem;
  color: #444;
}
.wrapper-4 .rule_wrapper .rule_container h2 {
  font-size: 0.88rem;
  color: #333;
  margin: 0.4rem 0;
}
.wrapper-4 .rule_wrapper .rule_container p {
  margin-bottom: 0.2rem;
}


/**二维码 */
.checkUrl{
      width: 25%;
   
    display: inline-block;
    position: absolute;
    right: 2%;
    
}
.checkUrl img{
  width: 100%;
}

/**导航按钮 */
.sale-nav{
  position: absolute;
    top: 20%;
    left: 31%;
    width: 150px;
}

.sale-location{
  position: absolute;
            top: 12%;
            left: 52%;
            transform: translate(-50%, -50%);
            text-align: center;
            margin-left: -1%;
            animation-name: beat; /*动画名称*/
            animation-duration: 2s; /*设置秒数*/
            animation-timing-function: linear;/*速度曲线*/
            animation-iteration-count: infinite;/*播放次数*/
            animation-direction: alternate;/*逆向播放*/
            animation-play-state: running;/*正在运行*/
            width: 10%;
}
@keyframes beat {
            0% {
                top: 13%;
            }

            25% {
                top: 11%;
            }

            50% {
                top: 13%;
            }

            75% {
                top: 11%;
            }

            100% {
                top: 13%;
            }
        }







</style>
