<template>
  <div class="shopList dylan">
    <!-- 启动页 -->
    <transition name="fade">
      <Start btn="我要报名" v-if="show_start" :imgUrl="activity.newUrl">
        <button class="start-btn" @click="show_start=false">我要报名</button>
      </Start>
    </transition>

    <div class="shopList-wrapper" v-show="!show_start">
      <!-- 大图 -->
      <div class="index-banner" v-if="activity.banner_img_app">
        <router-link :to="{path:'/activity/ActivityDetail',query:{aid:aid}}">
          <img :src="activity.banner_img_app" alt />
        </router-link>
      </div>

      <!--顶部 数据-->
      <div class="index-info">
        <div class="btn_box">
          <div class="btn" @click="showRankList">排行榜</div>
          <div class="btn rank">
            <span>活动于</span>
            <div v-if="activity.activity_end_time">
              <p>
                <span>{{activity.activity_end_time.split("-")[0]}}</span>年
                <span>{{activity.activity_end_time.split("-")[1]}}</span>月
                <span>{{activity.activity_end_time.split("-")[2]}}</span>日
              </p>
              <p>
                <span>24</span>时
                <span>00</span>分
                <span>00</span>秒
              </p>
            </div>
            <span>结束</span>
          </div>
          <a class="btn" href="javascript:;" @click.prevent="rule=true">活动规则</a>
        </div>
        <ul class="box clearfix">
          <li>
            <p>商户</p>
            <span>{{activity.shop_numbers}}</span>
          </li>
          <li>
            <p>图片</p>
            <span>{{activity.img_numbers}}</span>
            <!--<span class="days">00</span>天<span class="hours">00</span>时<span class="minutes">00</span>分<span class="seconds">00</span>秒-->
          </li>
          <li>
            <p>浏览</p>
            <span>{{activity.looks_numbers}}</span>
          </li>
          <li>
            <p>分享</p>
            <span>{{activity.share_numbers}}</span>
          </li>
        </ul>
        <!--  -->
      </div>

      <!-- 搜索 -->
      <div ref="search" class="index-search">
        <div class="search-wrapper">
          <i class="iconfont iconsousuo"></i>
          <input
            type="text"
            placeholder="搜索作品名称或编号"
            v-model="key_word"
            @focus="wrighting=true"
            @blur="wrighting=false"
          />
          <button type="submit" @click="search">搜索</button>
        </div>
      </div>
      <!-- <transition name="fade"> -->
      <div class="search-fix-top" v-show="search_fix_top">
        <div class="search-wrapper">
          <i class="iconfont iconsousuo"></i>
          <input
            type="text"
            placeholder="搜索作品名称或编号"
            v-model="key_word"
            @focus="wrighting=true"
            @blur="wrighting=false"
          />
          <button type="submit" @click="search">搜索</button>
        </div>
      </div>
      <!-- </transition> -->

      <!-- 作品列表 -->
      <div class="no-work" v-if="!display_list || !display_list.length">
        <img src="../../assets/switch/error.png" alt />
        <p>还没有作品~~</p>
      </div>
      <div class="work-wrapper" ref="loadmore" v-if="display_list && display_list.length">
        <div class="work-list">
          <div class="col">
            <div class="item" v-for="(k,i) in filterByIndex(display_list, 1)" :key="i">
              <div class="pic-wrapper" @click="jumpToDetail(k.id)">
                <span class="num">{{k.id}}号</span>

                <img class="pic" :src="k.baseUrl" />
                <span class="img_num">共{{k.img_numbers}}张</span>
              </div>

              <div class="u-card">
                <h1>{{k.title}}</h1>
                <div class="card-data">
                  <p>
                    <span class="card-data-name">浏览</span>
                    <span class="card-data-value">{{k.looks_numbers}}</span>
                  </p>
                  <p>
                    <span class="card-data-name">分享</span>
                    <span class="card-data-value">{{k.share_numbers}}</span>
                  </p>
                </div>
              </div>
              <div
                class="vote"
                @click="vote(k)"
                :class="{'no':  k.is_join_shop_detail == 0 && ((k.is_show == 0) || (k.is_show == 1 && k.is_vote == 1) || (k.is_show == 2) || (!is_weixin))}"
              >
                <i class="add" :class="k.add ? 'show_add' : ''">+1</i>
                <p v-if="is_weixin && k.is_join_shop_detail == 0">
                  <span v-if="k.is_show == 0">投票未开始</span>
                  <span v-else-if="k.is_show == 1 && k.is_vote == 0">我要投票</span>
                  <span v-else-if="k.is_show == 1 && k.is_vote == 1">已投票</span>
                  <span v-if="k.is_show == 2">投票已结束</span>
                </p>
                <p v-if="!is_weixin && k.is_join_shop_detail == 0">
                  <span>微信中投票</span>
                </p>
                <p v-if="k.is_join_shop_detail == 1">
                  <span>查看详情</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="item" v-for="(k,i) in filterByIndex(display_list, 2)" :key="i">
              <div class="pic-wrapper" @click="jumpToDetail(k.id)">
                <span class="num">{{k.id}}号</span>
                <img class="pic" :src="k.baseUrl" />
                <span class="img_num">共{{k.img_numbers}}张</span>
              </div>

              <div class="u-card">
                <h1>{{k.title}}</h1>
                <div class="card-data">
                  <p>
                    <span class="card-data-name">浏览</span>
                    <span class="card-data-value">{{k.looks_numbers}}</span>
                  </p>
                  <p>
                    <span class="card-data-name">分享</span>
                    <span class="card-data-value">{{k.share_numbers}}</span>
                  </p>
                </div>
              </div>
              <div
                class="vote"
                @click="vote(k)"
                :class="{'no': k.is_join_shop_detail == 0 && ((k.is_show == 0) || (k.is_show == 1 && k.is_vote == 1) || (k.is_show == 2) || (!is_weixin))}"
              >
                <i class="add" :class="k.add ? 'show_add' : ''">+1</i>
                <p v-if="is_weixin && k.is_join_shop_detail == 0">
                  <span v-if="k.is_show == 0">投票未开始</span>
                  <span v-else-if="k.is_show == 1 && k.is_vote == 0">我要投票</span>
                  <span v-else-if="k.is_show == 1 && k.is_vote == 1">已投票</span>
                  <span v-if="k.is_show == 2">投票已结束</span>
                </p>
                <p v-if="!is_weixin && k.is_join_shop_detail == 0">
                  <span>微信中投票</span>
                </p>
                <p v-if="k.is_join_shop_detail == 1">
                  <span>查看详情</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--点击加载更多按钮-->
        <div
          class="click-load-more"
          @click="click_load_more"
          v-if="display_list && display_list.length"
        >
          <div class="load-more-line"></div>
          <div class="load-more-text">{{load_more ? '点击加载更多' : '我是有底线的'}}</div>
        </div>
      </div>
      <!-- 红包雨 -->
      <red-packet
        :redPacketList="info.red_packet_rain_image_url_list"
        v-if="info.have_red_packet_rain"
        :is_admin="is_admin"
        :id_type_="backUrl"
        :red_packet_type_="info.red_packet_type_"
        :red_packet_image_url="info.red_packet_image_url"
      ></red-packet>

      <!-- 投票规则 - 弹出层 -->
      <transition name="fade">
        <div class="popups-bg" v-show="rule"></div>
      </transition>
      <transition name="bounce">
        <div class="popups apply" id="rule" v-show="rule">
          <div class="popups-box">
            <div class="box-header">
              <h2>活动规则</h2>
              <div class="close" @click="rule=false">
                <i class="iconfont iconbaseline-close-px"></i>
              </div>
            </div>
            <div class="wrapper">
              <div class="text" v-html="activity.activity_content"></div>
            </div>
          </div>
        </div>
      </transition>
      <!--20180324 在投票页面增加发布足迹功能按钮 go_vote表示发布成功之后，跳转到投票页面-->

      <router-link
        :to="{path:'/shop/shopListPublish',query:{aid:aid}}"
        tag="div"
        :class="['photo_publish', wrighting ? 'photo_publish_hide' : '']"
        v-if="is_show_publish"
      >我要报名</router-link>

      <!-- 排行榜 -->
      <transition name="slider">
        <div class="rank-wrapper" v-show="show_rank">
          <div class="rank-header">
            <div class="rank-menu">
              <h2
                :class="current_menu === 'looks' ? 'rank-menu-active' : ''"
                @click="checkRankMenu('looks')"
              >浏览排行</h2>
              <h2
                :class="current_menu === 'share' ? 'rank-menu-active' : ''"
                @click="checkRankMenu('share')"
              >分享排行</h2>
            </div>
            <div class="close" @click="show_rank=false">
              <i class="iconfont iconclose"></i>
            </div>
          </div>
          <div class="rank-content" ref="rank" @scroll="scrollLoadMore($event)">
            <ul class="rank-list" v-if="rank_info.list && rank_info.list.length">
              <li
                class="rank-list-item"
                v-for="(item,i) in rank_info.list"
                :key="i"
                @click="jumpToDetail(item.id)"
              >
                <div class="rank-ranking">
                  <p>{{item.rank}}</p>
                </div>
                <div class="rank-item-icon">
                  <img :src="item.newUrl" alt />
                </div>

                <div class="rank-item-info">
                  <p class="info-name">{{item.title}}</p>
                  <p class="info-num">编号：{{item.id}}</p>
                  <p class="info-votes" v-if="rank_info.type === 'looks'">浏览：{{item.looks_numbers}}</p>
                  <p
                    class="info-votes"
                    v-else-if="rank_info.type === 'share'"
                  >分享：{{item.share_numbers}}</p>
                </div>
              </li>
            </ul>
            <div class="no-rank-list" v-else>
              <img src="../../assets/switch/error.png" />
              <p>暂无排行数据~~</p>
            </div>
            <div class="load-more" v-if="!rank_info.more && rank_info.list.length">
              <p class="load-more-line"></p>
              <p class="load-more-text">我是有底线的</p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator } from 'mint-ui'

import FootprintsList from '../../components/FootprintsList'
import download from '../../components/Download'
import RedPacket from '../../components/RedPacket'
import bgMusic from '../../components/Music'
import UserList from '../../components/UserList'
import PublishButton from '../../components/PublishButton'
import Start from '../../components/Start'

var wx = require('weixin-js-sdk')
export default {
  components: {
    Start,
    FootprintsList,
    download,
    'red-packet': RedPacket,
    'bg-music': bgMusic,
    UserList,
    'publish-button': PublishButton
  },
  data () {
    return {
      // 背景音乐
      aid: this.$route.query.aid ? this.$route.query.aid : 0,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      union_id: localStorage.getItem('open_id_' + this.$route.query.aid)
        ? localStorage.getItem('open_id_' + this.$route.query.aid)
        : '',
      open_id: localStorage.getItem('open_id_' + this.$route.query.aid)
        ? localStorage.getItem('open_id_' + this.$route.query.aid)
        : '',

      rule: false,
      backUrl: 'shopList',
      info: {},
      key_word: '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      page: 1,
      activity: {},
      work_list: [],
      display_list: [],
      park_id: 298, // 给发布足迹页面使用的
      is_vote: true, // 表示是否投票
      user_id: '',
      load_more: false,
      // 分享地址
      sUrl: '',
      is_admin: 0, // 管理员
      is_voting: false, // 正在投票
      is_show_publish: 1,
      // 排行榜
      show_rank: false, // 显示排行榜
      rank_info: {
        page: 1,
        list: [],
        more: false,
        type: 'looks'
      },
      looks_rank: {
        page: 1,
        list: [],
        more: false,
        type: 'looks'
      },
      share_rank: {
        page: 1,
        list: [],
        more: false,
        type: 'share'
      },
      loading: false,
      current_menu: 'looks',

      search_fix_top: false, // 搜索栏吸顶
      se_offsetTop: 0, // 搜索栏距离顶部高度

      show_start: true, // 显示启动页

      wrighting: false, // 正在输入

      is_load: false
    }
  },

  mounted () {
    this.initSUrl()
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem(
            'open_id_' + this.aid,
            this.$route.query.open_id
          )
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.aid, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.aid, '')
      }
    }
    if (this.open_id) {
    } else {
      this.authorize()
    }
    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }

    this.getData(1)
    this.$nextTick(() => {
      // 在冒泡阶段处理滚动的事件
      window.addEventListener('scroll', this.throttle(this.handleScroll))
    })
  },
  methods: {
    // 作品列表根据索引分列
    filterByIndex (ls, type) {
      if (type === 1) {
        return ls.filter((item, i) => {
          return i % 2 === 0
        })
      } else if (type === 2) {
        return ls.filter((item, i) => {
          return i % 2 === 1
        })
      }
    },
    // 切换目录
    checkRankMenu (type) {
      this.current_menu = type
      if (type === 'looks') {
        if (this.looks_rank.list.length) {
          this.rank_info = this.looks_rank
        } else {
          this.getRankList(this.looks_rank)
        }
      } else if (type === 'share') {
        if (this.share_rank.list.length) {
          this.rank_info = this.share_rank
        } else {
          this.$refs.rank.scrollTop = 0
          this.getRankList(this.share_rank)
        }
      }
    },
    // 滚动加载排行榜
    scrollLoadMore (e) {
      let rank = this.$refs.rank
      let sh = rank.scrollHeight,
        st = rank.scrollTop,
        ch = rank.clientHeight
      if (st + ch >= sh - 100 && !this.loading && this.rank_info.more) {
        if (this.rank_info.type === 'looks') {
          this.getRankList(this.looks_rank)
        } else if (this.rank_info.type === 'share') {
          this.getRankList(this.share_rank)
        }
      }
    },
    // 显示排行榜
    showRankList () {
      this.rank_info = this.looks_rank
      if (this.rank_info.page === 1) {
        this.getRankList(this.looks_rank)
      }

      this.show_rank = true
    },
    // 获取排行榜
    getRankList (rank) {
      if (this.aid && this.token && this.backUrl) {
        Indicator.open('加载中...')
        this.loading = true
        this.$ajax(
          'post',
          'Shop/get_shop_rank_list',
          {
            aid: this.aid,
            token: this.token,
            page: rank.page,
            type_: rank.type,
            id_type_: this.backUrl
          },
          ret => {
            if (ret.data.id == 0) {
              if (rank.type === 'looks' && rank.page === 1) {
                rank.list = ret.data.data.looks_list
              } else if (rank.type === 'share' && rank.page === 1) {
                rank.list = ret.data.data.share_list
              } else {
                rank.list = rank.list.concat(ret.data.data.list)
              }
              let len = rank.list.length // 新加的
              if (len % 10 == 0 && len != 0) {
                rank.more = true
              } else {
                rank.more = false
              }
              console.log(rank)

              rank.page++
            } else if (ret.data.id == -2) {
              rank.more = false
            } else {
              Toast('获取失败')
            }
            this.rank_info = rank
            console.log(this.rank_info)
            Indicator.close()
            this.loading = false
          }
        )
      } else {
        Toast('参数错误')
      }
    },
    // 跳转详情
    jumpToDetail (id) {
      this.$router.push({
        path: '/footprints/PrimaryScenic',
        query: {
          aid: this.aid,
          sid: id
        }
      })
    },

    // 初始化sUrl
    initSUrl () {
      this.sUrl = 'https://m.zmlxj.com/#/shop/shopList?aid=' + this.aid
    },

    // 搜索
    search () {
      this.page = 1
      this.getData()
    },
    getData (init) {
      Indicator.open()
      this.is_load = true
      if (this.aid && this.backUrl && this.token && this.open_id) {
        this.$ajax(
          'post',
          'Shop/get_shop_list',
          {
            aid: this.aid,
            id_type_: this.backUrl,
            token: this.token,
            page: this.page,
            key: this.key_word,
            open_id: this.open_id
          },
          ret => {
            if (ret.data.id == 0) {
              if (init) {
                this.info = ret.data.data
                this.activity = ret.data.data.activity // 活动结束的时间
                this.work_list = ret.data.data.list || ''
                if (!ret.data.data.list) {
                  this.load_more = false
                } else {
                  this.load_more = true
                }
                // 微信分享
                let wx_title = ret.data.data.activity.activity_name
                let wx_content = ret.data.data.activity.activity_name
                let wx_img = ret.data.data.activity.banner_img_app

                if (this.is_weixin) {
                  this.wx_share_callback(window.location.href, {
                    url: this.sUrl,
                    id_: this.aid,
                    id_type_: this.backUrl,
                    token: this.token,
                    open_id: this.open_id,
                    title: wx_title,
                    content: wx_content,
                    img: wx_img
                  })
                }

                document.title = '专题-' + ret.data.data.activity.activity_name
              } else if (this.page == 1) {
                this.work_list = ret.data.data.list
              } else {
                if (ret.data.data.list) {
                  this.work_list = this.work_list.concat(ret.data.data.list)
                }
              }
              this.page++
              this.display_list = this.work_list
              if (ret.data.data.list.length < 10) {
                this.load_more = false
              } else {
                this.load_more = true
              }
            } else if (ret.data.id == 1) {
              this.authorize()
            }

            Indicator.close()
            this.is_load = false
          }
        )
      } else {

      }
    },
    onVote (data) {
      if (this.aid && this.open_id) {
        this.is_voting = true // 正在投票
        this.$ajax(
          'post',
          'SpotsRace/vote',
          {
            aid: this.aid,
            spots_id: data.id,
            union_id: this.open_id,
            user_id: this.user_id
          },
          ret => {
            if (ret.data.id == 0) {
              Toast({
                message: ret.data.msg,
                iconClass: 'iconfont icon-chenggong',
                duration: 1000
              })
              // vue是数据驱动，直接修改show 和vote的值

              data.is_vote = 1
              data.vote_numbers++
              data.add = 1
            } else if (ret.data.id == 1) {
              this.authorize()
            } else {
              Toast({
                message: ret.data.msg,
                iconClass: 'iconfont icon-fail',
                duration: 1000
              })
            }

            this.is_voting = false // 投票完成
          }
        )
      } else {
        this.authorize()
      }
    },
    //            点击投票
    vote (data) {
      if (this.is_voting) {
        // 正在投票中
        return
      }
      if (data.is_join_shop_detail == 1) {
        // 跳转详情
        this.$router.push({
          path: '/footprints/PrimaryScenic',
          query: {
            aid: this.aid,
            sid: data.id
          }
        })
      } else {
        // 投票
        if (data.is_show == 0) {
          Toast('投票未开始')
        } else if (data.is_show == 2) {
          Toast('投票已结束')
        } else if (data.is_show == 1 && data.is_vote == 1) {
          Toast('今日已投票')
        } else if (data.is_show == 1 && data.is_vote == 0) {
          this.onVote(data)
        }
      }
    },

    //
    login () {
      if (!this.check_login()) {
        localStorage.setItem('login_url', location.hash)
        this.$router.push({ path: '/my/login', query: { log_type: 1 } })
      } else {
        this.user_id = this.check_login().user_id
      }
    },

    // 点击在家更多
    click_load_more () {
      if (this.load_more) {
        this.getData()
      }
    },

    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id == 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var open_id = this.open_id
            if (info != '') {
              if (info.url != '') {
                let url_arr = info.url.split('share_open_id')

                url = url_arr[0] + '&share_open_id=' + open_id
              } else {
                url = url + '&share_open_id=' + open_id
              }
            } else {
              url = url + '&share_open_id=' + open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id == 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=' +
        this.$route.query.aid +
        '&token=' +
        this.token +
        '&state=' +
        this.backUrl
      window.location.href = open_url
    },
    // 获取页面滚动的高度
    handleScroll () {
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight

      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      // 滚动条到底部的条件
      if (scrollTop > 0 && scrollTop + windowHeight >= scrollHeight) {
        this.showPublish = false
      } else {
        this.showPublish = true
      }

      // 搜索栏吸顶
      if (this.se_offsetTop) {
        this.search_fix_top = scrollTop >= this.se_offsetTop
      } else {
        let se_dom = this.$refs.search
        if (se_dom) {
          this.se_offsetTop = se_dom.offsetTop
          this.search_fix_top = scrollTop >= this.se_offsetTop
        } else {
          console.log('加载中')
          return
        }
      }

      // 加载更多
      if (scrollTop + windowHeight > scrollHeight - 100) {
        if (this.load_more && !this.is_load) {
          this.getData()
        }
      }
    },
    throttle (fn, interval = 100) {
      let canRun = true
      return function () {
        if (!canRun) return
        canRun = false
        setTimeout(() => {
          fn.apply(this, arguments)
          canRun = true
        }, interval)
      }
    }
  }
}
</script>
<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: ease 0.3s;
}
.bounce-enter,
.bounce-leave-to {
  opacity: 0;
  transform: translateY(10%);
}
.bounce-enter-to,
.bounce-leave {
  opacity: 1;
  transform: translateY(0);
}
.bounce-enter-active,
.bounce-leave-active {
  transition: ease 0.3s;
}
.shopList {
  /* padding-top: 2.8rem; */
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  /* padding-bottom: 2.4rem; */
  box-sizing: border-box;
}
.shopList-wrapper {
  width: 100%;
  position: relative;
}
.start-btn {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
  border: none;
  background: linear-gradient(45deg, rgba(126, 2, 2, 0.9), rgba(0, 0, 0, 0.9));
  box-shadow: 0 0 2px #ffffff33;
  padding: 10px 30px;
  font-size: 0.88rem;
  color: #fff;
  border-radius: 2em;
  font-weight: bold;
  letter-spacing: 2px;
}
/*投票规则 中富文本内容的图片*/
.popups-box .text >>> img {
  max-width: 100%;
}

.shopList .index-banner img {
  width: 100%;
  height: auto;
}

.shopList .index-info {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  border-bottom: 0.1rem solid #cdcdcd;
}

.shopList .btn_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shopList .btn {
  color: #fff;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  height: 2rem;
  line-height: 2rem;
  border-radius: 0.3rem;
  flex: 1;
  margin: 0 0.1rem;
  padding: 0 0.1rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  box-shadow: 0 0 4px #aaa;
  background: linear-gradient(to right, #ee3d52, #f5744d);
  border-bottom: none;
}

.shopList .btn.rank {
  font-size: 1rem;
  height: 3rem;
  line-height: 2.8rem;
  border-radius: 0.5rem;
  background: #e03347;
  background: linear-gradient(45deg, #f5744d, #ff6a45, #ee3d3d, #ee3d52);
  border-bottom: none;
  flex: 3;
}

.shopList .btn.rank > div {
  display: inline-block;
  vertical-align: inherit;
}

.shopList .btn.rank > div p {
  line-height: 1.2rem;
  font-size: 0.6rem;
}

.shopList .btn.rank > div p span {
  background-color: #fff;
  color: #e13046;
  display: inline-block;
  line-height: 0.8rem;
  padding: 0 0.05rem;
  margin: 0 0.05rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
}

.shopList .btn.rank i {
  font-size: 1.7rem;
  line-height: 0;
}

.shopList .index-info .box {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  display: flex;
}

.shopList .index-info .box li {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #666;
}
.shopList .index-info .box li span {
  padding: 0 6px;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

.index-search {
  position: relative;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  padding: 0.5rem 0.5rem;
  box-sizing: border-box;
}
.search-fix-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2);
  padding: 0.5rem 0.5rem;
  box-sizing: border-box;
}

.shopList .search-wrapper {
  position: relative;
  padding: 0;
  display: flex;
  text-align: center;
  width: 100%;
}
.shopList .search-wrapper i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.5rem;
  font-size: 0.8rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  color: #666;
}
.shopList .search-wrapper input {
  height: 1.8rem;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  background: #fff;
  padding: 0 0.5rem 0 2rem;
  border: 1px solid rgba(238, 51, 51, 0.3);
  border-radius: 0.15rem 0 0 0.15rem;
  color: #666;
  outline: 0;
  -webkit-appearance: none;
  border-radius: 2em 0 0 2em;
  flex: 1;
  font-size: 0.78rem;
  border-right: none;
}
.shopList .search-wrapper input:focus {
  border-color: rgba(238, 51, 51, 0.5);
}

.shopList .search-wrapper button {
  height: 1.8rem;
  line-height: 1.8rem;
  display: inline-block;
  outline: 0;
  background: linear-gradient(rgb(252, 60, 60), rgb(240, 123, 55));
  color: #fff;
  border: none;
  border-radius: 0 2em 2em 0;
  width: 4rem;
  font-size: 0.78rem;
}

.shopList .search-wrapper a {
  flex: 0.8;
}

.shopList .search-wrapper .all-photo {
  height: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  font-size: 0.7rem;
  background: linear-gradient(rgb(252, 60, 60), rgb(240, 123, 55));
  color: #fff;
  border-radius: 4px;
  text-align: center;
  margin-left: 1rem;
  box-shadow: 0 0 2px #ff9a80;
}

/* 作品列表 */
.no-work {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-work img {
  opacity: 0.4;
  width: 40%;
  margin-bottom: 1rem;
}
.no-work p {
  font-size: 0.8rem;
  color: #999;
}
.work-wrapper {
  width: 100%;
  background-color: rgb(233, 236, 237);
  padding-bottom: 3rem;
}
.work-list {
  padding: 3%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.8rem;
  width: 100%;
  box-sizing: border-box;
}
.work-list .col {
  width: 48.5%;
  overflow: visible;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.work-list .item {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 1.2rem;
  background-color: #fff;
  box-sizing: border-box;
  border: none;
  padding-bottom: 0.8rem;
}
.work-list .item:last-child {
  margin-bottom: 0;
}
.work-list .item .num {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 0.2rem 0.4rem;
  border-radius: 0;
  font-size: 0.6rem;
  color: #fff;
}

.work-list .item .pic-wrapper {
  position: relative;
  width: 100%;
}
.work-list .item .pic {
  width: 100%;
  display: block;
  border-radius: 0;
}

.work-list .item .img_num {
  position: absolute;
  bottom: 0;
  color: #fff;
  /* width: 100%; */
  left: 0;
  font-size: 0.7rem;
  background: rgba(0, 0, 0, 0.4);
  padding: 0.2rem 0.4rem;
}

.work-list .item .u-card {
  width: 100%;
  position: relative;
  color: #666;
  padding: 0.5rem;
  box-sizing: border-box;
}
.work-list .item .u-card h1 {
  font-size: 0.78rem;
  margin-bottom: 0.4rem;
  color: #000;
  text-align: justify;
  font-weight: 400;
}
.card-data {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.work-list .item .u-card p {
  width: 50%;
  font-size: 0.68rem;
  color: #444;
}
.work-list .item .u-card p .card-data-value {
  font-size: 0.8rem;
  font-weight: bold;
  color: rgb(182, 33, 33);
}

.work-list .item .u-card img.user_icon {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin: 0.4rem 0.2rem;
  border: 1px solid #ff9a80;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.work-list .item .vote.no {
  background: #ccc;
}
.work-list .item .vote {
  position: relative;
  width: 70%;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  position: absolute;
  bottom: -0.8rem;
  left: 15%;
  border-radius: 0.8rem;
  color: #fff;
  background: linear-gradient(rgb(252, 60, 60), rgb(240, 123, 55));
  font-size: 0.7rem;
}
.work-list .item .vote .add {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 0.6rem;
  font-weight: 600;
  color: #333;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.work-list .item .vote .show_add {
  animation: add 1s linear 1 both;
}
@keyframes add {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -150%);
  }
}

.shopList .photo_publish {
  position: fixed;
  bottom: 1rem;
  left: 30%;
  width: 40%;
  height: 2rem;
  margin: 0;
  font-size: 0.88rem;
  color: #fff;
  background: linear-gradient(
    45deg,
    rgba(247, 98, 13, 0.9),
    rgba(238, 51, 51, 0.9)
  );
  border-radius: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.photo_publish_hide {
  visibility: hidden;
}

/* 人数统计 */
.stats {
  margin-top: 0.5rem;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stats .stats_item {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stats .stats_item .stats_item_name {
  font-size: 0.75rem;
  color: #333;
}
.stats .stats_item .stats_item_number {
  font-size: 0.8rem;
  font-weight: 600;
  color: #666;
}

.click-load-more {
  position: relative;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  margin-bottom: 0.5rem;
  z-index: 1;
}

.click-load-more .load-more-line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.3);
  z-index: -1;
}
.click-load-more .load-more-text {
  padding: 0 4px;
  font-size: 0.68rem;
  color: hsla(0, 0%, 0%, 0.3);
  background-color: inherit;
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, 0.3);
}

/* 投票规则 */
.popups-bg {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}
.popups {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}
.popups-box {
  width: 85%;
  background-color: #fff;
  text-align: center;
  border-radius: 0.4rem;
  overflow: hidden;
  max-height: 90%;
  padding: 0.4rem;
  padding-bottom: 0.7rem;
}

.shopList .popups-box .box-header {
  position: relative;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #333;
  border-bottom: 1px solid #eee;
}
.shopList .popups-box .box-header h2 {
  font-size: 0.88rem;
  font-weight: bold;
  color: #333;
}
.popups.apply .close {
  position: absolute;
  top: 0rem;
  right: 0;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  overflow: hidden;
  background: #fff;
  border-radius: 50%;
}
.popups.apply .close i {
  font-size: 1rem;
  color: #666;
}
.shopList .popups .wrapper {
  overflow-y: auto;
  height: 80vh;
  box-sizing: border-box;
  padding-top: 0.5rem;
}
.shopList .popups .wrapper::-webkit-scrollbar {
  display: none;
}
.shopList .popups .text {
  font-size: 0.7rem;
  text-align: left;
}
.shopList .popups >>> p {
  margin: 10px 0;
  font-weight: 100;
}
.popups.apply >>> span {
  color: #fe0101;
}

.shopList .popups >>> p >>> img {
  margin: 0 auto;
}

.shopList .popups >>> h1 {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  color: #333;
  text-align: center;
  font-weight: 400;
}

.shopList .popups.apply >>> ol >>> li {
  font-size: 0.7rem;
}

/* 排行榜 */
.slider-enter,
.slider-leave-to {
  transform: translateX(100%);
}
.slider-enter-to,
.slider-leave {
  transform: translateX(0);
}
.slider-enter-active,
.slider-leave-active {
  transition: ease 0.3s;
}
.rank-wrapper {
  position: relative;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(111, 156, 252);
  padding: 0;
  box-sizing: border-box;
}
.rank-header {
  position: relative;
  height: 6rem;
  width: 100%;
  position: relative;
  /* background: linear-gradient(rgb(255, 115, 40), rgb(252, 168, 59)); */
  /* background-color: rgba(3, 77, 235, 0.65); */
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 0 0 0.8rem 0.8rem;
  overflow: hidden;
}
.rank-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(45deg, #ffffff11, #00000022);
}
.rank-header::after {
  content: "";
  position: absolute;
  width: calc(100% - 1rem);
  height: 10px;
  background: linear-gradient(#00000066, #00000011);
  bottom: calc(2rem - 5px);
  left: 0.5rem;
  border-radius: 2em;
}
.rank-menu {
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.rank-wrapper h2 {
  position: relative;
  font-size: 0.88rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  padding: 0 6px;
  transition: color 0.3s;
}
.rank-wrapper h2.rank-menu-active {
  color: #fff;
  transition: color 0.3s;
}
.rank-wrapper h2::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  transition: width 0.3s;
}
.rank-wrapper h2.rank-menu-active::after {
  width: 2.64rem;
  transition: width 0.3s;
}
.rank-wrapper .close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rank-wrapper .close i {
  font-size: 1rem;
  color: #eee;
}

/* 排行列表 */
.no-rank-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-rank-list img {
  opacity: 0.3;
  width: 40%;
  margin-bottom: 1rem;
}

.no-rank-list p {
  font-size: 0.8rem;
  color: #999;
}
.rank-content {
  width: 100%;
  height: calc(100% - 3.5rem);
  overflow-y: auto;
  padding: 0.8rem;
  padding-top: 0;
  box-sizing: border-box;
  margin-top: -2rem;
  background-color: inherit;
}
.rank-list {
  width: 100%;
  padding: 0;
}
.rank-list-item {
  position: relative;
  width: 100%;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  padding: 0.8rem 0.8rem 0.8rem 1.8rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  background-color: #6fcef6;
  overflow: hidden;
}
.rank-list-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1)
  );
}
.rank-list-item:nth-child(1) {
  background-color: #e33;
}
.rank-list-item:nth-child(2) {
  background-color: #f37e11;
}
.rank-list-item:nth-child(3) {
  background-color: #67c23a;
}
.rank-ranking::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.2) 50%, transparent 0);
  border-top-left-radius: 0.5rem;
  z-index: -1;
}
.rank-ranking {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.78rem;
  font-weight: 600;
  z-index: 1;
}
.rank-list-item .rank-item-icon {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}
.rank-item-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rank-item-info {
  position: relative;
  width: calc(100% - 3rem);
  height: 3rem;
  padding-left: 0.4rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.rank-item-info .info-name {
  height: 1.6rem;
  line-height: 1.6rem;
  font-size: 0.88rem;
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
}
.rank-item-info .info-num {
  height: 1.4rem;
  line-height: 1.4rem;
  font-size: 0.68rem;
  color: #ffffffef;
  border-radius: 2em;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
}
.rank-item-info .info-votes {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.68rem;
  padding: 2px 12px;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 2em;
}
.load-more {
  position: relative;
  height: 2rem;
  /* line-height: 2rem; */
  /* text-align: center; */
  width: 100%;
  z-index: 1;
  background-color: inherit;
}
.load-more .load-more-line {
  position: absolute;
  z-index: 0;
  width: 40%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.3);
  top: 50%;
  left: 30%;
}
.load-more .load-more-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: hsla(0, 0%, 0%, 0.3);
  font-size: 0.68rem;
  background-color: inherit;
  padding: 0 4px;
  z-index: 1;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.3);
}
</style>
