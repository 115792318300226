<template>
  <div class="tl-body">
    <header class="header home fixed">
      <div class="LOGO_box">
        <img src="../../assets/img/map_ticket_selected.png" alt class="LOGO" />
      </div>
      <router-link
        :to="{ path: '/SearchHome/SearchIndex/',query:{type_:4,token:'zmlxj_201808012',is_ticket:1}}"
        class="search_img"
      >
        <img src="../../assets/img/search-bg.png" alt />
      </router-link>
    </header>

    <!--4景区公园和省份-->
    <div class="park-ticket-list" v-if=" list != '' ">
      <div class="list">
        <div class="item" v-for="(k,i) in list" :key="i">
          <router-link
            :to="{path:'/Ticket/TicketList',query:{type_:'park_ticket',id:k.id,token:'zmlxj_2018',title:k.park_name}}"
          >
            <div class="img" :class="i % 2 > 0 ? 'park_margin_left':''">
              <img :src="k.main_img" />
            </div>
          </router-link>

          <div class="name" :class="i % 2 > 0 ? 'park_margin_left':''">
            <span>{{k.park_name}}</span>
          </div>
        </div>
      </div>
    </div>

    <!--点击加载更多按钮-->
    <div class="click-load-more" @click="click_load_more">
      <div class="load-more-text">点击加载更多</div>
      <div class="load-more-img">
        <img src="../../assets/img/click-load-more.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";

export default {
  components: {},
  data() {
    return {
      id: this.$route.query.id ? this.$route.query.id : 0,
      lng: this.$route.query.lng ? this.$route.query.lng : "",
      lat: this.$route.query.lat ? this.$route.query.lat : "",
      type_: this.$route.query.type_,
      list: [],
      title: "票务信息",
      page: this.$route.query.type_ == "park_ticket" ? 1 : 2
    };
  },
  mounted() {
    //请求数据
    this.get_server_data(this.page, this.type_, this.id, this.lng, this.lat);
  },
  methods: {
    //请求数据
    get_server_data(page_, type_, id, lng, lat) {
      //根据type_的不通知请求不同的接口
      var url = "";
      if (type_ == "near_ticket") {
        //请求附近的景点
        url = "Ticket/get_near_ticket_list";
      } else if (type_ == "hot_ticket") {
        //请求热门景点
        url = "Ticket/get_hot_ticket_list";
      } else if (type_ == "park") {
        //请求公园列表
        url = "Ticket/get_park_list";
      } else if (type_ == "park_ticket") {
        //请求某一个公园的票务
        url = "Ticket/get_park_ticket_list";
      }
      this.$ajax(
        "post",
        url,
        { id: id, page: page_, token: "zmlxj_2018", lng: lng, lat: lat },
        ret => {
          console.log(ret);
          if (ret.data.id == 0) {
            this.list = ret.data.data;
          } else {
            Toast("无数据");
          }
        }
      );
    },
    //加载更多
    click_load_more() {
      var url = "";
      if (this.type_ == "near_ticket") {
        //请求附近的景点
        url = "Ticket/get_near_ticket_list";
      } else if (this.type_ == "hot_ticket") {
        //请求热门景点
        url = "Ticket/get_hot_ticket_list";
      } else if (this.type_ == "park") {
        //请求公园列表
        url = "Ticket/get_park_list";
      } else if (this.type_ == "park_ticket") {
        //请求某一个公园的票务
        url = "Ticket/get_park_ticket_list";
      }
      var page_ = ++this.page;
      var id = this.id;
      var lng = this.lng;
      var lat = this.lat;
      this.$ajax(
        "post",
        url,
        { id: id, page: page_, token: "zmlxj_2018", lng: lng, lat: lat },
        ret => {
          //console.log(ret);
          if (ret.data.id == 0) {
            this.list = this.list.concat(ret.data.data);
          } else {
            Toast("无数据");
          }
        }
      );
    }
  }
};
</script>

