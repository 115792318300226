<template>
  <div class="container">
    <div class="start" v-show="showStart">
      <div class="start-bg">
        <img :src="startBgUrl" class="img" />
      </div>
      <div class="start-btn">
        <button class="btn" @click="enterAlbum">进入相册</button>
      </div>
    </div>
    <div class="main" v-show="!showStart">
      <div class="banner">
        <img :src="activity.banner_img_app || ''" class="img" />
      </div>
      <div class="header">
        <h1 class="title">{{activity.activity_name || ''}}</h1>
        <div class="subtitle">
          <div class="look">
            <span class="number">{{activity.looks_numbers || ''}}</span>
            <span class="text">次照片浏览</span>
          </div>
          <div class="detail-btn" @click="handleShowDetail">
            <span class="text">活动详情</span>
            <i class="iconfont iconqianjin"></i>
          </div>
        </div>
        <div class="address">
          <p>{{activity.activity_city || ''}}·{{activity.address || ''}}</p>
        </div>
      </div>

      <div class="content">
        <ul class="menu-list">
          <li :class="['item', activeMenu === 1 ? 'active' : '']" @click="toggleMenu(1)">
            <span class="text">照片直播</span>
          </li>
          <li :class="['item', activeMenu === 2 ? 'active' : '']" @click="toggleMenu(2)">
            <span class="text">时间轴</span>
          </li>
          <li :class="['item', activeMenu === 3 ? 'active' : '']" @click="toggleMenu(3)">
            <span class="text">热门</span>
          </li>
        </ul>
        <!-- 直播 -->
        <ul class="img-list live-list" v-show="activeMenu === 1">
          <li class="item" v-for="(item, i) in liveList" :key="i">
            <img :src="item.ImageUrl" class="img activity-img" @click="handleShowPreview(i)" />
          </li>
        </ul>
        <!-- 时间轴 -->
        <ul class="img-list time-list" v-show="activeMenu === 2">
          <li class="item" v-for="(item, i) in timeList" :key="i">
            <div class="time">
              <span class="text">{{item.time}}</span>
            </div>
            <ul class="sublist">
              <li class="subitem" v-for="(it,idx) in item.ls" :key="idx">
                <img
                  :src="it.ImageUrl"
                  class="img activity-img"
                  @click="handleShowPreview(it.index)"
                />
              </li>
            </ul>
          </li>
        </ul>
        <!-- 热门 -->
        <ul class="img-list hot-list" v-show="activeMenu === 3">
          <li class="item" v-for="(item, i) in hotList" :key="i">
            <span :class="['rank', i < 3 ? 'rank3' : '']" v-if="i < 10">{{i + 1}}</span>
            <img
              :src="i < 2 ? item.DownloadImageUrl : item.ImageUrl"
              class="img activity-img"
              @click="handleShowPreview(i)"
            />
            <div class="praise">
              <i class="iconfont iconxihuan"></i>
              <span class="text">{{item.PraiseQty}}</span>
            </div>
          </li>
        </ul>
      </div>
      <!-- 活动详情 -->
      <div class="mask" v-if="showDetail" @click="showDetail=false"></div>
      <div class="activity-detail" v-if="showDetail">
        <div class="tag">活动详情</div>
        <div class="wrapper">
          <p class="title">{{activity.activity_name}}</p>
          <p class="subtitle">时间：{{activity.activity_begin_time}}</p>
          <p class="subtitle">城市：{{activity.activity_city}}</p>
          <div class="qrcode">
            <img :src="activity.qr_album_code" class="img" />
            <p class="text">分享朋友圈 扫码看相册</p>
          </div>
        </div>
        <div class="close" @click="showDetail=false">
          <i class="iconfont iconclose"></i>
        </div>
      </div>
      <!-- 大图预览 -->
      <div class="preview" v-if="showPreview">
        <div class="preview-header">
          <div class="tag">
            <i class="iconfont icontip1"></i>
            <span class="text">长按照片保存</span>
          </div>
          <div class="close" @click="showPreview = false">
            <i class="iconfont iconclose"></i>
          </div>
        </div>

        <swiper class="swiper-container" :options="swiperOption()" ref="mainSwiper">
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
      <!-- 发布 -->
      <div class="publish fixed-btn" @click="toPublish">
        <span class="text">发布</span>
      </div>
      <!-- 回到顶部 -->
      <div class="back-top fixed-btn" v-show="showToTop" @click="scrollToTop">
        <i class="iconfont iconUp"></i>
      </div>
      <div class="footer">
        <img src="../../assets/switch/white_tempBg.jpg" class="img" />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator } from "mint-ui";
import { Swiper } from "swiper";

export default {
  components: {
    Swiper,
    //swiperSlide
  },
  data() {
    return {
      // 接口
      backUrl: "album",
      park_id: 298,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      aid: this.$route.query.aid ? this.$route.query.aid : 0,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      version: "1.0.0",
      open_id: localStorage.getItem("open_id")
        ? localStorage.getItem("open_id")
        : "",
      // 自定义
      swiper: null,
      showPreview: false,
      activeIndex: 0,
      showStart: true,
      livePage: 1,
      isLoading: false,
      haveMoreLive: true,
      activeMenu: 1,
      showToTop: false,
      haveMoreTime: true,
      timePage: 1,
      haveMoreHot: true,
      hotPage: 1,
      showDetail: false,
      // 获取数据
      activity: {},
      startBgUrl: "",
      companyName: "",

      totalCount: "",
      liveList: [],
      timeList: [],
      preTimeList: [],
      hotList: []
      // swiper
    };
  },

  mounted() {
    if (this.token) {
    } else {
      //第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    console.log("token=" + this.token);
    //投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有union_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }

    this.dealWithUrl(window.location.href);
    /* 微信分享 */
    if (this.is_weixin && !this.open_id) {
      this.authorize();
    }

    /* 获取数据 */
    this.getData();
    this.$nextTick(() => {
      //在冒泡阶段处理滚动的事件
      window.addEventListener("scroll", this.throttle(this.handleScroll));
    });
  },
  computed: {
    currentPreviewList: function() {
      if (this.activeMenu === 1) {
        return this.liveList;
      } else if (this.activeMenu === 2) {
        return this.preTimeList;
      } else if (this.activeMenu === 3) {
        return this.hotList;
      } else {
        return false;
      }
    }
  },
  methods: {
    // 去发布
    toPublish() {
      this.$router.push({
        path: "/album/publishPhoto",
        query: {
          aid: this.aid,
          activity_name: this.activity.activity_name,
          back: this.back
        }
      });
    },
    // swiper 选项设置
    swiperOption() {
      return {
        speed: 300,
        loop: false,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        virtual: {
          // 虚拟slide，提高性能
          slides: (() => {
            const slides = [];
            for (
              let i = 0, len = this.currentPreviewList.length;
              i < len;
              i += 1
            ) {
              slides.push(`<img src="${this.currentPreviewList[i].BigImageUrl}" class="img" />
            <div class="info">
              <div class="user">
                <img src="${this.currentPreviewList[i].CreateUserIcon}" class="icon" />
                <div class="marker"> 
                <span class="nick">${this.currentPreviewList[i].CreateUserName}</span>
                <span class="time">${this.currentPreviewList[i].ShootTime}</span>
                </div>
              </div>
              <div class="handle">
                <i class="iconfont iconlike3"></i>
                <span class="praise">${this.currentPreviewList[i].PraiseQty}</span>
              </div>
            </div>`);
            }
            return slides;
          })()
        }
      };
    },
    // 显示预览图
    handleShowPreview(i) {
      this.showPreview = true;
      this.$nextTick(() => {
        this.swiper = this.$refs.mainSwiper.swiper;
        this.swiper.slideTo(i, 0);
        this.swiper.on("slideChange", () => {
          this.activeIndex = this.swiper.activeIndex;
        });
        this.swiper.on("tap", () => {
          this.showPreview = false;
        });
      });
    },
    // 进入相册
    enterAlbum() {
      this.showStart = false;
    },

    // 显示活动详情
    handleShowDetail() {
      this.showDetail = true;
    },
    /**
     * 切换目录
     * @param {number} n 1：直播，2：时间轴，3：热门
     */
    toggleMenu(n) {
      this.activeMenu = n;
      if (n === 2 && this.timePage === 1) {
        this.getTimeList();
      } else if (n === 3 && this.hotPage === 1) {
        this.getHotList();
      }
    },
    // 活动数据
    getData() {
      if (this.aid && this.token) {
        this.$ajax(
          "post",
          "ForOther/get_activity_detail",
          {
            aid: this.aid,
            token: this.token
          },
          res => {
            if (res.data.id === 0) {
              this.activity = { ...res.data.data.Activity };
              this.startBgUrl = res.data.data.ImageUrl;
              this.companyName = res.data.data.CompanyName;
              document.title = this.activity.activity_name;
              // 微信分享
              const wx_title = this.activity.activity_name,
                wx_content = this.activity.activity_name,
                wx_img = this.activity.banner_img_app,
                url = window.location.href;
              if (this.is_weixin) {
                this.wx_share_callback(url, {
                  url: url,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                });
              }
            }
          }
        );

        this.getLiveList();
      } else {
        console.log("参数错误");
      }
    },
    // 获取热门
    getHotList() {
      if (this.isLoading || !this.haveMoreHot) {
        return false;
      }
      this.isLoading = true;
      Indicator.open("加载中...");
      const url =
        "ForOther/get_image_list?aid=" +
        this.aid +
        "&token=" +
        this.token +
        "&where=hot" +
        "&page=" +
        this.hotPage +
        "&open_id=" +
        this.open_id;
      this.$ajax("get", url, null, res => {
        if (res.data.id === 0) {
          //   this.hotList.push(...res.data.data.list);
          this.hotList.push(...this.sortByKey(res.data.data.list, "PraiseQty"));
          this.hotPage++;
        }
        Indicator.close();
        this.isLoading = false;
      });
    },
    /**
     * 根据指定key对数组进行排序
     */
    sortByKey(ls, key) {
      return ls.sort((item1, item2) => {
        return item2[key] - item1[key];
      });
    },
    // 获取时间轴
    getTimeList() {
      if (this.isLoading || !this.haveMoreTime) {
        return false;
      }
      this.isLoading = true;
      Indicator.open("加载中...");
      const url =
        "ForOther/get_image_list?aid=" +
        this.aid +
        "&token=" +
        this.token +
        "&where=time" +
        "&page=" +
        this.timePage +
        "&open_id=" +
        this.open_id;
      this.$ajax("get", url, null, res => {
        if (res.data.id === 0) {
          let date;
          if (this.timePage === 1) {
            date = "";
          } else {
            let len = this.timeList.length;
            date = this.timeList[len - 1].ls[0].ShootTime.split(" ")[0];
          }
          res.data.data.list.forEach((item, i) => {
            let shootDate = item.ShootTime.split(" ")[0];
            let len = this.timeList.length;

            if (shootDate == date) {
              this.timeList[len - 1].ls.push(item);
            } else {
              let obj = {};
              Object.assign(obj, { idx: len, ls: [] });
              obj.ls.push(item);
              // idx++
              date = shootDate;
              this.timeList.push(obj);
            }
          });
          this.timeList.forEach(item => {
            let startDateList = item.ls[item.ls.length - 1].ShootTime.split(
              " "
            );
            let endDateList = item.ls[0].ShootTime.split(" ");
            let date = startDateList[0];
            let startHour = startDateList[1].split(":")[0];
            let endHour = endDateList[1].split(":")[0] * 1 + 1;
            endHour = endHour > 9 ? endHour + "" : "0" + endHour;
            item.time = date + " " + startHour + ":00 ~ " + endHour + ":00";
          });
          // console.log(this.timeList);
          this.timePage++;
          this.preTimeList.push(...res.data.data.list);
          if (!res.data.data.list || res.data.data.list.length < 20) {
            this.haveMoreTime = false;
          }
        } else {
          this.haveMoreTime = false;
        }
        this.isLoading = false;
        Indicator.close();
      });
    },
    // 获取相册列表
    getLiveList() {
      if (this.isLoading || !this.haveMoreLive) {
        return false;
      }
      this.isLoading = true;
      Indicator.open("加载中...");
      const url =
        "ForOther/get_image_list?aid=" +
        this.aid +
        "&token=" +
        this.token +
        "&where=all" +
        "&page=" +
        this.livePage;
      this.$ajax("get", url, null, res => {
        if (res.data.id === 0) {
          this.liveList.push(...res.data.data.list);
          if (!this.totalCount) {
            this.totalCount = res.data.data.TotalCount;
          }
          this.livePage++;

          if (!res.data.data.list || res.data.data.list.length < 20) {
            this.haveMoreLive = false;
          }
        } else {
          this.haveMoreLive = false;
        }
        this.isLoading = false;
        Indicator.close();
      });
    },

    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=" +
        this.token +
        "&id=" +
        this.aid +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    },
    //获取页面滚动的高度
    handleScroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;

      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop > 400) {
        this.showToTop = true;
      } else {
        this.showToTop = false;
      }

      // 加载更多
      if (scrollTop + windowHeight > scrollHeight - 100) {
        if (this.activeMenu === 1) {
          this.getLiveList();
        } else if (this.activeMenu === 2) {
          this.getTimeList();
        }
      }
    },
    throttle(fn, interval = 100) {
      let canRun = true;
      return function() {
        if (!canRun) return;
        canRun = false;
        setTimeout(() => {
          fn.apply(this, arguments);
          canRun = true;
        }, interval);
      };
    }
  }
};
</script>

<style scoped>
.container {
  width: 100vw;
  min-height: 100vh;
}

.iconfont {
  vertical-align: middle;
}

/* 启动页 */
.start {
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  overflow: hidden;
}

.start > .start-bg {
  width: 100%;
  height: 100%;
  animation: scaledown 0.5s ease 0.5s 1 both;
}

@keyframes scaledown {
  from {
    opacity: 0;
    transform: scale(1.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.start > .start-bg > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.start > .start-btn {
  position: fixed;
  top: 74%;
  left: 50%;
  transform: translateX(-50%);
}

.start > .start-btn > .btn {
  outline: none;
  border: none;
  padding: 8px 24px;
  border: 1px solid #fff;
  font-size: 0.88rem;
  border-radius: 2em;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: -1px 2px 2px -1px #000;
  color: #fff;
  animation: fade 0.5s linear 0.5s 1 both;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* main */
.main {
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
}

.banner {
  width: 100%;
}

.banner > .img {
  width: 100%;
}

.header {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.header > .title {
  font-size: 1rem;
  text-align: justify;
}

.header > .subtitle {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  font-size: 0.72rem;
}

.header > .subtitle > .look > .number {
  color: #777;
}

.header > .subtitle > .detail-btn > .iconfont {
  color: #999;
  font-size: 0.72rem;
}

.header > .address {
  margin-top: 0.5rem;
  color: #576b95;
  font-size: 0.72rem;
}

/* content */
.content {
  padding: 1rem 0 0;
}

.menu-list {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  font-size: 0.74rem;
  color: #666;
}

.menu-list > .item {
  margin-right: 1rem;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid transparent;
}

.menu-list > .item.active {
  font-weight: bold;
  color: #444;
  border-color: #05adc4;
}

/* 图片列表 */

.img-list {
  padding: 1rem 1vw;
  display: flex;
  flex-wrap: wrap;
}

.activity-img {
  animation: scale-up 0.5s ease 1 both;
}

@keyframes scale-up {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

/* 直播列表 */

.live-list > .item {
  width: 32vw;
  height: 32vw;
  margin-top: 1vw;
}

.live-list > .item:nth-child(3n + 2) {
  margin: 1vw 1vw 0;
}

.live-list > .item > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 时间轴 */
.time-list {
  display: flex;
  flex-direction: column;
}

.time-list > .item:not(:first-child) {
  margin-top: 1rem;
}

.time-list > .item > .time {
  position: relative;
  padding-left: 8px;
  font-size: 0.8rem;
  color: #666;
}

.time-list > .item > .time::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 3px;
  height: 0.7rem;
  background-color: #999;
}

.sublist {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
}

.sublist > .subitem {
  width: 24vw;
  height: 24vw;
  margin-top: 0.5vw;
}

.sublist > .subitem:nth-child(4n + 1) {
  margin-right: 0.5vw;
}

.sublist > .subitem:nth-child(4n + 2) {
  margin-right: 0.5vw;
}

.sublist > .subitem:nth-child(4n + 3) {
  margin-right: 0.5vw;
}

.sublist > .subitem > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 热门 */

.hot-list {
  display: block;
}

.hot-list > .item {
  position: relative;
  float: left;
  width: 32vw;
  height: 32vw;
  margin-top: 1vw;
}

.hot-list > .item:nth-child(1) {
  width: 100%;
  height: 60vw;
}

.hot-list > .item:nth-child(2) {
  margin-right: 1vw;
  width: 65vw;
  height: 65vw;
}

.hot-list > .item > .rank {
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem;
  font-size: 0.88rem;
  padding: 0.2rem;
  color: #fff;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.8) 50%,
    transparent 0
  );
  z-index: 1;
}

.hot-list > .item > .rank3 {
  background: linear-gradient(
    135deg,
    rgb(255, 194, 55),
    rgb(255, 194, 55) 50%,
    transparent 0
  );
}

.hot-list > .item:nth-child(3n + 6) {
  margin: 1vw 1vw 0;
}

.hot-list > .item > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hot-list > .item > .praise {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  border-radius: 2em;
  font-size: 0.68rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}

.hot-list > .item > .praise > .iconfont {
  margin-right: 4px;
  color: #fff;
  font-size: 0.68rem;
}

/* 回到详情 */

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.activity-detail {
  position: fixed;
  top: 15vh;
  left: 10vw;
  width: 80vw;
  padding: 1.5rem;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 2;
}

.activity-detail > .tag {
  width: 5.37rem;
  height: 2.6rem;
  margin-top: -1.5rem;
  margin-left: -1.89rem;
  background: url("../../assets/switch/title01.png") 0 0 no-repeat;
  background-size: 5.37rem;
  text-align: center;
  line-height: 2.6rem;
  font-size: 0.8rem;
  color: #fff;
}

.activity-detail > .tag > .img {
  width: 100%;
}

.activity-detail > .tag > .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-size: 0.8rem;
  color: #fff;
}

.activity-detail > .wrapper {
  display: flex;
  flex-direction: column;
}

.activity-detail > .wrapper > .title {
  padding: 0.5rem 0;
  font-size: 0.98rem;
  text-align: justify;
}

.activity-detail > .wrapper > .subtitle {
  margin-top: 0.5rem;
  font-size: 0.7rem;
  color: #666;
  text-indent: 1em;
}

.activity-detail > .wrapper > .qrcode {
  margin-top: 1rem;
  text-align: center;
  color: #666;
  font-size: 0.7rem;
}

.activity-detail > .wrapper > .qrcode > .img {
  width: 6.8rem;
}

.activity-detail > .close {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.6rem;
  width: 1.6rem;
  text-align: center;
  line-height: 1.6rem;
  border-radius: 50%;
  border: 2px solid #ddd;
}

.activity-detail > .close > .iconfont {
  font-size: 0.8rem;
  color: #ddd;
}

/* 回到顶部 */
.back-top {
  position: fixed;
  right: 1rem;
  bottom: 2rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.back-top > .iconfont {
  font-size: 1rem;
  color: #333;
}

/* 回到顶部 */
.back-top {
  position: fixed;
  right: 1rem;
  bottom: 2rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.back-top > .iconfont {
  font-size: 1rem;
  color: #333;
}

/* footer */
.footer {
  width: 100%;
}
.footer > .img {
  display: block;
  width: 100%;
}

/* 预览 */

.preview {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 10;
  overflow-y: hidden;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-container >>> .swiper-slide {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  color: #fff;
}

.swiper-container >>> .swiper-slide .img {
  width: 100vw;
  max-height: 100vh;
  object-fit: cover;
}

.preview-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#000, rgba(0, 0, 0, 0));
  z-index: 2;
}

.preview-header > .tag {
  font-size: 0.68rem;
  color: #fff;
}

.preview-header > .tag > .iconfont {
  font-size: 0.68rem;
  color: #fff;
}

.preview-header > .close {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  height: 2rem;
  width: 2rem;
  text-align: center;
  line-height: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.preview-header > .close > .iconfont {
  font-size: 0.8rem;
  vertical-align: middle;
  color: #fff;
}

.swiper-container >>> .swiper-slide .info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3rem;
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: linear-gradient(rgba(0, 0, 0, 0), #000);
  z-index: 2;
}

.swiper-container >>> .swiper-slide .info > .user {
  flex: 1;
  display: flex;
  align-items: center;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.68rem;
}

.swiper-container >>> .swiper-slide .info > .user > .icon {
  display: block;
  margin-right: 8px;
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #eee;
}

.swiper-container >>> .swiper-slide .info > .user > .marker {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.swiper-container >>> .swiper-slide .info > .handle {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.68rem;
}

.swiper-container >>> .swiper-slide .info > .handle > .iconfont {
  color: #fff;
  font-size: 0.8rem;
}

.swiper-container >>> .swiper-button-prev,
.swiper-container >>> .swiper-button-next {
  color: rgba(255, 255, 255, 0);
}

/* 固定定位按钮 */
.fixed-btn {
  position: fixed;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* 发布 */
.publish {
  bottom: 4.4rem;
  font-size: 0.66rem;
  font-weight: bold;
  color: #333;
}

/* 回到顶部 */
.back-top {
  bottom: 2rem;
}

.back-top > .iconfont {
  font-size: 1rem;
  color: #333;
}
</style>