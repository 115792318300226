<template>
  <div class="contact_us">
    <!-- 样式1 -->
    <div
      class="contact_us_content"
      :class="show_contact ? 'contact_us_content_active' : ''"
      @click="rightClickTel(isShowTelMe)"
      v-if="contact_side=='right'"
    >
      <span>联系我们</span>
    </div>

    <!-- 样式2 -->
    <div class="contact_left" v-if="contact_side=='left'">
      <div class="mak_tab">
        <img src="../assets/map/tel.png" @click="ClickTel(isShowTelMe)" />
      </div>
    </div>

    <!-- 样式3 -->
    <div class="contact_3" v-if="contact_side=='bottom'" @click="ClickTel(isShowTelMe)">
      <img src="../assets/switch/books_phone.png" alt />
    </div>
    <!-- 样式4 -->
    <div class="contact_4" v-if="contact_side=='text'" @click="ClickTel(isShowTelMe)">
      <span>{{text ? text : company ? company.title : "武汉地普游科技提供技术支持"}}</span>
    </div>
    <!-- 样式4 -->
    <div class="contact-new" v-if="contact_side=='new'" @click="ClickTel(isShowTelMe)">
      <span>{{text ? text : company ? company.title : "武汉地普游科技提供技术支持"}}</span>
    </div>
    <!-- 样式5 -->
    <div class="contact_5" v-if="contact_side=='top'" @click="ClickTel(isShowTelMe)">
      <img src="../assets/switch/books_phone.png" alt />
    </div>
    <!-- 样式6 -->
    <div class="contact_6" v-if="contact_side=='feedback'" @click="ClickTel(isShowTelMe)">
      <i class="iconfont iconfankui"></i>
      <!-- <p>反馈</p> -->
    </div>

    <!--显示联系我的页面-->
    <transition name="contact">
      <div
        class="tel-body zindex_leaflet_1100"
        v-if="isShowTelMe"
        @click.self="isShowTelMe = 0"
      >
        <div class="tel-us zindex_leaflet_1100" v-if="isShowTelMe">
          <div class="tm-head">
            <div class="tmh-text">
              <span v-if="is_show_input == 0">建议与反馈</span>
              <span v-else-if="is_show_input == 1">地图问题</span>
              <span v-else-if="is_show_input == 2">语音问题</span>
              <span v-else-if="is_show_input == 3">功能问题</span>
              <span v-else-if="is_show_input == 4">其他问题</span>
            </div>
            <div class="tmh-backup" @click="is_show_input=0" v-show="is_show_input">
              <img src="../assets/img/back.png" alt />
              <span>返回</span>
            </div>
            <div class="tmh-del" @click="ClickTel(isShowTelMe)">
              <img src="../assets/img/del.png" />
            </div>
          </div>
          <div v-if="is_show_input == 0" class="tm-tip">
            <span>使用中遇到问题?请联系我们</span>
          </div>
          <div v-if="is_show_input == 0" class="tm-body">
            <div class="tmb-item" @click="ClickItemTelMe(1)">
              <div class="tmb-item-img">
                <img src="../../static/map/DiTu.png" />
              </div>
              <div class="tmb-item-text">
                <span>地图问题</span>
              </div>
            </div>
            <div class="tmb-item" @click="ClickItemTelMe(2)">
              <div class="tmb-item-img">
                <img src="../../static/map/audio.png" />
              </div>
              <div class="tmb-item-text">
                <span>音频问题</span>
              </div>
            </div>
            <div class="tmb-item" @click="ClickItemTelMe(3)">
              <div class="tmb-item-img">
                <img src="../../static/map/function.png" />
              </div>
              <div class="tmb-item-text">
                <span>功能问题</span>
              </div>
            </div>
            <div class="tmb-item" @click="ClickItemTelMe(4)">
              <div class="tmb-item-img">
                <img src="../../static/map/other_.png" />
              </div>
              <div class="tmb-item-text">
                <span>其他问题</span>
              </div>
            </div>
          </div>
          <div class="tm-input" v-if="is_show_input > 0">
            <div class="tmi-tel">
              <div class="tmit-tel">
                <span>手机号码:</span>
              </div>
              <div class="tmi-input">
                <input
                  v-model="input_tel"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  placeholder="请输入手机号码"
                  :style="'color:' + input_color"
                />
              </div>
            </div>
            <div class="tmi-tel">
              <div class="tmit-tel">
                <span>描述:</span>
              </div>
              <div class="tmi-textarea">
                <textarea v-model="input_content" rows="5" placeholder="请写下你的宝贵意见,我们一定认真改进呢!"></textarea>
              </div>
            </div>
          </div>
          <a :href="'tel:' + tel_" v-if="is_show_input == 0" class="tm-tel">
            <span>
              <i class="iconfont iconphonenew"></i>
              联系客服
            </span>
          </a>
          <div v-if="is_show_input > 0" class="tm-tel" @click="ClickSubmit">
            <span>提交</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Toast } from 'mint-ui'
export default {
  props: {
    ts_type_: {
      type: String
    },
    contact_side: {
      type: String
    },
    id: {
      type: [String, Number]
    },
    company: {
      type: [String, Object]
    },
    text: {
      type: String,
      default: '武汉地普游科技提供技术支持'
    }
  },
  data () {
    return {
      user_id: this.$route.query.user_id ? this.$route.query.user_id : '', // 用户的ID
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      park_id: 298,
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',

      isShowTelMe: 0,
      is_show_input: 0,
      input_content: '', // 客户留下的语言
      input_tel: '', // 客户留下的电话
      input_color: '#666',
      // tel_: 13317130676, // 阿苏
      show_contact: false // 显示联系我们
    }
  },
  computed: {
    tel_: function () {
      return this.company && this.company.tel_
        ? this.company.tel_
        : 13317130676
    }
  },

  watch: {
    input_tel: function (val) {
      this.input_tel = val.replace(/^0/g, '')
      if (val && (val.length < 11 || val.length > 11)) {
        this.input_color = '#e33'
      } else if (val.length === 11) {
        this.input_color = '#666'
      }
    }
  },
  methods: {
    rightClickTel (isShowTelMe) {
      if (this.show_contact) {
        // this.show_contact = false;
        this.isShowTelMe = 1
      } else {
        this.show_contact = true
        setTimeout(() => {
          this.show_contact = false
        }, 3000)
      }
    },
    // 显示联系我们
    ClickTel (isShowTelMe) {
      if (this.company) {
        if (this.company.url_ && this.company.url_ !== '') {
          window.location.href = this.company.url_
        } else if (!this.company.url_ || this.company.tel_ !== '') {
          if (this.isShowTelMe) {
            this.isShowTelMe = 0
          } else {
            this.isShowTelMe = 1
          }
          this.is_show_input = 0
        } else {
          console.log('没有信息')
          return false
        }
      } else {
        if (this.isShowTelMe) {
          this.isShowTelMe = 0
        } else {
          this.isShowTelMe = 1
        }
        this.is_show_input = 0
      }
    },

    // 选择菜单
    ClickItemTelMe (index) {
      this.is_show_input = index
    },
    ClickSubmit () {
      // console.log(this.input_tel+this.input_content);
      var telFlag = this.input_tel.match(
        /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/
      )
      if (telFlag) {
        if (this.input_content) {
          this.$ajax(
            'post',
            'DrawMap/save_advert_for_draw_map',
            {
              id: this.id,
              park_id: this.park_id,
              token: this.token,
              version: this.version,
              tel: this.input_tel,
              content: this.input_content,
              type_: this.ts_type_
            },
            ret => {
              if (ret.data.id === 0) {
                Toast('提交成功,感谢你宝贵的意见!')
              } else {
                Toast('提交失败')
              }
              this.is_show_input = 0
            }
          )
        } else {
          Toast('请输入内容')
        }
      } else {
        Toast('请输入正确的手机号')
        console.log('1111')
      }
    }
  }
}
</script>

<style scoped>
.contact_us_content {
  position: fixed;
  bottom: 33%;
  right: -40px;
  /* width: 6rem; */
  /* height: 3rem; */
  font-size: 12px;
  padding: 4px;
  padding-left: 8px;
  color: #fff;
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
  background-color: rgb(239, 79, 79);
  z-index: 2;
  transition: ease 0.3s;
}
.contact_us_content_active {
  right: 0;
  transition: ease 0.3s;
}
.contact-enter,
.contact-leave-to {
  opacity: 0;
  transform: translateY(10vh);
}
.contact-enter-to,
.contact-leave {
  opacity: 1;
  transform: translateY(0);
}
.contact-enter-active,
.contact-leave-active {
  transition: ease 0.5s;
}
.tel-body {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;

  height: 120vh;
  position: fixed;
  top: -10vh;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.tel-us {
  background-color: white;
  width: 70%;
  z-index: 1;
  border-radius: 10px;
}
.tel-us .tm-head {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 2.4rem;
  border-bottom: 1px solid #eee;
}
.tel-us .tm-head .tmh-text {
  height: 100%;
  line-height: 2.4rem;
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: center;
}
.tel-us .tm-head .tmh-text span {
  display: inline-block;
  font-size: 1rem;
  text-align: center;
  color: #333;
}
.tel-us .tm-head .tmh-backup {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 0.6rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tel-us .tm-head .tmh-backup img {
  display: block;
  height: 0.8rem;
}
.tel-us .tm-head .tmh-backup span {
  color: #666;
}
.tel-us .tm-head .tmh-del {
  position: absolute;
  height: 2.4rem;
  right: 0;
  top: 0;
}
.tel-us .tm-head .tmh-del img {
  width: 2rem;
  height: 2rem;
  margin-top: 0.2rem;
  opacity: 0.6;
}
.tel-us .tm-tip {
  display: inline-block;
  position: relative;
  width: 100%;
}
.tel-us .tm-tip span {
  display: block;
  padding: 10px 0px 10px 0px;
  font-size: 0.6rem;
  text-align: center;
  color: #ababab;
}
.tel-us .tm-body {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #eee;
  padding-top: 5%;
  padding-bottom: 5%;
}
.tel-us .tm-body .tmb-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  /* float: left; */
}
.tel-us .tm-body .tmb-item .tmb-item-img {
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: center;
}
.tel-us .tm-body .tmb-item .tmb-item-img img {
  width: 45px;
  height: 45px;
  margin: 0;
}
.tel-us .tm-body .tmb-item .tmb-item-text {
  display: inline-block;
  position: relative;
  width: 100%;
}
.tel-us .tm-body .tmb-item .tmb-item-text span {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 10px 5px 10px;
  font-size: 0.8rem;
  text-align: center;
  color: #666;
}
.tel-us .tm-tel {
  display: inline-block;
  position: relative;
  width: 60%;
  margin-left: 20%;
  margin-top: 5%;
  margin-bottom: 5%;
}
.tel-us .tm-tel span {
  display: block;
  padding: 5px 10px 5px 10px;
  font-size: 1rem;
  background-color: #ff9138;
  color: white;
  text-align: center;
  border-radius: 4px;
}

.tel-us .tm-input {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.5rem;
  border-bottom: 1px solid #eee;
}
.tel-us .tm-input .tmi-tel {
  display: inline-block;
  position: relative;
  width: 100%;
  /* border-bottom: 1px solid #eee; */
  padding-bottom: 1rem;
}
.tel-us .tm-input .tmi-tel .tmit-tel {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  position: relative;
  width: 100%;
}
.tel-us .tm-input .tmi-tel .tmit-tel span {
  /* display: block; */
  /* padding: ; */
  font-size: 0.88rem;
  color: #333;
}
.tel-us .tm-input .tmi-input {
  display: inline-block;
  position: relative;
  width: 100%;
}
.tel-us .tm-input .tmi-input input {
  display: inline-block;
  box-sizing: border-box;
  height: 2rem;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 0.4rem;
  margin: 0;
  color: #666;
}
.tel-us .tm-input .tmi-tel .tmi-textarea {
  display: inline-block;
  position: relative;
  width: 100%;
  /* margin-left: 3%; */
}
.tel-us .tm-input .tmi-tel .tmi-textarea textarea {
  width: 100%;
  resize: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  color: #666;
  overflow-x: hidden;
  box-sizing: border-box;
}
/* 样式2 */
.contact_left {
  position: absolute;
  left: 0.5rem;
  bottom: 2.2rem;
  z-index: 1;
}

.contact_left .mak_tab {
  background-color: #fff;
  width: 2rem;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px #ccc;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
}

.contact_left .mak_tab:last-child {
  margin-bottom: 0;
}

.contact_left .mak_tab img {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
}

.contact_left .mak_tab img:nth-of-type(1) {
  border-bottom: 1px solid #ccc;
}

.contact_left .mak_tab img:last-child,
.contact_left .mak_tab.map_my_location img {
  border: none;
}

/* 样式3 */
.contact_us .contact_3 {
  position: fixed;
  width: 1.6rem;
  height: 1.6rem;
  right: 0.6rem;
  bottom: 4.6rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.contact_us .contact_3 img {
  width: 70%;
  height: 70%;
}
.contact_us .contact_4 {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0.74rem;
  color: rgba(255, 255, 255, 0.6);
  z-index: 3;
  cursor: pointer;
}
.contact_us .contact_5 {
  position: fixed;
  width: 1.8rem;
  height: 1.8rem;
  right: 0.6rem;
  top: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact_us .contact_5 img {
  width: 70%;
  height: 70%;
}
.contact_us .contact_6 {
  /* position: fixed; */
  width: 2rem;
  height: 2rem;
  /* left: 0.6rem; */
  /* bottom: 3rem; */
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 2px #aaa;
  cursor: pointer;
}
.contact_us .contact_6 p {
  color: #999;
  font-size: 0.6rem;
}
.contact_us .contact_6 i {
  color: rgb(255, 60, 0);
  font-size: 1rem;
}
</style>
