<template>
  <div v-title data-title="订单支付">
    <mt-header fixed title="订单支付">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="order-item">
        <div class="item-wrap clearfix">
          <div class="item-icon foot-icon"></div>
          <div class="order-name">{{orderTitle}}</div>
          <div class="order-time">出行时间：{{startTime}}</div>
        </div>
        <div class="item-warp clearfix">
          <div class="user-img">
            <img :src="contactImg" />
          </div>
          <div class="user-wrap">
            <div class="user-name">{{contactName}}</div>
            <div class="order-price">￥{{orderPrice}}</div>
          </div>
          <div class="user-wrap">
            <div class="order-num">人数：{{peopleNum}}</div>
            <div class="order-status">合计</div>
          </div>
        </div>
      </div>

      <div class="order-pay-way">
        <h1>支付方式</h1>
        <ul>
          <a :href="'https://www.zmlxj.com/api.php/Service/ali_pay?order_id='+orderId">
            <li>
              <img src="../../assets/img/tour_zhifubao.png" />
              <h1>支付宝支付</h1>
              <h2>使用支付宝支付，安全又便捷</h2>
            </li>
          </a>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: "",
      orderId: "",
      // 表单信息
      orderPrice: "",
      orderTitle: "",
      startTime: "",
      peopleNum: "",
      contactName: "",
      contactImg: ""
    };
  },

  methods: {
    getLoginInfo: function() {
      //检测登录
      // 用户ID
      if (this.hybrid.hybrid.versions.myapp) {
        // FOR 安卓最美旅行家APP
        $(".mint-header").remove();
        $(".page-content").css("padding", "0");

        this.userId = JavaScriptInterface.getUserId();
      } else if (this.hybrid.hybrid.versions.myiOSapp) {
        // FOR IOS最美旅行家APP

        $(".mint-header").remove();
        $(".page-content").css("padding", "0");

        this.hybrid.hybrid.registerHandler(
          "getIOSUserId",
          (data, responseCallback) => {
            this.userId = data;
            var responseData = "已经收到IOS传来的userid";
            responseCallback(responseData);
          }
        );
      } else {
        // For web
        this.userId = this.get_User_Id();
      }
    }
  },

  mounted() {
    this.getLoginInfo();
    this.orderId = this.$route.params.id;
    this.orderPrice = this.$route.params.price;
    this.orderTitle = this.$route.params.tit;
    this.startTime = this.$route.params.time;
    this.peopleNum = this.$route.params.num;
    this.contactName = this.$route.params.name;
    this.contactImg = this.$route.params.img;
    console.log(this.orderId);
  }
};
</script>

<style scoped>
.order-item {
  margin-bottom: 0;
  border: 0;
}
.order-pay-way > h1 {
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
}
.order-pay-way ul {
  background: #fff;
  overflow: hidden;
}
.order-pay-way ul a {
  display: block;
  padding: 10px;
  border-bottom: 1px solid #f2f2f2;
}
.order-pay-way li {
  background: url(../../assets/img/home_icon_title_more.png) no-repeat right
    center;
  background-size: auto 33%;
}
.order-pay-way li img {
  width: 42px;
  height: 42px;
  float: left;
  margin-right: 10px;
}
.order-pay-way li h1 {
  color: #333;
}
.order-pay-way li h2 {
  color: #c6c6c6;
}
</style>