<template>
  <div class="permission">
    <ul class="wrap" v-if="type == '小米' && name == '启动'">
      <li>
        <p>
          打开
          <span>安全中心</span>
        </p>
        <img src="../../assets/img/xm8.png" alt style="width:30%;" />
        <img src="../../assets/img/permission01.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          进入
          <span>授权管理</span>
        </p>
        <img src="../../assets/img/xm9.png" alt style="width:30%;" />
        <img src="../../assets/img/permission02.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          选择
          <span>自启动管理</span>
        </p>
        <img src="../../assets/img/xm11.png" alt />
        <img src="../../assets/img/permission03.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          允许
          <span>最美旅行家</span>自启动
        </p>
        <img src="../../assets/img/xm10.png" alt />
        <img src="../../assets/img/permission04.png" alt class="dingwei" />
      </li>
    </ul>
    <ul class="wrap" v-if="type == '小米' && name == '神隐'">
      <li>
        <p>
          打开
          <span>设置</span>
        </p>
        <img src="../../assets/img/xm4.png" alt style="width:30%;" />
        <img src="../../assets/img/permission01.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          选择
          <span>其他高级设置</span>
        </p>
        <img src="../../assets/img/xm3.png" alt />
        <img src="../../assets/img/permission02.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          进入
          <span>电量和性能</span>
        </p>
        <img src="../../assets/img/xm1.png" alt />
        <img src="../../assets/img/permission03.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          进入
          <span>神隐模式</span>
        </p>
        <img src="../../assets/img/xm6.png" alt />
        <img src="../../assets/img/permission04.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          进入
          <span>应用配置</span>
        </p>
        <img src="../../assets/img/xm7.png" alt />
        <img src="../../assets/img/permission05.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          找到
          <span>最美旅行家</span>并进入
        </p>
        <img src="../../assets/img/xm2.png" alt />
        <img src="../../assets/img/permission06.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          选择
          <span>自定义设置，</span>并在后台定位配置中选择
          <span>保持定位可用</span>
        </p>
        <img src="../../assets/img/xm5.png" alt />
        <img src="../../assets/img/permission07.png" alt class="dingwei" />
      </li>
    </ul>
    <ul class="wrap" v-if="type == '华为' && name == '启动'">
      <li>
        <p>
          打开
          <span>设置</span>
        </p>
        <img src="../../assets/img/hw6.png" alt style="width:30%;" />
        <img src="../../assets/img/permission01.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          在全部设置中选择
          <span>开机自启动</span>
        </p>
        <img src="../../assets/img/hw5.png" alt />
        <img src="../../assets/img/permission02.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          将
          <span>最美旅行家</span>的
          <span>自启动</span>开关打开
        </p>
        <img src="../../assets/img/xm10.png" alt />
        <img src="../../assets/img/permission03.png" alt class="dingwei" />
      </li>
    </ul>
    <ul class="wrap" v-if="type == '华为' && name == '后台保护'">
      <li>
        <p>
          打开
          <span>手机管家</span>
        </p>
        <img src="../../assets/img/hw3.png" alt style="width:30%;" />
        <img src="../../assets/img/permission01.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          进入
          <span>省电管理</span>
        </p>
        <img src="../../assets/img/hw2.png" alt style="width:30%;" />
        <img src="../../assets/img/permission02.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          选择
          <span>受保护应用</span>
        </p>
        <img src="../../assets/img/hw4.png" alt />
        <img src="../../assets/img/permission03.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          将
          <span>最美旅行家</span>开关打开
        </p>
        <img src="../../assets/img/hw1.png" alt />
        <img src="../../assets/img/permission04.png" alt class="dingwei" />
      </li>
    </ul>
    <ul class="wrap" v-if="type == '华为' && name == '白名单'">
      <li>
        <p>
          点击手机底部的
          <span>菜单键</span>进入
          <span>后台应用列表</span>
        </p>
        <img src="../../assets/img/hw8.png" alt />
        <img src="../../assets/img/permission01.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          找到最美旅行家并
          <span>下拉锁定</span>，使最美旅行家上显示一个锁状图标
        </p>
        <img src="../../assets/img/hw9.png" alt />
        <img src="../../assets/img/permission02.png" alt class="dingwei" />
      </li>
    </ul>
    <ul class="wrap" v-if="type == '魅族' && name == '启动'">
      <li>
        <p>
          打开
          <span>手机管家</span>
        </p>
        <img src="../../assets/img/mz7.png" alt style="width:30%;" />
        <img src="../../assets/img/permission01.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          进入
          <span>权限管理</span>
        </p>
        <img src="../../assets/img/mz9.png" alt style="width:30%;" />
        <img src="../../assets/img/permission02.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          选择
          <span>自启动管理</span>
        </p>
        <img src="../../assets/img/xm11.png" alt />
        <img src="../../assets/img/permission03.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          将
          <span>最美旅行家</span>自启动
        </p>
        <img src="../../assets/img/xm10.png" alt />
        <img src="../../assets/img/permission04.png" alt class="dingwei" />
      </li>
    </ul>
    <ul class="wrap" v-if="type == '魅族' && name == '后台保护'">
      <li>
        <p>
          打开
          <span>手机管家</span>
        </p>
        <img src="../../assets/img/mz7.png" alt style="width:30%;" />
        <img src="../../assets/img/permission01.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          进入
          <span>省电模式</span>
        </p>
        <img src="../../assets/img/mz6.png" alt style="width:30%;" />
        <img src="../../assets/img/permission02.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          选择
          <span>待机耗电管理</span>
        </p>
        <img src="../../assets/img/mz8.png" alt />
        <img src="../../assets/img/permission03.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          将
          <span>最美旅行家</span>打开
        </p>
        <img src="../../assets/img/mz5.png" alt />
        <img src="../../assets/img/permission04.png" alt class="dingwei" />
      </li>
    </ul>
    <ul class="wrap" v-if="type == '魅族' && name == '白名单'">
      <li>
        <p>
          打开
          <span>设置</span>
        </p>
        <img src="../../assets/img/mz10.png" alt style="width:30%;" />
        <img src="../../assets/img/permission01.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          进入
          <span>手机加速</span>
        </p>
        <img src="../../assets/img/mz2.png" alt style="width:30%;" />
        <img src="../../assets/img/permission02.png" alt class="dingwei" />
      </li>

      <li>
        <p>
          选择右上角的
          <span>设置</span>
        </p>
        <img src="../../assets/img/mz1.png" alt />
        <img src="../../assets/img/permission03.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          选择
          <span>手机加速白名单</span>
        </p>
        <img src="../../assets/img/mz13.png" alt />
        <img src="../../assets/img/permission04.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          将
          <span>最美旅行家</span>添加到白名单
        </p>
        <img src="../../assets/img/mz4.png" alt />
        <img src="../../assets/img/permission05.png" alt class="dingwei" />
      </li>
    </ul>
    <ul class="wrap" v-if="type == '大神' && name == '启动'">
      <li>
        <p>
          打开
          <span>设置</span>
        </p>
        <img src="../../assets/img/ds1.png" alt style="width:30%;" />
        <img src="../../assets/img/permission01.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          进入
          <span>应用自启动</span>
        </p>
        <img src="../../assets/img/ds4.png" alt />
        <img src="../../assets/img/permission02.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          将
          <span>最美旅行家</span>的
          <span>自启动</span>开关打开
        </p>
        <img src="../../assets/img/ds5.png" alt />
        <img src="../../assets/img/permission03.png" alt class="dingwei" />
      </li>
    </ul>
    <ul class="wrap" v-if="type == '大神' && name == '后台保护'">
      <li>
        <p>
          打开
          <span>设置</span>
        </p>
        <img src="../../assets/img/ds1.png" alt style="width:30%;" />
        <img src="../../assets/img/permission01.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          进入
          <span>电池和省电</span>
        </p>
        <img src="../../assets/img/ds6.png" alt />
        <img src="../../assets/img/permission02.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          选择
          <span>锁屏受保护应用</span>
        </p>
        <img src="../../assets/img/ds3.png" alt />
        <img src="../../assets/img/permission04.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          将
          <span>最美旅行家</span>的保护开关打开
        </p>
        <img src="../../assets/img/ds2.png" alt />
        <img src="../../assets/img/permission04.png" alt class="dingwei" />
      </li>
    </ul>
    <ul class="wrap qt" v-if="type == '其他手机' && name == '启动'">
      <li>
        <p>
          打开
          <span>设置或安全中心</span>
        </p>
        <img src="../../assets/img/permission01.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          进入
          <span>自启动管理</span>
        </p>
        <img src="../../assets/img/permission02.png" alt class="dingwei" />
      </li>
      <li>
        <p>
          找到
          <span>自启动管理</span>，并允许其
          <span>自启动</span>
        </p>
        <img src="../../assets/img/permission03.png" alt class="dingwei" />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: this.$route.query.type,
      name: this.$route.query.name
    };
  }
};
</script>
<style scoped>
.wrap.qt li {
  position: relative;
  border-left: 1px solid #ddd;
  padding: 0 0 0 1.5rem;
  margin-top: 10px;
  height: 3.2rem;
}
</style>