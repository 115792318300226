<template>
  <div class="GiveRabPacket" :class="{active:id!=undefined}" v-title data-title="发红包">
    <mt-header fixed title="发红包">
      <a slot="left">
        <mt-button @click.native="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <!--地图-->
      <div class="container_box">
        <div id="container" style="width:100%; height:100%"></div>
        <div class="left_but">
          <div class="mak_tab">
            <!--地图 + -  -->
            <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
            <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
          </div>
        </div>
      </div>
      <!--搜索框-->
      <div id="myPageTop" v-if="id==undefined">
        <i class="iconfont iconsearch"></i>
        <input id="tipinput" placeholder="丹霞山地质公园" />
      </div>
      <div class="setting_info">
        <form
          id="form"
          action="https://www.zmlxj.com/api.php/foot/ali_pay"
          method="post"
          enctype="multipart/form-data"
          onsubmit="return false"
        >
          <div class="scope">
            <p>范围</p>
            <input type="text" name="radius" :disabled="id!=undefined" v-model="radius" />千米
          </div>
          <div class="time">
            <p>时间</p>
            <span v-if="id!=undefined" :class="{active:time==1}">一天</span>
            <span v-if="id!=undefined" :class="{active:time==7}">七天</span>
            <span v-if="id!=undefined" :class="{active:time==30}">三十天</span>
            <span v-if="id==undefined" :class="{active:time==1}" @click="time=1">一天</span>
            <span v-if="id==undefined" :class="{active:time==7}" @click="time=7">七天</span>
            <span v-if="id==undefined" :class="{active:time==30}" @click="time=30">三十天</span>
            <input type="hidden" name="time_length" v-model="time" />
          </div>
          <div class="w_steal">
            <p>谁可以抢</p>
            <span v-if="id!=undefined" :class="{active:w_steal==1}">全部</span>
            <span v-if="id!=undefined" :class="{active:w_steal==2}">粉丝</span>
            <span v-if="id!=undefined" :class="{active:w_steal==3}">关注</span>
            <span v-if="id==undefined" :class="{active:w_steal==1}" @click="w_steal=1">全部</span>
            <span v-if="id==undefined" :class="{active:w_steal==2}" @click="w_steal=2">粉丝</span>
            <span v-if="id==undefined" :class="{active:w_steal==3}" @click="w_steal=3">关注</span>
            <input type="hidden" name="fansi" value="1" v-if="w_steal==1" />
            <input type="hidden" name="atten" value="1" v-if="w_steal==2" />
            <input type="hidden" name="all" value="1" v-if="w_steal==3" />
          </div>
          <div class="overall_money">
            <p>总金额</p>
            <input
              type="text"
              name="money"
              :disabled="id!=undefined"
              placeholder="0.00"
              v-model="overall_money"
            />元
          </div>
          <div class="number">
            <p>个数</p>
            <input
              :disabled="id!=undefined"
              type="text"
              name="numbers"
              v-model="numbers"
              placeholder="填写个数"
            />个
          </div>
          <div class="leave_word">
            <p>留言</p>
            <input
              :disabled="id!=undefined"
              type="text"
              name="title"
              v-model="title"
              placeholder="恭喜发财，大吉大利"
            />
          </div>
          <div class="money" v-if="id==undefined">￥{{overall_money}}</div>
          <input type="hidden" name="lng" v-model="lng" />
          <input type="hidden" name="lat" v-model="lat" />
          <input type="hidden" name="user_id" v-model="user_id" />
          <div class="btn" v-if="id==undefined">
            <mt-button type="primary" class="mint-header" @click="success">塞钱进红包</mt-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
var circle;
import { MessageBox } from "mint-ui";
import { Toast } from "mint-ui";
import icon from "../../assets/img/landmark.png";
import footprint_red from "../../assets/img/footprint_red.png"; //红包
export default {
  data() {
    return {
      map: {},
      overall_money: 0.1, //总金额
      time: 1, //点击切换时间
      w_steal: 1, //点击切换谁可以抢
      radius: 1,
      numbers: 1,
      lng: 0,
      lat: 0,
      user_id: this.$route.query.user_id,
      title: "人生就是一场旅行",
      id: this.$route.query.id
    };
  },
  watch: {
    radius: function(e) {
      circle.setRadius(e * 1000);
      this.map.setFitView();
    }
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      var that = this;
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      if (!this.user_id) {
        if (!this.check_login()) {
          MessageBox.alert("请先登陆").then(action => {
            if (action == "confirm") {
              localStorage.setItem("login_url", location.hash);
              this.$router.push({ path: "/my/login", query: { log_type: 1 } });
              return;
            }
          });
        } else {
          this.user_id = this.check_login().user_id;
        }
      }
      this.map = this.map_init();

      if (this.id) {
        this.$ajax(
          "post",
          "foot/get_user_last_red_packet",
          {
            user_id: this.user_id,
            id: this.id
          },
          res => {
            if (res.data.id == 0) {
              var data = res.data.data;
              if (data.park_res.length > 0) {
                this.map.setZoomAndCenter(11, [
                  data.park_res.lng,
                  data.park_res.lat
                ]);
              }
              if (data.red_packet_list.length > 0) {
                //                                this.map_add_Marker(this.map, data.red_packet_list)
                this.map_add_Marker({
                  map: this.map,
                  list_data: data.red_packet_list
                });
              }
              data.park_res.all == 1
                ? (that.w_steal = 1)
                : data.park_res.atten == 1
                ? (that.w_steal = 2)
                : data.park_res.fansi == 1
                ? (that.w_steal = 3)
                : (that.w_steal = 0);
              that.time = data.park_res.time_length;
              that.title = data.park_res.title;
              that.overall_money = data.park_res.money;
              that.numbers = data.park_res.numbers;
              that.radius = data.park_res.radius;
              AMap_circle(data.park_res.lng, data.park_res.lat); //画圆半径

              //                            for (var i = 0; i < data.red_packet_list.length; i++) {
              //                                //添加点标记，并使用自己的icon
              //                                Marker = new AMap.Marker({
              //                                    map: this.map,
              //                                    position: [data.red_packet_list[i].lng, data.red_packet_list[i].lat],
              //                                    icon: new AMap.Icon({
              //                                        size: new AMap.Size(20, 21),  //图标大小
              //                                        image: footprint_red
              //                                    })
              //                                });
              //                            }
            }
          }
        );
      } else {
        that.map.plugin(
          ["AMap.Autocomplete", "AMap.PlaceSearch", "AMap.Circle"],
          function() {
            //输入提示
            var autoOptions = {
              input: "tipinput"
            };
            var auto = new AMap.Autocomplete(autoOptions);
            var placeSearch = new AMap.PlaceSearch({
              map: that.map
            }); //构造地点查询类
            AMap.event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发
            function select(e) {
              placeSearch.setCity(e.poi.adcode);
              placeSearch.search(e.poi.name); //关键字查询查询
              that.lng = e.poi.location.lng;
              that.lat = e.poi.location.lat;
              AMap_circle(that.lng, that.lat);
            }
          }
        );
        //为地图注册click事件获取鼠标点击出的经纬度坐标
        that.map.on("click", function(e) {
          that.lng = e.lnglat.getLng();
          that.lat = e.lnglat.getLat();
          //添加点标记，并使用自己的icon
          that.map.clearMap();
          that.map_add_Marker_1(
            that.map,
            {
              lng: that.lng,
              lat: that.lat
            },
            "",
            false
          );
          AMap_circle(that.lng, that.lat);
        });
      }
      //                画圆半径
      function AMap_circle(lng, lat) {
        if (circle) {
          circle.setMap(null);
        }
        circle = new AMap.Circle({
          center: [lng, lat], // 圆心位置
          radius: that.radius * 1000, //半径
          strokeColor: "#ff9a80", //线颜色
          strokeOpacity: 1, //线透明度
          strokeWeight: 3, //线粗细度
          fillColor: "#ff9a80", //填充颜色
          fillOpacity: 0.35 //填充透明度
        });
        circle.setMap(that.map);
        that.map.setFitView();
      }
    },
    success() {
      if (this.lng == 0) {
        Toast("请先选择公园");
        return;
      }
      if (parseInt(this.overall_money) <= 0.1) {
        Toast("红包金额不得低于0.1元钱，请重新填写金额");
        return;
      }
      if (
        parseInt(this.numbers) * parseInt(this.overall_money) <
        parseInt(this.overall_money)
      ) {
        Toast("单个红包金额不得低于0.1元钱，请重新填写金额");
        return;
      }
      document.getElementById("form").removeAttribute("onsubmit");
    }
  }
};
</script>
<style scoped>
.GiveRabPacket {
  min-height: 100%;
  background-color: #fff;
}

.GiveRabPacket input {
  -webkit-appearance: none;
}

.GiveRabPacket.active .container_box {
  height: 400px;
}

.GiveRabPacket .page-content {
  padding-bottom: 0;
}

#myPageTop {
  position: fixed;
  top: 2.2rem;
  left: 10px;
  right: 10px;
}

#myPageTop .iconfont {
  margin-top: 5px;
  position: absolute;
  top: 0;
  left: 5px;
  font-size: 1.5rem;
  color: rgb(255, 155, 128)
}

#myPageTop #tipinput {
  background-color: #fff;
  width: 100%;
  height: 2rem;
  border: 1px solid #f2f2f2;
  padding-left: 2rem;
  box-sizing: border-box;
  font-size: 0.8rem;
}

.setting_info {
  background-color: #fff;
  padding: 10px;
}

.setting_info form > div {
  margin: 4px 0;
}

.setting_info p {
  display: inline-block;
  width: 4rem;
}

.setting_info input {
  border: 1px solid #ccc;
  height: 1.5rem;
  margin-right: 0.5rem;
  text-align: right;
  padding-right: 0.5rem;
  width: 40%;
  vertical-align: middle;
  font-size: 0.6rem;
}

.setting_info .w_steal span,
.setting_info .time span {
  border: 1px solid #ff9a80;
  padding: 2px 3%;
  margin-right: 10px;
  border-radius: 4px;
  font-size: 0.7rem;
  height: 1.2rem;
  line-height: 1.2rem;
  display: inline-block;
}

.setting_info .w_steal span.active,
.setting_info .time span.active {
  background-color: #ff9a80;
  color: #fff;
}

.setting_info input::-ms-input-placeholder {
  text-align: right;
  padding-right: 4px;
}

.setting_info input::-webkit-input-placeholder {
  text-align: right;
  padding-right: 4px;
}

.setting_info .money {
  font-size: 24px;
  text-align: center;
  padding: 20px 0;
}
</style>