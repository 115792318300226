<template>
  <div v-title data-title="详情" class="GuideDetail">
    <mt-header fixed title="详情">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
      <mt-button icon="more" slot="right"></mt-button>
    </mt-header>
    <div class="page-content">
      <div class="guide-detail-img" v-imgBg="detail.img" v-if="detail.img"></div>
      <div class="guide-detail-top">
        <div class="row">
          <div class="distance">{{detail.range_km}}</div>
          <h1>{{detail.title}}</h1>
        </div>
        <div class="row">
          <div class="date-price">
            ￥{{detail.service_money}}
            <span>/天</span>
          </div>
          <div class="guide-tag">
            <a v-for="(item, i) in detail.label" :key="i">{{item.label_name}}</a>
          </div>
        </div>
      </div>
      <div class="guide-detail-intro">
        <span>简介：</span>
        {{detail.funy_text}}
      </div>
      <router-link
        :to="{ name: 'GuidePage', params: { id: detail.user.user_id }}"
        class="guide-card"
        v-if="detail.user"
      >
        <div class="avatar fl">
          <div class="img" v-imgBg="detail.user.user_img" v-if="detail.user.user_img"></div>
        </div>
        <div class="fuwu">{{detail.user.service_number}}项服务</div>
        <div class="row">
          <div class="name fl">{{detail.user.user_nick}}</div>
          <div class="sex fl">
            <img height="16" src="../../assets/img/tour_sex_1.png" v-if="detail.user.sex == 1" />
            <img height="16" src="../../assets/img/tour_sex_0.png" v-else />
          </div>
        </div>
        <div class="row">
          <div class="sign">个性签名：{{detail.notes}}</div>
        </div>
      </router-link>
      <ul class="guide-fuwu-list">
        <li v-if="detail.have_car == 1">
          <h1 class="car">
            <span>提供车辆</span>
          </h1>
          <h2>有车接送</h2>
        </li>
        <li class="no" v-else>
          <h1 class="car">
            <span>提供车辆</span>
          </h1>
          <h2>无车接送</h2>
        </li>
        <li>
          <h1 class="duration">
            <span>游玩时长</span>
          </h1>
          <h2>{{detail.time_length}}小时</h2>
        </li>
        <li v-if="detail.have_guide == 1">
          <h1 class="guide">
            <span>提供向导</span>
          </h1>
          <h2>提供</h2>
        </li>
        <li class="no" v-else>
          <h1 class="guide">
            <span>提供向导</span>
          </h1>
          <h2>不提供</h2>
        </li>
        <li>
          <h1 class="time">
            <span>服务时段</span>
          </h1>
          <h2>{{detail.time_slot}}</h2>
        </li>
        <li>
          <h1 class="reception">
            <span>接待人数</span>
          </h1>
          <h2>{{detail.service_number}}</h2>
        </li>
        <li v-if="detail.lang == 1">
          <h1 class="language">
            <span>服务语言</span>
          </h1>
          <h2>中文</h2>
        </li>
        <li v-else-if="detail.lang == 0">
          <h1 class="language">
            <span>服务语言</span>
          </h1>
          <h2>英文</h2>
        </li>
      </ul>

      <!-- 轨迹截图 -->
      <div class="track-shot-img" v-imgBg="detail.path_img" v-if="detail.path_img"></div>

      <!-- 注意事项 -->
      <div class="guide-notice">
        <h1 class="tit">
          <b></b>提前预约
        </h1>
        <div class="info">{{detail.refund_text}}</div>
        <h1 class="tit">
          <b></b>行程详情
        </h1>
        <div class="info">
          {{detail.funy_text}}
          <ul class="play_some">
            <li v-for="(k, i) in detail.funy" :key="i">
              <img :src="item.img" alt v-for="(item, i) in filterBy(k.img)" :key="i" />
              <h2 v-if="detail.root_or_notes!=2">
                <img src="../../assets/img/tour_plane.png" alt />
                {{k.title}}
              </h2>
              <pre v-html="k.content"></pre>
            </li>
          </ul>
          <div class="container_box" :class="{active:container_big}">
            <div id="container" style="width:100%; height:100%"></div>
            <ul class="map_info clearfix" v-if="detail.root_books">
              <li>
                <p>总里程</p>
                <p>{{detail.root_books.length}}</p>
              </li>
              <li>
                <p>最高海拔</p>
                <p>{{detail.root_books.height}}</p>
              </li>
              <li>
                <p>最低海拔</p>
                <p>{{detail.root_books.low}}</p>
              </li>
              <li>
                <p>徒步时间</p>
                <p>{{detail.root_books.time_length}}</p>
              </li>
            </ul>
            <div class="left_but" v-if="container_big">
              <div class="mak_tab">
                <img
                  src="../../assets/img/map_3d.png"
                  alt
                  class="map_3d"
                  @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
                  v-if="is_tab_map_3d"
                />
                <img
                  src="../../assets/img/map_3d_yes.png"
                  alt
                  class="map_3d"
                  @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
                  v-if="!is_tab_map_3d"
                />
              </div>
              <div class="mak_tab">
                <!--地图 + -  -->
                <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
                <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
              </div>
            </div>
            <i
              class="container_quanping iconfont"
              :class="{'icon-quitquanping':container_big,'icon-quanping':!container_big}"
              @click="container_big=!container_big"
            ></i>
          </div>
          <!--路书列表详情-->
          <div v-if="detail.root_books">
            <line-info-content
              :datainfo="detail.root_books.spots_list"
              :user_id="user_id"
              :is_app="false"
            ></line-info-content>
          </div>
        </div>
        <h1 class="tit">
          <b></b>出发与返回
        </h1>
        <pre class="info">{{detail.route}}</pre>
        <h1 class="tit">
          <b></b>费用包括
        </h1>
        <ul class="cost">
          <li :class="{ yes:costIn[0] }">
            <span>燃油费</span>
          </li>
          <li :class="{ yes:costIn[1] }">
            <span>过路费</span>
          </li>
          <li :class="{ yes:costIn[2] }">
            <span>停车费</span>
          </li>
          <li :class="{ yes:costIn[3] }">
            <span>餐费</span>
          </li>
          <li :class="{ yes:costIn[4] }">
            <span>住宿费</span>
          </li>
          <li :class="{ yes:costIn[5] }">
            <span>门票</span>
          </li>
          <li :class="{ yes:costIn[6] }">
            <span>公共交通</span>
          </li>
          <li :class="{ yes:costIn[7] }">
            <span>其他</span>
          </li>
        </ul>
        <div class="info">
          <pre>{{detail.cost_more}}</pre>
        </div>
        <h1 class="tit">
          <b></b>费用不包括
        </h1>
        <div class="info">
          <pre>{{detail.cost_out}}</pre>
        </div>
        <!-- <h1 class="tit"><b></b>提前预约</h1>
                <div class="info">
                    <p>提前一天</p>
        </div>-->
        <h1 class="tit">
          <b></b>注意事项
        </h1>
        <div class="info">
          <pre>{{detail.notes}}</pre>
        </div>
        <h1 class="tit">
          <b></b>退单需知
        </h1>
        <div class="info">
          <pre>{{detail.refund_text}}</pre>
        </div>
      </div>
      <!-- 用户点评 -->
      <!-- <div class="guide-comment">
                <div class="tit">
                    <span>用户点评</span>
                    <a>写点评</a>
                </div>
      </div>-->
    </div>
    <div class="guide-footbar">
      <router-link
        :to="{name:'ChatWindow',params:{uid:detail.user.user_id}}"
        v-if="detail.user"
        class="ask"
      >
        <span>咨询</span>
      </router-link>
      <div class="price">￥{{detail.service_money}}</div>
      <a class="book" @click="goOrderEdit">预定项目</a>
    </div>
  </div>
</template>
<script>
import LineInfoContent from "../../components/LineInfoContent";
export default {
  components: {
    LineInfoContent
  },
  data() {
    return {
      map: {},
      user_id: "",
      guideId: this.$route.params.id,
      detail: {},
      sexSrc: "",
      // 费用包括
      costIn: [false, false, false, false, false, false, false, false],
      container_big: false, //地图放大缩小
      map_user_yes: false, //是否点击游客按钮
      is_tab_map_3d: true, //3D地图按钮是否点击
      verify: this.$route.params.verify == 0 ? null : this.$route.params.verify
    };
  },
  mounted() {
    this.getGuideDetail();
  },
  methods: {
    filterBy(ls) {
      return ls.filter(item => {
        return item.img;
      });
    },
    getGuideDetail: function() {
      if (this.check_login()) {
        this.user_id = this.check_login().user_id;
      }

      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      var that = this;
      //初始化地图对象，加载地图
      this.map = this.map_init();
      this.$ajax(
        "post",
        "/Service/get_service_info",
        {
          service_id: this.guideId,
          type: 1,
          verify: this.verify
        },
        res => {
          var data = res.data.data;
          that.detail = data;
          // 费用包括
          var costInArray = data.cost_in.split(",");
          for (let i = 0; i < costInArray.length; i++) {
            let num = costInArray[i] - 1;
            that.costIn[num] = true;
          }
          if (data.root_books.spots_point_list.length > 0) {
            this.map_root_book_point(
              this.map,
              data.root_books.spots_point_list,
              data => {
                that.jump(data, "", this.container_big);
              }
            );
          }
        }
      );
    },

    goOrderEdit: function() {
      var user_info = this.check_login();
      if (!user_info) {
        localStorage.setItem("login_url", location.hash);
        this.$router.push({ name: "login" });
      } else {
        //商品id、发布者luId、单价price、标题tit
        this.$router.push({
          name: "GuideOrderEdit",
          params: {
            id: this.guideId,
            luId: this.detail.l_fk_id,
            price: this.detail.service_money,
            tit: this.detail.title,
            verify: this.verify == null ? 0 : this.verify
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.page-content {
  padding-top: 0;
}

.mint-header {
  background: transparent;
}

.GuideDetail .container_box.active {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.GuideDetail .container_box .container_quanping {
  bottom: 10px;
}

.guide-detail-img {
  width: 100%;
  height: 10rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.guide-detail-top {
  margin: 10px;
  padding: 10px 0 10px 10px;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
}

.guide-detail-top .distance {
  float: right;
  color: #fff;
  background: #b2b2b2;
  border-radius: 3px 0 0 3px;
  padding: 2px 5px;
  font-size: 0.6rem;
}

.guide-detail-top .date-price {
  float: right;
  clear: right;
  color: #ff9704;
  font-size: 0.7rem;
}

.guide-detail-top .date-price span {
  color: #999;
  font-size: 0.6rem;
}

.guide-detail-top h1 {
  color: #333;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.guide-detail-top .guide-tag a {
  background: url("../../assets/img/tour_label.png") no-repeat left center;
  background-size: auto 0.7rem;
  padding-left: 1rem;
  margin-right: 10px;
  font-size: 0.7rem;
  color: #999;
}

.guide-detail-intro {
  margin: 10px;
  font-size: 0.7rem;
  color: #666;
}

.guide-detail-intro span {
  color: #fe9b00;
}

.guide-card {
  margin: 10px;
  padding: 10px 0 10px 10px;
  background: #fff;
  border-radius: 3px;
  color: #666;
  font-size: 0.7rem;
  display: block;
}

.guide-card .row {
  overflow: hidden;
}

.guide-card .sex {
  margin-left: 10px;
}

.guide-card .avatar {
  margin-right: 10px;
}

.guide-card .sign {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.guide-card .fuwu {
  float: right;
  color: #ff9a80;
  border: 1px solid #ff9a80;
  border-radius: 3px 0 0 3px;
  padding: 0 3px;
  margin-top: 5px;
}

.guide-fuwu-list {
  overflow: hidden;
  margin-bottom: 5px;
}

.guide-fuwu-list li {
  width: 33.33333%;
  float: left;
  text-align: center;
  font-size: 0.7rem;
  color: #666;
  margin: 8px 0;
}

.guide-fuwu-list h2 {
  font-size: 0.8rem;
  margin-top: 2px;
}

.guide-fuwu-list .no {
  color: #999;
}

.guide-fuwu-list span {
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 0.8rem;
  padding-left: 1rem;
}

.guide-fuwu-list .car span {
  background-image: url(../../assets/img/tour_vehicle_yes.png);
}

.guide-fuwu-list .no .car span {
  background-image: url(../../assets/img/tour_vehicle_no.png);
}

.guide-fuwu-list .duration span {
  background-image: url(../../assets/img/tour_duration_yes.png);
  background-size: auto 20px;
}

.guide-fuwu-list .no .duration span {
  background-image: url(../../assets/img/tour_duration_no.png);
}

.guide-fuwu-list .guide span {
  background-image: url(../../assets/img/tour_guide_yes.png);
}

.guide-fuwu-list .no .guide span {
  background-image: url(../../assets/img/tour_guide_no.png);
}

.guide-fuwu-list .time span {
  background-image: url(../../assets/img/tour_time_yes.png);
}

.guide-fuwu-list .no .time span {
  background-image: url(../../assets/img/tour_time_no.png);
}

.guide-fuwu-list .reception span {
  background-image: url(../../assets/img/tour_reception_yes.png);
}

.guide-fuwu-list .no .reception span {
  background-image: url(../../assets/img/tour_reception_no.png);
}

.guide-fuwu-list .language span {
  background-image: url(../../assets/img/tour_language_yes.png);
}

.guide-fuwu-list .no .language span {
  background-image: url(../../assets/img/tour_language_no.png);
}

.track-shot-img {
  width: 100%;
  padding-top: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.guide-notice {
  padding: 10px 10px 0;
}

.guide-notice >>> .LineInfoList_list_box {
  padding: 0;
}

.guide-notice .tit {
  color: #ff9801;
  margin: 5px 0;
  font-size: 0.8rem;
}

.guide-notice .tit b {
  border: 1px solid #ff9801;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  float: left;
  margin: 0.4rem 0.3rem 0 0;
}

.guide-notice .info {
  color: #666;
  padding: 0 17px;
  font-size: 0.7rem;
  margin-bottom: 15px;
}

.guide-notice .cost {
  overflow: hidden;
  margin-left: 10%;
  margin-bottom: 10px;
}

.guide-notice .cost li {
  float: left;
  width: 32%;
  text-align: left;
  margin: 5px 0;
}

.guide-notice .cost li span {
  background-image: url(../../assets/img/tour_cost_no.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 0.8rem;
  padding-left: 1rem;
  font-size: 0.8rem;
  color: #999;
}

.guide-notice .cost .yes span {
  background-image: url(../../assets/img/tour_cost_yes.png);
  color: #333;
}

.guide-notice .play_some li {
  padding-top: 10px;
}

.guide-notice .play_some li > img {
  width: 100%;
  height: 200px;
  display: inline-block;
}

.guide-notice pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: initial;
}

.guide-notice .play_some li h2 {
  font-size: 16px;
  color: #333;
  text-align: center;
}

.guide-notice .play_some li h2 img {
  display: inline-block;
  width: 22px;
  margin-right: 20px;
}

.guide-comment {
  background: #fff;
  padding: 10px;
}

.guide-comment .tit span {
  color: #ff9c00;
  border-left: 1px solid #ff9c00;
  padding-left: 5px;
}

.guide-comment .tit a {
  float: right;
  background: #ff9c00;
  padding: 0 5px;
  color: #fff;
  font-size: 14px;
  border-radius: 3px;
}

.guide-footbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 0;
  font-size: 0.7rem;
}

.guide-footbar .ask {
  width: 30%;
  float: left;
  background: #fff;
  color: #666;
  font-size: 0.7rem;
  line-height: 2.1rem;
}

.guide-footbar .ask span {
  background: url(../../assets/img/tour_consult.png) no-repeat left center;
  background-size: auto 80%;
  padding-left: 1.5rem;
}

.guide-footbar .price {
  width: 30%;
  float: left;
  background: #fe5b10;
  color: #fff;
  font-size: 0.8rem;
  line-height: 2.1rem;
}

.guide-footbar .book {
  width: 40%;
  float: left;
  background: #ff9c00;
  color: #fff;
  font-size: 0.8rem;
  line-height: 2.1rem;
}
</style>