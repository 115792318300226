<template>
    <div class="feed-list note" v-if="itemLen>0">
        <!-- <div class="feed-title"><h1 class="fl">游记推荐</h1><a class="more" href="javascript:;">更多</a></div> -->

        <mt-loadmore :bottom-method="loadBottom" :bottom-all-loaded="allLoaded" ref="loadmore" :auto-fill="false">
            <div class="feed-item" v-for="(item, index) in items" :key="index">
                <!-- 下拉菜单 -->
                <div class="feed-item-downbtn" :collect="item.collect" :report="item.report"
                     :attention="item.attention" @click="setting($event,item.id,index,item.user_id)"></div>
                <!-- 游记内容 -->
                <div class="feed-item-body">
                    <!-- 用户信息 -->
                    <div class="feed-item-top">
                        <router-link :to="{name:'otherIndex', params: { uid: item.user_id }}"
                                     v-if="item.user_id != userId">
                            <div class="avatar fl">
                                <img class="img" :src="item.user_icon" v-if="item.user_icon"/>
                                <div class="img" v-else></div>
                            </div>
                            <h1><span class="name">{{item.user_nick}}</span></h1>
                            <h2>
                                <span class="time">{{item.travel_notes_time}}</span>
                                <!--<span class="location"-->
                                <!--v-if="item.travel_notes_address !='' && item.travel_notes_address !=1 ">{{item.travel_notes_address}}</span>-->
                            </h2>
                        </router-link>
                        <div v-else>
                            <div class="avatar fl">
                                <img class="img" :src="item.user_icon" v-if="item.user_icon"/>
                                <div class="img" v-else></div>
                            </div>
                            <h1><span class="name">{{item.user_nick}}</span></h1>
                            <h2>
                                <span class="time">{{item.travel_notes_time}}</span>
                                <!--<span class="location"-->
                                <!--v-if="item.travel_notes_address !='' && item.travel_notes_address !=1 ">{{item.travel_notes_address}}</span>-->
                            </h2>
                        </div>
                    </div>
                    <router-link :to="{ name: 'NoteDetail', params: { id: item.id }}">
                        <a class="text">
                            {{item.travel_notes_title}}
                        </a>
                        <div class="audio" v-if="item.speech_path" @click="audio_paly">
                            <audio>
                                <source :src="item.speech_path" type="audio/mpeg">
                            </audio>
                            <i class="icon-play"></i> <span class="fr text-5">{{item.speech_time}}</span>
                        </div>
                        <div class="img-box" :class="getRowType(item.img.length)">
                            <div class="item" v-for="(itemimg, imgIndex) in item.img" :key="imgIndex"
                                 :pic="itemimg.travel_notes_img_1">
                                <img v-if="imgIndex < 9" v-lazy="itemimg.android_newUrl" @click.prevent="onClickMe(item.img,imgIndex)"/>
                            </div>
                            <div class="video item" :class="{active:click_video && click_video_index == index}"
                                 v-if="item.pai_path">
                                <div class="box">
                                    <video :src="item.pai_path" :poster="item.pai_img"
                                           preload="none" :controls="click_video && click_video_index == index"></video>
                                    <b @click="click_video_paly(index)"></b><i @click="click_video_remove">x</i></div>
                            </div>
                        </div>
                        <!-- 轨迹 -->
                        <div class="note-track-card" v-if="item.record_id>0">
                            <router-link :to="{ name: 'TrackDetail', params: { id: item.id }}">
                                <div class="map">
                                    <div class="map-pic" v-imgBg="item.record_img"></div>
                                </div>
                                <div class="info">
                                    <div class="tit">{{item.record_title}}</div>
                                    <div class="cat"><span
                                            :class="'cat' + item.label_id[0].label_id">{{item.label_id[0].label_name}}</span>
                                    </div>
                                    <span class="dis">{{item.distance}}km</span>
                                    <span class="time">{{item.record_time | formatDate}}</span>
                                </div>
                            </router-link>
                        </div>
                    </router-link>
                    <!--公园-->
                    <div class="feed-item-tag">
                        <a @click="parkdata(item.park_id)">{{item.park_name}}</a>
                    </div>
                </div>
                <!-- 工具栏 -->
                <div class="feed-item-tool">
                    <a class="zan" :class="{'yes':item.like == 1}"
                       @click="postLike(item.id, index)"><span>{{item.like_numbers}}</span></a>
                    <router-link
                            :to="{ name: 'NoteDetail', params: { id: item.id }}" class="ping" href="javascript:;"><span>{{item.discuss_numbers}}</span>
                    </router-link>
                    <a class="zhuan" href="javascript:;"><span>{{item.transpond_numbers}}</span></a>
                </div>
            </div>
        </mt-loadmore>
        <div class="radiolist_box" v-show="is_report" @click.prevent="is_report = false">
            <ul class="radiolist" @click.stop="is_report = true">
                <h2>投诉类别</h2>
                <li v-for="(k,i) in radiolist" @click.prevent="radiolist_num = (i+1)" :key="i">
                    <input class="" type="checkbox" :data-num="(i+1)" :class="{checkbox:radiolist_num == (i+1)}">
                    <label>{{k.name}}</label>
                </li>
                <div class="btn btn_exit">
                    <mt-button type="primary" class="mint-header" @click="click_report()">确认举报</mt-button>
                </div>
            </ul>
        </div>
        <div class="preview-content" v-if="is_preview" @click="is_preview=false">
            <mt-swipe :auto="0" :showIndicators="true" :defaultIndex="index">
                <mt-swipe-item v-for="(item,i) in message" :key="i"><a href="javascript:;"><img
                        :src="item.travel_notes_img_1" alt=""></a>
                </mt-swipe-item>
            </mt-swipe>
        </div>
        <mt-actionsheet :actions="setting_info" v-model="sheetVisible"></mt-actionsheet>
    </div>
    <div v-else-if="itemLen<0" style="text-align:center;color:#999;padding-top:26px;font-size: 14px;">加载中...</div>
    <div v-else style="text-align:center;color:#999;padding-top:26px;font-size: 14px;">还没有游记</div>
</template>

<script>
import {Toast, MessageBox} from 'mint-ui'

export default {
  data () {
    return {
      userId: '',
      parkId: this.$route.query.pid,
      items: [],
      itemLen: -1,
      allLoaded: false,
      bottomStatus: '',
      is_preview: false,
      message: [],
      index: 0,
      setting_info: [
        {name: '收藏', method: this.collect},
        {name: '关注', method: this.attention},
        {name: '取消', method: this.report}
      ],
      sheetVisible: false,
      travel_notes_id: '', // 游记id
      user_be_id: '', // 关注的id
      setting_status: {
        collect: '', // 收藏状态
        attention: '', // 关注状态
        report: '', // 举报状态
        index: 0
      },
      is_report: false, // 是否显示举报框
      radiolist: [
        {name: '垃圾信息'},
        {name: '不实信息'},
        {name: '淫秽色情'},
        {name: '违法信息'}
      ],
      radiolist_num: 0, // 举报类别id
      click_video: false,
      click_video_index: -1,
      is_parklink: true
    }
  },
  mounted () {
    this.onload()
  },
  methods: {
    onload () {

    },
    parentMsg (data) {
      this.items = data.items
      this.itemLen = data.itemLen
      this.userId = data.userId
      this.is_parklink = data.parklink
    },
    //            点击图片放大
    onClickMe (img, index) {
      this.is_preview = true
      this.message = img
      this.index = index
      return false
    },
    parkdata (parkId) {
      if (this.is_parklink) {
        this.$router.push({
          path: '/note/NoteIndex',
          query: {pid: parkId, type: 'parkNote'}
        })
      } else {
        this.$emit('getparkdata', parkId)
      }
    },
    // 点赞
    postLike (nid, index) {
      if (!this.check_login()) {
        MessageBox({
          title: '提示',
          message: '确认登陆吗？',
          showCancelButton: true
        }).then(action => {
          if (action == 'confirm') {
            this.get_User_Id()
          }
        })
      } else {
        this.$ajax('post', 'TravelNotes/clickLike', {user_id: this.userId, travel_notes_id: nid}, (res) => {
          if (res.data.id == 0) {
            var items = this.items[index]
            if (items.like == 1) {
              this.$set(items, 'like', 0)
              items.like_numbers--
            } else {
              this.$set(items, 'like', 1)
              items.like_numbers++
            }
            this.$set(items, 'like_numbers', items.like_numbers)
          }
        })
      }
    },
    //            设置
    setting (e, travel_notes_id, i, user_be_id) {
      this.sheetVisible = !this.sheetVisible
      this.travel_notes_id = travel_notes_id
      this.user_be_id = user_be_id
      this.$set(this.setting_status, 'collect', e.target.getAttribute('collect'))
      this.$set(this.setting_status, 'attention', e.target.getAttribute('attention'))
      this.$set(this.setting_status, 'report', e.target.getAttribute('report'))
      this.$set(this.setting_status, 'index', i)
      if (this.setting_status.collect == 0) {
        this.$set(this.setting_info[0], 'name', '收藏')
      } else {
        this.$set(this.setting_info[0], 'name', '取消收藏')
      }
      if (this.setting_status.attention == 0) {
        this.$set(this.setting_info[1], 'name', '关注')
      } else {
        this.$set(this.setting_info[1], 'name', '取消关注')
      }
      if (this.setting_status.report == 0) {
        this.$set(this.setting_info[2], 'name', '举报')
      } else {
        this.$set(this.setting_info[2], 'name', '已举报')
      }
    },
    //            收藏
    collect () {
      if (!this.check_login()) {
        MessageBox({
          title: '提示',
          message: '请先登陆',
          showCancelButton: true
        }).then(action => {
          if (action == 'confirm') {
            localStorage.setItem('login_url', location.hash)
            this.$router.push({path: '/my/login'})
          }
        })
      } else {
        this.$ajax('post', 'TravelNotes/collectTravelNotes', {
          user_id: this.userId,
          travel_notes_id: this.travel_notes_id
        }, (res) => {
          if (res.data.id == 0) {
            if (this.setting_status.collect == 0) {
              Toast('收藏成功')
            } else {
              Toast('取消收藏成功')
            }
            this.$set(this.setting_status, 'collect', res.data.data.is_cancel)
            $('.feed-item-downbtn')[this.setting_status.index].setAttribute('collect', res.data.data.is_cancel)
          }
        })
      }
    },
    //            关注
    attention () {
      if (!this.check_login()) {
        MessageBox({
          title: '提示',
          message: '请先登陆',
          showCancelButton: true
        }).then(action => {
          if (action == 'confirm') {
            localStorage.setItem('login_url', location.hash)
            this.$router.push({path: '/my/login'})
          }
        })
      } else {
        this.$ajax('post', 'TravelNotes/attentionUser', {
          user_main_id: this.userId,
          user_be_id: this.user_be_id
        }, (res) => {
          if (res.data.id == 0) {
            if (this.setting_status.attention == 0) {
              Toast('关注成功')
            } else {
              Toast('取消关注成功')
            }
            this.$set(this.setting_status, 'attention', res.data.data.attention)
            $('.feed-item-downbtn')[this.setting_status.index].setAttribute('attention', res.data.data.attention)
          }
        })
      }
    },
    //            举报
    report () {
      if (!this.check_login()) {
        MessageBox({
          title: '提示',
          message: '请先登陆',
          showCancelButton: true
        }).then(action => {
          if (action == 'confirm') {
            localStorage.setItem('login_url', location.hash)
            this.$router.push({path: '/my/login'})
          }
        })
      } else {
        if (this.setting_status.report == 0) {
          this.is_report = !this.is_report
        } else {
          Toast('已经举报过了')
        }
      }
    },
    click_report () {
      if (this.radiolist_num == 0) {
        Toast('请选择举报类别')
        return
      }
      this.$ajax('post', 'index/clickReport', {
        user_id: this.userId,
        travel_notes_id: this.travel_notes_id,
        report_id: this.radiolist_num
      }, (res) => {
        if (res.data.id == 0) {
          if (this.data.data.report == 1) {
            Toast('举报成功')
            this.$set(this.setting_status, 'attention', res.data.data.report)
            $('.feed-item-downbtn')[this.setting_status.index].setAttribute('attention', res.data.data.report)
          } else {
            Toast('操作失败，请稍后再试！')
          }
          this.is_report = false
          this.radiolist_num = 0
        }
      })
    },
    //            加载更多
    loadBottom () {
      var that = this
      var itemLen = that.items.length
      that.page = that.page + 1
      setTimeout(() => {
        if (itemLen % 10 == 0) {
          //                        that.getData(that.page);
          this.$emit('getData', that.page)
        } else {
          that.allLoaded = true
          Toast('没有更多了')
        }
        that.$refs.loadmore.onBottomLoaded()
      }, 1500)
    },
    click_video_paly (index) {
      this.click_video = true
      this.click_video_index = index
    },
    click_video_remove (e) {
      this.click_video = false
      $(e.target).siblings('video')[0].pause()
    },
    audio_paly (e) {
      var audio = $('.audio')
      var video = $('.video')
      if (audio) {
        for (var i = 0; i < audio.length; i++) {
          $('.audio').eq(i).find('audio')[0].pause()
        }
      }
      if (video) {
        for (var i = 0; i < video.length; i++) {
          $('.video').eq(i).find('video')[0].pause()
        }
      }
      e.target.childNodes[0].play()
    },
    getRowType (value) {
      var video = $('.video video')[0]
      if (video) {
        value += 1
      }
      var rowType = ''
      if (value == 1) {
        rowType = 'row1'
      } else if (value == 2 || value == 4) {
        rowType = 'row2'
      } else if (value >= 5 || value == 3) {
        rowType = 'row3'
      }
      return rowType
    }
  }
}
</script>

<style scoped>
    /*模块标题*/
    .feed-list {
        background: #fff;
    }

    .feed-title {
        overflow: hidden;
        padding: 10px;
        position: relative;
    }

    .feed-title h1 {
        height: 2rem;
        line-height: 2rem;
        font-size: .8rem;
        color: #666;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        padding-left: 28px;
    }

    /*.note .feed-title h1 {*/
    /*background-image: url(../assets/img/feed-title-note.png);*/
    /*}*/

    .feed-title .more {
        position: absolute;
        border-right: 10px solid transparent;
        right: 0;
        top: 0;
        height: 2rem;
        line-height: 2rem;
        color: #999;
        background: url(../assets/img/feed-title-more.png) right center no-repeat;
        background-size: auto 15px;
        padding-left: .5rem;
        padding-right: 1rem;
    }

    .feed-title .more:active {
        background-color: #fafafa;
        border-color: #fafafa;
        -webkit-tap-highlight-color: transparent;
    }

    /*图片格子显示*/
    .img-box {
        width: 100%;
        margin-left: -3px;
        overflow: hidden;
    }

    .img-box .item,
    .img-box .video {
        /*图片格子默认样式2列*/
        position: relative;
        float: left;
        width: 50%;
        padding: 0;
        margin: 0;
        border-width: 0 0 3px 3px;
        border-style: solid;
        border-color: #fff;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .img-box .item p {
        padding-top: 100%;
        position: relative;
        display: block;
        background-size: cover;
        background-position: center;
    }

    .img-box.row4 .item {
        /*图片格子4列显示*/
        width: 25%;
    }

    .img-box.row3 .item {
        /*图片格子3列显示*/
        width: 33.3333%;
    }

    .img-box.row1 .item {
        /*图片格子1列显示*/
        width: 80%;
    }

    .img-box.row1 .item p {
        /*图片格子1列显示*/
        width: 100%;
        padding-top: 60%;
    }

    /*视频*/
    .img-box.row4 .video {
        /*图片格子4列显示*/
        width: 25%;
    }

    .img-box.row3 .video {
        /*图片格子3列显示*/
        width: 33.3333%;
    }

    .img-box.row1 .video {
        /*图片格子1列显示*/
        padding-top: 60%;
        width: 80%;
    }

    .img-box .video .box {
        width: 100%;
        padding-top: 100%;
        position: relative;
        background-color: #000;
    }

    .img-box.row1 .video .box {
        width: 100%;
        padding-top: 60%;
    }

    .img-box .video video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .img-box .video .box b {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../assets/img/icon_video_play.png) center no-repeat;
        background-size: 50px;
    }

    .img-box .video .box i {
        display: none;
        position: absolute;
        z-index: 5;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        font-style: normal;
        background: rgba(0, 0, 0, 0.5);
    }

    .img-box .video.active {
        width: 100%;
    }

    .img-box .video.active .box b {
        display: none;
    }

    .img-box .video.active .box i {
        display: block;
    }

    .feed-item {
        padding-top: 10px;
        border-bottom: 3px solid #f2f2f2;
        position: relative;
    }

    .feed-item-downbtn {
        position: absolute;
        top: .8rem;
        right: .4rem;
        width: 2rem;
        height: 2rem;
        background: url(../assets/img/feed-downlist.png) center no-repeat;
        background-size: 1rem;
    }

    .feed-item-downbtn:active {
        background-color: #f2f2f2;
    }

    .feed-item-top {
        overflow: hidden;
        padding: .4rem 0;
    }

    .feed-item-top .avatar {
        margin-right: .4rem;
    }

    .feed-item-top h1 {
        padding: 2px 0;
        font-size: .7rem;
    }

    .feed-item-top h1 .name {
        color: #ff9a80;
        font-size: .8rem;
    }

    .feed-item-top h2 {
        font-size: .6rem
    }

    .feed-item-top h2 .time {
        color: #999;
        background: url(../assets/img/feed-item-time.png) left center no-repeat;
        background-size: auto 80%;
        padding-left: 20px;
    }

    .feed-item-top h2 .location {
        color: #666;
        margin-left: 22px;
        background: url(../assets/img/landmark.png) left center no-repeat;
        background-size: auto 80%;
        padding-left: 20px;
    }

    .feed-item-body {
        padding: 0 .4rem;
        display: block;
    }

    .feed-item-body .text {
        font-size: .7rem;
        color: #333333;
        padding: 10px 0 0 0;
        overflow: hidden;
    }

    .feed-item-body .img-box {
        padding: .4rem 0 0 0;
    }

    .img-box .item img {
        width: 100%;
        height: 100%;
    }

    .mint-spinner-snake {
        margin: 10px 0;
        display: inline-block;
        text-align: center;
    }

    /*标签*/
    .feed-item-tag {
        padding-top: 5px;
        overflow: hidden;
    }

    .feed-item-tag a {
        font-size: .6rem;
        color: #666;
        background: url(../assets/img/feed-item-tag.png) left 6px no-repeat;
        background-size: .8rem;
        padding: 5px 0 5px 1rem;
        margin-right: 10px;
        /*float: left;*/
        display: block;
    }

    /*工具栏*/
    .feed-item-tool {
        margin-top: 5px;
        border-top: .1rem solid #f2f2f2;
        border-bottom: .1rem solid #f2f2f2;
        overflow: hidden;
    }

    .feed-item-tool a {
        float: left;
        color: #999;
        font-size: .8rem;
        padding: 3px 0;
        margin: 5px 0;
        width: 33.33333%;
        text-align: center;
        overflow: hidden;
    }

    .feed-item-tool span {
        background: left center no-repeat;
        background-size: contain;
        padding-left: 1.4rem;
    }

    .feed-item-tool .zan span {
        background-image: url(../assets/img/feed-tool-zan.png);
    }

    .feed-item-tool .zan.yes span {
        background-image: url(../assets/img/home_icon_hot_list.png);
    }

    .feed-item-tool .ping {
        box-sizing: border-box;
        border: .1rem solid #f2f2f2;
        border-width: 0 1px;
    }

    .feed-item-tool .ping span {
        background-image: url(../assets/img/feed-tool-ping.png);
    }

    .feed-item-tool .zhuan span {
        background-image: url(../assets/img/feed-tool-zhuan.png);
    }

    .page-content .feed-list .radiolist_box {
        position: fixed;
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        z-index: 11;
    }

    .page-content .feed-list .radiolist h2 {
        font-size: 16px;
    }

    .page-content .feed-list .radiolist {
        display: inline-block;
        width: 80%;
        margin-top: 50%;
        background-color: #f5f5f5;
        border-radius: 4px;
        padding: 10px;
    }

    .page-content .feed-list .radiolist li {
        width: 50%;
        float: left;
        padding: 10px 0;
        font-size: 14px;
    }

    .page-content .feed-list .radiolist input {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 1px solid #999;
        -webkit-appearance: none;
        border-radius: 50%;
        vertical-align: middle;
    }

    .page-content .feed-list .radiolist input.checkbox {
        background-color: #ff9a80;
        border: 1px solid #ff9a80;
    }

    .page-content .feed-list .radiolist label {
        vertical-align: middle;
    }

    .page-content .feed-list .mint-header {
        font-size: 14px;
    }

    .feed-list .feed-item .audio {
        height: 24px;
        width: 72px;
        border: 1px solid #e5e5e5;
        border-radius: 10px;
        margin-bottom: 8px;
    }

    .feed-list .feed-item .audio i {
        height: 22px;
        width: 22px;
        margin: 1px 2px;
        border-radius: 10px;
        background-image: url(../assets/img/icon-play.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: inline-block;
    }

    .feed-list .feed-item .audio span {
        line-height: 24px;
        padding-right: 4px;
        font-size: 14px;
    }

    .feed-list .feed-item .images .video {
        position: relative;
        float: left;
        width: 50%;
        padding: 0;
        margin: 0;
        border-width: 0 0 .3rem .3rem;
        border-style: solid;
        border-color: #fff;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .feed-list .feed-item .images .video.active {
        width: 100%;
    }

    .feed-list .feed-item .images .video .box i {
        position: absolute;
        z-index: 5;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        font-style: normal;
        background: rgba(0, 0, 0, 0.5);
    }

    .feed-item-body .text {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .note-track-card {
        height: 100px;
    }

    .note-track-card .map {
        width: 33.3333%;
        border-width: 0 3px 0 0;
        border-style: solid;
        border-color: #fff;
        box-sizing: border-box;
    }

    .note-track-card .map-pic {
        height: 100px;
        background-position: center;
        background-size: cover;
        /*float: left;*/
        margin-right: 10px;
        overflow: hidden;
    }

</style>
