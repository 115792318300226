<template>
  <div v-title data-title="赞">
    <div class="message">
      <mt-header fixed title="赞">
        <a slot="left">
          <mt-button icon="back" @click="$router.go(-1)"></mt-button>
        </a>
      </mt-header>
    </div>

    <div class="page-content">
      <div class="container container-wh">
        <div class="message-list" v-if="likeList != '' ">
          <mt-loadmore
            :bottom-method="loadBottom"
            :bottom-all-loaded="allLoaded"
            ref="loadmore"
            :auto-fill="false"
          >
            <div class="user-card clearfix" v-for="like in likeList" :key="like.id" :id="like.id">
              <div class="user-content clearfix">
                <div class="avatar" v-if="like.user_id != user_id">
                  <router-link
                    :to="{name:'otherIndex', params: { uid: like.user_id }}"
                    v-if="like.user_icon == '' "
                  >
                    <img src="../../assets/img/apply_avatar_bg.png" />
                  </router-link>
                  <router-link
                    :to="{name:'otherIndex', params: { uid: like.user_id }}"
                    v-if="like.user_icon != '' "
                  >
                    <img :src="like.user_icon" />
                  </router-link>
                </div>
                <div class="avatar" v-if="like.user_id == user_id">
                  <router-link
                    :to="{name:'MyIndex', params: { uid: like.user_id }}"
                    v-if="like.user_icon == '' "
                  >
                    <img src="../../assets/img/apply_avatar_bg.png" />
                  </router-link>
                  <router-link
                    :to="{name:'MyIndex', params: { uid: like.user_id }}"
                    v-if="like.user_icon != '' "
                  >
                    <img :src="like.user_icon" />
                  </router-link>
                </div>

                <div class="name">
                  <span v-if="like.user_id != user_id">
                    <router-link
                      :to="{name:'otherIndex', params: { uid: like.user_id }}"
                    >{{like.user_nick}}</router-link>
                  </span>
                  <span v-if="like.user_id == user_id">
                    <router-link
                      :to="{name:'MyIndex', params: { uid: like.user_id }}"
                    >{{like.user_nick}}</router-link>
                  </span>

                  <div class="chat-time">{{like.like_time}}</div>
                  <div class="last-chat">
                    <div class="like-me">赞了这条游记</div>
                  </div>
                </div>
              </div>
              <!--like.notes_or_spots == 1  跳转到足迹详情-->
              <a
                href="javascript:;"
                @click="click_path_new(like.is_spots,like.notes_or_spots,like.id)"
                class="note-card"
              >
                <div class="pic" v-imgBg="like.img"></div>
                <div class="author">@{{user_name}}</div>
                <div class="content">{{like.travel_notes_title}}</div>
              </a>
              <!--<router-link :to="{ name: 'NoteDetail', params: { id: like.id }}" class="note-card" v-else>-->
              <!--<div class="pic" v-imgBg="like.img"></div>-->
              <!--<div class="author">@{{user_name}}</div>-->
              <!--<div class="content">{{like.travel_notes_title}}</div>-->
              <!--</router-link>-->
            </div>
          </mt-loadmore>
        </div>
        <div v-else class="foot_noinfo">
          <img src="/static/img/foot_noinfo@2x.14391c2.png" alt />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Indicator, Loadmore } from "mint-ui";
import { Toast } from "mint-ui";

export default {
  data() {
    return {
      likeList: [],
      user_name: "",
      user_id: "",
      page: 1,
      allLoaded: false, //是否加载
      totalCount: "" //初始化方法中的数量总数
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      this.getLike(this.page);
    },

    // 得到点赞
    getLike(page) {
      var that = this;
      var user_info = JSON.parse(localStorage.user_info);

      this.user_name = user_info.user_nick;

      var user_id = this.get_User_Id();
      this.user_id = user_id;

      var data = {
        user_id: user_id,
        page: page
      };

      this.$ajax("post", "Common/getLike", data, function(res) {
        //                    console.log(res.data.data);
        that.likeList = that.likeList.concat(res.data.data);
        that.totalCount = res.data.data.length;
        console.log(that.likeList);
      });
    },
    click_path_new(is_spots, notes_or_spots, id) {
      if (notes_or_spots == 1) {
        if (is_spots == 1) {
          this.$router.push({
            path: "/footprints/PrimaryScenic",
            query: { sid: id, id_: 0 }
          });
        } else {
          this.$router.push({
            path: "/footprints/FootprintsParticulars",
            query: { id: id }
          });
        }
      } else {
        this.$router.push({ name: "NoteDetail", params: { id: like.id } });
      }
    },
    //  下拉加载页面
    loadBottom() {
      setTimeout(() => {
        if (this.totalCount % 10 == 0) {
          this.page = this.page + 1;
          this.getLike(this.page);
        } else {
          this.allLoaded = true; // 若数据已全部获取完毕
          Toast("没有更多了");
        }
        this.$refs.loadmore.onBottomLoaded();
      }, 500);
    }
  }
};
</script>
<style scoped>
.message-list .user-card .last-chat .like-me {
  padding-top: 0.5rem;
  background: url(../../assets/img/home_icon_hot_list.png) no-repeat;
  background-position: 50% 80%;
  background-repeat: no-repeat;
  background-size: 1rem;
}

.no-data {
  text-align: center;
  padding-top: 40px;
  font-size: 14px;
}
</style>
