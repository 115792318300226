<template>
  <div class="pixi-load-container">
    <div class="pixi-page-1" v-show="show_start">
      <div class="bg-mask"></div>
      <div class="pl-bg">
        <img :src="bg_img" alt />
      </div>
      <div class="pl-logo">
        <img class="logo-icon" :src="map_info.logo_" alt />
      </div>
      <div class="pl-main">
        <div class="pl-mascot" v-if="loaded">
          <div class="mascot-wrapper" v-for="(item, i) in filterLogo(info)" :key="i">
            <div class="mascot-back"></div>
            <transition
              appear
              :appear-class="'appear-' + i"
              :appear-to-class="'appear-to-' + i"
              :appear-active-class="'appear-active-' + i"
            >
              <img :src="item.logo_" alt />
              <!-- <span>吉祥物</span> -->
            </transition>
          </div>
        </div>
      </div>
      <div class="pl-button" v-if="loaded">
        <button @click="jumpToArea">点击进入</button>
      </div>
    </div>

    <!-- 第二个页面 -->
    <div class="pixi-page-2" v-show="!show_start">
      <img class="pixi-2-bg" :src="map_info.bg_" alt />
      <div class="pa-header">
        <!-- 顶部缩略图 -->
        <swiper class="swiper-container-0" :options="swiperThumb" ref="thumbSwiper">
          <swiper-slide class="swiper-slide" v-for="(item,i) in filterArea(info)" :key="i">
            <div class="slide-wrapper" @click="jumpToMap(i)">
              <img :src="item.logo_" alt />
              <span>{{item.title}}</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="pa-main">
        <!-- 区域切换 -->
        <swiper class="swiper-container-1" :options="swiperOption" ref="mainSwiper">
          <swiper-slide class="swiper-slide" v-for="(item, i) in filterArea(info)" :key="i">
            <div
              class="slide-body"
              :style="'background-image:url(' + item.baseUrl + ')'"
              @click="jumpToMap(item.index_)"
            >
              <span class="area-title">{{item.title}}</span>
              <div class="area-audio" v-if="item.zhAudioPath != ''">
                <i
                  v-if="audio_play_list[i] == 0"
                  :data="item.is_play"
                  class="iconfont iconbofang2 audio-btn-play"
                  @click.stop="playAudio(i)"
                ></i>
                <i v-else class="iconfont iconbofang1 audio-btn-pause" @click.stop="playAudio(i)"></i>
              </div>
              <div class="slide-notes" v-if="item.notes">{{item.notes}}</div>

              <div class="btn-detail" v-else>
                <span class="detail-text">查看详情</span>
                <i class="iconfont iconmove-right"></i>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>

      <!-- 音频 -->
      <audio src ref="audio"></audio>
    </div>

    <!-- 联系我们 -->
    <technical-support
      v-if="id != '1328'"
      ts_type_="pixiMap"
      contact_side="text"
      :text="map_info.label_name + ' | 武汉地普游科技有限公司'"
      :id="id"
    ></technical-support>
  </div>
</template>

<script>
import { Toast } from 'mint-ui'

import SwiperClass, { Pagination } from 'swiper'
import { Swiper ,SwiperSlide} from 'vue-awesome-swiper'
import TechnicalSupport from '../../components/TechnicalSupport'

// import swiper module styles
import 'swiper/css'
import 'swiper/css/pagination'

export default {
  components: {
    Swiper,
    SwiperSlide,
    'technical-support': TechnicalSupport
  },
  data () {
    return {
      // 主swiper
      swiperOption: {
        autoplay: false,

        speed: 300,
        initialSlide: 2,
        pagination: {
          el: '.swiper-pagination'
        },
        spaceBetween: 40,
       
      },
      // 顶部关联swiper
      swiperThumb: {
        autoplay: false,

        speed: 300,
        initialSlide: 2,
        spaceBetween: 10 // slide之间的距离
      },
      /* 授权及相关 */
      ts_type_: 'label',
      park_id: 298,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      id: this.$route.query.id ? this.$route.query.id : 0,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      /* 后台数据 */
      bg_list: [], // 背景图列表
      map_info: [],
      info: [],
      /*  */
      show_start: true, // 显示开始页面
      mascot_img_list: [], // 吉祥物图片列表，用于计算加载进度
      show_button: false, // 显示开始按钮
      count: 0, // 计算加载的图片
      loaded: false, // 加载完成
      bg_img: '', // 从列表中随机选中的背景图
      imglength: 0, // 加载的总的图片数量
      mainSwiper: {}, // 主区域是swiper
      thumbSwiper: {}, // 顶部关联swiper
      is_show_to_url: '',

      // 音频播放
      audio_dom: '',
      audio_play: false,
      audio_play_list: []
    }
  },
  mounted () {
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    //console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有union_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }

    this.dealWithUrl(window.location.href)
    /* 微信分享 */
    if (this.is_weixin) {
      if (this.open_id) {
      } else {
        this.authorize()
      }
    }

    /* 获取数据 */
    this.getData()

    this.$nextTick(() => {
      console.log(1,this.$refs.mainSwiper);
      this.mainSwiper = this.$refs.mainSwiper//.swiper
      console.log(2,this.mainSwiper)
      this.thumbSwiper = this.$refs.thumbSwiper//.swiper
      //this.mainSwiper.controller.control = this.thumbSwiper
      //this.thumbSwiper.controller.control = this.mainSwiper
      this.audio_dom = this.$refs.audio
      this.audio_dom.addEventListener('ended', () => {
        this.audio_play = false
        this.checkIsPlay()
      })
    })
  },

  methods: {
    // 过滤area
    filterArea (ls) {
      return ls.filter(item => {
        return item.is_show_area == 1
      })
    },
    // 过滤logo
    filterLogo (ls) {
      return ls.filter(item => {
        return item.is_show_logo == 1
      })
    },

    //   播放语音
    playAudio (i) {
      if (this.is_weixin) {
        if (this.info[i].zhAudioPath != '') {
          if (this.audio_play_list[i] != 0) {
            this.$set(this.audio_play_list, i, 0)
            this.audio_dom.pause()
          } else {
            this.checkIsPlay()
            this.$set(this.audio_play_list, i, 1)
            this.audio_dom.src = this.info[i].zhAudioPath
            this.audio_dom.play()
            // this.audio_dom.playbackRate = 4;  // 控制播放速度
          }
        } else {
          Toast('没有语音')
        }
      } else {
        Toast('请在微信中打开')
      }
    },
    // 修改播放标记
    checkIsPlay () {
      this.audio_play_list.forEach((item, i) => {
        if (item != 0) {
          this.$set(this.audio_play_list, i, 0)
        }
      })
    },
    // 获取数据
    getData () {
      if (this.id && this.token) {
        this.$ajax(
          'post',
          'Label/get_map_data_by_label',
          {
            id: this.id,
            token: this.token
          },
          res => {
            // console.log(res);
            if (res.data.id == 0) {
              // console.log(res.data.data);
              this.bg_list = res.data.data.bg_list || '' // 背景图片列表
              this.info = res.data.data.data || '' // 数据
              this.map_info = res.data.data.map || ''
              this.is_show_to_url = res.data.data.is_show_to_url || ''
              // 图片加载进度
              this.preloadFirst()
              document.title = this.map_info.label_name

              // 给音频设置is_play
              for (let i = 0; i < this.info.length; i++) {
                this.audio_play_list[i] = 0
              }

              // 微信分享
              localStorage.setItem('wx_content', res.data.data.map.content)
              localStorage.setItem('wx_title', res.data.data.map.label_name)
              localStorage.setItem('wx_img', res.data.data.map.logo_)

              this.wx_share(window.location.href)
            } else {
              Toast('获取数据失败')
            }
          }
        )
      } else {
      }
    },
    // 背景图片
    preloadFirst () {
      let i = Math.floor(Math.random() * this.bg_list.length)
      this.bg_img = this.bg_list[i]
      let imgs = []
      if (this.info != '') {
        this.info.forEach(item => {
          this.mascot_img_list.push(item.logo_)
        })
      }

      imgs.concat(this.mascot_img_list)
      imgs.unshift(this.bg_img)
      this.imglength = imgs.length

      for (let img of imgs) {
        let image = new Image()
        image.src = img

        image.onload = () => {
          this.count++
          if (this.count == this.imglength) {
            console.log('图片加载完成')
            this.loaded = true
          }
        }
      }
    },
    // 跳转目录
    jumpToArea () {
      this.show_start = false
    },
    /* 第二个页面 */
    //   进入地图
    jumpToMap (i) {
      let path = ''
      console.log(this.is_show_to_url)
      if (this.id == '1837') {
        path = '/nihewan/map'
      } else if (this.is_show_to_url === 'leafletMap') {
        path = '/DrawMap/LeafletMap'
      } else {
        path = '/pixi/pixiMap'
      }
      this.$router.push({
        path: path,
        query: {
          area_index: i,
          id: this.id
        }
      })
    },

    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&state=pixiLoad'
      window.location.href = open_url
    }
  }
}
</script>

<style scoped>
/* 首页的 */
.pixi-load-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;
}
.bg-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
@media screen and (max-width: 596px) {
  /* 背景图 */
  .pl-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .pl-bg img {
    display: block;
    height: 100%;
    position: relative;
    animation: bg 60s linear infinite alternate;
  }
  @keyframes bg {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100% + 100vw));
    }
  }

  /* logo */
  .pl-logo {
    position: relative;
    height: 20vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .pl-logo .logo-icon {
    display: inline-block;
    width: 36%;
  }

  /* main */
  .pl-main {
    width: 100%;
    height: 60vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .pl-main .pl-mascot {
    width: 80%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
  }
  .pl-main .pl-mascot .mascot-wrapper {
    position: relative;
    width: 24vw;
    height: 24vw;
    margin: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 1;
  }
}
/* PC端 */
@media screen and (min-width: 597px) {
  .pl-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  /* 背景图 */
  .pl-bg img {
    display: block;
    width: 100% !important;
    height: 100%;
    object-fit: cover;
  }
  /* logo */
  .pl-logo {
    position: relative;
    height: 20vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .pl-logo .logo-icon {
    display: inline-block;
    height: 140px;
  }
  /* main */
  .pl-main {
    width: 100%;
    height: 60vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .pl-main .pl-mascot {
    width: 420px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
  }
  .pl-main .pl-mascot .mascot-wrapper {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 1;
  }
}
.pl-main .pl-mascot .mascot-wrapper .mascot-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: -1;
  animation: wrapper 2s ease 1 both;
}
@keyframes wrapper {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.pl-main .pl-mascot .mascot-wrapper img {
  display: inline-block;
  /* width: 100%; */
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #fff; */
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
}

.appear-0 {
  transform: translate(-100%, -100%);
}
.appear-to-0 {
  transform: translate(0, 0);
}
.appear-active-0 {
  transition: ease 2s;
}
.appear-1 {
  transform: translate(0, -100%);
}
.appear-to-1 {
  transform: translate(0, 0);
}
.appear-active-1 {
  transition: ease 2s;
}
.appear-2 {
  transform: translate(100%, -100%);
}
.appear-to-2 {
  transform: translate(0, 0);
}
.appear-active-2 {
  transition: ease 2s;
}
.appear-3 {
  transform: translate(-100%, 100%);
}
.appear-to-3 {
  transform: translate(0, 0);
}
.appear-active-3 {
  transition: ease 2s;
}
.appear-4 {
  transform: translate(0, 100%);
}
.appear-to-4 {
  transform: translate(0, 0);
}
.appear-active-4 {
  transition: ease 2s;
}

.appear-5 {
  transform: translate(100%, 100%);
}
.appear-to-5 {
  transform: translate(0, 0);
}
.appear-active-5 {
  transition: ease 2s;
}
/* 点击进入 */
.pl-button {
  position: relative;
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
}
.pl-button button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  /* background: rgba(0, 0, 0, 0.6); */
  background: linear-gradient(
    135deg,
    rgba(5, 255, 180, 0.81),
    rgba(2, 244, 252, 0.81),
    rgba(2, 235, 252, 0.81),
    rgba(5, 176, 255, 0.81)
  );
  border: none;
  color: #034d57;

  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  user-select: none;
  padding: 14px 36px;
  font-weight: 600;
  font-size: 18px;
  border-radius: 2em;
  transform: scale(0);
  animation: btn 0.5s linear 2s 1 both;
}
@keyframes btn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* 导航页面 */

/* 顶部信息 */
.pixi-page-2 {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.pixi-page-2 .pixi-2-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: 50% 100%; */
}
.pa-header {
  width: 100%;
  box-sizing: border-box;
  padding-top: 2vh;
}
@media screen and (max-width: 596px) {
  .swiper-container-0 {
    width: 25vw;
    margin: 0 auto;
  }
  .swiper-container-0 .swiper-slide img {
    height: 16vw;
  }
}

@media screen and (min-width: 597px) {
  .swiper-container-0 {
    width: 120px;
    margin: 0 auto;
  }
  .swiper-container-0 .swiper-slide img {
    width: 80px;
  }
}
.swiper-container {
  overflow: visible;
}
.swiper-container-0 .swiper-slide {
  position: relative;
  box-sizing: border-box;
  border: 2px solid transparent;
  opacity: 0.2;
}
.swiper-container-0 .swiper-slide .slide-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swiper-container-0 .swiper-slide span {
  width: 100%;
  padding: 4px 6px;
  font-size: 0.68rem;
  text-align: center;
  color: #fff;
  border-radius: 2em;
  white-space: nowrap;
  background: linear-gradient(135deg, #0edefa, #25baf5, #05b0ff);
}

.swiper-container-0 .swiper-slide-active {
  opacity: 1;

  border-image: linear-gradient(135deg, #d1ff05, #02ebfc, #05b0ff) 30 30;
}

.swiper-container-0 .swiper-slide-prev {
  opacity: 0.6;
}
.swiper-container-0 .swiper-slide-next {
  opacity: 0.6;
}

/* 中间信息 */
.pa-main {
  width: 100%;
  height: 50vh;
  padding-top: 10vh;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 596px) {
  .swiper-container-1 {
    width: 64%;
    height: 100%;
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    overflow: visible;
  }
}

@media screen and (min-width: 597px) {
  .swiper-container-1 {
    width: 33vh;
    height: 100%;
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    overflow: visible;
  }
}
.swiper-container-1 .swiper-slide {
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-sizing: border-box;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  opacity: 0.9;
  transition: ease 0.3s;
  cursor: pointer;
}

.swiper-container-1 .swiper-slide-active {
  transform: scale(1.12);
  opacity: 1;
  transition: ease 0.3s;
}

.swiper-container-1 .swiper-slide .slide-body {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.swiper-container-1 .swiper-slide .slide-body .area-title {
  position: absolute;
  display: inline-block;
  padding: 12px 8px;
  border: 1px solid #fff;
  font-size: 14px;
  width: 14px;
  border-radius: 4px;
  top: 1rem;
  left: 1rem;
  color: #fff;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}
.swiper-container-1 .swiper-slide .slide-body .area-audio {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swiper-container-1 .swiper-slide .slide-body .audio-btn-play {
  font-size: 3rem;
  color: #fff;
}

.swiper-container-1 .swiper-slide .slide-body .audio-btn-pause {
  font-size: 3rem;
  color: #fff;
}

.swiper-container-1 .swiper-slide .slide-body .slide-notes {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  text-shadow: -1px 1px rgba(2, 170, 148, 0.8), -1px 2px rgba(2, 170, 148, 0.7),
    -1px 3px rgba(2, 170, 148, 0.6), 0 5px 10px #000;
}

.swiper-container-1 .swiper-slide .slide-body .btn-detail {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  box-sizing: border-box;
  font-size: 0.68rem;
  color: #81f0ff;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.5);
  transform: translateX(-50%);
  opacity: 0;
  transition: ease 1s;
}

.swiper-container-1 .swiper-slide-active .slide-body .btn-detail {
  transform: translateX(0);
  opacity: 1;
  transition: ease 1s;
}

.swiper-container-1 .swiper-slide .slide-body .btn-detail > .iconfont {
  vertical-align: middle;
  font-size: 0.68rem;
}

.swiper-container-1 .swiper-pagination {
  bottom: -1rem;
}

.swiper-container-1 .swiper-pagination :deep() .swiper-pagination-bullet {
  width: 24px;
  height: 4px;
  border-radius: 0;
  opacity: 0.3;
  background-color: #fff;
}

.swiper-container-1 .swiper-pagination :deep() .swiper-pagination-bullet-active {
  background-color: #08d3ee;
  opacity: 1;
}
</style>
