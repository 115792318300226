<template>
  <div class="GuideOrderCancel" v-title data-title="取消订单">
    <mt-header fixed title="取消订单">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="order-detail-top">
        <div class="box">
          <div class="tit">
            <div v-if="status == 0">待付款</div>
            <div v-else-if="status == 1">商家已接单</div>
            <div v-else-if="status == 2">商家拒绝接单</div>

            <!-- 撤销 -->
            <div v-else-if="status == 3">正在撤销</div>
            <div v-else-if="status == 4">撤销失败</div>
            <div v-else-if="status == 5">撤销成功</div>

            <!-- 完成 -->
            <div v-else-if="status == 6">订单完成</div>

            <div>下单时间：{{orderTime}}</div>
          </div>
          <div class="info">
            <div class="order-id">订单编号：{{orderNum}}</div>
            <div class="price">￥{{orderPrice}}</div>
          </div>
        </div>
      </div>
      <div class="order-detail-main">
        <div class="order-cancel-help">
          <p>取消行程说明：</p>
          <p>1.行程一旦取消，不可恢复</p>
          <p>2.取消行程后，客户将获得全额退款</p>
          <p>3.多次取消行程将一定程度上影响您的服务评级，慎重操作</p>
        </div>
        <div class="order-why-cancel">
          <h1>
            <img src="../../assets/img/order_cancel_list_tit.png" />取消原因
          </h1>
          <mt-checklist class="why-checklist" v-model="value1" :options="options1"></mt-checklist>
          <mt-field
            class="other-why"
            label
            placeholder="请在这里填写其他取消原因"
            type="textarea"
            rows="4"
            v-model="reasonText"
          ></mt-field>
        </div>

        <div class="order-cancel-confirm" v-if="status != 3" @click="postReason()">确认取消行程</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";

export default {
  name: "order-why-cancel",

  data() {
    return {
      userId: "",
      orderId: this.$route.query.id,
      orderTime: this.$route.query.time,
      orderNum: this.$route.query.num,
      orderPrice: this.$route.query.price,
      status: this.$route.query.status,
      reasonId: "",
      reasonText: "",
      value1: []
    };
  },

  methods: {
    getLoginInfo: function() {
      //检测登录
      // 用户ID
      if (this.hybrid.hybrid.versions.myapp) {
        // FOR 安卓最美旅行家APP
        $(".mint-header").remove();
        $(".page-content").css("padding", "0");

        this.userId = JavaScriptInterface.getUserId();
      } else if (this.hybrid.hybrid.versions.myiOSapp) {
        // FOR IOS最美旅行家APP

        $(".mint-header").remove();
        $(".page-content").css("padding", "0");

        this.hybrid.hybrid.registerHandler(
          "getIOSUserId",
          (data, responseCallback) => {
            this.userId = data;
            var responseData = "已经收到IOS传来的userid";
            responseCallback(responseData);
          }
        );
      } else {
        // For web
        this.userId = this.get_User_Id();
      }
    },

    postReason() {
      var data = {
        order_id: this.orderId,
        reason_id: this.reasonId,
        reason_text: this.reasonText,
        user_id: this.userId
      };

      this.$ajax("post", "Service/remove_order", data, res => {
        console.log(res);
        if (res.data.id == 0) {
          Toast("取消订单成功");
          this.status = "3";
        }
      });
    }
  },

  watch: {
    value1(value) {
      this.reasonId = value.join(",");
    }
  },

  created() {
    this.options1 = ["我不想买了", "信息填写错误", "其他原因"];
    this.options1 = [
      {
        label: "我不想买了",
        value: "0"
      },
      {
        label: "信息填写错误",
        value: "1"
      },
      {
        label: "其他原因",
        value: "2"
      }
    ];
  },

  mounted() {
    this.getLoginInfo();
  }
};
</script>


<style scoped>
.order-detail-top .tit {
  justify-content: space-around;
}

.order-detail-main {
  background: transparent;
  padding-top: 5rem;
}

.GuideOrderCancel {
  font-size: 0.7rem;
}

.GuideOrderCancel >>> .mint-field-core {
  text-align: left;
}
.order-cancel-help {
  color: #999;
  margin: 0.5rem 0;
}

.order-why-cancel h1 {
  line-height: 1.6rem;
  background: #fff;
  padding: 0 0.5rem;
  border-bottom: 1px dashed #cacaca;
}

.order-why-cancel h1 img {
  float: left;
  height: 0.8rem;
  width: auto;
  margin: 0.5rem 0.5rem 0 0;
}

.order-why-cancel .why-checklist >>> .mint-checklist-label {
  padding: 0;
}

.order-why-cancel .why-checklist >>> .mint-checklist-title {
  margin: 0;
}

.order-why-cancel .other-why {
  margin-top: 0.5rem;
  color: #ccc;
}

.order-cancel-confirm {
  color: #fff;
  background: #ff7364;
  width: 100%;
  line-height: 1.6rem;
  border-radius: 9rem;
  text-align: center;
  margin-top: 1rem;
}
</style>

