<template>
  <div class="container" v-show="loaded" v-title data-title="泥河湾活动列表">
    <header class="header">
      <i class="back iconfont iconmenu2" @click="goBack"></i>
      <div class="search-handle">
        <i class="iconfont iconsearch"></i>
        <input class="inp" type="text" placeholder="搜相册" ref="search" v-model="searchValue" />
      </div>
      <i class="iconfont cancle iconclose" @click="searchValue=''" v-show="searchValue"></i>
    </header>
    <main class="main">
      <ul class="list">
        <li
          class="item"
          v-for="item in filterBySearch(list)"
          :key="item.aid"
          @click="navToAlbum(item.aid)"
        >
          <img :src="item.newUrl" class="img" />
          <div class="item-info">
            <div class="title">
              <span class="text">{{item.title}}</span>
            </div>
            <div class="content">
              <div class="time item">
                <span class="text">{{item.activity_begin_time}}</span>
              </div>
              <div class="numbers item">
                <i class="iconfont iconxiangce1"></i>
                <span class="numbers">{{item.img_numbers}}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </main>
    <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator } from 'mint-ui'
import TechnicalSupport from '../../components/TechnicalSupport'
export default {
  components: {
    'technical-support': TechnicalSupport
  },
  data () {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || '1837',
      version: '1.0.0',
      type_: 'label',
      backUrl: 'nihewanAlbumList',
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      open_id: localStorage.getItem('open_id_' + this.$route.query.id)
        ? localStorage.getItem('open_id_' + this.$route.query.id)
        : '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      // 请求数据
      page: 1,
      isLoading: false,
      loaded: false,
      haveMore: true,
      list: [],
      // 自定义
      searchValue: '',

      // company
      companyInfo: {},
      haveCompany: false
    }
  },
  mounted () {
    if (this.token) {
      // 已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id_' + this.id, this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.id, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.id, '')
      }
    }
    if (!this.open_id) {
      this.authorize()
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }

    this.getData()
    this.getCompanyInfo(this.id, this.token).then(
      res => {
        this.companyInfo = res
        this.haveCompany = true
      },
      err => {
        console.log(err)
      }
    )
    this.$nextTick(() => {
      // 在冒泡阶段处理滚动的事件
      window.addEventListener('scroll', this.throttle(this.handleScroll))
    })
  },
  methods: {
    // 相册
    navToAlbum (aid) {
      this.$router.push({
        path: '/album/album',
        query: {
          id: this.id,
          aid: aid
        }
      })
    },

    // 根据搜索值返回数组
    filterBySearch (ls) {
      return ls.filter(item => {
        return item.title.match(this.searchValue)
      })
    },
    // 上一级
    goBack () {
      this.$router.push({
        path: '/nihewan/home',
        query: {
          id: this.id
        }
      })
    },
    // 获取数据
    getData () {
      if (!this.haveMore || this.isLoading) {
        return false
      }

      this.isLoading = true
      Indicator.open('加载中...')
      // 获取相册

      this.$ajax(
        'post',
        'weixin/get_image_list',
        {
          park_id: this.park_id,
          token: this.token,
          page: this.page,
          where: 'head',
          version: this.version
        },
        res => {
          if (res.data.id === 0) {
            this.list.push(...res.data.data)
            this.page++
            const sUrl = window.location.href,
              wx_title = res.data.data[0].title,
              wx_content = res.data.data[0].shareContent,
              wx_img = res.data.data[0].newUrl
            if (this.is_weixin) {
              this.wx_share_callback(sUrl, {
                url: sUrl,
                id_: this.id,
                id_type_: this.backUrl,
                token: this.token,
                open_id: this.open_id,
                title: wx_title,
                content: wx_content,
                img: wx_img
              })
            }
          }

          if (
            res.data.id !== 0 ||
            !res.data.data ||
            res.data.data.length < 10
          ) {
            this.haveMore = false
          }

          this.loaded = true
          this.isLoading = false
          Indicator.close()
        }
      )
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=' +
        this.id +
        '&token=' +
        this.token +
        '&state=' +
        this.backUrl
      window.location.href = open_url
    },
    // 获取页面滚动的高度
    handleScroll () {
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight

      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight

      // 加载更多
      if (
        scrollTop + windowHeight > scrollHeight - 100 &&
        this.haveMore &&
        this.page > 1
      ) {
        this.getData()
      }
    },
    throttle (fn, interval = 100) {
      let canRun = true
      return function () {
        if (!canRun) return
        canRun = false
        setTimeout(() => {
          fn.apply(this, arguments)
          canRun = true
        }, interval)
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  background-color: rgb(243, 245, 248);
}

.header {
  position: fixed;
  height: 2.8rem;
  width: 100%;
  padding: 0.4rem 0.8rem 0.4rem 0.4rem;
  display: flex;
  box-sizing: border-box;
  box-shadow: 0 2px 2px -1px rgba(211, 211, 211, 0.4);
  background-color: #fff;
  z-index: 1;
}

.header > .back {
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}

.search-handle {
  flex: 1;
  height: 100%;
  padding-left: 1rem;
  border-radius: 2em;
  line-height: 2rem;
  font-size: 0.8rem;
  color: #666;
  box-sizing: border-box;
  background-color: rgb(243, 245, 248);
}

.search-handle > .iconfont {
  font-size: 0.8rem;
  color: #999;
}

.header > .iconclose {
  position: absolute;
  top: 0.4rem;
  right: 0.8rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  font-size: 0.8rem;
  color: #666;
}

/* main */
.main {
  width: 100vw;
  min-height: calc(100vh - 2rem);
  box-sizing: border-box;
  padding-top: 2.8rem;
  padding-bottom: 2rem;
  background-color: rgb(243, 245, 248);
}

.list {
  width: 100%;
  padding: 3vw;
  box-sizing: border-box;
}

.list::after {
  content: "";
  display: block;
  clear: both;
}

.list > .item {
  width: 45.5vw;
  margin-bottom: 3vw;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 10px #dfdfdf;
}

.list > .item:nth-child(even) {
  float: right;
}

.list > .item:nth-child(odd) {
  float: left;
}

.list > .item > .img {
  display: block;
  width: 100%;
  height: 45.5vw;
  object-fit: cover;
  box-sizing: border-box;
}

.item-info {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
}

.item-info > .title {
  width: 100%;
  font-size: 0.88rem;
  /* font-weight: bold; */
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item-info > .content {
  margin-top: 0.5rem;
  width: 100%;
  font-size: 0.74rem;
  font-family: "Times New Roman", Times, serif;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
.item-info > .content > .item {
  display: flex;
  align-items: center;
}
.item-info .iconfont {
  margin-right: 6px;
  font-size: 0.8rem;
}

.company {
  position: relative;
  width: 100%;
  padding-bottom: 1rem;
  color: rgba(0, 0, 0, 0.3);
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.2);
  text-align: center;
  font-size: 0.74rem;
}
</style>
