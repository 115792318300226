<template>
  <div>
    <ServiceTitle></ServiceTitle>

    <div class="page-content">
      <div class="new-title-content">分类标签</div>

      <div class="input-wrap f12" @click="catesPopshow">
        <mt-cell to="javascript:;" is-link>
          <div slot="title">
            <i class="need"></i>
            <span>分类</span>
          </div>
          {{cateName}}
        </mt-cell>
      </div>

      <div class="input-wrap f12">
        <mt-cell to="javascript:;" is-link class="menuList">
          <div slot="title">
            <i class="need"></i>
            <span>标签</span>
          </div>
        </mt-cell>
        <div class="selected-tips">
          <span
            v-for="(label, i) in labels"
            :key="i"
            :id="label.id"
            @click="getLabelId(label.id,label.label_name)"
          >{{label.label_name}}</span>
        </div>
      </div>
    </div>

    <div class="mint-actionsheet" style="z-index: 2005;" v-show="catesPop">
      <ul class="mint-actionsheet-list" style="margin-bottom: 5px;">
        <li
          class="mint-actionsheet-listitem"
          v-for="(cate,index) in cates"
          :key="index"
          @click="getLabel(index,cate)"
        >{{cate}}</li>
      </ul>
      <a class="mint-actionsheet-button" @click="popHide">取消</a>
    </div>
    <div class="v-modal" style="z-index: 2000;" v-show="shadow" @click="popHide"></div>
  </div>
</template>

<style scoped>
.foot-guid-wrap {
  border-bottom: 1px solid #f2f2f2;
}
.foot-tags .mint-actionsheet {
  background-color: #fff;
}
.foot-tags a {
  margin-top: 122px;
}
.foot-tags ul li {
  float: left;
  width: 18%;
  height: 30px;
  line-height: 30px;
  margin: 5px 10px;
  border: 1px solid #cbcbcb;
  color: #333333;
  font-size: 12px;
  border-radius: 30px;
}
.selected-tips {
  background-color: #fff;
  padding: 10px;
}
.selected-tips span {
  padding: 2px 5px;
  border-radius: 30px;
  font-size: 12px;
  border: 1px solid #ccc;
  margin-right: 10px;
}
.selected-tips span.active {
  color: #00cab6;
  border: 1px solid #00cab6;
}
</style>

<script>
import ServiceTitle from "../../components/ServiceTitle";

export default {
  name: "CategoryTags",
  components: {
    ServiceTitle
  },
  mounted() {
    this.getServiceCates();
  },
  data() {
    return {
      catesVisible: false,
      lists: [
        {
          content:
            "行程标题，不仅是您服务特色的表现，还是吸引出行客眼球的入口，给自己的服务取个有特色的标题吧"
        },
        {
          content: "范例："
        },
        {
          content: "1.最美旅行家热门景点一日游"
        },
        {
          content: "2.黄鹤楼、江滩、归元寺本地服务"
        },
        {
          content: "3.专职摄像机带您漫步江南水乡小镇，记录不一样的旅游"
        }
      ],
      cates: [],
      labels: [],
      catesPop: false,
      shadow: false,
      cateName: ""
    };
  },
  methods: {
    // 得到类别
    getServiceCates() {
      this.$ajax("post", "Service/get_service_label", "", res => {
        var ret = res.data.data;
        var cate = new Array();

        for (var i = 0; i <= ret.length - 1; i++) {
          for (var j = 0; j <= ret[i].length - 1; j++) {
            //                            console.log(ret[i][j]);
            cate.push(ret[i][j].type_name);
          }
        }

        // 对获取到的后台类别去重
        var new_cates = new Array();
        for (var k = 0; k <= cate.length - 1; k++) {
          if (new_cates.indexOf(cate[k]) < 0) {
            new_cates.push(cate[k]);
          }
        }

        this.cates = new_cates;
      });
    },

    catesPopshow() {
      this.catesPop = true;
      this.shadow = true;
    },

    popHide() {
      this.catesPop = false;
      this.shadow = false;
    },

    // 得到对应的标签
    getLabel(index, value) {
      //                console.log(index);
      this.$ajax("post", "Service/get_service_label", "", res => {
        var ret = res.data.data[index];
        this.labels = ret;
        this.cateName = value;
        this.save_arr("cateName", value);
      });
    },

    // 得到标签ID
    getLabelId(id, value) {
      var that = this;

      if ($("#" + id + "").hasClass("active")) {
        $("#" + id + "").removeClass("active");
      } else {
        $("#" + id + "").addClass("active");
      }

      var id_str = "";
      $(".selected-tips span.active").each(function() {
        id_str += $(this).attr("id") + ",";
      });
      var newstr = id_str.substring(0, id_str.length - 1);
      this.save_arr("label_str", newstr);
      that.save_arr("labelName", value);
    }
  }
};
</script>