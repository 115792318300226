<template>
  <div class="wx-body" v-title :data-title="title">
    <div class="wx-head">
      <img :src="info.newurl" />
    </div>
    <div class="wx-title">
      <span>{{info.title}}</span>
    </div>
    <div class="wx-content">
      <div class="wx-get-coupon width_20" @click="click_get_coupon">
        <span>我要领劵</span>
      </div>
      <div class="wx-order width_20" @click="click_get_order">
        <span>我要预约</span>
      </div>
    </div>

    <div class="wx-notes">
      <div class="wx-open-time margin_left_30">
        <span>{{info.open_time}}</span>
      </div>
      <div class="wx-service-tel margin_left_30">
        <span>{{info.service_tel}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      title: "美丽乡村",
      park_id: this.$route.query.park_id,
      info: ""
    };
  },
  mounted() {
    this.get_park_list();
  },
  methods: {
    get_park_list() {
      var data_ = {
        park_id: this.park_id,
        token: "zmlxj_2019_wuhanlvyouxiehui"
      };

      this.$ajax("post", "Other/get_park_list", data_, ret => {
        if (ret.data.id == 0) {
          this.info = ret.data.data.park;
        } else {
          //没有数据
          Toast("没有数据");
        }
      });
    },
    /*我要领劵*/
    click_get_coupon() {
      //进入领劵页面
      this.$router.push({
        path: "/wuhanlvyouxiehui/wx_coupon_list",
        query: { park_id: this.park_id }
      });
    },
    /*我要预约*/
    click_get_order() {
      //进入预约平台
      this.$router.push({
        path: "/wuhanlvyouxiehui/wx_prev_detail",
        query: { park_id: this.park_id }
      });
    }
  }
};
</script>

<style scoped>
.wx-body {
  display: inline-block;
  position: relative;
  width: 98%;
  margin-left: 1%;
  background-color: #f0f0f0;
}
.wx-body .wx-head {
  display: inline-block;
  position: relative;
  width: 100%;
}
.wx-body .wx-head img {
  width: 100%;
}
.wx-body .wx-title {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 2%;
}
.wx-body .wx-title span {
  display: block;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
}
.wx-body .wx-content {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 12%;
}
.wx-body .wx-content .width_20 {
  display: inline-block;
  position: relative;
  width: 50%;
  margin-left: 25%;
  border-radius: 25px;
  padding: 10px;
  text-align: center;
  font-size: 1.2rem;
}
.wx-body .wx-content .wx-get-coupon {
  background-color: #ff696a;
  color: white;
}
.wx-body .wx-content .wx-order {
  background-color: #01ce96;
  color: white;
  margin-top: 10%;
}
.wx-body .wx-notes {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 10%;
}
.wx-body .wx-notes .margin_left_30 {
  display: inline-block;
  position: relative;
  width: 45%;
  margin-left: 30%;
}
.wx-body .wx-notes span {
  font-size: 0.8rem;
  color: #5c6368;
}
</style>