<template>
  <div class="myindex" :class="{active:container_big}" v-title data-title="TA的">
    <mt-header fixed :title="user_info.user_nick">
      <a slot="left">
        <mt-button @click.native="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="map_box">
        <ul class="map_info clearfix">
          <li>
            <p>{{user_info.country_numbers}}</p>
            <p>国家</p>
          </li>
          <li>
            <p>{{user_info.province_numbers}}</p>
            <p>省</p>
          </li>
          <li>
            <p>{{user_info.city_numbers}}</p>
            <p>城市</p>
          </li>
          <li>
            <p>{{user_info.district_numbers}}</p>
            <p>地点</p>
          </li>
          <li>
            <p>{{user_info.img_numbers}}</p>
            <p>照片</p>
          </li>
          <li>
            <p>{{user_info.foot_length}}k</p>
            <p>里程km</p>
          </li>
        </ul>
        <div id="container" class="zoom" style="width:100%; height:100%"></div>
        <img
          class="red_packet_spread"
          src="../../assets/img/red_packet_spread.png"
          alt
          @click="container_big = !container_big"
        />
        <div class="left_but" v-if="container_big">
          <div class="mak_tab">
            <!--点/图片 切换-->
            <img
              src="../../assets/img/map_park_tab_img.png"
              alt
              @click="click_mak_type"
              v-if="is_tab_button"
            />
            <img
              src="../../assets/img/map_park_tab_img_maks.png"
              alt
              @click="click_mak_type"
              v-if="!is_tab_button"
            />
            <img
              src="../../assets/img/map_3d.png"
              alt
              class="map_3d"
              @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
              v-if="is_tab_map_3d"
            />
            <img
              src="../../assets/img/map_3d_yes.png"
              alt
              class="map_3d"
              @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
              v-if="!is_tab_map_3d"
            />
          </div>
          <div class="mak_tab">
            <!--地图 + -  -->
            <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
            <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
          </div>
        </div>
      </div>
      <div class="my_header">
        <div class="avatar_box">
          <div class="avatar" id="avatar">
            <img v-if="user_info.user_icon!=''" :src="user_info.user_icon" />
            <img v-if="user_info.user_icon==''" src="../../assets/img/apply_avatar_bg.png" />
          </div>
        </div>
        <div class="my-header-info" style="bottom: 0">
          <div class="name">{{user_info.user_nick}}</div>
          <div class="social">
            <span class="funs">
              <router-link
                :to="{path:'/my/MyFans',query:{type:'tafans',user_id:user_info.user_id}}"
              >粉丝&nbsp;{{user_info.fansi}}</router-link>
            </span>
            <span class="follow">
              <router-link
                :to="{path:'/my/follow',query:{type:'tafollow',user_id:user_info.user_id}}"
              >关注&nbsp;{{user_info.atten}}</router-link>
            </span>
          </div>
          <div class="profile">简介：{{user_info.user_signature}}</div>
        </div>
      </div>
      <div id="footer">
        <div class="otherFooterBtn flex-box flex-center">
          <a
            class="follow atten"
            @click="click_follow(user_info.user_id)"
            v-if="user_info.cancel_attention==0"
          >关注</a>
          <a class="follow atten" @click="click_follow(user_info.user_id)" v-else>取消关注</a>
          <div class="line"></div>
          <a @click="Chat" class="msg">私信</a>
        </div>
      </div>
      <div class="other-content">
        <div class="my-tab flex-box flex-justify-center">
          <ul class="clearfix other-tab">
            <li v-for="(k, i) in my_tab" :key="i">
              <router-link :to="{path:k.href,query:{type:k.query,user_id:k.user_id}}"></router-link>
              <img :src="k.assets" alt />
              <div class="my-tab-info">
                <span>{{k.name}}</span>
                <p class="record_numbers" :class="k.color">{{k.number}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import my_track from "../../assets/img/my-track.png"; //轨迹
import my_notes from "../../assets/img/my-notes.png"; //游记
import my_foot_track from "../../assets/img/my-foot-track.png"; //足迹
import my_head_bg from "../../assets/img/my_head_bg.png";

import { Toast } from "mint-ui";
import { MessageBox } from "mint-ui";
export default {
  data() {
    return {
      map: {},
      user_info: [],
      my_tab: [
        {
          assets: my_track,
          name: "轨迹",
          number: 0,
          color: "blue-color",
          href: "/track/myTrackList",
          query: ""
        },
        {
          assets: my_notes,
          name: "游记",
          number: 0,
          color: "green-color",
          href: "/my/MyNote",
          query: "taNote",
          user_id: ""
        },
        {
          assets: my_foot_track,
          name: "足迹",
          number: 0,
          color: "green-color",
          href: "/footprints/FootprintsIndex",
          query: ""
        }
      ],
      my_head_bg: my_head_bg,
      mak_type: "img",
      container_big: false, //地图放大缩小
      micro_img_marker_list: [], //所有点
      is_tab_button: true, //图片切换是否点击
      map_user_yes: false, //是否点击游客按钮
      is_tab_map_3d: true //3D地图按钮是否点击
    };
  },
  mounted() {
    this.getOtherUserInfo();
  },
  methods: {
    getOtherUserInfo() {
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      var that = this;
      var user_id = this.$route.params.uid;
      this.my_tab[0].query = user_id;
      this.my_tab[1].user_id = user_id;
      this.my_tab[2].query = user_id;

      if (this.check_login()) {
        var my_id = this.check_login().user_id;
      }
      //初始化地图对象，加载地图
      this.map = this.map_init();

      this.$ajax(
        "post",
        "Index/get_user_info",
        {
          user_id: user_id,
          my_id: my_id
        },
        res => {
          this.user_info = res.data.data;
          this.my_tab[0].number = this.user_info.record_numbers;
          this.my_tab[1].number = this.user_info.notes_numbers;
          this.my_tab[2].number = this.user_info.foot_numbers;

          //                    加载地图上图片
          if (res.data.data.spots_list.length > 0) {
            //                        this.map_add_Marker(this.map, res.data.data.spots_list, 'img_num', (Marker_arr) => {
            //                            that.micro_img_marker_list = Marker_arr
            //                        })

            this.map_add_Marker({
              map: this.map,
              list_data: res.data.data.spots_list,
              type: "img_num",
              callback: function(Marker_arr) {
                that.micro_img_marker_list = Marker_arr;
              }
            });
          }
        }
      );
    },
    click_follow(uid) {
      if (!this.check_login()) {
        MessageBox("请先登陆").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
          }
        });
      } else {
        var data = {
          user_id: this.check_login().user_id,
          attention_id: uid
        };
        this.$ajax("post", "foot/clickAttentionButton", data, res => {
          if (res.data.id == 0) {
            if (res.data.data.each == 1) {
              Toast("关注成功");
              this.$set(this.user_info, "cancel_attention", 2);
            } else {
              Toast("取消关注成功");
              this.$set(this.user_info, "cancel_attention", 0);
            }
          } else {
            Toast("操作失败，请稍后再试！");
          }
        });
      }
    },
    click_mak_type() {
      this.map_cut_Marker(
        this.map,
        this.micro_img_marker_list,
        this.is_tab_button
      );
      this.is_tab_button = !this.is_tab_button;
    },
    //            私信
    Chat() {
      if (!this.check_login()) {
        MessageBox("请先登陆").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
          }
        });
      } else {
        this.$router.push({
          name: "ChatWindow",
          params: { uid: this.$route.params.uid }
        });
      }
    }
  }
};
</script>
<style scoped>
.my_header .btn-box {
  width: 5.7rem;
  height: 2.6rem;
  position: absolute;
  right: 1.15rem;
  top: 1rem;
}

.my_header .btn-box.left {
  width: 5.7rem;
  height: 2.6rem;
  position: absolute;
  left: 1.15rem;
  top: 1rem;
  z-index: 1;
}

.my_header .btn-box > i {
  width: 2rem;
  height: 2.6rem;
  background-position: center top;
  position: relative;
}

.my_header .btn-box i.return {
  background: url(../../assets/img/login_close.png) no-repeat;
  background-size: 0.5rem;
  float: left;
  width: 2.3rem;
  background-position: left top;
}

.other-tab {
  width: 100%;
}

.other-tab li {
  width: 33%;
}

#footer {
  height: 2.4rem;
  line-height: 3.4rem;
  background-color: #fff;
  width: 100%;
  text-align: center;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.otherFooterBtn {
  width: 100%;
}

.otherFooterBtn a {
  height: 2.4rem;
  line-height: 3.4rem;
  font-size: 0.7rem;
  text-align: center;
  flex: 1;
  box-flex: 1;
  color: #666;
  background-repeat: no-repeat;
}

.otherFooterBtn a.follow {
  background-image: url(../../assets/img/other_btn_follow.png);
  background-position: center 0.5rem;
  background-size: 0.8rem;
}

.otherFooterBtn a.msg {
  background-image: url(../../assets/img/other_btn_msg.png);
  background-position: center 0.5rem;
  background-size: 0.8rem;
}

.otherFooterBtn .line {
  height: 2.2rem;
  border-right: 0.1rem solid #ccc;
}
</style>