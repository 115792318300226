<template>
  <div v-title data-title="我的轨迹">
    <div class="message">
      <mt-header fixed title="我的轨迹">
        <a slot="left">
          <mt-button icon="back" @click="$router.go(-1)"></mt-button>
        </a>
      </mt-header>
    </div>

    <div class="page-content">
      <div class="message-list" v-if="trackList != ''">
        <ul>
          <li class="clearfix" v-for="track in trackList" :key="track.id">
            <router-link :to="{ name: 'TrackDetail', params: { id: track.id }}">
              <span>{{track.record_title}}</span>
              <div class="btn go fr"></div>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="message-list" style="text-align: center;padding: 50px 0" v-else>暂无轨迹</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      trackList: []
    };
  },
  mounted() {
    this.getTrackList();
  },
  methods: {
    getTrackList() {
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        $(".mint-header.is-fixed").remove();
        $(".page-content").css("padding", "0");
      }
      var that = this;

      var user_id = this.$route.query.type;

      var data = {
        user_id: user_id,
        record_id: 1
      };

      this.$ajax("post", "User/get_user_notes_new", data, function(res) {
        that.trackList = res.data.data;
      });
    }
  }
};
</script>