<template>
  <!--旅游电子导图列表模块-->
  <div class="LineList" v-title data-title="最美路书">
    <mt-header fixed title="最美路书">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <ul class="LineList_list clearfix">
        <li class="jingdian" @click="Line_tab(19)">
          <span class="iconfont icon-jingdian"></span>景
          <i v-if="label_type==19" class="iconfont icon-xuanze"></i>
        </li>
        <li class="chifan" @click="Line_tab(18)">
          <span class="iconfont icon-chifan"></span>吃
          <i v-if="label_type==18" class="iconfont icon-xuanze"></i>
        </li>
        <li class="zhuzhi" @click="Line_tab(21)">
          <span class="iconfont icon-zhuzhi"></span>住
          <i v-if="label_type==21" class="iconfont icon-xuanze"></i>
        </li>
        <li class="gouwuche-copy" @click="Line_tab(20)">
          <span class="iconfont icon-gouwuche-copy"></span>购
          <i v-if="label_type==20" class="iconfont icon-xuanze"></i>
        </li>
      </ul>
      <div v-if="info.length != 0">
        <line-info
          ref="child"
          :is_border="false"
          is_tit
          :datainfo="info"
          :user_id="user_id"
          @ievent="ievent"
        ></line-info>
      </div>
      <div
        class="foot_noinfo"
        v-if="(label_type==19 && jingdian_info.length ==0) || (label_type==18 && chifan_info ==0 ) || (label_type==21 && zhuzhi_info ==0) || (label_type==20 && gouwuche_info ==0)"
      >
        <img src="../../assets/img/foot_noinfo@2x.png" alt />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>
<script>
// 景 19     吃 18    住 21    购 20
import { Toast } from 'mint-ui'
import LineInfo from '../../components/LineInfo'
export default {
  components: {
    LineInfo
  },
  data () {
    return {
      user_id: this.$route.query.user_id,
      page: 1, // 页码
      label_type: 19, // 类别
      info: [], // 获取的数据列表
      jingdian_info: [], // 景获取的数据
      chifan_info: [], // 吃获取的数据
      zhuzhi_info: [], // 住获取的数据
      gouwuche_info: [] // 购获取的数据
    }
  },
  mounted () {
    this.unload()
  },
  methods: {
    unload () {
      if (!this.user_id) {
        if (this.check_login()) {
          this.user_id = this.check_login().user_id
        } else {
          localStorage.setItem('login_url', location.hash)
          this.$router.push({ path: '/my/login', query: { log_type: 1 } })
          return
        }
      }
      this.getData(1, 19) // 景
      this.getData(1, 18) // 吃
      this.getData(1, 21) // 住
      this.getData(1, 20) // 购
    },
    ievent (...data) {
      this.getData(data[0], this.label_type)
    },
    getData (page, label_type) {
      this.$ajax(
        'post',
        'foot/user_collect_spots_list',
        {
          user_id: this.user_id,
          page: page,
          label_type: label_type
        },
        res => {
          if (page == 1) {
            if (label_type == 19) {
              if (res.data.id == 0) {
                this.jingdian_info = res.data.data
                this.info = this.jingdian_info
              }
            } else if (label_type == 18) {
              if (res.data.id == 0) {
                this.chifan_info = res.data.data
              }
            } else if (label_type == 21) {
              if (res.data.id == 0) {
                this.zhuzhi_info = res.data.data
              }
            } else if (label_type == 20) {
              if (res.data.id == 0) {
                this.gouwuche_info = res.data.data
              }
            }
          } else {
            if (res.data.id == 0) {
              this.info = this.info.concat(res.data.data)
              this.$refs.child.parentMsg({ info: this.info })
            }
          }
        }
      )
    },
    //            切换
    Line_tab (label_type) {
      this.label_type = label_type
      if (label_type == 19) {
        this.info = this.jingdian_info
      } else if (label_type == 18) {
        this.info = this.chifan_info
      } else if (label_type == 21) {
        this.info = this.zhuzhi_info
      } else if (label_type == 20) {
        this.info = this.gouwuche_info
      }
      if (this.$refs.child) {
        this.$refs.child.parentMsg({ info: this.info, allLoaded: false })
      }
    }
  }
}
</script>
<style scoped>
.LineList {
  min-height: 100%;
  background-color: #fff;
}

.LineList .page-content {
  padding: 2rem 0 0;
}

.LineList_list {
  padding: 0.4rem;
}

.ParkMapLineList-content .more {
  line-height: 1.4rem;
  height: 1.4rem;
  text-align: center;
  color: #ccc;
  font-size: 0.7rem;
}

.ParkMapLineList-content .more i {
  transform: rotate(90deg);
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.LineList_list li {
  float: left;
  width: 22%;
  margin: 0 1.5%;
  position: relative;
  color: #fff;
  padding: 8px 0;
  text-align: center;
  border-radius: 4px;
  font-size: 0.7rem;
}

.LineList_list li.jingdian {
  background-color: #90b4fe;
}

.LineList_list li.chifan {
  background-color: #ade777;
}

.LineList_list li.zhuzhi {
  background-color: #ff633d;
}

.LineList_list li.gouwuche-copy {
  background-color: #f6cc2a;
}

.LineList_list li span {
  margin-right: 5px;
  font-size: 100%;
}

.LineList_list li i {
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 2px;
}

.LineList_list li.jingdian i {
  color: #2477d3;
}

.LineList_list li.chifan i {
  color: #1cab1b;
}

.LineList_list li.zhuzhi i {
  color: #cc1b15;
}

.LineList_list li.gouwuche-copy i {
  color: #ba9101;
}

.LineList_info_list li {
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  overflow: hidden;
}

.LineList_info_list li .img_box {
  height: 8rem;
  position: relative;
}

.LineList_info_list li .img_box > img {
  height: 100%;
  width: 100%;
}

.LineList_info_list li .img_info {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.LineList_info_list li .img_info p {
  width: 100%;
  text-align: center;
  padding: 0.2rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.4);
}

.LineList_info_list li .user_info {
  padding: 0.3rem 10px;
}

.LineList_info_list li .user_info img {
  height: 1.4rem;
  border-radius: 50%;
  margin-right: 0.4rem;
  display: inline-block;
}

.LineList_info_list li .user_info span {
  overflow: hidden;
  height: 1.4rem;
  line-height: 1.4rem;
  margin-left: 10px;
}
</style>
