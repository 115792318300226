<template>
  <div class="container">
    <!-- 设置提示 -->
    <div class="set_mask"></div>
    <!-- 设置界面 -->
    <transition name="set">
      <div class="set_item" v-show="showSetting">
        <div class="set_item_wrapper">
          <div class="item_close" @click="showSetting = false"></div>
          <div class="item_header">
            <h2>奖项{{settingIndex + 1}}设置</h2>
          </div>
          <div class="item_content">
            <div class="content_body">
              <!-- 类型 -->
              <div class="content_row content_type">
                <h3 class="row_title">设置类型：</h3>
                <div class="row_item">
                  <span></span>
                  <select
                    name="type"
                    class="select_type select_item"
                    v-model="settingType"
                    :disabled="settingIndex == 0 || settingIndex == 1"
                    :class="(settingIndex == 0 || settingIndex == 1) ? 'type_disbled' : ''"
                  >
                    <option value="money">现金</option>
                    <option value="coupon">优惠券</option>
                    <option value="gift">礼品</option>
                    <option value="thanks">谢谢参与</option>
                  </select>
                </div>
              </div>
              <!-- 图片 -->
              <div class="content_row content_img" v-show="settingType == 'gift'">
                <h3 class="row_title">设置图片：</h3>
                <div class="row_item">
                  <div class="upload_img">
                    <input
                      type="file"
                      accept="image/*"
                      class="select_img"
                      @input="checkImg"
                      ref="uploadImg"
                    />
                    <span ref="uploadTxt">点击设置图片</span>
                  </div>
                  <div class="item_img">
                    <img :src="settingIcon" alt ref="imgContent" />
                  </div>
                </div>
              </div>
              <!-- 礼品名称 -->
              <div class="content_row content_giftname" v-show="settingType == 'gift'">
                <h3 class="row_title">
                  设置礼品名称：
                  <span>(1-4)</span>
                </h3>
                <div class="row_item">
                  <span></span>
                  <input
                    type="text"
                    class="select_giftname select_item"
                    v-model="settingTitle"
                    maxlength="4"
                    minlength="1"
                    placeholder="请输入1-4个字符"
                  />
                </div>
              </div>
              <!-- 面值 -->
              <div class="content_row content_value">
                <h3 class="row_title">
                  设置面值：
                  <span>(元)</span>
                </h3>
                <div class="row_item">
                  <span>￥</span>
                  <input
                    type="number"
                    :style="'color:' + valueColor"
                    class="select_value select_item"
                    v-model="settingValue"
                    @input="checkValue"
                    placeholder="最小0.3元 "
                    :disabled="valueDisabled"
                    :class="valueDisabled ? 'select_disabled' : ''"
                  />
                  <i class="value_tip" v-if="valueTip">至少0.3元</i>
                </div>
              </div>

              <!-- 概率 -->
              <div class="content_row content_chance">
                <h3 class="row_title">
                  设置中奖概率：
                  <span>(%)</span>
                </h3>
                <div class="row_item">
                  <span>%</span>
                  <input
                    type="number"
                    class="select_value select_item"
                    v-model="settingChance"
                    @input="checkChance"
                    placeholder="请输入(0-100)"
                  />
                  <i>剩余{{remainChance}}</i>
                </div>
              </div>
              <!-- 个数 -->
              <div class="content_row content_number">
                <h3 class="row_title">设置奖品个数：</h3>
                <div class="row_item">
                  <span>个</span>
                  <input
                    type="number"
                    class="select_value select_item"
                    v-model="settingNumber"
                    @input="checkNumber"
                    placeholder="请输入(1-100,000)"
                    :disabled="numberDisabled"
                    :class="numberDisabled ? 'select_disabled' : ''"
                  />
                </div>
              </div>
            </div>
            <div class="content_btn">
              <button class="btn_cancel btn_left" @click="showSetting = false">取消</button>
              <button class="btn_submit btn_right" @click="selectSubmit">确定</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 上传图片进度 -->
    <transition name="set">
      <div class="select_title" v-show="showUpload">
        <div class="upload_wrapper">
          <div class="upload_titile">图片上传进度:</div>
          <div class="upload_progress">
            <div class="progress_wrapper">
              <div class="progress" :style="'width:' + uploadProgress + '%'"></div>
            </div>
            <div class="progress_percent">{{uploadProgress}}%</div>
          </div>
        </div>
      </div>
    </transition>

    <!-- 大转盘 -->
    <div class="lucky-wheel">
      <div class="lucky-title"></div>
      <!-- 设置奖项提示 -->
      <div class="prize_tip">
        <span class="tip_name">奖项设置：点击转盘中的图片进行设置</span>
        <img src="../../assets/switch/wenhao.png" alt @click="showRule=true" />

        <span class="tip_content"></span>
      </div>
      <div class="wheel-main">
        <div class="wheel-pointer-box">
          <div
            class="wheel-pointer"
            @click="rotate_handle()"
            :style="{transform:rotate_angle_pointer,transition:rotate_transition_pointer}"
          ></div>
        </div>
        <div class="wheel-bg" :style="{transform:rotate_angle,transition:rotate_transition}">
          <div class="prize-list">
            <div class="prize-item" v-for="(item,index) in prize_list" :key="index">
              <div class="prize-pic" @click="toSetting(index)">
                <img
                  :src="item.iconUrl ? item.iconUrl : (item.type_=='money' ? money_icon : (item.type_ == 'coupon' ? coupon_icon : thanks_icon))"
                />
              </div>
              <div class="prize-price" v-if="item.price && parseFloat(item.price)">￥{{item.price}}</div>
              <div class="prize-type">{{item.label_name}}</div>
              <div class="prize-to-set" v-if="item.is_set == 0">
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 支付按钮 -->
      <div class="pay_money" v-show="money_ > 0 && is_pay == 0">
        <span @click="jumpToPay" :class="all_set ? '' : 'setBeforePay'">去支付 ￥{{money_}}</span>
      </div>
    </div>
    <div class="main">
      <div class="main-bg"></div>
      <div class="bg-p"></div>
      <!-- 答题 -->
      <transition-group
        name="qs_list"
        class="qs_group"
        tag="div"
        v-if="have_question && questionList.length"
      >
        <div class="question" v-for="(item, i) in questionList" :key="item.id ? item.id : i">
          <h3 class="question_preview">题目{{i+1}}预览</h3>
          <div class="qs_preview_wrapper">
            <div class="question_modify" @click="addQuestion(i+1)">修改</div>
            <div class="question_del" @click="delQuestion(i)">删除</div>
            <div class="qs_preview_title">
              <span>{{item.title}}</span>
              <img :src="item.newUrl" alt v-if="item.newUrl" />
            </div>
            <ul v-if="item.type_ == 'text'" class="qs_preview_text">
              <li
                @click="choseAnswer(i,'a')"
                :class="item.showAnswer && (item.showAnswer == 'a' || item.answer_ == 'a') ? (item.answer_ == 'a' ? 'right_answer' : 'wrong_answer') : ''"
              >A.{{item.a_}}</li>

              <li
                @click="choseAnswer(i,'b')"
                :class="item.showAnswer && (item.showAnswer == 'b' || item.answer_ == 'b') ? (item.answer_ == 'b' ? 'right_answer' : 'wrong_answer') : ''"
              >B. {{item.b_}}</li>
              <li
                @click="choseAnswer(i,'c')"
                :class="item.showAnswer && (item.showAnswer == 'c' || item.answer_ == 'c') ? (item.answer_ == 'c' ? 'right_answer' : 'wrong_answer') : ''"
              >C. {{item.c_}}</li>
              <li
                @click="choseAnswer(i,'d')"
                :class="item.showAnswer && (item.showAnswer == 'd' || item.answer_ == 'd') ? (item.answer_ == 'd' ? 'right_answer' : 'wrong_answer') : ''"
              >D. {{item.d_}}</li>
            </ul>
            <ul v-else-if="item.type_ == 'img'" class="qs_preview_img">
              A:
              <li
                @click="choseAnswer(i,'a')"
                :class="item.showAnswer && (item.showAnswer == 'a' || item.answer_ == 'a') ? (item.answer_ == 'a' ? 'right_answer' : 'wrong_answer') : ''"
              >
                <img :src="item.a_" alt />
              </li>B:
              <li
                @click="choseAnswer(i,'b')"
                :class="item.showAnswer && (item.showAnswer == 'b' || item.answer_ == 'b') ? (item.answer_ == 'b' ? 'right_answer' : 'wrong_answer') : ''"
              >
                <img :src="item.b_" alt />
              </li>C:
              <li
                @click="choseAnswer(i,'c')"
                :class="item.showAnswer && (item.showAnswer == 'c' || item.answer_ == 'c') ? (item.answer_ == 'c' ? 'right_answer' : 'wrong_answer') : ''"
              >
                <img :src="item.c_" alt />
              </li>D:
              <li
                @click="choseAnswer(i,'d')"
                :class="item.showAnswer && (item.showAnswer == 'd' || item.answer_ == 'd') ? (item.answer_ == 'd' ? 'right_answer' : 'wrong_answer') : ''"
              >
                <img :src="item.d_" alt />
              </li>
            </ul>
          </div>
        </div>
      </transition-group>
      <!-- 统计数据 -->
      <div class="datastat_tip" @click="showData">
        <span class="tip_name">奖品详情</span>
        <span class="tip_content"></span>
      </div>
      <!-- 新增答题按钮 -->
      <transition name="new-btn">
        <div
          class="question_tip"
          @click="addQuestion(0)"
          v-if="have_question"
          v-show="increaseQuestion"
        >
          <span class="tip_name">新增答题</span>
          <span class="tip_content"></span>
        </div>
      </transition>

      <!-- 删除答题提示框 -->
      <transition name="set">
        <div class="question_delete" v-show="showDelQuestion" @click.self="showDelQuestion = false">
          <div class="qd_wrapper">
            <div class="item_close" @click="showDelQuestion = false"></div>
            <div class="qd_title">删除后将无法恢复，请问是否确定删除？</div>
            <div class="content_btn">
              <button class="btn_cancel btn_left" @click="showDelQuestion = false">取消</button>
              <button class="btn_submit btn_right" @click="deleteQuestion">确定</button>
            </div>
          </div>
        </div>
      </transition>
      <!-- 查看统计数据 -->
      <transition name="set">
        <div class="question_set" v-show="show_statistics">
          <div class="question_wrapper">
            <div class="item_close" @click="show_statistics = false"></div>
            <h2 class="qs_header">奖品详情</h2>
            <div class="qs_content">
              <div class="qs_item statistics_item" v-for="(item,i) in prize_list" :key="i">
                <h3 class="qs_item_title">奖品{{i+1}}：</h3>
                <div class="statistics_content">
                  <div class="statistics_type statistics_item_opt">奖品类型：{{item.label_name}}</div>
                  <div
                    class="statistics_price statistics_item_opt"
                  >奖品面值：{{item.price ? filterPrice(item.price,item.numbers) : 0 }}</div>
                  <div class="statistics_percentage statistics_item_opt">
                    <span class="statistics_name">中奖概率：{{parseFloat(item.chance) ? parseFloat(item.chance) : 0}}%</span>
                    <span class="chance_percentage_outer">
                      <!-- <span class="chance_percentage_num"></span> -->
                      <span
                        class="chance_percentage_inner"
                        :style="'width:' + parseFloat(item.chance)+'%' "
                      ></span>
                    </span>
                  </div>
                  <div class="statistics_percentage statistics_item_opt">
                    <span
                      class="statistics_name"
                    >剩余数量：{{ item.price ? filterNumbers(item.price,item.numbers_) : item.numbers_}}</span>
                    <span class="numbers_percentage_outer">
                      <span
                        class="numbers_percentage_inner"
                        :style="'width:' + item.numbers_/item.numbers *100+ '%' "
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <!-- 设置答题 -->
      <transition name="set">
        <div class="question_set" v-show="showSetQuestion" v-if="have_question">
          <div class="question_wrapper">
            <div class="item_close" @click="showSetQuestion = false"></div>
            <h2 class="qs_header">{{qs_header}}</h2>
            <div class="qs_content">
              <div class="qs_item">
                <!-- 设置题目 -->
                <h3 class="qs_item_title">题目设置：</h3>
                <div class="qs_select">
                  <span
                    class="noImg qs_select_img"
                    :class="!title_have_img ? 'qs_select_active' : ''"
                    @click="title_have_img=false"
                  >无图片</span>
                  <span
                    class="haveImg qs_select_img"
                    :class="title_have_img ? 'qs_select_active' : ''"
                    @click="title_have_img=true"
                  >有图片</span>
                </div>

                <div class="qs_item_content">
                  <textarea
                    name
                    id
                    cols="30"
                    rows="5"
                    placeholder="请输入题目，200字以内"
                    v-model="qusetion_title"
                    maxlength="200"
                  ></textarea>
                  <i>
                    <span>{{qs_title_len}}</span>
                    <span>/200</span>
                  </i>
                </div>
                <transition name="qt_img">
                  <div class="qs_item_content show_qt_img" v-if="title_have_img">
                    <input
                      type="file"
                      accept="image/*"
                      class="select_img"
                      ref="qs_title_img"
                      @input="checkTitleImg"
                    />
                    <span class="section_img" ref="qs_title_txt">点击插入图片</span>
                    <div class="qs_item_img">
                      <img :src="title_img" />
                    </div>
                  </div>
                </transition>
              </div>
              <!-- 选项设置 -->
              <h3 class="qs_item_title">答案类型：</h3>
              <div class="qs_select">
                <span
                  class="noImg qs_select_img"
                  :class="!content_have_img ? 'qs_select_active' : ''"
                  @click="content_have_img=false"
                >文字答案</span>
                <span
                  class="haveImg qs_select_img"
                  :class="content_have_img ? 'qs_select_active' : ''"
                  @click="content_have_img=true"
                >图片答案</span>
              </div>
              <transition name="option" mode="out-in">
                <div class="qs_item_no qs_item_wrapper" v-if="!content_have_img" key="noimg">
                  <div class="qs_item">
                    <div class="qs_item_content">
                      <span class="section">A：</span>
                      <input
                        type="text"
                        placeholder="请输入选项内容"
                        v-model="qs_content_a"
                        maxlength="200"
                        minlength="1"
                        @input="checkOptionTxt(0,'a')"
                      />
                    </div>
                  </div>
                  <div class="qs_item">
                    <div class="qs_item_content">
                      <span class="section">B：</span>
                      <input
                        type="text"
                        placeholder="请输入选项内容"
                        v-model="qs_content_b"
                        maxlength="200"
                        minlength="1"
                        @input="checkOptionTxt(1,'b')"
                      />
                    </div>
                  </div>
                  <div class="qs_item">
                    <div class="qs_item_content">
                      <span class="section">C：</span>
                      <input
                        type="text"
                        placeholder="请输入选项内容"
                        v-model="qs_content_c"
                        maxlength="200"
                        minlength="1"
                        @input="checkOptionTxt(2,'c')"
                      />
                    </div>
                  </div>
                  <div class="qs_item">
                    <div class="qs_item_content">
                      <span class="section">D：</span>
                      <input
                        type="text"
                        placeholder="请输入选项内容"
                        v-model="qs_content_d"
                        maxlength="200"
                        minlength="1"
                        @input="checkOptionTxt(3,'d')"
                      />
                    </div>
                  </div>
                </div>
                <div class="qs_item_have qs_item_wrapper" v-else key="isimg">
                  <div class="qs_item">
                    <div class="qs_item_content">
                      <input
                        type="file"
                        accept="image/*"
                        class="select_img"
                        ref="option_img_a"
                        @input="checkOptionImg(0,'a')"
                      />
                      <span class="section_img" ref="option_txt_a">点击选择A图片</span>
                      <div class="qs_item_img">
                        <img :src="qs_icon_a" />
                      </div>
                    </div>
                    <div class="qs_item_content">
                      <input
                        type="file"
                        accept="image/*"
                        class="select_img"
                        ref="option_img_b"
                        @input="checkOptionImg(1,'b')"
                      />
                      <span class="section_img" ref="option_txt_b">点击选择B图片</span>
                      <div class="qs_item_img">
                        <img :src="qs_icon_b" />
                      </div>
                    </div>
                    <div class="qs_item_content">
                      <input
                        type="file"
                        accept="image/*"
                        class="select_img"
                        ref="option_img_c"
                        @input="checkOptionImg(2,'c')"
                      />
                      <span class="section_img" ref="option_txt_c">点击选择C图片</span>
                      <div class="qs_item_img">
                        <img :src="qs_icon_c" />
                      </div>
                    </div>
                    <div class="qs_item_content">
                      <input
                        type="file"
                        accept="image/*"
                        class="select_img"
                        ref="option_img_d"
                        @input="checkOptionImg(3,'d')"
                      />
                      <span class="section_img" ref="option_txt_d">点击选择D图片</span>
                      <div class="qs_item_img">
                        <img :src="qs_icon_d" />
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <!-- 选择答案 -->
              <h3 class="qs_item_title">正确答案：</h3>
              <div class="qs_select">
                <span
                  :class="[answer_ == 'a' ? 'qs_select_active' : '','qs_select_img']"
                  @click="answer_ = 'a'"
                >A</span>
                <span
                  :class="[answer_ == 'b' ? 'qs_select_active' : '','qs_select_img']"
                  @click="answer_ = 'b'"
                >B</span>
                <span
                  :class="[answer_ == 'c' ? 'qs_select_active' : '','qs_select_img']"
                  @click="answer_ = 'c'"
                >C</span>
                <span
                  :class="[answer_ == 'd' ? 'qs_select_active' : '','qs_select_img']"
                  @click="answer_ = 'd'"
                >D</span>
              </div>
            </div>
            <div class="content_btn">
              <button class="btn_cancel btn_left" @click="showSetQuestion = false">取消</button>
              <button class="btn_submit btn_right" @click="questionSubmit">确定</button>
            </div>
          </div>
        </div>
      </transition>
      <!-- 活动规则 -->
      <div class="tip">
        <div class="tip-title">活动规则</div>
        <div class="tip-content">
          <p v-for="(item, i) in content" :key="i">{{ item }}</p>
        </div>
      </div>
    </div>
    <!-- 答题结果 -->
    <div class="toast" v-show="answer_control">
      <div class="toast-container">
        <img :src="answer_pictrue" class="toast-picture" />
        <div class="close" @click="close_toast()"></div>
        <div class="toast-title">{{answer_title}}</div>
        <div class="toast-btn">
          <div class="toast-cancel" @click="close_toast">关闭</div>
        </div>
      </div>
    </div>
    <div class="toast-mask" v-show="answer_control"></div>

    <!-- 抽奖结果 -->
    <div class="toast" v-show="toast_control">
      <div class="toast-container">
        <img :src="toast_pictrue" class="toast-picture" />
        <div class="close" @click="close_toast()"></div>
        <div class="toast-title">{{toast_title}}</div>
        <div class="toast-btn">
          <div class="toast-cancel" @click="close_toast">关闭</div>
        </div>
      </div>
    </div>
    <div class="toast-mask" v-show="toast_control"></div>
    <!-- 音频 -->
    <audio class="aright" ref="aright" :src="rightAudio"></audio>
    <audio class="wrong" ref="wrong" :src="wrongAudio"></audio>

    <!-- 设置规则 -->
    <transition name="rule">
      <section class="set_rule" v-show="showRule">
        <div class="rule_close" @click="showRule = false"></div>
        <div class="rule_wrapper">
          <div class="rule_header">
            <h2 class="rule_title">设置说明</h2>
          </div>
          <article class="rule_content">
            <div class="content_rule_lottery rule_item">
              <h3 class="rule_lottery_title">大转盘设置规则：</h3>
              <p>1.分别点击大转盘中八个奖品的图片可对每一奖品进行单独设置；</p>
              <p>2.可选择的奖品类型为：现金、优惠券、礼品、谢谢参与，其中‘谢谢参与’即为‘未中奖’；</p>
              <p>3.若选择奖品类型为‘礼品’，则需要上传一张该礼品的效果图。图片形状建议为正方形，大小无限制；</p>
              <p>4.奖品面值为以人民币所标识的奖品价值，若该奖品为现金，则奖品面值即为等额的人民币。奖金面值限制为最低0.3元人民币；</p>
              <p>5.中奖概率为该项奖品中奖的几率，最低为1%，所有奖品的概率总和最高为100%；</p>
              <p>6.奖品个数为该项奖品的个数，至少设置为1个，最多可设置为100,000个；</p>
              <p>7.为保证系统效率，第一个奖品限制为‘现金’，第二个奖品限制为‘谢谢参与’，且这两个奖品的类型无法更改；</p>
              <p>8.所需支付的金额为‘现金’奖品的 【面值 * 数量】，支付完成并且通过分享或答题获得抽奖次数后，可点击‘抽奖’按钮查看效果；</p>
            </div>
            <div class="content_rule_question rule_item">
              <h3 class="rule_question_title">答题设置规则：</h3>
              <p>1.若当前活动的参与条件中有‘答题’，则可在当前页面进行答题设置，否则不能设置；</p>
              <p>2.点击屏幕右侧‘新增答题’按钮打开答题设置界面；</p>
              <p>3.题目中必须有文字，可选择是否插入图片。文字最多为200字；</p>
              <p>4.答案类型必须为文字或图片其中之一，不可同时选择。答案的4个选项都必须设置；</p>
              <p>5.每道题目必须设置唯一的正确答案；</p>
              <p>6.新增的答题会实时显示在当前页面，点击答题选项可预览答题效果，点击标题下方的‘删除’、‘修改’按钮可对当前答题进行删除或修改操作；</p>
            </div>
          </article>
          <div class="rule_footer">
            若有其他疑问，可来电
            <a href="tel:13317130676">联系我们</a>
          </div>
        </div>
      </section>
    </transition>
  </div>
</template>
<script>
import { fail } from "assert";
import { Toast } from "mint-ui";
import * as qiniu from "qiniu-js";
import md5 from "js-md5";
var wx = require("weixin-js-sdk");
export default {
  data() {
    return {
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id")
        ? localStorage.getItem("open_id")
        : "", // 备注删除
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      id: this.$route.query.id ? this.$route.query.id : 0,
      id_: this.$route.query.id_ ? this.$route.query.id_ : 0,
      aid: this.$route.query.aid ? this.$route.query.aid : 0,
      vid: this.$route.query.vid ? this.$route.query.vid : 0,
      label_id: this.$route.query.label_id ? this.$route.query.label_id : 0,
      lid: this.$route.query.lid ? this.$route.query.lid : 0,

      id_type_: this.$route.query.id_type_ ? this.$route.query.id_type_ : 0,
      state: "setLottery",
      park_id: 298,

      rightAudio: "", // 答对
      wrongAudio: "",
      easejoy_bean: 0, //金豆
      park_luck: {}, // 转盘信息
      content: [], //活动规则
      thanks_icon: require("../../assets/lottery/give_up.png"), // 奖品图片
      money_icon: require("../../assets/switch/redpacket.png"),
      coupon_icon: require("../../assets/switch/coupon.png"), // 优惠券
      prize_list: [], //奖品列表
      prize_name: "点击设置",
      toast_control: false, //抽奖结果弹出框控制器
      hasPrize: false, //是否中奖
      start_rotating_degree: 0, //初始旋转角度
      rotate_angle: 0, //将要旋转的角度
      start_rotating_degree_pointer: 0, //指针初始旋转角度
      rotate_angle_pointer: 0, //指针将要旋转的度数
      rotate_transition: "transform 5s ease-in-out", //初始化选中的过度属性控制
      rotate_transition_pointer: "transform 10s ease-in-out", //初始化指针过度属性控制
      isRotate: false, //是否正在抽奖
      index: 0,
      deno: 0,
      token_: "", // 服务器返回的token

      isAdmin: "",
      content: "", // 抽奖规则
      /* 设置奖品 */
      prize_set_count: 0,
      showSetting: false,
      settingIndex: "", // 设置索引
      settingType: "", // 设置奖品类型
      settingValue: "", // 设置现金金额
      valueTip: false, // 金额提示
      valueColor: "#444", // 金额提示颜色
      valueDisabled: false,
      settingChance: "", // 设置中奖几率
      // remainChance: 0, // 已经设置的总几率
      settingNumber: "", // 设置奖品数量
      settingTitle: "", // 设置礼品名称
      numberDisabled: false, // 禁止输入
      settingIcon: "", // 选择的图片
      img_key: "", // 图片key
      lottery_item_id: "", // 选项id
      img_file: "", // 图片文件
      img_blob: {}, // 图片的blob对象
      img_change: false, // 是否修改了礼品图片
      qiniu_token: "", // 七牛token
      showUpload: false,
      uploadProgress: 0,
      isPrize: false,

      /* 金额 */
      money_: 0,
      is_pay: 0,

      have_question: false, // 有没有答题
      have_share: false, // 有没有分享
      have_chance: 0, // 抽奖机会
      have_chance_id: "",
      /* 答题 */
      increaseQuestion: false, //显示新增答题
      showSetQuestion: false, // 设置答题
      showDelQuestion: false, // 删除答题
      delIndex: "", // 要删除题目的索引
      qs_header: "",
      qusetion_title: "", // 题目
      // qs_title_len: 0,
      title_have_img: false, // 题目有没有图片
      title_img: "", // 题目图片
      qt_img_blob: "", // 题目图片blob格式
      title_img_file: "",
      img_file_a: "",
      img_file_b: "",
      img_file_c: "",
      img_file_d: "",
      qt_img_change: false, // 题目图片改变
      content_have_img: false, // 选项是不是图片
      answer_: 0, // 设置正确答案
      qs_icon_a: "", // 选项图片地址
      qs_icon_b: "",
      qs_icon_c: "",
      qs_icon_d: "",
      qs_blob_a: "", // 选项图片blob对象
      qs_blob_b: "",
      qs_blob_c: "",
      qs_blob_d: "",
      qs_content_a: "", // 选项文字内容
      qs_content_b: "",
      qs_content_c: "",
      qs_content_d: "",
      qs_change_a: false, // 选项图片是否被改变
      qs_change_b: false,
      qs_change_c: false,
      qs_change_d: false,
      isAnswer: false,
      answer_control: false,
      qs_set_edit: 0, //新建还是编辑
      qs_index: "", // 设置答题时的题目索引
      as_index: 0, // 点击答案时题目的索引
      clickAnswer: "", // 点击题目的正确答案
      questionList: [],
      // 设置说明
      showRule: "",
      // 分享链接
      sUrl: "",
      wx_title: "",
      wx_content: "",
      wx_img: "",

      // 统计数据
      show_statistics: false
    };
  },
  created() {
    this.getData();
    this.init_prize_list();
    this.initSUrl();
  },
  watch: {
    settingValue: function(val) {
      // console.log(val);
      if (parseFloat(val) && parseFloat(val) < 0.3) {
        this.valueColor = "#e44";
        this.valueTip = true;
      } else {
        this.valueColor = "#444";
        this.valueTip = false;
      }
    },
    settingType: function(val) {
      if (val == "thanks") {
        this.valueDisabled = true;
        this.numberDisabled = true;
        this.settingNumber = this.prize_list[this.settingIndex].numbers;
        this.settingValue = 0;
      } else {
        this.valueDisabled = false;
        this.numberDisabled = false;
        this.settingNumber = this.prize_list[this.settingIndex].numbers;
        this.settingValue = parseFloat(
          this.prize_list[this.settingIndex].price
        );
      }
    }
  },
  computed: {
    //
    all_set() {
      return this.prize_list.every((item, i) => {
        return item.is_set == 1;
      });
    },
    // 剩余概率
    remainChance() {
      let sum = 0;
      this.prize_list.forEach((item, i) => {
        if (i == this.settingIndex) {
        } else {
          if (item.chance) {
            sum += parseFloat(item.chance);
          }
        }
      });
      if (parseFloat(this.settingChance) > 100 - sum) {
        this.settingChance = 100 - sum;
      }
      let chance = this.settingChance ? this.settingChance : 0;
      return 100 - sum - chance;
    },
    toast_title() {
      return this.hasPrize
        ? "恭喜您，获得价值 ￥" +
            this.prize_list[this.index].price +
            " 的" +
            this.prize_list[this.index].label_name
        : "未中奖";
    },

    toast_pictrue() {
      return this.hasPrize
        ? require("../../assets/lottery/congratulation.png")
        : require("../../assets/lottery/sorry.png");
    },
    answer_title() {
      return this.isAnswer
        ? "答对了！获得1次抽奖机会"
        : "答错了！正确答案是:" + this.clickAnswer.toUpperCase();
    },

    answer_pictrue() {
      return this.isAnswer
        ? require("../../assets/lottery/congratulation.png")
        : require("../../assets/lottery/sorrydacuole.png");
    },
    qs_title_len() {
      return this.qusetion_title.length;
    }
  },
  mounted() {
    if (this.token) {
    } else {
      //第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    console.log("token=" + this.token);
    //投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }
    if (this.open_id) {
    } else {
      this.authorize();
    }

    // if (this.is_weixin) {
    //   this.dealWithUrl(this.sUrl);
    // }

    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    filterPrice(price, num) {
      return "￥" + price + " / ￥" + (price * num).toFixed(2);
    },
    filterNumbers(price, num) {
      return num + " / ￥" + (num * price).toFixed(2);
    },

    // 微信分享回调
    wx_share_callback(url, info) {
      if (url) {
        var that = this;
        //先使用？进行分截
        var url_ = url.split("#")[0];
        this.$ajax("post", "Weixin/get_wx_jskey", { url: url_ }, ret => {
          if (ret.data.id == 0) {
            //表示获得了数据-存储在本地
            var title = info.title;
            var content = info.content;
            var img = info.img;
            console.log(ret);
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              //需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                "onMenuShareAppMessage",
                "onMenuShareTimeline",
                "onMenuShareQQ",
                "onMenuShareQZone",
                "openLocation",
                "getLocation"
              ]
            });

            //处理验证失败的信息
            wx.error(function(res) {
              // that.save_share_data_(info);
            });

            var open_id = this.open_id;
            if (info != "") {
              if (info.url != "") {
                let url_arr = info.url.split("share_open_id");
                
                url = url_arr[0] + "&share_open_id=" + open_id;
              } else {
                url = url + "&share_open_id=" + open_id;
              }
            } else {
              url = url + "&share_open_id=" + open_id;
            }

            //处理验证成功的信息
            wx.ready(function() {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function(res) {
                  that.save_share_data_(info);
                }
              });
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function(res) {
                  that.save_share_data_(info);
                }
              });

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function(res) {
                  that.save_share_data_(info);
                }
              });
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function(res) {
                  that.save_share_data_(info);
                }
              });
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function() {
                  // 设置成功
                  that.save_share_data_(info);
                }
              });
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function() {
                  // 设置成功
                  that.save_share_data_(info);
                }
              });
            });
          } else {
          }
        });
      } else {
      }
    },
    // 分享成功回调
    save_share_data_(info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            "post",
            "Share/web_share_label_spots",
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: "",
              token: info.token
            },
            ret => {
              if (ret.data.id == 0) {
                this.have_chance = 1;
                this.have_chance_id = ret.data.data.have_chance_id;
              } else {
                this.have_chance = 0;
                this.have_chance_id = "";
              }
            }
          );
        } else {
          this.have_chance = 0;
          this.have_chance_id = "";
        }
      } else {
        this.have_chance = 0;
        this.have_chance_id = "";
      }
    },
    // 初始化sUrl
    initSUrl() {
      if (this.id_) {
        this.sUrl =
          "https://m.zmlxj.com/#/footprints/PrimaryScenic?sid=" +
          this.id_;
      } else if (this.aid) {
        this.sUrl =
          "https://m.zmlxj.com/#/activity/ActivityDetail?aid=" + this.aid;
      } else if (this.vid) {
        this.sUrl = "https://m.zmlxj.com/#/vote/vote?vid=" + this.vid;
      } else if (this.label_id) {
        this.sUrl =
          "https://m.zmlxj.com/#/footprints/LineInfoList?label_id=" +
          this.label_id;
      } else if (this.lid) {
        this.sUrl = "https://m.zmlxj.com/#/books/books?label_id=" + this.lid;
      }
    },
    // 提交答题
    questionSubmit() {
      // 选项是否设置完成
      if (!this.qusetion_title.length) {
        Toast("请设置题目");
        return;
      } else if (!this.content_have_img) {
        if (!this.qs_content_a) {
          Toast("请设置选项A");
          return;
        } else if (!this.qs_content_b) {
          Toast("请设置选项B");
          return;
        } else if (!this.qs_content_c) {
          Toast("请设置选项C");
          return;
        } else if (!this.qs_content_d) {
          Toast("请设置选项D");
          return;
        }
      } else if (this.content_have_img) {
        if (!this.qs_icon_a) {
          Toast("请设置选项A");
          return;
        } else if (!this.qs_icon_b) {
          Toast("请设置选项B");
          return;
        } else if (!this.qs_icon_c) {
          Toast("请设置选项C");
          return;
        } else if (!this.qs_icon_d) {
          Toast("请设置选项D");
          return;
        }
      }
      if (!this.answer_) {
        Toast("请设置正确答案");
        return;
      }

      // 判断内容是否被改变
      let i = this.qs_index; // 拿到当前修改题目的索引
      if (this.questionList[i]) {
        //判断是否是修改
        if (this.qs_set_edit) {
          if (
            this.qusetion_title == this.questionList[i].title &&
            this.title_img == this.questionList[i].newUrl &&
            this.answer_ == this.questionList[i].answer_ &&
            ((this.title_have_img && this.questionList[i].img_key) ||
              (!this.title_have_img && !this.questionList[i].img_key))
          ) {
            // console.log('...');
            if (
              this.content_have_img &&
              this.qs_icon_a == this.questionList[i].a_ &&
              this.qs_icon_b == this.questionList[i].b_ &&
              this.qs_icon_c == this.questionList[i].c_ &&
              this.qs_icon_d == this.questionList[i].d_
            ) {
              this.showSetQuestion = false;
              return;
            } else if (
              !this.content_have_img &&
              this.qs_content_a == this.questionList[i].a_ &&
              this.qs_content_b == this.questionList[i].b_ &&
              this.qs_content_c == this.questionList[i].c_ &&
              this.qs_content_d == this.questionList[i].d_
            ) {
              this.showSetQuestion = false;
              return;
            }
          }
        }
      }

      let that = this; //
      let id = this.questionList[i] ? this.questionList[i].id : 0;
      let type_ = this.content_have_img ? "img" : "text";
      let bg_ = this.title_have_img ? this.title_img : "";
      let is_del_bg_key = this.title_have_img ? 0 : 1;
      let bg_key = this.questionList[i] ? this.questionList[i].img_key : "";
      let a_key, b_key, c_key, d_key;
      if (this.questionList[i] && this.questionList[i].type_ == "img") {
        a_key = this.questionList[i].a_key;
        b_key = this.questionList[i].b_key;
        c_key = this.questionList[i].c_key;
        d_key = this.questionList[i].d_key;
      } else {
        a_key = "";
        b_key = "";
        c_key = "";
        d_key = "";
      }
      let a_, b_, c_, d_;
      if (this.content_have_img) {
        a_ = this.qs_icon_a;
        b_ = this.qs_icon_b;
        c_ = this.qs_icon_c;
        d_ = this.qs_icon_d;
      } else {
        a_ = this.qs_content_a;
        b_ = this.qs_content_b;
        c_ = this.qs_content_c;
        d_ = this.qs_content_d;
      }

      if (
        this.token &&
        this.open_id &&
        this.park_id &&
        this.id_ &&
        this.id_type_ &&
        this.qusetion_title &&
        this.answer_ &&
        a_ &&
        b_ &&
        c_ &&
        d_
      ) {
        if (
          this.qt_img_change ||
          this.qs_change_a ||
          this.qs_change_b ||
          this.qs_change_c ||
          this.qs_change_d
        ) {
          this.$ajax(
            "post",
            "Lottery/get_qiniu_token",
            {
              token: this.token,
              bucket: "public",
              open_id: this.open_id
            },
            res => {
              if (res.data.id == 0) {
                (async () => {
                  this.qiniu_token = res.data.data.token;
                  console.log(this.qt_img_change);
                  if (this.qt_img_change) {
                    bg_ = this.handleImgByQiniu(
                      this.title_img_file.name,
                      this.qt_img_blob,
                      this.qiniu_token,
                      bg_key
                    );
                  }
                  if (this.qs_change_a) {
                    a_ = this.handleImgByQiniu(
                      this.img_file_a.name,
                      this.qs_blob_a,
                      this.qiniu_token,
                      a_key
                    );
                  }
                  if (this.qs_change_b) {
                    b_ = this.handleImgByQiniu(
                      this.img_file_b.name,
                      this.qs_blob_b,
                      this.qiniu_token,
                      b_key
                    );
                  }
                  if (this.qs_change_c) {
                    c_ = this.handleImgByQiniu(
                      this.img_file_c.name,
                      this.qs_blob_c,
                      this.qiniu_token,
                      c_key
                    );
                  }
                  if (this.qs_change_d) {
                    d_ = this.handleImgByQiniu(
                      this.img_file_d.name,
                      this.qs_blob_d,
                      this.qiniu_token,
                      d_key
                    );
                  }
                })().then(
                  this.$ajax(
                    "post",
                    "Lottery/save_question_data",
                    {
                      id: id,
                      id_: this.id_,
                      id_type_: this.id_type_,
                      title: this.qusetion_title,
                      type_: type_,
                      btime: "",
                      etime: "",
                      bg_: bg_,
                      a_: a_,
                      b_: b_,
                      c_: c_,
                      d_: d_,
                      park_id: this.park_id,
                      score: 0,
                      answer_: this.answer_,
                      open_id: this.open_id,
                      token: this.token,
                      bg_key: bg_key,
                      a_key: a_key,
                      b_key: b_key,
                      c_key: c_key,
                      d_key: d_key,
                      is_del_bg_key: is_del_bg_key
                    },
                    res => {
                      // console.log(res);
                      if (res.data.id == 0) {
                        let info = res.data.data.data;
                        if (this.questionList[i]) {
                          this.questionList[i].type_ = info.type_;
                          this.questionList[i].title = info.title;
                          this.questionList[i].newUrl = info.newUrl;
                          this.questionList[i].a_ = info.a_;
                          this.questionList[i].b_ = info.b_;
                          this.questionList[i].c_ = info.c_;
                          this.questionList[i].d_ = info.d_;
                          this.questionList[i].answer_ = info.answer_;
                        } else {
                          this.questionList.push(info);
                          // console.log(this.questionList);
                        }
                        // console.log(this.questionList);
                        this.showSetQuestion = false;
                        // 重置change
                        this.qt_img_change = false;
                        this.qs_change_a = false;
                        this.qs_change_b = false;
                        this.qs_change_c = false;
                        this.qs_change_d = false;
                      }
                    }
                  )
                );
              } else {
                Toast("提交失败");
              }
            }
          );
        } else {
          this.$ajax(
            "post",
            "Lottery/save_question_data",
            {
              id: id,
              id_: this.id_,
              id_type_: this.id_type_,
              title: this.qusetion_title,
              type_: type_,
              btime: "",
              etime: "",
              bg_: bg_,
              a_: a_,
              b_: b_,
              c_: c_,
              d_: d_,
              park_id: this.park_id,
              score: 0,
              answer_: this.answer_,
              open_id: this.open_id,
              token: this.token,
              bg_key: bg_key,
              a_key: a_key,
              b_key: b_key,
              c_key: c_key,
              d_key: d_key,
              is_del_bg_key: is_del_bg_key
            },
            res => {
            
              if (res.data.id == 0) {
                let info = res.data.data.data;
                if (this.questionList[i]) {
                  this.questionList[i].type_ = info.type_;
                  this.questionList[i].title = info.title;
                  this.questionList[i].newUrl = info.newUrl;
                  this.questionList[i].a_ = info.a_;
                  this.questionList[i].b_ = info.b_;
                  this.questionList[i].c_ = info.c_;
                  this.questionList[i].d_ = info.d_;
                  this.questionList[i].answer_ = info.answer_;
                } else {
                  this.questionList.push(info);
                }
                // console.log(this.questionList);
                this.showSetQuestion = false;
                // 重置change
                this.qt_img_change = false;
                this.qs_change_a = false;
                this.qs_change_b = false;
                this.qs_change_c = false;
                this.qs_change_d = false;
              }
            }
          );
        }
      } else {
        //缺少的就是open_id
        //还未授权过
        this.authorize();
      }
    },

    handleImgByQiniu(fileName, blob, token, old_key) {
      let that = this;
      let t = new Date()
        .toLocaleDateString()
        .split("/")
        .map(item => {
          if (item.length <= 1) {
            return "0" + item;
          }
          return item;
        })
        .join("");

      let key = t + "_" + md5(fileName);
      
      if (key == old_key) {
        return old_key;
      }

      var observable = qiniu.upload(blob, key, token);

      var observer = {
        next(res) {
          
          that.showSetQuestion = false;
          that.showUpload = true;
          that.uploadProgress = parseInt(res.total.percent);
        },
        error(err) {
          // ...
          console.log(err);
        },
        complete(res) {
          that.showUpload = false;
        
        }
      };
      var subscription = observable.subscribe(observer);

      return key;
    },
    // 点击答题选项
    choseAnswer(index, answer) {
      if (this.questionList[index].showAnswer) {
        Toast("您已经答过了");
        return;
      }
      this.as_index = index;
      this.answer_control = true; // 显示答题结果
      this.questionList[index].showAnswer = answer;
      this.clickAnswer = this.questionList[index].answer_;
      if (answer == this.questionList[index].answer_) {
        this.isAnswer = true;
        this.$refs.aright.play();
      } else {
        this.isAnswer = false;
        this.$refs.wrong.play();
      }

      let id = this.questionList[index].id;
      if (
        this.open_id &&
        this.token &&
        id &&
        answer &&
        this.park_id &&
        this.id_ &&
        this.id_type_
      ) {
        this.$ajax(
          "post",
          "Lottery/save_answer_question_data",
          {
            open_id: this.open_id,
            id: id,
            answer_: answer,
            token: this.token,
            park_id: this.park_id,
            id_: this.id_,
            id_type_: this.id_type_
          },
          res => {
            if (res.data.id == 0) {
              this.have_chance = res.data.data.have_chance;
              this.have_chance_id = res.data.data.have_chance_id;
            } else {
              Toast("答题失败");
            }
          }
        );
      } else {
        this.authorize();
      }
    },
    // 检查题目图片
    checkTitleImg() {
      if (!this.$refs.qs_title_img.value) {
        return;
      }
      this.qt_img_change = true;
      let arr = this.$refs.qs_title_img.value.split("\\");
      let imgPath = arr[arr.length - 1];
      this.$refs.qs_title_txt.innerHTML = imgPath;

      this.title_img_file = this.$refs.qs_title_img.files[0];

      let reader = new FileReader();
      reader.onload = e => {
        this.title_img = e.target.result;
        this.render(e.target.result, this.title_img_file.name);
        this.qt_img_blob = this.upload(
          this.title_img,
          this.title_img_file.type
        );
      };

      reader.readAsDataURL(this.title_img_file);
    },
    // 检查选项图片
    checkOptionImg(i, opt) {
      // console.log(this.$refs["option_img_" + opt]);
      if (!this.$refs["option_img_" + opt].value) {
        return;
      }
      this["qs_change_" + opt] = true;
      let arr = this.$refs["option_img_" + opt].value.split("\\");
      let imgPath = arr[arr.length - 1];
      this.$refs["option_txt_" + opt].innerHTML = imgPath;
      this["img_file_" + opt] = this.$refs["option_img_" + opt].files[0];

      let reader = new FileReader();
      reader.onload = e => {
        this["qs_icon_" + opt] = e.target.result;
        this.render(e.target.result, this["img_file_" + opt].name);
        this["qs_blob_" + opt] = this.upload(
          this["qs_icon_" + opt],
          this["img_file_" + opt].type
        );
      };

      reader.readAsDataURL(this["img_file_" + opt]);
    },
    // 检查选项内容
    checkOptionTxt(i) {},
    // 删除答题
    deleteQuestion() {
      let id = this.questionList[this.delIndex].id;
      if (this.open_id && this.token && id) {
        this.$ajax(
          "post",
          "Lottery/del_question_data",
          {
            id: id,
            token: this.token,
            open_id: this.open_id
          },
          res => {
            console.log(res);
            if (res.data.id == 0) {
              this.questionList.splice(this.delIndex, 1);
            } else {
              Toast("删除失败");
            }
          }
        );
      } else {
        this.authorize();
      }
      this.showDelQuestion = false;
    },
    // 显示删除答题
    delQuestion(i) {
      if (!this.isAdmin || this.isAdmin == "0") {
        Toast("没有权限");
        return;
      }
      this.delIndex = i;
      this.showDelQuestion = true;
    },
    // 统计数据
    showData() {
      this.show_statistics = true;
    },
    /* 新增答题 */
    addQuestion(i) {
      if (!this.isAdmin || this.isAdmin == "0") {
        Toast("没有权限");
        return;
      }
      if (i == 0) {
        this.qs_index = this.questionList.length;
        console.log(this.qs_index);
        this.qs_set_edit = 0;
        this.qs_header = "答题" + (this.questionList.length + 1) + "设置";
        this.qusetion_title = "";
        this.title_have_img = false;
        this.title_img = "";
        this.qt_img_blob = "";
        this.qt_img_change = false;
        this.content_have_img = false;
        this.answer_ = 0;
        this.qs_icon_a = "";
        this.qs_icon_b = "";
        this.qs_icon_c = "";
        this.qs_icon_d = "";
        this.qs_blob_a = "";
        this.qs_blob_b = "";
        this.qs_blob_c = "";
        this.qs_blob_d = "";
        this.qs_content_a = "";
        this.qs_content_b = "";
        this.qs_content_c = "";
        this.qs_content_d = "";
      } else {
        this.qs_index = i - 1;
        console.log(this.qs_index);
        this.qs_set_edit = 1;
        this.qs_header = "答题" + i + "修改";
        this.qusetion_title = this.questionList[i - 1].title;

        if (this.questionList[i - 1].img_key) {
          this.title_have_img = true;
        } else {
          this.title_have_img = false;
        }

        this.title_img = this.questionList[i - 1].newUrl;
        this.qt_img_blob = this.questionList[i - 1].qt_img_blob;
        this.qt_img_change = this.questionList[i - 1].qt_img_change;
        this.answer_ = this.questionList[i - 1].answer_;

        if (this.questionList[i - 1].type_ == "img") {
          this.content_have_img = true;
          this.qs_icon_a = this.questionList[i - 1].a_;
          this.qs_icon_b = this.questionList[i - 1].b_;
          this.qs_icon_c = this.questionList[i - 1].c_;
          this.qs_icon_d = this.questionList[i - 1].d_;
          this.qs_content_a = "";
          this.qs_content_b = "";
          this.qs_content_c = "";
          this.qs_content_d = "";
        } else if (this.questionList[i - 1].type_ == "text") {
          this.content_have_img = false;
          this.qs_content_a = this.questionList[i - 1].a_;
          this.qs_content_b = this.questionList[i - 1].b_;
          this.qs_content_c = this.questionList[i - 1].c_;
          this.qs_content_d = this.questionList[i - 1].d_;
          this.qs_icon_a = "";
          this.qs_icon_b = "";
          this.qs_icon_c = "";
          this.qs_icon_d = "";
        }
        this.qs_change_a = false; // 选项图片是否被改变
        this.qs_change_b = false;
        this.qs_change_c = false;
        this.qs_change_d = false;
      }
      this.showSetQuestion = true;
      // console.log(this.qs_index);
    },
    // 提交设置
    selectSubmit() {
      // console.log(this.settingType);
      let i = this.settingIndex;
      if (this.settingType == "thanks") {
        if (this.settingChance < 0) {
          Toast("请设置中奖几率");
          return;
        }
      } else {
        if (!this.settingValue || parseFloat(this.settingValue) < 0.3) {
          Toast("请设置奖品价值");
          return;
        } else if (!this.settingNumber || this.settingNumber == "0") {
          Toast("请设置奖品数量");
          return;
        } else if (this.settingChance < 0) {
          Toast("请设置中奖几率");
          return;
        }
        if (this.settingType == "gift" && !this.settingIcon) {
          Toast("请选择礼品图片");
          return;
        }
      }

      if (
        this.prize_list[i].type_ == this.settingType &&
        this.prize_list[i].chance == this.settingChance &&
        this.prize_list[i].numbers == this.settingNumber &&
        this.prize_list[i].price == this.settingValue
      ) {
        this.showSetting = false;
        // console.log('~~~');
        return;
      }
      // this.prize_list[i].isSet = 1;
      this.prize_list[i].type_ = this.settingType;
      this.prize_list[i].chance = this.settingChance; // 奖品几率
      this.prize_list[i].numbers = this.settingNumber; // 奖品数量
      this.prize_list[i].price = this.settingValue; // 奖品价值

      this.showSetting = false;
      let old_img_key = this.prize_list[i].img_key;
      // console.log(this.prize_list[i]);
      // console.log(qiniu);
      var that = this;
      if (
        this.token &&
        this.open_id &&
        this.id &&
        this.lottery_item_id &&
        this.settingType &&
        this.settingNumber
      ) {
        if (this.settingType == "gift") {
          if (this.img_change) {
            this.$ajax(
              "post",
              "Lottery/get_qiniu_token",
              {
                token: this.token,
                bucket: "public",
                open_id: this.open_id
              },
              res => {
                if (res.data.id == 0) {
                  this.qiniu_token = res.data.data.token;
                  let t = new Date()
                    .toLocaleDateString()
                    .split("/")
                    .map(item => {
                      if (item.length <= 1) {
                        return "0" + item;
                      }
                      return item;
                    })
                    .join("");

                  let key = t + "_" + md5(this.img_file.name);
                  var observable = qiniu.upload(
                    this.img_blob,
                    key,
                    this.qiniu_token
                  );

                  var observer = {
                    next(res) {
                      // ...
                      // console.log(res);
                      that.showUpload = true;
                      that.uploadProgress = parseInt(res.total.percent);
                    },
                    error(err) {
                      // ...
                      console.log(err);
                    },
                    complete(res) {
                      // console.log(res);

                      that.$ajax(
                        "post",
                        "Lottery/save_park_luck_label_data",
                        {
                          id: that.lottery_item_id,
                          token: that.token,
                          open_id: that.open_id,
                          park_luck_id: that.id,
                          type_: that.settingType,
                          value_: that.settingValue,
                          prize: that.settingChance,
                          numbers: that.settingNumber,
                          title: that.settingTitle,
                          img_key: res.key,
                          old_img_key: old_img_key
                        },
                        res => {
                          if (res.data.id == 0) {
                            that.money_ = res.data.data.money_;

                            that.prize_list[i].label_name = that.settingTitle; // 奖品名
                            that.prize_list[i].iconUrl = that.settingIcon; //奖品图片
                            // that.prize_list[i].isPrize = 1; // 是否是奖品

                            that.showUpload = false; // 显示上传进度
                            that.img_change = false; // 图片是否修改

                            that.prize_list[i].is_set = 1;
                          } else {
                            Toast("设置失败");
                          }
                        }
                      );
                    }
                  };
                  var subscription = observable.subscribe(observer);
                }
              }
            );
          } else {
            that.$ajax(
              "post",
              "Lottery/save_park_luck_label_data",
              {
                id: that.lottery_item_id,
                token: that.token,
                open_id: that.open_id,
                park_luck_id: that.id,
                type_: that.settingType,
                value_: that.settingValue,
                prize: that.settingChance,
                numbers: that.settingNumber,
                title: that.settingTitle,
                img_key: "",
                old_img_key: old_img_key
              },
              res => {
                if (res.data.id == 0) {
                  that.money_ = res.data.data.money_;

                  that.prize_list[i].label_name = that.settingTitle; // 奖品名
                  that.prize_list[i].iconUrl = that.settingIcon; //奖品图片
                  // that.prize_list[i].isPrize = 1; // 是否是奖品

                  that.showUpload = false; // 显示上传进度
                  that.img_change = false; // 图片是否修改
                  this.prize_list[i].is_set = 1;
                } else {
                  Toast("设置失败");
                }
              }
            );
          }
        } else {
          that.$ajax(
            "post",
            "Lottery/save_park_luck_label_data",
            {
              id: that.lottery_item_id,
              token: that.token,
              open_id: that.open_id,
              park_luck_id: that.id,
              type_: that.settingType,
              value_: that.settingValue,
              prize: that.settingChance,
              numbers: that.settingNumber,
              title: "",
              img_key: "",
              old_img_key: old_img_key
            },
            res => {
              if (res.data.id == 0) {
                that.money_ = res.data.data.money_;
                switch (that.settingType) {
                  case "thanks":
                    that.prize_list[i].label_name = "谢谢参与"; // 奖品名
                    that.prize_list[
                      i
                    ].iconUrl = require("../../assets/lottery/give_up.png"); //奖品图片
                    // that.prize_list[i].isPrize = 0; // 是否是奖品
                    break;
                  case "money":
                    that.prize_list[i].label_name = "现金"; // 奖品名
                    that.prize_list[
                      i
                    ].iconUrl = require("../../assets/switch/redpacket.png"); //奖品图片
                    // that.prize_list[i].isPrize = 1; // 是否是奖品
                    break;
                  case "coupon":
                    that.prize_list[i].label_name = "优惠券"; // 奖品名
                    that.prize_list[
                      i
                    ].iconUrl = require("../../assets/switch/coupon.png"); //奖品图片
                    // that.prize_list[i].isPrize = 1; // 是否是奖品
                    break;
                }
                this.prize_list[i].is_set = 1;
              } else {
                Toast("设置失败");
              }
            }
          );
        }
      } else {
        //缺少的就是open_id
        //还未授权过
        this.authorize();
      }
    },
    // 去支付
    jumpToPay() {
      if (!this.all_set) {
        Toast("请先完成设置");
        return;
      }

      if (
        this.id &&
        this.token &&
        this.park_id &&
        this.open_id &&
        this.id_ &&
        this.id_type_
      ) {
        var open_url =
          "https://www.zmlxj.com/api.php/Activity/web_pay_park_luck?token=" +
          this.token +
          "&id=" +
          this.id +
          "&park_id=" +
          this.park_id +
          "&open_id=" +
          this.open_id +
          "&id_type_=" +
          this.id_type_ +
          "&id_=" +
          this.id_ +
          "&type_=setLottery";
         window.location.href = open_url;
      } else {
        this.authorize();
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=" +
        this.token +
        "&id=" +
        this.id +
        "&state=" +
        this.state;
       window.location.href = open_url;
    },
    // 检查数量
    checkNumber() {
      this.settingNumber = this.settingNumber.replace(/^0/g, "");
      let val = this.settingNumber.replace(/[^\d]/g, "");
      if (val >= 1 && val <= 100000) {
        this.settingNumber = val;
      } else if (val < 1) {
        this.settingNumber = "";
      } else if (val > 100000) {
        this.settingNumber = 100000;
      }
    },
    // 检查概率
    checkChance() {
      // this.settingChance = this.settingChance.replace(/^0/g, "");
      let val = this.settingChance.replace(/[^\d]/g, "");
      if (val >= 0 && val <= 100) {
        this.settingChance = val;
      } else if (val < 0) {
        this.settingChance = "";
      } else if (val > 100) {
        this.settingChance = 100;
      }
    },
    // 检查金额
    checkValue() {
      var regStrs = [
        ["[^\\d\\.]+$", ""], //禁止录入任何非数字和点
        ["\\.(\\d?)\\.+", ".$1"], //禁止录入两个以上的点
        ["^(\\d+\\.\\d{2}).+", "$1"] //禁止录入小数点后两位以上
      ];
      for (let i = 0; i < regStrs.length; i++) {
        var reg = new RegExp(regStrs[i][0]);
        this.settingValue = this.settingValue.replace(reg, regStrs[i][1]);
      }
      // console.log(typeof this.settingValue);
    },
    // 检查图片
    checkImg() {
      if (!this.$refs.uploadImg.value) {
        return;
      }
      this.img_change = true; // 图片被修改了
      let arr = this.$refs.uploadImg.value.split("\\");
      let imgPath = arr[arr.length - 1];
      this.$refs.uploadTxt.innerHTML = imgPath;

      this.img_file = this.$refs.uploadImg.files[0];
      // console.log(file.name);
      let reader = new FileReader();
      reader.onload = e => {
        this.settingIcon = e.target.result;
        this.render(e.target.result, this.img_file.name);
        this.img_blob = this.upload(this.settingIcon, this.img_file.type);
      };

      reader.readAsDataURL(this.img_file);
    },
    // 压缩图片
    render(src, name) {
      let image = new Image();
      let MAX_SIZE = 128;
      image.onload = () => {
        // console.log(image.width, image.height);
        //通过固定的宽高比压缩
        //宽大于高的情况
        if (image.width > MAX_SIZE && image.width >= image.height) {
          image.height *= MAX_SIZE / image.width;
          image.width = MAX_SIZE;
        }
        //宽小于高的情况
        if (image.height > MAX_SIZE && image.height > image.width) {
          image.width *= MAX_SIZE / image.height;
          image.height = MAX_SIZE;
        }
        var canvas = document.createElement("canvas");
        //获取2d画布
        var ctx = canvas.getContext("2d");
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        //绘制图片
        ctx.drawImage(image, 0, 0, image.width, image.height);
        //生成base64码
        var blob = canvas.toDataURL("image/png");
        // console.log(blob);
        this.$refs.imgContent.src = blob;
        // this.settingIcon = blob;
      };
      image.src = src;
    },
    // 去设置
    toSetting(i) {
      if (!this.isAdmin || this.isAdmin == "0") {
        Toast("没有权限");
        return;
      }
      if (this.is_pay == 1 && this.money_ > 0) {
        Toast("支付后不能修改");
        return;
      }

      this.settingIndex = i;
      this.img_key = this.prize_list[i].img_key;
      this.lottery_item_id = this.prize_list[i].id;
      if (i == 0) {
        this.settingType = "money";
      } else if (i == 1) {
        this.settingType = "thanks";
      } else {
        this.settingType = this.prize_list[i].type_;
      }

      this.settingChance = parseFloat(this.prize_list[i].chance); // 奖品几率
      this.settingNumber = parseFloat(this.prize_list[i].numbers); // 奖品数量
      this.settingValue = parseFloat(this.prize_list[i].price)
        ? parseFloat(this.prize_list[i].price)
        : 0; // 奖品面值
      // console.log(parseFloat(this.prize_list[i].price))
      if (this.settingType == "gift") {
        this.settingIcon = this.prize_list[i].iconUrl;
        this.settingTitle = this.prize_list[i].label_name;
        // console.log(this.settingTitle);
      } else {
        this.settingIcon = "";
        this.$refs.uploadTxt.innerHTML = "点击设置图片";
        this.$refs.imgContent.src = "";
      }

      this.showSetting = true;
    },

    // 获取数据
    getData() {
      if (this.id && this.token && this.open_id) {
        this.$ajax(
          "post",
          "lottery/get_park_luck_label_list",
          {
            id: this.id,
            token: this.token,
            open_id: this.open_id,
            id_: this.id_,
            id_type_: this.id_type_
          },
          res => {
            if (res.data.id == 0) {
              // console.log(res.data.data);
              this.wx_title = res.data.data.share.title;
              this.wx_content = res.data.data.share.content;
              this.wx_img = res.data.data.share.imgUrl;
              if (this.is_weixin) {
                this.wx_share_callback(this.sUrl, {
                  url: this.sUrl,
                  id_: this.id_,
                  id_type_: this.id_type_,
                  token: this.token,
                  open_id: this.open_id,
                  title: this.wx_title,
                  content: this.wx_content,
                  img: this.wx_img
                });
              }
              localStorage.setItem("wx_title", res.data.data.share.title);
              localStorage.setItem("wx_content", res.data.data.share.content);
              localStorage.setItem("wx_img", res.data.data.share.imgUrl);
              this.isAdmin = res.data.data.is_admin;
              if (res.data.data.data) {
                this.prize_list = res.data.data.data;
              }
              if (res.data.data.park_luck) {
                //park_luck
                this.park_luck = res.data.data.park_luck;
                this.money_ = this.park_luck.money_;
                this.is_pay = this.park_luck.is_pay;
                this.content = this.park_luck.content.split("\n");
              }
              if (res.data.data.question_list) {
                this.questionList = res.data.data.question_list;
              } else {
                this.questionList = [];
              }

              document.title = this.park_luck.title;
              this.token_ = res.data.data.token ? res.data.data.token : "";
              this.rightAudio = res.data.data.rightAudio
                ? res.data.data.rightAudio
                : ""; // 答对了音效
              this.wrongAudio = res.data.data.wrongAudio
                ? res.data.data.wrongAudio
                : ""; // 答错了音效
              this.have_chance = res.data.data.have_chance
                ? res.data.data.have_chance
                : 0; // 抽奖条件
              this.have_chance_id = res.data.data.have_chance_id
                ? res.data.data.have_chance_id
                : ""; //抽奖条件

              if (this.park_luck.condition_ == "all") {
                // 参加活动的条件
                this.have_question = true;
                this.have_share = true;
              } else if (this.park_luck.condition_ == "question") {
                this.have_question = true;
                this.have_share = false;
              } else if (this.park_luck.condition_ == "share") {
                this.have_question = false;
                this.have_share = true;
              }
            } else {
              Toast("获取数据失败");
            }
          }
        );
      } else {
        //缺少的就是open_id
        //还未授权过
        //this.authorize();
      }
    },

    // 根据概率获取奖品的索引
    getPrizeIndex() {
      this.getDeno();
      let ran = Math.round(Math.random() * this.deno);
      // console.log(ran);
      let sum = 0;
      for (let i = 0; i < this.prize_list.length; i++) {
        sum += parseFloat(this.prize_list[i].chance);
        if (ran < sum) {
          this.index = i;
          // console.log(i);
          return;
        }
      }
    },

    //此方法为处理奖品数据
    init_prize_list(list) {},
    rotate_handle() {
      for (let i = 0; i < this.prize_list.length; i++) {
        if (!this.prize_list[i].is_set || this.prize_list[i].is_set == 0) {
          this.toSetting(i);
          return;
        }
      }
      if (this.money_ > 0 && this.is_pay == 0) {
        this.jumpToPay();
        return;
      }
      if (this.isRotate) {
        Toast("正在抽奖中...");
        return;
      }
      if (this.token_ && this.open_id && this.park_id && this.id) {
        if (this.have_chance == 1 && this.have_chance_id) {
          this.$ajax(
            "post",
            "Lottery/get_chance",
            {
              user_id: "",
              is_zmlxj: "0",
              token: this.token_,
              id: this.id,
              open_id: this.open_id,
              park_id: this.park_id,
              have_chance_id: this.have_chance_id
            },
            res => {
              // console.log(res);
              if (res.data.id == 0) {
                this.have_chance = res.data.data.have_chance;
                this.have_chance_id = res.data.data.have_chance_id;
                this.index = res.data.data.index_;
                this.rotating();
                if (this.prize_list[this.index].type_ == "thanks") {
                  this.isPrize = false;
                } else {
                  this.isPrize = true;
                }
              } else {
                Toast("未中奖");
              }
            }
          );
        } else {
          Toast("没有抽奖机会");
        }
      } else {
        this.authorize();
      }
    },
    rotating() {
      this.isRotate = true; // 旋转结束前，不允许再次触发
      var type = 0; // 默认为 0  转盘转动 1 箭头和转盘都转动(暂且遗留)
      var during_time = 5; // 默认为5s
      var random = Math.floor(Math.random() * 7);
      var result_index = this.index; // 最终要旋转到哪一块，对应prize_list的下标
      var result_angle = [337.5, 292.5, 247.5, 202.5, 157.5, 112.5, 67.5, 22.5]; //最终会旋转到下标的位置所需要的度数
      var rand_circle = 6; // 附加多转几圈，2-3
      if (type == 0) {
        // 转动盘子
        var rotate_angle =
          this.start_rotating_degree +
          rand_circle * 360 +
          result_angle[result_index] -
          (this.start_rotating_degree % 360);
        this.start_rotating_degree = rotate_angle;
        this.rotate_angle = "rotate(" + rotate_angle + "deg)";
        // // //转动指针
        // this.rotate_angle_pointer = "rotate("+this.start_rotating_degree_pointer + 360*rand_circle+"deg)";
        // this.start_rotating_degree_pointer =360*rand_circle;
        var that = this;
        // 旋转结束后，允许再次触发
        setTimeout(function() {
          that.isRotate = false;
          if (this.isPrize) {
            that.$refs.aright.play();
          } else {
            that.$refs.wrong.play();
          }
          that.game_over();
        }, during_time * 1000 + 500); // 延时，保证转盘转完
      } else {
        //
      }
    },
    game_over() {
      this.toast_control = true;

      this.hasPrize = this.prize_list[this.index].type_ == "thanks" ? 0 : 1;
    },
    //关闭弹窗
    close_toast() {
      this.toast_control = false;
      this.answer_control = false;
      this.isAnswer = false;
    },
    handleScroll() {
      //pageYOffset 表示在Y轴的移动值
      //document.documentElement //返回文档的根节点
      // .scrollTop //是否滚到顶部
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 50) {
        this.increaseQuestion = true;
      } else {
        this.increaseQuestion = false;
      }
    }
  }
};
</script>
<style scoped>
html {
  font-size: 14px;
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 376px) and (max-width: 414px) {
  html {
    font-size: 18px;
  }
}
@media screen and (min-width: 415px) and (max-width: 639px) {
  html {
    font-size: 20px;
  }
}
@media screen and (min-width: 640px) and (max-width: 719px) {
  html {
    font-size: 24px;
  }
}
@media screen and (min-width: 720px) and (max-width: 749px) {
  html {
    font-size: 28px;
  }
}
@media screen and (min-width: 750px) and (max-width: 799px) {
  html {
    font-size: 30px;
  }
}
@media screen and (min-width: 800px) {
  html {
    font-size: 48px;
  }
}

.container {
  width: 100%;
  overflow-x: hidden;
}
.lucky-wheel {
  position: relative;
  width: 100%;
  height: 35.5625rem;
  background: rgb(252, 207, 133) url("../../assets/lottery/color_pillar.png")
    no-repeat center bottom;
  background-size: 100%;
  padding-top: 1.5625rem;
}
.lucky-wheel .pay_money {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 2rem;
  text-align: center;
  /* margin-top: 1rem; */
}
.lucky-wheel .pay_money span {
  display: inline-block;
  /* padding: 6px 20px; */
  height: 3rem;
  line-height: 3rem;
  width: 100%;
  font-size: 1rem;
  /* letter-spacing: 4px; */
  color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: rgb(233, 52, 44);
  /* border: 1px solid rgb(206, 36, 36); */
  box-shadow: 0 0 4px rgb(126, 11, 11);
}
.lucky-wheel .pay_money span.setBeforePay {
  background-color: rgba(133, 136, 142, 0.9);

  box-shadow: 0 0 4px rgb(133, 136, 142);
  color: #eee;
}
.lucky-wheel .prize_tip {
  height: 2rem;
  box-sizing: border-box;
  width: 100%;
  padding: 0 1rem;
  text-align: center;
  opacity: 0.8;
  display: flex;
  align-items: center;
}

.lucky-wheel .prize_tip .tip_name {
  display: inline-block;
  border-radius: 4px;
  /* border: 1px solid rgb(228, 46, 36); */
  /* background-color: rgb(233, 52, 44); */
  color: rgb(233, 52, 44);
  margin-right: 0.5rem;
}
.lucky-wheel .prize_tip img {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
}
.new-btn-enter,
.new-btn-leave-to {
  transform: translateX(100%);
}
.new-btn-enter-to,
.new-btn-leave {
  transform: translateX(0);
}
.new-btn-enter-active,
.new-btn-leave-active {
  transition: ease 0.3s;
}

.lucky-title {
  width: 100%;
  height: 8.125rem;
  background: url("../../assets/lottery/lucky_title.png") no-repeat center top;
  background-size: 100%;
}
.wheel-main {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.wheel-bg {
  width: 18.4375rem;
  height: 18.4375rem;
  background: url("../../assets/lottery/draw_wheel.png") no-repeat center top;
  background-size: 100%;
  color: #fff;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  transition: transform 3s ease;
}
.wheel-pointer-box {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -60%);
  width: 5.3125rem;
  height: 5.3125rem;
}
.wheel-pointer {
  width: 5.3125rem;
  height: 5.3125rem;
  background: url("../../assets/lottery/draw_btn.png") no-repeat center top;
  background-size: 100%;
  transform-origin: center 60%;
}
.wheel-bg div {
  text-align: center;
}
.prize-list {
  width: 100%;
  height: 100%;
  position: relative;
}
.prize-list .prize-item {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.prize-list .prize-item:first-child {
  top: 0;
  left: 8.3125rem;
  transform: rotate(20deg);
}
.prize-list .prize-item:nth-child(2) {
  top: 2.8rem;
  left: 10.8rem;
  transform: rotate(67deg);
}
.prize-list .prize-item:nth-child(3) {
  top: 6.4rem;
  left: 10.6rem;
  transform: rotate(-250deg);
}
.prize-list .prize-item:nth-child(4) {
  top: 9rem;
  left: 8.2125rem;
  transform: rotate(-210deg);
}
.prize-list .prize-item:nth-child(5) {
  top: 9.2125rem;
  left: 4.4rem;
  transform: rotate(-160deg);
}
.prize-list .prize-item:nth-child(6) {
  top: 6.3875rem;
  left: 1.9rem;
  transform: rotate(-111deg);
}
.prize-list .prize-item:nth-child(7) {
  top: 2.8rem;
  left: 1.8125rem;
  transform: rotate(-69deg);
}
.prize-list .prize-item:nth-child(8) {
  top: 0;
  left: 4.5rem;
  transform: rotate(-20deg);
}
.prize-item {
  width: 5.875rem;
  height: 9rem;
}

.prize-pic {
  height: 4rem;
}
.prize-pic img {
  height: 2rem;
  margin-top: 1.5625rem;
}
.prize-price {
  font-size: 0.75rem;
}
.prize-type {
  width: 70%;
  margin: 0 auto;
  font-size: 0.75rem;
  color: #fff;
}
.prize-to-set {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: rgba(238, 51, 51, 0.4);
  top: 44%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  animation: to_set 1.6s ease-in-out infinite normal;
}
.prize-to-set span {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #e33;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
@keyframes to_set {
  0% {
    background-color: transparent;
  }
  60% {
    background-color: rgba(238, 51, 51, 0.4);
  }
  100% {
    background-color: transparent;
  }
}
.main {
  position: relative;
  width: 100%;
  min-height: 14.25rem;
  background: rgb(243, 109, 86);
  padding-bottom: 1.6875rem;
  /* margin-top: 2rem; */
}
.main-bg {
  width: 100%;
  height: 6.5625rem;
  position: absolute;
  top: -3.3375rem;
  left: 0;
  background: url("../../assets/lottery/luck_bg.png") no-repeat center top;
  background-size: 100%;
}
.bg-p {
  width: 100%;
  height: 2.5rem;
  background: rgb(252, 207, 133);
}
/* 统计数据 */
.datastat_tip {
  position: fixed;
  top: 70%;
  right: 0;
  box-sizing: border-box;
  /* padding: 0 1rem; */
  text-align: center;
  z-index: 667;
}

.datastat_tip .tip_name {
  display: inline-block;
  font-size: 0.8rem;
  padding: 4px 4px 4px 12px;
  background-color: rgb(255, 169, 33);
  color: #fff;
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
}
/* 答题 */

.question_tip {
  position: fixed;
  top: calc(70% + 2rem);
  right: 0;
  box-sizing: border-box;
  /* padding: 0 1rem; */
  text-align: center;
  z-index: 667;
}

.question_tip .tip_name {
  display: inline-block;
  font-size: 0.8rem;
  padding: 4px 4px 4px 12px;
  background-color: rgb(255, 169, 33);
  color: #fff;
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
}

/* 设置答题 */
.question_set {
  width: 100vw;
  height: 120vh;
  position: fixed;
  top: -10vh;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 667;
}
.question_set .question_wrapper {
  position: relative;
  width: 80%;
  /* height: 60vh; */
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 1rem 0.4rem 1rem;
}
.question_set .question_wrapper .qs_header {
  height: 3rem;
  line-height: 3rem;
  font-size: 0.88rem;
  text-align: center;
  border-bottom: 1px solid #eee;
}
.question_set .question_wrapper .qs_content {
  position: relative;
  padding: 0.8rem 0;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.qs_content .qs_item {
  width: 100%;
  /* padding-bottom: 0.5rem; */
  /* border-bottom: 1px solid #eee; */
}
.question_set .question_wrapper .qs_content .qs_select {
  position: relative;
  display: inline-block;
  margin-left: 1rem;
}
.question_wrapper .qs_content .qs_select .qs_select_img {
  position: relative;
  margin-right: 2rem;
  color: #999;
  font-size: 0.8rem;
  height: 1.6rem;
  line-height: 1.6rem;
  transition: 0.3s;
}
.question_wrapper .qs_content .qs_select .qs_select_img:last-child {
  margin-right: 0;
}
.question_wrapper .qs_content .qs_select_img::before {
  content: "";
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  background-color: rgb(230, 50, 50);
  border-radius: 2px;
  left: -1rem;
  top: 0rem;
  transition: 0.3s;
}
.question_wrapper .qs_content .qs_select .qs_select_active {
  position: relative;
  margin-right: 2rem;
  color: #333;
  transition: 0.3s;
}
.question_wrapper .qs_content .qs_select_active::before {
  content: "\2714";
  color: #fff;
  font-size: 0.6rem;
  line-height: 0.8rem;
  text-align: center;
  transition: 0.3s;
}
.qs_content .qs_item_title {
  display: inline-block;
  height: 1.6rem;
  line-height: 1.6rem;
  color: #333;
}

.qs_content .qs_item .statistics_content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.qs_content .qs_item .statistics_content .statistics_item_opt {
  /* height: 1.6rem; */
  padding: 0.4rem 0;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
}
.qs_content .statistics_item {
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}
.qs_content .qs_item .statistics_content .statistics_percentage {
  width: 100%;
  box-sizing: border-box;
}
.statistics_content .statistics_percentage .chance_percentage_outer {
  display: inline-block;
  width: 100%;
  height: 0.6rem;
  background-color: rgba(252, 200, 200, 0.5);
  border-radius: 2em;
}
.chance_percentage_outer .chance_percentage_inner {
  display: block;
  width: 12%;
  height: 100%;
  background-color: rgb(248, 85, 85);
  border-radius: 2em;
  color: #fff;
}
.statistics_content .statistics_percentage .numbers_percentage_outer {
  display: inline-block;
  width: 100%;
  height: 0.6rem;
  background-color: rgba(252, 200, 200, 0.5);
  border-radius: 2em;
}
.numbers_percentage_outer .numbers_percentage_inner {
  display: block;
  width: 98%;
  height: 100%;
  background-color: rgb(248, 85, 85);
  border-radius: 2em;
  color: #fff;
}

/*  */
.qs_content .qs_item .qs_item_content {
  position: relative;
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}
.qs_content .qs_item .qs_item_content .section {
  position: absolute;
  height: 2rem;
  line-height: 2rem;
  color: #333;
  left: 0.2rem;
}
.qs_content .qs_item .qs_item_content .qs_item_img {
  height: 2rem;
  width: 40%;
  text-align: center;
  overflow: hidden;
}
.qs_content .qs_item .qs_item_content .qs_item_img img {
  height: 2rem;
}
.qs_content .qs_item .qs_item_content textarea {
  box-sizing: border-box;
  width: 100%;
  resize: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  color: #666;
  overflow-x: hidden;
}
textarea:focus {
  outline: none;
}
i {
  font-style: normal;
}
.qs_content .qs_item .qs_item_content textarea::-webkit-input-placeholder {
  color: #999;
}
.qs_content .qs_item .qs_item_content input {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #666;
  display: inline-block;
  font-size: inherit;
  height: 2rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  outline: none;
  padding: 0 0.4rem 0 1rem;
  width: 100%;
}
.qs_item_have .qs_item {
  margin: 0;
}
.qs_item_content {
  width: 100%;
  text-align: center;
}
.show_qt_img {
  height: 3rem;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0 !important;
  /* border-bottom: 1px solid #eee; */
}
.qt_img-enter,
.qt_img-leave-to {
  height: 0;
  opacity: 0;
}
.qt_img-enter-to,
.qt_img-leave {
  height: 3rem;
  opacity: 1;
}
.qt_img-enter-active,
.qt_img-leave-active {
  transition: ease 0.5s;
}
.qs_item_content .select_img {
  position: absolute;
  width: 60%;
  height: 2rem;
  top: 0.5rem;
  padding: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
}

.qs_item_content .section_img {
  position: relative;
  display: inline-block;
  width: 60%;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #99d3f5;
  background-color: #d0eeff;
  color: #1e88c7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.qs_content .qs_item .qs_item_content input::-webkit-input-placeholder {
  color: #999;
}
.qs_content .qs_item .qs_item_content i {
  position: absolute;

  font-size: 0.6rem;
  color: #aaa;
  right: 0.3rem;
  bottom: -0.3rem;
}

.option-enter,
.option-leave-to {
  opacity: 0;
  transform: translateX(50%);
}
.option-enter-to,
.option-leave {
  opacity: 1;
  transform: translateX(0);
}
.option-enter-active,
.option-leave-active {
  transition: ease 0.3s;
}

.question_delete {
  position: fixed;
  width: 100vw;
  height: 120vh;
  left: 0;
  top: -10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 667;
}
.question_delete .qd_wrapper {
  position: relative;
  width: 80%;
  /* height: 40vh; */
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0.5rem;
}
.question_delete .qd_wrapper .qd_title {
  width: 100%;
  /* height: 3rem; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* line-height: 3rem; */
  text-align: center;
  font-size: 0.88rem;
  /* font-weight: 600; */
  color: #666;
}
/* 题目 */
.qs_group {
  position: relative;
}
.qs_list-enter,
.qs_list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.qs_list-leave-active {
  transition: all ease 0.5s;
  position: absolute;
}
.question {
  position: relative;
  margin: 0 auto;
  width: 17.5rem;
  min-height: 6rem;
  border: 1px solid #fbc27f;
  margin-bottom: 1rem;
}
.question .question_preview {
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1rem;
  color: #fccc6e;
  background: rgb(243, 109, 86);
  padding: 0.3125rem 0.625rem;
  font-weight: 400;
}
.question .qs_preview_wrapper {
  position: relative;
  padding: 1.5625rem 0.625rem 1rem;
  font-size: 0.875rem;
  color: #fff8c5;
  line-height: 1.5;
}
.question .qs_preview_wrapper .qs_preview_title img {
  width: 60%;
  display: block;
  margin: 1rem auto;
}

.question .qs_preview_wrapper .qs_preview_text li {
  margin: 0.4rem 0;
  padding: 0 0.4rem;
  color: #fae4b9;
  line-height: 1.8rem;
  box-sizing: border-box;
  border-radius: 4px;
  background: rgb(250, 132, 112);
}

.question .qs_preview_wrapper .qs_preview_img li {
  position: relative;
  left: 20%;
  width: 60%;
  padding: 0.5rem;
  background: rgb(250, 132, 112);
  border-radius: 4px;
  box-sizing: border-box;
}
.question .qs_preview_wrapper ul li img {
  max-width: 100%;
  position: relative;
  display: block;
  margin: 0 auto;
  border-radius: 4px;
  box-sizing: border-box;
}
.question .qs_preview_wrapper ul li.wrong_answer {
  color: #fff;
  background: rgb(255, 44, 7);
  /* transition: 0.5s; */
}
.question .qs_preview_wrapper ul li.right_answer {
  color: #fff;
  background: rgb(24, 217, 66);
  /* transition: 0.5s; */
}
.question .qs_preview_wrapper .question_modify {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  font-size: 0.8rem;
  padding: 4px 12px;
  background-color: rgb(223, 73, 47);
  color: #fff;
}
.question .qs_preview_wrapper .question_del {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  font-size: 0.8rem;
  padding: 4px 12px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #eee;
}

.upcase-enter,
.upcase-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.upcase-leave-active {
  position: absolute;
}

.tip {
  position: relative;
  margin: 2.5rem auto 0;
  width: 17.5rem;
  border: 1px solid #fbc27f;
}
.tip-title {
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1rem;
  color: #fccc6e;
  background: rgb(243, 109, 86);
  padding: 0.3125rem 0.625rem;
}
.tip-content {
  padding: 1.5625rem 0.625rem;
  font-size: 0.875rem;
  color: #fff8c5;
  line-height: 1.5;
}
.toast-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  width: 100%;
  height: 100%;
}
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20000;
  transform: translate(-50%, -50%);
  width: 15.4375rem;
  background: #fff;
  border-radius: 0.3125rem;
  padding: 0.3125rem;
  background-color: rgb(252, 244, 224);
}
.toast-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px dotted #fccc6e;
}
.toast-picture {
  position: absolute;
  top: -6.5rem;
  left: -1.5rem;
  width: 18.75rem;
  height: 8.5625rem;
}
.toast-pictrue-change {
  position: absolute;
  top: -1.5rem;
  left: -1.375rem;
  width: 17.5rem;
  height: 3.125rem;
}
.toast-title {
  padding: 2.8125rem 0;
  font-size: 18px;
  color: #fc7939;
  text-align: center;
}
.toast-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.9375rem;
}
.toast-btn div {
  background-image: -moz-linear-gradient(
    -18deg,
    rgb(242, 148, 85) 0%,
    rgb(252, 124, 88) 51%,
    rgb(252, 124, 88) 99%
  );

  background-image: -ms-linear-gradient(
    -18deg,
    rgb(242, 148, 85) 0%,
    rgb(252, 124, 88) 51%,
    rgb(252, 124, 88) 99%
  );
  background-image: -webkit-linear-gradient(
    -18deg,
    rgb(242, 148, 85) 0%,
    rgb(252, 124, 88) 51%,
    rgb(252, 124, 88) 99%
  );
  box-shadow: 0px 4px 0px 0px rgba(174, 34, 5, 0.7);
  width: 4.6875rem;
  height: 1.875rem;
  border-radius: 1.875rem;
  text-align: center;
  line-height: 1.875rem;
  color: #fff;
}
.close {
  position: absolute;
  top: -0.9375rem;
  right: -0.9375rem;
  width: 2rem;
  height: 2rem;
  background: url("../../assets/lottery/close_store.png") no-repeat center top;
  background-size: 100%;
}

/*  设置界面 */
.set-enter,
.set-leave-to {
  opacity: 0;
  transform: translateY(10vh);
}
.set-enter-to,
.set-leave {
  opacity: 1;
  transform: translateY(0);
}
.set-enter-active,
.set-leave-active {
  transition: ease 0.3s;
}
.set_item {
  position: fixed;
  width: 100vw;
  height: 110vh;
  top: -10vh;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  /* background-color: transparent; */
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
}
.set_item .set_item_wrapper {
  position: relative;
  /* height: 20vh; */
  width: 80%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px #aaa;
  box-sizing: border-box;
  padding: 0 1rem 0.4rem 1rem;
}
.item_close {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  background: url("../../assets/img/pm_close.png") no-repeat center top;
  background-size: 100%;
}

.set_item .set_item_wrapper .item_header {
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  border-bottom: 1px solid #eee;
}
.set_item .set_item_wrapper .item_header h2 {
  font-size: 0.88rem;
  color: #333;
}
.set_item .set_item_wrapper .item_content {
  position: relative;
  padding: 0.8rem 0;
  max-height: 60vh;
  overflow-y: auto;
}
.set_item .set_item_wrapper .item_content .content_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* height: 2rem; */
}
.set_item_wrapper .item_content .content_body .content_row {
  /* height: 3rem; */
  width: 100%;
  text-align: left;
  margin-bottom: 0.4rem;
}
.set_item_wrapper .item_content .content_body .content_row .row_title {
  height: 1rem;
  line-height: 1rem;
  color: #333;
}
.set_item_wrapper .item_content .content_body .content_row .row_title span {
  /* height: 3rem; */
  font-size: 0.6rem;
  font-weight: 400;
  color: #666;
}
.content_body .content_row .row_item {
  position: relative;
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.content_body .content_row .row_item select {
  appearance: none;
}
.content_body .content_row .row_item .select_item {
  background-color: transparent;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #444;
  display: inline-block;
  font-size: inherit;
  height: 2rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  outline: none;
  padding: 0 0.4rem;
  padding-right: 2rem;
  width: 100%;
  font-size: 0.8rem;
  z-index: 1;
}

.content_body .content_row .row_item i {
  position: absolute;
  right: 2rem;
  /* background-color: rgb(230, 66, 66); */
  color: rgb(230, 66, 66);
}
.content_body .content_row .row_item .select_disabled:disabled {
  background-color: #eee;
}
.content_body .content_row .row_item select.type_disbled {
  background-color: #eee;
}
.content_body
  .content_row
  .row_item
  .select_disabled:disabled::-webkit-input-placeholder {
  color: #eee;
}
.content_body .content_row .row_item input::-webkit-input-placeholder {
  color: #aaa;
  font-size: 0.6rem;
}

.content_body .content_row .row_item span {
  position: absolute;
  width: 2rem;
  text-align: center;
  height: 3rem;
  line-height: 3rem;
  top: 0;
  right: 0rem;
  font-size: 0.8rem;
  color: #333;
  z-index: 0;
}
.content_body .content_type .row_item span::after {
  content: "";
  display: block;
  position: absolute;
  border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  border-top: 0.4rem solid #333;
  top: 1.35rem;
  left: 0.6rem;
}
.content_body .content_row .row_item .select_type {
  padding: 0 0.4rem;
}
.content_body .content_row .row_item .upload_img {
  position: relative;
  width: 60%;
  height: 2rem;
  line-height: 2rem;
  margin-right: 1rem;
}
.content_body .content_row .row_item .upload_img span {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #99d3f5;
  background-color: #d0eeff;
  color: #1e88c7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content_body .content_row .row_item .upload_img input {
  position: absolute;
  width: 100%;
  height: 2rem;
  top: 0rem;
  left: 0;
  opacity: 0;
  z-index: 1;
}
.content_body .content_row .row_item .item_img {
  display: inline-block;
  width: 40%;
  height: 2rem;
  overflow: hidden;
  text-align: center;
}
.content_body .content_row .row_item .item_img img {
  height: 2rem;
}

.content_btn {
  width: 100%;
  height: 3rem;
  border-top: 1px solid #eee;
  padding-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_btn button {
  /* width: 100%; */
  /* height: 2rem; */
  white-space: nowrap;
  border: none;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  font-weight: 500;
  border-radius: 4px;
  font-size: 0.88rem;
  padding: 6px 20px;
}
.content_btn button.btn_left {
  margin-right: 1rem;
}
.content_btn button.btn_submit {
  background-color: rgb(226, 46, 38);
  border: 1px solid rgb(224, 41, 31);
  color: #fff;
}
.content_btn button.btn_cancel {
  background-color: transparent;
  border: 1px solid #ccc;
  color: #333;
}
/* 图片上传进度 */
.container .select_title {
  width: 100vw;
  height: 110vh;
  position: fixed;
  top: -10vh;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 667;
}

.container .select_title .upload_wrapper {
  width: 80%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0.4rem 1rem;
}
.container .select_title .upload_wrapper .upload_titile {
  height: 1.6rem;
  line-height: 1.6rem;
  width: 100%;
  color: #666;
  text-align: left;
  font-size: 0.8rem;
}
.container .select_title .upload_wrapper .upload_progress {
  width: 100%;
  height: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container .select_title .upload_wrapper .progress_wrapper {
  position: relative;
  width: 80%;
  height: 10px;
  background-color: rgb(225, 226, 230);
  border-radius: 2em;
}
.container .select_title .upload_wrapper .progress_wrapper .progress {
  height: 10px;
  background: linear-gradient(
    rgb(233, 44, 44),
    rgb(220, 30, 30),
    rgb(233, 44, 44)
  );
  border-radius: 2em;
}
.container .select_title .upload_wrapper .progress_percent {
  text-align: center;
  color: #666;
  width: 20%;
  font-size: 0.8rem;
}
/* 去支付 */

/* 设置说明 */
.rule-enter,
.rule-leave-to {
  transform: translateX(100%);
}
.rule-enter-to,
.rule-leave {
  transform: translateX(0);
}
.rule-enter-active,
.rule-leave-active {
  transition: ease 0.3s;
}
.set_rule {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 0 0.5rem;
  background-color: #fff;
  z-index: 668;
  overflow-y: auto;
  color: #333;
}
.set_rule .rule_close {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  background: #b5d5f9 url("../../assets/img/pm_close.png") no-repeat center
    top;
  background-size: 100%;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;
}
.rule_wrapper .rule_header {
  width: 100%;
  height: 3rem;
  text-align: center;
  line-height: 3rem;
}
.rule_wrapper .rule_header .rule_title {
  display: inline-block;
  padding: 0 1rem;
  border-bottom: 1px solid #eee;
}

.rule_wrapper .rule_content .rule_item {
  margin: 1rem 0;
  border-bottom: 1px solid #eee;
}
.rule_wrapper .rule_content .rule_item p {
  line-height: 2;
  text-align: justify;
  margin: 0.2rem 0;
  color: #666;
}
.rule_wrapper .rule_footer {
  text-align: center;
  color: #999;
  height: 3rem;
  line-height: 3rem;
}
.rule_wrapper .rule_footer a {
  text-decoration: underline;
  color: rgb(62, 149, 248);
}
</style>

