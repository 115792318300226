<template>
  <div v-title data-title="提现成功">
    <mt-header fixed title="提现成功">
      <router-link to="/my" slot="left">
        <mt-button icon="back"></mt-button>
      </router-link>
    </mt-header>

    <div class="page-content">
      <div class="success-content">
        <div class="success-bg"></div>
        <div class="success-text">
          <div class="success-icon">提交成功</div>
          <p>恭喜您，支付成功</p>
          <!-- <span>999.99</span> -->
          <!-- <p>我们将在<label>3个工作日</label>内审核完成!</p> -->
          <p>有任何疑问或者需要协助的地方，欢迎随时联系我们</p>
        </div>
        <div class="success-text">
          <p>客服QQ:</p>
          <span class="tel">377614027</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.success-content {
  padding-top: 120px;
  width: 90%;
  margin: 0 auto;
}
.success-content .success-bg {
  margin-bottom: 20px;
  height: 150px;
  background: url("../../assets/img/tour_bg01.png") no-repeat;
  background-size: cover;
}
.success-content .success-icon {
  font-size: 18px;
  color: #fb884f;
  text-align: center;
  background: url("../../assets/img/tour_yes.png") no-repeat;
  background-size: 20px 20px;
  background-position: 30% 50%;
}
.success-text {
  text-align: center;
  margin-bottom: 26px;
}
.success-text:last-child {
  margin-bottom: 0;
}
.success-text p {
  margin-top: 10px;
  color: #303030;
  font-size: 10px;
}
.success-text p label {
  font-size: 16px;
  color: #f98751;
  text-decoration: underline;
}
.success-text span {
  margin-top: 10px;
  color: #80cefc;
  text-decoration: underline;
}
.success-text span.tel {
  color: #809dfb;
}
</style>

<script>
export default {
  mounted() {
    this.removeDomTitle();
  },
  methods: {
    removeDomTitle() {
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        $(".mint-header").remove();
        $(".page-content").css("padding", "0");
      }
    }
  }
};
</script>