<template>
  <div class="container" v-cloak>
    <div class="head">
      <img :src="sale.user_icon" />
      <span class="user-nick">{{sale.user_nick}}</span>
      <p class="notes">
        邀请了
        <span class="numbers">{{sale.numbers}}</span>名好友
      </p>
    </div>

    <div class="layer">
      <span
        class="label-name"
        :class="sale.is_use == 1 ? 'no-color' : 'yes-color'"
        v-if="sale.is_numbers == 1"
      >
        抽中
        <em>{{sale.label_name}}</em>折
      </span>
      <span
        class="label-name"
        :class="sale.is_use == 1 ? 'no-color' : 'yes-color'"
        v-else
      >抽中{{sale.label_name}}</span>

      <span class="code" :class="sale.is_use == 1 ? 'no-color' : 'yes-color'">核销码{{sale.code}}</span>
      <div class="check" @click="bindCheck(sale.is_use)">
        <span
          class="text"
          :class="sale.is_use == 1 ? 'no-color' : 'yes-color'"
        >{{sale.is_use == 1 ? '核销' : '立即'}}</span>
        <span
          class="text"
          :class="sale.is_use == 1 ? 'no-color' : 'yes-color'"
        >{{sale.is_use == 1 ? '完成' : '核销'}}</span>
      </div>
      <img src="../../assets/sale/yes_sale.png" v-if="sale.is_use == 0" />
      <img src="../../assets/sale/no_sale.png" v-else />
    </div>

    <div class="list">
      <div class="item" v-for="item in list" :key="item.key">
        <div class="icon">
          <img :src="item.user_icon" />
        </div>
        <div class="content">
          <span class="nick">{{item.user_nick}}</span>
          <span class="add-time">{{item.check_time}}</span>
        </div>
        <div class="code">
          <span class="code-text" v-if="item.is_numbers == 1">
            <em>{{item.label_name}}</em>折
          </span>
          <span class="code-text" v-else>
            <ul>{{item.label_name}}</ul>
          </span>
        </div>
      </div>
      <div class="footer" v-if="isShowLoadMore && list.length > 10">
        <span class="footer_text" @click="loadMoreData" :disabled="loadMoreDisable">{{loadMoreText}}</span>
      </div>
    </div>

    <div class="dialog" v-if="is_show_tel">
      <div class="dialog_mask"></div>
      <div class="dialog_container">
        <div class="dialog_content_top">请输入手机号验证身份</div>
        <div class="dialog_content">
          <div class="input-tel">
            <input type="number" placeholder="手机号" v-model="tel_" />
          </div>
          <div class="dialog_btn">
            <ul class="btn" @click="inputTel">确定</ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Toast, MessageBox } from "mint-ui";
import TechnicalSupport from "../../components/TechnicalSupport";
var wx = require("weixin-js-sdk");
export default {
  components: {
    "technical-support": TechnicalSupport,
  },
  data() {
    return {
      // 授权信息
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      version: "1.0.0",
      park_id: 298,
      open_id: localStorage.getItem("open_id")
        ? localStorage.getItem("open_id")
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin, // -------------注意
      token_: this.$route.query.token ? this.$route.query.token : 0, //扫码url后面的 参数
      id_type_: "checkSale",
      user_id: "", //这个值必须要
      is_show_tel: false,
      tel_: "", //用户手机号
     
      sale: "",
      user: "",
      list: "",
      loadMoreText: "加载更多",
      page: 2,
      loadMoreDisable: false,
      isShowLoadMore: false,
    };
  },
  created() {},
  updated() {},
  mounted() {
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    console.log("token=" + this.token);
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== "undefined" &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有union_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.open_id = "";
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }

    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
      if (this.open_id) {
      } else {
        this.authorize();
      }
    }
    if (!this.user_id) {
      var user_id = this.check_login().user_id;
      if (user_id) {
        console.log("user_id:", user_id);
        this.user_id = user_id;
        this.user = this.check_login();
      } else {
        this.is_show_tel = true;
      }
    }
    this.getData(); //
  },
  methods: {
    //弹出输入手机号
    inputTel() {
      if(this.tel_)
      {
        if(/^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[7]))\d{8}$/.test(this.tel_))
        {
           this.is_show_tel = false;
           this.getData();
        }
        else
        {
          Toast("手机号码错误");
        }
      }
      else
      {
        Toast("请输入手机号");
      }
    
    },
    loadMoreData() {
      if (this.token_ && this.token && this.user_id && this.open_id) {
        this.$ajax(
          "post",
          "Shop/ajax_get_check_more_sale_record_list",
          {
            token: this.token,
            token_: this.token_,
            open_id: this.open_id,
            page: this.page,
            user_id: this.user_id,
          },
          (ret) => {
            if (ret.data.id == 0) {
              if (ret.data.data.list.length < 10) {
                //无加载更多数据了
                this.loadMoreText = "已达我的底线了";
                this.loadMoreDisable = true;
              } else {
              }
              this.list = this.list.concat(ret.data.data.list);
              ++this.page;
            } else {
              this.loadMoreText = "我是有底线的";
              Toast("无更多数据");
            }
          }
        );
      } else {
        if (this.user_id == "") {
          //表示需要注册
          this.inputTel();
        } else if (this.open_id == "") {
          this.authorize();
        } else if (this.token_ == "") {
          Toast("识别失败!");
        } else {
          Toast("无效Token");
        }
      }
    },
    /**
     * 进行核销
     */
    bindCheck(is_check) {
      if (is_check == 0) {
        if (this.token_ && this.open_id && this.token && this.user_id) {
          this.$ajax(
            "post",
            "Shop/ajax_check_sale_record_status",
            {
              token: this.token,
              token_: this.token_,
              open_id: this.open_id,
              user_id: this.user_id,
            },
            (ret) => {
              if (ret.data.id == 0) {
                this.sale.is_use = 1;
                //核销成功之后，把数据加到list中
                if(this.list)
                {
                  //为空，先赋值数组，后面再写入值
                  this.list = [];
                }
                else
                {

                }
                this.list.push(this.sale);
                Toast("核销成功");
              } else {
                Toast("核销失败");
              }
            }
          );
        } else {
          if (this.user_id == "") {
            //表示需要注册
            this.inputTel();
          } else if (this.open_id == "") {
            this.authorize();
          } else if (this.token_ == "") {
            Toast("识别失败!");
          } else {
            Toast("无效Token");
          }
        }
      } else {
        Toast("暂不能核销");
      }
    },
    // 获取数据
    getData() {
      if (
        this.token &&
        this.open_id &&
        this.token_ &&
        (this.user_id || this.tel_)
      ) {
        this.$ajax(
          "post",
          "Shop/ajax_get_check_sale_record_list",
          {
            token: this.token,
            token_: this.token_,
            open_id: this.open_id,
            user_id: this.user_id,
            tel_: this.tel_,
          },
          (res) => {
            if (res.data.id == 0) {
              this.sale = res.data.data.sale || "";
              this.list = res.data.data.list || "";
              if (res.data.data.user != "") {
                this.user = res.data.data.user;
                //console.log(res.data.data.sale)
                this.save_arr("user_info", this.user);
              }
              document.title = '店铺折扣核销';
              if (this.list) {
                this.isShowLoadMore = true;
              }
            } else {
              this.isShowLoadMore = false;
              Toast("无数据");
            }
          }
        );
      } else {
        if (this.user_id == "") {
          this.is_show_tel = true;
        } else if (this.open_id == "") {
          this.authorize();
        } else {
          Toast("扫码失败");
        }
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=" +
        this.token_ +
        "&id=" +
        this.park_id +
        "&state=checkSale";
      window.location.href = open_url;
    },

    // 初始化sUrl
    initSUrl() {
      this.sUrl = window.location.href;
    },
    // 微信分享回调
    wx_share_callback(url, info) {
      if (url) {
        var that = this;
        // 先使用？进行分截
        var url_ = url.split("#")[0];
        this.$ajax("post", "Weixin/get_wx_jskey", { url: url_ }, (ret) => {
          if (ret.data.id == 0) {
            // 表示获得了数据-存储在本地
            var title = info.title;
            var content = info.content;
            var img = info.img;
            console.log(ret);
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                // "onMenuShareAppMessage",
                //'onMenuShareTimeline',
                // "onMenuShareQQ",
                // "onMenuShareQZone",
                "openLocation",
                "getLocation",
                "hideMenuItems",
              ],
            });

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            });

            var open_id = this.open_id;
            if (info != "") {
              if (info.url != "") {
                let url_arr = info.url.split("share_open_id");

                url = url_arr[0] + "&share_open_id=" + open_id;
              } else {
                url = url + "&share_open_id=" + open_id;
              }
            } else {
              url = url + "&share_open_id=" + open_id;
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.hideMenuItems({
                menuList: [
                  "menuItem:share:appMessage",
                  "menuItem:share:qq",
                  "menuItem:share:weiboApp",
                  "menuItem:share:facebook",
                  "menuItem:share:QZone",
                ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
              });
            });
          } else {
          }
        });
      } else {
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  background-color: #fd8073;
  min-height: 100%;
}

.container .head {
  width: 98%;
  margin-left: 1%;

  min-height: 30%;
}

.container .head img {
  width: 20%;
  border-radius: 50%;
  margin-left: 41%;
  margin-top: 2%;
  border: 2px solid white;
}

.container .head .user-nick {
  display: block;
  padding: 10px;
  font-size: 1rem;
  text-align: center;
  color: white;
  font-weight: bold;
}

.container .head .notes {
  display: block;

  font-size: 0.7rem;
  color: white;
  text-align: center;
}

.container .head .notes .numbers {
  font-size: 0.8rem;
  color: #ffc75b;
  font-weight: bold;
}

.container .layer {
  width: 100%;
  height: 20%;
}

.container .layer img {
  width: 90%;
  margin-left: 5%;
}
.container .layer .label-name {
  display: block;
  padding: 5px 0px 5px 0px;
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  margin-left: 20%;
  margin-top: 9%;
}
.container .layer .label-name em {
  font-size: 2rem;
  color: tomato;
}
.container .layer .code {
  display: block;
  padding: 3px 0px 3px 0px;
  font-size: 0.8rem;
  text-align: center;
  position: absolute;
  margin-left: 20%;
  margin-top: 21%;
}
.container .layer .check {
  position: absolute;
  margin-top: 9%;
  line-height: 1.9rem;
  margin-left: 70%;
}
.container .layer .check .text {
  display: block;
  padding: 0px;
  font-size: 1.5rem;
  text-align: center;
}
.no-color {
  color: #808080;
}
.yes-color {
  color: #ffc75b;
}

.container .list {
  display: inline-block;
  position: relative;
  width: 90%;
  margin-left: 8%;
  margin-top: 2%;
  margin-bottom: 5%;
}

.container .list .item {
  display: inline-block;
  position: relative;
  width: 100%;
  border-bottom: 2px dotted white;
  padding: 10px 0px 10px 0px;
}
.container .list .item .icon {
  display: inline-block;
  position: relative;
  float: left;
  width: 10%;
}
.container .list .item .icon img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 10px;
}

.container .list .item .content {
  display: inline-block;
  position: relative;
  width: 60%;
  float: left;
}
.container .list .item .content .nick {
  display: block;
  padding: 0px 0px 0px 10px;
  font-size: 1rem;
  color: white;
}
.container .list .item .content .add-time {
  display: block;
  padding: 2px 0px 2px 10px;
  font-size: 0.8rem;
  color: #9c9c9cde;
}

.container .list .item .code {
  display: inline-block;
  position: relative;
  width: 30%;
  float: left;
}
.container .list .item .code .code-text {
  display: block;
  text-align: center;

  color: white;
}
.container .list .item .code .code-text em {
  font-size: 2rem;
}
.container .list .item .code .code-text ul {
  font-size: 1rem;
}

/**加载更多 */
.container .list .footer {
  position: relative;
  width: 40%;
  margin: 1rem auto 0;
  color: #999;
  text-align: center;
  background-color: #eee;
  height: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.container .list .footer .footer_text {
  display: inline-block;
  padding: 0 4px;
  background-color: #fff;
}

/*对话框style*/
.dialog {
  top: 36%;
  position: fixed;
  left: 13%;
}

.dialog_mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.dialog_container {
  background: #fff;
  width: 300px;
  height: 120px;
  position: relative;
  border-radius: 10px;
  margin: 0 auto;
}
.dialog_content_top {
  text-align: center;
  font-size: 0.8rem;
  padding-top: 5px;
}
.dialog_content {
  text-align: center;
  padding-top: 8px;
}
.dialog_btn {
  margin-top: 20px;
}
.dialog_btn a {
  padding: 2px 30px;
  border-radius: 5px;

  text-decoration: none;
  width: 50px;
  display: inline-block;
}
.dialog_btn a:nth-child(2) {
  margin-left: 20px;
}
.dialog_content .input-tel {
  display: block;
}
.dialog_content .input-tel input {
      height: 35px;
    border: 1px solid #fd8073;
    width: 90%;
    margin-left: 2%;
    border-radius: 4px;
}
</style>
