<template>
    <mt-header fixed :title="headertitle">
        <router-link to="/my/MyTrack" slot="left">
            <mt-button icon="back"></mt-button>
        </router-link>
        <mt-button slot="right">
            <router-link :to="link">+</router-link>
        </mt-button>
    </mt-header>
</template>

<script>
    export default {
        data () {
            return {
                headertitle: '',
                link:''
            }
        },
        mounted(){
            this.getServiceType();
        },
        methods:{
            getServiceType(){
                var stype = this.$route.query.type;
                this.link = '/footguide/NewFootGuide?type=' + stype;

                switch(stype){
                    case '1':
                        this.headertitle = '我的徒步向导';
                        break;
                    case '2':
                        this.headertitle = '我的接送机服务';
                        break;
                    case '3':
                        this.headertitle = '我的包车向导';
                        break;
                    case '4':
                        this.headertitle = '我的导游服务';
                        break;
                }

                // 重新添加时，清除指定的localStorage
                var  local = ['title','label_str',
                    'funy','route','notes','cateName','labelName','park_name',
                    'service_number','range_km','time_length','record_title',
                    'time_slot','lang','lang_name','service_money','cost_in',
                    'cost_out','cost_more','advance_time','firstDay','firstKickback',
                    'secondDay','secondKickback','thirdDay','thirdKickback','refund_text',
                    'other','park_id','f_localhost_id'];

                for(var i = 0;i<=local.length - 1;i++){
                    localStorage.removeItem(local[i]);
                }

//                 console.log(this.headertitle);
            }
        }
    }
</script>
