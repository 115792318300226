<template>
  <div class="container" v-title data-title="我的活动" v-show="loaded">
    <i class="back iconfont iconmenu2" @click="goBack"></i>
    <div class="list" v-if="list && list.length">
      <div class="item" v-for="(item, i) in list" :key="i" @click="navToActivity(item.id)">
        <div class="banner">
          <img class="img" :src="item.banner_img_app" />
        </div>
        <div class="content">
          <div class="title">
            <span class="text">{{item.activity_name}}</span>
          </div>

          <div class="info">
            <span class="text time">{{item.activity_begin_time}} - {{item.activity_end_time}}</span>
            <span class="text status">{{item.status}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="none" v-else>还没有活动信息哦~</div>

    <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator, MessageBox } from "mint-ui";
import TechnicalSupport from "../../components/TechnicalSupport";
export default {
  components: {
    "technical-support": TechnicalSupport
  },
  data() {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanMy",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      // 数据
      user: {},

      // 自定义
      page: 1,
      loaded: false,
      isLoading: false,
      haveMore: true,
      list: [],

      // company
      companyInfo: {},
      haveCompany: false
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }

    this.checkLogin();

    this.getData();
    this.getCompanyInfo(this.id, this.token).then(
      res => {
        this.companyInfo = res;
        this.haveCompany = true;
      },
      err => {
        console.log(err);
      }
    );
    this.$nextTick(() => {
      //在冒泡阶段处理滚动的事件
      window.addEventListener("scroll", this.throttle(this.handleScroll));
    });

    if (this.is_weixin && this.id == "1837") {
      this.dealWithUrl(window.location.href);
      const sUrl = "https://m.zmlxj.com/#/nihewan/home?id=" + this.id,
        wx_title = "泥河湾国家自然保护区",
        wx_content = "泥河湾国家自然保护区",
        wx_img = "";
      this.wx_share_callback(sUrl, {
        url: sUrl,
        id_: this.id,
        id_type_: this.backUrl,
        token: this.token,
        open_id: this.open_id,
        title: wx_title,
        content: wx_content,
        img: wx_img
      });
    }
  },
  methods: {
    // 上一级
    goBack() {
      this.$router.push({
        path: "/nihewan/home",
        query: {
          id: this.id
        }
      });
    },
    // 活动详情
    navToActivity(id) {
      this.$router.push({
        path: "/nihewan/activity",
        query: {
          id: this.id,
          aid: id
        }
      });
    },
    /**
     * 获取数据
     */
    getData() {
      if (this.isLoading || !this.haveMore) {
        return false;
      }
      this.isLoading = true;
      Indicator.open("加载中...");
      //请求参加过的活动数据
      //判断用户是否登录
      const user_id = this.user.user_id;
      if (user_id) {
        this.$ajax(
          "post",
          "weixin/get_user_join_activity",
          {
            user_id: user_id,
            token: this.token,
            page: this.page,
            park_id: this.park_id,
            version: this.version
          },
          res => {
            if (res.data.id == 0) {
              this.list.push(...res.data.data);
              this.page++;
            }

            if (
              res.data.id !== 0 ||
              !res.data.data ||
              res.data.data.length < 10
            ) {
              this.haveMore = false;
            }
            this.isLoading = false;
            this.loaded = true;
            Indicator.close();
          }
        );
      } else {
        //没有参加过活动
        Toast("没有活动信息");
      }
    },
    // 登录
    checkLogin() {
      if (!this.check_login()) {
        MessageBox.alert("请先登录").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
          }
        });
      } else {
        this.user = this.check_login();
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    },
    //获取页面滚动的高度
    handleScroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;

      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;

      // 加载更多
      if (
        scrollTop + windowHeight > scrollHeight - 100 &&
        this.haveMore &&
        this.page > 1
      ) {
        this.getData();
      }
    },
    throttle(fn, interval = 100) {
      let canRun = true;
      return function() {
        if (!canRun) return;
        canRun = false;
        setTimeout(() => {
          fn.apply(this, arguments);
          canRun = true;
        }, interval);
      };
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: rgb(243, 245, 248);
}

.back {
  position: fixed;
  top: 0.4rem;
  left: 0.4rem;
  height: 1.6rem;
  width: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.list {
  width: 100%;
  height: 100%;
  padding: 3rem 1rem;
  box-sizing: border-box;
  background-color: rgb(249, 250, 250);
}

.item {
  width: 100%;
  margin-bottom: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.item > .banner {
  width: 100%;
  height: 51vw;
}

.item > .banner > .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item > .content {
  padding: 1rem;
  color: #666;
}
.item > .content > .title {
  font-size: 0.88rem;
  color: #000;
}

.item > .content > .info {
  font-size: 0.68rem;
  color: #999;
}

.item > .content > .info {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item > .content > .info > .status {
  padding: 2px 6px;
  border: 1px solid rgb(253, 206, 136);
  color: #e6a23c;
}

/* 没有信息 */
.none {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: #999;
  background-color: rgb(245, 245, 245);
  text-shadow: 0 1px 1px #fff;
}

.company {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  color: rgba(5, 5, 5, 0.3);
  text-align: center;
  font-size: 0.74rem;
}
</style>