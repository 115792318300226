<template>
  <div class="pay_goods width-768">
    <div class="pay_goods_wrapper">
      <!-- 收货地址: is_address 判断是否需要收货地址 -->
      <div class="address-box pointer" v-if="showAddress" @click="choseAddress(!!address)">
        <!-- 如果地址列表有值 address_list -->
        <div class="delivery-address" v-if="address">
          <div class="address-area">
            <!-- 默认地址标记 -->
            <span class="main-address-tag" v-if="!!+address.is_main">默认地址</span>
            <span class="province">{{address.province_ || ""}}</span>
            <span class="city">{{address.city_ || ""}}</span>
          </div>
          <div class="address-detail">
            <span class="address">{{address.address_}}</span>
            <span class="house">{{address.house_}}</span>
          </div>
          <div class="address-user">
            <span class="name">{{address.name_}}</span>
            <span class="tel">{{address.tel_}}</span>
          </div>
        </div>
        <!-- 如果地址列表没有值 address_list -->
        <div class="no-address" v-else>
          <i class="iconfont iconjia"></i>
          <span>新增收货地址</span>
        </div>
        <div class="address-chose-btn">
          <i class="iconfont iconqianjin"></i>
        </div>
      </div>
      <!-- 用户 -->
      <div class="goods_store">
        <img :src="info.user_icon" alt class="goods_store_img" />
        <span class="goods_store_name">{{info.user_nick}}</span>
      </div>
      <!-- 商品信息 -->
      <div class="goods_info" @click="backToMerchant">
        <img :src="info.newUrl" alt class="goods_info_img" />
        <div class="goods_info_content">
          <span class="goods_info_title">{{info.title}}</span>
          <span class="goods_info_chose">已选：{{info.label_str}}</span>
          <span class="goods_info_price">单价: ￥{{info.amount}}</span>
        </div>
      </div>

      <!-- 选择购买数量 -->
      <div class="goods_num">
        <span class="goods_num_name">购买数量</span>
        <div class="goods_num_input">
          <span
            class="num_input_left"
            :class="preDisabled ? 'is_disabled' : ''"
            @click.stop="changeGoodsNumber(0)"
          >-</span>
          <span
            class="num_input_right"
            :class="lasDisabled ? 'is_disabled' : ''"
            @click.stop="changeGoodsNumber(1)"
          >+</span>
          <div class="num_input">
            <input
              type="text"
              min="1"
              max="256"
              oninput="value=value.replace(/[^\d]/g || /^0/g,'')"
              v-model="goodsNumber"
            />
          </div>
        </div>
      </div>

      <!-- 选择时间 -->
      <div class="goods_date">
        <div class="goods_date_name">选择时间</div>
        <div class="goods_date_input">
          <input class="goods_date_start" type="date" v-model="startDate" />
          <span>&nbsp;-&nbsp;</span>
          <input class="goods_date_end" type="date" v-model="endDate" />
        </div>
      </div>
      <!-- 备注 -->
      <div class="goods_remarks">
        <span class="goods_remarks_name">店铺备注</span>
        <input
          type="text"
          placeholder="选填,给商家留言"
          v-model="inputValue"
          maxlength="45"
          @click="toView($event)"
        />
        <span class="goods_remarks_count">{{inputLength}}/45</span>
      </div>
      <!-- 选择支付方式 -->

      <div class="goods_payment">
        <!-- 使用优惠券 -->
        <div class="goods_coupon">
          <div class="good_coupon_left payment_type">
            <img src="../../assets/switch/wx_coupon.png" alt />
            <span>使用券</span>
          </div>
          <div class="good_coupon_right"></div>
        </div>
        <!-- 自己支付 -->
        <div
          :class="[payment===1 ? 'payment_active' : '','payment_chose']"
          @click="chosePayment(1)"
        >
          <div class="payment_wechat payment_type">
            <img src="../../assets/switch/wx_pay.png" alt />
            <span class="payment_type_name">微信支付</span>
          </div>
          <i class="iconfont iconradiochecked" v-show="payment===1"></i>
        </div>
        <!-- 找好友支付 -->
        <div
          :class="[payment===2 ? 'payment_active' : '','payment_chose']"
          @click="chosePayment(2)"
        >
          <div class="payment_wechat_friend payment_type">
            <img src="../../assets/switch/wx_friend.png" alt />
            <span class="payment_type_name">找微信好友支付</span>
          </div>
          <i class="iconfont iconradiochecked" v-show="payment===2"></i>
        </div>
        <!-- 测试选择的时间 -->
        <div class="goods_selectTime payment_type">
          <img src="../../assets/switch/wx_day.png" alt />
          <span>已选天数： {{fullDate}}</span>
        </div>
      </div>

    </div>
    <!-- 底部支付信息 -->
    <div class="goods_footer width-768" ref="footer">
      <div class="goods_realpay">
        <span class="realpay_name">实付款:</span>
        <span class="realpay_content">￥{{acountPrice * fullDate}}</span>
      </div>
      <div class="goods_submit" @click="submitPay">{{ payment_text }}</div>
    </div>

    <!-- 选择收货地址 -->
    <transition duration="300">
      <div class="select-address-box width-768" v-show="showSelectAddress" @click.self="showSelectAddress = false">
        <transition name="slide-top">
          <div class="select-address-wrapper" v-show="showSelectAddress">
              <div class="head">
                <div class="title">选择收货地址</div>
                <div class="close pointer" @click="showSelectAddress = false">
                  <i class="iconfont iconbaseline-close-px"></i>
                </div>
              </div>
              <!-- 收货地址列表 -->
              <div class="list">
                <div :class="['item', i === addressIndex ? 'item-active' : '']" v-for="(item, i) in info.address_list" :key="item.id" @click="checkedAddress(i)">
                  <!-- 选择地址按钮 -->
                  <div class="item-radio pointer">
                    <i class="iconfont iconradiochecked" v-if="i === addressIndex"></i>
                    <i class="iconfont iconradio" v-else></i>
                  </div>
                  <div class="item-content">
                    <div class="address-area">
                      <!-- 默认地址标记 -->
                      <span class="main-address-tag" v-if="!!+item.is_main">默认地址</span>
                      <span class="province">{{item.province_ || ""}}</span>
                      <span class="city">{{item.city_ || ""}}</span>
                    </div>
                    <div class="address-detail">
                      <span class="address">{{item.address_}}</span>
                      <span class="house">{{item.house_}}</span>
                    </div>
                    <div class="address-user">
                      <span class="name">{{item.name_}}</span>
                      <span class="tel">{{item.tel_}}</span>
                    </div>
                  </div>
                  <div class="item-edit pointer" @click.stop="handleEditAddress(item)">
                    <i class="iconfont iconbianji1"></i>
                  </div>
                </div>
              </div>
              <!-- 新增收货地址 -->
              <div class="foot">
                <div class="new-address pointer" @click="handleEditAddress(null)">
                  <i class="iconfont iconjia"></i>
                  <span>新增收货地址</span>
                </div>
              </div>
          </div>
        </transition>
      </div>
    </transition>

    <!-- start 添加 / 编辑 收货地址 -->
    <transition duration="300">
      <div class="edit-address-box width-768" v-show="showEditAddress">
        <transition name="slide-right">
          <div class="edit-address-wrapper" v-show="showEditAddress">
            <div class="head">{{editTitle}}</div>
            <div class="content">
              <!-- 联系人 -->
              <p class="field-title">联系人</p>
              <div class="contact field-box">
                <div class="field">
                  <div class="label">收货人</div>
                  <div class="value">
                    <input type="text" class="inp" v-model="formAddressee">
                  </div>
                </div>
                <div class="field">
                  <div class="label">默认地址</div>
                  <div class="value">
                    <div class="radio pointer" @click="formMainAddress=true">
                      <i class="iconfont iconradiochecked" v-if="formMainAddress"></i>
                      <i class="iconfont iconradio" v-else></i>
                      <span>是</span>
                    </div>
                    <div class="radio pointer" @click="formMainAddress=false">
                      <i class="iconfont iconradio" v-if="formMainAddress"></i>
                      <i class="iconfont iconradiochecked" v-else></i>
                      <span>否</span>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="label">手机号</div>
                  <div class="value">
                    <input type="text" class="inp" v-model="formTel">
                  </div>
                </div>
              </div>

              <!-- 收货地址 -->
              <p class="field-title">收货地址</p>
              <div class="address field-box">
                <div class="field">
                  <div class="label">省市区</div>
                  <div class="value pointer">
                    <div class="btn" role="button" @click="gaodeSearch('country')">{{formProvince || "请选择省/市/区"}}</div>
                  </div>
                </div>
                <div class="field">
                  <div class="label">地级市</div>
                  <div class="value pointer">
                    <div class="btn" role="button"  @click="gaodeSearch('province')">{{formCity || "请选择所在地级市"}}</div>
                  </div>
                </div>
                <div class="field">
                  <div class="label">详细地址</div>
                  <div class="value">
                    <input type="text" id="map-address" class="inp" v-model="formAddress" placeholder="请输入详细地址" @input="addressChange($event)">
                  </div>
                </div>
                <div class="field">
                  <div class="label">门牌号</div>
                  <div class="value">
                    <input type="text" class="inp" v-model="formHouse" placeholder="地址详细补充，如“5大楼101室” ">
                  </div>
                </div>
              </div>

              <div class="btns">
                <button class="btn save pointer" @click="handleSaveAddress">保存</button>
                <button class="btn cancel pointer" @click="showEditAddress=false">取消</button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <!-- end 添加 / 编辑 收货地址 -->

    <!-- start 行政区域选择器 -->
    <transition duration="300">
      <div class="district-box width-768" v-show="showDistrict">
        <transition name="slide-top">
          <div class="district-wrapper" v-show="showDistrict">
            <!-- <ul class="list" v-if="districtList">
              <li class="item" v-for="(item, i) in districtList" :key="item.adcode">
                {{item.name}}
              </li>
            </ul> -->
            <div class="head">
              <div class="btn pointer" role="button" @click="cancleFormDistrict">取消</div>
              <div class="btn pointer" role="button" @click="setFormDistrict">确定</div>
              <!-- <div class="close pointer"><i class="iconfont iconclose"></i></div> -->
            </div>
            <mt-picker
              :slots="districtSlots"
              valueKey="name"
              :visibleItemCount="visibleItemCount"
              @change="onDistrictChange"
              v-if="districtList"></mt-picker>
          </div>
        </transition>
      </div>
    </transition>
    <!-- end 行政区域选择器 -->
  </div>
</template>

<script>
import { setTimeout } from 'timers'
import { Toast, Indicator } from 'mint-ui'
export default {
  data () {
    return {
      sid: this.$route.query.sid ? this.$route.query.sid : '',
      id_: this.$route.query.id_ ? this.$route.query.id_ : '',
      user_id: '',

      version: '1.0.0',
      park_id: 298,
      oid: this.$route.query.oid ? this.$route.query.oid : 0,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      //   支付信息
      payment: 1, // 支付方式
      payment_text: '立即支付',
      inputValue: '', // 备注信息
      scrollHeight: 0, // 窗口高度
      startDate: '', // 选择开始日期
      endDate: '', // 选择结束日期

      // 获取到的数据
      info: [],
      goodsNumber: 1, // 商品数量
      preDisabled: true, // 是否不可减少数量
      lasDisabled: false, // 是否不可增加数量
      acountPrice: 0, // 总金额
      curPrice: 0, // 单价
      state: 'payOrder',

      // 自定义数据
      showAddress: false, // 显示收货地址
      address: null, // 收货地址
      addressIndex: 0, // 选择的地址索引
      showSelectAddress: false, // 显示选择收货地址列表
      showEditAddress: false, // 显示添加/编辑地址

      // 添加/编辑地址 子页面
      editTitle: '添加收货地址', // 添加或编辑地址标题
      formAddressee: '', // 收件人
      formMainAddress: false, // 是否是默认地址
      formTel: '', // 手机号
      formProvince: '', // 省市区
      formCity: '', // 地级市
      formAddress: '', // 详细地址
      formHouse: '', // 详细门牌号
      districtList: null, // 行政区域列表
      showDistrict: false, // 显示行政区域选择列表
      districtSlots: [{
        flex: 1,
        values: [],
        textAlign: 'center',
        defaultIndex: 4
      }],
      visibleItemCount: 7, // 区域选择器显示的区域个数
      level: null, // 高德区域选择级别
      selectProvince: null,
      selectCity: null,
      isSelectAddress: false, // 是否是地址联想

      isLoad: false, // 正在向服务器发送请求
      lng: null, // 收货地址的经纬度
      lat: null,

      // 编辑地址
      addressId: '',
      editType: ''
    }
  },

  created () {
    //   let priceList = this.price.split("_")
    this.initData()
    this.scrollHeight = document.body.scrollHeight
    // 屏幕高度改变时，显示或隐藏底部按钮
    // 解决固定定位被软键盘顶起的问题
    window.onresize = () => {
      if (document.body.scrollHeight < this.scrollHeight) {
        this.$refs.footer.style.visibility = 'hidden'
      } else {
        this.$refs.footer.style.visibility = 'visible'
      }
    }
  },
  mounted () {
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id !== 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有union_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token === this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }

    if (this.open_id) {
    } else {
      this.authorize()
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
      this.wx_share(window.location.href)
    }

    this.getData() // 最后发送网络请求，获取数据
  },
  methods: {
    // 授权方法
    authorize () {
      const url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id_=' +
        this.id_ +
        '&id=' +
        this.sid +
        '&state=' +
        this.state
      window.location.href = url
    },
    // 提交支付
    submitPay () {
      if (this.is_weixin) {
        if (this.payment === 1) {
          // 还未支付 支付方式为：立即支付
          if (this.open_id) {
            // 已经授权过了,进行支付
            let address = ''
            if (this.info.is_address === '1') {
              address = this.address.province_ + ',' + this.address.city_ + ',' + this.address.address_ + ',' + this.address.house_ + ',' + this.address.tel_
            } else {
              address = ''
            }
            const url =
              'https://www.zmlxj.com/api.php/Activity/web_pay_spots_order?id=' +
              this.oid +
              '&label_id=' +
              this.id_ +
              '&open_id=' +
              this.open_id +
              '&token=' +
              this.token +
              '&park_id=' +
              this.park_id +
              '&version=' +
              this.version +
              '&is_pay=1' +
              '&numbers=' +
              this.goodsNumber +
              '&btime=' +
              this.startDate +
              '&etime=' +
              this.endDate +
              '&notes=' +
              this.inputValue +
              '&address=' + address

            window.location.href = url
          } else {
            // 还未授权过
            this.authorize()
          }
        } else if (this.payment === 2) {
          // console.log("22");
          // 支付方式为 请好友代付
          this.$router.push({
            path: '/payforgoods/payByFriend',
            query: {
              sid: this.sid,
              oid: this.oid,
              id_: this.id_
            }
          })
        }
      } else {
        Toast('请在微信中打开！')
      }
    },
    // 返回商户
    backToMerchant () {
      this.$router.push({
        path: '/footprints/PrimaryScenic',
        query: {
          sid: this.sid,
          id_: this.id_
        }
      })
    },
    // 获取数据
    getData () {
      this.$ajax(
        'post',
        'Shop/get_spots_order',
        {
          id: this.oid,
          token: this.token,
          park_id: this.park_id,
          version: this.version,
          open_id: this.open_id,
          is_pay: 0
        },
        res => {
          if (res.data.id === 0) {
            // 返回的数据
            this.info = res.data.data.data
            document.title = this.info.title
            // 商品数量
            this.goodsNumber = +this.info.numbers
            // 商品价格
            this.curPrice = +this.info.amount
            // 开始时间
            if (this.info.btime) {
              this.startDate = this.info.btime
            }
            // 结束时间
            if (this.info.etime) {
              this.endDate = this.info.etime
            }
            // 商品总价
            this.acountPrice = (
              this.goodsNumber * this.curPrice
            ).toFixed(2)

            // 获取收货地址
            this.setAddress()
          } else {
            // 缺少的就是open_id
            // 还未授权过
            this.authorize()
          }
        }
      )
    },
    setAddress () {
      if (this.info.is_address === '1') {
        // 如果当前订单需要收货地址
        this.showAddress = true // 显示顶部地址栏
        if (this.info.address_list && this.info.address_list.length) {
          // 如果用户已经有地址了
          // 设置默认显示的地址
          for (let i = 0, len = this.info.address_list.length; i < len; i++) {
            let flag = !!+this.info.address_list[i].is_main
            if (flag) {
              this.address = this.info.address_list[i]
              this.addressIndex = i
              break
            }
          }

          if (!this.address) {
            this.address = this.info.address_list[0]
            this.addressIndex = 0
          }
        } else {
          this.address = null
        }
      } else {
        this.showAddress = false
      }
    },
    // 选择支付方式
    chosePayment (payment) {
      this.payment = payment
      if (payment === 1) {
        this.payment_text = '立即支付'
      } else if (payment === 2) {
        this.payment_text = '找好友代付'
      }
    },

    // 初始化数据
    initData () {
      // 初始时间
      let t = new Date()
        .toLocaleDateString()
        .split('/')
        .map(item => {
          if (item.length <= 1) {
            return '0' + item
          }
          return item
        })
      this.startDate = this.endDate = t.join('-')
      // console.log(this.startDate);
      this.preDisabled = !(this.goodsNumber > 1)
    },
    // 加减号修改商品数量
    changeGoodsNumber (flag) {
      let num = Number(this.goodsNumber)
      //   console.log(flag);
      //   console.log(num);
      if (flag === 0) {
        if (num <= 1) {
          return
        } else {
          num--
        }
      } else if (flag === 1) {
        if (num >= 256) {
          return
        } else {
          num++
        }
      }
      this.goodsNumber = num.toString()
    },
    // 点击输入时，将输入框移动到可视区域
    toView (event) {
      setTimeout(() => {
        event.target.scrollIntoView()
      }, 300)
    },
    /**
     * 选择收货地址，根据flag判断是选择地址还是直接添加新的地址
     * 如果有收货地址，去选择； 如果没有收货地址，去添加地址
     * @param {Boolean} flag 判断是否已经有地址了
     */
    choseAddress (flag) {
      if (flag) {
        // 如果有地址，去选择
        this.showSelectAddress = true
      } else {
        // 没有地址，去添加
        this.handleEditAddress(null)
      }
    },
    /**
     * 选中收货地址
     * @param {Number} i 选中的地址在地址列表中的索引值
     */
    checkedAddress (i) {
      this.addressIndex = i
      this.address = this.info.address_list[i]
      this.showSelectAddress = false
    },
    /**
     * 处理新增或编辑地址事件
     * @param {Object} obj 新增时为null，编辑时为当前编辑的地址对象。通过判断 obj 是否为 null，区分是新增还是编辑
     */
    handleEditAddress (obj) {
      if (obj === null) {
        // 新增地址
        this.editType = 'new'
        // 初始化数据
        this.editTitle = '添加收货地址'
        this.formAddressee = ''
        this.formMainAddress = false
        this.formTel = ''
        this.formProvince = ''
        this.formCity = ''
        this.formAddress = ''
        this.formHouse = ''
        this.lng = null
        this.lat = null // 重置地址经纬度
        // 高德地图区域查询重置
        this.level = null
        this.districtList = null
        this.selectProvince = null
        this.selectCity = null
        this.showDistrict = false
        this.districtSlots[0].values.length = 0
        this.isSelectAddress = false // 重置是否是联想地址标记
      } else {
        // 编辑地址
        this.editType = 'edit'
        this.editTitle = '编辑收货地址'
        this.addressId = obj.id
        this.formAddressee = obj.name_
        this.formMainAddress = !!+obj.is_main
        this.formTel = obj.tel_
        this.formAddress = obj.address_
        this.formProvince = obj.province_
        this.formCity = obj.city_
        this.formHouse = obj.house_
        this.lng = obj.lng
        this.lat = obj.lat
        // 高德地图区域查询重置
        this.level = 'province'
        this.districtList = null
        this.selectCity = this.formCity
        this.selectProvince = this.formProvince
        this.isSelectAddress = true
        this.districtSlots[0].values.length = 0
        this.showDistrict = false

        // 编辑时，设置区域范围为城市，设置地址联想的城市限制
        let cityName = this.formCity
        if (this.formCity.indexOf('城区') > -1) {
          cityName = this.formProvince
        }
        // 绑定地址联想的所在城市
        this.bindAutoComplete(cityName)
      }

      this.showEditAddress = true
      this.showSelectAddress && (this.showSelectAddress = false)
    },
    /**
     * 高德行政区域查询及输入联想
     * @param {String} level 搜索级别  country  city
     */
    gaodeSearch (level) {
      this.level = level
      let searchName = null
      if (level === 'country') {
        searchName = '中国'
      } else if (level === 'province') {
        if (!this.formProvince) {
          Toast('请先选择省市区')
          return false
        } else {
          searchName = this.selectProvince
        }
      }
      // eslint-disable-next-line no-undef
      AMap.plugin('AMap.DistrictSearch', () => {
        // eslint-disable-next-line no-undef
        let districtSearch = new AMap.DistrictSearch({
          // 关键字对应的行政区级别，country表示国家
          level: level,
          //  显示下级行政区级数，1表示返回下一级行政区
          subdistrict: 1
        })

        // 搜索所有省/直辖市信息
        districtSearch.search(searchName, (status, result) => {
          // 查询成功时，result即为对应的行政区信息
          if (status === 'complete' && result.info.toUpperCase() === 'OK') {
            this.districtList = result.districtList[0].districtList
            this.districtSlots[0].values = this.districtList
            // console.log(result)
            this.showDistrict = true
          }
        })
      })
    },
    /**
     * 区域选择器值改变
     * @param {Picker} picker mint-ui 中 Picker 的实例
     * @param {Array} values 当前选择的区域 包含区域对象的数组
     */
    onDistrictChange (picker, values) {
      // console.log(values)
      if (this.level === 'country') {
        this.selectProvince = values[0].name
      } else if (this.level === 'province') {
        this.selectCity = values[0].name
      }
    },
    /**
     * 设置表单的数据  根据选择的级别判断是省市区或者是地级市
     * @param {String} name 选择的省市区或地级市名称
     */
    setFormDistrict (name) {
      if (this.level === 'country') {
        this.formProvince = this.selectProvince
      } else if (this.level === 'province') {
        this.formCity = this.selectCity
        let cityName = this.formCity
        // 如果选择的城市包含 ‘城区’ ，表明之前选择的省市区为直辖市，在地址联想时，需要通过直辖市名称而不是城区名称来联想
        if (this.formCity.indexOf('城区') > -1) {
          cityName = this.formProvince
        }
        // 绑定地址联想的所在城市
        this.bindAutoComplete(cityName)
      }
      this.showDistrict = false
    },
    /**
     * 取消选择的省市区/地级市
     * 取消时需要将picker选择的重置
     */
    cancleFormDistrict () {
      if (this.level === 'country') {
        this.selectProvince = this.formProvince
      } else if (this.level === 'province') {
        this.selectCity = this.formCity
      }
      this.showDistrict = false
    },
    /**
     * 高德地址联想
     * @param {String} city 设置地址联想的城市，在该城市中进行联想
     */
    bindAutoComplete (city) {
      // eslint-disable-next-line no-undef
      AMap.plugin('AMap.Autocomplete', () => {
        // 实例化Autocomplete
        var autoOptions = {
          city: city,
          // input 为绑定输入提示功能的input的DOM ID
          input: 'map-address',
          citylimit: true
        }
        // eslint-disable-next-line no-undef
        this.autoComplete = new AMap.Autocomplete(autoOptions)
        this.autoComplete.on('select', (e) => {
          // 当用户点击了联想地址后，处理事件
          this.handleAddressSelect(e)
          // console.log(e)
        })
        // 无需再手动执行search方法，autoComplete会根据传入input对应的DOM动态触发search
      })
    },
    /**
     * 如果选择的地址为地址联想中的地址，则为有效地址；否则用户自己输入的地址无效
     * @param {Object} e 点击联想地址时的事件对象。包含该地址的相关信息
     */
    handleAddressSelect (e) {
      if (e.type === 'select') {
        // 当选择的是联想地址时，有效
        this.isSelectAddress = true
        this.formAddress = e.poi.name
        // 设置地址的经纬度
        this.lng = e.poi.location.lng
        this.lat = e.poi.location.lat
      }
    },
    /**
     * 用户输入改变详细地址，可能导致地址不可用
     * 详细地址应该是高德提供的地址联想
     */
    addressChange (e) {
      if (this.isSelectAddress) {
        // 如果已经选择了联想地址的情况下，用户继续输入，则该地址无效
        this.isSelectAddress = false
      } else {
        return false
      }
    },
    /**
     * 处理保存地址  保存地址前验证数据完整性和可用性
     * 需要判断是新增还是编辑，它们的 ajax 提交地址不同，编辑时需要提交地址的 id
     */
    handleSaveAddress () {
      // 先对数据进行验证
      // 验证收货人
      if (!this.formAddressee) {
        // 没有收货人
        Toast('请输入收货人')
        return false
      }
      // 验证手机号
      if (!this.formTel) {
        // 没有手机号
        Toast('请输入手机号')
        return false
      } else if (!(/^1[3456789]\d{9}$/.test(this.formTel))) {
        Toast('请输入正确的手机号')
        return false
      }
      // 省市区
      if (!this.formProvince) {
        Toast('请选择省市区')
        return false
      }
      // 地级市
      if (!this.formCity) {
        Toast('请选择城市')
        return false
      }

      // 验证联想地址
      if (!this.formAddress) {
        Toast('请选择一个联想地址')
        return false
      } else if (!this.isSelectAddress) {
        // 选择的不是联想地址
        Toast('请选择正确的联想地址')
        return false
      }

      // 验证经纬度
      if (!this.lat || !this.lng) {
        Toast('请选择正确的联想地址')
        return false
      }

      // 验证门牌号
      if (!this.formHouse) {
        Toast('请输入门牌号')
        return false
      }
      console.log('验证通过')
      // 当验证通过后，开始处理数据提交

      if (this.isLoad) {
        // 正在向服务器发送请求，限制重复发送
        return false
      }
      this.isLoad = true
      Indicator.open('正在提交...')

      // 默认为新增，设置提交地址和地址的id
      let url = 'sjz/ajax_add_address'
      let id = ''
      // 根据新增或是编辑，设置 ajax 提交的地址，以及编辑地址的 id
      if (this.editType === 'edit') {
        url = '/sjz/ajax_set_modify_address'
        id = this.addressId
      }
      this.$ajax(
        'post',
        url,
        {
          id: id,
          open_id: this.open_id,
          token: this.token,
          address_: this.formAddress,
          house_: this.formHouse,
          tel_: this.formTel,
          name_: this.formAddressee,
          is_main: this.formMainAddress ? '1' : '0',
          province_: this.formProvince,
          city_: this.formCity,
          lng: this.lng,
          lat: this.lat
        },
        res => {
          console.log(res)
          if (res.data.id === 0) {
            Toast('提交成功')
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          } else {
            Toast('提交失败')
          }
          this.isLoad = false
          Indicator.close()
        }
      )
    }
  },
  watch: {
    // 选择商品数量
    goodsNumber: function (val) {
      if (Number(val) <= 1) {
        this.goodsNumber = 1
        this.preDisabled = true
      } else if (Number(val) >= 256) {
        this.goodsNumber = 256
        this.lasDisabled = true
      } else {
        this.preDisabled = this.lasDisabled = false
      }
      this.goodsNumber = Number(this.goodsNumber)
      this.acountPrice = (
        parseFloat(this.curPrice) * Number(this.goodsNumber)
      ).toFixed(2)
    }
  },
  computed: {
    inputLength: function () {
      return this.inputValue.length
    },
    fullDate: function () {
      let start = Date.parse(new Date(this.startDate))
      let end = Date.parse(new Date(this.endDate)) + 1000 * 60 * 60 * 24;
      if (end - start <= 0) {
        Toast('请选择正确的日期！')
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.endDate = this.startDate
        end = Date.parse(new Date(this.endDate)) + 1000 * 60 * 60 * 24
      }

      return end - start > 0 ? (end - start) / 1000 / 60 / 60 / 24 : 0;
    }
  }
}
</script>

<style scoped>
.pay_goods {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  overflow: auto;
}

/* 收货地址 */
.address-box {
  padding: .8rem .5rem;
  background-color: #fff;
  margin-bottom: .5rem;
  display: flex;
  align-items: center;
}

.address-box .delivery-address, .no-address {
  flex: 1;
}

.no-address {
  text-align: center;
  font-size: 1rem;
}

.no-address .iconfont {
  color: #666;
  font-size: 1rem;
}

.address-box .address-chose-btn {
  padding-left: .5rem;
  text-align: right;
  color: #6b6b6b;
}

.address-area {
  display: flex;
  align-items: center;
  font-size: .8rem;
}

.address-area span {
  margin-right: .4rem;
}

.main-address-tag {
  padding: 0.05rem .2rem;
  border-radius: 2px;
  font-size: .68rem;
  color: #fff;
  background-color: rgb(15, 207, 95);
}

.address-box .address-detail {
  margin-top: .2rem;
  font-size: 1rem;
  font-weight: bold;
}

.address-box .address-user {
  margin-top: .2rem;
  font-size: .8rem;
}

.pay_goods .pay_goods_wrapper {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  padding-bottom: 3.6rem;
}
.pay_goods .pay_goods_wrapper .goods_store {
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-sizing: border-box;
}
.pay_goods .pay_goods_wrapper .goods_store .goods_store_name {
  font-size: 0.9rem;
}
.pay_goods_wrapper .goods_store .goods_store_img {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  margin: 0 0.5rem;
}
.pay_goods_wrapper .goods_info {
  display: flex;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
}
.pay_goods_wrapper .goods_info .goods_info_img {
  display: inline-block;
  width: 6rem;
  height: 6rem;
  margin-right: 0.5rem;
}
.pay_goods_wrapper .goods_info .goods_info_content {
  max-width: calc(100vw - 7.5rem);
}
.pay_goods_wrapper .goods_info .goods_info_content .goods_info_title {
  display: block;
  height: 2.4rem;
  line-height: 1.2rem;
  font-size: .8rem;
  overflow: auto;
}
.pay_goods_wrapper .goods_info .goods_info_content .goods_info_chose {
  display: flex;
  align-items: center;
  height: 2.4rem;
  line-height: 1.2rem;
  overflow: auto;
  color: #959595;
  font-size: .8rem;
}
.pay_goods_wrapper .goods_info .goods_info_content .goods_info_price {
  display: inline-block;
  padding: 0 6px;
  height: 1.2rem;
  line-height: 1.2rem;
  color: rgb(250, 68, 68);
  box-sizing: border-box;
  border: 1px solid rgb(255, 163, 163);
  font-size: .8rem;
  overflow: hidden;
}
.pay_goods_wrapper .goods_num {
  display: flex;
  height: 2.4rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 0.5rem;
  box-sizing: border-box;
  margin-bottom: 0.4rem;
}
.pay_goods_wrapper .goods_num .goods_num_name {
  font-size: 0.88rem;
}
.pay_goods_wrapper .goods_num .goods_num_input {
  position: relative;
  display: inline-block;
  width: 6rem;
  line-height: 1.6rem;
  border-radius: 4px;
  height: 1.6rem;
  box-sizing: border-box;
}
.pay_goods_wrapper .goods_num .goods_num_input span.is_disabled {
  color: #aaa;
  cursor: not-allowed;
  background-color: #eee;
}
.pay_goods_wrapper .goods_num .goods_num_input span {
  position: absolute;
  top: 0;
  width: 1.8rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  font-size: 1.2rem;
  z-index: 1;
  border-radius: 2px;
  background-color: rgb(226, 226, 226);
  color: #333;
  cursor: pointer;
  /* font-weight: 600; */
}
.pay_goods_wrapper .goods_num .goods_num_input .num_input_left {
  left: 1px;
}
.pay_goods_wrapper .goods_num .goods_num_input .num_input_right {
  right: 1px;
}
.pay_goods_wrapper .goods_num .goods_num_input .num_input {
  position: relative;
  font-size: 0.8rem;
  display: inline-block;
  width: 100%;
}
.pay_goods_wrapper .goods_num .goods_num_input .num_input input {
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  display: inline-block;
  line-height: 1.6rem;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}
/* 选择时间 */
.pay_goods_wrapper .goods_date {
  position: relative;
  width: 100%;
  height: 2.4rem;
  line-height: 2.4rem;
  background-color: #fff;
  margin-bottom: 0.4rem;
}
.pay_goods_wrapper .goods_date .goods_date_name {
  font-size: 0.88rem;
  position: absolute;
  top: 0;
  left: 0.5rem;
}
.pay_goods_wrapper .goods_date .goods_date_input {
  font-size: 0.88rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 5rem;
  height: 2.4rem;
  line-height: 2.4rem;
  padding-right: 0.5rem;
  box-sizing: border-box;
  color: #333;
}
.pay_goods_wrapper .goods_date .goods_date_input input {
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 0.74rem;
  text-align: center;
  height: 1.6rem;
  margin-top: 0.4rem;
}
/* 备注 */
.pay_goods_wrapper .goods_remarks {
  position: relative;
  width: 100%;
  height: 2.4rem;
  line-height: 2.4rem;

  background-color: #fff;
  margin-bottom: 0.4rem;
}
.pay_goods_wrapper .goods_remarks .goods_remarks_name {
  font-size: 0.88rem;
  position: absolute;
  left: 0.5rem;
  top: 0;
}
.pay_goods_wrapper .goods_remarks input {
  font-size: 0.88rem;
  width: 100%;
  display: block;
  padding-left: 5rem;
  height: 2.4rem;
  line-height: 2.4rem;
  padding-right: 0.5rem;
  box-sizing: border-box;
  color: #333;
}
.pay_goods_wrapper .goods_remarks .goods_remarks_count {
  position: absolute;
  line-height: 1.1;
  bottom: 0;
  right: 0.5rem;
  font-size: 0.6rem;
  color: #999;
}
/* 选择支付方式 */

.pay_goods_wrapper .goods_payment .payment_chose {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding: 0 0.5rem;
  background-color: #fff;
  height: 2.4rem;
  border-bottom: 1px solid rgb(247, 245, 246);
}

.pay_goods_wrapper .payment_type {
  display: flex;
  align-items: center;
  height: 100%;
  color: #333;
}
.pay_goods_wrapper
  .goods_payment
  .payment_chose
  .payment_type
  .payment_type_name {
  font-size: 0.88rem;
}

.pay_goods_wrapper .goods_payment .payment_chose .payment_type img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.4rem;
}

.pay_goods_wrapper .payment_chose .iconfont {
  color: rgb(182, 77, 81);
  font-size: 1rem;
}
.pay_goods_wrapper .goods_selectTime {
  background-color: #fff;
  height: 2.4rem;
  width: 100%;
  margin-top: 0.4rem;
  font-size: 0.88rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(247, 245, 246);
}
.pay_goods_wrapper .goods_selectTime img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.4rem;
}
.pay_goods_wrapper .goods_coupon {
  background-color: #fff;
  height: 2.4rem;
  width: 100%;
  margin-bottom: 0.4rem;
  font-size: 0.88rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
/* .pay_goods_wrapper .goods_coupon .payment_type{
  display: flex;
  height: 100%;
  align-items: center;
} */
.pay_goods_wrapper .goods_coupon .payment_type img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.4rem;
}
.goods_footer {
  position: fixed;
  left: 50%;
  width: 100%;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 3.2rem;
  background-color: #fff;
  border-top: 1px solid #eee;
}
.goods_footer .goods_realpay {
  display: flex;
  align-items: center;
  margin-right: 0.4rem;
}
.goods_footer .goods_realpay .realpay_name {
  font-size: 0.8rem;
}
.goods_footer .goods_realpay .realpay_content {
  font-size: 1.2rem;
  color: rgb(182, 77, 81);
}
.goods_footer .goods_submit {
  width: 40%;
  background-color: rgb(226, 46, 38);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.2rem;
}

/* 选择收货地址 */
.select-address-box {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.select-address-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.select-address-wrapper .head {
  position: relative;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
  font-size: .8rem;
}

.select-address-wrapper .head .close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 0.5rem;
}

.select-address-wrapper .head .close .iconfont {
  font-size: 1rem;
  color: #959595;
}

.select-address-wrapper .list {
  height: 36vh;
  padding: .5rem .5rem 3.6rem;
  overflow-y: auto;
}

.select-address-wrapper .list .item {
  padding: .5rem 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  font-size: .8rem;
  opacity: .8;
}

.select-address-wrapper .list .item-active {
  opacity: 1;
}

.select-address-wrapper .list .item-active .iconradiochecked{
  color: #409eff;
}

.select-address-wrapper .address-user {
  color: #959595;
}

.select-address-wrapper .item-radio {
  padding-right: .5rem;
}

.select-address-wrapper .item-radio .iconfont{
  font-size: 1rem;
  color: #c9c9c9;
}

.select-address-wrapper .item-content {
  flex: 1;
}

.select-address-wrapper .item-edit {
  padding-left: .5rem;
}

.select-address-wrapper .item-edit .iconfont{
  color: #666;
  font-size: 1.2rem;
}

.select-address-wrapper .foot {
  position: absolute;
  width: 100%;
  bottom: .8rem;
  padding: 0 .5rem;
  box-sizing: border-box;
}

.select-address-wrapper .foot .new-address {
  height: 2.2rem;
  background-color: #f2f2f2;
  border-radius: 4px;
  line-height: 2.2rem;
  text-align: center;
  font-size: .8rem;
}

.select-address-wrapper .new-address .iconfont {
  font-size: .8rem;
  color: #6b6b6b;
}

.edit-address-box {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%);
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
}

.edit-address-wrapper {
  width: 100%;
  min-height: 100%;
  background-color: #f2f2f2;
}

.edit-address-wrapper .head {
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
  font-size: 1rem;
  background-color: #fff;
}

.edit-address-wrapper .content {
  padding: .5rem 0;
}

.edit-address-wrapper .field-title {
  padding: .5rem 1rem;
  font-size: .88rem;
  color: #6b6b6b;
}

.edit-address-wrapper .field-box {
  background-color: #fff;
}

.edit-address-wrapper .field {
  padding: .6rem 1rem;
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  font-size: .8rem;
}

.edit-address-wrapper .field .label,
.edit-address-wrapper .field .value {
  display: inline-block;
  line-height: 1.3rem;
}

.edit-address-wrapper .field .label {
  flex: none;
  width: 4.6rem;
}

.edit-address-wrapper .field .value {
  flex: 1;
}

.edit-address-wrapper .field .inp,
.edit-address-wrapper .field .btn {
  display: inline-block;
  line-height: inherit;
  width: 100%;
  padding: 0;
  color: #323232;
  font-size: .8rem;
}

.edit-address-wrapper .field  .radio {
  display: inline-block;
  padding: 0 .5rem;
  line-height: inherit;
}

.edit-address-wrapper .field  .radio .iconfont {
  color: #c9c9c9;
}

.edit-address-wrapper .field  .radio .iconradiochecked {
  color: #409eff;
}
.edit-address-wrapper .btns {
  margin: 1rem 0;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}

.edit-address-wrapper .btns .btn {
  width: 100%;
  padding: 0 15px;
  font-size: .86rem;
  box-sizing: border-box;
  height: 2.4rem;
  margin: 0;
  line-height: 2.4rem;
  text-align: center;
  border-radius: 2px;
  transition: opacity .2s;
  border: 1px solid transparent;
}

.edit-address-wrapper .btns .save {
  color: rgb(255, 255, 255);
  background: rgb(57, 61, 73);
  border-color: rgb(57, 61, 73);
}

.edit-address-wrapper .btns .cancel {
  margin-top: 1rem;
  background-color: transparent;
  color: rgb(57, 61, 73);
  border-color: rgb(57, 61, 73);
}

/* 行政区域选择器 */
.district-box {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
}

.district-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 60vh; */
  background-color: #fff;
}

.district-wrapper > .head {
  position: relative;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
}

.district-wrapper > .head > .btn {
  padding: 0 1rem;
  display: inline-block;
  font-size: 1rem;
  color: #1E9FFF;
}

</style>
