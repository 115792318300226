<template>
    <div class="app-content" style="height: 97%">
        <form action="https://www.zmlxj.com/api.php/foot/app_ali_pay" method="post"
              enctype="multipart/form-data">
            <input type="hidden" name="id" v-model="order.id"/>

            <div class="app-wrap clearfix">
                <label>订单号</label>
                <span>{{order.order_id}}</span>
            </div>
            <div class="app-wrap clearfix">
                <label>范围</label>
                <span>{{order.radius / 1000}}KM</span>
            </div>
            <div class="app-wrap clearfix">
                <label>谁可以抢</label>
                <span v-if="order.all == 1">全部</span>
                <span v-if="order.fansi == 1">粉丝</span>
                <span v-if="order.attention == 1">关注的人</span>
            </div>
            <div class="app-wrap clearfix">
                <label>总金额</label>
                <span>{{order.money}}元</span>
            </div>
            <div class="app-wrap clearfix">
                <label>个数</label>
                <span>{{order.numbers}}个</span>
            </div>
            <div class="app-wrap clearfix">
                <label>留言</label>
                <span>{{order.title}}</span>
            </div>
            <input type="hidden" name="user_id" v-model="user_id">
            <div class="btn">
                <mt-button type="primary" class="mint-header">立即支付</mt-button>
            </div>
        </form>
    </div>
</template>

<style scoped>
    .app-content {
        background-color: #fff;
        padding: 20px;
    }

    .app-wrap {
        margin-bottom: 10px;
        font-size: 14px;
        color: #666;
    }

    .app-wrap label {
        display: block;
        width: 100px;
        height: 30px;
        float: left;
        margin-right: 20px;
    }

    .app-wrap span {
        float: left;
    }
</style>

<script>
    export default{
        data(){
            return {
                order: '',
                user_id: '',
                id: this.$route.query.id
            }
        },
        mounted(){
            this.unload()
        },
        methods: {
            unload(){
                var that = this;
                if (this.hybrid.hybrid.versions.myapp) {    // FOR 安卓最美旅行家APP
                    that.user_id = JavaScriptInterface.getUserId();
                    that.getdata()
                } else if (this.hybrid.hybrid.versions.myiOSapp) {  // FOR IOS最美旅行家APP
                    this.hybrid.hybrid.registerHandler('getIOSUserId', function (data, responseCallback) {
                        that.user_id = data.user_id;
                        var responseData = '已经收到IOS传来的userid';
                        responseCallback(responseData);
                        that.getdata()
                    })
                }
            },
            getdata(){
                this.$ajax('post', 'foot/get_web_park_packet', {id: this.id}, (res) => {
                    this.order = res.data.data;
                })
            }
        }
    }
</script>