<template>
  <div class="parklist" v-title data-title="选择地质公园">
    <mt-header fixed title="选择地质公园">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="book-content" v-if="items">
        <ul class="park_info">
          <li class="recommend">
            <p class="tit">附近公园</p>
            <p v-for="(k, i) in recommend_park" :key="i" v-show="recommend_park">
              <router-link @click.native="getParkId(k.id,k.park_name)" to>{{k.park_name}}</router-link>
            </p>
            <p v-if="recommend_park == ''">定位中...</p>
          </li>
          <li v-for="(item,index) in items" :key="item.index">
            <p :id="index">{{item.index}}</p>
            <ul class="park_info_name">
              <li v-for="(park, i) in item.data" :key="i">
                <p @click="clickparkid(park.id,park.park_name)">
                  <a>{{park.park_name}}</a>
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="skip">
          <li v-for="(item,index) in items" :key="index">
            <a @click="goAnchor(index,item.index)">{{item.index}}</a>
          </li>
        </ul>
        <div class="indicator" v-show="text">{{text}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      items: [],
      text: "",
      recommend_park: [],
      type: this.$route.query.type,
      title: this.$route.query.title,
      content: this.$route.query.content,
      label_id: this.$route.query.label_id,
      open: this.$route.query.open
    };
  },
  mounted() {
    this.get_park();
  },
  methods: {
    get_park() {
      this.$nextTick(() => {
        $(".park_info").height($("#app").height() - 40);
      });
      //                var that = this
      //                $(".skip").on("click", "a", function () {
      //                    that.goAnchor($(this).parent().index(), $(this).html())
      //                })

      this.$ajax("post", "Service/get_park", { web: 1 }, res => {
        //                    localStorage.setItem("park", JSON.stringify(res.data.data.normal));
        //                    this.items = JSON.parse(localStorage.getItem("park"));
        this.items = res.data.data.normal;
      });
      this.map();
    },
    clickparkid(parkid, park_name, lng, lat) {
      //                首页点击选择公园 跳转到公园足迹列表
      if (this.type == "linkpark") {
        this.$router.push({
          path: "/footprints/FootprintsIndex",
          query: { pid: parkid, type: "parkFoot" }
        });
      }
      //                足迹发布选择公园
      else if (this.type == "footgetpark") {
        this.$router.push({
          path: "/footprints/FootprintsPublish",
          query: {
            type: 2,
            pid: parkid,
            park_name: park_name,
            title: this.title,
            content: this.content,
            label_id: this.label_id,
            open: this.open
          }
        });
      }
      //                游记发布选择公园
      else if (this.type == "notegetpark") {
        this.$router.push({
          path: "/note/NotePost",
          query: {
            type: 2,
            pid: parkid,
            pname: park_name,
            content: this.content
          }
        });
      }
    },
    goAnchor(selector, item) {
      this.text = item;
      this.$nextTick(() => {
        var element = document.getElementById(selector);
        element.scrollIntoView();
        var that = this;
        setTimeout(function() {
          that.text = "";
        }, 1000);
      });
    },
    map() {
      var that = this;
      AMap.plugin("AMap.Geolocation", () => {
        var Geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 5000 //超过5秒后停止定位，默认：无穷大
        });
        Geolocation.getCurrentPosition((status, result) => {
          if (status === "complete") {
            that.$ajax(
              "post",
              "service/get_near_park",
              {
                lng: result.position.lng,
                lat: result.position.lat
              },
              res => {
                that.recommend_park = res.data.data;
              }
            );
          } else {
            Toast({
              message: "定位失败",
              duration: 1000
            });
          }
        });
      });
    }
  }
};
</script>

<style scoped>
.page-content {
  padding-bottom: 0;
}

.go-guide {
  color: #666;
}

.book-content .skip {
  position: fixed;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.6rem;
  border-left: 1px solid #cccccc;
  background-color: #fff;
}

.book-content .skip li {
  text-align: center;
}

.book-content .skip li a {
  padding: 2px 6px;
  display: inline-block;
}

.book-content .park_info {
  overflow-y: scroll;
  margin-right: 24px;
  background-color: #fff;
  font-size: 0.8rem;
}

.book-content .park_info > li > p {
  margin: 0;
  padding: 10px;
  background-color: #fafafa;
}

.book-content a {
  color: inherit;
  text-decoration: none;
}

.book-content .park_info_name li p a {
  background-color: #fff;
  box-sizing: border-box;
  min-height: 48px;
  line-height: 48px;
  display: block;
  overflow: hidden;
  position: relative;
  padding: 0 10px;
}

.book-content .indicator {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 50px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  color: #fff;
  font-size: 22px;
}

.book-content .park_info .recommend p.tit {
  font-weight: 700;
}

.book-content .park_info .recommend p {
  background-color: #fff;
}
</style>