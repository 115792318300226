<template>
  <div class="MapNavigation" v-title data-title="地图">
    <mt-header fixed title="地图">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div id="container"></div>
      <div class="left_but">
        <div class="mak_tab">
          <img
            src="../../assets/img/map_3d.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="is_tab_map_3d"
          />
          <img
            src="../../assets/img/map_3d_yes.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="!is_tab_map_3d"
          />
        </div>
        <div class="mak_tab">
          <!--地图 + -  -->
          <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
          <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Indicator } from 'mint-ui'
import landmark from '../../assets/img/landmark.png'
export default {
  data () {
    return {
      map: {},
      Lng: 0,
      Lat: 0,
      endlng: this.$route.query.lng,
      endlat: this.$route.query.lat,
      park_name: this.$route.query.park_name,
      id: this.$route.query.id,
      is_tab_map_3d: true // 3D地图按钮是否点击
    }
  },
  mounted () {
    this.unload()
  },
  methods: {
    unload () {
      // 加载地图，调用浏览器定位服务
      this.map = this.map_init()
      if (this.hybrid.hybrid.versions.myapp) {
        // FOR 最美旅行家APP
        $('.mint-header.is-fixed').remove()
        $('.page-content').css('padding', '0')
        this.Lng = JavaScriptInterface.getLng()
        this.Lat = JavaScriptInterface.getLat()
      }
      this.map_add_Marker_1(
        this.map,
        {
          lng: this.endlng,
          lat: this.endlat,
          spots_name: this.park_name,
          id: this.id
        },
        '',
        true,
        'RedPacket'
      )
    }
  }
}
</script>
<style scoped>
.MapNavigation {
  width: 100%;
  height: 100%;
}

.MapNavigation .page-content {
  width: 100%;
  height: 100%;
  padding-bottom: 0;
  box-sizing: border-box;
}

.MapNavigation #container {
  height: 100%;
  /*height: 300px;*/
}

.MapNavigation .center {
  width: 100%;
}

.center .btmtip {
  font-size: 0.6rem;
  padding: 2px 5px;
  background-color: #ff9a80;
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  border-radius: 2px;
}
</style>
