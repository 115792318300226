<template>
  <div class="d-body" v-title :data-title="title">
    <div class="d-head" :class="[type_ == 'is_shop' ? 'shop_bg' : 'activity_bg']">
      <span>领 劵 中 心</span>
    </div>
    <div class="d_content">
      <div class="dc_title">
        <span>{{info.title}}</span>
      </div>
      <div class="dc_close_day">
        <span>{{info.close_day_text}}</span>
      </div>
      <div class="dc_content">
        <div class="dc_c_left">
          <span>简&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;介</span>
        </div>
        <div class="dc_c_right">
          <span>{{info.content}}</span>
        </div>
      </div>
      <div class="dc_content">
        <div class="dc_c_left">
          <span>说&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;明</span>
        </div>
        <div class="dc_c_right">
          <span>{{info.notes}}</span>
        </div>
      </div>
    </div>

    <div
      class="d_foot"
      :class="[type_ == 'is_shop' ? 'activity_bg' : 'shop_bg']"
      @click="receive_(info.id,user_info.user_id)"
      v-if="user_info.user_id"
    >
      <span v-if="info.is_receive == 1">已经领取</span>
      <span v-else>立即领取</span>
    </div>
    <div class="d_foot_no" v-else>
      <div class="d_f_input">
        <input v-model="tel" name="tel" type="number" placeholder="请输入手机号领取" />
      </div>
      <div class="d_f_submit" @click="receive_(info.id,0)">
        <span :class="[type_ == 'is_shop' ? 'activity_bg' : 'shop_bg']">立即领取</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";

export default {
  data() {
    return {
      id: this.$route.query.id,
      type_: this.$route.query.type_,
      park_id: this.$route.query.park_id,
      title: "美丽乡村",
      info: "",
      user_info: this.check_login(),
      is_weixin: this.hybrid.hybrid.versions.is_weixin, //判断是不是微信
      tel: ""
    };
  },
  mounted() {
    this.get_coupon_detail();
  },
  methods: {
    get_coupon_detail() {
      if (this.id && this.type_) {
        var user_id = this.check_login().user_id
          ? this.check_login().user_id
          : "";
        var data_ = {
          id: this.id,
          type_: this.type_,
          token: "zmlxj_2019",
          user_id: user_id,
          park_id: this.park_id
        };
        this.$ajax("post", "Other/get_coupon_detail", data_, ret => {
          if (ret.data.id == 0) {
            this.info = ret.data.data.data;
          } else {
            Toast("无数据");
          }
        });
      } else {
        //数据有误
        Toast("数据有误");
      }
    },
    //跳转到小程序
    open_mini() {
      if (this.is_weixin) {
        this.wx_open_mini("/pages/activity/detail?aid=192", 298);
      }
    },
    //点击领取
    receive_(id, user_id) {
      if (id) {
        if (this.info.is_receive) {
          //已经领取过了
          Toast("已经领取过了");
        } else {
          //还没领取
          if (user_id) {
            var data_ = {
              id: id,
              type_: this.type_,
              token: "zmlxj_2019",
              user_id: user_id,
              park_id: this.park_id,
              etime: this.info.close_day,
              title_: this.info.title,
              tel: ""
            };
            this.$ajax("post", "Other/receive_coupon", data_, ret => {
              if (ret.data.id == 0) {
                //修改修改is_receive = 1
                this.info.is_receive = 1;
                Toast("领取成功");
              } else {
                Toast("无数据");
              }
            });
          } else if (this.tel) {
            var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;

            if (this.tel == "") {
              Toast("请输入手机号码");
            } else if (!reg.test(this.tel)) {
              Toast("手机格式不正确");
            } else {
              //正确的手机号
              var data_ = {
                id: id,
                type_: this.type_,
                token: "zmlxj_2019",
                user_id: "",
                park_id: this.park_id,
                etime: this.info.close_day,
                title_: this.info.title,
                tel: this.tel
              };
              this.$ajax("post", "Other/receive_coupon", data_, ret => {
                if (ret.data.id == 0) {
                  Toast("领取成功");
                  //修改修改is_receive = 1
                  this.info.is_receive = 1;
                  if (ret.data.data.user) {
                    that.save_arr("user_info", ret.data.data.user);
                  }
                } else {
                  Toast("无数据");
                }
              });
            }
          } else {
            Toast("请输入手机号");
          }
        }
      } else {
        Toast("不能领取");
      }
    }
  }
};
</script>

<style scoped>
.d-body {
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: white;
  height: 100%;
}
.d-body .d-head {
  display: inline-block;
  position: relative;
  width: 100%;
}
.d-body .d-head span {
  display: block;
  padding: 50px;
  font-size: 1.5rem;
  text-align: center;
  color: white;
}
.d-body .d_content {
  display: inline-block;
  position: relative;
  width: 94%;
  margin-top: 10%;
  margin-left: 3%;
}
.d-body .d_content .dc_title {
  display: inline-block;
  position: relative;
  width: 100%;
}
.d-body .d_content .dc_title span {
  display: block;
  padding: 10px 0px 10px 0px;
  font-size: 1.2rem;
}
.d-body .d_content .dc_close_day {
  display: inline-block;
  position: relative;
  width: 100%;
}
.d-body .d_content .dc_close_day span {
  display: block;
  padding: 5px 0px 5px 0px;
  font-size: 0.8rem;
}
.d-body .d_content .dc_content {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 5%;
}
.d-body .d_content .dc_content .dc_c_left {
  position: relative;
  width: 15%;
  float: left;
}
.d-body .d_content .dc_content .dc_c_left span {
  display: block;
  padding: 5px 0px 5px 0px;
  font-size: 0.8rem;
  color: #c2c2c2;
}
.d-body .d_content .dc_content .dc_c_right {
  display: inline-block;
  position: relative;
  width: 85%;
}
.d-body .d_content .dc_content .dc_c_right span {
  display: block;
  font-size: 0.8rem;
  padding: 5px 0px 0px 20px;
}
.d-body .d_foot {
  position: fixed;
  width: 100%;
  bottom: 0px;
}
.d-body .d_foot span {
  display: block;
  padding: 10px 0px 10px 0px;
  color: white;

  text-align: center;
  font-size: 1rem;
}
.d-body .d_foot_no {
  position: fixed;
  width: 100%;
  bottom: 0px;
  background-color: #f5f5f5;
}
.d-body .d_foot_no .d_f_input {
  position: relative;
  width: 70%;
  float: left;
  margin-left: 5%;
}
.d-body .d_foot_no .d_f_input input {
  height: 45px;
  width: 100%;
  border: 1px solid white;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  border-radius: 45px;
}
.d-body .d_foot_no .d_f_submit {
  position: relative;
  width: 20%;
  float: left;
  margin-top: 2%;
  margin-left: 2%;
}
.d-body .d_foot_no .d_f_submit span {
  display: block;
  padding: 5px;
  font-size: 0.8rem;
  color: white;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
</style>