<template>
  <div class="feed-list_box" v-title :data-title="wx_title">
    <!--微信分享-->
    <div class="hidden">
      <img :src="wx_img" />
    </div>
    <mt-header fixed title="游记详情">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
      <!-- <mt-button icon="more" slot="right"></mt-button> -->
    </mt-header>

    <div class="page-content">
      <div class="feed-list note" v-if="item.id">
        <div class="feed-item">
          <!-- 下拉菜单 -->
          <div
            class="feed-item-downbtn"
            :collect="item.collect"
            :attention="item.attention"
            :report="item.report"
            @click="setting($event,item.id,item.user_id)"
          ></div>
          <!-- 游记内容 -->
          <div class="feed-item-body">
            <!-- 用户信息 -->
            <div class="feed-item-top">
              <router-link
                :to="{name:'otherIndex', params: { uid: item.user_id }}"
                v-if="item.user_id != userId"
              >
                <div class="avatar fl">
                  <img :src="item.user_icon" class="img" alt />
                </div>
                <h1>
                  <span class="name">{{item.user_nick}}</span>
                </h1>
                <h2>
                  <span class="time">{{item.travel_notes_time}}</span>
                  <!--<span class="location"-->
                  <!--v-if="item.travel_notes_address !='' && item.travel_notes_address !=1 ">{{item.travel_notes_address}}</span>-->
                </h2>
              </router-link>
              <router-link to tag="div" v-else>
                <div class="avatar fl">
                  <img :src="item.user_icon" class="img" alt />
                </div>
                <h1>
                  <span class="name">{{item.user_nick}}</span>
                </h1>
                <h2>
                  <span class="time">{{item.travel_notes_time}}</span>
                  <!--<span class="location"-->
                  <!--v-if="item.travel_notes_address !='' && item.travel_notes_address !=1 ">{{item.travel_notes_address}}</span>-->
                </h2>
              </router-link>
            </div>
            <div class="text">{{item.travel_notes_title}}</div>
            <div class="audio" v-if="item.speech_path" @click="audio_paly">
              <audio>
                <source :src="item.speech_path" type="audio/mpeg" />
              </audio>
              <i class="icon-play"></i>
              <span class="fr text-5">{{item.speech_time}}</span>
            </div>
            <div class="img-box" :class="getRowType(item.img.length)" v-if="item.img">
              <div
                class="item"
                :pic="itemimg.travel_notes_img_1"
                v-for="(itemimg, imgIndex) in item.img"
                :key="imgIndex"
              >
                <img
                  v-if="imgIndex < 9"
                  :src="itemimg.android_newUrl"
                  @click="onClickMe(item.img,imgIndex)"
                />
                <!--<p v-imgBg="itemimg.travel_notes_img_2" @click="onClickMe(item.img,imgIndex)"></p>-->
              </div>
              <div class="video item" :class="{active:click_video}" v-if="item.pai_path">
                <div class="box">
                  <video
                    :src="item.pai_path"
                    :poster="item.pai_img"
                    preload="none"
                    :controls="click_video"
                  ></video>
                  <b @click="click_video=true"></b>
                  <i @click="click_video_remove">x</i>
                </div>
              </div>
            </div>
            <!-- 轨迹 -->
            <div class="note-track-card" v-if="item.record_id>0">
              <router-link :to="{ name: 'TrackDetail', params: { id: item.id }}">
                <div class="map">
                  <div class="map-pic" v-imgBg="item.record_img"></div>
                </div>
                <div class="info">
                  <div class="tit">{{item.record_title}}</div>
                  <div class="cat">
                    <span :class="'cat' + item.label_id[0].label_id">{{item.label_id[0].label_name}}</span>
                  </div>
                  <span class="dis">{{item.distance}}km</span>
                  <span class="time">{{item.record_time | formatDate}}</span>
                </div>
              </router-link>
            </div>
            <div class="feed-item-tag">
              <a href="javascript:;">{{item.park_name}}</a>
            </div>
          </div>
          <!-- 工具栏 -->
          <div class="feed-item-tool">
            <a class="zan" @click="postLike(item.id)" v-if="item.like==0">
              <span>{{item.like_numbers}}</span>
            </a>
            <a class="zan yes" @click="postLike(item.id)" v-else>
              <span>{{item.like_numbers}}</span>
            </a>
            <a class="ping" @click="discuss">
              <span>{{item.discuss_numbers}}</span>
            </a>
            <a class="zhuan" href="javascript:;">
              <span>{{item.transpond_numbers}}</span>
            </a>
          </div>
        </div>

        <!-- 观看人数 -->
        <div class="viewer-list">
          <h4>
            浏览人数
            <span>{{item.browse_numbers}}</span>
          </h4>
          <div class="box">
            <div class="avatar" v-for="(icon, i) in item.browse_user" :key="i">
              <router-link
                :to="{name:'otherIndex', params: { uid: icon.user_id }}"
                v-if="icon.user_id != userId"
              >
                <div class="img" v-imgBg="icon.user_icon" v-if="icon.user_icon"></div>
                <div class="img" v-else></div>
              </router-link>
              <a href="javascript:;" v-else>
                <div class="img" v-imgBg="icon.user_icon" v-if="icon.user_icon"></div>
                <div class="img" v-else></div>
              </a>
            </div>
          </div>
        </div>

        <!-- 评论列表 -->
        <div class="travelcomment">
          <h4>
            最新评论
            <span>{{item.discuss_numbers}}</span>
          </h4>
          <div class="travelcomment-list park-list" v-if="item.discuss.length > 0">
            <div class="travelcomment-item" v-for="(reply, i) in item.discuss" :key="i">
              <a class="go-reply" @click="add_discuss_content(reply.user_nick,reply.id)">回复</a>
              <div class="user-head">
                <router-link
                  tag="div"
                  :to="{name:'otherIndex', params: { uid: reply.user_id }}"
                  v-if="reply.user_id != userId"
                  class="avatar"
                >
                  <div class="img" v-imgBg="reply.user_icon" v-if="reply.user_icon"></div>
                  <div class="img" v-else></div>
                </router-link>
                <router-link tag="div" to v-else class="avatar">
                  <div class="img" v-imgBg="reply.user_icon" v-if="reply.user_icon"></div>
                  <div class="img" v-else></div>
                </router-link>
              </div>
              <div class="travel-info">
                <h3>
                  <a class="user-nick" href="javascript:;">{{reply.user_nick}}</a>
                </h3>
                <span class="time text-9">{{reply.discuss_time}}</span>
                <p class="discuss_content">{{reply.discuss_content}}</p>
              </div>
              <div class="img-box" :class="getRowType(reply.img.length)" v-if="reply.img">
                <div v-for="(replyitem,i) in reply.img" class="item" :key="i">
                  <p
                    v-imgBg="replyitem.thumb_img_1"
                    v-if="replyitem.thumb_img_1"
                    @click="onClickMe(reply.img,i)"
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="foot_noinfo">
            <img src="/static/img/foot_noinfo@2x.14391c2.png" alt />
            <p>暂无评论</p>
          </div>
        </div>

        <div class="discuss">
          <input type="text" placeholder="我来评论一下" @focus="discuss" />
          <router-link to="/my/UserReg" v-if="!userId">注册</router-link>
          <div @click="discuss" v-if="userId">评论</div>
        </div>
        <div class="shade" v-if="is_discuss" @click="is_discuss=false"></div>
        <div class="text_discuss TrackPublish" v-if="is_discuss">
          <form id="form" action method="post" enctype="multipart/form-data">
            <div class="bd_text_discuss">
              <mt-field
                :attr="{ name: 'discuss_content' }"
                v-model="content"
                placeholder="添加评论"
                rows="4"
                type="textarea"
              ></mt-field>
              <div class="imgs clearfix" v-show="is_add_img"></div>
            </div>
            <div class="click_button clearfix">
              <div class="fl">
                <div class="footprint-image">
                  <img src="../../assets/img/footprint_photo.png" alt @click="AddImage($event)" />
                  <input type="file" multiple accept="image/*" />
                </div>
                <div class="emoji">
                  <img src="../../assets/img/footprint_emoji.png" alt />
                </div>
              </div>
              <div class="fr" @click="add_discuss">评论</div>
            </div>
            <input type="hidden" name="user_id" v-model="userId" />
            <input type="hidden" name="travel_notes_id" :value="item.id" />
            <input type="hidden" name="discuss_id" v-model="discuss_id" />
            <input type="hidden" name="address" />
          </form>
        </div>
        <!--投诉类别-->
        <div class="radiolist_box" v-show="is_report" @click.prevent="is_report = false">
          <ul class="radiolist" @click.stop="is_report = true">
            <h2>投诉类别</h2>
            <li v-for="(k,i) in radiolist" @click.prevent="radiolist_num = (i+1)" :key="i">
              <input
                class
                type="checkbox"
                :data-num="(i+1)"
                :class="{checkbox:radiolist_num == (i+1)}"
              />
              <label>{{k.name}}</label>
            </li>
            <div class="btn btn_exit">
              <mt-button type="primary" class="mint-header" @click="click_report()">确认举报</mt-button>
            </div>
          </ul>
        </div>
        <mt-actionsheet :actions="setting_info" v-model="sheetVisible"></mt-actionsheet>
      </div>
    </div>
    <!--图片放大-->
    <div class="preview-content" v-if="is_preview" @click="is_preview=false">
      <mt-swipe :auto="0" :showIndicators="true" :defaultIndex="index">
        <mt-swipe-item v-for="(item,i) in message" :key="i">
          <a href="javascript:;">
            <img :src="item.travel_notes_img_1" alt v-if="item.travel_notes_img_1" />
            <img :src="item.resource_img" alt v-else />
          </a>
        </mt-swipe-item>
      </mt-swipe>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
import { MessageBox } from "mint-ui";
import { Indicator } from "mint-ui";
export default {
  data() {
    return {
      userId: "",
      noteId: "",
      trackId: "",
      item: {},
      is_preview: false,
      message: [],
      index: 0,
      setting_info: [
        //下拉内容
        { name: "收藏", method: this.collect },
        { name: "关注", method: this.attention },
        { name: "取消", method: this.report }
      ],
      sheetVisible: false, //是否显示下拉
      travel_notes_id: "", //游记id
      setting_status: {
        collect: "", //收藏状态
        attention: "", //关注状态
        report: "" //举报状态
      },
      is_report: false, //是否显示举报框
      radiolist: [
        { name: "垃圾信息" },
        { name: "不实信息" },
        { name: "淫秽色情" },
        { name: "违法信息" }
      ], //举报类别
      radiolist_num: 0, //举报类别id
      is_discuss: false,
      discuss_img_arr: [],
      content: "",
      is_add_img: false,
      discuss_id: 0,
      click_video: false,
      wx_title: "", //微信分享标题
      wx_content: "", //desc
      wx_img: "", //图片
      wx_url:
        "http://www.zmlxj.com/api.php/weixin/wx_share?type=NoteDetail&notes_id=" +
        this.$route.params.id, //url
      is_weixin: this.hybrid.hybrid.versions.is_weixin
    };
  },
  methods: {
    //检测登录
    getLoginInfo: function() {
      var user_info = this.check_login();
      if (user_info) {
        this.userId = user_info.user_id;
      }
    },
    //            点击下拉设置
    setting(e, travel_notes_id, user_be_id) {
      this.sheetVisible = !this.sheetVisible;
      this.travel_notes_id = travel_notes_id;
      this.user_be_id = user_be_id;
      this.$set(
        this.setting_status,
        "collect",
        e.target.getAttribute("collect")
      );
      this.$set(
        this.setting_status,
        "attention",
        e.target.getAttribute("attention")
      );
      this.$set(this.setting_status, "report", e.target.getAttribute("report"));
      if (this.setting_status.collect == 0) {
        this.$set(this.setting_info[0], "name", "收藏");
      } else {
        this.$set(this.setting_info[0], "name", "取消收藏");
      }
      if (this.setting_status.attention == 0) {
        this.$set(this.setting_info[1], "name", "关注");
      } else {
        this.$set(this.setting_info[1], "name", "取消关注");
      }
      if (this.setting_status.report == 0) {
        this.$set(this.setting_info[2], "name", "举报");
      } else {
        this.$set(this.setting_info[2], "name", "已举报");
      }
    },
    //            收藏
    collect() {
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "请先登陆",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
            return;
          }
        });
      } else {
        this.$ajax(
          "post",
          "TravelNotes/collectTravelNotes",
          {
            user_id: this.userId,
            travel_notes_id: this.travel_notes_id
          },
          res => {
            if (res.data.id == 0) {
              if (this.setting_status.collect == 0) {
                Toast("收藏成功");
              } else {
                Toast("取消收藏成功");
              }
              this.$set(
                this.setting_status,
                "collect",
                res.data.data.is_cancel
              );
              $(".feed-item-downbtn")[0].setAttribute(
                "collect",
                res.data.data.is_cancel
              );
            }
          }
        );
      }
    },
    //            关注
    attention() {
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "请先登陆",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
            return;
          }
        });
      } else {
        this.$ajax(
          "post",
          "TravelNotes/attentionUser",
          {
            user_main_id: this.userId,
            user_be_id: this.user_be_id
          },
          res => {
            if (res.data.id == 0) {
              if (this.setting_status.attention == 0) {
                Toast("关注成功");
              } else {
                Toast("取消关注成功");
              }
              this.$set(
                this.setting_status,
                "attention",
                res.data.data.attention
              );
              $(".feed-item-downbtn")[0].setAttribute(
                "attention",
                res.data.data.attention
              );
            }
          }
        );
      }
    },
    //            举报
    report() {
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "请先登陆",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
            return;
          }
        });
      } else {
        if (this.setting_status.report == 0) {
          this.is_report = !this.is_report;
        } else {
          Toast("已经举报过了");
        }
      }
    },
    //            举报
    click_report() {
      if (this.radiolist_num == 0) {
        Toast("请选择举报类别");
        return;
      }
      this.$ajax(
        "post",
        "index/clickReport",
        {
          user_id: this.userId,
          travel_notes_id: this.travel_notes_id,
          report_id: this.radiolist_num
        },
        res => {
          if (res.data.id == 0) {
            if (this.data.data.report == 1) {
              Toast("举报成功");
              this.$set(this.setting_status, "attention", res.data.data.report);
              $(".feed-item-downbtn")[0].setAttribute(
                "attention",
                res.data.data.report
              );
            } else {
              Toast("操作失败，请稍后再试！");
            }
            this.is_report = false;
            this.radiolist_num = 0;
          }
        }
      );
    },
    //            点击图片放大
    onClickMe(img, index) {
      this.is_preview = true;
      this.message = img;
      this.index = index;
      return false;
    },
    //            获取数据
    getNoteInfo: function() {
      this.$ajax(
        "post",
        "TravelNotes/travelNotesInfo_new",
        {
          travel_notes_id: this.noteId,
          user_id: this.userId
        },
        res => {
          this.item = res.data.data;
          this.wx_title = res.data.data.travel_notes_title;
          this.wx_content = res.data.data.travel_notes_title;
          this.wx_img = res.data.data.img[0].android_newUrl;
          localStorage.setItem("wx_title", res.data.data.travel_notes_title);
          localStorage.setItem("wx_content", res.data.data.travel_notes_title);
          localStorage.setItem("wx_img", res.data.data.img[0].android_newUrl);
        }
      );
    },
    audio_paly(e) {
      var audio = $(".audio audio")[0];
      var video = $(".video video")[0];
      if (audio) {
        audio.pause();
      }
      if (video) {
        video.pause();
      }
      e.target.childNodes[0].play();
    },
    //点赞景点
    postLike(nid) {
      if (this.userId != "") {
        this.$ajax(
          "post",
          "TravelNotes/clickLike",
          { user_id: this.userId, travel_notes_id: nid },
          res => {
            var num = parseInt(this.item.like_numbers);
            var like = parseInt(this.item.like);

            if (like == 1) {
              this.item.like = 0;
              num--;
            } else if (like == 0) {
              this.item.like = 1;
              num++;
            }
            this.item.like_numbers = num;
          }
        );
      } else {
        Toast("请登录后操作！");
      }
    },
    //            设置图片宽度
    getRowType(value) {
      var video = $(".video video")[0];
      if (video) {
        value += 1;
      }
      var rowType = "";
      if (value == 1) {
        rowType = "row1";
      } else if (value == 2 || value == 4) {
        rowType = "row2";
      } else if (value >= 5 || value == 3) {
        rowType = "row3";
      }
      return rowType;
    },
    //            点击评论
    discuss() {
      this.content = "";
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "确认登陆吗？",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            this.get_User_Id();
          }
        });
      } else {
        this.is_discuss = true;
        this.$nextTick(() => {
          $(".mint-field-core")[0].focus();
        });
      }
    },
    //            @其他人评论
    add_discuss_content(content, pid) {
      this.discuss();
      this.content = "@" + content + ":";
      this.discuss_id = pid;
    },
    //            选择图片
    AddImage($event) {
      var file = $($event.target).siblings()[0];
      file.click();
      var that = this;
      file.onchange = function() {
        that.is_add_img = true;
        var files = Array.prototype.slice.call(this.files);
        files.forEach(function(file, i) {
          var reader = new FileReader();
          reader.onload = function() {
            that.discuss_img_arr.push(that.upload(this.result, file.type));
          };
          reader.readAsDataURL(file);
          var objUrl = that.getObjectURL(file);
          var html =
            '<div class="item up-img">' +
            '<img src="' +
            objUrl +
            '"><i class="remove"></i>' +
            "</div>";
          $(".bd_text_discuss .imgs").append(html);
          if ($(".footprint-image input").length > 9) {
            Toast("最多可上传9张图片");
          }
        });
        $(".remove").on("click", that.remove_img);
        if ($(".up-img").length >= 9) {
          Toast("最多可上传9张图片");
        }
      };
    },
    //            删除评价图片
    remove_img(e) {
      this.discuss_img_arr.splice(
        $(e.target)
          .parent()
          .index(),
        1
      );
      $(e.target)
        .parent()
        .remove();
    },
    //            提交评论
    add_discuss() {
      if (!this.content) {
        Toast("请输入评论内容");
        return;
      }
      if (this.discuss_img_arr.length > 9) {
        Toast("最多可上传9张图片");
        return;
      }
      Indicator.open("发布中...");
      var that = this;
      var form = document.getElementById("form");
      var formdata = new FormData(form);
      for (var i = 0; i < this.discuss_img_arr.length; i++) {
        formdata.append("img" + (i + 1), this.discuss_img_arr[i]);
      }
      this.formMethod("TravelNotes/discussTravelNotes_new", formdata, function(
        data
      ) {
        Indicator.close();
        that.is_discuss = false;
        if (data.id == 0) {
          that.$ajax(
            "post",
            "TravelNotes/travelNotesInfo_new",
            {
              travel_notes_id: that.noteId,
              user_id: that.userId
            },
            res => {
              if (res.data.id == 0) {
                that.discuss_img_arr = [];
                that.item = Object.assign({}, that.item, res.data.data);
                that.$set(
                  that.item,
                  "discuss",
                  res.data.data.discuss.reverse()
                );
                that.content = "";
                that.file_num = 0;
                var feedbox = document.querySelector(".feed-list_box");
                document.body.scrollTop = feedbox.scrollHeight;
              }
            }
          );
        } else {
          Toast(data.msg);
        }
      });
    }
  },
  mounted() {
    this.noteId = this.$route.params.id;
    this.getLoginInfo();
    this.getNoteInfo();
    if (is_weixin) {
      this.wx_share(this.wx_url);
    }
  }
};
</script>

<style scoped>
.feed-item-tag {
  padding-bottom: 5px;
}

.note-track-card {
  height: 100px;
}

.note-track-card .map {
  width: 33.3333%;
  border-width: 0 3px 0 0;
  border-style: solid;
  border-color: #fff;
  box-sizing: border-box;
}

.note-track-card .map-pic {
  height: 100px;
}

.travelcomment-item .img-box {
  width: auto;
  padding: 0 10px;
}

.go-reply {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.4rem;
  color: #999;
  line-height: 1rem;
  font-size: 0.7rem;
}

/*关注人列表*/
.viewer-list {
  overflow: hidden;
  height: 4rem;
}

.viewer-list h4 {
  font-size: 0.6rem;
  line-height: 1.6rem;
  padding-left: 0.5rem;
  color: #ff9a80;
}

.viewer-list h4 span {
  color: #666;
  margin-left: 5px;
}

.viewer-list .box {
  padding: 0 10px 7px;
  height: 3rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.viewer-list .avatar {
  margin: 0 5px 5px 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  border: 1px solid #ff9a80;
  box-sizing: border-box;
  border-radius: 50%;
}

/*游记详情*/
.travelcomment {
  overflow: hidden;
  clear: both;
  margin-bottom: 10rem;
}

.travelcomment h4 {
  border-bottom: 1px solid #f2f2f2;
  border-top: 0.5rem solid #f2f2f2;
  font-size: 0.6rem;
  line-height: 2rem;
  padding-left: 0.4rem;
  color: #ff9a80;
}

.travelcomment h4 span {
  padding-left: 0.5rem;
  color: #808080;
}

.travelcomment-list .travelcomment-item {
  position: relative;
  clear: both;
  padding: 10px 0;
  border-bottom: 1px solid #fafafa;
  overflow: hidden;
}

.travelcomment-list .travelcomment-item:nth-last-child(1) {
  border-bottom: 0;
}

.travelcomment-list .user-head {
  float: left;
  margin-left: 10px;
}

.travelcomment-list .user-head a {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}

.travelcomment-list .user-head a img {
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.travelcomment-list .user-head a.vol img {
  width: 70%;
  height: 70%;
  margin-left: 15%;
  margin-top: 15%;
}

.travelcomment-list .user-head a.vol i {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*background-image: url(../image/avatar_vol_bg.png);*/
  background-size: contain;
  background-position: bottom left;
}

.travelcomment-list .travel-info {
  padding-left: 3rem;
  font-size: 0.7rem;
}

.travelcomment-list .travel-info h3 {
  line-height: 0.7rem;
}

.travelcomment-list .travel-info h3 .user-nick {
  color: #ff9a80;
  font-size: 0.6rem;
  line-height: 1rem;
}

.travelcomment-list .travel-info h3 p {
  float: right;
  line-height: 0.6rem;
}

.travelcomment-list .travel-info h3 p span {
  color: #ff9a80;
  vertical-align: middle;
  padding-left: 0.5rem;
}

.travelcomment-list .travel-info .time {
  color: #999;
  line-height: 150%;
  font-size: 0.5rem;
}

.travelcomment-list .travel-info .discuss_content {
  color: #666;
  line-height: 150%;
}

/*游记 - 轨迹卡片*/
.note-track-card {
  background: #f2f2f2;
  border-radius: 3px;
  /*height: 80px;*/
}

.note-track-card .map-pic {
  width: 90px;
  height: 80px;
  background-position: center;
  background-size: cover;
  /*float: left;*/
  margin-right: 10px;
  overflow: hidden;
}

.note-track-card .info {
  padding: 8px 0;
  height: 84px;
}

.note-track-card .info .tit {
  font-size: 12px;
  color: #666;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.note-track-card .info .cat {
  font-size: 14px;
  color: #666;
  margin: 8px 0;
}

.note-track-card .info .cat span {
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  padding-left: 25px;
}

.note-track-card .info .dis {
  font-size: 0.5rem;
  color: #999;
  background: url(../../assets/img/track_info_dis.png) no-repeat left center;
  background-size: contain;
  padding-left: 21px;
  filter: grayscale(100%);
}

.note-track-card .info .time {
  margin-left: 10px;
  font-size: 12px;
  color: #999;
  background: url(../../assets/img/track_info_time.png) no-repeat left center;
  background-size: contain;
  padding-left: 20px;
  filter: grayscale(100%);
}

.feed-list .TrackPublish .imgs .item {
  width: 2.5rem;
  height: 2.5rem;
}
</style>