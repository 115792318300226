<template>
    <div class="start-container">
        <img class="start-bg" :src="imgUrl" alt="">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ["imgUrl"],
    data() {
        return {
           
        }
    },
}
</script>

<style scoped>
.start-container{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    /* background-color: #e33; */
    z-index: 3;
}
.start-bg{
    width: 100%;
    height: 100%;
}


</style>