<template>
  <div class="issue_container">
    <div class="issue_content" :class="show_issue ? 'issue_content_btn' : ''" :style="'bottom:' + bottom ">
      <span class="issue_icon" @click="show_issue = !show_issue"></span>
      <span class="issue_btn" @click="jumpToIssue">发布</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id_type_: {
      type: String,
      required: true
    },
    id: {
      type: [String, Number],
      required: true
    },
    bottom: {
      type: String
    }
  },
  data () {
    return {
      show_issue: false
    }
  },
  methods: {
    // 去发布
    jumpToIssue () {
      this.$router.push({
        path: '/publish/Mypublish',
        query: {
          id_type_: this.id_type_, // 路书
          id_: this.id
        }
      })
    }
  }
}
</script>

<style scoped>
/* 发布 */
.issue_content{
  position: fixed;
  bottom: 6.4rem;
  right: 0.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  border-radius: 2em;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
}
.issue_content .issue_icon{
  display: block;
  height: 1.6rem;
  width: 1.6rem;
  background: url(../assets/switch/books_issue.png) no-repeat;
  background-size: 80%;
  background-position: center center;
  float: left;
  transition: 0.3s;
  cursor: pointer;
}
.issue_content_btn .issue_icon{
  transform: rotate(-135deg);
  transition: 0.3s;
}
.issue_content .issue_btn{
  color: rgb(168, 243, 248);
  display: block;
  height: 1.6rem;
  width: 0;
  line-height: 1.6rem;
  font-size: 0.76rem;
  float: left;
  transition: 0.3s;
  cursor: pointer;
}
.issue_content_btn .issue_btn{
  width: 2.4rem;
  transition: 0.3s;
}
</style>
