<template>
  <div class="body body_height" v-if="is_weixin">
    <div class="head">
      <div class="banner">
        <img :src="info.banner_img_app" />
      </div>
    </div>
    <div class="rule-list">
      <div class="title" v-if="0">
        <span>活动时间：{{info.activity_begin_time}}-{{info.activity_end_time}}</span>
      </div>
      <div class="content" v-html="info.rich_content"></div>
    </div>
    <!--投票按钮-->
    <div class="vote">
      <div class="vote-up" v-if="is_show">
        <div class="method_">
          <div class="all_" @click="select_all">
            <span>全选</span>
          </div>
          <div class="vote_fun" v-if="is_show == 0">
            <span>投票未开始</span>
          </div>
          <div class="vote_fun" @click="video_vote" v-else-if="is_show == 1">
            <span>投票</span>
          </div>
          <div class="vote_fun" v-else-if="is_show == 3">
            <span>投票已结束</span>
          </div>
        </div>
      </div>
      <div class="vote-bottom">
        <div class="vote-index">
          <router-link class="link_" :to="{path:'/parkPhoto/VideoVote',query:{aid:aid}}">
            <div class="index-img">
              <img src="../../assets/img/index-img.png" />
            </div>
            <div class="index-text">
              <span>首页</span>
            </div>
          </router-link>
        </div>
        <div class="vote-index">
          <router-link class="link_" :to="{path:'/parkPhoto/VideoRank',query:{aid:aid}}">
            <div class="index-img">
              <img src="../../assets/img/rank_.png" />
            </div>
            <div class="index-text">
              <span>排行榜</span>
            </div>
          </router-link>
        </div>
        <div class="vote-index">
          <router-link class="link_" :to="{path:'/parkPhoto/VideoRule',query:{aid:aid}}">
            <div class="index-img">
              <img src="../../assets/img/rule.png" />
            </div>
            <div class="index-text">
              <span>规则说明</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.body_height {
  height: 100%;
}
.body .rule-list {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 2%;
  color: #db3e3a;
}
.body .rule-list .title {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 2%;
}
.body .rule-list .title span {
  display: block;
  padding: 4px;
  font-size: 1rem;
  font-weight: bold;
}
</style>
<script>
import { Toast } from "mint-ui";
import { Indicator } from "mint-ui";
export default {
  components: {},
  data() {
    return {
      aid: this.$route.query.aid,
      info: "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      is_show: false,
      m_url:
        "https://m.zmlxj.com/#/parkPhoto/VoteRule?aid=" +
        this.$route.query.aid +
        "&type=VideoRule"
    };
  },
  mounted() {
    this.unload();
    if (this.is_weixin) {
      this.wx_share(this.wx_url);
    }
    //this.weixin_hidden();
  },
  methods: {
    unload() {
      this.get_server_data();
    },
    weixin_hidden() {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function onBridgeReady() {
          // 通过下面这个API隐藏右上角按钮
          WeixinJSBridge.call("hideOptionMenu");
        }
      );
    },
    get_server_data() {
      this.$ajax(
        "post",
        "SpotsRace/get_video_rule",
        {
          aid: this.aid,
          token: "SpotsRace-get_video_rule"
        },
        ret => {
          if (ret.data.id == 0) {
            this.info = ret.data.data;
          } else {
            Toast("无数据");
          }
        }
      );
    }
  }
};
</script>