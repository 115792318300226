<template>
  <div class="container" v-show="loaded" v-title data-title="泥河湾科普答题">
    <img src="../../assets/nihewan/question/question-bg.png" class="bg" />
    <i class="back iconfont iconmenu2" @click="goBack"></i>
    <header class="header">
      <img :src="activity.bg_" class="banner" />
    </header>

    <div class="win-box" v-if="lotteryJoinList && lotteryJoinList.length">
      <ul class="win-list" ref="winList">
        <!-- 测试参与人数 -->
        <li class="item" v-for="(item, i) in lotteryJoinList" :key="i">
          <img :src="item.user_icon" alt class="icon" />
          <span class="nick">{{item.user_nick}}</span>
          <span>抽中</span>
          <span class="name">{{item.title}}</span>
          <span class="price" v-if="parseFloat(item.price) > 0">{{item.price}} 元</span>
        </li>
      </ul>
    </div>

    <div class="user-card">
      <div class="card-bg">
        <img src="../../assets/question/question_icon_new.png" alt />
      </div>
      <div class="user-info">
        <img class="icon" :src="user.user_icon" alt v-if="user.user_icon" />
        <img class="icon" src="../../assets/img/apply_avatar_bg.png" alt v-else />

        <p class="nick">{{user.user_nick || '*'}}</p>
        <div class="stats">
          <p class="stats-integral">
            <span class="value">{{user.score || '-'}}</span>
          </p>
          <p class="stats-rank">
            <span class="value">{{user.rank || '-'}}</span>
          </p>
        </div>
      </div>
    </div>

    <!-- 分类 -->
    <!-- <div class="content">
      <div class="left item-box">
        <div class="item item-rank" @click="handleShowRank">
          <div class="value">
            <i class="iconfont iconcanyu"></i>
            <span class="text">{{userNumbers || 0}}</span>
          </div>
          <img class="img" src="../../assets/nihewan/question/paihang.png" />
        </div>
        <div class="item item-rule" @click="showRule = true">
          <img class="img" src="../../assets/nihewan/question/guize.png" />
        </div>
      </div>
      <div class="right item-box">
        <div class="item item-start" @click="handleStart">
          <img class="img" src="../../assets/nihewan/question/kaishi.png" />
        </div>
        <div class="item item-prize" @click="handleShowPrize">
          <img class="img" src="../../assets/nihewan/question/jiangpin.png" />
        </div>
      </div>
    </div>-->

    <div class="content">
      <div class="menu-left">
        <!-- 排行榜 -->
        <div class="menu-rank menu-item" @click="handleShowRank">
          <p class="user-numbers">{{userNumbers || 0}}</p>
          <img src="../../assets/question/paihang.png" alt />
        </div>
        <!-- 规则 -->
        <div class="menu-rule menu-item" @click="showRule = true">
          <img src="../../assets/question/guize.png" alt />
        </div>
      </div>
      <div class="menu-right">
        <!-- 去答题 -->
        <div class="menu-question menu-item" @click="handleStart">
          <img src="../../assets/question/kaishi.png" alt />
        </div>
        <!-- 奖品 -->
        <div class="menu-prize menu-item" @click="handleShowPrize">
          <img src="../../assets/question/jiangpin.png" alt />
        </div>
      </div>
    </div>

    <!-- 排行榜 -->
    <transition name="slide">
      <div class="rank-box" v-show="showRank">
        <!-- <img src="../../assets/nihewan/question/question-bg.png" class="bg" /> -->
        <i class="iconfont iconclose close" @click="showRank=false"></i>
        <div class="nav">
          <div :class="['nav-score', 'item', showScore ? 'active' : '']">
            <span class="text" @click="toggleNav('score')">积分排行</span>
          </div>
          <div :class="['nav-join', 'item', showJoin ? 'active' : '']">
            <span class="text" @click="toggleNav('join')">答题排行</span>
          </div>
        </div>
        <div class="score list" v-show="showScore" ref="scoreList">
          <div class="item" v-for="(item, i) in scoreList" :key="i">
            <div class="ranking">{{item.rank}}</div>
            <div class="icon">
              <img class="img" :src="item.user_icon" v-if="item.user_icon" />
              <img class="img" src="../../assets/img/apply_avatar_bg.png" v-else alt />
            </div>
            <div class="info">
              <div class="nick">{{item.user_nick || '*'}}</div>
              <div class="num-box">
                <span class="num">{{item.num_ || 0}}</span>
                <span class="value">积分：{{item.score}}</span>
              </div>
              <!-- <div class="num">{{item.num_ || 0}}</div> -->
              <!-- <div class="value">积分：{{item.score}}</div> -->
            </div>
          </div>
        </div>

        <div class="join list" v-show="showJoin" ref="joinList">
          <div class="item" v-for="(item, i) in joinList" :key="i">
            <div class="ranking">{{item.rank}}</div>
            <div class="icon">
              <img class="img" :src="item.user_icon" v-if="item.user_icon" />
              <img class="img" src="../../assets/img/apply_avatar_bg.png" v-else alt />
            </div>
            <div class="info">
              <div class="nick">{{item.user_nick || '*'}}</div>
              <div class="num-box">
                <span class="num">{{item.num_ || 0}}</span>
                <span class="value">答题：{{item.join_numbers}}</span>
              </div>
              <!-- <span class="text nick">{{item.user_nick}}</span> -->
              <!-- <span class="text num">答题：{{item.join_numbers}}</span> -->
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 答题规则显示在当前页面 -->
    <div class="rule-box" v-show="showRule" @click.self="showRule = false">
      <div class="rule">
        <div class="close" @click="showRule = false">
          <i class="iconfont iconclose"></i>
        </div>
        <div class="title">
          <span class="text">答题规则</span>
        </div>
        <div class="content rich-content">
          <p class="text" v-for="(item,i) in ruleContent" :key="i">{{item}}</p>
        </div>
      </div>
    </div>

    <!-- 奖品 -->
    <transition name="slide">
      <div class="prize-box" v-show="showPrize">
        <i class="iconfont iconclose close" @click="showPrize=false"></i>
        <div class="prize-title">
          <p class="text">{{prizeInfo.title}}</p>
        </div>
        <div class="prize-content">
          <p class="prize-preview">- 奖品预览 -</p>
          <ul class="prize-list">
            <li class="item" v-for="(item,i) in filterByPrice(prizeList)" :key="i">
              <div class="prize">
                <img
                  class="icon"
                  :src="item.iconUrl ? item.iconUrl : (item.type_=='money' ? money_icon : (item.type_ == 'coupon' ? coupon_icon : thanks_icon))"
                />
                <span class="name">{{item.label_name === '现金' ? '现金红包' : item.label_name}}</span>
              </div>
              <div class="value">￥{{item.price}}</div>
            </li>
          </ul>
          <!-- 去抽奖 按钮 -->
          <div class="to-lottery">
            <button class="btn" @click="handleToLottery">去抽奖</button>
          </div>
          <!-- 参与人数 -->
          <div class="stats_wrapper">
            <div
              class="stats_join stats_item"
              :class="showStats == 1 ? 'stats_active' : ''"
              @click="showStats = 1"
            >
              <span class="stats_item_name">参与次数</span>
              <span class="stats_item_number">{{joinNumbers}}</span>
            </div>
            <div
              class="stats_win stats_item"
              :class="showStats == 2 ? 'stats_active' : ''"
              @click="showStats = 2"
            >
              <span class="stats_item_name">中奖次数</span>
              <span class="stats_item_number">{{winNumbers}}</span>
            </div>
          </div>
          <!-- 参与列表 -->
          <transition name="stats">
            <div class="stats_container stats-list" v-show="showStats == 1 && joinNumbers">
              <div class="join_list stats-list-body">
                <!-- <transition-group name="stats" tag="div" class="join_list"> -->
                <div
                  class="join_list_item stats-list-item"
                  v-for="(k,i) in lotteryJoinList"
                  :key="i"
                >
                  <div class="list-item-icon">
                    <img :src="k.user_icon" v-if="k.user_icon" />
                    <img src="../../assets/img/apply_avatar_bg.png" v-else-if="!k.user_icon" />
                  </div>
                  <div class="list-item-content">
                    <span class="list-item-content-name">{{k.user_nick}}</span>
                    <!-- <span>&nbsp;访问了{{k.numbers}}次</span> -->
                  </div>
                  <div class="list-item-count">
                    <!-- <span class="count-progress-hold"></span> -->
                    <span class="count-progress" :style="computedWidth(k.numbers)"></span>
                    <span class="count-number">{{k.numbers}}次</span>
                  </div>
                </div>
                <!-- </transition-group> -->
              </div>
              <div class="stats-list-footer">
                <span class="footer_text" @click="loadMoreData('join')">{{join_data_bottom}}</span>
              </div>
            </div>
          </transition>
          <!-- 中奖列表 -->
          <transition name="stats">
            <div class="stats_container stats-list" v-show="showStats == 2 && winNumbers">
              <div class="win_list stats-list-body">
                <!-- <transition-group name="stats" tag="div" class="win_list"> -->
                <!-- <div class="win_list_item" v-for="(item,i) in filterBy(win_list)" :key="i"> -->
                <div class="win_list_item" v-for="(item,i) in lotteryWinList" :key="i">
                  <img class="win_list_item_icon" :src="item.user_icon" alt v-if="item.user_icon" />
                  <img src="../../assets/img/apply_avatar_bg.png" alt v-else />
                  <span
                    class="win_list_item_content"
                  >{{filterByNick(item.user_nick)}}&nbsp;抽中&nbsp;￥{{item.price}}元&nbsp;{{item.notes}}</span>
                  <span class="win_list_item_time">{{item.add_time}}</span>
                </div>
                <!-- </transition-group> -->
              </div>
              <div class="stats-list-footer">
                <span class="footer_text" @click="loadMoreData('win')">{{win_data_bottom}}</span>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <!-- 发布红包 -->
    <publish-button :id="id" :id_type_="backUrl" v-if="isAdmin" bottom="2.8rem"></publish-button>
    <!-- 公司信息 -->
    <!-- <div class="company-box" v-if="companyInfo.title">
      <a :href="companyInfo.url" class="name">{{companyInfo.title}}</a>
    </div> -->
    <!-- 音频 -->
    <div class="bg-audio" v-html="activity.activity_other" style="display: none"></div>
    <!-- 反馈 -->
    <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator, MessageBox } from 'mint-ui'
import PublishButton from '../../components/PublishButton'
import TechnicalSupport from '../../components/TechnicalSupport'
import { TweenMax } from 'gsap'
const wx = require('weixin-js-sdk')
export default {
  components: {
    'technical-support': TechnicalSupport,
    'publish-button': PublishButton
  },
  data () {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || '1837',
      version: '1.0.0',
      type_: 'label',
      backUrl: 'nihewanQuestion',
      share_type: 'nihewanQuestionShare',
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      open_id: localStorage.getItem('open_id_' + this.$route.query.id)
        ? localStorage.getItem('open_id_' + this.$route.query.id)
        : '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      // 自定义
      isLoading: false,
      loaded: false,
      user_info: {},
      showRule: false,
      showPrize: false,

      // 排行榜
      firstShowRank: true,
      showRank: false,
      scorePage: 1,
      joinPage: 1,

      scoreList: [],
      joinList: [],

      showScore: true,
      showJoin: false,
      haveMoreScore: true,
      haveMoreJoin: true,

      // 获取数据
      activity: {},
      ruleContent: [],
      user: {},
      userNumbers: 0,
      isAdmin: 0,
      companyInfo: {},
      haveCompany: false,
      joinNumbers: 0,
      maxJoinNumber: 1,

      winNumbers: 0,
      maxWinNumber: 1,

      join_data_page: 1,
      join_data_bottom: '点击查看更多',
      join_data_len: '',
      win_data_bottom: '点击查看更多',
      win_data_len: '',
      win_data_page: 1,

      // 奖品信息
      prizeList: [],
      prizeInfo: {},
      isGetPrize: false,
      thanks_icon: require('../../assets/lottery/give_up.png'), // 奖品图片
      money_icon: require('../../assets/switch/redpacket.png'),
      coupon_icon: require('../../assets/switch/coupon.png'), // 优惠券

      // 抽奖
      have_chance: 0,
      have_chance_id: '',
      have_red_packet_rain: '',
      red_packet_image_url: '',
      red_packet_image_url_list: '',
      red_packet_numbers: 0,
      red_packet_type_: 0,
      lotteryInfo: {},
      // 用户列表
      lotteryWinList: [],
      lotteryJoinList: [],
      showStats: 1
    }
  },
  mounted () {
    if (this.token) {
      // 已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id_' + this.id, this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.id, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.id, '')
      }
    }
    if (!this.open_id) {
      this.authorize()
    }
    // this.checkLogin();
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }
    this.saveUserInfo()
    this.getData()

    this.getCompanyInfo(this.id, this.token).then(
      res => {
        // console.log(res);
        this.companyInfo = res
        this.haveCompany = true
      },
      err => {
        console.log(err)
      }
    )

    this.$nextTick(() => {
      this.handleRankLoad()
    })
  },
  methods: {
    // 加载更多数据
    loadMoreData (dataType) {
      let where_, page
      if (dataType == 'join') {
        if (this.join_data_len == 0) {
          this.join_data_bottom = '我是有底线的'
          return
        } else {
          this.join_data_page++
          where_ = 'join'
          page = this.join_data_page
        }
      } else if (dataType == 'win') {
        if (this.win_data_len == 0) {
          this.win_data_bottom = '我是有底线的'
          return
        } else {
          this.win_data_page++
          where_ = 'win'
          page = this.win_data_page
        }
      }

      if (this.id && this.token) {
        this.$ajax(
          'post',
          'Lottery/get_lottery_user_list',
          {
            id: this.lotteryInfo.id,
            id_: this.id,
            id_type_: this.backUrl,
            token: this.token,
            where_: where_,
            page: page
          },
          res => {
            // console.log(res);
            if (res.data.id == 0 && res.data.data[dataType + '_list']) {
              this[dataType + '_list'] = this[dataType + '_list'].concat(
                res.data.data[dataType + '_list']
              )
              // console.log(this[dataType + "_list"]);
              this[dataType + '_data_len'] =
                res.data.data[dataType + '_list'].length
            } else {
              Toast('我是有底线的')
              this[dataType + '_data_len'] = 0
            }
          }
        )
      } else {
        // 还未授权过
        this.authorize()
      }
    },
    // 截取昵称长度
    filterByNick (n) {
      if (n && n.length > 4) {
        return n.substring(0, 4) + '...'
      } else {
        return n
      }
    },
    // 计算进度条宽度
    computedWidth (num) {
      let width =
        (num / this.maxJoinNumber) * 100 >= 6
          ? (num / this.maxJoinNumber) * 100
          : 6
      // console.log(width);
      return {
        width: width + '%'
      }
    },
    // 上一级
    goBack () {
      this.$router.push({
        path: '/nihewan/home',
        query: {
          id: this.id
        }
      })
    },
    // 去答题
    handleStart () {
      this.$router.push({
        path: '/nihewan/question',
        query: {
          id: this.id
        }
      })
    },
    // 去抽奖
    handleToLottery () {
      if (this.have_chance > 0) {
        this.$router.push({
          path: '/lottery/lottery',
          query: {
            id: this.lotteryInfo.id,
            id_: this.lotteryInfo.id_,
            id_type_: this.lotteryInfo.id_type_
          }
        })
      } else {
        MessageBox.confirm('是否前往答题获取抽奖机会？').then(
          action => {
            this.handleStart()
          },
          reject => {
            return false
          }
        )
      }
    },
    // 过滤奖品中的谢谢参与
    filterByPrice (ls) {
      return ls.filter(item => {
        return parseFloat(item.price) > 0
      })
    },
    // 显示奖品
    handleShowPrize () {
      if (this.lotteryInfo.id && this.lotteryInfo.id_ && !this.isGetPrize) {
        // 有抽奖
        this.getPrize(this.lotteryInfo.id, this.lotteryInfo.id_)
        this.showPrize = true
      } else if (
        this.lotteryInfo.id &&
        this.lotteryInfo.id_ &&
        this.isGetPrize
      ) {
        this.showPrize = true
      } else {
        // 没有抽奖
        Toast('暂未开放')
        return false
      }
    },
    // 获取奖品

    getPrize (id, id_) {
      if (this.id && this.token && this.open_id) {
        Indicator.open('加载中...')
        this.$ajax(
          'post',
          'lottery/get_park_luck_label_list',
          {
            id: id,
            token: this.token,
            open_id: this.open_id,
            id_: id_,
            id_type_: this.backUrl
          },
          res => {
            Indicator.close()
            this.isGetPrize = true
            if (res.data.id == 0) {
              this.prizeList.push(...res.data.data.data)
              Object.assign(this.prizeInfo, res.data.data.park_luck)
              this.joinNumbers = this.prizeInfo.join_numbers
              this.winNumbers = this.prizeInfo.win_numbers
            }
          }
        )
      }
    },
    // 切换排行榜
    toggleNav (type) {
      if (type === 'score') {
        this.showScore = true
        this.showJoin = false
      } else {
        this.showScore = false
        this.showJoin = true
      }
    },
    // 排行榜加载更多
    handleRankLoad () {
      const scoreList = this.$refs.scoreList,
        joinList = this.$refs.joinList
      scoreList.addEventListener('scroll', e => {
        const scrollTop = e.target.scrollTop,
          scrollHeight = e.target.scrollHeight,
          offsetHeight = e.target.offsetHeight
        if (scrollTop + offsetHeight >= scrollHeight - 50) {
          this.getRank(this.scorePage, 'score')
        }
      })
      joinList.addEventListener('scroll', e => {
        const scrollTop = e.target.scrollTop,
          scrollHeight = e.target.scrollHeight,
          offsetHeight = e.target.offsetHeight
        if (scrollTop + offsetHeight >= scrollHeight - 50) {
          this.getRank(this.joinPage, 'join')
        }
      })
    },
    // 显示排行榜
    handleShowRank () {
      if (this.firstShowRank) {
        // 第一次显示排行榜
        this.getRank(this.scorePage, 'score')
        this.firstShowRank = false
        this.showRank = true
      } else {
        this.showRank = true
      }
    },
    /**
     * 获取排行榜数据
     */
    getRank (page, type) {
      if (this.isLoading) {
        return false
      }

      if (
        (type === 'score' && !this.haveMoreScore) ||
        (type === 'join' && !this.haveMoreJoin)
      ) {
        console.log('' + type + '-加载完')
        return false
      }

      this.isLoading = true

      Indicator.open('加载中...')

      if (this.id && this.token && this.type_) {
        this.$ajax(
          'post',
          'Question/get_rank_list',
          {
            id_: this.id,
            token: this.token,
            id_type_: this.type_,
            page: page,
            type_: type
          },
          res => {
            if (res.data.id == 0) {
              if (page === 1) {
                this.scoreList.push(...res.data.data.score_list)
                this.joinList.push(...res.data.data.join_list)
                this.scorePage++
                this.joinPage++

                if (
                  !res.data.data.score_list ||
                  res.data.data.score_list.length < 10
                ) {
                  this.haveMoreScore = false
                }
                if (
                  !res.data.data.join_list ||
                  res.data.data.join_list.length < 10
                ) {
                  this.haveMoreJoin = false
                }
              } else if (type === 'score') {
                this.scoreList.push(...res.data.data.list)
                this.scorePage++
              } else if (type === 'join') {
                this.joinList.push(...res.data.data.list)
                this.joinPage++
              }
            } else {
              if (type === 'score') {
                this.haveMoreScore = false
              } else if (type === 'join') {
                this.haveMoreJoin = false
              }
            }
            if (
              page > 1 &&
              (!res.data.data.list || res.data.data.list.length < 10)
            ) {
              if (type === 'score') {
                this.haveMoreScore = false
              } else if (type === 'join') {
                this.haveMoreJoin = false
              }
            }
            this.isLoading = false

            Indicator.close()
          }
        )
      }
    },
    userListSwipe () {
      let ls = this.$refs.winList
      let wd = parseFloat(getComputedStyle(ls).width)
      let win_wd =
        document.documentElement.clientWidth || document.body.clientWidth
      // console.log(win_wd);
      // console.log(wd);
      let ti = (wd / win_wd) * 10
      TweenMax.to(ls, ti, {
        x: -wd + win_wd + 'px',
        ease: Power0.easeNone,
        repeat: -1
      })
    },
    // 获取用户参与数据
    getUserList (id) {
      // 测试！！
      // id = 29;
      this.$ajax(
        'post',
        'Lottery/get_lottery_user_list',
        {
          id: id,
          id_: this.id,
          id_type_: this.backUrl,
          token: this.token,
          // is_eject: 1, // 滚动数据
          page: 1,
          where_: 'join'
        },
        res => {
          if (res.data.id == 0) {
            // console.log(res.data.data);

            if (res.data.data.join_list && res.data.data.join_list.length > 0) {
              this.lotteryJoinList.push(...res.data.data.join_list)
              this.join_data_len = this.lotteryJoinList.length
              this.join_data_bottom = '点击加载更多'
              this.maxJoinNumber = this.lotteryJoinList[0].numbers || 1

              this.$nextTick(() => {
                this.userListSwipe()
              })
            } else {
              this.join_data_bottom = '我是有底线的'
            }
            if (res.data.data.win_list && res.data.data.win_list.length > 0) {
              this.lotteryWinList.push(...res.data.data.win_list)
              this.maxWinNumber = this.lotteryWinList[0].numbers || 1
              this.win_data_len = this.lotteryWinList.length
              this.win_data_bottom = '点击加载更多'
            } else {
              this.win_data_bottom = '我是有底线的'
            }
          } else {
            console.log('暂无参与信息')
          }
        }
      )
    },
    // 获取数据
    getData () {
      this.isLoading = true
      Indicator.open('加载中...')
      this.$ajax(
        'post',
        'Question/get_question_data',
        {
          id_: this.id,
          park_id: this.park_id,
          id_type_: this.backUrl,
          open_id: this.open_id,
          token: this.token
        },
        res => {
          if (res.data.id == 0) {
            if (res.data.data.is_del_open_id == 1) {
              localStorage.setItem('open_id_' + this.id, '')
              this.authorize()
            }

            this.user = res.data.data.user || this.user_info

            this.activity = res.data.data.activity
            this.ruleContent.push(
              ...this.activity.activity_content.split('\n')
            )
            this.userNumbers = res.data.data.user_numbers
            this.isAdmin = res.data.data.is_admin || 0

            this.have_chance = res.data.data.have_chance || 0
            this.have_chance_id = res.data.data.have_chance_id || ''
            this.have_red_packet_rain =
              res.data.data.have_red_packet_rain || ''
            this.red_packet_image_url =
              res.data.data.red_packet_image_url || ''
            this.red_packet_image_url_list =
              res.data.data.red_packet_image_url_list || ''
            this.red_packet_numbers = res.data.data.red_packet_numbers || 0
            this.red_packet_type_ = res.data.data.red_packet_type_ || 0

            if (
              this.red_packet_image_url_list &&
              this.red_packet_image_url_list.length > 0
            ) {
              Object.assign(this.lotteryInfo, {
                id: this.red_packet_image_url_list[0].id,
                id_: this.red_packet_image_url_list[0].id_,
                id_type_: this.red_packet_image_url_list[0].id_type_
              })
            }

            if (this.lotteryInfo.id) {
              this.getUserList(this.lotteryInfo.id)
            }

            const sUrl = window.location.href,
              wx_title = res.data.data.activity.activity_name,
              wx_content = res.data.data.activity.activity_content,
              wx_img = res.data.data.activity.bg_
            if (this.is_weixin) {
              this.wx_share_callback(sUrl, {
                url: sUrl,
                id_: this.id,
                id_type_: this.share_type,
                token: this.token,
                open_id: this.open_id,
                title: wx_title,
                content: wx_content,
                img: wx_img
              })
            }
          }

          this.isLoading = false
          if (!this.loaded) {
            this.loaded = true
          }
          Indicator.close()
        }
      )
    },

    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id == 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var open_id = this.open_id
            if (info != '') {
              if (info.url != '') {
                let url_arr = info.url.split('share_open_id')

                url = url_arr[0] + '&share_open_id=' + open_id
              } else {
                url = url + '&share_open_id=' + open_id
              }
            } else {
              url = url + '&share_open_id=' + open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id == 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },
    /**
     * 保存用户访问答题信息
     */
    saveUserInfo () {
      if (
        this.open_id &&
        this.token &&
        this.user_info &&
        this.id &&
        this.type_
      ) {
        this.$ajax(
          'post',
          'label/save_mini_user_info_data',
          {
            user_nick: this.user_info.user_nick,
            user_icon: this.user_info.user_icon,
            park_id: this.park_id,
            id_: this.id,
            id_type_: this.type_,
            open_id: this.open_id,
            token: this.token,
            version: this.version
          },
          res => {
            if (res.data.id === 0) {
              console.log('保存用户信息')
            }
          }
        )
      }
    },
    // 登录
    checkLogin () {
      if (!this.check_login()) {
        MessageBox.alert('请先登录').then(action => {
          if (action == 'confirm') {
            localStorage.setItem('login_url', location.hash)
            this.$router.push({ path: '/my/login' })
          }
        })
      } else {
        this.user_info = this.check_login()
      }
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=' +
        this.id +
        '&token=' +
        this.token +
        '&state=' +
        this.backUrl
      window.location.href = open_url
    }
  }
}
</script>

<style scoped>
.container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-color: rgb(207, 232, 255);
}

.bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.back {
  position: fixed;
  top: 0.4rem;
  left: 0.4rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

/* header */
.header {
  position: relative;
  width: 100%;
}

.banner {
  display: block;
  width: 100%;
  height: 50vw;
  object-fit: cover;
  /* filter: blur(20px); */
}

/* 中奖信息 */
.win-box {
  /* margin-top: 1rem; */
  /* position: fixed; */

  position: relative;
  width: calc(100% - 0.4rem);
  height: 2rem;
  margin: 0.2rem;
  /* top: calc(44vw - 2rem); */
  /* left: 0; */
  overflow-x: hidden;
  z-index: 2;
}
.win-box::-webkit-scrollbar {
  display: none;
}

.win-list {
  height: 100%;
  display: inline-flex;
  /* background-color: rgb(255, 251, 197); */
}

.win-list > .item {
  height: 2rem;
  margin-left: 1rem;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: .7rem; */
}

.win-list > .item:first-child {
  margin-left: 100vw;
}

.win-list > .item:last-child {
  margin-right: 100vw;
}

.win-list > .item > .icon {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  object-fit: cover;
}

.win-list > .item > .nick {
  margin: 0 4px;
  color: #666;
}

.win-list > .item > .name {
  margin: 0 4px;
}

/* 用户信息 */

.user-card {
  position: relative;
  width: calc(100% - 2rem);
  background-size: contain;
  margin: 0.2rem auto 0.5rem;
  border-radius: 0.5rem;
  /* box-shadow: 0 0 1px 4px rgb(207, 232, 255); */
}
.user-card .card-bg {
  width: 100%;
}
.user-card .card-bg img {
  width: 100%;
  display: block;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.4);
}
.user-card .user-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4%;
}
.user-card .user-info .num {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 2px 12px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 2em;
  color: #fff;
}
.user-card .icon {
  width: 18%;
  border-radius: 50%;
  margin-bottom: 0.4rem;
  box-shadow: 0 0 1px 4px rgba(255, 255, 255, 0.88);
}
.user-card .nick {
  font-size: 0.8rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0.4rem;
  width: 50%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-card .stats {
  position: absolute;
  top: 72%;
  left: 0;
  width: 100%;
  font-size: 0.8rem;
  /* font-weight: bold; */
}
.user-card .stats p {
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}
.user-card .stats p.stats-integral {
  left: 14%;
}
.user-card .stats p.stats-rank {
  left: 86.4%;
}

.user-card .stats .value {
  color: rgb(160, 250, 250);
}

/* content */
/* .container > .content {
  margin-top: 1rem;
  padding: 4vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.container > .content > .item-box {
  width: 44vw;
}
.container > .content > .item-box > .item {
  position: relative;
  margin-bottom: 4vw;
  width: 44vw;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
}

.container > .content > .item-box > .item > .img {
  display: block;
  width: 100%;
}

.container > .content > .item-box > .item-rank > .value {
  position: absolute;
  left: 10%;
  top: 11%;
  font-size: 0.74rem;
  color: #e6a23c;
}

.container > .content > .item-box > .item-rank > .value > .iconfont {
  font-size: 0.8rem;
  color: #e6a23c;
} */

/* 菜单 */
.content {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.menu-left,
.menu-right {
  width: calc(50% - 0.5rem);
  display: flex;
  flex-direction: column;
}

.menu-item {
  position: relative;
  width: 100%;
  border-radius: 0.24rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.menu-item img {
  width: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
}
.menu-item .user-numbers {
  position: absolute;
  top: 4%;
  right: 15%;
  color: #fff;
  font-size: 0.8rem;
  width: 42%;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 排行榜 */
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}
.slide-enter-active,
.slide-leave-active {
  transition: ease 0.3s;
}
.rank-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: rgb(127, 160, 255);
  /* background-color: rgb(243, 245, 248); */
  overflow: hidden;
  z-index: 100;
}

.rank-box > .close {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.6);
  z-index: 2;
}

/* 目录 */
.nav {
  position: relative;
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem 2rem 3rem;
  /* background-color: rgb(1, 71, 109); */
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.nav::after {
  content: "";
  position: absolute;
  left: 0.8rem;
  width: calc(100% - 1.6rem);
  bottom: calc(1.5rem - 6px);
  height: 12px;
  border-radius: 6px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2));
}

.nav > .item {
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.nav > .item > .text {
  display: inline-block;
  padding: 0.2rem 0;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  font-size: 1rem;
  color: #eee;
}

.nav > .active > .text {
  border-bottom-color: rgba(255, 255, 255, 0.8);
  color: #fff;
}

.nav > .nav-score {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

/* 列表 */
.list {
  position: relative;
  margin-top: -1.5rem;
  height: calc(100% - 3.5rem);
  padding: 0 1rem 1rem;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 3;
}

.list .item {
  position: relative;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 1.5rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, rgb(93, 184, 240), rgb(70, 170, 231));
  /* background: linear-gradient(45deg, #409eff, #1c8bfa); */
}

.list .item:nth-child(1) {
  background: linear-gradient(45deg, #e33, rgb(233, 41, 41));
}

.list .item:nth-child(2) {
  background: linear-gradient(45deg, #fd8f1e, #f18416);
}

.list .item:nth-child(3) {
  background: linear-gradient(45deg, #7ac305, #78c003);
}

.list .item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2.6rem;
  height: 2.6rem;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.2) 50%, transparent 0);
  border-top-left-radius: 8px;
}

.list .item > .ranking {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
}

.list .item > .icon {
  width: 2.6rem;
  height: 2.6rem;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}

.list .item > .icon > .img {
  display: block;
  width: 100%;
  height: 100%;
}

.list .item > .info {
  position: relative;
  width: calc(100% - 3.5rem);
  height: 2.6rem;
  padding-left: 0.5rem;
  box-sizing: border-box;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-around; */
  color: #fff;
}

.list .item > .info > .nick {
  width: 100%;
  height: 1.3rem;
  line-height: 1.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8rem;
}

.list .item > .info > .num-box {
  width: 100%;
  height: 1.3rem;
  line-height: 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list .item > .info > .num-box > .num {
  flex: 1;
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #f6f6f6;
}
.list .item > .info > .num-box > .value {
  padding: 0 12px;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 2em;
}

.list .item > .info > .num {
  /* font-size: 0.68rem; */
  height: 1.3rem;
  line-height: 1.3rem;
  color: #f6f6f6;
}
.list .item > .info > .value {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 1.3rem;
  line-height: 1.3rem;
  padding: 0 12px;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 2em;
}

/* rule */

.rule-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  animation: opa 0.3s linear 1 both;
}

@keyframes opa {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rule-box > .rule {
  position: relative;
  width: 84%;
  margin: 0 auto;
  /* padding: 0 1rem; */
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.rule-box > .rule > .close {
  position: absolute;
  top: 0;
  right: 0;
  height: 3rem;
  width: 3rem;
  text-align: center;
  line-height: 3rem;
}

.rule-box > .rule > .close > .iconfont {
  font-size: 1rem;
  color: #eee;
}

.rule > .title {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  /* border-bottom: 1px solid #eee; */
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  background-color: rgb(252, 108, 25);
  color: #fff;
}

.rule > .content {
  padding: 1rem;
  width: 100%;
  min-height: 30vh;
  max-height: 80vh;
  box-sizing: border-box;
  font-size: 0.8rem;
  color: #333;
  overflow-y: auto;
}

/* 奖品 */
.prize-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: rgb(241, 89, 82);
  /* background-color: rgb(243, 245, 248); */
  overflow: hidden;
  z-index: 100;
}

.prize-box > .close {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.6);
  z-index: 2;
}

.prize-title {
  height: 2.8rem;
  width: 100%;
  padding: 0 2.8rem;
  box-sizing: border-box;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.prize-title > .text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.prize-content {
  width: 100%;
  height: calc(100vh - 2.8rem);
  padding: 1rem 0;
  box-sizing: border-box;
  overflow-y: auto;
}

.prize-preview {
  font-size: 1rem;
  color: rgb(255, 215, 69);
  font-weight: bold;
  text-align: center;
}

.prize-list {
  width: 80%;
  margin: 0 auto;
}

.prize-list > .item {
  height: 2.4rem;
  width: 100%;
  margin: 1rem auto 0;
  padding: 0 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 0.8rem;
  color: #fff;
  border-radius: 4px;
}

.prize-list > .item > .prize {
  display: flex;
  align-items: center;
}

.prize-list > .item > .prize > .icon {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 6px;
  object-fit: cover;
}

/* 参与人数 */
.stats-enter {
  transform: translate(30%, 0);
  opacity: 0;
}

.stats-enter-to {
  transform: translate(0, 0);
  opacity: 1;
}
.stats-enter-active {
  transition: ease 0.3s;
}
.stats_wrapper {
  /* margin-top: 2.5rem; */
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
}
.stats_wrapper .stats_item {
  height: 100%;
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: #eee;
  background-color: rgba(255, 166, 0, 1);
  transition: 0.5s;
}
/* .stats_wrapper .stats_item:nth-child(1){
  border-right: 1px solid rgb(236, 154, 2);
} */
.stats_wrapper .stats_item.stats_active {
  background-color: rgba(255, 166, 0, 0.6);
  color: #fff;
  transition: 0.5s;
}
.stats_wrapper .stats_item .stats_item_name {
  font-size: 0.8rem;
}
.stats_wrapper .stats_item .stats_item_number {
  font-size: 0.88rem;
  font-weight: 600;
}

.win_list {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.win_list .win_list_item {
  width: 100%;
  height: 2.2rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.win_list .win_list_item img {
  width: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.win_list .win_list_item .win_list_item_content {
  width: 60%;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.win_list .win_list_item .win_list_item_time {
  width: 40%;
  color: #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stats-list {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0.4rem 1rem;
  /* background-color: #fff; */
}

.stats-list .stats-list-body {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  /* flex-wrap: wrap; */
  flex-direction: column;
  padding: 0.5rem 0;
}
.stats-list .stats-list-body .stats-list-item {
  position: relative;
  width: 100%;
  height: 2.2rem;
  display: flex;
  align-items: center;
}
.stats-list .stats-list-body img {
  width: 1.8rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.stats-list .stats-list-body .list-item-content {
  font-size: 0.8rem;
  color: #fff;
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stats-list .stats-list-body .list-item-content .list-item-content-name {
  color: #fff;
}
.stats-list .stats-list-body .list-item-count {
  position: relative;
  width: 80%;
  height: 0.8rem;
  line-height: 0.8rem;
  background-color: rgba(255, 166, 0, 0.2);
  text-align: right;
  border-radius: 100px;
  /* display: flex; */
}
/* .stats-list .stats-list-body .list-item-count .count-progress-hold {
  display: block;
  height: 100%;
  background-color: rgba(255, 0, 0, 1);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  width: 5%;
} */
.stats-list .stats-list-body .list-item-count .count-progress {
  display: block;
  height: 100%;
  background-color: rgba(255, 166, 0, 1);
  /* border-top-right-radius: 100px; */
  /* border-bottom-right-radius: 100px; */
  border-radius: 100px;
}
.stats-list .stats-list-body .list-item-count .count-number {
  position: absolute;
  top: 0;
  right: 0.5rem;
  height: 100%;
  color: #fff;
}
.stats-list .jl-body {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0.5rem 0;
}
.stats-list .jl-body .jl-item {
  display: flex;
  width: 100%;
  height: 2.4rem;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0.4rem;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.stats-list .jl-body .jl-item img {
  display: block;
  border-radius: 50%;
  width: 2rem;
  margin-right: 1%;
}
.stats-list .jl-body .jl-item .jl-name {
  margin-right: 5%;
  display: flex;
  align-items: center;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stats-list .jl-body .jl-item .jl-time {
  width: 40%;
  font-size: 12px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stats-list .jl-body .jl-item .jl-prize {
  color: #333;
}
.stats-list .jl-body .jl-item .jl-prize .jl-prize-price {
  color: rgb(168, 4, 4);
}
.stats-list .stats-list-footer {
  position: relative;
  width: 40%;
  margin: 1rem auto 0;
  color: #ccc;
  text-align: center;
  background-color: #ccc;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats-list .stats-list-footer .footer_text {
  display: inline-block;
  padding: 0 4px;
  background-color: rgb(241, 89, 82);
}
/* 去抽奖 */

.to-lottery {
  position: relative;
  width: 60%;
  margin: 1rem auto;
  padding: 1rem;
  box-sizing: border-box;
}

.to-lottery > .btn {
  width: 100%;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    rgba(243, 172, 40, 0.9),
    rgba(252, 195, 72, 0.9)
  );
  font-size: 1rem;
  border-radius: 1.2rem;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2),
    0 2px 4px -2px inset rgba(255, 255, 255, 0.8);
  color: #fff;
  font-weight: bold;
}
/* 公司信息 */
.company {
  position: relative;
  width: 100%;
  padding: 1rem 0;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 0.74rem;
  z-index: 1;
}

</style>
