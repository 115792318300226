<template>
  <div class="AwardList TrackIndex" v-title data-title="我的奖励">
    <mt-header fixed title="我的奖励">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
      <mt-button @click="GetMoney(info.user.red_packet)" slot="right">提现</mt-button>
    </mt-header>
    <div class="page-content">
      <div class="AwardList_body">
        <ul class="tab_list clearfix">
          <li :class="{active:tab==1}" @click="navtab(1)">
            <span>收到奖励</span>
          </li>
          <li :class="{active:tab==2}" @click="navtab(2)">
            <span>发出奖励</span>
          </li>
        </ul>
        <div class="user" v-if="tab==1">
          <img :src="info.user.user_icon" alt />
          <p>{{info.user.user_nick}}</p>
        </div>
        <div class="user" v-if="tab==2">
          <img :src="info.packet_user.user_icon" alt />
          <p>{{info.packet_user.user_nick}}</p>
        </div>
        <ul class="summary clearfix" v-if="tab==1">
          <li>
            <p>{{info.user.red_packet}}元</p>
            <!--<span>{{info.user.money_number}}个</span>-->
            <p>可提现金额</p>
          </li>
          <li>
            <p>{{info.user.coupon}}元</p>
            <!--<span>{{info.user.coupon_number}}张</span>-->
            <p>收到优惠卷</p>
          </li>
          <li>
            <p>{{info.user.goods}}元</p>
            <!--<span>{{info.user.goods_number}}个</span>-->
            <p>收到礼品</p>
          </li>
        </ul>
        <ul class="summary clearfix" v-if="tab==2">
          <li>
            <p>{{info.packet_user.money}}元</p>
            <span>{{info.packet_user.money_number}}个</span>
            <p>发出红包</p>
          </li>
          <li>
            <p>{{info.packet_user.coupon_number}}元</p>
            <span>{{info.packet_user.coupon}}张</span>
            <p>发出优惠卷</p>
          </li>
          <li>
            <p>{{info.packet_user.goods}}元</p>
            <span>{{info.packet_user.goods_number}}个</span>
            <p>发出礼品</p>
          </li>
        </ul>
        <ul class="item_list" v-if="info.item_list.length !=0">
          <mt-loadmore
            :bottom-method="loadBottom"
            :bottom-all-loaded="allLoaded"
            ref="loadmore"
            :auto-fill="false"
          >
            <router-link
              :to="{path:'/my/MyAwardInfo',query:{id:k.id,type:tab,label_fk_id:k.label_fk_id,label_id:k.label_id,spot_id:k.spot_id}}"
              tag="li"
              v-for="(k,i) in info.item_list"
              :key="i"
            >
              <h2 class="clearfix">
                <span class="fl">
                  {{k.park_name}}{{k.title}}
                  <img
                    v-if="k.label_id == 155"
                    src="../../assets/img/footprint_coupon.png"
                    alt
                  />
                  <img v-if="k.label_fk_id == 155" src="../../assets/img/footprint_coupon.png" alt />
                  <img v-if="k.label_id == 154" src="../../assets/img/footprint_red.png" alt />
                  <img v-if="k.label_fk_id == 154" src="../../assets/img/footprint_red.png" alt />
                  <img v-if="k.label_id == 156" src="../../assets/img/footprints_gifts.png" alt />
                  <img v-if="k.label_fk_id == 156" src="../../assets/img/footprints_gifts.png" alt />
                </span>
                <span class="fr">
                  {{k.red_packet}}
                  <span v-if="tab==1">元</span>
                  <span v-if="k.label_fk_id != 155">{{k.input_money}}</span>
                  <span v-if="k.label_fk_id == 155">￥{{k.input_money}}</span>
                  <span v-if="k.label_fk_id == 155">{{k.value_number}}张</span>
                </span>
              </h2>
              <h3 class="clearfix">
                <span class="fl">{{k.add_time}}</span>
                <span class="fr" v-if="tab==2">已领完{{k.user_number}}个</span>
              </h3>
            </router-link>
          </mt-loadmore>
        </ul>
        <div class="foot_noinfo" v-else>
          <img src="../../assets/img/foot_noinfo@2x.png" alt />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      user_id: this.get_User_Id(), //user_id
      tab: 1,
      info: { item_list: [], user: {}, packet_user: {} },
      receive_list: [], //收到数据
      emit_list: [], //发出数据
      page: 1, //页码
      allLoaded: false
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      this.get_data_1(this.page);
      this.get_data_2(this.page);
    },
    //收到数据
    get_data_1(page) {
      this.$ajax(
        "post",
        "foot/receive_packet",
        { page: page, user_id: this.user_id },
        res => {
          this.receive_list = this.receive_list.concat(
            res.data.data.red_packet
          );
          if (this.page == 1) {
            this.info.user = res.data.data.user;
          }
          this.info.item_list = this.receive_list;
          this.have_packet_list = res.data.data.have_packet_list;
        }
      );
    },
    //发出数据
    get_data_2(page) {
      this.$ajax(
        "post",
        "foot/send_packet",
        { page: page, user_id: this.user_id },
        res => {
          this.emit_list = this.emit_list.concat(res.data.data.packet_list);
          if (this.page == 1) {
            this.info.packet_user = res.data.data.user;
            console.log(this.info.packet_user);
          }
          this.have_packet_list = res.data.data.have_packet_list;
        }
      );
    },
    //切换tab i==1 收到  i=2 发出
    navtab(i) {
      this.tab = i;
      if (i == 1) {
        this.info.item_list = this.receive_list;
      } else {
        this.allLoaded = false;
        this.$set(this.info, "item_list", this.emit_list);
      }
    },
    //            加载更多
    loadBottom() {
      var that = this;
      var item = this.info.item_list.length;
      if (item < 10) {
        that.$refs.loadmore.onBottomLoaded();
      }
      setTimeout(() => {
        if (item % 10 == 0) {
          that.page++;
          if (that.tab == 1) {
            that.get_data_1(that.page);
          } else {
            that.get_data_2(that.page);
          }
        } else {
          that.allLoaded = true;
          Toast("没有更多了");
        }
        that.$refs.loadmore.onBottomLoaded();
      }, 1500);
    },
    GetMoney(money) {
      this.$router.push({
        path: "/my/GetMoney",
        query: { money: money, accounts: this.check_login().accounts }
      });
    }
  }
};
</script>
<style scoped>
.AwardList {
  min-height: 100%;
  background-color: #fff;
}

.AwardList .page-content .summary li {
  width: 33.333%;
  float: left;
  text-align: center;
  margin-top: 20px;
}

.AwardList .page-content .summary li p {
  height: 1.1rem;
}

.AwardList .page-content .summary li span {
  font-size: 0.5rem;
  color: #ccc;
}
</style>