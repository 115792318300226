<template>
  <div class="activity_box grayness" v-title data-title="景区活动">
    <mt-header fixed title="景区活动">
      <a slot="left">
        <mt-button icon="back" @click.native="$router.go(-1)"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="activitylist">
        <div class="item pic-wide" v-for="(k, n) in list" :key="n">
          <router-link :to="{path:'/activity/ActivityDetail',query:{aid:k.id}}"></router-link>
          <div class="pic">
            <img :src="k.theme_img_1" :alt="k.activity_name" />
          </div>
          <div class="txt">
            <span>{{k.activity_name}}</span>
            <span>开始时间：{{k.activity_begin_time}}&nbsp;结束时间：{{k.activity_begin_time}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      this.$ajax("post", "Park/clickHotActivity", "", ret => {
        console.log(ret);
        if (ret.data.id == 0) {
          this.list = ret.data.data;
        } else {
          $(".activitylist").html("<p class='err'>没有获取到服务器内容</p>");
        }
      });
    }
  }
};
</script>
<style scoped>
.activity_box .page-content {
  padding-bottom: 0;
}
.activity_box {
  background-color: #fff;
}
.activitylist .item {
  margin-top: 8px;
  padding: 0 14px 12px;
  position: relative;
}
.activitylist .item a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.activitylist .pic-wide .pic img {
  border-radius: 4px;
  width: 100%;
  display: block;
}
.activitylist .item .txt {
  font-size: 0.6rem;
  line-height: 0.8rem;
  color: #666;
  padding: 0.8rem 0.6rem 0.4rem;
  border: 1px solid #f2f2f2;
  border-top: 0;
}
.activitylist .item .txt span {
  color: #2c2c2c;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.activitylist .err {
  margin: 20px;
  text-align: center;
  color: #ccc;
}
</style>