<template>
  <div class="grayness" v-title :data-title="title">
    <mt-header fixed :title="title">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="message-list">
        <div v-if="info != '' ">
          <mt-loadmore
            :bottom-method="loadBottom"
            :bottom-all-loaded="allLoaded"
            ref="loadmore"
            :auto-fill="false"
          >
            <div class="user-card clearfix" v-for="(k,i) in info" :key="k.id" :id="k.id">
              <div class="avatar">
                <router-link
                  :to="{name:'otherIndex', params: { uid: k.user_id }}"
                  v-if="k.user_id != user_id"
                >
                  <img :src="k.user_icon" />
                </router-link>
                <router-link
                  :to="{name:'MyIndex', params: { uid: k.user_id }}"
                  v-if="k.user_id == user_id"
                >
                  <img :src="k.user_icon" />
                </router-link>
              </div>
              <div class="name">
                <span>
                  <router-link
                    :to="{name:'otherIndex', params: { uid: k.user_id }}"
                    v-if="k.user_id != user_id"
                  >{{k.user_nick}}</router-link>
                  <router-link
                    :to="{name:'MyIndex', params: { uid: k.user_id }}"
                    v-else
                  >{{k.user_nick}}</router-link>
                </span>
                <div class="k-content">
                  <div class="text">{{k.user_signature}}</div>
                  <div class="count">
                    <span>粉丝：{{k.fansi}}</span>
                    <span>关注：{{k.focus}}</span>
                  </div>
                </div>
              </div>
              <div
                class="btn fr atte no-each"
                :id="k.user_id"
                v-if="k.cancel_attention == 1"
                @click="attention(k.user_id,i)"
              >已关注</div>
              <div
                class="btn fr atte followed"
                :id="k.user_id"
                v-if="k.cancel_attention == 2  && k.user_id != user_id"
                @click="attention(k.user_id,i)"
              >互相关注</div>
              <div
                class="fr atte attention"
                :id="k.user_id"
                v-if="k.cancel_attention == 0"
                @click="attention(k.user_id,i)"
              >+关注</div>
            </div>
          </mt-loadmore>
        </div>
        <div class="no-data" v-if="info == '' ">你还没有粉丝~</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      my_id: "",
      user_id: "",
      info: [],
      type: this.$route.query.type,
      page: 1,
      allLoaded: false,
      title: "我的粉丝"
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      this.getData(this.page);
    },
    getData(page) {
      if (this.check_login()) {
        this.my_id = this.check_login().user_id;
      }
      if (this.type == "tafans") {
        this.user_id = this.$route.query.user_id;
        this.title = "他的粉丝";
      } else {
        this.user_id = this.check_login().user_id;
      }
      this.$ajax(
        "post",
        "foot/getMyFansi",
        { user_id: this.user_id, my_id: this.my_id, page: page },
        res => {
          if (res.data.id == 0) {
            this.info = this.info.concat(res.data.data);
          }
        }
      );
    },
    //加载更多
    loadBottom() {
      var that = this;
      var itemLen = that.info.length;
      setTimeout(() => {
        if (itemLen % 10 == 0) {
          that.page++;
          that.getData(that.page);
        } else {
          that.allLoaded = true;
          Toast("没有更多了");
        }
        that.$refs.loadmore.onBottomLoaded();
      }, 1500);
    },
    attention(user_id, i) {
      this.$ajax(
        "post",
        "foot/clickAttentionButton",
        {
          user_id: this.my_id,
          attention_id: user_id
        },
        res => {
          if (res.data.id == 0) {
            if (res.data.data) {
              if (res.data.data.each == 2) {
                this.$set(this.info[i], "cancel_attention", 2);
              } else if (res.data.data.each == 1) {
                this.$set(this.info[i], "cancel_attention", 1);
              } else if (res.data.data.each == 0) {
                this.$set(this.info[i], "cancel_attention", 0);
              }
            } else {
              this.$set(this.info[i], "cancel_attention", 0);
            }
          }
        }
      );
    }
  }
};
</script>
<style scoped>
.grayness {
  min-height: 100%;
  background-color: #fff;
}

.page-content {
  padding-bottom: 0;
}

.k-content {
  font-size: 12px;
  color: #999;
}

.k-content span {
  margin-right: 10px;
}
</style>