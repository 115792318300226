<template>
  <div class="container" data-title="泥河湾人文历史" v-title v-show="loaded">
    <img src="../../assets/nihewan/left-bottom.png" class="bg" />
    <header class="header">
      <i class="back iconfont iconmenu2" @click="goBack"></i>
      <div class="search-handle">
        <i class="iconfont iconsearch"></i>
        <input class="inp" type="text" placeholder="搜人文历史" ref="search" v-model="searchValue" />
      </div>
      <i class="iconfont cancle iconclose" @click="searchValue=''" v-show="searchValue"></i>
    </header>

    <swiper class="main-swiper-container" :options="swiperOption" ref="mainSwiper">
      <swiper-slide class="swiper-slide" v-for="(item, i) in filterBySearch(rootList)" :key="i">
        <div class="content" @click="navToRoot(item.id)">
          <img :src="item.newUrl" class="img" />
          <div class="detail-btn">
            <span class="text">查看详情</span>
            <i class="iconfont iconmove-right"></i>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "swiper/vue";
import { Toast, Indicator } from "mint-ui";
import TechnicalSupport from "../../components/TechnicalSupport";
export default {
  components: {
    swiper,
    swiperSlide,
    "technical-support": TechnicalSupport
  },
  data() {
    return {
      swiperOption: {
        autoplay: false,
        loop: false,
        speed: 300,
        pagination: {
          el: ".swiper-pagination"
        },
        spaceBetween: 40
      },

      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanRootList",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      // 获取数据
      rootList: [],
      // 自定义
      page: 1,
      isLoading: false,
      loaded: false,
      haveMore: true,
      activeIndex: 0,
      searchValue: "",

      // company
      companyInfo: {},
      haveCompany: false
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
    }

    this.getData();
    this.getCompanyInfo(this.id, this.token).then(
      res => {
        this.companyInfo = res;
        this.haveCompany = true;
      },
      err => {
        console.log(err);
      }
    );
  },
  watch: {
    activeIndex: function(value) {
      if (value % 10 === 9 && this.haveMore && this.page > 1) {
        this.getData();
      }
    }
  },
  methods: {
    // 人文
    navToRoot(id) {
      this.$router.push({
        path: "/nihewan/root",
        query: {
          id: this.id,
          aid: id
        }
      });
    },
    // 上一级
    goBack() {
      this.$router.push({
        path: "/nihewan/home",
        query: {
          id: this.id
        }
      });
    },
    // 根据搜索值返回数组
    filterBySearch(ls) {
      return ls.filter(item => {
        return item.title.match(this.searchValue);
      });
    },
    // 获取数据
    getData() {
      if (!this.haveMore || this.isLoading) {
        return false;
      }
      this.isLoading = true;
      Indicator.open("加载中...");
      this.$ajax(
        "post",
        "Label/get_science_data",
        {
          park_id: this.park_id,
          id_: this.id,
          id_type_: this.type_,
          where_: "history", // spots, history, books, video, question, activity
          page: this.page,
          token: this.token,
          version: this.version
        },
        res => {
          if (res.data.id == 0) {
            if (this.page === 1) {
              this.rootList.push(...res.data.data.root_list);
              this.$nextTick(() => {
                const mainSwiper = this.$refs.mainSwiper.swiper;

                mainSwiper.on("slideChange", () => {
                  this.activeIndex = mainSwiper.activeIndex;
                });
              });
            } else {
              this.rootList.push(...res.data.data.list);
            }
            this.page++;

            const sUrl = window.location.href,
              wx_title = res.data.data.root_list[0].title,
              wx_content = res.data.data.root_list[0].content,
              wx_img = res.data.data.root_list[0].newUrl;
            if (this.is_weixin) {
              this.wx_share_callback(sUrl, {
                url: sUrl,
                id_: this.id,
                id_type_: this.backUrl,
                token: this.token,
                open_id: this.open_id,
                title: wx_title,
                content: wx_content,
                img: wx_img
              });
            }
          }
          if (
            res.data.id !== 0 ||
            (this.page === 1 &&
              (!res.data.data.root_list ||
                res.data.data.root_list.length < 10)) ||
            (this.page > 1 &&
              (!res.data.data.list || res.data.data.list.length < 10))
          ) {
            this.haveMore = false;
          }
          this.isLoading = false;
          Indicator.close();
          if (!this.loaded) {
            this.loaded = true;
          }
        }
      );
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(243, 245, 248);
  overflow: hidden;
}

.header {
  position: fixed;
  height: 2.8rem;
  width: 100%;
  padding: 0.4rem 0.8rem 0.4rem 0.4rem;
  display: flex;
  box-sizing: border-box;
  box-shadow: 0 2px 2px -1px rgba(211, 211, 211, 0.4);
  background-color: #fff;
  z-index: 1;
}

.header > .back {
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}

.search-handle {
  flex: 1;
  height: 100%;
  padding-left: 1rem;
  display: flex;
  border-radius: 2em;
  line-height: 2rem;
  font-size: 0.8rem;
  color: #666;
  box-sizing: border-box;
  background-color: rgb(243, 245, 248);
}

.search-handle > .inp {
  flex: 1;
}

.search-handle > .iconfont {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  color: #999;
}

.header > .iconclose {
  position: absolute;
  top: 0.4rem;
  right: 0.8rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  font-size: 0.8rem;
  color: #666;
}

.bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 10rem;
}

/* subswipe */
.sub-swiper-container {
  width: 100%;
  height: 100%;
}

.sub-swiper-container .swiper-slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.88rem;
}

.sub-swiper-container .swiper-slide-active {
  color: #01c2db;
}

.sub-swiper-container .swiper-slide-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 4px;
  background-color: #01c2db;
}

/* swipe */
.main-swiper-container {
  margin-top: 4rem;
  width: 66vw;
  height: 99vw;
  padding: 2rem 0;
  overflow: visible;
}

.main-swiper-container .swiper-slide {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
}

.main-swiper-container .swiper-slide-active {
  transform: scale(1.1);
  transition: 0.5s;
}

.main-swiper-container .swiper-slide .content {
  position: relative;
  width: 100%;
  height: 100%;
}

.main-swiper-container .swiper-slide .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.main-swiper-container .swiper-slide .detail-btn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  padding: 2px 6px;
  border: 1px solid #81f0ff;
  border-radius: 2px;
  font-size: 0.68rem;
  color: #81f0ff;
  filter: drop-shadow(-1px 1px 2px #000);
}

.main-swiper-container .swiper-slide .detail-btn > .iconfont {
  vertical-align: middle;
  font-size: 0.68rem;
}

.main-swiper-container .swiper-pagination {
  bottom: -1rem;
}

.main-swiper-container .swiper-pagination >>> .swiper-pagination-bullet {
  width: 24px;
  height: 4px;
  border-radius: 0;
  opacity: 0.1;
}

.main-swiper-container .swiper-pagination >>> .swiper-pagination-bullet-active {
  background-color: #08d3ee;
  opacity: 1;
}

.company {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 1rem;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 0.74rem;
}
</style>