<template>
  <div class="body_order">
    <mt-header fixed :title="title">
      <a slot="left">
        <mt-button @click="$router.go(-1)" icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="head">
      <div class="menu" :class="index == 0 ? 'red_':'' " @click="select_menu('all',0)">
        <span>全部</span>
      </div>
      <div
        class="menu"
        :class="index == 1 ? 'red_':'' "
        style="margin-left: 1%;"
        @click="select_menu('no_pay',1)"
      >
        <span>未支付</span>
      </div>
      <div
        class="menu"
        :class="index == 2 ? 'red_':'' "
        style="margin-left: 1%;"
        @click="select_menu('yes_pay',2)"
      >
        <span>已支付</span>
      </div>
      <div
        class="menu"
        :class="index == 3 ? 'red_':'' "
        style="margin-left: 1%;"
        @click="select_menu('no_status',3)"
      >
        <span>未核销</span>
      </div>
      <div
        class="menu"
        :class="index == 4 ? 'red_':'' "
        style="margin-left: 1%;"
        @click="select_menu('yes_status',4)"
      >
        <span>已核销</span>
      </div>
    </div>
    <div class="list" v-if="list">
      <div class="item" v-for="(k,i) in list" :key="i">
        <div class="head">
          <div class="img">
            <i class="iconfont iconicon-order"></i>
          </div>
          <div class="order_id">
            <span>订单编号:{{k.order_id}}</span>
          </div>
        </div>
        <div class="body">
          <div class="img">
            <img :src="k.newUrl" />
          </div>
          <div class="title">
            <span>{{k.title}}</span>
          </div>
          <div class="tel">
            <img src="../../assets/img/call.png" />
          </div>
        </div>

        <div class="info">
          <div class="span_">
            <div class="left_code">
              <span>兑换码：</span>
            </div>
            <div class="right_value">
              <span>{{k.code}}</span>
            </div>
          </div>
          <div class="span_">
            <div class="left_code">
              <span>总金额：</span>
            </div>
            <div class="right_value">
              <span>￥{{k.amount}}</span>
            </div>
          </div>
          <div class="span_">
            <div class="left_code">
              <span>购买件数：</span>
            </div>
            <div class="right_value">
              <span>{{k.numbers}}</span>
            </div>
          </div>
          <div class="span_">
            <div class="left_code">
              <span>下单时间：</span>
            </div>
            <div class="right_value">
              <span>{{k.add_time}}</span>
            </div>
          </div>
          <div class="span_">
            <div class="left_code">
              <span>核销人：</span>
            </div>
            <div class="right_value">
              <span>{{k.check_user}}</span>
            </div>
          </div>
          <div class="span_">
            <div class="left_code">
              <span>核销时间：</span>
            </div>
            <div class="right_value">
              <span>{{k.check_time}}</span>
            </div>
          </div>
        </div>
        <div class="pay_status">
          <div class="img">
            <i class="iconfont icontime"></i>
          </div>
          <div class="text">
            <span>{{k.is_pay_text}}|{{k.is_status_text}}</span>
          </div>
        </div>
      </div>
    </div>
    <!--点击加载更多按钮-->
    <div class="click-load-more" @click="click_load_more">
      <div class="load-more-text">点击加载更多</div>
      <div class="load-more-img">
        <img src="../../assets/img/click-load-more.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
export default {
  components: {},
  data() {
    return {
      page: 1,
      list: [],
      all_list: [],
      no_pay_list: [],
      yes_pay_list: [],
      no_status_list: [],
      yes_status_list: [],
      index: 0,
      type_: "all",
      user_id: "",
      title: "订单"
    };
  },
  mounted() {
    this.get_server_data();
  },
  methods: {
    //请求数据
    get_server_data() {
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "确认登陆吗？",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            this.get_User_Id();
          }
        });
      } else {
        var user_id = this.check_login().user_id;
        this.user_id = user_id;
        var page = this.page;
        if (user_id) {
          this.$ajax(
            "post",
            "weixin/get_park_spots_order_list",
            { user_id: user_id, token: "zmlxj_032as", page: page },
            ret => {
              if (ret.data.id == 0) {
                this.list = ret.data.data.all_list;
                this.all_list = ret.data.data.all_list;
                this.no_pay_list = ret.data.data.no_pay_list;
                this.yes_pay_list = ret.data.data.yes_pay_list;
                this.no_status_list = ret.data.data.no_status_list;
                this.yes_status_list = ret.data.data.yes_status_list;
              } else {
                Toast("没有订单");
              }
            }
          );
        } else {
          Toast("请登录");
        }
      }
    },
    select_menu(type_, ind) {
      this.index = ind;
      this.type_ = type_;
      if (type_ == "all") {
        this.list = this.all_list;
      } else if (type_ == "no_pay") {
        this.list = this.no_pay_list;
      } else if (type_ == "yes_pay") {
        this.list = this.yes_pay_list;
      } else if (type_ == "no_status") {
        this.list = this.no_status_list;
      } else if (type_ == "yes_status") {
        this.list = this.yes_status_list;
      }
    },
    click_load_more() {
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "确认登陆吗？",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            this.get_User_Id();
          }
        });
      } else {
        var user_id = this.check_login().user_id;
        var page = ++this.page;
        var type_ = this.type_;
        if (type_ && user_id) {
          this.$ajax(
            "post",
            "weixin/get_park_spots_order_list",
            {
              user_id: user_id,
              token: "zmlxj_032as",
              page: page,
              where: type_
            },
            ret => {
              if (ret.data.id == 0) {
                this.list = this.list.concat(ret.data.data);
                if (type_ == "all") {
                  this.all_list = this.all_list.concat(ret.data.data);
                } else if (type_ == "no_pay") {
                  this.no_pay_list = this.no_pay_list.concat(ret.data.data);
                } else if (type_ == "yes_pay") {
                  this.yes_pay_list = this.yes_pay_list.concat(ret.data.data);
                } else if (type_ == "no_status") {
                  this.no_status_list = this.no_status_list.concat(
                    ret.data.data
                  );
                } else if (type_ == "yes_status") {
                  this.yes_status_list = this.yes_status_list.concat(
                    ret.data.data
                  );
                }
              } else {
                Toast("没有订单");
              }
            }
          );
        }
      }
    }
  }
};
</script>

<style scoped>
.body_order {
  display: block;
  position: relative;
  width: 98%;

  margin-left: 1%;
  background-color: #f5f5f5;
  height: 100%;
}
.body_order .head {
  display: block;
  margin-left: 1%;
  width: 100%;
  margin-top: 3%;
}
.body_order .head .menu {
  position: relative;
  width: 18%;
  float: left;
}
.body_order .head .menu span {
  display: block;
  padding: 8px;

  background-color: white;
  text-align: center;
}
.red_ {
  color: red;
  text-decoration: underline;
}
.body_order .list {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 1%;
}
.body_order .list .item {
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: white;
  margin-bottom: 4%;
}
.body_order .list .item .head {
  height: 30px;
  border-bottom: 1px solid #cccccc;
}
.body_order .list .item .head .img {
  position: relative;
  float: left;
}
.body_order .list .item .head .img .iconfont {
  font-size: 11px;
}
.body_order .list .item .head .order_id {
  position: relative;
  float: left;
  width: 87%;
  margin-left: 4px;
}
.body_order .list .item .body {
  display: inline-block;
  width: 100%;
  margin-top: 1%;
  border-bottom: 1px #cccccc dashed;
}
.body_order .list .item .body .img {
  position: relative;
  width: 40px;
  float: left;
}
.body_order .list .item .body .img img {
  width: 100%;
}
.body_order .list .item .body .title {
  position: relative;
  float: left;
  width: 80%;
}
.body_order .list .item .body .title span {
  display: block;
  padding: 4px;
  text-align: left;
  font-size: 0.6rem;
}
.body_order .list .item .body .tel {
  position: relative;
  float: left;
  margin-top: 1.5%;
  width: 4%;
}
.body_order .list .item .body .tel img {
  width: 100%;
}
.body_order .list .item .info {
  position: relative;
  width: 100%;
  float: left;
}
.body_order .list .item .info .span_ {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
.body_order .list .item .info .span_ .left_code {
  position: relative;
  width: 50%;
  float: left;
}
.body_order .list .item .info .span_ .left_code span {
  display: block;
  padding: 10px;
  text-align: left;
}
.body_order .list .item .info .span_ .right_value {
  position: relative;
  width: 50%;
  float: left;
}
.body_order .list .item .info .span_ .right_value span {
  display: block;
  padding: 10px;
  text-align: right;
}
.body_order .list .item .pay_status {
  display: inline-block;
  position: relative;
  width: 100%;
}
.body_order .list .item .pay_status .img {
  position: relative;
  float: left;
  width: 40%;
  margin-top: 2%;
}
.body_order .list .item .pay_status .img .iconfont {
  font-size: 22px;
  float: right;
}
.body_order .list .item .pay_status .text {
  position: relative;
  float: left;
  width: 60%;
}
.body_order .list .item .pay_status .text span {
  display: block;
  padding: 10px;
  text-align: left;
}
</style>