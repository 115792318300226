<template>
  <div class="ol-body" v-if="is_show == 1" v-title :data-title="title">
    <div class="ol-menu">
      <div class="ol-m-item" @click="ClickActivityList">
        <span>预约路线</span>
      </div>
      <div class="ol-m-item" @click="ClickShowWxacode">
        <span>预约民宿</span>
      </div>
      <div class="ol-m-item" @click="ClickMyOrderList">
        <span>我的预约订单</span>
      </div>
    </div>
    <img id="img_" :src="info.bgurl" />
  </div>
  <div class="ol-body" v-else v-title :data-title="title">
    <div class="ol-b-close" @click="ClickShowWxacode">
      <i class="iconfont iconclose"></i>
    </div>
    <div class="ol-b-img">
      <img :src="info.wxacode" />
    </div>
    <div class="ol-b-text">
      <span>长按识别二维码,进行预约</span>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
import AlloyFinger from "alloyfinger";
export default {
  data() {
    return {
      title: "美丽乡村",
      park_id: this.$route.query.park_id,
      info: "",
      width_: 200,
      height_: 200,
      angle_: 0,

      initScale: 1,
      x_: 0,
      y_: 0,
      is_show: 1 //显示二维码信息
    };
  },
  computed: {
    imgStyle() {
      var tempObj = {
        transform:
          "scale(" +
          this.initScale +
          ") translate(" +
          this.x_ +
          "px ," +
          this.y_ +
          "px)"
      };
      return tempObj;
    }
  },
  mounted() {
    this.get_park_list();
  },
  methods: {
    get_park_list() {
      var data_ = {
        park_id: this.park_id,
        token: "zmlxj_2019_wuhanlvyouxiehui"
      };

      this.$ajax("post", "Other/get_park_list", data_, ret => {
        if (ret.data.id == 0) {
          this.info = ret.data.data.park;
        } else {
          //没有数据
          Toast("没有数据");
        }
      });
    },
    /*显示二维码*/
    ClickShowWxacode() {
      if (this.is_show) {
        this.is_show = 0;
      } else {
        this.is_show = 1;
      }
    },
    /*路线列表*/
    ClickActivityList() {
      this.$router.push({
        path: "/wuhanlvyouxiehui/wx_activity_list",
        query: { park_id: this.park_id }
      });
    },
    /**预约的订单**/
    ClickMyOrderList() {
      this.$router.push({
        path: "/wuhanlvyouxiehui/wx_order_list",
        query: { park_id: this.park_id }
      });
    }
  }
};
</script>

<style scoped>
.ol-body {
  display: inline-block;
  position: relative;

  width: 100%;
  height: 100%;
  background-color: white;
}
.ol-body .ol-menu {
  position: absolute;
  top: 55%;
  margin-left: 10%;
}
.ol-body img {
  width: 100%;
  height: 100%;
}
.ol-body .ol-menu .ol-m-item {
  display: inline-block;
  position: relative;
  width: 60%;
  margin-left: 20%;
  margin-bottom: 2%;
}
.ol-body .ol-menu .ol-m-item span {
  display: block;
  padding: 10px;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
}
.ol-body .ol-b-close {
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: right;
  margin-top: 2%;
  margin-right: 2%;
}
.ol-body .ol-b-close > .iconfont {
  font-size: 40px;
  color: rgb(244, 107, 107);
}
.ol-body .ol-b-img {
  display: inline-block;
  position: relative;
  width: 60%;
  margin-left: 20%;
  text-align: center;
  margin-top: 55%;
}
.ol-body .ol-b-img img {
  width: 100px;
  height: 100px;
}
.ol-body .ol-b-text {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 4%;
}
.ol-body .ol-b-text span {
  display: block;
  padding: 10px;
  font-size: 1rem;
  text-align: center;
}
/*图片放大缩小*/
.finger-demo {
  width: 100%;
  text-align: center;
}
.finger-demo-img {
  width: 80%;
}
</style>