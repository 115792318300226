import { render, staticRenderFns } from "./GuideOrderPay.vue?vue&type=template&id=725bb45a&scoped=true&"
import script from "./GuideOrderPay.vue?vue&type=script&lang=js&"
export * from "./GuideOrderPay.vue?vue&type=script&lang=js&"
import style0 from "./GuideOrderPay.vue?vue&type=style&index=0&id=725bb45a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "725bb45a",
  null
  
)

export default component.exports