<template>
  <div class="container" v-title data-title="我的">
    <div class="footer-menu">
      <div class="item" @click="handleMenu('map')">
        <i class="iconfont iconzuji"></i>
        <span class="text">地图</span>
      </div>
      <div class="item" @click="handleMenu('impression')">
        <i class="iconfont icondetail-copy"></i>
        <span class="text">科普</span>
      </div>
      <div class="item active">
        <i class="iconfont iconicon-user"></i>
        <span class="text">我的</span>
      </div>
    </div>
    <div class="header">
      <div class="bg">
        <img class="img" src="../../assets/nihewan/bg-my.jpg" />
      </div>
      <div class="user">
        <img class="img icon" :src="user.user_icon" />
        <span class="text nick">{{user.user_nick}}</span>
        <span class="text welcome">泥河湾国家级自然保护区欢迎您~</span>
      </div>
    </div>

    <div class="content">
      <div class="form">
        <div class="item">
          <div class="icon">
            <i class="iconfont icondetail-copy"></i>
          </div>
          <button class="btn nav" form-type="submit" @click="navToMyActivity">
            <span class="text">我的活动</span>
            <i class="iconfont iconqianjin"></i>
          </button>
        </div>
      </div>

      <div class="form">
        <div class="item">
          <div class="icon">
            <i class="iconfont iconzuji"></i>
          </div>
          <button class="btn nav" form-type="submit" @click="navToMyFootprint">
            <span class="text">我的足迹</span>
            <i class="iconfont iconqianjin"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator, MessageBox } from "mint-ui";
import TechnicalSupport from "../../components/TechnicalSupport";
export default {
  components: {
    "technical-support": TechnicalSupport
  },
  data() {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || "1837",
      version: "1.0.0",
      type_: "label",
      backUrl: "nihewanMy",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id_" + this.$route.query.id)
        ? localStorage.getItem("open_id_" + this.$route.query.id)
        : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      // 数据
      user: {},

      // company
      companyInfo: {},
      haveCompany: false
    };
  },
  mounted() {
    if (this.token) {
      //已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id_" + this.id, this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id_" + this.id, "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id_" + this.id, "");
      }
    }
    if (!this.open_id) {
      this.authorize();
    }

    this.checkLogin();
    if (this.is_weixin && this.id == "1837") {
      this.dealWithUrl(window.location.href);
      const sUrl = "https://m.zmlxj.com/#/nihewan/home?id=" + this.id,
        wx_title = "泥河湾国家自然保护区",
        wx_content = "泥河湾国家自然保护区",
        wx_img = "";
      this.wx_share_callback(sUrl, {
        url: sUrl,
        id_: this.id,
        id_type_: this.backUrl,
        token: this.token,
        open_id: this.open_id,
        title: wx_title,
        content: wx_content,
        img: wx_img
      });
    };

    this.getCompanyInfo(this.id, this.token).then(
      res => {
        this.companyInfo = res;
        this.haveCompany = true;
      },
      err => {
        console.log(err);
      }
    );
  },
  methods: {
    // 底部导航
    handleMenu(type) {
      if (type === "impression") {
        this.$router.push({
          path: "/nihewan/impression",
          query: {
            id: this.id
          }
        });
      } else if (type === "map") {
        this.$router.push({
          path: "/nihewan/map",
          query: {
            id: this.id
          }
        });
      }
    },

    // 我的足迹
    navToMyFootprint() {
      this.$router.push({
        path: "/nihewan/myFootprint",
        query: {
          id: this.id
        }
      });
    },
    // 我的活动
    navToMyActivity() {
      this.$router.push({
        path: "/nihewan/myActivity",
        query: {
          id: this.id
        }
      });
    },
    // 登录
    checkLogin() {
      if (!this.check_login()) {
        MessageBox.alert("请先登录").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
          }
        });
      } else {
        this.user = this.check_login();
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=" +
        this.id +
        "&token=" +
        this.token +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    }
  }
};
</script>

<style scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  padding: 2rem 1rem 2.8rem;
  box-sizing: border-box;
  background: linear-gradient(
    rgb(227, 206, 158) 0,
    rgb(227, 206, 158) 5rem,
    #fff 0
  );
}

.back {
  position: fixed;
  top: 0.4rem;
  left: 0.4rem;
  height: 1.6rem;
  width: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.footer-menu {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 2.8rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 -2px 2px -2px rgba(0, 0, 0, 0.2);
}

.footer-menu > .item {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 0.74rem;
  cursor: pointer;
}

.footer-menu > .item > .iconfont {
  font-size: 1rem;
}

.footer-menu > .active {
  color: #40d9ff;
}

.header {
  position: relative;
  width: 100%;
  padding: 1.5rem;
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.header > .bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
}

.header > .bg > .img {
  width: 100%;
  height: 100%;
}

.header > .user {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.header > .user > .img {
  display: block;
  height: 3.8rem;
  width: 3.8rem;
  border: 4px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  border-radius: 50%;
}

.header > .user > .text {
  margin-top: 0.5rem;
}

.header > .user > .nick {
  font-size: 0.88rem;
}

.header > .user > .welcome {
  font-size: 0.8rem;
  color: #e6a23c;
}

/* content */

.content {
  width: 100%;
  margin-top: 2rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
}

.content > .form {
  width: 100%;
}

.item {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid transparent;
}

.item .icon {
  margin-right: 0.5rem;
  width: 0.88rem;
  height: 40px;
  display: flex;
  align-items: center;
}

.item .icon > .img {
  width: 100%;
  height: 0.88rem;
}

.item .nav {
  flex: 1;
  width: 100%;
  height: 40px;
  min-height: 40px;
  line-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(243, 245, 247);
  box-sizing: border-box;
  font-size: 0.8rem;
  background-color: #fff;
  cursor: pointer;
}

.item .nav > .img {
  width: 0.88rem;
  height: 0.88rem;
}

.company {
  position: absolute;
  bottom: 3.8rem;
  left: 0;
  width: 100%;
  padding-top: 1rem;
  color: rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 0.72rem;
}
</style>