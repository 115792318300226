/* eslint-disable no-undef */
import Vue from 'vue'
import { Toast, Indicator } from 'mint-ui'

import footprintRed from '../../assets/img/footprint_red.png' //  红包
import footprintGifts from '../../assets/img/footprints_gifts.png' //  礼物
import footprintCoupon from '../../assets/img/footprint_coupon.png' //  卷

import mapGeo11 from '../img/mark_geo.png' //  地质
import mapPeople12 from '../img/mark_people.png' //  人文
import mapLeft13 from '../img/mark_life.png' //  生物
import mapPlant14 from '../img/mark_plant.png' //  植物
import mapStop15 from '../img/mark_stop.png' //  停车场
import mapticket16 from '../img/mark_ticket.png' //  售票厅

import mapScensMak from '../img/map_scens_mak.png' //  景
import mapEatMak from '../img/map_eat_mak.png' //  吃
import mapCeasMak from '../img/map_ceas_mak.png' //  住
import mapBuyMak from '../img/map_buy_mak.png' //  购
import mapFootMak from '../img/map_foot_mak.png' //  足迹
import mapLavatMak from '../img/map_lavat_mak.png' //  厕所
import mapLordPark from '../img/map_lord_park.png' //  主景点
import activityPersonage from '../img/activity_personage.png' //  活动-餐馆
import activityRestaurant from '../img/activity_restaurant.png' //  活动-个人

import logoIcon from '../img/map_logo.png' //  logo

import origin from '../img/origin.png' //  起点
import emphasis from '../img/emphasis.png' //  终点
import mark from '../img/mark.png' //  mak
import landmark from '../img/landmark.png'
import { AntPath, antPath } from 'leaflet-ant-path'
var markerArr = []
var numMarker = {}
/**
 * 初始化地图对象，加载地图 返回一个地图对象
 */
Vue.prototype.map_init = function (map_ = '', centerLng = '', centerLat = '') {
  if (map_['map_'] && map_['map_'] != '') {
    //  eslint-disable-next-line no-undef
    var imageLayer = new AMap.ImageLayer({
      url: map_['map_'],
      //  eslint-disable-next-line no-undef
      bounds: new AMap.Bounds(
        [map_['left_lng'], map_['left_lat']], //  左下角
        [map_['right_lng'], map_['right_lat']] //  右上角
      ),
      zIndex: 100,
      opacity: 1,
      visible: true,
      zooms: [map_['min_zoom'] * 1, map_['max_zoom'] * 1]
    })

    var lng_ = (parseFloat(map_['left_lng']) + parseFloat(map_['right_lng'])) / 2
    var lat_ = (parseFloat(map_['left_lat']) + parseFloat(map_['right_lat'])) / 2
    if (map_['id'] == 1209) {
      //  eslint-disable-next-line no-undef
      var map = new AMap.Map('container', {
        resizeEnable: true,
        zoom: map_['init_zoom'],
        zIndex: 1,
        mapStyle: 'amap:// styles/whitesmoke', //  设置地图的显示样式
        layers: [
          //  eslint-disable-next-line no-undef
          new AMap.TileLayer(),
          imageLayer
        ],
        expandZoomRange: true, //  可以让 pc中的 maxzoom 达到20
        zooms: [map_['min_zoom'] * 1, map_['max_zoom'] * 1],
        center: [lng_, lat_]
      })
    } else {
      //  eslint-disable-next-line no-undef
      map = new AMap.Map('container', {
        resizeEnable: false,
        zoom: map_['init_zoom'],
        zIndex: 1,
        mapStyle: 'amap:// styles/whitesmoke', //  设置地图的显示样式
        layers: [
          //  eslint-disable-next-line no-undef
          new AMap.TileLayer(),
          imageLayer
        ],
        expandZoomRange: true,
        zooms: [map_['min_zoom'] * 1, map_['max_zoom'] * 1],
        center: [lng_, lat_]
      })
    }

    //  eslint-disable-next-line no-undef
    map.setLimitBounds(new AMap.Bounds(
      [map_['left_lng'], map_['left_lat']], //  左下角
      [map_['right_lng'], map_['right_lat']] //  右上角parseFloat(map_['right_lat']) + parseFloat(lat__)
    ))
  } else {
    if (centerLng && centerLat) {
      //  eslint-disable-next-line no-undef
      map = new AMap.Map('container', {
        resizeEnable: true,
        zoom: map_['init_zoom'],
        expandZoomRange: true,
        zooms: [map_['min_zoom'] * 1, map_['max_zoom'] * 1],
        center: [centerLng, centerLat]
      })
    } else {
      //  eslint-disable-next-line no-undef
      map = new AMap.Map('container', {
        resizeEnable: true,
        zoom: map_['init_zoom'] || 9,
        expandZoomRange: true,
        zooms: [map_['min_zoom'] * 1 || 9, map_['max_zoom'] * 1 || 21]
      })
    }
  }
  return map
}

/**
 * 第二种地图初始化的方法
 *
 */
Vue.prototype.gdMapInit = function (argument) {
  //  var that = this;
  console.log(argument)
  if (argument) {
    if (argument.map_['ImageUrl'] != '' && parseFloat(argument.map_['left_lng']) && parseFloat(argument.map_['left_lat']) &&
      parseFloat(argument.map_['right_lng']) && parseFloat(argument.map_['right_lat'])) {
      //  贴图
      var imageLayer = new AMap.ImageLayer({
        url: argument.map_['ImageUrl'],
        bounds: new AMap.Bounds(
          [argument.map_['left_lng'], argument.map_['left_lat']], //  左下角
          [argument.map_['right_lng'], argument.map_['right_lat']] //  右上角
        ),
        zIndex: 100,
        opacity: 1,
        visible: true,
        expandZoomRange: true,
        zooms: [1, 21]
      })

      //  切片图层
      /* var tile = new AMap.TileLayer({
          tileUrl:argument.map_['ImageUrl']+'?x=2y=2'// 图块取图地址
      }) */
      var lng_ = (parseFloat(argument.map_['left_lng']) + parseFloat(argument.map_['right_lng'])) / 2;
      var lat_ = (parseFloat(argument.map_['left_lat']) + parseFloat(argument.map_['right_lat'])) / 2;
      //  var lng__ = 0.004715
      //  var lat__ = 0.003427
      if (argument.map_['tile_list']) {
        var map = new AMap.Map('gd_map', {
          resizeEnable: false,
          zoom: 15,
          zIndex: 1,
          dragEnable: true,
          expandZoomRange: true,
          zooms: [15, 20],
          center: [lng_, lat_],
          features: []
        })

        var tile = ''
        tile = new AMap.TileLayer.Flexible({
          tileSize: 256,
          createTile: function (x, y, z, success, fail) {
            console.log('x=' + x + 'y=' + y + 'z=' + z)
            //  加载自定义的切片图片
            var c = document.createElement('canvas')
            c.width = c.height = 256

            var cxt = c.getContext('2d')

            //  创建新的图片对象
            var img = new Image()
            //  指定图片的URL
            img.src = argument.map_['ImageUrl']
            //  浏览器加载图片完毕后再绘制图片
            img.onload = function () {
              //  以Canvas画布上的坐标(10,10)为起始点，绘制图像
              cxt.drawImage(img, 0, 0)
            }
            //  通知API切片创建完成
            success(c)
          },
          cacheSize: 11256, //  内存中缓存的切片的数量上限
          zIndex: 2,
          expandZoomRange: true,
          zooms: [15, 20] //  设置可见级别，[最小级别，最大级别]
        })
        tile.setMap(map)
      } else if (argument.map_['draw_type'] == 1) {
        //  不存在瓦片拼接---------第一种直接贴手图
        map = new AMap.Map('gd_map', {
          resizeEnable: false,
          zoom: 15,
          zIndex: 1,
          view: new AMap.View2D({ //  创建地图二维视口
            center: [lng_, lat_], //  创建中心点坐标
            zoom: 12, //  设置地图缩放级别
            rotation: 0 //  设置地图旋转角度
          }),
          //  mapStyle: 'amap:// styles/518f22b378480937cc06e3aea051b8fd', 
          //  设置地图的显示样式
          layers: [
            //  new AMap.TileLayer(),
            imageLayer
          ],
          dragEnable: true,
          expandZoomRange: true,
          zooms: [15, 20],

          center: [lng_, lat_],
          features: []
        })
        var size = map.getSize() //  resize
        console.log(size)
      } else if (argument.map_['draw_type'] == 2) {
        //  -------------------第二种手动在绘制
        map = new AMap.Map('gd_map', {
          resizeEnable: false,
          zoom: 15,
          zIndex: 1,
          view: new AMap.View2D({ //  创建地图二维视口
            center: [lng_, lat_], //  创建中心点坐标
            zoom: 12, //  设置地图缩放级别
            rotation: 0 //  设置地图旋转角度
          }),
          dragEnable: true,
          expandZoomRange: true,
          zooms: [15, 20],
          center: [lng_, lat_],
          features: []
        })

        AMap.plugin(['AMap.CustomLayer'], function () {
          var canvas = document.createElement('canvas')
          canvas.width = argument.map_['width_']
          canvas.height = argument.map_['height_']

          var bounds = new AMap.Bounds(
            [argument.map_['left_lng'], argument.map_['left_lat']], //  左下角
            [argument.map_['right_lng'], argument.map_['right_lat']])
          var CanvasLayer = new AMap.CanvasLayer({
            bounds: bounds,
            canvas: canvas,
            map: map,
            visible: true,
            zIndex: 100
          })

          //  获取对应的CanvasRenderingContext2D对象(画笔)
          var ctx = canvas.getContext('2d')
          //  创建新的图片对象
          var img = new Image()
          //  指定图片的URL
          img.src = argument.map_['ImageUrl']
          //  浏览器加载图片完毕后再绘制图片
          img.onload = function () {
            //  以Canvas画布上的坐标(10,10)为起始点，绘制图像
            ctx.drawImage(img, 0, 0)
          }
          CanvasLayer.show()
          CanvasLayer.setMap(map)
        })
      } else {
        // ------------第三种绘制方式
        map = new AMap.Map('gd_map', {
          resizeEnable: false,
          zoom: 15,
          zIndex: 1,
          view: new AMap.View2D({ // 创建地图二维视口
            center: [lng_, lat_], // 创建中心点坐标
            zoom: 12, // 设置地图缩放级别
            rotation: 0 // 设置地图旋转角度
          }),
          dragEnable: true,
          expandZoomRange: true,
          zooms: [15, 20],
          center: [lng_, lat_],
          features: []
        })
        var canvas = document.createElement('canvas')
        canvas.width = argument.map_['width_']
        canvas.height = argument.map_['height_']

        var customLayer = new AMap.CustomLayer(canvas, {
          zooms: [3, 10],
          alwaysRender: true, // 缩放过程中是否重绘，复杂绘制建议设为false
          zIndex: 120,

        })

        customLayer.render = function () {
          var retina = AMap.Browser.retina;
          console.log("这是什么值：" + retina)
          var size = map.getSize() // resize
          console.log(size)
          var width = size.width;
          var height = size.height;
          canvas.style.width = width + 'px';
          canvas.style.height = height + 'px';
          if (retina) { // 高清适配
            width *= 2;
            height *= 2;
          }
          canvas.width = width;
          canvas.height = height; // 清除画布

          // 获取对应的CanvasRenderingContext2D对象(画笔)
          var ctx = canvas.getContext("2d")
          // 创建新的图片对象
          var img = new Image()
          // 指定图片的URL
          img.src = argument.map_['ImageUrl']
          // 浏览器加载图片完毕后再绘制图片
          img.onload = function () {
            // 以Canvas画布上的坐标(10,10)为起始点，绘制图像
            ctx.drawImage(img, -0, 0)
          }
        }
        customLayer.setMap(map)

      }
      map.setLimitBounds(new AMap.Bounds(
        [argument.map_['left_lng'], argument.map_['left_lat']], // 左下角
        [argument.map_['right_lng'], argument.map_['right_lat']] // 右上角parseFloat(map_['right_lat']) + parseFloat(lat__)
      ))


    } else {

      if (argument.centerLng && argument.centerLat) {
        var map = new AMap.Map("gd_map", {
          resizeEnable: true,
          expandZoomRange: true,
          zooms: [15, 20],
          zoom: 15,
          dragEnable: true,
          view: new AMap.View2D({ // 创建地图二维视口
            center: [argument.centerLng, argument.centerLat], // 创建中心点坐标
            zoom: 12, // 设置地图缩放级别
            rotation: 0 // 设置地图旋转角度
          }),
          center: [argument.centerLng, argument.centerLat]
        })
      } else {
        var min_zoom = argument.map_['min_zoom'] || 10, max_zoom = argument.map_['max_zoon'] || 20, init_zoom = argument.map_['init_zoom'] || 15;
        var map = new AMap.Map("gd_map", {
          resizeEnable: true,
          expandZoomRange: true,
          zooms: [min_zoom, max_zoom],
          zoom: init_zoom,
          dragEnable: true,
          //  mapStyle: 'amap:// styles/macaron', // 设置地图的显示样式
          features: ['bg', 'building', 'road', 'point']
        })
        console.log(map)
      }
    }
  } else {
    var map = new AMap.Map("gd_map", {
      resizeEnable: true,
      expandZoomRange: true,
      zooms: [15, 20],
      zoom: 15,
      dragEnable: true,
      mapStyle: 'amap:// styles/macaron', // 设置地图的显示样式
      features: ['bg', 'building', 'road', 'point']
    })
  }


  // 设置点聚合效果
  // *********************************
  /*
  argument.map = map;
  argument.cluster_type_ = 0;
  var that = this;
  that.gdAddCluster(argument)
  */
  // *********************************
  return map;
}

Vue.prototype.render = function () {
  console.log("到这里了=======")
}
/**
 * 点聚合的样式
 * {
 * type:0默认样式,1自定义图标,2完全自定
 * cluster:
 * callback:
 * }
 *
 */
Vue.prototype.gdAddCluster = function (argument) {
  var that = this;
  var cluster = '';
  console.log("点聚合：")
  console.log(argument)
  if (argument.cluster) {
    argument.cluster.setMap(null)
  }
  if (argument.cluster_type_ == 2) { // 完全自定义

    cluster = new AMap.MarkerClusterer(argument.map, argument.markers_cluster, {
      gridSize: 80,
      renderClusterMarker: that._renderClusterMarker
    })
  } else if (argument.cluster_type_ == 1) { // 自定义图标
    var sts = [{
      url: "https://a.amap.com/jsapi_demos/static/images/blue.png",
      size: new AMap.Size(32, 32),
      offset: new AMap.Pixel(-16, -16)
    }, {
      url: "https://a.amap.com/jsapi_demos/static/images/green.png",
      size: new AMap.Size(32, 32),
      offset: new AMap.Pixel(-16, -16)
    }, {
      url: "https://a.amap.com/jsapi_demos/static/images/orange.png",
      size: new AMap.Size(36, 36),
      offset: new AMap.Pixel(-18, -18)
    }, {
      url: "https://a.amap.com/jsapi_demos/static/images/red.png",
      size: new AMap.Size(48, 48),
      offset: new AMap.Pixel(-24, -24)
    }, {
      url: "https://a.amap.com/jsapi_demos/static/images/darkRed.png",
      size: new AMap.Size(48, 48),
      offset: new AMap.Pixel(-24, -24)
    }]
    cluster = new AMap.MarkerClusterer(argument.map, argument.markers_cluster, {
      styles: sts,
      gridSize: 80
    })
  } else { // 默认样式
    argument.map.plugin(["AMap.MarkerClusterer"], function () {
      cluster = new AMap.MarkerClusterer(argument.map, argument.markers_cluster, { gridSize: 80, maxZoom: 10 })
    })
  }
  if (argument.callback) {
    argument.callback({ cluster: cluster })
  }
}
/**
 * 定制化缩放的标签
 * @param context
 * @private
 */
Vue.prototype._renderClusterMarker = function (context) {
  // 取第一个
}
/**
 * 循环添加mak点
 * @param map               地图对象
 * @param list_data         传入渲染的点数据arr
 * @param type              点数据点击的效果
 *                          type=='user'=>  点击进入用户个人中心 !=>进足迹详情  判断显示的图标
 *                              =='park'=>  公园
 *                              =='img_num'=>   显示图标 编号
 *                              =='activity'=>  活动
 * @param callback          加完点之后的回调
 * @param aid               公园aid
 * @param click_callback    点击执行的事件
 * @param clearMap          是否清除地图
 * map, list_data, type, callback, aid, click_callback, clearMap
 */
Vue.prototype.map_add_Marker = function (argument) {
  var that = this;
  if (!argument.map.clearMap) {
    argument.map.clearMap()
  }
  // console.log(argument)
  argument.list_data.forEach(function (mak, i) {

    var icon = '';

    if (mak.is_other_icon == 1) {
      if (mak.type_ == 1) {
        icon = map_icon_label_1;
      } else if (mak.type_ == 2) {
        icon = map_icon_label_2;
      } else if (mak.type_ == 3) {
        icon = map_icon_label_3;
      } else if (mak.type_ == 4) {
        icon = map_icon_label_4;
      } else if (mak.type_ == 5) {
        icon = map_icon_label_5;
      } else if (mak.type_ == 6) {
        icon = map_icon_label_6;
      } else if (mak.type_ == 7) {
        icon = map_icon_label_7;
      } else if (mak.type_ == 8) {
        icon = map_icon_label_8;
      } else if (mak.type_ == 9) {
        icon = map_icon_label_9;
      } else if (mak.type_ == 10) {
        icon = map_icon_label_10;
      } else if (mak.type_ == 11) {
        icon = map_icon_label_11;
      } else if (mak.type_ == 12) {
        icon = map_icon_label_12;
      } else if (mak.type_ == 13) {
        icon = map_icon_label_13;
      } else if (mak.type_ == 14) {
        icon = map_icon_label_14;
      } else if (mak.type_ == 15) {
        icon = map_icon_label_15;
      } else if (mak.type_ == 16) {
        icon = map_icon_label_16;
      } else if (mak.type_ == 17) {
        icon = map_icon_label_17;
      } else if (mak.type_ == 18) {
        icon = map_icon_label_18;
      } else if (mak.type_ == 19) {
        icon = map_icon_label_19;
      } else if (mak.type_ == 20) {
        icon = map_icon_label_20;
      }

    } else {
      if (mak.is_spots == 11) {
        icon = mapGeo11;
      } else if (mak.is_spots == 12) {
        icon = mapPeople12;
      } else if (mak.is_spots == 13) {
        icon = mapLeft13;
      } else if (mak.is_spots == 14) {
        icon = mapPlant14;
      } else if (mak.is_spots == 15) {
        icon = mapStop15;
      } else if (mak.is_spots == 16) {
        icon = mapticket16;
      } else if (mak.is_spots == 19) {
        icon = mapScensMak;
      } else if (mak.is_spots == 18) {
        icon = mapEatMak
      } else if (mak.is_spots == 21) {
        icon = mapCeasMak
      } else if (mak.is_spots == 20) {
        icon = mapBuyMak
      } else if (mak.is_spots == 17) {
        icon = mapLavatMak
      } else if (mak.is_spots == 2) {
        icon = mapLordPark
      } else if (mak.is_spots == 3) {
        icon = mapFootMak
      } else if (mak.type == 154) {
        icon = footprintRed
      } else if (mak.type == 155) {
        icon = footprintCoupon
      } else if (mak.type == 156) {
        icon = footprintGifts
      } else if (mak.is_seller == 1) {
        icon = activityRestaurant
      } else if (mak.is_seller == 2) {
        icon = activityPersonage
      } else {
        icon = landmark
      }
    }
    if (argument.type == 'user') {
      numMarker = new AMap.Marker({
        map: argument.map,
        position: [mak['lng'], mak['lat']],
        offset: new AMap.Pixel(-12, -36),
        extData: mak,
        zIndex: 100,
        anchor: "bottom-center",
        content: "<div class='no_bg user_bg'><img src=" + mak.user_icon + " /></div>"
      })
    } else if (argument.type == 'park') {
      numMarker = new AMap.Marker({
        map: argument.map,
        position: [mak['lng'], mak['lat']],
        offset: new AMap.Pixel(-12, -36),
        extData: mak,
        zIndex: 100,
        anchor: "bottom-center",
        content: "<div>" + (i + 1) + "</div>"
      })

      var Label_name = "park_name";
      if (mak.radius) {
        that.map_new_Circle(argument.map, mak)
      }
    } else if (argument.type == 'img_num') {
      if (mak.img_numbers > 1) {
        var html = "<div class='img more_picture'><img src=" + mak.newUrl + " /><i>" + mak.img_numbers + "</i></div>"
      } else {
        var html = "<div class='img sola_picture'><img src=" + mak.newUrl + " /></div>"
      }
      numMarker = new AMap.Marker({
        map: argument.map,
        position: [mak['spot_lng'], mak['spot_lat']],
        offset: new AMap.Pixel(-24, -24),
        extData: mak,
        content: html,
        zIndex: 100,
        anchor: "bottom-center",
      })
    } else if (argument.type == 'activity') {
      numMarker = new AMap.Marker({
        map: argument.map,
        icon: new AMap.Icon({
          size: new AMap.Size(25, 25), // 图标大小
          image: icon
        }),
        zIndex: 100,
        anchor: "bottom-center",
        position: [mak.lng, mak.lat],
        extData: mak
      })
      var Label_name = "spots_name";
    } else {
      if (mak.lng) {
        var lng = mak.lng;
        var lat = mak.lat;
      } else {
        var lng = mak.spot_lng;
        var lat = mak.spot_lat;
      }
      if (mak['width_'] && mak['height_']) {
        var width_ = mak['width_']
        var height_ = mak['height_']
      } else {
        var width_ = 30;
        var height_ = 30;
      }
      if (mak['is_other_icon'] == 1) {
        numMarker = new AMap.Marker({
          map: argument.map,
          icon: new AMap.Icon({
            size: new AMap.Size(width_, height_), // 图标大小
            image: icon,
            imageSize: new AMap.Size(width_, height_)
          }),
          animation: 'AMAP_ANIMATION_DROP',
          anchor: "bottom-center",
          zIndex: 100,
          position: [lng, lat],
          extData: mak
        })
      } else {
        numMarker = new AMap.Marker({
          map: argument.map,
          icon: new AMap.Icon({
            size: new AMap.Size(width_, height_), // 图标大小
            image: icon,
            imageSize: new AMap.Size(width_, height_)
          }),
          anchor: "bottom-center",
          zIndex: 100,
          position: [lng, lat],
          extData: mak
        })
      }


      var Label_name = "spots_name";
    }

    if (mak.reward_list && mak.reward_list.length > 0) {
      var html = "<div>"
      for (var k = 0; k < mak.reward_list.length; k++) {
        if (mak.reward_list[k].status == 0) {
          if (mak.reward_list[k].is_send == 0) {
            html += "<p>" + mak.reward_list[k].title + " <span>未送到</span></p>"
          } else {
            html += "<p>" + mak.reward_list[k].title + " <span>已送到</span></p>"
          }
        } else {
          html += "<p>" + mak.reward_list[k].title + " <span>已领取</span></p>"
        }
      }
      html += "</div>"
      numMarker.setLabel({ // label默认蓝框白底左上角显示，样式className为：amap-marker-label
        offset: new AMap.Pixel(0, 36), // 修改label相对于maker的位置
        content: html
      })
    } else {
      if (mak['is_other_icon'] == 1) {

      } else {
        numMarker.setLabel({ // label默认蓝框白底左上角显示，样式className为：amap-marker-label
          offset: new AMap.Pixel(0, -20), // 修改label相对于maker的位置
          content: mak[Label_name]
        })
      }


    }
    // 绑定鼠标点击事件
    numMarker.on('click', function (e) {
      e.target.setTop(true)
      var data = e.target.getExtData()
      if (data.is_other_icon == 1 && 0) {
        // console.log(data)
        that.$router.push({
          path: '/parkPhoto/LabelDetail', // TA的足迹列表页面
          query: { id: data.id, title: data.spots_name, park_id: 1, park_logo: data.newUrl }
        })
      } else {
        if (argument.type == 'user') {
          that.$router.push({ name: 'otherIndex', params: { uid: data.user_id } })
        } else if (argument.type == 'park') {
          that.$router.push({
            path: '/parkPhoto/ParkMapLineList',
            query: { aid: argument.aid, park_id: data.id }
          })
        } else if (argument.type == 'img_num') {
          if (data.is_spots == 1) {
            that.$router.push({
              path: '/footprints/PrimaryScenic',
              query: { sid: data.id, id_: 0 }
            })
          } else {
            that.$router.push({
              path: '/footprints/FootprintsParticulars',
              query: { id: data.id }
            })
          }
        } else {
          if (argument.click_callback) {
            argument.click_callback(data)
          } else {
            new AMap.InfoWindow({
              isCustom: true, // 使用自定义窗体
              content: that.map_createInfoWindow({
                map: argument.map,
                data: data,
                type: argument.type,
                is_audio_select: argument.is_audio_select,
                aid: argument.aid,
                is_show_predetermine: argument.is_show_predetermine,
                click_reserve: argument.click_reserve,
                click_set: argument.click_set
              }),
              offset: new AMap.Pixel(20, -20)
            }).open(argument.map, e.target.getPosition())
          }
        }
      }
    })
    markerArr.push(numMarker)
  })

  var l_lng = parseFloat(argument.l_lng) || ''; // 118.952967;
  var l_lat = parseFloat(argument.l_lat) || ''; // 32.144917;
  //  var lng_ = argument.lng_;
  var lng_ = 0.001179 * 2
  //  var lat_ = argument.lat_;
  var lat_ = 0.000856
  var l_index = argument.l_index ? argument.l_index : 0
  l_index = parseInt(l_index) || 0
  var r_index = argument.r_index ? argument.r_index : 0
  r_index = parseInt(r_index) || 0
  console.log(argument)
  that.map_rectangle(argument.map, { 'l_lng': 118.952967, 'l_lat': 32.144917, 'r_lng': 118.981259, 'r_lat': 32.165481 })
  console.log(lat_ + '--' + lng_)
  if (r_index > 0 && l_index > 0 && l_lng != '' && l_lat != '') {
    for (let i = 0; i < l_index; i++) {
      for (let j = 0; j < r_index; j++) {
        // that.map_rectangle(argument.map,{'l_lng':l_lng + j * lng_,'l_lat':l_lat + i * lat_,'r_lng':l_lng + i * lng_,'r_lat':l_lat + (j + 1) * lat_})
        that.map_rectangle(argument.map, { 'l_lng': l_lng + i * lng_, 'l_lat': l_lat + j * lat_, 'r_lng': l_lng + (i + 1) * lng_, 'r_lat': l_lat + (j + 1) * lat_ })
        let pt = new AMap.LngLat(l_lng + (i + 0.5) * lng_, l_lat + (j + 0.5) * lat_)
        let px = new AMap.Text({
          text: j + "-" + i,
          map: argument.map,
          position: pt
        })
        px.setStyle({
          "background": "#ffffff88",
          "font-size": "16px"
        })

        let angle1 = new AMap.LngLat(l_lng + i * lng_, l_lat + j * lat_)
        let tx = new AMap.Text({
          text: "(" + (l_lng + i * lng_).toFixed(6) + "," + (l_lat + j * lat_).toFixed(6) + ")",
          map: argument.map,
          position: angle1,
          anchor: "bottom-left"
        })
        tx.setStyle({
          "background-color": "#00000066",
          "color": "#ffffff"
        })
        if (j == r_index - 1) {
          let angle2 = new AMap.LngLat(l_lng + (i + 1) * lng_, l_lat + (j + 1) * lat_)
          let tx = new AMap.Text({
            text: "(" + (l_lng + (i + 1) * lng_).toFixed(6) + "," + (l_lat + (j + 1) * lat_).toFixed(6) + ")",
            map: argument.map,
            position: angle2,
            anchor: "top-right"
          })
          tx.setStyle({
            "background-color": "#00000066",
            "color": "#ffffff"
          })
        }
      }
    }
  }

  if (argument.callback) {
    argument.callback(markerArr)
  }
  if (argument.l_lng && argument.l_lat) {
    if (argument.is_map_setFitView == 1) {
      argument.map.setFitView()
    }
  } else {
    argument.map.setFitView(markerArr)
  }
}

/**
 * 加载点
 * @param argument
 */
Vue.prototype.gdMapAddMarker = function (argument) {
  var that = this
  if (!argument.map.clearMap) {
    argument.map.clearMap()
  }
  //  console.log(argument)
  //  循环处理点位数据
  if (argument.type_ == 0) {

  } else {
    if (argument.cluster) {
      argument.cluster.cluster.setMap(null)
    }
  }
  markerArr = [] //  清空数据
  argument.point_list.forEach(function (mak, i) {
    var icon = ''
    //  设置每个点位不同的样式
    if (mak.type_ == 11) {
      icon = mapGeo11;
    } else if (mak.type_ == 12) {
      icon = mapPeople12;
    } else if (mak.type_ == 13) {
      icon = mapLeft13;
    } else if (mak.type_ == 14) {
      icon = mapPlant14;
    } else if (mak.type_ == 15) {
      icon = mapStop15;
    } else if (mak.type_ == 16) {
      icon = mapticket16;
    } else if (mak.type_ == 19) {
      if (mak.have_audio == 1) {
        // 有音频文件
        icon = mapLordPark
      } else {
        // 无音频文件
        icon = mapScensMak;

      }
    } else if (mak.type_ == 18) {
      icon = mapEatMak
    } else if (mak.type_ == 21) {
      icon = mapCeasMak
    } else if (mak.type_ == 20) {
      icon = mapBuyMak
    } else if (mak.type_ == 17) {
      icon = mapLavatMak
    } else if (mak.type_ == 2) {
      icon = mapLordPark
    } else if (mak.type_ == 3) {
      icon = mapFootMak
    } else if (mak.type_ == 154) {
      icon = footprintRed
    } else if (mak.type_ == 155) {
      icon = footprintCoupon
    } else if (mak.type_ == 156) {
      icon = footprintGifts
    } else if (mak.type_ == 1) {
      icon = activityRestaurant
    } else if (mak.type_ == 2) {
      icon = activityPersonage
    } else {
      icon = landmark
    }

    if (argument.type_ == 0) {
      if (argument.gdMapIconType == 'image') {
        // 表示显示图片
        if (mak.img_numbers > 1) {
          var html = "<div class='img more_picture'><img src=" + mak.newUrl + " /><i>" + mak.img_numbers + "</i></div>"
        } else {
          var html = "<div class='img sola_picture'><img src=" + mak.newUrl + " /></div>"
        }

        numMarker = new AMap.Marker({
          map: argument.map,
          position: [mak['lng'], mak['lat']],
          offset: new AMap.Pixel(-24, -24),
          extData: mak, // 给事件使用的数据
          content: html,
          zIndex: 100
        })

      } else {
        // 表示显示icon

        numMarker = new AMap.Marker({
          map: argument.map,
          icon: new AMap.Icon({
            size: new AMap.Size(35, 35), // 图标大小
            image: icon,
            imageSize: new AMap.Size(35, 35),
          }),
          animation: 'AMAP_ANIMATION_DROP',
          zIndex: 100,
          position: [mak.lng, mak.lat],
          extData: mak // 给事件使用的数据
        })
      }
      numMarker.setLabel({ // label默认蓝框白底左上角显示，样式className为：amap-marker-label
        offset: new AMap.Pixel(0, -20), // 修改label相对于maker的位置
        content: mak['spots_name'],

      })
      // 绑定鼠标点击事件
      numMarker.on('click', function (e) {
        e.target.setTop(true) // 停止消息向上冒泡时间
        var data = e.target.getExtData() // 得到marker中的extDatad字段的数据

        // 弹出弹框
        new AMap.InfoWindow({
          isCustom: true, // 使用自定义窗体
          content: that.gdMapCreateInfoWindow({
            map: argument.map,
            data: data,
            is_pay: argument.is_pay,
            price: argument.price,
            open_id: argument.open_id,
            is_weixin: argument.is_weixin,
            token: argument.token,
            version: argument.version,
            label_id: argument.label_id,
            park_id: argument.park_id,
            backUrl: argument.backUrl,
            currentUrl: argument.currentUrl,
            type: argument.type,
            is_audio_select: argument.is_audio_select,
            aid: argument.aid,
            is_show_predetermine: argument.is_show_predetermine,
            click_reserve: argument.click_reserve,
            click_set: argument.click_set
          }),
          offset: new AMap.Pixel(20, -20)
        }).open(argument.map, e.target.getPosition())
      })
    } else {
      if (argument.type_ == mak.type_) {
        if (argument.gdMapIconType == 'image') {
          // 表示显示图片
          if (mak.img_numbers > 1) {
            var html = "<div class='img more_picture'><img src=" + mak.newUrl + " /><i>" + mak.img_numbers + "</i></div>"
          } else {
            var html = "<div class='img sola_picture'><img src=" + mak.newUrl + " /></div>"
          }
          numMarker = new AMap.Marker({
            map: argument.map,
            position: [mak['lng'], mak['lat']],
            offset: new AMap.Pixel(-24, -24),
            extData: mak, // 给事件使用的数据
            content: html,
            zIndex: 100
          })
        } else {
          // 表示显示icon
          numMarker = new AMap.Marker({
            map: argument.map,
            icon: new AMap.Icon({
              size: new AMap.Size(35, 35), // 图标大小
              image: icon,
              imageSize: new AMap.Size(35, 35)
            }),
            animation: 'AMAP_ANIMATION_DROP',
            zIndex: 100,
            position: [mak.lng, mak.lat],
            extData: mak // 给事件使用的数据
          })
        }
        numMarker.setLabel({ // label默认蓝框白底左上角显示，样式className为：amap-marker-label
          offset: new AMap.Pixel(-10, 20), // 修改label相对于maker的位置
          content: mak['spots_name']
        })
        // 绑定鼠标点击事件
        numMarker.on('click', function (e) {
          e.target.setTop(true) // 停止消息向上冒泡时间
          var data = e.target.getExtData() // 得到marker中的extDatad字段的数据

          // 弹出弹框
          new AMap.InfoWindow({
            isCustom: true, // 使用自定义窗体
            content: that.gdMapCreateInfoWindow({
              map: argument.map,
              data: data,
              is_pay: argument.is_pay,
              price: argument.price,
              open_id: argument.open_id,
              is_weixin: argument.is_weixin,
              token: argument.token,
              version: argument.version,
              label_id: argument.label_id,
              park_id: argument.park_id,
              backUrl: argument.backUrl,
              currentUrl: argument.currentUrl,
              type: argument.type,
              is_audio_select: argument.is_audio_select,
              aid: argument.aid,
              is_show_predetermine: argument.is_show_predetermine,
              click_reserve: argument.click_reserve,
              click_set: argument.click_set
            }),
            offset: new AMap.Pixel(20, -20)
          }).open(argument.map, e.target.getPosition())
        })
      } else {
        // 不保存进数组
      }
    }

    markerArr.push(numMarker)

  })
  // console.log(markerArr)
  var cluster = '' // 先取消聚合功能
  if (argument.type_ == 0 && false) {
    // ******************************
    that.gdAddCluster({
      map: argument.map,
      markers_cluster: markerArr,
      gdMapIconType: argument.gdMapIconType,
      cluster_type_: 2,
      callback: function (ret_) {
        cluster = ret_
      }
    })
    // *******************************
  }
  // 存在回调函数,把数据返回
  if (argument.callback) {
    argument.callback({ markers: markerArr, cluster: cluster })
  }

  // 始终回调到当前页面
  argument.map.setFitView()
}

/**
 * 地图插件Leaflet 设置marker
 * @param argument
 * @constructor
 */
var audio = document.createElement('audio')
Vue.prototype.LeafletMapAddMarker = function (argument) {
  console.log(argument.map)
  var that = this
  if (argument.map && argument.L && argument.point_list) {
    var markerArr = []
    if (argument.layers) {
      argument.layers.clearLayers()
    }
    argument.point_list.forEach(function (mak, i) {
      var icon = ''
      // 设置每个点位不同的样式
      if (mak.type_ == 11) {
        icon = mapGeo11
      } else if (mak.type_ == 12) {
        icon = mapPeople12
      } else if (mak.type_ == 13) {
        icon = mapLeft13
      } else if (mak.type_ == 14) {
        icon = mapPlant14
      } else if (mak.type_ == 15) {
        icon = mapStop15
      } else if (mak.type_ == 16) {
        icon = mapticket16
      } else if (mak.type_ == 19) {
        if (mak.have_audio == 1) {
          // 有音频文件
          icon = mapLordPark
        } else {
          // 无音频文件
          icon = mapScensMak

        }
      } else if (mak.type_ == 18) {
        icon = mapEatMak
      } else if (mak.type_ == 21) {
        icon = mapCeasMak
      } else if (mak.type_ == 20) {
        icon = mapBuyMak
      } else if (mak.type_ == 17) {
        icon = mapLavatMak
      } else if (mak.type_ == 2) {
        icon = mapLordPark
      } else if (mak.type_ == 3) {
        icon = mapFootMak
      } else if (mak.type_ == 154) {
        icon = footprintRed
      } else if (mak.type_ == 155) {
        icon = footprintCoupon
      } else if (mak.type_ == 156) {
        icon = footprintGifts
      } else if (mak.type_ == 1) {
        icon = activityRestaurant
      } else if (mak.type_ == 2) {
        icon = activityPersonage
      } else {
        icon = landmark
      }
      var is_show = 0 // 表示是否显示

      if (argument.type_ == -1 && argument.sid > 0) {
        // 表示是从底部景点列表中选择的某一项
        // 存在sid
        if (argument.sid == mak.id) {
          var myDivIcon = argument.L.divIcon({
            html: "<div class='leaflet-title'><span>" + mak.spots_name + "</span></div>" + "<img style='width:35px;height:35px;' src='" + icon + "'/>",
            bgPos: [mak.lat, mak.lng],
            className: 'div_title'
          })
          var html = ''
          var sdata = mak
          if (sdata.have_audio) {
            // 表示有音频
            html += "<div class='popu-body'><div class='pb-title'><h3>" + mak.spots_name + "</h3></div>" +
              "<div class='pb-image' id='ClickItemDetail'><img src=" + mak.baseUrl + "></div>" +
              "<div class='pb-right'><div class='pbr-content'> <span>" + mak.spots_content + "</span></div>" +
              "<div class='pbr-bottom'>" +
              "<div class='pbr-audio iconfont' id='ClickPlayAudio'" + ">解说</div>" +
              "<div class='pbr-nav iconfont' id='ClickNav'>导航</div>" +
              "</div>" +
              "</div>" +
              "</div>";
          } else {
            // 无音频=详情导航
            html += "<div class='popu-body'><div class='pb-title'><h3>" + mak.spots_name + "</h3></div>" +
              "<div class='pb-image' ><img src=" + mak.newUrl + "></div>" +
              "<div class='pb-right'><div class='pbr-content'> <span>" + mak.spots_content + "</span></div>" +
              "<div class='pbr-bottom'>" +
              "<div class='pbr-detail iconfont' id='ClickItemDetail'>详情</div>" +
              "<div class='pbr-nav iconfont'  id='ClickNav'>导航</div>" +
              "</div>" +
              "</div>" +
              "</div>";
          }
          L.popup({
            minWidth: 300,
            keepInView: true
          })
            .setLatLng([sdata.lat, sdata.lng])

            .setContent(html)
            .openOn(argument.map)
          document.getElementById('ClickItemDetail').onclick = function () {
            // 进入详情页面
            that.$router.push({ // 足迹详情页面
              path: '/footprints/PrimaryScenic',
              query: { sid: sdata.id, id_: argument.label_id }
            })
          }
          if (sdata.have_audio) {
            document.getElementById('ClickPlayAudio').onclick = function () {
              // 播放音频

              var is_play = sdata.is_play
              if (argument.is_weixin) {
                if (argument.price > 0) {
                  // 收费
                  if (argument.is_pay == 1) {
                    // 已经支付
                    if (is_play) {
                      // 表示暂停
                      audio.pause()
                      argument.point_list[index_].is_play = 0;
                      $(this).text('播放')
                    } else {
                      // 开始播放
                      audio.src = sdata.zhAudioUrl;
                      audio.play()
                      argument.point_list[index_].is_play = 1;
                      $(this).text('播放中')
                    }
                  } else {
                    // 还未支付
                    if (argument.open_id) {
                      // 进行支付
                      var open_url = 'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' + argument.label_id + '&backUrl=' + argument.backUrl + '&open_id=' + argument.open_id + '&token=' + argument.token + '&park_id=' + argument.park_id + '&version=' + argument.version + '&user_id=';
                      window.location.href = open_url;
                    } else {
                      // 获取微信授权
                      var open_url = 'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' + argument.token + '&label_id=' + argument.label_id + '&id=0&user_id=' + '&state=' + argument.backUrl;
                      window.location.href = open_url;
                    }
                  }
                } else {
                  // 免费的
                  if (is_play) {
                    // 表示暂停
                    audio.pause()
                    argument.point_list[index_].is_play = 0;
                    $(this).text('播放')
                  } else {
                    // 开始播放
                    audio.src = sdata.zhAudioUrl;
                    audio.play()
                    argument.point_list[index_].is_play = 1;
                    $(this).text('播放中')
                  }

                }
              } else {
                Toast("请在微信中打开,听解说")
              }
            }

          }

          document.getElementById("ClickNav").onclick = function () {
            // 导航
            if (argument.is_weixin) {
              if (argument.price > 0) {
                // 收费
                if (argument.is_pay || true) {
                  // 已经支付了
                  that.wx_nav(argument.currentUrl, argument.data.lng, argument.data.lat, argument.data.spots_name, argument.data.address)

                } else {
                  // 还未支付
                  if (argument.open_id) {
                    // 进行支付
                    var open_url = 'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' + argument.label_id + '&backUrl=' + argument.backUrl + '&open_id=' + argument.open_id + '&token=' + argument.token + '&park_id=' + argument.park_id + '&version=' + argument.version + '&user_id=';
                    window.location.href = open_url;
                  } else {
                    // 进行微信授权
                    var open_url = 'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' + argument.token + '&label_id=' + argument.label_id + '&id=0&user_id=' + '&state=' + argument.backUrl;
                    window.location.href = open_url;
                  }
                }
              } else {
                // 免费
                that.wx_nav(argument.currentUrl, argument.point_list[index_].lng, argument.point_list[index_].lat, argument.point_list[index_].spots_name, argument.point_list[index_].address)

              }
            } else {
              Toast('请在微信中打开,使用导航')
            }
          }
        } else {

        }
      } else {
        // 表示选择是右边的分类菜单或者是第一次初始化加载
        // type_ = 0 或者是 line 或者是 分类的ID
        if (argument.type_ == 0) {
          // 显示所有的
          is_show = 1
        } else {
          if (argument.type_ == mak.type_) {
            is_show = 1
          }
        }
      }
      if (is_show == 1) {
        var CustomerIcon = argument.L.Icon.extend({
          options: {
            iconUrl: icon,
            // shadowUrl: CustomerShadowImg,
            iconSize: [35, 35],
            shadowSize: [50, 64],
            iconAnchor: [12, 20],
            shadowAnchor: [4, 4],
          }
        })
        // 第二种divIcon
        var myDivIcon = argument.L.divIcon({
          html: "<div class='leaflet-title'><span>" + mak.spots_name + "</span></div>" + "<img style='width:35px;height:35px;' src='" + icon + "'/>",
          bgPos: [mak.lat, mak.lng],
          className: 'div_title',
        })
        // 第三种
        var myIcon = L.icon({
          iconUrl: icon,
          iconSize: [35, 35],
          iconAnchor: [22, 22],
          popupAnchor: [-3, -76],
          // shadowUrl: 'my-icon-shadow.png',
          shadowSize: [35, 35],
          shadowAnchor: [22, 94],
          className: 'myIconCss',
        })

        var marker = new argument.L.Marker([mak.lat, mak.lng], {
          title: mak.spots_name,
          tabindex: i,
          icon: myDivIcon
        }) // .addTo(argument.map)

        marker.on({
          'click': function (e) {
            // console.log(e.target.options.tabindex)
            var index_ = e.target.options.tabindex
            var sdata = argument.point_list[index_]
            // console.log(sdata)
            var html = '';
            if (sdata.have_audio) {
              // 表示有音频
              html += "<div class='popu-body'><div class='pb-title'><h3>" + mak.spots_name + "</h3></div>" +
                "<div class='pb-image' id='ClickItemDetail'><img src=" + mak.baseUrl + "></div>" +
                "<div class='pb-right'><div class='pbr-content'> <span>" + mak.spots_content + "</span></div>" +
                "<div class='pbr-bottom'>" +
                "<div class='pbr-audio iconfont' id='ClickPlayAudio'" + ">解说</div>" +
                "<div class='pbr-nav iconfont' id='ClickNav'>导航</div>" +
                "</div>" +
                "</div>" +
                "</div>";
            } else {
              // 无音频=详情导航
              html += "<div class='popu-body'><div class='pb-title'><h3>" + mak.spots_name + "</h3></div>" +
                "<div class='pb-image' ><img src=" + mak.newUrl + "></div>" +
                "<div class='pb-right'><div class='pbr-content'> <span>" + mak.spots_content + "</span></div>" +
                "<div class='pbr-bottom'>" +
                "<div class='pbr-detail iconfont' id='ClickItemDetail'>详情</div>" +
                "<div class='pbr-nav iconfont'  id='ClickNav'>导航</div>" +
                "</div>" +
                "</div>" +
                "</div>";
            }
            var popup = L.popup({
              minWidth: 300,
              keepInView: true
            })
              .setLatLng([sdata.lat, sdata.lng])

              .setContent(html)
              .openOn(argument.map)
            document.getElementById('ClickItemDetail').onclick = function () {
              // 进入详情页面
              that.$router.push({ // 足迹详情页面
                path: '/footprints/PrimaryScenic',
                query: { sid: sdata.id, id_: argument.label_id }
              })
            }
            if (sdata.have_audio) {
              document.getElementById('ClickPlayAudio').onclick = function () {
                // 播放音频

                var is_play = sdata.is_play
                if (argument.is_weixin) {
                  if (argument.price > 0) {
                    // 收费
                    if (argument.is_pay == 1) {
                      // 已经支付
                      if (is_play) {
                        // 表示暂停
                        audio.pause()
                        argument.point_list[index_].is_play = 0
                        $(this).text('播放')
                      } else {
                        // 开始播放
                        audio.src = sdata.zhAudioUrl
                        audio.play()
                        argument.point_list[index_].is_play = 1
                        $(this).text('播放中')
                      }
                    } else {
                      // 还未支付
                      if (argument.open_id) {
                        // 进行支付
                        var open_url = 'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' + argument.label_id + '&backUrl=' + argument.backUrl + '&open_id=' + argument.open_id + '&token=' + argument.token + '&park_id=' + argument.park_id + '&version=' + argument.version + '&user_id='
                        window.location.href = open_url
                      } else {
                        // 获取微信授权
                        var open_url = 'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' + argument.token + '&label_id=' + argument.label_id + '&id=0&user_id=' + '&state=' + argument.backUrl
                        window.location.href = open_url
                      }
                    }
                  } else {
                    // 免费的
                    if (is_play) {
                      // 表示暂停
                      audio.pause()
                      argument.point_list[index_].is_play = 0
                      $(this).text('播放')
                    } else {
                      // 开始播放
                      audio.src = sdata.zhAudioUrl
                      audio.play()
                      argument.point_list[index_].is_play = 1
                      $(this).text('播放中')
                    }
                  }
                } else {
                  Toast("请在微信中打开,听解说")
                }
              }
            }
            document.getElementById('ClickNav').onclick = function () {
              // 导航
              if (argument.is_weixin) {
                if (argument.price > 0) {
                  // 收费
                  if (argument.is_pay) {
                    // 已经支付了
                    that.wx_nav(argument.currentUrl, argument.data.lng, argument.data.lat, argument.data.spots_name, argument.data.address)

                  } else {
                    // 还未支付
                    if (argument.open_id) {
                      // 进行支付
                      var open_url = 'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' + argument.label_id + '&backUrl=' + argument.backUrl + '&open_id=' + argument.open_id + '&token=' + argument.token + '&park_id=' + argument.park_id + '&version=' + argument.version + '&user_id='
                      window.location.href = open_url
                    } else {
                      // 进行微信授权
                      var open_url = 'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' + argument.token + '&label_id=' + argument.label_id + '&id=0&user_id=' + '&state=' + argument.backUrl
                      window.location.href = open_url
                    }
                  }
                } else {
                  // 免费
                  that.wx_nav(argument.currentUrl, argument.point_list[index_].lng, argument.point_list[index_].lat, argument.point_list[index_].spots_name, argument.point_list[index_].address)

                }
              } else {
                Toast('请在微信中打开,使用导航')
              }
            }
          }
        })
        markerArr.push(marker)
      }
    })

    var myGroup = argument.L.layerGroup(markerArr)
    argument.map.addLayer(myGroup)

    if (argument.callback) {
      argument.callback({ layers: myGroup })
    }
  } else {
    // 缺少三个重要的值
  }
}
/**
 * leaflet绘制路线
 */

Vue.prototype.leafletPath = function (argument) {
  console.log(argument)
  if (argument.map && argument.L && argument.point_list) {
    if (argument.gPath) {
      argument.map.removeLayer(argument.gPath)
    }
    var longLatList = []
    for (var k = 0; k < argument.point_list.length; k++) {
      longLatList.push([argument.point_list[k].lat, argument.point_list[k].lng])
    }
    var antPath = argument.L.polyline.antPath
    var gPath = antPath(longLatList, {
      'paused': false,
      // 暂停  初始化状态
      'reverse': false,
      // 方向反转
      'delay': 3000,
      // 延迟，数值越大效果越缓慢
      'dashArray': [10, 20],
      // 间隔样式
      'weight': 3,
      // 线宽
      'opacity': 0.5,
      // 透明度
      'color': 'red',
      // 颜色
      'pulseColor': '#FFFFFF' // 块颜色
    })
    gPath.addTo(argument.map)

    //  缩放地图到折线所在区域
    argument.map.fitBounds(gPath.getBounds())
    if (argument.callback) {
      argument.callback({ gPath: gPath })
    }
  } else {
    Toast('无数据')
  }
}
/**
 * 新建一个mak点     首次打开弹窗
 * @param map
 * @param data      传入渲染的点数据arr
 * @param icon      用的icon  如果，没有就用默认的
 * @param Window    true 可以点击
 * @param type      传入map_createInfoWindow == ‘RedPacket’ 调用高德地图导航
 */
Vue.prototype.map_add_Marker_1 = function (map, data, icon, Window, type) {
  var that = this;
  if (!icon) {
    icon = landmark
  }
  var Marker = new AMap.Marker({
    map: map,
    position: [data.lng, data.lat],
    icon: new AMap.Icon({
      size: new AMap.Size(25, 35), // 图标大小
      image: icon
    }),
    //  anchor: "bottom-center",
    zIndex: 101
  })
  // 绑定鼠标点击事件
  if (Window) {
    // 绑定鼠标点击事件
    Marker.on('click', () => {
      that.map_newInfoWindow(map, data, type)
    })
    that.map_newInfoWindow(map, data, type)
  }
}
/**
 * mak点、图片 切换
 * @param map
 * @param list_Marker       mak点对象数组
 * @param state             状态  是切换成图片还是mak
 */
Vue.prototype.map_cut_Marker = function (map, list_Marker, state) {
  if (!state) {
    list_Marker.forEach(function (mak) {
      if (mak.getExtData().img_numbers > 1) {
        var html = "<div class='img more_picture'><img src=" + mak.getExtData().newUrl + " /><i>" + mak.getExtData().img_numbers + "</i></div>"
      } else {
        html = "<div class='img sola_picture'><img src=" + mak.getExtData().newUrl + " /></div>"
      }
      mak.setContent(html)
    })
  } else {
    list_Marker.forEach(function (mak) {
      var icon = ''
      if (mak.getExtData().is_spots == 19) {
        icon = mapScensMak
      } else if (mak.getExtData().is_spots == 18) {
        icon = mapEatMak
      } else if (mak.getExtData().is_spots == 21) {
        icon = mapCeasMak
      } else if (mak.getExtData().is_spots == 20) {
        icon = mapBuyMak
      } else if (mak.getExtData().is_spots == 17) {
        icon = mapLavatMak
      } else if (mak.getExtData().is_spots == 2) {
        icon = mapLordPark
      } else if (mak.getExtData().is_spots == 3) {
        icon = mapFootMak
      } else {
        icon = landmark
      }
      mak.setContent("<img style='width: 1.2rem' src=" + icon + " />")
    })
  }
}

/**
 * 在地图上面进行切换：图片和icon标记
 * @param map
 * @param list_Marker
 * @param state
 */
Vue.prototype.gdMapCutMarker = function (map, list_Marker, gdMapIconType) {
  // console.log(gdMapIconType)

  if (gdMapIconType == 'image') {
    list_Marker.forEach(function (mak) {
      if (mak.getExtData().img_numbers > 1) {
        var html = "<div class='img more_picture'><img src=" + mak.getExtData().newUrl + " /><i>" + mak.getExtData().img_numbers + "</i></div>"
      } else {
        var html = "<div class='img sola_picture'><img src=" + mak.getExtData().newUrl + " /></div>"
      }
      mak.setContent(html)
    })
  } else {
    list_Marker.forEach(function (mak) {
      var icon = ''
      if (mak.getExtData().type_ == 19) {
        icon = mapScensMak
      } else if (mak.getExtData().type_ == 18) {
        icon = mapEatMak
      } else if (mak.getExtData().type_ == 21) {
        icon = mapCeasMak
      } else if (mak.getExtData().type_ == 20) {
        icon = mapBuyMak
      } else if (mak.getExtData().type_ == 17) {
        icon = mapLavatMak
      } else if (mak.getExtData().type_ == 2) {
        icon = mapLordPark
      } else if (mak.getExtData().type_ == 3) {
        icon = mapFootMak
      } else {
        icon = landmark
      }
      mak.setContent("<img style='width: 1.2rem' src=" + icon + " />")
    })
  }
  // 始终回调到当前页面
  map.setFitView()
}
/**
 * 构建自定义信息窗体     html结构
 * @param data          窗体信息 对象
 * @param type          进入详情 user进入用户个人中心   '' 进入足迹详情   type == 'primary' 主景点 景点详情
 * @returns {Element}
 * map, data, type, aid
 */
Vue.prototype.map_createInfoWindow = function (argument) {
  var that = this;
  var info = document.createElement("div")
  info.className = "center";
  // console.log(argument)console.log(415)
  var center = document.createElement("div")
  info.appendChild(center)
  //          //  定义内容
  //    标题
  if (argument.data.spots_name) {
    var titleD = document.createElement("div")
    titleD.innerHTML = argument.data.spots_name;
    if (argument.data.is_other_icon == 1) {
      titleD.className = "title_";
    } else {
      titleD.className = "titleD";
    }

    center.appendChild(titleD)
  }

  // X
  var closeX = document.createElement('img')
  closeX.src = 'https://webapi.amap.com/images/close2.gif'
  closeX.className = 'closeX'
  closeX.onclick = closeInfoWindow
  center.appendChild(closeX)

  //                               内容
  var content_box = document.createElement('div')
  content_box.className = 'content_box clearfix'
  //  是否有图片
  if (argument.data.newUrl) {
    var contentimg = document.createElement('img')
    contentimg.className = 'contentimg'
    contentimg.src = argument.data.newUrl
    content_box.appendChild(contentimg)
  }
  //  是否有内容
  if (argument.data.spots_content) {
    var contentinfo = document.createElement('div')
    contentinfo.className = 'contentinfo'
    contentinfo.innerHTML = argument.data.spots_content
    content_box.appendChild(contentinfo)
  }
  //  劲酒活动是否有列表
  if (argument.data.reward_list && argument.data.reward_list.length > 0) {
    var reward_list = document.createElement("ul")
    reward_list.className = "reward_list"
    for (var i = 0; i < argument.data.reward_list.length; i++) {
      var reward_list_li = document.createElement("li")
      var reward_list_button = document.createElement("button")
      reward_list_li.innerHTML = argument.data.reward_list[i].title;

      if (argument.data.reward_list[i].status == 0) {
        if (argument.data.reward_list[i].is_send == 0) {
          reward_list_button.innerHTML = "未送到";
          reward_list_button.onclick = click_set
        } else {
          reward_list_button.innerHTML = "已送到";
        }
      } else {
        reward_list_button.innerHTML = "已领取";
      }
      reward_list_button.id = argument.data.reward_list[i].id
      reward_list_button.className = "fr"
      reward_list_li.appendChild(reward_list_button)
      reward_list.appendChild(reward_list_li)
    }
    content_box.appendChild(reward_list)
  }
  center.appendChild(content_box)

  //  定义按钮内容
  var bottom_box = document.createElement("div")
  bottom_box.className = 'bottom_box';

  if (argument.data.is_spots == 2) {
    var bottom3 = document.createElement("div")
    if (!argument.data['audio_path']) {
      bottom3.className = "btmtip erji";
    } else {
      bottom3.className = "btmtip erji active";
      var audio = document.createElement("audio")
      audio.className = "bgMusic";
      var source1 = document.createElement("source")
      if (argument.is_audio_select == -1 || argument.is_audio_select == 0) {
        source1.src = argument.data['audio_path']
      } else if (argument.is_audio_select == 1) {
        source1.src = argument.data['man_audio_path']
      } else {
        if (argument.data['audio_path']) {
          source1.src = argument.data['audio_path']
        } else {
          // 手绘地图
          source1.src = argument.data['woman_audio_path']
        }
      }
      source1.type = "audio/mp3";
      audio.appendChild(source1)
      bottom_box.appendChild(audio)
    }
    bottom3.innerHTML = "<i class='iconfont icon-erji'></i>解说";
    bottom3.onclick = audio_path
    bottom_box.appendChild(bottom3)
  }
  if (!argument.data.id) {
    var bottom1 = document.createElement("div")
    bottom1.className = "btmtip luxian";
    bottom1.innerHTML = "<i class='iconfont icon-luxian'></i><span>到这里去</span>";
    bottom1.onclick = navigation;
    bottom_box.appendChild(bottom1)
  } else {
    var bottom2 = document.createElement("div")
    bottom2.className = "btmtip jingdian";
    if (argument.data.url != '') {
      bottom2.innerHTML = "<i class='iconfont icon-jingdian'></i><a  href='" + argument.data.url + "'><span>景点详情</span></a>";
    } else if (argument.data.is_other_icon == 1) {
      bottom2.className = "btmtip green_";
      bottom2.innerHTML = "<i class='iconfont icon-jingdian'></i><span>特产详情</span>";
    } else if (argument.type == 'primary' || argument.type == 2) {
      bottom2.innerHTML = "<i class='iconfont icon-jingdian'></i><span>景点详情</span>";
    } else if (argument.type == 'activity') {
      bottom2.innerHTML = "<i class='iconfont icon-jingdian'></i><span>作品详情</span>";
    } else if (argument.type == 15 || argument.type == 16 || argument.type == 17) {
      bottom2.className = "btmtip jingdian_gray";
      bottom2.innerHTML = "<i class='iconfont icon-jingdian'></i><span>详情</span>";
    } else {
      bottom2.innerHTML = "<i class='iconfont icon-jingdian'></i><span>足迹详情</span>";
    }
    if (argument.data.url != '') {

    } else {
      bottom2.onclick = enterinfo;
    }

    bottom_box.appendChild(bottom2)


    if (argument.data.is_other_icon == 1) {

    } else {
      var bottom1 = document.createElement("div")
      bottom1.className = "btmtip luxian";
      //  if (argument.type == 'activity') {
      //  bottom1.innerHTML = "<i class='iconfont icon-luxian'></i><span>到这里去</span>";
      //  } else {
      bottom1.innerHTML = "<i class='iconfont icon-luxian'></i><span>查看路线</span>";
      //  }
      if (argument.data.is_show_nav == 1) {
        bottom1.onclick = navigation;
      } else {
        // 导航不能使用

      }

      bottom_box.appendChild(bottom1)
    }




    //  是否显示预定按钮
    if (argument.is_show_predetermine) {
      var bottom3 = document.createElement("div")
      bottom3.className = "btmtip yuding";
      bottom3.innerHTML = "<i class='iconfont icon-yuding-on'></i><span>预 定</span>";
      bottom3.onclick = reserve
      bottom_box.appendChild(bottom3)
    }
  }

  center.appendChild(bottom_box)
  //                                 底部尖尖
  var sharp = document.createElement("img")
  sharp.src = "https://webapi.amap.com/images/sharp.png";
  sharp.className = "sharp";
  info.appendChild(sharp)

  //                                 路线距离
  var centerp = document.createElement("p")
  //  centerp.className = "hide"
  center.appendChild(centerp)

  return info;
  // 音频
  var is_play = true;

  function audio_path() {
    var bgMusic = document.getElementsByClassName("bgMusic")[0]
    var erji = document.getElementsByClassName("erji")[0]
    if (bgMusic) {
      if (is_play) {
        erji.innerHTML = "<i class='iconfont icon-erji'></i>解说"
        audio.pause()
        is_play = false
      } else {
        erji.innerHTML = "<i class='iconfont icon-erji'></i>暂停"
        bgMusic.play()
        is_play = true
      }
    }
  }

  // 查看路线
  function navigation() {
    if (argument.type == 'RedPacket') { // 直接跳转高德导航页面  || argument.type == "activity"
      that.map_Geo_location({
        map: argument.map,
        lng: argument.data.lng,
        lat: argument.data.lat,
        type: argument.type,
        callback: function (Lng, Lat) {
          AMap.plugin(["AMap.Walking", "AMap.Driving"], function () {
            var walking_drivingOption = {
              map: argument.map,
              hideMarkers: true,
            }
            var driving_drivingOption = {
              policy: AMap.DrivingPolicy.LEAST_TIME,
              map: argument.map,
              hideMarkers: true,
            }
            var walking = new AMap.Walking(walking_drivingOption) // 构造驾车导航类
            var driving = new AMap.Driving(driving_drivingOption) // 构造驾车导航类
            // 根据起终点坐标规划驾车路线
            walking.search(new AMap.LngLat(Lng, Lat), new AMap.LngLat(argument.data.lng, argument.data.lat), function (status, result) {
              if (status == 'error') {
                driving.search(new AMap.LngLat(Lng, Lat), new AMap.LngLat(argument.data.lng, argument.data.lat),
                  function (driving_status, driving_result) {
                    if (driving_status != "error") {
                      driving.searchOnAMAP({
                        origin: driving_result.origin,
                        destination: driving_result.destination
                      })
                    } else {
                      Toast("路线规划失败！")
                    }
                  })
              } else {
                walking.searchOnAMAP({
                  origin: result.origin,
                  destination: result.destination
                })
              }
            })
          })
        }
      })
    } else if (argument.type != 'primary') { // 显示我的位置并连接去点
      //  that.map_Geo_location(argument.map, argument.data.lng, argument.data.lat, '', '', argument.type, argument.data)
      that.map_Geo_location({
        map: argument.map,
        lng: argument.data.lng,
        lat: argument.data.lat,
        type: argument.type
      })
    } else if (argument.type == 'primary') {
      // add by lyh 公园地图主景点定位功能
      that.map_Geo_location({
        map: argument.map,
        lng: argument.data.lng,
        lat: argument.data.lat,
        type: argument.type
      })
    }
  }

  // 进入详情
  function enterinfo() {
    if (argument.type == 'user') {
      that.$router.push({
        path: '/footprints/FootprintsIndex', // TA的足迹列表页面
        query: { user_id: argument.data.user_id }
      })
    } else if (argument.type == 'primary') {
      that.$router.push({
        path: '/footprints/PrimaryScenic', // 公园景点详情页面
        query: { sid: argument.data.id, id_: 0 }
      })
    } else if (argument.type == 'activity') { // 劲酒投票详情页面
      that.$router.push({
        path: '/parkPhoto/detail',
        query: { aid: argument.aid, id: argument.data.id }
      })
    } else {
      if (argument.data.is_spots == 1) {
        that.$router.push({ // 足迹详情页面
          path: '/footprints/PrimaryScenic',
          query: { sid: argument.data.id, id_: 0 }
        })
      } else if (argument.data.is_spots == 15 || argument.data.is_spots == 16 || argument.data.is_spots == 17) {

      } else {
        if (argument.data.is_other_icon == 1) {
          that.$router.push({ // 足迹详情页面
            path: '/parkPhoto/LabelDetail',
            query: { id: argument.data.id, park_id: 1, title: argument.data.spots_name, park_logo: argument.data.newUrl }
          })
        } else {
          that.$router.push({ // 足迹详情页面
            path: '/footprints/FootprintsParticulars',
            query: { user_id: argument.data.user_id, id: argument.data.id }
          })
        }
      }
    }
  }

  //  点击预订
  function reserve() {
    if (argument.click_reserve) {
      argument.click_reserve(argument.data)
    }
  }

  function click_set(e) {
    if (argument.click_set) {
      argument.click_set(e, argument.data)
    }
  }

  // 关闭信息窗体
  function closeInfoWindow() {
    argument.map.clearInfoWindow()
  }
}

/**
 * 自定义弹出窗口
 * @param argument
 */
Vue.prototype.gdMapCreateInfoWindow = function (argument) {
  var that = this;
  var info = document.createElement("div")
  info.className = "center"; // 样式名
  // 创建一个子div
  var center = document.createElement("div")
  info.appendChild(center)
  //  定义内容
  // 标题
  if (argument.data.spots_name) {
    var titleD = document.createElement("div")
    titleD.innerHTML = argument.data.spots_name;
    titleD.className = "titleD";

    center.appendChild(titleD)
  }

  //                                 X
  var closeX = document.createElement("img")
  closeX.src = "https://webapi.amap.com/images/close2.gif";
  closeX.className = "closeX";
  closeX.onclick = closeInfoWindow; // 定义消息执行名称
  center.appendChild(closeX)

  //                               内容
  var content_box = document.createElement("div")
  content_box.className = "content_box clearfix"
  //  是否有图片
  if (argument.data.newUrl) {
    var contentimg = document.createElement("img")
    contentimg.className = "contentimg"
    contentimg.src = argument.data.newUrl;
    content_box.appendChild(contentimg)
  }
  //  是否有内容
  if (argument.data.spots_content) {
    var contentinfo = document.createElement("div")
    contentinfo.className = "contentinfo"
    contentinfo.innerHTML = argument.data.spots_content;
    content_box.appendChild(contentinfo)
  }

  center.appendChild(content_box)

  //  定义按钮内容
  var bottom_box = document.createElement("div")
  bottom_box.className = 'bottom_box';
  // console.log("zhAudioUrl:"+argument.data['zhAudioUrl'])
  if (argument.data['zhAudioUrl']) {
    var bottom3 = document.createElement("div")
    if (argument.data['is_play'] == 0) {
      bottom3.className = "btmtip erji active";
    } else {
      bottom3.className = "btmtip erji active";

    }
    var audio = document.createElement("audio")
    audio.className = "bgMusic";
    audio.src = argument.data['zhAudioUrl']

    bottom_box.appendChild(audio)
    bottom3.innerHTML = "<i class='iconfont icon-erji '></i>解说";
    bottom3.onclick = audio_path; // 播放音频事件
    bottom_box.appendChild(bottom3)
  }

  // 第二个按钮
  var bottom2 = document.createElement("div")
  bottom2.className = "btmtip jingdian";
  bottom2.innerHTML = "<i class='iconfont icon-jingdian'></i><span>查看详情</span>";
  bottom2.onclick = enterinfo;
  bottom_box.appendChild(bottom2)

  // 第三个按钮
  var bottom1 = document.createElement("div")
  bottom1.className = "btmtip luxian";
  bottom1.innerHTML = "<i class='iconfont icon-luxian'></i><span>导航</span>";
  bottom1.onclick = navigation;

  bottom_box.appendChild(bottom1)

  center.appendChild(bottom_box)
  //                                 底部尖尖
  var sharp = document.createElement("img")
  sharp.src = "https://webapi.amap.com/images/sharp.png";
  sharp.className = "sharp";
  info.appendChild(sharp)

  //                                 路线距离
  var centerp = document.createElement("p")
  center.appendChild(centerp)

  return info;
  // 音频播放消息处理
  function audio_path() {
    console.log("音频播放事件:")
    // console.log(argument)
    // 判断是否已经支付了
    var bgMusic = document.getElementsByClassName("bgMusic")[0]
    // console.log(bgMusic)
    var erji = document.getElementsByClassName("erji")[0]
    // console.log(erji)
    if (argument.is_weixin) {
      if (argument.price > 0) {
        // 收费的,判断用户是否已经支付
        if (argument.is_pay == 1) {
          // 已经支付了
          if (bgMusic) {
            if (argument.data.is_play) {
              // erji.setAttribute('class','btmtip erji ')
              erji.innerHTML = "<i class='iconfont icon-erji '></i>解说"
              audio.pause()
              argument.data.is_play = 0
            } else {
              // erji.setAttribute('class','btmtip erji active')
              erji.innerHTML = "<i class='iconfont icon-erji '></i>暂停"
              bgMusic.play()
              argument.data.is_play = 1;
            }
          }
        } else {
          // 还未支付
          if (argument.open_id) {
            // 进行支付
            var open_url = 'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' + argument.label_id + '&backUrl=' + argument.backUrl + '&open_id=' + argument.open_id + '&token=' + argument.token + '&park_id=' + argument.park_id + '&version=' + argument.version + '&user_id=';
            window.location.href = open_url;
          } else {
            // 去获取授权
            var open_url = 'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' + argument.token + '&label_id=' + argument.label_id + '&id=0&user_id=' + '&state=NoDrawMap';
            window.location.href = open_url;
          }
        }
      } else {
        // 免费的
        if (bgMusic) {
          if (argument.data.is_play) {
            // erji.setAttribute('class','btmtip erji ')
            erji.innerHTML = "<i class='iconfont icon-erji '></i>解说"
            audio.pause()
            argument.data.is_play = 0
          } else {
            // erji.setAttribute('class','btmtip erji active')
            erji.innerHTML = "<i class='iconfont icon-erji '></i>暂停"
            bgMusic.play()
            argument.data.is_play = 1;
          }
        }
      }
    } else {
      Toast('请在微信中打开')
    }
  }

  // 查看路线,导航
  function navigation() {
    // 调用微信导航
    // console.log(that)
    if (argument.is_weixin) {
      if (argument.price > 0) {
        // 收费
        if (argument.is_pay || true) {
          // 已经支付了
          that.wx_nav(argument.currentUrl, argument.data.lng, argument.data.lat, argument.data.spots_name, argument.data.address)

        } else {
          // 还未支付
          if (argument.open_id) {
            // 进行支付
            var open_url = 'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' + argument.label_id + '&backUrl=' + argument.backUrl + '&open_id=' + argument.open_id + '&token=' + argument.token + '&park_id=' + argument.park_id + '&version=' + argument.version + '&user_id=';
            window.location.href = open_url;
          } else {
            // 进行微信授权
            var open_url = 'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' + argument.token + '&label_id=' + argument.label_id + '&id=0&user_id=' + '&state=NoDrawMap';
            window.location.href = open_url;
          }
        }
      } else {
        // 免费
        that.wx_nav(argument.currentUrl, argument.data.lng, argument.data.lat, argument.data.spots_name, argument.data.address)

      }
    } else {
      Toast('请在微信中打开,使用导航')
    }
  }

  // 进入详情
  function enterinfo() {
    // 判断是否需要跳转到外部网页
    if (argument.data.url) {
      // 跳转到外部指定的网页
      window.location.href = argument.data.url;
    } else {
      // 跳转到我们指定的网页
      that.$router.push({ // 足迹详情页面
        path: '/footprints/PrimaryScenic',
        query: { sid: argument.data.id, id_: argument.label_id }
      })
    }

  }



  // 关闭信息窗体
  function closeInfoWindow() {
    argument.map.clearInfoWindow()
  }
}
/**
 * 新建一个自定义窗口
 * @param map
 * @param item          传入要显示的数据 arr
 */
Vue.prototype.map_newInfoWindow = function (map, item, type, is_audio_select) {

  var that = this;
  new AMap.InfoWindow({
    isCustom: true, // 使用自定义窗体
    //  content: this.map_createInfoWindow(map, item, type),
    content: that.map_createInfoWindow({
      map: map,
      data: item,
      type: type,
      is_audio_select: is_audio_select,
    }),
    offset: new AMap.Pixel(22, -20),
  }).open(map, [item.lng, item.lat])
  // map.setFitView()
}

/**
 * 选择一个点之后弹出的弹框
 * @param map
 * @param item
 */
Vue.prototype.gdMapNewInfoWindow = function (map, item, is_pay, price, open_id, is_weixin, token, version, label_id, park_id, backUrl, currentUrl) {
  var that = this;
  new AMap.InfoWindow({
    isCustom: true, // 使用自定义窗体
    content: that.gdMapCreateInfoWindow({
      map: map,
      data: item,
      is_pay: is_pay,
      price: price,
      open_id: open_id,
      is_weixin: is_weixin,
      token: token,
      version: version,
      label_id: label_id,
      park_id: park_id,
      backUrl: backUrl,
      currentUrl: currentUrl,
    }),
    offset: new AMap.Pixel(22, -20),
  }).open(map, [item.lng, item.lat])
}
/**
 * 绘制矩形
 * @param map
 * @param mak
 */
Vue.prototype.map_rectangle = function (map, mak) {
  var southWest = new AMap.LngLat(mak['l_lng'], mak['l_lat'])
  var northEast = new AMap.LngLat(mak['r_lng'], mak['r_lat'])

  var bounds = new AMap.Bounds(southWest, northEast)


  var rectangle = new AMap.Rectangle({
    map: map,
    bounds: bounds,
    strokeColor: 'red',
    strokeWeight: 1,
    strokeOpacity: 0.5,
    strokeDasharray: [30, 10],
    strokeStyle: 'dashed',
    // fillColor:'blue',
    fillOpacity: 0,
    zIndex: 10,
    bubble: true,
    cursor: 'pointer',
    bubble: false
  })

  rectangle.setMap(map)
}
/**
 * 新建覆盖物圆
 * @param map
 * @param mak       圆的的数据   arr
 */
Vue.prototype.map_new_Circle = function (map, mak) {
  if (mak['radius']) {
    //  覆盖物圆
    var num_Circle = new AMap.Circle({
      center: new AMap.LngLat(mak['lng'], mak['lat']), //  圆心位置
      radius: mak['radius'] * 1000, // 半径
      strokeColor: "#ff9a80", // 线颜色
      strokeWeight: 3, // 线粗细度
      strokeOpacity: 1, // 线透明度
      fillColor: "#ff9a80", // 填充颜色
      fillOpacity: 0.35, // 填充透明度
      extData: mak
    })
    // 圆显示
    num_Circle.setMap(map)
  }
}
/**
 * 地图定位
 * @param map
 * @param lng           经度
 * @param lat           纬度  要去的经纬度  如果有传入经纬度就把当前位置和传入的经纬度连线
 * @param callback
 * @param no            定位中窗口不显示
 *
 * map, lng, lat, callback, is_show_Indicator, type
 * @constructor
 */
Vue.prototype.map_Geo_location = function (argument) {
  var geolocation, that = this;
  if (!argument.is_show_Indicator) {
    Indicator.open('定位中...')
  }

  argument.map.plugin(['AMap.Geolocation'], function () {
    geolocation = new AMap.Geolocation({
      enableHighAccuracy: true, // 是否使用高精度定位，默认:true
      timeout: 5000 // 超过5秒后停止定位，默认：无穷大
    })
    //  ios环境切换到使用远程https定位
    if (AMap.UA.ios) {
      // AMap.UA.ios && document.location.protocol !== 'https:'
      // 使用远程定位，见 remogeo.js
      var remoGeo = new RemoGeoLocation()

      // 替换方法
      navigator.geolocation.getCurrentPosition = function () {
        return remoGeo.getCurrentPosition.apply(remoGeo, arguments)
      }

      // 替换方法
      navigator.geolocation.watchPosition = function () {
        return remoGeo.watchPosition.apply(remoGeo, arguments)
      }
    }

    geolocation.getCurrentPosition()
    AMap.event.addListener(geolocation, 'complete', onComplete) // 返回定位信息
    AMap.event.addListener(geolocation, 'error', onError) // 返回定位出错信息
  })
  // 解析定位结果
  function onComplete(data) {
    Indicator.close()
    var currentLng = data.position.getLng()
    var currentLat = data.position.getLat()

    new AMap.Marker({
      map: argument.map,
      icon: new AMap.Icon({
        size: new AMap.Size(35, 43), // 图标大小
        image: logoIcon
      }),
      position: [data.position.getLng(), data.position.getLat()],
      offset: new AMap.Pixel(-18, -40),
      //  anchor: "bottom-center",
    })
    if (argument.type == "RedPacket") {

    } else if (argument.lng) {
      that.map_path(argument.map, data.position.getLng(), data.position.getLat(), argument.lng, argument.lat)
    } else {
      argument.map.setZoomAndCenter(argument.map.getZoom(), [data.position.getLng(), data.position.getLat()])
    }
    if (argument.callback) {
      argument.callback(data.position.getLng(), data.position.getLat())
    }
  }

  // 解析定位错误信息
  function onError(data) {
    console.log(JSON.stringify(data))
    console.log(JSON.stringify(data, null, 2))
    Indicator.close()
    Toast("定位失败")
  }

}

/**
 * 获取当前定位
 * @param argument
 */
Vue.prototype.gdMapLocation = function (argument) {
  var geolocation, that = this;
  if (!argument.is_show_Indicator) {
    // Indicator.open('定位中...')
  }
  argument.map.plugin(['AMap.Geolocation'], function () {
    geolocation = new AMap.Geolocation({
      enableHighAccuracy: true, // 是否使用高精度定位，默认:true
      timeout: 10000 // 超过10秒后停止定位，默认：无穷大
    })
    //  ios环境切换到使用远程https定位
    if (AMap.UA.ios) {
      // AMap.UA.ios && document.location.protocol !== 'https:'
      // 使用远程定位，见 remogeo.js
      var remoGeo = new RemoGeoLocation()

      // 替换方法
      navigator.geolocation.getCurrentPosition = function () {
        return remoGeo.getCurrentPosition.apply(remoGeo, arguments)
      }

      // 替换方法
      navigator.geolocation.watchPosition = function () {
        return remoGeo.watchPosition.apply(remoGeo, arguments)
      }
    }

    geolocation.getCurrentPosition()
    AMap.event.addListener(geolocation, 'complete', onComplete) // 返回定位信息
    AMap.event.addListener(geolocation, 'error', onError) // 返回定位出错信息
  })
  // 解析定位结果
  function onComplete(data) {
    Indicator.close()
    var currentLng = data.position.getLng()
    var currentLat = data.position.getLat()

    if (argument.callback) {
      argument.callback(data.position.getLng(), data.position.getLat())
    }
  }

  // 解析定位错误信息
  function onError(data) {
    console.log(JSON.stringify(data))
    console.log(JSON.stringify(data, null, 2))
    Indicator.close()
    Toast("定位失败")

  }
}
/**
 * 设置路书线路
 * @param map
 * @param mylng         自己当前经度
 * @param mylat         自己当前纬度
 * @param lng           要去的经度
 * @param lat           要去的纬度
 */
Vue.prototype.map_path = function (map, mylng, mylat, lng, lat, i) {
  map.plugin(['AMap.Walking', 'AMap.Driving'], () => {
    //                         首先构造步行导航，失败就构造驾车导航
    var walking = new AMap.Walking({ // 步行导航类
      map: map,
      hideMarkers: true,
    })
    var Driving = new AMap.Driving({ // 驾车导航类
      map: map,
      hideMarkers: true,
    })
    walking.search(new AMap.LngLat(mylng, mylat), new AMap.LngLat(lng, lat), function (status, result) {
      if (status === 'complete') {
        console.log("步行导航")
        // 步行导航
        walking.search([mylng, mylat], [lng, lat], function (status, result) {
          if (status === 'complete') {
            $('.center>div>p').html("预计步行需要" + formatSeconds(result.routes[0].time) + "钟(" + (result.routes[0].distance / 1000).toFixed(2) + "公里)")
          } else {
            Toast('规划路线失败')
          }
        })
      } else {
        console.log("驾车导航")
        // 驾车导航
        Driving.search([mylng, mylat], [lng, lat], function (status, result) {
          if (status === 'complete') {
            $('.center>div>p').html("预计驾车需要" + formatSeconds(result.routes[0].time) + "钟(" + (result.routes[0].distance / 1000).toFixed(2) + "公里)")
          } else {
            Toast('规划路线失败')
            console.log(result)
          }
        })
      }
    })
  })
  // map.setFitView()
  function formatSeconds(value) {
    var theTime = parseInt(value) //  秒
    var theTime1 = 0; //  分
    var theTime2 = 0; //  小时
    if (theTime > 60) {
      theTime1 = parseInt(theTime / 60)
      if (theTime1 > 60) {
        theTime2 = parseInt(theTime1 / 60)
        theTime1 = parseInt(theTime1 % 60)
      }
    }
    var result = "";
    if (theTime1 > 0) {
      result = "" + parseInt(theTime1) + "分" + result;
    }
    if (theTime2 > 0) {
      result = "" + parseInt(theTime2) + "小时" + result;
    }
    return result;
  }
}

/**
 * 设置路书线路上的msk点 并连线
 * @param map
 * @param point_list    点数据arr
 * @param label_fk_id   路书id 点击路书上的点跳转到路书详情页面
 */
Vue.prototype.map_root_book_point = function (map, point_list, callback) {
  var that = this;
  map.clearMap()
  for (let i = 0; i < point_list.length; i++) {
    if (i != point_list.length - 1) {
      that.map_path(map, point_list[i].lng, point_list[i].lat, point_list[i + 1].lng, point_list[i + 1].lat, i)
    }
    if (i == 0) { // 起点
      icon_type(origin, point_list[i])
    } else if (i == point_list.length - 1) { // 终点
      icon_type(emphasis, point_list[i])
    } else {
      icon_type(mark, point_list[i], i)
    }
  }
  // map.setFitView()
  // 添加点标记，并使用自己的icon
  function icon_type(icon, point_list, i) {
    if (i) {
      var Marker_type = new AMap.Marker({
        map: map,
        position: [point_list.lng, point_list.lat],
        extData: point_list,
        //  anchor: "bottom-center",
        content: "<div>" + (i + 1) + "</div>",
      })
    } else {
      var Marker_type = new AMap.Marker({
        map: map,
        position: [point_list.lng, point_list.lat],
        extData: point_list,
        //  anchor: "bottom-center",
        icon: new AMap.Icon({
          size: new AMap.Size(25, 35), // 图标大小
          image: icon
        })
      })
    }
    that.map_new_Circle(map, point_list)

    Marker_type.setLabel({ // label默认蓝框白底左上角显示，样式className为：amap-marker-label
      offset: new AMap.Pixel(-20, -16),
      content: point_list.spots_name
    })
    Marker_type.on('click', (e) => {
      if (callback) {
        callback(e.target.getExtData())
      }
    })
  }
}

/**
 * 设置地图缩放
 * @param state    状态 缩小还是放大
 */
Vue.prototype.map_set_zoom = function (map, state) {
  var zoom_num = map.getZoom()
  if (state == '+') {
    zoom_num++;
    if (zoom_num >= 20) {
      zoom_num = 20
    }
  } else if (state == '-') {
    zoom_num--;
    if (zoom_num <= 0) {
      zoom_num = 0
    }
  }
  console.log(zoom_num)
  map.setZoomAndCenter(zoom_num)
}

/**
 * 设置地图2D、3D切换
 * @param state 状态 切换成什么
 */
Vue.prototype.map_set_3d = function (map, state) {

  map.plugin(['AMap.MapType'], function () {
    if (state) {
      let layer = map.getLayers()
      layer.forEach(item => {
        map.remove(item)
      })
      var type = new AMap.MapType({
        defaultType: 0,
      }) // 使用2D地图
    } else {
      var type = new AMap.MapType({
        defaultType: 1,
        showTraffic: true,
        showRoad: true,
      })
    }
    type.hide()
    map.addControl(type)
  })
}

/**
 * 点击路书线路上的点
 * @param id                锚点id
 * @param label_fk_id       路书id 跳转到路书路线详情
 * @param container_big     地图放大状态跳转到足迹详情   地图缩小状态状态滚动到指定足迹
 */
Vue.prototype.jump = function (data, label_fk_id, container_big) {
  if (label_fk_id) {
    this.$router.push({ path: '/footprints/LineInfoListtwo', query: { label_id: label_fk_id } })
  } else if (container_big) {
    this.$router.push({
      path: '/footprints/FootprintsParticulars',
      query: { user_id: this.user_id, id: data.id }
    })
  } else {
    let jump = document.getElementById(data.id)
    //  获取需要滚动的距离
    let total = jump.offsetTop - 100
    //  Chrome
    document.body.scrollTop = total
    //  Firefox
    document.documentElement.scrollTop = total
    //  Safari
    window.pageYOffset = total
  }
}
/**
 * 点击路线上的点，进入详情
 * @param data
 * @param label_fk_id
 * @param container_big
 */
Vue.prototype.gdJump = function (data, label_id, token) {

  this.$router.push({
    path: '/footprints/PrimaryScenic',
    query: { sid: data.id, id_: label_id }
  })
}
/**
 * 获取该经纬度的地址信息
 * @param map
 * @param lng
 * @param lat
 * @param callback
 */
Vue.prototype.map_getAddress = function (map, lng, lat, callback) {
  map.plugin(['AMap.Geocoder'], function () {
    var geocoder = new AMap.Geocoder({
      city: "010" // 城市，默认：“全国”
    })

    geocoder.getAddress([lng, lat], function (status, result) {
      if (status == 'complete') {
        callback(result.regeocode.formattedAddress)
      }
    })

  })
}
// 初始化地图，就行修改，多传入一个参数，用于区分用户选择的哪一种地图
// 默认是map_type=0表示打开高德地图
// map_type = 1 腾讯地图
// map_type = 2 百度地图
Vue.prototype.map_open = function (lng, lat, map_type = 0) {
  // console.log(lng, lat,map_type)
  Indicator.open('定位中...')
  if (map_type == 0) {
    AMap.plugin('AMap.Geolocation', () => { // 异步
      var geolocation = new AMap.Geolocation({
        enableHighAccuracy: true, // 是否使用高精度定位，默认:true
        timeout: 10000 // 超过10秒后停止定位，默认：无穷大
      })
      //  ios环境切换到使用远程https定位
      if (AMap.UA.ios) {
        // AMap.UA.ios && document.location.protocol !== 'https:'
        // 使用远程定位，见 remogeo.js
        var remoGeo = new RemoGeoLocation()

        // 替换方法
        navigator.geolocation.getCurrentPosition = function () {
          return remoGeo.getCurrentPosition.apply(remoGeo, arguments)
        }

        // 替换方法
        navigator.geolocation.watchPosition = function () {
          return remoGeo.watchPosition.apply(remoGeo, arguments)
        }
      }

      geolocation.getCurrentPosition()
      AMap.event.addListener(geolocation, 'complete', onComplete) // 返回定位信息
      AMap.event.addListener(geolocation, 'error', onError) // 返回定位出错信息
    })
    // 解析定位结果
    function onComplete(data) {
      Indicator.close()
      var mylng = data.position.getLng()
      var mylat = data.position.getLat()
      AMap.plugin(['AMap.Walking', 'AMap.Driving'], () => {
        var drivingOption = {
          policy: AMap.DrivingPolicy.LEAST_TIME
        }
        var driving = new AMap.Driving(drivingOption) // 构造驾车导航类
        // 根据起终点坐标规划驾车路线
        driving.search(new AMap.LngLat(mylng, mylat), new AMap.LngLat(lng, lat), function (status, result) {
          driving.searchOnAMAP({
            origin: result.origin,
            destination: result.destination
          })
        })
      })
    }

    // 解析定位错误信息
    function onError(data) {
      Indicator.close()
      Toast("定位失败")
    }
  } else if (map_type == 1) {
    // 腾讯地图
    var address = this.info.address ? this.info.address : this.info.title;
    var tengxun = "https://apis.map.qq.com/uri/v1/routeplan?type=drive&fromcoord=&to=" + address + "&tocoord=" + lat + "," + lng + "&coord_type=1&policy=0&referer=zmlxj_tengxun_web_map_key_2018";

    window.open(tengxun)
  } else if (map_type == 2) {
    // 百度地图
    // 由于百度地图没有自动提供当前的经纬度，所以需要自己手动获取
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition)
    } else {
      alert("浏览器不支持定位")
    }

    function showPosition(position) {
      // console.log(12121)
      // 百度全球化所以必须指明起点和终点所在那一个城市
      window.location.href = "https://api.map.baidu.com/direction?origin=latlng:" + position.coords.latitude + "," + position.coords.longitude + "|name:当前位置&destination=latlng:" + lat + "," + lng + "|name=目的地&mode=driving&region=中国&output=html&src=武汉地普游科技有限公司|最美旅行家-网页端";
    }
  }


  return false;
}

/**
 * 计算两个点的距离
 *
 */
Vue.prototype.gdDistance = function (map, pointStart, pointEnd) {
  // console.log(map)
  var lnglat = new AMap.LngLat(pointStart[0], pointStart[1]) // 测量点
  var distance = 0;
  if (pointStart && pointEnd) {
    distance = lnglat.distance(pointEnd) // map.GeometryUtil.distance(pointStart, pointEnd)
  } else {
    distance = 0;
  }
  return distance;
}

/**
 * 获取查询城市的天气情况
 * @param {String} cityName 城市名称
 * @return {Object} 返回一个错误对象或者当前城市的天气信息 {city: '..', temperature: '..', ... }
 */
Vue.prototype.getWeatherByCity = function (cityName) {
  return new Promise((resolve, reject) => {
    AMap.plugin('AMap.Weather', () => {
      //创建天气查询实例
      let weather = new AMap.Weather();
  
      //执行实时天气信息查询
      weather.getForecast(cityName, (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      });
    });
  })
}