<template>
  <div class="pixi-map-container dylan" @click.self="bodyClickHandle">
    <!-- view区域 -->
    <div id="pixi-body" class="pixi-body"></div>

    <!-- 分区logo -->
    <transition name="area-fade">
      <div class="pixi-logo" v-if="logo_list" v-show="show_area">
        <div class="logo-wrapper">
          <ul class="logo-menu">
            <li class="logo-menu-list" v-for="(item, i) in logo_list" :key="i">
              <img :src="item.logo_" alt @click="areaLocation(item.x_, item.y_)" />
              <!-- <p>{{item.title}}</p> -->
            </li>
          </ul>
          <div class="logo-btn" @click="show_area= false">
            <i class="iconfont iconqianjin"></i>
          </div>
        </div>
      </div>
    </transition>
    <!-- 顶部导航 -->
    <transition name="top-fade">
      <div class="pixi-top" v-show="!show_area">
        <ul class="top-menu">
          <li
            class="top-menu-li top-brief"
            @click="show_spot_brief = !show_spot_brief;show_point_brief = false"
          >
            <i class="iconfont iconShape"></i>
            <p>简介</p>
          </li>
          <li v-if="is_show_area" class="top-menu-li top-zone" @click="show_area = true">
            <i class="iconfont iconfenqu"></i>
            <p>分区</p>
          </li>
        </ul>
        <!-- 统计数据 -->
        <map-user-list v-if="map_data.is_show_data > 0" :mapData="map_data"></map-user-list>
        <div class="top-menu-li top-menu top-music" @click="playBgMusic" v-if="map_data.bgAudioUrl">
          <i class="iconfont iconswitch_open" v-if="bgmusic_play"></i>
          <i class="iconfont iconswitch_close" v-else></i>
          <p>音乐</p>
        </div>
      </div>
    </transition>

    <!-- 景区简介 -->
    <transition name="spot-brief">
      <div class="pixi-spot-brief" v-show="show_spot_brief">
        <div class="spot-brief-wrapper">
          <div class="brief-close" @click="show_spot_brief = false">
            <i class="iconfont iconbaseline-close-px"></i>
          </div>
          <div class="spot-brief-icon">
            <img :src="map_data.logo_" alt />
          </div>
          <div class="spot-brief-header">
            <h2 class="spot-brief-name">{{map_data.label_name}}</h2>
          </div>
          <div class="spot-brief-info">
            <div class="spot-brief-address spot-brief-item" v-if="map_data.address">
              <p class="item-name">
                <i class="iconfont icondizhi2"></i>
                <span>景区地址：</span>
              </p>
              <p class="item-value">{{map_data.address}}</p>
            </div>
            <div class="spot-brief-time spot-brief-item" v-if="map_data.time">
              <p class="item-name">
                <i class="iconfont iconshijian"></i>
                <span>开放时间：</span>
              </p>
              <p class="item-value">{{map_data.time}}</p>
            </div>
          </div>
          <div class="spot-brief-content">
            <article v-html="map_data.content"></article>
          </div>
        </div>
      </div>
    </transition>
    <!-- 底部导航 -->
    <div class="pixi-footer" :class="show_menu ? 'pixi-footer-active' : ''">
      <!-- 目录 -->
      <div class="footer-menu">
        <ul class="footer-menu-list">
          <li
            class="menu-item"
            v-for="(item, i) in menu_list"
            :key="i"
            @click="menuClickHandle(item.id,i)"
            :class="first_menu_type == item.id ? 'menu-item-active' : ''"
          >
            <img :src="choseIconByType(item.id)" alt />
            <span>{{item.title}}</span>
          </li>
        </ul>
        <div class="footer-btn" @click="menuBtnHandle">
          <i class="iconfont iconUp"></i>
        </div>
      </div>
      <!-- 导航 -->
      <div class="footer-nav" v-show="show_menu">
        <!-- 搜索 -->
        <div
          class="footer-search"
          v-show="(typeof first_menu_type === 'string' && first_menu_type != 15 && first_menu_type != 16 && first_menu_type != 17 && first_menu_type != 6 && first_menu_type != 7) ||
          (typeof first_menu_type === 'object' && !first_menu_type.includes(15) && !first_menu_type.includes(16) && !first_menu_type.includes(17) && !first_menu_type.includes(6) && !first_menu_type.includes(7))"
        >
          <div class="search-wrapper">
            <div class="search-input">
              <input type="text" placeholder="输入景点名称" v-model="search_value" />
            </div>
            <div class="search-icon">
              <i class="iconfont iconsousuo"></i>
            </div>
          </div>
        </div>
        <!-- 点位列表 -->
        <div class="nav-wrapper">
          <ul class="footer-nav-list"  v-if="first_menu_type !== 'line'" key="point">
            <li
              :class="[point_id[1] == item.id ? 'nav-item-active' : '','nav-item']"
              v-for="(item,i) in filterPointListByType(point_list)"
              :key="i"
              @click="menuLocation(item)"
            >
              <img :src="filterIconByType(item.type_)" alt />
              <p>{{clampText(item.spots_name, 11)}}</p>
              <p v-if="item.distance" class="distance">{{item.distance}}</p>
            </li>
          </ul>

          <ul class="footer-nav-list" v-else key="line">
              <li
                :class="[path_arr[0] && path_arr[0].id == item.id ? 'nav-item-active' : '','nav-item']"
                v-for="(item,i) in (line_list)"
                :key="i"
                @click="menuLocation(item)"
              >
                <img :src="filterIconByType(item.type_)"/>
                <p v-if="item.spots_name">{{clampText(item.spots_name, 11)}}</p>
                <p v-if="item.title">{{clampText(item.title, 11)}}</p>
              </li>
            </ul>
        </div>
      </div>
    </div>

    <!-- 点位简介 -->
    <transition name="brief-fade">
      <div class="point-brief" v-show="show_point_brief">
        <div class="brief-wrapper">
          <div class="brief-close" @click="pointBriefHide">
            <i class="iconfont iconbaseline-close-px"></i>
          </div>
          <div class="brief-header">
            <div class="brief-icon">
              <div class="audio-btn" v-show="point_item.zhAudioUrl">
                <i
                  class="audio-btn-play iconfont iconbofang1"
                  v-if="point_item.is_play != 0"
                  @click="playAudio"
                ></i>
                <i class="audio-btn-play iconfont iconbofang" v-else @click="playAudio"></i>
              </div>
              <img :src="point_item.logo_ ? point_item.logo_ : point_item.newUrl" alt />
            </div>
            <div class="brief-title" @click="jumpToDetail">
              <div
                class="brief-title-tip"
                v-show="point_item.type_ != 17 && point_item.is_have_detail == 1"
              ></div>
              <h2>{{point_item.spots_name}}</h2>
            </div>
            <div class="brief-nav" @click="jumpPos">
              <i class="iconfont icondaohang1"></i>
              <span>导航</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 音频播放 -->
    <audio :src="map_data.bgAudioUrl" ref="audio" autoplay="autoplay"></audio>
    <!-- 播放界面 -->
    <transition name="audio-fade">
      <div class="audio-play" v-show="show_audio">
        <span class="audio-play-icon">
          <img src="../../assets/switch/bgmusic.png" alt />
        </span>
        <span class="audio-play-name">{{audio_name || map_data.label_name}}</span>
        <span class="audio-play-close" @click="closeAudio">
          <i class="iconfont iconbaseline-close-px"></i>
        </span>
      </div>
    </transition>

    <!-- 反馈 -->
    <div class="function-btn">

    <technical-support ts_type_="pixiMap" contact_side="feedback" :id="id" v-show="show_feedback"></technical-support>
    </div>
    <!-- 发布红包 -->
    <publish-button :id="id" :id_type_="backUrl" v-if="is_admin" bottom="3rem"></publish-button>
    <!-- 红包雨 -->
    <red-packet
      :redPacketList="redPacketList"
      v-if="have_red_packet_rain"
      :id_type_="backUrl"
      :red_packet_type_="red_packet_type_"
      :red_packet_image_url="red_packet_image_url"
      :is_admin="is_admin"
    ></red-packet>
    <!-- 微信扫描二维码 -->
    <transition name='anim-opacity'>
      <div
        class="qrcode-box"
        v-show="showQrCode"
        v-if="map_data.wxacodeUrl"
        @click.self="showQrCode=false">
        <qr-code
          :showQrCode="showQrCode"
          :qrcode="map_data.wxacodeUrl"
          @closecode="showQrCode=false">
        </qr-code>
      </div>
    </transition>
    <!-- 天气信息 -->
    <div class="weather-box">
      <weather-info :info="weatherInfo" v-if="weatherInfo"></weather-info>
    </div>
  </div>
</template>

<script>
import * as PIXI from 'pixi.js'
import { GlowFilter } from '@pixi/filter-glow' // shadow

import { Viewport } from 'pixi-viewport'
import { TweenMax } from 'gsap'
import { Toast } from 'mint-ui'
import TechnicalSupport from '../../components/TechnicalSupport'
import PublishButton from '../../components/PublishButton'
import RedPacket from '../../components/RedPacket'
import MapUserList from '../../components/MapUserList'
import QrCode from '../../components/QrCode'
import WeatherInfo from '../../components/WeatherInfo'
import { iconStore } from '../../assets/utils/map-marker.js'
var wx = require('weixin-js-sdk')
export default {
  components: {
    TechnicalSupport, // 联系我们
    PublishButton,
    RedPacket,
    MapUserList,
    QrCode,
    WeatherInfo
  },
  data () {
    return {
      // 红包
      redPacketList: [],
      have_red_packet_rain: false,
      red_packet_type_: '',
      red_packet_image_url: '',
      is_admin: 0,

      /* 授权及相关 */
      ts_type_: 'label',
      park_id: 298,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      id: this.$route.query.id ? this.$route.query.id : 0,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      backUrl: 'pixiMap',

      show_top_menu: true, // 显示顶部按钮
      show_feedback: true, // 显示反馈
      show_spot_brief: false, // 显示景区简介
      /* 初始数据 */
      MAX_SCALE: 4,
      MIN_SCALE: 1,
      offsetWidth: '',
      offsetHeight: '',

      sprite_array: [],
      text_array: [],
      area_index:
        this.$route.query.area_index > -1 ? this.$route.query.area_index : -1,
      pixiApp: {},
      mapBg: '',
      IMG_WIDTH: 4000, // 图片宽度
      IMG_HEIGHT: 2000, // 图片高度
      PRE_PERSENT: '', // 图片初始化比例
      WINDOW_WIDTH: '', // 设备宽度
      WINDOW_HEIGHT: '', // 设备高度
      viewport: {}, // 保存viewport实例

      /* 判断设备类型 */
      PC: '', // 是否是PC
      mobile: '', // 手机型号

      /* 目录 */
      show_menu: false,
      first_menu_type: '-1', // 一级目录的type ，默认景点
      check_menu: -1,
      point_id: [0, 0], // 点位id， 0是上一个点击的点位，1是当前点击的点位
      show_point_brief: false, // 显示点位简介

      /* ajax数据 */
      logo_list: [], // 分区列表
      point_list: [], // 点位列表
      point_item: [], // 当前显示的点位
      menu_list: [], // 目录列表
      title_list: [], // 点位名称列表
      ImageUrl: '',
      map_data: [], // 地图信息
      tel_: '', // 电话
      is_have_spots_list: 0,
      is_show_spots_list: 0,
      is_pay: 0, // 是否支付
      price: 0, // 价格
      is_show_area: 0,

      // icon
      icon_object: iconStore,

      /* 搜索 */
      show_input: false, // 显示搜索框
      show_result: false, // 显示搜索结果
      search_value: '', // 搜索结果
      // 音频

      audio_dom: '',
      audio_name: '', // 正在播放的音频名称
      audio_play: false,
      bgmusic_play: true,

      // show_audio: false, // 显示播放界面

      // 分区
      show_area: false, // 显示区域logo

      // 点位精灵
      pre_sprite: '', // 上一个点位精灵

      // 分享
      have_chance: 0,
      have_chance_id: '',

      // 高德定位
      gd_map: '',
      local_lnglat: '', // 首次进入地图时的坐标

      line_list: [], // 路线数据
      path_arr: [],
      // 显示微信二维码
      showQrCode: false,
      // 天气
      weatherInfo: null
    }
  },
  computed: {
    show_audio: function () {
      if (!this.show_area && this.audio_play) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    // 根据id获取点位对象
    point_id: function (val) {
      if (val[1] < 0) {
        this.point_item = []
      } else if (this.first_menu_type === 'line') {
        this.point_item = this.line_list.filter((item, i) => {
          return item.id == val[1]
        })[0]
      } else {
        this.point_item = this.point_list.filter((item, i) => {
          return item.id == val[1]
        })[0]
      }
    }
  },
  mounted () {
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有union_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }

    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
      if (this.open_id) {
      } else {
        this.authorize()
      }
    }
    // 初始化缩放
    this.initScale()
    // 获取数据
    this.getData()
    // 初始化高德地图， 定位使用
    this.gd_map = this.gdMapInit()
    // 定位
    this.getLocation()
    // 获取audio元素
    this.$nextTick(() => {
      this.audio_dom = this.$refs.audio
      this.audio_dom.addEventListener('ended', () => {
        this.audio_play = false
        if (this.bgmusic_play) {
          this.bgmusic_play = false
        }
        this.checkIsPlay()
      })
      // console.log(this.audio_dom);
    })
  },
  created () {
    this.equipment()
  },
  methods: {
    // 画路线
    drawLine (point) {
      let path = point.path.split(';')

      const realPath = new PIXI.Graphics()
      const sc = this.PRE_PERSENT * 1
      realPath.lineStyle(4, 0xf48220, 0.8)
      realPath.moveTo(0, 0)

      path.forEach((item, i) => {
        let xy = item.split(','), x_ = xy[0] * sc, y_ = xy[1] * sc
        if (i === 0) {
          // 设置起点
          realPath.x = realPath.position.x = x_
          realPath.y = realPath.position.y = y_
          console.log(x_, y_)
        } else {
          realPath.lineTo(x_ - realPath.x, y_ - realPath.y)
        }
      })

      realPath.id = point.id
      this.path_arr.push(realPath)
      this.viewport.addChild(realPath)
    },
    // 清除所有路线
    clearAllLine () {
      this.path_arr.forEach(item => {
        this.viewport.removeChild(item)
      })
      this.path_arr = []
    },
    // 长文本截取
    clampText (tx, n) {
      if (tx.length <= n) {
        return tx
      } else {
        return tx.substr(0, n) + '...'
      }
    },

    // 根据距离排序
    sortByDistance (lnglat) {
      this.point_list.sort((item1, item2) => {
        let lnglat1 = [item1.lng * 1, item1.lat * 1]
        let lnglat2 = [item2.lng * 1, item2.lat * 1]
        let distance1 = this.gdDistance(this.gd_map, lnglat1, lnglat)
        let distance2 = this.gdDistance(this.gd_map, lnglat2, lnglat)
        item1.distance =
          Math.round(distance1) > 1000
            ? (distance1 / 1000).toFixed(2) + '千米'
            : Math.round(distance1) + '米'
        item2.distance =
          Math.round(distance2) > 1000
            ? (distance2 / 1000).toFixed(2) + '千米'
            : Math.round(distance2) + '米'
        return distance1 - distance2
      })
    },
    // 定位
    getLocation () {
      this.gdMapLocation({
        map: this.gd_map,
        type: '',
        callback: (lng, lat) => {
          if (lng && lat) {
            console.log(lng + ',' + lat)
            this.local_lnglat = [lng, lat]
            this.sortByDistance([lng, lat])
          } else {
            Toast('定位错误')
          }
        }
      })
    },
    // 隐藏点位简介
    pointBriefHide () {
      this.show_point_brief = false
      this.pre_sprite.filters = null
    },
    // 跳转
    jumpToDetail () {
      if (
        this.point_item.is_have_detail == 0 ||
        this.point_item.type_ == 17 ||
        this.point_item.type_ == 6
      ) {

      } else {
        this.$router.push({
          path: '/footprints/PrimaryScenic',
          query: {
            id_: this.id,
            sid: this.point_item.id
          }
        })
      }
    },
    // 播放背景音乐
    playBgMusic () {
      if (this.bgmusic_play) {
        this.bgmusic_play = false
        this.audio_dom.pause()
      } else {
        // console.log(this.audio_dom);
        this.checkIsPlay() // 关闭点位播放音频
        this.bgmusic_play = true
        this.audio_play = false // 背景音乐不需要播放界面
        this.audio_dom.src = this.map_data.bgAudioUrl
        this.audio_dom.play()
      }
    },
    // 根据目录的ID值选择图片
    choseIconByType (id) {
      let flag = typeof id !== 'string'

      if (flag) {
        if (id.includes(this.first_menu_type[0])) {
          return this.icon_object['icon_act_' + id[0]]
        } else {
          return this.icon_object['icon_' + id[0]]
        }
      } else {
        if (this.first_menu_type == id) {
          return this.icon_object['icon_act_' + id]
        } else {
          return this.icon_object['icon_' + id]
        }
      }
    },
    // 停止播放
    closeAudio () {
      this.audio_dom.pause()
      this.audio_play = false
      this.checkIsPlay()
    },
    // 修改播放标记
    checkIsPlay () {
      this.point_list.forEach(item => {
        if (item.is_play != 0) {
          item.is_play = 0
        }
      })
    },
    // 音频
    playAudio () {
      if (this.is_weixin) {
        if (this.price == 0 || (this.price > 0 && this.is_pay == 1)) {
          if (this.point_item.zhAudioUrl) {
            if (this.point_item.is_play != 0) {
              this.point_item.is_play = 0
              this.audio_dom.pause()
            } else {
              this.checkIsPlay()
              this.bgmusic_play = false // 关闭背景音乐开关
              this.point_item.is_play = 1
              this.audio_play = true
              this.audio_name = this.point_item.spots_name
              this.audio_dom.src = this.point_item.zhAudioUrl
              this.audio_dom.play()
            }
          } else {
            Toast('音频播放错误')
          }
        } else {
          // 还未支付
          if (this.open_id) {
            // 进行支付
            var open_url =
              'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
              this.id +
              '&backUrl=' +
              this.backUrl +
              '&open_id=' +
              this.open_id +
              '&token=' +
              this.token +
              '&park_id=' +
              this.park_id +
              '&version=' +
              this.version +
              '&user_id='
            window.location.href = open_url
          } else {
            // 获取微信授权
            this.authorize()
          }
        }
      } else {
        if (this.map_data.wxacodeUrl) {
          this.showQrCode = true
        } else {
          Toast('请在微信中打开')
        }
      }
    },
    // 导航
    jumpPos () {
      if (this.is_weixin) {
        this.wx_nav(
          window.location.href,
          this.point_item.lng,
          this.point_item.lat,
          this.point_item.spots_name,
          this.point_item.address
        )
      } else {
        if (this.map_data.wxacodeUrl) {
          this.showQrCode = true
        } else {
          Toast('请在微信中打开')
        }
      }
    },
    // 根据景点名过滤
    filterPointListByName (list) {
      return list.filter(item => {
        return item.spots_name.match(this.search_value)
      })
    },

    //   viewport点击事件
    viewportClickHandle () {
      this.show_menu = false
      this.show_input = false
    },
    // 根据类型过滤
    filterPointListByType (list) {
      let flag = typeof this.first_menu_type !== 'string'
      if (!flag) {
        if (
          this.first_menu_type != 15 &&
          this.first_menu_type != 16 &&
          this.first_menu_type != 17 &&
          this.first_menu_type != 6 &&
          this.first_menu_type != 7
        ) {
          // 如果是景点，需要双层过滤
          let spot_list = list.filter(item => {
            // 根据类型过滤
            return item.type_ == this.first_menu_type
          })
          return spot_list.filter(item => {
            // 根据搜索过滤
            return item.spots_name.match(this.search_value)
          })
        } else {
          // 不是景点没有搜索
          return list.filter(item => {
            return item.type_ == this.first_menu_type
          })
        }
      } else if (flag) {
        if (
          !this.first_menu_type.includes(15) &&
          !this.first_menu_type.includes(16) &&
          !this.first_menu_type.includes(17) &&
          !this.first_menu_type.includes(6) &&
          !this.first_menu_type.includes(7)
        ) {
          let spot_list = list.filter(item => {
            return this.first_menu_type.includes(item.type_ * 1)
          })
          return spot_list.filter(item => {
            // 根据搜索过滤
            return item.spots_name.match(this.search_value)
          })
        } else {
          // 不是景点没有搜索
          return list.filter(item => {
            return this.first_menu_type.includes(item.type_ * 1)
          })
        }
      } else {
        console.log('类型错误')
      }
    },
    menuBtnHandle () {
      this.show_menu = !this.show_menu
      if (this.show_menu) {
        // this.pointBriefHide();
        this.search_value = ''
        if (this.first_menu_type < 0 || this.first_menu_type[0] < 0) {
          this.first_menu_type = this.menu_list[0].id
          this.check_menu = 0
          this.sprite_array.forEach(item => {
            if (
              (typeof this.first_menu_type === 'string' &&
                this.first_menu_type == item.type_) ||
              (typeof this.first_menu_type === 'object' &&
                this.first_menu_type.includes(item.type_ * 1))
            ) {
              item.visible = true
            } else {
              item.visible = false
            }
          })
        }
      }
    },
    // 弹出的目录图标
    filterIconByType (type) {
      return this.icon_object['iconMap' + type]
    },
    // 点击目录按钮
    menuClickHandle (type, index) {
      // 类型是字符串，flag为false， 类型是数组，flag 为 true
      let flag = typeof type !== 'string'
      if (this.show_menu) {
        this.check_menu = index
        // 目录显示
        this.showMenu(type, index)
        this.first_menu_type = type
        this.sprite_array.forEach(item => {
          if (
            (!flag && type == item.type_) ||
            (flag && type.includes(item.type_ * 1) || type == 'line')
          ) {
            item.visible = true
          } else {
            item.visible = false
          }
        })
      } else {
        // 目录隐藏
        if (this.check_menu !== index) {
          this.check_menu = index
          this.first_menu_type = type
          this.sprite_array.forEach(item => {
            if (
              (!flag && type == item.type_) ||
              (flag && type.includes(item.type_ * 1) || type == 'line')
            ) {
              item.visible = true
            } else {
              item.visible = false
            }
          })
        } else {
          this.sprite_array.forEach(item => {
            item.visible = true
          })
          this.check_menu = -1
          this.first_menu_type = flag ? [-1] : '-1'
        }

        this.clearAllLine()
        if (type === 'line') {
          this.line_list.forEach(item => {
            this.drawLine(item)
          })
        }
      }
    },
    //   显示目录列表
    showMenu (type, index) {
      let flag = typeof this.first_menu_type !== 'string'
      if (
        (!flag && this.first_menu_type == type) ||
        (flag && this.first_menu_type[0] === type[0])
      ) {
        this.show_menu = false
        this.check_menu = index
      }
    },
    getData () {
      if (
        this.park_id &&
        this.id &&
        this.token &&
        this.open_id &&
        this.version
      ) {
        this.$ajax(
          'post',
          'SpotsRace/get_map_data_by_label',
          {
            park_id: this.park_id,
            id: this.id,
            page: 1,
            where: 'web',
            token: this.token,
            is_type: 2,
            open_id: this.open_id,
            version: this.version,
            id_type_: this.backUrl
          },
          ret => {
            if (ret.data.id == 0) {
              this.map_data = ret.data.data.map_data || '' // 地图信息
              this.point_list = ret.data.data.point_list || '' // 点位信息
              this.line_list = ret.data.data.line_list || '' // 路线列表
              this.menu_list = ret.data.data.bottom_menu_list || '' // 底部目录列表
              this.first_menu_type =
                typeof this.menu_list[0].id === 'string' ? '-1' : [-1]
              this.logo_list = ret.data.data.logo_list || '' // 分区列表
              this.title_list = ret.data.data.title_list || '' // 点位名称列表
              this.ImageUrl = ret.data.data.map_data.ImageUrl || ''
              this.tel_ = ret.data.data.tel_ // 客服电话
              this.is_admin = ret.data.data.is_admin || 0
              this.is_show_area = ret.data.data.is_show_area || 0
              this.redPacketList =
                ret.data.data.red_packet.red_packet_rain_image_url_list || ''
              this.have_red_packet_rain =
                ret.data.data.red_packet.have_red_packet_rain || 0
              this.red_packet_type_ =
                ret.data.data.red_packet.red_packet_type_ || 0
              this.red_packet_image_url =
                ret.data.data.red_packet.red_packet_image_url || ''

              document.title = this.map_data.label_name || ''
              if (ret.data.data.point_list) {
                this.is_have_spots_list = 1
              } else {
                this.is_have_spots_list = 0
              }
              if (
                ret.data.data.map_data.width_ > 0 &&
                ret.data.data.map_data.height_ > 0
              ) {
                this.IMG_WIDTH = ret.data.data.map_data.width_
                this.IMG_HEIGHT = ret.data.data.map_data.height_
              }
              this.is_pay = ret.data.data.is_pay
              this.price = ret.data.data.price
              // 初始化pixi
              this.initPixi()

              // 获取天气信息
              this.getWeatherByCity(this.map_data.ecity_).then(wt => {
                this.weatherInfo = wt
              }, er => {
                console.log(er)
              })
              // 微信分享
              let wx_title = ret.data.data.map_data.label_name,
                wx_content = ret.data.data.map_data.content,
                wx_img =
                  ret.data.data.map_data.logo_ || ret.data.data.map_data.map_

              let sUrl = window.location.href
              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                })
              }
            }
          }
        )
      } else {
        this.authorize()
      }
    },
    // 初始化pixi
    initPixi () {
      // 设置初始缩放值,这个值并没有实际的加载价值,在数据初始化之后,就进行修改
      this.PRE_PERSENT = parseFloat(this.offsetHeight / this.IMG_HEIGHT)

      this.PRE_PERSENT = this.PC
        ? this.offsetWidth / this.IMG_WIDTH
        : this.PRE_PERSENT

      // console.log(this.PRE_PERSENT+','+this.IMG_HEIGHT);
      this.MIN_SCALE = Math.max(
        (this.PRE_PERSENT * this.IMG_WIDTH) / this.WINDOW_WIDTH,
        (this.PRE_PERSENT * this.IMG_HEIGHT) / this.WINDOW_HEIGHT
      )

      // 判断是否支持WEBGL
      var type = 'WebGL'
      /* 创建一个 pixi 舞台 */
      this.mapBg = '/static/map/map_' + this.id + '.jpg'
      const isIOS = navigator.userAgent.match(/iphone/i)
      this.pixiApp = new PIXI.Application({
        width: this.offsetWidth,
        height: this.offsetHeight,
        antialias: true, // 抗锯齿
        resolution: 2, // 分辨率
        autoDensity: true,
        forceCanvas: isIOS // 如果是 ios ，则使用 canvas引擎， 默认使用webgl引擎
        // threshold: 30, // 要移动以触发输入事件（例如，拖动，捏合）或禁用单击事件的像素数
        // stopPropagation: true // 停止影响视口的事件的传播
      })

      // 设置舞台样式
      this.pixiApp.renderer.backgroundColor = 0x505555 // 画布的背景颜色
      this.pixiApp.renderer.view.style.position = 'absolute'
      this.pixiApp.renderer.view.style.display = 'block'
      this.pixiApp.renderer.autoResize = true // 用于宽高格式的正确

      //  将 pixi 添加到页面
      document.getElementById('pixi-body').appendChild(this.pixiApp.view)
      /* 创建 PIXI 结束 */

      /* 创建viewport */
      this.viewport = new Viewport({
        screenWidth: this.WINDOW_WIDTH,
        screenHeight: this.WINDOW_HEIGHT,
        worldWidth: this.PRE_PERSENT * this.IMG_WIDTH,
        worldHeight: this.PRE_PERSENT * this.IMG_HEIGHT,
        interaction: this.pixiApp.renderer.plugins.interaction // 该模块用于移动或缩放
      })

      this.viewport
        .drag()
        .pinch()
        .wheel()
        .bounce()

      // 绘制背景
      var texture = PIXI.Texture.from(this.mapBg)
      var bg = new PIXI.Sprite(texture)

      this.viewport.clampZoom({
        maxHeight: this.WINDOW_HEIGHT * this.MIN_SCALE,
        maxWidth: this.WINDOW_WIDTH * this.MIN_SCALE,
        minHeight: this.WINDOW_HEIGHT * 0.1,
        minWidth: this.WINDOW_WIDTH * 0.1
      })

      bg.zOrder = 100
      bg.width = this.PRE_PERSENT * this.IMG_WIDTH
      bg.height = this.PRE_PERSENT * this.IMG_HEIGHT

      // 设置窗口对应的位子

      this.viewport.left = (bg.width - this.WINDOW_WIDTH) / 2
      this.viewport.top = (bg.height - this.WINDOW_HEIGHT) / 2

      this.viewport.addChild(bg) // 将 viewport 添加进PIXI舞台
      this.pixiApp.stage.addChild(this.viewport)

      this.viewport.on('clicked', this.viewportClickHandle)

      // 先加载点
      // console.log(this.point_list);
      if (this.point_list) {
        var sprite_list = this.point_list
        var sprite = ''
        for (let i = 0; i < sprite_list.length; i++) {
          if (sprite_list[i].have_audio) {
            sprite = PIXI.Sprite.from(this.icon_object['iconMapAudio'])
          } else {
            if (this.icon_object['iconMap' + sprite_list[i].type_]) {
              sprite = PIXI.Sprite.from(this.icon_object['iconMap' + sprite_list[i].type_])
            } else {
              sprite = PIXI.Sprite.from(this.icon_object['iconMapNormal'])
            }
          }

          if (sprite_list[i].x_ == 0 || sprite_list[i].y_ == 0) {
            sprite_list[i].x_ = Math.round(Math.random() * 3800)
            sprite_list[i].y_ = Math.round(Math.random() * 1800)
          }
          sprite.anchor.set(0.5, 1)
          sprite.position.set(sprite_list[i].x_, sprite_list[i].y_)
          sprite.id = sprite_list[i].id
          sprite.scale.set(0.8, 0.8) // 放大几倍
          sprite.interactive = true
          sprite.name = sprite_list[i].spots_name
          sprite.index_ = i
          sprite.type_ = sprite_list[i].type_

          sprite
            .on('pointerup', this.onPointerUp)
            .on('mouseup', this.onMouseUp)

          // bg.addChild(text);
          bg.addChild(sprite)
          // text.visible = false;//隐藏文字
          // this.text_array.push(text);
          this.sprite_array.push(sprite)
        }
      } else {
        Toast('暂无数据')
      }
      // 保存背景精灵对象
      this.bgSprite = bg
      if (this.area_index > -1) {
        // console.log(this.logo_list[this.area_index]);
        let sprite = {
          x: this.logo_list[this.area_index].x_,
          y: this.logo_list[this.area_index].y_
        }
        this.viewportMove(sprite)
      }
    },
    // 点位精灵点击
    onPointerUp (e) {
      console.log(e)
      let sprite = e.target
      this.spriteGlow(sprite)
      this.viewportMove(sprite)
      this.show_point_brief = true
      this.point_id.shift()
      this.point_id.push(sprite.id)
    },
    // 当前精灵发光
    spriteGlow (sprite) {
      if (this.pre_sprite == '') {
        sprite.filters = [new GlowFilter(10, 4, 0, 0xecfe68, 0.5)]
        this.pre_sprite = sprite
      } else {
        this.pre_sprite.filters = null
        // console.log(sprite);
        this.pre_sprite = sprite
        sprite.filters = [new GlowFilter(10, 4, 0, 0xecfe68, 0.5)]
      }
    },
    // 目录点击
    menuLocation (point) {
      this.point_id.shift()
      this.point_id.push(point.id)
      if (this.first_menu_type === 'line') {
        // 路线
        if (!this.path_arr.length) {
          this.drawLine(point)
        } else if (this.path_arr.length === 1) {
          this.viewport.removeChild(this.path_arr[0])
          if (this.path_arr[0].id !== point.id) {
            this.drawLine(point)
          }
          this.path_arr.shift()
        } else {
          this.clearAllLine()
          this.drawLine(point)
        }

        this.point_id = point.id
      } else {
        let sprite = this.sprite_array.filter(item => {
          return item.id == point.id
        })[0]

        this.spriteGlow(sprite)
        this.viewportMove(sprite)
        this.show_menu = false
        this.show_point_brief = true
      }
    },
    // 点击logo
    areaLocation (x, y) {
      let sprite = {
        x: x,
        y: y
      }
      this.viewportMove(sprite)
    },
    // 移动viewport
    viewportMove (sprite) {
      // console.log(sprite);
      // console.log(this.viewport);
      var scale = this.viewport.scale.x

      var target_left =
          sprite.x * this.PRE_PERSENT - this.WINDOW_WIDTH / scale / 2,
        target_top =
          sprite.y * this.PRE_PERSENT - this.WINDOW_HEIGHT / scale / 2
      let v_x = this.PRE_PERSENT * this.IMG_WIDTH - this.WINDOW_WIDTH / scale
      let v_y = this.PRE_PERSENT * this.IMG_HEIGHT - this.WINDOW_HEIGHT / scale

      if (this.viewport.height >= this.WINDOW_HEIGHT) {
        if (target_top < 0) {
          target_top = 0
        } else if (target_top > v_y) {
          target_top = v_y
        }
      } else {
        target_top = (this.viewport.height * scale - this.WINDOW_HEIGHT) / 2
        if (target_top > 0) {
          target_top = 0
        } else if (target_top < v_y) {
          target_top = v_y
        }
      }
      if (this.viewport.width >= this.WINDOW_WIDTH) {
        if (target_left < 0) {
          target_left = 0
        } else if (target_left > v_x) {
          target_left = v_x
        }
      } else if (this.viewport.width < this.WINDOW_WIDTH) {
        target_left = (this.viewport.width - this.WINDOW_WIDTH) / 2
        if (target_left > 0) {
          target_left = 0
        } else if (target_left < v_x) {
          target_left = v_x
        }
      }
      if (scale >= 1) {
        TweenMax.to(this.viewport, 0.3, { left: target_left, top: target_top })
      } else {
        TweenMax.to(this.viewport, 0.3, { left: target_left })
      }
    },
    onMouseUp () {},
    initScale () {
      // 获取浏览器宽高
      this.WINDOW_WIDTH = window.innerWidth
      this.WINDOW_HEIGHT = window.innerHeight
      //   获取body宽高
      this.offsetWidth = document.querySelector('body').offsetWidth
      this.offsetHeight = document.querySelector('body').offsetHeight
      // 上面两个值是相等的
      // console.log(offsetWidth+','+offsetHeight+','+this.WINDOW_WIDTH+','+this.WINDOW_HEIGHT);
    },
    // 获取设备类型
    equipment () {
      this.PC = this.isPC()
      this.mobile = this.phoneType()
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&state=' +
        this.backUrl
      window.location.href = open_url
    },

    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id == 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var open_id = this.open_id
            if (info != '') {
              if (info.url != '') {
                let url_arr = info.url.split('share_open_id')

                url = url_arr[0] + '&share_open_id=' + open_id
              } else {
                url = url + '&share_open_id=' + open_id
              }
            } else {
              url = url + '&share_open_id=' + open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id === 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    }
  }
}
</script>

<style scoped>
.pixi-map-container {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
}
.dylan .pixi-body {
  position: relative;
  width: 100%;
  height: 100vh;
}
/* 顶部分区 */
.area-fade-enter,
.area-fade-leave-to {
  transform: translateX(-100%);
}
.area-fade-enter-to,
.area-fade-leave {
  transform: translateY(0);
}
.area-fade-enter-active,
.area-fade-leave-active {
  transition: ease 0.3s;
}
.dylan .pixi-logo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
}
.dylan .pixi-logo .logo-wrapper {
  width: 100%;
  height: 4rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 2px 20px rgb(95, 95, 95);
  display: flex;
}
.dylan .pixi-logo .logo-wrapper .logo-btn {
  width: 1.6rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.623); */
}
.dylan .pixi-logo .logo-wrapper .logo-btn i {
  transform: rotate(180deg);
  color: #fff;
  font-size: 1.6rem;
}
.dylan .pixi-logo .logo-menu {
  /* width: 100%; */
  flex: auto;
  height: 4rem;
  box-sizing: border-box;
  display: inline-flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.dylan .pixi-logo .logo-menu .logo-menu-list {
  width: 100%;
  text-align: center;
}
.dylan .pixi-logo .logo-menu .logo-menu-list img {
  height: 4rem;
  /* height: 2rem; */
}
/* 顶部导航 */
.top-fade-enter,
.top-fade-leave-to {
  transform: translateX(100%);
}
.top-fade-enter-to,
.top-fade-leave {
  transform: translateX(0);
}
.top-fade-enter-active,
.top-fade-leave-active {
  transition: ease 0.3s;
}
.dylan .pixi-top {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 0.5rem;
}
.dylan .pixi-top .top-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  justify-content: space-between;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 2px #aaa;
}
.dylan .pixi-top .top-menu-li {
  display: flex;
  flex-direction: column;
  /* padding: 4px 6px; */
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2.2rem;
}
.dylan .pixi-top .top-menu-li p {
  color: #00000066;
  font-size: 0.6rem;
}
.dylan .pixi-top .top-brief i {
  color: rgb(250, 88, 59);
  font-size: 0.9rem;
}
.dylan .pixi-top .top-zone i {
  color: rgb(250, 119, 59);
  font-size: 0.9rem;
}
.dylan .pixi-top .top-music {
  margin-top: 6px;
}
.dylan .pixi-top .top-music i {
  font-size: 1rem;
}
.dylan .pixi-top .top-music i.iconswitch_open {
  color: rgb(250, 119, 59);
}
.dylan .pixi-top .top-music i.iconswitch_close {
  color: #00000066;
}

/* 底部导航 */
.dylan .pixi-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 2.4rem;
  overflow: hidden;
  width: 100%;
  z-index: 501;
  background-color: #fff;
  transition: ease 0.3s;
  box-shadow: 0 0 1px #e9e9e9;
}
.dylan .pixi-footer-active {
  height: 19rem;
  transition: ease 0.3s;
}
.dylan .pixi-footer .footer-menu {
  height: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #eee; */
}
.dylan .pixi-footer .footer-menu .footer-menu-list {
  width: calc(100% - 2.4rem);
  height: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dylan .pixi-footer .footer-menu .menu-item {
  width: 100%;
  height: 100%;
  color: #666;
  text-align: center;
  /* line-height: 2.4rem; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-top: 1px solid rgb(45, 63, 85); */
}
.dylan .pixi-footer .footer-menu .menu-item-active {
  color: #333;
}
.dylan .pixi-footer .footer-menu .menu-item img {
  /* width: 1rem; */
  height: 0.88rem;
}
.dylan .pixi-footer .footer-btn {
  width: 2.4rem;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
  color: #666;
}
.dylan .pixi-footer .footer-btn i {
  font-size: 1rem;
}
.dylan .pixi-footer-active .footer-btn {
  transform: rotate(180deg);
}
/* 搜索 */
.dylan .footer-search {
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* padding: 0.5rem 0; */
  margin-bottom: 0.4rem;
}
.dylan .footer-search .search-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background: #fff;
}
.dylan .footer-search .search-wrapper .search-input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 40px 0 12px;
}
.dylan .footer-search .search-wrapper .search-input input {
  width: 100%;
  height: 100%;
  font-size: 12px;
}
.dylan .footer-search .search-wrapper .search-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
}
.dylan .footer-search .search-wrapper .search-icon i {
  font-size: 1rem;
  color: #999;
}
/* 目录列表 */
.dylan .pixi-footer .footer-nav {
  width: 100%;
  height: calc(100% - 2.4rem);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
}
.dylan .pixi-footer .footer-nav .nav-wrapper {
  width: 100%;
  height: 13.2rem;
  overflow: auto;
}
.dylan .pixi-footer .footer-nav .footer-nav-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.dylan .pixi-footer .footer-nav .footer-nav-list .nav-item {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.footer-nav-list .nav-item-active {
  background-color: rgba(4, 159, 248, 0.1);
}
.nav-item .distance {
  margin-left: 6px;
  font-size: 0.68rem;
  color: #aeaeae;
}
.dylan .pixi-footer .footer-nav .footer-nav-list .nav-item img {
  width: 1.2rem;
  /* margin-right: 6px; */
}

/* 点位简介 */
.brief-fade-enter,
.brief-fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.brief-fade-enter-to,
.brief-fade-leave {
  transform: translateY(0);
  opacity: 1;
}
.brief-fade-enter-active,
.brief-fade-leave-active {
  transition: ease 0.3s;
}
.dylan .point-brief {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 2.4rem;
  box-sizing: border-box;
  padding: 0.5rem;
  z-index: 500;
  /* background-color: rgba(0, 0, 0, 0.3); */
}
.dylan .point-brief .brief-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 4px #ccc;
}
.dylan .point-brief .brief-wrapper .brief-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
  /* border-radius: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #aaa; */
  text-align: center;
}
.dylan .point-brief .brief-wrapper .brief-close i {
  font-size: 1rem;
  color: #999;
}
.dylan .point-brief .brief-wrapper .brief-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 4px;
}
.dylan .brief-header .brief-icon {
  position: relative;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dylan .brief-header .brief-icon .audio-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.dylan .brief-header .brief-icon .audio-btn-play {
  font-size: 1.5rem;
  color: #fff;
}
.dylan .brief-header .brief-icon img {
  width: 100%;
  /* height: 100%; */
}
.dylan .brief-header .brief-title {
  position: relative;
  height: 100%;
  width: calc(100% - 7rem);
  margin-right: 0.5rem;
}
.dylan .brief-header .brief-title .brief-title-tip {
  left: 50%;
  transform: translateX(-50%);
  bottom: -100%;
  position: absolute;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url(../../assets/switch/shou.gif) no-repeat;
  background-size: contain;
}
.dylan .brief-header .brief-title h2 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  text-align: center;
}
.dylan .brief-header .brief-nav {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dylan .brief-header .brief-nav i {
  font-size: 1.2rem;
  color: #666;
}

/* 景区简介 */
.spot-brief-enter,
.spot-brief-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.spot-brief-enter-to,
.spot-brief-leave {
  transform: translateY(0);
  opacity: 1;
}
.spot-brief-enter-active,
.spot-brief-leave-active {
  transition: 0.3s;
}
.dylan .pixi-spot-brief {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 2.4rem;
  box-sizing: border-box;
  padding: 0.5rem 2rem;
  z-index: 500;
}
.dylan .pixi-spot-brief .spot-brief-wrapper {
  position: relative;
  width: 100%;
  border-radius: 2px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 0 2px #aaa;
  padding-bottom: 1rem;
}
.dylan .pixi-spot-brief .spot-brief-wrapper .brief-close {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  width: 1.4rem;
  height: 1.4rem;
  /* background: rgba(0, 0, 0, 0.3); */
  /* color: #333;  */
  text-align: center;
  line-height: 1.4rem;
  border-radius: 50%;
}
.dylan .pixi-spot-brief .spot-brief-wrapper .brief-close i {
  color: #333;
  font-size: 1rem;
}

.dylan .pixi-spot-brief .spot-brief-icon {
  position: absolute;
  top: -1rem;
  left: 0.5rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  border: 2px solid rgb(255, 234, 175);
}
.dylan .pixi-spot-brief .spot-brief-icon img {
  height: 100%;
  width: 100%;
}
.dylan .spot-brief-header {
  margin-left: 4rem;
}

.dylan .spot-brief-header h2 {
  font-size: 0.8rem;
  height: 2rem;
  line-height: 2rem;
  color: #333;
  font-weight: 600;
}
.dylan .spot-brief-info {
  /* margin-top: 0.4rem; */
  box-sizing: border-box;
  padding: 10px 0.6rem;
}
.dylan .spot-brief-info .spot-brief-item {
  font-size: 12px;
  color: #333;
  display: flex;
  padding-bottom: 10px;
}
.dylan .spot-brief-info .spot-brief-item .item-name {
  white-space: nowrap;
}
.dylan .spot-brief-info .spot-brief-item .item-value {
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dylan .spot-brief-info .spot-brief-time {
  border-bottom: 1px solid #eee;
}
.dylan .spot-brief-info p i {
  font-size: 0.68rem;
  color: #999;
}

.dylan .spot-brief-content {
  padding: 0 0.6rem;
  box-sizing: border-box;
  color: #333;
  font-size: 0.76rem;
  width: 100%;
}
.dylan .spot-brief-content article {
  max-height: 10rem;
  overflow-y: auto;
}
.dylan .spot-brief-content article >>> span {
  font-size: 14px !important;
}

/* 播放界面 */
.audio-fade-enter,
.audio-fade-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
.audio-fade-enter-to,
.audio-fade-leave {
  opacity: 1;
  transform: translateY(0);
}
.audio-fade-enter-active,
.audio-fade-leave-active {
  transition: 0.3s;
}
.dylan .audio-play {
  position: fixed;
  left: 20%;
  top: 0.5rem;
  width: 60%;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.dylan .audio-play .audio-play-icon {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  animation: audio 4s linear infinite;
}
@keyframes audio {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dylan .audio-play .audio-play-icon img {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
}
.dylan .audio-play .audio-play-name {
  font-size: 0.8rem;
  color: #fff;
}
.dylan .audio-play .audio-play-close {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dylan .audio-play .audio-play-close i {
  font-size: 1rem;
  color: #fff;
}

/* 左下功能按钮 */
.function-btn {
  position: fixed;
  bottom: 3rem;
  left: 0.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 401;
}

/* 微信二维码 */
.qrcode-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* 天气信息 */
.weather-box {
  width: calc(100vw - 3.5rem);
  position: fixed;
  top: 3rem;
  left: .5rem;
  z-index: 401;
}
</style>
