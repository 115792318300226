<template>
  <div>
    <ServiceTitle></ServiceTitle>

    <div class="page-content">
      <div class="new-title-content">游玩详情</div>
      <div class="withdraw-wrap">
        <router-link :to="returnlink" class="menuList">
          <mt-cell is-link>
            <div slot="title">
              <i class="need"></i>
              <span>出发和返回</span>
            </div>
            {{route}}
          </mt-cell>
        </router-link>

        <router-link :to="tipslink" class="menuList">
          <mt-cell is-link>
            <div slot="title">
              <i class="need"></i>
              <span>注意事项</span>
            </div>
            {{tips}}
          </mt-cell>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceTitle from "../../components/ServiceTitle";
import { Toast } from "mint-ui";

export default {
  name: "VisitDetail",
  components: {
    ServiceTitle
  },
  data() {
    return {
      describelink: "",
      returnlink: "",
      tipslink: "",
      route: localStorage.route,
      tips: localStorage.notes
    };
  },
  mounted() {
    this.getServiceType();
  },
  methods: {
    getServiceType() {
      var stype = this.$route.query.type;

      //                this.describelink = '/footguide/VisitedDescribe?type='+stype;
      this.returnlink = "/footguide/GoAndReturn?type=" + stype;
      this.tipslink = "/footguide/AttentionTips?type=" + stype;
    }
  }
};
</script>
