<template>
  <div v-title data-title="私信">
    <div class="message">
      <mt-header fixed title="私信">
        <a slot="left">
          <mt-button icon="back" @click="$router.go(-1)"></mt-button>
        </a>
      </mt-header>
    </div>
    <div class="page-content">
      <div class="container container-wh" v-if="chatList != '' ">
        <div class="message-list">
          <mt-loadmore
            :bottom-method="loadBottom"
            :bottom-all-loaded="allLoaded"
            ref="loadmore"
            :auto-fill="false"
          >
            <div
              class="user-card clearfix"
              v-for="(chat,i) in chatList"
              :key="chat.id"
              :id="chat.id"
            >
              <div class="avatar">
                <router-link
                  :to="{name:'otherIndex', params: { uid: chat.user_id }}"
                  v-if="chat.user_id != user_id"
                >
                  <img :src="chat.user_icon" />
                </router-link>
                <router-link
                  :to="{name:'MyIndex', params: { uid: chat.user_id }}"
                  v-if="chat.user_id == user_id"
                >
                  <img :src="chat.user_icon" />
                </router-link>
              </div>
              <div class="name">
                <span>
                  <router-link
                    :to="{name:'otherIndex', params: { uid: chat.user_id }}"
                    v-if="chat.user_id != user_id"
                  >{{chat.user_nick}}</router-link>
                  <router-link
                    :to="{name:'MyIndex', params: { uid: chat.user_id }}"
                    v-else
                  >{{chat.user_nick}}</router-link>
                </span>
                <div class="chat-content" @click="chatWindow(chat.user_id)">
                  <div class="chat-time">{{chat.user_ask_time}}</div>
                  <div class="last-chat">{{chat.content}}</div>
                </div>
              </div>
              <div
                class="btn fr atte no-each"
                :id="chat.user_id"
                v-if="chat.cancel_attention == 1"
                @click="attention(chat.user_id,i)"
              >已关注</div>
              <div
                class="btn fr atte followed"
                :id="chat.user_id"
                v-if="chat.cancel_attention == 2  && chat.user_id != user_id"
                @click="attention(chat.user_id,i)"
              >互相关注</div>
              <div
                class="fr atte attention"
                :id="chat.user_id"
                v-if="chat.cancel_attention == 0"
                @click="attention(chat.user_id,i)"
              >+关注</div>
            </div>
          </mt-loadmore>
        </div>
      </div>
      <div v-else class="foot_noinfo">
        <img src="/static/img/foot_noinfo@2x.14391c2.png" alt />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>
<style scoped>
.message-list .user-card {
  padding: 0.5rem;
  border-bottom: 1px solid #f2f2f2;
}

.message-list .user-card .avatar {
  width: 3rem;
  height: 3rem;
  float: left;
  margin-right: 1.15rem;
}

.message-list .user-card .name {
  float: left;
  width: 60%;
}

.message-list .user-card .name span a {
  color: #00cbbd;
  font-size: 0.7rem;
}

.message-list .user-card .chat-time {
  color: #999;
  font-size: 0.6rem;
}

.message-list .user-card .last-chat {
  font-size: 0.6rem;
  color: #151515;
  word-wrap: break-word;
}

.message-list .user-card .btn {
  margin-top: 1rem;
  width: 18%;
  color: #999;
  padding-top: 1.5rem;
  font-size: 0.5rem;
}

.message-list .user-card .attention {
  margin-top: 1rem;
  width: 2.9rem;
  height: 1.2rem;
  line-height: 1.2rem;
  border: 1px solid #999;
  border-radius: 0.25rem;
  color: #ff9a80;
  text-align: center;
  font-size: 0.6rem;
}

.message-list .user-card .followed {
  background: url(../../assets/img/btn_followeach.png) no-repeat;
  background-size: 1.2rem;
  background-position: top center;
}

.message-list .user-card .no-each {
  background: url(../../assets/img/btn_followed.png) no-repeat;
  background-size: 1.2rem;
  background-position: top center;
}
</style>
<script>
import { Indicator, Loadmore } from "mint-ui";
import { Toast } from "mint-ui";

export default {
  data() {
    return {
      chatList: [],
      user_id: "",
      page: 1,
      allLoaded: false, //是否加载
      totalCount: "" //初始化方法中的数量总数
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      this.getChat(this.page);
    },

    // 得到消息
    getChat(page) {
      var that = this;

      var user_id = this.get_User_Id();
      this.user_id = user_id;
      var data = {
        user_id: user_id,
        page: page
      };

      this.$ajax("post", "Common/getLetter", data, function(res) {
        //                    console.log(res.data.data);
        if (res.data.id == 0) {
          that.chatList = that.chatList.concat(res.data.data);
          that.totalCount = res.data.data.length;
        }
      });
    },

    //  下拉加载页面
    loadBottom() {
      setTimeout(() => {
        if (this.totalCount % 10 == 0) {
          this.page = this.page + 1;
          this.getChat(this.page);
        } else {
          this.allLoaded = true; // 若数据已全部获取完毕
          Toast("没有更多了");
        }
        this.$refs.loadmore.onBottomLoaded();
      }, 500);
    },

    // 开启聊天窗口
    chatWindow(value) {
      this.$router.push({ name: "ChatWindow", params: { uid: value } });
    },

    // 关注
    attention(uid, i) {
      var data = {
        user_id: this.user_id,
        attention_id: uid
      };
      var that = this;
      this.$ajax("post", "foot/clickAttentionButton", data, function(res) {
        var ret = res.data;
        if (ret.id == 0) {
          Toast("取消关注成功");
          that.$set(that.chatList[i], "cancel_attention", 4);
        } else {
          Toast("操作失败，请稍后再试！");
        }
      });
    }
  }
};
</script>