<template>
  <div v-title data-title="评论">
    <div class="message">
      <mt-header fixed title="评论">
        <a slot="left">
          <mt-button icon="back" @click="$router.go(-1)"></mt-button>
        </a>
      </mt-header>
    </div>

    <div class="page-content">
      <div class="container container-wh">
        <div class="message-list" v-if="discussList != '' ">
          <mt-loadmore
            :bottom-method="loadBottom"
            :bottom-all-loaded="allLoaded"
            ref="loadmore"
            :auto-fill="false"
          >
            <div>
              <div
                class="user-card"
                v-for="discuss in discussList"
                :key="discuss.notes_id"
                :id="discuss.notes_id"
              >
                <div class="user-content clearfix">
                  <div class="avatar" v-if="discuss.user_id != user_id">
                    <router-link
                      :to="{name:'otherIndex', params: { uid: discuss.user_id }}"
                      v-if="discuss.user_icon == '' "
                    >
                      <img src="../../assets/img/apply_avatar_bg.png" />
                    </router-link>
                    <router-link
                      :to="{name:'otherIndex', params: { uid: discuss.user_id }}"
                      v-if="discuss.user_icon != '' "
                    >
                      <img :src="discuss.user_icon" />
                    </router-link>
                  </div>
                  <div class="avatar" v-if="discuss.user_id == user_id">
                    <router-link
                      :to="{name:'MyIndex', params: { uid: discuss.user_id }}"
                      v-if="discuss.user_icon == '' "
                    >
                      <img src="../../assets/img/apply_avatar_bg.png" />
                    </router-link>
                    <router-link
                      :to="{name:'MyIndex', params: { uid: discuss.user_id }}"
                      v-if="discuss.user_icon != '' "
                    >
                      <img :src="discuss.user_icon" />
                    </router-link>
                  </div>
                  <div class="name">
                    <span v-if="discuss.user_id != user_id">
                      <router-link
                        :to="{name:'otherIndex', params: { uid: discuss.user_id }}"
                      >{{discuss.user_nick}}</router-link>
                    </span>
                    <span v-if="discuss.user_id == user_id">
                      <router-link
                        :to="{name:'MyIndex', params: { uid: discuss.user_id }}"
                      >{{discuss.user_nick}}</router-link>
                    </span>

                    <div class="chat-time">{{discuss.discuss_time}}</div>
                    <div class="last-chat">{{discuss.discuss_content}}</div>
                  </div>
                </div>
                <a
                  href="javascript:;"
                  @click="click_path_new(discuss.notes_or_spots,discuss.is_spots ,discuss.notes_id )"
                  class="note-card"
                >
                  <!--:to="{ path: '/footprints/FootprintsParticulars', query: { id: discuss.notes_id }}"-->
                  <div class="pic" v-if="discuss.img == '' "></div>
                  <div class="pic" v-else>
                    <img :src="discuss.img" />
                  </div>
                  <div class="author">@{{discuss.travel_notes_author}}</div>
                  <div class="content">{{discuss.travel_notes_title}}</div>
                </a>
              </div>
            </div>
          </mt-loadmore>
        </div>
        <div v-else class="foot_noinfo">
          <img src="/static/img/foot_noinfo@2x.14391c2.png" alt />
          <p>暂无数据</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Indicator, Loadmore } from "mint-ui";
import { Toast } from "mint-ui";

export default {
  data() {
    return {
      discussList: [],
      user_id: "",
      page: 1,
      allLoaded: false, //是否加载
      totalCount: "" //初始化方法中的数量总数
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      this.getDiscuss(this.page);
    },

    // 得到评论
    getDiscuss(page) {
      var that = this;
      var user_id = this.get_User_Id();
      this.user_id = user_id;

      var data = {
        user_id: user_id,
        page: page
      };

      this.$ajax("post", "Common/getDiscuss", data, function(res) {
        that.discussList = that.discussList.concat(res.data.data);
        that.totalCount = res.data.data.length;
      });
    },

    //  下拉加载页面
    loadBottom() {
      setTimeout(() => {
        if (this.totalCount % 10 == 0) {
          this.page = this.page + 1;
          this.getDiscuss(this.page);
        } else {
          this.allLoaded = true; // 若数据已全部获取完毕
          Toast("没有更多了");
        }
        this.$refs.loadmore.onBottomLoaded();
      }, 500);
    },
    click_path_new(notes_or_spots, is_spots, id) {
      if (notes_or_spots == 1) {
        if (is_spots == 1) {
          this.$router.push({
            path: "/footprints/PrimaryScenic",
            query: { sid: id, id_: 0 }
          });
        } else {
          this.$router.push({
            path: "/footprints/FootprintsParticulars",
            query: { user_id: this.user_id, id: id }
          });
        }
      } else {
        this.$router.push({
          name: "NoteDetail",
          params: { id: discuss.notes_id }
        });
      }
    }
  }
};
</script>

<style scoped>
.no-data {
  text-align: center;
}

.user-card .note-card {
  display: block;
  margin-top: 0.5rem;
  width: 100%;
  height: 7rem;
  background-color: #f2f2f2;
  overflow: hidden;
  border-radius: 0.3rem;
}

.user-card .note-card .pic {
  float: left;
  height: 100%;
  width: 9.3rem;
  background: url(../../assets/img/pic-play-11.png) no-repeat;
  background-position: center;
  background-size: cover;
}

.user-card .note-card .pic img {
  width: 100%;
  height: 100%;
}

.user-card .note-card .author {
  margin-top: 1.5rem;
  font-size: 0.5rem;
  margin-left: 10.5rem;
  margin-right: 1.2rem;
  color: #333;
}

.user-card .note-card .content {
  margin-top: 0.5rem;
  margin-left: 10.5rem;
  margin-right: 0.5rem;
  color: #999;
  font-size: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
