<template>
  <div v-title data-title="游记列表" class="edit-note">
    <!-- 头部 -->
    <mt-header fixed title="游记列表">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
      <mt-button slot="right" @click.native="notePost()">发布</mt-button>
    </mt-header>

    <div class="page-content">
      <form id="noteForm" action="#" method="post" enctype="multipart/form-data">
        <mt-field
          placeholder="发布游记，分享快乐~"
          :attr="{name:'travel_title'}"
          type="textarea"
          rows="6"
          v-model="noteText"
        ></mt-field>
        <div class="imgs clearfix">
          <div class="item add-image">
            <img src="../../assets/img/tour_photo@2x.png" alt @click="AddImage($event)" />
            <input type="file" multiple accept="image/*" />
          </div>
        </div>
        <div class="location-box">
          <div class="my-location" v-show="cityShow">
            <span id="location-text">{{cityInfo}}</span>
            <i @click="cityShow=false">x</i>
          </div>
          <div
            class="show-location"
            id="show-location"
            v-show="!cityShow"
            @click="cityShow=true"
          >显示定位</div>
        </div>

        <!--<h1 class="edit-note-park-name" v-html="parkName"></h1>-->
        <div class="edit-note-park-search">

          <div class="hot-park">
            <div class="clearfix">
              <h1 class="fl">推荐公园</h1>
              <span class="fr">
                <router-link
                  :to="{path:'/park/ParkList/',query:{type:'notegetpark',content:noteText}}"
                  class="blue"
                >更多公园 >></router-link>
                <!--<router-link to="/note/NotePark" class="blue">更多>></router-link>-->
              </span>
            </div>
            <div class="box" id="parks_box">
              <!-- 推荐公园列表 -->
              <span class="selected" v-if="parkName && is_click">{{parkName}}</span>
              <span
                v-show="!is_click"
                v-for="(hotPark,i) in hotParkList"
                :key="i"
                @click="parkName = hotPark.park_name ,is_click = true"
              >{{hotPark.park_name}}</span>
              <a
                class="selected-remove"
                v-show="is_click"
                @click="is_click= false,parkName='',getHotPark()"
              >重选</a>
            </div>
          </div>
        </div>
        <!-- 表单 -->
        <input type="hidden" name="address" v-model="cityInfo" />
        <input type="hidden" name="park_id" id="park_id" v-model="parkId" />
        <input type="hidden" name="park_name" id="park_name" v-model="parkName" />
        <input type="hidden" name="label_id" id="label_id" v-model="labelId" />
        <input type="hidden" name="aid" id="aid" v-model="aid" />
        <input type="hidden" name="user_id" v-model="userId" />
        <input type="hidden" name="user_name" v-model="userName" />
      </form>
    </div>
  </div>
</template>
<script>
import { Toast, Indicator, MessageBox } from "mint-ui";
export default {
  data() {
    return {
      userId: "",
      userName: "",
      labelId: "",
      aid: "",
      noteText: this.$route.query.content ? this.$route.query.content : "",
      parkId: this.$route.query.pid ? this.$route.query.pid : "",
      parkName: this.$route.query.pname ? this.$route.query.pname : "",
      is_click: this.$route.query.pname ? true : false,
      type: this.$route.query.type,
      cityInfo: "定位中...",
      cityName: "",
      cityShow: true,
      note_park: 2,
      hotParkList: [],
      discuss_img_arr: [],
      foot_upload_img: [],
      get_foot_upload_img: []
    };
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    checkLogin() {
      if (!this.check_login()) {
        MessageBox.alert("请先登录").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login", query: { log_type: 1 } });
          }
        });
      } else {
        this.userId = this.check_login().user_id;
        this.userName = this.check_login().user_nick;
        this.showCityInfo();

        if (!this.parkId) {
          this.getHotPark();
        }

        var that = this;
        if (this.type == 2) {
          this.foot_upload_img = JSON.parse(
            localStorage.getItem("foot_upload_img")
          );
          this.get_foot_upload_img = JSON.parse(
            localStorage.getItem("foot_upload_img")
          );
          if (this.get_foot_upload_img && this.get_foot_upload_img.length > 0) {
            this.get_foot_upload_img.forEach(function(data, i) {
              var html =
                '<div class="item up-img">' +
                '<img src="' +
                data.result +
                '"><i class="remove"></i>' +
                "</div>";
              $(".imgs").prepend(html);
              that.discuss_img_arr.push(that.upload(data.result, data.type));
            });
          }
        } else {
          localStorage.removeItem("foot_upload_img");
        }
      }
    },
    // 提交游记
    notePost() {
      if (this.noteText == "") {
        Toast("说点什么吧~");
        return false;
      }
      if (this.parkId == "") {
        Toast("请选择一个公园！");
        return false;
      }
      Indicator.open("发布中...");
      var that = this;
      var form = document.getElementById("noteForm");
      var formdata = new FormData(form);
      for (var i = 0; i < this.discuss_img_arr.length; i++) {
        formdata.append("img" + (i + 1), this.discuss_img_arr[i]);
      }
      this.formMethod("TravelNotes/publishTravelNotes_new", formdata, function(
        data
      ) {
        Indicator.close();
        if (data.id == 0) {
          that.$router.push({ path: "/note/NoteIndex", query: {} });
        } else {
          Toast(data.msg);
        }
      });
    },
    AddImage($event) {
      var file = $($event.target).siblings()[0];
      var that = this;
      file.click();
      file.onchange = function() {
        var files = Array.prototype.slice.call(this.files);
        files.forEach(function(file, i) {
          var reader = new FileReader();
          reader.onload = function() {
            that.discuss_img_arr.push(that.upload(this.result, file.type));
            var img_data = {};
            img_data.result = this.result;
            img_data.filetype = file.type;

            that.foot_upload_img.push(img_data);
            localStorage.setItem(
              "foot_upload_img",
              JSON.stringify(that.foot_upload_img)
            );
          };
          reader.readAsDataURL(file);
          var objUrl = that.getObjectURL(file);
          var html =
            '<div class="item up-img">' +
            '<img src="' +
            objUrl +
            '"><i class="remove"></i>' +
            "</div>";
          $(".imgs").prepend(html);
          if ($(".up-img").length >= 9) {
            $(".add-image").hide();
            Toast("最多可上传9张图片");
          }
        });
        $(".remove").on("click", that.remove_img);
      };
    },
    //  删除图片
    remove_img(e) {
      this.discuss_img_arr.splice(
        $(e.target)
          .parent()
          .index(),
        1
      );
      $(e.target)
        .parent()
        .remove();
      if ($(".up-img").length < 9) {
        $(".add-image").show();
      }
    },
    // 获取用户所在城市信息
    showCityInfo() {
      AMap.plugin("AMap.CitySearch", () => {
        //异步
        //实例化城市查询类
        var citysearch = new AMap.CitySearch();
        //自动获取用户IP，返回当前城市
        citysearch.getLocalCity((status, result) => {
          if (status === "complete" && result.info === "OK") {
            if (result && result.city && result.bounds) {
              this.cityInfo = result.city;
              this.cityName = result.city;
            }
          } else {
            Toast("定位失败！");
          }
        });
      });
    },
    // 公园推荐
    getHotPark() {
      this.$ajax("post", "TravelNotes/getParkAddress", { park_id: "" }, res => {
        this.hotParkList = res.data;
      });
    }
  }
};
</script>

<style scoped>
.edit-note {
  height: 100%;
  background: #fff;
}

.page-content {
  padding-bottom: 0;
  background: #fff;
}

.location-box {
  padding: 0 10px 10px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 10px;
}
.note-edit-btn {
  background: url(../../assets/img/note_edit_btn.png) no-repeat center;
  background-size: 60%;
  width: 40px;
  height: 40px;
  margin-right: -10px;
}

.edit-note .mint-field-core {
  text-align: left;
}

.edit-note-park-name {
  color: #ff9a80;
  margin: 0 0 0 10px;
}

.edit-note .my-location {
  display: inline-block;
  height: 1.8rem;
  border: 1px solid #f2f2f2;
  border-radius: 0.5rem;
  padding-left: 1.8rem;
  background-image: url(../../assets/img/location.png);
  background-repeat: no-repeat;
  background-position: 0.5rem;
  background-size: 0.8rem;
}

.edit-note .my-location:after {
  content: "";
  clear: both;
}

.edit-note .my-location span {
  color: #ff9a80;
  height: 100%;
  line-height: 2rem;
  padding-right: 0.5rem;
  border-right: 1px solid #f2f2f2;
}

.edit-note .my-location i {
  color: red;
  display: inline-block;
  height: 100%;
  line-height: 2rem;
  padding-left: 0.4rem;
  padding-right: 0.6rem;
  font-style: normal;
}

.edit-note .my-location i:active {
  background-color: #f2f2f2;
}

.edit-note .show-location {
  display: inline-block;
  background-color: #f2f2f2;
  color: #999;
  height: 1.8rem;
  line-height: 1.8rem;
  padding: 0 0.5rem;
  border: 1px solid #f2f2f2;
  border-radius: 0.3rem;
}

.edit-note .show-location:active {
  background-color: #ccc;
  color: #666;
}

/*搜索*/


.edit-note-park-search .hot-park {
  margin: 10px;
}

/*推荐*/
.edit-note .hot-park h1 {
  color: #ff9a80;
  margin: 0 0 10px;
}


.edit-note .hot-park .box span,
.selected-remove {
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  padding: 0.2rem 0.4rem;
  border-radius: 0.3rem;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
  color: #666;
  line-height: 100%;
  font-size: 0.6rem;
  display: inline-block;
}

.edit-note .hot-park .box.selected span {
  display: none;
}

.edit-note .hot-park .box span.selected {
  display: inline-block;
  background-color: #ff9a80;
  color: #fff;
}

/*添加图片*/
.edit-note .imgs {
  padding: 10px;
}

.edit-note .imgs .item {
  position: relative;
  float: left;
  width: 50%;
  padding: 0;
  margin: 0 5px 5px 0;
  box-sizing: border-box;
  width: 3rem;
  overflow: hidden;
  height: 3rem;
}

.edit-note .imgs .item input {
  padding-top: 100%;
  position: relative;
  display: block;
  background-size: cover;
  border-radius: 2px;
  background-position: center;
  width: 100%;
  height: 0;
  z-index: -1;
}

.edit-note .imgs .item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.edit-note .imgs .item .remove {
  background: url(../../assets/img/search_clean.png) 0 0 no-repeat;
  background-size: 1rem;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.edit-note .imgs .add-image input {
  height: 0;
  border: none;
}

.edit-note .filenum {
  font-size: 12px;
  padding: 5px 10px 15px;
  border-bottom: 1px solid #f2f2f2;
}
</style>