<template>
    <div id="books">
        <div class="container" key="zmlxj" ship="true" :class="is_full ? 'full_height': 'half_height'">
            <!--地图页面-->
            <div class="mapboxgl-map" id="mapbox" width="dummyValue">
                <!--图层切换-->
                <!-- <div class="layer-switch">
                    <img src="../../assets/img/map_3d_yes.png" v-if="is_3d_map" @click="clickMap3d('2d')">

                    <img src="../../assets/img/map_3d.png" v-else @click="clickMap3d('3d')">
                </div> -->

                <!--左下角播放按钮-->
                <div class="play-path" v-if="is_show_play">
                    <img src="../../assets/img/playIcon.png"  @click="clickPlay">
                </div>
                <!--放大按钮-->
                <div class="go-full" @click="clickFull">
                    <img src="../../assets/img/full_1.png">
                </div>
            </div>
            
        </div>
        <!--详情-->
        <div class="info">
            <!--第一段：路线信息-->
            <div class="col1">
                <!--头像+昵称-->
                <div class="nick-icon">
                    <img :src="info.user_icon">
                    <span class="nick">{{info.user_nick}}</span>
                </div>
                <div class="col1-1">
                    <div class="col1-1-1">
                        <!--标题-->
                        <div class="title">
                                {{info.title}}
                        </div>
                        <!--里程-->
                        <div class="km">
                            <strong class="num">{{info.length}}</strong>
                            <span class="txt">{{info.length_text}}</span>
                        </div>    
                    </div>                
                </div>
                <!--第二段：-->
                <div class="col1-2">
                    <div class="item  ">
                        <div class="it">
                            <strong class="num">{{info.times}}</strong>
                        <span class="txt">用时({{info.times_text}})</span>
                        </div>
                    </div>
                        <div class="add-line"></div>

                    <div class="item ">
                        <div class="it">
                            <strong class="num">{{info.max_height}}</strong>
                        <span class="txt">{{info.height_text}}</span>
                        </div>
                    </div>
                        <div class="add-line"></div>

                    <div class="item">
                        <div class="it ">
                            <strong class="num">{{info.point_numbers}}</strong>
                        <span class="txt">途经({{info.point_text}})</span>
                        </div>
                    </div> 
                </div>
            </div>
            <!--第二段-->
            <div class="col2">
                <div class="col2-1">
                    {{info.desc}}
                </div>
                <div class="col2-2">
                    <div class="it">
                        <div class="icon">
                            <div :class="item.id == 1 ? 'star' : 'star_'" v-for="(item,index) in info.star_" :key="index"></div>
                        </div>
                        <div class="txt">{{info.star_title}}</div>
                    </div>
                    <div class="add-line"></div>
                    <div class="it">
                        <div class="title">{{info.open_time}}</div>
                        <div class="txt">{{info.over_time_text}}</div>
                    </div>
                </div>
            </div>
            <!--第三段-->
            <div class="col3">
                <div class="title">{{info.listText}}</div>
                <div class="item" v-for="(item,index) in info.list" :key="index" >

                    <div class="desc">
                        <div class="icon">
                            <img src="../../assets/img/location_1.png">
                        </div>
                        <div class="desc1">
                            {{item.desc}}
                        </div>
                    </div>
                    <div class="col3-1">
                        <!--途径的城市列表，没有就不显示-->
                        <div class="city" v-if="item.cityStr">
                            {{item.cityStr}}
                        </div>
                        <div class="line">
                            <div class="image">
                                    <img src="../../assets/img/play_1.png" class="play" @click="playAudio(item)" v-if="item.zhAudioUrl">
                                    <img :src="item.newUrl" class="newUrl">
                                    <span class="num">{{item.index}}</span>
                            </div>
                            <div class="content">
                                <div class="col"  @click="clickDetail(item)" >
                                <div class="col3-left">
                                    <div class="title1">{{item.title}}</div>
                                    <div class="txt">{{item.text}}</div>
                                    <div class="label" v-if="item.label_list">
                                        <span class="label-text" v-for="(it ,i) in item.label_list" :key="i">{{it.title}}</span>
                                    </div>
                                    <div class="cont" v-else>
                                        {{item.content}}
                                    </div>
                                </div>
                                <div class="col3-right">
                                    <img src="../../assets/img/to_right.png">
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <!--回到顶部  -->
        <transition name="backtop">
            <back-top v-show="showBackTop"></back-top>
        </transition>

        <!-- 联系我们 -->
        <technical-support :ts_type_="id_type_" contact_side="bottom" :id="id_"></technical-support>
         <!-- 赞赏 -->
        <div class="books_appreciate" v-if="is_show_money > 0" @click="pay">
            <span class="appreciate_btn">{{language == 1 ? "赞赏" : (language == 2 ? "Like" : "赞赏")}}</span>
            <span
            class="appreciate_num"
            v-if="language == 1"
            @click="OpenLabelUserList('pay_list')"
            >——— {{pay_numbers}}人赞赏 ———</span>
            <span
            class="appreciate_num"
            v-else-if="language == 2"
            @click="OpenLabelUserList('pay_list')"
            >——— {{pay_numbers}}&nbsp;likes ———</span>
        </div>

            <!-- 进入地图/ 邀请排行榜 -->
            <div class="function-btn">
                <div class="btn" @click="jumpToMap">
                <span class="name">进入地图</span>
                <i class="iconfont iconqianjin"></i>
                </div>
                <div class="btn" @click="getShareRank('show')">
                <span class="name">邀请达人榜</span>
                <i class="iconfont iconqianjin"></i>
                </div>
            </div>
            

            
            <!-- 分享排行 -->
            <transition name="slide-down">
            <div class="share-rank-box" v-show="showShareRank" ref="share-rank-box">
                <div class="close" @click="showShareRank = false">
                <i class="iconfont iconiconfontback"></i>
                </div>
                <div class="banner">
                <div class="banner-title">
                    <p class="text">邀请达人榜</p>
                </div>
                <div class="banner-icon">
                    <i class="iconfont iconjiangpai"></i>
                </div>

                </div>
                <!--增加显示红包剩余个数信息-->
                <div class="statistics_percentage statistics_item_opt" v-if="red_packet != ''">
                <span class="statistics_name">红包剩余数量：{{ red_packet.total_ - red_packet.numbers >= 0 ? red_packet.total_ - red_packet.numbers : 0}} / {{red_packet.total_}}</span>
                <span class="numbers_percentage_outer">
                            <span
                                    class="numbers_percentage_inner"
                                    :style="'width:' + (red_packet.total_ - red_packet.numbers)/red_packet.total_ *100+ '%' "
                            ></span>
                    </span>
                </div>
                <div class="self-share">
                <div class="left">
                    <img :src="myShareInfo.user_icon" class="icon" alt v-if="myShareInfo.user_icon" />
                    <img src="../../assets/img/apply_avatar_bg.png" alt class="icon" v-else />
                </div>
                <div class="middle">
                    <p class="nick">{{myShareInfo.user_nick || '*'}}</p>
                    <p class="tag" v-if="myShareInfo.numbers > 0">
                    <span class="text">邀请达人</span>
                    </p>
                    <p class="tip" v-if="myShareInfo.amount">
                    成功邀请
                    <span class="num">{{myShareInfo.numbers}}</span> 位好友,获得<span class="num">{{myShareInfo.amount}}</span> 
                    </p>
                    <p class="tip" v-else>
                    成功邀请
                    <span class="num">{{myShareInfo.numbers}}</span> 位好友
                    </p>
                    <div class="self-list" v-if="myShareInfo.list">
                    <ul class="list">
                        <li class="item" v-for="(item, i) in myShareInfo.list" :key="i">
                        <img :src="item.user_icon" alt class="icon" v-if="item.user_icon"/>
                        <img src="../../assets/img/apply_avatar_bg.png" alt class="icon" v-else />
                        </li>
                    </ul>
                    </div>
                </div>
                <div class="right">
                    <button class="btn" @click="handleShowTipShare">邀请好友</button>
                </div>
                </div>

                <ul class="share-list">
                <li
                    class="item"
                    v-for="(item,i) in shareRankList"
                    :key="i"
                    @click="handleShowSubShareList(item.list)"
                >
                    <div class="left">
                    <img class="icon" :src="item.user_icon" alt v-if="item.user_icon" />
                    <img src="../../assets/img/apply_avatar_bg.png" alt class="icon" v-else />
                    </div>
                    <div class="middle">
                    <p class="nick">{{item.user_nick  || '*'}}</p>
                    <p class="tip" v-if="item.amount">
                        邀请
                        <span class="num">{{item.numbers}}</span>
                        位好友,获得<span class="num">{{item.amount}}</span>
                    </p>
                    <p class="tip" v-else>
                        邀请
                        <span class="num">{{item.numbers}}</span>
                        位好友
                    </p>
                    </div>
                    <div class="right">
                    <div class="rank">{{item.rank}}</div>
                    </div>
                </li>
                </ul>
                <!-- 个人分享信息 -->
                <div class="sub-share-box" v-show="showSubShareList" @click.self="showSubShareList = false">
                <div class="sub-share-wrapper">
                    <div class="header">
                    <p class="title">邀请信息</p>
                    <i class="iconfont iconclose" @click="showSubShareList=false"></i>
                    </div>
                    <ul class="sub-share-list">
                    <li class="item" v-for="(item, i) in subShareList" :key="i">
                        <div class="item-icon">
                        <img class="icon" :src="item.user_icon" alt v-if="item.user_icon" />
                        <img src="../../assets/img/apply_avatar_bg.png" alt class="icon" v-else />
                        </div>
                        <div class="item-content">
                        <!-- <span class="nick">{{item.user_nick || '*'}}</span> -->
                        <p class="nick">{{item.user_nick || '*'}}
                            <span style="color:#999;font-size:0.7rem;" v-if="item.amount">,获得</span>
                            <span style="color:red;font-size:0.7rem;" v-if="item.amount">{{item.amount}}</span>
                        </p>
                        <div class="info">
                            <span class="time">{{item.add_time}}</span>
                            <span class="num">浏览：{{item.looks_numbers}}</span>
                        </div>
                        </div>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
            </transition>

            <!--播放控制条-->
            <transition name="fade-audio-down">
                <div class="audio-controls" v-if="is_show_audio">
                    <div class="ac">
                        <div class="icon">
                            <img src="../../assets/img/audio.png">
                        </div>
                    <div class="title">{{title}}</div>
                        <div class="del" @click="clickCloseAudio">
                            <img src="../../assets/img/del_1.png">
                        </div>
                    </div>
                </div>
            </transition>
            
            <!-- 联系信息 -->
            <div class="company" v-if="haveCompany">
                <technical-support
                :ts_type_="id_type_"
                contact_side="new"
                :text="companyInfo.title"
                :id="id_"
                :company="companyInfo"
                ></technical-support>
            </div>
        </div>
        
        
      
    </div>
</template>

<script>
import { Toast, Indicator } from 'mint-ui'
import TechnicalSupport from '../../components/TechnicalSupport'
import RedPacket from '../../components/RedPacket'
import bgMusic from '../../components/Music'
import BackTop from '../../components/BackTop'
import UserList from '../../components/UserList'
import PublishButton from '../../components/PublishButton'
import QrCode from '../../components/QrCode'
import { Marquee, Slide } from "vue-marquee-component"
var wx = require('weixin-js-sdk')
import 'mapbox-gl/dist/mapbox-gl.css'

export default {
    components:{
        'technical-support':TechnicalSupport,//联系我们
        'red-packet':RedPacket,//红包雨
        'bg-music':bgMusic,//背景音乐
        'back-top':BackTop,//回调到顶部
        'user-list':UserList,//用户列表
        'publish-button':PublishButton,
    QrCode,
     [Marquee.name]: Marquee,
    [Slide.name]: Slide
    },
    data(){
        return{
            id_:this.$route.query.id_ ? this.$route.query.id_ : 0,
            token:localStorage.getItem('token') ? localStorage.getItem('token') : '',
            share_open_id: this.$route.query.share_open_id
        ? this.$route.query.share_open_id
        : '',
        open_id:localStorage.getItem('open_id') ? localStorage.getItem('open_id') :'',
            park_id:this.$route.query.park_id ? this.$route.query.park_id : 298,
            info:'',//路线的信息
            version:'1.0.0',
            id_type_:'mapboxBooksDetail',
            is_weixin: this.hybrid.hybrid.versions.is_weixin,
            sUrl:'',//当前页面的url
            price:0,
            is_pay:0,
            is_show_audio:0,//显示音频播放进度控制条
            audio:'',//音频播放对象
            title:'',//选择的音频节点名称
            showBackTop:false,
            bg_audio_url:'',//背景音乐
            pay_numbers:0,//已经赞赏的人数
            is_show_money:0,//是否允许显示赞赏按钮
            money_:0,//已经获得的金额
            redPacletList:'',//红包
            have_red_packet:0,
            is_admin:0,

            //company
            companyInfo:{},
            haveCompany:false,

            // share
            myShareInfo: {},
            shareRankList: [],
            subShareList: [],
            isGetShareData: false,
            showShareRank: false,
            shareRankPage: 1,
            haveMoreShareData: true,
            showTipShare: false,
            showSubShareList: false,
                red_packet:'',
                marquee_list:[],//获奖用户滚动显示

            // 非微信导航、解说时显示二维码
            showQrCode: false,

            //地图
            map:'',
            lng:'',
            lat:'',
            is_3d_map:true,//图层切换
            is_show_play:false,//重新绘制路线
            is_show_language:0,
            language:1,
            user_id:'',
            

            //全屏
            is_full:0,
        }
    },
    watch:{

    },
    mounted(){
        //初始化当前页面的url
        this.initSUrl();
        if(this.token)
        {
            //已经存在token
        }
        else
        {
            //第一次生成token
            var token = this.make_token();
            this.token = token;
            localStorage.setItem('token',token);
        }

        //判断是否有open_id
        if(this.open_id && !(!this.open_id && typeof this.open_id !== 'undefined' && this.open_id !== 0))
        {
            //已经存在正常的open_id值
        }
        else
        {
            //本地没有open_id，就从url后面读取
            //判断url后面的token，是否和本地的token保持一致

            if(this.$route.query.token)
            {
                if(this.token === this.$route.query.token && this.$route.query.open_id)
                {
                    localStorage.setItem('open_id',this.$route.query.open_id);
                    this.open_id = this.$route.query.open_id;
                }
                else
                {
                    //说明是别人分享给我的，不用保存open_id
                    localStorage.setItem('open_id','');
                    this.open_id = '';
                }
            }
            else
            {
                this.open_id = '';
                localStorage.setItem('open_id','');
            }
        }

        //微信分享
        if(this.is_weixin)
        {
            //如果是在微信中打开，需要判断是否授权，不再微信中打开，不用授权
            if(this.open_id)
            {

            }
            else
            {
                this.authorize();//微信授权
            }
            //处理url
            this.dealWithUrl(this.sUrl);
        }
        //获取定位
        this.locationFn().then(res=>{
            console.log('定位:',res)
        }).catch(err=>{
                console.log('位置信息获取失败')
        });
        
        
        //获取路书信息
        this.getData();

        //初始化地图
        //this.initMap();
        //获取公司信息
        this.getCompanyInfo(this.id_,this.token).then(res=>{
            this.companyInfo = res;
            this.haveCompany = true;
        },err=>{
            console.log('获取公司信息失败'+err);
        });
        this.$nextTick(() => {
            this.$refs['share-rank-box'].addEventListener('scroll', e => {
                this.bindShareListScroll(e)
            })
        })
    },
    methods:{
        //初始化surl
        initSUrl()
        {
            this.sUrl = window.location.href;
        },
        //获取位置信息异步函数
        locationFn(){
            let that=this;
            return new Promise(function(res,err){
                //获取位置信息失败的回调函数
                function showError(error){

                    switch(error.code) {

                        case error.PERMISSION_DENIED:
                            console.log("定位失败,用户拒绝请求地理定位");
                            //err();
                            break;
                        case error.POSITION_UNAVAILABLE:
                            console.log("定位失败,位置信息是不可用");
                            //err();
                            break;
                        case error.TIMEOUT:
                            console.log("定位失败,请求获取用户位置超时");
                            //err();
                            break;
                        case error.UNKNOWN_ERROR:
                            console.log("定位失败,定位系统失效");
                            //err();
                            break;
                    }

                }

                //获取位置信息成功的回调函数
                function showPosition(position){
                    that.lat = position.coords.latitude;
                    that.lng = position.coords.longitude;
                    
                }

                //获取位置信息
                if (navigator.geolocation){
                    navigator.geolocation.getCurrentPosition(showPosition,showError,{enableHighAccuracy: true});
                }else{
                    console.log("浏览器不支持地理定位。");
                    
                }
            })
        },
        /**
         * 图层切换
         */
        clickMap3d(type_)
        {
            //map.setStyle('mapbox://styles/mapbox/' +item )
            if(type_ == '3d')
            {
                // this.map.setStyle({
                //             "version": 8,
                //             "sources": {
                //                 "raster-tiles": {
                //                 "type": "raster",
                //                 'tiles': [
                                   
                //                     "http://wprd01.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=6"
                //                 ],
                //                 "tileSize": 256
                //                 }
                //             },
                //             "layers": [{
                //                 "id": "simple-tiles",
                //                 "type": "raster",
                //                 "source": "raster-tiles",
                //                 "minzoom": 0,
                //                 // "maxzoom": 18
                //             }]
                //             })
                this.map.setStyle('mapbox://styles/mapbox/satellite-v9');
            }
            else
            {
                this.map.setStyle('mapbox://styles/mapbox/streets-v11');
            }
            this.is_3d_map = !this.is_3d_map;
            
        },
        /**
         * 初始化定图并且获取定位
         * 
         */
        initMap()
        {
            var that = this;

            this.$mapboxgl.accessToken =
            "pk.eyJ1IjoiY2VvODQxMSIsImEiOiJjanh0bHBxNDQwdndrM3JueXZ2ZzRlNjhiIn0.RMJ_7x7Sgvlw7LD9JA79bw";
            this.map = new this.$mapboxgl.Map({
            container: "mapbox",
            //mapbox style
            //style:"mapbox://styles/mapbox/satellite-v9",//mapbox://styles/mapbox/streets-v11
            //高德 style
            style:{
                "version": 8,
                "sources": {
                    "raster-tiles": {
                    "type": "raster",
                    'tiles': [
                        // wprd0{1-4} 
                        // scl=1&style=7 为矢量图（含路网和注记）
                        // scl=2&style=7 为矢量图（含路网但不含注记）
                        // scl=1&style=6 为影像底图（不含路网，不含注记）
                        // scl=2&style=6 为影像底图（不含路网、不含注记）
                        // scl=1&style=8 为影像路图（含路网，含注记）
                        // scl=2&style=8 为影像路网（含路网，不含注记）
                        "https://wprd01.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=2&style=6"
                    ],
                    "tileSize": 256
                    }
                },
                "layers": [{
                    "id": "simple-tiles",
                    "type": "raster",
                    "source": "raster-tiles",
                    "minzoom": 0,
                    // "maxzoom": 18
                }]
                },
                    
            //谷歌
            


            zoom: 5,
            
            pitch: 40, //视野倾斜，0-60
            bearing: 0,//视野旋转角度
            });

            /**
             *  // .addControl(new this.$mapboxgl.FullscreenControl).resize((obj)=>{
            //     console.log(obj)
            // })
             */
           
            this.map.on('load',function(){
                that.makeMap();
            });
        },
        /**
         * 获取路线信息
         * id:
         * token
         * park_id
         * user_id
         * open_id
         */
        getData()
        {
            this.$ajax('post','ApiMini/ajax_get_label_record_detail',{
                id:this.id_,
                token:this.token,
                user_id:'',
                park_id:this.park_id,
                lng:this.lng,
                lat:this.lat,
                open_id:this.open_id,
                share_open_id:this.share_open_id,
                id_type_:this.id_type_,

            },res=>{
                if(res.data.id == 0)
                {
                    this.info = res.data.data.data;
                    this.pay_numbers = res.data.data.data.pay_numbers;
                    this.is_show_money = res.data.data.data.is_show_money;
                    //设置标题
                    document.title = res.data.data.data.title;
                    //判断是否已经支付
                    this.is_pay = res.data.data.data.is_pay;
                    this.price = res.data.data.data.price;

                    //初始化地图
                    this.initMap();
                    //微信分享
                    if(this.is_weixin)
                    {
                        this.wx_share_callback(window.location.href, {
                            url: this.sUrl,
                            id_: this.id_,
                            id_type_: this.id_type_,
                            token: this.token,
                            open_id: this.open_id,
                            title: res.data.data.data.title,
                            content: res.data.data.data.content,
                            img: res.data.data.data.newUrl
                        });
                    }
                }
                else
                {
                    console.log('网络请求失败');
                }
            },'web')
        },
        makeMap(){
            // this.map.setRTLTextPlugin('https:/api.mapbox.com/mapbox-gl-js/pluginsmapbox-gl-rtl-text/v0.2.0mapbox-gl-rtl-text.js');
            var that = this;
            //添加marker点
            if(this.info.list)
            {
                for(var i =0; i < this.info.list.length;i++)
                {
                    // create a DOM element for the marker
                    

                    var el = document.createElement('div');
                    el.className = 'marker';
                   el.style.display ='flex';
                   el.style.width = '20%';
                   el.style.justifyContent = 'center';
                   el.style.alignItems='center';
                   el.style.flexWrap = 'wrap';

                    var span = document.createElement('span');
                    span.className = 'txt';
                    span.style.display = 'flex';
                    span.style.maxWidth = '72px';
                    span.style.justifyContent = 'start';
                    span.style.alignItems = 'center';
                    span.style.backgroundColor='white';
                    span.style.whiteSpace='nowrap';
                    span.style.overflow='hidden';
                    span.style.textOverflow='ellipsis';
                    span.style.padding="0px 2px 0px 2px";
                    span.style.borderRadius='3px';
                    span.setAttribute('data-index',i);



                    span.innerHTML = this.info.list[i].title;
                    el.appendChild(span);
                    var img = document.createElement('img');
                    img.className = 'icon';
                    img.setAttribute('src',this.info.list[i].markerUrl);
                    el.appendChild(img);
                    img.style.display = 'flex';
                    img.style.width = '35px';
                    img.style.height = '35px';
                    img.style.justifyContent = 'center';
                    img.style.alignItems = 'center';
                    img.setAttribute('data-id',this.info.list[i].id);
                    img.setAttribute('id',this.info.list[i].id);
                    img.setAttribute('data-index',i);

                    el.setAttribute('data-id',this.info.list[i].id);
                    el.setAttribute('id',this.info.list[i].id);
                    el.setAttribute('data-index',i);
                    var item = this.info.list[i];
                    el.addEventListener('click', function (res) {
                        //进入详情
                      
                        that.$router.push({
                            path: '/footprints/PrimaryScenic',
                            query: {
                            sid: that.info.list[res.target.dataset.index].id,
                            id_: that.id_
                            }
                        })
                    });
                    var options={
                        element:el,
                        offset:new this.$mapboxgl.Point(0, 0)
                    }
                    // add marker to map
                    new this.$mapboxgl.Marker(options)
                    .setLngLat([this.info.list[i].lng * 1,this.info.list[i].lat * 1])
                    .addTo(this.map);
              

                    
                }
            }


            
           this.map.rotateTo(45, {
                duration: 1e3,
                pitch: 60,
                bearing: 30
            })
           ;
           var mapbox_line_path_format = {
               type:'FeatureCollection',
               features:[
                   {
                       type:'Feature',
                       geometry:{
                           type:'LineString',
                           coordinates:[]
                       }
                   }
               ]
           };
           //处理数据
           var coordinates =[];
           var list = this.info.mapbox_line_path_format.features[0].geometry.coordinates;
           for(var i =0; i < list.length;i++)
           {
               coordinates.push([list[i][0] * 1,list[i][1] * 1]);
           }

            //设置mapbox_line_path_format
            mapbox_line_path_format.features[0].geometry.coordinates = [coordinates[0]];

            console.log('mapbox_line_path_format=',mapbox_line_path_format);
            // add it to the map
            this.map.addSource('trace', { type: 'geojson', data: mapbox_line_path_format });

            this.map.addLayer({
                "id": "trace",
                "type": "line",
                "source": "trace",
                "paint": {
                    "line-color": "yellow",
                    "line-opacity": 0.75,
                    "line-width": 5
                }
            });

            // setup the viewport
            this.map.jumpTo({ 'center': coordinates[0], 'zoom': this.info.init_zoom });
            
        
            var that = this;
            var i = 0;
            function render(){
                if (i < coordinates.length) {
                            mapbox_line_path_format.features[0].geometry.coordinates.push(coordinates[i]);
                            that.map.getSource('trace').setData(mapbox_line_path_format);
                            //console.log(i,mapbox_line_path_format)
                            that.map.panTo(coordinates[i]);
                            i++;

                            that.is_show_play = false;
                }
                else
                {
                    cancelAnimationFrame(render);
                    that.is_show_play = true;
                }
                requestAnimationFrame(render);
            }
            render();
        },
        
        /**
         * 播放音频文件
         * 还是需要判断是否已经支付了
         * 
         */
        playAudio(object)
        {
            //判断是否需要进行支付
            if(this.price * 1 > 0)
            {
                if(this.is_pay == 1)
                {
                    //表示已经支付了

                }
                else
                {
                    //表示还未支付，跳转到支付页面
                    if(this.open_id)
                    {
                        // 已经授权过了,进行支付
                        const openUrl ='https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +this.id_ +
                                        '&sid=0&backUrl='+this.id_type_+'&open_id=' +
                                        this.open_id +
                                        '&token=' +
                                        this.token +
                                        '&park_id=' +
                                        this.park_id +
                                        '&version=' +
                                        this.version +
                                        '&user_id=' +
                                        this.user_id;
                        window.location.href = openUrl;
                    }
                    else
                    {
                        //需要去授权
                        this.authorize();
                    }
                }
            }
            else
            {
                //表示不需要进行支付
                this.is_pay = 1;
            }

            if(this.is_pay && object.zhAudioUrl)
            {
                this.is_show_audio = 1;//显示播放控制条
                //创建音频播放控件
                if(this.audio)
                {
                    this.audio.pause();
                }
                else
                {
                    this.audio = new Audio();
                }
                this.audio.src = object.zhAudioUrl;
                this.audio.play();
                this.title = object.title;
            }
            else
            {
                console.log('不能播放!');
            }
        },

        /**
         * 关闭音频文件
         * 
         */
        clickCloseAudio()
        {
            if(this.is_show_audio)
            this.is_show_audio = !this.is_show_audio;
            if(this.audio) this.audio.pause();
        },

        /**
         * 全屏显示地图
         */
        clickFull()
        {
            var that = this;
            this.is_full = !this.is_full;
            
            this.map.on('idle',function(){
                that.map.resize()
            })
            
            
        },
        /***
         * 回放轨迹
         */
        clickPlay()
        {
            this.map.rotateTo(45, {
                duration: 1e3,
                pitch: 60,
                bearing: 30
            });
            var mapbox_line_path_format = {
               type:'FeatureCollection',
               features:[
                   {
                       type:'Feature',
                       geometry:{
                           type:'LineString',
                           coordinates:[]
                       }
                   }
               ]
           };
           //处理数据
           var coordinates =[];
           var list = this.info.mapbox_line_path_format.features[0].geometry.coordinates;
           for(var i =0; i < list.length;i++)
           {
               coordinates.push([list[i][0] * 1,list[i][1] * 1]);
           }

            //设置mapbox_line_path_format
            mapbox_line_path_format.features[0].geometry.coordinates = [coordinates[0]];




            var that = this;
            var i = 0;
            function render(){
                if (i < coordinates.length) {
                            mapbox_line_path_format.features[0].geometry.coordinates.push(coordinates[i]);
                            that.map.getSource('trace').setData(mapbox_line_path_format);
                            //console.log(i,mapbox_line_path_format)
                            that.map.panTo(coordinates[i]);
                            i++;

                            that.is_show_play = false;
                }
                else
                {
                    cancelAnimationFrame(render);
                    that.is_show_play = true;
                }
                requestAnimationFrame(render);

            }
            render();
        },
        handleShowTipShare () {
            if (this.is_weixin) {
                this.showTipShare = true
                setTimeout(() => {
                this.showTipShare = false
                }, 3000)
            } else {
                Toast('请在微信中打开')
            }
        },
        // 分享列表滚动
        bindShareListScroll (e) {
        if (!this.haveMoreShareData || this.isGetShareData) {
            // 没有更多数据或者正在请求数据
            return false
        }
        // console.log(e.target.scrollTop);
        // console.log(e.target.clientHeight);
        // console.log(e.target.scrollHeight);
        let st = e.target.scrollTop
        let ch = e.target.clientHeight
        let sh = e.target.scrollHeight
        if (st + ch >= sh - 10) {
            this.getShareRank('more') // 获取更多分享数据
        }
        },
        // 获取邀请数据
        getShareRank (type) {
        if (this.isGetShareData) {
            return false
        }

        if (type === 'show' && this.shareRankPage > 1) {
            this.showShareRank = true
            return false
        }
        this.isGetShareData = true
        Indicator.open('加载中...')
        this.$ajax(
            'post',
            'Share/get_label_share_looks_rank_data',
            {
            page: this.shareRankPage,
            id: this.id_,
            token: this.token,
            open_id: this.open_id,
                id_type_:this.id_type_
            },
            res => {
            // console.log(res);
            if (res.data.id === 0) {
                Object.assign(this.myShareInfo, res.data.data.myself);
                if(res.data.data.red_packet)
                {
                    this.red_packet = res.data.data.red_packet;
                }

                this.shareRankList.push(...res.data.data.list);

            } else {
                console.log(res.data.msg)
            }

            if (
                res.data.id !== 0 ||
                !res.data.data.list ||
                res.data.data.list.length < 10
            ) {
                this.haveMoreShareData = false
            }

            if (
                // eslint-disable-next-line eqeqeq
                this.shareRankPage == 1 &&
                (res.data.id !== 0 ||
                !res.data.data.list ||
                res.data.data.list.length === 0)
            ) {
                Toast('暂无邀请信息')
                Indicator.close()

                this.isGetShareData = false
                return false
            }

            Indicator.close()
            this.shareRankPage++
            this.showShareRank = true
            this.isGetShareData = false
            }
        )
        },
        /**
         * 进入商户详情
         * 
         */
        clickDetail(object)
        {
            //console.log(object);
            this.$router.push({
                path: '/footprints/PrimaryScenic',
                query: {
                sid: object.id,
                id_: this.id_
                }
            });
        },

        // 跳转地图
        jumpToMap () {
            if (this.info.url_) {
                window.location.href = this.info.url_
            } else {
                this.$router.push({
                path: '/DrawMap/leafletNoDrawMap',
                query: {
                    id: this.id_
                }
                })
            }
        },

        // 进行赞赏支付
        pay () {
        if (this.is_weixin) {
            if (this.open_id) {
            this.$router.push({
                path: '/footprints/PayMoney',
                query: { label_id: this.id_ ,id_type_:this.id_type_+'Pay'}
            })
            } else {
            // 还未授权
            this.authorize()
            }
        } else {
            Toast('请在微信中打开')
        }
        },
        /**
         * 微信授权
         */
        authorize(){
            const openUrl =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.park_id + '&id_='+this.id_+
        '&state=' +
        this.id_type_ +
        '&share_open_id=' +
        this.share_open_id
      window.location.href = openUrl
        },

        // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id === 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            if (info && info !== '') {
              if (info.url && info.url !== '') {
                let urlArr = info.url.split('share_open_id')

                url = urlArr[0] + '&share_open_id=' + this.open_id
              } else {
                url = url + '&share_open_id=' + this.open_id
              }
            } else {
              url = url + '&share_open_id=' + this.open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id === 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    }

    }
}
</script>

<style scoped>

#books{
    padding: 0;
  
    min-height: 100%;
    background: #f7f7f7;

      
}

@media screen and (min-width: 1100px){
    #books {
        width: 28rem;
        margin: 0 auto!important;
        position: relative;
    }
}

.container{
   
    position: relative;
   
}
.half_height{
    height: 21.2rem;

}
.full_height{
    height: 100vh;
}
.container #mapbox{
    width: 100%;
    height: 100%;
}


.layer-switch{
        position: absolute;
    top: 1rem;
    right: 0.8rem;
    background-color: white;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layer-switch img{
        width: 25px;
    height: 25px;
    padding: 2px;
}
/******路线绘制 */
.play-path {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 2.68rem;
    left: 0.24rem;
    
    position: absolute;

  
    border-radius: .3rem;
    z-index: 1000;
    background-color: white;
}
.play-path img{
    width: 25px;
    height: 25px;
    padding: 2px;
}
/***全屏 */
.go-full{
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 2.68rem;
    left: 2.5rem;
    
    position: absolute;

  
    border-radius: .3rem;
    z-index: 1000;
    background-color: white;
}
.go-full img{
    width: 25px;
    height: 25px;
    padding: 2px;
}

.modify-popup{
    position: relative;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
   padding: 3px !important;
     white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    pointer-events: auto;
}
 /******详情***********/
 .info {
     display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
   
    position: absolute;
    margin-top: -20px;
    z-index: 10;
    flex-wrap: wrap;
 }
.info  .col1{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
    
    margin-top: -20px;
    z-index: 10;
    flex-wrap: wrap;
}

.info  .col1 .nick-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 2.2rem;
    position: absolute;
    right: 1.4rem;
    top: -3rem;
}

.info  .col1 .nick-icon img{
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
}
.info  .col1 .nick-icon .nick{
    display: flex;
    justify-content: start;
    align-items: center;
    
    white-space: nowrap;
    text-overflow: ellipsis;
    /* overflow: hidden; */

    font-family: PingFangSC-Medium;
    font-size: 0.8rem;
    color: #363a3e;
    margin-top: .3rem;
}
.info  .col1 .col1-1{
    display: flex;
    width: 95%;
    justify-content: start;
    align-items: center;

}
.info  .col1 .col1-1 .col1-1-1{
    display: flex;
    width: 80%;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}

.info  .col1 .col1-1 .col1-1-1 .title
{
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.8rem;
    color: #363a3e;
    padding: 10px 0px 5px 0px;
}
.info  .col1 .col1-1 .col1-1-1 .km{
    display: flex;
    justify-content: start;
    align-items: baseline;
    width: 100%;
}
.info  .col1 .col1-1 .col1-1-1 .km .num{
        display: flex;
    justify-content: start;
    align-items: center;
    padding: 5px 0px 10px 0px;
    font-size: 3rem;
}
.info  .col1 .col1-1 .col1-1-1 .km .txt{
    display: flex;
    justify-content: start;
    align-items: flex-end;
    color: #a0a9b9;
}


.info  .col1 .col1-2{
    display: flex;
    width: 100%;
     justify-content: center;
    align-items: center;
    border-top: 1px solid #a0a9b92e;
        padding: 1rem 0px 1rem 0px;
}
.info  .col1 .col1-2 .item{
    width: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.info  .col1 .col1-2 .item .it{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.info  .col1 .col1-2 .item .it .num{
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
        padding-top: 10px;
    padding-bottom: 5px;
    font-size: 0.8rem;
}
.info  .col1 .col1-2 .item  .it .txt{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: #a0a9b9;
    padding-top: 5px;
    padding-bottom: 10px;
}

.info  .col1 .col1-2  .add-line{
    content:"";
 width:1px;
height:2rem;
background-color:#a0a9b92e;
display: flex;
justify-content: center;
align-items: center;


    
}

.info  .col2 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    background-color: white;
    margin-top: 0.6rem;
}
.info  .col2 .col2-1{
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    font-size: 1rem;
        color: #363a3e;
    /* font-weight: 400; */
        padding: 10px 5px 5px 10px;
}
.info .col2 .col2-2{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0px 10px 0px;
}
.info .col2 .col2-2 .it{
    display: flex;
    width: 48%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.info .col2 .col2-2 .it .icon {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 5px 0px 5px 0px;
}

.info .col2 .col2-2 .it .icon .star{
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid red;
    display: flex;
    justify-content: start;
    align-items: center;     

}
.info .col2 .col2-2 .it .icon .star_{
width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid rgb(94,96,114);
    display: flex;
    justify-content: start;
    align-items: center;  
}

.info .col2 .col2-2 .it .txt{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: #a0a9b9;
    padding: 5px 0px 5px 0px;
}

.info .col2 .col2-2 .it .title{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;

}

.info .col2 .col2-2 .add-line{
        content:"";
 width:1px;
height:2rem;
background-color:#a0a9b92e;
display: flex;
justify-content: center;
align-items: center;
}

/******列表********/
.info .col3 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 0.6rem;
    flex-wrap: wrap;
        background-color: white;
}
.info .col3 .title{
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    font-size: 1.2rem;
    padding: 10px;
    font-weight: bold;
}

.info .col3 .item{
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 2rem;
}



.info .col3 .item .desc{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.info .col3 .item .desc .icon{
    display: flex;
    width: 5%;
    justify-content: center;
    align-items: center;
}
.info .col3 .item .desc .icon img{
    width: 20px;
    height: 20px;
}
.info .col3 .item .desc .desc1{
    display: flex;
    width: 95%;
    justify-content: start;
    align-items: center;
    font-size: 0.8rem;
    padding: 5px;
    font-weight: 300;
    color: #a0a9b9;
}
.info .col3 .item .col3-1 {
    display: flex;
    width: 95%;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    border-left: 0.2rem dotted #a0a9b9;
    margin-top: 1rem;

}
.info .col3 .item .col3-1  .city{
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    background-color: rgba(243,246,250,.8);
   
    padding: 5px;
    color: #363a3e;
    line-height: 1rem;

    margin-top: 1rem;
    margin-bottom: 1rem;

}

.info .col3 .item .col3-1 .line{
    display: flex;
    width: 95%;
    justify-content: center;
    align-items: center;
}

.info .col3 .item .col3-1 .line .image{
    display: flex;
    position: relative;
    
    justify-content: center;
    align-items: center;
        z-index: 10;
    left: 2%;
    position: absolute;
    border-radius: 10px;
   
}

.info .col3 .item .col3-1 .line .image .newUrl{
        width: 4rem;
    height: 6rem;
    border-radius: 6px;
    object-fit: fill;
}
.info .col3 .item .col3-1 .line .image .play{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
}

.info .col3 .item .col3-1 .line .image .num{
    position: absolute;
    background-color: yellow;
    color: #000;
    left: 0px;
    bottom: 0px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 3px;
}
.info .col3 .item .col3-1 .line .content{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 95%;
    background: #fff;
    box-shadow: 0 0.08rem 0.24rem 0 rgb(45 49 57 / 5%), 0 0.04rem 0.08rem 0 rgb(45 49 57 / 4%);
    border-radius: .24rem;
    position: relative;
    padding: .32rem 0 .32rem 1.28rem;
}

.info .col3 .item .col3-1 .line .content .col{
    display: flex;
    width: 95%;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
        padding: 1rem 0px 1rem 0px;
}

.info .col3 .item .col3-1 .line .content .col .col3-left{
    display: flex;
    width: 95%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.info .col3 .item .col3-1 .line .content .col .col3-left .title1{
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    font-size: 0.8rem;
    padding: 10px 5px 0px 5px;
    font-weight: bold;


    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.info .col3 .item .col3-1 .line .content .col .col3-left .txt{
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    font-size: 0.8rem;
    padding: 5px;
    color: #7b8391;
}

.info .col3 .item .col3-1 .line .content .col .col3-left .cont{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #175199;
    background-color: #f3f6fa;
    border-radius: 10px;
    padding: 2px;
}

.info .col3 .item .col3-1 .line .content .col .col3-left .label{
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}
.info .col3 .item .col3-1 .line .content .col .col3-left .label .label-text{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:3px;
    text-align: center;
    background: rgba(255,231,0,.1);
    color: #f5a623;
    border-radius: 10px;
}


.info .col3 .item .col3-1 .line .content .col .col3-right{
    display: flex;
    width: 5%;
    justify-content: center;
    align-items: center;
}

.info .col3 .item .col3-1 .line .content .col .col3-right img{
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
} 

/* 进入地图 */
.function-btn {
  width: 100%;
  margin-bottom: 0.5rem;
  /* padding: 0 12px 0 50px; */
  box-sizing: border-box;
  font-size: 0.8rem;
}

.function-btn > .btn {
  margin-top: 2px;
  padding: 0.6rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  color: #333;
  background-color: #fff;
  cursor: pointer;
}

.function-btn > .btn > .iconfont {
  color: #aaa;
  font-size: 0.8rem;
}

.company {
  position: relative;
  width: 100%;
  padding: 1rem 0;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 0.74rem;
}

#books .books_appreciate {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
#books .books_appreciate .appreciate_btn {
  margin-bottom: 1rem;
  display: inline-block;
  text-align: center;
  padding: 10px 24px;
  border-radius: 4px;
  color: #fff;
  background-color: rgb(230, 55, 58);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
}
#books .books_appreciate .appreciate_num {
  color: #999;
}

/*******音频播放控件
linear// 意思就是匀速的运动   infinite// 就是无限滚动的意思
******/
.audio-controls {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    bottom: 2rem;
    border-radius: 4px;
}
.audio-controls .ac {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.audio-controls .ac .icon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.audio-controls .ac .icon img{
    width: 25px;
    height: 25px;
    animation: rotate 3s linear infinite;  
}
@keyframes rotate{
    from{transform: rotate(0deg)}

    to{transform: rotate(360deg)}
}
.audio-controls .ac .title{
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.audio-controls .ac .del{
    display: flex;
    justify-content: center;
    align-items: center;

}
.audio-controls .ac .del img{
    width: 20px;
    height: 20px;
}


/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.fade-audio-down-enter-active {
  transition: all .3s ease;
}
.fade-audio-down-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-audio-down-enter, .fade-audio-down-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(-3rem);
  opacity: 0;
}



.share-rank-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(244, 245, 247);
  overflow-y: auto;
  z-index: 1000;
}

.share-rank-box > .close {
  position: fixed;
  top: 0.6rem;
  left: 0.6rem;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  background-color: rgba(255, 255, 255, 0.4);
}

.share-rank-box > .close > .iconfont {
  vertical-align: middle;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.4);
}

.share-rank-box > .banner {
  height: 5.6rem;
  padding: 0 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(rgb(255, 155, 105), rgb(255, 205, 145));
}

.share-rank-box > .banner >   .banner-title {
  padding-bottom: 0.2rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  border-bottom: 1px solid #fff;

}

.share-rank-box > .banner >   .banner-icon > .iconfont {
  font-size: 4rem;
  color: rgba(255, 255, 255, 0.3);
}

.share-rank-box > .self-share {
  width: 100%;
  display: flex;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;
}

.share-rank-box > .self-share > .left {
  margin-right: 0.5rem;
}

.share-rank-box > .self-share > .middle {
  width: calc(100% - 7.2rem);
}

.self-share > .left > .icon {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
}

.self-share > .middle > .nick {
  font-size: 0.9rem;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.self-share > .middle > .tag {
  margin-top: 2px;
}

.self-share > .middle > .tag > .text {
  display: inline-block;
  padding: 1px 2px;
  /* background-color: rgb(255, 155, 105); */
  border: 1px solid rgb(255, 155, 105);
  color: rgb(255, 155, 105);
  border-radius: 2px;
  font-size: 11px;
}

.self-share > .middle > .tip {
  margin-top: 4px;
  font-size: 0.74rem;
  color: #999;
}

.self-share > .middle > .tip > .num {
  color: rgb(240, 122, 53);
}

.self-share > .middle > .self-list {
  margin-top: 4px;
  display: inline-flex;
  width: 100%;
  overflow-x: auto;
}

.self-list::-webkit-scrollbar {
  display: none;
}

.self-list > .list {
  display: inline-flex;
}

.self-list > .list > .item {
  width: 1rem;
  height: 1rem;
  margin-right: 2px;
  margin-top: 2px;
}

.self-list > .list > .item > .icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.self-share > .right {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}

.self-share > .right > .btn {
  width: 4rem;
  height: 1.6rem;
  border-radius: 2em;
  font-size: 0.74rem;
  color: #fff;
  background-color: rgb(26, 173, 22);
}

.share-rank-box > .share-list {
  width: 100%;
  margin-top: 0.5rem;
  background-color: #fff;

  box-sizing: border-box;
}

.share-list > .item {
  position: relative;
  width: 100%;
  display: flex;
  padding: 0.5rem;
  box-sizing: border-box;
  /* border-bottom: 1px solid transparent; */
  /* border-image: 100% 0 0 linear-gradient(90deg, transparent 3.6rem, #eee 0); */
}

.share-list > .item:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0.5rem;
  width: 80%;
  height: 0;
  background-color: transparent;
  border-bottom: 1px solid #eee;
}

.share-list > .item > .left {
  margin-right: 0.5rem;
}

.share-list > .item > .left > .icon {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
}

.share-list > .item > .middle {
  flex: 1;
}

.share-list > .item > .middle > .nick {
  font-size: 0.9rem;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.share-list > .item > .middle > .tip {
  margin-top: 4px;
  font-size: 0.74rem;
  color: #999;
}

.share-list > .item > .middle > .tip > .num {
  color: rgb(240, 122, 53);
}

.share-list > .item > .right {
  margin-left: 0.5rem;
}

.share-list > .item > .right > .rank {
  width: 1.7rem;
  height: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: #81888f;
  border: 4px solid rgba(255, 255, 255, 0.6);
}

.share-list > .item:nth-child(1) > .right > .rank {
  background-color: rgb(255, 110, 117);
}
.share-list > .item:nth-child(2) > .right > .rank {
  background-color: rgb(255, 197, 91);
}
.share-list > .item:nth-child(3) > .right > .rank {
  background-color: rgb(111, 189, 255);
}




</style>