<template>
    <div class="NewTeamName" v-title data-title="我是领队">
        <mt-header fixed title="我是领队">
            <a slot="left">
                <mt-button icon="back" @click="$router.go(-1)"></mt-button>
            </a>
        </mt-header>
        <div class="page-content">
            <h2>先给团队起个队名吧 <span>(10字以内)</span></h2>
            <input type="text" placeholder="请输入队名" maxlength="10" v-model='group_name'>
            <div class="act-btn-box">
                <!-- 报名按钮 -->
                <div @click="link" class="act-btn">确认提交</div>
            </div>
        </div>
    </div>
</template>
<script>
    import {Toast} from 'mint-ui';
    export default {
        data () {
            return {
                aid: this.$route.query.aid,
                price: this.$route.query.price,
                user_id: "",
                group_name: "",
            }
        },
        mounted(){
            this.unload();
        },
        methods: {
            unload(){
                if (this.check_login()) {
                    this.user_id = this.check_login().user_id
                }
            },
            link(){
                this.$ajax("post", "activity/user_add_group_name", {
                    aid: this.aid,
                    user_id: this.user_id,
                    group_name: this.group_name
                }, (res) => {
                    if (res.data.id == 0) {
                        this.$router.push({path: "/activity/PriceApply", query: {aid: this.aid, price: this.price}})
                    } else {
                        Toast(res.data.msg)
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .NewTeamName {
        height: 100%;
        background-color: #fff;
    }

    .NewTeamName h2 {
        font-size: 1rem;
        height: 1.6rem;
        line-height: 1.6rem;
        padding: .4rem;
    }

    .NewTeamName h2 span {
        font-size: .6rem;
        color: #666;
    }

    .NewTeamName input {
        height: 1.6rem;
        line-height: 1.6rem;
        width: 80%;
        margin-left: .4rem;
        font-size: .8rem;
    }
</style>