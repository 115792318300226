<template>
  <div class="container">
    <div class="map-wrapper">
      <!-- leaftmap容器 -->
      <div id="map_" :style="getMapBoxHeight()"></div>
      
      <!-- 底部菜单 -->
      <div
        class="bottom-menu"
        :class="show_menu ? 'bottom-menu-active' : ''"
        v-if="bottom_menu_list"
      >
        <div class="footer-menu">
          <div class="wrapper">
            <ul class="list" :style="getMenuListWidth()">
              <li
                class="item"
                :style="getMenuItemWidth()"
                :class="first_menu_type == item.id ? 'item-active' : ''"
                v-for="(item,i) in bottom_menu_list"
                :key="i"
                @click="menuClickHandle(item.id,i, true)"
              >
                <img class="icon" :src="choseIconByType(item.id)" alt/>
                <p class="name" v-if="is_language == 'zh'">{{item.title}}</p>
                <p class="name" v-else-if="is_language == 'en'">{{item.en_title}}</p>
                <p class="name" v-else>{{item.or_title}}</p>

              </li>
            </ul>
          </div>
          <div class="btn" @click="menuBtnHandle">
            <i class="iconfont iconUp"></i>
          </div>
        </div>
        <!-- 底部导航 -->
        <div class="footer-nav" v-show="show_menu">
          <!-- 搜索 -->
          <div
            class="footer-search"
            v-show="((typeof first_menu_type == 'string') && first_menu_type != '15' && first_menu_type != '16' && first_menu_type != '17' && first_menu_type != 'line')
        || ((typeof first_menu_type =='object') && !first_menu_type.includes(15) && !first_menu_type.includes(16) && !first_menu_type.includes(17))"
          >
            <div class="wrapper">
              <div class="search-input">
                <input type="text" :placeholder="placeholderText" v-model="search_value" />
              </div>
              <div class="search-icon">
                <i class="iconfont iconsousuo"></i>
              </div>
            </div>
          </div>
          <!-- 点位列表 -->
          <div class="nav-wrapper">
            <ul class="footer-nav-list" v-if="first_menu_type != 'line'">
              <li
                class="nav-item"
                :class="marker_id == item.id ? 'nav-item-active' : ''"
                v-for="(item,i) in filterPointListByType(point_list)"
                :key="i"
                @click="menuLocation(item.id)"
              >
                <img :src="icon_object['iconMap' + item.type_]" alt  style="width:1.2rem;"/>
                <!-- <img :src="filterIconByType(item.type_)" alt /> -->
                <p v-if="is_language == 'zh'">{{item.spots_name.length > 11 ? item.spots_name.substr(0, 11) + "..." : item.spots_name}}</p>
                <p v-else-if="is_language == 'en'">{{  item.en_title}}</p>
                <p v-else>{{ item.or_title}}</p>
                <!-- <p>{{clampText(item.spots_name, 11)}}</p> -->
                <p v-if="item.distance" class="distance">{{item.distance}}</p>
                <i v-if="marker_id == item.id" class="iconfont iconxuanze"></i>
              </li>
            </ul>
            <ul class="footer-nav-list" v-else>
              <li
                class="nav-item"
                :class="point_id == item.id ? 'nav-item-active' : ''"
                v-for="(item,i) in lineList"
                :key="i"
                @click="drawLine(item)"
              >
                <img :src="icon_object['iconMapline']" alt style="width:1.2rem;"/>
                <p v-if="is_language == 'zh'">{{item.title.length > 11 ? item.title.substr(0, 11) + "..." : item.title}}</p>
                <p v-else-if="is_language == 'en'">{{ item.en_title}}</p>
                <p v-else>{{item.or_title}}</p>
                <!-- <p>{{clampText(item.title, 11)}}</p> -->
                <p v-if="item.distance" class="distance">{{item.distance}}</p>
                <i v-if="marker_id == item.id" class="iconfont iconxuanze"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 分区logo -->
      <transition name="area-fade">
        <div class="pixi-logo" v-if="logo_list" v-show="show_area">
          <div class="logo-wrapper">
            <ul class="logo-menu">
              <li class="logo-menu-list" v-for="(item, i) in logo_list" :key="i">
                <img :src="item.logo_" alt @click="areaLocation(item.x_, item.y_)" />
                <!-- <p>{{item.title}}</p> -->
              </li>
            </ul>
            <div class="logo-btn" @click="show_area= false">
              <i class="iconfont iconqianjin"></i>
            </div>
          </div>
        </div>
      </transition>
       <!-- 统计数据 -->
          <map-user-list v-if="map_data.is_show_data > 0" :mapData="map_data" :show_data="show_data"></map-user-list>
      <!-- 顶部导航 -->
      <transition name="top-fade">
        <div class="function-top" v-show="!show_area">
          <ul class="top-menu">
            <li class="top-menu-li top-brief" @click="show_spot_brief = !show_spot_brief">
              <i class="iconfont iconShape"></i>
              <p v-if="is_language == 'zh'">简介</p>
              <p v-else-if="is_language == 'en'">Desc</p>
              <p v-else>{{brief_}}</p>
            </li>
            <li v-if="languageCount == 2" class="top-menu-li top-zone" @click="set_is_language">
              <i class="iconfont iconpaiming"></i>
              <p v-if="is_language == 'zh'">En</p>
              <p v-else>中文</p>

            </li>
            <li v-if="languageCount == 3" class="top-menu-li top-zone" @click="is_show_language = !is_show_language">
              <i class="iconfont iconpaiming"></i>
              <p v-if="is_language == 'zh'">语言</p>
              <p v-else-if="is_language == 'en'">En</p>
              <p v-else>{{language_}}</p>
            </li>
            <li v-if="is_show_area" class="top-menu-li top-zone" @click="show_area = true">
              <i class="iconfont iconfenqu"></i>
              <p>分区</p>
            </li>
          </ul>
          <div
            :class="['top-data', 'top-menu-li', 'top-menu', show_data ? 'top-data-active' : '']"
            @click="show_data=!show_data"  v-if="map_data.is_show_data > 0"
          >
            <i class="iconfont iconsanxiangmulu"></i>
            <span>数据</span>
          </div>
          <!--VR-->
          <div
            :class="['top-data', 'top-menu-li', 'top-menu', show_data ? 'top-data-active' : '']"
            @click="openVr"  v-if="vr"
          >
            <i class="iconfont iconvr"></i>
            <span>{{id == 2149 ? '全景' : 'VR'}}</span>
          </div>

          <a
            v-if="id == 1328"
            class="top-menu-li top-menu top-heat -active"
            href="http://z.zmlxj.com/zhoushan_1328"
          >
            <i class="iconfont iconshanghurelitu"></i>
            <p>热力</p>
          </a>
          <a
            v-else-if="is_show_heat"
            class="top-menu-li top-menu top-heat"
            :class="showHeat ? '-active' : ''"
            @click="toggleHeat"
          >
            <i class="iconfont iconshanghurelitu"></i>
            <p>热力</p>
          </a>
          <div class="top-menu-li top-menu top-line"  v-if="is_show_line" @click="showLine">
            <i class="iconfont iconluxian1"></i>
            <p v-if="is_language == 'zh'">路线</p>
            <p v-else-if="is_language == 'en'">Path</p>
            <p v-else>{{path_}}</p>

          </div>
          <div
            class="top-menu-li top-menu top-music"
            @click="playBgMusic"
            v-if="map_data.bgAudioUrl"
          >
            <i class="iconfont iconswitch_open" v-if="bgmusic_play"></i>
            <i class="iconfont iconswitch_close" v-else></i>
            <p v-if="is_language == 'zh'">音乐</p>
            <p v-else-if="is_language == 'en'">Music</p>
            <p v-else>{{music_}}</p>
          </div>
          
        </div>
      </transition>
      <!--语言切换-->
      <transition name="language">
        <div class="lan-div" v-if="is_show_language">
          <div class="lan-it" :class="it.is_selected ? 'lan-selected' : 'lan-active' " v-for="(it ,i) in language" :key="i" @click="switchLanguage(it,i)">
            
            <p>{{it.title}}</p>
          </div>
          
        </div>
      </transition>
      <!-- 景区简介 -->
      <transition name="bounce">
        <div class="spot-brief" v-show="show_spot_brief">
          <div class="wrapper">
            <div class="brief-close" @click="show_spot_brief = false">
              <i class="iconfont iconbaseline-close-px"></i>
            </div>
            <div class="spot-brief-icon">
              <img class="icon" :src="map_data.logo_" alt />
            </div>
            <div class="spot-brief-header">
              <h2 class="spot-brief-name" v-if="is_language == 'zh'">{{map_data.label_name}}</h2>
              <h2 class="spot-brief-name" v-else-if="is_language =='en'">{{map_data.en_title}}</h2>
              <h2 class="spot-brief-name" v-else>{{map_data.or_title}}</h2>
            </div>
            <div class="spot-brief-info">
              <div class="spot-brief-address spot-brief-item">
                <p class="item-name">
                  <i class="iconfont icondizhi2"></i>
                  <span>{{language_address_[is_language]}}：</span>
                </p>
                
                <p class="item-value" v-if="is_language == 'zh'">{{map_data.address}}</p>
                <p class="item-value" v-else-if="is_language == 'en'">{{map_data.en_address}}</p>
                <p class="item-value" v-else>{{map_data.or_address}}</p>
              </div>
              <div class="spot-brief-time spot-brief-item" v-if="map_data.time">
                <p class="item-name">
                  <i class="iconfont iconshijian"></i>
                  <span>{{language_open_time_[is_language]}}：</span>
                </p>
                <p class="item-value">{{map_data.time}}</p>
              </div>
            </div>
            <div class="spot-brief-content">
              <article v-html="map_data.content" v-if="is_language == 'zh'"></article>
              <article v-html="map_data.en_content" v-else-if="is_language == 'en'"></article>
              <article v-html="map_data.or_content" v-else></article>

            </div>
          </div>
        </div>
      </transition>
      <!-- 音频播放 -->
      <audio :src="map_data.bgAudioUrl" autoplay="autoplay" ref="audio"></audio>
      <!-- 播放界面 -->
      <transition name="audio-fade">
        <div class="audio-play" v-show="show_audio">
          <span class="audio-play-icon">
            <img src="../../assets/switch/bgmusic.png" alt />
          </span>
          <span class="audio-play-name">{{audio_name || map_data.label_name}}</span>
          <span class="audio-play-close" @click="closeAudio">
            <i class="iconfont iconbaseline-close-px"></i>
          </span>
        </div>
      </transition>
      <!-- 左下功能按钮 -->
      <div class="function-btn">
        <!-- 定位按钮 -->
        <div
          v-if="is_show_location"
          class="location item"
          :class="locate === null ? '' : 'locate-active'"
          @click="updateLocation"
        >
          <i class="iconfont icondingwei"></i>
        </div>

        <!-- 公司名称 -->
        <technical-support :ts_type_="ts_type_" contact_side="feedback" :id="id"></technical-support>
      </div>
      <!-- 发布红包 -->
      <publish-button :id="label_id" :id_type_="id_type_" v-if="is_admin" bottom="3rem"></publish-button>
      <!-- 红包雨 -->
      <red-packet
        :redPacketList="redPacketList"
        v-if="have_red_packet_rain"
        :id_type_="id_type_"
        :red_packet_type_="red_packet_type_"
        :red_packet_image_url="red_packet_image_url"
        :is_admin="is_admin"
      ></red-packet>
      <!-- 微信扫描二维码 -->
      <transition name='anim-opacity'>
        <div
          class="qrcode-box"
          v-show="showQrCode"
          v-if="map_data.wxacodeUrl"
          @click.self="showQrCode=false">
          <qr-code
            :showQrCode="showQrCode"
            :qrcode="map_data.wxacodeUrl"
            @closecode="showQrCode=false">
          </qr-code>
        </div>
      </transition>
      <!-- 天气信息 -->
      <div class="weather-box">
        <weather-info :info="weatherInfo" :is_language="is_language" :languageCount="languageCount" :weather="weather" v-if="weatherInfo"></weather-info>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { Toast } from 'mint-ui'

import { LMap, LTileLayer, LMarker, LPopup, LImageOverlay } from 'vue2-leaflet' // leaflet
import 'leaflet.markercluster/dist/MarkerCluster.css' // leaflet 点位聚合插件
import 'leaflet.markercluster/dist/MarkerCluster.Default.css' // leaflet 点位聚合插件
import 'leaflet.markercluster' // leaflet 点位聚合插件
import 'leaflet-responsive-popup' // leaflet popup弹出框插件
import 'leaflet.polyline.snakeanim' // leaflet 折线动画插件
import 'leaflet.heat' // 热力图插件

import TechnicalSupport from '../../components/TechnicalSupport'
import PublishButton from '../../components/PublishButton'
import RedPacket from '../../components/RedPacket'
import MapUserList from '../../components/MapUserList'
import QrCode from '../../components/QrCode'
import WeatherInfo from '../../components/WeatherInfo'
import { iconStore } from '../../assets/utils/map-marker.js'
var wx = require('weixin-js-sdk')
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LImageOverlay,
    TechnicalSupport,
    PublishButton,
    RedPacket,
    MapUserList,
    QrCode,
    WeatherInfo
  },
  data () {
    return {
      // 初始数据
      park_id: 298,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      label_id: this.$route.query.id ? this.$route.query.id : 0,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      version: '1.0.0',
      open_id: localStorage.getItem('open_id_'+this.$route.query.id)
        ? localStorage.getItem('open_id_'+this.$route.query.id)
        : '',
      id: this.$route.query.id ? this.$route.query.id : 0,
      ts_type_: 'label',
      id_type_: 'leafletMap',
      area_index:
        this.$route.query.area_index > -1 ? this.$route.query.area_index : -1,
      // PC端嵌入演示
      come_: this.$route.query.come_ || '',
      // 请求数据
      bottom_menu_list: [], // 底部导航条
      map_data: [], // 地图对象
      point_list: [], // 点位列表
      title_list: [], // 标题列表
      tel_: '', // 联系电话
      is_pay: 0, // 是否支付
      price: 0, // 价格
      backUrl: 'leafletMap',
      is_draw_rect: 0, // 画矩形区域
      is_make_lnglat_by_xy: '',

      // icon
      icon_object: iconStore,

      mapExtent: [], // 地图西南和东北的点位列表
      zoom: 17, // 地图初始缩放
      mapMinZoom: 16, // 地图最小缩放
      mapMaxZoom: 20, // 地图最大缩放
      bounds: [], // 地图矩形区域对象
      map: null, // 地图对象
      zIndex: 1000,

      first_menu_type: this.$route.query.mtype_ || '', // 初始目录类型,在龙岩中传递过来有：43-地质遗迹，45-人文景观 ，46-自然景观，2510-文化遗产
      show_menu: false, // 显示详细目录
      marker_group: {}, // marker列表
      marker_list: [],
      search_value: '', // 搜索内容
      show_point_brief: false,
      point_id: '', // 当前点位的id
      point_item: [], // 当前点位
      marker_id: '', // 当前显示的popup的marker的id
      check_menu: -1,
      is_show_location: 0, // 显示定位
      is_show_area: 0, // 显示分区
      show_area: false,
      logo_list: [],
      audio_id: -1,
      // 地图初始中心坐标
      centerLng: '',
      centerLat: '',
      center: [], // 地图初始的坐标中心点经纬度

      audio_dom: '', // 音频播放dom节点
      audio_name: '', // 正在播放的音频名称
      audio_play: false, // 解说播放
      bgmusic_play: true, // 背景音乐播放
      show_spot_brief: false, // 显示地图简介
      locate: null, // 定位locate对象
      locate_marker: null, // 定位marker对象

      first_latlng: '', // 初次获取的定位坐标
      is_admin: 0,

      // 红包
      redPacketList: [],
      have_red_packet_rain: 0,
      red_packet_type_: '',
      red_packet_image_url: '',

      // 分享
      have_chance: 0,
      have_chance_id: '',

      // 动态路径
      is_show_line: '',
      currentPath: null, // 动态路径对象
      lineList: [], // 折线经过的经纬度列表

      snake: null, // 折线对象
      isDrawLine: false, // 正在进行折线动画

      // 热力
      max_line_numbers: '', // 最大排队人数
      is_show_heat: '', // 是否有热力图
      showHeat: false, // 显示热力图
      heatList: [],
      heatLayer: null, // 热力图图层

      // 顶部数据栏
      show_data: true,
      // 显示微信二维码
      showQrCode: false,
      // 天气
      weatherInfo: null,

      firstLocationTimer: null // 进入地图后，延迟定位定时器
      ,
      pathList:[],//路线经纬度集合

      //语言切换-----------------------
      language:'',//有哪几种语言
      is_language:this.$route.query.is_show_language ? this.$route.query.language : 'zh',//默认选择中文
      is_show_language:this.$route.query.is_show_language ? (this.$route.query.is_show_language == 1 ? true : false) : false,//选择语言
      languageCount:0,//拥有几种语言，0，2，3、没有1是因为只有中文就不用显示了
      brief_:'',
      language_:'',
      path_:'',
      placeholderText:'输入景点名称',
      music_:'',
      play_:'',
      nav_:'',
      vr_:'',
      detail_:'',
      marker_list_en:[],
      marker_list_or:[],
      weather:'',


      language_address_:[],
      language_open_time_:[],
      vr:'',//VR地址

    }
  },
  watch: {
    // 根据id获取点位对象
    point_id: function (val) {
      this.point_item = this.point_list.filter((item, i) => {
        return item.id === val
      })[0]
    }
  },
  computed: {
    // 根据解说的播放状态,显示或隐藏播放条
    show_audio: function () {
      if (this.audio_play) {
        return true
      } else {
        return false
      }
    }
  },
  beforeDestroy () {
    // 页面销毁前，如果有定时器，清除定时器
    if (this.firstLocationTimer) {
      clearTimeout(this.firstLocationTimer)
    }
  },

  mounted () {
    if (this.token) {
      // 已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id !== 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token === this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id_'+this.id, this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_'+this.id, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_'+this.id, '')
      }
    }

    if(this.is_weixin && this.open_id == '')
      {
        this.authorize();

      }
      else
      {
        //在其他的平台，不能使用微信授权

      }

    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }

    this.getData()
    // 获取audio元素
    this.$nextTick(() => {
      this.audio_dom = this.$refs.audio
      this.audio_dom.addEventListener('ended', () => {
        this.audio_play = false
        if (this.bgmusic_play) {
          this.bgmusic_play = false
        }
        this.checkIsPlay()
      })
    })
  },
  methods: {
    //2:两种语言的语言切换
    set_is_language(){
      this.is_language = this.is_language == 'zh' ? 'en' : 'zh';
      this._make_language();
    },
    //3种语言:语言切换
    switchLanguage(it,i){
     
      this.is_language = it.id;
        for(var k = 0; k < this.language.length;k++)
        {
          this.language[k].is_selected = 0;
        }
        this.language[i].is_selected = 1;
      this.is_show_language = !this.is_show_language;
      if(this.is_language == 'zh')
              {
                document.title = this.map_data.label_name

              }
              else if(this.is_language == 'en')
              {
              document.title = this.map_data.en_title

              }
              else
              {
              document.title = this.map_data.or_title;

              }
      this._make_language();
    },
    /**
     * 语言选择公共的方法
     * 
     */
    _make_language()
    {
        this.placeholderText = this.is_language == 'zh' ? '输入景点名称' :(this.is_language == 'en' ? 'plase input spots name' : '');

      //先清空所有的图层
      this.marker_group.clearLayers();
      //同时清空marker_list
      this.marker_list = [];
      this.map.removeLayer(this.marker_list);
      this.initPoint(this.point_list);
    },
    // 显示路线列表
    showLine () {
      this.show_menu = true
      this.first_menu_type = 'line'
    },
    // 显示热力图
    toggleHeat () {
      if (!this.showHeat) {
        if (!this.heatLayer) {
          // 如果没有热力图层,创建热力图层
          this.heatLayer = L.heatLayer(this.heatList, {
            minOpacity: 0.6,
            radius: 40,
            gradient: {
              0: '#000000',
              0.25: '#30b6f6',
              0.5: '#0dffd2',
              0.75: 'yellow',
              1: 'red'
            }
          }).addTo(this.map)
        } else {
          // 如果已经有热力图层,直接添加进地图
          this.heatLayer.addTo(this.map)
        }
        this.showHeat = true
      } else {
        // 将热力图层从地图中移除
        this.map.removeLayer(this.heatLayer)
        this.showHeat = false
      }
    },
    /**
     * 根据路径 id 从服务器获取路径点位列表
     * @param {String} id 路径的id
     */
    getRequestLine (id) {
      return new Promise((resolve, reject) => {
        this.$ajax(
          'post',
          'Label/get_leaflet_map_books_point_list',
          {
            id: id,
            token: this.token
          },
          res => {
            if (res.data.id === 0) {
              //清除原来的marker图层
              this.marker_group.clearLayers();
              this.point_list = this.point_list.concat(res.data.data.list);

              this.initPoint(this.point_list.concat(res.data.data.list));
              resolve(res.data.data.list)
              
            }
          }
        )
      })
    },
    /**
     * 在leaflet map 上画路线
     *
     * @param { object } line 保存了路径字符串的路线对象
     */
    drawLine (line) {
      if (this.isDrawLine) {
        // 正在进行折线动画，退出
        return
      }
      this.isDrawLine = true
      // 将当前点位的id设置为路线的id
      this.point_id = line.id
      let pathList = []
      if (this.is_make_lnglat_by_xy > 0) {
        // 如果经纬度通过平面坐标计算
        line.path.split(';').forEach(item => {
          let xy = item.split(',')
          // 获取模拟的经纬度
          let lnglat = this.mockLagLng({
            x_: xy[0] * 1,
            y_: xy[1] * 1,
            lat: this.mapExtent[3],
            lng: this.mapExtent[2],
            width: this.map_data.width_,
            height: this.map_data.height_
          })
          pathList.push([lnglat[0], lnglat[1]])
        });

        this.drawLineOnMap(pathList);
      }
      else if(line.is_request == 1)
      {
        //表示需要网络请求获取数据
        this.getRequestLine(this.point_id).then(ls => {
          //console.log(ls)
          ls.forEach(v => {
            pathList.push([v.lat  , v.lng ]);
          })
          this.drawLineOnMap(pathList);
        }, err => {
          console.log(err)
        })
        
      }
       else {
        line.path.split(';').forEach(item => {
          let lnglat = item.split(',')
          pathList.push([lnglat[1] * 1, lnglat[0] * 1])
        });
        this.drawLineOnMap(pathList);
      }
       
      
    },
    /**
     * 绘制路线的公共方法
     */
    drawLineOnMap(pathList)
    {
      // 在map上画折线动画的方法
      this.snake = {
        layers: []
      }

      if (this.snake.group) {
        this.snake.group.clearLayers()
        this.snake.layers = []
      }

      this.clearPath()
      
      this.map.fitBounds(L.latLngBounds(pathList))
      this.show_menu = false
      this.snake.line = L.polyline(pathList, {
        snakingSpeed: 200,
        color: '#407eff',
        weight: 6
      });
      
      this.snake.line.addTo(this.map).snakeIn() // 动画方法
      // 标记起点与终点
      // let start = L.marker(this.pathList[0]);
      // let end = L.marker(this.pathList[this.pathList.length - 1]);
      // this.snake.layers.push(start)
      // this.snake.layers.push(end)
      this.snake.layers.push(this.snake.line)
      this.snake.group = L.layerGroup(this.snake.layers).addTo(this.map)

      this.snake.line.on('snakeend', () => {
        // 动画完成后清除画完的路线并显示动态路线
        this.isDrawLine = false
        this.showPath(pathList)
        this.map.removeLayer(this.snake.group)
      })
    },
    // 清除当前显示的动态路线
    clearPath () {
      if (this.currentPath) {
        this.map.removeLayer(this.currentPath)
      }
    },
    /**
     * 在 map 中显示动态路线
     *
     * @param { array } pathList 保存路径点位的二维数组
     */
    showPath (pathList) {
      this.currentPath = L.polyline.antPath(pathList, {
        paused: false,
        reverse: false,
        delay: 3000,
        dashArray: [10, 30],
        weight: 6,
        opacity: 1,
        color: '#407eff',
        pulseColor: '#ffffff'
      })

      this.currentPath.addTo(this.map)

      // this.map.fitBounds(currentPath.getBounds());
    },
    /**
     * 根据是否有底部菜单来返回地图容器的高度
     * @return { object } 返回地图容器的高度
     */
    getMapBoxHeight () {
      if (this.first_menu_type) {
        return {
          height: 'calc(100% - 2.4rem)'
        }
      } else {
        return {
          height: '100%'
        }
      }
    },
    /**
     * 根据底部菜单元素的个数，动态调整底部菜单元素的显示方法
     * 如果元素的个数大于5个，则将底部菜单设置为可以水平滚动
     *
     * @return { object } 底部菜单栏的宽度（百分比数值）
     */
    getMenuListWidth () {
      let wd
      const WIN_WIDTH = document.documentElement.clientWidth || document.body.clientWidth
      console.log(WIN_WIDTH)
      if (this.bottom_menu_list.length > 5 && WIN_WIDTH < 579) {
        wd = 22 * this.bottom_menu_list.length + '%'
      } else {
        wd = '100%'
      }
      return {
        width: wd
      }
    },
    /**
     * 根据底部菜单元素的个数（大于5个或小于等于5个），动态调整每一个菜单元素的宽度
     *
     * @return { object } 底部菜单元素的宽度（百分比数值）
     */
    getMenuItemWidth () {
      let wd
      if (this.bottom_menu_list.length > 5) {
        wd = '22%'
      } else {
        wd = '50%'
      }

      return {
        width: wd
      }
    },
    /**
     * 将分区移动到屏幕显示区域
     *
     * @param { string } x_ 分区的 x 坐标
     * @param { string } y_ 分区的 y 坐标
     */
    areaLocation (x_, y_) {
      let mLatLng = this.mockLagLng({
        // 公共方法，将 x y 映射为 lng lat
        x_: x_,
        y_: y_,
        lat: this.mapExtent[3],
        lng: this.mapExtent[2],
        width: this.map_data.width_,
        height: this.map_data.height_
      })
      this.map.setView(L.latLng(mLatLng[0], mLatLng[1]), this.zoom + 1)
    },
    // 点击定位按钮，更新定位
    updateLocation () {
      if (this.firstLocationTimer) {
        clearTimeout(this.firstLocationTimer)
      }
      if (this.locate != null) {
        this.locate = null
        if (this.locate_marker !== null) {
          this.map.removeLayer(this.locate_marker)
          this.locate_marker = null
        }
        this.map.stopLocate()
        return
      }
      this.locate = this.map.locate({
        setView: false,
        watch: true,
        maxZoom: this.mapMaxZoom,
        maximumAge: 3000,
        timeout: 3000
      })
      // console.log(this.locate);
      if (this.is_show_location) {
        this.map.on('locationfound', e => {
          // console.log(e);
          
          if (
            e.latlng.lng* 1 < this.mapExtent[0] * 1 ||
            e.latlng.lng* 1 > this.mapExtent[2] * 1 ||
            e.latlng.lat* 1 < this.mapExtent[1] * 1 ||
            e.latlng.lat* 1 > this.mapExtent[3] * 1
          ) 
          {
            Toast('你不在该地图区域')
            this.map.stopLocate()
            this.locate = null
            return
          }
          if (this.locate_marker != null) {
            this.map.removeLayer(this.locate_marker)
          } else {
            this.map.setView(e.latlng)
          }
          this.locate_marker = L.marker(e.latlng)
            .addTo(this.map)
            .bindPopup('你在这里')
          this.sortByDistance(e.latlng)
        })
        this.map.on('locationerror', e => {
          Toast('定位失败,请允许权限')
          this.map.stopLocate()
          this.locate = null
          this.locate_marker = null
        })
      }
    },
    /**
     * 点击底部点位菜单中的点位元素
     * @param { string } id 点位的 id 值
     */
    menuLocation (id) {
      this.point_id = id
      if (id === this.marker_id) {
        return
      }
      let marker
      this.marker_list.forEach(item => {
        if (item.id === id) {
          marker = item
        }
      })
      this.show_menu = false
      this.togglePopup(marker)
    },
    /**
     * 根据类型过滤显示点位列表
     * @return { array } 根据类型值过滤了的点位列表
     */
    filterPointListByType (list) {
      if (typeof this.first_menu_type === 'string') {
        if (
          this.first_menu_type !== '15' &&
          this.first_menu_type !== '16' &&
          this.first_menu_type !== '17' &&
          this.first_menu_type !== 15 &&
          this.first_menu_type !== 16 &&
          this.first_menu_type !== 17
        ) {
          // 如果是景点，需要双层过滤
          let spotsList = list.filter(item => {
            // 根据类型过滤
            return item.type_ === this.first_menu_type
          })
          return spotsList.filter(item => {
            // 根据搜索过滤
            return item.spots_name.match(this.search_value)
          })
        } else {
          // 不是景点没有搜索
          return list.filter(item => {
            return item.type_ === this.first_menu_type
          })
        }
      } else if (typeof this.first_menu_type === 'object') {
        if (
          !this.first_menu_type.includes(15) &&
          !this.first_menu_type.includes(16) &&
          !this.first_menu_type.includes(17)
        ) {
          let spotsList = list.filter(item => {
            return this.first_menu_type.includes(item.type_ * 1)
          })
          return spotsList.filter(item => {
            // 根据搜索过滤
            return item.spots_name.match(this.search_value)
          })
        } else {
          // 不是景点没有搜索
          return list.filter(item => {
            return this.first_menu_type.includes(item.type_ * 1)
          })
        }
      } else {
        console.log('id是其他类型')
      }
    },
    // 显示目录列表。根据条件判断是否隐藏点位菜单
    showMenu (id, index) {
      let flag = typeof this.first_menu_type !== 'string'

      if (
        (!flag && this.first_menu_type === id) ||
        (flag && this.first_menu_type[0] === id[0])
      ) {
        this.show_menu = false
      }
    },
    // 点击目录控制按钮（方向箭头）
    menuBtnHandle () {
      if (this.isDrawLine) {
        // 如果正在画路径，则不响应点击事件
        return
      }
      this.show_menu = !this.show_menu
      if (this.show_menu) {
        this.search_value = ''
        this.firstLocation()
        if (this.first_menu_type < 0 || this.first_menu_type[0] < 0) {
          this.first_menu_type = this.bottom_menu_list[0].id
          this.check_menu = 0
          this.marker_list.forEach(item => {
            this.marker_group.removeLayer(item)
            if (
              (typeof this.first_menu_type === 'string' &&
                this.first_menu_type === item.type_) ||
              (typeof this.first_menu_type === 'object' &&
                this.first_menu_type.includes(item.type_ * 1))
            ) {
              this.marker_group.addLayer(item)
            }
          })
        }
      }
    },

    /**
     * 点击底部的目录菜单
     * @param { String / Array } id 以字符串或数组表示的目录的id
     * @param { Number } index 点击的目录在目录列表中的索引值
     * @param { Boolean } flag 点击目录菜单时，如果目录菜单是隐藏的，则显示出来
     */
    menuClickHandle (id, index, flag) {
      if (this.isDrawLine) {
        // 如果正在画路径，则不响应点击事件
        Toast('正在绘制路线')
        return false
      }
      let firstPointInGroup = true
      let firstPointInGroupLatLng = null;
      if (this.show_menu) {
        // 如果目录是升起来的
        this.showMenu(id, index)
        this.check_menu = index
        this.first_menu_type = id;
        //console.log('1',this.marker_group);
        this.marker_list.forEach((item, i) => {
          this.marker_group.removeLayer(item)

          if (
            (typeof id === 'string' && id === item.type_) ||
            ((typeof id === 'object' && id.includes(item.type_ * 1)) ||
              id === 'line')
          ) {
            if (firstPointInGroup) {
              firstPointInGroupLatLng = item._latlng
              firstPointInGroup = false
            }
            // this.map.removeLayer(item);
            this.marker_group.addLayer(item)
          }
        })
      } else {
        // 如果目录没有升起来
        // 先清除路线
        this.clearPath()
        if (flag) {
          this.show_menu = true
        }
        // if (this.check_menu !== index) {
        // 如果点击的目录不是正在显示的目录

        this.check_menu = index
        this.first_menu_type = id
        this.marker_list.forEach((item, i) => {
          this.marker_group.removeLayer(item);//先清除所有的marker
          if (
            (typeof id === 'string' && id === item.type_) ||
              ((typeof id === 'object' && id.includes(item.type_ * 1)) ||
                id === 'line')
          ) {
            if (firstPointInGroup) {
              firstPointInGroupLatLng = item._latlng
              firstPointInGroup = false
            }
            //console.log('->',item)
            this.marker_group.addLayer(item)
          }
        })
        
        if (id === 'line') {
          // 如果点击的是推荐路线
          // 显示第一条推荐的路线
          this.drawLine(this.lineList[0]);
        }
      }
      console.log('firstPointInGroupLatLng=',firstPointInGroupLatLng,'id=',id,'index=',index);
      // 点击时移动地图
      if (flag) {
        this.map.setView(
          firstPointInGroupLatLng,
          this.zoom
        )
      }
    },
    
    /**
     * 根据点位的类型值返回对应的icon
     * @reutrn { string } icon 的相对路径
     */
    filterIconByType (type) {
      return this.icon_object['iconMap' + type]
    },
    /**
     * 根据菜单类型显示菜单的icon
     * @return { string } icon 的相对路径
     */
    choseIconByType (id) {
      if (typeof id === 'string') {
        if (this.first_menu_type === id) {
          return this.icon_object['icon_act_' + id]
        } else {
          return this.icon_object['icon_' + id]
        }
      } else if (typeof id === 'object') {
        if (id.includes(this.first_menu_type[0])) {
          return this.icon_object['icon_act_' + id[0]]
        } else {
          return this.icon_object['icon_' + id[0]]
        }
      }
    },
    /**
     * 切换 marker 对应的弹出框
     * @param { object } marker map中的marker对象
     */
    togglePopup (marker) {
      // 如果已经打开  就不继续执行
      if (this.marker_id === marker.id) {
        // this.marker_id = "";
        return
      }

      this.marker_group.getVisibleParent(marker)
      this.map.setView(marker._latlng, this.mapMaxZoom - 1)

      marker.setZIndexOffset(this.zIndex)
      this.zIndex++
      this.point_id = marker.id

      if (marker.getPopup()) {
        setTimeout(() => {
          marker.openPopup()
          this.marker_id = marker.id
          this.btnClickHandle(marker)
        }, 300)
      } else {
        this.createPopup(marker)
        setTimeout(() => {
          marker.openPopup()
          this.marker_id = marker.id
          this.btnClickHandle(marker)
        }, 300)
      }
    },
    /**
     * 给marker对应的popup中的按钮绑定点击事件
     * @param { object } marker  map 中的 marker 对象
     */
    btnClickHandle (marker) {
      let nav = document.querySelector('.brief-nav')
      //导航的事件回调函数
      nav.addEventListener('click', () => {
        this.jumpPos()
      })

      //厕所等类型
      if (
        this.point_item.type_ !== '15' &&
        this.point_item.type_ !== '16' &&
        this.point_item.type_ !== '17' &&
        this.point_item.type_ !== 15 &&
        this.point_item.type_ !== 16 &&
        this.point_item.type_ !== 17
      ) {
        let detail = document.querySelector('.brief-detail')
        let audio = document.querySelector('.brief-audio')
        let vr = document.querySelector('.brief-vr')

        detail.addEventListener('click', () => {
          this.jumpToDetail()
        })
        if (this.point_item.zhAudioUrl) {
          audio.addEventListener('click', () => {
            this.audio_id = this.point_item.id
            this.playAudio()
          })
        }
        //点击进入VR全景
        vr.addEventListener('click',()=>{
          window.location.href= marker.vr_;
        })
      }
    },
    /**
     * 给 marker 创建对应的 popup 弹出框
     * @param { object } marker  map 中的 marker 对象
     */
    createPopup (marker) {
      let popup = L.responsivePopup({
        maxWidth: 'auto',
        keepInView: false,
        className: 'brief',
        hasTip: true, // 三角形箭头。。
        autoPan: false,
        offset: [15, 25]
      })
        .setLatLng(marker._latlng)
        .setContent(this.createPopupContent(marker))
      marker.bindPopup(popup)
    },
    /**
     * 当文本过长时，截取指定的长度并返回
     * @param { string } tx 要截取的文本字符串
     * @param { number } n  要截取的长度
     *
     * @return { string } 返回被截取后的文本
     */
    clampText (tx, n) {
      if (tx.length <= n) {
        return tx
      } else {
        return tx.substr(0, n) + '...'
      }
    },

    /**
     * 创建marker的popup中的内容
     * @param { object } marker  map 中的 marker 对象
     *
     * @return { string } 返回生成的字符串格式的dom
     */
    createPopupContent (marker) {
      let title_ = this.is_language == 'zh' ? marker.spots_name :(this.is_language == 'en' ? marker.en_title : marker.or_title);
      let content_ = this.is_language == 'zh' ? marker.spots_content :(this.is_language == 'en' ? marker.en_content : marker.or_content);
      let title = "<div class='brief-title'>" + title_ + '</div>';
      let detail_ = this.is_language == 'zh' ? '详情' : (this.is_language == 'en' ? 'Detail' : this.detail_);
      let play_   = this.is_language == 'zh' ? '解说' : (this.is_language == 'en' ? 'Play' : this.play_);
      let nav_   = this.is_language == 'zh' ? '导航' : (this.is_language == 'en' ? 'Nav' : this.nav_);
      let vr_ = this.is_language == 'zh' ? '全景' : (this.is_language == 'en' ? 'Vr' : ''); 
      let img =
        "<div class='brief-icon'><img class='icon' src=" +
        marker.logo_ +
        '></div>'
      
      let content, detail, audio, nav,vr

      if (
        marker.type_ === '15' ||
        marker.type_ === '16' ||
        marker.type_ === '17' ||
        marker.type_ === 15 ||
        marker.type_ === 16 ||
        marker.type_ === 17
      ) {
        content =
          "<div class='brief-content'>" +
          this.clampText(marker.address, 40) +
          '</div>'

        detail =
          "<div class='brief-detail brief-btn-item brief-btn-item-no'><i class='iconfont iconjianjie1'></i><span class='btnname'>"+ detail_+"</span></div>"
        audio =
          "<div class='brief-audio brief-btn-item brief-btn-item-no'><i class='iconfont icon1'></i><span class='btnname'>"+play_+"</span></div>"
        nav =
          "<div class='brief-nav brief-btn-item'><i class='iconfont icondaohang1'></i><span class='btnname'>"+nav_+"</span></span>"
      } else {
        content =
          "<div class='brief-content'>" +
          this.clampText(content_, 30) +
          '</div>'

        detail =
          "<div class='brief-detail brief-btn-item'><i class='iconfont iconjianjie1'></i><span  class='btnname'>"+detail_+"</span></div>"
        if (marker.zhAudioUrl) {
          audio =
            "<div class='brief-audio brief-btn-item'><i class='iconfont icon1'></i><span  class='btnname'>"+play_+"</span></div>"
        } else {
          audio =
            "<div class='brief-audio brief-btn-item brief-btn-item-no'><i class='iconfont icon1'></i><span  class='btnname'>"+play_+"</span></div>"
        }
        nav =
          "<div class='brief-nav brief-btn-item'><i class='iconfont icondaohang1'></i><span class='btnname'>"+nav_+"</span></div>"
      }
      //添加VR全景
      if(marker.vr != '')
      {
          vr =   "<div class='brief-vr brief-btn-item brief-btn-item'><i class='iconfont iconvr'></i><span  class='btnname'>"+vr_+"</span></div>"
        
      }
      //console.log(marker);
      let btns
      if (marker.zhAudioUrl) {
        if(marker.vr_)
        {
          btns = "<div class='brief-btn'>" + detail + audio +vr+ nav + '</div>'

        }
        else
        {
          btns = "<div class='brief-btn'>" + detail + audio + nav + '</div>'

        }
      } else {
        if(marker.vr_)
        {
        btns = "<div class='brief-btn'>" + detail + vr+nav + '</div>'

        }
        else
        {
        btns = "<div class='brief-btn'>" + detail + nav + '</div>'

        }
      }
      //console.log('btns:',btns)
      let info = "<div class='brief-info'>" + content + btns + '</div>'
      let body = "<div class='brief-body'>" + img + info + '</div>'

      let html = title + body
      return html
    },
    // 首次进入地图时定位
    firstLocation () {
      if (this.is_show_location) {
        // Indicator.open('定位中...')
        this.map.locate({
          setView: false,
          timeout: 3000
        })

        this.map.on('locationfound', e => {
          this.first_latlng = e.latlng
          this.sortByDistance(e.latlng)
          // console.log(this.point_list);
        })
        this.map.on('locationerror', e => {
          Toast('定位失败,请允许权限')
          this.map.stopLocate()
        })

        setTimeout(() => {
          this.firstLocation()
        }, 60000)
      }
    },
    /**
     * 根据定位获取的经纬度计算与点位之间的距离，并将点位列表中的点位按照距离进行排序显示
     * @param { array } latlng 定位获取到的当前所在的纬度/经度数据
     */
    sortByDistance (latlng) {
      this.point_list.sort((item1, item2) => {
        let latlng1 = L.latLng(item1.lat * 1, item1.lng * 1)

        let latlng2 = L.latLng(item2.lat * 1, item2.lng * 1)
        let distance1 = this.map.distance(latlng1, latlng)
        let distance2 = this.map.distance(latlng2, latlng)
        item1.distance =
          Math.round(distance1) > 1000
            ? (distance1 / 1000).toFixed(2) + '千米'
            : Math.round(distance1) + '米'
        item2.distance =
          Math.round(distance2) > 1000
            ? (distance2 / 1000).toFixed(2) + '千米'
            : Math.round(distance2) + '米'
        return distance1 - distance2
      })
    },
    // 初始化leaflet
    _initMap () {
      // 创建矩形限制区域，地图左下角和右上角坐标经纬度
      let bounds = new L.LatLngBounds(
        new L.LatLng(this.mapExtent[1], this.mapExtent[0]),
        new L.LatLng(this.mapExtent[3], this.mapExtent[2])
      )

      // 设置地图初始的中心点
      if (this.centerLng && !isNaN(this.centerLng * 1) && this.centerLat && !isNaN(this.centerLat * 1)) {
        this.center = [ this.centerLat * 1, this.centerLng * 1 ]
      } else {
        this.center = [ (this.mapExtent[1] + this.mapExtent[3]) / 2, (this.mapExtent[0] + this.mapExtent[2]) / 2 ]
      }

      // 后台既没有限制地图范围,也没有设置显示中心点
      if (!this.center[0] && !this.center[1]) {
        // 无法从后台获取中心点没有中心点
        // 根据点位来计算中心点
        this.center = this.getCenterByPoints()
      }

      // 创建map
      if(this.mapExtent[1])
      {
        this.map = L.map('map_', {
            maxBounds: bounds,
            zoomControl: false,
            // zoomAnimation: false, // 是否执行动画
            attributionControl: false
        }).fitBounds(bounds)
      }
      else
      {
          this.map = L.map('map_', {
           
            zoomControl: false,
            // zoomAnimation: false, // 是否执行动画
            attributionControl: false
        });
      }
      

      let options = {
        minZoom: this.mapMinZoom,
        maxZoom: this.mapMaxZoom,
        opacity: 1.0,
        tms: false
      }
      // 手绘地图的切片层
      L.tileLayer(
        '/static/map_tiles_' + this.id + '/{z}/{x}/{y}.png',
        options
      ).addTo(this.map)

      // 弹出层显示时添加marker标记
      this.map.on('popupopen', e => {
        e.popup.marker1 = L.circleMarker(e.popup.getLatLng(), {
          radius: 10,
          opacity: 0,
          fillColor: 'rgba(0, 0, 0, 0.5)',
          fillOpacity: 0.6
        }).addTo(this.map)
      })
      this.map.on('popupclose', e => {
        this.marker_id = ''
        this.map.removeLayer(e.popup.marker1)
      })

     
      this.map.setView(this.center, this.zoom)

      if (this.is_show_location) {
        // 为了防止 IOS 端使用定位导致图片和音频无法使用，给初始定位功能设置延时器
        this.firstLocationTimer = setTimeout(() => {
          this.firstLocation() // 初次进入地图时定位
        }, 10000)
      }
      this.initPoint(this.point_list)
    },
    /**
     * 当后台没有设置地图经纬度数据(限制范围或中心点)时,根据点位列表计算中心点
     */
    getCenterByPoints () {
      const len = this.point_list.length
      let sumLng = 0
      let sumLat = 0
      this.point_list.forEach((item) => {
        sumLng += +item.lng
        sumLat += +item.lat
      })
      return [sumLat / len, sumLng / len]
    },
    /**
     * 根据获取到的点位列表初始化点位
     * @param { array } ls 从服务器获取的点位信息
     * type_:init 地图初始，line 绘制路线
     */
    initPoint (ls) {
      
      for (let i = 0; i < ls.length; i++) {
        let iconUrl
        if (ls[i].zhAudioUrl && ls[i].zhAudioUrl !== '') {
          iconUrl = this.icon_object.iconMapAudio
        } else {
          iconUrl = this.icon_object['iconMap' + ls[i].type_]
        }

        L.icon({
          iconUrl: iconUrl,
          iconSize: [40, 40],
          iconAnchor: [20, 40],
          popupAnchor: [0, -20]
        })

        let html,spots_name,spots_content;//中文的标题
        spots_name = this.is_language == 'zh' ? ls[i].spots_name : (this.is_language == 'en' ? ls[i].en_title : ls[i].or_title);
        

        if (ls[i].is_show_title === 0) {
          html =
             "<img class='icon-value' src='" +
             iconUrl +
             "'/>"
        } else {
          if (ls[i].line_numbers > 0 && this.is_show_heat) {
            html =
             "<div class='icon-name icon-name-number'>" +
             "<p class='name'>" +
             spots_name +
             '</p>' +
             "<p class='isline'>当前：<span class='self'>" +
             ls[i].line_number +
             "</span> / <span class='total'>" +
             ls[i].line_numbers +
             '</span></p></div>' +
             "<img class='icon-value' src='" +
             iconUrl +
             "'/>"
          } else {
            
            html =
             "<p class='icon-name spots_name_divicon' >" +
             spots_name
             +'</p>' +
             "<img class='icon-value' src='" +
             iconUrl +
             "'/>"
          }
        }

        let divIcon = L.divIcon({
          html: html,
          iconSize: [40, 40],
          iconAnchor: [20, 40],
          popupAnchor: [0, -20]
        })
        // 创建marker
        let marker = null
        let mLatLng
        if (this.is_make_lnglat_by_xy > 0) {
          // 如果需要模拟经纬度
          mLatLng = this.mockLagLng({
            // 将x y 模拟成经纬度的公共方法
            x_: ls[i].x_,
            y_: ls[i].y_,
            lat: this.mapExtent[3],
            lng: this.mapExtent[2],
            width: this.map_data.width_,
            height: this.map_data.height_
          })
          marker = L.marker(L.latLng(mLatLng[0], mLatLng[1]), {
            icon: divIcon
          })
        } else {
          marker = L.marker(L.latLng(ls[i].lat * 1, ls[i].lng * 1), {
            icon: divIcon
          })
          // console.log(ls[i].lat);
          // console.log(ls[i].lng);
        }

        // 将部分点位属性绑定到marker上
        marker.id = ls[i].id // 点位的id
        marker.logo_ = ls[i].logo_ // 点位的logo
        marker.type_ = ls[i].type_ // 点位的类型，与菜单类型相对应
        marker.spots_name = ls[i].spots_name // 点位名称
        marker.spots_content = ls[i].spots_content // 点位的详情内容
        marker.en_title = ls[i].en_title;
        marker.en_content = ls[i].en_content;
        marker.or_title   = ls[i].or_title;
        marker.or_content = ls[i].or_content;
        marker.zhAudioUrl = ls[i].zhAudioUrl // 中文解说
        marker.enAudioUrl = ls[i].enAudioUrl // 英文解说
        marker.address = ls[i].address // 地址
        marker.en_address = ls[i].en_address;
        marker.or_address = ls[i].or_address;
        marker.line_numbers = ls[i].line_numbers // 点位的热力值
        marker.is_show_title = ls[i].is_show_title
        marker.vr_ = ls[i].vr_;//vr 连接
        if (this.is_show_heat && (this.is_make_lnglat_by_xy === 0 || this.is_make_lnglat_by_xy === '0')) {
          if (ls[i].line_numbers > 0) {
            this.heatList.push([
              ls[i].lat * 1,
              ls[i].lng * 1,
              ls[i].line_numbers / this.max_line_numbers
            ])
          }
        } else if (this.is_show_heat && this.is_make_lnglat_by_xy > 0) {
          if (ls[i].line_numbers > 0) {
            this.heatList.push([
              mLatLng[0],
              mLatLng[1],
              ls[i].line_numbers / this.max_line_numbers
            ])
          }
        }
        //console.log(marker);

        marker.on('click', () => {
          
          this.togglePopup(marker);
        })
        
        this.marker_list.push(marker);
      }
      

      this.loadCluster();

      
    },

    
    /**
     * 点位聚合
     * @param { array } ls 获取的点位数据
     */
    initCluster (ls) {
      let markers = L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        removeOutsideVisibleBounds: false,
        disableClusteringAtZoom: this.mapMaxZoom - 1,
        maxClusterRadius: 40,
        iconCreateFunction: cluster => {
          let _markers = cluster.getAllChildMarkers()
          let text = this.is_language == 'zh' ? '等' : '';

          let markerName;
          if(this.is_language == 'zh')
          {
            markerName =
                        _markers[0].spots_name.length > 3
                          ? _markers[0].spots_name.substr(0, 3) + '...'+text
                          : _markers[0].spots_name // ? this.clampText(_markers[0].spots_name, 3) + "等"
          }
          else if(this.is_language == 'en')
          {
            markerName =
                        _markers[0].en_title.length > 10
                          ? _markers[0].en_title.substr(0, 10) + '...'+text
                          : _markers[0].en_title // ? this.clampText(_markers[0].spots_name, 3) + "等"
          }
          else
          {
            markerName =
                        _markers[0].or_title.length > 10
                          ? _markers[0].or_title.substr(0, 10) + '...'+text
                          : _markers[0].or_title // ? this.clampText(_markers[0].spots_name, 3) + "等"
          }
          
          let html = ''
          if (_markers[0].is_show_title === 0) {
            html = "<p class='icon-number'>" +
              cluster.getChildCount() +
              '</p>' +
              "<img class='icon-value' src='" +
              this.icon_object['iconMapCluster'] +
              "'/>"
          } else {
            html = "<p class='icon-name'>" +
              markerName +
              '</p>' +
              "<p class='icon-number'>" +
              cluster.getChildCount() +
              '</p>' +
              "<img class='icon-value' src='" +
              this.icon_object['iconMapCluster'] +
              "'/>"
          }
          return L.divIcon({
            html,
            iconSize: [40, 40],
            iconAnchor: [20, 40]
          })
        }
      })
      markers.addLayer(L.layerGroup(ls));
      return markers
    },
    // 跳转详情，需要把选择的语言类型发送过去
    jumpToDetail () {
  
      if (this.point_item.type_ === '17' || this.point_item.type_ === '15') {

      } else {
        this.$router.push({
          path: '/footprints/PrimaryScenic',
          query: {
            id_: this.id,
            sid: this.point_item.id,
            language:this.is_language == 'zh' ? 1 : 2,//只有中文和英文
          }
        })
      }
    },
    // 停止播放
    closeAudio () {
      this.audio_dom.pause()
      this.audio_play = false
      this.checkIsPlay()
    },
    // 修改播放标记
    checkIsPlay () {
      this.point_list.forEach(item => {
        if (item.is_play !== 0) {
          item.is_play = 0
        }
      })
    },
    // 播放背景音乐
    playBgMusic () {
      if (this.bgmusic_play) {
        this.bgmusic_play = false
        this.audio_dom.pause()
      } else {
        // console.log(this.audio_dom);
        this.checkIsPlay() // 关闭点位播放音频
        this.bgmusic_play = true
        this.audio_play = false // 背景音乐不需要播放界面
        this.audio_dom.src = this.map_data.bgAudioUrl
        this.audio_dom.play()
      }
    },
    // 音频
    playAudio () {
     
      if (this.point_item.type_ === '15' || this.point_item.type_ === '17') {
        console.log('暂无音频')
        return
      }
      if (this.is_weixin || this.price * 1 == 0) {
        if (this.price === 0 || (this.price > 0 && this.is_pay === 1)) {
          if (this.point_item.zhAudioUrl) {
            if (this.point_item.is_play !== 0) {
              this.point_item.is_play = 0
              this.audio_dom.pause()
              this.audio_play = false
            } else {
              this.checkIsPlay()
              this.bgmusic_play = false // 关闭背景音乐开关
              this.point_item.is_play = 1
              this.audio_play = true
              var audio_name = '';
              var audioSrc = '';
              //console.log(this.point_item);
              if(this.is_language == 'zh')
              {
                audio_name = this.point_item.spots_name;
                audioSrc   = this.point_item.zhAudioUrl;
              }
              else if(this.is_language == 'en')
              {
                audio_name = this.point_item.en_title;
                audioSrc   = this.point_item.enAudioUrl;
              }
              else
              {
                audio_name = this.point_item.or_title;
                audioSrc  = this.point_item.orAudioUrl;
              }
              this.audio_name = audio_name;
              this.audio_dom.src = audioSrc;
              this.audio_dom.play()
            }
          } else {
            Toast('暂无音频')
          }
        } else {
          // 还未支付
          if (this.open_id) {
            // 进行支付
            var openUrl =
              'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
              this.id +
              '&backUrl=' +
              this.backUrl +
              '&open_id=' +
              this.open_id +
              '&token=' +
              this.token +
              '&park_id=' +
              this.park_id +
              '&version=' +
              this.version +
              '&user_id='
            window.location.href = openUrl
          } else {
            // 获取微信授权
            this.authorize()
          }
        }
      } else {
        if (this.map_data.wxacodeUrl) {
          this.showQrCode = true
        } else {
          Toast('请在微信中打开')
        }
      }
    },
    // 导航
    jumpPos () {
      if (this.is_weixin) {
        if (this.price > 0) {
          // 收费
          if (this.is_pay) {
            // 已经支付了
            this.wx_nav(
              window.location.href,
              this.point_item.lng,
              this.point_item.lat,
              this.point_item.spots_name,
              this.point_item.address
            )
          } else {
            // 还未支付
            if (this.open_id) {
              // 进行支付
              var openUrl =
                'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
                this.id +
                '&backUrl=' +
                this.backUrl +
                '&open_id=' +
                this.open_id +
                '&token=' +
                this.token +
                '&park_id=' +
                this.park_id +
                '&version=' +
                this.version +
                '&user_id='
              window.location.href = openUrl
            } else {
              // 进行微信授权
              this.authorize()
            }
          }
        } else {
          // 免费
          this.wx_nav(
            window.location.href,
            this.point_item.lng,
            this.point_item.lat,
            this.point_item.spots_name,
            this.point_item.address
          )
        }
      } else {
        if (this.map_data.wxacodeUrl) {
          this.showQrCode = true
        } else {
          Toast('请在微信中打开,使用导航')
        }
      }
    },
    // 获取服务器数据
    getData () {
      if (
        this.id &&
        this.park_id &&
        this.id_type_ &&
        this.token 
      ) {
        this.$ajax(
          'post',
          'SpotsRace/get_map_data_by_label',
          {
            park_id: this.park_id,
            id: this.label_id,
            id_type_: this.id_type_,
            page: 1,
            where: 'map',
            token: this.token,
            is_type: 2,
            open_id: this.open_id,
            version: this.version,
            mtype_:this.first_menu_type,//在龙岩程序中需要告诉后台，前端指定显示类型
          },
          res => {
            if (res.data.id === 0) {
              //   console.log(res.data.data);
              this.bottom_menu_list = res.data.data.bottom_menu_list || ''

              this.map_data = res.data.data.map_data
              this.point_list = res.data.data.point_list
              this.title_list = res.data.data.title_list
              this.tel_ = res.data.data.tel_
              this.is_pay = res.data.data.is_pay || 0
              this.price = res.data.data.price || 0
              this.is_draw_rect = res.data.data.is_draw_rect || 0
              this.is_admin = res.data.data.is_admin || 0
              this.is_show_location = res.data.data.is_show_location || 0
              this.is_show_area = res.data.data.is_show_area || 0
              this.zoom = this.map_data.init_zoom * 1 || 16
              this.mapMinZoom = this.map_data.min_zoom * 1 || 16
              this.mapMaxZoom = this.map_data.max_zoom * 1 || 20
              this.logo_list = res.data.data.logo_list || '' // 分区列表
              this.is_make_lnglat_by_xy =
                res.data.data.is_make_lnglat_by_xy || '' // 是否需要模拟经纬度
              // 获取地图初始中心坐标点
              this.centerLng = res.data.data.map_data.centerLng || ''
              this.centerLat = res.data.data.map_data.centerLat || ''

              this.max_line_numbers = res.data.data.max_line_numbers || '' // 最大排队人数
              this.is_show_heat = res.data.data.is_show_heat || 0 // 是否显示热力图
              this.is_show_line = res.data.data.is_show_line > 0 ? res.data.data.is_show_line : 0 // 是否显示路线

              this.lineList = res.data.data.line_list || [] // 路径列表
              this.vr = res.data.data.vr || '';//是否显示VR
              
              this.mapExtent = [
                this.map_data.left_lng * 1,
                this.map_data.left_lat * 1,
                this.map_data.right_lng * 1,
                this.map_data.right_lat * 1
              ]

              this.language = res.data.data.language;//语言切换
              this.languageCount = res.data.data.have_language || 0;//拥有几种语言
              if(res.data.data.language)
              {
                res.data.data.language.forEach(it=>{
                  
                  
                  if(this.$route.query.language == it.id)
                  {
                    this.is_language = it.id;
                  }
                  else
                  {
                    if(1 == it.is_selected)
                    {
                      this.is_language = it.id;
                    }
                  }
                  
                });
              }
              if(this.is_language == 'zh')
              {
                document.title = this.map_data.label_name

              }
              else if(this.is_language == 'en')
              {
              document.title = this.map_data.en_title

              }
              else
              {
              document.title = this.map_data.or_title;

              }
              this.brief_ = res.data.data.brief_;
              this.language_ = res.data.data.language_;
              this.path_ = res.data.data.path_;
              this.music_ = res.data.data.music_;
              this.play_  = res.data.data.play_;
              this.detail_  = res.data.data.detail_;
              this.nav_  = res.data.data.nav_;
              
              //天气信息
              this.weather = res.data.data.weather || '';

              this.redPacketList =
                res.data.data.red_packet.red_packet_rain_image_url_list
              this.have_red_packet_rain =
                res.data.data.red_packet.have_red_packet_rain || 0
              this.red_packet_type_ =
                res.data.data.red_packet.red_packet_type_ || ''
              this.red_packet_image_url =
                res.data.data.red_packet.red_packet_image_url || ''

              //地址语言
              this.language_address_ = res.data.data.language_address_;
              this.language_open_time_ = res.data.data.language_open_time_;

              // 微信分享
              let wxTitle = res.data.data.map_data.label_name
              let wxContent = res.data.data.map_data.shareContent
              let wxImg =
                  res.data.data.map_data.logo_ || res.data.data.map_data.map_

              let sUrl = window.location.href

              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wxTitle,
                  content: wxContent,
                  img: wxImg
                })
              }

              if (this.bottom_menu_list && this.bottom_menu_list.length) {
                this.bottom_menu_list.forEach(item => {
                  // 如果底部菜单 id 为 number，转换成 string
                  if (typeof item.id === 'number') {
                    item.id = '' + item.id
                  }
                })
                this.first_menu_type =
                  typeof this.bottom_menu_list[0].id === 'string' ? '-1' : [-1]
              } else {
                // 如果没有底部目录列表，则为空
                this.first_menu_type = ''
              }

              this._initMap() // 初始化地图

              // 获取天气信息
              this.getWeatherByCity(this.map_data.ecity_).then(wt => {
                this.weatherInfo = wt
              }, er => {
                console.log(er)
              })

              if (this.area_index > -1) {
                let x_ = this.logo_list[this.area_index * 1].x_
                let y_ = this.logo_list[this.area_index * 1].y_
                this.areaLocation(x_, y_)
              }
            }
          }
        )
      } else {
        console.log('参数错误')
      }
    },
    // 加载点位聚合方法
    loadCluster () {
      // if (L.markerClusterGroup) {
      this.marker_group = this.initCluster(this.marker_list)
      setTimeout(() => {
        // 地图初始设置中心和缩放时的动画会导致聚合错乱。等待动画完成后再添加聚合点位
        this.map.addLayer(this.marker_group)
        if (this.bottom_menu_list) {
          this.bottom_menu_list.forEach((item, i) => {
            if (item.is_selected && item.is_selected === 1) {
              this.first_menu_type = item.id
              this.menuClickHandle(this.first_menu_type, i)
            }
          })
        }
      }, 500)
    },

    // 授权方法
    authorize () {
      var openUrl =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&id_=0&state=' +
        this.backUrl
      window.location.href = openUrl
    },
    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id === 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            if (info && info !== '') {
              if (info.url && info.url !== '') {
                let urlArr = info.url.split('share_open_id')

                url = urlArr[0] + '&share_open_id=' + this.open_id
              } else {
                url = url + '&share_open_id=' + this.open_id
              }
            } else {
              url = url + '&share_open_id=' + this.open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id === 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },

    /**
     * 进入VR
     */
    openVr()
    {
      if(this.vr)
      {
        window.location.href=this.vr;
      }
      else
      {
        Toast.msg('无VR内容');
      }
    }



  }
}
</script>
<style scoped>
.container {
  position: relative;
  width: 100vw;
  height: 100vh;
}

/* 地图页 */
.map-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#map_ {
  width: 100%;
  height: calc(100% - 2.4rem);
}

/* 底部导航 */
.bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 2.4rem;
  padding-top: 2px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  z-index: 403;
  background-color: #fff;
  transition: ease 0.3s;
  box-shadow: 0 0 1px #e9e9e9;
}
.bottom-menu-active {
  height: 19rem;
  transition: ease 0.3s;
}
.footer-menu {
  height: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #eee; */
}
.footer-menu > .wrapper {
  width: calc(100% - 2.4rem);
  height: 2.4rem;
  overflow: auto;
  background: linear-gradient(to right, #fff 0.4rem, transparent) 0 0 / 1.2rem
      100%,
    radial-gradient(at left, #00000028, transparent 70%) 0 0 / 0.4rem 100%,
    linear-gradient(to left, #fff 0.4rem, transparent) right / 1.2rem 100%,
    radial-gradient(at right, #00000028, transparent 70%) right / 0.4rem 100%;
  background-repeat: no-repeat;
  background-attachment: local, scroll, local, scroll;
}
.footer-menu > .wrapper::-webkit-scrollbar {
  display: none;
}
.footer-menu .list {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-menu  .item {
  width: 50%;
  height: 100%;
  color: #888;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: .7rem;
  cursor: pointer;
}
.footer-menu .item-active {
  color: #333;
}
.footer-menu .item .icon {
  height: 0.88rem;
}
.footer-menu > .btn {
  width: 2.4rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  cursor: pointer;
}
.footer-menu > .btn .iconfont {
  font-size: 1rem;
}
.bottom-menu-active .btn {
  transform: rotate(180deg);
}
/* 搜索 */
.footer-search {
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* padding: 0.5rem 0; */
  margin-bottom: 0.4rem;
}
.footer-search > .wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background: #fff;
}
.footer-search > .wrapper > .search-input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 40px 0 12px;
}
.footer-search > .wrapper > .search-input input {
  width: 100%;
  height: 100%;
  font-size: 12px;
}
.footer-search > .wrapper > .search-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
}
.footer-search > .wrapper > .search-icon .iconfont {
  font-size: 1rem;
  color: #999;
}
/* 目录列表 */
.footer-nav {
  width: 100%;
  height: calc(100% - 2.4rem);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
}
.footer-nav .nav-wrapper {
  width: 100%;
  height: 13.2rem;
  overflow: auto;
}
.footer-nav .footer-nav-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.footer-nav .footer-nav-list::-webkit-scrollbar {
  width: 4px;
}
.footer-nav .footer-nav-list::-webkit-scrollbar-thumb {
  /*滚动条中的滚动块*/
  border-radius: 2px;

  background: #999;
}
.footer-nav .footer-nav-list .nav-item {
  position: relative;
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.footer-nav .footer-nav-list .nav-item-active {
  background-color: rgb(251, 253, 227);
}
.footer-nav .footer-nav-list .nav-item-active i {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  color: rgba(0, 0, 0, 0.3);
}

/* .footer-nav .footer-nav-list .nav-item img {
  width: 1.2rem;
} */
.iconfont::before {
  vertical-align: middle;
}
.nav-item .distance {
  margin-left: 6px;
  font-size: 0.66rem;
  color: #aeaeae;
}
/* 播放界面 */
.audio-fade-enter,
.audio-fade-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
.audio-fade-enter-to,
.audio-fade-leave {
  opacity: 1;
  transform: translateY(0);
}
.audio-fade-enter-active,
.audio-fade-leave-active {
  transition: 0.3s;
}
.audio-play {
  position: fixed;
  left: 20%;
  bottom: 3rem;
  width: 60%;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 402;
}
.audio-play .audio-play-icon {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  animation: audio 4s linear infinite;
}
@keyframes audio {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.audio-play .audio-play-icon img {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
}
.audio-play .audio-play-name {
  font-size: 0.8rem;
  color: #fff;
}
.audio-play .audio-play-close {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.audio-play .audio-play-close i {
  font-size: 1rem;
  color: #fff;
}
/* 顶部分区 */
.area-fade-enter,
.area-fade-leave-to {
  transform: translateX(-100%);
}
.area-fade-enter-to,
.area-fade-leave {
  transform: translateY(0);
}
.area-fade-enter-active,
.area-fade-leave-active {
  transition: ease 0.3s;
}
 .pixi-logo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 401;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
}
 .pixi-logo .logo-wrapper {
  width: 100%;
  height: 4rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 2px 20px rgb(95, 95, 95);
  display: flex;
}
 .pixi-logo .logo-wrapper .logo-btn {
  width: 1.6rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.623); */
}
 .pixi-logo .logo-wrapper .logo-btn i {
  transform: rotate(180deg);
  color: #fff;
  font-size: 1.6rem;
}
 .pixi-logo .logo-menu {
  /* width: 100%; */
  flex: auto;
  height: 4rem;
  box-sizing: border-box;
  display: inline-flex;
  overflow-x: auto;
  overflow-y: hidden;
}
 .pixi-logo .logo-menu .logo-menu-list {
  width: 100%;
  text-align: center;
}
 .pixi-logo .logo-menu .logo-menu-list img {
  height: 4rem;
  /* height: 2rem; */
}
/* 顶部导航 */
.top-fade-enter,
.top-fade-leave-to {
  transform: translateX(100%);
}
.top-fade-enter-to,
.top-fade-leave {
  transform: translateX(0);
}
.top-fade-enter-active,
.top-fade-leave-active {
  transition: ease 0.3s;
}
 .function-top {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 401;
  padding: 0.5rem;
}
 .function-top .top-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 2px #aaa;
  cursor: pointer;
}
 .function-top .top-menu-li {
  display: flex;
  flex-direction: column;
  /* padding: 4px 6px; */
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2.2rem;
}
 .function-top .top-menu-li p {
  color: #00000066;
  font-size: 0.6rem;
      overflow: hidden;
    white-space: nowrap;
    width: 1.2rem;
    text-align:center;
}
 .function-top .top-brief i {
  color: rgb(250, 88, 59);
  font-size: 0.9rem;
}
 .function-top .top-zone i {
  color: rgb(250, 119, 59);
  font-size: 0.9rem;
}

 .function-top .top-music,
 .function-top .top-heat {
  margin-top: 0.4rem;
}
 .function-top .top-music i {
  font-size: 1rem;
}

 .function-top .top-music i.iconswitch_open {
  color: rgb(250, 119, 59);
}
 .function-top .top-music i.iconswitch_close {
  color: #999;
}

.top-data {
  margin-top: .4rem;
}

.top-data i {
  font-size: 1rem;
  color: #00000066;
  transition: 0.3s;
}
.top-data-active i {
  color: #fe5d08;
  transition: 0.3s;
}
.top-data span {
  font-size: 0.6rem;
  color: #00000066;
}
.top-heat i {
  font-size: 0.88rem;
  color: #aaaaaa;
  transition: color 0.3s;
}
.top-heat.-active i {
  color: rgb(235, 63, 20);
  transition: color 0.3s;
}

.top-line {
  margin-top: 6px;
}

.top-line > .iconfont {
  font-size: .88rem;
  color: rgb(235, 63, 20);
}

/* 语言选择 */
.language-enter,
.language-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.language-enter-to,
.language-leave {
  transform: translateY(0);
  opacity: 1;
}
.language-enter-active,
.language-leave-active {
  transition: 0.3s;
}

.lan-div{
      position: fixed;
    width: 85%;
    
    left: 0.5rem;
    top: 3rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    z-index: 402;
            background-color: white;
    box-shadow: 1px 1px 2px #aaa;
    border-radius: 0.3rem;
}
.lan-div .lan-it{
  
  display: inline-block;
 
  margin-right: 1rem;
   
    /* margin-left: 1rem; */
    border-radius: 0.3rem;
}
.lan-div .lan-it p{
  display: inline-block;
  padding: 5px 10px 5px 10px;
  font-size: 0.8rem;
  max-width: 104px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -icab-text-overflow: ellipsis;
    -khtml-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
  
}
.lan-selected {
  color: white;
  background-color: rgb(67, 209, 112);
}
.lan-active{
  background-color: #efecec;
 
 color: #666;
}
.lan-die{
  background-color: #efecec;
  color: white;
}
/* 景区简介 */
.bounce-enter,
.bounce-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.bounce-enter-to,
.bounce-leave {
  transform: translateY(0);
  opacity: 1;
}
.bounce-enter-active,
.bounce-leave-active {
  transition: 0.3s;
}
 .spot-brief {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 2.4rem;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  z-index: 402;
}
 .spot-brief > .wrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 0 2px #aaa;
  padding-bottom: 1rem;
}
 .spot-brief > .wrapper .brief-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.4rem;
  height: 1.4rem;
  text-align: center;
  line-height: 1.4rem;
}
 .spot-brief > .wrapper .brief-close i {
  color: #333;
  font-size: 1rem;
}

 .spot-brief .spot-brief-icon {
  position: absolute;
  top: -1rem;
  left: 0.5rem;
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  border: 2px solid rgb(255, 234, 175);
}
 .spot-brief .spot-brief-icon .icon {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
 .spot-brief-header {
  margin-left: 4.2rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
}

 .spot-brief-header h2 {
  font-size: 0.88rem;
  padding-right: 2rem;
  color: #333;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
 .spot-brief-info {
  box-sizing: border-box;
  padding: 10px 0.6rem;
}
 .spot-brief-info .spot-brief-item {
  font-size: 12px;
  color: #666;
  display: flex;
  padding-bottom: 10px;
}
 .spot-brief-info .spot-brief-item .item-name {
  white-space: nowrap;
}
 .spot-brief-info .spot-brief-item .item-value {
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
 .spot-brief-info .spot-brief-time {
  border-bottom: 1px solid #eee;
}
 .spot-brief-info p i {
  font-size: 14px;
  color: #666;
  margin-right: 2px;
}

 .spot-brief-content {
  width: 100%;
  padding: 0 0.6rem;
  box-sizing: border-box;
  color: #333;
  font-size: 0.7rem;
  line-height: 1.5;
  text-align: justify;
}
 .spot-brief-content article {
  max-height: 16rem;
  overflow-y: auto;
}
 .spot-brief-content article >>> span {
  text-align: justify;
  color: #333;
  font-size: 0.7rem !important;
}

/* 左下功能按钮 */
.function-btn {
  position: fixed;
  bottom: 3rem;
  left: 0.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 401;
}

.function-btn > .item{
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6px;
  align-items: center;
  background-color: #fff;
  box-shadow: 1px 1px 2px #aaa;
  border-radius: 4px;
}

/* 定位 */
.location {
  transition: ease 0.3s;
}
.location .iconfont {
  font-size: 1rem;
  color: #999;
}

.function-btn > .locate-active {
  background-color: rgb(250, 88, 59);
  transition: ease 0.3s;
}
.function-btn > .locate-active .iconfont {
  color: rgb(250, 250, 250);
}

/* 弹出框的样式 */

#map_ >>> .brief {
  width: 16rem;
  height: 8.2rem;
  box-sizing: border-box;
}
/* 简介内容 */
#map_ >>> .brief .leaflet-popup-close-button {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  padding: 0;
  top: 0;
  right: 0rem;
  font-size: 1rem;
  cursor: pointer;
}
#map_ >>> .brief .brief-title {
  width: 100%;
  padding-right: 2rem;
  padding-left: 0.6rem;
  box-sizing: border-box;
  height: 2rem;
  line-height: 2rem;
  color: #333;
  font-size: 0.8rem;
  font-weight: 600;
  border-bottom: 1px solid rgb(243, 243, 243);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#map_ >>> .brief .brief-body {
  width: 100%;
  padding: 0.6rem;
  box-sizing: border-box;
  display: flex;
}
#map_ >>> .brief .brief-icon {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
}
#map_ >>> .brief .brief-icon .icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#map_ >>> .brief .brief-info {
  margin-left: 0.5rem;
  width: calc(100% - 5.5rem);
  display: flex;
  flex-direction: column;
}
#map_ >>> .brief .brief-content {
  width: 100%;
  min-height: 2.8rem;
  color: #666;
  font-size: 0.7rem;
  text-align: justify;
   text-indent: 2em; 
}
#map_ >>> .brief .brief-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.35rem;
}
#map_ >>> .brief .brief-btn-item {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#map_ >>> .brief .brief-btn-item .iconfont {
  color: rgb(243, 98, 40);
  font-size: 0.8rem;
}
#map_ >>> .brief .brief-btn-item-no .iconfont {
  color: #999;
  font-size: 0.8rem;
}
#map_ >>> .brief .brief-btn-item .btnname {
  color: #333;
  font-size: 0.66rem;
}
#map_ >>> .brief .brief-btn-item-no .btnname {
  color: #999;
}
/*  */
#map_ >>> .brief .leaflet-popup-content-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  padding: 0;
}
#map_ >>> .brief .leaflet-popup-content {
  margin: 0;
  width: 100%;
  height: 100%;
}
/* North */

/* South */
#map_ >>> .leaflet-resp-popup-south .leaflet-popup-tip-container {
  top: 0;
  margin-top: -20px;
}

#map_ >>> .leaflet-resp-popup-south .leaflet-popup-tip {
  margin: 11px auto 0;
  box-shadow: none;
}

/* West */
#map_ >>> .leaflet-resp-popup-west .leaflet-popup-tip-container {
  right: 0;
  margin-right: -20px;
  left: 100%;
  margin-left: -2px;
  top: 50%;
  margin-top: -11px;
}

#map_ >>> .leaflet-resp-popup-west .leaflet-popup-tip {
  margin: 1px -8px 0 -8px;
  box-shadow: none;
}

/* East */
#map_ >>> .leaflet-resp-popup-east .leaflet-popup-tip-container {
  width: 20px;
  left: 2px;
  top: 50%;
  margin-top: -11px;
}

#map_ >>> .leaflet-resp-popup-east .leaflet-popup-tip {
  margin: 0 11px;
  box-shadow: none;
}

/* South-East */
#map_ >>> .leaflet-resp-popup-south-east .leaflet-popup-tip-container {
  left: 0;
  margin-left: 0;
  top: 0;
  margin-top: -20px;
}

#map_ >>> .leaflet-resp-popup-south-east .leaflet-popup-tip {
  margin: 11px 11px 11px -8px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-south-east .leaflet-popup-content-wrapper {
  border-radius: 0 20px 20px 20px;
}

/* East-South */
#map_ >>> .leaflet-resp-popup-east-south .leaflet-popup-tip-container {
  width: 20px;
  left: 0;
  top: 0;
}

#map_ >>> .leaflet-resp-popup-east-south .leaflet-popup-tip {
  margin: -8px 11px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-east-south .leaflet-popup-content-wrapper {
  border-radius: 0 20px 20px 20px;
}

/* North-East */
#map_ >>> .leaflet-resp-popup-north-east .leaflet-popup-tip-container {
  left: 0;
  margin-left: 0;
  margin-top: -1px;
}

#map_ >>> .leaflet-resp-popup-north-east .leaflet-popup-tip {
  margin: -8px 11px 11px -8px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-north-east .leaflet-popup-content-wrapper {
  border-radius: 20px 20px 20px 0;
}

/* East-North */
#map_ >>> .leaflet-resp-popup-east-north .leaflet-popup-tip-container {
  left: 0;
  margin-left: -20px;
  margin-top: -20px;
}

#map_ >>> .leaflet-resp-popup-east-north .leaflet-popup-tip {
  margin: 11px 11px 11px 11px;
  box-shadow: none;
}

/* North-West */
#map_ >>> .leaflet-resp-popup-north-west .leaflet-popup-tip-container {
  width: 20px;
  margin-right: 0;
  margin-top: -1px;
  left: 100%;
  right: 0;
}

#map_ >>> .leaflet-resp-popup-north-west .leaflet-popup-tip {
  margin: -8px 11px 11px 11px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-north-west .leaflet-popup-content-wrapper {
  border-radius: 20px 20px 0 20px;
}

/* West-North */
#map_ >>> .leaflet-resp-popup-west-north .leaflet-popup-tip-container {
  margin-left: 0;
  margin-top: -20px;
  left: 100%;
  right: 0;
}

#map_ >>> .leaflet-resp-popup-west-north .leaflet-popup-tip {
  margin: 11px 11px 0px -8px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-west-north .leaflet-popup-content-wrapper {
  border-radius: 20px 20px 0 20px;
}

/* South-West */
#map_ >>> .leaflet-resp-popup-south-west .leaflet-popup-tip-container {
  width: 20px;
  left: 100%;
  top: 0;
  margin-top: -20px;
}

#map_ >>> .leaflet-resp-popup-south-west .leaflet-popup-tip {
  margin: 11px 11px 11px 11px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-south-west .leaflet-popup-content-wrapper {
  border-radius: 20px 0 20px 20px;
}

/* West-South */
#map_ >>> .leaflet-resp-popup-west-south .leaflet-popup-tip-container {
  margin-left: 0;
  margin-right: -20px;
  left: 100%;
  right: 0;
  top: 0;
}

#map_ >>> .leaflet-resp-popup-west-south .leaflet-popup-tip {
  margin: -8px;
  box-shadow: none;
}

#map_ >>> .leaflet-resp-popup-west-south .leaflet-popup-content-wrapper {
  border-radius: 20px 0 20px 20px;
}
/* 点位名称和icon */
#map_ >>> .leaflet-marker-pane .leaflet-marker-icon {
  position: relative;
  border: none;
  background-color: transparent;
}
#map_ >>> .icon-name {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -20px;
  font-size: 13px;
  left: 50%;
  border: 1px solid #333;
  border-radius: 4px;
  transform: translateX(-50%);
  padding: 0 6px;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.6);
  color: #333;
}
#map_ >>> .icon-name-number {
  top: -40px;
}
#map_ >>> .icon-name .self {
  color: #ec6002;
  font-weight: bold;
}
#map_ >>> .icon-name .total {
  color: #e33;
  font-weight: bold;
}
#map_ >>> .leaflet-marker-pane .icon-number {
  position: absolute;
  white-space: nowrap;
  top: 43.8%;
  font-size: 13px;
  font-weight: 600;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f6ad15;
}
#map_ >>> .leaflet-marker-pane .icon-value {
  width: 40px;
  height: 40px;
}

.map-wrapper >>> .red_packet_container .red_packet {
  z-index: 403;
}
.map-wrapper >>> .red_packet_container .red_packet_btn {
  z-index: 403;
}
.map-wrapper >>> .red_packet_container .red_packet_bounced {
  z-index: 403;
}
.map-wrapper >>> .red_packet_container .red_packet_banner {
  z-index: 403;
}

/* ===========================
    路线
=============================== */

.path {
  width: 2rem;
  height: 2rem;
  position: fixed;
  bottom: 7.4rem;
  left: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 1px 1px 2px #aaa;
  border-radius: 4px;
  z-index: 401;
}

.path button {
  outline: none;
  background-color: transparent;
  border: none;
  font-size: 0.68rem;
}

.map-wrapper >>> .issue_content {
  z-index: 401;
}

/* 微信二维码 */
.qrcode-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* 天气信息 */
.weather-box {
  width: calc(100vw - 3.5rem);
  position: fixed;
  top: 3rem;
  left: .5rem;
  z-index: 401;
}
</style>
