<template>
  <div v-title :data-title="tit">
    <!-- 头部 -->
    <mt-header fixed :title="tit">
      <a slot="left">
        <mt-button v-if="type=='parkNote'" icon="back" @click="$router.go(-1)"></mt-button>
        <mt-button v-if="type!='parkNote'"></mt-button>
      </a>
      <router-link :to="{name:'NotePost',query:{pid:parkId,pname:parkName}}" slot="right">
        <mt-button class="note-edit-btn"></mt-button>
      </router-link>
    </mt-header>
    <div class="page-content" :class="{botton0:type=='parkNote'}">
      <feed-list ref="child" @getData="getData" @getparkdata="getparkdata"></feed-list>
    </div>
    <home-footer v-if="type!='parkNote'"></home-footer>
  </div>
</template>
<script>
import FeedList from "@/components/FeedList";
import HomeFooter from "../../components/HomeFooter";
export default {
  components: {
    FeedList,
    HomeFooter
  },
  data() {
    return {
      parkId: this.$route.query.pid,
      parkName: this.$route.query.pname,
      type: this.$route.query.type,
      userId: "",
      page: 1,
      items: [],
      itemLen: -1,
      ago_title: "",
      tit: "游记列表"
    };
  },
  mounted() {
    this.getLoginInfo();
  },
  methods: {
    getLoginInfo() {
      //检测登录
      var user_info = this.check_login();
      if (user_info) {
        this.userId = user_info.user_id;
      }
      this.getData(1);
    },
    getData(...page) {
      if (page[1]) {
        this.parkId = page[1];
      }
      var note_url = "TravelNotes/clickFind_new";
      var data = {
        page: page[0],
        user_id: this.userId,
        park_id: this.parkId
      };
      if (this.type == "parkNote") {
        this.tit = "公园游记列表";
      }
      this.$ajax("post", note_url, data, res => {
        if (page[0] == 1) {
          this.items = [];
          this.itemLen = res.data.data.length;
        }
        this.items = this.items.concat(res.data.data);

        this.$refs.child.parentMsg({
          items: this.items,
          itemLen: this.itemLen,
          userId: this.userId,
          parklink: false
        });
      });
    },
    //            点击列表公园名称，页面显示这一个公园的列表 只是改变显示的数据 未进行跳转
    getparkdata(...park_id) {
      if (park_id[0] != this.parkId) {
        this.parkId = park_id[0];
        this.items = [];
        this.page = 1;
        this.type = "parkFoot";
        this.ago_title = this.tit;
        this.tit = "公园足迹列表";
        this.getData(1);
      }
    }
  }
};
</script>
<style scoped>
.page-content.botton0 {
  padding-bottom: 0;
}

.note-edit-btn {
  background: url(../../assets/img/note_edit_btn.png) no-repeat center;
  background-size: 60%;
  width: 40px;
  height: 40px;
  margin-right: -10px;
}
</style>