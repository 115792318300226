<template>
  <div class="PrimaryScenic width-768" v-title :data-title="info.title" v-show="info.id" :style="setPadByUserData()">
    <div class="page-content TrackParticulars">
      <div class="aplayer" id="aplayer" ref="header">
        <div class="header_qr_code" @click="show_qr_sale = true" v-if="qr_sale">
          <img src="../../assets/switch/qr_code.png" alt class="qr_code_icon" />
        </div>
        <div class="header_qr_code" @click="show_qr_info = true" v-if="qr_info">
          <img src="../../assets/switch/qr_code.png" alt class="qr_code_icon" />
        </div>
        <!-- <img :src="info.img[0].img" id="img"  class="aplayer_img" /> -->

        <mt-swipe :auto="5000">
          <mt-swipe-item v-for="(item,i) in info.img" :key="i">
            <img :src="item.img_res" alt />
          </mt-swipe-item>
        </mt-swipe>
        <!-- 天气信息 -->
        <div class="weather-box">
          <weather-info :info="weatherInfo" v-if="weatherInfo"></weather-info>
        </div>
      </div>

      <!-- 商户信息 -->
      <div class="main_title">
        <h2 v-if="info.title">
          <p class="main_title_value">{{language == 1 ? info.title : language == 2 ? info.en_title : ""}}</p>
          <div class="main_spots_tag" v-if="spots_label">
            <span class="tag_name" v-for="(item,i) in spots_label" :key="i">{{item.label_name}}</span>
          </div>
        </h2>
        <div class="main_open_time main_title_info" v-if="open_time">
          <span class="time_title title_info_name">
            <i class="iconfont iconshijian"></i>{{language == 1 ? "营业时间" : language == 2 ? "hours" : ""}}
          </span>
          <span
            class="time_item title_info_value"
            v-for="(item, i) in open_time"
            :key="i"
          >{{item.label_name}}</span>
        </div>
        
        <div class="main_address main_title_info" v-if="info.address">
          <span class="address_name title_info_name">
            <i class="iconfont icondizhi2"></i>{{language == 1 ? "详细地址" : language == 2 ? "address" : ""}}
          </span>
          <span
            class="address_value title_info_value"
            @click="open_map_nav(info.spot_lng,info.spot_lat,info.title,info.address)"
          >{{language == 1 ? info.address : (language == 2 ? info.en_address : info.or_address)}}</span>
        </div>
        <div class="main_tel main_title_info" v-if="info.user_tel">
          <span class="tel_name title_info_name">
            <i class="iconfont iconphonenew"></i>{{language == 1 ? "联系电话" : language == 2 ? "phone" : ""}}
          </span>
          <a :href="'tel:' + info.user_tel" class="tel_value title_info_value">{{info.user_tel}}</a>
        </div>
        <div class="main_shop_tag" v-if="shop_label_list">
          <div class="tag_wrapper">
            <span class="tag_name" v-for="(item,i) in shop_label_list" :key="i">{{item.label_name}}</span>
          </div>
        </div>
        <div class="main_shop_tag main_food_tag" v-if="food_label_list">
          <div class="tag_wrapper">
            <span class="tag_name" v-for="(item,i) in food_label_list" :key="i">{{item.label_name}}</span>
          </div>
        </div>
        <div class="main_shop_tag main_play_tag" v-if="play_label_list">
          <div class="tag_wrapper">
            <span class="tag_name" v-for="(item,i) in play_label_list" :key="i">{{item.label_name}}</span>
          </div>
        </div>
        <div class="main_shop_tag main_sleep_tag" v-if="sleep_label_list">
          <div class="tag_wrapper">
            <span class="tag_name" v-for="(item,i) in sleep_label_list" :key="i">{{item.label_name}}</span>
          </div>
        </div>

        <!--显示VR-->
        <div class="show-vr" v-if="vr_">
          
          <div class="vr">
            <div class="vr-mask"  @click="openVr"></div>
            <iframe :src="vr_" width="100%"  frameborder="0" scrolling="auto" id="iframename" name="iframename" ></iframe>
          </div>
        </div>

      </div>
      <!-- 用户获取的折扣 -->
      <div class="user_sale" v-if="user_sale">
        <div class="user_sale_wrapper">
          <div class="main_content">
            <div class="user_sale_content" @click="jumpToActive">
              <!-- 奖品 -->
              <div class="user_sale_box" v-if="user_sale.newUrl">
                <div class="user_sale_content">
                  <img :src="user_sale.user_icon" alt class="user_sale_icon" />
                  <div class="info">
                    <div class="info-1">
                      <span class="sale_user_nick">{{filterByNick(user_sale.user_nick)}}</span>抽中了
                      <span class="sale_user_sale_num">{{user_sale.label_name}}</span>
                    </div>
                    <p class="user_sale_code">编码：{{user_sale.code}}</p>
                    <p class="user_sale_time">{{user_sale.add_time}}</p>
                  </div>
                </div>
                <div class="user_sale_preview">
                  <img :src="user_sale.newUrl" alt />
                </div>
              </div>
              <!-- 折扣 -->
              <div class="user_sale_box" v-else>
                <div class="user_sale_content">
                  <img :src="user_sale.user_icon" alt class="user_sale_icon" />
                  <div class="info">
                    <div class="info-1">
                      <span class="sale_user_nick">{{filterByNick(user_sale.user_nick)}}</span>抽中了
                      <span class="sale_user_sale_num">{{user_sale.label_name}}</span>折折扣券
                    </div>
                    <p class="user_sale_code">编码：{{user_sale.code}}</p>
                    <p class="user_sale_time">{{user_sale.add_time}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 底部奖品信息 -->
      <div class="show-sale" v-if="sale_list">
        <div class="finger">
          <img src="../../assets/sale/sale_hand.png" alt />
        </div>
        <div class="show-title">
          <span class="title_line"></span>
          <span class="title_text" @click="jumpToActive">奖品展示</span>
        </div>

        <!-- 奖品展示 -->
        <div class="show-content" v-if="sale_list" @click="jumpToActive">
          <div class="sale-list">
            <div class="sale-item" v-for="(item, i) in sale_list" :key="i">
              <div class="item sale" v-if="!item.newUrl">
                <div class="value">
                  <span>{{item.label_name}}</span>
                  <span>折</span>
                </div>
                <span class="name">折扣券</span>
              </div>
              <div class="item prize" v-else>
                <div class="value">
                  <img :src="item.newUrl" alt />
                </div>
                <span class="name">{{item.label_name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="switch_" v-if="is_show_language">
        <div class="sw-item_" v-if="language == 1" @click="switch_language(language)">
          <!--中文-->
          <img src="../../assets/switch/en.png" />
        </div>
        <div class="sw-item_" v-else-if="language == 2" @click="switch_language(language)">
          <!--英文-->
          <img src="../../assets/switch/zh.png" />
        </div>
      </div>

      <!-- 拼单信息 -->
      <div class="spell_info" :style="spell_info_style" v-if="spellLength">
        <div class="spell_header">
          <div>
            <span>{{spellLength}}</span>人在拼单，可直接参与
          </div>
          <div class="spell_show_more" @click="spellShowMore(1)" v-show="spellLength">
            <span>查看更多</span>
            <i class="iconfont iconqianjin"></i>
          </div>
        </div>
        <div class="spell_wheel" :style="'height:' + spellWrapperNum * swipeHeight + 'rem'">
          <div class="spell_wrapper" ref="spell_wrapper">
            <!-- 用户1 -->
            <div
              class="spell_content"
              v-for="(item,i) in filterBy(spell_list_swipe)"
              :key="i"
              :class="spellClassNum"
            >
              <div class="spell_user">
                <img :src="item.user_icon" alt />
                <span>{{item.user_nick}}</span>
              </div>
              <div class="spell_detail">
                <div class="spell_remain">
                  <span>
                    还差
                    <span
                      class="spell_remain_number"
                    >{{item.differ_numbers ? item.differ_numbers : 1}}人</span>拼成
                  </span>
                  <span>剩余{{ formatTime(item.pid_etime , item.id) }}</span>
                </div>
                <button @click="showGoods(3,item.id)">去拼单</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 拼单结束 -->

      <!-- 显示更多拼单用户 -->
      <div class="spell_more_client" v-if="spell_show_more">
        <div class="spell_more_enter">
          <div class="spell_more_close" @click="spellShowMore(0)"></div>
          <div class="spell_more_title">正在拼单</div>
          <div class="spell_more_content" :style="spell_more_content_style">
            <ul class="spell_more_list">
              <li v-for="(item,i) in filterJoinList(join_group_list)" :key="i">
                <div class="spell_more_user">
                  <img class="spell_more_user_img" :src="item.user_icon" alt />

                  <span class="spell_more_user_info">
                    <span class="spell_more_user_top">
                      <span class="spell_more_user_name">{{item.user_nick}}</span>
                      <span
                        class="spell_more_user_remain"
                      >还差{{item.differ_numbers ? item.differ_numbers : 1}}人</span>
                    </span>
                    <span
                      class="spell_more_user_bottom"
                    >剩余 {{ formatTime(item.pid_etime, item.id) }}</span>
                  </span>
                </div>
                <button class="spell_more_button" @click="showGoods(3,item.id)">去拼单</button>
              </li>
            </ul>
            <div class="spell_more_hid" v-if="spellLength > 10">仅显示10个正在拼单的人</div>
          </div>
        </div>
      </div>
      <!-- 音频解说 -->
      <div class="ps-about" v-if="info.audio_path || info.enAudioPath">
        <span v-if="language == 1">关于{{info.title}}</span>
        <span v-else-if="language == 2">About {{info.en_title}}</span>
      </div>
      <div class="ps-audio" v-if="info.audio_path || info.enAudioPath">
        <div class="psa-image">
          <img src="../../assets/audio/AudionoPlay.png" v-if="is_play == 0" />
          <img src="../../assets/audio/AudioPlaying.gif" v-else />
        </div>
        <div class="psa-time">
          <span v-if="language == 1">{{zhCurrentTimeText}}/{{zhAudioDurationText}}</span>
          <span v-else-if="language == 2">{{enCurrentTimeText}}/{{enAudioDurationText}}</span>
        </div>
        <div class="psa-play-image" @click="playAudio">
          <i class="iconfont iconbofang" v-if="is_play == 0" ></i>
          <i class="iconfont iconbofang1" v-else></i>
        </div>
        <audio :src="info.audio_path" id="audio"></audio>
      </div>
      <!-- 广告 -->
      <div class="advert" v-if="advert_list">
        <ul class="advert-list">
          <li class="advert-item" v-for="(item,i) in advert_list" :key="i">
            <!-- 图片 -->
            <a
              class="advert-image advert-wrapper"
              v-if="item && item.advert_type_ == 'image'"
              :href="item.content"
            >
              <img :src="item.newUrl" alt />
            </a>
            <!-- 富文本 -->
            <div
              class="advert-html advert-wrapper"
              v-if="item && item.advert_type_ == 'richtext'"
              v-html="item.content"
            ></div>
            <!-- 视频 -->
            <div class="advert-video advert-wrapper" v-if="item && item.advert_type_ == 'video'">
              <video :src="item.content" :poster="item.newUrl" controls="controls"></video>
            </div>
          </li>
        </ul>
      </div>
      <!-- 活动 -->
      <div class="ps-activity" v-if="activity" @click="jumpToActivity(activity.id)">
        <img :src="activity.banner_img_app" alt />
      </div>
      <!-- 商户介绍 -->
      <div class="track_list">
        <div class="center_info padding_4">
          <div class="center_txt" v-html=" language == 1 ? info.content : info.en_content"></div>
          <div class="poster-img" @click="jumpToActivity(activity.id)">
            <img :src="activity.posterUrl" alt />
          </div>
        </div>
      </div>
      <!-- 进入地图/ 邀请排行榜 -->
      <div class="function-btn">
        <a :href="info.mapurl_" class="btn" v-if="info.mapurl_">
          <span class="name">{{language == 1 ?  '进入地图' : (language == 2 ? 'Open Map' : '')}}</span>
          <i class="iconfont iconqianjin"></i>
        </a>
        <div class="btn" @click="getShareRank('show')">
          <span class="name">{{language == 1 ? '邀请达人榜' : (language == 2 ? 'Expert List' : '')}}</span>
          <i class="iconfont iconqianjin"></i>
        </div>
      </div>
      <!-- 分享排行 -->
    <transition name="slide-down">
      <div class="share-rank-box" v-show="showShareRank" ref="share-rank-box">
        <div class="close" @click="showShareRank = false">
          <i class="iconfont iconiconfontback"></i>
        </div>
        <div class="banner">
          <div class="banner-title">
            <p class="text">邀请达人榜</p>
          </div>
          <div class="banner-icon">
            <i class="iconfont iconjiangpai"></i>
          </div>
        </div>
         <!--增加显示红包剩余个数信息-->
        <div class="statistics_percentage statistics_item_opt" v-if="red_packet != ''">
          <span class="statistics_name">红包剩余数量：{{ red_packet.total_ - red_packet.numbers >= 0 ? red_packet.total_ - red_packet.numbers : 0}} / {{red_packet.total_}}</span>
          <span class="numbers_percentage_outer">
                      <span
                              class="numbers_percentage_inner"
                              :style="'width:' + (red_packet.total_ - red_packet.numbers)/red_packet.total_ *100+ '%' "
                      ></span>
              </span>
        </div>
        <div class="self-share">
          <div class="left">
            <img :src="myShareInfo.user_icon" class="icon" alt v-if="myShareInfo.user_icon" />
            <img src="../../assets/img/apply_avatar_bg.png" alt class="icon" v-else />
          </div>
          <div class="middle">
            <p class="nick">{{myShareInfo.user_nick || '*'}}</p>
            <p class="tag" v-if="myShareInfo.numbers > 0">
              <span class="text">邀请达人</span>
            </p>
            <p class="tip" v-if="myShareInfo.amount">
              成功邀请
              <span class="num">{{myShareInfo.numbers}}</span> 位好友,获得<span class="num">{{myShareInfo.amount}}</span> 
            </p>
            <p class="tip" v-else>
              成功邀请
              <span class="num">{{myShareInfo.numbers}}</span> 位好友
            </p>
            <div class="self-list" v-if="myShareInfo.list">
              <ul class="list">
                <li class="item" v-for="(item, i) in myShareInfo.list" :key="i">
                  <img :src="item.user_icon" alt class="icon" v-if="item.user_icon"/>
                  <img src="../../assets/img/apply_avatar_bg.png" alt class="icon" v-else />
                </li>
              </ul>
            </div>
          </div>
          <div class="right">
            <button class="btn" @click="handleShowTipShare">邀请好友</button>
          </div>
        </div>

        <ul class="share-list">
          <li
            class="item"
            v-for="(item,i) in shareRankList"
            :key="i"
            @click="handleShowSubShareList(item.list)"
          >
            <div class="left">
              <img class="icon" :src="item.user_icon" alt v-if="item.user_icon" />
              <img src="../../assets/img/apply_avatar_bg.png" alt class="icon" v-else />
            </div>
            <div class="middle">
              <p class="nick">{{item.user_nick  || '*'}}</p>
              <p class="tip" v-if="item.amount">
                邀请
                <span class="num">{{item.numbers}}</span>
                位好友,获得<span class="num">{{item.amount}}</span>
              </p>
              <p class="tip" v-else>
                邀请
                <span class="num">{{item.numbers}}</span>
                位好友
              </p>
            </div>
            <div class="right">
              <div class="rank">{{item.rank}}</div>
            </div>
          </li>
        </ul>
        <!-- 个人分享信息 -->
        <div class="sub-share-box" v-show="showSubShareList" @click.self="showSubShareList = false">
          <div class="sub-share-wrapper">
            <div class="header">
              <p class="title">邀请信息</p>
              <i class="iconfont iconclose" @click="showSubShareList=false"></i>
            </div>
            <ul class="sub-share-list">
              <li class="item" v-for="(item, i) in subShareList" :key="i">
                <div class="item-icon">
                  <img class="icon" :src="item.user_icon" alt v-if="item.user_icon" />
                  <img src="../../assets/img/apply_avatar_bg.png" alt class="icon" v-else />
                </div>
                <div class="item-content">
                  <p class="nick">{{item.user_nick || '*'}}
                    <span style="color:#999;font-size:0.7rem;" v-if="item.amount">,获得</span>
                    <span style="color:red;font-size:0.7rem;" v-if="item.amount">{{item.amount}}</span>
                  </p>
                  <div class="info">
                    <span class="time">{{item.add_time}}</span>
                    <span class="num">浏览：{{item.looks_numbers}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>

    <!-- 提示分享界面 -->
    <div class="tip-share" v-show="showTipShare" @click.self="showTipShare = false">
      <p class="text">点击右上角，开始邀请好友吧~~</p>
      <img class="img" src="../../assets/switch/share_arrow.png" alt />
    </div>
      <!-- 红包雨 -->
      <red-packet
        :redPacketList="redPacketList"
        :red_packet_image_url="info.red_packet_image_url"
        :red_packet_type_="info.red_packet_type_"
        :id_="sid"
        id_type_="spots"
        v-if="info.have_red_packet_rain"
      ></red-packet>
      <!-- fast duration 获奖用户滚动显示-->
      <vue-marquee style="height:48px;background-color:white;margin-bottom:10px;border-bottom:solid 1px #eee;" :showProgress="false" v-if="marquee_list.length > 0">
        <vue-marquee-slide v-for="item in marquee_list" :key="item.id">
          <span style="display:block;padding:10px 2px 10px 2px;text-align:center; font-size:0.6rem">{{item.user_nick}}<span style="color:#999;font-size:0.4rem;padding:0px 10px 0px 10px ;">{{item.add_time}}获得</span><span style="color:red; font-size:0.8rem;">{{item.amount}}￥</span> </span>
        </vue-marquee-slide>
      </vue-marquee>
      <!--路书列表 -->
      <div class="root_list" v-if="root_list">
        <div class="root_list-title">
          <span class="title_line"></span>
          <span class="title_name">相关路书</span>
        </div>
        <div
          class="root_list_item"
          v-for="(item,i) in root_list"
          :key="i"
          @click="jumpToBooks(item.label_fk_id)"
        >
          <img :src="item.baseUrl" alt />
          <p class="item_name">{{item.label_name}}</p>
          <p class="item_type">{{item.type_name}}</p>
        </div>
      </div>

      <!-- 统计信息 -->
      <user-list :language="language ? language : 1" :info="info" id_type_="spots" v-if="is_show && is_weixin"></user-list>

      <!--评论输入框-->
      <div class="fixed_nav width-768" ref="fixed_nav">
        <div class="dis-left">
          <div class="dis-image">
            <img src="../../assets/map/current_location.png" />
          </div>
          <div class="dis-text">
            <span v-if="language == 1">{{zhDistanceText + distance}}</span>
            <span v-else-if="language == 2">{{enDistanceText + distance}}</span>
          </div>
        </div>
        <div
          class="dis-right pointer"
          @click="open_map_nav(info.spot_lng,info.spot_lat,info.title,info.address)"
        >
          <!-- <img src="../../assets/map/go_nav.png" /> -->
          <span class="nav-btn">{{language == 1 ? '导航' : 'Nav'}}</span>
        </div>
      </div>
      <!--评论弹窗输入框遮罩-->
      <div class="shade" v-if="is_discuss" @click="is_discuss=false"></div>
      <!--评论弹窗-->
      <div class="text_discuss TrackPublish" v-if="is_discuss">
        <form id="form" action method="post" enctype="multipart/form-data">
          <div class="bd_text_discuss">
            <mt-field
              :attr="{ name: 'content' }"
              v-model="content"
              placeholder="添加评论"
              rows="4"
              type="textarea"
            ></mt-field>
            <input type="hidden" name="spot_id" v-model="info.id" />
            <input type="hidden" name="star" value="1" />
            <input type="hidden" name="user_id" v-model="my_id" />
            <input type="hidden" name="discuss_id" v-model="discuss_id" />

            <div class="imgs clearfix" v-show="is_add_img"></div>
          </div>
          <div class="click_button clearfix">
            <div class="fl">
              <div class="footprint-image">
                <img src="../../assets/img/footprint_photo.png" alt @click="AddImage($event)" />
                <input type="file" multiple accept="image/*" />
              </div>
              <div class="emoji">
                <img src="../../assets/img/footprint_emoji.png" alt />
              </div>
            </div>
            <div class="fr" @click="add_discuss">评论</div>
          </div>
        </form>
      </div>

      <!-- 底部导航栏 -->
      <div class="footer_wrapper width-768">
        <a :href="'tel:' + info.user_tel" class="footer_nav pointer">
          <img src="../../assets/switch/shop_contact.png" alt />
          <span>{{ language == 1 ? '客服' : 'Contact'}}</span>
        </a>
        <div class="footer_nav pointer" @click="navToShopOrder">
          <img src="../../assets/switch/shop_order.png" alt />

          <span>{{ language == 1 ? '订单' : 'Order'}}</span>
        </div>
        <div class="footer_nav pointer" @click="jumpToMe">
          <img src="../../assets/switch/shop_my.png" alt />
          <span>{{ language == 1 ? '我的' : 'Me'}}</span>
        </div>
        <div class="footer_nav pointer panel-price" @click="showGoods(1,0)" v-if="goodsInfo && goodsInfo.length">
          <p>￥ {{info.price}}</p>
          <p>{{ language == 1 ? '单独购买' : 'Shopping'}}</p>
        </div>
        <div class="footer_nav pointer panel-price" @click="showGoods(2,0)" v-if="is_group">
          <p>￥ {{info.g_price_}}</p>
          <p>{{ language == 1 ? '发起团购' : 'Group Buy'}}</p>
        </div>
      </div>

      <!-- 底部预留高度 -->
      <div class="footer_hold"></div>

      <!--附近景点-->
      <div class="nearby_scenic padding_4" v-if="info.near_spots_list && info.near_spots_list.length > 0">
        <h2>附近景点</h2>
        <ul>
          <li v-for="k in info.near_spots_list" :key="k.id" @click="new_path(k.id)">
            <img :src="k.web_newUrl" alt />
            <h3>
              <nobr>{{k.spots_name}}</nobr>
            </h3>
            <p>{{k.length}}</p>
          </li>
        </ul>
      </div>
      <!-- 购买商品 -->
      <transition duration="300">
        <div class="goods_wrapper width-768" v-show="choseGoods" @click.self="closeGoods">
          <transition name="goodschose">
            <div class="goods_client" v-show="choseGoods">
              <div class="goods_header">
                <div class="goods_img" v-if="info.img">
                  <img :src="info.img[0].img" alt />
                </div>
                <div class="goods_close pointer" @click="closeGoods"></div>
                <div class="goods_header_info">
                  <span class="goods_price">￥</span>
                  <span class="goods_price" v-show="minPrice && !curPrice">{{minPrice}} -</span>
                  <span class="goods_price" v-show="maxPrice && !curPrice">{{maxPrice}}</span>
                  <span class="goods_price" v-show="acountPrice">{{acountPrice}}</span>
                  <div class="goods_typeinfo">
                    <span v-if="language === 1">{{selectedNumber ? '已选 :' : '请选择'}}</span>
                    <span class="goods_select_show">{{selectedName}}</span>
                  </div>
                </div>
              </div>
              <div class="goods_content">
                <div class="goods_type_1 goods_type" v-for="(item, i) in goodsInfo" :key="i">
                  <div class="goods_type_title">{{item.title}}</div>
                  <div class="goods_type_list">
                    <span
                      class="pointer"
                      v-for="(it, j) in item.list"
                      :key="j"
                      @click="goodsSelect($event,i,j)"
                      :class="item.select == i + '-'+ j ? 'goods_select' : ''"
                      :data-price="shoppingType == 1 ? it.price : it.g_price_"
                      :data-name="it.label_name"
                      :data-id="it.label_fk_id"
                      :data-g-price="it.g_price_"
                    >{{ shoppingType == 1 ? it.text_ : it.g_text_ }}</span>
                  </div>
                </div>

                <div class="goods_number">
                  <span class="goods_number_name">数量</span>
                  <div class="goods_input_number">
                    <span
                      class="goods_input_pre"
                      :class="preDisabled ? 'is_disabled' : ''"
                      @click.stop="changeGoodsNumber(0)"
                    >-</span>
                    <span
                      class="goods_input_las"
                      :class="lasDisabled ? 'is_disabled' : ''"
                      @click.stop="changeGoodsNumber(1)"
                    >+</span>
                    <div class="goods_input">
                      <input
                        type="text"
                        v-model="goodsNumber"
                        ref="goodsNumberInput"
                        min="1"
                        max="256"
                        oninput="value=value.replace(/[^\d]/g || /^0/g,'')"
                        @click="toView($event)"
                      />
                    </div>
                  </div>
                </div>
                <div class="content_bottom_hold"></div>
              </div>
              <div class="goods_submit">
                <button
                  :class="goodsChoseComplete ? 'goods_is_submit' : ''"
                  :disabled="!goodsChoseComplete"
                  @click="payGoods"
                >确定</button>
              </div>
            </div>
          </transition>
        </div>
      </transition>
    </div>

    <!--img轮播图-->
    <div class="preview-content" v-if="is_preview" @click="is_preview=false">
      <mt-swipe :auto="0" :showIndicators="true" :defaultIndex="index">
        <mt-swipe-item v-for="(item,i) in message" :key="i">
          <a href="javascript:;">
            <img :src="item.img_res+'&imageMogr/auto-orient'" alt />
          </a>
        </mt-swipe-item>
      </mt-swipe>
    </div>
    <!--地图定位-->
    <div id="gd_map" style="display: none;"></div>

    <!-- 折扣二维码 -->
    <transition name="qr_code">
      <div
        v-if="qr_sale"
        class="act_qr_code"
        v-show="show_qr_sale"
        @click.self="show_qr_sale = false"
      >
        <img :src="qr_sale" alt class="qr_code_img" />
        <p>扫一扫去打折</p>
      </div>
    </transition>
    <!-- 商户二维码 -->
    <transition name="qr_code">
      <div
        v-if="qr_info"
        class="act_qr_code"
        v-show="show_qr_info"
        @click.self="show_qr_info = false"
      >
        <img :src="qr_info" alt class="qr_code_img" />
        <p>保存不迷路</p>
      </div>
    </transition>
    <!-- 联系我们 -->
    <technical-support :ts_type_="ts_type_" contact_side="right" :id="sid"></technical-support>
    <!-- 下载桌贴 -->
    <a class="side-download" :href="info.saleUrl" download v-if="is_admin && info.saleUrl">
      <i class="iconfont iconxiazai1"></i>
    </a>

    <div class="download-url" v-if="0">
      <img class="img" :src="info.saleUrl" alt />
      <a class="download" :href="info.saleUrl" download>下载</a>
    </div>

    <!-- 背景音乐 -->
    <bg-music :bg_audio_url="bg_audio_url" v-if="bg_audio_url" :play_bg_music="play_bg_music"></bg-music>
    <!-- 发布红包 -->
    <publish-button :id="sid" :id_type_="backUrl" v-if="is_admin" bottom="8rem"></publish-button>

    <!-- 二级页面： 我的 -->
    <transition duration="300">
      <div class="spots-my width-768" v-show="show_my">
        <transition name="fade">
          <div class="my-wrapper" v-show="show_my">
            <div class="close pointer" @click="show_my = false">
              <i class="iconfont iconbaseline-close-px"></i>
            </div>
            <div class="my-header">
              <div class="my-header-user">
                <img :src="user_info.user_icon" alt />
                <p>
                  <span class="my-header-nick">{{user_info.user_nick}}</span>
                  <span class="my-header-score">
                    <span>积分：0</span>
                  </span>
                </p>
              </div>
            </div>

            <ul class="my-content">
              <li class="content-item" v-for="item in my_menu" :key="item.id">
                <div class="item-left">
                  <i class="iconfont" :class="item.icon1"></i>
                </div>
                <div class="item-right pointer" @click="handleMyClick(item.id)">
                  <span>{{item.title}}</span>
                  <i class="iconfont" :class="item.icon2"></i>
                </div>
              </li>
            </ul>

            <ul class="my-content my-content-spots" v-if="is_admin">
              <li class="content-item" v-for="item in my_menu_spots" :key="item.id">
                <div class="item-left">
                  <i class="iconfont" :class="item.icon1"></i>
                </div>
                <div class="item-right pointer" @click="handleMyClick(item.id)">
                  <span>{{item.title}}</span>
                  <i class="iconfont" :class="item.icon2"></i>
                </div>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </transition>

    <!-- 微信扫描二维码 -->
    <transition name='anim-opacity'>
      <div class="qrcode-box" v-show="showQrCode" v-if="qr_info" @click.self="showQrCode=false">
        <qr-code :showQrCode="showQrCode" :qrcode="qr_info" @closecode="showQrCode=false"></qr-code>
      </div>
    </transition>
  </div>
</template>
<script>
/* eslint-disable camelcase */
import { Toast, MessageBox, Indicator } from 'mint-ui'
import FootprintsList from '../../components/FootprintsList'
import LineInfo from '../../components/LineInfo'
import TechnicalSupport from '../../components/TechnicalSupport'
import RedPacket from '../../components/RedPacket'
import UserList from '../../components/UserList'
import { setTimeout, setInterval, clearInterval } from 'timers'
import bgMusic from '../../components/Music'
import PublishButton from '../../components/PublishButton'
import QrCode from '../../components/QrCode'
import WeatherInfo from '../../components/WeatherInfo'
import { Marquee, Slide } from "vue-marquee-component"
var wx = require('weixin-js-sdk')
export default {
  components: {
    FootprintsList,
    LineInfo,
    'technical-support': TechnicalSupport,
    'red-packet': RedPacket,
    'bg-music': bgMusic,
    'user-list': UserList,
    'publish-button': PublishButton,
    QrCode,
    WeatherInfo,
    [Marquee.name]: Marquee,
    [Slide.name]: Slide
  },
  data () {
    return {
      // 活动
      activity: '',
      // 我的
      show_my: false,
      user_info: {},
      my_menu: [
        {
          id: 1,
          icon1: 'iconicon-order',
          title: '我的订单',
          icon2: 'iconqianjin'
        },
        {
          id: 2,
          icon1: 'iconyouhuiquan',
          title: '我的卡券',
          icon2: 'iconqianjin'
        },
        {
          id: 3,
          icon1: 'icontubiao_zhongjiangjilu',
          title: '中奖纪录',
          icon2: 'iconqianjin'
        }
      ],
      my_menu_spots: [
        {
          id: 101,
          icon1: 'iconicon-order',
          title: '订单记录',
          icon2: 'iconqianjin'
        },
        {
          id: 102,
          icon1: 'iconyouhuiquan',
          title: '发布卡券',
          icon2: 'iconqianjin'
        },
        {
          id: 103,
          icon1: 'icontubiao_zhongjiangjilu',
          title: '发布红包',
          icon2: 'iconqianjin'
        }
      ],

      bg_audio_url: '', // 背景音乐
      play_bg_music: '',
      is_path: this.$route.query.is_path || 0,
      my_id: this.$route.query.user_id,
      
      sid: this.$route.query.sid || 0,
      id_: this.$route.query.id_ || 0,
      ts_type_: 'spots',
      backUrl: 'spots',
      info: {},
      near_foot: [],
      timer1: null,
      used: '00:00',
      remaining: '00:00',
      page: 1,

      is_show: false,
      is_begin: false,
      is_video: false,
      audiocurrentTime: 0, // 音频已播放时长
      videocurrentTime: 0, // 视频已播放总时长
      audio_duration: 0, // 音频总时长
      video_duration: 0, // 视频总时长
      a_Url: false,
      v_Url: false,
      content: '',
      is_discuss: false,
      is_add_img: false,
      is_preview: false,
      file_num: 0,
      discuss_img_arr: [],
      discuss_id: 0,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      is_show_language: 0,
      enDistanceText: 'Distance ',
      zhDistanceText: '距离您 ',
      map: {},
      lng: '',
      lat: '',
      distance: '',
      is_play: 0,
      zhAudioDuration: 0,
      enAudioDuration: 0,
      zhAudioDurationText: 0,
      enAudioDurationText: 0,
      zhCurrentTime: 0,
      enCurrentTime: 0,
      zhCurrentTimeText: 0,
      enCurrentTimeText: 0,
      timer: '',
      union_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      share_open_id: this.$route.query.share_open_id
        ? this.$route.query.share_open_id
        : '',
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      park_id: 298,
      user_id: '',
      version: '1.0.0',
      language: this.$route.query.language || 1, // 1是中文， 2是英文

      /* 底部导航 */
      footerMenu: ['详情', '我的'],
      active_menu: 0,
      shoppingType: 1,

      /* 拼单时间 */
      isSpell: true,
      duringTime: 30 * 24 * 60 * 60, // 拼单持续时间
      // remainTime: "", // 拼单剩余时间

      /* 参与拼单 */
      // enterSpell: false,
      spellLength: 0,
      spellWrapperNum: 0,
      spell_info_style: {},
      //   拼单列表
      join_group_list: [], // 拼单用户
      spell_list_swipe: [], // 拼单信息轮播
      spell_show_more: false, // 显示更多拼单信息
      spell_more_content_style: {}, // 显示更多拼单信息的样式
      /* 选择商品 */
      minPrice: 0, // 最低价
      maxPrice: 0, // 最高价
      curPrice: 0, // 当前选择的价格
      acountPrice: 0, // 总价格
      choseGoods: false,
      goodsNumber: 1, // 选择的商品数量
      preDisabled: true,
      lasDisabled: false,
      selectGoodsArr: [],
      selectPriceArr: [],
      selectIdArr: [],
      selectedNumber: 0,
      selectedName: '',

      goodsInfo: [], // 商品信息
      is_group: '', // 团购按钮
      goodsChoseComplete: false,
      /* 轮播 */
      swipeInnerTimer: '', // 内部计时器
      swipeOutterTimer: '', // 外部计时器
      swipeHeight: 2.4, // 单个界面高度
      swipeCount: 0, // 计时器
      swipeSpeed: 0.12, // 内部移动速度
      swipeCurrent: 0,
      tempTimer: '',

      spellClassNum: '',

      group_id: 0, // 拼团ID
      is_admin: '', // 管理者标识

      open_time: '', // 营业时间
      spots_label: '', // 商户标签
      food_label_list: '', // 这是什么
      play_label_list: '', // 这是什么
      shop_label_list: '', // 这是什么
      sleep_label_list: '', // 这是什么
      show_qr_info: '', // 二维码图标
      show_qr_sale: '', // 二维码图标
      qr_info: '', // 二维码图片
      qr_sale: '', // 折扣二维码
      user_sale: '', // 用户获取的折扣
      sale_list: '',
      sale_bg_arr: [],
      root_list: [],

      // 广告
      advert_list: [],

      sale_icon_0: require('../../assets/sale/sale_icon_0.png'),
      sale_icon_1: require('../../assets/sale/sale_icon_1.png'),
      sale_icon_2: require('../../assets/sale/sale_icon_2.png'),
      sale_icon_3: require('../../assets/sale/sale_icon_3.png'),
      sale_icon_4: require('../../assets/sale/sale_icon_4.png'),
      sale_icon_5: require('../../assets/sale/sale_icon_5.png'),
      sale_icon_6: require('../../assets/sale/sale_icon_6.png'),
      sale_icon_7: require('../../assets/sale/sale_icon_7.png'),
      sale_icon_8: require('../../assets/sale/sale_icon_8.png'),
      sale_icon_9: require('../../assets/sale/sale_icon_9.png'),

      // share
      myShareInfo: {},
      shareRankList: [],
      subShareList: [],
      isGetShareData: false,
      showShareRank: false,
      shareRankPage: 1,
      haveMoreShareData: true,
      showTipShare: false,
      showSubShareList: false,
      // 显示微信二维码
      showQrCode: false,
      // 天气信息
      weatherInfo: null,

      firstLocationTimer: null, // 延迟定位定时器

      red_packet:"",//红包信息数据
      marquee_list:[],//获得红包滚动数据

      vr_:'',//VR
    }
  },
  beforeDestroy () {
    if (this.firstLocationTimer) {
      clearTimeout(this.firstLocationTimer)
    }
  },
  watch: {
    goodsNumber: function (val) {
      if (Number(val) <= 1) {
        this.goodsNumber = 1
        this.preDisabled = true
      } else if (Number(val) >= 256) {
        this.goodsNumber = 256
        this.lasDisabled = true
      } else {
        this.preDisabled = this.lasDisabled = false
      }
      this.goodsNumber = Number(this.goodsNumber)
      this.acountPrice = parseFloat(
        (this.curPrice * this.goodsNumber).toFixed(2)
      )
    },
    spellLength: function (val) {
      if (val < 1) {
        this.spellWrapperNum = 0
        this.spell_more_content_style.height = 0
        this.spell_more_content_style['touch-action'] = 'none'
        this.spell_info_style.height = 0
      } else if (val === 1 || val === '1') {
        this.spellWrapperNum = 1
        this.spell_more_content_style.height = '3rem'
        this.spell_more_content_style['touch-action'] = 'none'
        this.spell_info_style.height = '4.6rem'
      } else if (val >= 2 && val <= 5) {
        this.spellWrapperNum = 2
        this.spell_more_content_style.height = val * 3 + 'rem'
        this.spell_more_content_style['touch-action'] = 'none'
        this.spell_info_style.height = '7rem'
      } else {
        this.spellWrapperNum = 2
        this.spell_more_content_style.height = 5 * 3 + 1.5 + 'rem'
        this.spell_more_content_style['touch-action'] = 'auto'
        this.spell_info_style.height = '7rem'
      }
    }
  },
  mounted () {
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.union_id &&
      !(
        !this.union_id &&
        typeof this.union_id !== 'undefined' &&
        this.union_id !== 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有union_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token === this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.union_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.union_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.union_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }
    if (this.union_id) {
    } else if(this.is_weixin) {
      this.authorize()
    }
    else
    {
      //表示不用去授权
    }

    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }

    this.unload()
    this.map = this.gdMapInit()

    this.visibilityChange()
    this.setSpellClass()

    // 在冒泡阶段处理滚动的事件
    // window.addEventListener("scroll", this.throttle(this.handleScroll));
    this.$nextTick(() => {
      this.$refs['share-rank-box'].addEventListener('scroll', e => {
        this.bindShareListScroll(e)
      })
    })
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer) // 在vue实例销毁钱，清除我们的定时器
    }
    if (this.swipeOutterTimer) {
      clearInterval(this.swipeOutterTimer)
    }
    if (this.swipeInnerTimer) {
      clearInterval(this.swipeInnerTimer)
    }
  },
  methods: {
    // 设置页面顶部是否预留高度（有用户数据时）
    setPadByUserData () {
      // 根据是否显示用户数据返回 padding-top
      if (this.is_weixin && this.is_admin) {
        return {
          'padding-top': '2.8rem'
        }
      } else {
        return {
          'padding-top': '0'
        }
      }
    },
    // 弹出子分享列表
    handleShowSubShareList (ls) {
      if (ls && ls.length > 0) {
        this.subShareList = ls
        this.showSubShareList = true
      } else {
        return false
      }
    },
    // 弹出提示分享
    handleShowTipShare () {
      if (this.is_weixin) {
        this.showTipShare = true
        setTimeout(() => {
          this.showTipShare = false
        }, 3000)
      } else {
        Toast('请在微信中打开')
      }
    },
    // 分享列表滚动
    bindShareListScroll (e) {
      if (!this.haveMoreShareData || this.isGetShareData) {
        // 没有更多数据或者正在请求数据
        return false
      }
      let st = e.target.scrollTop
      let ch = e.target.clientHeight
      let sh = e.target.scrollHeight
      if (st + ch >= sh - 10) {
        this.getShareRank('more') // 获取更多分享数据
      }
    },
    // 获取邀请数据
    getShareRank (type) {
      if (this.isGetShareData || !this.open_id || !this.token) {
        return false
      }

      if (type === 'show' && this.shareRankPage > 1) {
        this.showShareRank = true
        return false
      }
      this.isGetShareData = true
      //Indicator.open('加载中...')
      this.$ajax(
        'post',
        'Share/get_spots_share_looks_rank_data',
        {
          page: this.shareRankPage,
          id: this.sid,
          token: this.token,
          open_id: this.open_id,
          id_type_:this.ts_type_
        },
        res => {
          // console.log(res);
          if (res.data.id === 0) {
            Object.assign(this.myShareInfo, res.data.data.myself);
            if(res.data.data.red_packet)
            {
                this.red_packet = res.data.data.red_packet;
            }
            this.shareRankList.push(...res.data.data.list)
          } else {
            console.log(res.data.msg)
          }

          if (
            res.data.id !== 0 ||
            !res.data.data.list ||
            res.data.data.list.length < 10
          ) {
            this.haveMoreShareData = false
          }

          if (
            // eslint-disable-next-line eqeqeq
            this.shareRankPage == 1 &&
            (res.data.id !== 0 ||
              !res.data.data.list ||
              res.data.data.list.length === 0)
          ) {
            Toast('暂无邀请信息')
            Indicator.close()

            this.isGetShareData = false
            return false
          }

          //Indicator.close()
          this.shareRankPage++
          this.showShareRank = true
          this.isGetShareData = false
        }
      )
    },
    // 跳转活动
    jumpToActivity (id) {
      this.$router.push({
        path: '/shop/shopList',
        query: {
          aid: id
        }
      })
    },
    // 切换订单目录
    toggleOrderMenu (i) {
      this.order_menu_active = i
    },
    // “我的” 事件
    handleMyClick (id) {
      // 1 我的订单 2 我的卡券 3 中奖纪录  101 订单记录 102 发布卡券 103 发布红包
      if (id === 1) {
        this.$router.push({
          path: '/payforgoods/shopOrder',
          query: {
            id: this.sid,
            self_: 1
          }
        })
      } else if (id === 2) {
      } else if (id === 3) {
      } else if (id === 101) {
        this.$router.push({
          path: '/payforgoods/shopOrder',
          query: {
            id: this.sid,
            self_: 0
          }
        })
      } else if (id === 102) {
      } else if (id === 103) {
        this.jumpToPublish()
      } else {
        Toast('错误的事件')
      }
    },
    // 跳转路书
    jumpToBooks (id) {
      this.$router.push({
        path: '/books/books',
        query: {
          label_id: id
        }
      })
    },
    // 调到活动页面
    jumpToActive () {
      this.$router.push({
        path: '/shop/sale',
        query: {
          id: this.sid
        }
      })
    },
    // 初始化折扣券背景
    init_sale_bg () {
      for (let i = 0; i < this.sale_list.length; i++) {
        let icon = this['sale_icon_' + (i % 10)]
        // require("../../assets/sale/sale_icon_" + (i % 10) + ".png");
        this.sale_bg_arr.push(icon)
      }
    },

    BottomDotStyle (i) {
      return {
        width: '12px',
        height: '12px',
        bottom: '-6px',
        left: 6 * (i - 1) + 3 + 'vw'
      }
    },
    // 截取昵称长度
    filterByNick (n) {
      if (n && n.length > 4) {
        return n.substring(0, 4) + '...'
      } else {
        return n
      }
    },

    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id === 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var open_id = this.open_id
            if (info) {
              if (info.url) {
                let url_arr = info.url.split('share_open_id')

                url = url_arr[0] + '&share_open_id=' + open_id
              } else {
                url = url + '&share_open_id=' + open_id
              }
            } else {
              url = url + '&share_open_id=' + open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id === 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },
    setSpellClass () {
      let n = 0
      setInterval(() => {
        if (n >= 60) {
          n = 0
        }
        this.spellClassNum = 'spell_' + n
        n++
      }, 1000)
    },
    formatTime (val) {
      let endTime = val
      let currentTime = Date.parse(new Date()) / 1000
      let re = endTime - currentTime
      if (re < 0) {
        return '0天 00:00:00(结束)'
      }
      let day = parseInt(re / (60 * 60 * 24))
      re = re % (60 * 60 * 24)
      let h =
        parseInt(re / (60 * 60)) < 10
          ? '0' + parseInt(re / (60 * 60))
          : parseInt(re / (60 * 60))
      re = re % (60 * 60)
      let s =
        parseInt(re / 60) < 10 ? '0' + parseInt(re / 60) : parseInt(re / 60)
      let m = re % 60 < 10 ? '0' + (re % 60) : re % 60
      return day + '天 ' + h + ':' + s + ':' + m
    },
    visibilityChange () {
      let that = this
      // 页面显示状态改变
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          try {
            clearInterval(that.swipeOutterTimer)
            that.spellSwipe()
            // console.log("show");
          } catch (err) {
            console.log(err)
          }
        }
        if (document.visibilityState === 'hidden') {
          clearInterval(that.swipeOutterTimer)
          clearInterval(that.swipeInnerTimer)
          // console.log("hide");
        }
      })
    },
    filterJoinList (arr) {
      return arr.filter((item, i) => {
        return i <= 10
      })
    },
    // 拼单轮播
    filterBy (arr) {
      return arr.filter((item, i) => {
        return this.swipeCount - i < 1 && this.swipeCount - i > -3
      })
    },
    // 初始拼单信息
    initSpellInfo () {
      this.spellLength = this.join_group_list.length
      this.spell_list_swipe = this.spell_list_swipe.concat(
        this.join_group_list
      )

      if (this.spellLength > 2) {
        this.spell_list_swipe.push(this.join_group_list[0])
        this.spell_list_swipe.push(this.join_group_list[1])
      }
      // console.log(this.spell_list_swipe);
    },
    //   显示更多拼单信息
    spellShowMore (flag) {
      if (flag && (flag === 1 || flag === '1')) {
        this.spell_show_more = true
      } else {
        this.spell_show_more = false
      }
    },
    // 拼单轮播动画
    spellSwipeAnimate () {
      this.swipeInnerTimer = setInterval(() => {
        this.swipeCurrent += this.swipeSpeed
        if (this.swipeCurrent >= this.swipeHeight) {
          this.swipeCurrent = 0
          this.swipeCount++
          this.$refs.spell_wrapper.style.top = 0
          clearInterval(this.swipeInnerTimer)
        }
        this.$refs.spell_wrapper.style.top = -this.swipeCurrent + 'rem'
      }, 15)
    },
    //  拼单轮播
    spellSwipe () {
      if (this.spellLength > 2) {
        if (!this.$refs.spell_wrapper) {
          this.tempTimer = setInterval(() => {
            this.spellSwipe()
          }, 1000)
        } else {
          clearInterval(this.tempTimer)
          this.swipeOutterTimer = setTimeout(() => {
            // console.log(this.swipeCount);
            // clearInterval(this.swipeInnerTimer);
            if (this.swipeCount >= this.spellLength) {
              this.swipeCount = 0
            }
            this.spellSwipeAnimate()
            this.spellSwipe()
          }, 3000)
        }
      } else {

      }
    },
    //   确认支付
    payGoods () {
      let price = this.selectPriceArr.filter(Boolean).join('_')
      let label_id = this.selectIdArr.filter(Boolean).join('_')
      var id = this.sid + '_' + this.union_id
      let is_group_ = 0
      let group_id = this.group_id
      // console.log(this.shoppingType);
      if (this.shoppingType === 2 || this.shoppingType === '2') {
        // 开团
        is_group_ = 1
      } else if (this.shoppingType === 3 || this.shoppingType === '3') {
        // 拼团
        is_group_ = 0
      } else if (this.shoppingType === 1 || this.shoppingType === '1') {
        is_group_ = 0
      }
      // console.log(this.goodsNumber);
      if (id && this.union_id) {
        this.$ajax(
          'post',
          'Shop/spots_order',
          {
            id: id,
            title: this.info.title,
            token: this.token,
            park_id: this.park_id,
            user_id: this.user_id,
            open_id: this.union_id,
            is_group_: is_group_,
            group_id: group_id,
            price: price,
            label_id: label_id,
            coupon_id: 0,
            coupon_id_: 0,
            numbers: this.goodsNumber,
            btime: 0,
            etime: 0,
            address: 0,
            notes: 0,
            language: this.language
          },
          res => {
            // console.log(res);
            if (res.data.id === 0) {
              this.$router.push({
                path: '/payforgoods/payforgoods',
                query: {
                  oid: res.data.data.id,
                  sid: this.sid,
                  id_: this.id_,
                  user_id: ''
                }
              })
            } else {
              Toast('下单失败')
            }
          }
        )
      } else {
        this.authorize()
      }
    },
    // 选择商品
    goodsSelect (event, i, j) {
      //   console.log(event.target);
      this.curPrice = 0
      if (this.goodsInfo[i].select === (i + '-' + j)) {
        this.goodsInfo[i].select = ''
        this.selectedNumber--
        this.selectGoodsArr[i] = ''
        this.selectPriceArr[i] = ''
        this.selectIdArr[i] = ''
      } else {
        if (this.goodsInfo[i].select) {
          this.selectedNumber--
        }
        this.selectedNumber++
        this.goodsInfo[i].select = i + '-' + j

        this.selectGoodsArr[i] = event.target.innerText
        this.selectPriceArr[i] = event.target.getAttribute('data-price')
        this.selectIdArr[i] = event.target.getAttribute('data-id')
      }

      this.selectedName = ''
      this.selectedName = this.selectGoodsArr.filter(Boolean).join(' ； ')
      this.selectPriceArr.forEach(item => {
        if (item) {
          this.curPrice += parseFloat(item)
        }
      })
      this.acountPrice = parseFloat(
        (this.curPrice * this.goodsNumber).toFixed(2)
      )
      if (this.selectedNumber >= 1) {
        this.goodsChoseComplete = true
      } else {
        this.goodsChoseComplete = false
      }

      // console.log(this.selectPriceArr);
    },
    // 选择商品数量
    changeGoodsNumber (flag) {
      let num = parseFloat(this.goodsNumber)
      //   console.log(flag);
      //   console.log(num);
      if (flag === 0 || flag === '0') {
        if (num <= 1) {
          return
        } else {
          num--
        }
      } else if (flag === 1 || flag === '1') {
        if (num >= 256) {
          return
        } else {
          num++
        }
      }
      this.goodsNumber = num.toString()
      // console.log(this.$refs.goodsNumberInput.value);
    },
    // 关闭商品界面
    closeGoods () {
      this.choseGoods = false
    },
    // 显示商品界面
    showGoods (type, id) {
      this.group_id = id
      if (this.union_id) {
        // console.log(this.goodsInfo);
        this.shoppingType = type
        this.curPrice = 0
        if (type === 1 || type === '1') {
          this.goodsInfo.forEach(item => {
            if (item.select) {
              let i = item.select.split('-')[0]
              let j = item.select.split('-')[1]
              this.curPrice += parseFloat(item.list[j].price)
              this.selectPriceArr[i] = parseFloat(item.list[j].price)
              this.selectGoodsArr[i] = item.list[j].text_
            }
          })
          // this.curPrice =
        } else if (type === 2 || type === 3 || type === '2' || type === '3') {
          this.goodsInfo.forEach(item => {
            if (item.select) {
              let i = item.select.split('-')[0]
              let j = item.select.split('-')[1]
              this.curPrice += parseFloat(item.list[j].g_price_)
              this.selectPriceArr[i] = parseFloat(item.list[j].g_price_)
              this.selectGoodsArr[i] = item.list[j].g_text_
            }
          })
        }
        // console.log(this.selectGoodsArr);
        this.selectedName = ''
        this.selectedName = this.selectGoodsArr.filter(Boolean).join(' ； ')
        this.acountPrice = parseFloat(
          (this.curPrice * this.goodsNumber).toFixed(2)
        )
        // this.curPrice =
        if (this.spell_show_more) {
          this.spell_show_more = false
        }
        this.choseGoods = true
      } else {
        // 还未授权过
        this.authorize()
      }
    },

    unload () {
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        // FOR 最美旅行家APP
        // eslint-disable-next-line no-undef
        $('.mint-header.is-fixed').remove()
        // eslint-disable-next-line no-undef
        $('.page-content').css('padding', '0')
      }

      this.getData(1, this.sid)
    },
    // 获取广告
    getAdvert () {
      if (this.token && this.backUrl && this.sid) {
        this.$ajax(
          'post',
          'Advert/get_advert_list',
          {
            id_: this.sid,
            id_type_: this.backUrl,
            token: this.token
          },
          res => {
            if (res.data.id === 0) {
              this.advert_list = res.data.data.list || ''
            }
          }
        )
      }
    },
    // 获取数据
    getData (page, sid) {
      //Indicator.open('加载中...')
      // var aplayer = document.getElementById('aplayer')
      this.$ajax(
        'post',
        'foot/get_foot_info_v2',
        {
          spot_id: sid,
          user_id: '',
          page: page,
          open_id: this.union_id ? this.union_id : '',
          where_: 'web',
          park_id: this.park_id,
          share_open_id: this.share_open_id,
          label_id: this.id_,
          id_type_:this.ts_type_
        },
        res => {
          if (res.data.id === 0) {
            if (page === 1) {
              this.info = res.data.data
              // 网页名称
              document.title = res.data.data.title
              // 营业时间
              this.open_time = this.info.open_time ? this.info.open_time : ''
              // 主体标签
              this.spots_label = this.info.spots_label
                ? this.info.spots_label
                : ''
              // 路书列表
              this.root_list = this.info.root_list ? this.info.root_list : ''
              // 美食标签
              this.food_label_list = this.info.food_label_list
                ? this.info.food_label_list
                : ''
              // 游玩标签
              this.play_label_list = this.info.play_label_list
                ? this.info.play_label_list
                : ''
              // 商户标签
              this.shop_label_list = this.info.shop_label_list
                ? this.info.shop_label_list
                : ''
              // 住宿标签
              this.sleep_label_list = this.info.sleep_label_list
                ? this.info.sleep_label_list
                : ''
              this.qr_info = this.info.qr_info ? this.info.qr_info : '' // 商户二维码
              this.qr_sale = this.info.qr_sale ? this.info.qr_sale : '' // 折扣二维码
              this.user_sale = this.info.sale ? this.info.sale : '' // 用户折扣
              this.sale_list = this.info.sale_list ? this.info.sale_list : '' // 用户折扣
              this.marquee_list = res.data.data.marquee_list || [];//获奖用户滚动显示

              // 如果有折扣列表，显示折扣信息
              if (this.sale_list) {
                this.init_sale_bg() // 折扣背景图
              }
              this.vr_ = this.info.vr_ ? this.info.vr_ : '';//VR 地址
              // 只有第一种需要获取banner图的高度
              var img = new Image()
              img.src = this.info.img[0].img_res
              img.onload = () => {
                let prop = img.height / img.width
                this.$refs.header.style.height = prop * this.$refs.header.clientWidth + 'px'
              }

              // 微信分享
              let wx_title = res.data.data.title
              let wx_content = res.data.data.content
              let wx_img = res.data.data.img[0].android_newUrl
              let sUrl = window.location.href
              if (this.is_weixin) {
                this.wx_share_callback(window.location.href, {
                  url: sUrl,
                  id_: this.sid,
                  id_type_: 'spots',
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                })
              }

              // 显示语言切换按钮
              this.is_show_language = res.data.data.is_show_language
              // 背景音乐
              this.bg_audio_url = res.data.data.bg_audio_url
                ? res.data.data.bg_audio_url
                : ''
              // 中文音频时长
              this.zhAudioDuration = res.data.data.zhAudioDuration || 0
              this.zhAudioDurationText = this.zhAudioDuration ? this.FormatAudioTime(this.zhAudioDuration) : 0
              // 英文音频时长
              this.enAudioDuration = res.data.data.enAudioDuration || 0
              this.enAudioDurationText = this.enAudioDuration ? this.FormatAudioTime(this.enAudioDuration) : 0
              // 音频 / 视频
              this.a_Url = !!this.info.audio_path
              this.v_Url = !!this.info.video_path

              scrollTo(0, 0)
              // 5s 后再执行定位
              this.firstLocationTimer = setTimeout(() => {
                this.gdMapLocation({
                  map: this.map,
                  type: '',
                  callback: (lng, lat) => {
                    this.lng = lng
                    this.lat = lat
                    let spot_lng = res.data.data.spot_lng
                    let spot_lat = res.data.data.spot_lat
                    if (lng && lat) {
                      console.log(lng + ',' + lat + ',' + spot_lng + ',' + spot_lat)
                      let distance = this.gdDistance(
                        this.map,
                        [lng, lat],
                        [spot_lng, spot_lat]
                      )
                      distance = distance / 1000
                      this.distance = distance.toFixed(2) + 'KM'
                    } else {
                      this.distance = ''
                    }
                  }
                })
              }, 10000)

              // 商品信息
              let info = res.data.data
              this.minPrice = info.price || 0
              this.maxPrice = info.max_price || 0
              this.goodsInfo = info.label_list_
              this.is_group = parseFloat(info.is_group_) // info.is_group_ 是一个长度为1的字符串

              // 红包信息
              if (info.red_packet_image_url_list && info.red_packet_image_url_list.length) {
                this.redPacketList = info.red_packet_image_url_list
              }
              // 拼团信息
              this.join_group_list = info.join_group_list
              this.initSpellInfo()
              // console.log(this.join_group_list);
              this.spellSwipe()

              // 管理员标识
              this.is_admin = info.is_admin;
              // 是否显示统计的数据：浏览，分享，参与，中奖
                this.is_show = info.is_show;
              // 我的
              this.user_info = res.data.data.user || ''

              // 活动
              this.activity = res.data.data.activity || ''

              // 获取广告
              this.getAdvert()

              // 查询天气
              this.getWeatherByCity(this.info.city).then(wt => {
                this.weatherInfo = wt
              }, er => {
                console.log('获取天气失败：' + er.message)
              })
            }
          }
          //Indicator.close()

        }
      )
    },
    // 点击切换音频视频
    click_tab () {
      let audio = document.getElementById('audio')
      let video = document.getElementById('video')
      let showProgress = document.getElementById('showProgress')
      let offsetWidth = document.getElementById('line').offsetWidth
      this.is_video = !this.is_video
      this.is_begin = false
      audio.pause()
      video.pause()
      //   console.log(this.is_video);
      if (this.is_video) {
        // console.log(111);
        if (this.audiocurrentTime === 0 || this.audiocurrentTime === '0') {
          this.used = '00:00'
          showProgress.style.width = '2px'
        } else {
          this.used = this.formatSeconds(this.audiocurrentTime)
          showProgress.style.width =
            (this.audiocurrentTime / this.audio_duration) * offsetWidth + 'px'
        }
        this.remaining = this.formatSeconds(this.audio_duration)
      } else {
        if (this.videocurrentTime === 0 || this.videocurrentTime === '0') {
          this.used = '00:00'
          showProgress.style.width = '2px'
        } else {
          this.used = this.formatSeconds(this.videocurrentTime)
          showProgress.style.width =
            (this.videocurrentTime / this.video_duration) * offsetWidth + 'px'
        }
        this.remaining = this.formatSeconds(this.video_duration)
      }
    },

    //            开始播放音频之后的事件
    audioStart () {
      let audio = document.getElementById('audio')
      let video = document.getElementById('video')
      let that = this
      let currentTime
      let duration
      if (this.is_video) {
        duration = this.audio_duration = audio.duration
      } else {
        duration = this.video_duration = video.duration
      }
      let showProgress = document.getElementById('showProgress')
      let offsetWidth = document.getElementById('line').offsetWidth

      this.remaining = this.formatSeconds(duration)
      this.timer1 = setInterval(function () {
        if (that.is_video) {
          currentTime = Math.ceil(audio.currentTime)
          that.audiocurrentTime = currentTime
        } else {
          currentTime = Math.ceil(video.currentTime)
          that.videocurrentTime = currentTime
        }
        that.used = that.formatSeconds(currentTime)
        var percent = currentTime / duration
        showProgress.style.width = percent * offsetWidth + 'px'
      }, 1000)
    },
    //            暂停清除定时器
    pause () {
      window.clearInterval(this.timer1)
    },
    //            播放完成之后清除定时器，改变按钮样式
    ended () {
      window.clearInterval(this.timer1)
      this.is_begin = false
    },
    //            点击进度条
    videoSeek (e) {
      if (!this.a_Url && this.is_video) {
        Toast('暂无音频')
        return
      }
      if (!this.v_Url) {
        Toast('暂无视频')
        return
      }
      let audio = document.getElementById('audio')
      let that = this
      let video = document.getElementById('video')
      let line = document.getElementById('line')
      let showProgress = document.getElementById('showProgress')
      let progressFlag
      if (this.is_video) {
        if (audio.paused || audio.ended) {
          play(audio)
          enhanceVideoSeek(e, audio)
        } else {
          enhanceVideoSeek(e, audio)
        }
      } else {
        if (video.paused || video.ended) {
          play(video)
          enhanceVideoSeek(e, video)
        } else {
          enhanceVideoSeek(e, video)
        }
      }
      function play (type) {
        if (type.paused || type.ended) {
          if (type.ended) {
            type.currentTime = 0
          }
          type.play()
          that.is_begin = true
          progressFlag = setInterval(getProgress, 60)
        } else {
          type.pause()
          that.is_begin = false
          clearInterval(progressFlag)
        }
      }

      // video的播放条
      function getProgress () {
        let percent = null
        if (that.is_video) {
          percent = audio.currentTime / audio.duration
          showProgress.style.width = percent * line.offsetWidth - 2 + 'px'
        } else {
          percent = video.currentTime / video.duration
          showProgress.style.width = percent * line.offsetWidth - 2 + 'px'
        }
      }

      function enhanceVideoSeek (e, type) {
        clearInterval(progressFlag)
        var length = e.pageX - line.offsetLeft
        var percent = length / line.offsetWidth
        showProgress.style.width = percent * line.offsetWidth - 2 + 'px'
        type.currentTime = percent * type.duration
        progressFlag = setInterval(getProgress, 60)
      }
    },
    discuss () {
      if (!this.check_login()) {
        MessageBox({
          title: '提示',
          message: '确认登陆吗？',
          showCancelButton: true
        }).then(action => {
          if (action === 'confirm') {
            this.get_User_Id()
          }
        })
      } else {
        this.is_discuss = true
        this.$nextTick(() => {
          // eslint-disable-next-line no-undef
          $('.mint-field-core')[0].focus()
        })
      }
    },
    //            提交评论
    add_discuss () {
      if (!this.content) {
        Toast('请输入评论内容')
        return
      }
      if (this.discuss_img_arr.length > 9) {
        Toast('最多可上传9张图片')
        return
      }
      Indicator.open('发布中...')
      var that = this
      var form = document.getElementById('form')
      var formdata = new FormData(form)
      for (var i = 0; i < this.discuss_img_arr.length; i++) {
        formdata.append('img' + (i + 1), this.discuss_img_arr[i])
      }
      this.formMethod('foot/discuss_spots_and_foot', formdata, function (data) {
        Indicator.close()
        that.is_discuss = false
        if (data.id === 0) {
          that.$ajax(
            'post',
            'foot/get_foot_info',
            {
              spot_id: that.sid,
              user_id: that.my_id,
              page: 1
            },
            res => {
              if (res.data.id === 0) {
                that.discuss_img_arr = []
                that.info = Object.assign({}, that.info, res.data.data)
                that.$set(
                  that.info,
                  'discuss',
                  res.data.data.discuss.reverse()
                )
                that.content = ''
                that.file_num = 0
                var TrackParticulars = document.querySelector(
                  '.TrackParticulars'
                )
                document.body.scrollTop = TrackParticulars.scrollHeight
              }
            }
          )
        } else {
          Toast(data.msg)
        }
      })
    },
    add_discuss_content (content, pid) {
      this.discuss()
      this.content = '@' + content + ':'
      this.discuss_id = pid
    },
    //            删除评价图片
    remove_img (e) {
      this.discuss_img_arr.splice(
        // eslint-disable-next-line no-undef
        $(e.target)
          .parent()
          .index(),
        1
      )
      // eslint-disable-next-line no-undef
      $(e.target)
        .parent()
        .remove()
    },
    // 选择图片
    AddImage ($event) {
      // eslint-disable-next-line no-undef
      var file = $($event.target).siblings()[0]
      file.click()
      var that = this
      file.onchange = function () {
        that.is_add_img = true
        var files = Array.prototype.slice.call(this.files)
        files.forEach(function (file, i) {
          var reader = new FileReader()
          reader.onload = function () {
            that.discuss_img_arr.push(that.upload(this.result, file.type))
          }
          reader.readAsDataURL(file)
          var objUrl = that.getObjectURL(file)
          var html =
            '<div class="item up-img">' +
            '<img src="' +
            objUrl +
            '"><i class="remove"></i>' +
            '</div>'
            // eslint-disable-next-line no-undef
          $('.bd_text_discuss .imgs').append(html)
          // eslint-disable-next-line no-undef
          if ($('.footprint-image input').length > 9) {
            Toast('最多可上传9张图片')
          }
        })// eslint-disable-next-line no-undef
        $('.remove').on('click', that.remove_img)// eslint-disable-next-line no-undef
        if ($('.up-img').length >= 9) {
          Toast('最多可上传9张图片')
        }
      }
    },
    zan (sid) {
      // 赞景点
      if (!this.check_login()) {
        MessageBox({
          title: '提示',
          message: '确认登陆吗？',
          showCancelButton: true
        }).then(action => {
          if (action === 'confirm') {
            this.get_User_Id()
          }
        })
      } else {
        var user_id = this.check_login().user_id
        this.$ajax(
          'post',
          'foot/click_spots',
          {
            user_id: user_id,
            spot_id: sid
          },
          res => {
            if (res.data.id === 0) {
              if (this.info.like === 0 || this.info.like === '0') {
                this.info.like_numbers++
                this.$set(this.info, 'like', 1)
                this.$set(this.info, 'like_numbers', this.info.like_numbers)
              } else {
                this.info.like_numbers--
                this.$set(this.info, 'like', 0)
                this.$set(this.info, 'like_numbers', this.info.like_numbers)
              }
            }
          }
        )
      }
    },
    getRowType (value) {
      var rowType = ''
      if (value === 1 || value === '1') {
        rowType = 'row1'
      } else if (value === 2 || value === 4 || value === '2' || value === '4') {
        rowType = 'row2'
      } else if (value === 3 || value === '3' || value >= 5) {
        rowType = 'row3'
      }
      return rowType
    },
    onClickMe (img, index) {
      this.is_preview = true
      this.message = img
      this.index = index
      return false
    },
    zan_click_discuss (id, i) {
      if (!this.check_login()) {
        MessageBox({
          title: '提示',
          message: '确认登陆吗？',
          showCancelButton: true
        }).then(action => {
          if (action === 'confirm') {
            this.get_User_Id()
          }
        })
      } else {
        var user_id = this.check_login().user_id
        this.$ajax(
          'post',
          'foot/click_spots_discuss_like',
          {
            user_id: user_id,
            discuss_id: id
          },
          res => {
            if (res.data.id === 0) {
              if (this.info.discuss[i].like === 0 || this.info.discuss[i].like === '0') {
                this.info.discuss[i].like_numbers++
                this.$set(this.info.discuss[i], 'like', 1)
                this.$set(
                  this.info,
                  'like_numbers',
                  this.info.discuss[i].like_numbers
                )
              } else {
                this.info.discuss[i].like_numbers--
                this.$set(this.info.discuss[i], 'like', 0)
                this.$set(
                  this.info.discuss[i],
                  'like_numbers',
                  this.info.discuss[i].like_numbers
                )
              }
            }
          }
        )
      }
    },
    new_path (sid) {
      this.getData(1, sid)
    },
    open_map_nav (lng, lat, title, address) {
      if (this.is_weixin) {
        if (this.info.AudioPrice > 0) {
          if (this.info.is_pay) {
            // 已经进行支付
            this.wx_nav(window.location.href, lng, lat, title, address)
          } else {
            // 还未支付
            if (this.union_id) {
              // 已经授权过了,进行支付
              var open_url =
                'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
                this.id_ +
                '&sid=' +
                this.sid +
                '&backUrl=spots&open_id=' +
                this.union_id +
                '&token=' +
                this.token +
                '&park_id=' +
                this.park_id +
                '&version=' +
                this.version +
                '&user_id=' +
                this.user_id
              window.location.href = open_url
            } else {
              // 还未授权过
              this.authorize()
            }
          }
        } else {
          // 免费导航
          this.wx_nav(window.location.href, lng, lat, title, address)
        }
      } else {
        if (this.qr_info) {
          this.showQrCode = true
        } else {
          Toast('请在微信中打开,使用导航')
        }
      }
    },
    // 切换语言
    switch_language (language) {
      this.is_play = 0
      var audio = document.getElementById('audio')
      this.zhCurrentTimeText = this.enCurrentTimeText = 0
      if (language === 1 || language === '1') {
        this.language = 2 // 英文
        if (this.info.zhAudioDuration) {
          audio.src = this.info.audio_path
        } else {
          Toast('无中文音频解说')
        }
      } else {
        this.language = 1 // 中文
        if (this.info.enAudioDuration) {
          audio.src = this.info.enAudioPath
        } else {
          Toast('无英文音频解说')
        }
      }
      if (this.timer) {
        clearInterval(this.timer) // 在vue实例销毁钱，清除我们的定时器
      }
    },
    // 播放音频文件
    playAudio () {
      //   console.log("音频播放:");
      var audio = document.getElementById('audio')
      var is_can_play = 0
      var _this = this // 声明一个变量指向vue实例this,保证作用域一致
      if (this.info.AudioPrice > 0) {
        if (this.info.is_pay === 1 || this.info.is_pay === '1') {
          // 已经支付了
          is_can_play = 1
        } else {
          // 还未支付
          if (this.union_id) {
            // 已经授权过了,进行支付
            var open_url =
              'https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=' +
              this.id_ +
              '&sid=' +
              this.sid +
              '&backUrl=spots&open_id=' +
              this.union_id +
              '&token=' +
              this.token +
              '&park_id=' +
              this.park_id +
              '&version=' +
              this.version +
              '&user_id=' +
              this.user_id
            window.location.href = open_url
          } else {
            // 还未授权过
            this.authorize()
          }
          is_can_play = 0
        }
      } else {
        // 免费的解说,可以直接播放
        is_can_play = 1
      }
      if (is_can_play === 1 || is_can_play === '1') {
        if (this.is_play === 1 || this.is_play === '1') {
          this.is_play = 0
          if (this.timer) {
            clearInterval(this.timer) // 在vue实例销毁钱，清除我们的定时器
          }
          audio.pause()
        } else {
          if (this.language === 1 || this.language === '1') {
            // 中文
            if (this.zhAudioDuration) {
              // console.log("中文音频:"+this.info.audio_path);
              audio.src = this.info.audio_path
              audio.play()
              this.play_bg_music = false
            } else {
              Toast('没有中文音频解说')
            }
          } else {
            // 英文
            if (this.enAudioDuration) {
              audio.src = this.info.enAudioPath
              audio.play()
              this.play_bg_music = false
            } else {
              Toast('无英文音频解说')
            }
          }
          this.is_play = 1
          this.timer = setInterval(function () {
            if (_this.language === 1 || _this.language === '1') {
              // 中文
              // console.log(_this.zhCurrentTime+','+_this.zhAudioDuration);
              if (_this.zhCurrentTime < _this.zhAudioDuration) {
                _this.zhCurrentTimeText = _this.FormatAudioTime(
                  ++_this.zhCurrentTime
                )
              } else {
                // 时间已经执行到最大值
                _this.zhCurrentTimeText = 0
              }
            } else {
              // 英文
              if (_this.enCurrentTime < _this.enAudioDuration) {
                _this.enCurrentTimeText = _this.FormatAudioTime(
                  ++_this.enCurrentTime
                )
              } else {
                // 时间已经执行到最大值
                _this.enCurrentTimeText = 0
              }
            }
          }, 1000)
        }
      } else {
        Toast('请先购买,在播放')
      }
    },
    // 加载更多数据
    loadMoreData (dataType) {
      let where_, page
      if (dataType === 'looks') {
        // this.looks_data_len % 10 !== 0 ||
        if (this.looks_data_len === 0) {
          this.looks_data_bottom = '我是有底线的'
          return
        } else {
          this.looks_data_page++
          where_ = 'looks'
          page = this.looks_data_page
        }
      } else if (dataType === 'share') {
        // this.share_data_len % 10 !== 0 ||
        if (this.share_data_len === 0) {
          this.share_data_bottom = '我是有底线的'
          return
        } else {
          this.share_data_page++
          where_ = 'share'
          page = this.share_data_page
        }
      } else if (dataType === 'join') {
        // this.join_data_len % 10 !== 0 ||
        if (this.join_data_len === 0) {
          this.join_data_bottom = '我是有底线的'
          return
        } else {
          this.join_data_page++
          where_ = 'join'
          page = this.join_data_page
        }
      } else if (dataType === 'win') {
        // this.win_data_len % 10 !== 0 ||
        if (this.win_data_len === 0) {
          this.win_data_bottom = '我是有底线的'
          return
        } else {
          this.win_data_page++
          where_ = 'win'
          page = this.win_data_page
        }
      }

      // this.getPayMoneyData();
      let id_ = this.sid ? this.sid : this.id_
      let id_type_ = this.sid ? 'spots' : 'label'

      if (this.union_id && this.token && this.park_id && id_ && id_type_) {
        this.$ajax(
          'post',
          'Lottery/get_four_type_user_list',
          {
            id_: id_,
            id_type_: id_type_,
            token: this.token,
            open_id: this.union_id,
            park_id: this.park_id,
            where_: where_,
            page: page
          },
          res => {
            console.log(res)
            if (res.data.id === 0) {
              this[dataType + '_list'] = this[dataType + '_list'].concat(
                res.data.data.data
              )
              console.log(this[dataType + '_list'])
              // console.log(res.data.data.data);
              this[dataType + '_data_len'] = res.data.data.data.length
            } else if (res.data.id === -2) {
              // Toast("我是有底线的");

              this[dataType + '_data_len'] = 0
              this[dataType + '_data_bottom'] = '我是有底线的'
            } else {
              Toast('加载失败！')
            }
          }
        )
      } else {
        // 还未授权过
        this.authorize()
      }
    },

    // 点击 '订单' 跳转
    navToShopOrder () {
      this.$router.push({
        path: '/payforgoods/shopOrder',
        query: {
          id: this.sid,
          self_: 1
        }
      })
    },
    // 点击 '我的' 跳转
    jumpToMe () {
      this.show_my = true
    },
    jumpToPublish () {
      this.$router.push({
        path: '/publish/Mypublish',
        query: {
          id_type_: 'spots',
          id_: this.sid
        }
      })
    },
    jumpToOrder () {
      Toast('正在开发中！')
    },

    toView (event) {
      setTimeout(() => {
        event.target.scrollIntoView()
      }, 300)
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.sid +
        '&id_=' +
        this.id_ +
        '&id_type_=' +
        '&state=spots'
      window.location.href = open_url
    },

    //进入VR
    openVr()
    {
      if(this.vr_)
      {
        window.location.href=this.vr_;
      }
      else
      {
        Toast.msg('无VR内容');
      }
    }


  }
}
</script>
<style scoped>
.PrimaryScenic {
  background-color: #fff;
  position: relative;
  margin: 0 auto;
}

.PrimaryScenic .page-content {
  overflow-x: hidden;
  padding: 0;
  /* padding-bottom: 2.4rem; */
}
/*  */
.aplayer {
  position: relative;
  height: 40vh;
}

.aplayer video {
  width: 100%;
  display: block;
}

.aplayer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -10;
}

.aplayer .paly {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aplayer .play_button {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  position: absolute;
  left: 0;
  display: block;
  text-align: left;
  height: 2rem;
  line-height: 2rem;
  color: #fff;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  z-index: 1;
}

.aplayer .play_button .btn {
  flex: 1;
  font-size: 1.2rem;
  display: inline-block;
}

.aplayer .play_button span {
  display: inline-block;
  font-size: 0.5rem;
  flex: 1;
  text-align: center;
}

.aplayer .play_button .line {
  display: inline-block;
  flex: 4;
  height: 0.2rem;
  background-color: #ccc;
  position: relative;
  border-radius: 0.1rem;
}

.aplayer .play_button .line span {
  width: 2px;
  background-color: rgb(226, 46, 38);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.1rem;
  height: 0.2rem;
}

.aplayer .play_button .line span i {
  width: 0.3rem;
  height: 0.3rem;
  display: inline-block;
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  border: 0.2rem solid rgb(226, 46, 38);
  background-color: #fff;
  border-radius: 50%;
}

.aplayer .top_btn {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2rem;
  color: #fff;
  font-size: 0.7rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 2rem;
  margin-top: 0.2rem;
  z-index: 1;
}

.aplayer .top_btn span {
  width: 2rem;
  height: 1.4rem;
  line-height: 1.4rem;
  text-align: center;
  display: inline-block;
  color: #666;
}

.aplayer .top_btn span.avtive {
  color: #fff;
}

.aplayer .top_img_box {
  background: none;
  height: auto;
}

.aplayer .top_img_box .img_num,
.aplayer .top_img_box .navigation {
  bottom: 2rem;
}

.synopsis_info {
  padding: 0.4rem;
  font-size: 0.75rem;
  line-height: 1.4rem;
}

.nearby_scenic {
  height: 9.5rem;
  overflow: hidden;
}

.nearby_scenic > h2 {
  font-size: 0.8rem;
  line-height: 1.6rem;
  height: 1.6rem;
}

.nearby_scenic ul {
  height: 10rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nearby_scenic ul li {
  margin: 0 5px 5px 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
  width: 6rem;
}

.nearby_scenic ul li img {
  width: 100%;
}

.nearby_scenic ul li h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8rem;
}

.nearby_scenic ul li p {
  font-size: 0.6rem;
  color: #999;
}

.fixed_nav {
  position: fixed;
  height: 2.3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  font-size: 0.8rem;
  bottom: 3rem;
  box-sizing: border-box;
  box-shadow: 0 -2px 2px -2px #00000033;
  z-index: 1;
}

.fixed_nav .dis-left {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.fixed_nav .dis-left .dis-image {
  position: relative;
  width: 2.2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fixed_nav .dis-left .dis-image img {
  width: 1.7rem;
  height: 1.7rem;
}
.fixed_nav .dis-left .dis-text {
  position: relative;
  /* width: 85%; */
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.fixed_nav .dis-left .dis-text span {
  display: block;
  font-size: 0.8rem;
  color: #666;
  text-align: left;
}
.fixed_nav .dis-right {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  color: #666;
  padding: 0 .5rem;
}
.fixed_nav .dis-right img {
  width: 1rem;
  height: 1rem;
  margin: 0 .25rem;
}
.fixed_nav .dis-right .nav-btn {
  display: inline-block;
  padding: 2px 12px;
  border: 1px solid rgb(255, 76, 28);
  color: rgb(255, 76, 28);
  border-radius: 4px;
}
.footer_wrapper {
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 3rem;
  box-sizing: border-box;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #eee;
  z-index: 1;
}

.footer_wrapper .footer_nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.footer_wrapper .footer_nav:nth-child(1) {
  width: 12%;
  flex-grow: 1;
}
.footer_wrapper .footer_nav:nth-child(2) {
  width: 12%;
  flex-grow: 1;
}
.footer_wrapper .footer_nav:nth-child(3) {
  width: 12%;
  flex-grow: 1;
}
.footer_wrapper .footer_nav:nth-child(4) {
  width: 32%;
  background-color: rgb(248, 97, 80);
  color: #fff;
  flex-grow: 1;
}
.footer_wrapper .footer_nav:nth-child(5) {
  width: 32%;
  background-color: rgb(221, 47, 36);
  color: #fff;
  flex-grow: 1;
}
.footer_wrapper .footer_nav span {
  display: inline-block;
  color: #333;
}
.footer_wrapper .footer_nav img {
  width: 1rem;
  opacity: 0.8;
}

.footer_wrapper .footer_nav_active {
  color: rgb(238, 79, 78);
}

.footer_wrapper .panel-price {
  font-size: 0.8rem;
}

.page-content .footer_hold {
  height: 6rem;
  background-color: #fff;
}

/*  */

/* 中英切换CSS */

.switch_ {
  position: fixed;
  top: 4.8rem;
  right: 0.6rem;
  z-index: 2;
  height: 1.6rem;
  width: 1.6rem;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #fff;
  border-radius: 50%;
}
.switch_ .sw-item_ {
  display: inline-block;
  position: relative;
  width: 100%;
}
.switch_ .sw-item_ img {
  width: 100%;
}

.ps-about {
  display: inline-block;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.5rem;
  margin-top: 2%;
  border-bottom: 1px solid #f5f5f5;
}
.ps-about span {
  display: block;
  padding: 10px 0px 10px 0px;
  font-size: 1rem;
}
.ps-audio {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #f5f5f5;
}
.ps-audio .psa-image {
  position: relative;
  width: 15%;
  float: left;
}
.ps-audio .psa-image img {
  width: 30px;
  height: 30px;
  margin-left: 15%;
}
.ps-audio .psa-time {
  position: relative;
  width: 70%;
  float: left;
}
.ps-audio .psa-time span {
  display: block;
  padding: 8px 0px 10px 0px;
  font-size: 0.8rem;
}
.ps-audio .psa-play-image {
  position: relative;
  width: 10%;
  float: left;
}
.ps-audio .psa-play-image > .iconfont {
  font-size: 30px;
  color: rgb(255, 155, 128)
}

/* v-html */
.center_txt {
  width: 100%;
}
.center_txt >>> img {
  width: 100%;
  margin: 0.5rem 0;
  display: block;
}
.center_txt >>> .MsoNormal {
  text-indent: 2em !important;
  line-height: 1.8;
  color: #333;
}
.poster-img {
  width: 100%;
}
.poster-img img {
  width: 100%;
}
/* 拼单信息 */
.spell_info {
  background-color: #ddd;
  box-sizing: border-box;
  padding: 0.2rem 0;
}
.spell_info .spell_header {
  width: 100%;
  height: 1.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.5rem;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}
.spell_info .spell_header .spell_show_more {
  display: flex;
  align-items: center;
  color: #999;
}
.spell_info .spell_header .spell_show_more > .iconfont {
  font-size: 1rem;
  color: #999;
}
.spell_info .spell_wheel {
  position: relative;
  overflow: hidden;
}
.spell_info .spell_wheel .spell_wrapper {
  position: absolute;
  width: 100%;
}
.spell_info .spell_wrapper .spell_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.5rem;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  height: 2.4rem;
}
.spell_info .spell_wrapper .spell_content .spell_user {
  display: flex;
  align-items: center;
}
.spell_info .spell_wrapper .spell_content .spell_user img {
  display: block;
  border-radius: 100%;
  width: 1.6rem;
  height: 1.6rem;
}
.spell_info .spell_wrapper .spell_content .spell_user span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 6rem;
  font-size: 0.8rem;
  margin-left: 0.4rem;
}
.spell_info .spell_wrapper .spell_content .spell_detail {
  display: flex;
  align-items: center;
}
.spell_info .spell_wrapper .spell_content .spell_detail .spell_remain {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.4rem;
}
.spell_info
  .spell_wrapper
  .spell_content
  .spell_detail
  .spell_remain
  span:nth-child(2) {
  color: #666;
}
.spell_info
  .spell_wrapper
  .spell_content
  .spell_detail
  .spell_remain
  .spell_remain_number {
  color: rgb(226, 46, 38);
}
.spell_info .spell_wrapper .spell_content .spell_detail button {
  white-space: nowrap;
  border: 1px solid rgb(226, 46, 38);
  box-sizing: border-box;
  outline: none;
  margin: 0;
  font-weight: 500;
  border-radius: 4px;
  height: 1.6rem;
  font-size: 0.8rem;
  background-color: rgb(226, 46, 38);
  color: #fff;
  padding: 0 12px;
}

/* 拼单界面 */
.page-content .spell_client {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  touch-action: none;
}
.page-content .spell_client .spell_enter {
  position: relative;
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 1.4rem 0;
}
.page-content .spell_client .spell_enter .spell_enter_close {
  position: absolute;
  right: -0.7rem;
  top: -0.7rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  background: #eee url(../../assets/img/pm_close.png) no-repeat;
  background-position: center;
  background-size: contain;
}

.page-content .spell_client .spell_enter .spell_enter_title {
  height: 1.4rem;
  line-height: 1.4rem;
  font-size: 0.85rem;
}
.page-content
  .spell_client
  .spell_enter
  .spell_enter_remain
  .spell_remain_number {
  height: 1.4rem;
  line-height: 1.4rem;
  color: rgb(226, 46, 38);
}
.page-content .spell_client .spell_enter .spell_enter_user {
  position: relative;
  margin: 0.87rem 0;
}
.page-content .spell_client .spell_enter .spell_enter_user span {
  position: absolute;
  top: -0.3rem;
  left: 0;
  display: inline-block;
  font-size: 0.6rem;
  background-color: rgb(252, 171, 48);
  border-radius: 10px;
  height: 0.9rem;
  line-height: 0.9rem;
  width: 2.2rem;
  text-align: center;
  border: 1px solid #fff;
  color: #666;
}
.page-content .spell_client .spell_enter .spell_enter_user img {
  display: inline-block;
  border-radius: 50%;
  height: 3rem;
}
.page-content .spell_client .spell_enter button {
  white-space: nowrap;
  border: 1px solid rgb(226, 46, 38);
  box-sizing: border-box;
  outline: none;
  margin: 0;
  font-weight: 500;
  border-radius: 4px;
  height: 2.4rem;
  width: 80%;
  font-size: 0.88rem;
  background-color: rgb(226, 46, 38);
  color: #fff;
}

.spell_client .spell_enter .spell_enter_remain .spell_remain_number {
  height: 1.4rem;
  line-height: 1.4rem;
  color: rgb(226, 46, 38);
}

/* 更多拼单界面 */
.page-content .spell_more_client {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
  touch-action: none;
}

.page-content .spell_more_client .spell_more_enter {
  position: relative;
  width: 80vw;
  /* height: 40vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  /* padding-bottom: 0.4rem; */
  /* overflow: hidden; */
  touch-action: none;
}
.page-content .spell_more_client .spell_more_enter .spell_more_close {
  position: absolute;
  right: -0.7rem;
  top: -0.7rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  background: #eee url(../../assets/img/pm_close.png) no-repeat;
  background-position: center;
  background-size: contain;
}

.spell_more_client .spell_more_enter .spell_more_title {
  height: 2.8rem;
  line-height: 2.8rem;
  font-size: 1rem;
  width: 100%;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  text-align: center;
}
.spell_more_client .spell_more_enter .spell_more_content {
  position: relative;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
}
.spell_more_client .spell_more_enter .spell_more_content::-webkit-scrollbar {
  display: none;
}

.spell_more_enter .spell_more_content .spell_more_list {
  width: 100%;
}
.spell_more_enter .spell_more_content .spell_more_hid {
  width: 100%;
  text-align: center;
  font-size: 0.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  color: #666;
}

.spell_more_enter .spell_more_content .spell_more_list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.6rem;
  border-top: 1px solid #eee;
  height: 3rem;
}
.spell_more_content .spell_more_list li .spell_more_user {
  display: flex;
}
.spell_more_list li .spell_more_user .spell_more_user_img {
  display: inline-block;
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.4rem;
}

.spell_more_list li .spell_more_user .spell_more_user_info {
  display: flex;
  flex-direction: column;
}
.spell_more_user .spell_more_user_info .spell_more_user_top {
  display: flex;
}
.spell_more_user .spell_more_user_info .spell_more_user_bottom {
  color: #666;
}
.spell_more_user
  .spell_more_user_info
  .spell_more_user_top
  .spell_more_user_name {
  display: inline-block;
  margin-right: 0.6rem;
  max-width: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.spell_more_user
  .spell_more_user_info
  .spell_more_user_top
  .spell_more_user_remain {
  display: inline-block;
  color: red;
}
.spell_more_content .spell_more_list li .spell_more_button {
  white-space: nowrap;
  border: 1px solid rgb(226, 46, 38);
  box-sizing: border-box;
  outline: none;
  margin: 0;
  font-weight: 500;
  border-radius: 4px;
  height: 1.6rem;
  font-size: 0.8rem;
  background-color: rgb(226, 46, 38);
  color: #fff;
  padding: 0 12px;
}

/* 选择商品 */
.goodschose-enter-active {
  animation: goods-in 0.3s ease;
}
.goodschose-leave-active {
  animation: goods-in 0.3s ease reverse;
}

@keyframes goods-in {
  0% {
    height: 0;
  }
  100% {
    height: 60vh;
  }
}

.page-content .goods_wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
  touch-action: none;
}
.page-content .goods_wrapper .goods_client {
  position: absolute;
  height: 60%;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.page-content .goods_wrapper .goods_client .goods_img {
  position: absolute;
  top: -2rem;
  left: 0.4rem;
  width: 6rem;
  height: 6rem;
  border-radius: 4px;
  background-color: #fff;
  /* padding: 0.2rem; */
  border: 0.2rem solid #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.page-content .goods_wrapper .goods_client .goods_img img {
  /* min-width: 6rem; */
  height: 6rem;
}
.page-content .goods_wrapper .goods_client .goods_close {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  background: url(../../assets/img/pm_close.png) no-repeat;
  background-size: contain;
}

.page-content .goods_wrapper .goods_client .goods_header .goods_price {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  color: rgb(177, 41, 31);
  font-size: 1.1rem;
}
.page-content .goods_wrapper .goods_client .goods_header .goods_select_show {
  margin: 0 0.2rem;
  color: rgb(1, 133, 173);
  font-size: 0.6rem;
  border-radius: 4px;
}
.page-content .goods_wrapper .goods_client .goods_header .goods_typeinfo {
  display: block;
  font-size: 0.8rem;
}
.page-content
  .goods_wrapper
  .goods_client
  .goods_header
  .goods_typeinfo
  .goods_info_name {
  margin-left: 0.5rem;
}
.page-content .goods_wrapper .goods_client .goods_header {
  height: 5rem;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
}
.page-content .goods_wrapper .goods_client .goods_header .goods_header_info {
  margin-left: 7.2rem;
}

.page-content .goods_wrapper .goods_client .goods_content {
  height: calc(60vh - 7.8rem);
  overflow-y: auto;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.page-content .goods_wrapper .goods_client .goods_content::-webkit-scrollbar {
  display: none;
}
.page-content .goods_wrapper .goods_client .goods_content .goods_type {
  margin-top: 0.4rem;
  width: 100%;
}
.page-content .goods_wrapper .goods_client .goods_content .goods_type_title {
  font-size: 0.82rem;
  margin-left: 0.4rem;
}
.page-content .goods_wrapper .goods_client .goods_content .goods_type_list {
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  padding-bottom: 0.6rem;
  width: 100%;
}
.goods_wrapper .goods_client .goods_content .goods_type_list .goods_select {
  background-color: rgb(224, 46, 37);
  color: #fff;
}

.goods_wrapper .goods_client .goods_content .goods_type_list span {
  display: inline-block;
  padding: 0.2rem 0.4rem;
  background-color: #eee;
  border-radius: 2px;
  font-size: 0.8rem;
  margin: 0.4rem;
}

.page-content .goods_wrapper .goods_client .goods_content .goods_number {
  display: flex;
  height: 2.4rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 0 0.4rem;
}
.goods_wrapper .goods_client .goods_content .goods_number .goods_number_name {
  font-size: 0.84rem;
}
.goods_wrapper .goods_client .goods_content .goods_number .goods_input_number {
  position: relative;
  display: inline-block;
  width: 6rem;
  line-height: 1.6rem;

  border-radius: 4px;
  height: 1.6rem;
  box-sizing: border-box;
}

.goods_client .goods_content .goods_number .goods_input_number > span {
  position: absolute;

  cursor: not-allowed;
  top: 0;
  width: 1.8rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  font-size: 1rem;
  z-index: 1;
  border-radius: 2px;
  background-color: rgb(226, 226, 226);
  color: #333;
  /* color: #606266; */
  cursor: pointer;
  /* font-size: 13px; */
  font-weight: 600;
}

.goods_content .goods_number .goods_input_number .goods_input_pre {
  left: 1px;
}

.goods_content .goods_number .goods_input_number .goods_input_las {
  right: 1px;
}
.goods_content .goods_number .goods_input_number span.is_disabled {
  color: #aaa;
  cursor: not-allowed;
  background-color: #eee;
}
.goods_client .goods_content .goods_number .goods_input {
  position: relative;
  font-size: 0.8rem;
  display: inline-block;
  width: 100%;
}
.goods_client .goods_content .goods_number .goods_input input {
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  display: inline-block;
  line-height: 1.6rem;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}
.goods_client .goods_content .content_bottom_hold {
  height: 3rem;
  width: 100%;
}
.page-content .goods_wrapper .goods_client .goods_submit {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.8rem;
  z-index: 2;
}
.page-content .goods_wrapper .goods_client .goods_submit button {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  border: 1px solid rgb(252, 150, 144);
  box-sizing: border-box;
  outline: none;
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  background-color: rgb(252, 150, 144);
  color: #ddd;
  text-align: center;
}
.page-content
  .goods_wrapper
  .goods_client
  .goods_submit
  button.goods_is_submit {
  border: 1px solid rgb(226, 46, 38);
  background-color: rgb(226, 46, 38);
  color: #fff;
}

/* 商户信息 */
.page-content .main_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  margin: 1.5rem 0 1rem;
  padding: 0 0.5rem;
}
.page-content .main_title h2 {
  width: 100%;
  display: flex;
  margin: 0.4rem 0;
  align-items: center;
}
.page-content .main_title h2 .main_title_value {
  font-size: 18px;
  color: #333;
  max-width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-content .main_title h2 .main_spots_tag {
  margin-left: 10px;
}
.page-content .main_title h2 .main_spots_tag .tag_name {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  background-color: #999;
  padding: 0 4px;
}

/*********VR**********/

.page-content .main_title .show-vr{
  width: 100%;
  margin-top: 3%;
}

.page-content .main_title .show-vr .vr{
  width: 100%;
  height: 30%;
}
.page-content .main_title .show-vr .vr .vr-mask{
  position: absolute;
    z-index: 2;
    width: 95%;
    background-color: rgba(0,0,0,0);
    height: 14%;
}


/* 地址栏 */
.page-content .main_title_info {
  margin: 0.2rem 0;
  display: flex;
  width: 100%;
  align-items: center;
}
.page-content .main_title_info i {
  color: #999;
  font-size: 0.68rem;
  margin-right: 2px;
}
.page-content .main_title_info .title_info_name {
  color: #666;
  /* width: 20%; */
  white-space: nowrap;
}
.page-content .main_title_info .title_info_value {
  color: #333;
  margin-left: 10px;
  width: 100%;
  display: inline-flex;
  overflow: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-content .main_title_info .title_info_value::-webkit-scrollbar {
  display: none;
}
.page-content .main_title_info .tel_value {
  color: #576b95;
  width: auto;
}
.page-content .main_title_info .address_value {
  color: #576b95;
}


.page-content .main_shop_tag {
  position: relative;
  width: 100%;

  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 4px;
}
.page-content .main_shop_tag::-webkit-scrollbar {
  display: none;
}
.page-content .main_shop_tag .tag_wrapper {
  display: inline-flex;
  white-space: nowrap;
}
.page-content .main_shop_tag .tag_name {
  display: inline-block;
  padding: 2px 6px;
  font-size: 12px;
  margin-right: 0.5rem;
  background-color: rgba(240, 139, 64, 0.42);
  color: rgb(143, 37, 0);
}

/* 二维码 */
.page-content .header_qr_code {
  position: absolute;
  width: 2rem;
  height: 2rem;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: #fff;
  z-index: 1;
  animation: qr_code_bg 3s linear infinite alternate;
}
.page-content .header_qr_code:nth-child(1) {
  right: 3rem;
}
@keyframes qr_code_bg {
  0% {
    background: transparent;
  }

  100% {
    background: #fff;
  }
}
.page-content .header_qr_code .qr_code_icon {
  width: 100%;
  height: 100%;
}

/* 二维码图片 */
.act_qr_code {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
}
.act_qr_code .qr_code_img {
  width: 60%;
}
.act_qr_code p {
  color: #fff;
  margin-top: 0.5rem;
  font-size: 0.8rem;
}
.qr_code-enter,
.qr_code-leave-to {
  opacity: 0;
}
.qr_code-enter-to,
.qr_code-leave {
  opacity: 1;
}
.qr_code-enter-active,
.qr_code-leave-active {
  transition: ease 0.3s;
}

/* 用户获取的折扣 */
.page-content .user_sale {
  width: 100%;
  background-color: transparent;
  padding: 0 3vw;
  box-sizing: border-box;
  margin-top: 1rem;
}
.page-content .user_sale .user_sale_wrapper {
  position: relative;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: gold;
}
.page-content .user_sale .user_sale_wrapper::before {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% + 10px);
  top: -5px;
  left: 10px;
  box-sizing: border-box;
  background-color: transparent;
  border-top: 10px dotted #fff;
  border-bottom: 10px dotted #fff;
}

.page-content .user_sale .user_sale_wrapper .main_content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page-content .user_sale .main_content .user_sale_box .user_sale_code {
  font-size: 16px;
  font-weight: 600;
  color: rgb(105, 56, 1);
}
.page-content .user_sale .user_sale_box .user_sale_time {
  font-size: 12px;
  color: #999;
}
.page-content .user_sale .user_sale_wrapper .user_sale_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-content .user_sale .user_sale_wrapper .main_content .user_sale_content {
  width: 100%;
  color: #666;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.page-content .user_sale .user_sale_box .sale_user_nick {
  color: rgb(165, 89, 3);
}
.page-content .user_sale .user_sale_box .user_sale_item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page-content .user_sale .main_content .user_sale_icon {
  width: 3rem;
  border-radius: 50%;
  margin-right: 10px;
}

.page-content .user_sale .main_content .sale_user_sale_num {
  padding: 0 2px;
  color: #e33;
  font-size: 20px;
  font-weight: 600;
}
.page-content .user_sale .sale_user_nick {
  margin-right: 10px;
}
.page-content .user_sale_box .user_sale_preview {
  height: 4rem;
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
}
.page-content .user_sale_box .user_sale_preview img {
  height: 100%;
}
/* 折扣展示 */
/* 底部 */
.show-sale {
  position: relative;
  width: 100%;
  background-color: transparent;
  padding: 0 0.5rem 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.show-sale .finger {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
  z-index: 1;
  overflow: hidden;
}

.show-sale .finger > img {
  height: 100%;
  transform: translateX(0);
  animation: finger 1s infinite steps(2);
}

@keyframes finger {
  to {
    transform: translateX(-100%);
  }
}

.show-sale .show-title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
}
.show-sale .show-title .title_line {
  width: 40%;
  height: 1px;
  background: #eee;
}
.show-sale .show-title .title_text {
  display: block;
  position: absolute;
  font-size: 0.8rem;
  height: 40px;
  line-height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #576b95;
  background-color: #fff;
  padding: 0 6px;
}

.show-sale .show-content {
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0.4rem 0;
}

.show-sale .show-content::-webkit-scrollbar {
  display: none;
}

.show-sale .sale-list {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
}

.show-sale .sale-item {
  position: relative;
  box-sizing: border-box;
}

.show-sale .sale-item:not(:last-child) {
  margin-right: 0.5rem;
}

.show-sale .item .name {
  position: relative;
  display: block;
  height: 1.4rem;
  line-height: 1.4rem;
  width: 100%;
  text-align: center;
  color: #666;
  font-size: 0.68rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.show-sale .item .value {
  position: relative;
  width: 4rem;
  height: 4rem;
  color: rgb(255, 234, 138);
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(244, 69, 69);
  border-radius: 4px;
  box-sizing: border-box;
}
.show-sale .prize img {
  position: relative;
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 4px;
}
.show-sale .value::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 2px 2px inset rgb(250, 211, 100);
  z-index: 1;
}

/* 点位信息 */
.track_list {
  margin: 1rem 0;
  padding-bottom: 1rem;
}
.track_list .center_txt >>> p {
  font-size: 0.7rem;
  text-indent: 2em;
  line-height: 1.8;
  color: #333;
  /* text-align: justify; */
  word-wrap: break-word;
}

.PrimaryScenic .root_list {
  width: 100%;
  /* background-color: rgb(238,241,243); */
  box-sizing: border-box;
  padding: 0.5rem;
}
.PrimaryScenic .root_list .root_list-title {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: 0.5rem;
}
.PrimaryScenic .root_list .root_list-title .title_line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 1px;
  background-color: #ccc;
  transform: translateX(-50%);
  z-index: -1;
}
.PrimaryScenic .root_list .root_list-title .title_name {
  color: #333;
  font-size: 0.88rem;
  font-weight: 600;
  background-color: #fff;
  padding: 0 4px;
}
.PrimaryScenic .root_list .root_list_item {
  width: 100%;
  height: 150px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 0 6px #ccc;
}
.PrimaryScenic .root_list .root_list_item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.PrimaryScenic .root_list .root_list_item img {
  width: 100%;
  height: 100%;
  display: block;
}
.PrimaryScenic .root_list .root_list_item .item_name {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 4px 6px;
  box-sizing: border-box;
}
.PrimaryScenic .root_list .root_list_item .item_type {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(5, 5, 5, 0.5);
  color: #fff;
  padding: 4px 6px;
  box-sizing: border-box;
  border-radius: 4px;
}

/* 二级页面 我的 */
.fade-enter,
.fade-leave-to {
  transform: translateY(100%);
}
.fade-enter-to,
.fade-leave {
  transform: translateY(0);
}
.fade-enter-active,
.fade-leave-active {
  transition: ease 0.3s;
}
.spots-my {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}
.spots-my .my-wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(238, 241, 243);
}
.close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.close i {
  font-size: 1.4rem;
  color: #00000099;
}

.my-header {
  position: relative;
  /* height: 6rem; */
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #00000011;
}

.my-header .my-header-user {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 1rem 2.5rem 1rem 1rem;
  box-sizing: border-box;
}
.my-header .my-header-user img {
  width: 3rem;
  width: 3rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.my-header .my-header-user p {
  height: 100%;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.my-header .my-header-user p .my-header-nick {
  color: #333;
  font-size: 0.88rem;
  font-weight: 600;
}

.my-header .my-header-user p .my-header-score span {
  display: inline-block;
  color: #cf0606;
  padding: 2px 10px;
  font-size: 0.66rem;
  border-radius: 1em;
  background-color: #cf060622;
}

.my-content {
  width: 100%;
  margin-top: 0.5rem;
}

.content-item {
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  height: 2.4rem;
  line-height: 2.4rem;
  display: flex;
  background-color: #fff;
  font-size: 0.7rem;
}
.content-item .item-left {
  width: 2rem;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
}
.content-item .item-left i {
  font-size: 1.2rem;
  color: rgb(7, 116, 160);
}
.content-item .item-right {
  width: calc(100% - 2rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00000011;
}
.content-item:last-child .item-right {
  border-bottom: none;
}
.content-item .item-right i {
  font-size: 1rem;
  color: #999;
}
.my-content-spots .content-item .item-left i {
  color: rgb(161, 4, 4);
}
/* 20191103 活动 */
.ps-activity {
  margin: 1rem 0;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
}
.ps-activity img {
  width: 100%;
  border-radius: 0.4rem;
}
.advert {
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
}

.advert .advert-wrapper {
  display: block;
  width: 100%;
}
.advert .advert-image img {
  display: block;
  width: 100%;
  border-radius: 0.4rem;
}
.advert .advert-wrapper video {
  display: block;
  width: 100%;
}

/* 下载桌贴 */
.side-download {
  position: fixed;
  right: 0.6rem;
  bottom: 10rem;
  height: 1.6rem;
  width: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.side-download > .iconfont {
  font-size: 1rem;
  color: #fff;
}

.download-url {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  z-index: 3;
}
.download-url .img {
  width: 100%;
}
.download-url .download {
  margin-top: 2rem;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #fff;
}
/* 进入地图 */
.function-btn {
  width: 100%;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  border: 1px solid #f2f2f2;
}

.function-btn > .btn {
  width: 100%;
  padding: 0.6rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  text-align: left;
  font-size: 0.8rem;
  color: #333;
  background-color: #fff;
  cursor: pointer;
}

.function-btn > .btn:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}

.function-btn > .btn > .iconfont {
  color: #aaa;
  font-size: 0.8rem;
}

/* 分享排行页面 */
.slide-down-enter,
.slide-down-leave-to {
  transform: translateY(-100%);
}
.slide-down-enter-to,
.slide-down-leave {
  transform: translateY(0);
}
.slide-down-enter-active,
.slide-down-leave-active {
  transition: 0.3s;
}

.share-rank-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(244, 245, 247);
  overflow-y: auto;
  z-index: 1000;
}

.share-rank-box > .close {
  position: fixed;
  top: 0.6rem;
  left: 0.6rem;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  background-color: rgba(255, 255, 255, 0.4);
}

.share-rank-box > .close > .iconfont {
  vertical-align: middle;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.4);
}

.share-rank-box > .banner {
  height: 5.6rem;
  padding: 0 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(rgb(255, 155, 105), rgb(255, 205, 145));
}

.share-rank-box > .banner > .banner-title {
  padding-bottom: 0.2rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  border-bottom: 1px solid #fff;
}

.share-rank-box > .banner > .banner-icon > .iconfont {
  font-size: 4rem;
  color: rgba(255, 255, 255, 0.3);
}

.share-rank-box > .self-share {
  width: 100%;
  display: flex;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;
}

.share-rank-box > .self-share > .left {
  margin-right: 0.5rem;
}

.share-rank-box > .self-share > .middle {
  width: calc(100% - 7.2rem);
}

.self-share > .left > .icon {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
}

.self-share > .middle > .nick {
  font-size: 0.9rem;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.self-share > .middle > .tag {
  margin-top: 2px;
}

.self-share > .middle > .tag > .text {
  display: inline-block;
  padding: 1px 2px;
  /* background-color: rgb(255, 155, 105); */
  border: 1px solid rgb(255, 155, 105);
  color: rgb(255, 155, 105);
  border-radius: 2px;
  font-size: 11px;
}

.self-share > .middle > .tip {
  margin-top: 4px;
  font-size: 0.74rem;
  color: #999;
}

.self-share > .middle > .tip > .num {
  color: rgb(240, 122, 53);
}

.self-share > .middle > .self-list {
  margin-top: 4px;
  display: inline-flex;
  width: 100%;
  overflow-x: auto;
}

.self-list::-webkit-scrollbar {
  display: none;
}

.self-list > .list {
  display: inline-flex;
}

.self-list > .list > .item {
  width: 1rem;
  height: 1rem;
  margin-right: 2px;
  margin-top: 2px;
}

.self-list > .list > .item > .icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.self-share > .right {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}

.self-share > .right > .btn {
  width: 4rem;
  height: 1.6rem;
  border-radius: 2em;
  font-size: 0.74rem;
  color: #fff;
  background-color: rgb(26, 173, 22);
}

.share-rank-box > .share-list {
  width: 100%;
  margin-top: 0.5rem;
  background-color: #fff;

  box-sizing: border-box;
}

.share-list > .item {
  position: relative;
  width: 100%;
  display: flex;
  padding: 0.5rem;
  box-sizing: border-box;
  /* border-bottom: 1px solid transparent; */
  /* border-image: 100% 0 0 linear-gradient(90deg, transparent 3.6rem, #eee 0); */
}

.share-list > .item:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0.5rem;
  width: 80%;
  height: 0;
  background-color: transparent;
  border-bottom: 1px solid #eee;
}

.share-list > .item > .left {
  margin-right: 0.5rem;
}

.share-list > .item > .left > .icon {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
}

.share-list > .item > .middle {
  flex: 1;
}

.share-list > .item > .middle > .nick {
  font-size: 0.9rem;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.share-list > .item > .middle > .tip {
  margin-top: 4px;
  font-size: 0.74rem;
  color: #999;
}

.share-list > .item > .middle > .tip > .num {
  color: rgb(240, 122, 53);
}

.share-list > .item > .right {
  margin-left: 0.5rem;
}

.share-list > .item > .right > .rank {
  width: 1.7rem;
  height: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: #81888f;
  border: 4px solid rgba(255, 255, 255, 0.6);
}

.share-list > .item:nth-child(1) > .right > .rank {
  background-color: rgb(255, 110, 117);
}
.share-list > .item:nth-child(2) > .right > .rank {
  background-color: rgb(255, 197, 91);
}
.share-list > .item:nth-child(3) > .right > .rank {
  background-color: rgb(111, 189, 255);
}

/* 提示分享 */
.tip-share {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
}

.tip-share > .text {
  width: 100%;
  margin-top: 130px;
  text-align: center;
  font-size: 0.8rem;
  color: #fff;
}

.tip-share > .img {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
}

/* 个人分享列表 */
.sub-share-box {
  position: fixed;
  top: -10vh;
  left: -10vw;
  width: 120vw;
  height: 120vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
}

.sub-share-wrapper {
  width: 80vw;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.sub-share-wrapper > .header {
  position: relative;
  height: 2.6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, .3);
  background: linear-gradient(rgb(255, 155, 105), rgb(255, 205, 145));
}

.sub-share-wrapper > .header > .title {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.sub-share-wrapper > .header > .iconfont {
  position: absolute;
  right: 0;
  top: 0;
  width: 2.4rem;
  height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

.sub-share-list {
  min-height: 20vh;
  max-height: 60vh;
  padding: 0.5rem;
  box-sizing: border-box;
  overflow-y: auto;
}

.sub-share-list > .item {
  position: relative;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.sub-share-list > .item:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 3rem);
  height: 0;
  border-bottom: 1px solid #eee;
}

.sub-share-list .item-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.sub-share-list .item-icon > .icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.sub-share-list .item-content {
  width: calc(100% - 2.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub-share-list .item-content > .nick {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333;
  font-size: .8rem;
}

.sub-share-list .item-content > .info {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}

.sub-share-list .item-content > .info > .time {
  font-size: .7rem;
  color: #999;
}

.sub-share-list .item-content > .info > .num {
  padding: 1px 2px;
  /* background-color: rgb(255, 130, 110); */
  font-size: 11px;
  color: rgb(255, 130, 110);
  border: 1px solid rgba(255, 130, 110, .6);
  border-radius: 2px;
}

/* 微信二维码 */
.qrcode-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* 天气信息 */
.weather-box {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}




  /**
  红包显示数据
   */
   .statistics_percentage{
    width: 100%;
    box-sizing: border-box;

     margin-top: 1%;
     background-color: white;
     margin-bottom: 1%;
  }
  .statistics_percentage .statistics_name{
    display: block;
    padding: 0.2rem 0.1rem 0.4rem 0.1rem;
    color: #999;
    font-size: 0.9rem;
  }
  .statistics_percentage .numbers_percentage_outer {
  display: inline-block;
  width: 100%;
  height: 0.6rem;
  background-color: rgba(252, 200, 200, 0.5);
  border-radius: 2em;
}
 .numbers_percentage_outer .numbers_percentage_inner {
  display: block;
  width: 98%;
  height: 100%;
  background-color: rgb(248, 85, 85);
  border-radius: 2em;
  color: #fff;
}
</style>
