<template>
  <div class="ParkPhoto" v-title :data-title="'专题-' + title_name ">
    <!--v-if="is_weixin"-->
    <!-- 大图 -->
    <div class="index-banner" v-if="info.have_banner==1">
      <router-link :to="{path:'/activity/ActivityDetail',query:{aid:info.banner.id}}">
        <img :src="info.banner.banner_img_app" alt />
      </router-link>
    </div>
    <!-- 公园 数据 -->
    <div class="index-info" v-if="!is_no_park">
      <div class="btn_box">
        <router-link
          class="btn"
          :to="{path:'/ParkPhoto/RankList',query:{aid:aid,show_type:show_type}}"
        >排行榜</router-link>
        <div class="btn rank">
          <span>活动于</span>
          <div>
            <p>
              <span>{{m_time.slice(0, 4)}}</span>年
              <span>{{m_time.slice(4, 6)}}</span>月
              <span>{{m_time.slice(6,8)}}</span>日
            </p>
            <p>
              <span>24</span>时
              <span>00</span>分
              <span>00</span>秒
            </p>
          </div>
          <span>结束</span>
        </div>
        <a class="btn" href="javascript:;" @click.prevent="rule=true">投票规则</a>
      </div>
      <ul class="box clearfix">
        <li>
          <p>参与公园数</p>
          <span>{{info.join_park}}</span>
        </li>
        <li>
          <p>足迹数</p>
          <span>{{info.spots_numbers}}</span>
        </li>
        <li>
          <p>图片数</p>
          <span>{{info.img_numbers}}</span>
        </li>
        <li>
          <p>投票数</p>
          <span>{{info.vote_numbers}}</span>
          <!--<span class="days">00</span>天<span class="hours">00</span>时<span class="minutes">00</span>分<span class="seconds">00</span>秒-->
        </li>
      </ul>
    </div>
    <!--非公园 数据-->
    <div class="index-info" v-if="is_no_park">
      <div class="btn_box" v-if="show_head">
        <router-link
          class="btn"
          :to="{path:'/ParkPhoto/RankList',query:{aid:aid,show_type:show_type}}"
        >排行榜</router-link>
        <!--<a class="btn rank" v-if="is_no_park"><i class="icon-toupiao iconfont"></i>投票未开始</a>-->
        <div class="btn rank">
          <span>活动于</span>
          <div>
            <p>
              <span>{{m_time.slice(0, 4)}}</span>年
              <span>{{m_time.slice(4, 6)}}</span>月
              <span>{{m_time.slice(6,8)}}</span>日
            </p>
            <p>
              <span>24</span>时
              <span>00</span>分
              <span>00</span>秒
            </p>
          </div>
          <span>结束</span>
        </div>
        <a class="btn" href="javascript:;" @click.prevent="rule=true">投票规则</a>
      </div>
      <ul class="box clearfix">
        <li v-if="show_restaurant_numbers">
          <p>餐馆</p>
          <span>{{info.restaurant_numbers}}</span>
        </li>
        <li v-if="show_person_numbers">
          <p>人数</p>
          <span>{{info.human_numbers}}</span>
          <!--<span class="days">00</span>天<span class="hours">00</span>时<span class="minutes">00</span>分<span class="seconds">00</span>秒-->
        </li>
        <li v-if="show_spots_numbers">
          <p>作品</p>
          <span>{{info.opus_numbers}}</span>
        </li>
        <li v-if="show_img_numbers">
          <p>图片</p>
          <span>{{info.img_numbers}}</span>
        </li>
        <li v-if="show_vote_numbers">
          <p>投票</p>
          <span>{{info.vote_numbers}}</span>
        </li>
        <li v-if="show_park_numbers">
          <p>自驾游协会</p>
          <span>{{info.park_numbers}}</span>
        </li>
      </ul>
      <!--  -->

      <!--20180319 动态显示菜单-->
      <ul class="LineList_list clearfix" v-if="go_park_id">
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:19,aid:aid,park_name:park_name}}"
          class="jingdian"
          v-if="show_btn_spots"
        >
          <img src="../../assets/img/change_btn_86_img.jpg" alt v-if="change_btn_86_img" />
          <img src="../../assets/img/park_button_scene.png" alt v-else />
          <p>景点</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:18,aid:aid,park_name:park_name}}"
          class="chifan"
          v-if="show_btn_food"
        >
          <img src="../../assets/img/change_btn_86_img.jpg" alt v-if="change_btn_86_img" />
          <img src="../../assets/img/park_button_eat.png" alt v-else />
          <p>美食</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:21,aid:aid,park_name:park_name}}"
          class="zhuzhi"
          v-if="show_btn_stay"
        >
          <img src="../../assets/img/change_btn_86_img.jpg" alt v-if="change_btn_86_img" />
          <img src="../../assets/img/park_button_ceas.png" alt v-else />
          <p>住宿</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:20,aid:aid,park_name:park_name}}"
          class="gouwuche-copy"
          v-if="show_btn_shop"
        >
          <img src="../../assets/img/change_btn_86_img.jpg" alt v-if="change_btn_86_img" />
          <img src="../../assets/img/park_button_buy.png" alt v-else />
          <p>购物</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:'',aid:aid,park_name:park_name}}"
          class="youji"
          v-if="show_btn_notes"
        >
          <img src="../../assets/img/change_btn_86_img.jpg" alt v-if="change_btn_86_img" />
          <img src="../../assets/img/park_button_note.png" alt v-else />
          <p>游记</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:'',aid:aid,park_name:park_name}}"
          class="zuji"
          v-if="show_btn_foot"
        >
          <img src="../../assets/img/change_btn_86_img.jpg" alt v-if="change_btn_86_img" />
          <img src="../../assets/img/park_button_foot.png" alt v-else />
          <p>足迹</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:'',aid:aid,park_name:park_name}}"
          class="huodong"
          v-if="show_btn_activity"
        >
          <img src="../../assets/img/change_btn_86_img.jpg" alt v-if="change_btn_86_img" />
          <img src="../../assets/img/park_button_guide.png" alt v-else />
          <p>活动</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:17,aid:aid,park_name:park_name}}"
          class="cesuo"
          v-if="show_btn_wc"
        >
          <img src="../../assets/img/change_btn_86_img.jpg" alt v-if="change_btn_86_img" />
          <img src="../../assets/img/park_button_cesuo.png" alt v-else />
          <p>厕所</p>
        </router-link>
        <router-link
          tag="li"
          :to="{path:'/parkPhoto/ParkMapLineList',query:{park_id:go_park_id,select_type:'',aid:aid,park_name:park_name}}"
          class="cesuo"
          v-if="show_btn_root_books"
        >
          <img src="../../assets/img/change_btn_86_img.jpg" alt v-if="change_btn_86_img" />
          <img src="../../assets/img/line.png" alt v-else />
          <p>路书</p>
        </router-link>
      </ul>
    </div>
    <div v-if="!is_no_park" class="active_user_box ParkPhotoListDetail">
      <div class="who_vote">
        <div class="row">
          <div class="time">活跃用户</div>
          <div class="list">
            <router-link
              :to="{name:'otherIndex',params:{uid:k.user_id}}"
              class="num_box"
              v-for="(k,i) in info.user_list"
              :key="k.user_id"
            >
              <img :src="k.user_icon" />
              <div class="num">
                <i class="iconfont icon-zuji"></i>
                {{k.spots_numbers}}
              </div>
              <span v-if="i<3">NO.{{i + 1}}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索 -->
    <div class="index-search">
      <div class="search-box">
        <div>
          <input type="text" :placeholder="key_word_info" v-model="key_word" />
          <button type="submit" @click="search">搜索</button>
        </div>
        <router-link
          v-if="is_go_map"
          class="all-photo"
          :to="{path:'/parkPhoto/ActivityMap',query:{aid:aid}}"
        >进入地图</router-link>
        <router-link
          class="all-photo"
          :to="{path:'/parkPhoto/PhotoList',query:{aid:aid}}"
          v-if="is_vote"
        >全部作品</router-link>
      </div>
    </div>
    <!-- 公园列表 -->
    <div v-if="!is_no_park" class="PhotoList">
      <div class="park-list" v-if="park_list">
        <router-link
          :to="{path:'/parkPhoto/PhotoList',query:{'park_id':k.id,aid:aid,banner_img:info.banner.banner_img_app,park_name:k.park_name}}"
          class="item"
          v-for="k in park_list"
          :key="k.id"
        >
          <a href="photo_list.html">
            <div class="count">
              <span>
                <i class="iconfont icon-zuji"></i>
                {{k.img_numbers}}
              </span>
            </div>
            <img class="pic" :src="k.img || k.thumb_img_3" alt />
          </a>
          <div class="name">{{k.park_name}}</div>
        </router-link>
      </div>
      <div class="page-tips" v-else>请输入关键字</div>
    </div>
    <!-- 作品列表 -->
    <mt-loadmore
      :bottom-method="loadBottom"
      :bottom-all-loaded="allLoaded"
      ref="loadmore"
      :auto-fill="false"
    >
      <div class="photo-list">
        <div class="col">
          <div class="item" v-for="(k,i) in info_1" :key="i">
            <div class="num">
              <span>{{k.id}}号</span>
            </div>
            <router-link
              :to="{path:'/parkPhoto/detail',query:{id:k.id,aid:aid,park_id:park_id,park_name:park_name,go_vote:1}}"
              v-if="!k.go_root_books && is_vote"
            >
              <img class="pic" :src="k.newUrl" />
            </router-link>
            <!--跳转进入足迹详情-->
            <router-link
              :to="{path:'/footprints/FootprintsParticulars',query: {user_id: k.user_id, id: k.id,popular_type_fk_id:aid}}"
              v-if="!is_vote"
            >
              <img class="pic" :src="k.newUrl" />
            </router-link>
            <router-link
              :to="{path:'/footprints/LineInfoList',query:{label_id:k.root_id,show_vote:1,bShow:k.show,bVote:k.vote,spot_id:k.id,aid:aid,user_id:k.user_id}}"
              v-if="k.go_root_books"
            >
              <img class="pic" :src="k.newUrl" />
            </router-link>
            <div class="img_num">
              <span>共{{k.img_numbers}}张</span>
            </div>
            <div class="u-card" v-if="k.type != 1">
              <router-link
                :to="{path:'/parkPhoto/PhotoList',query:{photo_all:1,aid:aid,user_id:k.user_id}}"
              >
                <img class="user_icon" :src="k.user_icon" />
              </router-link>
              <h1>{{k.user_nick}}</h1>
              <h2 v-if="is_vote">{{k.numbers}}票</h2>
              <h2 v-else>{{k.numbers}}条评论</h2>
              <div @click.stop.prevent="map_open_(k.lng,k.lat,k.title,k.address)">
                <!--:to="{path:'/parkPhoto/ActivityMap',-->
                <!--query:{aid:aid,-->
                <!--production_id:k.id,-->
                <!--type:k.type,-->
                <!--spots_name:k.spots_name,-->
                <!--lng:k.lng,-->
                <!--lat:k.lat}}"-->
                <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
              </div>
            </div>
            <div class="u-card" v-if="k.type == 1" :class="{no_park:k.type == 1}">
              <h1>{{k.spots_name}}</h1>
              <h2 v-if="is_vote">{{k.numbers}}票</h2>
              <h2 v-else>{{k.numbers}}条评论</h2>
              <div
                @click.stop.prevent="map_open_(k.lng,k.lat,k.title,k.address)"
                v-if="is_show_nav"
              >
                <!--:to="{path:'/parkPhoto/ActivityMap',-->
                <!--query:{aid:aid,-->
                <!--production_id:k.id,-->
                <!--type:k.type,-->
                <!--spots_name:k.spots_name,-->
                <!--lng:k.lng,-->
                <!--lat:k.lat}}"-->
                <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
              </div>
            </div>
            <div
              class="vote"
              v-if="is_weixin && is_vote"
              @click="vote(k)"
              :class="{'no': (k.show == 0)|| (k.show == 1 && k.vote == 1) || (k.show == 3) }"
            >
              <span v-if="k.show == 0">投票未开始</span>
              <span v-else-if="k.show == 1 && k.vote == 0">我要投票</span>
              <span v-else-if="k.show == 1 && k.vote == 1">已投票</span>
              <span v-if="k.show == 3">投票已结束</span>
            </div>
            <div class="vote" v-else-if="is_vote" :class="{'no': 1}">
              <span>微信中投票</span>
            </div>
            <div class="vote" v-else :class="{'no': 1}">
              <span>点击评论</span>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="item" v-for="(k,i) in info_2" :key="i">
            <div class="num">
              <span>{{k.id}}号</span>
            </div>
            <router-link
              :to="{path:'/parkPhoto/detail',query:{id:k.id,aid:aid,park_id:park_id,park_name:park_name,go_vote:1}}"
              v-if="!k.go_root_books && is_vote"
            >
              <img class="pic" :src="k.newUrl" />
            </router-link>
            <!--跳转进入足迹详情-->
            <router-link
              :to="{path:'/footprints/FootprintsParticulars',query: {user_id: k.user_id, id: k.id,popular_type_fk_id:aid}}"
              v-if="!is_vote"
            >
              <img class="pic" :src="k.newUrl" />
            </router-link>
            <router-link
              :to="{path:'/footprints/LineInfoList',query:{label_id:k.root_id,show_vote:1,bShow:k.show,bVote:k.vote,spot_id:k.id,aid:aid,user_id:k.user_id}}"
              v-if="k.go_root_books"
            >
              <img class="pic" :src="k.newUrl" />
            </router-link>

            <div class="img_num">
              <span>共{{k.img_numbers}}张</span>
            </div>
            <div class="u-card" v-if="k.type != 1">
              <router-link
                :to="{path:'/parkPhoto/PhotoList',query:{photo_all:1,aid:aid,user_id:k.user_id}}"
              >
                <img class="user_icon" :src="k.user_icon" />
              </router-link>
              <h1>{{k.user_nick}}</h1>
              <h2 v-if="is_vote">{{k.numbers}}票</h2>
              <h2 v-else>{{k.numbers}}条评论</h2>
              <div
                @click.stop.prevent="map_open_(k.lng,k.lat,k.title,k.address)"
                v-if="is_show_nav"
              >
                <!--:to="{path:'/parkPhoto/ActivityMap',-->
                <!--query:{aid:aid,-->
                <!--production_id:k.id,-->
                <!--type:k.type,-->
                <!--spots_name:k.spots_name,-->
                <!--lng:k.lng,-->
                <!--lat:k.lat}}"-->
                <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
              </div>
            </div>
            <div class="u-card" v-if="k.type == 1" :class="{no_park:k.type == 1}">
              <h1>{{k.spots_name}}</h1>
              <h2 v-if="is_vote">{{k.numbers}}票</h2>
              <h2 v-else>{{k.numbers}}条评论</h2>
              <div
                @click.stop.prevent="map_open_(k.lng,k.lat,k.title,k.address)"
                v-if="is_show_nav"
              >
                <!--:to="{path:'/parkPhoto/ActivityMap',-->
                <!--query:{aid:aid,-->
                <!--production_id:k.id,-->
                <!--type:k.type,-->
                <!--spots_name:k.spots_name,-->
                <!--lng:k.lng,-->
                <!--lat:k.lat}}"-->
                <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
              </div>
            </div>
            <div
              class="vote"
              v-if="is_weixin && is_vote"
              @click="vote(k)"
              :class="{'no': (k.show == 0)|| (k.show == 1 && k.vote == 1) || (k.show == 3) }"
            >
              <span v-if="k.show == 0">投票未开始</span>
              <span v-else-if="k.show == 1 && k.vote == 0">我要投票</span>
              <span v-else-if="k.show == 1 && k.vote == 1">已投票</span>
              <span v-if="k.show == 3">投票已结束</span>
            </div>
            <div class="vote" v-else-if="is_vote" :class="{'no': 1 }">
              <span>微信中投票</span>
            </div>
            <div class="vote" v-else :class="{'no': 1 }">
              <span>点击评论</span>
            </div>
          </div>
        </div>
      </div>
    </mt-loadmore>
    <!--点击加载更多按钮-->
    <div class="click-load-more" @click="click_load_more" v-if="load_more == 1 ">
      <div class="load-more-line"></div>
      <div class="load-more-text">点击加载更多</div>
    </div>
    <!-- 红包雨 -->
    <red-packet
      :redPacketList="info.red_packet_rain_image_url_list"
      v-if="info.have_red_packet_rain"
      id_type_="photo"
      :red_packet_type_="info.red_packet_type_"
      :red_packet_image_url="info.red_packet_image_url"
    ></red-packet>
    <!-- 访问信息 -->
    <user-list :language="1" :info="info" id_type_="photo"></user-list>
    <!-- 底部数据 -->

    <!-- 注意事项 - 弹出层 -->
    <div class="popups apply" id="notice" v-if="notice">
      <div class="popups-bg"></div>
      <div class="popups-box">
        <div class="close" @click="notice=false">x</div>
        <div class="text">
          <h1>注意事项</h1>
          <p>入围作品公布时间:7月3号</p>
          <p>活动允许选手通过亲朋好友进行拉票，点击微信右上角“分享”将该链接转发给好友/群，分享到朋友圈，可标记参赛序号进行宣传，为您的摄影作品拉票；每天每位投票者只能投一票，直至投票结束，累计投票；</p>
          <p>主办方委托相关领域专家从初入选作品中评出一、二、三等奖和优秀奖；</p>
          <p>奖项设置：</p>
          <ul>
            <li>一等奖2名（奖金3000并颁发证书）</li>
            <li>二等奖3名（奖金1500并颁发证书）</li>
            <li>三等级5名（奖金800并颁发证书）</li>
            <li>优秀奖若干名（颁发证书及纪念品）</li>
          </ul>
          <br />
          <p>作品要求：</p>
          <ol>
            <li>摄影作品必须围绕黄冈大别山的地质地貌景观，反映当地人文历史，民风民俗及因黄冈大别山地质公园创建工作而发生村落变化、人物风貌等观赏性、科普性和艺术性强的内容。</li>
            <li>作品彩色、黑白不限；</li>
            <li>拍摄时间为2016年1月1日起的原创照片，画面上不允许添加任何文字标记及LOGO、不得加修饰框；</li>
            <li>每位参赛者最多可提交2组照片，每组不超过4张。超过两组以最先上传的前两组照片作为评选作品，其余上传的照片则不计入评选范畴每张作品不得小于1M，以JPG格式为主，保留元数据（EXIT参数）；</li>
            <li>所有获奖品将在主办方官网和官微上进行公示，接受社会监督反馈；</li>
            <li>对于获奖作品，主承办单位有权以复制、发行、展览、放映、信息传播、媒体传播等方式使用，并可不支付报酬；</li>
            <li>本活动主承办单位工作人员不得投稿，一经发现取消资格；</li>
            <li>本次活动不收参与费，不退稿；</li>
            <li>本次活动解释权属主办单位。凡投稿者，即视为其已同意本活动细则之所有规定。</li>
            <li>如遇照片无法上传等相关问题，可来电进行咨询，咨询电话：027-87058906，联系人：王丽萍（咨询时间为9：00-17:30）</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- 投票规则 - 弹出层 -->
    <div class="popups apply" @click="rule=false" id="rule" v-if="rule">
      <div class="popups-bg"></div>
      <div class="popups-box">
        <div class="close" @click="rule=false">x</div>
        <div class="text" v-html="info.banner.content"></div>
      </div>
    </div>
    <!--20180324 在投票页面增加发布足迹功能按钮 go_vote表示发布成功之后，跳转到投票页面-->
    <transition name="publish">
      <router-link
        :to="{path:'/footprints/FootprintsPublish',query:{aid:aid,park_id:park_id,park_name:park_name,back_:'ParkPhoto'}}"
        tag="div"
        class="photo_publish"
        v-if="is_show_publish"
        v-show="showPublish"
      >
        <img src="../../assets/switch/footprint_send.png" alt />
      </router-link>
    </transition>
    <!--App下载浮框-->
    <download v-if="is_app"></download>

    <!-- 背景音乐 -->
    <bg-music :bg_audio_url="bg_audio_url" v-if="bg_audio_url"></bg-music>
    <!-- 发布红包 -->
    <publish-button :id="aid" id_type_="photo" v-if="is_admin" bottom="2rem"></publish-button>
  </div>
  <!--<div v-else class="foot_noinfo"><img src="/static/img/foot_noinfo@2x.14391c2.png" alt="">
  <p>请在微信页面打开链接</p></div>-->
</template>

<script>
import { Toast, Indicator } from 'mint-ui'

import FootprintsList from '../../components/FootprintsList'
import download from '../../components/Download'
import RedPacket from '../../components/RedPacket'
import bgMusic from '../../components/Music'
import UserList from '../../components/UserList'
import PublishButton from '../../components/PublishButton'
var wx = require('weixin-js-sdk')
export default {
  components: {
    FootprintsList,
    download,
    'red-packet': RedPacket,
    'bg-music': bgMusic,
    'user-list': UserList,
    'publish-button': PublishButton
  },
  data () {
    return {
      // 背景音乐
      bg_audio_url: '',
      is_app: false,
      aid: this.$route.query.aid,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      union_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      notice: false,
      rule: false,
      is_no_park: false,
      info: {},
      park_list: [],
      key_word: '',
      key_word_info: this.is_no_park ? '搜索公园' : '搜索作品编号',
      title_name: '', // this.is_no_park ? '工业旅游' : '探寻劲牌品质之源',
      type: 0,
      banner_img: '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      page: 1,
      info_1: [],
      info_2: [],
      allLoaded: false,
      show_type: '', // 1-显示商家,2-显示个人,3-显示全部
      m_time: '', // 活动结束的时间
      show_btn_spots: 0, // 显示景点
      show_btn_food: 0, // 显示美食
      show_btn_shop: 0, // 购物
      show_btn_wc: 0, // 厕所
      show_btn_notes: 0, // 游记
      show_btn_activity: 0, // 活动
      show_btn_stay: 0, // 住宿
      show_btn_foot: 0, // 足迹
      show_btn_root_books: 0, // 路书
      change_btn_86_img: 0, // 是否改变按钮的图片
      go_park_id: '', // 菜单跳转到那家公园的ID
      is_show_publish: 0, // 是否显示足迹发布按钮
      park_id: '', // 给发布足迹页面使用的
      park_name: '',
      wx_url:
        'http://www.zmlxj.com/api.php/weixin/wx_share?type=ParkPhoto&aid=' +
        this.$route.query.aid,
      show_head: true, // 是否显示banner下面的菜单(排行榜,时间,投票规则)
      show_restaurant_numbers: true, // 是否显示餐厅
      show_person_numbers: true, // 人数
      show_img_numbers: true, // 图片个数
      show_vote_numbers: true, // 投票总数
      show_spots_numbers: true, // 作品数
      show_park_numbers: true, // 参与公园,机构，商家个数
      is_vote: true, // 表示是否投票
      spots_list: [], // 足迹列表
      is_show_nav: false,
      is_go_map: false,
      user_id: '',
      load_more: 0,
      // 分享地址
      sUrl: '',

      // 滚动到底部时隐藏
      showPublish: false,

      is_admin: 0 // 管理员
    }
  },
  mounted () {
    this.initSUrl()
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }
    if (this.open_id) {
    } else {
      this.authorize()
    }
    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }

    this.unload()

    // 在冒泡阶段处理滚动的事件
    window.addEventListener('scroll', this.throttle(this.handleScroll))
  },
  methods: {
    // 初始化sUrl
    initSUrl () {
      this.sUrl = 'https://m.zmlxj.com/#/parkPhoto/parkPhoto?aid=' + this.aid
    },
    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id == 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
            })

            var open_id = this.open_id
            if (info != '') {
              if (info.url != '') {
                let url_arr = info.url.split('share_open_id')

                url = url_arr[0] + '&share_open_id=' + open_id
              } else {
                url = url + '&share_open_id=' + open_id
              }
            } else {
              url = url + '&share_open_id=' + open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
          }
        })
      } else {
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id == 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },
    //
    unload () {
      this.getData(1, this.union_id)
    },
    ievent (...data) {
      this.getData(data[0])
    },
    getData (page, union_id) {
      this.$ajax(
        'post',
        'SpotsRace/get_user_numbers',
        {
          aid: this.aid,
          page: page,
          key_word: this.key_word,
          union_id: union_id
        },
        ret => {
          if (ret.data.id == 0) {
            if (page == 1) {
              this.info = ret.data.data
              this.m_time = ret.data.data.m_time // 活动结束的时间
              this.is_no_park = ret.data.data.is_no_park == 1
              this.show_type = ret.data.data.show_type // 20180302增加的判断排行榜显示的内容
              this.show_btn_spots = ret.data.data.show_btn_spots
              this.show_btn_food = ret.data.data.show_btn_food
              this.show_btn_stay = ret.data.data.show_btn_stay
              this.show_btn_wc = ret.data.data.show_btn_wc
              this.show_btn_activity = ret.data.data.show_btn_activity
              this.show_btn_notes = ret.data.data.show_btn_notes
              this.show_btn_shop = ret.data.data.show_btn_shop
              this.show_btn_foot = ret.data.data.show_btn_foot
              this.show_btn_root_books = ret.data.data.show_btn_root_books
              this.change_btn_86_img = ret.data.data.change_btn_86_img
              this.go_park_id = ret.data.data.go_park_id
              this.park_name = ret.data.data.park_name
                ? ret.data.data.park_name
                : '最美旅行家'
              this.park_id = ret.data.data.go_park_id
                ? ret.data.data.go_park_id
                : 298
              this.is_show_publish = ret.data.data.is_show_publish // 是否显示发布按钮
              this.is_admin = ret.data.data.is_admin // 是否管理员
              // 微信分享
              let wx_title = ret.data.data.banner.activity_name
              let wx_content = ret.data.data.banner.activity_name
              let wx_img = ret.data.data.banner.banner_img_app

              if (this.is_weixin) {
                this.wx_share_callback(window.location.href, {
                  url: this.sUrl,
                  id_: this.aid,
                  id_type_: 'photo',
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                })
              }
              // 背景音乐
              this.bg_audio_url = ret.data.data.bg_audio_url
                ? ret.data.data.bg_audio_url
                : ''
              // 是否显示顶部banner下面的排行版,时间,投票规则
              this.show_head = ret.data.data.show_head == 1
              this.show_restaurant_numbers =
                ret.data.data.show_restaurant_numbers == 1 // 是否显示餐厅
              this.show_person_numbers =
                ret.data.data.show_person_numbers == 1 // 人数
              this.show_img_numbers =
                ret.data.data.show_img_numbers == 1 // 图片个数
              this.show_vote_numbers =
                ret.data.data.show_vote_numbers == 1 // 投票总数
              this.show_spots_numbers =
                ret.data.data.show_spots_numbers == 1 // 作品数
              this.show_park_numbers =
                ret.data.data.show_park_numbers == 1
              this.is_vote = ret.data.data.is_vote == 1
              this.title_name = ret.data.data.park_name // 没有效果,不知道为什么？
              this.is_show_nav = ret.data.data.is_show_nav == 1
              this.is_go_map = ret.data.data.is_go_map
              this.load_more = ret.data.data.load_more
              this.load_more = 1
              document.title = '专题-' + ret.data.data.park_name

              // console.log(this.info);
              if (this.is_no_park) {
                this.type = 2
                this.info_1 = ret.data.data.park_list.slice(
                  0,
                  ret.data.data.park_list.length / 2
                )
                this.info_2 = ret.data.data.park_list.slice(
                  ret.data.data.park_list.length / 2
                )
              } else {
                this.type = 1
                this.park_list = ret.data.data.park_list
              }
            } else {
              if (this.is_no_park) {
                this.type = 2
                if (this.getMinUl().index() == 0) {
                  this.info_1 = this.info_1.concat(
                    ret.data.data.park_list.slice(
                      0,
                      ret.data.data.park_list.length / 2
                    )
                  )
                  this.info_2 = this.info_2.concat(
                    ret.data.data.park_list.slice(
                      ret.data.data.park_list.length / 2
                    )
                  )
                } else {
                  this.info_2 = this.info_2.concat(
                    ret.data.data.park_list.slice(
                      0,
                      ret.data.data.park_list.length / 2
                    )
                  )
                  this.info_1 = this.info_1.concat(
                    ret.data.data.park_list.slice(
                      ret.data.data.park_list.length / 2
                    )
                  )
                }
              } else {
                this.type = 1
                this.park_list = this.park_list.concat(ret.data.data.park_list)
                //                                this.park_list = ret.data.data.park_list;
              }
            }
            this.itemLen = ret.data.data.park_list.length
          }
        }
      )
    },
    //            点击投票
    vote (data) {
      if (data.show == 0) {
        Toast('投票未开始')
      } else if (data.show == 3) {
        Toast('投票已结束')
      } else if (data.show == 1 && data.vote == 1) {
        Toast('今日已投票')
      } else {
        if (
          this.union_id &&
          !(
            !this.union_id &&
            typeof this.union_id !== 'undefined' &&
            this.union_id != 0
          )
        ) {
          this.$ajax(
            'post',
            'SpotsRace/vote',
            {
              aid: this.aid,
              spots_id: data.id,
              union_id: this.union_id,
              user_id: this.user_id
            },
            ret => {
              if (ret.data.id == 0) {
                Toast({
                  message: ret.data.msg,
                  iconClass: 'iconfont icon-chenggong',
                  duration: 1000
                })
                // vue是数据驱动，直接修改show 和vote的值
                data.show = 1
                data.vote = 1
                ++data.numbers
              } else if (ret.data.id == 1) {
                this.authorize()
              } else {
                Toast({
                  message: ret.data.msg,
                  iconClass: 'iconfont icon-fail',
                  duration: 1000
                })
              }
            }
          )
        } else {
          this.authorize()
        }
      }
    },
    //            搜索
    search () {
      this.$ajax(
        'post',
        'SpotsRace/search',
        {
          aid: this.aid,
          key: this.key_word,
          type: this.type,
          page: this.page,
          union_id: this.union_id
            ? this.union_id
            : localStorage.getItem('weixin_user_info'),
          where_: 'web'
        },
        ret => {
          if (ret.data.id == 0) {
            if (this.page == 1) {
              if (this.is_no_park) {
                this.info_2 = ret.data.data.data.slice(
                  0,
                  ret.data.data.data.length / 2
                )
                this.info_1 = ret.data.data.data.slice(
                  ret.data.data.data.length / 2
                )
              } else {
                this.park_list = ret.data.data
              }
            } else {
              if (this.is_no_park) {
                this.info_2 = this.info_2.concat(
                  ret.data.data.data.slice(0, ret.data.data.data.length / 2)
                )
                this.info_1 = this.info_1.concat(
                  ret.data.data.data.slice(ret.data.data.data.length / 2)
                )
              } else {
                this.park_list = this.park_list.concat(ret.data.data.data)
              }
            }
          } else {
            Toast({
              message: ret.data.msg,
              iconClass: 'iconfont icon-fail',
              duration: 1000
            })
          }
        }
      )
    },
    getMinUl () {
      var $arrUl = $('.photo-list .col')
      var $minUl = $arrUl.eq(0)
      $arrUl.each(function (index, elem) {
        if ($(elem).height() < $minUl.height()) {
          $minUl = $(elem)
        }
      })
      return $minUl
    },
    //
    login () {
      if (!this.check_login()) {
        localStorage.setItem('login_url', location.hash)
        this.$router.push({ path: '/my/login', query: { log_type: 1 } })
      } else {
        this.user_id = this.check_login().user_id
      }
    },
    // 加载更多
    loadBottom () {
      var that = this
      setTimeout(() => {
        if (this.itemLen % 10 == 0) {
          ++that.page
          // that.getData(that.page,that.union_id);
          that.search()
        } else {
          that.allLoaded = true
          Toast('没有更多了')
        }
        that.$refs.loadmore.onBottomLoaded()
      }, 1500)
    },
    // 点击在家更多
    click_load_more () {
      var that = this

      that.page++
      that.search()
    },
    map_open_ (lng, lat, title, address) {
      if (this.is_weixin) {
        this.wx_nav(lng, lat, title, address)
      } else {
        Toast('请在微信中打开,使用导航')
      }
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=' +
        this.$route.query.aid +
        '&token=' +
        this.token +
        '&state=parkPhoto'
      window.location.href = open_url
    },
    // 获取页面滚动的高度
    handleScroll () {
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight

      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      // 滚动条到底部的条件
      if (scrollTop > 0 && scrollTop + windowHeight >= scrollHeight) {
        this.showPublish = false
      } else {
        this.showPublish = true
      }
    },
    throttle (fn, interval = 300) {
      let canRun = true
      return function () {
        if (!canRun) return
        canRun = false
        setTimeout(() => {
          fn.apply(this, arguments)
          canRun = true
        }, interval)
      }
    }
  }
}
</script>
<style scoped>
.ParkPhoto {
  padding-top: 2.8rem;
}
.notes {
  position: fixed;
  float: right;
  top: 65%;
  right: 0;
}
.LineList_list {
  padding: 0.5rem;
}

.LineList_list li {
  float: left;
  width: 22%;
  margin: 0 1.5%;
  position: relative;
  padding: 8px 0;
  border-radius: 4px;
  font-size: 0.7rem;
  color: #666;
  text-align: center;
}

.LineList_list li.jingdian,
.LineList_list li.zhuzhi,
.LineList_list li.gouwuche-copy,
.LineList_list li.chifan {
  background: none;
}

.LineList_list li img {
  width: 2rem;
  display: inline-block;
}

.LineList_list li p {
  margin: 0.2rem 0;
}

/*投票规则 中富文本内容的图片*/
.popups-box .text img {
  max-width: 100%;
}
.ParkPhoto {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  /* margin-bottom: 60%; */
  padding-bottom: 2.4rem;
}

.ParkPhoto .index-banner img {
  width: 100%;
  height: auto;
}

.ParkPhoto .index-info {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  border-bottom: 0.1rem solid #cdcdcd;
}

.ParkPhoto .btn_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ParkPhoto .btn {
  color: #fff;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  height: 2rem;
  line-height: 1.8rem;
  border-radius: 0.3rem;
  background: #f59f32;
  border-bottom: 0.2rem solid #936425;
  flex: 1;
  margin: 0 0.1rem;
  padding: 0 0.1rem;
  box-sizing: border-box;
  font-size: 0.9rem;
}

.ParkPhoto .btn.rank {
  font-size: 1rem;
  height: 3rem;
  line-height: 2.8rem;
  border-radius: 0.5rem;
  background: #e03347;
  border-bottom: 5px solid #892c37;
  flex: 3;
}

.ParkPhoto .btn.rank > div {
  display: inline-block;
  vertical-align: inherit;
}

.ParkPhoto .btn.rank > div p {
  line-height: 1.2rem;
  font-size: 0.6rem;
}

.ParkPhoto .btn.rank > div p span {
  background-color: #fff;
  color: #e13046;
  display: inline-block;
  line-height: 0.8rem;
  padding: 0 0.05rem;
  margin: 0 0.05rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
}

.ParkPhoto .btn.rank i {
  font-size: 1.7rem;
  line-height: 0;
}

.ParkPhoto .index-info .box {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  display: flex;
}

.ParkPhoto .index-info .box li {
  flex: 1;
}

.ParkPhoto .radio-box {
  color: #1db4e1;
  text-align: center;
  padding: 0.5rem 0;
}

.ParkPhoto .radio-box li {
  display: inline-block;
  margin: 0 1.5rem;
}

.ParkPhoto .radio-box b {
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  border: 1px solid #1db4e1;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.ParkPhoto .radio-box .sel i {
  display: block;
  width: 0.2rem;
  height: 0.2rem;
  margin: 0.15rem;
  border-radius: 50%;
  background: #1db4e1;
}

.ParkPhoto .radio-box span {
  height: 1.2rem;
  line-height: 1.2rem;
  display: inline-block;
}

.ParkPhoto .search-box {
  font-size: 0;
  padding: 1rem 0.5rem;
  display: flex;
}

.ParkPhoto .search-box > div {
  text-align: center;
  flex: 3;
  display: flex;
  border-radius: 4px;
  box-shadow: 0 0 2px #ff9a80;
}

.ParkPhoto .search-box input {
  height: 1.4rem;
  display: inline-block;
  vertical-align: top;
  background: #fff;
  padding: 0 0.5rem;
  /* border: 1px solid rgb(224, 51, 71); */
  border: 1px solid #ff9a80;
  border-radius: 0.15rem 0 0 0.15rem;
  color: #666;
  outline: 0;
  -webkit-appearance: none;
  border-radius: 4px 0 0 4px;
  flex: 3;
  font-size: 0.7rem;
}

.ParkPhoto .search-box button {
  height: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  outline: 0;
  border-radius: 0 0.15rem 0.15rem 0;
  /* background: rgb(224, 51, 71); */
  background: #ff9a80;
  color: #fff;
  border: none;
  border-radius: 0 4px 4px 0;
  flex: 1;
  font-size: 0.7rem;
}

.ParkPhoto .search-box a {
  flex: 0.8;
}

.ParkPhoto .search-box .all-photo {
  height: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  font-size: 0.7rem;
  /* color: #ff9a80; */
  /* background-color: rgb(224, 51, 71); */
  background-color: #ff9a80;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  margin-left: 1rem;
  box-shadow: 0 0 2px #ff9a80;
}

.ParkPhoto .popups-box {
  width: 85%;
  padding-bottom: 0.7rem;
}

.ParkPhoto .popups .text {
  font-size: 0.7rem;
  text-align: left;
}

.ParkPhoto .popups p {
  margin: 10px 0;
  font-weight: 100;
}

.ParkPhoto .popups p img {
  margin: 0 auto;
}

.ParkPhoto .popups h1 {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  color: #333;
  text-align: center;
  font-weight: 400;
}

.ParkPhoto .popups.apply ol li {
  font-size: 0.7rem;
}

.ParkPhoto .active_user_box,
.ParkPhoto .active_user_box .who_vote .time {
  font-weight: 700;
  color: #000;
  padding: 0.4rem;
}

.ParkPhoto .active_user_box.ParkPhotoListDetail .who_vote .row {
  height: 3.2rem;
}

.ParkPhoto.ParkPhotoListDetail .who_vote .time {
  color: #333;
}

.ParkPhotoListDetail .who_vote .row {
  text-align: left;
  padding: 0 0.3rem;
}

.ParkPhoto .active_user_box.ParkPhotoListDetail .who_vote .list {
  height: 4rem;
}

.ParkPhoto .active_user_box.ParkPhotoListDetail .who_vote img {
  margin: 0 auto;
  width: 2.2rem;
  height: 2.2rem;
}

.ParkPhoto .active_user_box.ParkPhotoListDetail .who_vote .list .num_box {
  display: inline-block;
  text-align: center;
  position: relative;
  margin-right: 0.2rem;
}

.ParkPhoto
  .active_user_box.ParkPhotoListDetail
  .who_vote
  .list
  .num_box
  > span {
  position: absolute;
  top: 1.3rem;
  left: 0.3rem;
  font-size: 0.5rem;
  font-weight: 100;
  color: #fff;
  padding: 0.05rem 0.1rem;
  display: inline-block;
}

.ParkPhoto
  .active_user_box.ParkPhotoListDetail
  .who_vote
  .list
  .num_box:nth-of-type(1)
  > span {
  background-color: red;
}

.ParkPhoto
  .active_user_box.ParkPhotoListDetail
  .who_vote
  .list
  .num_box:nth-of-type(2)
  > span {
  background-color: #ff5023;
}

.ParkPhoto
  .active_user_box.ParkPhotoListDetail
  .who_vote
  .list
  .num_box:nth-of-type(3)
  > span {
  background-color: #ffa717;
}

.ParkPhoto .active_user_box.ParkPhotoListDetail .who_vote .list .num {
  font-size: 0.6rem;
  color: #333;
  font-weight: 100;
  height: 1rem;
  line-height: 1rem;
}

.ParkPhoto .active_user_box.ParkPhotoListDetail .who_vote .list .num i {
  font-size: 0.6rem;
  margin-right: 2px;
  transform: rotateY(180deg);
  display: inline-block;
}

.PhotoList {
  font-size: 0.7rem;
}

.PhotoList .park-list {
  padding: 2%;
  overflow: hidden;
}

.border {
  border-top: 0.1rem solid #cccccc;
  border-bottom: 0.1rem solid #cccccc;
  padding-bottom: 1rem;
  padding-top: 0.7rem;
}

.PhotoList .park-list h3 {
  font-size: 0.8rem;
  margin-left: 10px;
}

.PhotoList .more {
  line-height: 1.4rem;
  height: 1.4rem;
  text-align: center;
  color: #ccc;
}

.PhotoList .more i {
  transform: rotate(90deg);
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.PhotoList .park-list .item {
  width: 46%;
  height: 8rem;
  margin: 0.4rem 2% 0;
  float: left;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  border: 1px solid #ff9a80;
  box-sizing: border-box;
}

.park-list .item a {
  display: block;
  color: #fff;
  width: 100%;
  height: 6.5rem;
  position: relative;
}

.park-list .item .count {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.park-list .item .count span {
  background: rgba(0, 0, 0, 0.4);
  padding: 0.2rem 0.3rem;
}

.park-list .item .count i {
  display: inline-block;
  transform: rotateY(180deg);
  display: inline-block;
  margin-right: 4px;
}

.park-list .item .name {
  padding: 0 0.3rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  color: #333;
}

.park-list .item .name span {
  font-family: serif;
  font-weight: 700;
  font-size: 0.4rem;
  color: #ff9a80;
  margin-left: 0.1rem;
}

.park-list .item .pic {
  width: 100%;
  height: 100%;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.PhotoParkList .page-tips {
  width: 100%;
  text-align: center;
  padding: 0.8rem 0;
}
/*  */

.photo-list {
  padding: 2%;
  display: inline-block;
  width: 96%;
}
.photo-list .col {
  width: 46%;
  overflow: hidden;
  margin: 0.5rem 2% 0;
  float: left;
}
.photo-list .item {
  vertical-align: top;
  width: 100%;
  position: relative;
  color: #fff;
  border-radius: 4px;
  float: left;
  margin-bottom: 1.2rem;
  border: 1px solid #ff9a80;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 0.6rem;
}
.photo-list .item .num {
  position: absolute;
  width: 100%;
}
.photo-list .item .num span {
  background: #ff9a80;
  /* background: #e03347; */
  float: right;
  padding: 0.2rem 0.4rem;
  border-radius: 0 3px 0 4px;
  font-size: 0.6rem;
}
a {
  color: #000;
  text-decoration: none;
}

.photo-list .item .pic {
  width: 100%;
  display: block;
  border-radius: 4px 4px 0 0;
}

.photo-list .item .img_num {
  position: absolute;
  bottom: 3.8rem;
  width: 100%;
  left: 0;
  font-size: 0.7rem;
}
.photo-list .item .img_num span {
  background: rgba(0, 0, 0, 0.4);
  padding: 0.2rem 0.4rem;
  display: inline-block;
}
.photo-list .item .u-card.no_park {
  text-align: center;
}
.photo-list .item .u-card {
  width: 100%;
  position: relative;
  color: #666;
  height: 3.2rem;
}
.photo-list .item .u-card h1 {
  padding: 0.4rem 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.7rem;
  color: #333;
  font-weight: 700;
}
.photo-list .item .u-card h2 {
  font-size: 0.6rem;
}

.photo-list .item .u-card a {
  float: left;
  margin-left: 1rem;
}
.photo-list .item .u-card img.user_icon {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin: 0.4rem 0.2rem;
  border: 1px solid #ff9a80;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.photo-list .item .u-card img.navigation_icon {
  display: none;
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  top: -1.6rem;
  right: 0.4rem;
}
.photo-list .item .vote.no {
  background: #ccc;
}
.photo-list .item .vote {
  width: 70%;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  position: absolute;
  bottom: -0.8rem;
  left: 15%;
  border-radius: 0.8rem;
  background-color: #ff9a80;
  font-size: 0.7rem;
}

.ParkPhoto .photo_publish {
  position: fixed;
  width: 3rem;
  height: 3rem;
  bottom: 2rem;
  left: calc(50% - 1.5rem);
  margin: 0;
}
.ParkPhoto .photo_publish img {
  width: 100%;
}
/* 人数统计 */
.stats {
  margin-top: 0.5rem;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stats .stats_item {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: orange; */
  /* color: #fff; */
}
.stats .stats_item .stats_item_name {
  font-size: 0.75rem;
  color: #333;
}
.stats .stats_item .stats_item_number {
  font-size: 0.8rem;
  font-weight: 600;
  color: #666;
  /* color: #eee; */
}

/*  */

.publish-enter,
.publish-leave-to {
  opacity: 0;
}
.publish-enter-to,
.publish-leave {
  opacity: 1;
}
.publish-enter-active,
.publish-leave-active {
  transition: ease 0.3s;
}

.click-load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.click-load-more .load-more-line {
  width: 40%;
  height: 1px;
  background-color: #eee;
}
.click-load-more .load-more-text {
  padding: 0 4px;
  font-size: 0.68rem;
  color: #999;
  transform: translateY(-50%);
  background-color: #fff;
}
</style>
