<template>
  <div v-title data-title="红包提现记录">
    <!-- 头部 -->
    <mt-header fixed title="红包提现记录" v-if="header">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
    </mt-header>

    <div class="page-content" :style="style" v-if="itemLen>0">
      <mt-loadmore
        :bottom-method="loadBottom"
        @bottom-status-change="handleBottomChange"
        :bottom-all-loaded="allLoaded"
        ref="loadmore"
        :auto-fill="false"
      >
        <mt-cell
          :title="'提现到' + item.title + '账户'"
          :label="item.add_time"
          :value="'-' + item.get_money"
          v-for="item in list"
          :key="item.id"
        ></mt-cell>

        <div slot="bottom" class="mint-loadmore-bottom">
          <span
            v-show="bottomStatus !== 'loading'"
            :class="{ 'is-rotate': bottomStatus === 'drop' }"
          >↑</span>
          <span v-show="bottomStatus === 'loading'">
            <mt-spinner type="snake"></mt-spinner>
          </span>
        </div>
      </mt-loadmore>
    </div>

    <div v-else-if="itemLen<0" class="page-tips">加载中...</div>
    <div v-else class="page-tips">还没有记录</div>
  </div>
</template>

<script>
import { Toast, MessageBox } from "mint-ui";
export default {
  data() {
    return {
      userId: "",
      header: true,
      style: "",
      page: 1,
      list: [],
      itemLen: -1,
      more: false,
      allLoaded: false,
      bottomStatus: ""
    };
  },

  methods: {
    checkLogin() {
      // 用户ID
      if (this.hybrid.hybrid.versions.myapp) {
        // FOR 安卓最美旅行家APP
        this.header = false;
        this.style = "padding:0";

        this.userId = JavaScriptInterface.getUserId();
        this.getList(1);
      } else if (this.hybrid.hybrid.versions.myiOSapp) {
        // FOR IOS最美旅行家APP

        this.header = false;
        this.style = "padding:0";

        this.hybrid.hybrid.registerHandler(
          "getIOSUserId",
          (data, responseCallback) => {
            this.userId = data.user_id;
            var responseData = "已经收到IOS传来的userid";
            responseCallback(responseData);
            this.getList(1);
          }
        );
      } else {
        // For web
        this.userId = this.get_User_Id();
        this.getList(1);
      }
    },
    // api.php/foot/give_money_list
    // 传入参数：
    // $_POST['user_id'];
    // $_POST['page'] ；
    getList(page) {
      this.$ajax(
        "post",
        "foot/give_money_list",
        { user_id: this.userId, page: page },
        res => {
          this.list = this.list.concat(res.data.data);
          if (page == 1) {
            this.itemLen = res.data.data.length;
          }
        }
      );
    },

    handleBottomChange(status) {
      this.bottomStatus = status;
    },

    loadBottom() {
      var that = this;
      var itemLen = that.list.length;
      that.page = that.page + 1;
      setTimeout(() => {
        if (itemLen % 20 == 0) {
          that.getList(that.page);
        } else {
          that.allLoaded = true;
          Toast("没有更多了");
        }
        that.$refs.loadmore.onBottomLoaded();
      }, 1500);
    }
  },

  mounted() {
    this.checkLogin();
  }
};
</script>

<style scoped>
.page-content {
  padding-bottom: 0;
}

.get_money_btn {
  margin: 20px auto;
  display: block;
  width: 50%;
}
.btn_more {
  margin: 10px auto;
  display: block;
}
</style>