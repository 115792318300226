<template>
  <div class="weather-container">
    <div class="item">
      <div class="img-box">
        <img class="img" :src="getWeatherIcon(info.forecasts[0].dayWeather)" alt="">
      </div>
      <div class="info">
        <span class="name" v-if="is_language=='zh'">今天</span>
        <span class="name" v-else-if="is_language=='en' && languageCount > 0">{{weather[0][0].title}}</span>
        <span class="name" v-else-if="languageCount > 0">{{weather[1][0].title}}</span>
        <span class="name" v-else>今天</span>


        <span class="value">{{info.forecasts[0].nightTemp}}/{{info.forecasts[0].dayTemp}}&#8451;</span>
      </div>
    </div>
    <div class="item">
      <div class="img-box">
        <img class="img" :src="getWeatherIcon(info.forecasts[1].dayWeather)" alt="">
      </div>
      <div class="info">
        <span class="name" v-if="is_language == 'zh'">明天</span>
        <span class="name" v-else-if="is_language == 'en' && languageCount > 0">{{weather[0][1].title}}</span>
        <span class="name" v-else-if="languageCount > 0">{{weather[1][1].title}}</span>
        <span class="name" v-else>明天</span>


        <span class="value">{{info.forecasts[1].nightTemp}}/{{info.forecasts[1].dayTemp}}&#8451;</span>
      </div>
    </div>
    <div class="item">
      <div class="img-box">
        <img class="img" :src="getWeatherIcon(info.forecasts[2].dayWeather)" alt="">
      </div>
      <div class="info">
        <span class="name" v-if="is_language == 'zh'">后天</span>
        <span class="name" v-else-if="is_language == 'en' && languageCount > 0">{{weather[0][2].title}}</span>
        <span class="name" v-else-if="languageCount > 0">{{weather[1][2].title}}</span>
        <span class="name" v-else>后天</span>


        <span class="value">{{info.forecasts[2].nightTemp}}/{{info.forecasts[2].dayTemp}}&#8451;</span>
      </div>
    </div>
  </div>
</template>

<script>
import { weather } from '../assets/utils/weather-icon.js'
export default {
  props: ['info','is_language','weather','languageCount'],
  data(){
    return {
      is_show:1,//始终显示第一个（中文）
    }
  },
  methods: {
    getWeatherIcon (w) {
      let iconKey = ''
      if (w === '晴' || w === '少云' || w === '热') {
        iconKey = 'icon0'
      } else if (w === '晴间多云' || w === '多云') {
        iconKey = 'icon1'
      } else if (w === '阴' || w === '平静') {
        iconKey = 'icon2'
      } else if (w === '阵雨' || w === '强阵雨') {
        iconKey = 'icon3'
      } else if (w === '雷阵雨' || w === '强雷阵雨') {
        iconKey = 'icon4'
      } else if (w === '雷阵雨并伴有冰雹') {
        iconKey = 'icon5'
      } else if (w === '雨雪天气' || w === '雨夹雪' || w === '阵雨夹雪') {
        iconKey = 'icon6'
      } else if (w === '小雨' || w === '雨' || w === '毛毛雨/细雨') {
        iconKey = 'icon7'
      } else if (w === '中雨' || w === '小雨-中雨') {
        iconKey = 'icon8'
      } else if (w === '大雨' || w === '中雨-大雨') {
        iconKey = 'icon9'
      } else if (w === '暴雨' || w === '大雨-暴雨') {
        iconKey = 'icon10'
      } else if (w === '大暴雨' || w === '暴雨-大暴雨') {
        iconKey = 'icon11'
      } else if (w === '特大暴雨' || w === '大暴雨-特大暴雨' || w === '极端降雨') {
        iconKey = 'icon12'
      } else if (w === '阵雪') {
        iconKey = 'icon13'
      } else if (w === '雪' || w === '小雪') {
        iconKey = 'icon14'
      } else if (w === '中雪' || w === '小雪-中雪') {
        iconKey = 'icon15'
      } else if (w === '大雪' || w === '中雪-大雪' || w === '冷') {
        iconKey = 'icon16'
      } else if (w === '暴雪' || w === '大雪-暴雪') {
        iconKey = 'icon17'
      } else if (w === '雾' || w === '轻雾') {
        iconKey = 'icon18'
      } else if (w === '冻雨') {
        iconKey = 'icon19'
      } else if (w === '沙尘暴') {
        iconKey = 'icon20'
      } else if (w === '浓雾' || w === '强浓雾' || w === '大雾' || w === '特强浓雾') {
        iconKey = 'icon21'
      } else if (w === '浮尘' || w === '扬沙') {
        iconKey = 'icon22'
      } else if (w === '强沙尘暴') {
        iconKey = 'icon23'
      } else if (w === '霾' || w === '中度霾' || w === '重度霾' || w === '严重霾') {
        iconKey = 'icon24'
      } else if (w === '有风' || w === '微风' || w === '和风' || w === '清风') {
        iconKey = 'icon26'
      } else if (w === '强风/劲风' || w === '疾风') {
        iconKey = 'icon27'
      } else if (w === '大风' || w === '烈风' || w === '风暴') {
        iconKey = 'icon28'
      } else if (w === '狂爆风' || w === '飓风' || w === '热带风暴') {
        iconKey = 'icon29'
      } else {
        // 未知
        iconKey = 'icon25'
      }

      return weather[iconKey]
    }
  }
}
</script>

<style scoped>
.weather-container {
  padding: 1rem .5rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.item {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-box {
  width: 2rem;
  height: 2rem;
  /* margin-right: .2rem; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.img-box > .img {
  width: 1.6rem;
  height: 1.6rem;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info > .name {
  font-size: .8rem;
}

.info > .value {
  font-size: .7rem;
  color: #333;
}
</style>
