<template>
  <div class="pm-body">
    <div class="pm-header">
      <div class="header-bg"></div>
      <div class="header-portrait">
        <img :src="icon_url" alt />
      </div>
    </div>
    <div class="pm-title">
      <h1>{{user_nick}}</h1>
    </div>
    <div class="pm-container">
      <div class="container-money" v-for="(item,i) in money_number" :key="i" @click="pay(item)">
        <span class="money-number">{{item}}</span>
        <span class="money-unit">元</span>
      </div>
    </div>
    <div class="pm-other-money">
      <a href @click.prevent="inputOtherMoney">其他金额</a>
    </div>
    <transition name="mask-fide">
      <div class="pm-mask" v-show="maskFlag">
        <div class="mask-content">
          <div class="mask-header">
            <div class="mask-close" @click="inputOtherMoney"></div>
            <p>其他金额</p>
          </div>
          <div class="mask-input">
            <span>金额（元）</span>
            <input
              type="text"
              placeholder="可填写1-256"
              v-model="inputNumber"
              :class="{'input-over' : overNumber }"
              oninput="value=value.replace(/[^\d]/g,'')"
            />
          </div>
          <div
            class="mask-button"
            :class="{'input-over' : overNumber, 'input-active' : inputActive}"
          >
            <button :disabled="overNumber || !inputActive" @click="pay(parseInt(inputNumber))">赞赏</button>
          </div>
        </div>
      </div>
    </transition>
    <div class="pm-footer">赞赏是为了表示鼓励而对路书的无偿赠与</div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      label_id: this.$route.query.label_id ? this.$route.query.label_id : 0,
      id_type_:this.$route.query.id_type_ ? this.$route.query.id_type_ :'booksPay',
      user_id: this.$route.query.user_id ? this.$route.query.user_id : "", //用户的ID
      is_weixin: this.hybrid.hybrid.versions.is_weixin, //-------------注意
      union_id: localStorage.getItem("open_id")
        ? localStorage.getItem("open_id")
        : '',
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      version: "1.0.0",
      park_id: 298,
      money_number: [5, 20, 50, 80, 100, 200],
      maskFlag: false, // 遮罩层判断
      inputNumber: "", // 输入的金额
      overNumber: false, // 输入的金额超出256时为true
      inputActive: false, // 输入金额后为true
      icon_url: "",
      user_nick: ""
    };
  },
  watch: {
    inputNumber: function(val) {
      this.inputNumber = val.replace(/^0/g, "");
      if (parseInt(val) > 0 && parseInt(val) <= 256) {
        this.inputActive = true;
      } else {
        this.inputActive = false;
      }
      if (parseInt(val) > 256) {
        this.overNumber = true;
      } else {
        this.overNumber = false;
      }
    }
  },
  created() {
    this.getPayMoneyData();
  },
  mounted() {
    // console.log(webview);
    if (this.token) {
    } else {
      //第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    //投票相关
    if (
      this.union_id &&
      !(
        !this.union_id &&
        typeof this.union_id != "undefined" &&
        this.union_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有union_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.union_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.union_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.union_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }

    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
      this.wx_share(window.location.href);
    }
  },
  methods: {
    getPayMoneyData() {
      this.$ajax(
        "post",
        "Label/get_label_data",
        {
          id: this.label_id,
          park_id: this.park_id,
          token: this.token,
          version: this.version
        },
        ret => {
          let res = ret.data;
          if (res.id === 0) {
            // console.log(res);
            // console.log(res.data);
            let data = res.data.label;
            this.icon_url = data.user_icon;
            this.user_nick = data.user_nick;

            /* 解决IOS不修改标题栏的问题 */
            var i = document.createElement("iframe");
            i.src = "https://www.baidu.com/favicon.ico";
            i.style.display = "none";
            i.onload = function() {
              setTimeout(function() {
                i.remove();
              }, 9);
            };
            document.body.appendChild(i);
            document.title = data.title;
          }
        }
      );
    },
    inputOtherMoney() {
      this.maskFlag = !this.maskFlag;
    },
    //支付方法
    pay(money) {
      if (this.is_weixin) {
        if (this.union_id) {
          //存在直接支付
          var open_url =
            "https://www.zmlxj.com/api.php/Activity/web_root_books_weixin_pay?id=" +
            this.label_id +
            "&spot_id=0&open_id=" +
            this.union_id +
            "&token=" +
            this.token +
            "&park_id=" +
            this.park_id +
            "&version=" +
            this.version +
            "&user_id=" +
            this.user_id +
            "&money=" +
            money+'&backUrl='+this.id_type_;
          window.location.href = open_url;
        } else {
          //不存在获取用户open_id
          this.authorize();
        }
      } else {
        Toast("请在微信中打开");
      }
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=" +
        this.token +
        "&id=" +
        this.label_id +
        "&state="+this.id_type_+'&id_type_='+this.id_type_;
      window.location.href = open_url;
    }
  }
};
</script>

<style scoped>
.pm-body {
  width: 100vw;
  height: 100vh;
  position: relative;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  background-color: #fff;
  margin: 0;
  padding: 0;
}
.pm-body .pm-header {
  position: relative;
  width: 100%;
  height: 24%;
  transform: translateY(-50%);
  text-align: center;
}
.pm-body .pm-header .header-bg {
  width: 100%;
  height: 100%;
  background-color: #f56c6c;
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 100%;
}
.pm-body .pm-header .header-portrait {
  display: inline-block;
  margin-top: -1.8rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
}
.pm-body .pm-header .header-portrait img {
  width: 3rem;
  height: 3rem;
}

.pm-body .pm-title {
  text-align: center;
}
.pm-body .pm-container {
  width: 90%;
  /* height: 100%; */
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.pm-body .pm-container .container-money {
  width: 25%;
  height: 3rem;
  border: 2px solid #f56c6c;
  margin-top: 1rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f56c6c;
}
.pm-body .pm-container .container-money:hover {
  background-color: #f56c6c;
  color: #fff;
}
.pm-body .pm-container .container-money .money-number {
  font-size: 1.4rem;
  font-style: italic;
  margin-right: 0.4rem;
}
.pm-body .pm-container .container-money .money-unit {
  margin-top: 0.4rem;
}
.pm-body .pm-other-money {
  text-align: center;
}

.pm-body .pm-other-money a {
  color: #409eff;
}

.pm-body .pm-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mask-fide-enter,
.mask-fide-leave-to {
  opacity: 0;
}
.mask-fide-enter-to,
.mask-fide-leave {
  opacity: 1;
}
.mask-fide-enter-active,
.mask-fide-leave-active {
  transition: 0.5s;
}

.pm-body .pm-mask .mask-content {
  position: relative;
  width: 80%;
  height: 10rem;
  background-color: #fff;
  border-radius: 2px;
}
.pm-body .pm-mask .mask-content .mask-header {
  text-align: center;
  height: 2.4rem;
  line-height: 2.4rem;
  border-bottom: 1px solid rgb(244, 244, 244);
}
.pm-body .pm-mask .mask-content .mask-header .mask-close {
  background: url(../../assets/img/pm_close.png) no-repeat;
  background-size: contain;
  width: 2rem;
  height: 2rem;
  /* float: left; */
  position: absolute;
  top: 0;
  right: 0.4rem;
}
.pm-body .pm-mask .mask-content .mask-header p {
  font-size: 0.8rem;
}
.pm-body .pm-mask .mask-content .mask-input {
  width: 90%;
  height: 2rem;
  border: 1px solid rgb(244, 244, 244);
  border-radius: 2px;
  margin: 1rem auto;
  display: flex;
  /* justify-content: center; */
  transition: 0.3s;
}
/* .pm-body .pm-mask .mask-content .mask-input:focus-within {
  outline: none;
  border-color: #a8a8a8;
} */
.pm-body .pm-mask .mask-content .mask-input span {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  margin-left: 0.4rem;
}
.pm-body .pm-mask .mask-content .mask-input input {
  height: 2rem;
}

.pm-body .pm-mask .mask-content .mask-button {
  text-align: center;
}
.pm-body .pm-mask .mask-content .mask-button button {
  width: 90%;
  color: rgba(255, 255, 255, 0.6);
  background-color: rgb(4, 190, 2);
  display: inline-block;
  line-height: 0.8;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid rgb(4, 190, 2);
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 4px;
}

.pm-body .pm-mask .mask-content .mask-button.input-over button {
  cursor: not-allowed;
  background-color: rgb(173, 248, 171);
  border-color: rgb(173, 248, 171);
}
.pm-body .pm-mask .mask-content .mask-button.input-active button {
  color: #fff;
}
.pm-body .pm-mask .mask-content .mask-input input.input-over {
  color: red;
}
.pm-body .pm-footer {
  position: absolute;
  width: 100%;
  bottom: 0.6rem;
  color: #999;
  text-align: center;
  font-size: 0.6rem;
}
</style>