<template>
  <div class="mpl-container">
    <transition name="fade">
      <ul class="list-wrapper" v-if="show_data">
        <li class="list-item">
          <span class="item-name">访问次数</span>
          <span class="item-number">{{mapData.looks_numbers}}</span>
        </li>
        <li class="list-item">
          <span class="item-name">分享次数</span>
          <span class="item-number">{{mapData.share_numbers}}</span>
        </li>
        <li class="list-item">
          <span class="item-name">活跃人数</span>
          <span class="item-number">{{mapData.today_user_numbers}}</span>
        </li>
        <li class="list-item">
          <span class="item-name">今日访问</span>
          <span class="item-number">{{mapData.today_looks_numbers}}</span>
        </li>
      </ul>
    </transition>

    <!-- <div
      :class="['list-button', show_data ? 'list-button-active' : '']"
      @click="show_data=!show_data"
    >
      <i class="iconfont iconsanxiangmulu"></i>
      <span>数据</span>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    mapData: {
      type: Object,
      required: true
    },
    show_data: {
      type: Boolean
    }
  },
  data () {
    return {
      // show_data: true
    }
  },
  methods: {
    toggleModel () {
      this.show_data = !this.show_data
    }
  }
}
</script>

<style scoped>
/*  */
.mpl-container {
  /* z-index: 401; */
}

.list-wrapper {
  position: fixed;
  width: calc(100vw - 3.5rem);
  height: 2.2rem;
  top: 0.5rem;
  left: 0.5rem;
  display: flex;
  border-radius: 4px;
  box-shadow: 0 2px 4px #aaa;
  background-color: #ffffff88;
   z-index: 401;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  height: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
  height: 2.2rem;
}
.fade-enter-active,
.fade-leave-active {
  transition: all ease 0.3s;
}
.list-wrapper .list-item {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.list-wrapper .list-item .item-name {
  font-size: 0.6rem;
  color: #000000aa;
}
.list-wrapper .list-item .item-number {
  font-size: 0.7rem;
  color: #fe5d08;
}
</style>
