import { render, staticRenderFns } from "./videoList.vue?vue&type=template&id=33648d5a&scoped=true&"
import script from "./videoList.vue?vue&type=script&lang=js&"
export * from "./videoList.vue?vue&type=script&lang=js&"
import style0 from "./videoList.vue?vue&type=style&index=0&id=33648d5a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33648d5a",
  null
  
)

export default component.exports