<template>
  <div class="ParkPhotoList ParkPhoto" v-title :data-title="'投票详情-' + wx_title">
    <!--v-if="is_weixin"-->
    <div class="hidden">
      <img :src="wx_img" />
    </div>
    <!-- 大图 -->
    <div class="index-banner" v-if="banner_img">
      <router-link :to="{path:'/parkPhoto/ParkMapLineList',query:{aid:aid,park_id:park_id}}">
        <img :src="banner_img" alt />
      </router-link>
    </div>
    <!-- 搜索 -->
    <div class="index-search">
      <div class="search-box">
        <input type="text" placeholder="搜索作品（手机号、编号）" id="search_text" v-model="key_word" />
        <button @click="get_data(1,union_id)">搜索</button>
        <a class="all-photo" @click="get_data(1,union_id,1,1)">全部作品</a>
      </div>
    </div>
    <!-- 作品列表 -->
    <mt-loadmore
      :bottom-method="loadBottom"
      :bottom-all-loaded="allLoaded"
      ref="loadmore"
      :auto-fill="false"
    >
      <div class="photo-list">
        <div class="col">
          <div class="item" v-for="(k,n) in info_1" :key="n">
            <div class="num">
              <span>{{k.id}}号</span>
            </div>
            <div @click="jumpToDetail(k.id)" v-if="!k.go_root_books">
              <img class="pic" :src="k.baseUrl" />
            </div>
            <router-link
              :to="{path:'/footprints/LineInfoList',query:{label_id:k.root_id,show_vote:1,bShow:k.show,bVote:k.vote,spot_id:k.id,aid:aid,user_id:k.user_id}}"
              v-if="k.go_root_books"
            >
              <img class="pic" :src="k.baseUrl" />
            </router-link>
            <div class="img_num">
              <span>共{{k.img_numbers}}张</span>
            </div>
            <div class="u-card" v-if="k.type != 1">
              <router-link
                :to="{path:'/parkPhoto/PhotoList',query:{photo_all:1,aid:aid,user_id:k.user_id}}"
              >
                <img class="user_icon" :src="k.user_icon" />
              </router-link>
              <h1>{{k.user_nick}}</h1>
              <h2>{{k.numbers}}票</h2>
              <div @click.stop.prevent="map_open(k.lng,k.lat)">
                <!--:to="{path:'/parkPhoto/ActivityMap',-->
                <!--query:{aid:aid,-->
                <!--production_id:k.id,-->
                <!--type:k.type,-->
                <!--spots_name:k.spots_name,-->
                <!--lng:k.lng,-->
                <!--lat:k.lat}}"-->
                <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
              </div>
            </div>
            <div class="u-card" v-if="k.type == 1" :class="{no_park:k.type == 1}">
              <h1>{{k.spots_name}}</h1>
              <h2>{{k.numbers}}票</h2>
              <div @click.stop.prevent="map_open(k.lng,k.lat)">
                <!--:to="{path:'/parkPhoto/ActivityMap',-->
                <!--query:{aid:aid,-->
                <!--production_id:k.id,-->
                <!--type:k.type,-->
                <!--spots_name:k.spots_name,-->
                <!--lng:k.lng,-->
                <!--lat:k.lat}}"-->
                <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
              </div>
            </div>
            <div
              class="vote"
              v-if="is_weixin"
              @click="vote(k)"
              :class="{'no': (k.show == 0)|| (k.show == 1 && k.vote == 1) || (k.show == 3) || (is_once_vote && is_have_vote)}"
            >
              <i class="add" :class="k.add ? 'show_add' : ''">+1</i>
              <span v-if="k.show == 0">投票未开始</span>
              <span v-else-if="k.show == 1 && k.vote == 0">我要投票</span>
              <span v-else-if="k.show == 1 && k.vote == 1">已投票</span>
              <span v-if="k.show == 3">投票已结束</span>
            </div>
            <div class="vote" v-else :class="{'no': 1 }">
              <span>微信中投票</span>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="item" v-for="(k,m) in info_2" :key="m">
            <div class="num">
              <span>{{k.id}}号</span>
            </div>
            <div @click="jumpToDetail(k.id)" v-if="!k.go_root_books">
              <img class="pic" :src="k.baseUrl" />
            </div>
            <router-link
              :to="{path:'/footprints/LineInfoList',query:{label_id:k.root_id,show_vote:1,bShow:k.show,bVote:k.vote,spot_id:k.id,aid:aid,user_id:k.user_id}}"
              v-if="k.go_root_books"
            >
              <img class="pic" :src="k.baseUrl" />
            </router-link>
            <div class="img_num">
              <span>共{{k.img_numbers}}张</span>
            </div>
            <div class="u-card" v-if="k.type != 1">
              <router-link
                :to="{path:'/parkPhoto/PhotoList',query:{photo_all:1,aid:aid,user_id:k.user_id}}"
              >
                <img class="user_icon" :src="k.user_icon" />
              </router-link>
              <h1>{{k.user_nick}}</h1>
              <h2>{{k.numbers}}票</h2>
              <div @click.stop.prevent="map_open(k.lng,k.lat)">
                <!--:to="{path:'/parkPhoto/ActivityMap',-->
                <!--query:{aid:aid,-->
                <!--production_id:k.id,-->
                <!--type:k.type,-->
                <!--spots_name:k.user_nick,-->
                <!--lng:k.lng,-->
                <!--lat:k.lat}}"-->
                <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
              </div>
            </div>
            <div class="u-card" v-if="k.type == 1" :class="{no_park:k.type == 1}">
              <h1>{{k.spots_name}}</h1>
              <h2>{{k.numbers}}票</h2>
              <div @click.stop.prevent="map_open(k.lng,k.lat)">
                <!--:to="{path:'/parkPhoto/ActivityMap',-->
                <!--query:{aid:aid,-->
                <!--production_id:k.id,-->
                <!--type:k.type,-->
                <!--spots_name:k.spots_name,-->
                <!--lng:k.lng,-->
                <!--lat:k.lat}}"-->
                <img class="navigation_icon" src="../../assets/img/navigation.png" alt />
              </div>
            </div>
            <div
              class="vote"
              v-if="is_weixin"
              @click="vote(k)"
              :class="{'no': (k.show == 0)|| (k.show == 1 && k.vote == 1) || (k.show == 3) || (is_once_vote && is_have_vote)}"
            >
              <i class="add" :class="k.add ? 'show_add' : ''">+1</i>
              <span v-if="k.show == 0">投票未开始</span>
              <span v-else-if="k.show == 1 && k.vote == 0">我要投票</span>
              <span v-else-if="k.show == 1 && k.vote == 1">已投票</span>
              <span v-if="k.show == 3">投票已结束</span>
            </div>
            <div class="vote" v-else :class="{'no':1 }">
              <span>微信中投票</span>
            </div>
          </div>
        </div>
      </div>
    </mt-loadmore>
    <!--点击加载更多按钮-->
    <div class="click-load-more" @click="click_load_more">
      <div class="load-more-line"></div>
      <div class="load-more-text">点击加载更多</div>
    </div>
    <!--投票成功弹窗-->
    <div class="pop-up-box" @touchmove.prevent v-if="is_pop_up">
      <img class="pop-up" src="../../assets/img/vote_pop-up.png" alt />
    </div>
    <!--App下载浮框-->
    <!-- <download v-if="is_app"></download> -->
    <!-- 红包雨 -->
    <red-packet
      :redPacketList="red_packet_rain_image_url_list"
      v-if="have_red_packet_rain"
      id_type_="vote"
      :red_packet_type_="info.red_packet_type_"
      :red_packet_image_url="info.red_packet_image_url"
    ></red-packet>
    <!-- 背景音乐 -->
    <bg-music :bg_audio_url="bg_audio_url" v-if="bg_audio_url"></bg-music>
  </div>
  <!--<div v-else class="foot_noinfo"><img src="/static/img/foot_noinfo@2x.14391c2.png" alt="">
  <p>请在微信页面打开链接</p></div>-->
</template>
<script>
import { Toast } from 'mint-ui'

import $ from 'jquery';

//import download from '../../components/Download'
import RedPacket from '../../components/RedPacket'
import Music from '../../components/Music'
var wx = require('weixin-js-sdk')
export default {
  components: {
    //download,
    'red-packet': RedPacket,
    'bg-music': Music
  },
  data () {
    return {
      info: {},
      // 背景音乐
      bg_audio_url: '',
      have_red_packet_rain: 0,
      red_packet_rain_image_url_list: '',
      is_app: true,
      aid: this.$route.query.aid,
      sUrl: '',
      key_word: this.$route.query.key_word,
      user_id: this.$route.query.user_id,
      park_id: this.$route.query.park_id,
      park_name: this.$route.query.park_name,
      banner_img: this.$route.query.banner_img,
      page: 1,
      info_1: [],
      info_2: [],
      allLoaded: false,
      itemLen: 0,
      search_list: false,
      is_pop_up: false,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      union_id: localStorage.getItem('open_id_' + this.$route.query.aid)
        ? localStorage.getItem('open_id_' + this.$route.query.aid)
        : '',
      open_id: localStorage.getItem('open_id_' + this.$route.query.aid)
        ? localStorage.getItem('open_id_' + this.$route.query.aid)
        : '',
      show_all: this.$route.query.photo_all,
      wx_title: '',
      wx_content: '',
      wx_img: '',
      wx_url:
        'http://www.zmlxj.com/api.php/weixin/wx_share?type=PhotoList&aid=' +
        this.$route.query.aid,
      is_once_vote: 0,
      is_have_vote: 0,

      is_subscribe: '', // 是否需要关注
      subscribeUrl: '', // 关注的二维码
      is_show_detail: ''
    }
  },
  mounted () {
    this.initSUrl();
    if (this.token) {
      console.log('token')
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem(
            'open_id_' + this.aid,
            this.$route.query.open_id
          )
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.aid, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.aid, '')
      }
    }
    if (this.open_id) {
      console.log('')
    } else {
      this.authorize()
    }
    /* 微信分享 */
    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }
    this.unload()
  },
  methods: {
    // 判断是否关注
    checkSubscribe () {
      if (this.open_id && this.aid && this.token && this.park_id) {
        this.$ajax(
          'post',
          'SpotsRace/get_is_subscribe',
          {
            open_id: this.open_id,
            id: this.aid,
            token: this.token,
            park_id: this.park_id
          },
          res => {
            if (res.data.id == 0) {
              this.is_subscribe = 1
            }
          }
        )
      }
    },
    // 跳转详情
    jumpToDetail (id) {
      if (this.is_show_detail == 1) {
        this.$router.push({
          path: '/vote/voteDetail',
          query: { id: id, aid: this.aid }
        })
      }
    },
    visibilityChange () {
      
      if (this.is_subscribe == -1) {
        // 页面显示状态改变
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState == 'visible') {
            window.location.reload()
          }
        })
      }
    },
    // 初始化sUrl
    initSUrl () {
      this.sUrl = 'https://m.zmlxj.com/#/vote/vote?aid=' + this.aid
    },
    // 微信分享回调
    wx_share_callback (url, info) {
      if (url) {
        var that = this
        // 先使用？进行分截
        var url_ = url.split('#')[0]
        this.$ajax('post', 'Weixin/get_wx_jskey', { url: url_ }, ret => {
          if (ret.data.id == 0) {
            // 表示获得了数据-存储在本地
            var title = info.title
            var content = info.content
            var img = info.img
            console.log(ret)
            wx.config({
              debug: false,
              appId: ret.data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
              timestamp: ret.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: ret.data.nonceStr, // 必填，生成签名的随机串
              signature: ret.data.data, // 必填，签名，见附录1
              // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
              jsApiList: [
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'onMenuShareQZone',
                'openLocation',
                'getLocation'
              ]
            })

            // 处理验证失败的信息
            wx.error(function (res) {
              // that.save_share_data_(info);
              console.log(res)
            })

            var open_id = this.open_id
            if (info != '') {
              if (info.url != '') {
                let url_arr = info.url.split('share_open_id')

                url = url_arr[0] + '&share_open_id=' + open_id
              } else {
                url = url + '&share_open_id=' + open_id
              }
            } else {
              url = url + '&share_open_id=' + open_id
            }

            // 处理验证成功的信息
            wx.ready(function () {
              wx.onMenuShareAppMessage({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info);
                  console.log(res)
                }
              })
              wx.onMenuShareTimeline({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info);
                  console.log(res)
                }
              })

              // qq空间
              wx.onMenuShareQZone({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                  console.log(res)
                }
              })
              // qq
              wx.onMenuShareQQ({
                title: title,
                desc: content,
                link: url,
                imgUrl: img,
                success: function (res) {
                  that.save_share_data_(info)
                  console.log(res)
                }
              })
              wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: content, // 分享描述
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
              wx.updateTimelineShareData({
                title: title, // 分享标题
                desc: content,
                link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: img, // 分享图标
                success: function () {
                  // 设置成功
                  that.save_share_data_(info)
                }
              })
            })
          } else {
            console.log('')
          }
        })
      } else {
        console.log('')
      }
    },
    // 分享成功回调
    save_share_data_ (info) {
      if (info) {
        if (info.id_ && info.id_type_ && info.open_id && info.token) {
          this.$ajax(
            'post',
            'Share/web_share_label_spots',
            {
              id_: info.id_,
              id_type_: info.id_type_,
              open_id: info.open_id,
              share_open_id: '',
              token: info.token
            },
            ret => {
              console.log(ret)
              if (ret.data.id == 0) {
                this.have_chance = 1
                this.have_chance_id = ret.data.data.have_chance_id
              } else {
                this.have_chance = 0
                this.have_chance_id = ''
              }
            }
          )
        } else {
          this.have_chance = 0
          this.have_chance_id = ''
        }
      } else {
        this.have_chance = 0
        this.have_chance_id = ''
      }
    },
    unload () {
      // if (!this.is_weixin)return false;
      this.get_data(1, this.union_id, 1)
    },
    get_data (page, union_id, all, clear_page) {
      if (clear_page == 1) {
        this.page = 1
      }

      if (this.user_id) {
        // 表示查询这个用户的足迹
        var data = {
          type: 2,
          aid: this.aid,
          user_id: this.user_id,
          union_id: union_id
        }
      } else if (this.park_id) {
        // 查询这个公园的足迹
         data = {
          park_id: this.park_id,
          page: page,
          aid: this.aid,
          type: 2,
          key: this.key_word,
          union_id: union_id
        }
      } else {
        // 随机查询
         data = {
          type: 2,
          aid: this.aid,
          park_id: this.park_id,
          page: page,
          key: this.key_word,
          union_id: union_id,
          where_: 'web'
        }
      }
      this.$ajax('post', 'SpotsRace/search', data, ret => {
        if (ret.data.id == 0) {
          this.info = ret.data.data
          // 红包
          this.have_red_packet_rain = ret.data.data.have_red_packet_rain
          this.red_packet_rain_image_url_list =
            ret.data.data.red_packet_rain_image_url_list

          // 背景音乐
          this.bg_audio_url = ret.data.data.bg_audio_url
            ? ret.data.data.bg_audio_url
            : ''

          // 投票
          this.is_once_vote = ret.data.data.is_once_vote
            ? ret.data.data.is_once_vote
            : 0
          this.is_have_vote = ret.data.data.is_have_vote
            ? ret.data.data.is_have_vote
            : 0

          this.is_show_detail = ret.data.data.is_show_detail || 0

          if (page == 1) {
            this.info_1 = ret.data.data.data.slice(
              0,
              ret.data.data.data.length / 2
            )
            this.info_2 = ret.data.data.data.slice(
              ret.data.data.data.length / 2
            )
          } else {
            if (this.getMinUl().index() == 0) {
              this.info_1 = this.info_1.concat(
                ret.data.data.data.slice(0, ret.data.data.data.length / 2)
              )
              this.info_2 = this.info_2.concat(
                ret.data.data.data.slice(ret.data.data.data.length / 2)
              )
            } else {
              this.info_1 = this.info_1.concat(
                ret.data.data.data.slice(0, ret.data.data.data.length / 2)
              )
              this.info_2 = this.info_2.concat(
                ret.data.data.data.slice(ret.data.data.data.length / 2)
              )
            }
          }
          this.itemLen = ret.data.data.data.length

          this.is_subscribe = ret.data.data.is_subscribe // 是否需要关注， 0不需要， -1没有关注， 1已经关注
          this.subscribeUrl = ret.data.data.subscribeUrl // 关注的二维码
          this.visibilityChange() // 绑定页面从后台切换回来时刷新
          if (this.is_subscribe != 0) {
            if (this.is_subscribe == -1) {
              this.checkSubscribe()
            }
          }

          this.wx_title = ret.data.data.data[0].spots_name
          this.wx_content = ret.data.data.data[0].spots_name
          this.wx_img = ret.data.data.data[0].newUrl

          if (this.is_weixin) {
            this.dealWithUrl(window.location.href)
            this.wx_share_callback(window.location.href, {
              url: this.sUrl,
              id_: this.aid,
              id_type_: 'photo',
              token: this.token,
              open_id: this.open_id,
              title: this.wx_title,
              content: this.wx_content,
              img: this.wx_img
            })
          }
          // 微信数据赋值
          localStorage.setItem('wx_title', ret.data.data.data[0].spots_name)
          localStorage.setItem('wx_content', ret.data.data.data[0].spots_name)
          localStorage.setItem('wx_img', ret.data.data.data[0].newUrl)
        } else {
          Toast('没有更多了')
        }
      })
    },
    onVote (data) {
      if (this.union_id) {
        this.is_voting = true // 正在投票
        this.$ajax(
          'post',
          'SpotsRace/vote',
          {
            aid: this.aid,
            spots_id: data.id,
            union_id: this.union_id
          },
          ret => {
            if (ret.data.id == 0) {
              Toast({
                message: ret.data.msg,
                iconClass: 'iconfont icon-chenggong',
                duration: 1000
              })
              // 直接修改data里面的数据
              data.show = 1
              data.vote = 1
              data.add = 1
              ++data.numbers
              if (this.is_once_vote == 1) {
                this.is_have_vote = 1
              }
              this.is_voting = false // 投票完成
              // this.get_data(this.page, this.union_id)
            } else if (ret.data.id == 1) {
              this.is_voting = false // 投票完成
              this.authorize()
            } else {
              this.is_voting = false // 投票完成
              Toast({
                message: ret.data.msg,
                iconClass: 'iconfont icon-fail',
                duration: 1000
              })
            }
          }
        )
      } else {
        this.authorize()
      }
    },
    //            点击投票
    vote (data) {
      if (this.is_voting) {
        return
      }

      if (this.is_once_vote == 0) {
        if (data.show == 0) {
          Toast('投票未开始')
        } else if (data.show == 3) {
          Toast('投票已结束')
        } else if (data.show == 1 && data.vote == 1) {
          Toast('今日已投票')
        } else {
          this.onVote(data)
        }
      } else if (this.is_once_vote == 1) {
        if (this.is_have_vote == 1) {
          Toast('今日已投票')
        } else {
          this.onVote(data)
        }
      } else {
        console.log('投票参数错误')
      }
    },
    getMinUl () {
      var $arrUl = $('.photo-list .col')
      var $minUl = $arrUl.eq(0)
      $arrUl.each(function (index, elem) {
        if ($(elem).height() < $minUl.height()) {
          $minUl = $(elem)
        }
      })
      return $minUl
    },
    //            加载更多
    loadBottom () {
      var that = this
      setTimeout(() => {
        if (this.itemLen % 10 == 0) {
          that.page++
          that.get_data(that.page, this.union_id, 1)
        } else {
          that.allLoaded = true
          Toast('没有更多了')
        }
        that.$refs.loadmore.onBottomLoaded()
      }, 1500)
    },
    // 点击在家更多
    click_load_more () {
      var that = this

      that.page++
      that.get_data(that.page, this.union_id, 1)
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=' +
        this.$route.query.aid +
        '&token=' +
        this.token +
        '&state=voteList'
      window.location.href = open_url
    }
  }
}
</script>

<style scoped>
.ParkPhoto {
  padding-bottom: 1rem;
  padding-top: 2.4rem;
  background-color: rgb(238, 241, 243);
}

.pop-up-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
}

.pop-up-box .pop-up {
  width: 50%;
  margin-top: 30%;
}

.mint-loadmore-bottom {
  font-size: 0.8rem;
}

.photo-list {
  padding: 2%;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  background-color: rgb(238, 241, 243);
}

.photo-list .col {
  width: 49%;
  overflow: hidden;
  margin: 0;
}

.photo-list .item {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  position: relative;
  color: #fff;
  margin-bottom: 1.2rem;
  box-sizing: border-box;
  padding-bottom: 0.6rem;
  background-color: #fff;
  box-shadow: 0 0 4px #e6e6e6;
  border: none;
  border-radius: 0;
}

.photo-list .item .num {
  position: absolute;
  width: 100%;
}

.photo-list .item .num span {
  background: rgba(0, 0, 0, 0.6);
  float: right;
  padding: 0.2rem 0.4rem;
  font-size: 0.6rem;
  border-radius: 0;
}

.photo-list .item .u-card a {
  float: left;
  margin-left: 1rem;
}

.photo-list .item .pic {
  width: 100%;
  display: block;
  border-radius: 0;
}

.photo-list .item .img_num {
  position: absolute;
  bottom: 3.8rem;
  width: 100%;
  left: 0;
  font-size: 0.7rem;
}

.photo-list .item .img_num span {
  background: rgba(0, 0, 0, 0.4);
  padding: 0.2rem 0.4rem;
  display: inline-block;
}

.photo-list .item .u-card {
  width: 100%;
  position: relative;
  color: #666;
  height: 3.2rem;
}

.photo-list .item .u-card.no_park {
  text-align: center;
}

.photo-list .item .u-card img.navigation_icon {
  display: none;
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  top: -1.6rem;
  right: 0.4rem;
}

.photo-list .item .u-card img.user_icon {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin: 0.4rem 0.2rem;
  border: 1px solid #ff9a80;
  border-radius: 50%;
  box-sizing: border-box;
}

.photo-list .item .vote {
  width: 70%;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  position: absolute;
  bottom: -0.8rem;
  left: 15%;
  border-radius: 0.8rem;
  background: linear-gradient(rgb(252, 60, 60), rgb(240, 123, 55));
  font-size: 0.7rem;
}

.photo-list .item .vote.no {
  background: #ccc;
}

.photo-list .item .u-card h1 {
  padding: 0.4rem 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.7rem;
  color: #333;
  font-weight: 700;
}

.photo-list .item .u-card h2 {
  font-size: 0.6rem;
  color: #7e2f45;
}

.photo-list .item .u-card .vote a {
  color: #fff;
}
.ParkPhoto .search-box .all-photo {
  height: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  font-size: 0.7rem;
  background: linear-gradient(rgb(252, 60, 60), rgb(240, 123, 55));
  color: #fff;
  border-radius: 4px;
  text-align: center;
  margin-left: 1rem;
  box-shadow: 0 0 2px #ff9a80;
  flex: 1;
}
.ParkPhoto .loading {
  text-align: center;
  color: #ff9a80;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  clear: both;
}
.click-load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: rgb(234, 241, 243);
  padding-bottom: 1rem;
}

.click-load-more .load-more-line {
  height: 1px;
  width: 40%;
  background-color: #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, 0);
}
.click-load-more .load-more-text {
  color: #999;
  font-size: 13px;
  padding: 0 4px;
  background-color: rgb(238, 241, 243);
  z-index: 1;
  transform: translate(0, 0);
}

.ParkPhoto .search-box {
  position: fixed;
  top: 0;
  left: 0;
  height: 2.4rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 0;
  padding: 0 0.5rem;
  display: flex;
  background-color: #fff;
  z-index: 1;
  align-items: center;
  box-shadow: 0 0 2px #aaa;
  border-radius: 0;
}

.ParkPhoto .search-box input {
  height: 1.5rem;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  background: #fff;
  padding: 0 0.5rem;
  border: 1px solid rgba(238, 51, 51, 0.5);
  border-right: none;
  border-radius: 4px 0 0 4px;
  color: #666;
  outline: 0;
  flex: 3;
  font-size: 0.7rem;
}
.ParkPhoto .search-box button {
  height: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  outline: 0;
  border-radius: 0 4px 4px 0;
  /* background: rgb(224, 51, 71); */
  background: linear-gradient(rgb(252, 60, 60), rgb(240, 123, 55));
  color: #fff;
  border: none;
  flex: 1;
  font-size: 0.7rem;
}

/* 20190919 修改 */
.hidden {
  display: none;
}
.photo-list .item .vote .add {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 0.6rem;
  font-weight: 600;
  color: #333;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.photo-list .item .vote .show_add {
  animation: add 1s linear 1 both;
}
@keyframes add {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -150%);
  }
}
</style>
