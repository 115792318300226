// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import router from './router'
import $ from "jquery"
import filters from './assets/utils/filters.js'
import directives from './assets/utils/directives.js'
import common from './assets/utils/common.js'
import LuckyCard from './assets/utils/lucky-card.js'

import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
// 实例化Vue的filter
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]));

Vue.config.productionTip = false;
Vue.use(MintUI);

/*手势库*/
import AlloyFinger from 'alloyfinger'
import AlloyFingerPlugin from 'alloyfinger/vue/alloy_finger_vue'

Vue.use(AlloyFingerPlugin, {
  AlloyFinger
})

/**
 * 分页滑动效果
 */


// import style
//import 'swiper/swiper.min.css'


// If you use Swiper 6.0.0 or higher  import 'swiper/swiper-bundle.css'


//Vue.use(VueAwesomeSwiper, /* { default options with global component } */)


//---------------------leaflet
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

/* leaflet icon */
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})




import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)


import mapBoxGl from 'mapbox-gl'
Vue.prototype.$mapboxgl = mapBoxGl

/**
 * 得到user_id
 * @param user_id
 * @returns {*}  返回值为user_id
 */
Vue.prototype.get_User_Id = (user_id) => {
  var user_info = "";
  var _this = app;

  // 解决html5页面在最美旅行家APP里跳转到登录页面问题
  var myapp = Vue.prototype.hybrid.hybrid.versions.myapp || Vue.prototype.hybrid.hybrid.versions.myiOSapp;

  if (!myapp) {
    user_info = Vue.prototype.check_login();
    if (user_info) {
      user_id = user_info.user_id;
      return user_id;
    } else {
      localStorage.setItem('login_url', location.hash);
      _this.$router.push({ path: '/my/login' })
    }
  }
};
/**
 * 获取当前时间
 * 格式YYYY-MM-DD
 */
Vue.prototype.getNowFormatDate = function () {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
};


/* eslint-disable no-new */
var app = new Vue({
  el: '#app',
  router,
 
  render: h => h(App),
  components: { App },
  mounted() {
    this.getTravelNotes();
  },
  methods: {
    getTravelNotes() {
      ;
      (function (win, lib) {
        var doc = win.document;
        var docEl = doc.documentElement;
        var metaEl = doc.querySelector('meta[name="viewport"]');
        var flexibleEl = doc.querySelector('meta[name="flexible"]');
        var dpr = 0;
        var scale = 0;
        var tid;
        var flexible = lib.flexible || (lib.flexible = {});

        if (metaEl) {
          console.warn('将根据已有的meta标签来设置缩放比例');
          var match = metaEl.getAttribute('content').match(/initial\-scale=([\d\.]+)/);
          if (match) {
            scale = parseFloat(match[1]);
            dpr = parseInt(1 / scale);
          }
        } else if (flexibleEl) {
          var content = flexibleEl.getAttribute('content');
          if (content) {
            var initialDpr = content.match(/initial\-dpr=([\d\.]+)/);
            var maximumDpr = content.match(/maximum\-dpr=([\d\.]+)/);
            if (initialDpr) {
              dpr = parseFloat(initialDpr[1]);
              scale = parseFloat((1 / dpr).toFixed(2));
            }
            if (maximumDpr) {
              dpr = parseFloat(maximumDpr[1]);
              scale = parseFloat((1 / dpr).toFixed(2));
            }
          }
        }

        if (!dpr && !scale) {
          var isAndroid = win.navigator.appVersion.match(/android/gi);
          var isIPhone = win.navigator.appVersion.match(/iphone/gi);
          var devicePixelRatio = win.devicePixelRatio;
          if (isIPhone) {
            // iOS下，对于2和3的屏，用2倍的方案，其余的用1倍方案
            if (devicePixelRatio >= 3 && (!dpr || dpr >= 3)) {
              dpr = 3;
            } else if (devicePixelRatio >= 2 && (!dpr || dpr >= 2)) {
              dpr = 2;
            } else {
              dpr = 1;
            }
          } else {
            // 其他设备下，仍旧使用1倍的方案
            dpr = 1;
          }
          scale = 1 / dpr;
        }

        docEl.setAttribute('data-dpr', dpr);
        if (!metaEl) {
          metaEl = doc.createElement('meta');
          metaEl.setAttribute('name', 'viewport');
          metaEl.setAttribute('content', 'initial-scale=' + scale + ', maximum-scale=' + scale + ', minimum-scale=' + scale + ', user-scalable=no');
          if (docEl.firstElementChild) {
            docEl.firstElementChild.appendChild(metaEl);
          } else {
            var wrap = doc.createElement('div');
            wrap.appendChild(metaEl);
            doc.write(wrap.innerHTML);
          }
        }

        function refreshRem() {
          var width = docEl.getBoundingClientRect().width;
          if (width / dpr > 540) {
            width = 540 * dpr;
          }
          var rem = width / 21;
          docEl.style.fontSize = rem + 'px';
          flexible.rem = win.rem = rem;
        }

        win.addEventListener('resize', function () {
          clearTimeout(tid);
          tid = setTimeout(refreshRem, 300);
        }, false);
        win.addEventListener('pageshow', function (e) {
          if (e.persisted) {
            clearTimeout(tid);
            tid = setTimeout(refreshRem, 300);
          }
        }, false);

        if (doc.readyState === 'complete') {
          doc.body.style.fontSize = 12 * dpr + 'px';
        } else {
          doc.addEventListener('DOMContentLoaded', function (e) {
            doc.body.style.fontSize = 12 * dpr + 'px';
          }, false);
        }


        refreshRem();

        flexible.dpr = win.dpr = dpr;
        flexible.refreshRem = refreshRem;
        flexible.rem2px = function (d) {
          var val = parseFloat(d) * this.rem;
          if (typeof d === 'string' && d.match(/rem$/)) {
            val += 'px';
          }
          return val;
        }
        flexible.px2rem = function (d) {
          var val = parseFloat(d) / this.rem;
          if (typeof d === 'string' && d.match(/px$/)) {
            val += 'rem';
          }
          return val;
        }

      })(window, window['lib'] || (window['lib'] = {}));
    }
  }
})