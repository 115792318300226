<template>
  <div class="wrap" v-title data-title="忘记密码">
    <mt-header fixed title="忘记密码">
      <a slot="left">
        <mt-button icon="back" @click="$router.go(-1)"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="form-box">
        <div class="form-row flex-box">
          <i class="icon-phone"></i>
          <div class="form-input" id="userphone">
            <input type="number" placeholder="请输入你的手机号" v-model="userphone" />
          </div>
        </div>
        <div class="form-row flex-box">
          <i class="icon-vCode"></i>
          <div class="form-input" id="ImgCode">
            <input type="text" v-model="ImgCode" placeholder="请输入图片验证码" />
          </div>
          <img class="imgVcode" @click="refresh" />
        </div>
        <div class="form-row flex-box">
          <i class="icon-vCode"></i>
          <div class="form-input" id="vCode">
            <input type="number" v-model="vCode" placeholder="请输入短信验证码" />
          </div>
          <div class="getVcode" @click="verification">获取验证码</div>
        </div>
        <div class="form-row flex-box">
          <i class="icon-pw"></i>
          <div class="form-input" id="password">
            <input type="password" placeholder="请输入你的密码" v-model="password" />
          </div>
          <i class="icon-noSee" @click="showpass"></i>
        </div>
        <div class="form-tips"></div>
        <div class="btn">
          <mt-button type="primary" @click="login" id="login-submit" class="mint-header">提交</mt-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      userphone: "",
      password: "",
      ImgCode: "",
      vCode: "",
      token: "",
      issettime: false //倒计时是否能点击
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      this.$ajax("post", "index/send_msg_v2", "", ret => {
        //图片 验证码 获取token
        if (ret.data.id == 0) {
          this.token = ret.data.data.token;
          var url =
            "https://www.zmlxj.com/api.php/index/send_msg_v2?token=" +
            this.token +
            "&";
          $("img.imgVcode")
            .attr("data-url", url)
            .attr("src", url);
        }
      });
    },
    refresh(e) {
      //图片验证码的 点击 刷新
      $(e.target).attr(
        "src",
        $(e.target).attr("data-url") + "rand=" + Math.random()
      );
    },
    settime(val, t) {
      //倒计时
      var that = this;
      if (t == 0) {
        val.innerHTML = "重新发送";
        this.issettime = false;
      } else {
        val.innerHTML = t + "秒后重发";
        t--;
      }
      setTimeout(function() {
        that.settime(val, t);
      }, 1000);
    },
    verification() {
      if (this.issettime) return;
      var reg = /^1[3|4|5|7|8][0-9]{9}$/; //验证规则/^1[3|4|5|7|8][0-9]{9}$/
      if (!reg.test(this.userphone)) {
        Toast("请输入手机号码");
        return false;
      }
      if (!this.ImgCode || this.ImgCode.length < 4) {
        Toast("请输入 图片 验证码");
        return false;
      }
      var that = this;
      var url = $("img.imgVcode").attr("data-url"); // 获取验证码的 url
      $.post(
        url,
        { user_tel: this.userphone, flage: 5, verify: this.ImgCode },
        function(ret, err) {
          if (ret.id == 0) {
            that.settime($(".getVcode")[0], 60);
            that.issettime = true;
            Toast("验证码发送成功");
          } else if (ret.id == -1) {
            //客户端没有传递值到服务器
            Toast(ret.msg);
          }
        }
      );
    },
    showpass(e) {
      this.show = !this.show;
      if (this.show) {
        $("#password input").attr("type", "text");
        $(e.target)
          .removeClass("icon-noSee")
          .addClass("icon-see");
      } else {
        $("#password input").attr("type", "password");
        $(e.target)
          .removeClass("icon-see")
          .addClass("icon-noSee");
      }
    },
    login() {
      var that = this;
      if (
        this.vCode &&
        this.userphone &&
        this.vCode.length > 3 &&
        this.userphone.length > 10
      ) {
        this.$ajax(
          "post",
          "index/retrievePwd_v2",
          {
            user_tel: this.userphone,
            user_pwd: this.password,
            code: this.vCode
          },
          ret => {
            //图片 验证码 获取token
            if (ret.data.id == 0) {
              //修改密码成功-跳转到登录界面
              Toast(ret.data.msg);
              setTimeout(that.$router.push({ path: "/my/login" }), 1000);
            } else if (ret.data.id == -2) {
              //没有注册
              Toast(ret.data.msg);
              setTimeout(that.$router.push({ path: "/my/UserReg" }), 1000);
            } else if (ret.data.id == -1) {
              Toast(ret.data.msg);
            }
          }
        );
      } else {
        Toast("不能有空值");
      }
    }
  }
};
</script>
<style scoped>
.form-row:nth-child(1) {
  padding-top: 1rem;
}
</style>