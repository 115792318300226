<template>
  <div class="ActivityMap" v-title data-title="地图领取">
    <!--v-if="is_weixin"-->
    <div id="container" style="width:100%; height:100%"></div>
    <div v-if="this.link_production.id == undefined || this.link_production.id == ''">
      <ul class="top_btn" v-if="!this.is_staff">
        <li @click="click_tab(1)">
          <img src="../../assets/img/restaurant_mak.png" alt />
          <span>餐馆</span>
        </li>
        <li @click="click_tab(2)">
          <img src="../../assets/img/personage_mak.png" alt />
          <span>个人</span>
        </li>
        <router-link :to="{path:'/parkPhoto/ParkPhoto',query:{aid:aid}}" tag="li">返回首页</router-link>
      </ul>
      <div class="staff_button" @click="show_staff">
        <img src="../../assets/img/staff_entrance.png" alt v-if="!this.is_staff" />
        <img src="../../assets/img/user.png" alt v-if="this.is_staff" />
      </div>
    </div>
    <!--地图左下角功能按钮-->
    <div class="left_but">
      <div class="mak_tab">
        <img
          src="../../assets/img/map_3d.png"
          alt
          class="map_3d"
          @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
          v-if="is_tab_map_3d"
        />
        <img
          src="../../assets/img/map_3d_yes.png"
          alt
          class="map_3d"
          @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
          v-if="!is_tab_map_3d"
        />
      </div>
      <div class="mak_tab">
        <!--地图 + -  -->
        <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
        <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
      </div>
    </div>
  </div>
  <!--<div v-else class="foot_noinfo"><img src="/static/img/foot_noinfo@2x.14391c2.png" alt="">
  <p>请在微信页面打开链接</p></div>-->
</template>
<script>
import { Toast } from "mint-ui";
import { MessageBox } from "mint-ui";
export default {
  data() {
    return {
      map: {},
      aid: this.$route.query.aid,
      link_production: {
        id: this.$route.query.production_id,
        type: this.$route.query.type,
        spots_name: this.$route.query.spots_name,
        lng: this.$route.query.lng,
        lat: this.$route.query.lat
      },
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      is_tab_map_3d: true,
      makdata: [],
      marker: [],
      user_id: "",
      is_staff: false
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      /*if (!this.is_weixin) {
                    return false;
                }*/
      if (this.check_login()) {
        this.user_id = this.check_login().user_id;
      }
      this.map = this.map_init();
      this.getDeta();
    },
    //            获取数据
    getDeta(user_id) {
      var that = this;
      if (this.link_production.id) {
        this.map_add_Marker({
          map: this.map,
          list_data: [this.link_production],
          type: "activity",
          aid: this.aid
        });
      } else {
        this.$ajax(
          "post",
          "SpotsRace/get_map_data",
          { aid: this.aid, user_id: user_id },
          ret => {
            that.makdata = ret.data.data;
            if (user_id) {
              this.map_add_Marker({
                map: this.map,
                list_data: ret.data.data,
                type: "activity",
                is_show_predetermine: false,
                aid: this.aid,
                click_set: this.click_set
              });
            } else {
              this.map_add_Marker({
                map: this.map,
                list_data: ret.data.data,
                type: "activity",
                is_show_predetermine: true,
                aid: this.aid,
                //                            点击预订
                click_reserve: this.click_reserve
              });
            }
          }
        );
      }
    },
    //            点击预定
    click_reserve(data) {
      if (this.user_id) {
        MessageBox({
          title: "确认预定吗？",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            this.$ajax(
              "post",
              "SpotsRace/modify_spots",
              {
                aid: this.aid,
                spots_id: data.id,
                user_id: this.user_id
              },
              ret => {
                if (ret.data.id == 0) {
                  //                                    预订成功重新请求数据
                  this.getDeta();
                } else {
                  Toast({
                    message: ret.data.msg,
                    iconClass: "iconfont icon-fail",
                    duration: 1000
                  });
                }
              }
            );
          }
        });
      } else {
        MessageBox("请先登录").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login", query: { log_type: 1 } });
          }
        });
      }
    },
    //            点击餐馆个人切换
    click_tab(type) {
      this.map.clearMap();
      var mak_show = [];
      this.makdata.forEach(function(data) {
        if (data.is_seller == type) {
          mak_show.push(data);
        }
      });
      //                this.map_add_Marker(this.map, mak_show, 'activity', null, this.aid)
      this.map_add_Marker({
        map: this.map,
        list_data: mak_show,
        type: "activity",
        is_show_predetermine: true,
        aid: this.aid,
        //                            点击预订
        click_reserve: this.click_reserve
      });
    },
    //            点击配送人员、用户切换
    show_staff() {
      this.map.clearMap();
      this.is_staff = !this.is_staff;
      if (this.is_staff) {
        if (this.user_id) {
          this.getDeta(this.user_id);
        } else {
          MessageBox("请先登录").then(action => {
            if (action == "confirm") {
              localStorage.setItem("login_url", location.hash);
              this.$router.push({ path: "/my/login", query: { log_type: 1 } });
            }
          });
        }
      } else {
        this.getDeta();
      }
    },
    click_set(e, data) {
      MessageBox({
        title: "确认状态为已送达吗？",
        showCancelButton: true
      }).then(action => {
        if (action == "confirm") {
          this.$ajax(
            "post",
            "SpotsRace/modify_is_send",
            {
              id: e.target.id,
              user_id: this.user_id
            },
            ret => {
              if (ret.data.id == 0) {
                //                      设置已送到成功重新请求数据
                this.getDeta(this.user_id);
              } else {
                Toast(ret.data.msg);
              }
            }
          );
        }
      });
    }
  }
};
</script>
<style scoped>
.ActivityMap .staff_button {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  width: 3rem;
}

.ActivityMap .staff_button img {
  width: 100%;
}

.ActivityMap .amap-marker-label p {
  font-size: 0.7rem;
}

.ActivityMap .amap-marker-label p span {
  color: #ff9a80;
}

.ActivityMap {
  width: 100%;
  height: 100%;
}

.ActivityMap .top_btn {
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  background-color: #fff;
  border-radius: 0.4rem;
  padding: 0.2rem;
  box-shadow: 0.1rem 0.1rem 0.2rem #999;
}

.ActivityMap .top_btn li {
  border-bottom: 0.08rem solid #ccc;
  padding: 0.1rem;
  font-size: 0.7rem;
  line-height: 2rem;
  text-align: center;
}

.ActivityMap .top_btn li:last-child {
  border: none;
}

.ActivityMap .top_btn img {
  height: 2rem;
  vertical-align: middle;
  margin: 0 0.4rem;
}

.ActivityMap .top_btn span {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
}

.amap-info .center .content_box .reward_list li {
  line-height: 1.6rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.amap-info .center .content_box .reward_list button {
  background: none;
  border: 0;
  color: #f43a0c;
  padding: 0;
  padding-right: 1rem;
  flex: 1;
  outline: none;
}
.amap-icon img {
  width: 100%;
}
.amap-marker-label {
  border: 1px solid #ff9a80;
  border-radius: 0.4rem;
  font-size: 0.5rem;
  line-height: 1rem;
  padding: 0 0.5rem;
  top: 2rem !important;
}
.amap-marker-label {
  background-color: hsla(0, 0%, 100%, 0.4);
}
.left_but {
  position: absolute;
  left: 0.5rem;
  bottom: 4.6rem;
}
.left_but .mak_tab {
  background-color: #fff;
  width: 2rem;

  box-sizing: border-box;

  box-shadow: 2px 2px 5px #ccc;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
}

.left_but .mak_tab img {
  width: 100%;
  padding: 0.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.left_but .mak_tab img:first-of-type {
  border-bottom: 1px solid #ccc;
}
.left_but .mak_tab.map_my_location img,
.left_but .mak_tab img:last-child {
  border: none;
}
.amap-info > div {
  width: 85%;
  left: 6% !important;
}
.amap-info .center > div {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  line-height: 1.4;
  overflow: auto;
  box-shadow: 0 3px 14px rgba(0, 0, 100, 0.6);
  text-align: left;
  font-size: 0.7rem;
  min-width: 12.8rem;
  position: relative;
  border-radius: 5px;
}
.amap-info .center .titleD {
  border-bottom: 1px solid #f5f5f5;
}
.amap-info .center .closeX {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: #c3c3c3;
  text-decoration: none;
  font: 700 16px/14px Tahoma, Verdana, sans-serif;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.amap-info .center .content_box {
  margin-top: 0.4rem;
}
.amap-info .center .bottom_box,
.amap-info .center .btmtip {
  display: flex;
}
.amap-info .center .btmtip.jingdian {
  background-image: linear-gradient(10turn, #439dff, #95b5ff);
}
.amap-info .center .btmtip {
  margin-right: 5px;
  min-width: 3rem;
  max-width: 7rem;
  text-align: center;
  height: 1.4rem;
  line-height: 1.4rem;
  box-sizing: border-box;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.amap-info .center .btmtip.luxian {
  background-image: linear-gradient(10turn, #ec1447, #ff6767);
}
.amap-info .center .btmtip.yuding {
  background-image: linear-gradient(10turn, #ff9d42, #ffbf83);
}
.amap-info .center .sharp {
  height: 23px;
  margin: 0 auto;
  overflow: hidden;
  top: -1px;
  width: 30px;
  border-radius: 0;
  position: relative;
  display: block;
}
.amap-info .center .title_,
.amap-info .center .titleD {
  padding-bottom: 0.4rem;
  box-sizing: border-box;
  font-size: 0.9rem;
}
.amap-info .center .titleD {
  border-bottom: 1px solid #f5f5f5;
}
.center .btmtip {
  font-size: 0.6rem;
  padding: 2px 5px;
  background-color: #ff9a80;
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  border-radius: 2px;
}
</style>