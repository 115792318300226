<template>
  <div class="set" v-title data-title="设置">
    <mt-header fixed title="设置">
      <router-link to="/my" slot="left">
        <mt-button icon="back"></mt-button>
      </router-link>
    </mt-header>
    <div class="page-content">
      <div class="menu-list">
        <ul>
          <router-link to="/rests/DownloadApp">
            <li>
              手机版下载
              <div class="btn go fr"></div>
            </li>
          </router-link>
          <router-link to="/rests/about">
            <li>
              关于我们
              <div class="btn go fr"></div>
            </li>
          </router-link>
          <router-link to="/rests/feedback">
            <li>
              意见反馈
              <div class="btn go fr"></div>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="btn btn_exit">
        <mt-button type="primary" @click="exit" class="mint-header">退出登录</mt-button>
      </div>
    </div>
  </div>
</template>
<script>
import { MessageBox } from "mint-ui";
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      user_id: this.get_User_Id()
    };
  },
  methods: {
    exit() {
      var script = document.getElementsByTagName("script"),
        is_add = true,
        that = this;
      for (var i = 0; i < script.length; i++) {
        if (
          script[i].src ==
          "https://qzonestyle.gtimg.cn/qzone/openapi/qc_loader.js"
        ) {
          is_add = false;
        }
      }
      if (is_add) {
        let qqscript = document.createElement("script");
        qqscript.type = "text/javascript";
        qqscript.src = "https://qzonestyle.gtimg.cn/qzone/openapi/qc-1.0.1.js";
        qqscript.charset = "utf-8";
        document.body.appendChild(qqscript);

        let wbscript = document.createElement("script");
        wbscript.type = "text/javascript";
        wbscript.src =
          "https://tjs.sjs.sinajs.cn/open/api/js/wb.js?appkey=887705104";
        wbscript.charset = "utf-8";
        document.body.appendChild(wbscript);
      }

      MessageBox.confirm("确定执行此操作?").then(action => {
        if (action == "confirm") {
          //删除本地存储登录信息
          localStorage.removeItem("user_info");
          //删除本地存储跳转地址
          localStorage.setItem("login_url", "/");
          //QQ三方登陆退出
          QC.Login.signOut();
          //微博三方登录退出
          WB2.logout();
          Toast("退出登录成功");
          that.$router.push({ path: "/" });
        }
      });
    }
  }
};
</script>
<style scoped>
.btn {
  text-align: center;
}
.btn .mint-button--primary {
  width: 90%;
  height: 1.6rem;
}
.set .mint-header.is-fixed {
  background: #fafafa;
  color: #000;
}
.menu-list {
  background-color: #fff;
}

.menu-list ul {
  border-bottom: 0.3rem solid #f2f2f2;
}

.menu-list ul li {
  font-size: 0.6rem;
  color: #666;
  text-align: left;
  padding: 0 0.6rem;
  border-top: 1px solid #f2f2f2;
  line-height: 2rem;
  height: 2rem;
}

.menu-list ul li:active {
  background-color: #fcfcfc;
}

.menu-list ul a {
  color: #666;
  display: block;
}
.set .btn.go {
  background: url(../../assets/img/feed-title-more.png) center center no-repeat;
  background-size: 0.5rem 0.7rem;
  width: 0.8rem;
  height: 2rem;
}
.set .btn_exit {
  margin-top: 24px;
}
.set .btn_exit .mint-button-text {
  font-size: 0.6rem;
}
</style>