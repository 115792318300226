<template>
  <!--足迹列表模块-->
  <div class="feed-list track_list normal" v-if="info.length != 0">
    <div class="feed-title" v-if="is_tit">
      <h1>足迹列表</h1>
    </div>
    <div class="feed-title tit2" v-if="is_tit2">
      <h1>
        <i class="iconfont icon-zuji"></i>精品足迹
      </h1>
    </div>
    <div class="feed-title tit2" v-if="is_tit3">
      <h1>
        <i class="iconfont icon-zuji"></i>相关足迹
      </h1>
    </div>
    <router-link to="/footprints/AwardList" tag="div" class="fr redpack" v-if="is_isred">
      <img src="../assets/img/footprint_red.png" alt />
    </router-link>
    <mt-loadmore
      :bottom-method="loadBottom"
      :bottom-all-loaded="allLoaded"
      ref="loadmore"
      :auto-fill="false"
    >
      <div class="track_item feed-item feed-item-body" v-for="(k,i) in info" :key="k.id" :id="k.id">
        <a
          href="javascript:;"
          class="track_item_body"
          @click.prevent.stop="click_path_new(k.is_spots,k.user_id,k.id,k.popular_type_fk_id)"
        >
          <div class="feed-item-top">
            <div
              v-if="(k.red_packet!=2) && (type !='parkFoot')&&(k.user_id == my_id)"
              @click.stop="path_map(k.red_packet,k.park_id,k.lng,k.lat,k.user_id,k.red_packet_id,k.id,k.park_name)"
            >
              <img src="../assets/img/footprint_red.png" alt />
            </div>
            <div @click.stop="user_avatar(k.user_id)" class="user">
              <div class="avatar">
                <img class="img" :src="k.user_icon" v-if="k.user_icon" />
                <div class="img" v-else></div>
              </div>
              <h1>
                <span class="name">{{k.user_nick}}</span>
              </h1>
              <h2>
                <span class="time">{{k.add_time}}</span>
              </h2>
            </div>
          </div>
          <div class="track-item-title">
            <a>{{k.title}}</a>
          </div>
          <div class="track-item-tag" v-if="k.content">
            <a
              href="javascript:;"
              v-html="k.content"
              :class="{active:!is_active}"
              @click="is_active=!is_active"
            ></a>
          </div>
          <div class="img-box" :class="getRowType(k.img.length)" v-if="k.img">
            <div v-for="(imgitem,imgIndex) in k.img" :key="imgIndex" class="item">
              <img
                v-if="imgIndex < 9"
                class="img"
                v-lazy="imgitem.img"
                @click.stop="onClickMe(k.img,imgIndex)"
              />
            </div>
          </div>
        </a>
        <router-link
          tag="div"
          :to="{path:'/footprints/RedPacketMap',
                                 query:{type:1,r_lng:k.lng,r_lat:k.lat,park_id:k.park_id,user_id:k.user_id,spot_id:k.id,title:k.title}}"
          class="note-track-card"
          v-if="k.foot_img"
        >
          <div class="map-pic">
            <img v-lazy="k.foot_img" alt />
            <div @click.stop.prevent="map_open_(k.lng,k.lat,k.title,k.address)">
              <!--:to="{path:'/footprints/MapNavigation',query:{id:k.id,lng:k.lng,lat:k.lat,park_name:k.park_name}}"-->
              <img src="../assets/img/home_navigation.png" alt class="navigation" />
            </div>
            <p class="foot_heigth">
              <span>海拔:{{k.height}}&nbsp;&nbsp;&nbsp;时间:{{k.spots_time}}</span>
            </p>
          </div>
        </router-link>
        <a
          href="javascript:;"
          class="track_item_body"
          @click="parkfoot(k.park_id,'parkFoot',k.park_lng,k.park_lat)"
        >
          <div class="feed-item-tag last">
            <a href="javascript:;">
              {{k.park_name}}
              <span class="blue">#{{k.snum}}#</span>
            </a>
          </div>
        </a>
        <div class="feed-item-tool">
          <a class="zan" :class="{yes:k.like==1}" @click="zan(k.id,i)">
              <i class="iconfont iconzan"></i>
            <span>{{k.like_numbers}}</span>
          </a>
          <a
            href="javascript:;"
            @click="click_path_new(k.is_spots,k.user_id,k.id,k.popular_type_fk_id)"
            class="ping"
          >
            <i class="iconfont iconliuyan"></i>
            <span>{{k.discuss_numbers}}</span>
          </a>
          <!--:to="{path:'/footprints/FootprintsParticulars',query:{user_id:k.user_id,id:k.id,park_id:k.park_id}}"-->
          <a class="zhuan">
              <i class="iconfont iconshare4"></i>
            <span>{{k.transpond_numbers}}</span>
          </a>
        </div>
      </div>
    </mt-loadmore>
    <div class="preview-content" v-if="is_preview" @click="is_preview=false">
      <mt-swipe :auto="0" :showIndicators="true" :defaultIndex="index">
        <mt-swipe-item v-for="(item,i) in message" :key="i">
          <a href="javascript:;">
            <img :src="item.img_res+'&imageMogr/auto-orient'" alt />
          </a>
        </mt-swipe-item>
      </mt-swipe>
    </div>
  </div>
  <!--
    <div v-else class="foot_noinfo"><img src="/static/img/foot_noinfo@2x.14391c2.png" alt="">
  <p>暂无数据</p></div>-->
</template>
<script>
import { Toast } from "mint-ui";
import { MessageBox } from "mint-ui";
import { Indicator } from "mint-ui";
export default {
  props: {
    datainfo: Array
  },
  data() {
    return {
      info: [], //获取的数据列表
      page: 1, //数据页码
      allLoaded: false, //加载更多
      is_isred: false, //是否显示首页红包按钮
      my_id: "",
      user_id: "",
      park_id: "",
      bottomStatus: "",
      type: "",
      is_preview: false, //点击图片模块
      message: [], //点击显示图片数组数据
      index: 0, //点击显示第几张图片
      is_parklink: true, //点击公园是否跳转
      is_tit: true, //是否显示tit
      is_tit2: false, //是否显示精品足迹标题
      is_tit3: false, //是否显示相关足迹标题
      is_active: true,
      popular_type_fk_id: 7, //默认是其他标签
      is_weixin: this.hybrid.hybrid.versions.is_weixin
    };
  },
  mounted() {
    this.unload();
    if (this.is_weixin) {
      this.wx_share(window.location.href);
    }
  },
  methods: {
    parentMsg: function(data) {
      this.info = data.info;
      this.user_id = data.my_id;
      this.is_isred = data.isred;
      this.is_tit = data.tit;
      this.is_tit2 = data.tit2;
      this.is_tit3 = data.tit3;
      this.is_parklink = data.parklink;
      this.type = data.typename; //页面类型  从哪点击的
      this.popular_type_fk_id = data.popular_type_fk_id; //用于足迹被添加了商户标签之后
      //这个活动有作品数据是，需要这个值，用于在SpotsRace/Search接口中的aid字段参数
    },
    unload() {
      if (this.check_login()) {
        this.my_id = this.check_login().user_id;
      }
      window.addEventListener("scroll", () => {
        if (document.body.scrollTop > 500) {
          // $('.publish').show();//原来为hide()---动态的控制发布足迹的按钮 20180321
        } else {
          // $('.publish').show();
        }
      });
    },
    click_path_new(is_spots, user_id, id, popular_type_fk_id) {
      if (is_spots == 1) {
        this.$router.push({
          path: "/footprints/PrimaryScenic",
          query: { sid: id, id_: 0 }
        });
      } else {
        this.$router.push({
          path: "/footprints/FootprintsParticulars",
          query: {
            user_id: user_id,
            id: id,
            popular_type_fk_id: popular_type_fk_id
          }
        });
      }
    },
    parkfoot(park_id, type, lng, lat) {
      if (this.is_parklink) {
        this.$router.push({
          path: "/footprints/FootprintsIndex",
          query: { pid: park_id, type: type, lng: lng, lat: lat }
        });
      } else {
        this.$emit("getparkdata", park_id, lng, lat);
      }
    },
    //            加载更多
    loadBottom() {
      var that = this;
      var itemLen = that.info.length;
      setTimeout(() => {
        if (itemLen % 10 == 0) {
          that.page++;
          //                        that.getData(that.page);
          //                调用主模块方法
          this.$emit("ievent", that.page);
        } else {
          that.allLoaded = true;
          Toast("没有更多了");
        }
        that.$refs.loadmore.onBottomLoaded();
      }, 1500);
    },
    //            点赞
    zan(spot_id, i) {
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "确认登陆吗？",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login" });
          }
        });
      } else {
        this.$ajax(
          "post",
          "foot/click_spots",
          {
            user_id: this.check_login().user_id,
            spot_id: spot_id
          },
          res => {
            if (res.data.id == 0) {
              if (this.info[i].like == 0) {
                this.info[i].like_numbers++;
                this.$set(this.info[i], "like", 1);
                this.$set(
                  this.info[i],
                  "like_numbers",
                  this.info[i].like_numbers
                );
              } else {
                this.info[i].like_numbers--;
                this.$set(this.info[i], "like", 0);
                this.$set(
                  this.info[i],
                  "like_numbers",
                  this.info[i].like_numbers
                );
              }
            }
          }
        );
      }
    },
    // 点击更多显示更多内容
    unfold(e) {
      $(e.target)
        .siblings()
        .eq(0)
        .css({ "max-height": "initial" });
    },
    onClickMe(img, index) {
      this.is_preview = true;
      this.message = img;
      this.index = index;
      return false;
    },
    getRowType(value) {
      var rowType = "";
      if (value == 1) {
        rowType = "row1";
      } else if (value == 2 || value == 4) {
        rowType = "row2";
      } else if (value == 3 || value >= 5) {
        rowType = "row3";
      }
      return rowType;
    },
    //            点击右侧红包，如果已领取跳转到我的领取记录，未领取跳转到红包地图抢红包
    path_map(
      red_packet,
      park_id,
      lng,
      lat,
      user_id,
      red_packet_id,
      id,
      park_name
    ) {
      if (red_packet == 0) {
        this.$router.push({
          path: "/footprints/RedPacketMap",
          query: {
            type: 1,
            park_id: park_id,
            r_lng: lng,
            r_lat: lat,
            user_id: user_id,
            spot_id: id,
            title: park_name
          }
        });
      } else if (red_packet == 1) {
        this.$router.push({
          path: "/my/MyAwardInfo",
          query: {
            type: 1,
            id: red_packet_id,
            spot_id: id,
            label_id: 154
          }
        });
      }
    },
    user_avatar(user_id) {
      if (user_id != this.user_id) {
        this.$router.push({
          name: "otherIndex",
          params: { uid: user_id }
        });
      }
    },
    map_open_(lng, lat, title, address) {
      if (this.is_weixin) {
        this.wx_nav(window.location.href, lng, lat, title, address);
      } else {
        Toast("请在微信中打开,使用导航功能");
      }
    }
  }
};
</script>
<style scoped>
.track_list {
  position: relative;
  width: 100vw;
  padding: 0 1rem;
  box-sizing: border-box;
  background-color: #fff;
}

.track_list .feed-title {
  width: 100%;
}

.track_list .feed-title h1 {
  position: relative;
  padding-left: 0.5rem;
  color: #000;
  font-size: 1rem;
}

.track_list .feed-title h1::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 1rem;
  width: 0.2rem;
  background-color: #ff9a80;
}

.track_list .feed-title.tit2 {
  border-bottom: 1px solid #f2f2f2;
}

.track_list .feed-title.tit2 h1 {
  border: none;
  display: inline-block;
  margin: 0;
}

.track_list .feed-title.tit2 h1 i {
  color: #ff9a80;
  margin-right: 10px;
}

.track_list .redpack {
  position: fixed;
  top: 310px;
  right: 0px;
  z-index: 1;
}

.track_list .redpack img {
  width: 30px;
}

.preview-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 1000;
}

.preview-content .mint-swipe-items-wrap > div a {
  color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.preview-content .mint-swipe-items-wrap > div img {
  /*width: 100%;*/
  display: inline-block;
  /*height: 100%;*/
  max-height: 100%;
  margin: 0 auto;
  max-width: 100%;
}

.feed-item-top h1 span a {
  color: #ff9a80;
}

.navigation {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
}

.foot_heigth {
  color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  font-size: 0.7rem;
}

.foot_heigth span {
  background-color: rgba(0, 0, 0, 0.4);
  display: inline-block;
  padding: 0 0.4rem;
  color: #fff;
}

.map-pic > img {
  width: 100%;
  margin-top: 3rem;
}

.feed-item {
  display: block;
  position: relative;

  border-bottom: 3px solid #f2f2f2;
}

.track_item_body {
  position: relative;
  display: block;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  color: #000;
  text-decoration: none;
}

.feed-item-top {
  overflow: hidden;
  padding: 1rem 0;
}

.feed-item-top .user {
  position: relative;
  height: 2.4rem;
  padding-left: 2.8rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feed-item-top .avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.4rem;
  height: 2.4rem;
}

.feed-item-top .avatar .img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #f2f2f2;
  background-image: url(../assets/img/touxiang.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.feed-item-top h1 {
  font-size: 0.7rem;
}
.feed-item-top h1 .name {
  color: #333;
  font-size: 0.88rem;
  font-weight: normal;
}

.feed-item-top h2 {
  font-size: 0.68rem;
  font-weight: normal;
  color: #999;
}

.track_item_body .track-item-title a {
  width: 100%;
  font-size: 1rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.track_item_body .track-item-tag {
  width: 100%;
  margin-top: 1rem;
}

.track_item_body .track-item-tag a >>> h1 {
  font-size: 1rem;
  font-weight: normal;
  color: #333;
}

.track_item_body .track-item-tag a >>> .MsoNormal {
  font-size: 0.88rem;
  text-indent: 0 !important;
  text-align: justify;
}

.track_item_body .track-item-tag a >>> img {
  width: 100%;
}

.img-box {
  width: 100%;
  margin-top: 1rem;
  overflow: hidden;
}

.img-box .item,
.img-box .video {
  position: relative;
  float: left;
  width: 50%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.img-box.row1 .item {
  width: 100%;
}

.img-box .item img {
  width: 100%;
  height: 100%;
}

.note-track-card {
  background: #f2f2f2;
  border-radius: 3px;
}
.note-track-card .map-pic {
  width: 90px;
  height: 80px;
  background-size: cover;
  margin-right: 10px;
}
.note-track-card .map-pic {
  width: 100%;
  height: 6rem;
  background-position: 50%;
  background-size: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.map-pic > img {
  width: 100%;
  margin-top: 3rem;
}
.foot_heigth,
.navigation {
  position: absolute;
  right: 0;
  bottom: 0;
}
.navigation {
  display: inline-block;
  height: 100%;
}
.foot_heigth {
  color: #000;
  left: 0;
  z-index: 0;
  font-size: 0.7rem;
}
.foot_heigth span {
  background-color: rgba(0, 0, 0, 0.4);
  display: inline-block;
  padding: 0 0.4rem;
  color: #fff;
}

.track_item_body .feed-item-tag {
  width: 100%;
}

.feed-item-tag {
  margin-top: 0.5rem;
  overflow: hidden;
}

.feed-item-tag a {
  font-size: 0.68rem;
  color: #666;
  background: url(../assets/img/feed-item-tag.png) left 6px no-repeat;
  background-size: 0.68rem;
  padding: 5px 0 5px 1rem;
  margin-right: 10px;
  display: block;
}

.blue {
  color: #ff9a80;
}
.feed-item-tool {
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f2f2f2;
  overflow: hidden;
}
.feed-item-tool a {
  color: #999;
  font-size: 0.8rem;
  text-align: center;
  overflow: hidden;
}

.feed-item-tool .iconfont {
  font-size: 1rem;
}

.feed-item-tool .ping {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.1rem solid #f2f2f2;
  border-width: 0 1px;
}

.img-box.row3 .item {
  width: 33.3333%;
}
.img-box .item img {
  width: 100%;
  height: 100%;
}

.track-item-tag img {
  max-width: 100%;
}
</style>