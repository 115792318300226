<template>
  <div class="wrap userreg" v-title data-title="注册">
    <mt-header fixed title="注册">
      <a slot="left">
        <mt-button icon="back" @click="$router.go(-1)"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="form-box">
        <div class="form-row flex-box">
          <i class="icon-phone"></i>
          <div class="form-input" id="userphone">
            <input type="number" v-model="phone" placeholder="请输入你的手机号" />
          </div>
        </div>
        <div class="form-row flex-box">
          <i class="icon-vCode"></i>
          <div class="form-input" id="ImgCode">
            <input type="text" v-model="ImgCode" placeholder="请输入图片验证码" />
          </div>
          <img class="imgVcode" @click="refresh" />
        </div>
        <div class="form-row flex-box">
          <i class="iconfont iconfankui"></i>
          <div class="form-input" id="vCode">
            <input type="number" v-model="vCode" placeholder="请输入短信验证码" />
          </div>
          <div class="getVcode" @click="verification">获取验证码</div>
        </div>
        <div class="form-row flex-box">
          <i class="icon-pw"></i>
          <div class="form-input" id="password">
            <input type="password" v-model="pwd" placeholder="请输入你的密码" />
          </div>
          <i class="icon-noSee" @click="showpass"></i>
        </div>
        <div class="form-tips"></div>

        <div class="btn">
          <mt-button type="primary" @click="FormOnsubmit" class="mint-header">注册</mt-button>
        </div>
        <div class="go-login">
          已注册过最美旅行家？点击
          <router-link :to="{path:'/my/login'}">登录</router-link>
        </div>
      </div>
      <div class="bot-txt">
        点击上面的注册按钮，即表示您同意
        <router-link :to="{path:'/my/LonginAgreement'}">《用户注册协议》</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      show: false, //是否显示密码
      phone: "", //手机号
      vCode: "", //验证码
      pwd: "", //密码
      ImgCode: "", // 图片 验证码
      issettime: false, //倒计时是否能点击
      token: "",
      uid: this.$route.query.uid
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      this.$ajax("post", "index/send_msg_v2", "", ret => {
        //图片 验证码 获取token
        if (ret.data.id == 0) {
          this.token = ret.data.data.token;
          var url =
            "https://www.zmlxj.com/api.php/index/send_msg_v2?token=" +
            ret.data.data.token +
            "&";
          $("img.imgVcode")
            .attr("data-url", url)
            .attr("src", url);
        }
      });
    },
    refresh(e) {
      //图片验证码的 点击 刷新
      $(e.target).attr(
        "src",
        $(e.target).attr("data-url") + "rand=" + Math.random()
      );
    },
    settime(val, t) {
      //倒计时
      var that = this;
      if (t == 0) {
        val.innerHTML = "重新发送";
        this.issettime = false;
      } else {
        val.innerHTML = t + "秒后重发";
        t--;
      }
      setTimeout(function() {
        that.settime(val, t);
      }, 1000);
    },
    verification() {
      if (this.issettime) return;
      var reg = /^1[3|4|5|7|8][0-9]{9}$/; //验证规则/^1[3|4|5|7|8][0-9]{9}$/
      if (!reg.test(this.phone)) {
        Toast("请输入手机号码");
        return false;
      }
      if (!this.ImgCode || this.ImgCode.length < 4) {
        Toast("请输入 图片 验证码");
        return false;
      }
      var that = this;
      var url = $("img.imgVcode").attr("data-url"); // 获取验证码的 url
      $.post(
        url,
        { user_tel: this.phone, flage: 5, verify: this.ImgCode },
        function(ret, err) {
          if (ret.id == 0) {
            that.settime($(".getVcode")[0], 60);
            that.issettime = true;
            Toast("验证码发送成功");
          } else if (ret.id == -1) {
            //客户端没有传递值到服务器
            Toast(ret.msg);
          }
        }
      );
    },
    FormOnsubmit() {
      //提交
      var data = {
        user_tel: this.phone, //用户的手机号码
        user_pwd: this.pwd, //得到用户输入的密码
        code: this.vCode, //得到用户收到的验证码信息
        user_id: this.uid //分享人的id
      };
      if (
        this.vCode &&
        this.phone &&
        this.vCode.length > 3 &&
        this.phone.length > 10
      ) {
        this.$ajax("post", "index/register_v2", data, ret => {
          console.log(ret);
          if (ret) {
            if (ret.data.id == 0) {
              //注册成功-跳转到登录界面
              Toast(ret.data.msg);
              this.$router.push("/my/login");
            } else {
              Toast(ret.data.msg);
            }
          }
        });
      } else {
        Toast("请正确填写信息");
        return false;
      }
    },
    showpass(e) {
      //是否显示密码
      this.show = !this.show;
      if (this.show) {
        $("#password input").attr("type", "text");
        $(e.target)
          .removeClass("icon-noSee")
          .addClass("icon-see");
      } else {
        $("#password input").attr("type", "password");
        $(e.target)
          .removeClass("icon-see")
          .addClass("icon-noSee");
      }
    }
  }
};
</script>
<style scoped>
.wrap {
  height: 100%;
}
.userreg .form-box {
  padding-top: 34px;
}
.getVcode {
  padding: 0 0.5rem;
  height: 1.4rem;
  line-height: 1.4rem;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  color: #333;
  font-size: 0.7rem;
}
.iconfankui {
  font-size: 1.6rem;
}
.go-login,
.bot-txt {
  margin-top: 24px;
  font-size: 0.6rem;
  text-align: center;
  color: #333;
}
.bot-txt {
  width: 100%;
  position: absolute;
  bottom: 40px;
}
.imgVcode {
  height: 1.6rem;
  border-radius: 3px;
}
</style>