<template>
  <div class="login-container">
    <!-- 顶部 -->
    <div class="login-header">
      <div class="header-back" @click="goBack">
        <i class="iconfont iconback"></i>
      </div>
      <h2>{{getStatsName()}}</h2>
    </div>
    <!-- main 登录/注册/绑定 -->
    <div class="login-main">
      <div class="login-logo" v-if="stats=== 'login'">
        <img src="../../assets/img/login_geoLogo.png" alt />
      </div>
      <!-- 登录框 -->
      <div class="login-box">
        <div class="box-input">
          <i class="iconfont icon44"></i>
          <input type="number" placeholder="请输入你的手机号" maxlength="11" v-model="user_tel" />
        </div>

        <div class="box-input" v-if="stats != 'login'">
          <i class="iconfont iconyanzhengma"></i>
          <input type="text" placeholder="请输入图片验证码" v-model="img_code" />
          <img class="img-code" :src="img_code_src" @click="refresh" />
        </div>

        <div class="box-input" v-if="stats != 'login'">
          <i class="iconfont iconyanzhengma"></i>
          <input type="number" placeholder="请输入短信验证码" v-model="sms_code" />
          <p
            :class="[is_countdown ? 'sms-code-disable' : '','sms-code']"
            @click="getSmsCode"
          >{{countdown}}</p>
        </div>

        <div class="box-input" v-if="stats==='register' || stats==='login' || stats ==='forget'">
          <i class="iconfont iconsuo"></i>
          <input :type="password_type" placeholder="请输入你的密码" v-model="password" />
          <i
            :class="['iconfont', password_type === 'password' ? 'iconmimabukejian' :  'iconmimakejian']"
            @click="togglePasswordShow"
          ></i>
        </div>
        <div class="box-button">
          <button @click="handleClick">{{getStatsName()}}</button>
        </div>

        <div class="box-toggle-register" v-if="stats==='login'">
          <a class="toggle-register" @click="toggleStats('register')">新用户</a>
          <a class="toggle-forget" @click="toggleStats('forget')">忘记密码?</a>
        </div>

        <div class="box-toggle-login" v-if="stats==='register' || stats==='bind'">
          <p>
            已经注册过？ 点击
            <a class="toggle-login" @click="toggleStats('login')">登录</a>
          </p>
        </div>
      </div>
      <!-- 微信登录 -->
      <div class="login-wx" v-if="is_weixin && stats==='login'">
        <div class="login-wx-title">
          <div class="login-wx-line"></div>
          <div class="login-wx-text">第三方登录</div>
        </div>
        <div class="login-wx-content" @click="toggleStats('bind')">
          <i class="iconfont iconweixin"></i>
        </div>
      </div>

      <div class="register-agreement" v-if="stats==='register'">
        <p>
          点击上面的注册按钮，即表示您同意
          <a @click="show_agreement=true">《用户注册协议》</a>
        </p>
      </div>
    </div>
    <!-- 协议 -->
    <transition name="fade">
      <div class="agreement" v-show="show_agreement">
        <div class="agreement-wrapper">
          <div class="agreement-header">
            <h2>最美旅行家服务条款</h2>
            <div class="close" @click="show_agreement=false">
              <i class="iconfont iconclose"></i>
            </div>
          </div>
          <div class="agreement-content">
            <ol>
              <li>
                <h3>特别提示</h3>
                <p>欢迎您来到最美旅行家科普社区。请您仔细阅读以下条款，如果您对本协议的任何条款表示异议，您可以选择不进入最美旅行家科普社区。当您注册成功，无论是进入最美旅行家科普社区，还是在最美旅行家科普社区上发布任何内容（即“内容”），均意味着您（即“用户”）完全接受本协议项下的全部条款。</p>
              </li>
              <li>
                <h3>服务内容</h3>
                <ol>
                  <li>
                    <p>本服务的具体内容由最美旅行家科普社区根据实际情况提供，最美旅行家科普社区保留随时变更、中断或终止部分或全部服务的权利。最美旅行家科普社区不承担因业务调整给用户造成的损失。除非本协议另有其它明示规定，增加或强化目前本服务的任何新功能，包括所推出的新产品，均受到本协议之规范。用户了解并同意，本服务仅依其当前所呈现的状况提供，对于任何用户通讯或个人化设定之时效、删除、传递错误、未予储存或其它任何问题，最美旅行家科普社区均不承担任何责任。</p>
                  </li>
                  <li>
                    <p>最美旅行家科普社区在提供本服务时，可能会对部分服务的用户收取一定的费用。在此情况下，最美旅行家科普社区会在相关页面上做明确的提示。如用户拒绝支付该等费用，则不能使用相关的服务。</p>
                  </li>
                  <li>
                    <p>用户理解，最美旅行家科普社区仅提供相关的本服务，除此之外与相关本服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及移动上网费）均应由用户自行负担。</p>
                  </li>
                </ol>
              </li>
              <li>
                <h3>使用规则</h3>
                <ol>
                  <li>
                    <p>用户注册成功后，最美旅行家科普社区将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。</p>
                  </li>
                  <li>
                    <p>用户须对在最美旅行家科普社区的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用注册帐户导致其他用户误认；否则最美旅行家科普社区有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。</p>
                  </li>
                  <li>
                    <p>用户承诺不得以任何方式利用最美旅行家科普社区直接或间接从事违反中国法律、以及社会公德的行为，最美旅行家科普社区有权对违反上述承诺的内容予以删除。</p>
                  </li>
                  <li>
                    <p>用户不得利用最美旅行家科普社区服务制作、上载、复制、发布、传播或者转载如下内容：</p>
                    <ul>
                      <li>反对宪法所确定的基本原则的；</li>
                      <li>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</li>
                      <li>损害国家荣誉和利益的；</li>
                      <li>煽动民族仇恨、民族歧视，破坏民族团结的；</li>
                      <li>破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
                      <li>散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
                      <li>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
                      <li>侮辱或者诽谤他人，侵害他人合法权益的；</li>
                      <li>含有法律、行政法规禁止的其他内容的信息。</li>
                    </ul>
                  </li>
                  <li>
                    <p>最美旅行家科普社区有权对用户使用最美旅行家科普社区的情况进行审查和监督，如用户在使用最美旅行家科普社区时违反任何上述规定，最美旅行家科普社区或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用最美旅行家科普社区的权利）以减轻用户不当行为造成的影响。</p>
                  </li>
                </ol>
              </li>
              <li>
                <h3>内容所有权</h3>
                <ol>
                  <li>
                    <p>最美旅行家科普社区提供的本服务内容可能包括：文字、软件、声音、图片、录象、图表等。所有这些内容受版权、商标和其它财产所有权法律的保护。</p>
                  </li>
                  <li>
                    <p>用户只有在获得最美旅行家科普社区或其他相关权利人的授权之后才能使用这些内容，不能擅自复制、再造这些内容、或创造与内容有关的派生产品。</p>
                  </li>
                  <li>
                    <p>最美旅行家科普社区鼓励用户充分利用最美旅行家科普社区平台自由地张贴和共享自己的信息，但这些内容必须位于公共领域内，或者用户拥有这些内容的使用权。同时，用户对于其创作并在最美旅行家科普社区上发布的合法内容依法享有著作权及其相关权利。用户不应通过最美旅行家科普社区张贴其它受版权保护的内容。最美旅行家科普社区如果收到正式版权投诉，将会删除这些内容。</p>
                  </li>
                  <li>
                    <p>在最美旅行家科普社区张贴的公开信息。</p>
                  </li>
                  <li>
                    <p>在本协议中，“本服务公开使用区域”系指一般公众可以使用的区域。</p>
                  </li>
                  <li>
                    <p>用户同意已就用户于本服务公开使用区域及本服务其它任何公开使用区域张贴之内容，包括照片、文字或影音资料等内容，授予最美旅行家科普社区全球性、免许可费及非独家的使用权，最美旅行家科普社区可以为了展示、散布及推广张贴前述内容之特定服务目的，将前述内容复制、修改、改写、改编或出版，对于照片及文字影音资料的上述使用，仅为张贴该照片或文字影音于本服务之目的而为之。在用户将前述内容放入本服务期间，使用权持续有效；若用户将前述内容自本服务中删除，则使用权于删除时终止。</p>
                  </li>
                  <li>
                    <p>用户同意已就用户于本服务其它公开使用区域张贴的其它内容，授予最美旅行家科普社区免许可费、永久有效、不可撤销、非独家及可完全再授权之权利在全球使用、复制、修改、改写、改编、发行、翻译、创造衍生性著作，及或将前述内容（部分或全部）加以散布、表演、展示，及 或放入利用任何现在已知和未来开发出之形式、媒体和科技之其它著作物当中。</p>
                  </li>
                  <li>
                    <p>用户发表在最美旅行家科普社区的原创文章、评论、图片等内容的版权均归用户本人所有。</p>
                  </li>
                </ol>
              </li>
              <li>
                <h3>隐私保护</h3>
                <ol>
                  <li>
                    <p>保护用户隐私是最美旅行家科普社区的一项基本政策，最美旅行家科普社区保证不对外公开或向第三方提供用户注册资料及用户在使用本服务时存储在最美旅行家科普社区的非公开内容，但下列情况除外：</p>
                    <ul>
                      <li>事先获得用户的明确授权；</li>
                      <li>根据有关的法律法规要求；</li>
                      <li>按照相关政府主管部门的要求；</li>
                      <li>为维护社会公众的利益；</li>
                      <li>维护最美旅行家科普社区的合法权益；</li>
                    </ul>
                  </li>
                  <li>
                    <p>最美旅行家科普社区可能会与第三方合作向用户提供相关的本服务，在此情况下，如该第三方同意承担与最美旅行家科普社区同等的保护用户隐私的责任，则最美旅行家科普社区可将用户的注册资料等提供给该第三方。</p>
                  </li>
                  <li>
                    <p>在不透露单个用户隐私资料的前提下，最美旅行家科普社区有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</p>
                  </li>
                </ol>
              </li>
              <li>
                <h3>免责声明</h3>
                <ol>
                  <li>
                    <p>用户将照片、个人信息等资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识此类风险的存在。用户明确同意其使用最美旅行家科普社区服务所存在的风险（包括但不限于受到第三方侵权或对第三方造成侵权）将完全由其自己承担；因其使用最美旅行家科普社区服务而产生的一切后果也由其自己承担，最美旅行家科普社区对此不承担任何责任。</p>
                  </li>
                  <li>
                    <p>最美旅行家科普社区不担保本服务一定能满足用户的要求，也不担保本服务不会中断，对本服务的及时性、安全性、准确性、真实性、完整性也都不作担保。</p>
                  </li>
                  <li>
                    <p>对于因不可抗力或最美旅行家科普社区不能控制的原因造成的本服务中断或其它缺陷，最美旅行家科普社区不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>
                  </li>
                  <li>
                    <p>根据有关法律法规，最美旅行家科普社区在此郑重提请用户注意，任何经由本服务以上载、张贴、发送电子邮件或任何其它方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其它资料（以下简称“内容”），无论系公开还是私下传送，均由内容提供者承担责任。最美旅行家科普社区无法控制经由本服务传送之内容，因此不保证内容的正确性、完整性或品质。在任何情况下，最美旅行家科普社区均不为任何内容负责，包含但不限于任何内容之任何错误或遗漏，以及经由本服务以张贴、发送电子邮件或其它方式传送任何内容而衍生之任何损失或损害。但最美旅行家科普社区有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用本服务的全部或部分，保存有关记录，并向有关机关报告。</p>
                  </li>
                  <li>
                    <p>最美旅行家科普社区作为网络服务提供者，对非法转载，虚假发布、盗版行为的发生不具备充分的监控能力。最美旅行家科普社区对他人在网站上实施的此类侵权行为不承担法律责任，侵权的法律责任概由本人承担。</p>
                  </li>
                  <li>
                    <p>用户上传自行编辑的文字信息、或拍摄图片等内容，最美旅行家科普社区有权视为用户已将上述内容同意用于最美旅行家科普社区自有的产品展示或者非盈利的市场活动宣传。</p>
                  </li>
                </ol>
              </li>
              <li>
                <h3>协议修改</h3>
                <ol>
                  <li>
                    <p>最美旅行家科普社区将有权随时修改本协议的有关条款，一旦本协议的内容发生变动，最美旅行家科普社区将会通过适当方式在网站上提示。</p>
                  </li>
                  <li>
                    <p>如果不同意最美旅行家科普社区对本协议相关条款所做的修改，用户有权停止使用本服务。如果用户继续使用本服务，则视为用户接受最美旅行家科普社区对本协议相关条款所做的修改。</p>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Toast, Indicator } from "mint-ui";
export default {
  data() {
    return {
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      open_id: localStorage.getItem("open_id")
        ? localStorage.getItem("open_id")
        : "",
      backUrl: "login",
      id: 1,
      user_id: "", //用户ID
      verify: "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,

      user_info: null,

      //   状态
      stats: "login",
      // 密码
      password_show: false,
      password_type: "password",
      //   图片验证码
      img_code_url: "",
      img_code_src: "",
      // 输入
      password: "",
      user_tel: "",
      img_code: "",
      sms_code: "",
      //   短信验证码
      is_countdown: false,
      countdown: "获取验证码",
      countdown_time: 0,
      countdown_timer: "",

      //   服务协议
      show_agreement: false
    };
  },
  mounted() {
    if (this.token) {
    } else {
      //第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    console.log("token=" + this.token);
    //投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有open_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }
    if (this.open_id) {
    } else {
      this.authorize();
    }
  },
  methods: {
    //   返回上一级
    goBack() {
      if (this.stats === "login") {
        this.$router.go(-1);
      } else {
        this.toggleStats("login");
      }
    },
    //   提交按钮的名字
    getStatsName() {
      if (this.stats === "login") {
        return "登录";
      } else if (this.stats === "register") {
        return "注册";
      } else if (this.stats === "bind") {
        return "绑定";
      } else if (this.stats === "forget") {
        return "重置密码";
      }
    },
    //   登录
    login() {
      let reg = /^1[34578]\d{9}$/;
      if (!reg.test(this.user_tel)) {
        Toast("手机号码有误，请重填");
        return false;
      }
      if (this.password.length == 0) {
        Toast("请输入密码");
        return false;
      }
      let data = { user_tel: this.user_tel, user_pwd: this.password };
      this.$ajax("post", "index/index", data, res => {
        if (res.data.id == 0) {
          let user_info = res.data.data.user_info;
          this.save_arr("user_info", user_info);
          this.$router.push({ path: this.get_login_url() });
        } else if (res.data.id == -2) {
          Toast("请先注册");
        } else if (res.data.id == -3) {
          Toast("帐号或密码错误");
        }
      });
    },
    // 注册
    register() {
      if (
        this.sms_code.length > 3 &&
        this.user_tel.length > 10 &&
        this.password
      ) {
        let data = {
          user_tel: this.user_tel, //用户的手机号码
          user_pwd: this.password, //得到用户输入的密码
          code: this.sms_code, //得到用户收到的验证码信息
          user_id: "" //分享人的id
        };
        this.$ajax("post", "index/register_v2", data, res => {
          console.log(res);

          if (res.data.id == 0) {
            //注册成功-跳转到登录界面
            Toast(res.data.msg);
            this.toggleStats("login");
          } else {
            Toast(res.data.msg);
          }
        });
      } else {
        Toast("请正确填写信息");
        return false;
      }
    },
    // 绑定
    bind() {
      if (this.sms_code.length > 3 && this.user_tel.length > 10) {
        let type = 2; // 微信中

        this.$ajax(
          "post",
          "index/writeData_v2",
          {
            user_tel: this.user_tel,
            app_id: this.open_id,
            type: type,
            platform_type: 5,
            code: this.sms_code
          },
          res => {
            if (res.data.id == 0) {
              Toast("绑定账户成功！");
              let user_info = res.data.data;
              this.save_arr("user_info", user_info);
              this.$router.push({ path: this.get_login_url() });
            } else {
              Toast(res.data.msg);
            }
          }
        );
      } else {
        Toast("手机号或短信验证码错误");
        return false;
      }
    },
    // 忘记密码
    forget() {
      if (
        this.sms_code.length > 3 &&
        this.user_tel.length > 10 &&
        this.password
      ) {
        this.$ajax(
          "post",
          "index/retrievePwd_v2",
          {
            user_tel: this.user_tel,
            user_pwd: this.password,
            code: this.sms_code
          },
          res => {
            if (res.data.id == 0) {
              //修改密码成功-跳转到登录界面
              Toast(res.data.msg);
              setTimeout(() => {
                this.toggleStats("login");
              }, 1000);
            } else if (res.data.id == -2) {
              //没有注册
              Toast(res.data.msg);
              setTimeout(() => {
                this.toggleStats("register");
              }, 1000);
            } else if (res.data.id == -1) {
              Toast(res.data.msg);
            }
          }
        );
      } else {
        Toast("请填写正确的信息");
      }
    },
    //   处理点击
    handleClick() {
      if (this.stats === "login") {
        this.login();
      } else if (this.stats === "register") {
        this.register();
      } else if (this.stats === "bind") {
        this.bind();
      } else if (this.stats === "forget") {
        this.forget();
      }
    },
    //   切换密码可见
    togglePasswordShow() {
      this.password_show = !this.password_show;
      if (this.password_show) {
        this.password_type = "text";
      } else {
        this.password_type = "password";
      }
    },

    //   切换登录注册
    toggleStats(type) {
      this.password = "";
      this.img_code = "";
      this.sms_code = "";
      if (type === "register") {
        this.getImgCode();
      } else if (type === "bind") {
        if (this.open_id) {
          Toast("绑定手机号");
          this.getImgCode();
        } else {
          this.authorize();
        }
      } else if (type === "forget") {
        this.getImgCode();
      }
      clearTimeout(this.countdown_timer);
      this.countdown = "获取验证码";
      this.is_countdown = false;
      this.stats = type;
    },
    // 倒计时
    setCountdown(t) {
      if (t == 0) {
        this.countdown = "重新发送";
        this.is_countdown = false;
      } else {
        this.countdown = t + "秒后重发";
        t--;
      }
      this.countdown_timer = setTimeout(() => {
        this.setCountdown(t);
      }, 1000);
    },
    // 短信验证码
    getSmsCode() {
      if (this.is_countdown) return;
      let reg = /^1[3|4|5|7|8][0-9]{9}$/; //验证规则/^1[3|4|5|7|8][0-9]{9}$/
      if (!reg.test(this.user_tel)) {
        Toast("请输入正确的手机号码");
        return false;
      }
      if (!this.img_code || this.img_code.length < 4) {
        Toast("图片验证码错误");
        return false;
      }

      $.post(
        this.img_code_url,
        { user_tel: this.user_tel, flage: 5, verify: this.img_code },
        res => {
          if (res.id == 0) {
            this.setCountdown(60);
            this.is_countdown = true;
            Toast("验证码发送成功");
          } else if (res.id == -1) {
            //客户端没有传递值到服务器
            Toast(res.msg);
          }
        }
      );
    },
    //图片验证码的 点击 刷新
    refresh() {
      this.img_code_src = this.img_code_src + "rand=" + Math.random();
    },
    //   获取图片验证码
    getImgCode() {
      this.$ajax("post", "index/send_msg_v2", "", res => {
        //图片 验证码 获取token
        if (res.data.id == 0) {
          this.token = res.data.data.token;
          this.img_code_src = this.img_code_url =
            "https://www.zmlxj.com/api.php/index/send_msg_v2?token=" +
            res.data.data.token +
            "&";
        }
      });
    },
    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=" +
        this.token +
        "&id=" +
        this.id +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    }
  }
};
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(10vh);
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
  transform: translateY(0);
}
.fade-enter-active,
.fade-leave-active {
  transition: ease 0.3s;
}

.login-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}
/* 顶部 */
.login-header {
  position: relative;
  width: 100%;
  height: 2.2rem;
  box-shadow: 0 3px 2px -2px #00000016;
  background: linear-gradient(45deg, rgb(40, 184, 250), rgb(8, 142, 252));
}
.login-header h2 {
  text-align: center;
  line-height: 2.2rem;
  font-size: 0.88rem;
  font-weight: bold;
  color: #fff;
}
.login-header .header-back {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-header .header-back i {
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
}
/* main */
.login-main {
  width: 100%;
  padding: 1rem;
  height: calc(100% - 2.2rem);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.login-logo {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-logo img {
  width: 3rem;
  height: 3rem;
  border-radius: 4px;
}
.login-box {
  width: 100%;
}
.box-input {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  margin: 1rem 0;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
}
.box-input input {
  flex: 1;
  padding: 0 1rem;
  box-sizing: border-box;
  font-size: 0.78rem;
  color: #333;
}
.box-input input::-webkit-input-placeholder {
  font-size: 0.68rem;
  color: #999;
}
.box-input i {
  font-size: 0.88rem;
  color: #666;
}
.box-button {
  padding: 1rem 0;
  width: 100%;
}
.box-button button {
  width: 100%;
  height: 2.4rem;
  border-radius: 4px;
  outline: none;
  color: #fff;
  font-size: 0.88rem;
  background-color: #1cb6fdd7;
  border: 1px solid #00000011;
}
.box-toggle-register {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.box-toggle-register a {
  font-size: 0.68rem;
  color: #666;
  text-decoration: none;
}
.box-toggle-login {
  color: #666;
  font-size: 0.68rem;
  text-align: center;
}
.box-toggle-login a {
  color: #000;
}
.login-wx {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.login-wx-title {
  position: relative;
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  z-index: 2;
}
.login-wx-title .login-wx-line {
  position: absolute;
  top: 50%;
  left: 20%;
  width: 60%;
  height: 1px;
  background-color: #00000033;
  z-index: -1;
}
.login-wx-title .login-wx-text {
  display: inline-block;
  position: relative;
  font-size: 0.8rem;
  color: #999;
  padding: 0 4px;
  background-color: #fff;
}
.login-wx-content i {
  font-size: 2rem;
  color: rgb(7, 185, 6);
}

.register-agreement {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  text-align: center;
  color: #666;
  font-size: 0.68rem;
}
.register-agreement a {
  color: #000;
}
/* 图片验证码 */
.img-code {
  height: 100%;
}
/* 短信验证码 */
.sms-code {
  /* display: inline-block; */
  height: 1.6rem;
  line-height: 1.6rem;
  width: 5.4rem;
  text-align: center;
  font-size: 0.78rem;
  color: #666;
  background-color: transparent;
  box-sizing: border-box;

  border: 1px solid #00000033;
  border-radius: 4px;
}
.sms-code-disable {
  color: #999;
}
/* 协议 */
.agreement {
  position: fixed;
  top: -10vh;
  left: 0;
  width: 100vw;
  height: 120vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000099;
}
.agreement-wrapper {
  position: relative;
  width: 86%;
  height: 70%;
  background: #fff;
  border-radius: 0.4rem;
  padding: 0.5rem;
  box-sizing: border-box;
}
.agreement-wrapper .agreement-header {
  position: relative;
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  border-bottom: 1px solid #eee;
}
.agreement-wrapper .agreement-header h2 {
  font-size: 0.88rem;
  font-weight: bold;
  color: #333;
}
.close {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close i {
  font-size: 1rem;
  color: #666;
}
.agreement-content {
  width: 100%;
  height: calc(100% - 2rem);
  overflow-y: auto;
  /* padding: 0.5rem 0; */
}
.agreement-content ol {
  margin: 0;
  padding: 0.5rem 0;
}
.agreement-content ol h3 {
  color: #333;
  font-size: 0.78rem;
}
.agreement-content ol p {
  color: #666;
  font-size: 0.68rem;
}
</style>