<template>
  <div class="myindex" :class="{active:container_big}" v-title data-title="我的">
    <mt-header fixed title="我的">
      <router-link to="/my/SetExit" slot="left">
        <mt-button>
          <img src="../../assets/img/my-option.png" alt />
        </mt-button>
      </router-link>
      <router-link to="/message/MessageIndex" slot="right">
        <i :class="{active:num != 0}"></i>
        <mt-button>
          <img src="../../assets/img/my-msg.png" alt />
        </mt-button>
      </router-link>
    </mt-header>
    <div class="page-content">
      <div class="map_box">
        <ul class="map_info clearfix">
          <li>
            <p>{{user_info.country_numbers}}</p>
            <p>国家</p>
          </li>
          <li>
            <p>{{user_info.province_numbers}}</p>
            <p>省</p>
          </li>
          <li>
            <p>{{user_info.city_numbers}}</p>
            <p>城市</p>
          </li>
          <li>
            <p>{{user_info.district_numbers}}</p>
            <p>地点</p>
          </li>
          <li>
            <p>{{user_info.img_numbers}}</p>
            <p>照片</p>
          </li>
          <li>
            <p>{{user_info.foot_length}}k</p>
            <p>里程km</p>
          </li>
        </ul>
        <div id="container" class="zoom" style="width:100%; height:100%"></div>
        <img
          class="red_packet_spread"
          src="../../assets/img/red_packet_spread.png"
          alt
          @click="container_big = !container_big"
        />
  
        <div class="left_but" v-if="container_big">
          <div class="mak_tab">
            <!--点/图片 切换-->
            <img
              src="../../assets/img/map_park_tab_img.png"
              alt
              @click="click_mak_type"
              v-if="is_tab_button"
            />
            <img
              src="../../assets/img/map_park_tab_img_maks.png"
              alt
              @click="click_mak_type"
              v-if="!is_tab_button"
            />
            <img
              src="../../assets/img/map_3d.png"
              alt
              class="map_3d"
              @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
              v-if="is_tab_map_3d"
            />
            <img
              src="../../assets/img/map_3d_yes.png"
              alt
              class="map_3d"
              @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
              v-if="!is_tab_map_3d"
            />
          </div>
          <div class="mak_tab">
            <!--地图 + -  -->
            <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
            <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
          </div>
        </div>
      </div>
      <div class="my_header">
        <div class="avatar_box">
          <div class="volunteer">
            <img src="../../assets/img/volunteer.png" alt />
          </div>
          <div class="avatar" id="avatar">
            <img v-if="user_info.user_icon!=''" :src="user_info.user_icon" />
            <img v-if="user_info.user_icon==''" src="../../assets/img/apply_avatar_bg.png" />
          </div>
        </div>
        <div class="my-header-info">
          <div class="name">{{user_info.user_nick}}</div>
          <div class="social">
            <span class="funs">
              <router-link to="/my/MyFans">粉丝&nbsp;{{user_info.fansi}}</router-link>
            </span>
            <span class="follow">
              <router-link to="/my/follow">关注&nbsp;{{user_info.atten}}</router-link>
            </span>
          </div>
          <div class="profile">简介：{{user_info.user_signature}}</div>
        </div>
      </div>
      <div class="my-tab flex-box flex-justify-center">
        <ul class="clearfix">
          <li v-for="(k,i) in my_tab" :key="i">
            <router-link :to="{path:k.href,query:{type:'myNote',user_id:k.query}}" v-if="i==0"></router-link>
            <router-link :to="{path:k.href,query:{type:k.query}}" v-if="i!=0"></router-link>
            <img :src="k.assets" alt />
            <div class="my-tab-info">
              <span>{{k.name}}</span>
              <p class="record_numbers" :class="k.color">{{k.number}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>
<script>
import HomeFooter from "../../components/HomeFooter";
import apply_v from "../../assets/img/my-volunteer.png";
import my_notes from "../../assets/img/my-notes.png"; //游记
import my_foot_track from "../../assets/img/my-foot-track.png"; //足迹
import footprint_red from "../../assets/img/footprint_red.png"; //发红包
import red_packet_reward from "../../assets/img/red_packet_reward.png"; //奖励
import line from "../../assets/img/line.png"; //路书
import order from "../../assets/img/my_order.png"; //订单
import my_head_bg from "../../assets/img/my_head_bg.png";
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      map: {},
      user_id: "",
      my_tab: [
        /*                    {
                     "assets": my_track,
                     "name": "轨迹",
                     "number": 0,
                     "color": "blue-color",
                     'href':'/track/myTrackList',
                     'query':""
                     },
                     {
                     "assets": my_plat,
                     "name": "离线地图"
                     },
                     {
                     "assets": my_guide,
                     "name": "导游",
                     "href": "/my/MyTrack"
                     },
                     {
                     "assets": my_orders,
                     "name": "全部订单",
                     "href": '/order/MyOrders',
                     "query": '1'
                     },*/
        {
          assets: my_notes,
          name: "游记",
          number: 0,
          color: "green-color",
          href: "/my/MyNote",
          query: "myNote"
        },
        {
          assets: my_foot_track,
          name: "足迹",
          number: 0,
          color: "green-color",
          href: "/footprints/FootprintsIndex",
          query: "0"
        },
        /*    {
                     "assets": my_integral,
                     "name": "积分",
                     "number": 0,
                     "color": "red-color",
                     "href": "/integral/MyIntegral"
                     },
                     {
                     "assets": my_address,
                     "name": "收货地址",
                     "number": 0,
                     "color": "red-color",
                     "href": "/my/location"
                     },*/
        {
          assets: red_packet_reward,
          name: "奖励",
          number: 0,
          color: "red-color",
          href: "/my/MyAward"
        },
        {
          assets: footprint_red,
          name: "发红包",
          color: "red-color",
          href: "/footprints/GiveRabPacket"
        },
        {
          assets: line,
          name: "路书",
          href: "/footprints/LineList"
        },
        {
          assets: order,
          name: "订单",
          href: "/my/Order"
        }
        //                    {
        //                        "assets": my_file,
        //                        "name": "文件",
        //                        "color": "yellow-color"
        //                    }
      ],
      user_info: {},
      icon_img: my_head_bg,
      num: 0,
      container_big: false, //地图放大缩小
      micro_img_marker_list: [], //所有点
      is_tab_button: true, //图片切换是否点击
      is_tab_map_3d: true //3D地图按钮是否点击
    };
  },
  components: {
    HomeFooter
  },
  mounted() {
    this.getTravelNotes();
  },
  methods: {
    //            开始获取数据
    getTravelNotes() {
      if (!this.check_login()) {
        localStorage.setItem("login_url", location.hash);
        this.$router.push({ path: "/my/login", query: { log_type: 1 } });
        return;
      } else {
        this.user_id = this.check_login().user_id;
      }
      var that = this;
      this.map = this.map_init();
      this.$ajax(
        "post",
        "Index/get_user_info",
        { user_id: this.user_id },
        res => {
          this.user_info = res.data.data;
          this.my_tab[0].query = this.user_id;
          this.my_tab[0].number = this.user_info.notes_numbers;
          this.my_tab[1].number = this.user_info.foot_numbers;
          this.my_tab[2].number = this.user_info.red_packet;
          if (res.data.data.icon_img == "") {
            this.$set(this.user_info, "icon_img", this.icon_img);
          }
          //                    加载地图上图片
          if (res.data.data.spots_list.length > 0) {
            //                        this.map_add_Marker(this.map, res.data.data.spots_list, 'img_num', (Marker_arr) => {
            //                            that.micro_img_marker_list = Marker_arr
            //                        })

            this.map_add_Marker({
              map: this.map,
              list_data: res.data.data.spots_list,
              type: "img_num",
              callback: Marker_arr => {
                that.micro_img_marker_list = Marker_arr;
              }
            });
          }
        }
      );
      this.$ajax(
        "post",
        "Common/getNewMsg",
        {
          user_id: that.user_id
        },
        function(res) {
          that.chat_numbers = res.data.data.chat_numbers;
          that.discuss_numbers = res.data.data.discuss_numbers;
          that.like_numbers = res.data.data.like_numbers;
          var msg_num =
            parseInt(res.data.data.chat_numbers) +
            parseInt(res.data.data.discuss_numbers) +
            parseInt(res.data.data.like_numbers);
          that.num = msg_num;
        }
      );
    },
    click_mak_type() {
      this.map_cut_Marker(
        this.map,
        this.micro_img_marker_list,
        this.is_tab_button
      );
      this.is_tab_button = !this.is_tab_button;
    }
  }
};
</script>
<style scoped>
.my_header {
  width: 100%;
  position: relative;
  background-color: #fff;
  padding: 0.6rem 0;
}

.zoom .amap-touch-toolbar .amap-zoomcontrol {
  bottom: 3rem !important;
  z-index: 1;
}

.myindex .map_box {
  height: 10rem;
  position: relative;
}

.mint-header-button.is-right {
  position: relative;
}

.mint-header-button.is-right i.active {
  position: absolute;
  right: -2px;
  top: 6px;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: red;
  border-radius: 50%;
}

.myindex.active,
.myindex.active .page-content,
.myindex.active .map_box {
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.myindex .map_box .map_info {
  text-align: center;
  width: 90%;
  background-color: rgba(255, 154, 128, 0.6);
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  position: absolute;
  top: 10px;
  left: 2.5%;
  right: 2.5%;
  z-index: 10;
}

.myindex .map_box .map_info li {
  float: left;
  width: 16.66%;
  border-right: 1px dashed #fff;
  box-sizing: border-box;
  font-size: 0.7rem;
}

.myindex .map_box .map_info li:last-child {
  border: none;
}

.myindex .map_box .mak_type {
  bottom: 70px;
}

.myindex .mint-button-text img {
  width: 1rem;
  margin: 0 auto;
}

.myindex .red_packet_spread {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 1.8rem;
}

.my_header .avatar_box {
  width: 4.5rem;
  position: relative;
  float: left;
}

.my_header .avatar_box .volunteer {
  position: absolute;
  bottom: 0;
  right: 0.8rem;
  width: 1rem;
  height: 1rem;
}

.my_header .avatar_box .volunteer img {
  width: 100%;
  height: 100%;
}

.my_header .avatar {
  margin: 0 auto;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #c8b7ad;
  background: #fff;
}

.my_header .avatar img {
  width: 100%;
  height: 100%;
}

.my-header-info {
  text-align: left;
  overflow: hidden;
}

.my-header-info .name {
  font-size: 0.8rem;
}

.my-header-info .social {
  font-size: 0.6rem;
  margin: 6px 0;
}

.my-header-info .social a {
  color: #ccc;
}

.my-header-info .social .funs {
  border-right: 1px solid #f2f2f2;
  padding-right: 14px;
}

.my-header-info .social .follow {
  margin-left: 14px;
}

.my-header-info .profile {
  font-size: 0.6rem;
  line-height: 0.6rem;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #999;
}

.set_box ul li {
  float: left;
  /*width: 33.33%;*/
  width: 50%;
  text-align: center;
  padding: 12px 0 6px;
  background: #fff;
  color: #999999;
  font-size: 12px;
  text-align: center;
  position: relative;
}

.set_box ul li .img {
  position: relative;
  /*width: 18px;*/
  height: 18px;
  display: inline-block;
}

.set_box ul li img {
  /*width: 100%;*/
  height: 100%;
  float: left;
}

.set_box ul li .img i {
  left: 12px;
  top: -6px;
  position: absolute;
  font-style: normal;
  background: red;
  color: #fff;
  padding: 0 4px;
  border-radius: 6px;
}

.set_box ul li .img i:empty {
  display: none;
}

.my-tab {
  margin-top: 4px;
  margin-bottom: 50px;
  background-color: #fff;
  width: 100%;
}

.my-tab ul {
  padding: 0 10px;
  text-align: center;
  overflow: hidden;
  width: 100%;
}

.my-tab ul li {
  float: left;
  width: 33.33%;
  padding: 12px;
  position: relative;
  box-sizing: border-box;
}

.my-tab ul li:nth-of-type(1),
.my-tab ul li:nth-of-type(2),
.my-tab ul li:nth-of-type(4),
.my-tab ul li:nth-of-type(3) {
  border-bottom: 1px solid #f2f2f2;
}

.my-tab ul li img {
  height: 1.6rem;
  display: inline-block;
}

.set_box ul li a,
.my-tab ul li a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.my-tab .my-tab-info span {
  font-size: 0.6rem;
}

.my-tab .my-tab-info p {
  font-size: 0.6rem;
  line-height: 0.6rem;
  height: 0.6rem;
}

.blue-color {
  color: #36b3f6;
}

.green-color {
  color: #7ce04a;
}

.red-color {
  color: #e6191e;
}

.yellow-color {
  color: #fbb634;
}
</style>