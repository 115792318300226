<template>
  <div class="userlist-body">
    <mt-loadmore
      :bottom-method="loadBottom"
      :bottom-all-loaded="allLoaded"
      ref="loadmore"
      :auto-fill="false"
      bottomPullText
    >
      <div class="userlist-header">
        <div class="header-title">{{type_ == 'label' ? '路书' : '商户'}}信息</div>
        <div class="header-content">
          <div class="content-info" @click="backToUpPage">
            <span class="info-name">{{type_ == 'label' ? '路书' : '商户'}}标题</span>
            <span class="info-content">{{userlist_title}}</span>
            <i class="iconfont iconqianjin"></i>
          </div>
          <div class="content-info" v-if="type_ == 'label' || type_ == 'books'">
            <span class="info-name">原创作者</span>
            <span class="info-content">{{user_nick}}</span>
          </div>
        </div>
      </div>

      <div class="userlist-number">{{ where_=="looks_list" ? "访问人数" : "赞赏人数"}}:{{total_number}}</div>

      <div class="userlist-portrait">
        <div class="portrait-icon" v-for="(item,i) in user_list" :key="i">
          <img :src="item.user_icon" alt />
        </div>
      </div>
    </mt-loadmore>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      total_number: "",
      id_: this.$route.query.id_ ? this.$route.query.id_ : 0,
      where_: this.$route.query.where_
        ? this.$route.query.where_
        : "looks_list",
      type_: this.$route.query.type_ ? this.$route.query.type_ : "",
      sid: this.$route.query.sid ? this.$route.query.sid : "",
      user_id: this.$route.query.user_id ? this.$route.query.user_id : "", //用户的ID
      is_weixin: this.hybrid.hybrid.versions.is_weixin, //-------------注意
      union_id: localStorage.getItem("open_id")
        ? localStorage.getItem("open_id")
        : "",
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      version: "1.0.0",
      park_id: 298,
      user_list: [],
      userlist_title: "",
      user_nick: "",
      allLoaded: false,
      item_len: "",
      page: 1
    };
  },
  created() {
    this.getPayMoneyData();
  },
  methods: {
    backToUpPage() {
      if (this.type_ == "label") {
        this.$router.push({
          path: "/footprints/LineInfoList",
          query: {
            label_id: this.id_
          }
        });
      } else if (this.type_ == "spots") {
        this.$router.push({
          path: "/footprints/PrimaryScenic",
          query: {
            sid: this.sid,
            id_: this.id_
          }
        });
      } else if (this.type_ == "books") {
        this.$router.push({
          path: "/books/books",
          query: {
            label_id: this.id_
          }
        });
      } else {
      }
    },
    loadBottom() {
      if (this.item_len % 10 !== 0) {
        this.allLoaded = true;
        Toast("没有更多了");
      } else {
        this.page++;
        this.getPayMoneyData();
      }
      //   console.log('aaa');
      this.$refs.loadmore.onBottomLoaded();
    },
    getPayMoneyData() {
      this.$ajax(
        "post",
        "Label/get_label_user_list",
        {
          id: this.id_,
          park_id: this.park_id,
          token: this.token,
          version: this.version,
          where_: this.where_,
          page: this.page
        },
        ret => {
          let res = ret.data;
          //   console.log(res);
          if (res) {
            let label = res.data.label;
            if (this.page === 1) {
              this.userlist_title = label.title;
              this.user_nick = label.user_nick;
              /* 解决IOS不修改标题栏的问题 */
              var i = document.createElement("iframe");
              i.src = "https://www.baidu.com/favicon.ico";
              i.style.display = "none";
              i.onload = function() {
                setTimeout(function() {
                  i.remove();
                }, 9);
              };
              document.body.appendChild(i);
              document.title = label.title;
            }

            let list = res.data.list;
            this.total_number = res.data.numbers;
            /* 用户列表 */
            this.user_list = this.user_list.concat(list);

            this.item_len = list.length;
          }
        }
      );
    }
  },
  mounted() {
    if (this.token) {
    } else {
      //第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    //投票相关
    if (
      this.union_id &&
      !(
        !this.union_id &&
        typeof this.union_id != "undefined" &&
        this.union_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有union_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.union_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.union_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.union_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }

    if (this.is_weixin) {
      this.dealWithUrl(window.location.href);
      this.wx_share(window.location.href);
    }
  }
};
</script>

<style scoped>
.userlist-body {
  background-color: rgb(239, 238, 243);
  height: 100vh;
  width: 100vw;
  position: fixed;

  overflow-y: auto;
}
.userlist-body .userlist-header .header-title {
  height: 3rem;
  line-height: 3rem;
  font-size: 0.8rem;
  padding-left: 4.4vw;
  padding-top: 0.5rem;
  color: #999;
}
.userlist-body .userlist-header .header-content {
  background-color: #fff;
}
.userlist-body .userlist-header .header-content .content-info {
  position: relative;
  height: 3rem;
  line-height: 3rem;
  font-size: 0.8rem;
  padding-left: 4.4vw;
}
.userlist-body .userlist-header .header-content .content-info:nth-child(1) {
  border-bottom: 1px solid rgb(239, 238, 243);
}
.userlist-body .userlist-header .header-content .content-info:nth-child(2) {
  border-bottom: 1px solid rgb(239, 238, 243);
}
.userlist-body .userlist-header .header-content .content-info .info-name {
  display: inline-block;
  width: 24vw;
  color: #999;
}
.userlist-body .userlist-header .header-content .content-info > .iconfont {
  position: absolute;
  right: 0.5rem;
  top: 1rem;
  font-size: 1rem;
  color: #999;
}

.userlist-body .userlist-number {
  height: 3rem;
  font-size: 0.8rem;
  line-height: 3rem;
  margin-top: 0.5rem;
  padding-left: 4.4vw;
  color: #999;
}
.userlist-body .userlist-portrait {
  /* min-height: 20rem; */
  /* width: 100%; */
  height: auto;
  background-color: #fff;
  padding: 4vw 4vw 2vw 4vw;
  display: flex;
  flex-wrap: wrap;
}
/* .userlist-body .userlist-portrait .mint-loadmore{
    height: 100%;
    width: 100%;

}
.userlist-body .userlist-portrait .mint-loadmore .mint-loadmore-content{
    height: 100%;
    width: 100%;
} */
/* .userlist-body .userlist-portrait .mint-loadmore .mint-loadmore-content span{
    display: none;
} */

.userlist-body .userlist-portrait .portrait-icon {
  height: 12vw;
  width: 11.5vw;
  text-align: center;
}
.userlist-body .userlist-portrait .portrait-icon img {
  width: 9.6vw;
  height: 9.6vw;
}
</style>