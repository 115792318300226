<template>
  <div v-title data-title="提现成功">
    <mt-header fixed title="提现成功" v-if="header">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
    </mt-header>

    <div class="page-content" :style="style">
      <div class="success-content">
        <div class="success-bg"></div>
        <div class="success-text">
          <div class="success-icon">提现成功</div>
          <p>恭喜您，提现成功</p>
          <!-- <span>999.99</span> -->
          <!-- <p>我们将在<label>3个工作日</label>内审核完成!</p> -->
          <p>有任何疑问或者需要协助的地方，欢迎随时联系我们</p>
        </div>
        <div class="success-text">
          <p>客服QQ:</p>
          <span class="tel">377614027</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: "",
      header: true,
      style: "",
      money: this.$route.query.money
    };
  },

  mounted() {
    this.removeDomTitle();
  },

  methods: {
    removeDomTitle() {
      if (
        this.hybrid.hybrid.versions.myapp ||
        this.hybrid.hybrid.versions.myiOSapp
      ) {
        this.header = false;
        this.style = "padding:0";
      }
    }
  }
};
</script>