<script src="../../test/e2e/nightwatch.conf.js"></script>
<template>
<footer class="tabbar">
	<router-link to="/" class="tabbar-item home" :class="{selected:path=='/'}"></router-link>
	<router-link to="/Guide" class="tabbar-item guide" :class="{selected:path=='/Guide'}"></router-link>
	<router-link to="/ticket" class="tabbar-item ticket" :class="{selected:path=='/ticket'}"></router-link>
	<router-link to="/my" class="tabbar-item my" :class="{selected:path=='/my'}"></router-link>
</footer>
</template>
<script>
	export default{
	    data(){
	        return {
                option:true,
                path:this.$route.path
			}
		}
	}
</script>
<style scoped>
/*底部切换栏*/
.tabbar {
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 1;
    display: flex;
    padding: 0 10px;
	background-color: #fff;
	border-top: 1px solid #e9e9e9;
}
.tabbar-item {
	flex: 1;
	height: 2rem;
	margin: .2rem 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.tabbar-item.home {
	background-image: url(../assets/img/tabbar-home.png);
}
.tabbar-item.home.selected {
	background-image: url(../assets/img/tabbar-home-1.png);
}
.tabbar-item.guide {
	background-image: url(../assets/img/tabbar-guide.png);
}
.tabbar-item.guide.selected {
	background-image: url(../assets/img/tabbar-guide-1.png);
}

.tabbar-item.ticket{
	background-image: url(../assets/img/ticket_unselected.png);
}
.tabbar-item.ticket.selected{
	background-image: url(../assets/img/ticket_selected.png);
}

.tabbar-item.my {
	background-image: url(../assets/img/tabbar-my.png);
}
.tabbar-item.my.selected {
	background-image: url(../assets/img/tabbar-my-1.png);
}
</style>