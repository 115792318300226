<template>
  <div class="activity-list" v-title :data-title="title">
    <div class="al-body">
      <div class="al-b-item" v-for="(item,i) in list" :key="i">
        <div class="al-b-i-left">
          <img :src="item.banner_img_app" />
        </div>
        <div class="al-b-i-right">
          <div class="al-b-i-title">
            <span>{{item.activity_name}}</span>
          </div>
          <div class="al-b-i-content">
            <span>{{item.content}}</span>
          </div>
          <div class="al-b-i-method">
            <div class="al-b-i-m-order" @click="ClickMakeOrder(item.id)">
              <span>立即预约</span>
            </div>
            <div class="al-b-i-m-detail" @click="ClickOpenDetail(item.id)">
              <span>查看详情</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";

export default {
  data() {
    return {
      title: "请选择预约路线",
      park_id: this.$route.query.park_id,
      list: "",
      page: 1
    };
  },
  computed: {},
  mounted() {
    this.get_activity_list();
  },
  methods: {
    get_activity_list() {
      var data_ = {
        park_id: this.park_id,
        token: "zmlxj_2019_wuhanlvyouxiehui",
        page: this.page
      };

      this.$ajax("post", "Other/get_activity_list", data_, ret => {
        if (ret.data.id == 0) {
          this.list = ret.data.data.list;
        } else {
          //没有数据
          Toast("没有数据");
        }
      });
    },
    /*查看详情*/
    ClickOpenDetail(id) {
      if (id) {
        this.$router.push({
          path: "/wuhanlvyouxiehui/wx_activity_detail",
          query: { id: id }
        });
      } else {
        Toast("数据有误");
      }
    },
    /*立即预约*/
    ClickMakeOrder(id) {
      //进入预约平台
      if (id) {
        this.$router.push({
          path: "/wuhanlvyouxiehui/wx_make_order",
          query: { id: id }
        });
      } else {
        Toast("数据有误");
      }
    }
  }
};
</script>

<style scoped>
.activity-list {
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: #f0f0f0;
}
.activity-list .al-body {
  display: inline-block;
  position: relative;
  width: 100%;
}
.activity-list .al-body .al-b-item {
  display: inline-block;
  position: relative;
  width: 98%;
  margin-left: 1%;
  background-color: white;
  margin-bottom: 4%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.activity-list .al-body .al-b-item .al-b-i-left {
  position: relative;
  width: 40%;
  float: left;
}
.activity-list .al-body .al-b-item .al-b-i-left img {
  width: 100%;
  height: 100px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.activity-list .al-body .al-b-item .al-b-i-right {
  position: relative;
  width: 60%;
  float: left;
}
.activity-list .al-body .al-b-item .al-b-i-right .al-b-i-title {
  display: inline-block;
  position: relative;
  width: 100%;
}
.activity-list .al-body .al-b-item .al-b-i-right .al-b-i-title span {
  display: block;
  padding: 10px 0 10px 0;
  font-size: 1rem;

  white-space: nowrap;
  overflow-x: hidden;
}
.activity-list .al-body .al-b-item .al-b-i-right .al-b-i-content {
  display: inline-block;
  position: relative;
  width: 100%;
}
/*控制文本显示的行数*/
.activity-list .al-body .al-b-item .al-b-i-right .al-b-i-content span {
  display: block;

  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.activity-list .al-body .al-b-item .al-b-i-right .al-b-i-method {
  display: inline-block;
  position: relative;
  width: 100%;
}
.activity-list
  .al-body
  .al-b-item
  .al-b-i-right
  .al-b-i-method
  .al-b-i-m-order {
  position: relative;
  width: 45%;
  float: left;
  margin-left: 1%;
}
.activity-list
  .al-body
  .al-b-item
  .al-b-i-right
  .al-b-i-method
  .al-b-i-m-order
  span {
  display: block;
  padding: 5px 0 5px 0;
  text-align: center;
  font-size: 0.8rem;
  background-color: #33beff;
  color: white;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.activity-list
  .al-body
  .al-b-item
  .al-b-i-right
  .al-b-i-method
  .al-b-i-m-detail {
  position: relative;
  width: 45%;
  float: left;
  margin-left: 5%;
}
.activity-list
  .al-body
  .al-b-item
  .al-b-i-right
  .al-b-i-method
  .al-b-i-m-detail
  span {
  display: block;
  padding: 5px 0 5px 0;
  text-align: center;
  font-size: 0.8rem;
  background-color: #3de188;
  color: white;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
</style>