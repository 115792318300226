<template>
  <div class="back_top" @click="backTop">
    <i class="iconfont iconUp"></i>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    backTop () {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop

      let timer = setInterval(() => {
        let ispeed = Math.floor(-scrollTop / 5)
        document.documentElement.scrollTop = scrollTop + ispeed
        document.body.scrollTop = scrollTop + ispeed
        scrollTop = scrollTop + ispeed
        if (scrollTop <= 1) {
          clearInterval(timer)
        }
      }, 16)
    }

  }
}
</script>

<style scoped>
.back_top {
  position: fixed;
  bottom: 1rem;
  right: 0.6rem;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
}

.back_top > .iconfont {
  font-size: 1rem;
  color: #fff;
}

</style>
