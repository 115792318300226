<template>
  <div class="GuideOrderDetail" v-title data-title="订单详情">
    <mt-header fixed title="订单详情">
      <a onclick="window.history.go(-1)" slot="left">
        <mt-button icon="back"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <div class="order-detail-top">
        <div class="box">
          <div class="tit">
            <div v-if="my == 0 && orderInfo.is_pay == 0 && status == 0">待付款</div>
            <div v-else-if="my == 0 && orderInfo.is_pay == 0 && status == 1">商家已接单</div>
            <div v-else-if="orderInfo.is_pay == 0 && status == 2">商家拒绝接单</div>
            <div
              v-else-if="my == 1 && orderInfo.is_pay == 0 && status == 0 || my == 1 && orderInfo.is_pay == 0 && status == 1"
            >客户未支付</div>

            <!-- 确认订单 -->
            <div v-else-if="orderInfo.is_pay == 1">待确认</div>

            <!-- 撤销 -->
            <div v-else-if="status == 3">正在撤销</div>
            <div v-else-if="status == 4">撤销失败</div>
            <div v-else-if="status == 5">撤销成功</div>

            <!-- 完成 -->
            <div v-else-if="status == 6">订单完成</div>

            <div>下单时间：{{orderInfo.add_time}}</div>
          </div>
          <div class="info">
            <div class="order-id">订单编号：{{orderInfo.order_id}}</div>
            <div class="price">￥{{orderInfo.pay_money}}</div>
          </div>
        </div>
      </div>
      <div class="order-detail-main">
        <div class="tit">
          <!-- <img src="https://test.geoparker.cn/Public/TPuploads/image/20161028/58136124170a6.jpg"> -->
          <h1>{{orderInfo.title}}</h1>
        </div>
        <div class="list">
          <h2>
            <img src="../../assets/img/indent_message.png" />订单信息
          </h2>
          <!-- <mt-cell title="服务类型:" value="徒步向导"></mt-cell> -->
          <mt-cell title="出行时间:" :value="orderInfo.service_begin_time"></mt-cell>
          <mt-cell title="出行天数" :value="orderInfo.time_length + '天'"></mt-cell>
          <mt-cell title="见面地点" :value="orderInfo.meet_address"></mt-cell>
          <mt-cell title="出行人数" :value="'成人' + orderInfo.man + '人儿童' + orderInfo.child+ '人'"></mt-cell>
          <mt-cell title="备注信息" :value="orderInfo.notes"></mt-cell>
          <h2>
            <img src="../../assets/img/indent_linkman.png" />联系人信息
          </h2>
          <mt-cell title="联系人" :value="orderInfo.name"></mt-cell>
          <mt-cell title="电话" :value="orderInfo.tel"></mt-cell>
          <h2>
            <img src="../../assets/img/indent_cost.png" />费用说明
          </h2>
          <mt-cell title="出行费用" :value="orderInfo.pay_money + '元'"></mt-cell>
          <!-- <mt-cell title="优惠券" value="无"></mt-cell> -->
        </div>
        <div
          class="btn-list"
          v-if="my == 0 && status == 0 || my == 0 && status == 1 ||my == 0 && orderInfo.is_pay == 1 "
        >
          <router-link
            :to="{ name: 'GuideOrderCancel', query: { id: orderId, time: orderInfo.add_time, num: orderInfo.order_id, price: orderInfo.pay_money,status:status}}"
          >取消订单</router-link>
          <a class="contact" href="javascript:;">联系导游</a>
          <!-- 订单长id、出行时间time、人数num、总价price、用户名name、用户头像img、标题tit -->
          <router-link
            :to="{ name: 'GuideOrderPay', params: { id: orderId, time: orderInfo.add_time, num:peopleNum, price: orderInfo.pay_money, name: orderInfo.name, img: orderInfo.user_icon, tit: orderInfo.title }}"
            v-if="orderInfo.is_pay == 0"
            class="gopay"
          >立即支付</router-link>
        </div>
        <div class="user-wrap order-btn clearfix" v-else-if="status != 3">
          <mt-button type="primary" @click="setOrder(1,orderInfo.id)">接单</mt-button>
          <mt-button type="danger" @click="setOrder(2,orderInfo.id)">拒绝</mt-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MessageBox } from "mint-ui";
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      userId: "",
      orderId: "",
      // 表单信息
      orderInfo: "",
      peopleNum: 0,
      contactImg:
        "https://test.geoparker.cn/Public/TPuploads/image/20161028/58136124170a6.jpg",

      my: this.$route.params.my,
      status: this.$route.params.status
    };
  },
  mounted() {
    this.getLoginInfo();
    this.orderId = this.$route.params.id;
    this.getOrderInfo();
  },
  watch: {},
  methods: {
    getLoginInfo: function() {
      //检测登录
      // 用户ID
      if (this.hybrid.hybrid.versions.myapp) {
        // FOR 安卓最美旅行家APP
        $(".mint-header").remove();
        $(".page-content").css("padding", "0");

        this.userId = JavaScriptInterface.getUserId();
      } else if (this.hybrid.hybrid.versions.myiOSapp) {
        // FOR IOS最美旅行家APP

        $(".mint-header").remove();
        $(".page-content").css("padding", "0");

        this.hybrid.hybrid.registerHandler(
          "getIOSUserId",
          (data, responseCallback) => {
            this.userId = data.user_id;
            var responseData = "已经收到IOS传来的userid";
            responseCallback(responseData);
          }
        );
      } else {
        // For web
        this.userId = this.get_User_Id();
      }
    },
    getOrderInfo: function() {
      var that = this;
      // /order/GuideOrderDetail
      this.$ajax(
        "post",
        "/Service/order_info",
        {
          // user_id:that.userId,
          order_id: that.orderId
        },
        function(res) {
          console.log(res);
          let data = res.data.data;
          that.orderInfo = data;
          that.peopleNum = parseInt(data.man) + parseInt(data.child);
        }
      );
    },
    // 拒绝、接受、撤销订单
    // t-> 订单状态，id-> 订单ID
    setOrder(t, id) {
      var type = "";
      var msg = "";
      switch (t) {
        case 1:
          type = "0";
          msg = "接单";
          break;
        case 2:
          type = "0";
          msg = "拒绝订单";
          break;
        case 3:
          type = "1";
          msg = "订单撤销";
          break;
      }
      var data = {
        user_id: this.user_id,
        order_id: id,
        status: t,
        type: type
      };
      MessageBox({
        title: "提示",
        message: "确认" + msg + "吗？",
        showCancelButton: true
      }).then(action => {
        if (action == "confirm") {
          this.$ajax("post", "Service/setServiceOrderStatus", data, res => {
            if (res.data.id == 0) {
              Toast(msg + res.data.msg);
              window.location.reload();
            } else {
              Toast(res.data.msg);
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.page-content {
  padding-bottom: 0;
}

.mint-cell-title .mint-cell-text {
  color: #ccc;
}

.mint-button--normal {
  width: 49%;
}
.GuideOrderDetail {
  font-size: 0.7rem;
}

.order-detail-top {
  position: fixed;
  width: 100%;
  background: linear-gradient(45deg, #ff7364 0%, #ff9063 100%);
  z-index: 1;
}

.order-detail-top .box {
  padding: 0.5rem 0.5rem 0.8rem;
}

.order-detail-top .tit {
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.order-detail-top .info {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 0.5rem;
  margin: 0.8rem 0.8rem 0;
  border-radius: 0 0 0.25rem 0.25rem;
  position: relative;
}

.order-detail-top .info:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  background: #c45d4e;
  height: 0.5rem;
  border-radius: 0.25rem;
  padding: 0 0.25rem;
  z-index: -1;
  left: -0.25rem;
  top: -0.25rem;
}

.order-detail-top .info .order-id {
  color: #333;
}

.order-detail-top .info .price {
  color: #fe9b00;
}

.order-detail-main {
  background: #fff;
  padding-top: 5rem;
  margin: 0.5rem;
}

.order-detail-main .tit {
  padding: 0.5rem;
  border-bottom: 1px dashed #cacaca;
  overflow: hidden;
}

.order-detail-main .tit img {
  width: 2rem;
  height: 2rem;
  border-radius: 0.2rem;
  float: left;
  margin-right: 0.5rem;
}

.order-detail-main .list {
  padding: 0 0.5rem 0.5rem;
  border-bottom: 1px dashed #cacaca;
}

.order-detail-main .list h2 {
  padding-top: 0.5rem;
  line-height: 1.1rem;
}

.order-detail-main .list h2 img {
  float: left;
  height: 1rem;
  width: auto;
  margin: 0.1rem 0.4rem 0 0;
}

.order-detail-main .btn-list {
  display: flex;
}

.order-detail-main .btn-list a {
  flex: 1;
  margin: 0.4rem;
  color: #fff;
  background: #fe5a12;
  line-height: 1.6rem;
  text-align: center;
  border-radius: 1rem;
}

.order-detail-main .btn-list .contact {
  background: #ff9063;
}

.order-detail-main .btn-list .gopay {
  background: #ff9c00;
}
</style>