<template>
  <div class="feedback grayness" v-title data-title="意见反馈">
    <mt-header fixed title="意见反馈">
      <a slot="left">
        <mt-button icon="back" @click="$router.go(-1)"></mt-button>
      </a>
    </mt-header>
    <div class="page-content">
      <mt-field
        @keyup.native="uptextlength"
        v-model="content"
        placeholder="请输入您对我们网站的意见及反馈，最美旅行家的成长离不开您的用心"
        type="textarea"
        rows="4"
      ></mt-field>
      <div class="page-tips" style="padding-top:1rem;">
        {{txt}}
        <span style="color:red">
          <strong>{{textnum}}</strong>
        </span>个字
      </div>
      <div class="btn btn_exit" :class="{no_action:txt=='还差',red:txt=='输入字数已超出'}">
        <mt-button type="primary" @click="feedbacktxt" class="mint-header">提交反馈</mt-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      user_id: this.get_User_Id(),
      textnum: 20,
      txt: "还差",
      content: ""
    };
  },
  methods: {
    feedbacktxt() {
      //提交反馈
      if (this.txt == "还可以输入") {
        console.log(this.content);
        this.$ajax(
          "post",
          "User/advice",
          {
            user_id: this.user_id,
            type: 3,
            content: this.content
          },
          res => {
            console.log(res);
            if (res.data.id == 0) {
              Toast(res.data.msg);
            }
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          }
        );
      }
    },
    uptextlength(e) {
      //键盘弹起检查输入长度   最多200
      if (e.target.value.length <= 20) {
        this.textnum = 20 - e.target.value.length;
      } else if (e.target.value.length <= 200) {
        this.textnum = 200 - e.target.value.length;
        this.txt = "还可以输入";
      } else {
        this.txt = "输入字数已超出";
        this.textnum = e.target.value.length - 200;
      }
    }
  }
};
</script>
<style scoped>
.feedback .page-content >>> .mint-cell-value .mint-field-core {
  height: 180px;
  font-size: 0.8rem;
  text-align: left;
}

.feedback .page-content .btn {
  margin-top: 50px;
}

.feedback .page-content .btn >>> .mint-button-text {
  font-size: 0.7rem;
}

.btn.no_action .mint-header {
  background-color: #999;
}

.btn.red .mint-header {
  background-color: red;
}
</style>