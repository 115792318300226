<template>
  <div class="body r-bg" v-if="is_weixin">
    <!--活动图片-->
    <div class="head">
      <div class="tip-img">
        <img src="../../assets/img/ranklist.png" />
      </div>
      <div class="banner">
        <img :src="info.banner_img_app" />
      </div>
    </div>

    <div class="r-list">
      <div class="r-item" v-for="(k,i) in list" :key="i">
        <div class="r-left">
          <div class="r-num">
            <span>{{k.id}}</span>
          </div>
          <div class="r-icon">
            <img :src="k.baseUrl" />
          </div>
          <div class="r-nick">
            <span>{{k.user_name}}</span>
          </div>
        </div>
        <div class="r-vote_numbers">
          <span>{{k.vote_numbers}}</span>
        </div>
      </div>
    </div>

    <!--投票按钮-->
    <div class="vote">
      <div class="vote-up" v-if="is_show">
        <div class="method_">
          <div class="all_" @click="select_all">
            <span>全选</span>
          </div>
          <div class="vote_fun" v-if="is_show == 0">
            <span>投票未开始</span>
          </div>
          <div class="vote_fun" @click="video_vote" v-else-if="is_show == 1">
            <span>投票</span>
          </div>
          <div class="vote_fun" v-else-if="is_show == 3">
            <span>投票已结束</span>
          </div>
        </div>
      </div>
      <div class="vote-bottom">
        <div class="vote-index">
          <router-link
            class="link_"
            :to="{path:'/parkPhoto/VideoVote',query:{aid:aid,type:'VideoVote'}}"
          >
            <div class="index-img">
              <img src="../../assets/img/index-img.png" />
            </div>
            <div class="index-text">
              <span>首页</span>
            </div>
          </router-link>
        </div>
        <div class="vote-index">
          <router-link
            class="link_"
            :to="{path:'/parkPhoto/VideoRank',query:{aid:aid,type:'VideoRank'}}"
          >
            <div class="index-img">
              <img src="../../assets/img/rank_.png" />
            </div>
            <div class="index-text">
              <span>排行榜</span>
            </div>
          </router-link>
        </div>
        <div class="vote-index">
          <router-link
            class="link_"
            :to="{path:'/parkPhoto/VideoRule',query:{aid:aid,type:'VideoRule'}}"
          >
            <div class="index-img">
              <img src="../../assets/img/rule.png" />
            </div>
            <div class="index-text">
              <span>规则说明</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.body .tip-img {
  display: inline-block;
  position: absolute;
  margin-top: 50%;
  z-index: 1;
}
.body .tip-img img {
  width: 100%;
  height: 120px;
}
.body .r-list {
  display: inline-block;
  position: relative;
  width: 98%;
  margin-left: 1%;
  margin-top: 15%;
  bottom: 2rem;
  background-color: #ecc48d;
}
.body .r-list .r-item {
  display: inline-block;
  position: relative;
  width: 96%;

  font-weight: bold;
  color: red;
  background-color: #f8b455;
  margin-left: 2%;
}
.body .r-list .r-item .r-left {
  position: relative;
  width: 70%;
  float: left;
}
.body .r-list .r-item .r-left .r-num {
  position: relative;
  width: 20%;
  float: left;
  margin-top: 8%;
}
.body .r-list .r-item .r-left .r-num span {
  display: block;
  padding: 4px;
  font-size: 0.6rem;
}

.body .r-list .r-item .r-left .r-icon {
  position: relative;
  width: 20%;
  float: left;
  margin-top: 3%;
}
.body .r-list .r-item .r-left .r-icon img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-top: 10%;
}
.body .r-list .r-item .r-left .r-nick {
  position: relative;
  width: 60%;
  float: left;
  margin-top: 8%;
}
.body .r-list .r-item .r-left .r-nick span {
  display: block;
  padding: 4px;
  font-size: 0.6rem;
}
.body .r-list .r-item .r-vote_numbers {
  position: relative;
  width: 30%;
  float: left;
  margin-top: 5%;
}
.body .r-list .r-item .r-vote_numbers span {
  display: block;
  padding: 4px;
  font-size: 0.6rem;
  text-align: right;
  margin-right: 10%;
}
</style>
<script>
import { Toast } from "mint-ui";
import { Indicator } from "mint-ui";
export default {
  components: {},
  data() {
    return {
      aid: this.$route.query.aid,
      list: "",
      info: "",
      is_show: false,
      page: 1,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      m_url:
        "https://m.zmlxj.com/#/parkPhoto/VideoRank?aid=" +
        this.$route.query.aid +
        "&type=VideoRank"
    };
  },
  mounted() {
    this.unload();
    if (this.is_weixin) {
      this.wx_share(this.wx_url);
    }
    //this.weixin_hidden();
  },
  methods: {
    unload() {
      this.get_rank_list();
    },
    get_rank_list() {
      var aid = this.aid;
      if (aid) {
        this.$ajax(
          "post",
          "SpotsRace/get_video_rank_list",
          {
            aid: aid,
            token: "SpotsRace-get_video_rank_list",
            page: this.page
          },
          ret => {
            if (ret.data.id == 0) {
              this.list = ret.data.data.rank_list;
              this.info = ret.data.data.activity_info;
            } else {
              Toast("无榜单");
            }
          }
        );
      } else {
        Toast("少值");
      }
    },
    weixin_hidden() {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function onBridgeReady() {
          // 通过下面这个API隐藏右上角按钮
          WeixinJSBridge.call("hideOptionMenu");
        }
      );
    },
    click_load_more() {
      var aid = this.aid;
      var page = ++this.page;
      if (aid) {
        this.$ajax(
          "post",
          "SpotsRace/get_video_rank_list",
          {
            aid: aid,
            page: page,

            token: "SpotsRace-get_video_head_number"
          },
          ret => {
            if (ret.data.id == 0) {
              if (ret.data.data.rank_list) {
                this.list = this.list.concat(ret.data.data.rank_list);
              } else {
                Toast("加载完毕");
              }
            } else {
              Toast("无更多数据");
            }
          }
        );
      } else {
        Toast("加载失败");
      }
    }
  }
};
</script>