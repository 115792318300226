<template>
  <div class="body">
    <div class="input_">
      <div class="span_">
        <span>名称：</span>
      </div>
      <div class="input__">
        <input type="text" v-model="title" readonly="readonly" />
      </div>
    </div>
    <div class="input_">
      <div class="span_">
        <span>价格：</span>
      </div>
      <div class="input__">
        <input type="number" v-model="price" readonly="readonly" />
      </div>
    </div>
    <div class="input_">
      <div class="span_">
        <span>个数：</span>
      </div>
      <div class="input__">
        <input type="number" v-model="numbers" min="1" />
      </div>
    </div>
    <div class="input_">
      <div class="span_">
        <span>总金额：</span>
      </div>
      <div class="input__">
        <input type="number" v-bind:value="price * numbers" readonly="readonly" />
      </div>
    </div>
    <div class="input_">
      <div class="span_">
        <span>开始日期：</span>
      </div>
      <div class="input__">
        <vue-datepicker-local v-model="btime" />
      </div>
    </div>
    <div class="input_">
      <div class="span_">
        <span>结束日期：</span>
      </div>
      <div class="input__">
        <vue-datepicker-local v-model="etime" />
      </div>
    </div>
    <div class="input_">
      <div class="span_">
        <span>地址：</span>
      </div>
      <div class="input__">
        <input type="text" v-model="address" />
      </div>
    </div>
    <div class="input_">
      <div class="span_">
        <span>备注：</span>
      </div>
      <div class="input__">
        <input type="text" v-model="notes" />
      </div>
    </div>

    <div class="submit">
      <button type="submit" @click="submit">提交订单</button>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
import VueDatepickerLocal from "vue-datepicker-local";
export default {
  components: {
    VueDatepickerLocal
  },
  data() {
    return {
      id: this.$route.query.id,
      price: this.$route.query.price > 0 ? this.$route.query.price : 0,
      title: this.$route.query.title ? this.$route.query.title : "",
      numbers: this.$route.query.numbers > 0 ? this.$route.query.numbers : 1,
      address: "",
      notes: "",
      open_id: this.$route.query.open_id,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      label_id: this.$route.query.label_id ? this.$route.query.label_id : 0,
      amount:
        this.$route.query.price * this.$route.query.numbers > 0
          ? this.$route.query.price * this.$route.query.numbers
          : 0,
      label_id_: this.$route.query.label_id_ ? this.$route.query.label_id_ : 0,
      btime: this.getNowFormatDate(),
      etime: this.getNowFormatDate(),
      park_id: this.$route.query.park_id ? this.$route.query.park_id : 0
    };
  },
  mounts() {},
  methods: {
    //提交订单
    submit() {
      var title = this.title;
      var id = this.id;
      var price = this.price;
      var numbers = this.numbers;
      var address = this.address;
      var notes = this.notes;
      var label_id = this.label_id;
      var user_id = "";
      var open_id = this.open_id;
      var label_id_ = this.label_id_;
      var park_id = this.park_id;
      var btime = this.btime;

      var etime = this.etime;
      //console.log(this.btime,etime);

      //console.log(id,title,price,numbers,address);
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "确认登陆吗？",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            this.get_User_Id();
          }
        });
      } else {
        user_id = this.check_login().user_id;
        if (user_id && id && numbers) {
          this.$ajax(
            "post",
            "ticket/server_make_order",
            {
              user_id: user_id,
              id: id,
              numbers: numbers,
              price: price,
              label_id: label_id,
              token: "zmlxj_2018",
              address: address,
              notes: notes,
              label_id_: label_id_,
              btime: btime,
              etime: etime,
              park_id: park_id
            },
            ret => {
              if (ret.data.id == 0) {
                this.$router.push({
                  path: "/ticket/Pay",
                  query: {
                    id: ret.data.data.id_,
                    order_id: ret.data.data.order_id,
                    open_id: open_id
                  }
                });
              } else {
                Toast("下单失败");
              }
            }
          );
        } else {
          Toast("无数据,下单失败");
        }
      }
    }
  }
};
</script>
<style scoped>
.body {
  display: inline-block;
  position: relative;
  margin-top: 2%;
  margin-left: 1%;
  width: 98%;
}
.body .input_ {
  display: inline-block;
  position: relative;
  height: 45px;
  border-bottom: 1px solid #dedede;
  margin-top: 2%;
  margin-left: 1%;
  width: 98%;
}
.body .input_ .span_ {
  position: relative;
  float: left;
  width: 14%;
  margin-top: 2%;
}
.body .input_ .span_ span {
  display: block;
  padding: 5px;
}
.body .input_ .input__ {
  position: relative;
  width: 86%;
  float: left;
}
.body .input_ .input__ input {
  height: 45px;
}
.datepicker {
  float: right;
}

.body .submit {
  margin-top: 4%;
  margin-left: 40%;
}
.body .submit button {
  height: 40px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
</style>