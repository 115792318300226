<template>
  <div class="RankList ParkPhoto" v-title data-title="排行榜">
    <!--v-if="is_weixin"-->
    <div
      class="page-content rank-list"
      v-if="(merchant.length > 0 || user_info.length > 0) && is_show_user_or_spots == 1"
    >
      <mt-navbar v-model="type">
        <mt-tab-item id="1" v-if="(show_type == 1) || (show_type == 3)">餐馆排行榜</mt-tab-item>
        <mt-tab-item id="2" v-if="(show_type == 2) || (show_type == 3)">个人排行榜</mt-tab-item>
      </mt-navbar>
      <mt-tab-container v-model="type">
        <!--餐馆排行榜-->
        <mt-tab-container-item id="1" v-if="(show_type == 1) || (show_type == 3)">
          <mt-loadmore
            :bottom-method="loadBottom"
            :bottom-all-loaded="merchant_allLoaded"
            ref="merchant_loadmore"
            :auto-fill="false"
          >
            <div class="item" v-for="(k,i) in merchant" v-show="merchant.length > 0" :key="i">
              <div class="left_cent">
                <div class="avatar">
                  <router-link
                    :to="{path:'/parkPhoto/detail',query:{id:k.spots_fk_id,aid:aid,type:2}}"
                  >
                    <img :src="k.user_icon" />
                  </router-link>
                </div>
                <div class="info-line">
                  <span class="name">{{k.user_nick}}</span>
                  <div class="vote">票数：{{k.num}}</div>
                  <div class="vote">编号：{{k.spots_fk_id}}</div>
                </div>
              </div>
              <div class="rank">
                <img src="../../assets/img/rank_1.png" alt v-if="k.rank == 1" />
                <img src="../../assets/img/rank_2.png" alt v-else-if="k.rank == 2" />
                <img src="../../assets/img/rank_3.png" alt v-else-if="k.rank == 3" />
                <p v-else class="num">
                  第
                  <span>{{k.rank}}</span>名
                </p>
                <p class="goods_name">{{k.goods_name}}</p>
              </div>
              <div class="click_get">
                <router-link :to="{path:'/parkPhoto/ActivityMap',query:{aid:aid}}">
                  <img src="../../assets/img/click_get.png" alt />
                </router-link>
              </div>
            </div>
          </mt-loadmore>
        </mt-tab-container-item>

        <!--个人排行榜-->

        <mt-tab-container-item id="2" v-if="(show_type == 2) || (show_type == 3)">
          <mt-loadmore
            :bottom-method="loadBottom"
            :bottom-all-loaded="user_info_allLoaded"
            ref="user_info_loadmore"
            :auto-fill="false"
          >
            <div class="item" v-for="(item,i) in user_info" v-show="user_info.length > 0" :key="i">
              <div class="left_cent">
                <div class="avatar">
                  <router-link
                    :to="{path:'/parkPhoto/detail',query:{id:item.spots_fk_id,aid:aid,type:2}}"
                  >
                    <img :src="item.user_icon" />
                  </router-link>
                </div>
                <div class="info-line">
                  <span class="name">{{item.user_nick}}</span>
                  <div class="vote">票数：{{item.num}}</div>
                  <div class="vote">编号：{{item.spots_fk_id}}</div>
                </div>
              </div>
              <div class="rank">
                <img src="../../assets/img/rank_1.png" alt v-if="item.rank == 1" />
                <img src="../../assets/img/rank_2.png" alt v-else-if="item.rank == 2" />
                <img src="../../assets/img/rank_3.png" alt v-else-if="item.rank == 3" />
                <p v-else class="num">
                  第
                  <span>{{item.rank}}</span>名
                </p>
                <p class="goods_name">{{item.goods_name}}</p>
              </div>
              <div class="click_get">
                <router-link :to="{path:'/parkPhoto/ActivityMap',query:{aid:aid}}">
                  <img src="../../assets/img/click_get.png" alt />
                </router-link>
              </div>
            </div>
          </mt-loadmore>
        </mt-tab-container-item>
      </mt-tab-container>
    </div>

    <div
      class="rank-list-2 dylan"
      v-else-if="(merchant.length > 0 || user_info.length > 0) && is_show_user_or_spots == 2"
    >
      <div class="dylan-title">
        <h2>排行榜</h2>
      </div>

      <div class="dylan-content" v-if="user_info.length > 0">
        <!--作品排行榜-->
        <div class="dylan-item" v-for="(item,i) in user_info" :key="i">
          <div class="dylan-left">
            <div class="dylan-avatar">
              <img :src="item.newUrl" />
            </div>
            <div class="dylan-info">
              <span class="dylan-name">{{item.spots_name}}</span>
              <div class="dylan-vote">票数：{{item.num}}</div>
              <div class="dylan-vote">编号：{{item.spots_fk_id}}</div>
            </div>
          </div>
          <div class="dylan-rank">
            <img src="../../assets/img/rank_1.png" alt v-if="item.rank == 1" />
            <img src="../../assets/img/rank_2.png" alt v-else-if="item.rank == 2" />
            <img src="../../assets/img/rank_3.png" alt v-else-if="item.rank == 3" />
            <p v-else class="num">{{item.rank}}</p>
            <p class="goods_name">{{item.goods_name}}</p>
          </div>
        </div>
      </div>
      <div class="dylan-loadmore" @click="loadMore" v-if="load_more">
        <span class="dylan-loadmore-line"></span>
        <span class="dylan-loadmore-text">加载更多</span>
      </div>
    </div>
    <router-link
      tag="div"
      :to="{path:'/parkPhoto/ActivityMap',query:{aid:aid}}"
      v-else
      class="foot_noinfo"
    >
      <img src="/static/img/foot_noinfo@2x.14391c2.png" alt />
      <p>还没有排行榜,点击进入地图页面</p>
    </router-link>
  </div>
  <!--<div v-else class="foot_noinfo"><img src="/static/img/foot_noinfo@2x.14391c2.png" alt="">
  <p>请在微信页面打开链接</p></div>-->
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      aid: this.$route.query.aid,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      merchant: [], //商家数据
      user_info: [], //用户数据
      merchant_page: 1,
      user_info_page: 1,
      type: "2", //表示是谁是第一个显示
      show_type: this.$route.query.show_type ? this.$route.query.show_type : 2, //1-显示商家,2-显示个人,3-显示全部
      merchant_allLoaded: false,
      user_info_allLoaded: false,
      merchant_length: 0, //新加的加载更多数据长度
      user_length: 0, //新加的加载个人更多数据长度
      wx_title: "", //微信分享
      wx_content: "",
      wx_img: "",
      wx_url:
        "http://www.zmlxj.com/api.php/weixin/wx_share?type=RankList&aid=" +
        this.$route.query.aid +
        "&show_type=" +
        this.$route.query.show_type,
      is_show_user_or_spots: "",
      load_more: true
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      //if (!this.is_weixin)return false;
      this.get_data(1, 1);
      this.get_data(1, 2);
      if (this.is_weixin) {
        this.wx_share(this.wx_url);
      }
    },
    get_data(page, type) {
      this.$ajax(
        "post",
        "SpotsRace/get_ranklist",
        { aid: this.aid, page: page, find_type: type },
        ret => {
          if (ret.data.id == 0) {
            if (type == 1) {
              this.merchant = this.merchant.concat(ret.data.data.rank); //原来的代码
              this.merchant_length = ret.data.data.rank.length; //新加的
              console.log(this.merchant_length);
            } else {
              this.user_info = this.user_info.concat(ret.data.data.rank); //原来的代码
              this.user_length = ret.data.data.rank.length; //新加的
              if (this.user_length % 10 == 0 && this.user_length != 0) {
                this.load_more = true;
              } else {
                this.load_more = false;
              }
              //console.log(this.user_length);
            }
            this.is_show_user_or_spots = ret.data.data.is_show_user_or_spots;

            //微信数据设置
            this.wx_title = ret.data.data.rank[0].spots_name;
            this.wx_img = ret.data.data.rank[0].newUrl;
            this.wx_content = ret.data.data.rank[0].spots_name;

            localStorage.setItem("wx_title", ret.data.data.rank[0].spots_name);
            localStorage.setItem("wx_img", ret.data.data.rank[0].newUrl);
            localStorage.setItem(
              "wx_content",
              ret.data.data.rank[0].spots_name
            );
          }
        }
      );
    },
    // 加载更多
    loadMore() {
      if (this.load_more) {
        this.get_data(this.user_info_page, this.type);
      } else {
        Toast("我是有底线的");
      }
    },
    //            加载更多
    loadBottom() {
      var that = this;
      setTimeout(() => {
        if (this.type == 1) {
          //表示商家
          if (this.merchant_length % 10 == 0) {
            that.merchant_page++;
            that.get_data(that.merchant_page, that.type);
          } else {
            that.merchant_allLoaded = true;
            that.$refs.merchant_loadmore.onBottomLoaded();
            Toast("没有更多了");
          }
        } else if (this.type == 2) {
          //表示个人
          if (this.user_length % 10 == 0) {
            that.user_info_page++;
            that.get_data(that.user_info_page, that.type);
          } else {
            that.user_info_allLoaded = true;
            that.$refs.user_info_loadmore.onBottomLoaded();
            Toast("没有更多了");
          }
        }
      }, 1500);
    }
  }
};
</script>
<style scoped>
.RankList .page-content {
  min-height: 100%;
}

.RankList .mint-navbar {
  width: 65%;
  margin: 0 auto;
}

.RankList .mint-tab-item-label {
  font-size: 0.8rem;
  line-height: 1rem;
}

.RankList .mint-navbar .mint-tab-item {
  border-bottom: 3px solid #5e5e5e;
  color: #5e5e5e;
  margin: 0 0.4rem;
}

.RankList .mint-navbar .mint-tab-item:nth-of-type(1).is-selected {
  border-bottom: 3px solid #bb5988;
  color: #bb5988;
  font-weight: 700;
}

.RankList .mint-navbar .mint-tab-item:nth-of-type(2).is-selected {
  border-bottom: 3px solid #e05534;
  color: #e05534;
  font-weight: 700;
}

.rank-list {
  padding: 0 0.5rem;
  overflow: hidden;
}

.rank-list .item {
  background: #ff9a80;
  border-radius: 4px;
  color: #fff;
  margin: 0.5rem 0;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.rank-list .item .left_cent {
  padding: 0.5rem;
  flex: 4;
  display: flex;
  align-items: center;
}

.rank-list .avatar {
  padding-right: 0.5rem;
  flex: 1;
  width: auto;
  height: auto;
}

.rank-list .mint-tab-container-item .avatar img {
  width: 3rem;
  height: 3rem;
  vertical-align: bottom;
}

.rank-list .mint-tab-container-item:nth-of-type(1) .avatar img {
  border-radius: 0;
}

.rank-list .click_get img {
  max-height: 5rem;
  vertical-align: bottom;
}

.rank-list .goods_name {
  color: #ea3f09;
  font-weight: 700;
}

.rank-list .rank {
  flex: 2;
  text-align: center;
}

.rank-list .rank img {
  width: 2.4rem;
}

.rank-list .rank .num {
  font-size: 1rem;
}

.rank-list .rank .num span {
  display: inline-block;
  font-family: STXingkai;
  font-size: 3.4rem;
  padding: 0 0.1rem;
  text-align: center;
  line-height: 2rem;
}

.rank-list .info-line {
  overflow: hidden;
  flex: 3;
}

.rank-list .info-line .name {
  font-size: 1rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.rank-list .info-line .vote {
  font-size: 0.7rem;
  color: #ece9e9;
}
.foot_noinfo {
  width: 100%;
  text-align: center;
  padding: 100px 0;
  border-top: 5px solid #f2f2f2;
}
.ParkPhoto {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  /* margin-bottom: 60%; */
}
.foot_noinfo img {
  display: inline-block;
  width: 10rem;
}
.foot_noinfo p {
  font-size: 0.8rem;
}

.dylan {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(238, 241, 243);
}
.dylan .dylan-title {
  height: 3rem;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px #ccc;
}
.dylan .dylan-title h2 {
  height: 100%;
  line-height: 3rem;
  /* width: 50%; */
  padding: 0 0.5rem;
  box-sizing: border-box;
  text-align: center;
  color: rgb(187, 89, 136);
  border-bottom: 3px solid rgb(187, 89, 136);
}
.dylan .dylan-content {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dylan .dylan-content .dylan-item {
  width: 100%;
  display: flex;
  background-color: #fff;
  margin-bottom: 0.5rem;
  box-shadow: 0 0 10px #ccc;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0.4rem;
}
.dylan .dylan-content .dylan-item .dylan-left {
  /* flex: 1; */
  width: calc(100% - 2.4rem);
  display: flex;
  align-items: center;
}
.dylan .dylan-content .dylan-item .dylan-rank {
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dylan .dylan-content .dylan-item .dylan-rank img {
  width: 2.4rem;
  /* margin:0  0.5rem; */
}
.dylan .dylan-content .dylan-item .dylan-rank .num {
  width: 2.4rem;
  font-family: STXingkai;
  font-size: 2rem;
  text-align: center;
  line-height: 2rem;
  color: #666;
}

.dylan .dylan-content .dylan-item .dylan-left .dylan-avatar {
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dylan .dylan-content .dylan-item .dylan-left .dylan-avatar img {
  width: 100%;
}
.dylan .dylan-content .dylan-item .dylan-left .dylan-info {
  /* flex: 1; */
  height: 100%;
  width: calc(100% - 3.5rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dylan .dylan-content .dylan-item .dylan-left .dylan-info .dylan-name {
  font-size: 0.88rem;
  width: 100%;
  font-weight: 600;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dylan .dylan-content .dylan-item .dylan-left .dylan-info .dylan-vote {
  font-size: 0.6rem;
  color: #666;
}
.dylan .dylan-loadmore {
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 1;
}
.dylan .dylan-loadmore .dylan-loadmore-line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 1px;
  background-color: #dfdfdf;
  transform: translate(-50%, 0);
  z-index: -1;
}
.dylan .dylan-loadmore .dylan-loadmore-text {
  z-index: 1;
  font-size: 0.7rem;
  color: #999;
  padding: 0 4px;
  background-color: rgb(238, 241, 243);
}
</style>