<template>
  <div class="body_">
    <div class="pay_">
      <form
        id="form_weixin"
        action="https://www.zmlxj.com/api.php/ticket/pay"
        method="post"
        enctype="multipart/form-data"
      >
        <input type="hidden" name="id" v-model="id" />
        <input type="hidden" name="order_id" v-model="order_id" />
        <input type="hidden" name="is_weixin" v-model="is_weixin" />
        <input type="hidden" name="open_id" v-model="open_id" />
        <input type="hidden" name="token" v-model="token" />
        <div class="weixin_">
          <button type="submit">
            <span>微信支付</span>
          </button>
        </div>
      </form>

      <form
        id="form"
        action="https://www.zmlxj.com/api.php/ticket/pay"
        method="post"
        enctype="multipart/form-data"
      >
        <input type="hidden" name="id" v-model="id" />
        <input type="hidden" name="order_id" v-model="order_id" />
        <input type="hidden" name="is_weixin" v-model="is_weixin" />
        <input type="hidden" name="open_id" v-model="open_id" />
        <input type="hidden" name="token" v-model="token" />
        <div class="ali_" v-if=" is_weixin == 'is_ali'">
          <button type="submit">
            <span>支付宝支付</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
export default {
  components: {},
  data() {
    return {
      id: this.$route.query.id > 0 ? this.$route.query.id : 0,
      order_id: this.$route.query.order_id ? this.$route.query.order_id : "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin ? "is_weixin" : "is_ali",
      open_id: this.$route.query.open_id,
      token: "zmlxj_2018sadfsdf"
    };
  },
  mounts() {},
  methods: {
    //进行支付
    pay() {
      var url = "ticket/pay";
      var id = this.id;
      var order_id = this.order_id;
      var is_weixin = this.is_weixin ? "is_weixin" : "is_ali";
      var open_id = this.open_id
        ? this.open_id
        : localStorage.getItem("open_id");
      if (!this.check_login()) {
        MessageBox({
          title: "提示",
          message: "确认登陆吗？",
          showCancelButton: true
        }).then(action => {
          if (action == "confirm") {
            this.get_User_Id();
          }
        });
      } else {
        var user_id = this.check_login().user_id;
        if (id && order_id && user_id && is_weixin) {
          this.$ajax(
            "post",
            url,
            {
              id: id,
              order_id: order_id,
              user_id: user_id,
              token: "zmlxj_2018",
              is_weixin: is_weixin,
              open_id: open_id
            },
            ret => {
              if (ret.data.id == -2) {
                Toast("支付成功");
                this.$router.push({
                  path: "/ticket/Success",
                  query: { id: id, order_id: order_id }
                });
              } else if (ret.data.id == -1) {
                Toast("少值不能支付");
              }
            }
          );
        } else {
          Toast("少值不能支付");
        }
      }
    }
  }
};
</script>

<style scoped>
.body_ {
  display: block;
  position: relative;
  margin-top: 4%;
  margin-left: 1%;
  width: 98%;
}
.body_ .pay_ {
  display: block;
  position: relative;
  margin-top: 50%;
}
.body_ .pay_ .weixin_ {
  display: block;
  position: relative;
}
.body_ .pay_ .weixin_ button {
  display: block;
  position: relative;
  width: 98%;
  border-radius: 10px;
}
.body_ .pay_ .weixin_ span {
  display: block;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  background-color: #0aba66;
}
.body_ .pay_ .ali_ {
  display: block;
  position: relative;

  margin-top: 2%;
}
.body_ .pay_ .ali_ button {
  display: block;
  position: relative;
  width: 98%;
  border-radius: 10px;
}
.body_ .pay_ .ali_ span {
  display: block;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  background-color: #ffe4b5;
}
</style>