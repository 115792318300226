<template>
  <div class="container" v-show="loaded">
    <i class="back iconfont iconmenu2" @click="goBack"></i>
    <img :src="info.banner_img_app" class="banner" />

    <header class="header">
      <h1 class="title">{{info.activity_name}}</h1>
      <!-- 价格 -->
      <div class="price" v-if="info.is_plan == 0">
        <div class="item full-price" v-if="info.full_price > 0">
          <span class="text name">成人:￥</span>
          <span class="text value">{{info.full_price}}</span>
        </div>
        <div class="item child-price" v-if="info.child_price > 0">
          <span class="text name">儿童:￥</span>
          <span class="text value">{{info.child_price}}</span>
        </div>
        <div class="item order-price" v-if="info.price > 0">
          <span class="text name">定金:￥</span>
          <span class="text value">{{info.price}}</span>
        </div>
      </div>

      <div class="stats" v-if="info.looks_numbers">
        <div class="info look-numbers">
          <i class="iconfont icontip1"></i>
          <span class="text name"></span>
          <span class="text value">{{info.looks_numbers}}次浏览</span>
        </div>
        <div class="info max" v-if="info.max_number">
          <i class="iconfont iconuser5"></i>
          <span class="text value">{{info.max_number}}参与</span>
        </div>
        <div class="info time">
          <i class="iconfont icontime"></i>
          <span class="text value">{{info.activity_time}}</span>
        </div>
        <div class="info address" v-if="info.address">
          <i class="iconfont iconaddress"></i>
          <span class="text value">{{info.activity_city}} · {{info.address}}</span>
          <a class="handle" @click="goOpenMap">导航</a>
        </div>
      </div>
    </header>

    <!-- detail -->
    <main class="detail main">
      <div class="title">
        <p class="text">活动内容</p>
      </div>
      <div class="rich-content content" v-html="activityRichText"></div>
    </main>

    <!-- 报名用户 -->
    <div class="main user" v-if="enterList && enterList.length">
      <div class="title">
        <span class="text">报名用户</span>
        <span class="tag">{{info.enter_numbers}}人</span>
      </div>
      <div class="admin-list" v-if="info.is_admin">
        <div class="item" v-for="(item, index) in enterList" :key="index">
          <div class="icon">
            <img class="img" :src="item.user_icon" />
          </div>
          <div class="content">
            <div class="info">
              <div class="nick">
                <span class="text">{{item.user_nick}}</span>
              </div>
              <div class="stats">
                <span
                  :class="['text', item.is_pay == '1' ? 'is-pay' : 'no-pay']"
                >{{item.is_pay_text}}</span>
              </div>
            </div>
            <i class="iconfont iconPhone-FontAwesome contact"></i>
          </div>
        </div>
        <div class="more" v-if="haveMoreUser" @click="getMoreUser">
          <img class="img" src="../../assets/nihewan/more-read.png" />
        </div>
      </div>
      <div class="list" v-else>
        <div class="item" v-for="item in enterList" :key="item.id">
          <img class="img" :src="item.user_icon" />
          <span class="text">{{item.user_nick}}</span>
        </div>
        <div class="more item" v-if="haveMoreUser" @click="getMoreUser">
          <img class="img" src="../../assets/nihewan/more-read.png" />
        </div>
      </div>
    </div>

    <!-- 作品 -->
    <div class="works main">
      <div class="title">
        <span class="text">作品集</span>
      </div>
      <div class="content">
        <div
          class="item"
          v-for="(item, index) in spots_list"
          :key="index"
          @click="navToWorks(item.id)"
        >
          <div class="img-box">
            <img class="img" :src="item.newUrl" />
          </div>
          <div class="info">
            <span class="text nick">{{item.user_nick}}</span>
            <span class="text time">{{item.add_time}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系信息 -->
    <div class="company" v-if="haveCompany">
      <technical-support
        :ts_type_="type_"
        contact_side="new"
        :text="companyInfo.title"
        :id="id"
        :company="companyInfo"
      ></technical-support>
    </div>
    <!-- 底部占位 -->
    <img class="bottom-holder" src="../../assets/nihewan/white_tempBg.jpg" />
    <!-- 底部操作菜单 -->
    <div class="menu">
      <div class="item home" @click="navToHome">
        <i class="iconfont iconzhuye-copy"></i>
        <span class="text">首页</span>
      </div>
      <a class="item customer" :href="'tel:' + info.tel_">
        <i class="iconfont iconliuyan"></i>
        <span class="text">客服</span>
      </a>
      <div class="item publish" @click="handlePublish">
        <i class="iconfont iconshare2"></i>
        <span class="text">发布</span>
      </div>
      <div class="item entry" @click="handleEntry">
        <span class="text" v-if="info.status == 0">立即报名</span>
        <span class="text" v-else-if="info.status == 1">正在审核</span>
        <span class="text" v-else-if="info.status == 2">报名成功</span>
        <span class="text" v-else-if="info.status == 3">报名失败</span>
      </div>
    </div>
    <!-- 价目表 -->
    <div class="order-mask" v-if="showOrder" @click.self="showOrder=false"></div>
    <div class="order" v-show="showOrder">
      <div class="close" @click="showOrder=false">
        <i class="iconfont iconclose"></i>
      </div>

      <div class="icon">
        <img class="img" :src="info.banner_img_app" />
      </div>
      <div class="show-price">
        <span class="text name">￥</span>
        <span class="text value">{{selectPrice ? selectPrice * selectNumber : info.price}}</span>
      </div>
      <div class="list">
        <div class="title">
          <span class="text">选择金额：</span>
        </div>
        <div class="sublist">
          <div
            :class="['item', item.id === selectId ? 'active' : '']"
            v-for="(item, i) in info.price_label"
            :key="i"
            @click="handleSelectPrice(item)"
          >
            <span class="text name">{{item.title}}</span>
            <span class="text value">￥{{item.price}}</span>
          </div>
        </div>
      </div>
      <div class="number" v-if="canSetNumber">
        <div class="title">
          <span class="text">选择数量：</span>
        </div>
        <div class="select">
          <button class="btn" @click="handleDecrease">-</button>
          <input
            class="inp"
            type="number"
            min="1"
            max="256"
            v-model="selectNumber"
            @input="handleNumberInput"
          />
          <button class="btn" @click="handleIncrease">+</button>
        </div>
      </div>
      <div :class="['certain', selectId || selectId === 0 ? 'active' : '']" @click="handleCertain">
        <span class="text">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Indicator, MessageBox } from 'mint-ui'
import TechnicalSupport from '../../components/TechnicalSupport'
export default {
  components: {
    'technical-support': TechnicalSupport
  },
  data () {
    return {
      // 初始化
      park_id: 2609,
      id: this.$route.query.id || '1837',
      version: '1.0.0',
      type_: 'label',
      backUrl: 'nihewanActivity',
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      open_id: localStorage.getItem('open_id_' + this.$route.query.id)
        ? localStorage.getItem('open_id_' + this.$route.query.id)
        : '',
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      aid: this.$route.query.aid || 0,
      // 请求数据
      page: 1,
      isLoading: false,
      loaded: false,
      haveMoreSpots: true,
      spots_list: [],
      haveMoreUser: true,
      info: {},
      userPage: '',
      activityRichText: '',
      enterList: [],

      // 自定义
      showOrder: false,
      selectTitle: '',
      selectPrice: '',
      selectNumber: 1,
      selectId: '',
      is_price: '',
      canSetNumber: false,
      user_id: '',
      verify: '',

      // company
      companyInfo: {},
      haveCompany: false
    }
  },
  mounted () {
    if (this.token) {
      // 已经存在token了,就不用生成新的token
    } else {
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id_' + this.id, this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id_' + this.id, '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id_' + this.id, '')
      }
    }
    if (!this.open_id) {
      this.authorize()
    }

    this.checkLogin()

    if (this.is_weixin) {
      this.dealWithUrl(window.location.href)
    }

    this.getData()
    this.getWorks()
    this.getCompanyInfo(this.id, this.token).then(
      res => {
        this.companyInfo = res
        this.haveCompany = true
      },
      err => {
        console.log(err)
      }
    )

    this.$nextTick(() => {
      // 在冒泡阶段处理滚动的事件
      window.addEventListener('scroll', this.throttle(this.handleScroll))
    })
  },
  methods: {
    /**
     * 确定报名
     */
    handleCertain () {
      const is_free = this.info.is_free,
        is_collect = this.info.is_collect,
        is_group = this.info.is_group,
        is_leader = this.info.is_leader,
        aid = this.aid,
        selectPrice = this.selectPrice,
        number = this.selectNumber,
        tel = this.info.tel_, // 服务电话
        btime = this.info.activity_begin_time,
        etime = this.info.activity_end_time,
        title = this.info.activity_name,
        selectTitle = this.selectTitle,
        is_price = this.is_price,
        have_group_name = this.info.have_group_name
      let path = '',
        query = null
      if (is_free == '1') {
        // 免费活动
        path = '/nihewan/registFree'
        query = {
          id: this.id,
          aid,
          tel,
          btime,
          etime,
          title
        }
      } else if (is_collect == '1') {
        // 收费活动
        if ((this.selectId || this.selectId === 0) && number && selectPrice) {
          path = '/nihewan/registCollect'
          query = {
            id: this.id,
            aid,
            tel,
            btime,
            etime,
            selectPrice,
            number,
            title,
            selectTitle,
            is_price
          }
        } else {
          // 未选择价格
          return false
        }
      } else if (is_group > 0) {
        if ((this.selectId || this.selectId === 0) && number && selectPrice) {
          path = '/nihewan/registGroup'
          query = {
            id: this.id,
            aid,
            tel,
            btime,
            etime,
            selectPrice,
            number,
            title,
            selectTitle,
            is_group,
            is_leader,
            have_group_name,
            is_price
          }
        } else {
          // 未选择价格
          return false
        }
      } else {
        return false
      }
      this.$router.push({
        path,
        query
      })
    },
    // 报名
    handleEntry () {
      if (this.info.is_free == '1') {
        // 免费活动
        this.handleCertain()
      } else if (this.info.is_collect == '1') {
        // 普通收费活动

        this.showOrder = true
      } else if (this.info.is_group > 0) {
        // 组队活动
        this.showOrder = true
      } else {
        // 其他活动
        return false
      }
    },

    /**
     * 输入数值
     */
    handleNumberInput (e) {
      this.selectNumber = this.selectNumber.replace(/[^\d]/g, '')
      if (!this.selectNumber || this.selectNumber < 1) {
        this.selectNumber = 1
      } else if (this.selectNumber > 256) {
        this.selectNumber = 256
      }
    },
    /**
     * 增加数值
     */
    handleIncrease () {
      if (this.selectNumber < 256) {
        this.selectNumber = this.selectNumber + 1
      } else {
        this.selectNumber = 256
      }
    },
    /**
     * 减少数值
     */
    handleDecrease () {
      if (this.selectNumber > 1) {
        this.selectNumber = this.selectNumber - 1
      } else {
        this.selectNumber = 1
      }
    },
    /**
     * 选择价格
     */
    handleSelectPrice (item) {
      if (item.id === this.selectId) {
        this.selectTitle = ''
        this.selectPrice = ''
        this.selectId = ''
        this.is_price = ''
      } else {
        this.selectTitle = item.title
        this.selectPrice = item.price
        this.selectId = item.id
        this.is_price = item.is_price
      }
    },
    // 作品
    navToWorks (id) {
      this.$router.push({
        path: '/nihewan/works',
        query: {
          id: this.id,
          aid: id
        }
      })
    },
    // 导航
    goOpenMap () {
      if (this.is_weixin) {
        const lng = this.info.lng,
          lat = this.info.lat
        if (lng && lat) {
          this.wx_nav(
            window.location.href,
            lng * 1,
            lat * 1,
            this.info.activity_name,
            this.info.address
          )
        } else {
          Toast('没有导航')
        }
      } else {
        Toast('请在微信中打开,使用导航')
      }
    },
    // 首页
    navToHome () {
      this.$router.push({
        path: '/nihewan/impression',
        query: {
          id: this.id
        }
      })
    },
    // 发布
    handlePublish () {
      this.$router.push({
        path: '/album/publishPhoto',
        query: {
          id: this.id,
          aid: this.aid,
          back: 'nihewan_activity',
          activity_name: this.info.activity_name
        }
      })
    },
    // 上一级
    goBack () {
      this.$router.push({
        path: '/nihewan/home',
        query: {
          id: this.id
        }
      })
    },
    /**
     * 更多用户
     */
    getMoreUser () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true
      this.$ajax(
        'post',
        'index/get_more_enter_user',
        {
          aid: this.aid,
          token: this.token,
          page: this.userPage
        },
        res => {
          if (res.data.id == 0) {
            this.enterList.push(...res.data.data.data)
            if (this.enterList.length < this.info.enter_numbers) {
              this.haveMoreUser = true
            } else {
              this.haveMoreUser = false
            }
            this.userPage++
          }

          this.isLoading = false
        }
      )
    },
    /**
     * 获取作品
     */
    getWorks () {
      if (this.isLoading || !this.haveMoreSpots) {
        return false
      }
      this.isLoading = true
      this.$ajax(
        'post',
        'Common/search',
        {
          aid: this.aid,
          type: 2,
          page: this.page,
          union_id: this.open_id,
          version: this.version,
          park_id: this.park_id
        },
        res => {
          if (res.data.id == 0) {
            this.spots_list.push(...res.data.data)
            this.page++
          } else {
            console.log('没有作品')
          }

          if (!res.data.data || res.data.data.length < 10) {
            this.haveMoreSpots = false
          }

          this.isLoading = false
        }
      )
    },
    // 获取数据
    getData () {
      if (this.user_id && this.aid && this.open_id) {
        Indicator.open('加载中...')
        this.$ajax(
          'post',
          'index/getBannerInfo_new_v2',
          {
            banner_id: this.aid,
            user_id: this.user_id,
            token: this.token,
            version: this.version,
            park_id: this.park_id,
            open_id: this.open_id
          },
          res => {
            if (res.data.id == 0) {
              document.title = res.data.activity_name
              this.enterList.push(...res.data.enter)
              if (this.enterList.length < res.data.enter_numbers) {
                this.haveMoreUser = true
              } else {
                this.haveMoreUser = false
              }
              this.info = res.data
              this.userPage++
              this.activityRichText = res.data.activity_rich_text

              const sUrl = window.location.href,
                wx_title = res.data.activity_name,
                wx_content = res.data.activity_content,
                wx_img = res.data.banner_img_app
              if (this.is_weixin) {
                this.wx_share_callback(sUrl, {
                  url: sUrl,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                })
              }
            }

            if (!this.loaded) {
              this.loaded = true
            }
            Indicator.close()
          }
        )
      }
    },
    // 登录
    checkLogin () {
      if (!this.check_login()) {
        MessageBox.alert('请先登录').then(action => {
          if (action == 'confirm') {
            localStorage.setItem('login_url', location.hash)
            this.$router.push({ path: '/my/login' })
          }
        })
      } else {
        this.user_id = this.check_login().user_id
        this.verify = this.check_login().user_id + new Date().getTime()
      }
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?id=' +
        this.id +
        '&id_=' +
        this.aid +
        '&token=' +
        this.token +
        '&state=' +
        this.backUrl
      window.location.href = open_url
    },
    // 获取页面滚动的高度
    handleScroll () {
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight

      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      // 滚动条到底部的条件
      if (scrollTop > 400) {
        this.showToTop = true
      } else {
        this.showToTop = false
      }

      // 加载更多
      if (
        scrollTop + windowHeight > scrollHeight - 100 &&
        this.haveMoreSpots &&
        this.page > 1
      ) {
        this.getWorks()
      }
    },
    throttle (fn, interval = 100) {
      let canRun = true
      return function () {
        if (!canRun) return
        canRun = false
        setTimeout(() => {
          fn.apply(this, arguments)
          canRun = true
        }, interval)
      }
    }
  }
}
</script>

<style scoped>
.container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
}

.banner {
  display: block;
  width: 100%;
}

.back {
  position: fixed;
  top: 0.4rem;
  left: 0.4rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

/* header */

.header {
  width: 100%;
  padding: 1rem 3vw;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
}

.header > .title {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  text-align: justify;
  color: #000;
}

.header > .stats {
  width: 100%;
  margin-top: 0.8rem;
}

.header > .stats > .info {
  height: 1.8rem;
  display: flex;
  align-items: center;
  font-size: 0.74rem;
  color: #666;
}

.header > .stats > .info > .iconfont {
  line-height: 1.8rem;
  margin-right: 0.5rem;
  vertical-align: middle;
  font-size: 0.88rem;
  color: #000;
}

.header > .stats > .info > .value {
  line-height: 1.8rem;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header > .stats > .info > .handle {
  padding: 3px 9px;
  font-size: 0.68rem;
  color: #409eff;
  border: 1px solid rgba(64, 160, 255, 0.6);
  border-radius: 4px;
}

/* price */

.price {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.price > .item {
  margin-top: 1rem;
  padding: 0 4px;
  margin-right: 0.8rem;
  border-radius: 2px;
  border: 1px solid #ffc56f;
  font-size: 0.68rem;
  color: #e6a23c;
}

/* main */

.main {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(245, 247, 250);
}

.main > .title {
  padding: 1rem 3vw;
  font-size: 0.88rem;
  font-weight: bold;
  color: #333;
}

.main > .title > .text {
  position: relative;
  display: inline-block;
  padding-left: 10px;
}

.main > .title > .text::before {
  position: absolute;
  content: "";
  height: 0.88rem;
  width: 3px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #e6a23c;
}

.main > .content {
  padding: 0 3vw;
  font-size: 0.8rem;
  color: #444;
  text-align: justify;
}

.detail > .content {
  padding-bottom: 1rem;
}

.detail > .rich-content >>> img {
  display: block;
  width: 100%;
  margin-top: 1rem;
}

/* 报名用户 */

.user > .title > .tag {
  font-size: 0.68rem;
  font-weight: normal;
  color: #999;
}

/* 管理员 */

.user > .admin-list {
  padding: 3vw 4vw;
  box-sizing: border-box;
}

.user > .admin-list > .item {
  height: 12vw;
  display: flex;
  align-items: center;
}

.user > .admin-list > .item:not(:first-child) {
  margin-top: 1rem;
}

.user > .admin-list .icon {
  margin-right: 6px;
  width: 12vw;
  height: 12vw;
  padding: 2px;
  box-sizing: border-box;
}

.user > .admin-list .img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.user > .admin-list .content {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.user > .admin-list .info {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.user > .admin-list .nick {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.74rem;
}

.user > .admin-list .stats {
  font-size: 0.68rem;
}

.user > .admin-list .stats > .text {
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  border: 1px solid transparent;
}

.user > .admin-list .stats > .no-pay {
  border-color: #fdd79f;
  color: #e6a23c;
}

.user > .admin-list .stats > .is-pay {
  border-color: #81f0ff;
  color: #3cd2e6;
}

.user .contact {
  height: 8vw;
  width: 8vw;
  background-color: #e6a23c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
}

.user > .admin-list > .more {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user > .admin-list > .more > .img {
  display: block;
  width: 12vw;
  height: 12vw;
}

/* 非管理员 */

.user > .list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.user > .list > .item {
  width: 25vw;
  padding: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.user > .list > .item > .img {
  display: block;
  width: 12vw;
  height: 12vw;
  border-radius: 4px;
}

.user > .list > .item > .text {
  margin-top: 4px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.68rem;
  text-align: center;
  color: #333;
}

/* 作品 */

.works > .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.works > .content > .item {
  position: relative;
  margin-bottom: 1rem;
  width: 45.5vw;
  height: 45.5vw;
}

.works .img-box {
  width: 100%;
  height: 100%;
}

.works .img-box > .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.works .info {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.4);
}

.works .info > .nick {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.74rem;
  color: #fff;
}

.works .info > .time {
  font-size: 0.68rem;
  color: #eee;
}

.bottom-holder {
  display: block;
  width: 100%;
}

/* 目录 */

.menu {
  position: fixed;
  bottom: -1px;
  left: 0;
  display: flex;
  height: 3rem;
  width: 100%;
  box-shadow: 0 -1px 1px -1px rgba(0, 0, 0, 0.2);
}

.menu > .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.74rem;
}

.menu > .item > .iconfont {
  font-size: 1.2rem;
  color: #999;
}

.menu > .item:not(:last-child) {
  width: 18%;
  color: #999;
  background-color: #fff;
}

.menu > .entry {
  flex: 1;
  font-size: 1rem;
  color: #fff;
  background-color: #e6a23c;
}

/* 价目表 */

.order-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
}

.order {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 5;
  animation: bounce 0.3s linear 1 both;
}

@keyframes bounce {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.order > .icon {
  position: absolute;
  top: -2rem;
  left: 0.8rem;
  width: 5rem;
  height: 5rem;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
}

.order > .icon > .img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.order > .show-price {
  height: 3rem;
  width: 100%;
  padding: 0.5rem 3rem 0.5rem 7rem;
  line-height: 2rem;
  box-sizing: border-box;
  color: #fd4545;
}

.order > .show-price > .name {
  font-size: 0.8rem;
}

.order > .show-price > .value {
  font-size: 1.2rem;
  font-weight: bold;
}

.order > .close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order > .close > .iconfont {
  font-size: 1rem;
  color: #999;
}

.order > .list {
  margin-top: 1rem;
  padding: 0 1rem;
  box-sizing: border-box;
}

.order > .list > .title {
  font-size: 0.8rem;
}

.order > .list > .sublist {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.order > .list > .sublist > .item {
  margin-right: 0.5rem;
  padding: 4px 12px;
  border-radius: 2px;
  font-size: 0.68rem;
  color: #e6a23c;
  background-color: #fff3e3;
}

.order > .list > .sublist > .item.active {
  background-color: #e6a23c;
  color: #fff;
}

.order > .number {
  padding: 0 1rem;
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order > .number > .title {
  font-size: 0.8rem;
}

.order > .number > .select {
  display: flex;
  align-items: center;
}

.order > .number > .select > .btn {
  min-height: 0;
  width: 1.6rem;
  height: 1.6rem;
  background-color: rgb(243, 244, 247);
  font-size: 1rem;
  color: #666;
}

.order > .number > .select > .inp {
  width: 2.4rem;
  height: 1.6rem;
  text-align: center;
  font-size: 0.8rem;
}

.order > .certain {
  margin-top: 2rem;
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  background-color: #bdbdbd;
  color: #fff;
  font-size: 1rem;
}

.order > .certain.active {
  background-color: #e6a23c;
}

.company {
  position: relative;
  width: 100%;
  padding-top: 1rem;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 0.74rem;
}
</style>
