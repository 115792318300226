<template>
  <div class="HomeParkList" v-title data-title="公园列表">
    <mt-header fixed title="公园列表">
      <a slot="left">
        <mt-button @click.native="$router.go(-1)" icon="back"></mt-button>
      </a>
      <router-link to="/park/HomeSouPark" slot="right">
        <button class="mint-button mint-button--default mint-button--normal">
          <span class="mint-button-icon">
            <i class="iconfont icon-sousuo1"></i>
          </span>
          <label class="mint-button-text"></label>
        </button>
      </router-link>
    </mt-header>
    <div class="page-content">
      <home-park :is_tit="false" :is_hot="false" @ievent="ievent" ref="child"></home-park>
    </div>
  </div>
</template>
<script>
import HomePark from "../../components/HomePark";
export default {
  components: {
    HomePark
  },
  data() {
    return {
      park_list: []
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      this.getData(1);
    },
    ievent(...data) {
      this.getData(data[0]);
    },
    //            获取数据
    getData(page) {
      this.$ajax(
        "post",
        "Park/clickMore_v2",
        { key_word: "", page: page },
        res => {
          if (res.data.id == 0) {
            this.park_list = this.park_list.concat(res.data.data);

            this.$refs.child.parentMsg({
              park_list: this.park_list
            });
          }
        }
      );
    }
  }
};
</script>
<style scoped>
.HomeParkList .page-content {
  padding-bottom: 0;
}
</style>