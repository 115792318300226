<template>
  <div class="HuangShiTravelIndex ParkPhoto map" :class="{active:is_big}">
    <!--地图-->
    <div class="container_box" :class="{active:is_big}">
      <div id="container" style="width:100%; height:100%"></div>
      <div class="active_user_box ParkPhotoListDetail" :class="{active:have_title != 0}">
        <div class="who_vote">
          <div class="row" v-if="have_title==0&& !is_map_info">
            <div class="list">
              <router-link
                :to="{name:'otherIndex',params:{uid:k.user_id}}"
                class="num_box"
                v-for="(k,i) in info.user_list"
                :key="i"
              >
                <img :src="k.user_icon" />
                <div class="num">
                  <i class="iconfont icon-zuji"></i>
                  {{k.spots_numbers}}
                </div>
                <span v-if="i<3">NO.{{i + 1}}</span>
              </router-link>
            </div>
          </div>
          <h2 v-if="have_title==1">{{title_content}}</h2>
          <ul class="map_info clearfix" v-if="is_map_info">
            <li>
              <p>总里程</p>
              <p>{{spots_point.length}}</p>
            </li>
            <li>
              <p>最高海拔</p>
              <p>{{spots_point.height}}</p>
            </li>
            <li>
              <p>最低海拔</p>
              <p>{{spots_point.low}}</p>
            </li>
            <li>
              <p>徒步时间</p>
              <p>{{spots_point.time_length}}</p>
            </li>
          </ul>
        </div>
        <!--点击游客-->
        <div class="fr" @click="click_tab_label('user')">
          <img src="../../assets/img/map_park_user_yes.png" alt v-if="map_user_yes" />
          <img src="../../assets/img/map_park_user.png" alt v-else />
        </div>
      </div>
      <!--类型切换-->
      <div v-if="is_big" class="tab_label clearfix">
        <ul>
          <li @click="click_tab_label(19)">
            <img src="../../assets/img/map_park_scene_yes.png" alt v-if="label_info == 19" />
            <img src="../../assets/img/map_park_scene.png" alt v-else />
          </li>
          <li @click="click_tab_label(18)">
            <img src="../../assets/img/map_park_eat_yes.png" alt v-if="label_info == 18" />
            <img src="../../assets/img/map_park_eat.png" alt v-else />
          </li>
          <li @click="click_tab_label(21)">
            <img src="../../assets/img/map_park_cease_yes.png" alt v-if="label_info == 21" />
            <img src="../../assets/img/map_park_cease.png" alt v-else />
          </li>
          <li @click="click_tab_label(20)">
            <img src="../../assets/img/map_park_buy_yes.png" alt v-if="label_info == 20" />
            <img src="../../assets/img/map_park_buy.png" alt v-else />
          </li>
          <li @click="click_tab_label(17)">
            <img src="../../assets/img/map_park_lavatory_yes.png" alt v-if="label_info == 17" />
            <img src="../../assets/img/map_park_lavatory.png" alt v-else />
          </li>
          <li @click="click_tab_label(3)">
            <img src="../../assets/img/map_park_all_yes.png" alt v-if="label_info == 3" />
            <img src="../../assets/img/map_park_all.png" alt v-else />
          </li>
        </ul>
      </div>
      <div v-if="is_big" class="left_but">
        <div class="mak_tab">
          <!--点/图片 切换-->
          <img
            src="../../assets/img/map_park_tab_img.png"
            alt
            @click="tab_button"
            v-if="is_tab_button"
          />
          <img
            src="../../assets/img/map_park_tab_img_maks.png"
            alt
            @click="tab_button"
            v-if="!is_tab_button"
          />
          <img
            src="../../assets/img/map_3d.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="is_tab_map_3d"
          />
          <img
            src="../../assets/img/map_3d_yes.png"
            alt
            class="map_3d"
            @click="is_tab_map_3d = !is_tab_map_3d,map_set_3d(map, is_tab_map_3d)"
            v-if="!is_tab_map_3d"
          />
        </div>
        <div class="mak_tab">
          <!--地图 + -  -->
          <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
          <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map,'-')" />
        </div>
        <div class="mak_tab map_my_location">
          <!--地图定位- -->
          <img src="../../assets/img/map_my_location.png" alt @click="click_Geo_location()" />
        </div>
      </div>
      <div class="button_list">
        <ul class="click_list">
          <li @click="click_list('park')" :class="{active:is_park_search}">
            <i class="iconfont icon-senlingongyuan-copy"></i>公园列表
          </li>
          <li :class="{active:is_root_books}" @click="click_list('root')">
            <img src="../../assets/img/line.png" alt />好玩路线 | 路书
          </li>
          <li v-if="have_title==0 && is_big" @click="click_is_big()">
            <i class="iconfont icon-canyin2"></i>首页
          </li>
          <li v-if="have_title==0 && !is_big" @click="click_is_big()">
            <i class="iconfont icon-canyin2"></i>进入地图
          </li>
        </ul>
        <div
          class="content_list"
          :class="{root_book:is_root_books}"
          v-if="is_park_search || is_root_books"
        >
          <div class="park_search">
            <p v-if="is_park_search">共{{list_data.length}}个公园</p>
            <p v-if="is_root_books">共{{list_data.length}}个路书</p>
            <img @click="list_delete" src="../../assets/img/footprints_delete.png" alt />
            <div class="input_search clearfix">
              <input class="fl" type="text" placeholder="请输入公园" v-model="value_parkName" />
              <button class="fl" @click="searchData(value_parkName,is_park_search)">确定</button>
            </div>
            <ul>
              <li
                @click="list_path(k.id,k.label_fk_id)"
                v-for="(k, i) in list_data"
                :key="i"
              >{{k.park_name || k.label_name}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--活跃用户-->
    <div class="active_user_box ParkPhotoListDetail" v-if="info.have_user_list == 1">
      <div class="who_vote">
        <div class="row">
          <div class="time">活跃用户</div>
          <div class="list">
            <router-link
              :to="{name:'otherIndex',params:{uid:k.user_id}}"
              class="num_box"
              v-for="(k,i) in info.user_list"
              :key="i"
            >
              <img :src="k.user_icon" />
              <div class="num">
                <i class="iconfont icon-zuji"></i>
                {{k.spots_numbers}}
              </div>
              <span v-if="i<3">NO.{{i + 1}}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- 公园列表 -->
    <div class="PhotoList border">
      <div class="park-list" v-if="park_list">
        <h3>热门公园</h3>
        <router-link
          :to="{path:'/parkPhoto/ParkMapLineList',query:{aid:aid,park_id:k.id,lng:k.lng,lat:k.lat}}"
          class="item"
          v-for="(k,i) in filterBy(park_list)"
          :key="i"
        >
          <a href="photo_list.html">
            <div class="count">
              <span>
                <i class="iconfont icon-zuji"></i>
                {{k.spots_numbers}}
              </span>
            </div>
            <img class="pic" :src="k.img || k.thumb_img_3" alt />
          </a>
          <div class="name">{{k.park_name}}</div>
        </router-link>
      </div>
      <div class="more" @click="num = park_list.length" v-if="num==4">
        更多公园
        <i class="iconfont icon-jinru"></i>
      </div>
      <div class="more" @click="num = 4" v-else>收起</div>
      <!--<div class="page-tips" v-else>请输入关键字</div>-->
    </div>
    <!--路书列表  组件-->
    <div v-if="info.have_root_books" class="template-line-info">
      <line-info ref="child" :is_border="true" is_tit="黄石全域旅游电子导图" :datainfo="info.root_books_list"></line-info>
    </div>
  </div>
</template>
<script>
import { Toast } from 'mint-ui'
import LineInfo from '../../components/LineInfo'
export default {
  components: {
    LineInfo
  },
  data () {
    return {
      map: {},
      aid: this.$route.query.aid,
      info: {},
      list_data: [], // 搜索框列表显示数据
      park_list: [], // 公园列表数据
      spot_park_list: [], // 路书、路线列表数据
      Marker: [], // 公园点
      Circle: [], // 公园范围圆
      SpotMarker: [], // 景点、美食。。点
      is_big: true, // 地图放大缩小
      label_info: 0, // 点击景点、美食。。请求点数据    记录当前点击的tab
      is_tab_button: true, // 图片切换是否点击
      is_tab_map_3d: true, // 3D地图按钮是否点击
      map_user_yes: false, // 是否点击游客按钮
      is_park_search: false, // 是否显示下拉列表公园数据
      is_root_books: false, // 是否显示下拉列表路书线路数据
      num: 4, // 地图缩小状态：公园显示4个 点击更多显示更多公园
      lng: 0, // 经度
      lat: 0, // 纬度
      spots_point: {}, // 路书线路数据（里程、时间、海拔）
      is_map_info: false, // 是否显示路书线路数据（里程、时间、海拔）
      value_parkName: '', // 下拉列表搜索框val
      have_title: '', // =0 > 显示活跃用户 首页按钮     =1 > 显示title  路书线路数据（里程、时间、海拔）
      title_content: '' // have_title = 1 展示的title
    }
  },
  mounted () {
    this.unload()
  },
  methods: {
    filterBy (ls) {
      return ls.filter((v, i) => {
        return i < this.num
      })
    },
    unload () {
      // 初始化地图对象，加载地图
      this.map = this.map_init()
      this.get_data({ aid: this.aid })
    },
    // 输入关键字搜索列表数据   is_park_search == true 列表数据显示公园列表  ==false 数据为路书列表
    searchData (parkName, isParkSearch) {
      if (isParkSearch) {
        this.list_data = this.park_list
      } else {
        this.list_data = this.info.root_books_list
      }
      if (parkName) {
        var result = []
        for (var i = 0; i < this.list_data.length; i++) {
          var obj = this.list_data[i]
          for (var k in obj) {
            if (obj[k].indexOf(parkName.replace(/^\s*|\s*$/g, '')) != -1) {
              if (obj['park_name']) {
                result.push({ park_name: obj['park_name'], id: obj['id'] })
              } else {
                result.push({
                  park_name: obj['label_name'],
                  label_fk_id: obj['label_fk_id']
                })
              }
              break
            }
          }
        }
        this.list_data = result
      }
    },
    // ajax 地图点数据    开始显示公园 传入aid    点击吃,住，玩，购时，传入参数label_id
    get_data (data) {
      var that = this
      this.$ajax('post', 'SpotsRace/get_huang_shi_data', data, ret => {
        if (ret.data.id === 0) {
          this.info = ret.data.data
          // 判断have_title的值 =1> 隐藏活跃用户列表（显示title） 、 点击首页li
          this.have_title = ret.data.data.have_title
          // =0> 显示列表 点击首页缩小
          this.title_content = ret.data.data.title // have_title==1> 显示的title

          this.park_list = ret.data.data.park_list // 点数据
          this.map_add_Marker({
            map: this.map,
            list_data: ret.data.data.park_list,
            type: 'park',
            callback: function (markerArr) {
              that.Marker = markerArr
            },
            aid: this.aid
          })
        }
      })
    },
    click_tab_label (type) {
      this.map.clearMap()
      this.label_info = type
      this.is_tab_button = true
      this.is_map_info = false
      if (type === 'user') {
        // 用户
        this.map_user_yes = !this.map_user_yes
        this.get_spot_data(
          {
            aid: this.aid
          },
          type
        )
      } else {
        // 全部 为空    景 19     吃 18    住 21    购 20
        this.map_user_yes = false
        this.get_spot_data(
          {
            aid: this.aid,
            label_id: type
          },
          type
        )
      }
    },
    get_spot_data (data, type) {
      var url = ''
      if (type === 'user') {
        url = 'SpotsRace/get_huang_shi_user_data'
      } else {
        url = 'SpotsRace/get_huang_shi_spots_data'
      }
      this.$ajax('post', url, data, ret => {
        if (ret.data.id === 0) {
          this.spot_park_list = ret.data.data
          this.map_add_Marker({
            map: this.map,
            list_data: ret.data.data,
            type: type,
            callback: (markerArr) => {
              this.SpotMarker = markerArr
            }
          })
        } else {
          Toast(ret.data.msg)
        }
      })
    },
    tab_button () {
      if (!this.map_user_yes) {
        this.is_tab_button = !this.is_tab_button
        this.map_cut_Marker(this.map, this.SpotMarker, this.is_tab_button)
      }
    },
    click_list (type) {
      this.map.clearInfoWindow()
      if (!this.is_big) {
        Toast('请进入地图查看')
        return
      }
      if (type === 'park') {
        if (this.is_root_books) {
          this.is_root_books = false
          this.is_park_search = true
        } else {
          this.is_park_search = !this.is_park_search
        }
        if (this.park_list.length > 0) {
          this.list_data = this.park_list
        } else {
          Toast('无景点')
          this.is_park_search = false
        }
      } else if (type === 'root') {
        if (this.is_park_search) {
          this.is_park_search = false
          this.is_root_books = true
        } else {
          this.is_root_books = !this.is_root_books
        }
        if (this.info.root_books_list.length > 0) {
          this.list_data = this.info.root_books_list
        } else {
          Toast('无路线 | 路书')
          this.is_root_books = false
        }
      }
    },
    list_path (id, labelFkId) {
      this.is_map_info = false
      this.is_root_books = this.is_park_search = false
      if (id) {
        this.$router.push({
          path: '/parkPhoto/ParkMapLineList',
          query: { aid: this.aid, park_id: id }
        })
      } else if (labelFkId) {
        this.$ajax(
          'post',
          'SpotsRace/get_root_book_point',
          { label_id: labelFkId },
          ret => {
            if (ret.data.id === 0) {
              this.spots_point = ret.data.data
              this.is_map_info = true
              this.map_root_book_point(
                this.map,
                ret.data.data.spots_point_list,
                data => {
                  this.jump(data, labelFkId)
                }
              )
            } else {
              Toast(ret.data.msg)
            }
          }
        )
      }
    },
    click_is_big () {
      this.is_root_books = this.is_park_search = false
      this.is_big = !this.is_big
      this.map.clearInfoWindow()
    },
    list_delete () {
      this.is_root_books = this.is_park_search = false
    },
    click_Geo_location () {
      this.map_Geo_location({
        map: this.map,
        callback: (lng, lat) => {
          this.lng = lng
          this.lat = lat
        }
      })
    }
  }
}
</script>
<style scoped>
.ParkPhoto.map .who_vote h2 {
  color: #fff;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1rem;
  text-align: center;
}

.ParkPhoto.map .ParkPhotoListDetail .fr img {
  height: 2.2rem;
  padding: 0.4rem;
  cursor: pointer;
}

.ParkPhoto.map .container_box .ParkPhotoListDetail .who_vote {
  width: 80%;
  display: inline-block;
}

.ParkPhoto.map .container_box .active_user_box {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  z-index: 1;
}

.ParkPhoto.map .container_box .active_user_box.active {
  padding: 0;
}

.ParkPhoto.map .container_box .active_user_box.active img {
  padding: 0.1rem;
}

.ParkPhoto.map .active_user_box.ParkPhotoListDetail .who_vote .list .num {
  color: #fff;
}

.amap-info > div {
  width: 85%;
  left: 6% !important;
}

.amap-info .center > div {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  line-height: 1.4;
  overflow: auto;
  box-shadow: 0 3px 14px rgba(0, 0, 100, 0.6);
  text-align: left;
  font-size: 0.7rem;
  min-width: 12.8rem;
  position: relative;
  border-radius: 5px;
}

.amap-info .center .content_box {
  margin-top: 0.4rem;
}

.amap-info .center .content_box .contentimg {
  float: left;
  width: 3rem;
}

.amap-info .center .content_box .contentinfo {
  overflow: hidden;
  padding: 0 0.2rem;
  max-height: 3rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.amap-info .center .content_box .contentinfo p {
  width: 100%;
  display: block;
}
.amap-info .center .title_ {
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #9cd678;
  box-sizing: border-box;
  font-size: 0.9rem;
}
.amap-info .center .titleD {
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #f5f5f5;
  box-sizing: border-box;
  font-size: 0.9rem;
}

.amap-info .center .closeX {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: #c3c3c3;
  text-decoration: none;
  font: 700 16px/14px Tahoma, Verdana, sans-serif;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.amap-info .center .sharp {
  height: 23px;
  margin: 0 auto;
  overflow: hidden;
  top: -1px;
  width: 30px;
  border-radius: 0;
  position: relative;
  display: block;
}

.amap-info .center .bottom_box {
  display: flex;
}

.amap-info .center .btmtip {
  margin-right: 5px;
  min-width: 3rem;
  max-width: 7rem;
  text-align: center;
  height: 1.4rem;
  line-height: 1.4rem;
  box-sizing: border-box;
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amap-info .center .btmtip i {
  display: inline-block;
  margin-right: 0.2rem;
  vertical-align: middle;
}

.amap-info .center .btmtip.erji {
  background-color: #cdcdcd;
}

.amap-info .center .btmtip.erji.active {
  background-image: linear-gradient(3600deg, #ff9a00, #ffc372);
}
.amap-info .center .btmtip.green_ {
  background-color: #9cd678;
}
.amap-info .center .btmtip.jingdian {
  background-image: linear-gradient(3600deg, #439dff, #95b5ff);
}

.amap-info .center .btmtip.jingdian_gray {
  background-image: linear-gradient(3600deg, #d3d3d3, #d3d3d3);
}

.amap-info .center .btmtip.luxian {
  background-image: linear-gradient(3600deg, #ec1447, #ff6767);
}
.amap-info .center .btmtip.yuding {
  background-image: linear-gradient(3600deg, #ff9d42, #ffbf83);
}

.mint-indicator {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.ParkPhoto.map.active {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ParkPhoto .amap-container img {
  height: 100%;
  border-radius: 50%;
}

.amap-marker-content > div {
  width: 1.4rem;
  height: 2rem;
  line-height: 1.4rem;
  text-align: center;
  background: url(../../assets/img/landmark.png) 0 0 no-repeat;
  background-size: contain;
}

.amap-marker-content > div.no_bg {
  background: none;
}

.amap-marker-content > div.no_bg img {
  height: 1.7rem;
  border-radius: 50%;
}

.amap-marker-content > div.img {
  width: 2.4rem;
  height: 2.4rem;
  padding: 0.3rem;
  /*background: #fff;*/
  /*border: 1px solid #ccc;*/
  position: relative;
}

.amap-marker-content > div.img.sola_picture {
  background: url(../../assets/img/sola_picture.png) 0 0 no-repeat;
  background-size: contain;
}

.amap-marker-content > div.img.more_picture {
  background: url(../../assets/img/more_picture.png) 0 0 no-repeat;
  background-size: contain;
  padding: 0.5rem;
}

.amap-marker-content > div.img img {
  border-radius: 0;
  width: 100%;
  height: 90%;
}

.amap-marker-content > div.img i {
  position: absolute;
  bottom: 9px;
  z-index: 2;
  right: 10px;
  line-height: 0.7rem;
  background: #ff9a80;
  width: 0.7rem;
  height: 0.7rem;
  color: #fff;
  font-style: inherit;
  border-radius: 50%;
  font-size: 0.5rem;
}

.HuangShiTravelIndex .amap-icon img {
  width: 100%;
  max-height: 2rem !important;
  border-radius: 50%;
}

.HuangShiTravelIndex .amap-marker-label {
  border: 1px solid #ff9a80;
  background-color: #fff;
  border-radius: 0.4rem;
}

.template-line-info >>> .ParkMapLineList-content h3 {
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
  color: #333;
}

.template-line-info >>> .ParkMapLineList-content h3 img {
  display: inline-block;
  height: 1.2rem;
  vertical-align: bottom;
  margin-right: 0.6rem;
}

.ParkPhoto.map .tab_label {
  /*background-color: rgba(255, 154, 128, 0.6);*/
  width: 3rem;
  position: absolute;
  bottom: 2rem;
  right: 0.5rem;
  padding: 0.3rem;
  box-sizing: border-box;
  border-radius: 4px;
}

.ParkPhoto.map .tab_label > div,
.ParkPhoto.map .tab_label ul {
  width: 3rem;
}

.ParkPhoto.map .tab_label ul li {
  margin-top: 0.2rem;
  cursor: pointer;
}

.ParkPhoto.map .tab_label img {
  width: 100%;
  display: inline-block;
}

.template-line-info >>> .LineList_info_list li .user_info > div {
  overflow-x: auto;
  overflow-y: hidden;
  height: 1.5rem;
  white-space: nowrap;
}

.template-line-info >>> .LineList_info_list .label_name {
  font-size: 0.9rem;
  padding: 0.4rem;
  font-weight: 700;
  color: #333;
}

.LineInfoList_list_box .label_name span {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
}

.left_but {
  position: absolute;
  left: 0.5rem;
  bottom: 4.6rem;
}

.left_but .mak_tab {
  background-color: #fff;
  width: 2rem;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px #ccc;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
}

.left_but .mak_tab:last-child {
  margin-bottom: 0;
}

.left_but .mak_tab img {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
}

.left_but .mak_tab img:nth-of-type(1) {
  border-bottom: 1px solid #ccc;
}

.left_but .mak_tab img:last-child,
.left_but .mak_tab.map_my_location img {
  border: none;
}

.ParkPhoto.map .button_list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  max-width: 55rem;
}

.ParkPhoto.map .button_list .click_list {
  display: flex;
}

.ParkPhoto.map .button_list .click_list li {
  flex: 1;
  text-align: center;
  font-size: 0.7rem;
  height: 2rem;
  line-height: 2rem;
  box-shadow: -0.3rem -0.1rem 0.3rem rgb(234, 234, 234) inset;
  cursor: pointer;
}

.ParkPhoto.map .button_list .click_list li.active {
  background-color: #cdcdcd;
  box-shadow: 0rem 0.2rem 0.3rem #a9a9a9 inset;
}

.ParkPhoto.map .button_list .click_list li i {
  color: #ff9138;
  margin-right: 0.2rem;
}

.ParkPhoto.map .button_list .click_list li img {
  height: 0.8rem;
  vertical-align: text-top;
  margin-right: 0.2rem;
}
.ParkPhoto.map .button_list .click_list_ {
  display: flex;
}

.ParkPhoto.map .button_list .click_list_ li {
  flex: 1;
  text-align: center;
  font-size: 0.7rem;
  height: 2.5rem;

  /*box-shadow: -0.3rem -0.1rem 0.3rem rgb(234, 234, 234) inset;*/
  border-right: 1px solid #dedede;
  cursor: pointer;
  padding-top: 0.2rem;
}

.ParkPhoto.map .button_list .click_list_ li.active {
  background-color: #cdcdcd;
  box-shadow: 0rem 0.2rem 0.3rem #a9a9a9 inset;
}

.ParkPhoto.map .button_list .click_list_ li i {
  color: #ff9138;
  margin-right: 0.2rem;
}

.ParkPhoto.map .button_list .click_list_ li img {
  height: 1.5rem;
  vertical-align: text-top;
  margin-right: 0.2rem;
}
.ParkPhoto.map .button_list .content_list {
  position: absolute;
  bottom: 2rem;
  width: 100%;
  left: 0;
  background-color: #fff;
  font-size: 0.7rem;
}

@media screen and (min-width: 1201px) {
  .ParkPhoto.map .button_list .content_list {
    width: 33.33%;
  }

  .ParkPhoto.map .button_list .content_list.root_book {
    margin-left: 33.33%;
  }
}

.ParkPhoto.map .button_list .content_list .park_search p {
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
}

.ParkPhoto.map .button_list .content_list .park_search > img {
  height: 1.6rem;
  position: absolute;
  right: 1rem;
  top: 0.4rem;
}

.park_search .input_search {
  background: url(../../assets/img/search-bg.png) 0.8rem 0.8rem no-repeat;
  background-size: 1.2rem;
  display: flex;
  padding: 0.4rem 0.4rem 1rem;
  box-sizing: border-box;
}

.park_search .input_search input {
  height: 2rem;
  line-height: 2rem;
  flex: 4;
  border: 1px solid #ff9a80;
  border-radius: 0.4rem 0 0 0.4rem;
  padding-left: 2rem;
  font-size: 0.7rem;
}

.park_search .input_search button {
  flex: 1;
  background-color: #ff9a80;
  color: #fff;
  border: none;
  font-size: 0.7rem;
  border-radius: 0 0.4rem 0.4rem 0;
}

.ParkPhoto.map .button_list .content_list .park_search ul {
  border-top: 0.1rem solid #ccc;
  max-height: 15rem;
  min-height: 6rem;
  overflow-y: scroll;
  padding: 0 0.6rem;
  box-sizing: border-box;
}

.ParkPhoto.map .button_list .content_list .park_search ul li {
  line-height: 1.5rem;
  padding: 0.2rem;
  border-bottom: 0.01rem solid #ccc;
}

.ParkPhoto.map .button_list .content_list .park_search ul li:hover {
  background-color: #ccc;
}

.ParkPhoto.map .button_list .content_list .park_search ul li:last-child {
  border: none;
}

/* 20200503 */
.track-item-tag img {
  max-width: 100%;
}
.ParkPhotoListDetail .note-track-card {
  text-align: left;
}

.ParkPhotoListDetail .index-banner .park_link,
.ParkPhotoListDetail .more_park_photo a {
  width: 50%;
  display: inline-block;
  color: #fff;
  background: #ff9a80;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}

.ParkPhotoListDetail .more_park_photo {
  text-align: center;
  margin: 0 0 0.5rem;
  font-size: 0.7rem;
}

.ParkPhotoListDetail .content {
  margin: 1rem 0 1rem;
  text-align: center;
  font-size: 0.8rem;
}

.ParkPhotoListDetail .u-link img {
  width: 4rem;
  display: inline-block;
  border-radius: 50%;
}

.ParkPhotoListDetail .u-info {
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 0.8rem 0;
  border-bottom: 5px solid #ccc;
}

.ParkPhotoListDetail .content pre {
  font-size: 0.9rem;
  color: #fff;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0.5rem 0;
}

.ParkPhotoListDetail .u-info .table-box {
  width: 65%;
  display: inline-block;
  background: #f59f32;
  border-bottom: 0.2rem solid #936425;
  border-radius: 0.3rem;
  padding: 0 0.2rem;
}

.ParkPhotoListDetail .u-info .table-box li {
  float: left;
  width: 33.33%;
  height: 2.4rem;
  /* line-height: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ParkPhotoListDetail .u-info .table-box li span:nth-child(1) {
  color: #eee;
}

.ParkPhotoListDetail .u-info .vote-btn {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  /* line-height: 2.4rem; */
  background: #e03347;
  border-radius: 0.3rem;
  border-bottom: 0.2rem solid #892c37;
  padding: 0 0.2rem;
  margin-left: 1px;
}

.ParkPhotoListDetail .u-info .vote-btn.no {
  background-color: #ccc;
  border-bottom: 0.2rem solid #999;
}

.ParkPhotoListDetail .content .img_tit_box {
  padding: 0 10px;
  text-align: left;
}

.ParkPhotoListDetail .content .pic-list img {
  width: 100%;
  display: block;
  margin: 0.2rem 0;
}

.ParkPhotoListDetail .who_vote {
  font-size: 0;
}

.ParkPhotoListDetail .who_vote h1 {
  font-size: 0.9rem;
  color: #fff;
}

.ParkPhotoListDetail .who_vote .row {
  margin-top: 0.1rem;
  height: 1.2rem;
  /* overflow: hidden; */
}

.ParkPhotoListDetail .who_vote .time {
  font-size: 0.7rem;
  height: 1.2rem;
  line-height: 1.2rem;
  color: #fff;
  float: left;
  margin-right: 0.2rem;
}

.ParkPhotoListDetail .who_vote .list {
  height: 1.2rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.ParkPhotoListDetail .who_vote img {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
  border-radius: 50%;
}

.notes-box {
  padding: 0 0.5rem;
  line-height: 1.5rem;
  font-size: 0.7rem;
}
.notes-box .box {
  padding: 0 0 1.15rem 0;
  border-bottom: 1px solid #f2f2f2;
}
.box img {
  border: 0;
  max-width: 100%;
  margin: 0 auto;
  height: auto;
}

.template-line-info >>> .LineList_list {
  padding-top: 0.5rem;
}

.template-line-info >>> .LineList_list li {
  color: #666;
  text-align: center;
}

.template-line-info >>> .LineList_list li.jingdian,
.template-line-info >>> .LineList_list li.zhuzhi,
.template-line-info >>> .LineList_list li.gouwuche-copy,
.template-line-info >>> .LineList_list li.chifan {
  background: none;
}

.template-line-info >>> .LineList_list li img {
  width: 2rem;
  display: inline-block;
}

.template-line-info >>> .LineList_list li p {
  margin: 0.2rem 0;
}
.ParkPhotoListDetail .who_vote {
  font-size: 0;
}
.ParkPhotoListDetail .who_vote h1 {
  font-size: 0.9rem;
  color: #fff;
}
.ParkPhotoListDetail .who_vote .row {
  margin-top: 0.1rem;
  height: 1.2rem;
  overflow: hidden;
}
.ParkPhotoListDetail .who_vote .row {
  text-align: left;
  padding: 0 0.3rem;
}
.ParkPhotoListDetail .who_vote .time {
  font-size: 0.7rem;
  height: 1.2rem;
  line-height: 1.2rem;
  color: #fff;
  float: left;
  margin-right: 0.2rem;
}
.ParkPhoto.ParkPhotoListDetail .who_vote .time {
  color: #333;
}
.ParkPhotoListDetail .who_vote .list {
  height: 1.2rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.ParkPhotoListDetail .who_vote img {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
  border-radius: 50%;
}

.ParkPhoto .active_user_box,
.ParkPhoto .active_user_box .who_vote .time {
  font-weight: 700;
  color: #000;
  padding: 0.4rem;
}

.ParkPhoto .active_user_box.ParkPhotoListDetail .who_vote .row {
  height: 3.2rem;
}

.ParkPhoto.ParkPhotoListDetail .who_vote .time {
  color: #333;
}

.ParkPhotoListDetail .who_vote .row {
  text-align: left;
  padding: 0 0.3rem;
}

.ParkPhoto .active_user_box.ParkPhotoListDetail .who_vote .list {
  height: 4rem;
}

.ParkPhoto .active_user_box.ParkPhotoListDetail .who_vote img {
  margin: 0 auto;
  width: 2.2rem;
  height: 2.2rem;
}

.ParkPhoto .active_user_box.ParkPhotoListDetail .who_vote .list .num_box {
  display: inline-block;
  text-align: center;
  position: relative;
  margin-right: 0.2rem;
}

.ParkPhoto
  .active_user_box.ParkPhotoListDetail
  .who_vote
  .list
  .num_box
  > span {
  position: absolute;
  top: 1.3rem;
  left: 0.3rem;
  font-size: 0.5rem;
  font-weight: 100;
  color: #fff;
  padding: 0.05rem 0.1rem;
  display: inline-block;
}

.ParkPhoto
  .active_user_box.ParkPhotoListDetail
  .who_vote
  .list
  .num_box:nth-of-type(1)
  > span {
  background-color: red;
}

.ParkPhoto
  .active_user_box.ParkPhotoListDetail
  .who_vote
  .list
  .num_box:nth-of-type(2)
  > span {
  background-color: #ff5023;
}

.ParkPhoto
  .active_user_box.ParkPhotoListDetail
  .who_vote
  .list
  .num_box:nth-of-type(3)
  > span {
  background-color: #ffa717;
}

.ParkPhoto .active_user_box.ParkPhotoListDetail .who_vote .list .num {
  font-size: 0.6rem;
  color: #333;
  font-weight: 100;
  height: 1rem;
  line-height: 1rem;
}

.ParkPhoto .active_user_box.ParkPhotoListDetail .who_vote .list .num i {
  font-size: 0.6rem;
  margin-right: 2px;
  transform: rotateY(180deg);
  display: inline-block;
}

.PhotoList {
  font-size: 0.7rem;
}

.PhotoList .park-list {
  padding: 2%;
  overflow: hidden;
}

.border {
  border-top: 0.1rem solid #cccccc;
  border-bottom: 0.1rem solid #cccccc;
  padding-bottom: 1rem;
  padding-top: 0.7rem;
}

.PhotoList .park-list h3 {
  font-size: 0.8rem;
  margin-left: 10px;
}

.PhotoList .more {
  line-height: 1.4rem;
  height: 1.4rem;
  text-align: center;
  color: #ccc;
}

.PhotoList .more i {
  transform: rotate(90deg);
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.PhotoList .park-list .item {
  width: 46%;
  height: 8rem;
  margin: 0.4rem 2% 0;
  float: left;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  border: 1px solid #ff9a80;
  box-sizing: border-box;
}

.park-list .item a {
  display: block;
  color: #fff;
  width: 100%;
  height: 6.5rem;
  position: relative;
}

.park-list .item .count {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.park-list .item .count span {
  background: rgba(0, 0, 0, 0.4);
  padding: 0.2rem 0.3rem;
}

.park-list .item .count i {
  transform: rotateY(180deg);
  display: inline-block;
  margin-right: 4px;
}

.park-list .item .name {
  padding: 0 0.3rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  color: #333;
}

.park-list .item .name span {
  font-family: serif;
  font-weight: 700;
  font-size: 0.4rem;
  color: #ff9a80;
  margin-left: 0.1rem;
}

.park-list .item .pic {
  width: 100%;
  height: 100%;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.PhotoParkList .page-tips {
  width: 100%;
  text-align: center;
  padding: 0.8rem 0;
}
/*  */

.photo-list {
  padding: 2%;
  display: inline-block;
  width: 96%;
}
.photo-list .col {
  width: 46%;
  overflow: hidden;
  margin: 0.5rem 2% 0;
  float: left;
}
.photo-list .item {
  vertical-align: top;
  width: 100%;
  position: relative;
  color: #fff;
  border-radius: 4px;
  float: left;
  margin-bottom: 1.2rem;
  border: 1px solid #ff9a80;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 0.6rem;
}
.photo-list .item .num {
  position: absolute;
  width: 100%;
}
.photo-list .item .num span {
  background: #ff9a80;
  /* background: #e03347; */
  float: right;
  padding: 0.2rem 0.4rem;
  border-radius: 0 3px 0 4px;
  font-size: 0.6rem;
}
a {
  color: #000;
  text-decoration: none;
}

.photo-list .item .pic {
  width: 100%;
  display: block;
  border-radius: 4px 4px 0 0;
}

.photo-list .item .img_num {
  position: absolute;
  bottom: 3.8rem;
  width: 100%;
  left: 0;
  font-size: 0.7rem;
}
.photo-list .item .img_num span {
  background: rgba(0, 0, 0, 0.4);
  padding: 0.2rem 0.4rem;
  display: inline-block;
}
.photo-list .item .u-card.no_park {
  text-align: center;
}
.photo-list .item .u-card {
  width: 100%;
  position: relative;
  color: #666;
  height: 3.2rem;
}
.photo-list .item .u-card h1 {
  padding: 0.4rem 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.7rem;
  color: #333;
  font-weight: 700;
}
.photo-list .item .u-card h2 {
  font-size: 0.6rem;
}

.photo-list .item .u-card a {
  float: left;
  margin-left: 1rem;
}
.photo-list .item .u-card img.user_icon {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin: 0.4rem 0.2rem;
  border: 1px solid #ff9a80;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.photo-list .item .u-card img.navigation_icon {
  display: none;
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  top: -1.6rem;
  right: 0.4rem;
}
.photo-list .item .vote.no {
  background: #ccc;
}
.photo-list .item .vote {
  width: 70%;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  position: absolute;
  bottom: -0.8rem;
  left: 15%;
  border-radius: 0.8rem;
  background-color: #ff9a80;
  font-size: 0.7rem;
}

.ParkPhoto .photo_publish {
  position: fixed;
  width: 3rem;
  height: 3rem;
  bottom: 2rem;
  left: calc(50% - 1.5rem);
  margin: 0;
}
.ParkPhoto .photo_publish img {
  width: 100%;
}
/* 人数统计 */
.stats {
  margin-top: 0.5rem;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stats .stats_item {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: orange; */
  /* color: #fff; */
}
.stats .stats_item .stats_item_name {
  font-size: 0.75rem;
  color: #333;
}
.stats .stats_item .stats_item_number {
  font-size: 0.8rem;
  font-weight: 600;
  color: #666;
  /* color: #eee; */
}

/*  */

.publish-enter,
.publish-leave-to {
  opacity: 0;
}
.publish-enter-to,
.publish-leave {
  opacity: 1;
}
.publish-enter-active,
.publish-leave-active {
  transition: ease 0.3s;
}

.click-load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.click-load-more .load-more-line {
  width: 40%;
  height: 1px;
  background-color: #eee;
}
.click-load-more .load-more-text {
  padding: 0 4px;
  font-size: 0.68rem;
  color: #999;
  transform: translateY(-50%);
  background-color: #fff;
}

.LineList {
  min-height: 100%;
  background-color: #fff;
}

.LineList .page-content {
  padding: 2rem 0 0;
}

.template-line-info >>> .LineList_list {
  padding: 0.4rem;
}

.template-line-info >>> .ParkMapLineList-content .more {
  line-height: 1.4rem;
  height: 1.4rem;
  text-align: center;
  color: #ccc;
  font-size: 0.7rem;
}

.template-line-info >>> .ParkMapLineList-content .more i {
  transform: rotate(90deg);
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.template-line-info >>> .LineList_list li {
  float: left;
  width: 22%;
  margin: 0 1.5%;
  position: relative;
  color: #fff;
  padding: 8px 0;
  text-align: center;
  border-radius: 4px;
  font-size: 0.7rem;
}

.template-line-info >>> .LineList_list li.jingdian {
  background-color: #90b4fe;
}

.template-line-info >>> .LineList_list li.chifan {
  background-color: #ade777;
}

.template-line-info >>> .LineList_list li.zhuzhi {
  background-color: #ff633d;
}

.template-line-info >>> .LineList_list li.gouwuche-copy {
  background-color: #f6cc2a;
}

.template-line-info >>> .LineList_list li span {
  margin-right: 5px;
  font-size: 100%;
}

.template-line-info >>> .LineList_list li i {
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 2px;
}

.template-line-info >>> .LineList_list li.jingdian i {
  color: #2477d3;
}

.template-line-info >>> .LineList_list li.chifan i {
  color: #1cab1b;
}

.template-line-info >>> .LineList_list li.zhuzhi i {
  color: #cc1b15;
}

.template-line-info >>> .LineList_list li.gouwuche-copy i {
  color: #ba9101;
}

.template-line-info >>> .LineList_info_list li {
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  overflow: hidden;
}

.template-line-info >>> .LineList_info_list li .img_box {
  height: 8rem;
  position: relative;
}

.template-line-info >>> .LineList_info_list li .img_box > img {
  height: 100%;
  width: 100%;
}

.template-line-info >>> .LineList_info_list li .img_info {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.template-line-info >>> .LineList_info_list li .img_info p {
  width: 100%;
  text-align: center;
  padding: 0.2rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.4);
}

.template-line-info >>> .LineList_info_list li .user_info {
  padding: 0.3rem 10px;
}

.template-line-info >>> .LineList_info_list li .user_info img {
  height: 1.4rem;
  border-radius: 50%;
  margin-right: 0.4rem;
  display: inline-block;
}

.template-line-info >>> .LineList_info_list li .user_info span {
  overflow: hidden;
  height: 1.4rem;
  line-height: 1.4rem;
  margin-left: 10px;
}
.LineList {
  min-height: 100%;
  background-color: #fff;
}

.LineList .page-content {
  padding: 2rem 0 0;
}

.template-line-info >>> .LineList_list {
  padding: 0.4rem;
}

.template-line-info >>> .ParkMapLineList-content .more {
  line-height: 1.4rem;
  height: 1.4rem;
  text-align: center;
  color: #ccc;
  font-size: 0.7rem;
}

.template-line-info >>> .ParkMapLineList-content .more i {
  transform: rotate(90deg);
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.ParkPhoto .container_box.active {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.ParkPhoto.map .container_box .map_info {
  width: 100%;
  position: inherit;
  background: none;
  padding: 0.5rem;
}

.ParkPhoto.map >>> .amap-marker-label {
  position: absolute;
  z-index: 2;
  border: 1px solid rgb(0, 162, 255);
  background-color: rgba(255, 255, 255, 0.4);
  white-space: nowrap;
  cursor: default;
  padding: 3px;
  font-size: 12px;
  line-height: 14px;
}

.hide {
  display: none;
}

.ParkPhoto .amap-touch-toolbar .amap-zoomcontrol {
  width: 2rem;
}

.amap-zoom-touch-minus,
.amap-zoom-touch-plus {
  height: 2rem;
}

.amap-zoom-touch-minus > div,
.amap-zoom-touch-plus > div {
  line-height: 2rem;
}

.amap-touch-toolbar .amap-zoomcontrol {
  left: 10px;
  bottom: 10px;
}

.amap-toolbar {
  bottom: 0 !important;
}

.ParkMapLineList-content {
  padding: 0.4rem 10px 0;
}

.left_but {
  position: fixed;
  left: 0.5rem;
  bottom: 4.6rem;
}
.left_but .mak_tab {
  background-color: #fff;
  width: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 2px 2px 5px #ccc;
  box-shadow: 2px 2px 5px #ccc;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
}

.left_but .mak_tab img {
  width: 100%;
  padding: 0.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.left_but .mak_tab:last-child {
  margin-bottom: 0;
}
.left_but .mak_tab.map_my_location img,
.left_but .mak_tab img:last-child {
  border: none;
}

.dm-contain >>> .amap-marker-content > div {
  width: 1.4rem;
  height: 2rem;
  line-height: 1.4rem;
  text-align: center;
  background: url(../../assets/img/landmark.png) 0 0 no-repeat;
  background-size: contain;
}

#container >>> .amap-maps .amap-marker-label {
  border: 1px solid #999;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
}

#container >>> .amap-info > div {
  width: 85%;
  left: 6% !important;
}
#container >>> .amap-info .center > div {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  line-height: 1.4;
  overflow: auto;
  box-shadow: 0 3px 14px rgba(0, 0, 100, 0.6);
  text-align: left;
  font-size: 0.7rem;
  min-width: 12.8rem;
  position: relative;
  border-radius: 5px;
}
#container >>> .amap-info .center .titleD {
  border-bottom: 1px solid #f5f5f5;
}
#container >>> .amap-info .center .closeX {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: #c3c3c3;
  text-decoration: none;
  font: 700 16px/14px Tahoma, Verdana, sans-serif;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

#container >>> .amap-info .center .content_box {
  margin-top: 0.4rem;
}

#container >>> .amap-info .center .content_box .contentimg {
  float: left;
  width: 3rem;
}

#container >>> .amap-info .center .content_box .contentinfo {
  overflow: hidden;
  padding: 0 0.2rem;
  max-height: 3rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#container >>> .amap-info .center .bottom_box,
#container >>> .amap-info .center .btmtip {
  display: flex;
}
#container >>> .amap-info .center .btmtip.jingdian {
  background-image: linear-gradient(10turn, #439dff, #95b5ff);
}
#container >>> .amap-info .center .btmtip {
  margin-right: 5px;
  min-width: 3rem;
  max-width: 7rem;
  text-align: center;
  height: 1.4rem;
  line-height: 1.4rem;
  box-sizing: border-box;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
}
#container >>> .amap-info .center .btmtip.luxian {
  background-image: linear-gradient(10turn, #ec1447, #ff6767);
}
#container >>> .amap-info .center .btmtip.yuding {
  background-image: linear-gradient(10turn, #ff9d42, #ffbf83);
}
#container >>> .amap-info .center .sharp {
  height: 23px;
  margin: 0 auto;
  overflow: hidden;
  top: -1px;
  width: 30px;
  border-radius: 0;
  position: relative;
  display: block;
}
#container >>> .amap-info .center .title_,
#container >>> .amap-info .center .titleD {
  padding-bottom: 0.4rem;
  box-sizing: border-box;
  font-size: 0.9rem;
}
#container >>> .amap-info .center .titleD {
  border-bottom: 1px solid #f5f5f5;
}
#container >>> .center .btmtip {
  font-size: 0.6rem;
  padding: 2px 5px;
  background-color: #ff9a80;
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  border-radius: 2px;
}

#container >>> .amap-info > div {
  width: 85%;
  left: 6% !important;
}
#container >>> .amap-info .center > div {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  line-height: 1.4;
  overflow: auto;
  box-shadow: 0 3px 14px rgba(0, 0, 100, 0.6);
  text-align: left;
  font-size: 0.7rem;
  min-width: 12.8rem;
  position: relative;
  border-radius: 5px;
}
#container >>> .amap-info .center .titleD {
  border-bottom: 1px solid #f5f5f5;
}
#container >>> .amap-info .center .closeX {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: #c3c3c3;
  text-decoration: none;
  font: 700 16px/14px Tahoma, Verdana, sans-serif;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

#container >>> .amap-info .center .content_box {
  margin-top: 0.4rem;
}
#container >>> .amap-info .center .bottom_box,
#container >>> .amap-info .center .btmtip {
  display: flex;
}
#container >>> .amap-info .center .btmtip.jingdian {
  background-image: linear-gradient(10turn, #439dff, #95b5ff);
}
#container >>> .amap-info .center .btmtip {
  margin-right: 5px;
  min-width: 3rem;
  max-width: 7rem;
  text-align: center;
  height: 1.4rem;
  line-height: 1.4rem;
  box-sizing: border-box;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
}
#container >>> .amap-info .center .btmtip.luxian {
  background-image: linear-gradient(10turn, #ec1447, #ff6767);
}
#container >>> .amap-info .center .btmtip.yuding {
  background-image: linear-gradient(10turn, #ff9d42, #ffbf83);
}
#container >>> .amap-info .center .sharp {
  height: 23px;
  margin: 0 auto;
  overflow: hidden;
  top: -1px;
  width: 30px;
  border-radius: 0;
  position: relative;
  display: block;
}
#container >>> .amap-info .center .title_,
#container >>> .amap-info .center .titleD {
  padding-bottom: 0.4rem;
  box-sizing: border-box;
  font-size: 0.9rem;
}
#container >>> .amap-info .center .titleD {
  border-bottom: 1px solid #f5f5f5;
}
#container >>> .center .btmtip {
  font-size: 0.6rem;
  padding: 2px 5px;
  background-color: #ff9a80;
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  border-radius: 2px;
}
</style>
