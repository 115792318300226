<template>
  <div class="body" v-if="is_weixin">
    <!--顶部数据-->
    <div class="head">
      <div class="tip">
        <span>{{vote_time}}</span>
      </div>
      <div class="banner">
        <img :src="info.banner_img_app" />
      </div>
      <!--搜索-->
      <div class="data-search">
        <div class="data">
          <div class="data-item">
            <div class="number_">
              <span>{{join_user_numbers}}</span>
            </div>
            <div class="text_">
              <span>参展作品数</span>
            </div>
          </div>
          <div class="data-item">
            <div class="number_">
              <span>{{vote_numbers}}</span>
            </div>
            <div class="text_">
              <span>累计投票</span>
            </div>
          </div>
          <div class="data-item">
            <div class="number_">
              <span>{{vote_user_numbers}}</span>
            </div>
            <div class="text_">
              <span>参与人数</span>
            </div>
          </div>
        </div>
        <div class="data">
          <div class="search">
            <input type="text" :placeholder="key_word_info" v-model="key_word" />
          </div>
          <div class="search-btn" @click="search">
            <i class="iconfont iconsearch"></i>
          </div>
        </div>
      </div>
    </div>
    <!--作品数据-->
    <div class="list">
      <div class="item" :class="i % 2 == 1 ? 'margin_left' : ''" v-for="(k,i) in list" :key="i">
        <div class="left_up">
          <img src="../../assets/img/left_up.png" />
        </div>
        <div class="left_bottom">
          <img src="../../assets/img/left_bottom.png" />
        </div>
        <div class="right_up">
          <img src="../../assets/img/right_up.png" />
        </div>
        <div class="right_bottom">
          <img src="../../assets/img/right_bottom.png" />
        </div>
        <div class="i-item">
          <div class="num">
            <span>{{k.vote_numbers}}</span>
          </div>
          <div class="i-img">
            <div class="play">
              <router-link :to="{path:'/parkPhoto/VideoDetail',query:{id:k.id,aid:aid}}">
                <img src="../../assets/img/play.png" />
              </router-link>
            </div>
            <router-link :to="{path:'/parkPhoto/VideoDetail',query:{id:k.id,aid:aid}}">
              <img :src="k.baseUrl" />
            </router-link>
          </div>
          <div class="i-bottom">
            <div class="select_" @click="select_(i,k.id)" v-if="k.is_can_modify == 1 ">
              <img src="../../assets/img/select.png" v-if="k.is_select" />
              <span v-else></span>
            </div>
            <div class="select_" v-else>
              <img src="../../assets/img/select.png" v-if="k.is_select" />
              <span v-else></span>
            </div>
            <div class="num_">{{k.user_fk_id}} {{k.title}}</div>
          </div>

          <div class="i-title">
            <span>{{k.user_name}}</span>
          </div>
        </div>
      </div>
    </div>
  
    <!--弹框-->
    <div class="img-verify" v-if="is_show_verify">
      <div class="verify-body">
        <img class="img_vcode" :src="verify_img" @click="refresh" />
      </div>
      <div class="verify-input">
        <input type="number" v-model="vCode" placeholder="请输入图片数字(无图片-可点击上面白色区域)" />
      </div>
      <div class="verify-footer">
        <div class="verify-cancel">
          <span @click="video_vote_">取消</span>
        </div>
        <div class="verify-submit">
          <span @click="video_vote">投票</span>
        </div>
      </div>
    </div>
    <!--投票按钮-->
    <div class="vote">
      <div class="vote-up">
        <div class="method_">
          <div class="all_" @click="select_all" v-if="0">
            <span>全选</span>
          </div>
          <div class="vote_xing">
            <img src="../../assets/img/vote_xing.png" />
          </div>
          <div class="vote_fun" v-if="is_show == 0">
            <span>投票未开始</span>
          </div>
          <div class="vote_fun" @click="video_vote_" v-else-if="is_show == 1 && is_vote == 0">
            <span>{{title_}}</span>
          </div>
          <div class="vote_fun" v-else-if="is_show == 1 && is_vote == 1">
            <span>已投票</span>
          </div>
          <div class="vote_fun" v-else-if="is_show == 3">
            <span>投票已结束</span>
          </div>
        </div>
      </div>
      <div class="vote-bottom">
        <div class="vote-index">
          <router-link class="link_" :to="{path:'/parkPhoto/VideoVote',query:{aid:aid}}">
            <div class="index-img">
              <img src="../../assets/img/index-img.png" />
            </div>
            <div class="index-text">
              <span>首页</span>
            </div>
          </router-link>
        </div>
        <div class="vote-index">
          <router-link class="link_" :to="{path:'/parkPhoto/VideoRank',query:{aid:aid}}">
            <div class="index-img">
              <img src="../../assets/img/rank_.png" />
            </div>
            <div class="index-text">
              <span>排行榜</span>
            </div>
          </router-link>
        </div>
        <div class="vote-index">
          <router-link class="link_" :to="{path:'/parkPhoto/VideoRule',query:{aid:aid}}">
            <div class="index-img">
              <img src="../../assets/img/rule.png" />
            </div>
            <div class="index-text">
              <span>规则说明</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="no-body">
    <div class="is-no-weixin">
      <span>请在微信中打开</span>
    </div>
  </div>
</template>
<style scoped>
.body {
  display: inline-block;
  position: relative;
  width: 98%;
  margin-left: 1%;
  margin-top: 1%;
  background-color: #ecc48d;
}
.body_bottom {
  bottom: 3rem;
}
.body .head {
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: #cc0c09;
}
.body .head .tip {
  display: inline-block;
  position: absolute;
  margin-top: 56%;
  z-index: 10;
  margin-left: 25%;
}
.body .head .tip span {
  display: block;
  padding: 3px;
  color: white;
  font-size: 0.6rem;
  font-weight: bold;
}
.body .head .banner {
  display: inline-block;
  position: relative;
  width: 100%;
}
.body .head .banner img {
  width: 100%;
  height: 240px;
}

.body .head .data-search {
  display: inline-block;
  position: relative;
  width: 92%;
  margin-left: 4%;
  background-color: #db3e3a;
  margin-bottom: 4%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-top: 2%;
}
.body .head .data-search .data {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 2%;
}
.body .head .data-search .data .data-item {
  position: relative;
  width: 33%;
  float: left;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.body .head .data-search .data .data-item .number_ {
  display: inline-block;
  position: relative;
  width: 100%;
}
.body .head .data-search .data .data-item .number_ span {
  display: block;
  padding: 4px;
  text-align: center;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}
.body .head .data-search .data .data-item .text_ {
  display: inline-block;
  position: relative;
  width: 100%;
}
.body .head .data-search .data .data-item .text_ span {
  display: block;
  padding: 3px;
  text-align: center;
  font-size: 0.6rem;
  color: white;
}

.body .head .data-search .data .search {
  position: relative;
  width: 80%;
  margin-left: 5%;
  background-color: white;
  margin-bottom: 2%;
  float: left;
}
.body .head .data-search .data .search input {
  display: inline-block;
  height: 30px;
  border: none;
}

.body .head .data-search .data .search-btn {
  position: relative;
  float: left;
  height: 30px;
  width: 10%;
  background-color: white;
}
.body .head .data-search .data .search-btn > .iconfont {
  margin-top: 4px;
  margin-left: 4px;
  font-size: 20px;
  color: #cc0c09;
}

/***********************没有数据********************/
.no-body {
  display: inline-block;
  position: relative;
  background-color: #cc0c09;
  width: 98%;
  margin-left: 1%;
  height: 100%;
}
.no-body .is-no-weixin {
  display: inline-block;
  position: relative;
  background-color: white;
  width: 98%;
  margin-left: 1%;
  margin-top: 55%;
}
.no-body .is-no-weixin span {
  display: block;
  padding: 10px;
  text-align: center;
  font-size: 1.2rem;
}
.body .list {
  display: inline-block;
  position: relative;
  width: 98%;
  margin-left: 1%;
  margin-top: 2%;
  margin-bottom: 5rem;
}
.body .list .margin_left {
  margin-left: 2%;
}
.body .list .item {
  position: relative;
  width: 47%;
  float: left;
  padding: 2px;
  border: 1px solid #dedede;
  margin-top: 2%;

  height: 240px;
}
.body .list .item .left_up {
  display: inline-block;
  position: absolute;

  margin-top: -2%;
  margin-left: -2%;
}
.body .list .item .right_up {
  display: inline-block;
  position: absolute;

  top: -1%;
  right: -1%;
}
.body .list .item .left_bottom {
  display: inline-block;
  position: absolute;

  bottom: -2%;
  left: -1%;
}
.body .list .item .right_bottom {
  display: inline-block;
  position: absolute;

  right: -1%;
  bottom: -2%;
}
.body .list .item .i-item {
  display: inline-block;
  position: relative;
  width: 100%;
}
.body .list .item .num {
  display: inline-block;
  position: absolute;
  z-index: 2;
  margin-top: 6%;
}
.body .list .item .num span {
  display: block;
  padding: 2px;
  font-size: 0.8rem;
  background-color: #db3e3a;
  color: white;
}
.body .list .item .i-item .i-img .play {
  display: inline-block;
  position: absolute;

  margin-top: 35%;
  margin-left: 39%;
}
.body .list .item .i-item .i-img .play img {
  width: 30px;
  height: 30px;
}
.body .list .item .i-item .i-img {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 10%;
}
.body .list .item .i-item .i-img img {
  width: 100%;
  height: 150px;
}
.body .list .item .i-item .i-bottom {
  display: inline-block;
  position: relative;
  width: 98%; /*
        margin-bottom: 6%;*/
  margin-top: 5%;
  background-color: #db3e3a;
  padding: 2px;
  border-radius: 2px;
}
.body .list .item .i-item .i-bottom .select_ {
  position: relative;
  width: 20%;
  float: left;
}
.body .list .item .i-item .i-bottom .select_ span {
  display: block;
  padding: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: white;
  height: 5px;
  width: 5px;
}
.body .list .item .i-item .i-bottom .select_ img {
  width: 25px;
  height: 25px;
}
.body .list .item .i-item .i-bottom .num_ {
  position: relative;
  width: 78%;
  float: left;
  white-space: nowrap;
  overflow-x: scroll;
  font-size: 0.7rem;
  color: white;
  margin-top: 2%;
}

.body .list .item .i-item .i-bottom .num_ span {
  display: block;
  padding: 4px;
  font-size: 0.7rem;
  color: white;
}
.body .list .item .i-item .i-bottom .text_ {
  position: relative;
  width: 45%;
  float: left;
}
.body .list .item .i-item .i-bottom .text_ span {
  display: block;
  padding: 4px;
  font-size: 0.7rem;
  color: white;
  margin-top: 2%;
}
.body .list .item .i-item .i-title {
  display: inline-block;
  position: relative;
  width: 100%;
}
.body .list .item .i-item .i-title span {
  display: block;
  padding: 2px;
  font-size: 0.5rem;
  color: #db3e3a;
  text-align: center;
}
.margin_top_7 {
  margin-top: 20%;
}
.margin_top_16 {
  margin-top: 11%;
}
.margin_bottom {
  margin-bottom: 30%;
}
.body .vote {
  z-index: 3;
  position: fixed;
  bottom: 0rem;
  width: 98%;
  background-color: #edce8e;
}
.body .vote .vote-up {
  display: inline-block;
  position: relative;
  width: 98%;
}
.body .vote .vote-up .method_ {
  display: inline-block;
  position: relative;
  width: 100%;
}
.body .vote .vote-up .method_ .all_ {
  position: relative;
  float: left;
  margin-left: 35%;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 2%;
}
.body .vote .vote-up .method_ .vote_xing {
  position: relative;
  margin-left: 37%;
  float: left;
  z-index: 1;
}
.body .vote .vote-up .method_ .vote_xing img {
  width: 40px;
  height: 40px;
}
.body .vote .vote-up .method_ .all_ span {
  display: block;
  padding: 5px 8px 5px 8px;
  font-size: 0.8rem;
  color: #db3e3a;
  background-color: white;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.body .vote .vote-up .method_ .vote_fun {
  position: relative;
  float: left;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: -3%;
}
.body .vote .vote-up .method_ .vote_fun span {
  display: block;
  padding: 5px 8px 5px 8px;
  font-size: 1rem;
  color: white;
  float: left;
  background-color: #db3e3a;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.body .vote .vote-bottom {
  display: inline-block;
  position: relative;
  width: 98%;
  background-color: #db3e3a;

  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-left: 1%;
}
.body .vote .vote-bottom .vote-index {
  position: relative;
  width: 33%;
  float: left;
  margin-top: 1%;
  margin-bottom: 1%;
}
.body .vote .vote-bottom .vote-index .index-img {
  position: relative;
  width: 35%;
  float: left;
}
.body .vote .vote-bottom .vote-index .index-img img {
  width: 20px;
  height: 20px;
  float: right;
}
.body .vote .vote-bottom .vote-index .index-text {
  position: relative;
  width: 65%;
  float: left;
}
.body .vote .vote-bottom .vote-index .index-text span {
  display: block;
  padding: 4px;
  font-size: 0.7rem;
  text-align: left;
  font-weight: bold;
  color: white;
}

.img-verify {
  position: fixed;
  z-index: 2;
  width: 80%;
  left: 10%;
  top: 45%;
  background-color: white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.img-verify .verify-body {
  display: inline-block;
  position: relative;
  width: 100%;
}

.img-verify .verify-body .img_vcode {
  height: 2.5rem;
  border-radius: 3px;
  width: 80%;
  margin-left: 10%;
  margin-top: 5%;
}
.img-verify .verify-input {
  display: inline-block;
  position: relative;
  width: 100%;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  margin-top: 5%;
}
.img-verify .verify-input input {
  height: 2rem;
  border-top: 1px solid white;
  width: 100%;
}
.img-verify .verify-footer {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 5%;
}
.img-verify .verify-footer .verify-cancel {
  position: relative;
  margin-left: 15%;
  float: left;
  border-bottom-left-radius: 5px;
}
.img-verify .verify-footer .verify-cancel span {
  display: block;
  padding: 10px;
  color: white;
  background-color: #db3e3a;
  font-size: 1rem;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.img-verify .verify-footer .verify-submit {
  position: relative;
  margin-left: 26%;
  float: left;
  border-bottom-right-radius: 5px;
}
.img-verify .verify-footer .verify-submit span {
  display: block;
  padding: 10px;
  color: white;
  background-color: #db3e3a;
  font-size: 1rem;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
</style>
<script>
import { Toast } from "mint-ui";
import { Indicator } from "mint-ui";
export default {
  components: {},
  data() {
    return {
      aid: this.$route.query.aid,
      info: "",
      list: [],
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      vote_numbers: "",
      join_user_numbers: "",
      vote_user_numbers: "",
      vote_time: "",
      key_word_info: "请输入昵称/编号",
      key_word: "",
      sid: "",
      page: 1,
      union_id: "",
      is_show: 0,
      is_vote: 0,
      m_url:
        "https://m.zmlxj.com/#/parkPhoto/VideoVote?aid=" +
        this.$route.query.aid +
        "&type=VideoVote",
      vCode: "",
      is_show_verify: false,
      token: "",
      verify_img: "",
      title_: "投票"
    };
  },
  mounted() {
    this.unload();
    if (this.is_weixin) {
      this.wx_share(this.wx_url);
    }
    //this.weixin_hidden();
  },
  methods: {
    unload() {
      if (this.is_weixin) {
        this.$ajax("post", "index/send_msg_v2", "", ret => {
          //图片 验证码 获取token
          if (ret.data.id == 0) {
            this.token = ret.data.data.token;
            var url =
              "https://www.zmlxj.com/api.php/index/send_msg_v2?token=" +
              ret.data.data.token +
              "&";
            this.verify_img = url;
            //$('img.img_vcode').attr('data-url',url).attr('src',url);
          }
        });
        this.union_id = localStorage.getItem("weixin_user_info"); //没有就从本地缓存中读取
        if (
          this.union_id &&
          !(
            !this.union_id &&
            typeof this.union_id != "undefined" &&
            this.union_id != 0
          )
        ) {
          //本地有数据,不授权
          this.get_server_data(1, this.union_id);
        } else {
          //授权之前清空本地数据
          if (
            !(
              !this.union_id &&
              typeof this.union_id != "undefined" &&
              this.union_id != 0
            )
          ) {
            localStorage.setItem("weixin_user_info", "");
          }

          //本地没有数据重新授权一次
          var open_url =
            "https://www.zmlxj.com/api.php/SpotsRace/weixin_login?aid=" +
            this.$route.query.aid;
          window.location.href = open_url;
          if (this.$route.query.union_id) {
            localStorage.setItem(
              "weixin_user_info",
              this.$route.query.union_id
            );
            this.union_id = this.$route.query.union_id;
            this.$route.query.union_id = "";
          } else {
            this.union_id = "";
          }
          this.get_server_data(1, this.union_id);
        }
      } else {
      }
    },
    get_server_data(page, union_id) {
      this.$ajax(
        "post",
        "SpotsRace/get_video_head_number",
        {
          aid: this.aid,
          page: page,
          key_word: this.key_word,
          union_id: union_id,
          token: "SpotsRace-get_video_head_number"
        },
        ret => {
          if (ret.data.id == 0) {
            this.info = ret.data.data.activity_info;
            this.vote_numbers = ret.data.data.vote_numbers;
            this.join_user_numbers = ret.data.data.join_user_numbers;
            this.vote_user_numbers = ret.data.data.vote_user_numbers;
            this.list = ret.data.data.list;
            this.vote_time = ret.data.data.vote_time;
            this.is_show = ret.data.data.is_show;
            this.is_vote = ret.data.data.is_vote;
          } else {
          }
        }
      );
    },
    refresh(e) {
      //图片验证码的 点击 刷新
      //$(e.target).attr('src',$(e.target).attr('data-url')+'rand='+Math.random());
      this.verify_img = this.verify_img + "rand=" + Math.random();
    },
    video_vote_() {
      this.is_show_verify = !this.is_show_verify;
      if (this.is_show_verify) {
        this.title_ = "取消";
      } else {
        this.title_ = "投票";
      }
    },
    video_vote() {
      var sid = this.sid;
      var sid_arr = sid.split(",");
      var union_id = this.union_id;
      var aid = this.aid;
      var vCode = this.vCode;
      var vCodeToken = this.token;
      //console.log(sid_arr.length,union_id);
      if (sid_arr.length >= 11 && union_id && vCode) {
        this.$ajax(
          "post",
          "SpotsRace/video_vote",
          {
            sid: sid,
            union_id: union_id,
            token: "SpotsRace-video_vote",
            aid: aid,
            vCode: vCode,
            vCodeToken: vCodeToken
          },
          ret => {
            if (ret.data.id == 0) {
              Toast("投票成功");
              location.reload();
            } else {
              Toast("投票失败");
            }
          }
        );
      } else {
        if (sid) {
          if (union_id) {
            Toast("至少选择10位");
          } else {
            //本地没有数据重新授权一次
            var open_url =
              "https://www.zmlxj.com/api.php/SpotsRace/weixin_login?aid=" +
              this.$route.query.aid;
            window.location.href = open_url;
            if (this.$route.query.union_id) {
              localStorage.setItem(
                "weixin_user_info",
                this.$route.query.union_id
              );
              this.union_id = this.$route.query.union_id;
              this.$route.query.union_id = "";
            } else {
              this.union_id = "";
            }
          }
        } else {
          Toast("已投票");
        }
      }
    },
    search() {
      var key_word = this.key_word;
      var page = 2;
      var aid = this.aid;
      var union_id = this.union_id;
      if (key_word) {
        this.$ajax(
          "post",
          "SpotsRace/get_video_head_number",
          {
            aid: aid,
            page: page,
            key_word: key_word,
            union_id: union_id,
            token: "SpotsRace-Search"
          },
          ret => {
            if (ret.data.id == 0) {
              this.list = ret.data.data.list;
            } else {
              Toast("未找到数据");
            }
          }
        );
      } else {
        Toast("请输入昵称/编号");
      }
    },
    select_(i, id) {
      this.list[i].is_select = this.list[i].is_select == 1 ? 0 : 1;

      this.sid = this.sid + "," + id;
    },
    click_load_more() {
      var aid = this.aid;
      var page = ++this.page;
      if (aid) {
        this.$ajax(
          "post",
          "SpotsRace/get_video_head_number",
          {
            aid: aid,
            page: page,
            key_word: this.key_word,
            union_id: this.union_id,
            token: "SpotsRace-get_video_head_number"
          },
          ret => {
            if (ret.data.id == 0) {
              if (ret.data.data.list) {
                this.list = this.list.concat(ret.data.data.list);
              } else {
                Toast("加载完毕");
              }
            } else {
              Toast("无更多数据");
            }
          }
        );
      } else {
        Toast("加载失败");
      }
    },
    /*******选择全部************/
    select_all() {
      for (var i = 0; i < this.list.length; i++) {
        if (this.list[i].is_can_modify == 1) {
          //表示可以修改-就是投票
          if (this.list[i].is_select == 0) {
            this.sid = this.sid + "," + this.list[i].id;
            this.list[i].is_select = 1;
          }
        } else {
        }
      }
    },
    weixin_hidden() {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function onBridgeReady() {
          // 通过下面这个API隐藏右上角按钮
          WeixinJSBridge.call("hideOptionMenu");
        }
      );
    }
  }
};
</script>