import Vue from "vue";

import Router from "vue-router"; //改成了下面的
//import {createRouter,createWebHashHistory} from 'vue-router'


import ActivityDetail from "@/page/activity/ActivityDetail";
// 活动
import ActivityList from "@/page/activity/ActivityList";
import ApplyAgreement from "@/page/activity/ApplyAgreement";
import FormTeam from "@/page/activity/FormTeam";
import NewTeamName from "@/page/activity/NewTeamName";
import PriceApply from "@/page/activity/PriceApply";
// 相册
import album from "@/page/album/album";
import publishPhoto from "@/page/album/publishPhoto";
import Detail from "@/page/appDetail/detail";
// 新版路书
import books from "@/page/books/books";
// 自驾
import selfDrive from "@/page/car/selfDrive";
// 城市旅游地图
import CityIndex from "@/page/city/CityIndex";
import gaodeDrawMap from "@/page/DrawMap/gaodeDrawMap";
// leaflet瓦片加载
import leafletLoadMap from "@/page/DrawMap/leafletLoadMap";
import LeafletMap from "@/page/DrawMap/LeafletMap";
//十堰乡村振兴项目
import leafletMapGroup from "@/page/DrawMap/leafletMapGroup";
// 智慧景区首页
import leafletMapIndex from "@/page/DrawMap/leafletMapIndex";
//微信小程序中打开网页
import leafletMapMini from "@/page/DrawMap/leafletMapMini";
// 上海新场古镇
import LeafletMapV2 from "@/page/DrawMap/LeafletMapV2";
import LeafletNoDrawMap from "@/page/DrawMap/leafletNoDrawMap";
import linxiaNoDrawMap from "@/page/DrawMap/linxiaNoDrawMap";
import NoDrawMap from "@/page/DrawMap/NoDrawMap";
import AddVisitPoint from "@/page/footguide/AddVisitPoint";
import AttentionTips from "@/page/footguide/AttentionTips";
import CategoryTags from "@/page/footguide/CategoryTags";
import ChoosePark from "@/page/footguide/ChoosePark";
// 徒步向导
import FootGuideIndex from "@/page/footguide/FootGuideIndex";
import GoAndReturn from "@/page/footguide/GoAndReturn";
import GuideFeatures from "@/page/footguide/GuideFeatures";
import GuideTitle from "@/page/footguide/GuideTitle";
import LoadingTrack from "@/page/footguide/LoadingTrack";
import NewFootGuide from "@/page/footguide/NewFootGuide";
import ServiceNote from "@/page/footguide/ServiceNote";
import VisitDetail from "@/page/footguide/VisitDetail";
import VisitedDescribe from "@/page/footguide/VisitedDescribe";
import AppLineInfoList from "@/page/footprints/AppLineInfoList";
import AwardList from "@/page/footprints/AwardList";
// 足迹
import FootprintsIndex from "@/page/footprints/FootprintsIndex";
import FootprintsParticulars from "@/page/footprints/FootprintsParticulars";
import FootprintsPublish from "@/page/footprints/FootprintsPublish";
import GiveRabPacket from "@/page/footprints/GiveRabPacket";
import ImgList from "@/page/footprints/ImgList";
// 赞赏用户列表
import LabelUserList from "@/page/footprints/LabelUserList";
import LineInfoList from "@/page/footprints/LineInfoList";
import LineInfoListtwo from "@/page/footprints/LineInfoListtwo";
import LineInfoListV2 from "@/page/footprints/LineInfoListV2";
import LineList from "@/page/footprints/LineList";
import MakerMap from "@/page/footprints/MakerMap";
import MapNavigation from "@/page/footprints/MapNavigation";
// 赞赏列表
import PayMoney from "@/page/footprints/PayMoney";
import PrimaryScenic from "@/page/footprints/PrimaryScenic";
import RedPacketMap from "@/page/footprints/RedPacketMap";
// 定向赛活动点击路书排行榜进行切换
import RootBooksInfo from "@/page/footprints/RootBooksInfo";
import GuideDetail from "@/page/guide/GuideDetail";
// 服务
import Guide from "@/page/guide/GuideIndex";
import GuidePage from "@/page/guide/GuidePage";
import SelectPark from "@/page/guide/SelectPark";
import Home from "@/page/Home";
// 转盘
import lottery from "@/page/lottery/lottery";
// 聊天窗口
import ChatWindow from "@/page/message/ChatWindow";
import MessageChat from "@/page/message/MessageChat";
import MessageComment from "@/page/message/MessageComment";
// 消息
import MessageIndex from "@/page/message/MessageIndex";
import MessageLike from "@/page/message/MessageLike";
import MyActivities from "@/page/message/MyActivities";
import noteLike from "@/page/message/noteLike";
import spotComment from "@/page/message/spotComment";
import follow from "@/page/my/follow"; // 我的关注
import forget from "@/page/my/forget"; // 忘记密码
import GetMoney from "@/page/my/GetMoney"; // 红包提现
import GetMoneyRecord from "@/page/my/GetMoneyRecord"; // 红包提现记录
import GetMoneySuccess from "@/page/my/GetMoneySuccess"; // 红包提现成功
import login from "@/page/my/login"; // 注册
import LonginAgreement from "@/page/my/LonginAgreement"; // 注册协议
import MyAward from "@/page/my/MyAward"; // 奖品
import MyAwardInfo from "@/page/my/MyAwardInfo"; // 奖品详情
import MyFans from "@/page/my/MyFans"; // 我的粉丝
// 我的
import MyIndex from "@/page/my/MyIndex"; // 我的
import MyNote from "@/page/my/MyNote"; // 用户导游服务主页
import Order from "@/page/my/Order";
import SetExit from "@/page/my/SetExit"; // 设置
import UserReg from "@/page/my/UserReg"; // 登陆
import nihewanActivity from "@/page/nihewan/activity";
import activityList from "@/page/nihewan/activityList";
import albumList from "@/page/nihewan/albumList";
import nihewanBook from "@/page/nihewan/book";
import booksList from "@/page/nihewan/booksList";
// 泥河湾
import nihewanHome from "@/page/nihewan/home";
import nihewanImpression from "@/page/nihewan/impression";
import nihewanMap from "@/page/nihewan/map";
import nihewanMy from "@/page/nihewan/my";
import myActivity from "@/page/nihewan/myActivity";
import myFootprint from "@/page/nihewan/myFootprint";
import myFootprintList from "@/page/nihewan/myFootprintList";
import nihewanQuestion from "@/page/nihewan/question";
import questionIndex from "@/page/nihewan/questionIndex";
import registCollect from "@/page/nihewan/registCollect";
import registFree from "@/page/nihewan/registFree";
import registGroup from "@/page/nihewan/registGroup";
import nihewanRoot from "@/page/nihewan/root";
import rootList from "@/page/nihewan/rootList";
import nihewanSpot from "@/page/nihewan/spot";
import spotsList from "@/page/nihewan/spotsList";
import videoList from "@/page/nihewan/videoList";
import nihewanWorks from "@/page/nihewan/works";
import NoteDetail from "@/page/note/NoteDetail";
// 游记
import NoteIndex from "@/page/note/NoteIndex";
import NotePost from "@/page/note/NotePost";
import GuideAgreement from "@/page/order/GuideAgreement";
import GuideOrderCancel from "@/page/order/GuideOrderCancel";
import GuideOrderDetail from "@/page/order/GuideOrderDetail";
import GuideOrderEdit from "@/page/order/GuideOrderEdit";
import GuideOrderPay from "@/page/order/GuideOrderPay";
import GuideOrderSuccess from "@/page/order/GuideOrderSuccess";
// 订单
import MyOrders from "@/page/order/MyOrders";
// 其他用户界面
import otherIndex from "@/page/other/otherIndex";
import HomeParkList from "@/page/park/HomeParkList";
import HomeSouPark from "@/page/park/HomeSouPark";
// 公园
import ParkList from "@/page/park/ParkList";
import ActivityMap from "@/page/parkPhoto/ActivityMap";
import detail from "@/page/parkPhoto/detail";
// 新版的地图页面
import DrawMap from "@/page/parkPhoto/DrawMap";
import HuangShiTravelIndex from "@/page/parkPhoto/HuangShiTravelIndex";
import Label from "@/page/parkPhoto/Label";
import LabelDetail from "@/page/parkPhoto/LabelDetail";
import MerchantsInfo from "@/page/parkPhoto/MerchantsInfo";
import ParkLineFoot from "@/page/parkPhoto/ParkLineFoot";
import ParkMapLineList from "@/page/parkPhoto/ParkMapLineList";
// 活动
import ParkPhoto from "@/page/parkPhoto/ParkPhoto";
import PhotoList from "@/page/parkPhoto/PhotoList";
import RankList from "@/page/parkPhoto/RankList";
import VideoDetail from "@/page/parkPhoto/VideoDetail";
import VideoRank from "@/page/parkPhoto/VideoRank";
import VideoRule from "@/page/parkPhoto/VideoRule";
import VideoVote from "@/page/parkPhoto/VideoVote";
// 找好友代付界面
import payByFriend from "@/page/payforgoods/payByFriend";
// 支付商品界面
import payforgoods from "@/page/payforgoods/payforgoods";
// 商户订单
import shopOrder from "@/page/payforgoods/shopOrder";
// pixi
import pixiIndex from "@/page/pixi/pixiIndex";
// 新的景区
import PixiLoad from "@/page/pixi/PixiLoad";
import PixiMap from "@/page/pixi/PixiMap";
// 我的发布
import MyPublish from "@/page/publish/MyPublish";
// 餐馆折扣发布
import PublishSale from "@/page/publish/PublishSale";
import SetLottery from "@/page/publish/SetLottery";
import qdetail from "@/page/question/qdetail";
// 文总答题
import qindex from "@/page/question/qindex";
import qrank from "@/page/question/qrank";
import about from "@/page/rests/about";
// 其他
import DownloadApp from "@/page/rests/DownloadApp";
import feedback from "@/page/rests/feedback";
// 搜索
import SearchIndex from "@/page/SearchHome/SearchIndex";
// 安卓设置权限
import permission from "@/page/setting/permission";
import QiDongPermission from "@/page/setting/QiDongPermission";
/**
 * 商户扫码核销
 */
import check from "@/page/shop/check";
import payforsale from "@/page/shop/payforsale";
// 餐馆折扣
import sale from "@/page/shop/sale";
// 商户活动/list
import shopList from "@/page/shop/shopList";
import shopListPublish from "@/page/shop/shopListPublish";
import CheckTicket from "@/page/ticket/CheckTicket";
import MakeOrder from "@/page/ticket/MakeOrder";
import Pay from "@/page/ticket/Pay";
import Success from "@/page/ticket/Success";
// 20180720 增加售票页面+票务核销
import TicketIndex from "@/page/ticket/TicketIndex";
import TicketList from "@/page/ticket/TicketList";
import TicketParkList from "@/page/ticket/TicketParkList";
// 轨迹
import myTrackList from "@/page/track/myTrackList";
// 轨记
 import TrackDetail from "@/page/track/TrackDetail";
// 20180321 增加核销页面
import index from "@/page/verification/index";
import apply from "@/page/volunteer/apply";
import BaseMsg from "@/page/volunteer/BaseMsg";
import CompileVolunteer from "@/page/volunteer/CompileVolunteer";
import HealthyMsg from "@/page/volunteer/HealthyMsg";
// 志愿者
import volunteerindex from "@/page/volunteer/index";
import view from "@/page/volunteer/view";
import multipleVote from "@/page/vote/multipleVote";
// 投票
import vote from "@/page/vote/vote";
import voteDetail from "@/page/vote/voteDetail";
import voteList from "@/page/vote/voteList";
import wx_activity_detail from "@/page/wuhanlvyouxiehui/wx_activity_detail";
import wx_activity_list from "@/page/wuhanlvyouxiehui/wx_activity_list";
import wx_coupon_list from "@/page/wuhanlvyouxiehui/wx_coupon_list";
import wx_detail from "@/page/wuhanlvyouxiehui/wx_detail";
//武汉市旅游协会
import wx_index from "@/page/wuhanlvyouxiehui/wx_index";
import wx_order_list from "@/page/wuhanlvyouxiehui/wx_order_list";
import wx_prev_detail from "@/page/wuhanlvyouxiehui/wx_prev_detail";

//mapbox 
import mapboxBooksDetail from '@/page/books/mapboxBooksDetail';

//定位签约学校
import schoolIndex from '@/page/school/index';



Vue.use(Router)

export default new Router({
  mode: 'hash', // history
  base: '/',
  routes: [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true // 需要被缓存
    }
  },
  {
    path:'/school/index',
    name:'sindex',
    component:schoolIndex
  },
  {
    path: '/DrawMap/leafletMapGroup',
    name: 'leafletMapGroup',
    component: leafletMapGroup
  },
  {
    path:'/DrawMap/leafletMapMini',
    name:'leafletMapMini',
    component:leafletMapMini
  },
  {
    path: '/DrawMap/leafletMapIndex',
    name: 'leafletMapIndex',
    component: leafletMapIndex
  },
  {
    path:'/Books/mapboxBooksDetail',
    name:'mapboxBooksDetail',
    component:mapboxBooksDetail
  },
  {
    path: '/shop/check',
    name: 'check',
    component: check
  },
  {
    path: '/album/publishPhoto',
    name: 'publishPhoto',
    component: publishPhoto
  },
  {
    path: '/album/album',
    name: 'album',
    component: album
  },
  {
    path: '/car/selfDrive',
    name: 'selfDrive',
    component: selfDrive
  },
  {
    path: '/nihewan/map',
    name: 'nihewanMap',
    component: nihewanMap
  },
  {
    path: '/nihewan/myFootprint',
    name: 'myFootprint',
    component: myFootprint
  },
  {
    path: '/nihewan/myFootprintList',
    name: 'myFootprintList',
    component: myFootprintList
  },
  {
    path: '/nihewan/myActivity',
    name: 'myActivity',
    component: myActivity
  },
  {
    path: '/nihewan/my',
    name: 'nihewanMy',
    component: nihewanMy
  },
  {
    path: '/nihewan/registGroup',
    name: 'registGroup',
    component: registGroup
  },
  {
    path: '/nihewan/registCollect',
    name: 'registCollect',
    component: registCollect
  },
  {
    path: '/nihewan/registFree',
    name: 'registFree',
    component: registFree
  },
  {
    path: '/nihewan/albumList',
    name: 'albumList',
    component: albumList
  },
  {
    path: '/nihewan/works',
    name: 'nihewanWorks',
    component: nihewanWorks
  },
  {
    path: '/nihewan/activity',
    name: 'nihewanActivity',
    component: nihewanActivity
  },
  {
    path: '/nihewan/activityList',
    name: 'activityList',
    component: activityList
  },
  {
    path: '/nihewan/question',
    name: 'nihewanQuestion',
    component: nihewanQuestion
  },
  {
    path: '/nihewan/questionIndex',
    name: 'questionIndex',
    component: questionIndex
  },
  {
    path: '/nihewan/videoList',
    name: 'videoList',
    component: videoList
  },
  {
    path: '/nihewan/book',
    name: 'nihewanBook',
    component: nihewanBook
  },
  {
    path: '/nihewan/booksList',
    name: 'booksList',
    component: booksList
  },
  {
    path: '/nihewan/root',
    name: 'nihewanRoot',
    component: nihewanRoot
  },
  {
    path: '/nihewan/rootList',
    name: 'rootList',
    component: rootList
  },
  {
    path: '/nihewan/spot',
    name: 'nihewanSpot',
    component: nihewanSpot
  },
  {
    path: '/nihewan/spotsList',
    name: 'spotsList',
    component: spotsList
  },
  {
    path: '/nihewan/impression',
    name: 'nihewanImpression',
    component: nihewanImpression
  },
  {
    path: '/nihewan/home',
    name: 'nihewanHome',
    component: nihewanHome
  },
  {
    path: '/shop/shopListPublish',
    name: 'shopListPublish',
    component: shopListPublish
  },
  {
    path: '/shop/shopList',
    name: 'shopList',
    component: shopList
  },
  {
    path: '/payforgoods/shopOrder',
    name: 'shopOrder',
    component: shopOrder
  },
  {
    path: '/DrawMap/LeafletMapV2',
    name: 'LeafletMapV2',
    component: LeafletMapV2
  },
  {
    path: '/DrawMap/LeafletNoDrawMap',
    name: 'LeafletNoDrawMap',
    component: LeafletNoDrawMap
  },
  {
    path: '/DrawMap/linxiaNoDrawMap',
    name: 'linxiaNoDrawMap',
    component: linxiaNoDrawMap
  },
  {
    path: '/DrawMap/gaodeDrawMap',
    name: 'gaodeDrawMap',
    component: gaodeDrawMap
  },
  {
    path: '/DrawMap/LeafletMap',
    name: 'LeafletMap',
    component: LeafletMap
  },
  {
    path: '/DrawMap/leafletLoadMap',
    name: 'leafletLoadMap',
    component: leafletLoadMap
  },
  {
    path: '/city/CityIndex',
    name: 'CityIndex',
    component: CityIndex
  },
  {
    path: '/pixi/PixiMap',
    name: 'PixiMap',
    component: PixiMap
  },

  {
    path: '/pixi/PixiLoad',
    name: 'PixiLoad',
    component: PixiLoad
  },
  {
    path: '/shop/sale',
    name: 'sale',
    component: sale
  },
  {
    path: '/shop/payforsale',
    name: 'payforsale',
    component: payforsale
  },
  {
    path: '/publish/PublishSale',
    name: 'PublishSale',
    component: PublishSale
  },
  {
    path: '/books/books',
    name: 'books',
    component: books
  },
  {
    path: '/vote/voteList',
    name: 'voteList',
    component: voteList
  },
  {
    path: '/vote/voteDetail',
    name: 'voteDetail',
    component: voteDetail
  },
  {
    path: '/vote/vote',
    name: 'vote',
    component: vote
  },
  {
    path: '/vote/multipleVote',
    name: 'multipleVote',
    component: multipleVote
  },
  {
    path: '/publish/SetLottery',
    name: 'SetLottery',
    component: SetLottery
  },
  {
    path: '/publish/MyPublish',
    name: 'MyPublish',
    component: MyPublish
  },
  {
    path: '/footprints/PayMoney',
    name: 'PayMoney',
    component: PayMoney
  },
  {
    path: '/payforgoods/payByFriend',
    name: 'payByFriend',
    component: payByFriend
  },
  {
    path: '/payforgoods/payforgoods',
    name: 'payforgoods',
    component: payforgoods
  },
  {
    path: '/lottery/lottery',
    name: 'lottery',
    component: lottery
  },
  {
    path: '/footprints/LabelUserList',
    name: 'LabelUserList',
    component: LabelUserList
  },
  // 游记
  {
    path: '/note/NoteIndex/',
    name: 'NoteIndex',
    component: NoteIndex
  },
  {
    path: '/note/NoteDetail/:id',
    name: 'NoteDetail',
    component: NoteDetail
  },
  {
    path: '/note/NotePost/',
    name: 'NotePost',
    component: NotePost
  },

  {
    path: '/guide/',
    name: 'Guide',
    component: Guide
  },
  {
    path: '/GuideDetail/:id/:verify',
    name: 'GuideDetail',
    component: GuideDetail
  },
  {
    path: '/guide/GuidePage/:id',
    name: 'GuidePage',
    component: GuidePage
  },
  {
    path: '/selectPark',
    name: 'SelectPark',
    component: SelectPark,
    meta: {
      keepAlive: true
    }
  },
  // 徒步向导
  {
    path: '/footguide/FootGuideIndex',
    name: 'FootGuideIndex',
    component: FootGuideIndex
  },
  {
    path: '/footguide/NewFootGuide',
    name: 'NewFootGuide',
    component: NewFootGuide
  },
  {
    path: '/footguide/GuideTitle',
    name: 'GuideTitle',
    component: GuideTitle
  },
  {
    path: '/footguide/CategoryTags',
    name: 'CategoryTags',
    component: CategoryTags
  },
  {
    path: '/footguide/GuideFeatures',
    name: 'GuideFeatures',
    component: GuideFeatures
  },
  {
    path: '/footguide/LoadingTrack',
    name: 'LoadingTrack',
    component: LoadingTrack
  },
  {
    path: '/footguide/VisitDetail',
    name: 'VisitDetail',
    component: VisitDetail
  },
  {
    path: '/footguide/VisitedDescribe',
    name: 'VisitedDescribe',
    component: VisitedDescribe
  },
  {
    path: '/footguide/AddVisitPoint',
    name: 'AddVisitPoint',
    component: AddVisitPoint
  },
  {
    path: '/footguide/GoAndReturn',
    name: 'GoAndReturn',
    component: GoAndReturn
  },
  {
    path: '/footguide/AttentionTips',
    name: 'AttentionTips',
    component: AttentionTips
  },
  {
    path: '/footguide/ServiceNote',
    name: 'ServiceNote',
    component: ServiceNote
  },
  {
    path: '/footguide/ChoosePark',
    name: 'ChoosePark',
    component: ChoosePark
  },
  // 轨迹
  {
    path: '/track/TrackDetail/:id',
    name: 'TrackDetail',
    component: TrackDetail
  },
  // 公园
  {
    path: '/park/ParkList/',
    name: 'ParkList',
    component: ParkList
  },
  {
    path: '/park/HomeParkList/',
    name: 'HomeParkList',
    component: HomeParkList
  },
  {
    path: '/park/HomeSouPark/',
    name: 'HomeSouPark',
    component: HomeSouPark
  },
  // 我的
  {
    path: '/my',
    name: 'MyIndex',
    component: MyIndex
  },
  {
    path: '/my/login',
    name: 'login',
    component: login
  },
  {
    path: '/my/forget',
    name: 'forget',
    component: forget
  },
  {
    path: '/my/follow',
    name: 'follow',
    component: follow
  },
  {
    path: '/my/MyFans',
    name: 'MyFans',
    component: MyFans
  },
  {
    path: '/my/GetMoney',
    name: 'GetMoney',
    component: GetMoney
  },
  {
    path: '/my/GetMoneyRecord',
    name: 'GetMoneyRecord',
    component: GetMoneyRecord
  },
  {
    path: '/my/GetMoneySuccess',
    name: 'GetMoneySuccess',
    component: GetMoneySuccess
  },
  {
    path: '/my/MyNote',
    name: 'MyNote',
    component: MyNote
  },
  {
    path: '/my/UserReg',
    name: 'UserReg',
    component: UserReg
  },
  {
    path: '/my/SetExit',
    name: 'SetExit',
    component: SetExit
  },
  {
    path: '/my/LonginAgreement',
    name: 'LonginAgreement',
    component: LonginAgreement
  },
  {
    path: '/my/MyAward',
    name: 'MyAward',
    component: MyAward
  },
  {
    path: '/my/MyAwardInfo',
    name: 'MyAwardInfo',
    component: MyAwardInfo
  },
  // 活动
  {
    path: '/activity/ActivityList',
    name: 'ActivityList',
    component: ActivityList,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/activity/ActivityDetail',
    name: 'ActivityDetail',
    component: ActivityDetail
  },
  {
    path: '/activity/PriceApply',
    name: 'PriceApply',
    component: PriceApply
  },
  {
    path: '/activity/ApplyAgreement',
    name: 'ApplyAgreement',
    component: ApplyAgreement
  },
  {
    path: '/activity/FormTeam',
    name: 'FormTeam',
    component: FormTeam
  },
  {
    path: '/activity/NewTeamName',
    name: 'NewTeamName',
    component: NewTeamName
  },
  // 志愿者
  {
    path: '/volunteer/index',
    name: 'volunteerindex',
    component: volunteerindex
  },
  {
    path: '/volunteer/BaseMsg',
    name: 'BaseMsg',
    component: BaseMsg
  },
  {
    path: '/volunteer/HealthyMsg',
    name: 'HealthyMsg',
    component: HealthyMsg
  },
  {
    path: '/volunteer/CompileVolunteer',
    name: 'CompileVolunteer',
    component: CompileVolunteer
  },
  {
    path: '/volunteer/apply',
    name: 'apply',
    component: apply
  },
  {
    path: '/volunteer/view',
    name: 'view',
    component: view
  },
  // 消息
  {
    path: '/message/MessageIndex',
    name: 'MessageIndex',
    component: MessageIndex
  },
  {
    path: '/message/MessageChat',
    name: 'MessageChat',
    component: MessageChat
  },
  {
    path: '/message/MessageComment',
    name: 'MessageComment',
    component: MessageComment
  },
  {
    path: '/message/MessageLike',
    name: 'MessageLike',
    component: MessageLike
  },
  {
    path: '/message/noteLike',
    name: 'noteLike',
    component: noteLike
  },
  {
    path: '/message/spotComment',
    name: 'spotComment',
    component: spotComment
  },
  {
    path: '/message/MyActivities',
    name: 'MyActivities',
    component: MyActivities
  },

  // 其他用户主页
  {
    path: '/other/otherIndex/:uid',
    name: 'otherIndex',
    component: otherIndex
  },

  // 聊天窗口
  {
    path: '/message/ChatWindow/:uid',
    name: 'ChatWindow',
    component: ChatWindow
  }, // 其他
  {
    path: '/rests/DownloadApp',
    name: 'DownloadApp',
    component: DownloadApp
  },
  {
    path: '/rests/about',
    name: 'about',
    component: about
  },
  {
    path: '/rests/feedback',
    name: 'feedback',
    component: feedback
  },
  // 足迹
  {
    path: '/footprints/FootprintsIndex',
    name: 'FootprintsIndex',
    component: FootprintsIndex
  },
  {
    path: '/footprints/AwardList',
    name: 'AwardList',
    component: AwardList
  },
  {
    path: '/footprints/FootprintsParticulars',
    name: 'FootprintsParticulars',
    component: FootprintsParticulars
  },
  {
    path: '/footprints/FootprintsPublish',
    name: 'FootprintsPublish',
    component: FootprintsPublish
  },
  {
    path: '/footprints/RedPacketMap',
    name: 'RedPacketMap',
    component: RedPacketMap
  },
  {
    path: '/footprints/MapNavigation',
    name: 'MapNavigation',
    component: MapNavigation
  },
  {
    path: '/footprints/GiveRabPacket',
    name: 'GiveRabPacket',
    component: GiveRabPacket
  },
  {
    path: '/footprints/MakerMap',
    name: 'MakerMap',
    component: MakerMap
  },
  {
    path: '/footprints/PrimaryScenic',
    name: 'PrimaryScenic',
    component: PrimaryScenic
  },
  {
    path: '/footprints/LineList',
    name: 'LineList',
    component: LineList
  },
  {
    path: '/footprints/LineInfoList',
    name: 'LineInfoList',
    component: LineInfoList
  },
  {
    path: '/footprints/RootBooksInfo',
    name: 'RootBooksInfo',
    component: RootBooksInfo
  },
  {
    path: '/footprints/LineInfoListtwo',
    name: 'LineInfoListtwo',
    component: LineInfoListtwo
  },
  {
    path: '/footprints/AppLineInfoList',
    name: 'AppLineInfoList',
    component: AppLineInfoList
  },
  {
    path: '/footprints/ImgList',
    name: 'ImgList',
    component: ImgList
  },

  {
    path: '/track/myTrackList',
    name: 'myTrackList',
    component: myTrackList
  },
  {
    path: '/setting/permission',
    name: 'permission',
    component: permission
  },
  {
    path: '/setting/QiDongPermission',
    name: 'QiDongPermission',
    component: QiDongPermission
  },
  {
    path: '/appDetail/detail',
    name: 'Detail',
    component: Detail
  },

  // 订单
  {
    path: '/order/MyOrders',
    name: 'MyOrders',
    component: MyOrders
  },
  {
    // 订单长id、出行时间time、人数num、总价price、用户名name、用户头像img、标题tit
    path: '/order/GuideOrderPay/:id/:time/:num/:price/:name/:img/:tit',
    name: 'GuideOrderPay',
    component: GuideOrderPay
  },
  {
    path: '/order/GuideOrderEdit/:id/:luId/:price/:tit/:verify',
    name: 'GuideOrderEdit',
    component: GuideOrderEdit
  },
  {
    path: '/order/GuideOrderDetail/:id/:status/:my',
    name: 'GuideOrderDetail',
    component: GuideOrderDetail
  },
  {
    path: '/order/GuideOrderCancel/',
    name: 'GuideOrderCancel',
    component: GuideOrderCancel
  },
  {
    path: '/order/GuideOrderSuccess/',
    name: 'GuideOrderSuccess',
    component: GuideOrderSuccess
  },
  {
    path: '/order/GuideAgreement/',
    name: 'GuideAgreement',
    component: GuideAgreement
  },
  // 摄影大赛
  {
    path: '/parkPhoto/ParkPhoto',
    name: 'ParkPhoto',
    component: ParkPhoto
  },
  {
    path: '/parkPhoto/PhotoList',
    name: 'PhotoList',
    component: PhotoList
  },
  {
    path: '/parkPhoto/detail',
    name: 'detail',
    component: detail
  },
  {
    path: '/parkPhoto/RankList',
    name: 'RankList',
    component: RankList
  },
  {
    path: '/parkPhoto/ParkMapLineList',
    name: 'ParkMapLineList',
    component: ParkMapLineList
  },
  {
    path: '/parkPhoto/DrawMap',
    name: 'DrawMap',
    component: DrawMap
  },
  {
    path: '/parkPhoto/Label',
    name: 'Label',
    component: Label
  },
  {
    path: '/parkPhoto/LabelDetail',
    name: 'LabelDetail',
    component: LabelDetail
  },
  {
    path: '/parkPhoto/VideoVote',
    name: 'VideoVote',
    component: VideoVote
  },
  {
    path: '/parkPhoto/VideoRank',
    name: 'VideoRank',
    component: VideoRank
  },
  {
    path: '/parkPhoto/VideoRule',
    name: 'VideoRule',
    component: VideoRule
  },
  {
    path: '/parkPhoto/VideoDetail',
    name: 'VideoDetail',
    component: VideoDetail
  },
  {
    path: '/parkPhoto/HuangShiTravelIndex',
    name: 'HuangShiTravelIndex',
    component: HuangShiTravelIndex
  },
  {
    path: '/parkPhoto/ParkLineFoot',
    name: 'ParkLineFoot',
    component: ParkLineFoot
  },
  {
    path: '/parkPhoto/ActivityMap',
    name: 'ActivityMap',
    component: ActivityMap
  },
  {
    path: '/parkPhoto/MerchantsInfo',
    name: 'MerchantsInfo',
    component: MerchantsInfo
  },
  {
    path: '/SearchHome/SearchIndex',
    name: 'SearchIndex',
    component: SearchIndex
  },
  {
    path: '/verification/index',
    name: 'vindex',
    component: index
  },
  {
    path: '/ticket',
    name: 'TicketIndex',
    component: TicketIndex
  },
  {
    path: '/ticket/CheckTicket',
    name: 'CheckTicket',
    component: CheckTicket
  },
  {
    path: '/ticket/TicketList',
    name: 'TicketList',
    component: TicketList
  },
  {
    path: '/ticket/TicketParkList',
    name: 'TicketParkList',
    component: TicketParkList
  },
  {
    path: '/ticket/MakeOrder',
    name: 'MakeOrder',
    component: MakeOrder
  },
  {
    path: '/ticket/Pay',
    name: 'Pay',
    component: Pay
  },
  {
    path: '/ticket/Success',
    name: 'Success',
    component: Success
  },
  {
    path: '/my/Order',
    name: 'Order',
    component: Order
  },
  {
    path: '/wuhanlvyouxiehui/wx_index',
    name: 'wx_index',
    component: wx_index
  },
  {
    path: '/wuhanlvyouxiehui/wx_coupon_list',
    name: 'wx_coupon_list',
    component: wx_coupon_list
  },
  {
    path: '/wuhanlvyouxiehui/wx_prev_detail',
    name: 'wx_prev_detail',
    component: wx_prev_detail
  },
  {
    path: '/wuhanlvyouxiehui/wx_activity_list',
    name: 'wx_activity_list',
    component: wx_activity_list
  },
  {
    path: '/wuhanlvyouxiehui/wx_order_list',
    name: 'wx_order_list',
    component: wx_order_list
  },
  {
    path: '/wuhanlvyouxiehui/wx_detail',
    name: 'wx_detail',
    component: wx_detail
  },
  {
    path: '/wuhanlvyouxiehui/wx_activity_detail',
    name: 'wx_activity_detail',
    component: wx_activity_detail
  },

  {
    path: '/question/qindex',
    name: 'qindex',
    component: qindex
  },
  {
    path: '/question/qdetail',
    name: 'qdetail',
    component: qdetail
  },
  {
    path: '/question/qrank',
    name: 'qrank',
    component: qrank
  },
  {
    path: '/footprints/LineInfoListV2',
    name: 'LineInfoListV2',
    component: LineInfoListV2
  },

  {
    path: '/DrawMap/NoDrawMap',
    name: 'NoDrawMap',
    component: NoDrawMap
  },
  {
    path: '/pixi/pixiIndex',
    name: 'pixiIndex',
    component: pixiIndex
  }
  ]
})







// Vue.use(Router)

// let router =  createRouter({
//   history:createWebHashHistory(),
//   routes: [{
//     path: '/',
//     name: 'Home',
//     component: Home,
//     meta: {
//       keepAlive: true // 需要被缓存
//     }
//   },
//   {
//     path:'/school/index',
//     name:'sindex',
//     component:schoolIndex
//   },
//   {
//     path: '/DrawMap/leafletMapGroup',
//     name: 'leafletMapGroup',
//     component: leafletMapGroup
//   },
//   {
//     path:'/DrawMap/leafletMapMini',
//     name:'leafletMapMini',
//     component:leafletMapMini
//   },
//   {
//     path: '/DrawMap/leafletMapIndex',
//     name: 'leafletMapIndex',
//     component: leafletMapIndex
//   },
//   {
//     path:'/Books/mapboxBooksDetail',
//     name:'mapboxBooksDetail',
//     component:mapboxBooksDetail
//   },
//   {
//     path: '/shop/check',
//     name: 'check',
//     component: check
//   },
//   {
//     path: '/album/publishPhoto',
//     name: 'publishPhoto',
//     component: publishPhoto
//   },
//   {
//     path: '/album/album',
//     name: 'album',
//     component: album
//   },
//   {
//     path: '/car/selfDrive',
//     name: 'selfDrive',
//     component: selfDrive
//   },
//   {
//     path: '/nihewan/map',
//     name: 'nihewanMap',
//     component: nihewanMap
//   },
//   {
//     path: '/nihewan/myFootprint',
//     name: 'myFootprint',
//     component: myFootprint
//   },
//   {
//     path: '/nihewan/myFootprintList',
//     name: 'myFootprintList',
//     component: myFootprintList
//   },
//   {
//     path: '/nihewan/myActivity',
//     name: 'myActivity',
//     component: myActivity
//   },
//   {
//     path: '/nihewan/my',
//     name: 'nihewanMy',
//     component: nihewanMy
//   },
//   {
//     path: '/nihewan/registGroup',
//     name: 'registGroup',
//     component: registGroup
//   },
//   {
//     path: '/nihewan/registCollect',
//     name: 'registCollect',
//     component: registCollect
//   },
//   {
//     path: '/nihewan/registFree',
//     name: 'registFree',
//     component: registFree
//   },
//   {
//     path: '/nihewan/albumList',
//     name: 'albumList',
//     component: albumList
//   },
//   {
//     path: '/nihewan/works',
//     name: 'nihewanWorks',
//     component: nihewanWorks
//   },
//   {
//     path: '/nihewan/activity',
//     name: 'nihewanActivity',
//     component: nihewanActivity
//   },
//   {
//     path: '/nihewan/activityList',
//     name: 'activityList',
//     component: activityList
//   },
//   {
//     path: '/nihewan/question',
//     name: 'nihewanQuestion',
//     component: nihewanQuestion
//   },
//   {
//     path: '/nihewan/questionIndex',
//     name: 'questionIndex',
//     component: questionIndex
//   },
//   {
//     path: '/nihewan/videoList',
//     name: 'videoList',
//     component: videoList
//   },
//   {
//     path: '/nihewan/book',
//     name: 'nihewanBook',
//     component: nihewanBook
//   },
//   {
//     path: '/nihewan/booksList',
//     name: 'booksList',
//     component: booksList
//   },
//   {
//     path: '/nihewan/root',
//     name: 'nihewanRoot',
//     component: nihewanRoot
//   },
//   {
//     path: '/nihewan/rootList',
//     name: 'rootList',
//     component: rootList
//   },
//   {
//     path: '/nihewan/spot',
//     name: 'nihewanSpot',
//     component: nihewanSpot
//   },
//   {
//     path: '/nihewan/spotsList',
//     name: 'spotsList',
//     component: spotsList
//   },
//   {
//     path: '/nihewan/impression',
//     name: 'nihewanImpression',
//     component: nihewanImpression
//   },
//   {
//     path: '/nihewan/home',
//     name: 'nihewanHome',
//     component: nihewanHome
//   },
//   {
//     path: '/shop/shopListPublish',
//     name: 'shopListPublish',
//     component: shopListPublish
//   },
//   {
//     path: '/shop/shopList',
//     name: 'shopList',
//     component: shopList
//   },
//   {
//     path: '/payforgoods/shopOrder',
//     name: 'shopOrder',
//     component: shopOrder
//   },
//   {
//     path: '/DrawMap/LeafletMapV2',
//     name: 'LeafletMapV2',
//     component: LeafletMapV2
//   },
//   {
//     path: '/DrawMap/LeafletNoDrawMap',
//     name: 'LeafletNoDrawMap',
//     component: LeafletNoDrawMap
//   },
//   {
//     path: '/DrawMap/linxiaNoDrawMap',
//     name: 'linxiaNoDrawMap',
//     component: linxiaNoDrawMap
//   },
//   {
//     path: '/DrawMap/gaodeDrawMap',
//     name: 'gaodeDrawMap',
//     component: gaodeDrawMap
//   },
//   {
//     path: '/DrawMap/LeafletMap',
//     name: 'LeafletMap',
//     component: LeafletMap
//   },
//   {
//     path: '/DrawMap/leafletLoadMap',
//     name: 'leafletLoadMap',
//     component: leafletLoadMap
//   },
//   {
//     path: '/city/CityIndex',
//     name: 'CityIndex',
//     component: CityIndex
//   },
//   {
//     path: '/pixi/PixiMap',
//     name: 'PixiMap',
//     component: PixiMap
//   },

//   {
//     path: '/pixi/PixiLoad',
//     name: 'PixiLoad',
//     component: PixiLoad
//   },
//   {
//     path: '/shop/sale',
//     name: 'sale',
//     component: sale
//   },
//   {
//     path: '/shop/payforsale',
//     name: 'payforsale',
//     component: payforsale
//   },
//   {
//     path: '/publish/PublishSale',
//     name: 'PublishSale',
//     component: PublishSale
//   },
//   {
//     path: '/books/books',
//     name: 'books',
//     component: books
//   },
//   {
//     path: '/vote/voteList',
//     name: 'voteList',
//     component: voteList
//   },
//   {
//     path: '/vote/voteDetail',
//     name: 'voteDetail',
//     component: voteDetail
//   },
//   {
//     path: '/vote/vote',
//     name: 'vote',
//     component: vote
//   },
//   {
//     path: '/vote/multipleVote',
//     name: 'multipleVote',
//     component: multipleVote
//   },
//   {
//     path: '/publish/SetLottery',
//     name: 'SetLottery',
//     component: SetLottery
//   },
//   {
//     path: '/publish/MyPublish',
//     name: 'MyPublish',
//     component: MyPublish
//   },
//   {
//     path: '/footprints/PayMoney',
//     name: 'PayMoney',
//     component: PayMoney
//   },
//   {
//     path: '/payforgoods/payByFriend',
//     name: 'payByFriend',
//     component: payByFriend
//   },
//   {
//     path: '/payforgoods/payforgoods',
//     name: 'payforgoods',
//     component: payforgoods
//   },
//   {
//     path: '/lottery/lottery',
//     name: 'lottery',
//     component: lottery
//   },
//   {
//     path: '/footprints/LabelUserList',
//     name: 'LabelUserList',
//     component: LabelUserList
//   },
//   // 游记
//   {
//     path: '/note/NoteIndex/',
//     name: 'NoteIndex',
//     component: NoteIndex
//   },
//   {
//     path: '/note/NoteDetail/:id',
//     name: 'NoteDetail',
//     component: NoteDetail
//   },
//   {
//     path: '/note/NotePost/',
//     name: 'NotePost',
//     component: NotePost
//   },

//   {
//     path: '/guide/',
//     name: 'Guide',
//     component: Guide
//   },
//   {
//     path: '/GuideDetail/:id/:verify',
//     name: 'GuideDetail',
//     component: GuideDetail
//   },
//   {
//     path: '/guide/GuidePage/:id',
//     name: 'GuidePage',
//     component: GuidePage
//   },
//   {
//     path: '/selectPark',
//     name: 'SelectPark',
//     component: SelectPark,
//     meta: {
//       keepAlive: true
//     }
//   },
//   // 徒步向导
//   {
//     path: '/footguide/FootGuideIndex',
//     name: 'FootGuideIndex',
//     component: FootGuideIndex
//   },
//   {
//     path: '/footguide/NewFootGuide',
//     name: 'NewFootGuide',
//     component: NewFootGuide
//   },
//   {
//     path: '/footguide/GuideTitle',
//     name: 'GuideTitle',
//     component: GuideTitle
//   },
//   {
//     path: '/footguide/CategoryTags',
//     name: 'CategoryTags',
//     component: CategoryTags
//   },
//   {
//     path: '/footguide/GuideFeatures',
//     name: 'GuideFeatures',
//     component: GuideFeatures
//   },
//   {
//     path: '/footguide/LoadingTrack',
//     name: 'LoadingTrack',
//     component: LoadingTrack
//   },
//   {
//     path: '/footguide/VisitDetail',
//     name: 'VisitDetail',
//     component: VisitDetail
//   },
//   {
//     path: '/footguide/VisitedDescribe',
//     name: 'VisitedDescribe',
//     component: VisitedDescribe
//   },
//   {
//     path: '/footguide/AddVisitPoint',
//     name: 'AddVisitPoint',
//     component: AddVisitPoint
//   },
//   {
//     path: '/footguide/GoAndReturn',
//     name: 'GoAndReturn',
//     component: GoAndReturn
//   },
//   {
//     path: '/footguide/AttentionTips',
//     name: 'AttentionTips',
//     component: AttentionTips
//   },
//   {
//     path: '/footguide/ServiceNote',
//     name: 'ServiceNote',
//     component: ServiceNote
//   },
//   {
//     path: '/footguide/ChoosePark',
//     name: 'ChoosePark',
//     component: ChoosePark
//   },
//   // 轨迹
//   // {
//   //   path: '/track/TrackDetail/:id',
//   //   name: 'TrackDetail',
//   //   component: TrackDetail
//   // },
//   // 公园
//   {
//     path: '/park/ParkList/',
//     name: 'ParkList',
//     component: ParkList
//   },
//   {
//     path: '/park/HomeParkList/',
//     name: 'HomeParkList',
//     component: HomeParkList
//   },
//   {
//     path: '/park/HomeSouPark/',
//     name: 'HomeSouPark',
//     component: HomeSouPark
//   },
//   // 我的
//   {
//     path: '/my',
//     name: 'MyIndex',
//     component: MyIndex
//   },
//   {
//     path: '/my/login',
//     name: 'login',
//     component: login
//   },
//   {
//     path: '/my/forget',
//     name: 'forget',
//     component: forget
//   },
//   {
//     path: '/my/follow',
//     name: 'follow',
//     component: follow
//   },
//   {
//     path: '/my/MyFans',
//     name: 'MyFans',
//     component: MyFans
//   },
//   {
//     path: '/my/GetMoney',
//     name: 'GetMoney',
//     component: GetMoney
//   },
//   {
//     path: '/my/GetMoneyRecord',
//     name: 'GetMoneyRecord',
//     component: GetMoneyRecord
//   },
//   {
//     path: '/my/GetMoneySuccess',
//     name: 'GetMoneySuccess',
//     component: GetMoneySuccess
//   },
//   {
//     path: '/my/MyNote',
//     name: 'MyNote',
//     component: MyNote
//   },
//   {
//     path: '/my/UserReg',
//     name: 'UserReg',
//     component: UserReg
//   },
//   {
//     path: '/my/SetExit',
//     name: 'SetExit',
//     component: SetExit
//   },
//   {
//     path: '/my/LonginAgreement',
//     name: 'LonginAgreement',
//     component: LonginAgreement
//   },
//   {
//     path: '/my/MyAward',
//     name: 'MyAward',
//     component: MyAward
//   },
//   {
//     path: '/my/MyAwardInfo',
//     name: 'MyAwardInfo',
//     component: MyAwardInfo
//   },
//   // 活动
//   {
//     path: '/activity/ActivityList',
//     name: 'ActivityList',
//     component: ActivityList,
//     meta: {
//       keepAlive: true
//     }
//   },
//   {
//     path: '/activity/ActivityDetail',
//     name: 'ActivityDetail',
//     component: ActivityDetail
//   },
//   {
//     path: '/activity/PriceApply',
//     name: 'PriceApply',
//     component: PriceApply
//   },
//   {
//     path: '/activity/ApplyAgreement',
//     name: 'ApplyAgreement',
//     component: ApplyAgreement
//   },
//   {
//     path: '/activity/FormTeam',
//     name: 'FormTeam',
//     component: FormTeam
//   },
//   {
//     path: '/activity/NewTeamName',
//     name: 'NewTeamName',
//     component: NewTeamName
//   },
//   // 志愿者
//   {
//     path: '/volunteer/index',
//     name: 'volunteerindex',
//     component: volunteerindex
//   },
//   {
//     path: '/volunteer/BaseMsg',
//     name: 'BaseMsg',
//     component: BaseMsg
//   },
//   {
//     path: '/volunteer/HealthyMsg',
//     name: 'HealthyMsg',
//     component: HealthyMsg
//   },
//   {
//     path: '/volunteer/CompileVolunteer',
//     name: 'CompileVolunteer',
//     component: CompileVolunteer
//   },
//   {
//     path: '/volunteer/apply',
//     name: 'apply',
//     component: apply
//   },
//   {
//     path: '/volunteer/view',
//     name: 'view',
//     component: view
//   },
//   // 消息
//   {
//     path: '/message/MessageIndex',
//     name: 'MessageIndex',
//     component: MessageIndex
//   },
//   {
//     path: '/message/MessageChat',
//     name: 'MessageChat',
//     component: MessageChat
//   },
//   {
//     path: '/message/MessageComment',
//     name: 'MessageComment',
//     component: MessageComment
//   },
//   {
//     path: '/message/MessageLike',
//     name: 'MessageLike',
//     component: MessageLike
//   },
//   {
//     path: '/message/noteLike',
//     name: 'noteLike',
//     component: noteLike
//   },
//   {
//     path: '/message/spotComment',
//     name: 'spotComment',
//     component: spotComment
//   },
//   {
//     path: '/message/MyActivities',
//     name: 'MyActivities',
//     component: MyActivities
//   },

//   // 其他用户主页
//   {
//     path: '/other/otherIndex/:uid',
//     name: 'otherIndex',
//     component: otherIndex
//   },

//   // 聊天窗口
//   {
//     path: '/message/ChatWindow/:uid',
//     name: 'ChatWindow',
//     component: ChatWindow
//   }, // 其他
//   {
//     path: '/rests/DownloadApp',
//     name: 'DownloadApp',
//     component: DownloadApp
//   },
//   {
//     path: '/rests/about',
//     name: 'about',
//     component: about
//   },
//   {
//     path: '/rests/feedback',
//     name: 'feedback',
//     component: feedback
//   },
//   // 足迹
//   {
//     path: '/footprints/FootprintsIndex',
//     name: 'FootprintsIndex',
//     component: FootprintsIndex
//   },
//   {
//     path: '/footprints/AwardList',
//     name: 'AwardList',
//     component: AwardList
//   },
//   {
//     path: '/footprints/FootprintsParticulars',
//     name: 'FootprintsParticulars',
//     component: FootprintsParticulars
//   },
//   {
//     path: '/footprints/FootprintsPublish',
//     name: 'FootprintsPublish',
//     component: FootprintsPublish
//   },
//   {
//     path: '/footprints/RedPacketMap',
//     name: 'RedPacketMap',
//     component: RedPacketMap
//   },
//   {
//     path: '/footprints/MapNavigation',
//     name: 'MapNavigation',
//     component: MapNavigation
//   },
//   {
//     path: '/footprints/GiveRabPacket',
//     name: 'GiveRabPacket',
//     component: GiveRabPacket
//   },
//   {
//     path: '/footprints/MakerMap',
//     name: 'MakerMap',
//     component: MakerMap
//   },
//   {
//     path: '/footprints/PrimaryScenic',
//     name: 'PrimaryScenic',
//     component: PrimaryScenic
//   },
//   {
//     path: '/footprints/LineList',
//     name: 'LineList',
//     component: LineList
//   },
//   {
//     path: '/footprints/LineInfoList',
//     name: 'LineInfoList',
//     component: LineInfoList
//   },
//   {
//     path: '/footprints/RootBooksInfo',
//     name: 'RootBooksInfo',
//     component: RootBooksInfo
//   },
//   {
//     path: '/footprints/LineInfoListtwo',
//     name: 'LineInfoListtwo',
//     component: LineInfoListtwo
//   },
//   {
//     path: '/footprints/AppLineInfoList',
//     name: 'AppLineInfoList',
//     component: AppLineInfoList
//   },
//   {
//     path: '/footprints/ImgList',
//     name: 'ImgList',
//     component: ImgList
//   },

//   {
//     path: '/track/myTrackList',
//     name: 'myTrackList',
//     component: myTrackList
//   },
//   {
//     path: '/setting/permission',
//     name: 'permission',
//     component: permission
//   },
//   {
//     path: '/setting/QiDongPermission',
//     name: 'QiDongPermission',
//     component: QiDongPermission
//   },
//   {
//     path: '/appDetail/detail',
//     name: 'Detail',
//     component: Detail
//   },

//   // 订单
//   {
//     path: '/order/MyOrders',
//     name: 'MyOrders',
//     component: MyOrders
//   },
//   {
//     // 订单长id、出行时间time、人数num、总价price、用户名name、用户头像img、标题tit
//     path: '/order/GuideOrderPay/:id/:time/:num/:price/:name/:img/:tit',
//     name: 'GuideOrderPay',
//     component: GuideOrderPay
//   },
//   {
//     path: '/order/GuideOrderEdit/:id/:luId/:price/:tit/:verify',
//     name: 'GuideOrderEdit',
//     component: GuideOrderEdit
//   },
//   {
//     path: '/order/GuideOrderDetail/:id/:status/:my',
//     name: 'GuideOrderDetail',
//     component: GuideOrderDetail
//   },
//   {
//     path: '/order/GuideOrderCancel/',
//     name: 'GuideOrderCancel',
//     component: GuideOrderCancel
//   },
//   {
//     path: '/order/GuideOrderSuccess/',
//     name: 'GuideOrderSuccess',
//     component: GuideOrderSuccess
//   },
//   {
//     path: '/order/GuideAgreement/',
//     name: 'GuideAgreement',
//     component: GuideAgreement
//   },
//   // 摄影大赛
//   {
//     path: '/parkPhoto/ParkPhoto',
//     name: 'ParkPhoto',
//     component: ParkPhoto
//   },
//   {
//     path: '/parkPhoto/PhotoList',
//     name: 'PhotoList',
//     component: PhotoList
//   },
//   {
//     path: '/parkPhoto/detail',
//     name: 'detail',
//     component: detail
//   },
//   {
//     path: '/parkPhoto/RankList',
//     name: 'RankList',
//     component: RankList
//   },
//   {
//     path: '/parkPhoto/ParkMapLineList',
//     name: 'ParkMapLineList',
//     component: ParkMapLineList
//   },
//   {
//     path: '/parkPhoto/DrawMap',
//     name: 'DrawMap',
//     component: DrawMap
//   },
//   {
//     path: '/parkPhoto/Label',
//     name: 'Label',
//     component: Label
//   },
//   {
//     path: '/parkPhoto/LabelDetail',
//     name: 'LabelDetail',
//     component: LabelDetail
//   },
//   {
//     path: '/parkPhoto/VideoVote',
//     name: 'VideoVote',
//     component: VideoVote
//   },
//   {
//     path: '/parkPhoto/VideoRank',
//     name: 'VideoRank',
//     component: VideoRank
//   },
//   {
//     path: '/parkPhoto/VideoRule',
//     name: 'VideoRule',
//     component: VideoRule
//   },
//   {
//     path: '/parkPhoto/VideoDetail',
//     name: 'VideoDetail',
//     component: VideoDetail
//   },
//   {
//     path: '/parkPhoto/HuangShiTravelIndex',
//     name: 'HuangShiTravelIndex',
//     component: HuangShiTravelIndex
//   },
//   {
//     path: '/parkPhoto/ParkLineFoot',
//     name: 'ParkLineFoot',
//     component: ParkLineFoot
//   },
//   {
//     path: '/parkPhoto/ActivityMap',
//     name: 'ActivityMap',
//     component: ActivityMap
//   },
//   {
//     path: '/parkPhoto/MerchantsInfo',
//     name: 'MerchantsInfo',
//     component: MerchantsInfo
//   },
//   {
//     path: '/SearchHome/SearchIndex',
//     name: 'SearchIndex',
//     component: SearchIndex
//   },
//   {
//     path: '/verification/index',
//     name: 'vindex',
//     component: index
//   },
//   {
//     path: '/ticket',
//     name: 'TicketIndex',
//     component: TicketIndex
//   },
//   {
//     path: '/ticket/CheckTicket',
//     name: 'CheckTicket',
//     component: CheckTicket
//   },
//   {
//     path: '/ticket/TicketList',
//     name: 'TicketList',
//     component: TicketList
//   },
//   {
//     path: '/ticket/TicketParkList',
//     name: 'TicketParkList',
//     component: TicketParkList
//   },
//   {
//     path: '/ticket/MakeOrder',
//     name: 'MakeOrder',
//     component: MakeOrder
//   },
//   {
//     path: '/ticket/Pay',
//     name: 'Pay',
//     component: Pay
//   },
//   {
//     path: '/ticket/Success',
//     name: 'Success',
//     component: Success
//   },
//   {
//     path: '/my/Order',
//     name: 'Order',
//     component: Order
//   },
//   // {
//   //   path: '/wuhanlvyouxiehui/wx_index',
//   //   name: 'wx_index',
//   //   component: wx_index
//   // },
//   // {
//   //   path: '/wuhanlvyouxiehui/wx_coupon_list',
//   //   name: 'wx_coupon_list',
//   //   component: wx_coupon_list
//   // },
//   // {
//   //   path: '/wuhanlvyouxiehui/wx_prev_detail',
//   //   name: 'wx_prev_detail',
//   //   component: wx_prev_detail
//   // },
//   // {
//   //   path: '/wuhanlvyouxiehui/wx_activity_list',
//   //   name: 'wx_activity_list',
//   //   component: wx_activity_list
//   // },
//   // {
//   //   path: '/wuhanlvyouxiehui/wx_order_list',
//   //   name: 'wx_order_list',
//   //   component: wx_order_list
//   // },
//   // {
//   //   path: '/wuhanlvyouxiehui/wx_detail',
//   //   name: 'wx_detail',
//   //   component: wx_detail
//   // },
//   // {
//   //   path: '/wuhanlvyouxiehui/wx_activity_detail',
//   //   name: 'wx_activity_detail',
//   //   component: wx_activity_detail
//   // },

//   {
//     path: '/question/qindex',
//     name: 'qindex',
//     component: qindex
//   },
//   {
//     path: '/question/qdetail',
//     name: 'qdetail',
//     component: qdetail
//   },
//   {
//     path: '/question/qrank',
//     name: 'qrank',
//     component: qrank
//   },
//   {
//     path: '/footprints/LineInfoListV2',
//     name: 'LineInfoListV2',
//     component: LineInfoListV2
//   },

//   {
//     path: '/DrawMap/NoDrawMap',
//     name: 'NoDrawMap',
//     component: NoDrawMap
//   },
//   {
//     path: '/pixi/pixiIndex',
//     name: 'pixiIndex',
//     component: pixiIndex
//   }
//   ]
// })

// export default router;