<template>
  <div v-title data-title="选择要载入的轨迹">
    <mt-header fixed title="选择要载入的轨迹">
      <router-link :to="link" slot="left">
        <mt-button icon="back"></mt-button>
      </router-link>
    </mt-header>

    <div class="page-content">
      <div class="loading-track-wrap" v-for="track in loadingList" :key="track.id" :id="track.id">
        <mt-cell is-link>
          <div slot="title">
            <span class="load-track-title">{{track.record_title}}</span>
          </div>
        </mt-cell>
        <div class="load-track-content">
          <ul class="clearfix">
            <li>
              <router-link class="load-go" :to="{ name: 'TrackDetail', params: { id: track.id }}">
                <img src="../../assets/img/track_leavefor.png" />
                前往
              </router-link>
            </li>
            <li @click="showContent(track.record_title)">
              <img src="../../assets/img/track_toload.png" />
              加载
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.load-track-content {
  background-color: #fff;
  padding: 10px;
}
.load-track-title {
  font-size: 12px;
  color: #00cabe;
}
.load-track-content li {
  width: 25%;
  float: left;
  text-align: center;
  color: #666;
  font-size: 12px;
}
.load-track-content li .load-go {
  display: block;
  color: #666;
}
.load-track-content li img {
  margin: 10px auto;
  width: 30px;
  height: 30px;
}
</style>

<script>
export default {
  data() {
    return {
      link: "",
      loadingList: [],
      loading: false
    };
  },
  mounted() {
    this.backIndex();
  },
  methods: {
    backIndex() {
      var stype = this.$route.query.type;
      this.link = "/footguide/NewFootGuide?type=" + stype;

      var user_id = this.get_User_Id();
      var data = {
        user_id: user_id,
        user_main_id: user_id,
        record_id: "1"
      };
      var that = this;
      this.$ajax("post", "User/get_user_notes_new", data, function(res) {
        that.loadingList = res.data.data;
      });
    },
    showContent(value) {
      var record_title = value;
      //                console.log(record_title);
      this.save_arr("record_title", record_title);
      var stype = this.$route.query.type;
      this.$router.push("/footguide/NewFootGuide?type=" + stype);
    },

    // 展示轨迹
    showtrack(id) {
      console.log(id);
    }
  }
};
</script>