<template>
  <div class="activity-detail" v-title :data-title="info.activity_name">
    <div class="ad-img">
      <img :src="info.banner_img_app" />
    </div>
    <div class="ad-title">
      <span>{{info.activity_name}}</span>
    </div>
    <div class="ad-content">
      <div v-html="info.activity_rich_text"></div>
    </div>
    <!--立即预约-->
    <div class="ad-make-order" @click="ClickMakeOrder">
      <span>立即预约</span>
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";

export default {
  data() {
    return {
      title: "美丽乡村",
      id: this.$route.query.id,
      info: ""
    };
  },
  computed: {},
  mounted() {
    this.get_activity_detail();
  },
  methods: {
    get_activity_detail() {
      var data_ = {
        id: this.id,
        token: "zmlxj_2019_wuhanlvyouxiehui"
      };

      this.$ajax("post", "Other/get_activity_detail", data_, ret => {
        if (ret.data.id == 0) {
          this.info = ret.data.data.detail;
        } else {
          //没有数据
          Toast("没有数据");
        }
      });
    },

    /**预约的订单**/
    ClickMakeOrder() {
      this.$router.push({
        path: "/wuhanlvyouxiehui/wx_make_order",
        query: { id: this.id }
      });
    }
  }
};
</script>

<style scoped>
.activity-detail {
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: white;
}
.activity-detail .ad-img {
  display: inline-block;
  position: relative;
  width: 100%;
}

.activity-detail .ad-img img {
  width: 100%;
  height: 230px;
}
.activity-detail .ad-title {
  display: inline-block;
  position: relative;
  width: 100%;
}
.activity-detail .ad-title span {
  display: block;
  padding: 5px 0 5px 0;
  font-size: 1rem;
  text-align: center;
}
.activity-detail .ad-content {
  display: inline-block;
  position: relative;
  width: 100%;
}
.activity-detail .ad-content * {
  max-width: 100%;
}
.activity-detail .ad-make-order {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.activity-detail .ad-make-order span {
  display: block;
  padding: 10px;
  font-size: 1rem;
  background-color: #33beff;
  color: white;
  text-align: center;
}
</style>