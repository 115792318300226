import { render, staticRenderFns } from "./DownloadApp.vue?vue&type=template&id=81e82528&scoped=true&"
var script = {}
import style0 from "./DownloadApp.vue?vue&type=style&index=0&id=81e82528&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81e82528",
  null
  
)

export default component.exports