<template>
  <div class="publich-container" v-title data-title="我要报名">
    <div class="publish-wrapper">
      <div class="publish-header">
        <div class="header-back" @click="$router.go(-1)">
          <i class="iconfont iconback"></i>
        </div>
        <h2>报名信息</h2>
        <div class="header-issue" @click="issue">
          <p>提交</p>
        </div>
      </div>

      <div class="publish-content">
        <form id="form" action method="post" enctype="multipart/form-data">
          <ul class="form-list">
            <li class="form-item">
              <p class="form-name">商户名称</p>
              <input type="text" class="form-value" placeholder="请填写商户名称" v-model="title" />
            </li>
            <li class="form-item">
              <p class="form-name">经营地址</p>
              <input type="text" class="form-value" v-model="address" placeholder="请填写经营地址" />
            </li>
            <li class="form-item form-radio">
              <p class="form-name">特色标签</p>
              <div class="form-value">
                <p
                  v-for="(item,i) in label_list"
                  :key="i"
                  :class="select_feature === i ? 'checked' : ''"
                  @click="selectFeature(i)"
                >{{item.label_name}}</p>
              </div>
            </li>
          </ul>

          <input type="hidden" name="label_id" v-model="label_id" />
          <div class="describe">
            <!-- 描述简介 -->
            <mt-field
              :attr="{name:'content'}"
              v-model="content"
              placeholder="作品描述（根据作品特点进行描述）"
              rows="3"
              type="textarea"
            ></mt-field>
          </div>
          <div class="filenum clearfix" style="padding-bottom: 5px">
            <div class="fl" style="line-height: 32px">是否参与活动</div>
            <mt-switch v-model="value" class="fr"></mt-switch>
            <input type="hidden" name="open" v-if="value" value="1" />
            <input type="hidden" name="open" v-if="!value" value="0" />
          </div>
          <div class="imgs clearfix">
            <div class="item add-image">
              <img src="../../assets/img/tour_photo@2x.png" @click="AddImage($event)" />
              <input type="file" accept="image/*" />
            </div>
          </div>
          <div class="blue filenum">上传图片（最多上传9张图片）</div>
        </form>
        <div id="container" style="width:100%; height:150px" ref="container">
          <div class="left_but">
            <div class="mak_tab">
              <!--地图 + -  -->
              <img src="../../assets/img/map_+.png" alt @click="map_set_zoom(map,'+')" />
              <img src="../../assets/img/map_-.png" alt @click="map_set_zoom(map, '-')" />
            </div>
            <div class="mak_tab map_my_location">
              <!--地图定位- -->
              <img src="../../assets/img/map_my_location.png" alt @click="get_map(1)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Indicator, MessageBox, Toast } from 'mint-ui'
import * as qiniu from 'qiniu-js'
import md5 from 'js-md5'
export default {
  data () {
    return {
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      open_id: localStorage.getItem('open_id')
        ? localStorage.getItem('open_id')
        : '',
      backUrl: 'shopList',
      park_id: 298,
      user_id: '', // 用户ID
      verify: '',

      map: {},

      title: '', // 商户名
      content: '', // 详情描述
      label_id: this.$route.query.aid > 0 ? this.$route.query.aid : 7,
      address: '', // 经营地址

      select_feature: -1,
      value:
        !(this.$route.query.open != 1 && this.$route.query.open), // 是否公开
      add_time: new Date().getTime(),
      lng: 0,
      lat: 0,
      discuss_img_arr: [],
      foot_upload_img: [],
      get_foot_upload_img: [],
      text: '',
      is_tab_map_3d: true, // 3D地图按钮是否点击
      label_list: '', // 足迹标签列表
      maxSize: 100 * 1024, // 图片压缩
      is_android: false, // 判断在android还是在ios中打开

      // 七牛
      qiniu_token: '',
      qiniu_img_arr: [],
      qiniu_key: ''
    }
  },
  mounted () {
    if (this.token) {
    } else {
      // 第一次生成uuid
      var token = this.make_token()
      this.token = token
      localStorage.setItem('token', token)
    }

    console.log('token=' + this.token)
    // 投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id !== 'undefined' &&
        this.open_id != 0
      )
    ) {
      // 已经存在了,什么都不做
    } else {
      // 本地没有open_id 就从url后面读取
      // 判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem('weixin_user_info', this.$route.query.open_id)
          localStorage.setItem('open_id', this.$route.query.open_id)
          this.open_id = this.$route.query.open_id
        } else {
          // 说明是别人分享给我的,不保存open_id
          localStorage.setItem('weixin_user_info', '')
          localStorage.setItem('open_id', '')
          this.open_id = ''
        }
      } else {
        // 没有token,说明程序有误
        this.open_id = ''
        localStorage.setItem('weixin_user_info', '')
        localStorage.setItem('open_id', '')
      }
    }
    if (this.open_id) {
    } else {
      //   this.authorize();
    }

    this.unload()
  },
  methods: {
    // 选择特色
    selectFeature (i) {
      if (this.select_feature === i) {
        this.select_feature = -1
      } else {
        this.select_feature = i
      }
    },
    // 授权方法
    authorize () {
      var open_url =
        'https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=' +
        this.token +
        '&id=' +
        this.id +
        '&state=' +
        this.backUrl
      window.location.href = open_url
    },
    unload () {
      // 加载地图，调用浏览器定位服务
      this.map = this.map_init({
        init_zoom: 15,
        max_zoom: 20
      })

      var that = this

      localStorage.removeItem('foot_upload_img')

      this.$nextTick(() => {
        console.log(this.$refs.container.style.height)
        $('#container')[0].style.height =
          $('.publich-container')[0].offsetHeight -
          $('#form')[0].offsetHeight -
          $('.publish-header')[0].offsetHeight +
          'px'
        $('#container')[0].style.minHeight = '350px'
        $('.park_info').height($('#app').height() - 40)
      })
      if (!this.check_login()) {
        MessageBox.alert('请先登录').then(action => {
          if (action == 'confirm') {
            localStorage.setItem('login_url', location.hash)
            this.$router.push({ path: '/my/login' })
          }
        })
      } else {
        this.user_id = this.check_login().user_id
        this.verify = this.check_login().user_id + new Date().getTime()
      }
      this.get_map()
      let ua = navigator.userAgent.toLowerCase()
      // android终端
      let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
      this.is_android = isAndroid >= 0
      this.get_foot_label()
    },
    get_foot_label () {
      this.$ajax(
        'post',
        'foot/get_foot_label',
        {
          aid: this.label_id,
          token: this.token
        },
        ret => {
          if (ret.data.id == 0) {
            this.label_list = ret.data.data.list
          } else {
            // 没有得到标签-移动站发布必须有网
          }
        }
      )
    },
    get_map (type) {
      var that = this
      if (type == 1) {
        if (this.lng == 0) {
          type = ''
        } else {
          this.map.setZoomAndCenter(this.map.getZoom(), [this.lng, this.lat])
        }
      } else {
        type = 'no'
      }

      that.map_Geo_location({
        map: this.map,
        type: type,
        callback: function (lng, lat) {
          that.lng = lng
          that.lat = lat
        }
      })
    },
    AddImage ($event) {
      // console.log($event.target);
      var file = $($event.target).siblings()[0]
      console.log(file)
      var that = this
      file.click()
      file.onchange = function () {
        var files = Array.prototype.slice.call(this.files)
        files.forEach(function (file, i) {
          var reader = new FileReader()
          reader.onload = function () {
            var img_data = {}
            img_data.result = this.result
            img_data.filetype = file.type
            img_data.filename = file.name
            var length = file.size
            that.discuss_img_arr.push(that.upload(this.result, file.type))
            that.foot_upload_img.push(img_data)

            localStorage.setItem(
              'foot_upload_img',
              JSON.stringify(that.foot_upload_img)
            )
          }
          reader.readAsDataURL(file)
          var objUrl = that.getObjectURL(file)
          var html =
            '<div class="item up-img">' +
            '<img src="' +
            objUrl +
            '"><i class="remove"></i>' +
            '</div>'
          $('.imgs').prepend(html)
          if ($('.up-img').length >= 9) {
            $('.add-image').hide()
            Toast('每次只能上传9张图片')
          }
        })
        $('.remove').on('click', that.remove_img)
      }
    },
    // 删除图片
    remove_img (e) {
      this.discuss_img_arr.splice(
        $(e.target)
          .parent()
          .index(),
        1
      )
      $(e.target)
        .parent()
        .remove()
      if ($('.up-img').length < 9) {
        $('.add-image').show()
      }
    },
    // 发布
    issue () {
      if (this.lng == 0) {
        Toast('请重新定位')
        return
      } else if (!$('.up-img').length) {
        Toast('请选择至少一张图片')
        return
      } else if (this.select_feature == -1) {
        Toast('请选择标签')
        return
      }
      Indicator.open('发布中...')

      var that = this
      if (this.open_id && this.token && this.lng && this.lat && this.user_id) {
        this.$ajax(
          'post',
          'Lottery/get_qiniu_token',
          {
            token: this.token,
            bucket: 'image',
            open_id: this.open_id
          },
          res => {
            if (res.data.id == 0) {
              let t = new Date()
                .toLocaleDateString()
                .split('/')
                .map(item => {
                  if (item.length <= 1) {
                    return '0' + item
                  }
                  return item
                })
                .join('')

              let len = this.foot_upload_img.length,
                sum = 0,
                img_key = ''
              for (let i = 0; i < len; i++) {
                let fileName = this.foot_upload_img[i].filename
                let key = t + '_' + md5(fileName)
                let blob = this.discuss_img_arr[i]

                var observable = qiniu.upload(blob, key, res.data.data.token)
                var observer = {
                  next (res) {},
                  error (err) {
                    console.log(err)
                    Toast('上传图片失败')
                  },
                  complete (res) {
                    sum++
                    if (sum === 1) {
                      img_key = res.key
                    } else {
                      img_key = img_key + '|' + res.key
                    }
                    if (sum === len) {
                      that.$ajax(
                        'post',
                        'foot/web_foot_upload',
                        {
                          lng: that.lng,
                          lat: that.lat,
                          title: that.title,
                          address: that.address,
                          content: that.content,
                          id_: that.label_list[that.select_feature].id,
                          label_id: that.label_id,
                          user_id: that.user_id,
                          verify: that.make_token(),
                          open: that.value ? 1 : 0,
                          img_key: img_key,
                          open_id: that.open_id
                        },
                        res => {
                          if (res.data.id == 0) {
                            // 表示发布成功之后，跳转到投票页面
                            console.log('发布成功')
                            that.$router.push({
                              path: '/shop/shopList',
                              query: {
                                aid: that.label_id
                              }
                            })
                          } else {
                            Toast('发布失败')
                          }
                        }
                      )
                    }
                  }
                }
                var subscription = observable.subscribe(observer)
              }

              Indicator.close()
            } else {
              Toast('提交失败')
            }
          }
        )
      } else {
        if (this.open_id == '') {
          this.authorize()
        } else if (this.user_id == '') {
          MessageBox.alert('请先登录').then(action => {
            if (action == 'confirm') {
              localStorage.setItem('login_url', location.hash)
              this.$router.push({ path: '/my/login' })
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>
/* 顶部 */
.publish-header {
  position: relative;
  width: 100%;
  height: 2.2rem;
  box-shadow: 0 3px 2px -2px #00000016;
  background: linear-gradient(45deg, rgb(248, 104, 7), #e33);
}
.publish-header h2 {
  text-align: center;
  line-height: 2.2rem;
  font-size: 0.88rem;
  font-weight: bold;
  color: #fff;
}
.publish-header .header-back {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.publish-header .header-back i {
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
}
.publish-header .header-issue {
  position: absolute;
  top: 0;
  right: 0.5rem;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.78rem;
  /* background-color: #00000011; */
  color: #fff;
}
.header-issue p {
  display: inline-block;
  padding: 2px 12px;
  border: 1px solid #ffffff99;
  border-radius: 2em;
}

/*  */
.mint-indexlist-content {
  overflow: initial;
  overflow-y: scroll;
}

.publich-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #fff;
}

.publish-wrapper .el-vue-amap-container,
.publish-wrapper .el-vue-amap-container .el-vue-amap {
  width: 100%;
  height: 150px;
}

.publish-content {
  background-color: #fff;
  /* padding-top: 40px; */
}

.gray_bg {
  background-color: #f2f2f2;
}

.publish-wrapper .filenum {
  font-size: 0.78rem;
  padding: 0.2rem 0.6rem 0.8rem;
  color: #333;
}

.publish-wrapper .park {
  padding: 0.4rem;
  font-size: 0.6rem;
}

.publish-wrapper .park .list span {
  padding: 3px 8px;
  border-radius: 3px;
}

.publish-wrapper .specialty_list {
  font-size: 0.6rem;
  padding: 0 0.2rem 0.4rem;
}

.publish-wrapper .specialty_list span {
  display: inline-block;
  padding: 3px 5px;
  border-radius: 2px;
  color: #000;
}

.publish-wrapper .specialty_list span.active {
  background-color: #ff9a80;
  color: #fff;
}
/*  */
#container {
  position: relative;
}
.left_but {
  position: absolute;
  left: 0.5rem;
  top: 1rem;
  z-index: 1;
}
.left_but .mak_tab {
  background-color: #fff;
  width: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 2px 2px 5px #ccc;
  box-shadow: 2px 2px 5px #ccc;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
}

.left_but .mak_tab img {
  width: 100%;
  padding: 0.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.left_but .mak_tab:last-child {
  margin-bottom: 0;
}
.left_but .mak_tab.map_my_location img,
.left_but .mak_tab img:last-child {
  border: none;
}

.publish-wrapper >>> .mint-cell-wrapper {
  background: none;
  font-size: 0.78rem;
  border: none;
  align-items: center;
  padding: 0 0.5rem;
}
.publish-wrapper >>> .mint-cell-wrapper .mint-cell-title {
  color: #333;
}

.publish-wrapper .publish-content >>> .describe {
  border: none;
}
.publish-wrapper .publish-content >>> .describe .mint-cell-value {
  border: 1px solid #eee;
  font-size: 0.78rem;
  color: #666;
  border-radius: 4px;
  padding: 5px;
}

.publish-wrapper .publish-content >>> .mint-field.is-textarea {
  border: none;
}
.publish-wrapper .publish-content >>> .mint-field-core {
  text-align: left;
  font-size: 0.78rem;
  color: #333;
  resize: none;
}
.publish-wrapper
  .publish-content
  >>> .mint-field-core::-webkit-input-placeholder {
  font-size: 0.78rem;
  color: #999;
}

#form >>> .mint-switch-input:checked + .mint-switch-core {
  border-color: #fa4c21;
  background-color: #fa4c21;
}
#form >>> .mint-switch-core {
  transform: scale(0.666);
}

#form >>> .blue {
  color: #666;
}

.publich-container .publish-wrapper >>> .imgs {
  padding: 10px;
}

.publich-container .publish-wrapper >>> .imgs .item {
  position: relative;
  float: left;
  width: 50%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 4rem;
  overflow: hidden;
  height: 4rem;
}

.publich-container .publish-wrapper >>> .imgs .item input {
  padding-top: 100%;
  position: relative;
  display: block;
  background-size: cover;
  border-radius: 2px;
  background-position: center;
  width: 100%;
  height: 0;
  z-index: -1;
}

.publich-container .publish-wrapper >>> .imgs .item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.publich-container .publish-wrapper >>> .imgs .item .remove {
  background: url(../../assets/img/search_clean.png) 0 0 no-repeat;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.publich-container .publish-wrapper >>> .imgs .add-image input {
  height: 0;
  border: none;
}

/* 修改from样式 */
.form-list {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
}
.form-item {
  width: 100%;
  display: flex;
  min-height: 2.2rem;
  align-items: center;
  font-size: 0.78rem;
}
.form-name {
  width: 4rem;
  color: #333;
}
.form-value {
  width: calc(100% - 4rem);
  color: #666;
  font-size: 0.78rem;
}
.form-value::-webkit-input-placeholder {
  color: #999;
}
.form-radio {
  align-items: flex-start;
  margin-top: 0.5rem;
}
.form-radio .form-value {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.form-radio .form-value p {
  position: relative;
  padding: 0 1rem;
  margin-bottom: 0.4rem;
}
.form-radio .form-value p::before {
  content: "";
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  padding: 2px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-clip: content-box;
  background-color: transparent;
  transition: 0.3s;
}
.form-radio .form-value p.checked::before {
  border-color: rgba(238, 51, 51, 0.3);
  background-color: #e33;
  transition: 0.3s;
}
</style>
