<template>
	<div>
		<ServiceTitle></ServiceTitle>

		<div class="page-content">
			<div class="new-title-content">游玩详情</div>
			<form id="new-funy" action="#" method="post" enctype="multipart/form-data">
				<input type="hidden" name="verify" v-model="verify" />
				<input type="hidden" name="s_online_id" v-model="s_online_id" />
				<input type="hidden" name="f_localhost_id" v-model="f_localhost_id" />
				<input type="hidden" name="content" v-model="content" />

				<div class="withdraw-wrap">
					<div class="input-wrap" style="padding-left: 20px;">
						<input type="text" placeholder="请输入游玩点名称" name="title" v-model="title" />
					</div>
					<div class="input-wrap clearfix" style="padding:0">
						<mt-field   type="textarea" rows="4"  v-model="content"></mt-field>
						<span class="foot-guide-tips fl" style="padding-left:10px;">(您可以输入{{wordNum}}个字)</span>
					</div>
					<div class="add-cover-content clearfix">
						<img src="../../assets/img/tour_photo@2x.png" class="add-image-btn" alt="" @click="AddImage($event)">
						<input type="file" multiple accept="image/*" class="imgAdd" style="display: none">
					</div>
					<div class="add-tips">请上传封面图片（至少上传一张图片）<br />注：请尝试在浏览文件时选取一个以上的文件</div>
				</div>
				<div class="foot-guide-btn" id="add_funy" @click="saveMsg">保存</div>
			</form>
		</div>		
	</div>
</template>

<style scoped>
	.add-cover-content,.add-tips{
		background-color: #fff;
		padding: 10px;
	}
	.add-tips{
		font-size: 12px;
		color: #cbcbcb;
	}
	.img-add,.add-image-btn{
		position: relative;
		float: left;
		width: 78px;
		height: 100px;
		margin: 0 5px 5px;
	}
	.img-add img{
		width: 100%;
		height: 100%;
	}
	.cover-item{
		float: left;
		width: 80px;
		height: 100px;
		border: 1px dashed #c9c9c9;
		text-align: center;
		line-height: 100px;
		font-size: 30px;
		color: #cacaca;
	}
	.add-cover-content .img-add .remove {
		background: url(../../assets/img/search_clean.png) 0 0 no-repeat;
		background-size: 20px 20px;
		width: 20px;
		height: 20px;
		position: absolute;
		top: -10px;
		right: -10px;
		z-index: 1;
	}
</style>

<script>
    import ServiceTitle from '../../components/ServiceTitle'
    import { Toast } from 'mint-ui';

	export default{
        name:'AddVisitPoint',
        components:{
            ServiceTitle
        },
		mounted(){
          this.getBaseMsg();
		},
		data(){
			return{
                reviewSrc:'',
                verify:'',
				fileNum:0,
                content:'',
                wordNum:399,
                s_online_id:1,
            	f_localhost_id:localStorage.f_localhost_id,
                title:'',
                discuss_img_arr: []
			}
		},
		watch:{
            content(value){
                this.wordNum = 399 - value.length;
                this.save_arr('fun_content',value);
			},
            title(value){
                this.title = value;
			}
		},
		methods:{
            getBaseMsg(){
                // 得到verify
                var user_id = this.get_User_Id();
//                var user_id = '13554289325';
                var timestamp=new Date().getTime();
                this.verify = user_id + timestamp;
			},

			// 添加图片
            AddImage($event){
                var file = $('.imgAdd')[0];
                var that = this;
                file.click();
                file.onchange = function () {
                    var files = Array.prototype.slice.call(this.files);
                    files.forEach(function (file, i) {
                        var reader = new FileReader();
                        reader.onload = function () {
                            that.discuss_img_arr.push(that.upload(this.result, file.type))
                        }
                        reader.readAsDataURL(file);
                        var objUrl = that.getObjectURL(file);
                        var html = '<div class="img-add">'
                            + '<img src="' + objUrl + '"><i class="remove"></i>'
                            + '</div>';
                        $(".add-cover-content").prepend(html);

                        if($('.img-add').length >= 9){
							$('.add-image-btn').hide();
                            Toast('最多可上传9张图片');
						}
                    });

                    $('.remove').on("click", that.remove_img)
                }
            },

			// 删除图片
            remove_img(e){
                this.discuss_img_arr.splice($(e.target).parent().index(), 1);
                $(e.target).parent().remove();
                if ($('.up-img').length < 9) {
                    $('.add-image').show()
                }
            },
            saveMsg(){
                var stype = this.$route.query.type;
                var link = '/booking/SubmitSuccess?type='+stype;

                if(!this.title){
                    Toast("请输入游玩点名称");
                    return;
				}

				if(!this.content){
                    Toast("请输入游玩点详情");
                    return;
				}

                if (!$('.img-add').length) {
                    Toast('请选择至少一张图片');
                    return
                }
                if ($('.img-add').length > 9) {
                    Toast('选择图片最多9张');
                    return
                }

                var form = document.getElementById("new-funy");
                var formdata = new FormData(form);
                for (var i = 0; i < this.discuss_img_arr.length; i++) {
                    formdata.append("img[]", this.discuss_img_arr[i]);
                }

                this.formMethod('Service/web_add_funy',formdata,(res) => {
//                        console.log(data);
                        if(res.id == 0){
                            Toast(res.msg+'！跳转中……');
                            this.$router.push(link);
                        }else{
                            Toast(res.msg);
                        }
				})

			}
		}
	}
</script>
