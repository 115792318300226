<template></template>

<script>
import { Toast } from "mint-ui";

export default {
  data() {
    return {
      title: "美丽乡村",
      park_id: this.$route.query.park_id
    };
  },
  computed: {},
  mounted() {
    this.get_order_list();
  },
  methods: {
    get_order_list() {
      var data_ = {
        park_id: this.park_id,
        token: "zmlxj_2019_wuhanlvyouxiehui"
      };

      this.$ajax("post", "Other/get_park_list", data_, ret => {
        if (ret.data.id == 0) {
          this.info = ret.data.data.park;
        } else {
          //没有数据
          Toast("没有数据");
        }
      });
    },
    /*我要领劵*/
    click_get_coupon() {
      //进入领劵页面
      this.$router.push({
        path: "/wuhanlvyouxiehui/wx_coupon_list",
        query: { park_id: this.park_id }
      });
    },
    /*我要预约*/
    click_get_order() {
      //进入预约平台
      this.$router.push({
        path: "/wuhanlvyouxiehui/wx_order_list",
        query: { park_id: this.park_id }
      });
    },
    /*路线列表*/
    ClickActivityList() {
      this.$router.push({
        path: "/wuhanlvyouxiehui/wx_activity_list",
        quesy: { park_id: this.park_id }
      });
    },
    /**预约的订单**/
    ClickMyOrderList() {
      this.$router.push({
        path: "/wuhanlvyouxiehui/wx_order_list",
        query: { park_id: this.park_id }
      });
    }
  }
};
</script>

<style scoped>
</style>