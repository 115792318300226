<template>
  <div class="container">
    <div class="main" v-show="loaded">
      <swiper class="swiper-container" :options="swiperOption" ref="mainSwiper">
        <swiper-slide class="swiper-slide">
          <transition name="fade">
            <div class="slide-1 slide" v-show="activeIndex === 0">
              <div class="bg" v-if="loaded">

                <img src="../../assets/car/slide_1/bg.png" alt class="img" />
              </div>
              <div class="content">
            
              </div>
              <img src="../../assets/car/slide_1/vip.png" alt class="vip" />
              <img src="../../assets/car/slide_1/hui.png" alt class="hui" />
              <img src="../../assets/car/slide_1/yuan.png" alt class="yuan" />
              <img src="../../assets/car/slide_1/zhao.png" alt class="zhao" />
              <img src="../../assets/car/slide_1/mu.png" alt class="mu" />
              <img src="../../assets/car/slide_1/jiaruwomen.png" alt class="jiaruwomen" />
              <img src="../../assets/car/slide_1/qiche.png" alt class="qiche" />
              <img src="../../assets/car/slide_1/mingcheng.png" alt class="mingcheng" />
            </div>
          </transition>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <transition name="fade">
            <div class="slide-2 slide" v-show="activeIndex === 1">
              <div class="bg" v-if="loaded">
                <div class="mask"></div>
                <img :src="bgList[1].baseUrl" class="img" />
              </div>
              <div class="content">
                <div class="title">
                  <p class="text rotate-x">{{title}}</p>
                  <p class="text rotate-y">会员报名统计信息</p>
                </div>
                <ul class="sign-list">
                  <li class="item">
                    <input class="inp" type="text" placeholder="姓名" v-model="name" />
                  </li>
                  <li class="item">
                    <input class="inp" type="number" placeholder="手机号" v-model="phone" />
                  </li>
                  <li class="item">
                    <input class="inp" type="text" placeholder="车牌号" v-model="carNumber" />
                  </li>
                  <li class="item">
                    <input class="inp" type="number" placeholder="身份证号" v-model="idCard" />
                  </li>
                </ul>
                <div class="footer opacity-out">
                  <span class="text">-{{title}}-</span>
                </div>
              </div>
            </div>
          </transition>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <transition name="fade">
            <div class="slide-3 slide" v-show="activeIndex === 2">
              <div class="bg" v-if="loaded">
                <div class="mask"></div>
                <img :src="bgList[2].baseUrl" class="img" />
              </div>
              <div class="content">
                <div class="title">
                  <p class="text rotate-x">{{title}}</p>
                  <p class="text rotate-y">会员报名统计信息</p>
                </div>
                <ul class="upload-list">
                  <li class="item item-img rotate-x">
                    <img src="../../assets/car/element-1.png" class="img" />
                  </li>
                  <li class="item item-inp">
                    <p class="text">{{driveImgName}}</p>
                    <input
                      class="inp"
                      type="file"
                      accept="image/*"
                      placeholder="上传图片"
                      @input="setImg($event, 0)"
                    />
                  </li>

                  <li class="item item-img rotate-x">
                    <img src="../../assets/car/element-2.png" class="img" />
                  </li>
                  <li class="item item-inp">
                    <p class="text">{{safeImgName}}</p>
                    <input
                      class="inp"
                      type="file"
                      accept="image/*"
                      placeholder="上传图片"
                      @input="setImg($event, 1)"
                    />
                  </li>
                  <li class="item-btn item">
                    <button class="btn" @click="submit">提交信息</button>
                  </li>
                </ul>
                <div class="footer opacity-out">
                  <span class="text">-{{title}}-</span>
                </div>
              </div>
            </div>
          </transition>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <transition name="fade">
            <div class="slide-4 slide" v-show="activeIndex === 3">
              <div class="bg" v-if="loaded">
                <div class="mask"></div>
                <img :src="bgList[4].baseUrl" class="img" />
              </div>
              <div class="content">
                <div class="logo">
                  <img :src="logo" class="img rotate2d-y" />
                </div>
                <div class="contact">
                  <p class="text rotate2d-x">联系我们</p>
                </div>
                <div class="qrcode">
                  <img :src="qrcode" class="img rotatez-scale" />
                </div>
                <div class="tip">
                  <p class="text fade-in-1">长按二维码识别关注我</p>
                  <a :href="'tel:' + tel" class="link fade-in-2">联系电话：{{tel}}</a>
                </div>
                <div class="footer opacity-out">
                  <span class="text">-{{title}}-</span>
                </div>
              </div>
            </div>
          </transition>
        </swiper-slide>
        <swiper-slide class="swiper-slide" v-for="(list, index) in activityList" :key="index">
          <transition name="fade">
            <div class="slide-5 slide" v-show="activeIndex > 3">
              <!-- <div class="bg" v-if="loaded">
                <div class="mask"></div>
                <img :src="bgList[0].baseUrl" class="img" />
              </div>-->
              <ul class="activiti-list">
                <li
                  class="item"
                  v-for="(item, i) in list"
                  :key="i"
                  @click="jumpToActivity(item.id)"
                >
                  <img :src="item.banner_img_app" class="img" />
                  <span class="text">{{item.activity_name}}</span>
                </li>
              </ul>
            </div>
          </transition>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <!-- 向上的小箭头 -->
      <div class="up">
        <i class="iconfont iconUp"></i>
      </div>
      <!-- 背景音乐按钮 -->
      <div :class="['play-btn', isPlay ? 'playing' : '']" @click="handlePlay">
        <img src="../../assets/switch/bgmusic.png" class="img" />
      </div>
      <!-- 背景音乐 -->
      <audio :src="bgAudioUrl" loop="loop" autoplay="autoplay" ref="audio"></audio>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "swiper/vue";
import { Toast, Indicator, MessageBox } from "mint-ui";
import * as qiniu from "qiniu-js";
import md5 from "js-md5";
var wx = require("weixin-js-sdk");
export default {
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        direction: "vertical",
        autoplay: false,
        loop: false,
        speed: 300,
        pagination: {
          el: ".swiper-pagination",
          //   type: 'progressbar',
          //   type: 'fraction',
          clickable: false
        }
      },

      // 接口
      backUrl: "DriveCar",
      park_id: 298,
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      id: this.$route.query.id ? this.$route.query.id : 0,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      version: "1.0.0",
      open_id: localStorage.getItem("open_id")
        ? localStorage.getItem("open_id")
        : "",

      // 获取数据
      bgList: [],
      logo: "",
      title: "",
      content: "",
      bgAudioUrl: "",
      activityList: [],
      subscribeUrl: "",
      bgAudioUrl: "",
      activityParkId: "",
      car_park_id: "",
      tel: "",
      qrcode: "",
      // 自定义
      isPlay: true,
      haveMoreActivity: true,
      loaded: false,
      activeIndex: 0,
      swiper: null,
      audioDom: null,
      activityPage: 2,
      slideOneList: [],
      // 提交数据
      name: "",
      phone: "",
      carNumber: "",
      idCard: "",

      driveImgName: "上传图片",
      driveImgFile: null,
      driveImgKey: "",
      driveImgBlob: "",
      safeImgName: "上传图片",
      safeImgFile: null,
      safeImgKey: "",
      safeImgBlob: ""
    };
  },
  mounted() {
    if (this.token) {
    } else {
      //第一次生成uuid
      var token = this.make_token();
      this.token = token;
      localStorage.setItem("token", token);
    }

    console.log("token=" + this.token);
    //投票相关
    if (
      this.open_id &&
      !(
        !this.open_id &&
        typeof this.open_id != "undefined" &&
        this.open_id != 0
      )
    ) {
      //已经存在了,什么都不做
    } else {
      //本地没有union_id 就从url后面读取
      //判断URL后面的token，是否和本地的token保持一致
      if (this.$route.query.token) {
        if (
          this.token == this.$route.query.token &&
          this.$route.query.open_id
        ) {
          localStorage.setItem("weixin_user_info", this.$route.query.open_id);
          localStorage.setItem("open_id", this.$route.query.open_id);
          this.open_id = this.$route.query.open_id;
        } else {
          //说明是别人分享给我的,不保存open_id
          localStorage.setItem("weixin_user_info", "");
          localStorage.setItem("open_id", "");
          this.open_id = "";
        }
      } else {
        //没有token,说明程序有误
        this.open_id = "";
        localStorage.setItem("weixin_user_info", "");
        localStorage.setItem("open_id", "");
      }
    }

    this.dealWithUrl(window.location.href);
    /* 微信分享 */
    if (this.is_weixin) {
      if (!this.open_id) {
        this.authorize();
      }
    }

    /* 获取数据 */
    this.getData();

    this.$nextTick(() => {
      this.bindSlideChange();
      this.audioDom = this.$refs.audio;
      document.documentElement.addEventListener(
        "touchstart",
        this.initAudioPlay
      );
    });
  },
  methods: {
    // 活动相册详情
    jumpToActivity(id) {
      this.$router.push({
        path: "/album/album",
        query: {
          aid: id
        }
      });
    },
    // 管理背景音乐播放状态
    handlePlay() {
      if (this.isPlay) {
        this.audioDom.pause();
      } else {
        this.audioDom.play();
      }

      this.isPlay = !this.isPlay;
    },
    // 初始化播放背景音乐
    initAudioPlay() {
      this.audioDom.play();
      document.documentElement.removeEventListener(
        "touchstart",
        this.initAudioPlay
      );
    },
    // 设置图片
    setImg(e, i) {
      if (!e.target.value) {
        return false;
      }
      const file = e.target.files[0];
      let reader = new FileReader(); // 读取文件实例
      reader.onload = e => {
        const blob = this.upload(e.target.result, file.type); // 调用公共方法将data转换为blob
        if (i === 0) {
          // 行驶证图片
          this.driveImgName = file.name;
          this.driveImgFile = file;
          this.driveImgBlob = blob;
        } else if (i === 1) {
          // 保险单图片
          this.safeImgName = file.name;
          this.safeImgFile = file;
          this.safeImgBlob = blob;
        } else {
          return false;
        }
      };
      reader.readAsDataURL(file);
    },
    // 提交
    submit() {
      if (!this.token && !this.open_id && !this.car_park_id) {
        this.authorize();
        return false;
      }

      // 验证姓名
      if (!this.name) {
        MessageBox.confirm("姓名 为必填项，是否前往设置？").then(
          resolve => {
            this.swiper.slidePrev();
          },
          reject => {
            console.log(reject);
          }
        );
        return false;
      }
      // 验证手机号
      const phoneReg = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (!this.phone || !phoneReg.test(this.phone)) {
        MessageBox.confirm("手机号 填写错误，是否前往设置？").then(
          resolve => {
            this.swiper.slidePrev();
          },
          reject => {
            console.log(reject);
          }
        );
        return false;
      }
      // 验证车牌号
      const carNumberReg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/i;
      if (!this.carNumber || !carNumberReg.test(this.carNumber)) {
        MessageBox.confirm("车牌号 填写错误，是否前往设置？").then(
          resolve => {
            this.swiper.slidePrev();
          },
          reject => {
            console.log(reject);
          }
        );
        return false;
      }
      // 验证身份证号
      const idcardReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (!this.idCard || !idcardReg.test(this.idCard)) {
        MessageBox.confirm("身份证号 填写错误，是否前往设置？").then(
          resolve => {
            this.swiper.slidePrev();
          },
          reject => {
            console.log(reject);
          }
        );
        return false;
      }
      // 验证行驶证图片
      if (!this.driveImgFile) {
        MessageBox.alert("请选择行驶证照片", "提示");
        return false;
      }

      // 保险单照片
      if (!this.safeImgFile) {
        MessageBox.alert("请选择保险单照片", "提示");
        return false;
      }

      // 信息填写正确，开始提交
      if (this.isSubmit) {
        return false;
      }
      this.isSubmit = true;
      Indicator.open("正在提交...");
      this.saveSubmit("Lottery/get_qiniu_token", {
        token: this.token,
        bucket: "image",
        open_id: this.open_id
      }).then(
        res => {
          if (res.data.id === 0) {
            let t = new Date()
              .toLocaleDateString()
              .split("/")
              .map(item => {
                if (item.length <= 1) {
                  return "0" + item;
                }
                return item;
              })
              .join("");
            let token = res.data.data.token,
              driveKey = t + "_" + md5(this.driveImgName),
              safeKey = t + "_" + md5(this.safeImgName);

            this.qiniuUpload(this.driveImgBlob, driveKey, token)
              .then(
                res => {
                  this.driveImgKey = res.key;
                  return this.qiniuUpload(this.safeImgBlob, safeKey, token);
                },
                err => {
                  Toast("行驶证上传失败");
                }
              )
              .then(
                res => {
                  this.safeImgKey = res.key;
                  return this.saveSubmit("car/save_drive_car_data", {
                    name: this.name,
                    tel: this.phone,
                    park_id: this.car_park_id,
                    car_numbers: this.carNumber,
                    card_numbers: this.idCard,
                    drive_img_key: this.driveImgKey,
                    safe_img_key: this.safeImgKey,
                    open_id: this.open_id
                  });
                },
                err => {
                  Toast("保险单上传失败");
                }
              )
              .then(res => {
                if (res.data.id === 0) {
                  MessageBox.alert("提交成功！", "恭喜");
                  this.isSubmit = false;
                }
              });
            Indicator.close();
          } else {
            Toast("连接图片服务器失败");
          }
        },
        err => {
          throw err;
        }
      );
    },
    // 将数据保存到服务器
    saveSubmit(url, info) {
      let promise = new Promise((resolve, reject) => {
        this.$ajax("post", url, info, res => {
          resolve(res);
        });
      });
      return promise;
    },
    // 七牛上传图片获取返回的key
    qiniuUpload(blob, key, token) {
      let promise = new Promise((resolve, reject) => {
        let observable = qiniu.upload(blob, key, token);
        let observer = {
          next(res) {},
          error(err) {
            reject(err);
          },
          complete(res) {
            resolve(res);
          }
        };
        observable.subscribe(observer);
      });
      return promise;
    },
    // slide 切换
    bindSlideChange() {
      this.swiper = this.$refs.mainSwiper.swiper;
      this.swiper.on("slideChange", () => {
        this.activeIndex = this.swiper.activeIndex;
        if (this.swiper.isEnd && this.haveMoreActivity) {
          this.getActivityData();
        }
      });
    },
    getActivityData() {
      if (!this.haveMoreActivity || this.isLoadActivity) {
        return false;
      }
      this.isLoadActivity = true;

      this.$ajax(
        "post",
        "car/get_activity_list",
        {
          page: this.activityPage,
          park_id: this.activityParkId,
          token: this.token
        },
        res => {
          if (res.data.id === 0) {
            const ls = res.data.data.list;
            if (!ls || ls.length < 10) {
              this.haveMoreActivity = false;
            }
            if (ls && ls.length) {
              this.activityList.push([...ls]);
              this.activityPage++;
            }
          } else {
            this.haveMoreActivity = false;
          }
          this.isLoadActivity = false;
        }
      );
    },
    // 获取数据
    getData() {
      Indicator.open({
        text: "加载中...",
        spinnerType: "fading-circle"
      });
      if (this.id && this.token && this.backUrl) {
        this.$ajax(
          "post",
          "Car/get_drive_car_data",
          {
            id: this.id,
            token: this.token,
            id_type_: this.backUrl
          },
          res => {
            if (res.data.id === 0) {
              const rt = res.data.data;
              document.title = rt.data.title;
              this.bgList.push(...rt.data.image_list);
              this.title = rt.data.title;
              this.content = rt.data.content;
              this.logo = rt.data.logo_;
              this.bgAudioUrl = rt.data.bgAudioUrl;
              this.subscribeUrl = rt.subscribeUrl;
              this.bgAudioUrl = rt.data.bgAudioUrl;
              this.activityParkId = rt.data.park_id;
              this.tel = rt.data.tel_;
              this.car_park_id = rt.data.park_id;
              this.qrcode = rt.data.qrcode;
              // 活动相册
              let len = rt.activity_list ? rt.activity_list.length : 0;
              if (len > 0) {
                this.activityList.push([...rt.activity_list]);
              }
              if (len < 10) {
                this.haveMoreActivity = false;
              }

              // 获取活动数据
              this.getActivityData();
              // 微信分享
              const wx_title = rt.data.title,
                wx_content = rt.data.content,
                wx_img = rt.data.logo_,
                url = window.location.href;
              if (this.is_weixin) {
                this.wx_share_callback(url, {
                  url: url,
                  id_: this.id,
                  id_type_: this.backUrl,
                  token: this.token,
                  open_id: this.open_id,
                  title: wx_title,
                  content: wx_content,
                  img: wx_img
                });
              }
            }
            this.loaded = true;
            Indicator.close();
          }
        );
      }
    },

    // 授权方法
    authorize() {
      var open_url =
        "https://www.zmlxj.com/api.php/SpotsRace/weixin_login_base?token=" +
        this.token +
        "&id=" +
        this.id +
        "&state=" +
        this.backUrl;
      window.location.href = open_url;
    }
  }
};
</script>

<style scoped>
.container {
  width: 100vw;
  min-height: 100vh;
}

.container >>> .mint-indicator-wrapper {
  z-index: 10;
}

.swiper-container {
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.swiper-container .swiper-slide {
  width: 100%;
  height: 100%;
}

.up {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  animation: up 1s linear infinite;
}

@keyframes up {
  0% {
    opacity: 0;
    bottom: 0;
  }
  50% {
    opacity: 1;
    bottom: 0.6rem;
  }
  60% {
    opacity: 0.8;
    bottom: 0.6rem;
  }
  100% {
    opacity: 0;
    bottom: 1rem;
  }
}

.up > .iconfont {
  font-size: 1.5rem;
  color: #fff;
}

.play-btn {
  position: fixed;
  top: 2rem;
  right: 1rem;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.playing {
  animation: playing 6s linear infinite both;
}

@keyframes playing {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(1turn);
  }
}

.play-btn > .img {
  width: 100%;
  height: 100%;
}

/* slide */
.fade-leave {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: 0.5s;
}

.slide {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide > .bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/* .slide > .bg > .mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 2;
} */

.slide > .bg > .img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  z-index: 1;
}

.slide > .content {
  position: relative;
  width: 90%;
  z-index: 2;
}

.slide > .content > .footer {
  padding: 1rem 0;
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fff;
}

.opacity-out {
  animation: opacity-out 1.5s ease 1 both;
}

@keyframes opacity-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.6;
  }
}

/* 1 */

.slide-1 {
  perspective: 300;
}
.slide-1 > .content {
  width: 89.1%;
  height: 95.5%;
  perspective: 300;
}

.slide-1 > .content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  border: 2px dotted rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  animation: border-out 1.2s linear 1 both;
  z-index: 0;
}

.slide-1 > .content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  border: 2px dotted rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  animation: border-in 1.2s linear 1 both;
  z-index: 0;
}

@keyframes border-out {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes border-in {
  from {
    width: 150%;
  }
  to {
    width: 100%;
  }
}

.slide-1 > .content > .title {
  width: 100%;
  animation: rightslide 1s linear 1 both;
}

@keyframes rightslide {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-1 > .content > .title > .img {
  width: 100%;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.6));
}

.slide-1 > .content > .name {
  width: 100%;
  transform-origin: 50% 0;
  text-align: center;
}

.rotate-x {
  animation: rotate-x 1.2s ease 1 both;
}

@keyframes rotate-x {
  0% {
    opacity: 0;
    transform: rotateX(15deg);
  }
  20% {
    opacity: 1;
    transform: rotateX(-10deg);
  }
  40% {
    transform: rotateX(10deg);
  }
  60% {
    transform: rotateX(-5deg);
  }
  80% {
    transform: rotateX(5deg);
  }
  100% {
    transform: rotateX(0);
  }
}

.slide-1 > .content > .name > .text {
  display: inline-block;
  font-size: 1.2rem;
  padding: 8px 16px;
  color: #fff;
  background-color: #093c64;
}

.slide-1 > .content > .recruit {
  width: 100%;
  animation: scaledown 1s linear 1 both;
}

@keyframes scaledown {
  0% {
    opacity: 0;
    transform: scale(2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.slide-1 > .content > .recruit > .img {
  width: 100%;
}

.slide-1 .vip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 2;
  animation: vip .8s linear  1  both;
}

@keyframes vip {
  from{
    transform: translateX(100%)
  }
  to{
    transform: translateX(0)
  }
}

.slide-1 .hui {
  position: absolute;
  width: 16%;
  height: 9%;
  top: 26%;
  left: 19%;
  z-index: 2;
  transform-origin: 50% 0;
  animation: rotate-x 1.2s linear .8s 1 both;
}
.slide-1 .yuan {
  position: absolute;
  width: 16%;
  height: 9%;
  top: 26%;
  left: 36%;
  z-index: 2;
  transform-origin: 50% 0;
  animation: rotate-x 1.2s linear 1.1s 1 both;
}
.slide-1 .zhao {
  position: absolute;
  width: 16%;
  height: 9%;
  top: 26%;
  left: 53%;
  z-index: 2;
  transform-origin: 50% 0;
  animation: rotate-x 1.2s linear 1.4s 1 both;
}
.slide-1 .mu {
  position: absolute;
  width: 16%;
  height: 9%;
  top: 26%;
  left: 71%;
  z-index: 2;
  transform-origin: 50% 0;
  animation: rotate-x 1.2s linear 1.7s 1 both;
}
.slide-1 .jiaruwomen {
  position: absolute;
  width: 16%;
  height: 9%;
  top: 16.3%;
  left: 68.2%;
  z-index: 2;
  animation: scaledown 1s ease-in 1s 1  both;
}
.slide-1 .qiche {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0%;
  left: 0%;
  z-index: 2;
  animation: qiche 1.2s ease-in 1s 1  both;
}

@keyframes qiche {
  0% {
    transform: scale(0)
  }90% {
    transform: scale(1.01)
  }100%{
    transform: scale(1)
  }
}
.slide-1 .mingcheng {
  position: absolute;
  width: 100%;
  bottom: 0%;
  left: 0%;
  z-index: 2;
  animation: mingcheng .5s ease-in 2.2s 1  both;
}

@keyframes mingcheng {
  0% {
    transform: translateX(100%) skewX(0);
  }
  50%{
    transform: translateX(0) skewX(0);
  }
  60% {
    transform: translateX(0) skewX(30deg);
  }
  80% {
    transform: translateX(2px) skewX(30deg);
  }
  100% {
    transform: translateX(0) skewX(0)
  }
}

/* 2 */
.slide-2 > .content > .title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  text-align: center;
  perspective: 300;
}

.slide-2 > .content > .title > .text {
  transform-style: preserve-3d;
}

.rotate-y {
  animation: rotate-y 1s ease 0.4s 1 both;
}

@keyframes rotate-y {
  0% {
    opacity: 0;
    transform: rotateY(60deg);
  }
  40% {
    opacity: 1;
    transform: rotateY(-10deg);
  }
  55% {
    transform: rotateY(20deg);
  }
  70% {
    transform: rotateY(-5deg);
  }
  85% {
    transform: rotateY(6deg);
  }
  100% {
    transform: rotateY(0);
  }
}

.slide-2 > .content > .sign-list {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-list > .item {
  margin: 0.6rem 0;
  width: 70%;
}

.sign-list > .item > .inp {
  display: inline-block;
  padding: 0.6rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  background-color: #093c64;
  color: #fff;
  animation: scalebounce 1s 1 both;
}

@keyframes scalebounce {
  0% {
    transform: scale(0.92);
  }
  20% {
    transform: scale(1.04);
  }
  40% {
    transform: scale(0.95);
  }
  60% {
    transform: scale(1.02);
  }
  80% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

.sign-list > .item > .inp::placeholder {
  color: #dfdfdf;
}

/* 3 */
.slide-3 > .content > .title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  text-align: center;
  perspective: 300;
}

.upload-list {
  margin: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  perspective: 300;
}

.upload-list > .item-img {
  width: 100%;
  transform-origin: 50% 0;
  background-color: #409eff;
}

.upload-list > .item-img > .img {
  width: 100%;
}

.upload-list > .item-inp {
  position: relative;
  margin: 0.5rem 0;
  width: 100%;
  text-align: center;
}

.upload-list > .item-inp > .inp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.4rem;
  opacity: 0;
}

.upload-list > .item-inp > .text {
  width: 100%;
  height: 2.4rem;
  line-height: 2.4rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #333;
  font-size: 0.8rem;
  background-color: #fff;
}

.upload-list > .item-btn {
  width: 100%;
}

.upload-list > .item-btn > .btn {
  width: 100%;
  outline: none;
  border: none;
  background-color: rgb(250, 143, 20);
  color: #fff;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
  font-size: 0.88rem;
}

/* 4 */
.slide-4 > .content {
  text-align: center;
}

.slide-4 .logo {
  width: 100%;
  text-align: center;
}

.slide-4 .logo > .img {
  width: 60%;
}

.slide-4 .contact {
  margin-top: 1rem;
  display: inline-block;
  perspective: 300;
  background-color: #409eff;
}

.rotate2d-y {
  animation: rotate2d-y 2s ease 1 both;
}

@keyframes rotate2d-y {
  from {
    transform: rotateY(-90deg);
  }
  to {
    transform: rotateY(0);
  }
}

.rotate2d-x {
  transform-origin: 50% 0;
  animation: rotate2d-x 1.2s ease 0.5s 1 both;
}

@keyframes rotate2d-x {
  from {
    transform: rotateX(90deg);
  }
  to {
    transform: rotateX(0);
  }
}

.slide-4 .contact > .text {
  display: inline-block;
  padding: 6px 36px;
  font-size: 1.3rem;
  color: #fff;
}

.slide-4 .qrcode {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}

.rotatez-scale {
  animation: rotatez-scale 1s linear 1 both;
}

@keyframes rotatez-scale {
  from {
    transform: rotateZ(-360deg) scale(0);
  }
  to {
    transform: rotateZ(0) scale(1);
  }
}

.slide-4 .qrcode > .img {
  width: 40%;
}

.slide-4 .tip {
  margin-top: 1rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fff;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.6);
  text-align: center;
}

.slide-4 .tip .link {
  display: block;
  color: #fff;
}

.fade-in-1 {
  animation: fade-in 1s 0.5s 1 both;
}

.fade-in-2 {
  animation: fade-in 1s 1s 1 both;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(30%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 5 */
.slide-5 > .content {
  text-align: center;
}

.slide-5 .logo {
  width: 100%;
}

.slide-5 .logo > .img {
  width: 60%;
}

.slide-5 .title {
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.activiti-list {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  z-index: 1;
}

.activiti-list > .item {
  position: relative;
  display: inline-block;
  width: 50%;
  height: 20%;
  padding: 0.5rem;
  box-sizing: border-box;
}

.activiti-list > .item > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.activiti-list > .item > .text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.7rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  color: #fff;
  text-shadow: -1px 1px 1px #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>