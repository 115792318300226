<template>
    <mt-header fixed :title="headertitle">
        <router-link to="" slot="left">
            <mt-button icon="back"  @click.native="returnBack()"></mt-button>
        </router-link>
    </mt-header>
</template>

<script>
    export default {
        data () {
            return{
                headertitle:'',
            }
        },
        mounted(){
            this.getServiceType();
        },
        methods:{
            getServiceType(){
                var stype = this.$route.query.type;
                switch(stype){
                    case '1':
                        this.headertitle = '新建徒步向导路线';
                        break;
                    case '2':
                        this.headertitle = '新建接送机服务';
                        break;
                    case '3':
                        this.headertitle = '新建包车向导路线';
                        break;
                    default:
                        this.headertitle = '新建导游服务';
                        break;
                }

//                 console.log(this.headertitle);
            },

            returnBack(){
                this.$router.go(-1);
            }
        }
    }
</script>