<template>
  <div class="MerchantsInfo" v-title data-title=".11">
    <!--v-if="is_weixin"-->
    <div class="search_box">
      <div class="search">
        <div class="label_box">
          <label>手机号</label>
          <input type="text" v-model="user_tel" />
        </div>
        <div class="label_box">
          <label>兑换码</label>
          <input type="text" value v-model="code" />
        </div>
      </div>
      <div class="button" @click="search">
        <button>搜索</button>
      </div>
    </div>
    <div class="exchange_list" v-if="info.length > 0">
      <h2>兑换信息</h2>
      <mt-loadmore
        :bottom-method="loadBottom"
        :bottom-all-loaded="allLoaded"
        ref="loadmore"
        :auto-fill="false"
      >
        <ul>
          <li v-for="(k, i) in info" :key="i">
            <div class="box">
              <div class="left">
                <p>
                  <span>手机号</span>
                  {{k.user_tel}}
                </p>
                <p>
                  <span>兑换码</span>
                  {{k.code}}
                </p>
                <p>
                  <span>姓名</span>
                  {{k.user_nick}}
                </p>
                <p>
                  <span>奖品</span>
                  {{k.title}}
                </p>
              </div>
              <div
                class="r iconfont"
                :class="{'icon-yiduihuan':k.status == 1,'icon-weiduihuan':k.status == 0}"
              ></div>
            </div>
            <div class="conversion" v-if="is_show_conversion" @click="conversion(k.id)">去兑换</div>
          </li>
        </ul>
      </mt-loadmore>
    </div>
    <div v-else class="foot_noinfo">
      <img src="/static/img/foot_noinfo@2x.14391c2.png" alt />
      <p>暂无兑换信息</p>
    </div>
  </div>
  <!--<div v-else class="foot_noinfo"><img src="/static/img/foot_noinfo@2x.14391c2.png" alt="">
  <p>请在微信页面打开链接</p></div>-->
</template>
<script>
import { Toast } from "mint-ui";
import { MessageBox } from "mint-ui";
export default {
  data() {
    return {
      user_id: "",
      info_c: [], //数据
      info: [], //数据
      allLoaded: false,
      user_tel: "",
      code: "",
      is_weixin: this.hybrid.hybrid.versions.is_weixin,
      is_show_conversion: false
    };
  },
  mounted() {
    this.unload();
  },
  methods: {
    unload() {
      /*if (!this.is_weixin) {
                    return false;
                }*/
      if (this.check_login()) {
        this.user_id = this.check_login().user_id;
      } else {
        MessageBox("请先登录").then(action => {
          if (action == "confirm") {
            localStorage.setItem("login_url", location.hash);
            this.$router.push({ path: "/my/login", query: { log_type: 1 } });
          }
        });
      }
      this.get_data(1);
    },
    get_data(page, user_tel, code) {
      this.$ajax(
        "post",
        "SpotsRace/get_seller_reward_list",
        {
          user_id: this.user_id,
          page: page,
          user_tel: user_tel,
          code: code
        },
        ret => {
          if (ret.data.id == 0) {
            if (!user_tel) {
              //                            this.info_c = ret.data.data;
              this.info_c = this.info_c.concat(ret.data.data);
            }
            //                        this.info = ret.data.data;
            this.info = this.info.concat(ret.data.data);
          } else {
            this.info = [];
            Toast(ret.data.msg);
          }
        }
      );
    },
    //            点击搜索
    search() {
      if (this.user_tel && this.code) {
        this.get_data(1, this.user_tel, this.code);
        if (this.info.length > 0) {
          this.is_show_conversion = true;
        }
      } else {
        Toast("请输入手机号、兑换码");
      }
    },
    //            点击兑换
    conversion(id) {
      MessageBox({
        title: "确认兑换吗？",
        showCancelButton: true
      }).then(action => {
        if (action == "confirm") {
          this.$ajax(
            "post",
            "SpotsRace/modify_status",
            {
              id: id,
              user_tel: this.user_tel,
              code: this.code
            },
            ret => {
              if (ret.data.id == 0) {
                Toast({
                  message: ret.data.msg,
                  iconClass: "iconfont icon-chenggong",
                  duration: 1000
                });
              } else {
                Toast({
                  message: ret.data.msg,
                  iconClass: "iconfont icon-fail",
                  duration: 1000
                });
              }
              this.is_show_conversion = false;
              this.info = this.info_c;
            }
          );
        }
      });
    },
    //            加载更多
    loadBottom() {
      var that = this;
      setTimeout(() => {
        if (this.itemLen % 10 == 0) {
          that.page++;
          that.get_data(that.page);
        } else {
          that.allLoaded = true;
          Toast("没有更多了");
        }
        that.$refs.loadmore.onBottomLoaded();
      }, 1500);
    }
  }
};
</script>
<style scoped>
.MerchantsInfo {
  background-color: #fff;
}

.MerchantsInfo .search_box {
  display: flex;
  padding: 0.4rem 0.4rem 2rem;
  border-bottom: 0.15rem solid #cacdd2;
}

.MerchantsInfo .search_box .search {
  flex: 3;
}

.MerchantsInfo .search_box .label_box {
  display: flex;
  align-items: center;
}

.MerchantsInfo .search_box .search label {
  font-size: 0.7rem;
  line-height: 2rem;
  flex: 1;
}

.MerchantsInfo .search_box .search input {
  border: 1px solid #dfdfdf;
  height: 1.4rem;
  padding: 0 0.2rem;
  flex: 3;
  font-size: 0.7rem;
}

.MerchantsInfo .search_box .button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MerchantsInfo .search_box .button button {
  border: 0;
  background-color: #ff9a80;
  color: #fff;
  font-size: 1rem;
  border-radius: 0.2rem;
  height: 1.6rem;
  padding: 0 0.6rem;
}

.MerchantsInfo .exchange_list {
  padding: 0.4rem;
}

.MerchantsInfo .exchange_list h2 {
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.MerchantsInfo .exchange_list ul li .box {
  display: flex;
  background-color: #ff9a80;
  padding: 0.4rem;
  margin: 0.4rem 0;
  border-radius: 0.2rem;
}

.MerchantsInfo .exchange_list ul li {
  color: #fff;
  font-size: 0.7rem;
}

.MerchantsInfo .exchange_list ul li .left {
  flex: 4;
}

.MerchantsInfo .exchange_list ul li p {
  line-height: 1.4rem;
}

.MerchantsInfo .exchange_list ul li p span {
  width: 20%;
  display: inline-block;
}

.MerchantsInfo .exchange_list ul li .r {
  flex: 1.5;
  font-size: 4rem;
}

.MerchantsInfo .exchange_list ul li .r.icon-yiduihuan {
  color: #ff0f00;
}

.MerchantsInfo .exchange_list ul li .r.icon-weiduihuan {
  color: #666;
}

.conversion {
  background-color: #ff9a80;
  color: #fff;
  width: 70%;
  margin-left: 17%;
  display: inline-block;
  text-align: center;
  line-height: 2rem;
  font-size: 1rem;
  border-radius: 0.4rem;
}
</style>
