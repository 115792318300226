import { render, staticRenderFns } from "./MyPublish.vue?vue&type=template&id=18f6a5b3&scoped=true&"
import script from "./MyPublish.vue?vue&type=script&lang=js&"
export * from "./MyPublish.vue?vue&type=script&lang=js&"
import style0 from "./MyPublish.vue?vue&type=style&index=0&id=18f6a5b3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f6a5b3",
  null
  
)

export default component.exports