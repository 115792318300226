import { render, staticRenderFns } from "./NoteIndex.vue?vue&type=template&id=60b3b855&scoped=true&"
import script from "./NoteIndex.vue?vue&type=script&lang=js&"
export * from "./NoteIndex.vue?vue&type=script&lang=js&"
import style0 from "./NoteIndex.vue?vue&type=style&index=0&id=60b3b855&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b3b855",
  null
  
)

export default component.exports